import { WprBaseViewListMultiModeInfo } from '../../../../../wpr-framework/mode/WprBaseViewListMultiModeInfo';
import { WprMultiMode } from '../../../../../wpr-framework/mode/WprMultiMode';
import { AddPhoneConfirmView } from '../AddPhoneConfirmView';
import { WprDI_Object } from '../../../../../wpr-framework/view/di/WprDI_Object';
import { PhoneInfoModel } from '../../../../view/Phone/add_phone_select/models/PhoneInfoModel';

/**
 * OptionConfirmMode
 */
export class OptionConfirmMode extends WprBaseViewListMultiModeInfo<AddPhoneConfirmView, PhoneInfoModel> {
	// DI情報  ------------------------------------------------------------------
	@WprDI_Object
	private m_OptionConfirmList: PhoneInfoModel[]	= null;	// オプション確認リスト
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	public constructor() {
		super('OptionConfirmList');
	}
	// --------------------------------------------------------------------------
	
	// override メソッド  --------------------------------------------------------
	/**
	 * モードリスト取得（この画面モードで使用する全てのモードを定義する）
	 * @param list モードリスト
	 */
	public useModeList(list: string[]): void {
		list.push('BottomNone');
	}

	/**
	 * フィールド定義を登録処理
	 */
	public onRegisterControl(): void {
		this.addControl('optionName', { class: [ { mode: 'BottomNone', class: 'borderBottom' }, { mode: 'other', class: '' } ] });
	}

	/**
	 * ビューの状態をチェックして行のモードを変更する
	 * @param curMode 現在のモード
	 * @param newMode 新しいモード
	 * @param row 行データ
	 */
	public onCheckListMultiMode(curMode: WprMultiMode, newMode: WprMultiMode, row: PhoneInfoModel): void {
		const idx = this.m_OptionConfirmList.length;
		if (this.m_OptionConfirmList[idx-1].optionName === row.optionName)
			newMode.addMode('BottomNone');
	}
	// --------------------------------------------------------------------------
}
