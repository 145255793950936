import { WprBaseViewCore } from '../../WprBaseViewCore';
import { WprControlInfo } from '../WprControlInfo';
import { WprControRefreshMap } from './WprControRefreshMap';

/**
 * コントロール情報マップ
 */
export class WprControlInfoMap {
	// private 変数  ------------------------------------------------------------
	private m_ControlMap: Map<string, WprControRefreshMap>	= new Map<string, WprControRefreshMap>();	// コントロールリフレッシュ情報マップ
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** コントロールリフレッシュ情報マップ */
	public get controlRefreshMap(): Map<string, WprControRefreshMap> 	{ return this.m_ControlMap; }
	// --------------------------------------------------------------------------

	// public メソッド  ---------------------------------------------------------
	/**
	 * コントロール情報リスト追加
	 * @param viewCore ビュー情報
	 */
	public addControlList(viewCore: WprBaseViewCore, subViewName?: string) {
		viewCore.controlMap.forEach((cinfo, key, map) => {
			this.addControlInfo('@@all', viewCore, cinfo);
			if (subViewName)
				this.addControlInfo('@' + subViewName, viewCore, cinfo);
			else
				this.addControlInfo('@root', viewCore, cinfo);
			if (cinfo.config.categoryList) {
				cinfo.config.categoryList.forEach(category => {
					this.addControlInfo('#' + category, viewCore, cinfo);
				});
			}
		});
	}

	/**
	 * コントロール情報マップ取得
	 * @returns コントロール情報マップ
	 */
	public get(name: string): WprControRefreshMap {
		if (this.m_ControlMap.has(name) === true)
			return this.m_ControlMap.get(name);
		return null;
	}

	/**
	 * コントロール情報リスト取得
	 * @returns コントロール情報リスト
	 */
	public getControlInfoList(): WprControlInfo[] {
		if (this.m_ControlMap.has('@@all'))
			return this.m_ControlMap.get('@@all').getControlInfoList();
		return new Array();
	}
	// --------------------------------------------------------------------------

	// private メソッド  ---------------------------------------------------------
	/**
	 * コントロール情報取得
	 * @param key キー
	 * @param viewCore ビュー情報
	 * @param cinfo コントロール情報
	 */
	private addControlInfo(key: string, viewCore: WprBaseViewCore, cinfo: WprControlInfo): void {
		if (this.m_ControlMap.has(key) === false)
			this.m_ControlMap.set(key, new WprControRefreshMap(key));
		this.m_ControlMap.get(key).addControlInfo(viewCore, cinfo);
	}

	/**
	 * コントロールの情報でモデルを更新する
	 * @param viewCore ビュー情報
	 * @param key キー
	 */
	public refreshModel(viewCore: WprBaseViewCore, key: string): void {
		if (this.m_ControlMap.has('@@all') === false)
			return;
		const cinfo = this.m_ControlMap.get('@@all').getControlRefreshInfo(key);
		if (cinfo != null) {
			cinfo.refreshModel();
		}
		else {
			if (this.m_ControlMap.has(key) === false) {
				if (key.includes(',') === true) {
					key.split(',').forEach(name => {
						if (this.m_ControlMap.has(name) === true)
							this.m_ControlMap.get(name).refreshModel();
					});
					return;
				}
				else if (this.m_ControlMap.has('@' + key) === true) {
					key = '@' + key;
				}
				else if (this.m_ControlMap.has('#' + key) === true) {
					key = '#' + key;
				}
				else {
					viewCore.addErrorLog(`refreshModelの引数に誤りがあります。[${key}]`);
					return;
				}
			}
			this.m_ControlMap.get(key).refreshModel();
		}
	}

	/**
	 * モデルの情報でコントロールを更新する
	 * @param viewCore ビュー情報
	 * @param key キー
	 */
	public refreshView(viewCore: WprBaseViewCore, key: string): void {
		if (this.m_ControlMap.has('@@all') === false)
			return;
		const cinfo = this.m_ControlMap.get('@@all').getControlRefreshInfo(key);
		if (cinfo != null) {
			cinfo.refreshView();
		}
		else {
			if (this.m_ControlMap.has(key) === false) {
				if (key.includes(',') === true) {
					key.split(',').forEach(name => {
						if (this.m_ControlMap.has(name.trim()) === true)
							this.m_ControlMap.get(name.trim()).refreshView();
					});
					return;
				}
				else if (this.m_ControlMap.has('@' + key) === true) {
					key = '@' + key;
				}
				else if (this.m_ControlMap.has('#' + key) === true) {
					key = '#' + key;
				}
				else {
					viewCore.addErrorLog(`refreshViewの引数に誤りがあります。[${key}]`);
					return;
				}
			}
			this.m_ControlMap.get(key).refreshView();
		}
	}

	/**
	 * コントロールの値をクリアする
	 * @param viewCore ビュー情報
	 * @param key キー
	 */
	public clearValue(viewCore: WprBaseViewCore, key: string): void {
		const cinfo = this.m_ControlMap.get('@@all').getControlRefreshInfo(key);
		if (cinfo != null) {
			cinfo.clearValue(viewCore);
		}
		else {
			if (this.m_ControlMap.has(key) === false) {
				if (key.includes(',') === true) {
					key.split(',').forEach(name => {
						if (this.m_ControlMap.has(name) === true)
							this.m_ControlMap.get(name).clearValue(viewCore);
					});
					return;
				}
				else if (this.m_ControlMap.has('@' + key) === true) {
					key = '@' + key;
				}
				else if (this.m_ControlMap.has('#' + key) === true) {
					key = '#' + key;
				}
				else {
					viewCore.addErrorLog(`clearValueの引数に誤りがあります。[${key}]`);
					return;
				}
			}
			this.m_ControlMap.get(key).clearValue(viewCore);
		}
	}

	/**
	 * コントロールの入力チェック
	 * @param viewCore ビュー情報
	 * @param key 
	 * @return trueの場合、入力済みでエラーなし
	 */
	public isInput(viewCore: WprBaseViewCore, key: string): boolean {
		const cinfo = this.m_ControlMap.get('@@all').getControlRefreshInfo(key);
		if (cinfo != null) {
			return cinfo.isInput();
		}
		else {
			if (this.m_ControlMap.has(key) === false) {
				if (key.includes(',') === true) {
					key.split(',').forEach(name => {
						if (this.m_ControlMap.has(name) === true)
							this.m_ControlMap.get(name).isInput();
					});
					return;
				}
				else if (this.m_ControlMap.has('@' + key) === true) {
					key = '@' + key;
				}
				else if (this.m_ControlMap.has('#' + key) === true) {
					key = '#' + key;
				}
				else {
					viewCore.addErrorLog(`isInputの引数に誤りがあります。[${key}]`);
					return false;
				}
			}
			return this.m_ControlMap.get(key).isInput();
		}
	}

	/**
	 * 権限を再設定する
	 * @param viewCore ビュー情報
	 * @param key キー
	 */
	public refreshAuth(viewCore: WprBaseViewCore, key: string): void {
		if (this.m_ControlMap.has('@@all') === false)
			return;
		const cinfo = this.m_ControlMap.get('@@all').getControlRefreshInfo(key);
		if (cinfo != null) {
			cinfo.refreshAuth();
		}
		else {
			if (this.m_ControlMap.has(key) === false) {
				if (key.includes(',') === true) {
					key.split(',').forEach(name => {
						if (this.m_ControlMap.has(name.trim()) === true)
							this.m_ControlMap.get(name.trim()).refreshAuth();
					});
					return;
				}
				else if (this.m_ControlMap.has('@' + key) === true) {
					key = '@' + key;
				}
				else if (this.m_ControlMap.has('#' + key) === true) {
					key = '#' + key;
				}
				else {
					viewCore.addErrorLog(`refreshViewの引数に誤りがあります。[${key}]`);
					return;
				}
			}
			this.m_ControlMap.get(key).refreshAuth();
		}
	}

	/**
	 * 変更フラグ取得
	 * @param viewCore ビュー情報
	 * @param key キー
	 * @return 変更フラグ
	 */
	 public getDirty(viewCore: WprBaseViewCore, key: string): boolean {
		if (this.m_ControlMap.has('@@all') === false)
			return false;
		const cinfo = this.m_ControlMap.get('@@all').getControlRefreshInfo(key);
		if (cinfo != null) {
			return cinfo.controlInfo.dirty;
		}
		else {
			if (this.m_ControlMap.has(key) === false) {
				if (key.includes(',') === true) {
					key.split(',').forEach(name => {
						if (this.m_ControlMap.has(name.trim()) === true)
							this.m_ControlMap.get(name.trim()).refreshAuth();
					});
					return;
				}
				else if (this.m_ControlMap.has('@' + key) === true) {
					key = '@' + key;
				}
				else if (this.m_ControlMap.has('#' + key) === true) {
					key = '#' + key;
				}
				else {
					viewCore.addErrorLog(`refreshViewの引数に誤りがあります。[${key}]`);
					return;
				}
			}
			return this.m_ControlMap.get(key).getDirty();
		}
	}
	// --------------------------------------------------------------------------
}
