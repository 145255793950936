import { WprControlInfo } from '../../../../../wpr-framework/view/control/WprControlInfo';
import { WprListData } from '../../../../../wpr-framework/view/deco/WprListData';
import { WprModel } from '../../../../../wpr-framework/view/deco/WprModel';
import { WprViewMode } from '../../../../../wpr-framework/view/deco/WprViewMode';
import { WprDI_Control } from '../../../../../wpr-framework/view/di/WprDI_Control';
import { WprDI_ScopeModel } from '../../../../../wpr-framework/view/di/WprDI_ScopeModel';
import { ObjectUtil } from '../../../../common/util/ObjectUtil';
import { Customer } from '../../../../models/bss/customer/Customer';
import { ViewInfoModel } from '../../../../service/models/apimodels/ViewInfoModel';
import { MypageBaseView } from '../../../base/MypageBaseView';
import { ProgressBarData } from '../../../common/progress_bar/ProgressBarView';
import { MypageWizardMngr } from '../../../wizard/models/MypageWizardMngr';
import { BaseInfoListModel } from '../mod_net_select/models/BaseInfoListModel';
import { ContractInfo } from '../mod_net_select/models/ContractInfo';
import { OtokuMode } from './mode/OtokuMode';
import { CableMode } from './mode/CableMode';
import { NetServiceNewWizardModel } from '../../../wizard/NetServiceNewWizardFlow';
import { WprValidError } from '../../../../../wpr-framework/view/validation/WprValidError';
import { InputConfirmMode } from './mode/InputConfirmMode';
import { CourseListModel } from '../../../../models/mypage/net/CourseListModel';
import MypageCodeTypeConstants from '../../../../common/MypageCodeTypeConstants';
import { ErrorModel } from '../../../../models/mypage/smart/ErrorModel';
import { GetCampaignRequest } from '../../../../service/models/dto/mypagerenewal/GetCampaignRequest';
import { GetGradeRequest } from '../../../../service/models/dto/mypagerenewal/GetGradeRequest';
import MypageDispdivcdConstants from '../../../../common/MypageDispdivcdConstants';
import { MCommon } from '../../../../service/models/entity/primary/MCommon';

/**
 * サービス変更申込み
 */
export class ModNetSettingView extends MypageBaseView {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() { super('ModNetSettingView'); }
	// --------------------------------------------------------------------------

	// DI情報  ------------------------------------------------------------------
	@WprDI_Control
	private m_yesOtoku: WprControlInfo	= null;					// お得パック希望する
	@WprDI_Control
	private m_noOtoku: WprControlInfo	= null;					// お得パック希望しない
	@WprDI_Control
	private m_cable: WprControlInfo	= null;						// ケーブルモデム
	@WprDI_Control
	private m_cableWifi: WprControlInfo	= null;					// ケーブルモデムwifi
	@WprDI_Control
	private m_noChangeCable: WprControlInfo	= null;				// ケーブルモデム変更なし
	@WprDI_Control
	private m_buyCheck: WprControlInfo	= null;					// 機器のご提供方法
	@WprDI_Control
	private m_equipmentProvidingCheck: WprControlInfo	= null;	// 機器のご提供方法
	@WprDI_Control
	private m_noChange: WprControlInfo	= null;					// 機器のご提供方法
	@WprDI_Control
	private m_desiredDate1: WprControlInfo	= null;				// 第一希望
	@WprDI_Control
	private m_selectTime1: WprControlInfo	= null;				// 第一希望　時間帯
	@WprDI_Control
	private m_desiredDate2: WprControlInfo	= null;				// 第二希望
	@WprDI_Control
	private m_selectTime2: WprControlInfo	= null;				// 第二希望　時間帯
	@WprDI_Control
	private m_desiredDate3: WprControlInfo	= null;				// 第三希望
	@WprDI_Control
	private m_selectTime3: WprControlInfo	= null;				// 第三希望　時間帯
	@WprDI_Control
	private m_beforeContact: WprControlInfo	= null;	// 前日連絡先
	@WprDI_Control
	private m_sameDayContact: WprControlInfo	= null;	// 前日連絡先
	// --------------------------------------------------------------------------

	// モデル  ------------------------------------------------------------------
	@WprDI_ScopeModel('MypageWizardMngr')
	private m_MypageWizardMngr: MypageWizardMngr 			= null;	// 画面ウィザード管理クラス
	@WprModel('NetInfo')
	private m_NetInfo: CourseListModel						= null;	// 画面遷移用
	@WprListData('CourseListModel')
	private m_CourseListModel: CourseListModel[]			= null;	// コースリスト
	@WprModel('ContractInfo')
	private m_ContractInfo: ContractInfo					= null;	// 契約情報
	@WprListData('BaseInfoList')
	private m_BaseInfoList: BaseInfoListModel[]				= null;	// 基本情報リスト
	@WprListData('BeforeContractList')
	private m_BeforeContractList: CourseListModel[]			= null;	// 変更前契約リスト
	@WprListData('ErrorListModel')
	private m_ErrorListModel: ErrorModel[]					= null;	// エラーモデル
	// --------------------------------------------------------------------------

	// メンバ変数  ---------------------------------------------------------------
	private m_Customer:	Customer							= null;	// 顧客情報
	private m_CableName: MCommon                            = null; // ケーブルモデム
	private m_CableWifiName: MCommon                        = null; // ケーブルモデム(Wi-Fi)
	private m_NetServiceNewModel: NetServiceNewWizardModel  = null;	// ネット追加モデル
	// --------------------------------------------------------------------------

	// サブビュー/モード  --------------------------------------------------------
	@WprViewMode
	private m_OtokuMode = new OtokuMode();							// お得パックモード
	@WprViewMode
	private m_CableMode = new CableMode();							// ケーブルモード
	@WprViewMode
	private m_InputConfirmMode = new InputConfirmMode();			// 入力確認モード
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** ウィザード定義 */
	public get mngr(): MypageWizardMngr 					{ return this.m_MypageWizardMngr; }
	// --------------------------------------------------------------------------
	
	// override メソッド  --------------------------------------------------------
	/**
	 * コントロール定義設定
	 */
	public onRegisterConfig(): void {
		this.addConfig('cDispnamep', 'コース', 'NetInfo');
		this.addConfig('noOtoku', 'お得パック希望しない', 'NetInfo');
		this.addConfig('yesOtoku', 'お得パック希望', 'NetInfo');
		this.addConfig('equipmentProvidingCheck', '機器のご提供方法', 'NetInfo');
		this.addConfig('buyCheck', '機器のご提供方法', 'NetInfo');
		this.addConfig('noChange', '機器のご提供方法', 'NetInfo');
		this.addConfig('cable', 'ケーブルモデム', 'NetInfo');
		this.addConfig('cableWifi', 'ケーブルモデムwifi', 'NetInfo');
		this.addConfig('noChangeCable', 'ケーブルモデム変更なし', 'NetInfo');
		this.addConfig('deviceType', '機器の種類', 'NetInfo');
		this.addConfig('deviceForm', '機器の形態', 'NetInfo');
		this.addConfig('contractCourse', '契約コース名', 'ContractInfo');
		this.addListConfig('itemName', '項目名', 'BeforeContractList');
		this.addListConfig('itemData', '項目データ', 'BeforeContractList');
		this.addConfig('desiredDate1', '第一希望', 'NetInfo', { emptyValue: null, max: this.m_MypageWizardMngr.getMaxReqDate(), min: this.m_MypageWizardMngr.getMinReqDate() });
		this.addConfig('selectTime1', '第一希望　時間帯', 'NetInfo', { emptyValue: null});
		this.addConfig('desiredDate2', '第二希望', 'NetInfo', { emptyValue: null, max: this.m_MypageWizardMngr.getMaxReqDate(), min: this.m_MypageWizardMngr.getMinReqDate() });
		this.addConfig('selectTime2', '第二希望　時間帯', 'NetInfo', { emptyValue: null });
		this.addConfig('desiredDate3', '第三希望', 'NetInfo', { emptyValue: null, max: this.m_MypageWizardMngr.getMaxReqDate(), min: this.m_MypageWizardMngr.getMinReqDate() });
		this.addConfig('selectTime3', '第三希望　時間帯', 'NetInfo', { emptyValue: null});
		this.addConfig('campaignCode', '入力キャンペーンコード', 'NetInfo');
		this.addListConfig('errorMessage', 'エラーメッセージ', 'ErrorListModel');
		this.addConfig('securityFlg', 'セキュリティフラグ', 'NetInfo');
		this.addConfig('securityComment', 'セキュリティフリー入力', 'NetInfo');
		this.addConfig('beforeContact', '前日連絡先', 'NetInfo', { maxLength: 11, validation: '整数' });
		this.addConfig('sameDayContact', '当日連絡先', 'NetInfo', { maxLength: 11, validation: '整数' });
		this.addConfig('name', '当日立ち合い者', 'NetInfo');
	}
	// --------------------------------------------------------------------------
	/**
	 * アクション登録
	 */
	public onRegisterAction(): void {
		this.addAction('next', this.onNext, true);
		this.addAction('back', this.onBack);
	}

	/**
	 * 値変更通知
	 * @param name 名前
	 * @param value 値
	 * @returns falseの場合、変更を取り消す
	 */
	public onChangeValue(name: string, value: any): boolean {
		this.refreshModel();
		// お得パック
		if (name === 'yesOtoku') 
			this.m_noOtoku.value = false;
		else if (name === 'noOtoku') 
			this.m_yesOtoku.value = false;
		// 端末の種類
		else if (name === 'cable') {
			this.m_cableWifi.value = false;
			this.m_noChangeCable.value = false;
		}
		else if (name === 'cableWifi') {
			this.m_cable.value = false;
			this.m_noChangeCable.value = false;
			// 変更前[レンタル]以外の場合、[変更なし]は選択不可
			if (this.m_NetInfo.deviceForm !== 'レンタル') {
				this.m_equipmentProvidingCheck.value = true;
				this.m_noChange.value = false;
			}
		}
		else if (name === 'noChangeCable') {
			this.m_cable.value = false;
			this.m_cableWifi.value = false;

		}
		// 機器のご提供方法
		else if (name === 'equipmentProvidingCheck') {
			this.m_buyCheck.value = false;
			this.m_noChange.value = false;
		}
		else if (name === 'buyCheck') {
			this.m_equipmentProvidingCheck.value = false;
			this.m_noChange.value = false;
		}
		else if (name === 'noChange') {
			this.m_equipmentProvidingCheck.value = false;
			this.m_buyCheck.value = false;
		}
		// セキュリティ
		else if (name === 'securityFlg') {
			if (value)
				this.setViewMode('セキュリティ会社', '表示');
			else
				this.setViewMode('セキュリティ会社', '非表示');
		}
		this.updateMode();
		return true;
	}

	/**
	 * 入力チェック（独自のValidationを実施する場合に使用する）
	 *	エラー追加例：validError.addError('エラーメッセージ');				// 相関チェックなどのエラー
	 * 				 this.setControlError('control', 'error');			 // コントロールのエラー
	 * 				 validError.showErrorMessage.('エラーメッセージ');	   // エラーメッセージ表示
	 * 		※上記のエラーを追加した場合は、アクションは実行しません。
	 * 		※コントロールの値をチェックする場合は、refreshModelは使用しないでください。
	 * 		　コントロールの値をチェックする場合は、コントロールをDIしてvalueを使用してください。
	 * 		　コントロールが双方向のコンバータを使用している場合は、dataValueを使用してください。
	 * @param actionName アクション名
	 * @param validError バリデーションエラー情報
	 * @param row 行情報
	 */
	protected onValid(actionName: string, validError: WprValidError, row?: any): void {
		let emptyError: string = null;
		let dupError: string = null;
		let minError1: string = null;
		let minError2: string = null;
		let minError3: string = null;
		let numError1: string = null;
		let numError2: string = null;
		let campaignError: string = null;
		if (actionName == 'next' && this.getViewMode('希望日時') === '表示') {
			const hisssu1: boolean = !this.m_MypageWizardMngr.isNotEmpty(this.m_desiredDate1.value, this.m_selectTime1.value);
			const hisssu2: boolean = !this.m_MypageWizardMngr.isNotEmpty(this.m_desiredDate2.value, this.m_selectTime2.value);
			const hisssu3: boolean = !this.m_MypageWizardMngr.isNotEmpty(this.m_desiredDate2.value, this.m_selectTime3.value);
			this.m_ErrorListModel = new Array();
			if (hisssu1 || hisssu2 || hisssu3) {
				const itemStr: string = this.m_MypageWizardMngr.setErrorReqDateItem([{ flag: hisssu1, dispVal: '第一希望' }, { flag: hisssu2, dispVal: '第二希望' }, { flag: hisssu3, dispVal: '第三希望' },]);
				emptyError = itemStr + '：日付、時間帯のいずれかが入力されていません。再度入力してください。';
			}
			else {
				const selectTime1: string = this.m_desiredDate1.value + this.m_selectTime1.value;
				const selectTime2: string = this.m_desiredDate2.value + this.m_selectTime2.value;
				const selectTime3: string = this.m_desiredDate3.value + this.m_selectTime3.value;
				let reqTimeErrorFlg1: boolean = false;
				let reqTimeErrorFlg2: boolean = false;
				let reqTimeErrorFlg3: boolean = false;
				if (ObjectUtil.isNotNullOrUndefined(this.m_desiredDate1.value) && ObjectUtil.isNotNullOrUndefined(this.m_selectTime1.value) && selectTime1 === selectTime2) reqTimeErrorFlg1 = true;
				if (ObjectUtil.isNotNullOrUndefined(this.m_desiredDate2.value) && ObjectUtil.isNotNullOrUndefined(this.m_selectTime2.value) && selectTime1 === selectTime3) reqTimeErrorFlg2 = true;
				if (ObjectUtil.isNotNullOrUndefined(this.m_desiredDate1.value) && ObjectUtil.isNotNullOrUndefined(this.m_selectTime3.value) && selectTime2 === selectTime3) reqTimeErrorFlg3 = true;
				if (reqTimeErrorFlg1 || reqTimeErrorFlg2 || reqTimeErrorFlg3) {
					let itemStr: string;
					if (reqTimeErrorFlg1 === true && reqTimeErrorFlg3 == true)
						itemStr = '第一希望と第二希望と第三希望';
					else
						itemStr = this.m_MypageWizardMngr.setErrorReqDateItem([{ flag: reqTimeErrorFlg1, dispVal: '第一希望と第二希望' }, { flag: reqTimeErrorFlg2, dispVal: '第一希望と第三希望' }, { flag: reqTimeErrorFlg3, dispVal: '第二希望と第三希望' },]);
					dupError = itemStr + '：同じ希望日時が入力されています。別の日時を指定してください。';
				}
			}
			if (this.m_desiredDate1.value < this.m_MypageWizardMngr.getMinReqDate()) {
				const date = this.m_MypageWizardMngr.convertToDate(this.m_MypageWizardMngr.getMinReqDate());
				minError1 = '第一希望に' + date + '以降を入力してください';
			}
			if (this.m_desiredDate2.value < this.m_MypageWizardMngr.getMinReqDate() ) {
				const date = this.m_MypageWizardMngr.convertToDate(this.m_MypageWizardMngr.getMinReqDate());
				minError2 = '第二希望に' + date + '以降を入力してください';
			}
			if (this.m_desiredDate3.value < this.m_MypageWizardMngr.getMinReqDate()) {
				const date = this.m_MypageWizardMngr.convertToDate(this.m_MypageWizardMngr.getMinReqDate());
				minError3 = '第三希望に' + date + '以降を入力してください';
			}
			// 前日連絡先・当日連絡先
			
			if (!this.m_beforeContact.value.match(/^[0-9]+$/)) {
				numError1 = '前日連絡先は半角数字で入力してください。';
			}
			if (!this.m_sameDayContact.value.match(/^[0-9]+$/)) {
				numError2= '当日連絡先は半角数字で入力してください。';
			}
		}
		else
			this.m_NetInfo.campaignList = null;
		// エラーがある場合
		if (emptyError || dupError || minError1 || minError2 || minError3 || numError1 || numError2 || campaignError) {
			const errorlist: string[] = [minError1, minError2, minError3, emptyError, dupError, numError1, numError2, campaignError];
			for (const error of errorlist) {
				if (error) {
					const model = new ErrorModel();
					model.errorMessage = error;
					this.m_ErrorListModel.push(model);
				}
			}
			validError.addError('Error')
			this.setViewMode('エラー','表示')
			this.setListData('ErrorListModel', this.m_ErrorListModel);
		}
	}

	/**
	 * バリデーションエラーメッセージ通知
	 * @param name コントロール名
	 * @param rowIndex 行数(Listの場合)
	 * @param validatorName バリデーション名
	 * @param msg エラーメッセージ
	 * @returns エラーメッセージ（変更する場合に指定する、メッセージを使用しない場合は、nullを返す）
	 */
	public onInvalidMessage(name: string, rowIndex: number, validatorName: string, msg: string): string {
		if (((name === 'desiredDate1' || name === 'desiredDate2' || name === 'desiredDate3') && validatorName === '最小値')
			|| ((name === 'beforeContact' || name === 'sameDayContact') && (validatorName === '整数')))
			msg = null;
		return msg;
	}

	/**
	 * ビュー表示通知
	*/
	public onShowView(): void {
		this.m_MypageWizardMngr.initView(this, (result: ViewInfoModel) => {
			this.registAccessLog();
			this.setStorageItem('b_url', window.location.href);
			const sendData: ProgressBarData = new ProgressBarData();
			sendData.progressBarList = this.m_MypageWizardMngr.getProgressModelList(this);
			this.sendChildData('fP_ev_progressBarIntital', sendData);
			// 申し込み情報のクリア
			this.m_MypageWizardMngr.getCache().m_ConfirmListModel = new Array();
			this.m_NetServiceNewModel = new NetServiceNewWizardModel();
			this.m_ContractInfo = this.m_MypageWizardMngr.getCache().m_ContractInfo;
			this.m_ContractInfo.dupFlg = false;
			this.m_NetInfo = this.m_MypageWizardMngr.getCache().m_NetInfo;
			this.m_BaseInfoList = this.m_MypageWizardMngr.getCache().m_BaseInfoList;
			this.m_Customer = this.m_MypageWizardMngr.getViewInfo().customer;
			// 申込みコース
			const courseList = this.m_MypageWizardMngr.getCache().m_CourseListModel.filter(data => data.check == true);
			if (courseList && courseList.length > 0) {
				this.m_NetInfo.cDispnamep = courseList[0].cDispnamep;
				this.m_NetInfo.payAmount = courseList[0].payAmount;
				this.m_NetInfo.aftServiceCd = courseList[0].serviceCd;			
				this.m_NetInfo.tanakoServiceCd = courseList[0].tanakoServiceCd;	// 店子コースの場合
			}
			// 機器の種類、レンタル/購入
			const deviceInfo = this.m_BaseInfoList.filter(item => item.itemName === '機器の種類');
			this.m_CableName = this.m_MypageWizardMngr.getMCommonList(this,'cable_modem_wired', null, null, null)[0];
			this.m_CableWifiName = this.m_MypageWizardMngr.getMCommonList(this,'cable_modem_wifi', null, null, null)[0];
			if (deviceInfo && deviceInfo.length > 0) {
				if (deviceInfo[0].itemData !== '') {
					this.m_NetInfo.deviceType = deviceInfo[0].terminalType;
					this.m_NetInfo.deviceForm = deviceInfo[0].terminalForm;
					// 変更前、端末タイプ
					if (this.m_NetInfo.deviceType === this.m_CableWifiName.commonValue)
						this.m_NetInfo.befCableWifiFlg = true;
					else if (this.m_NetInfo.deviceType === this.m_CableName.commonValue)
						this.m_NetInfo.befCableFlg = true;
				}
			}
			// 変更前契約リスト
			this.m_BeforeContractList = this.m_MypageWizardMngr.getCache().m_BeforeContractList;
			if (this.m_BeforeContractList.length === 0) {
				// コース(変更前)
				const befCourse = new CourseListModel();
				befCourse.itemName = '申込みコース';
				befCourse.itemData = this.m_ContractInfo.contractCourse;
				this.m_BeforeContractList.push(befCourse);
				// 機器の種類(変更前)
				if (deviceInfo && deviceInfo.length > 0) {
					if (deviceInfo[0].itemData !== '') {
						const befDevType = new CourseListModel();
						befDevType.itemName = '機器の種類';
						befDevType.itemData = deviceInfo[0].terminalType;
						this.m_BeforeContractList.push(befDevType);
						const befDevForm = new CourseListModel();
						befDevForm.itemName = 'レンタル/購入'
						befDevForm.itemData = deviceInfo[0].terminalForm;
						this.m_BeforeContractList.push(befDevForm);
					}
				}
			}
			this.setListData('BeforeContractList', this.m_BeforeContractList);
			// 表示判定
			if (this.m_Customer.building[0].buildingLineTypeCD === '2') {
				this.setViewMode('インターネットのコース変更', 'FTTH');
				this.setViewMode('コース一覧', 'FTTH');
				const apartCourseList = this.m_MypageWizardMngr.getMCommonList(this,'srvnet', 'ftth_wire_sv','wire_disp_sv',null); 
				// 端末の種類
				for (const apart of apartCourseList) {
					if (apart.commonValue === this.m_NetInfo.aftServiceCd) {
						this.setViewMode('端末の種類','全種');
						// デフォルトチェック
						if (!this.m_NetInfo.cable && !this.m_NetInfo.cableWifi && !this.m_NetInfo.noChangeCable)
							this.m_NetInfo.noChangeCable = true;
						this.setViewMode('補足※1','FTTHマンションコース');
						break;
					}
				}
				// 戸建の場合
				if (this.m_Customer.building[0].buildingTypeCD === '3') {
					this.setViewMode('機器のご提供方法','変更なしのみ');
					this.m_NetInfo.noChange = true;
				}
				else {
					this.setViewMode('機器のご提供方法','レンタル、変更なし');
					// デフォルトチェック
					if (!this.m_NetInfo.equipmentProvidingCheck && !this.m_NetInfo.noChange)
						this.m_NetInfo.noChange = true;
				}
			}
			else {
				this.setViewMode('インターネットのコース変更', 'FTTH以外');
				this.setViewMode('コース一覧', 'FTTH以外');
				this.setViewMode('補足※1','FTTH以外の場合');
				this.setViewMode('補足※2','FTTH以外の場合');
				// 変更前が機器なしの場合
				const deviceType = this.m_BaseInfoList.filter(data => data.itemName === '機器の種類');
				if (deviceType && deviceType.length > 0 && ObjectUtil.isNullOrUndefined(deviceType[0].terminalType)) {
					this.setViewMode('端末の種類','変更なし非表示');
					this.setViewMode('機器のご提供方法', 'レンタルのみ')
					this.m_NetInfo.equipmentProvidingCheck = true;
					if (!this.m_NetInfo.cable && !this.m_NetInfo.cableWifi)
						this.m_NetInfo.cable = true;
				}
				else {
					this.setViewMode('端末の種類','全種');
					// デフォルトチェック
					if (!this.m_NetInfo.cable && !this.m_NetInfo.cableWifi && !this.m_NetInfo.noChangeCable)
						this.m_NetInfo.noChangeCable = true;
					this.setViewMode('機器のご提供方法','レンタル、変更なし');
					// デフォルトチェック
					if (!this.m_NetInfo.equipmentProvidingCheck && !this.m_NetInfo.buyCheck && !this.m_NetInfo.noChange)
						this.m_NetInfo.noChange = true;
				}
			}
			if (this.m_Customer.building[0].buildingTypeCD === '3')
				this.setViewMode('階数','表示');
			if (this.m_NetInfo.securityFlg)
				this.setViewMode('セキュリティ会社', '表示');
			this.setOtoku();
			this.refreshView();
		});
	}
	// アクション  ---------------------------------------------------------------
	/**
	 * 次へ
	 */
	public onNext(param: any): void {
		this.refreshModel();
		this.clearDate();
		const req: GetGradeRequest = new GetGradeRequest();
		req.befServiceCd = this.m_ContractInfo.serviceCd;
		if (ObjectUtil.isNotNullOrUndefined(this.m_NetInfo.tanakoServiceCd))
			req.aftServiceCd = this.m_NetInfo.tanakoServiceCd;
		else
			req.aftServiceCd = this.m_NetInfo.aftServiceCd;
		// 端末コード
		const device10000 = this.m_MypageWizardMngr.getMCommonList(this, 'Add_net', 'equ', 'rep', null);
		if (this.m_ContractInfo.equipmentTypeCd !== device10000[0].commonValue)
			req.befEquipmentCd = this.m_ContractInfo.equipmentTypeCd;
		if (this.m_cable.value)
			req.aftEquipmentName = 'ケーブルモデム';
		else if (this.m_cableWifi.value)
			req.aftEquipmentName = 'ケーブルモデム(Wi-Fi)';
		else if (this.m_noChangeCable.value)
			req.aftEquipmentName = this.m_NetInfo.deviceType;
		// グレードの取得
		this.m_CommonService.getGradeInfo(this, req, (result) => {
			if (result) {
				this.m_NetInfo.grade = result;
				//キャンペーンコードが入力されているとき
				if (this.m_NetInfo.campaignCode) {
					const req: GetCampaignRequest = new GetCampaignRequest();
					req.campaignGroupCd = this.m_NetInfo.campaignCode;
					this.m_CommonService.getCampaign(this, req, (result) => {
						if (result.mcampaignList && result.mcampaignList.length > 0) {
							this.m_NetInfo.campaignGroup = result.mCampaignGroup;
							this.m_NetInfo.campaignList = result.mcampaignList;
							this.next();
						}
						else {
							if (ObjectUtil.isNullOrUndefined(this.m_ErrorListModel))
								this.m_ErrorListModel = new Array();
							const model = new ErrorModel();
							model.errorMessage = 'キャンペーンコード：キャンペーンコードに誤りがあります。再度入力してください。';
							this.m_ErrorListModel.push(model);
						}
						if (this.m_ErrorListModel.length !== 0) {
							this.setViewMode('エラー','表示')
							this.setListData('ErrorListModel', this.m_ErrorListModel);
							window.scroll({top: 0, behavior: 'smooth'});	
						}
					});
				}
				else {
					this.m_NetInfo.campaignList = null;
					this.next();
				}
			}
		});
	}

	/**
	 * 戻る
	 */
	public onBack(param: any): void {
		this.m_MypageWizardMngr.getCache().m_NetInfo = new CourseListModel();
		this.m_MypageWizardMngr.goBack(this);
	}
	// --------------------------------------------------------------------------

	// privateメソッド  ----------------------------------------------------------
	/**
	 * お得パック申込み判定
	 */
	private setOtoku(): void {
		const planList = this.m_MypageWizardMngr.getViewInfo().contractModel.planList;
		const customerPayment =this.m_MypageWizardMngr.getViewInfo().customerPayment;
		if (customerPayment && this.m_MypageWizardMngr.showOtokuPlan(this, customerPayment[0].payment[0], planList)) {
			this.setViewMode('お得パック', '申込み');
			// デフォルトチェック
			if (!this.m_NetInfo.yesOtoku && !this.m_NetInfo.noOtoku) 
				this.m_NetInfo.noOtoku = true;
		}
		else {
			// お得パック加入者
			const otokuPlanName = this.m_MypageWizardMngr.getOtokuPlanName(this, planList);
			if (ObjectUtil.isNotNullOrUndefined(otokuPlanName)) {
				this.m_NetInfo.otokuFlg = true;
				this.m_NetInfo.otokuPlan = '※【' + otokuPlanName.dispnamep + '】割引適用)';	// 名称
			}
		}
	}

	/**
	 * 申込み判定
	 */
	private application() {
		if (ObjectUtil.isNotNullOrUndefined(this.m_NetInfo.tanakoServiceCd)) {
			if (this.m_NetInfo.tanakoServiceCd === this.m_ContractInfo.serviceCd) 
				this.m_ContractInfo.dupFlg = true;
		}
		else if (this.m_NetInfo.aftServiceCd === this.m_ContractInfo.serviceCd)
			this.m_ContractInfo.dupFlg = true;
	}

	/**
	 * 希望日時クリア
	 */
	private clearDate() {
		let flg = false;
		// 機器ありコース→機器ありコース
		if (this.getViewMode('端末の種類') === '全種' || this.getViewMode('端末の種類') === '変更なし非表示') {
			// 変更前[ケーブルモデム]　かつ　変更後[ケーブルモデムWi-Fi]
			if (this.m_NetInfo.befCableFlg && this.m_cableWifi.value)
				flg = true;
			// 変更前[ケーブルモデムWi-Fi]　かつ　変更後[ケーブルモデム]
			if (this.m_NetInfo.befCableWifiFlg && this.m_cable.value)
				flg = true;
			// 変更前がモデム以外
			if (!this.m_NetInfo.befCableFlg && !this.m_NetInfo.befCableWifiFlg && (this.m_cable.value || this.m_cableWifi.value))
				flg = true;
			const deviceFormList = this.m_MypageWizardMngr.getMDisplayList(this, MypageDispdivcdConstants.ITSCOMHOME_TERMINALSTATUS, '');
			// 変更前[レンタル]以外　かつ　変更後[レンタル]
			if (this.m_NetInfo.deviceForm !== deviceFormList.filter(data => data.namecd === '1')[0].dispnamep
			 && this.m_equipmentProvidingCheck.value) {
				flg = true;
			 }
		}
		// FTTHコースの場合、機器の提供方法が変更になる場合
		else if (ObjectUtil.isNullOrUndefined(this.getViewMode('端末の種類'))) {
			if (this.m_NetInfo.deviceType !== 'レンタル' && this.m_equipmentProvidingCheck.value)
				flg = true;
		}
		// 判定結果
		this.m_NetInfo.constractionFlg = flg;
		if (!flg) {
			this.m_NetInfo.desiredDate1 = null;
			this.m_NetInfo.desiredDate2 = null;
			this.m_NetInfo.desiredDate3 = null;
			this.m_NetInfo.selectTime1 = null;
			this.m_NetInfo.selectTime2 = null;
			this.m_NetInfo.selectTime3 = null;
			this.m_NetInfo.beforeContact = null;
			this.m_NetInfo.sameDayContact = null;
			this.m_NetInfo.name = null;
			this.m_NetInfo.securityFlg = false;
			this.m_NetInfo.securityComment = null;
		}
	}

	/**
	 * 次へ処理
	 */
	private next() {
		this.application();
		if (this.m_ContractInfo.dupFlg) {
			this.m_MypageWizardMngr.getCache().m_NetInfo = new CourseListModel();
			this.m_MypageWizardMngr.goBack(this);
		}
		else {
			// this.refreshModel();
			// this.clearDate();
			this.m_MypageWizardMngr.goNext(this);
		}
	}
}
