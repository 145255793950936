/**
 * 機器モデル
 */
export class EquipmentModel {
	// public 変数	-----------------------------------------------------------
	public bdHitPot: boolean;		// BD-HitPot
	public hitPot: boolean;			// HitPot
	public stb: boolean;			// STB
	public rental: boolean;			// レンタル
	// ------------------------------------------------------------------------
}
