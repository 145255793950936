import { WprValueMap } from '../../../../wpr-framework/model/WprValueMap';
import { WprValueMapSetter } from '../../../../wpr-framework/model/WprValueMapSetter';

/**
 * 階数
 */
export class FloorValueMap {
	// static メソッド  --------------------------------------------------------
	public static addValueMap(vmSetter: WprValueMapSetter): void {
		let vm = new WprValueMap();
		vm.addValue('地下1F', '地下1F');
		vm.addValue('1F', '1F');
		vm.addValue('2F', '2F');
		vm.addValue('3F', '3F');
		vmSetter.addValueMap('floor', vm);
	}
	// --------------------------------------------------------------------------
}
