import { Card, Divider, TableCell, Typography } from '@material-ui/core';
import { Grid } from '@mui/material';
import React, { ReactNode } from 'react';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { MatButton } from '../../../../wpr-framework/component/controls/material/MatButton';
import { WprBaseViewComponent } from '../../../../wpr-framework/view/component/WprBaseViewComponent';
import { MvnoPlanSendData } from '../../../controls/checkMarkRadio/CheckMarkRadioContent';
import { MypageErrorView } from './MypageErrorView';
import { MatOneRowTable } from '../../../../wpr-framework/component/controls/material/MatOneRowTable';
import { WprContainer } from '../../../../wpr-framework/component/controls/WprContainer';
import { MatTypography } from '../../../../wpr-framework/component/controls/material/MatTypography';
import { WprErrorContainer } from '../../../../wpr-framework/component/controls/WprErrorContainer';
import { Header } from '../header/Header';


/**
 * エラー画面用コンポーネント
 */
export class MypageError extends WprBaseViewComponent {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: any) { super(new MypageErrorView(), props); }
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** エラー画面ビュー情報 */
	public get viewInfo(): MypageErrorView { return this.view as MypageErrorView; }
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	// /**
	//  * スタイルオブジェクト取得
	// */
	// public getStylesObject(): any { return styles; }

	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		return (
			<>
				{/* Milosから追加 START */}


				{/* Milosから追加 END */}
				{/* <Header /> */}
				<div>
					<div className='pageCard'>
						<div className="pageHeader title">
							エラー
						</div>
						<Divider />
						<div className='pageContent'>
							<div className='planContent'>
								<div className='completeMessage'>
									<div dangerouslySetInnerHTML={{ __html: this.state.data.errorMessage}} />
									{/* <div>
										<MatTypography name='errorMessage' view={this.view} />
									</div> */}
								</div>
							</div>
						</div>
							<Divider />
						<div className='pageFooter'>
							<div>
								<MatButton name='toMypageTop' view={this.view} className='backButton' style={{ width: '250px' }} >
									<NavigateBeforeIcon />
									マイページトップへ戻る
								</MatButton>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
	// --------------------------------------------------------------------------
}
