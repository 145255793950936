import React, { ReactNode } from 'react';
import { WprBaseViewComponent } from '../../../../../wpr-framework/view/component/WprBaseViewComponent';
import { Typography, Grid, Divider } from '@material-ui/core';
import { MatButton } from '../../../../../wpr-framework/component/controls/material/MatButton';
import { ProgressBar } from '../../../common/progress_bar/ProgressBar';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { ModIpopConfirmView } from './ModIpopConfirmView';
import { WprText } from '../../../../../wpr-framework/component/controls/WprText';
import { WprContainer } from '../../../../../wpr-framework/component/controls/WprContainer';
import netIcon from '/shared/image/common/logo_iTSCOMNET.png';
import importantIcon from '/shared/image/common/important_purple.png';
import styles from './ModIpopConfirm.scss';
/**
 * IPアドレス種別（内容確認）用コンポーネント
 */
export class ModIpopConfirm extends WprBaseViewComponent {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: any) { super(new ModIpopConfirmView(), props); }
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** IPアドレス種別（内容確認）ビュー情報 */
	public get viewInfo(): ModIpopConfirmView	{ return this.view as ModIpopConfirmView;	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * スタイルオブジェクト取得
	*/
	public getStylesObject(): any { return styles; }

	
	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		return (
			<>
				<div>
					<div className='pageCard' >
						<div className="pageHeader stepFlow">
							<ProgressBar name='progressBar' view={this.view}/>
						</div>
							<WprContainer  mode='グローバル' view={this.view}>
								<div className='main' style={{paddingTop:'0px'}}>
									<div className='pageTitle'>
										<div style={{textAlign:'right',height:'52px'}}>
											<img src={netIcon} style={{marginTop:'17.92px'}}></img>
										</div>
										<div className='title_left'>
											<Typography className='title_text'>iTSCOM NET IPアドレス種別選択オプション 変更確認</Typography>
											<Typography className='title_comment'>以下の内容でよろしければ、「申込む」ボタンをクリックしてください。</Typography>
										</div>
									</div>
									<div className='feeTable' style={{marginBottom:'0px'}}>
										<Grid container className='feeRow'>
											<Grid item xs={12} md={4} className='feeTitle'>
												<div>IPアドレス種別</div>
											</Grid>
											<Grid item xs={12} md={7} className='feeVal' style={{ color: 'black'}}>
												<span className='itemUnit' style={{ color: '#484848'}}>グローバルＩＰ</span>
											</Grid>
										</Grid>	
									</div>
									<div className='supportMsgArea' >
										<span style = {{color:'#6169B8', fontWeight:'700'}}>
											<img style={{ marginRight: '5px',verticalAlign: 'bottom',marginBottom: '3px'}} src={importantIcon}></img>
											グローバルIP、プライベートIP変更のタイミングについて
										</span>
										<br/>
										<div style={{margin:'5px'}}>
											お申込みいただいた翌日に変更いたします。ただし、17:30以降のお申込みは翌々日の変更となります。
										</div>	
									</div>
								</div>
							</WprContainer>
							<WprContainer mode='プライベート' view={this.view}>
								<div className='main' style={{paddingTop:'0px'}}>
									<div className='pageTitle'>
										<div style={{textAlign:'right',height:'52px'}}>
											<img src={netIcon} style={{marginTop:'17.92px'}}></img>
										</div>
										<div className='title_left'>
											<Typography className='title_text'>iTSCOM NET IPアドレス種別選択オプション 変更確認</Typography>
											<Typography className='title_comment'>以下の内容でよろしければ、「申込む」ボタンをクリックしてください。</Typography>
										</div>
									</div>
									<div className='feeTable' style={{marginBottom:'0px'}}>
										<Grid container className='feeRow'>
											<Grid item xs={12} md={4} className='feeTitle'>
												<div>IPアドレス種別</div>
											</Grid>
											<Grid item xs={12} md={7} className='feeVal' style={{ color: 'black'}}>
												<span className='itemUnit' style={{ color: '#484848'}}>プライベートＩＰ</span>
											</Grid>
										</Grid>	
									</div>
									<div className='supportMsgArea' >
										<span style = {{color:'#6169B8', fontWeight:'700'}}>
											<img style={{ marginRight: '5px',verticalAlign: 'bottom',marginBottom: '3px'}} src={importantIcon}></img>
											グローバルIP、プライベートIP変更のタイミングについて
										</span>
										<br/>	
										<div style={{margin:'5px'}}>
											お申込みいただいた当日に変更いたします。ただし、17:30以降のお申込みは翌日の変更となります。
										</div>
									</div>
								</div>
							</WprContainer>
						<Divider />
						<div className='pageFooter footer-space'>
							<div>
								<MatButton name='next' view={this.view} className='orangeButton orangeButton_parts' >
									この内容で申し込む
									<NavigateNextIcon />
								</MatButton>
							</div>
							<div>
								<MatButton name='back' view={this.view} className='backButton' >
									<NavigateBeforeIcon />
									戻る
								</MatButton>

							</div>
						</div>

					</div>
				</div>
			</>
		);
	}
	// --------------------------------------------------------------------------
}
