/**
 * HDocComfirm generated by hbm2java
 */
export class Application {
	// public 変数	-----------------------------------------------------------
	public customerID: string;				// お客様番号
	public appID: string;	// 申込書ID
	public appDate: string;		// 申込日
	public printDate: string;					// 書面発行日
	public nonHomeFlag: string;					// NON宅フラグ
	// ------------------------------------------------------------------------
}
