import { NameChangePayment } from "../../apimodels/NameChangePayment";

/**
 * 名義変更更新リクエスト
 */
 export class UpdateNameChangePaymentRequest {
	// public 変数	-----------------------------------------------------------
	public nameChangeId: number;					// 名義変更ID
	public customerId: string;						// お客様番号
	public newMail: string;							// 新名義人 メールアドレス
	public nameChangePayment: NameChangePayment;	// 支払情報
	// ------------------------------------------------------------------------
}