import { WprControlInfo } from "../../../../wpr-framework/view/control/WprControlInfo";
import { WprModel } from "../../../../wpr-framework/view/deco/WprModel";
import { WprDI_Control } from "../../../../wpr-framework/view/di/WprDI_Control";
import { WprDI_ScopeModel } from "../../../../wpr-framework/view/di/WprDI_ScopeModel";
import MypageDispdivcdConstants from "../../../common/MypageDispdivcdConstants";
import { Nhk } from "../../../models/bss/nhk/Nhk";
import { NhkDiscountService } from "../../../service/NhkDiscountService";
import { GetNhkRequest } from "../../../service/models/dto/mypagerenewal/GetNhkRequest";
import { MDisplay } from "../../../service/models/entity/secondary/MDisplay";
import { MypageBaseView } from "../../base/MypageBaseView";
import { ProgressBarData } from "../../common/progress_bar/ProgressBarView";
import { MypageWizardMngr } from "../../wizard/models/MypageWizardMngr";


/**
 * 解約申込内容の確認
 */
export class ModNhkConfirmView extends MypageBaseView {
	private m_MDisplayList: MDisplay[];
	// コンストラクタ  -----------------------------------------------------------
	public constructor() { super('ModNhkConfirmView'); }
	// --------------------------------------------------------------------------

	// DI情報  ------------------------------------------------------------------
	@WprDI_Control
	private m_discountSelect: WprControlInfo	= null;	// 割引種類
	// --------------------------------------------------------------------------

	
	
	// モデル  ------------------------------------------------------------------
	@WprDI_ScopeModel('MypageWizardMngr')
	private m_MypageWizardMngr: MypageWizardMngr			= null;		// 画面ウィザード管理クラス
	@WprModel('discountInfo')
	private m_discountInfo: Nhk	= null;	// nhkモデル
	// --------------------------------------------------------------------------

	// サービス  ----------------------------------------------------------------
	private m_NhkDiscountService: NhkDiscountService	= new NhkDiscountService();	// 動画配信サービス
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * コントロール定義設定
	 */
	public onRegisterConfig(): void {
		this.addConfig('discountSelect', '割引種類', 'discountInfo');
	}

	/**
	 * アクション登録
	 */
	public onRegisterAction(): void {
		this.addAction('next', this.onNext);
		this.addAction('back', this.onBack);
	}

	/**
	 * ビュー表示通知
	 */
	public onShowView(): void {
		this.m_MypageWizardMngr.initView(this, () => {
			this.registAccessLog();
			this.setStorageItem('b_url', window.location.href)
			const sendData: ProgressBarData = new ProgressBarData();
			sendData.progressBarList = this.m_MypageWizardMngr.getProgressModelList(this);
			this.sendChildData('fP_ev_progressBarIntital', sendData);
			
			this.m_MDisplayList = [] 
			this.m_MDisplayList.push(this.m_MypageWizardMngr.getMDisplay(this,MypageDispdivcdConstants.NHK_DISCOUNTTYPE,'1'));
			this.m_MDisplayList.push(this.m_MypageWizardMngr.getMDisplay(this,MypageDispdivcdConstants.NHK_DISCOUNTTYPE,'2'));
			this.m_MDisplayList.push(this.m_MypageWizardMngr.getMDisplay(this,MypageDispdivcdConstants.NHK_DISCOUNTTYPE,'3'));
		
			this.getDiscountValue();
			
			
			this.refreshView();
		});
	}

	// --------------------------------------------------------------------------

	// アクション  ---------------------------------------------------------------
	/**
	 * 申込む         <NavigateNextIcon />
	 */
	public onNext(param: any): void {
		this.m_MypageWizardMngr.goNext(this);
	}

	/**
	 * <NavigateBeforeIcon />         戻る
	 */
	public onBack(param: any): void {
		this.m_MypageWizardMngr.goBack(this);
	}
	// --------------------------------------------------------------------------
	
	public getDiscountValue(): void{
		const req: GetNhkRequest = new GetNhkRequest();
		req.customerId = this.getCustomerId()
		this.m_NhkDiscountService.getNhk(this, req, (result) => {
			let typeCd :string;
			typeCd = result.nhkList.paymentTypeCd;
			for(let i=0; i<this.m_MDisplayList.length; i++){
				if(typeCd === this.m_MDisplayList[i].namecd){
					this.m_discountSelect.value = this.m_MDisplayList[i].dispnamek;
				}
			}
		});
	}
}
