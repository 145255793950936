import { WprModel } from '../../../../wpr-framework/view/deco/WprModel';
import { WprDI_ScopeModel } from '../../../../wpr-framework/view/di/WprDI_ScopeModel';
import MypageDispdivcdConstants from '../../../common/MypageDispdivcdConstants';
import { AccessAccountService } from '../../../service/AccessAccountService';
import { ViewInfoModel } from '../../../service/models/apimodels/ViewInfoModel';
import { GetViewInfoRequest } from '../../../service/models/dto/mypagerenewal/GetViewInfoRequest';
import { ResetContractRadiusAccountRequest } from '../../../service/models/dto/mypagerenewal/ResetContractRadiusAccountRequest';
import { MDisplay } from '../../../service/models/entity/secondary/MDisplay';
import { MypageBaseView } from '../../base/MypageBaseView';
import { ProgressBarData } from '../../common/progress_bar/ProgressBarView';
import { MypageWizardMngr } from '../../wizard/models/MypageWizardMngr';
import { ReiAccessAccountModel } from './models/ReiAccessAccountModel';

/**
 * アカウントパスワード発行確認
 */
export class ReiAccesspassConfirmView extends MypageBaseView {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() { super('ReiAccesspassConfirmView'); }
	// --------------------------------------------------------------------------
	
	// モデル  ------------------------------------------------------------------
	@WprDI_ScopeModel('MypageWizardMngr')
	private m_MypageWizardMngr: MypageWizardMngr			= null;		// 画面ウィザード管理クラス
	@WprModel('ReiAccessAccountModel')
	private m_ReiAccessAccountModel: ReiAccessAccountModel			= null;		// アクセスアカウントパスワード発行モデル
	private m_AccessAccountService: AccessAccountService = new AccessAccountService();	// アクセスアカウントサービス
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * コントロール定義設定
	 */
	public onRegisterConfig(): void {
		this.addConfig('course', 'コース', 'ReiAccessAccountModel');
		this.addConfig('accessAccount', 'アクセスアカウント', 'ReiAccessAccountModel');
	}

	/**
	 * アクション登録
	 */
	public onRegisterAction(): void {
		this.addAction('next', this.onNext);
		this.addAction('back', this.onBack);
	}

	/**
	 * ビュー表示通知
	 */
	public onShowView(): void {
		this.m_MypageWizardMngr.initView(this, (result: ViewInfoModel) => {
			this.registAccessLog();
			this.setStorageItem('b_url', window.location.href);
			const sendData: ProgressBarData = new ProgressBarData();
			sendData.progressBarList = this.m_MypageWizardMngr.getProgressModelList(this);
			this.sendChildData('fP_ev_progressBarIntital', sendData);

			this.getCourse();
		});
	}
	// --------------------------------------------------------------------------

	// アクション  ---------------------------------------------------------------
	/**
	 * 次へ
	 */
	public onNext(param: any): void {
		this.getPassword();
	}

	/**
	 * 戻る	
	 */
	public onBack(param: any): void {
		this.m_MypageWizardMngr.goBack(this);
	}
	// --------------------------------------------------------------------------

	// privateメソッド  ----------------------------------------------------------
	private getCourse(): void {
		this.m_ReiAccessAccountModel = this.m_MypageWizardMngr.getCache().m_ReiAccessAccountModel;
		// パラメータからcontractIDを取得
		const contractID = this.m_MypageWizardMngr.getParams().contractId
		// パラメータからradiusAccountを取得
		this.m_ReiAccessAccountModel.accessAccount = decodeURIComponent(this.m_MypageWizardMngr.getParams().radiusAccount);
		// MDisplayからCD0051の一覧取得
		const serviceList: MDisplay[] =this.m_MypageWizardMngr.getMDisplayList(this,MypageDispdivcdConstants.ACCESSPASS_SERVICE,'');
		const viewInfo: ViewInfoModel = this.m_MypageWizardMngr.getViewInfo();
        const contractList = viewInfo.contractModel.contractList;
		let data = null;
		for (const contract of contractList) {
			if (contract.contractID.slice(11) === contractID) {
				if (contractID && contractList) {
					data = serviceList.filter(value => value.namecd === contract.serviceCD);
					this.m_ReiAccessAccountModel.course = data[0].dispnamep;
				}
			}
		}
		this.refreshView();
	}
	
	private getPassword(): void{
		const req: ResetContractRadiusAccountRequest = new ResetContractRadiusAccountRequest();
		const contractID = this.m_MypageWizardMngr.getParams().contractId
		// 外部APIに接続
		req.contractID = contractID;
		req.radiusAccount = this.m_ReiAccessAccountModel.accessAccount;
		this.m_AccessAccountService.resetContractRadiusAccount(this, req, (result) => {
			this.m_ReiAccessAccountModel.password = result.password;
			this.m_ReiAccessAccountModel.passwordKana = result.passwordKana;
			this.m_ReiAccessAccountModel.date = result.date
			this.m_MypageWizardMngr.goNext(this);
		});
	}

	// --------------------------------------------------------------------------
}

