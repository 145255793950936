import { Application } from '../../apimodels/fuse/soap/Application';
import { CertificationInfo } from '../../apimodels/CertificationInfo';

/**
 * LINE取得リクエスト
 */
export class GetLineInfoRequest {
	// public 変数	-----------------------------------------------------------
	public idToken: string;			// ログイントークン
	public terminalFlg: boolean;	// 端末登録フラグ
	// ------------------------------------------------------------------------
}
