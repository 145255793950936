import { BaseService } from './base/BaseService';
import { WprBaseViewCore } from '../../wpr-framework/view/WprBaseViewCore';
import { GetReferralUrlRequest } from './models/dto/ambassador/GetReferralUrlRequest';
import { GetReferralUrlResponse } from './models/dto/ambassador/GetReferralUrlResponse';
import { GetAmbassadorCampaignRequest } from './models/dto/ambassador/GetAmbassadorCampaignRequest';
import { GetAmbassadorCampaignResponse } from './models/dto/ambassador/GetAmbassadorCampaignResponse';

/**
 * MyPageRenewalサービス
 */
export class AmbassadorService extends BaseService {
	// サービス メソッド  --------------------------------------------------------
	/**
	 * 紹介専用URL発行
	 * @param viewCore ビュー情報
	 * @param request リクエスト情報
	 * @param resMethod 結果処理メソッド
	 */
	public getReferralUrl(viewCore: WprBaseViewCore, request: GetReferralUrlRequest, resMethod: (result: GetReferralUrlResponse) => void): void {
		return this.doPost(viewCore, '/ambassador/getReferralUrl', request, resMethod);
	}

	/**
	 * 紹介キャンペーン取得
	 * @param viewCore ビュー情報
	 * @param request リクエスト情報
	 * @param resMethod 結果処理メソッド
	 */
	public getAmbassadorCampaign(viewCore: WprBaseViewCore, request: GetAmbassadorCampaignRequest, resMethod: (result: GetAmbassadorCampaignResponse) => void): void {
		return this.doPost(viewCore, '/ambassador/getAmbassadorCampaign', request, resMethod);
	}
	// --------------------------------------------------------------------------
}
