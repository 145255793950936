import React, { ReactNode } from 'react';
import { WprBaseDialogViewComponent } from '../../../../../wpr-framework/view/component/WprBaseDialogViewComponent';
import { MvnoTmpGetDialogView } from './MvnoTmpGetDialogView';
import { MatButton } from '../../../../../wpr-framework/component/controls/material/MatButton';

/**
 * MVNO一時保存データ取得ダイアログ用コンポーネント
 */
export class MvnoTmpGetDialog extends WprBaseDialogViewComponent {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: any) { super(new MvnoTmpGetDialogView(), props); }
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** MVNO一時保存データ取得ダイアログビュー情報 */
	public get viewInfo(): MvnoTmpGetDialogView { return this.view as MvnoTmpGetDialogView; }
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		return (
			<>
				<div style={{ textAlign: 'center' }}>
					<div>
						<div>以前編集した内容が保存されています。</div>
						<div>保存した画面に遷移する場合は、以下のボタンからお進みください。</div>
					</div>
					<div>
						<MatButton name='toSavedView' view={this.view}>再開</MatButton>
					</div>
				</div>
				{/* Milosから追加 START */}
				{/* Milosから追加 END */}
			</>
		);
	}
	// --------------------------------------------------------------------------
}
