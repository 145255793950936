import React, { ReactNode } from 'react';
import { WprBaseViewComponent } from '../../../../wpr-framework/view/component/WprBaseViewComponent';
import { VideoPassPrintView } from './VideoPassPrintView';
import { Card, Grid, Typography } from '@material-ui/core';
import { WprSpan } from '../../../../wpr-framework/component/controls/WprSpan';
import { MatButton } from '../../../../wpr-framework/component/controls/material/MatButton';
import CancelIcon from '@mui/icons-material/Cancel';
import styles from './Video_pass_print.scss';
import itscomIcon from '/shared/image/common/itscomMyp_icon_blue.png';
import { MatTypography } from '../../../../wpr-framework/component/controls/material/MatTypography';

/**
 * 動画配信サービスパスワード再発行印刷画面用コンポーネント
 */
export class VideoPassPrint extends WprBaseViewComponent {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: any) { super(new VideoPassPrintView(), props); }
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** 動画配信サービスパスワード再発行印刷画面ビュー情報 */
	public get viewInfo(): VideoPassPrintView	{ return this.view as VideoPassPrintView;	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * スタイルオブジェクト取得
	*/
	public getStylesObject(): any { return styles; }

	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		return (
			<>
			<div>
				<div className='icon-hearder'>
					<img src={itscomIcon} style={{ maxWidth: '57%' }} />
				</div>
				<div className='card'>
					<div className='main'>
						<div className='pageTitle'>
							<div className='title_left'>
								{/* <Typography className='title_text2'>動画配信サービス パスワード再発行</Typography> */}
								<MatTypography name='title' className='title_text' view={this.view}/>
								<Typography className='title_comment'>あなたの登録情報です。大切に保管してください。</Typography>
							</div>
						</div>
						<div className='gray'>
							<Grid container>
								<Grid item xs={12} md={4} className='grid-print-4'>
									<div>契約者ID</div>
								</Grid>
								<Grid item xs={12} md={8}  style={{ color: 'black' }} className='grid-print-8'>
									<MatTypography name={'id'} view={this.view} className='itemUnit bold-black'></MatTypography>
								</Grid>
							</Grid>	
							<Grid container>
								<Grid item xs={12} md={4} className='grid-print-4'>
									<div>パスワード</div>
								</Grid>
								<Grid item xs={12} md={8}  style={{ color: 'black' }} className='grid-print-8'>
									<MatTypography name={'password'} view={this.view} className='itemUnit bold-black'></MatTypography>
								</Grid>
							</Grid>	
							<Grid container>
								<Grid item xs={12} md={4} className='grid-print-4'>
									<div>パスワード読み仮名</div>
								</Grid>
								<Grid item xs={12} md={8}  style={{ color: 'black' }} className='grid-print-8'>
									<div dangerouslySetInnerHTML={{ __html: this.state.data.sentenceData }}  className='itemUnit'/>
								</Grid>
							</Grid>	
							<Grid container>
								<Grid item xs={12} md={4} className='grid-print-4'>
									<div>変更日</div>
								</Grid>
								<Grid item xs={12} md={8}  style={{ color: 'black' }} className='grid-print-8'>
									<MatTypography name={'date'} view={this.view} className='itemUnit bold-black'></MatTypography>
								</Grid>
							</Grid>	
						</div>
						<div className='title_left'>
							<Typography className='titleComplete_memo'>※パスワードの読み仮名にアンダーラインが引かれている文字は大文字を意味します。</Typography>
						</div>
					</div>
					<div className='pageFooter footer-space printButton'>
						<div>
							<MatButton name='print' view={this.view} className='orangeButton orangeButton_parts' >
								印刷
							</MatButton>
						</div>
						<div>
							<MatButton name='close' view={this.view} className='backButton' >
								<CancelIcon />
								閉じる
							</MatButton>

						</div>
					</div>

				</div>
			</div>
			</>
		);
	}
	// --------------------------------------------------------------------------
}
