import { WprModel } from '../../../../wpr-framework/view/deco/WprModel';
import { WprDI_ScopeModel } from '../../../../wpr-framework/view/di/WprDI_ScopeModel';
import { MypageBaseView } from '../../base/MypageBaseView';
import { CompleteModel } from '../../common/common_complete/models/CompleteModel';
import { MypageWizardMngr } from '../../wizard/models/MypageWizardMngr';
import { WprListData } from '../../../../wpr-framework/view/deco/WprListData';
import { callForwardingModel } from '../../../models/mypage/callForwarding/callForwardingModel';

/**
 * 着信転送完了
 */
export class CallForwardingCompleteView extends MypageBaseView {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() { super('CallForwardingCompleteView'); }
	// --------------------------------------------------------------------------

	// モデル  ------------------------------------------------------------------
	@WprModel('CompleteModel')
	private m_CompleteModel: CompleteModel					= null;	// 完了情報モデル
	@WprDI_ScopeModel('MypageWizardMngr')
	private m_MypageWizardMngr: MypageWizardMngr			= null;	// 画面ウィザード管理クラス
	@WprListData('CallOptionList')
	private m_CallOptionList: callForwardingModel[]			= null;	// 着信転送オプションリスト
	// --------------------------------------------------------------------------

	// メンバ変数 ---------------------------------------------------------------
	private m_CustomerName: string							= null;	// 顧客名
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * コントロール定義設定
	 */
	public onRegisterConfig(): void {
		this.addConfig('title_complete', 'タイトル', 'CompleteModel', { bindName: 'title' });
		this.addConfig('user', 'ユーザー', 'CompleteModel');
		this.addConfig('msg1', '本文1', 'CompleteModel', { bindName: 'massage1' });
		this.addConfig('bikou1', '備考1', 'CompleteModel');
		this.addListConfig('optionName', 'オプション名', 'CallOptionList');
		this.addListConfig('optionFee', '月額料金', 'CallOptionList');
		this.addListConfig('payUnit', '料金単位', 'CallOptionList');
	}

	/**
	 * アクション登録
	 */
	public onRegisterAction(): void {
		this.addAction('toQuestionnaire', this.onToQuestionnaire);
		this.addAction('back', this.onBack);
	}

	/**
	 * ビュー表示通知
	 */
	public onShowView(): void {
		this.m_MypageWizardMngr.initView(this, (result) => {
			this.registAccessLog();
			this.setStorageItem('b_url', window.location.href);
			//顧客情報
			const customerInfo = this.m_MypageWizardMngr.getViewInfo().customer;
			if (customerInfo.sexCD === '3' || customerInfo.sexCD === '')
				this.m_CustomerName = customerInfo.corporateName;
			else
				this.m_CustomerName = customerInfo.surName + ' ' + customerInfo.givenName;
			this.setData();
			this.m_CallOptionList = this.m_MypageWizardMngr.getCache().m_CallOptionList;
			this.setListData('CallOptionList', this.m_CallOptionList);
			this.refreshView();
		});
		this.refreshView();
	}
	// --------------------------------------------------------------------------

	// アクション  ---------------------------------------------------------------
	/**
	 * アンケート
	 */
	public onToQuestionnaire(param: any): void {
		window.open('https://questant.jp/q/B7KS5E4S?id=' + this.m_MypageWizardMngr.getParams().customerId);
	}

	/**
	 * 契約内容の確認・変更メニューへ
	 */
	public onBack(param: any): void {
		location.href = this.getOldUrl() + 'Srvlist/Continfo_list/';
	}
	// --------------------------------------------------------------------------

	/**
	 * 文章セット
	 */
	private setData() {
		const model = new CompleteModel();
		model.title = 'お申込みありがとうございます！';
		model.user = this.m_CustomerName + 'さん';
		model.massage1 = 'ケーブルプラス電話オプションをお申込みいただき、誠にありがとうございます。<br/>着信転送を受け付けました。<br/>ご案内事項がある場合、弊社担当より数日中に連絡いたします。';
		model.bikou1 = '※ご連絡はお客さま情報の確認・変更に登録されている<br/>連絡先メールアドレスにいたします。';
		this.m_CompleteModel = model;
	}
}
