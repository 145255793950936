import { HDocConfirm } from '../../entity/HDocConfirm';
import { Application } from '../../apimodels/fuse/soap/Application';

/**
 * 資料情報取得リクエスト
 */
export class GetDocumentRequest {
	// public 変数	-----------------------------------------------------------
	public hDocConfirm: HDocConfirm;	// 書面確認履歴
	public application: Application;	// 
	// ------------------------------------------------------------------------
}
