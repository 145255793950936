/**
 * 表示状態
 */
export enum WprControlVisibility {
	/** 表示 */
	VISIBLE		= 1,
	/** 非表示 */
	HIDDEN		= 2,
	/** 領域確保 */
	COLLAPSED	= 3
}

/**
 * コントロールステータス情報
 */
export interface IWprControlState {
	value?: any;							// 値
	viewValue?: any;						// 表示値
	editValue?: any;						// 編集値
	disabled?: boolean;						// 非活性状態
	readOnly?: boolean;						// 読取り専用
	visibility?: WprControlVisibility;		// 表示状態
	style?: any;							// スタイル
	className?: any;						// クラス
	modeClassName?: any;					// モード用クラス
	authClassName?: any;					// 権限用クラス
	hissu?: boolean;						// 必須
	min?: number | string;					// 最小値
	max?: number | string;					// 最大値
	minLength?: number;						// 最小長
	maxLength?: number;						// 最大長
	dirty?: boolean;						// 変更フラグ
	invalid?: boolean;						// バリデーションエラー
	option?: any;							// オプション（拡張）
	placeholder?: string;					// placeholder
	errMessage?: string[];					// エラーメッセージリスト
	loadWait?: boolean;						// ロード待ちフラグ
	tooltipOpen?: boolean;					// ツールチップオープン状態
	focus?: boolean;						// フォーカス取得状態
	refresh?: number;						// 再表示カウンタ
	numOption1?: number;					// 数値オプション１
	numOption2?: number;					// 数値オプション２
	boolOption1?: boolean;					// boolオプション１
	boolOption2?: boolean;					// boolオプション２
}