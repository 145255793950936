import React, { ReactNode } from 'react';
import { WprBaseViewComponent } from '../../../../wpr-framework/view/component/WprBaseViewComponent';
import { LineAgreeView } from './LineAgreeView';
import itscomIcon from '/shared/image/common/itscomMyp_icon_blue.png';
import { MatButton } from '../../../../wpr-framework/component/controls/material/MatButton';
import { MatTypography } from '../../../../wpr-framework/component/controls/material/MatTypography';
import styles from './LineAgree.scss';
import { Typography } from '@material-ui/core';
import { WprContainer } from '../../../../wpr-framework/component/controls/WprContainer';
/**
 * LINE同意承諾画面用コンポーネント
 */
export class LineAgree extends WprBaseViewComponent {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: any) { super(new LineAgreeView(), props); }
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** 画面ビュー情報 */
	public get viewInfo(): LineAgreeView { return this.view as LineAgreeView; }
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * スタイルオブジェクト取得
	*/
	public getStylesObject(): any { return styles; }

	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		return (
			<>
				<div className='liff-view'>
					<div className='icon-hearder'>
						<img src={itscomIcon}/>
					</div>
					<div className='card liff-card'>
						<div className='main'>
							<div className='pageTitle'>
								<div className='title_left'>
									<WprContainer mode='タイトル.PC' view={this.view}>
										<Typography className='title_text'>LINEとiTSCOMマイページの連携</Typography>
									</WprContainer>
									<WprContainer mode='タイトル.SP' view={this.view}>
										<Typography className='title_text' style={{textAlign: 'center'}}>LINEとiTSCOMマイページの<br/>連携</Typography>
									</WprContainer>
								</div>
							</div>
							<div className='notes-area'>
								<ul>
									<li>連携には、iTSCOM IDとパスワードが必要です。</li>
									<li>お客さまのご契約・お手続き状況に合わせてトークへメッセージ・通知を送信することがございます。</li>
									<li>個人情報保護方針は<a href='https://www.itscom.co.jp/info/privacy/' target = '_blank'>こちら</a></li>
								</ul>
							</div>
							<div className='pageFooter footer-space'>
								<div>
									<MatButton name='onLogin' view={this.view} className='nextButton text_trans' >
										上記に同意の上、<br/>iTSCOMマイページへ接続<br/>（ログイン画面へ）
									</MatButton>
								</div>
								<div style={{marginTop:'15px'}}>iTSCOM ID　未登録の方はこちら</div>
								<div>
									<MatButton name='onRegist' view={this.view} className='backButton text_trans' >
										iTSCOM IDの新規登録
									</MatButton>
								</div>
								<MatTypography name='text' view={this.view}/>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
	// --------------------------------------------------------------------------
}
