import { WprBaseValidator } from '../../../../wpr-framework/view/validation/WprBaseValidator';

/**
 * 氏名（漢字）バリデータ
 */
export class NameValidator extends WprBaseValidator {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() {
		super('氏名（漢字）');
	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * 入力値チェック
	 * @param value 入力値
	 * @param option チェックオプション
	 * @returns trueの場合、成功
	 */
	public checkValidValue(value: any, option?: any): boolean {
		if (!value)
			return true;
		var errorPattern = '[！”＃＄％＆’（）＝～｜‘｛＋＊｝＜＞？＿－＾￥＠「；：」、。・]';
		var numberPattern = '[０-９]';
		if ((value.match(errorPattern) === null) && (value.match(numberPattern) === null))
			return true;
		return false;
	}

	/**
	 * エラーメッセージ取得
	 * @param option オプション
	 * @returns エラーメッセージ
	 */
	public getErrorMessage(option?: any): string {
		return '使用できない文字や数字が含まれています。';
	}
	// --------------------------------------------------------------------------
}
