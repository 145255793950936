// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@charset \"UTF-8\";\n/*\r\n * IPアドレス種別（内容確認）用CSS\r\n */\n.ModIpopConfirm-supportMsgArea {\n  background: #F5F5F5;\n  border-radius: 12px;\n  padding: 20px;\n  margin-top: 20px;\n  margin-bottom: 60px;\n  font-style: normal; }\n  @media screen and (max-width: 450px) {\n    .ModIpopConfirm-supportMsgArea {\n      margin-bottom: 28px;\n      margin-top: 16px;\n      padding: 14px 12px; } }\n  .ModIpopConfirm-supportMsgArea .ModIpopConfirm-textcolor {\n    display: flex;\n    color: #6169B8;\n    font-weight: 700;\n    font-size: 14px;\n    height: 18px;\n    margin-bottom: 6px; }\n    @media screen and (max-width: 450px) {\n      .ModIpopConfirm-supportMsgArea .ModIpopConfirm-textcolor {\n        color: #6169B8;\n        font-weight: 700;\n        font-size: 14px;\n        height: 18px;\n        margin-bottom: 6px; } }\n    .ModIpopConfirm-supportMsgArea .ModIpopConfirm-textcolor img {\n      margin-right: 5px; }\n", ""]);
// Exports
exports.locals = {
	"supportMsgArea": "ModIpopConfirm-supportMsgArea",
	"textcolor": "ModIpopConfirm-textcolor"
};
module.exports = exports;
