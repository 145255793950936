import { WprModel } from '../../../../../wpr-framework/view/deco/WprModel';
import { WprDI_ScopeModel } from '../../../../../wpr-framework/view/di/WprDI_ScopeModel';
import { HMvnoTmpSaved } from '../../../../service/models/entity/HMvnoTmpSaved';
import { MvnoEntryInfo } from '../../../../service/models/apimodels/MvnoEntryInfo';
import { UrlUtil } from '../../../../common/util/UrlUtil';
import { WprViewMode } from '../../../../../wpr-framework/view/deco/WprViewMode';
import { NextButtonMode } from './mode/NextButtonMode';
import { MvnoService } from '../../../../service/MvnoService';
import { CreateMvnoInquiryRequest } from '../../../../service/models/dto/mvno/CreateMvnoInquiryRequest';
import { MypageLinkConstatns } from '../../../../common/constants/MypageLinkConstatns';
import { MvnoCompleteView } from '../../mvno_complete/MvnoCompleteView';
import { ProgressBarModel, ProgressBarStopModel } from '../../../../models/ProgressBarModel';
import { ProgressBarData } from '../../../common/progress_bar/ProgressBarView';
import { MvnoBaseView } from '../../../base/MvnoBaseView';

/**
 * MVNO申込_本人確認
 */
export class MvnoEntryIdentificationView extends MvnoBaseView {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() { super('MvnoEntryIdentification'); }
	// --------------------------------------------------------------------------

	// モデル  ------------------------------------------------------------------
	@WprDI_ScopeModel('MvnoEntryInfo')
	@WprModel('MvnoEntryInfo')
	private m_EntryInfo: MvnoEntryInfo = null;	// MVNOプラン情報
	@WprModel('confirm')
	private m_confirm: boolean = null;	// 確認

	@WprModel('environment')
	private m_environment: string = null;	// 開発環境

	@WprModel('identificationTitle')
	private m_identificationTitle: string = null;	// 本人確認のタイトル
	private m_applicantFlg: boolean = false;	// 本人フラグ
	@WprModel('nextButtonName')
	private m_nextButtonName: string = null;	// nextボタンの表示名


	@WprModel('isCompleteIdentification') // 後で消す
	private m_isCompleteIdentification: boolean = false;
	public get isCompleteIdentification(): boolean { return this.m_isCompleteIdentification; }
	// --------------------------------------------------------------------------

	// サービス  ----------------------------------------------------------------
	private m_MvnoService: MvnoService = new MvnoService();	// MyPageRenewalサービス
	// --------------------------------------------------------------------------

	// サブビュー/モード  ---------------------------------------------------------
	@WprViewMode
	private m_NextButtonMode = new NextButtonMode();	// 次へボタン用モード
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * コントロール定義設定
	 */
	public onRegisterConfig(): void {
		this.addConfig('c_confirm', '確認', 'confirm', { bindModel: true });
		// this.addConfig('entryTerminalFlg', '端末申込', 'MvnoEntryInfo.entryInfo');

		this.addConfig('customerId', '顧客番号', 'MvnoEntryInfo.entryInfo');
		this.addConfig('environment', '開発環境', 'environment', { bindModel: true });
		this.addConfig('identificationTitle', '本人確認タイトル', 'identificationTitle', { bindModel: true });
		this.addConfig('nextButtonName', 'nextボタン表示名', 'nextButtonName', { bindModel: true });

		// 後で消す
		this.addConfig('isCompleteIdentification', '本人確認完了かどうか', 'isCompleteIdentification', { bindModel: true });
	}

	/**
	 * アクション登録
	 */
	public onRegisterAction(): void {
		this.addAction('next', this.onNext);
		this.addAction('back', this.onBack);
		this.addAction('helpLink', this.onHelpLink);
		this.addAction('helpIdentificationDoc', this.onHelpIdentification);
	}

	/**
	 * ビュー表示通知
	 */

	public onShowView(): void {
		this.registAccessLog();
			this.setStorageItem('b_url', window.location.href)
		{
			//<script id="identificationScript" charset='utf-8' async="async" src='https://app.protechidchecker.com/apps/org7915'></script>
			// <script charset='utf-8' async="async" src='https://app.protechidchecker.com/apps/org7915'></script>
			// 本人確認用スクリプト：element作成
			const script = document.createElement('script');
			script.src = 'https://app.protechidchecker.com/apps/org7915';
			// script.src ='https://app.protechidchecker.com/apps/org7915';
			script.async = true;
			script.charset = 'utf-8';
			script.id = 'identificationScript'
			document.head.appendChild(script);

			// 本人確認API完了時の処理を登録
			document.addEventListener('completed.pic', event => {
				this.rcNext();
			});
		}
		this.m_environment = UrlUtil.getEnvironmentName();

		this.setViewMode('確認用チェックボックス', this.m_environment === '本番' ? '非表示' : '表示');
		this.getCookieValListForMvno(this.m_EntryInfo);
		this.m_EntryInfo.entryInfo.customerId = this.getCustomerId();

		if (this.m_EntryInfo.entryInfo.relationShip === '本人') {
			this.m_identificationTitle = '契約者兼利用者本人確認';
			this.m_applicantFlg = true;
			this.m_nextButtonName = 'この内容で申込む';
		} else {
			this.m_identificationTitle = '契約者本人確認';
			this.m_applicantFlg = false;
			this.m_nextButtonName = '次へ';
		}
		if (this.m_EntryInfo.identificationFlg === true) {
			this.m_isCompleteIdentification = true;
		}

		const sendData: ProgressBarData = new ProgressBarData();
		sendData.progressBarList = this.getProgressModelList();
		this.sendChildData('fP_ev_progressBarIntital', sendData);
		this.refreshView();
	}

	/**
	 * 値変更通知
	 * @param name 名前
	 * @param value 値
	 * @returns falseの場合、変更を取り消す
	 */
	public onChangeValue(name: string, value: any): boolean {
		// 後で消す
		if (name === 'isCompleteIdentification') {
			this.refreshModel();
		}
		// 後で消す
		return true;
	}
	// --------------------------------------------------------------------------

	// アクション  ---------------------------------------------------------------
	/**
	 * 次へ
	 */
	public onNext(param: any): void {
		this.refreshModel();
		this.m_EntryInfo.entryInfo.customerId = this.getCustomerId();
		const req: CreateMvnoInquiryRequest = new CreateMvnoInquiryRequest();
		req.customerId = this.getCustomerId();
		req.mvnoEntryInfo = this.m_EntryInfo;
		req.mvnoInquiryType = '申込';
		if (this.m_applicantFlg === true) {
			this.m_MvnoService.createMvnoInquiry(this, req, (result) => {
				this.setScopeMode('MVNO', MvnoCompleteView.MVNO_ENTRY);
				this.setScopeModel('customerName', this.m_EntryInfo.entryInfo.familyName + ' ' + this.m_EntryInfo.entryInfo.firstName);

				{
					// // 本人確認用スクリプト：element削除
					const identificationScript = document.getElementById("identificationScript");
					identificationScript.remove();
				}
				this.pushPath('/mvno_009');
			});
		} else {
			this.m_EntryInfo.identificationFlg = true;
			this.setCookieValForMvno(this.m_EntryInfo);
			this.pushPath('/mvno_010');
		}
	}

	/**
	 * 問い合わせリンク
	 */
	public onHelpLink(param: any): void {
		window.open(MypageLinkConstatns.ITSCOM_MVNOENTRY_HELP);
	}

	/**
	 * 戻る
	 */
	public onBack(param: any): void {
		this.refreshModel();
		this.pushPath('/mvno_007');
	}

	/**
	 * 本人確認書類について
	 */
	public onHelpIdentification(param: any): void {
		this.showDialog('ヘルプ：本人確認書類について');
	}
	// --------------------------------------------------------------------------

	/**
	 * 本人確認API完了時に実行される処理
	 */
	private rcNext(): void {
		this.m_isCompleteIdentification = true;
		this.refreshView();
	}

	private getProgressModelList(): ProgressBarModel[] {
		const list: ProgressBarModel[] = new Array();

		list.push(new ProgressBarModel('1', 'お申し込み手続き', 'complete', this.getStopModelList()));
		list.push(new ProgressBarModel('2', '合計金額ご確認', 'complete', null));
		list.push(new ProgressBarModel('3', '事前確認事項', 'complete', null));
		list.push(new ProgressBarModel('4', 'WEB本人確認', 'current', null));
		list.push(new ProgressBarModel('5', 'お申込み完了', 'next', null));
		return list;
	}

	private getStopModelList(): ProgressBarStopModel[] {
		const list: ProgressBarStopModel[] = new Array();
		list.push(new ProgressBarStopModel('complete'));
		list.push(new ProgressBarStopModel('complete'));
		list.push(new ProgressBarStopModel('complete'));
		list.push(new ProgressBarStopModel('complete'));
		return list;
	}
}
