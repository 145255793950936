import { WprBaseDialogViewInfo } from '../../../../../wpr-framework/view/dialog/WprBaseDialogViewInfo';
import { WprModel } from '../../../../../wpr-framework/view/deco/WprModel';
import { HMvnoTmpSaved } from '../../../../service/models/entity/HMvnoTmpSaved';
import { MvnoEntryInfo } from '../../../../service/models/apimodels/MvnoEntryInfo';
import { MvnoEntryDetailPartsData } from '../../entry/parts/mvno_entry_detail_parts/MvnoEntryDetailPartsView';

export class MvnoFeeDialogSendData {
	public entryInfo: MvnoEntryInfo;
	// public total: string;
}

/**
 * MVNO_料金明細ダイアログ
 */
export class MvnoFeeDialogView extends WprBaseDialogViewInfo {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() { super('MvnoFeeDialog'); }
	// --------------------------------------------------------------------------

	// モデル  ------------------------------------------------------------------
	// @WprModel('MvnoEntryInfo')
	// private m_EntryInfo: MvnoEntryInfo	= null;	// MVNO一時保存
	// @WprModel('total')
	// private m_total: string	= null;	// total
	// --------------------------------------------------------------------------

	// override プロパティ  ------------------------------------------------------
	/** ダイアログタイトル */
	public get title(): string { return null; }
	/** バリデーション */
	public get validation(): boolean { return false; }
	/** Divider使用フラグ */
	public get useDividers(): boolean { return false; }
	/** フッタ使用フラグ */
	public get useFooter(): boolean { return false; }
	/** OKボタン使用フラグ */
	public get useOKButton(): boolean { return false; }
	/** キャンセルボタン使用フラグ */
	public get useCancelButton(): boolean { return false; }
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * コントロール定義設定
	 */
	public onRegisterConfig(): void {
		// this.addConfig('capacity', 'データ量', 'MvnoEntryInfo.entryInfo');
		// this.addConfig('course', 'コース', 'MvnoEntryInfo.entryInfo');
		// this.addConfig('terminal', '端末', 'MvnoEntryInfo.entryInfo');
		// this.addConfig('terminalColor', '端末カラー', 'MvnoEntryInfo.entryInfo');
		// this.addConfig('simSize', 'SIMサイズ', 'MvnoEntryInfo.entryInfo');
		// this.addConfig('simOption', 'SIMオプション', 'MvnoEntryInfo.entryInfo');
		// this.addConfig('initialSupportFlg', '初期設定サポート申込', 'MvnoEntryInfo.entryInfo', { converter: '必要/不要' });
		// this.addConfig('fivegUseFlg', '5G通信利用', 'MvnoEntryInfo.entryInfo', { converter: '利用する/利用しない' });
		// this.addConfig('total', '合計', 'MvnoEntryInfo', { converter: '3桁カンマ' });
	}

	/**
	 * ダイアログ表示通知
	 * @param sendData 送信データ
	 */
	public onShowDialog(sendData: MvnoFeeDialogSendData) {
		// this.m_EntryInfo = sendData.entryInfo;
		const data: MvnoEntryDetailPartsData = new MvnoEntryDetailPartsData();
		data.entryInfo = sendData.entryInfo
		this.sendChildData('fP_vP_MvnoFeeDialogView', data, 'entryDetailParts');
		// this.setViewMode('プラン', this.m_EntryInfo.entryInfo.plan);
		// this.setViewMode('端末申込', this.m_EntryInfo.entryInfo.terminalUseFlg ? '有' : '無');
		// this.refreshView();
	}
	// --------------------------------------------------------------------------
}
