import { WprValueMap } from '../../../../wpr-framework/model/WprValueMap';
import { WprValueMapSetter } from '../../../../wpr-framework/model/WprValueMapSetter';

/**
 * 申込日種別
 */
export class TokotonSupportValueMap {
	// static メソッド  --------------------------------------------------------
	public static addValueMap(vmSetter: WprValueMapSetter): void {
		let vm = new WprValueMap();
		vm.addValue('即日開始', '即日開始');
		vm.addValue('翌日開始', '翌日開始');
		vm.addValue('翌月開始', '翌月開始');
		vmSetter.addValueMap('TokotonSupport', vm);
	}
	// --------------------------------------------------------------------------
}
