import { WprBaseViewModeModeInfo } from '../../../../../wpr-framework/mode/WprBaseViewModeModeInfo';
import { NameChangeNewInformationView } from '../NameChangeNewInformationView';

/**
 * 約款モード
 */
export class AgreeMode extends WprBaseViewModeModeInfo<NameChangeNewInformationView> {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() {
		super('AgreeMode', 'agree');
	}
	// --------------------------------------------------------------------------
	
	// override メソッド  --------------------------------------------------------
	/**
	 * モードリスト取得（この画面モードで使用する全てのモードを定義する）
	 * @param list モードリスト
	 */
	public useModeList(list: string[]): void {
		list.push('承諾');
		list.push('未承諾');
	}

	/**
	 * フィールド定義を登録処理
	 */
	public onRegisterControl(): void {
		this.addControl('next', { enabled: [ { mode: '承諾', value: true }, { mode: '未承諾', value: false } ] });
	}
	// --------------------------------------------------------------------------
}
