import { WprBaseFrameworkComponent } from '../component/base/WprBaseFrameworkComponent';
import { WprErrorMngr } from '../error/WprErrorMngr';
import { WprModelMngr } from '../model/WprModelMngr';
import { WprViewMngr } from '../view/WprViewMngr';
import { WprBaseMngr } from '../WprBaseMngr';
import { WprSettingInfo } from '../WprSettingInfo';
import { WprXmlInfoCreator } from './WprXmlInfoCreator';

/**
 * デバック管理機能
 */
export class WprDebugMngr extends WprBaseMngr {
	// private 変数  ------------------------------------------------------------
	private m_ViewMngr: WprViewMngr		= null;			// ビュー管理機能
	private m_ErrorMngr: WprErrorMngr	= null;			// エラー管理機能
	private m_ModelMngr: WprModelMngr	= null;			// モデル管理機能
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	public constructor() {
		super('デバック管理機能');
	}
	// --------------------------------------------------------------------------

	// override メソッド	-----------------------------------------------------
	/**
	 * 初期化処理
	 * @param loader データローダ
	 * @param setting 設定情報
	 */
	 public initialize(loader: WprBaseFrameworkComponent, setting: WprSettingInfo): void {
//		if (this.m_OutputEventLog === true) {
			window.document.onkeydown = (event) => {
				if (event.altKey === true && event.ctrlKey === true && event.keyCode === 123)
					this.copyDebugInfo();
			};
//		}
	}

	/**
	 * 他の管理機能設定処理
	 * @param mngrMap 管理機能マップ
	 */
	public setManager(mngrMap: Map<string, WprBaseMngr>): void {
		this.m_ViewMngr = mngrMap.get('ビュー管理機能') as WprViewMngr;
		this.m_ErrorMngr = mngrMap.get('エラー管理機能') as WprErrorMngr;
		this.m_ModelMngr = mngrMap.get('モデル管理機能') as WprModelMngr;
	}
	// --------------------------------------------------------------------------

	// private メソッド	----------------------------------------------------------
	/**
	 * デバック情報クリップボード出力
	 */
	private copyDebugInfo(): void {
		const text = this.getDebugInfoText();
		if (!navigator.clipboard) {
			const element = document.createElement('debufInfo');
			element.style.userSelect = 'auto';
			element.textContent = text;
			document.body.appendChild(element);
			document.getSelection().selectAllChildren(element);
			const result = document.execCommand('copy');
			document.body.removeChild(element);
		}
		else {
			navigator.clipboard.writeText(text).then(() => {
				console.log('デバック情報をクリップボードにコピーしました');
			});
		}
	}

	/**
	 * デバック情報文字列取得
	 * @returns イベントログ文字列
	 */
	private getDebugInfoText(): string {
		const dc = new WprXmlInfoCreator('wpr_debug_info');
		dc.addRootChildTextElement('date', this.getDateString());
		this.m_ViewMngr.setDebugInfo(dc);
		this.m_ErrorMngr.setDebugInfo(dc);
		this.m_ModelMngr.setDebugInfo(dc);
		return dc.getHtmlCode();
	}

	/**
	 * 時刻文字列取得
	 * @return 発生時刻文字列
	 */
	private getDateString(): string {
		const date = new Date();
		const year 		= ('0000' + date.getFullYear()).slice( -4 );	// yyyy
		const month 	= ('00'   + (date.getMonth() + 1)).slice( -2 );	// mm
		const day 		= ('00'   + date.getDate()).slice( -2 );		// dd
		const hour 		= ('00'   + date.getHours()).slice( -2 );		// hh
		const min 		= ('00'   + date.getMinutes()).slice( -2 );		// MM
		const second 	= ('00'   + date.getSeconds()).slice( -2 );		// ss
		const milli 	= ('000' + date.getMilliseconds()).slice( -3 );	// fff
		return `${year}/${month}/${day} ${hour}:${min}:${second}.${milli}`;
	}
	// --------------------------------------------------------------------------
}
