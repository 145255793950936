import { WprBaseFrameworkComponent } from '../component/base/WprBaseFrameworkComponent';
import { WprXmlInfoCreator } from '../debug/WprXmlInfoCreator';
import { WprBaseMngr } from '../WprBaseMngr';
import { WprSettingInfo } from '../WprSettingInfo';
import { WprErrorInfo, WprLogLevel } from './WprErrorInfo';
import { WprLocalStrageUserLog } from './WprLocalStrageUserLog';
import { WprUserLogInfo } from './WprUserLogInfo';

/**
 * エラー管理機能
 */
export class WprErrorMngr extends WprBaseMngr {
	// private 変数  ------------------------------------------------------------
	private ERROR_INFO_MAX 					= 1000;					// 最大エラー数
	private m_LogLevel: WprLogLevel			= WprLogLevel.WARNING;	// 出力ログレベル
	private m_ErrorList: WprErrorInfo[]		= new Array();			// エラー情報リスト
	private m_UserLogList: WprUserLogInfo[] = new Array();			// ユーザログ情報リスト
	private m_OutputEventLog: boolean		= false;				// イベントログ出力フラグ
	private m_EventLogList: string[]		= new Array();			// イベントログリスト
	private m_IsClearEventLog				= false;				// イベントログクリアフラグ
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	public constructor() {
		super('エラー管理機能');
	}
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** イベントログ出力フラグ */
	public get outputEventLog(): boolean 		{ return this.m_OutputEventLog; }
	// --------------------------------------------------------------------------

	// override メソッド	-----------------------------------------------------
	/**
	 * 初期化処理
	 * @param loader データローダ
	 * @param setting 設定情報
	 */
	public initialize(loader: WprBaseFrameworkComponent, setting: WprSettingInfo): void {
		this.m_LogLevel = setting.logLevel;
		this.m_OutputEventLog = setting.outputEventLog;
		if (this.m_OutputEventLog === true) {
			window.document.onkeydown = (event) => {
				if (event.altKey === true && event.keyCode === 123)
					this.copyEventLog();
			};
		}
	}
	// --------------------------------------------------------------------------

	// public メソッド	---------------------------------------------------------
	/**
	 * 通常ログ出力
	 * @param clsName クラス名
	 * @param msg メッセージ
	 */
	public addDebugLog(clsName: string, msg: string): void {
		if (this.m_LogLevel <= WprLogLevel.DEBUG)
			this.addLog(WprLogLevel.DEBUG, clsName, msg);
	}

	/**
	 * 情報ログ出力
	 * @param clsName クラス名
	 * @param msg メッセージ
	 */
	public addInfoLog(clsName: string, msg: string): void {
		if (this.m_LogLevel <= WprLogLevel.INFO)
			this.addLog(WprLogLevel.INFO, clsName, msg);
	}

	/**
	 * 警告ログ出力
	 * @param clsName クラス名
	 * @param msg メッセージ
	 */
	public addWarningLog(clsName: string, msg: string): void {
		if (this.m_LogLevel <= WprLogLevel.WARNING)
			this.addLog(WprLogLevel.WARNING, clsName, msg);
	}

	/**
	 * エラーログ出力
	 * @param clsName クラス名
	 * @param msg メッセージ
	 * @param ex 例外情報
	 */
	public addErrorLog(clsName: string, msg: string, ex?: Error): void {
		let einfo = null;
		if (ex === undefined)
			einfo = this.addLog(WprLogLevel.ERROR, clsName, msg);
		else
			einfo = this.addLog(WprLogLevel.EXCEPTION, clsName, msg, ex);
		this.m_ErrorList.push(einfo);
		if (this.m_ErrorList.length >= this.ERROR_INFO_MAX)
			this.m_ErrorList.splice(0, 1);
	}

	/**
	 * イベントログ出力
	 * @param log イベントログ
	 */
	public addEventLog(log: string): void {
		if (this.m_IsClearEventLog === true) {
			this.m_IsClearEventLog = false;
			this.m_EventLogList = new Array();
		}
		this.m_EventLogList.push(log);
		console.log(log);
	}

	/**
	 * デバッグ情報設定
	 * @param dc デバック情報作成情報
	 */
	public setDebugInfo(dc: WprXmlInfoCreator): void {
		const mngr = dc.addRootChildElement('error_mngr');
		const logs = dc.addChildElement(mngr, 'logs');
		this.m_ErrorList.forEach(linfo => {
			const log = dc.addChildElement(logs, 'log');
			dc.addAttribute(log, 'date', WprErrorInfo.getDateString(linfo.date));
			dc.addAttribute(log, 'level', WprErrorInfo.getStrLevel(linfo.level));
			dc.addAttribute(log, 'class', linfo.className);
			dc.addAttribute(log, 'msg', linfo.message);
		});
		const userLogs = dc.addChildElement(mngr, 'user_logs');
		this.m_UserLogList.forEach(linfo => {
			const log = dc.addChildElement(userLogs, 'log');
			dc.addAttribute(log, 'date', WprErrorInfo.getDateString(linfo.date));
			dc.addAttribute(log, 'class', linfo.className);
			dc.addAttribute(log, 'msg', linfo.message);
		});
	}

	/**
	 * ユーザ指定ログ追加
	 * @param clsName クラス名
	 * @param msg メッセージ
	 */
	public addUserLog(clsName: string, msg: string): void {
		const errInfo = new WprErrorInfo(WprLogLevel.USER, clsName, msg, undefined);
		const log = errInfo.getLogMessage();
		this.writerLog(log);
		const logInfo = new WprUserLogInfo(clsName, msg);
		if (!window.localStorage) {
			this.m_UserLogList.push(logInfo);
		}
		else {
			const ls = new WprLocalStrageUserLog();
			ls.addLog(logInfo);
		}
	}

	/**
	 * ユーザログ情報リスト取得
	 * @returns ユーザログ情報リスト
	 */
	public getUserLogList(): WprUserLogInfo[] {
		if (!window.localStorage) {
			return this.m_UserLogList;
		}
		else {
			const ls = new WprLocalStrageUserLog();
			return ls.getLogList();
		}
	}

	/**
	 * ユーザログ情報クリア
	 */
	public clearUserLog(): void {
		if (!window.localStorage) {
			this.m_UserLogList = new Array();
		}
		else {
			const ls = new WprLocalStrageUserLog();
			ls.clearUserLog();
		}
	}
	// --------------------------------------------------------------------------

	// private メソッド	---------------------------------------------------------
	/**
	 * ログ追加処理
	 * @param level ログレベル
	 * @param clsName クラス名
	 * @param msg メッセージ
	 * @param ex 例外情報
	 * @return ログ
	 */
	private addLog(level: WprLogLevel, clsName: string, msg: string, ex?: Error): WprErrorInfo {
		const errInfo = new WprErrorInfo(level, clsName, msg, ex);
		// ATODE ローカルストレージ対応
		const log = errInfo.getLogMessage();
		this.writerLog(log);
		if (ex !== undefined) {
			// this.writerLog('    ' + ex.message);
			this.writerLog('    ' + ex.stack);
		}
		return errInfo;
	}

	/**
	 * ログ出力処理
	 * @msg ログメッセージ
	 */
	private writerLog(msg: string): void {
		// console.log(msg);
	}

	/**
	 * イベントログクリップボード出力
	 */
	private copyEventLog(): void {
		const text = this.getEventLogText();
		if (!navigator.clipboard) {
			const element = document.createElement('eventLog');
			element.style.userSelect = 'auto';
			element.textContent = text;
			document.body.appendChild(element);
			document.getSelection().selectAllChildren(element);
			const result = document.execCommand('copy');
			document.body.removeChild(element);
		}
		else {
			navigator.clipboard.writeText(text).then(() => {
				console.log('イベントログをクリップボードにコピーしました');
			});
		}
		this.m_IsClearEventLog = true;
	}

	/**
	 * イベントログ文字列取得
	 * @returns イベントログ文字列
	 */
	private getEventLogText(): string {
		let code = '【WprEventLog】\r\n';
		this.m_EventLogList.forEach(log => {
			code = code + log + '\r\n';
		});
		return code;
	}
	// --------------------------------------------------------------------------
}
