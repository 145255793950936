import React, { ReactNode } from 'react';
import { WprBaseViewComponent } from '../../../../wpr-framework/view/component/WprBaseViewComponent';
import { MagazineCancelConfirmView } from './MagazineCancelConfirmView';
import { Typography, Grid, Divider } from '@material-ui/core';
import { MatButton } from '../../../../wpr-framework/component/controls/material/MatButton';
import { ProgressBar } from '../../common/progress_bar/ProgressBar';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import styles from './MagazineCancelConfirm.scss';
import importantIcon from '/shared/image/common/important_purple.png';
import netIcon from '/shared/image/common/logo_iTSCOMTV.png';
/**
 * マガジン解約申込画面用コンポーネント
 */
export class MagazineCancelConfirm extends WprBaseViewComponent {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: any) { super(new MagazineCancelConfirmView(), props); }
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** マガジン解約申込画面ビュー情報 */
	public get viewInfo(): MagazineCancelConfirmView { return this.view as MagazineCancelConfirmView; }
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * スタイルオブジェクト取得
	*/
	public getStylesObject(): any { return styles; }

	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		return (
			<>
				<div className='pageCard'>
					<div className="pageHeader stepFlow">
						<ProgressBar name='progressBar' view={this.view} />
					</div>
					<div className='inContractArea'>
						<div className='sub-logo'>
						<img src={netIcon} alt="purple" style={{ verticalAlign: 'middle' }} />
						</div>
					</div>
					<div className='main'>
						<div className='pageTitle'>
							<div className='title_left'>
								<Typography className='title_text'>番組案内誌変更確認</Typography>
								<Typography className='title_comment' style={{ fontWeight: '500' }}>以下の内容でよろしければ、「解約」ボタンをクリックしてください。</Typography>
							</div>
						</div>
						<div className='feeTable'>
							<Grid container className='feeRow'>
								<Grid item xs={12} md={4} className='feeTitle'>
									<div>iTSCOM magazine</div>
								</Grid>
								<Grid item xs={12} md={8} className='feeVal courseValue' style={{ color: 'black' }}>
									<span className='itemUnit' style={{ color: '#484848' }}>解約</span>
								</Grid>
							</Grid>
						</div>
						<div className='supportMsgArea supportText'>
								<span className='textcolor'>
									<img src= {importantIcon} alt='purple' style={{ verticalAlign: 'middle'}} /> 配送終了時期に関して
								</span>
								<li>14日までの解約受付の場合
									<br />
									翌月号からの番組表の配送を停止いたします。今月利用分以降請求は発生いたしません。
									<br />
									(配送スケジュールの兼ね合い上、翌月号が届く場合がございますが、請求に影響はございません)
									<br />
								</li>
								<li>15日以降の解約受付の場合
									<br />
									翌々月号からの番組表の配送を停止いたします。翌月利用分以降請求は発生いたしません。
								</li>
						</div>
					</div>
					<Divider />
					<div className='pageFooter footer-space'>
						<div>
							<MatButton name='next' className='orangeButton orangeButton_parts' view={this.view}>解約
								<NavigateNextIcon />
							</MatButton>
						</div>
						<div>
							<MatButton name='back' view={this.view} className='backButton' >
								<NavigateBeforeIcon />
								戻る
							</MatButton>
						</div>
					</div>
				</div>
			</>
		);
	}
	// --------------------------------------------------------------------------
}
