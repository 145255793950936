/**
 * レイアウト状態情報
 */
export class WprLayoutStatus {
	// private 変数  ------------------------------------------------------------
	private m_Open?: boolean	= true;	// オープン状態
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** 必須入力 */
	public get open(): boolean 	{ return this.m_Open; 	}	public set open(open: boolean) { this.m_Open = open;	}
	// --------------------------------------------------------------------------

	// public メソッド  ---------------------------------------------------------
	/**
	 * クローン取得
	 * @returns クローン
	 */
	public clone(): WprLayoutStatus {
		const clone = new WprLayoutStatus();
		clone.open 		= this.open;
		return clone;
	}
	// --------------------------------------------------------------------------
}
