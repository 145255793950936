import { WprModel } from '../../../../wpr-framework/view/deco/WprModel';
import { AuthService } from '../../../service/AuthService';
import { GetLineInfoRequest } from '../../../service/models/dto/auth/GetLineInfoRequest';
import { MypageBaseView } from '../../base/MypageBaseView';
import {liff} from '../../../../../scripts/liff.js';
import { WprDI_ScopeModel } from '../../../../wpr-framework/view/di/WprDI_ScopeModel';
import { MypageWizardMngr } from '../../wizard/models/MypageWizardMngr';
import { WizardPostModel } from '../../../models/WizardModel';

/**
 * LINE同意承諾画面
 */
export class LineLiffView extends MypageBaseView {
	
	// コンストラクタ  -----------------------------------------------------------
	public constructor() { super('LineLiffView'); }
	// --------------------------------------------------------------------------

	// メンバ変数  ---------------------------------------------------------------
	private m_loginToken: string = null;
	@WprModel('TerminalId')
	private m_TerminalId: string	= null;	// 機器番号
	@WprModel('StockTypeCd')
	private m_StockTypeCd: string	= null;	// 在庫種別
	@WprModel('TerminalTypeCd')
	private m_TerminalTypeCd: string	= null;	// 機器タイプ
	// --------------------------------------------------------------------------

	// サービス  ------------------------------------------------------------------
	private m_AuthService: AuthService = new AuthService();	// 認証サービス
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * コントロール定義設定
	 */
	public onRegisterConfig(): void {
	}

	/**
	 * アクション登録
	 */
	public onRegisterAction(): void {
	}

	/**
	 * ビュー表示通知
	 */
	public onShowView(): void {
		this.registAccessLog();
		this.setStorageItem('b_url', window.location.href);
		const host = location.host;
		let liffId: string;
		this.m_TerminalId = this.getUrlParam('tid');
		this.m_StockTypeCd = this.getUrlParam('stcd');
		this.m_TerminalTypeCd = this.getUrlParam('ttcd');
		if (host.includes("localhost") || host.includes("stg")) 
			liffId = '2001902820-6ZVWX0NP';
		else 
			liffId = '2005701465-K0rLypao';
		liff.init({
			liffId: liffId, // Use own liffId
			withLoginOnExternalBrowser: true,
		})
		.then(() => {
			const idToken = liff.getIDToken();
			const reqGetLineInfoRequest: GetLineInfoRequest = new GetLineInfoRequest();
			reqGetLineInfoRequest.idToken = idToken;
			if (this.m_TerminalId)
				reqGetLineInfoRequest.terminalFlg = true;
			else
				reqGetLineInfoRequest.terminalFlg = false;
			this.m_AuthService.getLineInfo(this, reqGetLineInfoRequest, (result) => {
				if (result) {
					// 端末登録QRからの遷移
					if (this.m_TerminalId && this.m_StockTypeCd && this.m_TerminalTypeCd) {
						const paramList = new Array();
						paramList.push({name: WizardPostModel.URL_TERMINAL_ID, value: this.m_TerminalId});
						paramList.push({name: WizardPostModel.URL_STOCK_TYPE_CD, value: this.m_StockTypeCd});
						paramList.push({name: WizardPostModel.URL_TERMINAL_TYPE_CD, value: this.m_TerminalTypeCd});
						if (result.loginFlg) {
							// マイページログイン済み→トップ画面へ
							location.href = this.getOldUrl() + 'Top/My_top/' + '?tid=' + this.m_TerminalId + '&stcd=' + this.m_StockTypeCd + '&ttcd=' + this.m_TerminalTypeCd;
						}
						else if (result.linkedFlg == false) {
							// LINE未連携→同意画面へ
							this.setCookie('idToken', result.loginToken);
							this.pushPath('/line_agree', paramList);
						} else {
							// LINE連携済み、マイページ未ログイン→ログイン画面へ
							location.href = this.getOldUrl() + "Top/Login/" + '?tid=' + this.m_TerminalId + '&stcd=' + this.m_StockTypeCd + '&ttcd=' + this.m_TerminalTypeCd;
						}
					}
					else {
						// 端末登録QR以外からの遷移
						if (result.linkedFlg == false) {
							// LINE未連携、機器番号なし→同意画面へ
							this.setCookie('idToken', result.loginToken);
							this.pushPath('/line_agree');
						} else {
							// LINE連携済み、機器番号なし→ログイン画面へ
							location.href = this.getOldUrl() + "Top/Login/";
						}
					}
				} else {
					this.pushPath('/error');
				}
			});
		  }) .catch((err) => {
			this.pushPath('/error');
		});
	}
	// --------------------------------------------------------------------------
}
