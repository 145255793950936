/**
 * nhk
 */
export class Nhk {
	// public 変数	-----------------------------------------------------------
	public befDiscountName: string; // 割引種類名(変更前)
	public discountSelect: string;	// 割引種類名(変更後)
	public aftDiscountCode: string;	// 割引種類コード(変更後)
	public befDiscountCode: string;	// 割引種類コード(変更前)
	public checkBox1: boolean;		// 契約住所
	public checkBox2: boolean;		// 契約住所以外
	public postalNumber: any;		// 郵便番号
	public address: any;			// 住所
	public buildingName: any;		// 建物名
	public roomNumber: any;			// 部屋番号
	public mailing: any;			// 宛名
	// ------------------------------------------------------------------------
}
