import { UsageServiceInfo } from './UsageServiceInfo';
import { TokyuServiceInfo } from './TokyuServiceInfo';

/**
 * 利用明細情報（月単位）
 */
export class UsageMonthlyInfo {
	// public 変数	-----------------------------------------------------------
	public customerId: string;					// お客様ID
	public customerName: string;				// お客様氏名
	public month: string;						// 対象年月
	public monthLabel: string;					// 対象月（表示用）
	public yearLabel: string;					// 対象年（表示用）
	public monthIdx: number;					// 対象年月（インデックス）
	public total: number;						// 請求合計
	public serviceInfoList: UsageServiceInfo[];	// サービスの明細情報リスト
	public tokyuGasInfo: TokyuServiceInfo;		// 東急ガス情報
	public tokyuDenkiInfo: TokyuServiceInfo;	// 東急でんき情報
	// ------------------------------------------------------------------------
}
