import { GetTvInfoResponse } from "../../../service/models/dto/mypagerenewal/GetTvInfoResponse";
import { MCommon } from "../../../service/models/entity/primary/MCommon";
import { TvCourseListModel } from "./TvCourseListModel";

/**
 * 
 */
export class TvSelectSendModel {
	// public 変数	-----------------------------------------------------------
	public mstInfo: TvCourseListModel[];		// 表示情報配列
	public srvtvCourseName: MCommon[];			// 表示順コース名一覧
	public tanako: GetTvInfoResponse;			// 店子情報
	public type: string;						// 区分
	public tanakoFlg: boolean;						// 区分
	// ------------------------------------------------------------------------
}
