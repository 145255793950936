import { IWprModeBool } from '../inter/IWprModeBool';
import { WprBaseValueModeSetter } from './WprBaseValueModeSetter';
import { WprBaseViewMode } from '../WprBaseViewMode';
import { IWprConfigModeOption } from '../inter/IWprConfigModeOption';

/**
 * モード表示状態設定
 */
export class WprVisibilityModeSetter extends WprBaseValueModeSetter<IWprModeBool> {
	// コンストラクタ  -----------------------------------------------------------
	public constructor(option: IWprConfigModeOption, viewMoode: WprBaseViewMode, id?: string) {
		super('visibility', option, viewMoode, id);
	}
	// --------------------------------------------------------------------------

	// override メソッド	-----------------------------------------------------
	/**
	 * 初期化処理
	 * @param option モード定義オプション
	 */
	public initialize(): void {
		this.initializeModeList(this.option.visibility);
	}

	/**
	 * コントロールステータス設定処理
	 * @param id コントロールID
	 * @param vinfo 値情報
	 */
	public setControlStatus(vinfo: IWprModeBool, id?: string): void {
		this.controlInfo.setVisibility(vinfo.value, id);
	}
	// --------------------------------------------------------------------------
}
