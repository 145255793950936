import { WprModel } from '../../../../../wpr-framework/view/deco/WprModel';
import { WprDI_ScopeModel } from '../../../../../wpr-framework/view/di/WprDI_ScopeModel';
import { MCommon } from '../../../../service/models/entity/primary/MCommon';
import { MypageBaseView } from '../../../base/MypageBaseView';
import { ProgressBarData } from '../../../common/progress_bar/ProgressBarView';
import { MypageWizardMngr } from '../../../wizard/models/MypageWizardMngr';
import { Ipaddress } from '../../../../models/bss/ip/Ipaddress';
import MypageDispdivcdConstants from '../../../../common/MypageDispdivcdConstants';
import { BaseInfo } from '../../../../models/mypage/smart/BaseInfo';
import { Contract } from '../../../../models/bss/contract/Contract';
import { ObjectUtil } from '../../../../common/util/ObjectUtil';

/**
 * IPアドレス種別（内容確認）
 */
export class ModIpopConfirmView extends MypageBaseView {
	m_IpList: MCommon[];
	// コンストラクタ  -----------------------------------------------------------
	public constructor() { super('ModIpopConfirmView'); }
	// --------------------------------------------------------------------------

	// モデル  ------------------------------------------------------------------
	@WprDI_ScopeModel('MypageWizardMngr')
	private m_MypageWizardMngr: MypageWizardMngr			= null;		// 画面ウィザード管理クラス
	@WprModel('ipaddress')
	private m_ipaddress: Ipaddress	= null;	// nhkモデル
	@WprModel('BaseInfo')
	private m_BaseInfo: BaseInfo	= null;	// 基本情報
	// --------------------------------------------------------------------------
	private m_Contract: Contract							= null;	// 契約情報(変更対象)
	// override メソッド  --------------------------------------------------------
	/**
	 * コントロール定義設定
	 */
	public onRegisterConfig(): void {
	}

	/**
	 * アクション登録
	 */
	public onRegisterAction(): void {
		this.addAction('next', this.onNext);
		this.addAction('back', this.onBack);
	}

	/**
	 * ビュー表示通知
	 */
	public onShowView(): void {
		this.m_MypageWizardMngr.initView(this, (result) => {
			this.registAccessLog();
			this.setStorageItem('b_url', window.location.href)
			const sendData: ProgressBarData = new ProgressBarData();
			sendData.progressBarList = this.m_MypageWizardMngr.getProgressModelList(this);
			this.sendChildData('fP_ev_progressBarIntital', sendData);
			this.m_BaseInfo = this.m_MypageWizardMngr.getCache().m_BaseInfo;
			this.m_Contract = this.m_MypageWizardMngr.getCache().m_Contract;
			const contractId = this.m_MypageWizardMngr.getParams().contractId;
			if (ObjectUtil.isNotNullOrEmptyStr(contractId))
				this.m_Contract = this.m_MypageWizardMngr.getContract(this, contractId);
			this.m_BaseInfo.contractId = this.m_Contract.contractID;
			const glbl_f = this.m_MypageWizardMngr.getMDisplay(this,MypageDispdivcdConstants.IPADDRESS_TYPE,'0202000110');//グローバルIP FTTH
			const glbl = this.m_MypageWizardMngr.getMDisplay(this,MypageDispdivcdConstants.IPADDRESS_TYPE,'9999999999');//グローバルIP
			const prvt = this.m_MypageWizardMngr.getMDisplay(this,MypageDispdivcdConstants.IPADDRESS_TYPE,'OP00000009');//プライベートIP		
			const glbl_ip_f = this.m_MypageWizardMngr.getMCommonList(this,'namecd','ip_type','glbl_ip_f',null); //IP名称リスト0202000110
			const glbl_ip = this.m_MypageWizardMngr.getMCommonList(this,'namecd','ip_type','glbl_ip',null); //IP名称リスト999999999999
			const prvt_ip = this.m_MypageWizardMngr.getMCommonList(this,'namecd','ip_type','prvt_ip',null); //IP名称リストop0000009
			this.m_ipaddress = this.m_MypageWizardMngr.getCache().ipaddress;	
			this.refreshView();
			const iplist = [glbl_ip_f,glbl_ip,prvt_ip];
			const option = this.m_MypageWizardMngr.getParams().optionId
			for(const common of iplist){
				for(const common2 of common){
				    if(common2.commonValue === prvt.namecd && option === glbl.namecd || option === glbl_f.namecd){
				    	this.setViewMode('ip','プライベート')
						this.m_ipaddress.ip = 'プライベート';
						
				    }else if(common2.commonValue === glbl.namecd || common2.commonValue === glbl_f.namecd && option === prvt.namecd){
				    	this.setViewMode('ip','グローバル')
						this.m_ipaddress.ip = 'グローバル';
				    }
			    }
			}
			this.refreshView();
		});
	
	
	}
	// --------------------------------------------------------------------------

	// アクション  ---------------------------------------------------------------
	/**
	 * 申込む         <NavigateNextIcon />
	 */
	public onNext(param: any): void {
		this.refreshModel();
		this.m_MypageWizardMngr.goNext(this);

	}

	/**
	 * <NavigateBeforeIcon />         戻る
	 */
	public onBack(param: any): void {
		this.m_MypageWizardMngr.goBack(this);
	}
	
	// --------------------------------------------------------------------------
			
}
