import { FormControlLabel, Grid, Radio } from '@material-ui/core';
import React, { ReactNode } from 'react';
import { WprBaseInputControlComponent } from '../../../wpr-framework/component/controls/WprBaseInputControlComponent';
import { IWprInputControlProps } from '../../../wpr-framework/component/props/IWprControlProps';
import { WprControlVisibility } from '../../../wpr-framework/component/props/IWprControlState';
import { WprFramework } from '../../../wpr-framework/WprFramework';

import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import styles from './CheckMarkRadio.scss';
import { MvnoCapacitySendData, MvnoPlanSendData, CheckMarkRadioContent, MvnoTerminalSendData } from './CheckMarkRadioContent';

/**
 * ラジオボタンプロパティ情報
 */
interface CheckMarkRadioProps extends IWprInputControlProps {
	value: any;										// 値
	label?: string;									// ラベル
	color?: 'primary' | 'secondary' | 'default';	// 色
	clear?: boolean;								// 値クリアフラグ（選択済みをクリックした場合にクリアする）

	disabled?: boolean;
	itemType: string;	// 項目種別
	radioContentData?: MvnoPlanSendData | MvnoTerminalSendData;
}

/**
 * ラジオボタンコントロール
 */
export class CheckMarkRadio extends WprBaseInputControlComponent<CheckMarkRadioProps, HTMLInputElement> {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: CheckMarkRadioProps) {
		super(props);
		this.setChangeValueEvent(this.onChangeValue.bind(this));
		let isClear = WprFramework.getInstance().view.isRadioClear;
		if (this.props.clear !== undefined)
			isClear = this.props.clear;
		if (isClear === true)
			this.setClickEvent(this.onClick.bind(this));
	}
	// --------------------------------------------------------------------------

	// イベント  ----------------------------------------------------------------
	/**
	 * 値変更処理
	 * @param event 変更イベント 
	 */
	public onChangeValue(event: React.ChangeEvent<HTMLInputElement>): void {
		if (event != null && event.target.checked === false)
			return;
		if (this.controlInfo != null) {
			let value = this.props.value;
			if (event != null)
				this.addChangeValueEventLog(event.target, value);
			else
				value = this.controlInfo.value;
			this.changeValue(value);
		}
	}

	/**
	 * クリックイベント処理
	 * @param event クリックイベント 
	 */
	public onClick(event: React.MouseEvent<HTMLInputElement>): void {
		const pval = this.props.value;
		const val = this.controlInfo.value;
		if (pval === val) {
			this.addClickEventLog(event.target as HTMLInputElement);
			this.controlInfo.clearValue(null);
			this.onChangeValue(null);
		}
	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * スタイルオブジェクト取得
	*/
	public getStylesObject(): any { return styles; }

	/**
	 * コントロール名称取得
	 * @returns コントロール名称
	 */
	public getName(): string {
		if (this.props.row)
			return this.props.name + this.props.row.index;
		else
			return this.props.name;
	}

	/**
	 * 値取得
	 * @returns 値
	 */
	protected getValue(): any {
		return this.props.value;
	}

	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		if (this.state.visibility === WprControlVisibility.COLLAPSED)
			return <></>;
		const props = this.getProps('wpr-radio');
		const { name, view, className, label, children, ...other } = this.props;
		const state = this.getState(['style', 'disabled', 'readOnly']);
		const checked = (this.props.value === this.state.value);
		const options = this.getOptions();
		const events = this.getEvents();
		if (state.readOnly === true && state.disabled === false && checked === false)
			state.disabled = true;
		let radio = null;
		if (this.state.visibility === WprControlVisibility.VISIBLE) {
			radio = <Radio checked={checked} {...props} {...state} {...other} {...options} {...events} ref={this.ref} />;
		}
		else {
			state['style'] = { ...this.state.style, visibility: 'hidden' };
			radio = <input type='radio' checked={checked} {...props} {...state} {...other} {...options} ref={this.ref} />;
		}
		// if (this.props.label) {
		if (this.state.visibility === WprControlVisibility.VISIBLE) {
			let disabled: boolean = false;
			// if (this.props.itemType === CheckMarkRadioContent.MVNO_CAPACITY) disabled = (this.props.radioContentData as MvnoCapacitySendData).selected;
			const className: string = checked ? 'selected' : '';
			radio = <FormControlLabel className={'mvno-radio-label' + ' ' +  className } control={radio} label={this.getLabel(checked)} disabled={this.props.disabled} />;
		}
		else {
			radio = <FormControlLabel className='mat-checkbox-label' control={radio} label={this.props.label} style={{ visibility: 'hidden' }} />;
		}
		// }
		return (
			<>
				{radio}
			</>
		);
	}
	// --------------------------------------------------------------------------

	// private メソッド  ---------------------------------------------------------
	/**
	 * オプション情報取得
	 * @returns オプション情報
	 */
	private getOptions(): any {
		const rtn: any = {};
		if (this.props.color)
			rtn['color'] = this.props.color;
		else
			rtn['color'] = 'primary';
		return rtn;
	}


	private getLabel(isSelected: boolean): any {
		const icon: any = (isSelected) ? <CheckCircleIcon /> : <RadioButtonUncheckedIcon />;
		// const className: string = (isSelected) ? 'selected' : '';
		return (
			<Grid container>
				{/* 右上アイコン */}
				<Grid item xs={12}>
					<div style={{ textAlign: 'right', height: '2rem' }}>{icon}</div>
				</Grid>
				{/* ラジオボタンの内容 */}
				<Grid item xs={12}>	
					{this.getLabelContent()}
				</Grid>
				{/* バランス調整のため */}
				<Grid item xs={12}>
					　
				</Grid>
			</Grid>
		);
	}

	private getLabelContent(): any {
		const itemType: string = this.props.itemType;
		const contentData: any = this.props.radioContentData;
		const disabled: any = this.props.disabled;
		return CheckMarkRadioContent.getContent(itemType, contentData, disabled);
	}
	// --------------------------------------------------------------------------
}