import { WprBaseViewModeInfo } from '../../../../../../wpr-framework/mode/WprBaseViewMode';
import { WprBaseViewMultiModeInfo } from '../../../../../../wpr-framework/mode/WprBaseViewMultiMode';
import { WprMultiMode } from '../../../../../../wpr-framework/mode/WprMultiMode';
import { UsagePDFDownloadDialogView } from '../UsagePDFDownloadDialogView';

/**
 * PDF表示モード
 */
export class PDFDialogDisplayMode extends WprBaseViewMultiModeInfo<UsagePDFDownloadDialogView> {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() {
		super('PDFDialogDisplayMode');
	}
	// --------------------------------------------------------------------------
	
	// override メソッド  --------------------------------------------------------
	/**
	 * モードリスト取得（この画面モードで使用する全てのモードを定義する）
	 * @param list モードリスト
	 */
	public useModeList(list: string[]): void {
		list.push('一覧');
		list.push('詳細');
		list.push('ダウンロード可');
	}

	/**
	 * フィールド定義を登録処理
	 */
	public onRegisterControl(): void {
		const toMonthList = {
			enabled: [ {mode: '一覧', value: true}, {mode: 'other', value: false} ],
			class: [ { mode: '詳細', class: 'fieldset' }, { mode: 'other', class: '' } ]
		};
		const download = {
			enabled: [ {mode: 'ダウンロード可', value: true}, {mode: 'other', value: false} ]
		}
		this.addControl('toMonthSelect', toMonthList);
		this.addControl('pdfDownload', download);
	}

	/**
	 * 画面の状態をチェックしてモードを変更する
	 * @param curMode 現在のモード
	 * @param newMode 新しいモード
	 */
	public onCheckMultiMode(curMode: WprMultiMode, newMode: WprMultiMode): void {
		newMode.addMode(this.getViewMode('toMonthList'));
		newMode.addMode(this.getViewMode('download'));
	}
	// --------------------------------------------------------------------------
}
