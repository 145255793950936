import { WprBaseViewInfo } from '../../../../wpr-framework/view/WprBaseViewInfo';
import { MypageBaseView } from '../../base/MypageBaseView';

/**
 * セッションエラー
 */
export class S_01_005View extends MypageBaseView {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() { super('S_01_005'); }
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * コントロール定義設定
	 */
	public onRegisterConfig(): void {
	}
	/**
	 * アクション登録
	 */
	 public onRegisterAction(): void {
		this.addAction('b_MyPAGE_Top', this.b_MyPAGE_Top);
	}

	/**
	 * ビュー表示通知
	 */
	public onShowView(): void {
		this.registAccessLog();
		this.setStorageItem('b_url', window.location.href);
	}
	// --------------------------------------------------------------------------

	// アクション  ---------------------------------------------------------------
	/**
	 * PDF
	 */
	 public b_MyPAGE_Top(): void {
		// location.href = "https://119.224.199.206/Top/My_top/";
		location.href = this.getOldUrl() + "Top/My_top/";
	}
	// --------------------------------------------------------------------------
}
