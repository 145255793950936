import { WprBaseViewInfo } from './WprBaseViewInfo';

/**
 * オーバーライドチェック用ビュー情報
 */
export class WprOverrideCheckView extends WprBaseViewInfo {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() {
		super('オーバーライドチェック用ビュー情報');
	}
	// --------------------------------------------------------------------------
}