// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@charset \"UTF-8\";\n/*\r\n * 契約内容の確認用CSS\r\n */\n/*=================== 変数管理ファイル =================== */\n/* ------- 色 ------- */\n/* ------- width ------- */\n.S_01_004-comment {\n  font-style: normal;\n  font-weight: 500;\n  font-size: 14px;\n  line-height: 21px; }\n\n {\n  /* PDFダイアログ */ }\n  .pdfDialog .MuiDialog-scrollPaper .MuiDialog-paperWidthSm {\n    max-width: 1000px; }\n  .pdfDialog .MuiDialog-scrollPaper .MuiDialog-paper {\n    width: 1000px;\n    height: 95%;\n    margin: 5px; }\n    @media screen and (max-width: 600px) {\n      .pdfDialog .MuiDialog-scrollPaper .MuiDialog-paper .MuiDialogTitle-root {\n        padding: 6px 24px; }\n        .pdfDialog .MuiDialog-scrollPaper .MuiDialog-paper .MuiDialogTitle-root .MuiIconButton-root {\n          padding: 4px; }\n        .pdfDialog .MuiDialog-scrollPaper .MuiDialog-paper .MuiDialogTitle-root .MuiTypography-subtitle1 {\n          font-size: 12px; } }\n    @media screen and (min-width: 320px) {\n      .pdfDialog .MuiDialog-scrollPaper .MuiDialog-paper .MuiDialogTitle-root {\n        padding: 3px 24px 0px; }\n        .pdfDialog .MuiDialog-scrollPaper .MuiDialog-paper .MuiDialogTitle-root .MuiIconButton-root {\n          padding: 1px; } }\n    .pdfDialog .MuiDialog-scrollPaper .MuiDialog-paper .reactPdf {\n      width: 100%;\n      height: 100%;\n      overflow-y: scroll;\n      overflow-x: hidden; }\n      .pdfDialog .MuiDialog-scrollPaper .MuiDialog-paper .reactPdf .react-pdf__Page__canvas {\n        margin: 0 auto; }\n    .pdfDialog .MuiDialog-scrollPaper .MuiDialog-paper .MuiDialogContent-dividers {\n      padding: 0; }\n", ""]);
// Exports
exports.locals = {
	"comment": "S_01_004-comment"
};
module.exports = exports;
