import React, { ReactNode } from 'react';
import { WprBaseViewComponent } from '../../../../../wpr-framework/view/component/WprBaseViewComponent';
import { ModOthernetConfirmView } from './ModOthernetConfirmView';
import { MatTypography } from '../../../../../wpr-framework/component/controls/material/MatTypography';
import { Divider, Grid, Typography } from '@material-ui/core';
import { WprSpan } from '../../../../../wpr-framework/component/controls/WprSpan';
import { MatCheckBox } from '../../../../../wpr-framework/component/controls/material/MatCheckBox';
import { MatButton } from '../../../../../wpr-framework/component/controls/material/MatButton';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { ProgressBar } from '../../../common/progress_bar/ProgressBar';
import netIcon from '/shared/image/common/logo_iTSCOMNET.png';
import styles from './ModOthernetConfirm.scss';

/**
 * ネットその他サービス確認用コンポーネント
 */
export class ModOthernetConfirm extends WprBaseViewComponent {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: any) { super(new ModOthernetConfirmView(), props); }
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** ネットその他サービス確認ビュー情報 */
	public get viewInfo(): ModOthernetConfirmView	{ return this.view as ModOthernetConfirmView;	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * スタイルオブジェクト取得
	 */
	public getStylesObject(): any { return styles; }

	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		return (
			<>
			<div className='pageCard agree_button' >
						<div className="pageHeader stepFlow">
							<ProgressBar name='progressBar' view={this.view}/>
						</div>
							<div className='main message' style={{paddingTop:'10px'}}>
								<div className='pageTitle'>
									<div style={{textAlign:'right'}}>
										<img src={netIcon}></img>
									</div>
									<div className='title_left'>
									<MatTypography name='title' className='title_text' view={this.view} />
										<Typography className='title_comment'>以下の内容でよろしければ、「申込む」ボタンをクリックしてください。</Typography>
									</div>
								</div>
								<div className='title_space'>
									<div className='itemTitle title_maru'>追加申込み内容</div>
								</div>
								<div className='feeTable'>
									<Grid container className='feeRow table'>
										<Grid item xs={12} md={4} className='feeTitle'>
											<div>申込みコース</div>
										</Grid>
										<Grid item xs={12} md={8} className='feeVal courseValue' style={{ color: 'black' }}>
										<WprSpan name={'cDispnamep'} className='itemUnit' view={this.view} style={{ color: '#484848', fontWeight: '550', fontsize: '40spx' }}></WprSpan>
										</Grid>
									</Grid>
								</div>
								<div className='box_gray'>
									<div className='boxContent'>
										<div className='title_maru'>契約者情報の第三者提供に関する同意</div>
											<div>
												 <p className='comment'>
												 	<div className='title_number'>１．契約者情報を提供・開示する第三者</div>
													<div className='text'>
															（1）「かっとび光」をご契約いただく場合
														<br/>「かっとび光」における回線事業者としての東日本電信電話株式会社（以下「NTT東日本」といいます。）
														<br/>（2）イッツコムからNTT東日本または他社光コラボ事業者へ事業者変更する場合
														<br/>事業者変更先となる、NTT東日本および光コラボレーションモデルに関する契約を締結している電気通信事業者
														<br/>（3）NTT東日本の回線を利用した他社サービスの提供を受ける場合
														<br/>NTT東日本の協定事業者、特定事業者、その他関連サービス提供事業者等
													</div>

													<div className='title_number'>2．提供・開示する契約者情報</div>
													<div className='text'>契約ID、契約者名、契約者住所（機器設置場所住所）、契約者連絡用電話番号、ご利用サービス品目など</div>
													
													<div className='title_number'>3.提供・開示の手段および方法</div>
													<div className='text'>イッツコムとの契約時にNTT東日本管轄のネットワークシステムに登録したデータをNTT東日本の認証システムを介して提供 </div>
													
													<div className='title_number'>4．提供・開示を受けた第三者における利用目的</div>
													<div className='text'>新規申し込み、サービス品目変更、不具合発生時の対応、サービス保守、解約、転用、事業者変更の手続き、およびNTT東日本関連サービスの提供に伴う手続き</div>

												</p>
											</div>
										</div>
									</div>
								</div>
								<div className='title_center'>
									<MatCheckBox name='confirm' label='同意する' view={this.view} />
								</div>
						<Divider style={{ marginTop: '60px' }}/>
						<div className='pageFooter footer-space'>
							<div>
								<MatButton name='next' view={this.view} className='orangeButton orangeButton_parts' >
									この内容で申込む
									<NavigateNextIcon />
								</MatButton>
							</div>
							<div>
								<MatButton name='back' view={this.view} className='backButton' >
									<NavigateBeforeIcon />
									戻る
								</MatButton>
							</div>
						</div>

					</div>
			</>
		);
	}
	// --------------------------------------------------------------------------
}
