import { WprScopeModelDIInfo } from './WprScopeModelDIInfo';

/**
 * DI待ちモデル情報
 */
export class WprWaitModelDIInfo {
	// private 変数  ------------------------------------------------------------
	private m_DIInfo: WprScopeModelDIInfo	= null;		// スコープモデルDI情報
	private m_PropName: string				= null;		// プロパティ名
	private m_Value: any					= null;		// プロパティ値
	// --------------------------------------------------------------------------

	// コンストラクタ	----------------------------------------------------------
	constructor(diInfo: WprScopeModelDIInfo, propName: string, value: any) {
		this.m_DIInfo = diInfo;
		this.m_PropName = propName;
		this.m_Value = value;
	}
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** スコープモデルDI情報 */
	public get diInfo(): WprScopeModelDIInfo 	{ return this.m_DIInfo; 	}
	/** プロパティ名 */
	public get propName(): string 				{ return this.m_PropName; 	}
	/** スコープモデルDI情報 */
	public get value(): any 					{ return this.m_Value; 		}
	// --------------------------------------------------------------------------
}