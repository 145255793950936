import { Application } from '../../apimodels/fuse/soap/Application';

/**
 * 詳細取得リクエスト
 */
export class GetConfirmDetailRequest {
	// public 変数	-----------------------------------------------------------
	public application: Application;	// 
	public customerId: string;			// 
	// ------------------------------------------------------------------------
}
