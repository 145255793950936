import { Option } from "../../bss/masterdata/Option";

/**
 * テレビオプションリストモデル
 */
export class TvOptionListModel {
	// public 変数	-----------------------------------------------------------
	public select: boolean;		// 選択
	public option: string;		// オプション
	public channel: string;		// チャンネル
	public amount: string;		// 月額
	public status: string;		// ステータス
	public statusCd: string;	// ステータス
	public applyDate: string;	// 契約区分(翌月、翌日)
	public detail: Option;		// オプション詳細
	public wowwowFlg: boolean;	// wowwowフラグ
	public tunerFlg: boolean;	// チューナーフラグ
	public royalFlg: boolean;	// ロイヤル会員フラグ
	public contractType: string;// 契約変更区分
	// ------------------------------------------------------------------------
}
