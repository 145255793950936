import { WprBaseViewMultiModeInfo } from '../../../../../../wpr-framework/mode/WprBaseViewMultiMode';
import { WprMultiMode } from '../../../../../../wpr-framework/mode/WprMultiMode';
import { ModNetSettingView } from '../ModNetSettingView';
import { WprDI_Control } from '../../../../../../wpr-framework/view/di/WprDI_Control';
import { WprControlInfo } from '../../../../../../wpr-framework/view/control/WprControlInfo';
import { WprDI_Object } from '../../../../../../wpr-framework/view/di/WprDI_Object';
import { Customer } from '../../../../../models/bss/customer/Customer';
import { CourseListModel } from '../../../../../models/mypage/net/CourseListModel';
import { ObjectUtil } from '../../../../../common/util/ObjectUtil';

/**
 * 入力確認モード
 */
export class InputConfirmMode extends WprBaseViewMultiModeInfo<ModNetSettingView> {
	// DI情報  ------------------------------------------------------------------
	@WprDI_Control
	private m_cable: WprControlInfo	= null;	// ケーブルモデム
	@WprDI_Control
	private m_cableWifi: WprControlInfo	= null;	// ケーブルモデムwifi
	@WprDI_Control
	private m_noChangeCable: WprControlInfo	= null;	// ケーブルモデム変更なし
	@WprDI_Control
	private m_equipmentProvidingCheck: WprControlInfo	= null;	// 機器のご提供方法
	@WprDI_Control
	private m_buyCheck: WprControlInfo	= null;	// 機器のご提供方法
	@WprDI_Control
	private m_noChange: WprControlInfo	= null;	// 機器のご提供方法
	@WprDI_Control
	private m_desiredDate1: WprControlInfo	= null;	// 第一希望
	@WprDI_Control
	private m_selectTime1: WprControlInfo	= null;	// 第一希望　時間帯
	@WprDI_Control
	private m_desiredDate2: WprControlInfo	= null;	// 第二希望
	@WprDI_Control
	private m_selectTime2: WprControlInfo	= null;	// 第二希望　時間帯
	@WprDI_Control
	private m_desiredDate3: WprControlInfo	= null;	// 第三希望
	@WprDI_Control
	private m_selectTime3: WprControlInfo	= null;	// 第三希望　時間帯
	@WprDI_Control
	private m_beforeContact: WprControlInfo	= null;	// 前日連絡先
	@WprDI_Control
	private m_sameDayContact: WprControlInfo	= null;	// 当日連絡先
	@WprDI_Control
	private m_name: WprControlInfo	= null;	// 当日立ち合い者
	@WprDI_Object
	private m_Customer: Customer	= null;	// 顧客情報
	@WprDI_Object
	private m_NetInfo: CourseListModel	= null;	// ネット画面情報
	@WprDI_Control
	private m_securityFlg: WprControlInfo	= null;	// セキュリティフラグ
	@WprDI_Control
	private m_securityComment: WprControlInfo	= null;	// セキュリティフリー入力
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	public constructor() {
		super('InputConfirmMode');
	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * モードリスト取得（この画面モードで使用する全てのモードを定義する）
	 * @param list モードリスト
	 */
	public useModeList(list: string[]): void {
		list.push('入力済み');
		list.push('未入力');
		list.push('入力不要');
		list.push('入力必須');
	}

	/**
	 * フィールド定義を登録処理
	 */
	public onRegisterControl(): void {
		this.addControl('next', { enabled: [ { mode: '入力済み|入力不要', value: true }, { mode: '未入力', value: false } ] });
		this.addControl('selectTime1', { hissu: [ { mode: '入力必須', value: true }, { mode: 'other', value: false } ] });
		this.addControl('selectTime2', { hissu: [ { mode: '入力必須', value: true }, { mode: 'other', value: false } ] });
		this.addControl('selectTime3', { hissu: [ { mode: '入力必須', value: true }, { mode: 'other', value: false } ] });
	}

	/**
	 * 画面の状態をチェックしてモードを変更する
	 * @param curMode 現在のモード
	 * @param newMode 新しいモード
	 */
	public onCheckMultiMode(curMode: WprMultiMode, newMode: WprMultiMode): void {
		let flg = false;
		if (this.getViewMode('端末の種類') === '全種') {
			// 変更前[ケーブルモデム]　かつ　変更後[ケーブルモデムWi-Fi]
			if (this.m_NetInfo.befCableFlg && this.m_cableWifi.value)
			    flg = true;
			// 変更前[ケーブルモデムWi-Fi]　かつ　変更後[ケーブルモデム]
			if (this.m_NetInfo.befCableWifiFlg && this.m_cable.value)
			    flg = true;
			// 変更前がモデム以外の場合
			if (!this.m_NetInfo.befCableFlg && !this.m_NetInfo.befCableWifiFlg && (this.m_cable.value || this.m_cableWifi.value))
				flg = true;
			// 変更前[レンタル]以外　かつ　変更後[レンタル]
			if (this.m_NetInfo.deviceForm !== 'レンタル' && this.m_equipmentProvidingCheck.value)
			    flg = true;
		}
		// 変更前[機器なしコース] かつ　変更後[機器ありコース]
		if (this.getViewMode('端末の種類') === '変更なし非表示')
			flg = true;
		// FTTHコースの場合、機器の提供方法が変更になる場合
		if (ObjectUtil.isNullOrUndefined(this.getViewMode('端末の種類'))) {
			if (this.m_NetInfo.deviceForm !== 'レンタル' && this.m_equipmentProvidingCheck.value)
				flg = true;
		}
		if (flg) {
			this.setViewMode('希望日時', '表示')
			newMode.addMode('入力必須');
			// 入力チェック
			if (!this.m_desiredDate1.value || !this.m_selectTime1.value || !this.m_desiredDate2.value ||
				!this.m_selectTime2.value || !this.m_desiredDate3.value || !this.m_selectTime3.value ||
				!this.m_beforeContact.value || !this.m_sameDayContact || !this.m_name.value)
					newMode.addMode('未入力');
			else {
				if (this.m_securityFlg.value && !this.m_securityComment.value)
					newMode.addMode('未入力');
				else
					newMode.addMode('入力済み');
			}
		}
		else {
			this.setViewMode('希望日時', '非表示')
			newMode.addMode('入力不要');
		}
	}
	// --------------------------------------------------------------------------
}
