import { Card, Divider, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import { Grid } from '@mui/material';
import React, { ReactNode } from 'react';
import { WprBaseViewComponent } from '../../../../../wpr-framework/view/component/WprBaseViewComponent';
import { MvnoConfirmCapacityView } from './MvnoConfirmCapacityView';
import { MatButton } from '../../../../../wpr-framework/component/controls/material/MatButton';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import styles from './MvnoConfirmCapacity.scss';
import { MatTextField } from '../../../../../wpr-framework/component/controls/material/MatTextField';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { WprAnchor } from '../../../../../wpr-framework/component/controls/WprAnchor';
import { MatOneRowTable } from '../../../../../wpr-framework/component/controls/material/MatOneRowTable';
import { MatTypography } from '../../../../../wpr-framework/component/controls/material/MatTypography';
import { CouponModel } from '../../../../service/models/apimodels/CouponModel';
import { WprRowInfo } from '../../../../../wpr-framework/component/props/WprRowInfo';
import { WprInput } from '../../../../../wpr-framework/component/controls/WprInput';
import { WprContainer } from '../../../../../wpr-framework/component/controls/WprContainer';

/**
 * MVNお_データ容量確認用コンポーネント
 */
export class MvnoConfirmCapacity extends WprBaseViewComponent {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: any) { super(new MvnoConfirmCapacityView(), props); }
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** MVNお_データ容量確認ビュー情報 */
	public get viewInfo(): MvnoConfirmCapacityView { return this.view as MvnoConfirmCapacityView; }
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * スタイルオブジェクト取得
	*/
	public getStylesObject(): any { return styles; }

	/**
	 * リストコントロール定義登録
	*/
	protected onRegisterListRender(): void {
		this.addListRender('CouponList', null, this.onCouponListRender);
	}

	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		return (
			<>
				<div>
					<div className='pageCard'>
						<div className="pageHeader title">
							データ容量確認・追加申込
						</div>
						<Divider />
						<div className='pageContent'>
							<div>
								<div className='table_gray'>
									<Grid container className='tableRow'>
										<Grid item xs={12} md={4} className='rowTitle'>
											<div>回線番号</div>
										</Grid>
										<Grid item xs={12} md={8} className='rowContent'>
											<MatTypography name='phoneNumber' view={this.view} />
										</Grid>
									</Grid>
								</div>

								<div>
									<Table className='table_header_gray'>
										<colgroup>
											<col style={{ width: '50%' }} />{/* 有効期限 */}
											<col style={{ width: '50%' }} />{/* ご利用可能データ容量 */}
										</colgroup>
										<TableHead className='tableHeader'>
											<TableRow>
												<TableCell align='center'>
													<Typography>有効期限</Typography>
												</TableCell>
												<TableCell align='center'>
													<Typography>ご利用可能データ容量</Typography>
												</TableCell>
											</TableRow>
										</TableHead>
										<TableBody className='tableBody'>
											<WprContainer mode='クーポンリスト.有' view={this.view}>
												{this.renderList('CouponList')}
											</WprContainer>
											<WprContainer mode='クーポンリスト.無' view={this.view}>
												<TableRow >
													<TableCell colSpan={2} align='center'>
														<div>
															対象となるデータはございません。
														</div>
													</TableCell>
												</TableRow>
											</WprContainer>
										</TableBody>
									</Table>

								</div>

								<div className='comment'>
									<ul>
										<li>有効期限までご利用いただくことが可能なデータ容量を表示しております。</li>
										<li>「データ容量追加」オプションにより追加されたデータ容量は当月限り有効となります。</li>
									</ul>
								</div>
							</div>

							<div className='pageFooter'>
								<div>
									<MatButton name='next' view={this.view} className='nextButton' >
										「データ容量追加」<br />を申し込む
										<NavigateNextIcon />
									</MatButton>
								</div>
								<div>
									<MatButton name='back' view={this.view} className='backButton' >
										<NavigateBeforeIcon />
										戻る
									</MatButton>
								</div>
								<div  className='capaChangeLink'>
									<Typography>
										※毎月のプラン（データ容量）の変更は、
										<WprAnchor name='mvnoCapacityChangeLink' view={this.view}>こちら</WprAnchor>	
										より受付をいたします。
									</Typography>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}

	/**
	 * リスト名=CouponList
	 */
	public onCouponListRender(rowInfo: WprRowInfo): any {
		const data: CouponModel = rowInfo.rowData as CouponModel;

		return (

			<>
				<TableRow>
					<TableCell align='center'>
						<MatTypography name='expiration' view={this.view} row={rowInfo} />
					</TableCell>
					<TableCell align='center'>
						<MatTypography name='capacity' view={this.view} row={rowInfo} component='span' />
						<span>MB</span>
					</TableCell>
				</TableRow>
			</>

		);
	}
	// --------------------------------------------------------------------------
}
