/**
 * オプション変更登録リクエスト
 */
export class RegistTvOptionRequest {
	// public 変数	-----------------------------------------------------------
	public contractId: string;	// 契約ID
	public optionCd: string;	// オプションコード
	public startDate: string;	// 契約開始日
	public cancelDate: string;	// 解約日
	// ------------------------------------------------------------------------
}
