// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@charset \"UTF-8\";\n/*\r\n * 約款画面用CSS\r\n */\n.CommonAgree-hiddenClass {\n  display: none !important; }\n\n.CommonAgree-checked {\n  background-color: #6169B8 !important;\n  border: 2px solid #6169B8 !important; }\n  .CommonAgree-checked * {\n    color: #FFFFFF !important; }\n", ""]);
// Exports
exports.locals = {
	"hiddenClass": "CommonAgree-hiddenClass",
	"checked": "CommonAgree-checked"
};
module.exports = exports;
