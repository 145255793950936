import React, { ReactNode } from 'react';
import { WprBaseDialogViewComponent } from '../../../../../wpr-framework/view/component/WprBaseDialogViewComponent';
import { MvnoTmpSaveDialogView } from './MvnoTmpSaveDialogView';

/**
 * MVNO一時保存完了ダイアログ用コンポーネント
 */
export class MvnoTmpSaveDialog extends WprBaseDialogViewComponent {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: any) { super(new MvnoTmpSaveDialogView(), props); }
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** MVNO一時保存完了ダイアログビュー情報 */
	public get viewInfo(): MvnoTmpSaveDialogView	{ return this.view as MvnoTmpSaveDialogView;	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		return (
			<>
				<div style={{textAlign: 'center'}}>
					一時保存完了しました。<br/>
					期限は１か月です。
				</div>
			</>
		);
	}
	// --------------------------------------------------------------------------
}
