import { WprModel } from '../../../../wpr-framework/view/deco/WprModel';
import { WprDI_ScopeModel } from '../../../../wpr-framework/view/di/WprDI_ScopeModel';
import { MagazineModel } from '../../../service/models/apimodels/MagazineModel';
import { MypageBaseView } from '../../base/MypageBaseView';
import { CompleteModel } from '../../common/common_complete/models/CompleteModel';
import { MypageWizardMngr } from '../../wizard/models/MypageWizardMngr';

/**
 * マガジン申込完了画面
 */
export class MagazineApplyCompleteView extends MypageBaseView {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() { super('MagazineApplyCompleteView'); }
	// --------------------------------------------------------------------------
	@WprModel('CompleteModel')
	private m_CompleteModel: CompleteModel = null;	// 完了情報モデル
	@WprDI_ScopeModel('MypageWizardMngr')
	private m_MypageWizardMngr: MypageWizardMngr = null;		// 画面ウィザード管理クラス
	@WprModel('ServiceModel')
	private m_ServiceModel: string = null;	// コース
	private m_MagazineModel: MagazineModel = null;	// Itscomマガジンモデル
	// メンバ変数 ---------------------------------------------------------------
	private m_CustomerName: string = null;	// 顧客名
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------

	/**
 * コントロール定義設定
 */
	public onRegisterConfig(): void {
		this.addConfig('title_complete', 'タイトル', 'CompleteModel', { bindName: 'title' });
		this.addConfig('user', 'ユーザー', 'CompleteModel', { bindName: 'user' });
		this.addConfig('msg1', '本文1', 'CompleteModel', { bindName: 'massage1' });
		this.addConfig('msg2', '本文2', 'CompleteModel', { bindName: 'massage2' });
		this.addConfig('amount', '金額', 'ServiceModel', { bindModel: true, converter: '3桁カンマ' });
	}

	/**
 * アクション登録
 */
	public onRegisterAction(): void {
		this.addAction('toQuestionnaire', this.onToQuestionnaire);
		this.addAction('back', this.onBack);
	}

	/**
 * ビュー表示通知
 */
	public onShowView(): void {
		this.m_MypageWizardMngr.initView(this, (result) => {
			this.registAccessLog();
			this.setStorageItem('b_url', window.location.href)
			const customerInfo = this.m_MypageWizardMngr.getViewInfo().customer;
			this.m_MagazineModel = this.m_MypageWizardMngr.getCache().m_MagazineInfo
			if (customerInfo.sexCD === '3' || customerInfo.sexCD === '') {
				this.m_CustomerName = customerInfo.corporateName;
			} else {
				this.m_CustomerName = customerInfo.surName + ' ' + customerInfo.givenName;
			}

			if (this.m_MagazineModel.maxFlg === '1') {
				this.m_ServiceModel = '申込む　月額無料';
			} else {
				this.m_ServiceModel = '申込む　月額' + this.m_MagazineModel.amount + '円（税込）'
			}
			const model = new CompleteModel();
			model.user = this.m_CustomerName + 'さん';
			model.title = 'お申込みありがとうございます！';
			model.massage1 = 'お申込みいただき、誠にありがとうございます。<br/>iTSCOMより番組誌をお送りさせていただきます。';
			model.massage2 = '連絡先メールアドレスに確認メールを送信しました。';
			this.m_CompleteModel = model
			this.refreshView();
		});
	}
	// アクション  ---------------------------------------------------------------
	/**
	 * アンケート
	 */
	public onToQuestionnaire(param: any): void {
		window.open('https://questant.jp/q/B7KS5E4S?id=' + this.m_MypageWizardMngr.getParams().customerId);
	}

	/**
	 * 契約内容の確認・変更メニューへ
	 */
	public onBack(param: any): void {
		location.href = this.getOldUrl() + 'Srvlist/Continfo_list/';
	}
}
