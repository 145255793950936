import { WprUserLogInfo } from './error/WprUserLogInfo';
import { WprFramework } from './WprFramework';

/**
 * WPRフレームワーク基本クラス
 */
export abstract class WprBaseObject {
	// private 変数  ------------------------------------------------------------
	private m_Framework: WprFramework		= null;			// フレームワーク
	private m_ObjectName: string			= null;			// オブジェクト名
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** フレームワーク */
	public get FW(): WprFramework	{
		if (this.m_Framework == null)
			this.m_Framework = WprFramework.getInstance();
		return this.m_Framework;
	}
	/** オブジェクト名 */
	public get objectName(): string	{ return this.m_ObjectName;	}
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	public constructor(objectName?: string) {
		if (objectName)
			this.m_ObjectName = objectName;
		else
			this.m_ObjectName = 'NoName';
	}
	// --------------------------------------------------------------------------

	// public メソッド	---------------------------------------------------------
	/**
	 * グローバルスコープモデル取得
	 * @param name グローバルスコープ名 
	 * @returns グローバルスコープモデル
	 */
	public getGlobalScopeModel(name: string): object {
		return this.FW.scope.getGlobalScopeModel(name);
	}

	/**
	 * でバックログ出力
	 * @param msg メッセージ
	 */
	public addDebugLog(msg: string): void {
		this.FW.error.addDebugLog(this.m_ObjectName, msg);
	}

	/**
	 * 情報ログ出力
	 * @param msg メッセージ
	 */
	public addInfoLog(msg: string): void {
		this.FW.error.addInfoLog(this.m_ObjectName, msg);
	}

	/**
	 * 警告ログ出力
	 * @param msg メッセージ
	 */
	public addWarningLog(msg: string): void {
		this.FW.error.addWarningLog(this.m_ObjectName, msg);
	}

	/**
	 * エラーログ出力
	 * @param msg メッセージ
	 * @param ex 例外情報
	 */
	public addErrorLog(msg: string, ex?: Error): void {
		this.FW.error.addErrorLog(this.m_ObjectName, msg, ex);
	}

	/**
	 * ユーザ指定ログ出力
	 * @param msg メッセージ
	 */
	public addUserLog(msg: string): void {
		this.FW.error.addUserLog(this.m_ObjectName, msg);
	}

	/**
	 * ユーザログ情報リスト取得
	 * @returns ユーザログ情報リスト
	 */
	public getUserLogList(): WprUserLogInfo[] {
		return this.FW.error.getUserLogList();
	}

	/**
	 * ユーザログ情報リスト取得
	 * @returns ユーザログ情報リスト
	 */
	public clearUserLog(): void {
		this.FW.error.clearUserLog();
	}
	// --------------------------------------------------------------------------
}
