// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@charset \"UTF-8\";\n/*\r\n * 名義変更受付完了用CSS\r\n */\n.NameChangeComplete-subText {\n  text-align: center;\n  margin: 2rem; }\n\n.NameChangeComplete-grayBlock {\n  background: #F5F5FA;\n  border-radius: 12px;\n  margin: 1rem 3rem;\n  padding: 2rem; }\n  @media screen and (max-width: 700px) {\n    .NameChangeComplete-grayBlock {\n      margin: 1rem 0; } }\n  .NameChangeComplete-grayBlock .NameChangeComplete-grayBlockTitle {\n    font-weight: bold; }\n  .NameChangeComplete-grayBlock .NameChangeComplete-grayBlockInfo {\n    margin-top: 1rem;\n    margin-left: 10px; }\n", ""]);
// Exports
exports.locals = {
	"subText": "NameChangeComplete-subText",
	"grayBlock": "NameChangeComplete-grayBlock",
	"grayBlockTitle": "NameChangeComplete-grayBlockTitle",
	"grayBlockInfo": "NameChangeComplete-grayBlockInfo"
};
module.exports = exports;
