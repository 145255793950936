import { WprModel } from '../../../../../wpr-framework/view/deco/WprModel';
import { WprDI_ScopeModel } from '../../../../../wpr-framework/view/di/WprDI_ScopeModel';
import { HMvnoTmpSaved } from '../../../../service/models/entity/HMvnoTmpSaved';
import { MypageBaseView } from '../../../base/MypageBaseView';
import { MvnoService } from '../../../../service/MvnoService';
import { UpdateHMvnoTmpSavedRequest } from '../../../../service/models/dto/mvno/UpdateHMvnoTmpSavedRequest';
import { MvnoEntryInfo } from '../../../../service/models/apimodels/MvnoEntryInfo';
import { MvnoFeeDialogSendData } from '../../dialog/mvno_fee_dialog/MvnoFeeDialogView';
import { WprDI_Object } from '../../../../../wpr-framework/view/di/WprDI_Object';

export class MvnoEntryFooterReceiveData {
	public viewNmae: string;	// 画面名
}
/**
 * MVNO申込_フッター（一時保存・合計）
 */
export class MvnoEntryFooterView extends MypageBaseView {
	// コンストラクタ  -----------------------------------------------------------s
	public constructor() { super('MvnoEntryFooter'); }
	// --------------------------------------------------------------------------

	// サービス  ----------------------------------------------------------------
	private m_MvnoService: MvnoService	= new MvnoService();	// MyPageRenewalサービス
	// --------------------------------------------------------------------------
	// @WprDI_Object
	@WprDI_ScopeModel('MvnoEntryInfo')
	@WprModel('MvnoEntryInfo')
	private m_EntryInfo: MvnoEntryInfo	= null;	// MVNO申込情報

	// override メソッド  --------------------------------------------------------
	/**
	 * コントロール定義設定
	 */
	public onRegisterConfig(): void {
		this.addConfig('total', '変更前合計金額（税込）', 'MvnoEntryInfo', { converter: '3桁カンマ'});
	}

	/**
	 * アクション登録
	 */
	public onRegisterAction(): void {
		this.addAction('tempSave', this.onTempSave);
		this.addAction('feeDetail', this.onFeeDetail);
	}

	/**
	 * ビュー表示通知
	 */
	public onShowView(): void {
		this.registAccessLog();
		this.setStorageItem('b_url', window.location.href);
		setTimeout(() => {
			this.setFotterWidth();
		}, 350);
		this.refreshView();
	}	
	
	/**
	 * データ受信処理
	 * @param dataName データ名
	 * @param data データ
	 */
	public onReceiveData(dataName: string, data?: any): void {
		if (dataName === 'fP_ev_料金明細表示') {
			const receiveData: MvnoEntryFooterReceiveData = data as MvnoEntryFooterReceiveData;
			const req: UpdateHMvnoTmpSavedRequest = new UpdateHMvnoTmpSavedRequest();
			req.detail = this.m_EntryInfo.entryInfo;
			req.detail.saveViewName = receiveData.viewNmae;
			req.detail.customerId = this.getCustomerId();
			this.m_MvnoService.updateHMvnoTmpSaved(this, req, (result) => {
				// this.showInfoMessage('一時保存完了しました。期限は1か月です。');
				this.showDialog('MVNO一時保存完了ダイアログ');
			});
		}
		else if (dataName === 'fP_ev_料金シミュレーション取得') {
			this.setCookie('total', this.m_EntryInfo.total);
			this.refreshView();
		}
	}

	public onResizeWindow(width: number, height: number): void {
		this.setFotterWidth();
	}

	// /**
	//  * WprDI_Objectの設定通知
	//  * @param name WprDI_Objectメンバ名
	//  * @param obj 設定値
	//  */
	// public onSetDIObject(name: string, obj: any) {
	// 	this.refreshView();
	// }
	// --------------------------------------------------------------------------

	// アクション  ---------------------------------------------------------------
	/**
	 * 現在の内容を一時保存する
	 */
	public onTempSave(param: any): void {
		this.sendParentData('fC_ev_料金明細表示');
		
	}

	/**
	 * 明細
	 */
	public onFeeDetail(param: any): void {
		const sendData: MvnoFeeDialogSendData = new MvnoFeeDialogSendData();
		sendData.entryInfo  = this.m_EntryInfo;
		this.showDialog('MVNO_料金明細ダイアログ', sendData);
	}
	// --------------------------------------------------------------------------

	/**
	 * フッターの幅を設定
	 */
	private setFotterWidth(): void {
		const cardwidth: number = document.getElementsByClassName('pageCard')[0]?.getBoundingClientRect().width;
		document.getElementById('mvnoFooter').style.width = cardwidth + 'px';
	}
}
