// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@charset \"UTF-8\";\n/*\r\n * 契約一覧用CSS\r\n */\n/*=================== 変数管理ファイル =================== */\n/* ------- 色 ------- */\n/* ------- width ------- */\n.S_01_003-contentText {\n  font-style: normal;\n  font-weight: 500;\n  font-size: 14px;\n  line-height: 160%;\n  color: #000000; }\n\n.S_01_003-nextButtonSub {\n  width: 340px; }\n  .S_01_003-nextButtonSub .MuiSvgIcon-root {\n    left: 15px;\n    position: absolute; }\n\n.S_01_003-pdfButton {\n  background-color: #F87619 !important;\n  border-radius: 37px !important;\n  color: #FFFFFF !important;\n  height: 24px;\n  width: 82px; }\n\n@media screen and (max-width: 700px) {\n  .pageFooter.confirmFooter {\n    padding: 1rem 0; }\n  .pageCard .pageFooter.confirmFooter .nextButton {\n    width: 100%; } }\n", ""]);
// Exports
exports.locals = {
	"contentText": "S_01_003-contentText",
	"nextButtonSub": "S_01_003-nextButtonSub",
	"pdfButton": "S_01_003-pdfButton"
};
module.exports = exports;
