import React, { ReactNode } from 'react';
import { WprBaseViewComponent } from '../../../../wpr-framework/view/component/WprBaseViewComponent';
import { RelocateComfirmView } from './RelocateComfirmView';
import { Typography, Grid, Divider } from '@material-ui/core';
import { WprContainer } from '../../../../wpr-framework/component/controls/WprContainer';
import { MatButton } from '../../../../wpr-framework/component/controls/material/MatButton';
import { MatSelect } from '../../../../wpr-framework/component/controls/material/MatSelect';
import { MatTypography } from '../../../../wpr-framework/component/controls/material/MatTypography';
import { ProgressBar } from '../../common/progress_bar/ProgressBar';
import { MatTextField } from '../../../../wpr-framework/component/controls/material/MatTextField';
import { WprRowInfo } from '../../../../wpr-framework/component/props/WprRowInfo';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import styles from './RelocateComfirm.scss';
import importantIcon from '../../../../../shared/image/common/important_purple.png';

/**
 * 移設申込確認画面用コンポーネント
 */
export class RelocateComfirm extends WprBaseViewComponent {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: any) { super(new RelocateComfirmView(), props); }
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** 移設申込確認画面ビュー情報 */
	public get viewInfo(): RelocateComfirmView	{ return this.view as RelocateComfirmView;	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * スタイルオブジェクト取得
	*/
	public getStylesObject(): any { return styles; }

	/**
	 * リストコントロール定義登録
	*/
	protected onRegisterListRender(): void {
		this.addListRender('TvListModel', null, this.onTvListModelRender);
		this.addListRender('NetListModel', null, this.onNetListModelRender);
		this.addListRender('PhoneListModel', null, this.onPhoneListModelRender);
	}

	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		return (
			<>
			<div className='pageCard'>
				<div className='pageHeader stepFlow'>
					<ProgressBar name='progressBar' view={this.view} />
				</div>
				<div className='main'>
					<div className='pageTitle'>
						<div className='title_left'>
							<MatTypography name='title' className='title_text' view={this.view} />
						</div>
					</div>
					{/* テレビ */}
					<WprContainer mode={'テレビ.表示'} view={this.view}>
						<div className='serviceGroup'>
							<div className='title_space'>
								<div className='itemTitle title_maru'>テレビ</div>
							</div>
							{this.renderList('TvListModel')}
						</div>
					</WprContainer>
					{/* ネット */}
					<WprContainer mode={'ネット.表示'} view={this.view}>
						<div className='serviceGroup'>
							<div className='title_space'>
								<div className='itemTitle title_maru'>インターネット</div>
							</div>
							{this.renderList('NetListModel')}
						</div>
					</WprContainer>
					{/* 電話 */}
					<WprContainer mode={'電話.表示'} view={this.view}>
						<div className='serviceGroup'>
							<div className='title_space'>
								<div className='itemTitle title_maru'>ケーブルプラス電話</div>
							</div>
							{this.renderList('PhoneListModel')}
						</div>	
					</WprContainer>
					<div className='title_space'>
						<div className='itemTitle title_maru'>作業の希望日時</div>
					</div>
					<div className='feeTable'>
						<Grid container className='feeRow'>
							<Grid item xs={12} md={4} className='feeTitle'>
								<div>第一希望</div>
							</Grid>
							<Grid item xs={12} md={8} className='feeVal courseValue value'>
								<MatTypography name='desiredDate1' view={this.view} className='itemUnit' style={{marginRight: '20px'}}/>
								<MatSelect name='selectTime1' vm='時間帯' view={this.view} className='itemSelect'/>
							</Grid>
						</Grid>	
						<Grid container className='feeRow'>
							<Grid item xs={12} md={4} className='feeTitle'>
								<div>第二希望</div>
							</Grid>
							<Grid item xs={12} md={8} className='feeVal courseValue value'>
								<MatTypography name='desiredDate2' view={this.view} className='itemUnit' style={{marginRight: '20px'}}/>
								<MatSelect name='selectTime2' vm='時間帯' view={this.view} className='itemSelect'/>
							</Grid>
						</Grid>	
						<Grid container className='feeRow'>
							<Grid item xs={12} md={4} className='feeTitle'>
								<div>第三希望</div>
							</Grid>
							<Grid item xs={12} md={8} className='feeVal courseValue value'>
								<MatTypography name='desiredDate3' view={this.view} className='itemUnit' style={{marginRight: '20px'}}/>
								<MatSelect name='selectTime3' vm='時間帯' view={this.view} className='itemSelect'/>
							</Grid>
						</Grid>	
					</div>
					<div className='title_space'>
						<div className='itemTitle title_maru'>工事費</div>
					</div>
					<WprContainer mode={'金額.表示'} view={this.view}>
						<div className='feeTable'>	
							<Grid container className='feeRow'>
								<Grid item xs={12} md={4} className='feeTitle'>
									<div>工事費</div>
								</Grid>
								<Grid item xs={12} md={8} className='feeVal courseValue'>
									<MatTypography name='amount' view={this.view} className='itemUnit'/>
									<span className='itemUnit'>円</span>
								</Grid>
							</Grid>
						</div>
					</WprContainer>
					<WprContainer mode={'金額.非表示'} view={this.view}>
						<div className='feeTable'>	
							<Grid container className='feeRow'>
								<Grid item xs={12} md={4} className='feeTitle'>
									<div>工事費</div>
								</Grid>
								<Grid item xs={12} md={8} className='feeVal courseValue'>
									<span className='itemUnit'>お見積り</span>
								</Grid>
							</Grid>
						</div>
					</WprContainer>
					<WprContainer mode={'とことんサポート.表示'} view={this.view}>
						<div className='title_space'>
							<div className='itemTitle title_maru'>とことんサポート</div>
						</div>
						<div className='feeTable'>	
							<Grid container className='feeRow'>
								<Grid item xs={12} md={4} className='feeTitle'>
									<div>とことんサポート</div>
								</Grid>
								<Grid item xs={12} md={8} className='feeVal courseValue'>
									<MatTypography name={'tokosapoFee'} view={this.view} className='itemUnit'></MatTypography>
									{/* <span className='itemUnit'>月額：550円</span> */}
								</Grid>
							</Grid>
						</div>
						<div className='gray'>
							<div className='flex'>
								<img src= {importantIcon} style={{width: '15px'}}></img>
								<Typography className='title'>ご請求について</Typography> 
							</div>
							<div>
								利用開始日は機器移動の工事設置日<br/>
								とことんサポート利用開始日の翌月分より請求が発生いたします。
							</div>
						</div>
					</WprContainer>
					<div className='feeTable inputArea' style={{marginTop: '30px'}}>	
						<Grid container className='feeRow'>
							<Grid item xs={12} md={4} className='feeTitle'>
								<div>前日連絡先</div>
							</Grid>
							<Grid item xs={12} md={8} className='feeVal courseValue'>
								<MatTypography name='beforeContact' className='itemUnit' view={this.view} />
							</Grid>
						</Grid>	
						<Grid container className='feeRow'>
							<Grid item xs={12} md={4} className='feeTitle'>
								<div>当日連絡先</div>
							</Grid>
							<Grid item xs={12} md={8} className='feeVal courseValue'>
								<MatTypography name='sameDayContact' className='itemUnit' view={this.view} />
							</Grid>
						</Grid>
						<Grid container className='feeRow'>
							<Grid item xs={12} md={4} className='feeTitle'>
								<div>当日立ち合い者</div>
							</Grid>
							<Grid item xs={12} md={8} className='feeVal courseValue'>
								<MatTypography name='name' className='itemUnit' view={this.view} />
							</Grid>
						</Grid>	
						<Grid container className='feeRow'>
							<Grid item xs={12} md={4} className='feeTitle'>
								<div>リフォーム</div>
							</Grid>
							<Grid item xs={12} md={8} className='feeVal courseValue'>
								<MatTypography name='reformDisp' view={this.view} className='itemUnit'/>
							</Grid>
						</Grid>
					</div>
					<Divider />
					<div className='pageFooter footer-space'>
						<div>
							<MatButton name='next' view={this.view} className='orangeButton orangeButton_parts'>
								この内容で申込む
								<NavigateNextIcon />
							</MatButton>
						</div>
						<div>
							<MatButton name='back' view={this.view} className='backButton'>
								<NavigateBeforeIcon />
								戻る
							</MatButton>
						</div>
					</div>
				</div>
			</div>
			</>
		);
	}
	// --------------------------------------------------------------------------

	// リスト描画  ---------------------------------------------------------------
	/**
	 * リスト名=TvListModel
	 */
	public onTvListModelRender(rowInfo: WprRowInfo): any {
		return (
			<>
			<div className='feeTable'>
				<Grid container className='feeRow'>
					<Grid item xs={12} md={4} className='feeTitle'>
						<div>ご利用中のサービス</div>
					</Grid>
					<Grid item xs={12} md={8} className='feeVal courseValue'>
						<MatTypography name='no' row={rowInfo} view={this.view} className='noStyle'/>
						<MatTypography name='serviceInfo' row={rowInfo} view={this.view} className='itemUnit'/>
					</Grid>
				</Grid>	
				<Grid container className='feeRow'>
					<Grid item xs={12} md={4} className='feeTitle'>
						<div>希望場所</div>
					</Grid>
					<Grid item xs={12} md={8} className='feeVal courseValue'>
						<div>
							<WprContainer mode={'物件.戸建'} view={this.view}>
								<MatTypography name='floor' row={rowInfo} className='itemUnit' view={this.view} style={{marginRight: '20px'}}/>
							</WprContainer>
							<MatTypography name='location' row={rowInfo} className='itemUnit' view={this.view}/>
						</div>
						<div>
							<MatTypography name='comment' row={rowInfo} className='itemUnit' view={this.view}/>
						</div>
					</Grid>
				</Grid>
			</div>
			</>
		);
	}
	/**
	 * リスト名=NetListModel
	 */
	public onNetListModelRender(rowInfo: WprRowInfo): any {
		return (
			<>
			<div className='feeTable'>
				<Grid container className='feeRow'>
					<Grid item xs={12} md={4} className='feeTitle'>
						<div>ご利用中のサービス</div>
					</Grid>
					<Grid item xs={12} md={8} className='feeVal courseValue'>
						<MatTypography name='no' row={rowInfo} view={this.view} className='noStyle'/>
						<MatTypography name='serviceInfo' row={rowInfo} view={this.view} className='itemUnit'/>
					</Grid>
				</Grid>	
				<Grid container className='feeRow'>
					<Grid item xs={12} md={4} className='feeTitle'>
						<div>希望場所</div>
					</Grid>
					<Grid item xs={12} md={8} className='feeVal courseValue'>
						<div>
							<WprContainer mode={'物件.戸建'} view={this.view}>
								<MatTypography name='floor' row={rowInfo} className='itemUnit' view={this.view} style={{marginRight: '20px'}}/>
							</WprContainer>
							<MatTypography name='location' row={rowInfo} className='itemUnit' view={this.view}/>
						</div>
						<div>
							<MatTypography name='comment' row={rowInfo} className='itemUnit' view={this.view}/>
						</div>
					</Grid>
				</Grid>
			</div>
			</>
		);
	}
	/**
	 * リスト名=PhoneListModel
	 */
	public onPhoneListModelRender(rowInfo: WprRowInfo): any {
		return (
			<>
			<div className='feeTable'>
				<Grid container className='feeRow'>
					<Grid item xs={12} md={4} className='feeTitle'>
						<div>ご利用中のサービス</div>
					</Grid>
					<Grid item xs={12} md={8} className='feeVal courseValue'>
						<MatTypography name='no' row={rowInfo} view={this.view} className='noStyle'/>
						<MatTypography name='serviceInfo' row={rowInfo} view={this.view} className='itemUnit'/>
					</Grid>
				</Grid>	
				<Grid container className='feeRow'>
					<Grid item xs={12} md={4} className='feeTitle'>
						<div>希望場所</div>
					</Grid>
					<Grid item xs={12} md={8} className='feeVal courseValue'>
						<div>
							<WprContainer mode={'物件.戸建'} view={this.view}>
								<MatTypography name='floor' row={rowInfo} className='itemUnit' view={this.view} style={{marginRight: '20px'}}/>
							</WprContainer>
							<MatTypography name='location' row={rowInfo} className='itemUnit' view={this.view}/>
						</div>
						<div>
							<MatTypography name='comment' row={rowInfo} className='itemUnit' view={this.view}/>
						</div>
					</Grid>
				</Grid>
			</div>
			</>
		);
	}
	// --------------------------------------------------------------------------
}
