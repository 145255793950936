import React, { ReactNode } from 'react';
import { WprBaseViewComponent } from '../../../../../wpr-framework/view/component/WprBaseViewComponent';
import { ItscomHomeSelect2View } from './ItscomHomeSelect2View';
import { ProgressBar } from '../../../common/progress_bar/ProgressBar';
import { Grid, Typography, TableCell, Table, TableBody, TableHead, TableRow, Divider } from '@material-ui/core';
import { MatCheckBox } from '../../../../../wpr-framework/component/controls/material/MatCheckBox';
import { Scale } from 'chart.js';
import { MatTypography } from '../../../../../wpr-framework/component/controls/material/MatTypography';
import { MatTextField } from '../../../../../wpr-framework/component/controls/material/MatTextField';
import { WprRowInfo } from '../../../../../wpr-framework/component/props/WprRowInfo';
import { MatTableRow } from '../../../../../wpr-framework/component/controls/material/MatTableRow';
import { MatTableContainer } from '../../../../../wpr-framework/component/controls/material/MatTableContainer';
import { MatButton } from '../../../../../wpr-framework/component/controls/material/MatButton';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { WprDiv } from '../../../../../wpr-framework/component/controls/WprDiv';
import { WprContainer } from '../../../../../wpr-framework/component/controls/WprContainer';
import helpIcon from '/shared/image/common/icon_Help_bgLigntOrange.png';
import errorIcon from '/shared/image/common/icon_error.png';
import styles from './ItscomHomeSelect2.scss';

/**
 * ITSCOM HOME_追加申し込み(コース内容の選択)用コンポーネント
 */
export class ItscomHomeSelect2 extends WprBaseViewComponent {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: any) { super(new ItscomHomeSelect2View(), props); }
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** ITSCOM HOME_追加申し込み(コース内容の選択)ビュー情報 */
	public get viewInfo(): ItscomHomeSelect2View	{ return this.view as ItscomHomeSelect2View;	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------

	/**
	 * リストコントロール定義登録
	*/
	protected onRegisterListRender(): void {
		this.addListRender('OptListModel', 'PC', this.onOptListPcModelRender);
		this.addListRender('OptListModel', 'SP', this.onOptListSpModelRender);
		this.addListRender('ErrorModel', null, this.onErrorModelRender);
	}

	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		return (
			<>
			<div className='pageCard'>
				<div className="pageHeader stepFlow">
					<ProgressBar name='progressBar' view={this.view} />
				</div>
				<div className='main'>
					<div className='pageTitle'>
						<div className='title_left'>
							<MatTypography name='title' className='title_text' view={this.view}/>
							<Typography className='title_sentence'>ご希望の内容を選択してください。</Typography>
						</div>
					</div>
					<WprContainer mode='エラー.表示' view={this.view}>
						<div className='errorArea'>
							<span className='parallel'>
								<img src={errorIcon} alt='' className='x_img'/>
								<div className='errorList'>
									{this.renderList('ErrorModel')}
								</div>
							</span>
						</div>
					</WprContainer>
					<div className='title_space'>
						<div className='itemTitle title_maru'>基本情報
							<WprContainer mode='ヘルプ.IH' view={this.view}>
								<a href='https://www.itscom.co.jp/service/itscomhome/' target='_blank'><img src={helpIcon} alt='' style={{marginLeft: '4px'}}></img></a>
							</WprContainer>
							<WprContainer mode='ヘルプ.TV' view={this.view}>
								<a href='https://www.itscom.co.jp/service/tvpush/' target='_blank'><img src={helpIcon} alt='' style={{marginLeft: '4px'}}></img></a>
							</WprContainer>
						</div>
					</div>
					<div className='feeTable'>
						<Grid container className='feeRow'>
							<Grid item xs={12} md={4} className='feeTitle'>
								<div>申込みコース</div>
							</Grid>
							<Grid item xs={12} md={8} className='feeVal courseValue'>
								<WprContainer mode='申込みコース.IH' view={this.view}>
									<MatTypography name='course' className='itemUnit' view={this.view}/>
								</WprContainer>
								<WprContainer mode='申込みコース.IH以外' view={this.view}>
									<Typography className='font_black'><MatTypography name='course' className='itemUnit' view={this.view}/>(月額<MatTypography name='amount' className='itemUnit' view={this.view}/>円)</Typography>
								</WprContainer>
							</Grid>
						</Grid>	
						<Grid container className='feeRow'>
							<Grid item xs={12} md={4} className='feeTitle'>
								<div>機器のご提供方法</div>
							</Grid>
							<Grid item xs={12} md={8} className='feeVal courseValue checkArea'>
								<MatCheckBox name='rental' color='primary' className='checkbox_size' view={this.view} /><span className = 'font_black' style={{paddingRight: '18px'}}>レンタル</span>
								<MatCheckBox name='purchase' color='primary' className='checkbox_size' view={this.view}/><span className = 'font_black'>購入</span>
								<ul className='ul_kome'>
									<li className = 'notes'>端末を購入される場合、お得パック適用対象外となります。</li>
								</ul>
							</Grid>
						</Grid>	
						<WprContainer mode='お得パックエリア.表示' view={this.view}>
							<Grid container className='feeRow'>
								<Grid item xs={12} md={4} className='feeTitle'>
									<div>お得パック
										<a href='https://www.itscom.co.jp/service/profit/' target='_blank'><img src={helpIcon} alt='' style={{marginLeft: '3px', verticalAlign: 'middle'}}></img></a>
									</div>
								</Grid>
								<Grid item xs={12} md={8} className='feeVal courseValue'>
									<MatCheckBox name='yesOtoku' color='primary' className='checkbox_size' view={this.view} style={{padding: '9px 9px 9px 6px'}}/><span className = 'font_black checkValue'>希望する</span>
									<MatCheckBox name='noOtoku' color='primary' className='checkbox_size' view={this.view}/><span className = 'font_black'>希望しない</span>
								</Grid>
							</Grid>	
						</WprContainer>
					</div>
					<div className=''>
						<div className='itemTitle title_maru'>オプション</div>
						<Grid container>
							<Grid item xs={12}>
								<MatTableContainer listName={'OptListModel'} view={this.view} noDataUseHeader noDataMessage=' '>
									<WprContainer mode='オプション.PC' view={this.view}>
										{/* PC用 */}
										<Table className = 'optionSelsctPcTable'> 
											<colgroup>
												<col style={{width: '33%'}}/>
												<col style={{width: '23%'}}/>
												<col style={{width: '10%'}}/>
												<col style={{width: '20%'}}/>
												<col style={{width: '10%'}}/>
											</colgroup>
											<TableHead className='hedderrow'>
												<TableRow className='toptable'>
													<TableCell className='border_right font_bold'>オプション名</TableCell>
													<TableCell align='center' className='border_right font_bold'>月額レンタル料(税込)</TableCell>
													<TableCell align='center' className='border_right font_bold'>数量</TableCell>
													<TableCell align='center' className='border_right font_bold'>購入価格(税込)</TableCell>
													<TableCell align='center' className='font_bold'>数量</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												{this.renderList('OptListModel', 'PC')}
												<WprContainer mode='オプションなし.表示' view={this.view}>
													<TableRow>
														<TableCell className='non_list bold' colSpan={5}>申込み可能なオプションはございません。</TableCell>
													</TableRow>
												</WprContainer>
											</TableBody>
										</Table>
									</WprContainer>
									{/* SP用 */}
									<WprContainer mode='オプション.SP' view={this.view}>
										<Table className = 'optionSelsctSpTable'> 
											<colgroup>
												<col style={{width: '55%'}}/>
												<col style={{width: '45%'}}/>
											</colgroup>
											<TableHead className='hedderrow'>
												<TableRow className='toptable'>
													<TableCell rowSpan={4} align='center' className='border_right font_bold'>オプション名</TableCell>
													<TableCell rowSpan={1} align='center' className='font_bold'>月額レンタル料（税込）</TableCell>
												</TableRow>
												<TableRow className='toptable'>
													<TableCell rowSpan={1} align='center' className='font_bold'>数量</TableCell>
												</TableRow>
												<TableRow className='toptable'>
													<TableCell rowSpan={1} align='center' className='font_bold'>購入価格（税込）</TableCell>
												</TableRow>
												<TableRow className='toptable'>
													<TableCell rowSpan={1} align='center' className='font_bold'>数量</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												{this.renderList('OptListModel', 'SP')}
												<WprContainer mode='オプションなし.表示' view={this.view}>
													<TableRow>
														<TableCell className='non_list bold' colSpan={5}>申込み可能なオプションはございません。</TableCell>
													</TableRow>
												</WprContainer>
											</TableBody>
										</Table>
									</WprContainer>
								</MatTableContainer>
							</Grid>
						</Grid>
					</div>
					<div className='attention_fontSize'>
						<ul className='ul_kome'>
							<WprContainer mode='注釈OwnerIH.表示' view={this.view}>
								<li className='redText'>
									1 アパートメント契約のお客さまの料金については、ご連絡にてご案内致します。
								</li>
							</WprContainer>
							<li>
								表示されている月額は定価となります｡実際の請求金額とは異なる場合があります｡
							</li>
							<WprContainer mode='注釈IH.表示' view={this.view}>
								<li>
									スマートライトオプションのみレンタルのほか購入も、お選びいただけます。
								</li>
								<li>
									ゲートウェイの端末レンタル料金は月額330円/月(税込)です。
								</li>
							</WprContainer>
							<WprContainer mode='注釈TV.表示' view={this.view}>
								<li>
									イッツコム テレビ・プッシュの端末(IPボックス)レンタル料金は月額550円/月(税込)です。
								</li>
							</WprContainer>
							<li>
								各端末の月額利用料等については<a href='https://www.itscom.co.jp/info/covenant/gjslkq0000003us8-att/itscom_price.pdf' target = '_blank' className='link_color'>こちら</a>をご確認ください。
							</li>
							<li>
								スマートスピーカーオプションは家電コントローラーのレンタルが必須となります。
							</li>
						</ul>
					</div>
				</div>
				<Divider className='select2_underSpace'/>
				<div className='pageFooter footer-space'>
					<div>
						<MatButton name='next' view={this.view} className='orangeButton orangeButton_parts' >
							確認
							<NavigateNextIcon />
						</MatButton>
					</div>
					<div>
						<MatButton name='back' view={this.view} className='backButton' >
							<NavigateBeforeIcon />
							戻る
						</MatButton>
					</div>
				</div>
			</div>
			</>
		);
	}
	// --------------------------------------------------------------------------

	// リスト描画  ---------------------------------------------------------------
	/**
	 * リスト名=OptListModel
	 * PC用
	 */
	public onOptListPcModelRender(rowInfo: WprRowInfo): any {
		return (
			<MatTableRow view={this.view} row={rowInfo} className='optionRow'>
				<TableCell className='border_right'>
					<MatTypography name='option' className='font_bold' view={this.view} row={rowInfo}/>
				</TableCell>
				<TableCell align='center' className='border_right'>
					{/* オーナー契約IH */}
					<WprDiv name='ownerIhRental' view={this.view} row={rowInfo}>
						<Typography className='feeUnit'>-円<span className='ownerIh_kome'> ※1</span></Typography>
					</WprDiv> 
					{/* その他 */}
					<WprDiv name='otherRental' view={this.view} row={rowInfo}>
						<MatTypography name='rental' className='font_bold' view={this.view} row={rowInfo}/>
						<span className='feeUnit'>円</span>
					</WprDiv>
				</TableCell>
				<TableCell align='center' className='border_right optQuantity'>
					<div className = 'optionUnit'>
						<MatTextField name='quantity1' className='font_bold' view={this.view} row={rowInfo}/>
						<MatTypography name='rentalUnit' className='feeUnit' view={this.view} row={rowInfo}/>
					</div>
				</TableCell>
				<TableCell align='center' className='border_right'>
					{/* オーナー契約IH */}
					<WprDiv name='ownerIhPrice' view={this.view} row={rowInfo}>
						<Typography className='feeUnit'>-円<span className='ownerIh_kome'> ※1</span></Typography>
					</WprDiv>
					{/* その他 */}
					<WprDiv name='otherPrice' view={this.view} row={rowInfo}>
						<MatTypography name='price' className='font_bold' view={this.view} row={rowInfo}/>
						<MatTypography name='priceUnit' className='feeUnit' view={this.view} row={rowInfo}/>
					</WprDiv>
				</TableCell>
				<TableCell align='center' className='optQuantity'>
					{/* 購入可能 */}
					<WprDiv name='buyableUnit' view={this.view} row={rowInfo}>
						<div className = 'optionUnit'>
							<MatTextField name='quantity2' className='font_bold' view={this.view} row={rowInfo}/>
							<MatTypography name='buyUnit' className='feeUnit' view={this.view} row={rowInfo}/>
						</div>
					</WprDiv>
					{/* 購入不可 */}
					<WprDiv name='notBuyableUnit' view={this.view} row={rowInfo}>
						<Typography className='font_bold'>-</Typography>
					</WprDiv>
				</TableCell>
			</MatTableRow>
		);
	}
	
	/**
	 * リスト名=OptListModel
	 * SP用
	 */
	public onOptListSpModelRender(rowInfo: WprRowInfo): any {
		return (
			<>
				<TableRow>
					<TableCell className='border_right' align='center' rowSpan ={4}> 
						<MatTypography name='option' className='font_bold' view={this.view} row={rowInfo}/>
					</TableCell>
					<TableCell align='center' rowSpan ={1}>
						{/* オーナー契約IH */} 
						<WprDiv name='ownerIhRental' view={this.view} row={rowInfo}>
							<Typography className='feeUnit'>-円<span className='ownerIh_kome'> ※1</span></Typography>
						</WprDiv> 
						{/* その他 */}
						<WprDiv name='otherRental' view={this.view} row={rowInfo}>
							<MatTypography name='rental' className='font_bold' view={this.view} row={rowInfo}/>
							<span className='feeUnit'>円</span>
						</WprDiv>
					</TableCell>
				</TableRow>
				<TableRow className='optionRow'>
					<TableCell align='center' rowSpan ={1}>
						<div className = 'optionUnit'>
							<MatTextField name='quantity1' className='font_bold' view={this.view} row={rowInfo}/>
							<MatTypography name='rentalUnit' className='feeUnit' view={this.view} row={rowInfo}/>
						</div>
					</TableCell>
				</TableRow>
				<TableRow>
					<TableCell align='center' rowSpan ={1}>
						{/* オーナー契約IH */}
						<WprDiv name='ownerIhPrice' view={this.view} row={rowInfo}>
							<Typography className='feeUnit'>-円<span className='ownerIh_kome'> ※1</span></Typography>
						</WprDiv>
						{/* その他 */}
						<WprDiv name='otherPrice' view={this.view} row={rowInfo}>
							<MatTypography name='price' className='font_bold' view={this.view} row={rowInfo}/>
							<MatTypography name='priceUnit' className='feeUnit' view={this.view} row={rowInfo}/>
						</WprDiv>
					</TableCell>
				</TableRow>
				<TableRow className='optionRow'>
					<TableCell align='center' rowSpan ={1}>
						{/* 購入可能 */}
						<WprDiv name='buyableUnit' view={this.view} row={rowInfo}>
							<div className = 'optionUnit'>
								<MatTextField name='quantity2' className='font_bold' view={this.view} row={rowInfo}/>
								<MatTypography name='buyUnit' className='feeUnit' view={this.view} row={rowInfo}/>
							</div>
						</WprDiv>
						{/* 購入不可 */}
						<WprDiv name='notBuyableUnit' view={this.view} row={rowInfo} >
							<Typography className='font_bold'>-</Typography>
						</WprDiv>
					</TableCell>
				</TableRow>
			</>
		);
	}

	/**
	 * リスト名=ErrorModel
	 */
	public onErrorModelRender(rowInfo: WprRowInfo): any {
		return (
			<div>
				<MatTypography name='errorMessage' view={this.view} row={rowInfo}/>
			</div>
		);
	}
	// --------------------------------------------------------------------------
}
