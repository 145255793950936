import { MypageBaseView } from '../../base/MypageBaseView';
import { WprListData } from '../../../../wpr-framework/view/deco/WprListData';
import { Document } from '../../../service/models/Document';
import { ProgressBarModel, ProgressBarStopModel } from '../../../models/ProgressBarModel';
import { ObjectUtil } from '../../../common/util/ObjectUtil';


export class ProgressBarData {
	progressBarList: ProgressBarModel[]
}


/**
 * 進捗バー
 */
export class ProgressBarView extends MypageBaseView {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() { super('ProgressBar'); }
	// --------------------------------------------------------------------------

	// モデル  ------------------------------------------------------------------
	@WprListData('ProgressBarList')
	private m_ProgressBarList: ProgressBarModel[] = null;	// 資料情報
	@WprListData('ProgressBarStopList')
	private m_ProgressBarStopList: ProgressBarStopModel[] = null;	// 資料情報
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * コントロール定義設定
	 */
	public onRegisterConfig(): void {
		this.addListConfig('stepNum', 'ステップ番号', 'ProgressBarList');
		this.addListConfig('title', 'タイトル', 'ProgressBarList');
	}

	/**
	* ウインドウリサイズ通知
	* @param width 幅
	* @param height 高さ
	*/
	public onResizeWindow(width: number, height: number): void {
		if (!!this.m_ProgressBarList && this.m_ProgressBarList.length > 0) {
			this.setStepBar();
		}
	}

	/**
	 * データ受信処理
	 * @param dataName データ名
	 * @param data データ
	 */
	public onReceiveData(dataName: string, data?: ProgressBarData): void {
		if (dataName === 'fP_ev_progressBarIntital') {
			this.m_ProgressBarList = data.progressBarList;
			this.m_ProgressBarStopList = this.m_ProgressBarList[0].stopModel;
			if (ObjectUtil.isNotNullOrUndefined(this.m_ProgressBarStopList)) {
				this.setListData('ProgressBarStopList', this.m_ProgressBarStopList);
			}
			this.setListData('ProgressBarList', this.m_ProgressBarList);
		}
		// this.setStepBar;
		setTimeout(() => {
			this.setStepBar();
		}, 350);
	}
	// --------------------------------------------------------------------------

	// private メソッド  ---------------------------------------------------------
	/**
	 * ステップバーのスタイル諸々セット
	 */
	private setStepBar(): void {
		// ------------------ ステップバーの全体の幅を調整 ------------------
		let stepItems =null;
		const url = window.location.pathname;
		if (url.includes('relocate')) {
			stepItems = document.getElementsByClassName('ProgressBar-stepItem_relocate');
		} else {
			stepItems = document.getElementsByClassName('ProgressBar-stepItem');
		}
		// const stepItems = document.getElementsByClassName('ProgressBar-stepItem');

		const stepBarParent = document.getElementById('stepBarParentId');
		stepBarParent.style.width = (stepItems[stepItems.length-1]?.children[0]?.children[0]?.getBoundingClientRect().left - stepItems[0].children[0].children[0].getBoundingClientRect().left) + 'px';

		const stepBar = document.getElementById('stepBarId');
		stepBar.style.width = (stepItems[1]?.children[0]?.children[0]?.getBoundingClientRect().left - stepItems[0].children[0].children[0].getBoundingClientRect().left) + 'px';

		// ------------------ ステップバーの進捗度の棒の長さを調整 ------------------
		let start;
		let progressEnd;

		// ＞どこから線を引くかを判定する：一番最初のステップ番号の位置取得
		start = stepItems[0].children[0].children[0].getBoundingClientRect().right;

		// ＞どこまで線を引くかを判定する
		const barItems = document.getElementsByClassName('ProgressBar-next')[0];
		// 　　＞次がない場合：「ProgressBar-current」が一番最後となるため
		if (ObjectUtil.isNullOrUndefined(barItems)) {
			const currentItem = document.getElementsByClassName('ProgressBar-current')[0];
			progressEnd = currentItem.children[0].children[0].getBoundingClientRect().left + 25;
		}
		// 　　＞次がある場合：「ProgressBar-next」まで線を引く必要があるため
		else {
			if (barItems.classList.contains('ProgressBar-stepBar_stop')) {
				progressEnd = barItems.getBoundingClientRect().right + 10;
			}
			else if (barItems.classList.contains('ProgressBar-stepItem') || barItems.classList.contains('ProgressBar-stepItem_relocate')) {
				progressEnd = barItems.children[0].children[0].getBoundingClientRect().left + 25;
			}
		}

		// ＞どれだけすすんだかを表す、濃い色の棒のwidthを設定
		const activeBar = document.getElementsByClassName('ProgressBar-stepBar_active')[0];
		activeBar.style.width = (progressEnd - start) + 'px';
		
		this.refreshView();
	}
	// --------------------------------------------------------------------------
}
