import { WprDI_ScopeModel } from '../../../../wpr-framework/view/di/WprDI_ScopeModel';
import { MypageBaseView } from '../../base/MypageBaseView';
import { ProgressBarData } from '../../common/progress_bar/ProgressBarView';
import { MypageWizardMngr } from '../../wizard/models/MypageWizardMngr';
import { WprListData } from '../../../../wpr-framework/view/deco/WprListData';
import { callForwardingModel } from '../../../models/mypage/callForwarding/callForwardingModel';
import { WprModel } from '../../../../wpr-framework/view/deco/WprModel';
import MypageCodeTypeConstants from '../../../common/MypageCodeTypeConstants';
import { Customer } from '../../../models/bss/customer/Customer';
import { Contract } from '../../../models/bss/contract/Contract';
import { ObjectUtil } from '../../../common/util/ObjectUtil';
import MypageDispdivcdConstants from '../../../common/MypageDispdivcdConstants';

/**
 * 着信転送申込み
 */
export class CallForwardingSelectView extends MypageBaseView {
	// コンストラクタ  ----------------------------------------------------------
	public constructor() { super('CallForwardingSelectView'); }
	// --------------------------------------------------------------------------

	// モデル  ------------------------------------------------------------------
	@WprDI_ScopeModel('MypageWizardMngr')
	private m_MypageWizardMngr: MypageWizardMngr			= null;	// 画面ウィザード管理クラス
	@WprListData('ContractInfoList')
	private m_ContractInfoList: callForwardingModel[]		= null;	// 契約情報リスト
	@WprListData('CallOptionList')
	private m_CallOptionList: callForwardingModel[]			= null;	// 着信転送オプションリスト
	@WprModel('CallForwardingInfo')
	private m_CallForwardingInfo: callForwardingModel		= null;	// 着信転送画面情報
	// --------------------------------------------------------------------------

	// private変数  -------------------------------------------------------------
	private m_Contract: Contract							= null;	// 契約情報
	private m_Customer:	Customer							= null;	// 顧客情報	
	// --------------------------------------------------------------------------


	// override メソッド  --------------------------------------------------------
	/**
	 * コントロール定義設定
	 */
	public onRegisterConfig(): void {
		this.addListConfig('itemName', '項目名', 'ContractInfoList');
		this.addListConfig('itemValue', '項目値', 'ContractInfoList');
		this.addListConfig('check', '選択', 'CallOptionList', { readOnly: true });
		this.addListConfig('optionName', 'オプション名', 'CallOptionList');
		this.addListConfig('optionFee', '月額料金', 'CallOptionList', { converter: '3桁カンマ' });
		this.addListConfig('payUnit', '料金単位', 'CallOptionList');
		this.addConfig('contractStatus', '契約形態', 'CallForwardingInfo');
	}

	/**
	 * アクション登録
	 */
	public onRegisterAction(): void {
		this.addAction('next', this.onNext);
		this.addAction('back', this.onBack);
	}

	/**
	 * ビュー表示通知
	 */
	public onShowView(): void {
		this.m_MypageWizardMngr.initView(this, (result) => {
			this.registAccessLog();
			this.setStorageItem('b_url', window.location.href);
			const sendData: ProgressBarData = new ProgressBarData();
			sendData.progressBarList = this.m_MypageWizardMngr.getProgressModelList(this);
			this.sendChildData('fP_ev_progressBarIntital', sendData);
			this.m_CallForwardingInfo = this.m_MypageWizardMngr.getCache().m_CallForwardingInfo;
			const contractId = this.m_MypageWizardMngr.getParams().contractId;
			if (ObjectUtil.isNotNullOrEmptyStr(contractId)) {
				this.m_Contract = this.m_MypageWizardMngr.getContract(this, contractId);
				this.m_CallForwardingInfo.contractId = this.m_Contract.contractID;
			}
			this.setViewInfo();
			this.refreshView();
		});
	}
	// --------------------------------------------------------------------------

	// アクション  ---------------------------------------------------------------
	/**
	 * 確認
	 */
	public onNext(param: any): void {
		this.refreshModel();
		this.m_MypageWizardMngr.goNext(this);
	}

	/**
	 * 戻る
	 */
	public onBack(param: any): void {
		this.m_MypageWizardMngr.goBack(this);
	}
	// --------------------------------------------------------------------------

	// private メソッド  --------------------------------------------------------
	private setViewInfo() {
		// 契約情報
		const status = this.m_MypageWizardMngr.getMDisplayList(this, MypageDispdivcdConstants.ITSCOMHOME_SERVICESTATUS, '').filter(data => data.namecd === this.m_Contract.statusCD);
		if (status && status.length)
			this.m_CallForwardingInfo.contractStatus = status[0].dispnamep;
		this.m_ContractInfoList = this.m_MypageWizardMngr.getCache().m_ContractInfoList;
		if (this.m_ContractInfoList.length === 0) {
			const mac = new callForwardingModel();
			mac.itemName = 'MACアドレス';
			mac.itemValue = this.m_Contract.equipment[0].equipmentID;
			this.m_ContractInfoList.push(mac);
			const deviceType = this.m_MypageWizardMngr.getMDisplayList(this, MypageDispdivcdConstants.ITSCOMHOME_TERMINALTYPE, '').filter(data => data.namecd === this.m_Contract.equipment[0].equipmentTypeCD);
			const deviceForm = this.m_MypageWizardMngr.getMDisplayList(this, MypageDispdivcdConstants.ITSCOMHOME_TERMINALSTATUS, '').filter(data => data.namecd === this.m_Contract.equipment[0].rentalFlag);
			if (deviceType && deviceType.length > 0 && deviceForm && deviceForm.length > 0) {
				const device = new callForwardingModel();
				device.itemName = '端末の種類';
				device.itemValue = deviceType[0].dispnamep + '　' + deviceForm[0].dispnamep;
				this.m_ContractInfoList.push(device);
			}
		}
		this.setListData('ContractInfoList', this.m_ContractInfoList);
		// オプションリスト
		this.m_CallOptionList = this.m_MypageWizardMngr.getCache().m_CallOptionList;
		if (this.m_CallOptionList.length === 0) {
			const optionInfo = this.m_MypageWizardMngr.getMGenralList(this, MypageCodeTypeConstants.PHONE_OPTION, '').filter(row => row.flag1);
			if (optionInfo && optionInfo.length > 0) {
				const option = new callForwardingModel();
				option.check = true;
				option.optionName = optionInfo[0].name1;
				option.optionFee = this.m_MypageWizardMngr.getTaxFee(this, String(optionInfo[0].num1));
				option.payUnit = '円';
				this.m_CallOptionList.push(option);
			}
		}
		this.setListData('CallOptionList', this.m_CallOptionList);
	}
	// --------------------------------------------------------------------------
}
