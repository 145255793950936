import { WprBaseViewInfo } from '../../../../wpr-framework/view/WprBaseViewInfo';
import { MypageBaseView } from '../../base/MypageBaseView';
import { WprModel } from '../../../../wpr-framework/view/deco/WprModel';
import { AuthService } from '../../../service/AuthService';
import { DeleteSessionInfoRequest } from '../../../service/models/dto/auth/DeleteSessionInfoRequest';
import { ObjectUtil } from '../../../common/util/ObjectUtil';
import { CertificationInfo } from '../../../service/models/apimodels/CertificationInfo';

/**
 * ヘッダー
 */
export class HeaderView extends MypageBaseView {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() { super('Header'); }
	// --------------------------------------------------------------------------

	// モデル  ------------------------------------------------------------------
	@WprModel('fontSize')
	private m_fontSize: string = null;	// fontSize
	// --------------------------------------------------------------------------

	// サービス  ----------------------------------------------------------------
	private m_AuthService: AuthService	= new AuthService();	// MyPageRenewalサービス
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * コントロール定義設定
	 */
	public onRegisterConfig(): void {
		this.addConfig('fontSize', '文字サイズ', 'fontSize', { bindModel: true });
	}

	/**
	 * アクション登録
	 */
	public onRegisterAction(): void {
		this.addAction('toCustomerSupport', this.onToCustomerSupport);
		this.addAction('logout', this.onLogout);

		// トップ
		this.addAction('toTop', this.onToPage);
	}

	/**
	 * ビュー表示通知
	 */
	public onShowView(): void {
	}

	/**
	 * 値変更通知
	 * @param name 名前
	 * @param value 値
	 * @returns falseの場合、変更を取り消す
	 */
	public onChangeValue(name: string, value: any): boolean {
		this.refreshModel();
		// this.refreshView();
		return true;
	}

	/**
	 * ウインドウリサイズ通知
	 * @param width 幅
	 * @param height 高さ
	 */
	public onResizeWindow(width: number, height: number): void {
		if (width <= 700) {
			this.setViewMode('ヘッダー', '小');
		}
		else {
			this.setViewMode('ヘッダー', '大');
		}
	}
	// --------------------------------------------------------------------------

	// アクション  ---------------------------------------------------------------
	/**
	 * お客様サポート
	 */
	public onToCustomerSupport(param: any): void {
		location.href = this.getOldUrl() + 'Inquiry/Inquiry_entry/';
	}

	/**
	 * ログアウト
	 * セッション情報をテーブルから削除する
	 */
	public onLogout(param: any): void {
		const sessionId = this.getCookie('sessionId');
		if (ObjectUtil.isNotNullOrEmptyStr(sessionId)) {
			const req: DeleteSessionInfoRequest = new DeleteSessionInfoRequest();
			req.certificationInfo = new CertificationInfo();
			req.certificationInfo.sessionId = sessionId;
			this.m_AuthService.deleteSessionInfo(this, req, (result) => {
				location.href = this.getOldUrl() + "Top/Login/";
			});
		} else {
			location.href = this.getOldUrl() + "Top/Login/";
		}
	}

	/**
	 * マイページアイコン押下
	 */
	public onToPage(param: any): void {
		location.href = this.getOldUrl() + 'Top/My_top/';
	}
	// --------------------------------------------------------------------------
}
