// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@charset \"UTF-8\";\n/*\r\n * [サービス変更申し込み]申込内容の確認用CSS\r\n */\n.ModNetConfirm-value {\n  display: flex;\n  align-items: baseline; }\n  .ModNetConfirm-value .ModNetConfirm-itemSelect .MuiSelect-root {\n    width: auto !important;\n    height: auto !important;\n    background-color: #FFFFFF !important;\n    color: #333333 !important;\n    font-weight: 500 !important;\n    font-size: 14px;\n    padding: 0; }\n  .ModNetConfirm-value .ModNetConfirm-itemSelect svg {\n    display: none; }\n  .ModNetConfirm-value .ModNetConfirm-itemSelect fieldset {\n    border: 0px; }\n\n.ModNetConfirm-campaignArea {\n  margin: 1em 0;\n  border: 1px solid #15468b;\n  border-radius: 8px;\n  padding: 1rem;\n  background: #d9dcf3;\n  color: '#484848'; }\n  .ModNetConfirm-campaignArea .ModNetConfirm-campaignTitle {\n    font-weight: '700'; }\n  .ModNetConfirm-campaignArea .ModNetConfirm-campaignText {\n    font-size: 13px; }\n", ""]);
// Exports
exports.locals = {
	"value": "ModNetConfirm-value",
	"itemSelect": "ModNetConfirm-itemSelect",
	"campaignArea": "ModNetConfirm-campaignArea",
	"campaignTitle": "ModNetConfirm-campaignTitle",
	"campaignText": "ModNetConfirm-campaignText"
};
module.exports = exports;
