import React, { ReactNode } from 'react';
import { WprBaseViewComponent } from '../../../../wpr-framework/view/component/WprBaseViewComponent';
import { UsageDetails_001View } from './UsageDetails_001View';

import { MatTabs } from '../../../../wpr-framework/component/controls/material/MatTabs';
import { Grid, Paper, Tab, Typography } from '@material-ui/core';
import { MatTabPanel } from '../../../../wpr-framework/component/controls/material/MatTabPanel';
import { UsageFeeTab } from '../usage_fee_tab/UsageFeeTab';

import styles from './UsageDetails_001.scss'
import { WprRowInfo } from '../../../../wpr-framework/component/props/WprRowInfo';
import { MatTab } from '../../../../wpr-framework/component/controls/material/MatTab';
import { MenuBar } from '../../common/menu_bar/MenuBar';
import { WprContainer } from '../../../../wpr-framework/component/controls/WprContainer';

/**
 * ご利用明細画面用コンポーネント
 */
export class UsageDetails_001 extends WprBaseViewComponent {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: any) { super(new UsageDetails_001View(), props); }
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** ご利用明細画面ビュー情報 */
	public get viewInfo(): UsageDetails_001View { return this.view as UsageDetails_001View; }
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * スタイルオブジェクト取得
	*/
	public getStylesObject(): any { return styles; }

	/**
	 * リストコントロール定義登録
	*/
	protected onRegisterListRender(): void {
		this.addListRender('UsageMonthlyInfoList', 'ラベル', this.onUsageMonthlyTabLabelRender);
		this.addListRender('UsageMonthlyInfoList', 'タブ', this.onUsageMonthlyTabRender);
	}

	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		const maxDispTab = this.state.data.maxDispTab;
		const maxMobileDispTab = this.state.data.maxMobileDispTab;

		return (
			<>
					<div className='pageCard' id='usageDetailsID'>
						<div className='pageContent'>
							<WprContainer mode={'明細.有'} view={this.view}>
								<div className='usage-top'>
									<div>
										<Grid container>
											<Grid item xs={12}>
												<MatTabs mode='usageFeeTab' indicatorColor='primary' textColor='primary' variant='scrollable' scrollButtons='auto' view={this.view} className='tab-panel' maxDispTab={maxDispTab} maxMobileDispTab={maxMobileDispTab}>
													{this.renderList('UsageMonthlyInfoList', 'ラベル')}
												</MatTabs>
												{this.renderList('UsageMonthlyInfoList', 'タブ')}
											</Grid>
										</Grid>
									</div>
								</div>
							</WprContainer>
							<WprContainer mode={'明細.無'} view={this.view}>
								<Typography  className='noStatementMsg'>
								現在、ご利用明細情報が作成されていません。
								</Typography>
							</WprContainer>
						</div>
					</div>
			</>
		);
	}
	// --------------------------------------------------------------------------

	// リスト描画  ---------------------------------------------------------------
	/**
	 * リスト名=UsageDetailsInfoList, 描画種別=ラベル
	 */
	public onUsageMonthlyTabLabelRender(rowInfo: WprRowInfo): any {
		const lableMode: string = rowInfo.rowData.month;	// yyyyMM
		return (
			<MatTab label={rowInfo.rowData.yearLabel + '\r\n' + rowInfo.rowData.monthLabel} labelMode={lableMode} className='detailTab' view={this.view} />
		);
	}

	/**
	 * リスト名=UsageDetailsInfoList, 描画種別=タブ
	 */
	public onUsageMonthlyTabRender(rowInfo: WprRowInfo): any {
		const lableMode: string = rowInfo.rowData.month;	// yyyyMM
		return (
			<MatTabPanel mode='usageFeeTab' label={lableMode} className='detailsTabPanel' view={this.view}>
				<UsageFeeTab name={String(rowInfo.index)} view={this.view} row={rowInfo} />
			</MatTabPanel>
		);
	}

	// --------------------------------------------------------------------------

}
