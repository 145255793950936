import React, { ReactNode } from 'react';
import { WprBaseViewComponent } from '../../../../wpr-framework/view/component/WprBaseViewComponent';
import { TokotonSupportConfirmView } from './TokotonSupportConfirmView';
import { Typography, Grid, Divider } from '@material-ui/core';
import { MatButton } from '../../../../wpr-framework/component/controls/material/MatButton';
import { MatTypography } from '../../../../wpr-framework/component/controls/material/MatTypography';
import { ProgressBar } from '../../common/progress_bar/ProgressBar';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import styles from './TokotonSupportConfirm.scss';
import important from '/shared/image/common/important_purple.png'
import { WprContainer } from '../../../../wpr-framework/component/controls/WprContainer';
/**
 * とことんサポート確認画面用コンポーネント
 */
export class TokotonSupportConfirm extends WprBaseViewComponent {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: any) { super(new TokotonSupportConfirmView(), props); }
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** とことんサポート確認画面ビュー情報 */
	public get viewInfo(): TokotonSupportConfirmView { return this.view as TokotonSupportConfirmView; }
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * スタイルオブジェクト取得
	*/
	public getStylesObject(): any { return styles; }

	/**
	 * スタイルオブジェクト取得
	*/
	// public getStylesObject(): any { return styles; }

	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		return (
			<>
				<div className='pageCard'>
					<div className="pageHeader stepFlow">
						<ProgressBar name='progressBar' view={this.view} />
					</div>
					<div className='main margin'>
						<div className='pageTitle'>
							<div className='title_left'>
								<Typography className='title_text'>とことんサポート申込確認</Typography>
								<Typography className='title_comment'>以下のチャンネルのお申込みでよろしければ、「申込み」ボタンをクリックしてください。</Typography>
							</div>
						</div>
						<div className='feeTable'>
							<Grid container className='feeRow'>
								<Grid item xs={12} md={4} className='feeTitle table_header'>
									<div>とことんサポート</div>
								</Grid>
								<Grid item xs={12} md={8} className='feeVal courseValue'>
									<span className='itemUnit unit' style={{fontWeight: '500'}}>月額：<MatTypography className='itemUnit unit' name='amount' view={this.view}/>円（税込）</span>
								</Grid>
							</Grid>
							<Grid container className='feeRow'>
								<Grid item xs={12} md={4} className='feeTitle table_header'>
									<div>契約開始日</div>
								</Grid>
								<Grid item xs={12} md={8} className='feeVal courseValue'>
									<MatTypography name='applicationType' className='itemUnit unit' view={this.view}/>
								</Grid>
							</Grid>
						</div>
						<div className='supportMsgArea'>
							<ul className='aster' style={{ listStyle: 'none'}}>
								<span className='textcolor'>
									<img src={important}></img>
										ご請求について			
								</span>
								<li className='message'>とことんサポートは利用開始日の翌月分より請求が発生いたします。</li>
							</ul>
						</div>
						<WprContainer mode='請求書有料発行.表示' view={this.view}>
							<ul className='ul_kome' style={{color: '#333333'}}>
								<li>
									現在お支払い方法が未設定または現金振込となっております。<br/>
									お支払いが発生した際は、請求書（発行手数料330円［税込］/通）をお送りいたします。<br/>
									クレジットカード、口座振替へお支払い方法の変更が可能です。詳細はこちらをご確認ください。
								</li>
							</ul>
						</WprContainer>
					</div>
					<Divider style={{marginTop: '60px'}}/>
					<div className='pageFooter footer-space'>
						<div>
							<MatButton name='next' view={this.view} className='orangeButton orangeButton_parts' >
								この内容で申込む
								<NavigateNextIcon />
							</MatButton>
						</div>
						<div>
							<MatButton name='back' view={this.view} className='backButton ' >
								<NavigateBeforeIcon />
								戻る
							</MatButton>
						</div>
					</div>
				</div>
			</>
		);
	}
	// privateメソッド------------------------------------------------------------
	
	// private getApplicationType(): ReactNode{
	// 	const applicationType = this.viewInfo.getAppplicationType();
	// 	const cells:ReactNode[] = [];
	// 	cells.push(
	// 		<Typography style={{ color: 'black',fontWeight:'400', fontSize:'14px'}}>{applicationType}</Typography>
	// 	)
	// 	return cells;
	// }

	// --------------------------------------------------------------------------
}
