/**
 * ネット遷移情報
 */
export class NetFlowModel {
	// public 変数	-----------------------------------------------------------
	public otherFlg: boolean;	// その他サービスフラグ
	public kattobiFlg: boolean;	// かっとびサービスフラグ
	public defaultFlg: boolean; // かっとび光選択フラグ
	// ------------------------------------------------------------------------
}
