
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { Typography, Grid, Divider } from '@material-ui/core';
import { ReactNode } from 'react';
import { MatButton } from '../../../../wpr-framework/component/controls/material/MatButton';
import { WprBaseViewComponent } from '../../../../wpr-framework/view/component/WprBaseViewComponent';
import { ProgressBar } from '../../common/progress_bar/ProgressBar';
import { AddNhkConfirmView } from './AddNhkConfirmView';
import { MatTypography } from '../../../../wpr-framework/component/controls/material/MatTypography';
import { WprContainer } from '../../../../wpr-framework/component/controls/WprContainer';

/**
 * 支払い申込内容の確認用コンポーネント
 */
export class AddNhkConfirm extends WprBaseViewComponent {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: any) { super(new AddNhkConfirmView(), props); }
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** 申込内容の確認ビュー情報 */
	public get viewInfo(): AddNhkConfirmView	{ return this.view as AddNhkConfirmView;	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------

	

	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		return (
			<>
			<div>
				<div className='pageCard'>
					<div className="pageHeader stepFlow">
						<ProgressBar name='progressBar' view={this.view}/>
					</div>
					<div className='main'>
						<div className='pageTitle'>
							<div className='title_left'>
								<MatTypography name='title' className='title_text' view={this.view} />
								<Typography className='title_comment' style={{ paddingBottom:'30px'}}>
									以下の内容でよろしければ、「申込む」ボタンをクリックしてください。<br/>専用申込書をお送りいたします。
								</Typography>
							</div>
						</div>
						<div className='feeTable' style={{marginBottom:'0px'}}>
							<Grid container className='feeRow'>
								<Grid item xs={12} md={4} className='feeTitle'>
									<div>割引種類</div>
								</Grid>
								<Grid item xs={12} md={8} className='feeVal courseValue' style={{ color: 'black'}}>
									<span className='itemUnit'>
										<MatTypography name='discountSelect' style={{ color: 'black',fontWeight:'500',fontSize:'14px'}} view={this.view}/>
									</span>
								</Grid>
							</Grid>	
						</div>
						<WprContainer mode='請求書有料発行.表示' view={this.view}>
							<ul className='ul_kome' style={{ color: '#333333'}}>
								<li>
									現在お支払い方法が未設定または現金振込となっております。<br/>
									お支払いが発生した際は、請求書（発行手数料330円［税込］/通）をお送りいたします。<br/>
									クレジットカード、口座振替へお支払い方法の変更が可能です。詳細はこちらをご確認ください。
								</li>
							</ul>
						</WprContainer>
					</div>
					<Divider style={{marginTop:'50px'}}/>
					<div className='pageFooter footer-space'>
						<div>
							<MatButton name='next' view={this.view} className='orangeButton orangeButton_parts' >
								この内容で申込む
								<NavigateNextIcon />
							</MatButton>
						</div>
						<div>
							<MatButton name='back' view={this.view} className='backButton' >
								<NavigateBeforeIcon />
								戻る
							</MatButton>

						</div>
					</div>

				</div>
			</div>
			</>
		);
	}
	// --------------------------------------------------------------------------

	// // privateメソッド------------------------------------------------------------
	
	// private getDiscount(): ReactNode{
	// 	const discountValue = this.viewInfo.getDiscountValue();
	// 	const cells:ReactNode[] = [];
	// 	cells.push(
	// 		<Typography style={{ color: 'black',fontWeight:'500'}}>{discountValue}</Typography>
	// 	)
	// 	return cells;
	// }

	// // --------------------------------------------------------------------------
}
