import { Card, Divider } from '@material-ui/core';
import { Grid } from '@mui/material';
import React, { ReactNode } from 'react';
import { WprBaseViewComponent } from '../../../../../wpr-framework/view/component/WprBaseViewComponent';
import { MenuBar } from '../../../common/menu_bar/MenuBar';
import { MvnoEntryPlan3View } from './MvnoEntryPlan3View';
import styles from './MvnoEntryPlan3.scss';
import { WprRadio } from '../../../../../wpr-framework/component/controls/WprRadio';
import { MatRadio } from '../../../../../wpr-framework/component/controls/material/MatRadio';
import { CheckMarkRadio } from '../../../../controls/checkMarkRadio/CheckMarkRadio';
import { MvnoInitialSupportSendData, CheckMarkRadioContent, MvnoSimSizeSendData, MvnoTerminalColorSendData, MvnoTerminalSendData, MvnoTerminalUmuSendData, MvnoSimOptionSendData, MvnoWebFilteringSendData } from '../../../../controls/checkMarkRadio/CheckMarkRadioContent';
import { MatButton } from '../../../../../wpr-framework/component/controls/material/MatButton';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { WprContainer } from '../../../../../wpr-framework/component/controls/WprContainer';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import nanoSim from '/shared/image/mvno/sim/nano_sim.png';
import microSim from '/shared/image/mvno/sim/micro_sim.png';
import standardSim from '/shared/image/mvno/sim/standard_sim.png';
import simOption from '/shared/image/mvno/sim/350_terminal_MPA-ACC20BK_01.jpg';
import iPhoneSimOption from '/shared/image/mvno/sim/iPhone充電器.jpg';
import { WprRowInfo } from '../../../../../wpr-framework/component/props/WprRowInfo';
import { MvnoTerminalModel } from '../../../../service/models/apimodels/MvnoTerminalModel';
import { MvnoTerminalColorModel } from '../../../../service/models/apimodels/MvnoTerminalColorModel';
import { MvnoOptionModel } from '../../../../models/MvnoOptionModel';
import { MatCheckBox } from '../../../../../wpr-framework/component/controls/material/MatCheckBox';
import { MvnoEntryFooter } from '../mvno_entry_footer/MvnoEntryFooter';
import { MvnoHelpDialog } from '../../dialog/mvno_help_dialog/MvnoHelpDialog';
import { WprAnchor } from '../../../../../wpr-framework/component/controls/WprAnchor';
import { HelpInitialSupportDialog } from '../../dialog/help_initial_support_dialog/HelpInitialSupportDialog';
import { HelpWebFilteringDialog } from '../../dialog/help_web_filtering_dialog/HelpWebFilteringDialog';
import { ProgressBar } from '../../../common/progress_bar/ProgressBar';

/**
 * MVNO申込_端末・SIM用コンポーネント
 */
export class MvnoEntryPlan3 extends WprBaseViewComponent {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: any) { super(new MvnoEntryPlan3View(), props); }
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** MVNO申込_端末・SIMビュー情報 */
	public get viewInfo(): MvnoEntryPlan3View { return this.view as MvnoEntryPlan3View; }
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * リストコントロール定義登録
	*/
	protected onRegisterListRender(): void {
		// this.addListRender('CapacityRadioList', null, this.onCapacityRadioListRender);
		this.addListRender('TerminalRadioList', null, this.onTerminalRadioListRender);
		this.addListRender('TerminalColorList', null, this.onTerminalColorListRender);
		// this.addListRender('optionList', null, this.onoptionListRender);
	}

	/**
	 * スタイルオブジェクト取得
	*/
	public getStylesObject(): any { return styles; }

	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		const android:string =this.state.data.android;
		const iPhone1:string =this.state.data.iPhone1;
		const iPhone2:string =this.state.data.iPhone2;
		return (
			<>
				<div>
					<div className='pageCard' id='mvnoEntryPlan3ID'>
						<div className="pageHeader stepFlow">
							<ProgressBar name='progressBar' view={this.view} />
						</div>

						<div className='pageContent'>
							<div className='planContent'>
								<div className='link_blue'>
									<WprAnchor name='helpLink' view={this.view}>申込みについてご不明な方はこちら＞</WprAnchor>
								</div>

								{/* 端末有無 */}
								<div className='title_gray'>端末購入の有無をお選びください</div>
								<div className='planItem'>
									<Grid container className='entryRadio' spacing={1}>
										<Grid item xs={12} md={6}>
											<CheckMarkRadio name='terminalUseFlg' id='terminalUseFlg_1' value={false} itemType={CheckMarkRadioContent.MVNO_TERMINAL_UMU} radioContentData={this.getTerminalUmuData('SIMのみを申し込む')} view={this.view} />
										</Grid>
										<Grid item xs={12} md={6}>
											<CheckMarkRadio name='terminalUseFlg' id='terminalUseFlg_2' value={true} itemType={CheckMarkRadioContent.MVNO_TERMINAL_UMU} radioContentData={this.getTerminalUmuData('端末とSIMのセットを申し込む')} view={this.view} />
										</Grid>
									</Grid>
								</div>

								{/* 端末・端末カラー */}
								<WprContainer mode='端末申込.有' view={this.view}>
									<div className='title_gray'>端末をお選びください</div>
									<div className='planItem'>
										<div className='link_blue'>
											<WprAnchor name='terminalDetailLink' view={this.view}>端末詳細＞</WprAnchor>
										</div>
										<Grid container className='entryRadio' spacing={1}>
											{this.renderList('TerminalRadioList')}
											{this.renderList('TerminalColorList')}
										</Grid>
									</div>

									{/* 端末延長保証 */}
									<WprContainer mode='端末.選択済' view={this.view}>
										<div className='title_gray'>モバイル端末延長保証のお申込み</div>
										<div className='planItem'>
											<div className='link_blue'>
												<WprAnchor name='terminalGuaranteeLink' view={this.view}>モバイル端末延長保証について＞</WprAnchor>
											</div>
											<Grid container className='entryRadio' spacing={1}>
												<Grid item xs={6} md={6}>
													<CheckMarkRadio name='terminalGuaranteeFlg' id='terminalGuaranteeFlg_1' value={true} itemType={CheckMarkRadioContent.MVNO_INITITAL_SUPPORT} radioContentData={this.getTerminalGuartee('必要')} view={this.view} />
												</Grid>
												<Grid item xs={6} md={6}>
													<CheckMarkRadio name='terminalGuaranteeFlg' id='terminalGuaranteeFlg_2' value={false} itemType={CheckMarkRadioContent.MVNO_INITITAL_SUPPORT} radioContentData={this.getTerminalGuartee('不要')} view={this.view} />
												</Grid>
											</Grid>
										</div>


									</WprContainer>
								</WprContainer>

								{/* SIMサイズ */}
								<WprContainer mode='端末全項目.選択済' view={this.view}>
									<div className='title_gray'>SIMサイズをお選びください</div>
									<div className='planItem'>
										<div className='link_blue'>
											<WprAnchor name='simDialog' view={this.view}>SIMサイズについて⊕</WprAnchor>
										</div>
										<Grid container className='entryRadio' spacing={1}>
											<WprContainer mode='nanoSIM.表示' view={this.view}>
												<Grid item xs={6} md={4}>
													<CheckMarkRadio name='simSize' id='simSize_1' value={'nanoSIM'} itemType={CheckMarkRadioContent.MVNO_SIM_SIZE} radioContentData={this.getSimSizeData('nanoSIM')} view={this.view} />
												</Grid>
											</WprContainer>
											<WprContainer mode='マイクロSIM.表示' view={this.view}>
												<Grid item xs={6} md={4}>
													<CheckMarkRadio name='simSize' id='simSize_2' value={'マイクロSIM'} itemType={CheckMarkRadioContent.MVNO_SIM_SIZE} radioContentData={this.getSimSizeData('マイクロSIM')} view={this.view} />
												</Grid>
											</WprContainer>
											<WprContainer mode='標準SIM.表示' view={this.view}>
												<Grid item xs={6} md={4}>
													<CheckMarkRadio name='simSize' id='simSize_3' value={'標準SIM'} itemType={CheckMarkRadioContent.MVNO_SIM_SIZE} radioContentData={this.getSimSizeData('標準SIM')} view={this.view} />
												</Grid>
											</WprContainer>
										</Grid>
										<WprContainer mode='SIMサイズ.nanoSIMのみ' view={this.view}>
											<div className='comments'>※選択した端末はnanoSIM対応となります。</div>
										</WprContainer>
										<WprContainer mode='SIMサイズ.マイクロSIMのみ' view={this.view}>
											<div className='comments'>※選択した端末はマイクロSIM対応となります。</div>
										</WprContainer>
									</div>
									<WprContainer mode='プラン.音声' view={this.view}>
										{/* オプション */}
										<WprContainer mode='端末申込.有' view={this.view}>
											<div>
												<div className='planSubTitle title_maru'>オプションをお選びください</div>
												<div className='planItem'>
													<Grid container className='entryRadio' spacing={1}>
														<Grid item xs={12} md={9}>
															<CheckMarkRadio name='simOption' id='simOption_1' value={this.simOptionName} itemType={CheckMarkRadioContent.MVNO_SIM_OPTION} radioContentData={this.getSimOptionData()} view={this.view} />
														</Grid>
														<Grid item xs={12} md={3}>
															<CheckMarkRadio name='simOption' id='simOption_2' value={'オプション無し'} itemType={CheckMarkRadioContent.MVNO_TERMINAL_UMU} radioContentData={this.getTerminalUmuData('オプション無し')} view={this.view} />
														</Grid>
													</Grid>
												</div>
											</div>
										</WprContainer>
									</WprContainer>
								</WprContainer>

								{/* SIMサイズ */}
								<WprContainer mode='プラン.音声' view={this.view}>
									{/* <WprContainer mode='端末申込.有' view={this.view}> */}
									<WprContainer mode='SIM.選択済' view={this.view}>
										<div className='title_gray'>初期設定サポートのお申込み</div>
										<div className='planItem'>
											<div className='link_blue'>
												<WprAnchor name='helpIntiialSupport' view={this.view}>初期設定サポートについて⊕</WprAnchor>

											</div>
											<Grid container className='entryRadio' spacing={1}>
												<Grid item xs={12} md={6}>
													<CheckMarkRadio name='initialSupportFlg' id='initialSupportFlg_1' value={true} itemType={CheckMarkRadioContent.MVNO_INITITAL_SUPPORT} radioContentData={this.getInitialSupportData('必要')} view={this.view} />
												</Grid>
												<Grid item xs={12} md={6}>
													<CheckMarkRadio name='initialSupportFlg' id='initialSupportFlg_2' value={false} itemType={CheckMarkRadioContent.MVNO_INITITAL_SUPPORT} radioContentData={this.getInitialSupportData('不要')} view={this.view} />
												</Grid>
											</Grid>
										</div>
										{/* </WprContainer> */}
									</WprContainer>

									{/* WEBフィルタリング */}
									<WprContainer mode='SIM.選択済' view={this.view}>
										<div className='title_gray'>WEBフィルタリングのお申込み</div>
										<div className='planItem'>
											<div className='link_blue'>
												<WprAnchor name='helpWebFiltering' view={this.view}>Webフィルタリングについて⊕</WprAnchor>
											</div>
											<Grid container className='entryRadio' spacing={1}>
												{/* android */}
												<WprContainer mode='選択端末.Android' view={this.view}>
													<Grid item xs={12} md={6}>
														<CheckMarkRadio name='webFilteringServiceCd' id='webFilteringFlg_1' value={this.state.data.android} itemType={CheckMarkRadioContent.MVNO_WEB_FILTERING} radioContentData={this.getWebFilteringData('必要', android)} view={this.view} />
													</Grid>
												</WprContainer>
												{/* iPhone */}
												<WprContainer mode='選択端末.iPhone' view={this.view}>
													<Grid item xs={12} md={6}>
														<CheckMarkRadio name='webFilteringServiceCd' id='webFilteringFlg_3' value={this.state.data.iPhone1} itemType={CheckMarkRadioContent.MVNO_WEB_FILTERING} radioContentData={this.getWebFilteringData('必要', iPhone1)} view={this.view} />
													</Grid>
													<Grid item xs={12} md={6}>
														<CheckMarkRadio name='webFilteringServiceCd' id='webFilteringFlg_3' value={this.state.data.iPhone2} itemType={CheckMarkRadioContent.MVNO_WEB_FILTERING} radioContentData={this.getWebFilteringData('必要', iPhone2)} view={this.view} />
													</Grid>
												</WprContainer>
												{/* SIMのみ */}
												<WprContainer mode='選択端末.SIMのみ' view={this.view}>
													<Grid item xs={12} md={6}>
														<CheckMarkRadio name='webFilteringServiceCd' id='webFilteringFlg_1' value={this.state.data.android} itemType={CheckMarkRadioContent.MVNO_WEB_FILTERING} radioContentData={this.getWebFilteringData('必要', android)} view={this.view} />
													</Grid>
													<Grid item xs={12} md={6}>
														<CheckMarkRadio name='webFilteringServiceCd' id='webFilteringFlg_3' value={this.state.data.iPhone1} itemType={CheckMarkRadioContent.MVNO_WEB_FILTERING} radioContentData={this.getWebFilteringData('必要', iPhone1)} view={this.view} />
													</Grid>
													<Grid item xs={12} md={6}>
														<CheckMarkRadio name='webFilteringServiceCd' id='webFilteringFlg_3' value={this.state.data.iPhone2} itemType={CheckMarkRadioContent.MVNO_WEB_FILTERING} radioContentData={this.getWebFilteringData('必要', iPhone2)} view={this.view} />
													</Grid>
												</WprContainer>
												<Grid item xs={12} md={6}>
													<CheckMarkRadio name='webFilteringServiceCd' id='webFilteringFlg_2' value='不要' itemType={CheckMarkRadioContent.MVNO_WEB_FILTERING} radioContentData={this.getWebFilteringData('不要','')} view={this.view} />
												</Grid>
											</Grid>
											<div>
												<span>※ご利用者が18歳未満の場合原則としてWebフィルタリングにお申込みいただく必要があります。</span><br />
												<WprContainer mode='WEBフィルタリング文言.必要' view={this.view}>
													<span>※iPhoneでの設定はできません。</span>
												</WprContainer>
											</div>
										</div>
										{/* <div className='link_blue'>
										<WprAnchor name='helpWebFiltering' view={this.view}>Webフィルタリングについて</WprAnchor>
									</div> */}
									</WprContainer>
								</WprContainer>
							</div>
						</div>
						<div className='comment'>東京都公安委員会 古物商許可番号 303271505249 号<br />イッツ・コミュニケーションズ株式会社</div>
						<Divider />
						<div className='pageFooter'>
							<div>
								<MatButton name='next' view={this.view} className='nextButton' >
									次へ
									<NavigateNextIcon />
								</MatButton>

							</div>
							<div>
								<MatButton name='back' view={this.view} className='backButton' >
									<NavigateBeforeIcon />
									戻る
								</MatButton>

							</div>
							<div className='stepNumber'>3/4</div>
						</div>
					</div>
				</div>
				<MvnoEntryFooter name='mvnoFooter' view={this.view} />

				{/* ダイアログ */}
				<MvnoHelpDialog name='MVNO_SIMサイズダイアログ' view={this.view} />
				<HelpInitialSupportDialog name='初期設定サポートについて' view={this.view} className='dialog-medium' />
				<HelpWebFilteringDialog name='Webフィルタリングについて' view={this.view} className='dialog-medium' />
			</>
		);
	}
	// --------------------------------------------------------------------------
	private getTerminalUmuData(val: string): MvnoTerminalUmuSendData {
		const data: MvnoTerminalUmuSendData = new MvnoTerminalUmuSendData();
		data.val = val;
		return data;
	}

	private getTerminalData(info: MvnoTerminalModel): MvnoTerminalSendData {
		const data: MvnoTerminalSendData = new MvnoTerminalSendData();
		data.val = info.terminalM.name2;
		data.price = info.terminalM.num2?.toString();
		data.imgBase64 = info.terminalImgBase64;
		return data;
	}

	private getTerminalColorData(info: MvnoTerminalColorModel): MvnoTerminalColorSendData {
		const data: MvnoTerminalColorSendData = new MvnoTerminalColorSendData();
		data.val = info.terminalColorM.name2;
		data.imgBase64 = info.base64;
		return data;
	}

	private getSimSizeData(val: string): MvnoSimSizeSendData {
		const data: MvnoSimSizeSendData = new MvnoSimSizeSendData();
		data.val = val;
		switch (val) {
			case 'nanoSIM':
				data.img = nanoSim;
				data.tate = '12.3mm';
				data.yoko = '8.8mm';
				break;
			case 'マイクロSIM':
				data.img = microSim;
				data.tate = '15mm';
				data.yoko = '12mm';
				break;
			case '標準SIM':
				data.img = standardSim;
				data.tate = '25mm';
				data.yoko = '15mm';
				break;
			default:
				return;
		}
		return data;
	}

	// 初期設定サポート設定(今回変更なし)
	private getInitialSupportData(val: string): MvnoInitialSupportSendData {
		const data: MvnoInitialSupportSendData = new MvnoInitialSupportSendData();
		data.val = val;
		switch (val) {
			case '必要':
				data.decription = 'サポート充実で安心！面倒な初期設定サポートもお任せください！\n※お申込みの方はのちほど希望日をご入力いただきます。';
				break;
			case '不要':
				data.decription = '';
				break;
			// case 'エレコム MPA-ACC20BK':
			// 	data.decription = 
			// 	break;
			default:
				break;
		}
		return data;
	}

	// WEBフィルタリング
	private getWebFilteringData(val: string, cd:string): MvnoInitialSupportSendData {
		const data: MvnoWebFilteringSendData = new MvnoWebFilteringSendData();
		data.val = val;
		const price = (this.view as MvnoEntryPlan3View).getWebFilteringPrice(cd);
		const account = (this.view as MvnoEntryPlan3View).getWebFilteringAccount(cd);
		switch (val) {
			case '必要':
				data.decription = '月額利用料：' + price + '円／台（税込）';
				data.account = account;
				break;
			case '不要':
				data.decription = '\n';
				break;
			default:
				break;
		}
		return data;
	}

	// 端末延長保証
	private getTerminalGuartee(val: string): MvnoInitialSupportSendData {
		const data: MvnoInitialSupportSendData = new MvnoInitialSupportSendData();
		data.val = val;
		const price = (this.view as MvnoEntryPlan3View).getTerminalGuaranteePrice();
		switch (val) {
			case '必要':
				data.decription = '月額利用料：' + price + '円／台（税込）';
				break;
			case '不要':
				// data.decription = '';
				break;
			default:
				break;
		}
		return data;
	}

	// getSimOptionData旧
	// private getSimOptionData(val: string): MvnoSimOptionSendData {
	// 	const data: MvnoSimOptionSendData = new MvnoSimOptionSendData();
	// 	data.val = val;
	// 	data.decription = '家庭用コンセントからスマートフォンやタブレットなどを充電できるAC充電器';
	// 	data.img = simOption;
	// 	return data;
	// }
	private simOptionName: string = null;
	// getSimOptionData新
	private getSimOptionData(): MvnoSimOptionSendData {
		const data: MvnoSimOptionSendData = new MvnoSimOptionSendData();
		if (!this.viewInfo.judgeTerminal()) {
			data.val = 'エレコム MPA-ACC20BK';
			data.decription = '家庭用コンセントからスマートフォンやタブレットなどを充電できるAC充電器';
			data.img = simOption;
		} else {
			data.val = 'エレコム MPA-ACCP17WH';
			data.decription = 'USB Power Delivery認証済みのUSB Type-C(TM)(USB-C(TM))ポートを搭載し、対応するスマートフォン、タブレットなどを超高速充電できるUSB AC充電器';
			data.img = iPhoneSimOption;
		}
		this.simOptionName = data.val;
		return data;
	}

	// リスト描画  ---------------------------------------------------------------
	private static STOCK_NUM: number = 20;
	/**
	 * リスト名=CapacityRadioList
	 */
	public onTerminalRadioListRender(rowInfo: WprRowInfo): any {
		const data: MvnoTerminalModel = rowInfo.rowData as MvnoTerminalModel;
		const index: string = String(rowInfo.index);
		let colorList: any = this.getColorList(data);

		const radioLength: number = (this.view as MvnoEntryPlan3View).getTerminalListLength();

		let dispColorList: any = new Array();
		if (rowInfo.index % 2 == 0) {
			this.colorList = colorList;
			if (radioLength == rowInfo.index + 1) {
				dispColorList.push(colorList);
			}
		}
		else {
			dispColorList.push(this.colorList);
			dispColorList.push(colorList);
		}

		const val: string = data.terminalM.generalCd;
		let disabled: boolean = true;
		if (data.mvnoTerminalColorModelList != null) {
			data.mvnoTerminalColorModelList.forEach(color => {
				if (color.stockNum >= MvnoEntryPlan3.STOCK_NUM) disabled = false;	//一つでも在庫数が十分な場合はdisabled = false
			});
		}
		else {
			disabled = data.stockNum < MvnoEntryPlan3.STOCK_NUM;
		}
		// const nostockClassName: string = disabled ? 'noStock' : '';
		return (
			<>
				<Grid item xs={12} md={6}>
					<CheckMarkRadio name='terminalId'
						id={'terminalName_' + index}
						value={val}
						itemType={CheckMarkRadioContent.MVNO_TERMINAL}
						radioContentData={this.getTerminalData(data)}
						// className={nostockClassName}
						disabled={disabled}
						view={this.view} />
				</Grid>
			</>
		);
	}

	public onTerminalColorListRender(rowInfo: WprRowInfo): any {
		const data: MvnoTerminalModel = rowInfo.rowData as MvnoTerminalModel;
		let colorList: any = this.getColorList(data);

		const radioLength: number = (this.view as MvnoEntryPlan3View).getTerminalListLength();

		let dispColorList: any = new Array();
		if (rowInfo.index % 2 == 0) {
			this.colorList = colorList;
			if (radioLength == rowInfo.index + 1) {
				dispColorList.push(colorList);
			}
		}
		else {
			dispColorList.push(this.colorList);
			dispColorList.push(colorList);
		}

		const val: string = data.terminalM.generalCd;
		let disabled: boolean = true;
		if (data.mvnoTerminalColorModelList != null) {
			data.mvnoTerminalColorModelList.forEach(color => {
				if (color.stockNum >= MvnoEntryPlan3.STOCK_NUM) disabled = false;	//一つでも在庫数が十分な場合はdisabled = false
			});
		}
		else {
			disabled = data.stockNum < MvnoEntryPlan3.STOCK_NUM;
		}
		// const nostockClassName: string = disabled ? 'noStock' : '';
		return (
			<>
				<WprContainer mode='画面サイズ.中' view={this.view}>
					{colorList}
				</WprContainer>
				<WprContainer mode='画面サイズ.大' view={this.view}>
					{/* <div className='planSubTitle'>
						カラーをお選びください
					</div> */}
					{dispColorList}
				</WprContainer>
			</>
		);
	}

	private colorList: any = null;

	// 端末カラー描画
	private getColorList(terminalModel: MvnoTerminalModel): any {
		if (terminalModel.mvnoTerminalColorModelList == null || terminalModel.mvnoTerminalColorModelList.length == 0) return (<></>);


		const terminalName: string = terminalModel.terminalM.name2;
		let colorListRender: any = new Array();
		terminalModel.mvnoTerminalColorModelList.map((colorInfo, index) => {
			const disabled: boolean = (colorInfo.stockNum < MvnoEntryPlan3.STOCK_NUM);
			const nostockClassName: string = disabled ? 'noStock' : '';
			colorListRender.push(
				<>
					{/* <Grid item md={4} xs={6}>
						<MvnoTerminalColorRadio name='terminalColor' id={'terminalColor_' + index} value={colorInfo.color} terminalColorInfo={colorInfo} view={this.view} />
					</Grid> */}
					<Grid item xs={6} md={4}>
						<CheckMarkRadio
							name='terminalColorId'
							id={'terminalColor_' + index}
							value={colorInfo.terminalColorM.generalCd}
							itemType={CheckMarkRadioContent.MVNO_TERMINAL_COLOR}
							radioContentData={this.getTerminalColorData(colorInfo)}
							className={nostockClassName}
							disabled={disabled}
							view={this.view} />
					</Grid>
				</>
			);
		})
		return (
			<>
				<WprContainer mode={'端末名.' + terminalName} view={this.view}>
					<Grid item md={12}>
						<Grid container className='entryRadio' spacing={1}>
							<Grid item xs={12}>
								<div className='planSubTitle title_maru'>カラーをお選びください</div>
							</Grid>
							{colorListRender}
						</Grid>
					</Grid>
				</WprContainer>
			</>
		);
	}
}
