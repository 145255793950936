import React, { ReactNode } from 'react';
import { WprBaseViewComponent } from '../../../../../wpr-framework/view/component/WprBaseViewComponent';
import { ItscomHomeReviceComfirmView } from './ItscomHomeReviceComfirmView';
import { Typography, TableCell, Grid, Table, TableBody, Divider } from '@material-ui/core';
import { ProgressBar } from '../../../common/progress_bar/ProgressBar';
import { MatTypography } from '../../../../../wpr-framework/component/controls/material/MatTypography';
import { WprRowInfo } from '../../../../../wpr-framework/component/props/WprRowInfo';
import { MatTableRow } from '../../../../../wpr-framework/component/controls/material/MatTableRow';
import { MatTableContainer } from '../../../../../wpr-framework/component/controls/material/MatTableContainer';
import { MatButton } from '../../../../../wpr-framework/component/controls/material/MatButton';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';

/**
 * ITSCOMHOME_オプション変更(申込み内容の確認)用コンポーネント
 */
export class ItscomHomeReviceComfirm extends WprBaseViewComponent {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: any) { super(new ItscomHomeReviceComfirmView(), props); }
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** ITSCOMHOME_オプション変更(申込み内容の確認)ビュー情報 */
	public get viewInfo(): ItscomHomeReviceComfirmView	{ return this.view as ItscomHomeReviceComfirmView;	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * リストコントロール定義登録
	*/
	protected onRegisterListRender(): void {
		this.addListRender('OptRivComfirmListModel', null, this.onOptRivComfirmListModelRender);
	}

	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		return (
			<>
			<div className='pageCard'>
				<div className="pageHeader stepFlow">
					<ProgressBar name='progressBar' view={this.view} />
				</div>
				<div className='main'>
					<div className='pageTitle'>
						<div className='title_left'>
							<MatTypography name='title' className='title_text' view={this.view}/>
							<Typography className='title_sentence'>以下のお申込みでよろしければ、「申込み」ボタンをクリックしてください。</Typography>
						</div>
						<Grid container>
							<Grid item xs={12}>
								<MatTableContainer listName={'OptRivComfirmListModel'} view={this.view}>
									<Table className ='optionTable'>
										<colgroup>
											<col/>
											<col/>
										</colgroup>
										<TableBody className='optionBody'>
											{this.renderList('OptRivComfirmListModel')}
										</TableBody>
									</Table>
								</MatTableContainer>
							</Grid>
						</Grid>
					</div>
				</div>
				<Divider className='confirm_underSpace'/>
				<div className='pageFooter footer-space'>
					<div>
						<MatButton name='next' view={this.view} className='orangeButton orangeButton_parts' >
							この内容で申込む
						<NavigateNextIcon />
						</MatButton>
					</div>
					<div>
						<MatButton name='back' view={this.view} className='backButton' >
						<NavigateBeforeIcon />
							戻る
						</MatButton>
					</div>
				</div>
				</div>
			</>
		);
	}
	// --------------------------------------------------------------------------

	// リスト描画  ---------------------------------------------------------------
	/**
	 * リスト名=OptRivComfirmListModel
	 */
	public onOptRivComfirmListModelRender(rowInfo: WprRowInfo): any {
		return (
			<MatTableRow view={this.view} row={rowInfo} className='hedderrow'>
				<TableCell>
					<MatTypography name='option' view={this.view} row={rowInfo}/>
				</TableCell>
				<TableCell className='optionDetail'>
					<MatTypography name='detail' className='feeVal' view={this.view} row={rowInfo}/>
				</TableCell>
			</MatTableRow>
		);
	}
	// --------------------------------------------------------------------------
}
