/**
 * 移設モデル
 */
export class RelocateModel {
	// public 変数	-----------------------------------------------------------
	public appService: string;	// 移設コース
	public stb: string;			// セットトップボックス
	public rental:string;
	public contractId: string;	// 契約番号
	public floor: string;		// 階数
	public location: string;	// 場所
	public comment: string;		// フリー入力
	// ------------------------------------------------------------------------
}
