import { MypageBaseView } from '../../base/MypageBaseView';
import { WprModel } from '../../../../wpr-framework/view/deco/WprModel';
import { WprDI_ScopeModel } from '../../../../wpr-framework/view/di/WprDI_ScopeModel';
import { MypageWizardMngr } from '../../wizard/models/MypageWizardMngr';
import { ProgressBarData } from '../../common/progress_bar/ProgressBarView';
import { WprViewMode } from '../../../../wpr-framework/view/deco/WprViewMode';
import { TokotonSupportSelect } from './TokotonSupportSelect';
import { AgreeMode } from '../../common/common_agree/mode/AgreeMode';
import { MDisplay } from '../../../service/models/entity/secondary/MDisplay';
import { WprDI_Control } from '../../../../wpr-framework/view/di/WprDI_Control';
import { WprControlInfo } from '../../../../wpr-framework/view/control/WprControlInfo';
import { WprKeyValue } from '../../../../wpr-framework/model/WprKeyValue';
import { ServiceModel } from '../../../service/models/apimodels/ServiceModel';
import { ObjectUtil } from '../../../common/util/ObjectUtil';
import { TokotonSupportAppModel } from '../models/TokotonSupportAppModel';
import MypageDispdivcdConstants from '../../../common/MypageDispdivcdConstants';

/**
 * とことんサポートサービス選択
 */
export class TokotonSupportSelectView extends MypageBaseView {
	
	// コンストラクタ  -----------------------------------------------------------
	public constructor() { super('TokotonSupportSelectView'); }
	// --------------------------------------------------------------------------

	// DI情報  ------------------------------------------------------------------
	@WprDI_Control
	private m_applicationType: WprControlInfo	= null;	// とこサポ申込み種別
	// --------------------------------------------------------------------------

	// モデル  ------------------------------------------------------------------
	@WprDI_ScopeModel('MypageWizardMngr')
	private m_MypageWizardMngr: MypageWizardMngr = null;	// 画面ウィザード用モデル
	@WprModel('TokotonSupportAppModel')
	private m_TokotonSupportAppModel: TokotonSupportAppModel	= null;	// とことんサポートモデル
	// --------------------------------------------------------------------------

	// サブビュー/モード  --------------------------------------------------------
	@WprViewMode
	private m_AgreeMode = new AgreeMode();
	// private m_ConfirmMode = new ConfirmMode();	// 申し込みモード
	// --------------------------------------------------------------------------

	// private変数  ------------------------------------------------------------------
	private m_MDisplayList1: MDisplay[];	// 申込み種別取得用リスト
	private m_MDisplayList2: MDisplay[];	// コース名取得用リスト
	private m_ServiceList: ServiceModel[] 	= null;	// サービスリスト
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * コントロール定義設定
	 */
	public onRegisterConfig(): void {
		// this.addConfig('tokotonSupportType', 'ご契約種別', 'TokotonSupportSelectModel', { bindName: 'contracttype' });
		this.addConfig('confirm', '承諾', null);
		this.addConfig('applicationType', 'とこサポ申込み種別', null, { hissu: true });
		this.addConfig('courseNm', 'コース名', 'TokotonSupportAppModel');
		this.addConfig('supportFlg', 'サポート対応を希望する', 'TokotonSupportAppModel');
	}

	/**
	* アクション登録
	*/
	public onRegisterAction(): void {
		this.addAction('next', this.onNext, true);
		this.addAction('back', this.onBack);
	}

	/**
	 * ビュー表示通知
	 */
	public onShowView(): void {
		this.m_MypageWizardMngr.initView(this, (result) => {
			this.registAccessLog();
			this.setStorageItem('b_url', window.location.href)
			const sendData: ProgressBarData = new ProgressBarData();
			sendData.progressBarList = this.m_MypageWizardMngr.getProgressModelList(this);
			this.sendChildData('fP_ev_progressBarIntital', sendData);
			// 申込み種別取得
			this.m_MDisplayList1 = [] 
			this.m_MDisplayList1 = this.m_MypageWizardMngr.getMDisplayList(this, MypageDispdivcdConstants.TOKOTON_SUPPORT_APPLICATION_TYPE, '');
			this.m_applicationType.resetValueMap();
			this.m_applicationType.value = '0';
			this.m_TokotonSupportAppModel = new TokotonSupportAppModel();
			this.m_TokotonSupportAppModel = this.m_MypageWizardMngr.getCache().m_ApplicationInfo;
			// コース名取得
			this.m_MDisplayList2 = [];
			this.m_MDisplayList2 = this.m_MypageWizardMngr.getMDisplayList(this, MypageDispdivcdConstants.TOKOTON_SUPPORT_COURSE_NAME, '');
			if (ObjectUtil.isNotNullOrEmptyArray(this.m_MDisplayList2)) {
				this.m_TokotonSupportAppModel.courseNm = this.m_MDisplayList2[0].dispnamep;
			}
			// 確認画面から戻った際に入力画面で選択したものになるように
			if(this.m_TokotonSupportAppModel.applicationType){
				const changeVm = this.vm('applicationType','とこサポ申込み種別');
				for(const value of changeVm){
					if(value.value === this.m_TokotonSupportAppModel.applicationType){
						this.m_applicationType.value = value.key;
					}
				}
			}
			if (this.m_applicationType.value === '0')
				this.setViewMode('サポート対応', '表示');
			this.refreshView();
		});
		this.setViewMode('agree','未承諾');
	}
	// --------------------------------------------------------------------------
	/**
	 * 値変更通知
	 * @param name 名前
	 * @param value 値
	 * @returns falseの場合、変更を取り消す
	 */
	public onChangeValue(name: string, value: any): boolean {
		if (name === 'confirm')	{
			if(value === true)
				this.setViewMode('agree','承諾');
			else
				this.setViewMode('agree','未承諾');
		}
		if (name === 'applicationType') {
			// 「即日開始」の場合のみ
			if (value === '0')
				this.setViewMode('サポート対応', '表示');
			else
				this.setViewMode('サポート対応', '非表示');
		}
		return true;
	}

	/**
	 * ValueMap再設定処理
	 * @param name コントロール名
	 * @param id ValueMapID
	 * @list ValueMapのKey-Valueリスト
	 * @returns 再設定したKey-Valueリスト
	 */
	public onResetValueMap(name: string, id: string, list: WprKeyValue[]): WprKeyValue[] {
		if(name === 'applicationType' && this.m_MDisplayList1){
			list = new Array();
			for (const model of this.m_MDisplayList1) {
				// 「翌日開始」以外
				if (model.namecd === '1')
					continue;
				else
					list.push(new WprKeyValue(model.namecd, model.dispnamep));
			}
		}
		return list;
	}
	
	// アクション  ---------------------------------------------------------------
	/**
	 * 申し込む
	*/
	public onNext(param: any): void {
		// 選択した申込み種別
		const changeValue = this.vm('applicationType','とこサポ申込み種別');
		for(const value of changeValue){
			if(value.key === this.m_applicationType.value) {
				this.m_TokotonSupportAppModel.applicationType = value.value;
			}
		}
		this.refreshModel();
		if (this.m_applicationType.value !== '0')
			this.m_TokotonSupportAppModel.supportFlg = false;
		this.m_MypageWizardMngr.goNext(this);
	}

	/**
	 * 戻る
	*/
	public onBack(param: any): void {
		this.m_MypageWizardMngr.goBack(this);
	}
	// --------------------------------------------------------------------------
}
