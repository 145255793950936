import { Grid, Typography } from '@material-ui/core';
import CancelIcon from '@mui/icons-material/Cancel';
import { ReactNode } from 'react';
import { MatButton } from '../../../../wpr-framework/component/controls/material/MatButton';
import { MatTypography } from '../../../../wpr-framework/component/controls/material/MatTypography';
import { WprBaseViewComponent } from '../../../../wpr-framework/view/component/WprBaseViewComponent';
import { ReiAccesspassPrintView } from './ReiAccesspassPrintView';
import itscomIcon from '/shared/image/common/itscomMyp_icon_blue.png';
import styles from './ReiAccesspassPrint.scss';
import netIcon from '/shared/image/common/logo_iTSCOMNET.png';
import purpleIcon from '/shared/image/common/important_purple.png'

/**
 * アクセスアカウントパスワード発行印刷用コンポーネント
 */
export class ReiAccesspassPrint extends WprBaseViewComponent {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: any) { super(new ReiAccesspassPrintView(), props); }
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** アクセスアカウントパスワード発行印刷ビュー情報 */
	public get viewInfo(): ReiAccesspassPrintView	{ return this.view as ReiAccesspassPrintView;	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * スタイルオブジェクト取得
	*/
	public getStylesObject(): any { return styles; }

	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		return (
			<>
			<div>
				<div className='icon-hearder'>
					<img src={itscomIcon} style={{ maxWidth: '65%' }} />
				</div>
				<div className='card'>
					<div className='sub-logo'>
						<img src={netIcon}></img>
					</div>
					<div className='main'>
						<div className='pageTitle'>
							<div className='title_left'>
								<MatTypography name='title' className='title_text' view={this.view}/>
								<Typography className='title_comment'>あなたの登録情報です。大切に保管してください。</Typography>
							</div>
						</div>
						<div className='gray'>
							<Grid container>
								<Grid item xs={12} md={4} className='grid-print-4'>
									<div>コース</div>
								</Grid>
								<Grid item xs={12} md={8} className='grid-print-8'>
									<MatTypography name={'course'} view={this.view} className='itemUnit bold-black'></MatTypography>
								</Grid>
							</Grid>	
							<Grid container>
								<Grid item xs={12} md={4} className='grid-print-4' >
									<div>アクセスアカウント</div>
								</Grid>
								<Grid item xs={12} md={8} className='grid-print-8'>
									<MatTypography name={'accessAccount'} view={this.view} className='itemUnit bold-black'></MatTypography>
								</Grid>
							</Grid>	
							<Grid container>
								<Grid item xs={12} md={4} className='grid-print-4' >
									<div>パスワード</div>
								</Grid>
								<Grid item xs={12} md={8} className='grid-print-8'>
									<MatTypography name={'password'} view={this.view} className='itemUnit bold-black'></MatTypography>
								</Grid>
							</Grid>	
							<Grid container>
								<Grid item xs={12} md={4} className='grid-print-4a'>
									<div>パスワード読み仮名</div>
								</Grid>
								<Grid item xs={12} md={8} className='grid-print-8a'>
									<div dangerouslySetInnerHTML={{ __html: this.state.data.sentenceData }}  className='itemUnit bold-black'/>
								</Grid>
							</Grid>	
							<Grid container>
								<Grid item xs={12} md={4} className='grid-print-4'>
									<div>変更日</div>
								</Grid>
								<Grid item xs={12} md={8} className='grid-print-8'>
									<MatTypography name={'date'} view={this.view} className='itemUnit bold-black'></MatTypography>
								</Grid>
							</Grid>		
						</div>
						<div className='title_left'>
							<Typography className='title_memo'>※パスワードの読み仮名にアンダーラインが引かれている文字は大文字を意味します。</Typography>
						</div>
						<div className='supportMsgArea2'>
							<span className='textcolor'><img src={purpleIcon} alt="purple" style={{verticalAlign: 'middle'}}/> ルーター等へ設定する際には、以下のようにご入力いただく必要がございます。</span>

							<div className='color'>・アクセスアカウントの最後が「@a01」で終わる場合：「アクセスアカウント@itscom.net」と入力します。<br />
							 (例) a1234567@a01@itscom.net<br />
							<br />
							・それ以外の場合：「アクセスアカウント@246.ne.jp」と入力します。<br />
							 (例) 1234567@246.ne.jp
							</div>
						</div>
					</div>
					<div className='pageFooter footer-space printButton'>
						<div>
							<MatButton name='print' view={this.view} className='orangeButton orangeButton_parts' >
								印刷
							</MatButton>
						</div>
						<div>
							<MatButton name='close' view={this.view} className='backButton' >
								<CancelIcon />
								閉じる
							</MatButton>

						</div>
					</div>
				</div>
			</div>
			</>
		);
	}
	// --------------------------------------------------------------------------
}
