import { WprBaseConverter } from '../WprBaseConverter';

/**
 * 3桁カンマコンバータ
 */
export class WprCommaConverter extends WprBaseConverter {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() {
		super('3桁カンマ');
	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * 値を表示用の値に変換する (value -> viewValue)
	 * @param value 値
	 * @returns 表示用の値
	 */
	public convertView(value: any): string {
		if (!value)
			return value;
		if (typeof value == 'number')
			value = value.toString();
		try {
			let slist = String(value).split('.');
			let ret = slist[0];
			if (ret !== '0')
				ret = String(slist[0].replace(/^0+/, '')).replace( /(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
			if (slist.length > 1)
				ret += '.' + slist[1];
			return ret;
		}
		catch (ex) {
			return value;
		}
	}
	// --------------------------------------------------------------------------
}
