import { WprBaseMngr } from '../WprBaseMngr';
import { WprSettingInfo } from '../WprSettingInfo';
import { WprErrorMngr } from '../error/WprErrorMngr';
import { WprValueMap } from './WprValueMap';
import { WprValueMapSetter } from './WprValueMapSetter';
import { WprKeyValue } from './WprKeyValue';
import { WprDecoInfoList } from './WprDecoInfoList';
import { WprBaseViewCore } from '../view/WprBaseViewCore';
import { WprDecoInfoMap } from './WprDecoInfoMap';
import { WprBaseObject } from '../WprBaseObject';
import { WprBaseFrameworkComponent } from '../component/base/WprBaseFrameworkComponent';
import { WprViewMngr } from '../view/WprViewMngr';
import { WprBaseDIManage } from '../view/di/WprBaseDIManage';
import { WprXmlInfoCreator } from '../debug/WprXmlInfoCreator';
import { WprScopeMngr } from '../scope/WprScopeMngr';

/**
 * モデル管理機能
 */	
export class WprModelMngr extends WprBaseMngr {
	// private 変数  ------------------------------------------------------------
	private m_VMMap: Map<string, WprValueMap>				= new Map<string, WprValueMap>();		// ValueMapマップ
	private m_ErrorMngr: WprErrorMngr						= null;									// エラー管理機能
	private m_ViewMngr: WprViewMngr							= null;									// ビュー管理機能
	private m_ScopeMngr: WprScopeMngr						= null;									// スコープ管理機能
	private m_DecoNameMap: Map<string, WprDecoInfoMap>		= new Map<string, WprDecoInfoMap>();	// デコレータ名称マップ
	private m_DecoNameList: Map<string, WprDecoInfoList>	= new Map<string, WprDecoInfoList>();	// デコレータ名称リスト
	private m_LoadWaitList: string[]						= new Array();							// ロード待ちリスト
	private m_BaseDIObjectMap: Map<string, WprBaseObject>	= new Map<string, WprBaseObject>();		// ベースDI情報マップ
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	public constructor() {
		super('モデル管理機能');
	}
	// --------------------------------------------------------------------------

	// override メソッド	-----------------------------------------------------
	/**
	 * 初期化処理
	 * @param loader データローダ
	 * @param setting 設定情報
	 */
	public initialize(loader: WprBaseFrameworkComponent, setting: WprSettingInfo): void {
		loader.onRegisterValueMap(new WprValueMapSetter(this, this.m_ScopeMngr));
	}

	/**
	 * 他の管理機能設定処理
	 * @param mngrMap 管理機能マップ
	 */
	public setManager(mngrMap: Map<string, WprBaseMngr>): void {
		this.m_ErrorMngr = mngrMap.get('エラー管理機能') as WprErrorMngr;
		this.m_ViewMngr = mngrMap.get('ビュー管理機能') as WprViewMngr;
		this.m_ScopeMngr = mngrMap.get('スコープ管理機能') as WprScopeMngr;
	}
	// --------------------------------------------------------------------------

	// public メソッド	----------------------------------------------------------
	/**
	 * ValueMap登録
	 * @param id ValueMapのID
	 * @param vm ValueMap
	 */
	public addValueMap(id: string, vm: WprValueMap): void {
		if (this.m_VMMap.has(id) === false) {
			this.m_VMMap.set(id, vm);
			if (this.m_LoadWaitList.includes(id) === true) {
				const idx = this.m_LoadWaitList.indexOf(id);
				this.m_LoadWaitList.splice(idx, 1);
				this.m_ViewMngr.onLoadEndValueMap(id);
			}
		}
		else {
			this.m_ErrorMngr.addErrorLog('WprLoader', `同一のValueMapが既に登録されています。[${id}]`);
		}
	}

	/**
	 * ValueMap取得
	 * @param id ValueMapのID
	 * @returns ValueMap
	 */
	public getValueMap(id: string): WprValueMap {
		if (this.m_VMMap.has(id) === true)
			return this.m_VMMap.get(id);
		return null;
	}

	/**
	 * ValueMap登録チェック処理
	 * @param id ValueMapのID
	 * @returns trueの場合、ValueMapは登録済み
	 */
	public hasValueMap(id: string): boolean {
		return this.m_VMMap.has(id);
	}

	/**
	 * ValueMapから候補リスト取得
	 * @param id ID
	 * @param hasNull Null選択フラグ
	 * @returns Key-Valueリスト
	 */
	public getValueList(id: string, hasNull?: boolean): WprKeyValue[] {
		if (this.m_VMMap.has(id) === true)
			return this.m_VMMap.get(id).getCollection(hasNull);
		return new Array();
	}

	/**
	 * ValueMap登録チェック
	 * @param id ID
	 * @returns trueの場合、登録されている
	 */
	public checkValueList(id: string): boolean {
		return this.m_VMMap.has(id);
	}

	/**
	 * デコレータマップ情報追加
	 * @param type タイプ
	 * @param obj 対象オブジェクト
	 * @param modelName モデル名
	 * @param props プロパティ名
	 */
	public addDecoMapInfo(type: string, obj: WprBaseObject, modelName: string, props: string): void {
		if (this.m_DecoNameMap.has(type) === false)
			this.m_DecoNameMap.set(type, new WprDecoInfoMap());
		this.m_DecoNameMap.get(type).addDecoProperty(obj, modelName, props);
	}

	/**
	 * デコレータ名称マップ取得
	 * @param type タイプ
	 * @param obj 対象オブジェクト
	 * @returns デコレータマップ
	 */
	public getDecoNameMap(type: string, obj: WprBaseObject): Map<string, string> {
		const diManage = obj as WprBaseDIManage;
		if (this.m_DecoNameMap.has(type) === true) {
			const map = new Map<string, string>();
			if (diManage != null) {
				const nameList = diManage.getBaseNameList();
				nameList.forEach(baseName =>{
					if (this.m_BaseDIObjectMap.has(baseName) === true) {
						const bmap = this.m_DecoNameMap.get(type).getPropertyMap(this.m_BaseDIObjectMap.get(baseName));
						bmap.forEach((value, key) => {
							map.set(key, value);
						});
					}
				});
			}
			const cmap = this.m_DecoNameMap.get(type).getPropertyMap(obj);
			cmap.forEach((value, key) => {
				map.set(key, value);
			});
			return map;
/*
			if (diManage != null) {
				const baseName = diManage.getBaseName();
				if (baseName != null && this.m_BaseDIObjectMap.has(baseName) === true) {
					const bmap = this.m_DecoNameMap.get(type).getPropertyMap(this.m_BaseDIObjectMap.get(baseName));
					if (bmap.size > 0) {
						const map = new Map<string, string>();
						bmap.forEach((value, key) => {
							map.set(key, value);
						});
						const cmap = this.m_DecoNameMap.get(type).getPropertyMap(obj);
						cmap.forEach((value, key) => {
							map.set(key, value);
						});
						return map;
					}
				}
			}
			return this.m_DecoNameMap.get(type).getPropertyMap(obj);
			*/
		}
		return new Map<string, string>();
	}

	/**
	 * デコレータリスト情報追加
	 * @param type タイプ
	 * @param obj 対象オブジェクト
	 * @param props プロパティ名
	 */
	public addDecoListInfo(type: string, obj: WprBaseObject, props: string): void {
		if (this.m_DecoNameList.has(type) === false)
			this.m_DecoNameList.set(type, new WprDecoInfoList());
		this.m_DecoNameList.get(type).addDecoProperty(obj, props);
	}

	/**
	 * デコレータ名称リスト取得
	 * @param type タイプ
	 * @param obj 対象オブジェクト
	 * @returns モード名称リスト
	 */
	public getDecoNameList(type: string, obj: WprBaseDIManage): string[] {
		if (this.m_DecoNameList.has(type) === true)
			return this.m_DecoNameList.get(type).getPropertyList(obj, this.m_BaseDIObjectMap);
		return new Array();
	}

	/**
	 * ベースクラスDI情報追加
	 * @param baseName ベースクラス名
	 * @param obj オブジェクト
	 */
	public addBaseDIInfo(baseName: string, obj: WprBaseObject): void {
		if (this.m_BaseDIObjectMap.has(baseName) === false)
			this.m_BaseDIObjectMap.set(baseName, obj);
	}

	/**
	 * ロード待ちValueMap追加処理
	 * @param name Valuemap名
	 */
	public addLoadWaitValueMap(name: string) {
		if (this.m_LoadWaitList.includes(name) === false)
			this.m_LoadWaitList.push(name);
	}

	/**
	 * ロード中ValueMapチェック
	 * @param name Valuemap名
	 * @returns trueの場合、ロード中
	 */
	public isLoadValueMap(name: string): boolean {
		return this.m_LoadWaitList.includes(name);
	}

	/**
	 * デバッグ情報設定
	 * @param dc デバック情報作成情報
	 */
	public setDebugInfo(dc: WprXmlInfoCreator): void {
		const mngr = dc.addRootChildElement('model_mngr');
		const vms = dc.addChildElement(mngr, 'vms');
		this.m_VMMap.forEach((vmInfo, name) => {
			const vm = dc.addChildElement(vms, 'vm');
			dc.addAttribute(vm, 'name', name);
			vmInfo.getCollection().forEach(keyValue => {
				const kv = dc.addChildElement(vm, 'kv');
				dc.addAttribute(kv, 'key', keyValue.key);
				dc.addAttribute(kv, 'val', keyValue.value);
			});
		});
	}
	// --------------------------------------------------------------------------
}
