import { WizardModel } from '../../models/WizardModel';
import { MypageWizardFlow } from './MypageWizardFlow';
import { MypageBaseView } from '../base/MypageBaseView';
import { GetViewInfoRequest } from '../../service/models/dto/mypagerenewal/GetViewInfoRequest';
import { MDisplay } from '../../service/models/entity/secondary/MDisplay';
import { MagazineConfirmView } from '../magazine/magazine_confirm/MagazineConfirmView';
import { MCommon } from '../../service/models/entity/primary/MCommon';
import { MypageWizardMngr } from './models/MypageWizardMngr';
import { MagazineApplyCompleteView } from '../magazine/magazine_apply_complete/MagazineApplyCompleteView';
import { MagazineModel } from '../../service/models/apimodels/MagazineModel';
import { RegistInfoRequest } from '../../service/models/dto/mypagerenewal/RegistInfoRequest';
import { InquiryMailInfo } from '../../service/models/apimodels/InquiryMailInfo';
import { MAIL_CONSTANTS } from '../../common/MyPageMailIdConstants';

/**
 * ITSCOM マガジン_追加ウィザードクラス
 */
export class MagazineChangeWizardFlow extends MypageWizardFlow {
	// private 変数  ------------------------------------------------------------
	private m_DefaultWizardFlow: WizardModel[] = null;		// 画面ウィザードリスト
	private m_StartViewName: string = null;
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	public constructor() {
		super();
		this.m_StartViewName = new MagazineConfirmView().name;

		// 遷移パターン１
		this.m_DefaultWizardFlow = new Array();
		this.m_DefaultWizardFlow.push({ stepNum: 0, title: '契約選択', viewName: null, path: this.getOldUrl() + 'Srvlist/Continfo_list/' });
		this.m_DefaultWizardFlow.push({ stepNum: 1, title: '変更申込み', viewName: this.m_StartViewName, path: 'magazine_confirm' });
		this.m_DefaultWizardFlow.push({ stepNum: 2, title: '申込み完了', viewName: new MagazineApplyCompleteView().name, path: 'magazine_apply_complete' });
	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * 画面ウィザードフロー名取得
	 */
	public getWizardFlowName(viewName: string): string {
		if (viewName === this.m_StartViewName)
			return this.constructor.name;
		return null;
	}

	/**
	 * 画面ウィザードリスト取得：契約状態で切り替えたり、選択肢で切り替えたり
	 */
	public getWizardFlow(mngr: MypageWizardMngr): WizardModel[] {
		return this.m_DefaultWizardFlow;
	}

	/**
	 * 画面ウィザードAPI用モデル取得：契約状態で切り替えたり、選択肢で切り替えたり
	 */
	public getWizardApiModel(mngr: MypageWizardMngr, view: MypageBaseView): GetViewInfoRequest {
		const req = new GetViewInfoRequest();
		req.customerId = mngr.getParams().customerId;
		req.contractFlg = true;
		req.commonList = new Array();
		req.commonList.push(new MCommon('マガジン月額', 'charge_mag_info', 'price'));
		return req;
	}

	/**
	 * 画面ウィザード完了APIモデル取得
	 */
		public getRegistApiModel(mngr: MypageWizardMngr, view: MypageBaseView): RegistInfoRequest{
			const req: RegistInfoRequest = new RegistInfoRequest();
			let cacheData = mngr.getCache();
			req.customerId = mngr.getParams().customerId;
			const mailconstants = MAIL_CONSTANTS.find (mail => {
				return mail.view === 'Magaine_申込み';
			});
			// お客様メール
			req.customerMailId = mailconstants.customerMail;
			// 業務部門
			req.systemMailId = mailconstants.systemMail;
			// 問い合わせ
			req.inquiryId = mailconstants.inquiryMail;
			req.systemInfo = new InquiryMailInfo();
			req.systemInfo.magazineApp = '申込み';
			req.systemInfo.contractId = cacheData.m_MagazineInfo.contract.contractID;
			req.systemInfo.other = 'iTSCOM MAGAZINE申込み';
			return req;
		}
	

	/**
	 * 画面ウィザードフローの中断判定：例：申し込みが完了していれば、完了済みの画面へ遷移
	 */
	public checkFlowBreak(mngr: MypageWizardMngr): string {
		let test: boolean = false;
		if (test) {
			return '/error';
		}
		return null;
	}

	/**
	 * 画面キャッシュの初期化データ取得
	 */
	public getInitCacheData(mngr: MypageWizardMngr): MagazineChangeWizardModel {
		let cacheData = new MagazineChangeWizardModel();
		cacheData.m_MagazineInfo = new MagazineModel();
		return cacheData;
	}
	// ------------------------------------------------------------------------
}
/**
 * 画面ウィザードAPI用モデル
 */
export class MagazineChangeWizardModel {
	// public 変数	-----------------------------------------------------------
	public m_MagazineInfo: MagazineModel = null;	// Itscomマガジンモデル
	// ------------------------------------------------------------------------
}
