/**
 * 名義変更 旧名義人入力情報
 */
export class NameChangeInfo {
	// public 変数	-----------------------------------------------------------
	public nameChangeKbn: string;		// 名義変更区分
	public newName: string;				// 新名義人名
	public newNameKana: string;			// 新名義人名カナ
	public relationShip: string;		// 続柄
	public gender: string;				// 新名義人 性別
	public birthday: string;			// 新名義人 生年月日
	public phoneNumber1: string;		// 新名義人 電話番号1
	public phoneNumber2: string;		// 新名義人 電話番号2
	public newMail: string;				// 新名義人 メールアドレス
	// ------------------------------------------------------------------------
}
