// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@charset \"UTF-8\";\n/*\r\n * MVNOヘルプダイアログ用CSS\r\n */\n.MvnoHelpDialog-itemTitle {\n  font-style: normal;\n  font-weight: 700;\n  font-size: 16px;\n  line-height: 140%;\n  /* or 22px */\n  color: #000000; }\n", ""]);
// Exports
exports.locals = {
	"itemTitle": "MvnoHelpDialog-itemTitle"
};
module.exports = exports;
