import { WprBaseDIManage } from '../view/di/WprBaseDIManage';

/**
 * スコープ更新プロパティ情報
 */
export class WprScopeUpdatePropertyInfo {
	// private 変数  ------------------------------------------------------------
	private m_Obj: WprBaseDIManage	= null;		// 対象情報
	private m_Name: string			= null;		// モデル名
	private m_PropName: string		= null;		// プロパティ名
	// --------------------------------------------------------------------------

	// コンストラクタ	----------------------------------------------------------
	constructor(obj: WprBaseDIManage, name: string, prop: string) {
		this.m_Obj = obj;
		this.m_Name = name;
		this.m_PropName = prop;
	}
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** 対象情報 */
	public get obj(): WprBaseDIManage 	{ return this.m_Obj; 		}
	/** モデル名 */
	public get name(): string 			{ return this.m_Name; 		}
	/** プロパティ名 */
	public get propName(): string 		{ return this.m_PropName; 	}
	// --------------------------------------------------------------------------
}