import { WprListData } from '../../../../../wpr-framework/view/deco/WprListData';
import { WprModel } from '../../../../../wpr-framework/view/deco/WprModel';
import { WprViewMode } from '../../../../../wpr-framework/view/deco/WprViewMode';
import { WprDI_ScopeModel } from '../../../../../wpr-framework/view/di/WprDI_ScopeModel';
import { WprValidError } from '../../../../../wpr-framework/view/validation/WprValidError';
import MypageCodeTypeConstants from '../../../../common/MypageCodeTypeConstants';
import MypageDispdivcdConstants from '../../../../common/MypageDispdivcdConstants';
import { DateUtil } from '../../../../common/util/DateUtil';
import { ObjectUtil } from '../../../../common/util/ObjectUtil';
import { Contract } from '../../../../models/bss/contract/Contract';
import { TvBaseModel } from '../../../../models/mypage/tv/TvBaseModel';
import { TvOptionListModel } from '../../../../models/mypage/tv/TvOptionListModel';
import { GetCampaignRequest } from '../../../../service/models/dto/mypagerenewal/GetCampaignRequest';
import { GetGradeRequest } from '../../../../service/models/dto/mypagerenewal/GetGradeRequest';
import { ErrorModel } from '../../../Srvother/add_nhk_select/models/ErrorModel';
import { MypageBaseView } from '../../../base/MypageBaseView';
import { ProgressBarData } from '../../../common/progress_bar/ProgressBarView';
import { MypageWizardMngr } from '../../../wizard/models/MypageWizardMngr';
import { TvCheckBoxMode } from './mode/TvCheckBoxMode';

/**
 * テレビ変更選択画面2
 */
export class ModTvSelect2View extends MypageBaseView {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() { super('ModTvSelect2View'); }
	// --------------------------------------------------------------------------

	// モデル  ------------------------------------------------------------------
	@WprDI_ScopeModel('MypageWizardMngr')
	private m_MypageWizardMngr: MypageWizardMngr			= null;		// 画面ウィザード管理クラス
	@WprListData('TvOptionListModel')
	private m_TvOptionListModel: TvOptionListModel[]	= null;	// テレビオプションリストモデル
	@WprModel('TvBaseModelBefore')
	private m_BeforeTvBaseModel: TvBaseModel	= null;	// テレビ基本情報モデル
	@WprModel('TvBaseModelAfter')
	private m_AfterTvBaseModel: TvBaseModel	= null;	// テレビ基本情報モデル
	@WprListData('ErrorModel')
	private m_ErrorModelList: ErrorModel[]	= null;	// エラーモデル
	// --------------------------------------------------------------------------

	// private変数 -------------------------------------------------------------
	private m_Contract: Contract = null;
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** ウィザード定義 */
	public get mngr(): MypageWizardMngr 				{ return this.m_MypageWizardMngr; }
	// --------------------------------------------------------------------------

	// サブビュー/モード  --------------------------------------------------------
	@WprViewMode
	private m_TvCheckBoxMode = new TvCheckBoxMode();	// テレビチェックボックスモード
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * コントロール定義設定
	 */
	public onRegisterConfig(): void {
		this.addListConfig('select', '選択', 'TvOptionListModel');
		this.addListConfig('option', 'オプション', 'TvOptionListModel');
		this.addListConfig('amount', '月額', 'TvOptionListModel', { converter: '3桁カンマ' });
		this.addConfig('courseB', 'コース', 'TvBaseModelBefore', { bindName: 'course' });
		this.addConfig('amountB', '金額', 'TvBaseModelBefore', { bindName: 'amount' });
		this.addConfig('courseA', 'コース', 'TvBaseModelAfter', { bindName: 'course' });
		this.addConfig('amountA', '金額', 'TvBaseModelAfter', { bindName: 'amount' });
		this.addConfig('terminalB', '機器名', 'TvBaseModelBefore', { bindName: 'terminal' });
		this.addConfig('terminalA', '機器名', 'TvBaseModelAfter', { bindName: 'terminalDisp' });
		this.addConfig('applyType', '購入、レンタル ( 表示用 )', 'TvBaseModelBefore');
		this.addConfig('rental', 'レンタル', 'TvBaseModelAfter');
		this.addConfig('noRental', 'レンタルなし', 'TvBaseModelAfter');
		this.addConfig('magazine', 'マガジン ( 表示用 )', 'TvBaseModelBefore', { bindName: 'magazineDisp' });
		this.addConfig('magazineFee', 'マガジン月額', 'TvBaseModelBefore', { bindName: 'magazineFee' });
		this.addConfig('otokuApply', 'お得パック申込む', 'TvBaseModelAfter');
		this.addConfig('otokuUnapply', 'お得パック申込まない', 'TvBaseModelAfter');
		this.addConfig('securityFlg', 'セキュリティフラグ', 'TvBaseModelAfter');
		this.addConfig('securityComment', 'セキュリティフリー入力', 'TvBaseModelAfter');
		this.addConfig('desiredDate1', '第一希望', 'TvBaseModelAfter', { emptyValue: null, max: this.m_MypageWizardMngr.getMaxReqDate(), min: this.m_MypageWizardMngr.getMinReqDate() });
		this.addConfig('selectTime1', '第一希望　時間帯', 'TvBaseModelAfter', { emptyValue: null });
		this.addConfig('desiredDate2', '第二希望', 'TvBaseModelAfter', { emptyValue: null, max: this.m_MypageWizardMngr.getMaxReqDate(), min: this.m_MypageWizardMngr.getMinReqDate() });
		this.addConfig('selectTime2', '第二希望　時間帯', 'TvBaseModelAfter', { emptyValue: null });
		this.addConfig('desiredDate3', '第三希望', 'TvBaseModelAfter', { emptyValue: null, max: this.m_MypageWizardMngr.getMaxReqDate(), min: this.m_MypageWizardMngr.getMinReqDate() });
		this.addConfig('selectTime3', '第三希望　時間帯', 'TvBaseModelAfter', { emptyValue: null });
		this.addConfig('campaignCode', '入力キャンペーンコード', 'TvBaseModelAfter');
		this.addListConfig('errorMessage', 'エラーメッセージ', 'ErrorModel');
		this.addConfig('beforeContact', '前日連絡先', 'TvBaseModelAfter', { maxLength: 11, validation: '整数' });
		this.addConfig('sameDayContact', '当日連絡先', 'TvBaseModelAfter', { maxLength: 11, validation: '整数' });
		this.addConfig('name', '立ち合い者名', 'TvBaseModelAfter', { bindName: 'presentName' });
	}

	/**
	 * アクション登録
	 */
	public onRegisterAction(): void {
		this.addAction('next', this.onNext, true);
		this.addAction('back', this.onBack);
	}

	/**
	 * ビュー表示通知
	 */
	public onShowView(): void {
		this.m_MypageWizardMngr.initView(this, (result) => {
			this.registAccessLog();
			this.setStorageItem('b_url', window.location.href);
			const sendData: ProgressBarData = new ProgressBarData();
			sendData.progressBarList = this.m_MypageWizardMngr.getProgressModelList(this);
			this.sendChildData('fP_ev_progressBarIntital', sendData);

			this.m_BeforeTvBaseModel = this.m_MypageWizardMngr.getCache().m_BeforeTvBaseModel;
			this.m_AfterTvBaseModel = this.m_MypageWizardMngr.getCache().m_TvBaseModel;
			this.m_TvOptionListModel = this.m_MypageWizardMngr.getCache().m_TvOptionListModel;
			//機器名取得(※FHHTのときコード変更)
			if (this.m_AfterTvBaseModel.type === MypageDispdivcdConstants.TV_FTTH_SERVICE) {
				if (this.m_AfterTvBaseModel.terminalCd === '3')
					this.m_AfterTvBaseModel.terminalCd = '2';
				if (this.m_AfterTvBaseModel.terminalCd === '4')
					this.m_AfterTvBaseModel.terminalCd = '3';
			}
			if (this.m_AfterTvBaseModel.terminalDisp !== '変更なし') {
				if (this.m_AfterTvBaseModel.terminalCd)
					this.m_AfterTvBaseModel.terminal = this.getTerminal(this.m_AfterTvBaseModel);
				this.m_AfterTvBaseModel.rental = true;
				this.m_AfterTvBaseModel.noRental = false;
				this.m_AfterTvBaseModel.applyType = 'レンタル'
			}
			else {
				this.m_AfterTvBaseModel.noRental = true;
				this.m_AfterTvBaseModel.rental = false;
				this.m_AfterTvBaseModel.applyType = '変更なし'
			}
			//変更前表示情報取得
			const contractId = this.m_MypageWizardMngr.getParams().contractId;
			if (ObjectUtil.isNotNullOrEmptyStr(contractId)) {
				this.m_Contract = this.m_MypageWizardMngr.getContract(this, contractId);
			}
			const rep = this.m_MypageWizardMngr.getMCommonList(this, 'Add_net', 'equ', null, null).filter(item => item.category3 === 'rep')[0].commonValue;
			if (this.m_Contract.equipment[0].equipmentTypeCD !== rep) {
				const rental = this.m_MypageWizardMngr.getMDisplayList(this, MypageDispdivcdConstants.ITSCOMHOME_TERMINALSTATUS, '').filter(item => item.namecd === this.m_Contract.equipment[0].rentalFlag);
				this.m_BeforeTvBaseModel.applyType = rental[0].dispnamep;
			}
			//オプション取得
			if (this.m_TvOptionListModel.length === 0) {
				const serviceList = this.m_MypageWizardMngr.getServiceList(this, MypageDispdivcdConstants.TV_MAIJU_SERVICE).concat(this.m_MypageWizardMngr.getServiceList(this, MypageDispdivcdConstants.TV_FTTH_SERVICE)).concat(this.m_MypageWizardMngr.getServiceList(this, MypageDispdivcdConstants.TV_OTHER_SERVICE)).concat(this.m_MypageWizardMngr.getServiceList(this, MypageDispdivcdConstants.TV_TANAKO_SERVICE)).concat(this.m_MypageWizardMngr.getServiceList(this, MypageDispdivcdConstants.TV_AP_SERVICE));
				const optionList = serviceList.filter(item => item.service.serviceCD === this.m_AfterTvBaseModel.courseCd)[0].service.option;
				const optionNameList = this.m_MypageWizardMngr.getMDisplayList(this, MypageDispdivcdConstants.TV_OPTION, '');
				const unDispCd = this.m_MypageWizardMngr.getMCommonList(this, 'Mod_tv_entry', 'no_disp_cd', null, null);
				for (const option of optionList) {
					const optionName = optionNameList.filter(item =>item.namecd === option.optionCD);
					//名称の取得できたもの && geneCd = null のみ表示
					if (optionName.length > 0 && !optionName[0].genecd) {
						//MCommon'Mod_tv_entry''no_disp_cd'にあるオプションは表示しない
						let flg = true;
						for (const undisp of unDispCd ) {
							if (undisp.commonValue === option.optionCD)
								flg = false;
						}
						if (flg) {
							const model = new TvOptionListModel();
							model.amount = this.m_MypageWizardMngr.getTaxFee(this, option.amount.toString());
							model.option = optionName[0].dispnamep;
							model.detail = option;
							this.m_TvOptionListModel.push(model);
						}
					}
				}
			}
			if (this.m_TvOptionListModel.length === 0) 
				this.setViewMode('オプション一覧', 'なし');
			this.setListData('TvOptionListModel', this.m_TvOptionListModel);
			//お得パック判定
			this.setOtokuArea();
			//希望日時表示判定
			this.displayDate();
			//画面サイズによるレイアウトの設定
			if (window.outerWidth > 440) {
				this.setViewMode('オプション','PC');
			}
			else {
				this.setViewMode('オプション','SP');
			}
			if (this.m_AfterTvBaseModel.securityFlg) 
				this.setViewMode('セキュリティ会社', '表示');
			else
				this.setViewMode('セキュリティ会社', '非表示');
			this.refreshView();
		});
	}

	/**
	 * 値変更通知
	 * @param name 名前
	 * @param value 値
	 * @returns falseの場合、変更を取り消す
	 */
	public onChangeValue(name: string, value: any): boolean {
		this.refreshModel();
		if (name === 'rental') {
			this.m_AfterTvBaseModel.noRental = false;
			this.m_AfterTvBaseModel.applyType = 'レンタル';
			this.displayDate();
		}
		if (name === 'noRental') {
			this.m_AfterTvBaseModel.rental = false;
			this.m_AfterTvBaseModel.applyType = '変更なし';
			this.displayDate();
		}
		if (name === 'otokuApply') {
			this.m_AfterTvBaseModel.otokuUnapply = false;
		}
		if (name === 'otokuUnapply') {
			this.m_AfterTvBaseModel.otokuApply = false;
		}
		if (name === 'securityFlg') {
			if (value)
				this.setViewMode('セキュリティ会社', '表示');
			else
				this.setViewMode('セキュリティ会社', '非表示');

		}
		this.refreshView();
		this.updateMode();
		return true;
	}

	/**
	 * バリデーションエラーメッセージ通知
	 * @param name コントロール名
	 * @param rowIndex 行数(Listの場合)
	 * @param validatorName バリデーション名
	 * @param msg エラーメッセージ
	 * @returns エラーメッセージ（変更する場合に指定する、メッセージを使用しない場合は、nullを返す）
	 */
	public onInvalidMessage(name: string, rowIndex: number, validatorName: string, msg: string): string {
		if (((name === 'desiredDate1' || name === 'desiredDate2' || name === 'desiredDate3') && validatorName === '最小値')
			|| ((name === 'beforeContact' || name === 'sameDayContact') && (validatorName === '整数')))
			msg = null;
		return msg;
	}

	/**
	 * 入力チェック（独自のValidationを実施する場合に使用する）
	 *   エラー追加例：validError.addError('エラーメッセージ');		// 相関チェックなどのエラー
	 * 				  this.setControlError('control', 'error');		// コントロールのエラー
	 * 		※コントロールの値をチェックする場合は、refreshModelは使用しないでください。
	 * 		　コントロールの値をチェックする場合は、コントロールをDIしてvalueを使用してください。
	 * @param actionName アクション名
	 * @param validError バリデーションエラー情報
	 * @param row 行情報
	 */
	protected onValid(actionName: string, validError: WprValidError, row?: any): void {
		if (actionName == 'next') {
			this.m_ErrorModelList = new Array();
			const desireDateTime: TvBaseModel = this.m_AfterTvBaseModel;
			const reqTimeHissuErrorFlg1: boolean = !this.isNotEmpty(desireDateTime.desiredDate1, desireDateTime.selectTime1);
			const reqTimeHissuErrorFlg2: boolean = !this.isNotEmpty(desireDateTime.desiredDate2, desireDateTime.selectTime2);
			const reqTimeHissuErrorFlg3: boolean = !this.isNotEmpty(desireDateTime.desiredDate3, desireDateTime.selectTime3);
			if (reqTimeHissuErrorFlg1 || reqTimeHissuErrorFlg2 || reqTimeHissuErrorFlg3) {
				const itemStr: string = this.setErrorReqDateItem([{ flag: reqTimeHissuErrorFlg1, dispVal: '第一希望' }, { flag: reqTimeHissuErrorFlg2, dispVal: '第二希望' }, { flag: reqTimeHissuErrorFlg3, dispVal: '第三希望' },]);
				// validError.addError(itemStr + '：日付、時間帯のいずれかが入力されていません。再度入力してください。', 'sameReqDateError');
				const model = new ErrorModel();
				model.errorMessage = itemStr + '：日付、時間帯のいずれかが入力されていません。再度入力してください。';
				this.m_ErrorModelList.push(model);
			}
			else {
				const selectTime1: string = desireDateTime.desiredDate1 + desireDateTime.selectTime1;
				const selectTime2: string = desireDateTime.desiredDate2 + desireDateTime.selectTime2;
				const selectTime3: string = desireDateTime.desiredDate3 + desireDateTime.selectTime3;
				let reqTimeErrorFlg1: boolean = false;
				let reqTimeErrorFlg2: boolean = false;
				let reqTimeErrorFlg3: boolean = false;
				if (ObjectUtil.isNotNullOrUndefined(desireDateTime.desiredDate1) && ObjectUtil.isNotNullOrUndefined(desireDateTime.desiredDate2) && selectTime1 === selectTime2) reqTimeErrorFlg1 = true;
				if (ObjectUtil.isNotNullOrUndefined(desireDateTime.desiredDate1) && ObjectUtil.isNotNullOrUndefined(desireDateTime.desiredDate3) && selectTime1 === selectTime3) reqTimeErrorFlg2 = true;
				if (ObjectUtil.isNotNullOrUndefined(desireDateTime.desiredDate2) && ObjectUtil.isNotNullOrUndefined(desireDateTime.desiredDate3) && selectTime2 === selectTime3) reqTimeErrorFlg3 = true;

				if (reqTimeErrorFlg1 || reqTimeErrorFlg2 || reqTimeErrorFlg3) {
					let itemStr: string;
					if (reqTimeErrorFlg1 === true && reqTimeErrorFlg3 == true) {
						itemStr = '第一希望と第二希望と第三希望';
					}
					else {
						itemStr = this.setErrorReqDateItem([{ flag: reqTimeErrorFlg1, dispVal: '第一希望と第二希望' }, { flag: reqTimeErrorFlg2, dispVal: '第一希望と第三希望' }, { flag: reqTimeErrorFlg3, dispVal: '第二希望と第三希望' },]);
					}
					// validError.addError(itemStr + '：同じ希望日時が入力されています。別の日時を指定してください。', 'sameReqDateError');
					const model = new ErrorModel();
					model.errorMessage = itemStr + '：同じ希望日時が入力されています。別の日時を指定してください。';
					this.m_ErrorModelList.push(model);
				}
			}
			if (this.m_AfterTvBaseModel.desiredDate1 < this.m_MypageWizardMngr.getMinReqDate()) {
				const date = this.m_MypageWizardMngr.convertToDate(this.m_MypageWizardMngr.getMinReqDate());
				// validError.addError('第一希望に' + date + '以降を入力してください', 'sameReqDateError');
				const model = new ErrorModel();
				model.errorMessage = '第一希望に' + date + '以降を入力してください', 'sameReqDateError';
				this.m_ErrorModelList.push(model);
			}
			if (this.m_AfterTvBaseModel.desiredDate2 < this.m_MypageWizardMngr.getMinReqDate() ) {
				const date = this.m_MypageWizardMngr.convertToDate(this.m_MypageWizardMngr.getMinReqDate());
				// validError.addError('第二希望に' + date + '以降を入力してください', 'sameReqDateError');
				const model = new ErrorModel();
				model.errorMessage = '第二希望に' + date + '以降を入力してください', 'sameReqDateError';
				this.m_ErrorModelList.push(model);
			}
			if (this.m_AfterTvBaseModel.desiredDate3 < this.m_MypageWizardMngr.getMinReqDate()) {
				const date = this.m_MypageWizardMngr.convertToDate(this.m_MypageWizardMngr.getMinReqDate());
				// validError.addError('第三希望に' + date + '以降を入力してください', 'sameReqDateError');
				const model = new ErrorModel();
				model.errorMessage = '第三希望に' + date + '以降を入力してください', 'sameReqDateError';
				this.m_ErrorModelList.push(model);
			}
			if (this.m_AfterTvBaseModel.beforeContact && !this.m_AfterTvBaseModel.beforeContact.match(/^[0-9]+$/)) {
				const model = new ErrorModel();
				model.errorMessage = '前日連絡先は半角数字で入力してください。';
				this.m_ErrorModelList.push(model);
			}
			if (this.m_AfterTvBaseModel.sameDayContact && !this.m_AfterTvBaseModel.sameDayContact.match(/^[0-9]+$/)) {
				const model = new ErrorModel();
				model.errorMessage = '当日連絡先は半角数字で入力してください。';
				this.m_ErrorModelList.push(model);
			}
			if (this.m_AfterTvBaseModel.courseCd === this.m_BeforeTvBaseModel.courseCd) {
				this.m_ErrorModelList = new Array();
				const model = new ErrorModel();
				model.errorMessage = '同一のご契約を選択されています。変更後の契約内容を選択してお申込ください。';
				this.m_ErrorModelList.push(model);
			}
		}
		else
			this.m_AfterTvBaseModel.campaignList = null;
		if (this.m_ErrorModelList.length !== 0) {
			this.setViewMode('エラー','表示')
			this.setListData('ErrorModel', this.m_ErrorModelList);
			validError.addError('エラー');	
		}
	}

	/**
	 * エラー
	 */
	private setErrorReqDateItem(list: { flag: boolean, dispVal: string }[]): string {
		let itemList: string[] = new Array();
		list.forEach(val => {
			if (val.flag) itemList.push(val.dispVal);
		});
		let itemStr: string = '';
		for (let item of itemList) {
			if (itemStr != '') itemStr += '、';
			itemStr += item;
		}
		return itemStr;
	}
	private isNotEmpty(reqTime: string, reqDate: string): boolean {
		const valFlg1 = ObjectUtil.isNullOrEmptyStr(reqTime);
		const valFlg2 = ObjectUtil.isNullOrEmptyStr(reqDate);
		return (valFlg1 === valFlg2);
	}
	// --------------------------------------------------------------------------

	// アクション  ---------------------------------------------------------------
	/**
	 * 次へ
	 */
	public onNext(param: any): void {
		this.refreshModel();
		//キャンペーンコードが入力されているとき
		if (this.m_AfterTvBaseModel.campaignCode) {
			const req: GetCampaignRequest = new GetCampaignRequest();
			req.campaignGroupCd = this.m_AfterTvBaseModel.campaignCode;
			this.m_CommonService.getCampaign(this, req, (result) => {
				if (result.mcampaignList && result.mcampaignList.length > 0) {
					this.m_AfterTvBaseModel.campaignGroup = result.mCampaignGroup;
					this.m_AfterTvBaseModel.campaignList = result.mcampaignList;
					this.next();
				}
				else {
					const model = new ErrorModel();
					model.errorMessage = 'キャンペーンコード：キャンペーンコードに誤りがあります。再度入力してください。';
					this.m_ErrorModelList.push(model);
				}
				if (this.m_ErrorModelList.length !== 0) {
					this.setViewMode('エラー','表示')
					this.setListData('ErrorModel', this.m_ErrorModelList);
					window.scroll({top: 0, behavior: 'smooth'});	
				}
			});
		}
		else {
			this.m_AfterTvBaseModel.campaignList = null;
			this.next();
		}
	}

	/**
	 * 戻る
	 */
	public onBack(param: any): void {
		this.m_AfterTvBaseModel.desiredDate1 = null;
		this.m_AfterTvBaseModel.desiredDate2 = null;
		this.m_AfterTvBaseModel.desiredDate3 = null;
		this.m_AfterTvBaseModel.selectTime1 = null;
		this.m_AfterTvBaseModel.selectTime2 = null;
		this.m_AfterTvBaseModel.selectTime3 = null;
		this.m_AfterTvBaseModel.campaignCode = null;
		this.m_AfterTvBaseModel.securityFlg = false;
		this.m_AfterTvBaseModel.securityComment = null;
		this.m_AfterTvBaseModel.beforeContact = null;
		this.m_AfterTvBaseModel.sameDayContact = null;
		this.m_AfterTvBaseModel.presentName = null;
		this.m_MypageWizardMngr.getCache().m_TvOptionListModel = new Array();
		this.m_MypageWizardMngr.goBack(this);
	}
	// --------------------------------------------------------------------------

	

	// privateメソッド -----------------------------------------------------------
	/**
	 * 機器名称取得
	 */
	private getTerminal(data: TvBaseModel): string {
		if (data.terminalCd === '1')
			return this.m_MypageWizardMngr.getMCommonList(this, 'hanyo1', null, null, null)[0].commonValue;
		else if (data.terminalCd === '2')
			return this.m_MypageWizardMngr.getMCommonList(this, 'hanyo2', null, null, null)[0].commonValue;
		else if (data.terminalCd === '3')
			return this.m_MypageWizardMngr.getMCommonList(this, 'hanyo3', null, null, null)[0].commonValue;
		else if (data.terminalCd === '4')
			return this.m_MypageWizardMngr.getMCommonList(this, 'hanyo4', null, null, null)[0].commonValue;
		else if (data.terminalCd === '5')
			return this.m_MypageWizardMngr.getMCommonList(this, 'hanyo5', null, null, null)[0].commonValue;
		return null;
	}
	
	/**
	 * お得パック表示判定
	 */
	private setOtokuArea() {
		const contract = this.m_MypageWizardMngr.getViewInfo().contractModel;
		const paymentList  = this.m_MypageWizardMngr.getViewInfo().customerPayment;
		if (paymentList && this.m_MypageWizardMngr.showOtokuPlan(this, paymentList[0].payment[0], contract.planList)) 
			this.setViewMode('お得パックエリア','表示');
	}
	
	/**
	 * 希望日時表示判定
	 */
	private displayDate() {
		let flg = false;
		if (this.m_AfterTvBaseModel.terminal && this.m_AfterTvBaseModel.terminalDisp !== '変更なし' && this.m_BeforeTvBaseModel.terminal !== this.m_AfterTvBaseModel.terminal)
			flg = true;
		if (this.m_AfterTvBaseModel.applyType !== '変更なし' && this.m_BeforeTvBaseModel.applyType !== this.m_AfterTvBaseModel.applyType)
			flg = true;
		if (flg) {
			this.setViewMode('希望日時', '表示');
			this.m_AfterTvBaseModel.constractionFlg = true;
		}
		else {
			this.setViewMode('希望日時', '非表示');
			this.m_AfterTvBaseModel.constractionFlg = false;
		}
	}

	private next() {
		const req: GetGradeRequest = new GetGradeRequest();
		req.befServiceCd = this.m_BeforeTvBaseModel.courseCd;
		req.aftServiceCd = this.m_AfterTvBaseModel.courseCd;
		// 端末コード
		req.befEquipmentCd = this.m_Contract.equipment[0].equipmentTypeCD;
		req.aftEquipmentName = this.m_AfterTvBaseModel.terminal;
		// グレードの取得
		this.m_CommonService.getGradeInfo(this, req, (result) => {
			if (result) {
				this.m_AfterTvBaseModel.grade = result;
				this.m_MypageWizardMngr.goNext(this);
			}
		});
	}
	// --------------------------------------------------------------------------
}
