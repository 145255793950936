import React, { ReactNode } from 'react';
import { ExtractRouteParams, Route } from 'react-router';
import { RouteProps, RouterProps } from 'react-router-dom';
import { WprBaseComponent } from '../controls/WprBaseComponent';

/**
 * Routeコントロールプロパティ情報
 */
interface WprRouteProps< Path extends string = string,
Params extends { [K: string]: string | undefined } = ExtractRouteParams<Path, string>> extends RouteProps<Path, Params> {
	firstRedirect?: string;		// 開始リダイレクトパス
	noMove?: boolean;			// ブラウザでの移動を許可しない
	noUpdate?: boolean;			// ブラウザのリフレッシュを許可しない
}

/**
 * Routeコントロールステータス情報
 */
interface WprRouteState {
}

/**
 * Routeコントロール
 */
export class WprRoute extends WprBaseComponent<WprRouteProps, WprRouteState> {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: WprRouteProps) {
		super(props);
	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * コントロール名称取得
	 * @returns コントロール名称
	 */
	public getName(): string {
		return 'WprRoute';
	}

	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		const {firstRedirect, children, ...props} = this.props;
		return (
			<Route {...props}>{this.props.children}</Route>
		);
	}
	// --------------------------------------------------------------------------
}