import { WprBaseFrameworkComponent } from '../../../wpr-framework/component/base/WprBaseFrameworkComponent';
import { WprSettingInfo } from '../../../wpr-framework/WprSettingInfo';
import { WprValueMapSetter } from '../../../wpr-framework/model/WprValueMapSetter';
import { WprScopeSetter } from '../../../wpr-framework/scope/WprScopeSetter';
import { WprValidatorSetter } from '../../../wpr-framework/view/validation/WprValidatorSetter';
import { WprAuthoritySetter } from '../../../wpr-framework/authority/WprAuthoritySetter';
import { WprConverterSetter } from '../../../wpr-framework/view/convert/WprConverterSetter';
import { ContractScopeInfo } from '../../scope/ContractScopeInfo';
import { DateConverter } from './Converter/DateConverter';
import { MonthConverter } from './Converter/MonthConverter';
import { YearMonthConverter } from './Converter/YearMonthConverter';
import { NameValidator } from './Validator/NameValidator';
import { UsageDetailsTargerMonthValueMap } from './ValueMap/UsageDetailsTargerMonthValueMap';
import { UsageHeaderConverter } from './Converter/UsageHeaderConverter';
import { MvnoEntryNumValueMap } from './ValueMap/MvnoEntryNumValueMap';
import { AriNashiConverter } from './Converter/AriNashiConverter';
import { MvnoRequestTimeValueMap } from './ValueMap/MvnoRequestTimeValueMap';
import { UsageDetailsPhoneNumberValueMap } from './ValueMap/UsageDetailsPhoneNumberValueMap';
import { WprLogLevel } from '../../../wpr-framework/error/WprErrorInfo';
import { UseNotUseConverter } from './Converter/UseNotUseConverter';
import { NeedNotNeedConverter } from './Converter/NeedNotNeedConverter';
import { HyphenConverter } from './Converter/HyphenConverter';
import { HankakuNumberValidator } from './Validator/HankakuNumberValidator';
import { YearValueMap } from './ValueMap/YearValueMap';
import { MonthValueMap } from './ValueMap/MonthValueMap';
import { DayValueMap } from './ValueMap/DayValueMap';
import { CouponCapacityValueMap } from './ValueMap/CouponCapacityValueMap';
import { DateConverter_JPConverter } from './Converter/DateConverter_JPConverter';
import { ElectoricContractTypeValueMap } from './ValueMap/ElectoricContractTypeValueMap';
import { GasContractTypeValueMap } from './ValueMap/GasContractTypeValueMap';
import { PasswordValidator } from './Validator/PasswordValidator';
import { DiscountSelectValueMap } from './ValueMap/DiscountSelectValueMap';
import { ServiceListValueMap } from './ValueMap/ServiceListValueMap';
import { NameChangeConverter } from './Converter/NameChangeConverter';
import { TokotonSupportValueMap } from './ValueMap/TokotonSupportValueMap';
import { TokotonSupportApplicationValueMap } from './ValueMap/TokotonSupportApplicationValueMap';
import { MeshWifiTimeValueMap } from './ValueMap/MeshWifiTimeValueMap';
import { FloorValueMap } from './ValueMap/FloorValueMap';
import { LocationValueMap } from './ValueMap/LocationValueMap';
import { ConstrTimeValueMap } from './ValueMap/ConstrTimeValueMap';
import { PostCodeConverter } from './Converter/PostCodeConverter';



/**
 * フレームワークコンポーネント
 */
export class FrameworkComponent extends WprBaseFrameworkComponent {
	// override メソッド  --------------------------------------------------------
	/**
	 * WprFramework設定処理
	 * @param setter 設定情報
	 */
	onSetting(settingInfo: WprSettingInfo): void {
		// settingInfo.logLevel = WprLogLevel.INFO;
	}

	/**
	 * ValueMap設定処理
	 * @param vmSetter ValueMapセッター
	 */
	public onRegisterValueMap(vmSetter: WprValueMapSetter): void {
		(new UsageDetailsTargerMonthValueMap()).addServiceValueMap(vmSetter);
		MvnoEntryNumValueMap.addValueMap(vmSetter);
		MvnoRequestTimeValueMap.addValueMap(vmSetter);
		(new UsageDetailsPhoneNumberValueMap()).addServiceValueMap(vmSetter);
		YearValueMap.addValueMap(vmSetter);
		MonthValueMap.addValueMap(vmSetter);
		DayValueMap.addValueMap(vmSetter);
		(new CouponCapacityValueMap()).addServiceValueMap(vmSetter);
		ElectoricContractTypeValueMap.addValueMap(vmSetter);
		GasContractTypeValueMap.addValueMap(vmSetter);
		DiscountSelectValueMap.addValueMap(vmSetter);
		MeshWifiTimeValueMap.addValueMap(vmSetter);
		ServiceListValueMap.addValueMap(vmSetter);
		TokotonSupportValueMap.addValueMap(vmSetter);
		TokotonSupportApplicationValueMap.addValueMap(vmSetter);
		FloorValueMap.addValueMap(vmSetter);
		LocationValueMap.addValueMap(vmSetter);
		ConstrTimeValueMap.addValueMap(vmSetter);
	}

	/**
	 * バリデータ設定処理
	 * @param validSetter ValueMapセッター
	 */
	public onRegisterValidator(validSetter: WprValidatorSetter): void {
		validSetter.addValidator(new NameValidator());
		validSetter.addValidator(new HankakuNumberValidator());
		validSetter.addValidator(new PasswordValidator());
	}

	/**
	 * コンバータ設定処理
	 * @param convertSetter ValueMapセッター
	 */
	public onRegisterConverter(convertSetter: WprConverterSetter): void {
		convertSetter.addConverter(new DateConverter());
		convertSetter.addConverter(new MonthConverter());
		convertSetter.addConverter(new YearMonthConverter());
		convertSetter.addConverter(new UsageHeaderConverter());
		convertSetter.addConverter(new AriNashiConverter());
		convertSetter.addConverter(new UseNotUseConverter());
		convertSetter.addConverter(new NeedNotNeedConverter());
		convertSetter.addConverter(new HyphenConverter());
		convertSetter.addConverter(new DateConverter_JPConverter());
		convertSetter.addConverter(new NameChangeConverter());
		convertSetter.addConverter(new PostCodeConverter());
	}

	/**
	 * スコープ情報登録
	 * @param validSetter ValueMapセッター
	 */
	public onRegisterScope(scopeSetter: WprScopeSetter): void {
		// scopeSetter.addScope(new ContractScopeInfo());
		scopeSetter.addScope(new ContractScopeInfo());
	}

	/**
	 * 権限設定処理
	 * @param authoritySetter 権限セッター
	 */
	public onRegisterAuthority(authoritySetter: WprAuthoritySetter): void {
	}

	/**
	 * モバイルフラグ取得
	 */
	public isMobile(): boolean {
		if (window.innerWidth < 700) {
			return true;
		}
		return false;
	}
	// --------------------------------------------------------------------------
}
