import { MGeneral } from '../entity/MGeneral';

/**
 * 
 */
export class MGeneralListModel {
	// public 変数	-----------------------------------------------------------
	public mgeneral: MGeneral;		// 
	public isCalcTaxIn: boolean;	// 
	// ------------------------------------------------------------------------
}
