import { WprModel } from '../../../../wpr-framework/view/deco/WprModel';
import { WprDI_ScopeModel } from '../../../../wpr-framework/view/di/WprDI_ScopeModel';
import { ConstrBaseModel } from '../../../models/mypage/Construction/ConstrBaseModel';
import { MypageBaseView } from '../../base/MypageBaseView';
import { ProgressBarData } from '../../common/progress_bar/ProgressBarView';
import { MypageWizardMngr } from '../../wizard/models/MypageWizardMngr';
import { DayOfWeekModel } from '../../../models/mypage/Construction/DayOfWeekModel';
import { WprListData } from '../../../../wpr-framework/view/deco/WprListData';
import { ConstrDateListModel } from '../../../models/mypage/Construction/ConstrDateListModel';
import { WprViewMode } from '../../../../wpr-framework/view/deco/WprViewMode';
import { InputCheckMode } from './mode/InputCheckMode';
import { ConstructionService } from '../../../service/ConstructionService';
import { GetVacantMypWorkScheduleRequest } from '../../../service/models/dto/mypagerenewal/GetVacantMypWorkScheduleRequest';
import { WorkScheduleList } from '../../../models/iss/getMypWorkScheduleList/WorkScheduleList';
import { WorkPossibleList } from '../../../models/iss/getVacantMypWorkSchedule/WorkPossibleList';
import { DateUtil } from '../../../common/util/DateUtil';
import { ConstrFlowModel } from '../../../models/mypage/Construction/ConstrFlowModel';
import { WprValidError } from '../../../../wpr-framework/view/validation/WprValidError';
import { ObjectUtil } from '../../../common/util/ObjectUtil';
import { RelocateBaseModel } from '../../../models/mypage/relocate/RelocateBaseModel';
import { ErrorModel } from '../../Srvother/add_nhk_select/models/ErrorModel';

/**
 * 工事日程検索画面
 */
export class ConstrSearchView extends MypageBaseView {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() { super('ConstrSearchView'); }
	// --------------------------------------------------------------------------

	// モデル  ------------------------------------------------------------------
	@WprModel('ConstrBaseModel')
	private m_ConstrBaseModel: ConstrBaseModel	= null;	// 工事日基本情報モデル
	@WprDI_ScopeModel('MypageWizardMngr')
	private m_MypageWizardMngr: MypageWizardMngr			= null;		// 画面ウィザード管理クラス
	@WprListData('ConstrDateListModel')
	private m_ConstrDateListModel: ConstrDateListModel[]	= null;	// 工事リストモデル
	@WprListData('ErrorModel')
	private m_ErrorModelList: ErrorModel[]	= null;	// エラーモデル
	// --------------------------------------------------------------------------

	// private変数 --------------------------------------------------------------
	private m_FlowModel: ConstrFlowModel 	= null;	// 工事画面遷移モデル
	// --------------------------------------------------------------------------

	// サービス  ----------------------------------------------------------------
	private m_ConstructionService: ConstructionService	= new ConstructionService();	// 工事日サービス
	// --------------------------------------------------------------------------

	// サブビュー/モード  --------------------------------------------------------
	@WprViewMode
	private m_InputCheckMode = new InputCheckMode();	// InputCheckMode
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * コントロール定義設定
	 */
	public onRegisterConfig(): void {
		this.addConfig('beforeContact', '前日連絡先', 'ConstrBaseModel', { maxLength: 11, validation: '整数' });
		this.addConfig('sameDayContact', '当日連絡先', 'ConstrBaseModel', { maxLength: 11, validation: '整数' });
		this.addConfig('name', '立ち合い者名', 'ConstrBaseModel');
		this.addListConfig('dayOfWeek', '曜日', 'ConstrDateListModel', { bindName: 'dayOfWeek' });
		this.addListConfig('select', '選択', 'ConstrDateListModel', { bindName: 'select' });
		this.addConfig('searchDate', '検索条件 ( 日付 )', 'ConstrBaseModel', { max: this.m_MypageWizardMngr.getMaxReqDate(), min: this.m_MypageWizardMngr.getMinReqDate() });
		this.addConfig('searchTime', '検索条件 ( 時間key )', 'ConstrBaseModel', { bindName: 'searchDateTime' });
		this.addConfig('monday', '月曜日', 'ConstrBaseModel.searchDayOfWeek');
		this.addConfig('tuesday', '火曜日', 'ConstrBaseModel.searchDayOfWeek');
		this.addConfig('wednesday', '水曜日', 'ConstrBaseModel.searchDayOfWeek');
		this.addConfig('thursday', '木曜日', 'ConstrBaseModel.searchDayOfWeek');
		this.addConfig('friyday', '金曜日', 'ConstrBaseModel.searchDayOfWeek');
		this.addConfig('saturday', '土曜日', 'ConstrBaseModel.searchDayOfWeek');
		this.addConfig('sunday', '日曜日', 'ConstrBaseModel.searchDayOfWeek');
		this.addListConfig('date', '日付', 'ConstrDateListModel.workPossibleList', { bindName: 'possible_date', converter: '日付（yyyy/MM/dd）' });
		this.addListConfig('time', '時間', 'ConstrDateListModel', { bindName: 'preferredTime' });
		this.addListConfig('errorMessage', 'エラーメッセージ', 'ErrorModel');
	}

	/**
	 * アクション登録
	 */
	public onRegisterAction(): void {
		this.addAction('back', this.onBack);
		this.addAction('search', this.onSearch);
		this.addAction('research', this.onResearch);
		this.addAction('next', this.onNext);
		this.addAction('backTop', this.onBackTop);
	}
	
	/**
	 * ビュー表示通知
	 */
	public onShowView(): void {
		this.m_MypageWizardMngr.initView(this, (result) => {
			this.registAccessLog();
			this.setStorageItem('b_url', window.location.href)
			const sendData: ProgressBarData = new ProgressBarData();
			sendData.progressBarList = this.m_MypageWizardMngr.getProgressModelList(this);
			this.sendChildData('fP_ev_progressBarIntital', sendData);
			this.m_ConstrBaseModel = this.m_MypageWizardMngr.getCache().m_ConstrBaseModel;
			this.m_FlowModel = this.m_MypageWizardMngr.getCache().m_FlowModel;
			if (this.m_ConstrBaseModel.workScheduleList.workScheduleList.prev_day_contact_tel) {
				this.m_ConstrBaseModel.beforeContact = this.m_ConstrBaseModel.workScheduleList.workScheduleList.prev_day_contact_tel;
				this.m_ConstrBaseModel.sameDayContact = this.m_ConstrBaseModel.workScheduleList.workScheduleList.prev_day_contact_tel;
			}
			if (this.m_ConstrBaseModel.searchFlg) {
				this.m_ConstrDateListModel = this.m_ConstrBaseModel.selectDateList
				this.setListData('ConstrDateListModel', this.m_ConstrDateListModel);
				this.setViewMode('検索結果', '表示');
			}
			else 
				this.setViewMode('検索結果', '非表示');
			this.refreshView();
		});
	}

	/**
	 * 値変更通知
	 * @param name 名前
	 * @param value 値
	 * @returns falseの場合、変更を取り消す
	 */
	public onChangeValue(name: string, value: any): boolean {
		this.refreshModel();
		this.updateMode();
		return true;
	}

	/**
	 * リスト値変更通知
	 * @param name 名前
	 * @param listName リスト名
	 * @param row 行データ
	 * @param value 値
	 * @returns falseの場合、変更を取り消す
	 */
	public onChangeListValue(name: string, listName: string, row: any, value: any): boolean {
		this.refreshModel();
		for (const data of this.m_ConstrDateListModel) {
			data.select = false;
		}
		if (value)
			row.select = true;
		this.refreshView();
		this.updateMode();
		return true;
	}
	// --------------------------------------------------------------------------

	// アクション  ---------------------------------------------------------------
	/**
	 * <NavigateBeforeIcon />        戻る
	 */
	public onBack(param: any): void {
		this.m_ConstrBaseModel.searchFlg = false;
		this.m_ConstrBaseModel.beforeContact = null;
		this.m_ConstrBaseModel.sameDayContact = null;
		this.m_ConstrBaseModel.name = null;
		this.m_ConstrBaseModel.selectDateList = new Array();
		this.m_ConstrBaseModel.searchDate = null;
		this.m_ConstrBaseModel.searchDateTime = null;
		this.m_ConstrBaseModel.searchDayOfWeek = new DayOfWeekModel();
		this.m_MypageWizardMngr.goBack(this);
	}

	/**
	 * 条件に一致する工事可能日時を検索        <NavigateNextIcon />
	 */
	public onSearch(param: any): void {
		this.refreshModel();
		this.m_ConstrBaseModel.searchFlg = true;
		this.setViewMode('検索結果', '表示');
		this.getList();
	}

	/**
	 * 条件に一致する工事可能日時を再検索         <NavigateNextIcon />
	 */
	public onResearch(param: any): void {
		this.refreshModel();
		this.getList();
	}

	/**
	 * 選択した工事可能日時で申込          <NavigateNextIcon />
	 */
	public onNext(param: any): void {
		this.refreshModel();
		for (const data of this.m_ConstrDateListModel) {
			if (data.select) {
				this.m_ConstrBaseModel.workPossibleList = data;
			}
		}
		// 全角数字を半角に変換
		function toHalfWidth(str) {
			str = str.replace(/[０-９]/g, function(s) {
			  return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
			});
			return str;
		}
		this.m_ConstrBaseModel.beforeContact = toHalfWidth(this.m_ConstrBaseModel.beforeContact);
		this.m_ConstrBaseModel.sameDayContact = toHalfWidth(this.m_ConstrBaseModel.sameDayContact);
		this.refreshModel();
		this.m_MypageWizardMngr.goNext(this);
	}

	/**
	 * 希望に合う工事可能日時がないため<br />トップページに戻る          <NavigateNextIcon />
	 */
	public onBackTop(param: any): void {
		location.href = this.getOldUrl() + 'Top/My_top/';
	}
	// --------------------------------------------------------------------------

	// privateメソッド -----------------------------------------------------------
	/**
	 * 一覧取得
	 */
	private getList(): void {
		const req: GetVacantMypWorkScheduleRequest = new GetVacantMypWorkScheduleRequest();
		if (this.m_ConstrBaseModel.searchDate && this.m_ConstrBaseModel.searchDate < this.m_MypageWizardMngr.getMinReqDate()) 
			req.possible_date = DateUtil.dateLengthEight(DateUtil.getCulcDate(new Date(), 7));
		else if (this.m_ConstrBaseModel.searchDate && this.m_ConstrBaseModel.searchDate !== '')
			req.possible_date = DateUtil.dateLengthEight(new Date(this.m_ConstrBaseModel.searchDate));
		else
			req.possible_date = DateUtil.dateLengthEight(DateUtil.getCulcDate(new Date(), 7));
		req.day_of_week = this.getDayOfWeek();
		req.preferred_time_cd = this.m_ConstrBaseModel.searchDateTime;
		req.access_cd = this.m_ConstrBaseModel.workScheduleList.workScheduleList.access_cd;
		req.work_detail_list = this.m_ConstrBaseModel.workScheduleList.workScheduleList.work_detail_info;
		if (this.m_ConstrBaseModel.workScheduleList.workScheduleList.work_area_cd) 
			req.work_area_cd = this.m_ConstrBaseModel.workScheduleList.workScheduleList.work_area_cd;
		else {
			req.city = this.m_MypageWizardMngr.getViewInfo().buildingList[0].address[0].addressLine3;
			req.town = this.m_MypageWizardMngr.getViewInfo().buildingList[0].address[0].addressLine4;
		}
		this.m_ConstructionService.getVacantMypWorkSchedule(this, req, (result) => {
			if (result) {
				this.m_ConstrDateListModel = new Array();
				const map = new Map<string, WorkPossibleList[]>();
				const sortList = result.work_schedule_list.sort(function(a, b) { if (a.possible_date === b.possible_date) return Number(a.preferred_time_cd) - Number(b.preferred_time_cd); else return Number(a.possible_date) -Number(b.possible_date) });
				for (const data of sortList) {
					if (!data.preferred_time_cd) {
						if (Number(data.possible_start_time.substring(0, 2)) < 11)
							data.preferred_time_cd = '01';
						else if (Number(data.possible_start_time.substring(0, 2)) < 14)
							data.preferred_time_cd = '02';
						else
							data.preferred_time_cd = '03';
					}
					if (!map.has(data.possible_date + data.preferred_time_cd))
						map.set(data.possible_date + data.preferred_time_cd, []);
					map.get(data.possible_date + data.preferred_time_cd).push(data);
				}
				let cnt = 0;
				for (const key of map.keys()) {
					if (cnt === 10) 
						break;
					const list =  map.get(key).sort(function(a, b) { return a.priority > b.priority? -1: 1 })
					const listModel = new ConstrDateListModel();
					listModel.workPossibleList = list[0];
					listModel.dayOfWeek = this.getDayOfWeekJP(list[0].possible_date);
					listModel.preferredTime = this.getValueMap('工事時間帯').getValueFormKey(list[0].preferred_time_cd);
					this.m_ConstrDateListModel.push(listModel);
					cnt++;
				}
				this.m_ConstrBaseModel.selectDateList = this.m_ConstrDateListModel;
				this.setListData('ConstrDateListModel', this.m_ConstrDateListModel);
				this.refreshView();
			}
		});
	}

	/**
	 * 曜日取得
	 */
	public getDayOfWeekJP(date: string): string {
		let dayOfWeekStrJP = [ "日", "月", "火", "水", "木", "金", "土" ] ;
		return dayOfWeekStrJP[new Date(DateUtil.convertToDateWithSlash(date)).getDay()];
	}

	/**
	 * 曜日→コード変換
	 */
	private getDayOfWeek(): string {
		const list = new Array();
		const week = this.m_ConstrBaseModel.searchDayOfWeek;
		if (week.sunday)
			list.push('1');
		if (week.monday)
			list.push('2');
		if (week.tuesday)
			list.push('3');
		if (week.wednesday)
			list.push('4');
		if (week.thursday)
			list.push('5');
		if (week.friyday)
			list.push('6');
		if (week.saturday)
			list.push('7');
		if (list.length > 0)
			return list.join(',');
		else
			return null;
	}
	// --------------------------------------------------------------------------
}
