import { WprBaseValidator } from './WprBaseValidator';
import { WprControlInfo } from '../control/WprControlInfo';
import { WprValidError } from './WprValidError';
import { WprValidErrorInfo } from './WprValidErrorInfo';
import { WprInvalidInfo } from './WprInvalidInfo';

/**
 * バリデーション情報
 */
export class WprValidatorInfo {
	// private 変数  ------------------------------------------------------------
	private m_Description: string			= null;						// 説明
	private m_Validator: WprBaseValidator	= null;						// バリデータ
	private m_Option: any					= undefined;				// オプション
	private m_ParamMap: Map<string, any>	= new Map<string, any>();	// パラメータマップ
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** バリデータ */
	public get validator(): WprBaseValidator 	{ return this.m_Validator; 	}
	/** オプション */
	public get option(): any 					{ return this.m_Option; 	}
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	public constructor(description: string, validator: WprBaseValidator, option?: any) {
		this.m_Description = description;
		this.m_Validator = validator;
		this.m_Option = option;
	}
	// --------------------------------------------------------------------------

	// public メソッド  ----------------------------------------------------------
	/**
	 * 入力値チェック
	 * @param value 入力値
	 * @param dispValue 表示値
	 * @returns trueの場合、成功
	 */
	public checkValidValue(value: any, dispValue?: any): boolean {
		return this.m_Validator.checkValidValue(value, this.getOption(), dispValue);
	}

	/**
	 * 入力チェック処理
	 * @param value 入力値
	 * @return falseの場合、入力無効
	 */
	public checkInput(value: any): boolean {
		return this.m_Validator.checkInput(value, this.getOption());
	}

	/**
	 * 入力チェック処理
	 * @returns falseの場合、入力チェックは行なわない
	 */
	public useInputCheck(): boolean {
		return this.m_Validator.useInputCheck();
	}

	/**
	 * バリデーションエラー情報取得
	 * @returns バリデーションエラー情報
	 */
	public getInvalidInfo(): WprInvalidInfo {
		return new WprInvalidInfo(this.m_Validator.name, this.getOption(), this.m_Validator.getErrorMessage(this.m_Description, this.getOption()));
	}

	/**
	 * エラー情報設定処理
	 * @param cinfo コントロール情報
	 * @param validError: 入力エラー情報
	 */
	public setErrorInfo(cinfo: WprControlInfo, validError: WprValidError): void {
		let message = this.m_Validator.getErrorMessage(this.m_Description, this.getOption());
		const customMsg = this.m_Validator.getCustomMessage(this.m_Description);
		if (customMsg)
			 message = customMsg;
		const errInfo = new WprValidErrorInfo(cinfo.name, cinfo.description, cinfo.rowIndex, this.m_Validator.name, this.getOption(), message);
		errInfo.isCustom = this.m_Validator.isCustom;
		validError.addErrorInfo(cinfo, errInfo);
	}

	/**
	 * 最小値設定
	 * @param min 最小値
	 */
	public setMin(min: any) {
		this.m_ParamMap.set('min', min);
	}

	/**
	 * 最大値設定
	 * @param max 最大値
	 */
	public setMax(max: any) {
		this.m_ParamMap.set('max', max);
	}
	// --------------------------------------------------------------------------

	// public メソッド  ----------------------------------------------------------
	/**
	 * オプション値取得
	 * @returns オプション値
	 */
	private getOption(): any {
		const param = this.m_Validator.getOptionParam();
		if (param && this.m_ParamMap.has(param) === true)
			return this.m_ParamMap.get(param);
		return this.m_Option;
	}
	// --------------------------------------------------------------------------
}
