import { Divider, TableCell, Typography } from '@material-ui/core';
import React, { ReactNode } from 'react';
import { MatOneRowTable } from '../../../../../wpr-framework/component/controls/material/MatOneRowTable';
import { MatTypography } from '../../../../../wpr-framework/component/controls/material/MatTypography';
import { WprContainer } from '../../../../../wpr-framework/component/controls/WprContainer';
import { WprBaseDialogViewComponent } from '../../../../../wpr-framework/view/component/WprBaseDialogViewComponent';
import { HelpInitialSupportDialogView } from './HelpInitialSupportDialogView';
import styles from './HelpInitialSupportDialog.scss';

/**
 * ヘルプ：初期設定サポートについて用コンポーネント
 */
export class HelpInitialSupportDialog extends WprBaseDialogViewComponent {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: any) { super(new HelpInitialSupportDialogView(), props); }
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** ヘルプ：初期設定サポートについてビュー情報 */
	public get viewInfo(): HelpInitialSupportDialogView { return this.view as HelpInitialSupportDialogView; }
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
		/**
	 * スタイルオブジェクト取得
	*/
	public getStylesObject(): any { return styles; }
	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		return (
			<>
				<div className='dialogHeader'>
					<Typography className='title'>初期設定サポートについて</Typography>
				</div>
				<div className='dialogContent'>
					<div>
						<div className='itemTitle title_maru'>初期設定項目</div>
						<div>
							番号ポータビリティ（MNP)回線切り替え<br />
							電話帳の移行<br />
							Googleアカウント設定<br />
							端末のAPN設定当社指定アプリ（※1）インストール
							<ul>
								<li>
									LINEのインストール&アカウント作成補助
									またはかんたんQRコードにてアカウント引継ぎ
								</li>
								<li>
									データ移行（※2）（※3）
								</li>
								<li>
									メール設定

								</li>
							</ul>

							※1 イッツコム　かんたんスマホアプリ、セキュリティアプリ、ケーブルスマホダイヤルアプリ<br/>
							※2 当社で端末購入の場合<br/>
							※3　フューチャーフォンの場合には電話帳の移行のみ
						</div>
					</div>
					<div>
						※音声プランを新規お申し込みと同時に初期設定サポートをご希望いただいた場合、1回線につき無料で初期設定項目をご利用いただけます。<br />
						※すでにGoogleアカウントをお持ちの場合は事前にご準備ください。<br />
						※Wi-Fiモデムのパスワードは事前にご準備ください。
					</div>
				</div>
			</>
		);
	}
	// --------------------------------------------------------------------------
}
