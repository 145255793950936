// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@charset \"UTF-8\";\n/*\r\n * 移設申込確認画面用CSS\r\n */\n.RelocateComfirm-serviceGroup .RelocateComfirm-serviceTitle {\n  font-size: 19px;\n  font-weight: 500; }\n\n.RelocateComfirm-serviceGroup .RelocateComfirm-noStyle {\n  font-size: 15px;\n  background-color: #E3E3E3;\n  width: fit-content;\n  padding: 3px;\n  font-weight: 500;\n  border-radius: 5px;\n  margin-right: 10px;\n  color: #484848 !important;\n  display: inline; }\n\n.RelocateComfirm-value {\n  display: flex;\n  align-items: baseline; }\n  .RelocateComfirm-value .RelocateComfirm-itemSelect .MuiSelect-root {\n    width: auto !important;\n    height: auto !important;\n    background-color: #FFFFFF !important;\n    color: #484848 !important;\n    font-weight: 500 !important;\n    font-size: 14px;\n    padding: 0; }\n  .RelocateComfirm-value .RelocateComfirm-itemSelect svg {\n    display: none; }\n  .RelocateComfirm-value .RelocateComfirm-itemSelect fieldset {\n    border: 0px; }\n\n.RelocateComfirm-gray {\n  background-color: #f7f7f7;\n  border-radius: 2%/15%;\n  padding: 20px;\n  margin: 30px 0; }\n  .RelocateComfirm-gray .RelocateComfirm-flex {\n    display: flex;\n    align-items: center;\n    margin-bottom: 3px; }\n    .RelocateComfirm-gray .RelocateComfirm-flex .RelocateComfirm-title {\n      color: #6169B8;\n      font-weight: 700;\n      font-size: 14px;\n      margin-left: 5px; }\n", ""]);
// Exports
exports.locals = {
	"serviceGroup": "RelocateComfirm-serviceGroup",
	"serviceTitle": "RelocateComfirm-serviceTitle",
	"noStyle": "RelocateComfirm-noStyle",
	"value": "RelocateComfirm-value",
	"itemSelect": "RelocateComfirm-itemSelect",
	"gray": "RelocateComfirm-gray",
	"flex": "RelocateComfirm-flex",
	"title": "RelocateComfirm-title"
};
module.exports = exports;
