import { WprViewMngr } from '../WprViewMngr';
import { WprBaseConverter } from './WprBaseConverter';

/**
 * コンバータ設定
 */
export class WprConverterSetter {
	// private 変数  ------------------------------------------------------------
	private m_ViewMngr: WprViewMngr		= null;			// ビュー管理クラス
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	public constructor(viewMngr: WprViewMngr) {
		this.m_ViewMngr = viewMngr;
	}
	// --------------------------------------------------------------------------

	// public メソッド	----------------------------------------------------------
	/**
	 * コンバータ登録
	 * @param converter コンバータ
	 */
	public addConverter(converter: WprBaseConverter): void {
		this.m_ViewMngr.addConverter(converter);
	}
	// --------------------------------------------------------------------------
}
