import { IWprConfigModeOption } from '../inter/IWprConfigModeOption';
import { WprBaseViewMode } from '../WprBaseViewMode';
import { WprBaseModeSetter } from './WprBaseModeSetter';
import { IWprBaseModeInfo, WprModeJoinCondition } from '../inter/IWprBaseModeInfo';

/**
 * 値設定モード設定基本クラス(値の設定状態があるモード)
 */
export abstract class WprBaseValueModeSetter<T extends IWprBaseModeInfo> extends WprBaseModeSetter {
	// private 変数  ------------------------------------------------------------
	private m_SetValueMode: T[]	= null;		// 値設定モード（「値を設定するモード：値」で指定する、それ以外のモードとする場合は、「other:値」で指定する）
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	public constructor(name: string, option: IWprConfigModeOption, viewMoode: WprBaseViewMode, id?: string) {
		super(name, option, viewMoode, id);
	}
	// --------------------------------------------------------------------------

	// abstract メソッド	-----------------------------------------------------
	/**
	 * コントロールステータス設定処理
	 * @param vinfo 値情報
	 * @param id コントロールID
	 */
	public abstract setControlStatus(vinfo: T, id?: string): void;
	// --------------------------------------------------------------------------

	// override メソッド	-----------------------------------------------------
	/**
	 * モードチェック処理
	 * @modeList モードリスト
	 */
	public checkMode(modeList: string[]): void {
		if (this.m_SetValueMode == null)
			return;
		const list = new Array();
		this.m_SetValueMode.forEach(vmode => {
			if (this.containsMode(vmode.modeList, modeList) === true)
				list.push(vmode);
		});
		this.m_SetValueMode = list;
	}

	/**
	 * コントロールの状態を設定する
	 * @param mode モード
	 */
	public setStatus(mode: string): void {
		if (this.m_SetValueMode != null) {
			for (const vmodeInfo of this.m_SetValueMode) {
				if (vmodeInfo.modeList.includes(mode) === true) {
					const minfo = this.viewMode.onSetModeStatus(mode, this.name, this.controlName, vmodeInfo);
					this.setControlStatus(minfo as T, this.id);
					return;
				}
			}
			if (mode !== 'other')
				this.setStatus('other');
		}
	}

	/**
	 * 複数モードのコントロールの状態を設定する
	 * @modeList modeList モードリスト
	 */
	public setMultiStatus(modeList: string[]): void {
		if (this.m_SetValueMode != null) {
			if (this.checkControlStatus(modeList) === true)
				return;
			if (modeList.includes('other') === false)
				this.setMultiStatus(['other']);
		}
	}
	// --------------------------------------------------------------------------

	// protected メソッド	-----------------------------------------------------
	/**
	 * モードリスト初期化処理
	 * @param valueMode valueモード
	 */
	protected initializeModeList(valueMode: T[]): void {
		if (valueMode) {
			this.m_SetValueMode  = valueMode;
			this.m_SetValueMode.forEach(vmode => {
				this.getModeList(vmode, this.name);
			});
		}
	}
	// --------------------------------------------------------------------------

	// private メソッド	---------------------------------------------------------
	/**
	 * モード存在チェック
	 * @param checkList チェックするモードリスト
	 * @param modeList モードリスト
	 * @returns trueの場合、checkListは、modeListに含まれる
	 */
	private containsMode(checkList: string[], modeList: string[]): boolean {
		let ret = true;
		for (const moode of checkList) {
			if (modeList.includes(moode) === false && moode !== 'other') {
				this.viewMode.addErrorLog(`モード定義のモードに誤りがあります。[${this.controlName}][${this.name}][${moode}]`);
				ret = false;
			}
		}
		return ret;
	}

	/**
	 * モードステータスチェック
	 * @param modeList 現在のモードリスト
	 * @return trueの場合、ステータス設定済み
	 */
	private checkControlStatus(modeList: string[]): boolean {
		for (const vmodeInfo of this.m_SetValueMode) {
			if (vmodeInfo.condition === WprModeJoinCondition.OR) {
				if (this.checkOrMode(modeList, vmodeInfo.modeList) === true) {
					const minfo = this.viewMode.onSetMultiModeStatus(modeList, this.name, this.controlName, vmodeInfo);
					this.setControlStatus(minfo as T, this.id);
					return true;
				}
			}
			else {
				if (this.checkAndMode(modeList, vmodeInfo.modeList) === true) {
					const minfo = this.viewMode.onSetMultiModeStatus(modeList, this.name, this.controlName, vmodeInfo);
					this.setControlStatus(minfo as T, this.id);
					return true;
				}
			}
		}
		return false;
	}

	/**
	 * OR条件判定
	 * @param modeList 現在のモードリスト
	 * @param checkList チェックするモードリスト
	 * @return 現在のモードが一つでもチェックするモードリストに含まれていれば、true
	 */
	private checkOrMode(modeList: string[], checkList: string[]): boolean {
		for (const mode of modeList) {
			if (checkList.includes(mode) === true)
				return true;
		}
		return false;
	}

	/**
	 * AND条件判定
	 * @param modeList 現在のモードリスト
	 * @param checkList チェックするモードリスト
	 * @return 現在のモードが全てチェックするモードリストに含まれていれば、true
	 */
	private checkAndMode(modeList: string[], checkList: string[]): boolean {
		for (const mode of checkList) {
			if (modeList.includes(mode) === false)
				return false;
		}
		return true;
	}
	// --------------------------------------------------------------------------
}
