import { GetSimulationResponse } from "../../../../service/models/dto/mypagerenewal/GetSimulationResponse";
import { MDisplay } from "../../../../service/models/entity/secondary/MDisplay";

/**
 * Phoneサービス追加情報モデル
 */
export class PhoneInfoModel {
	// public 変数	-----------------------------------------------------------
	public customerId: string;						// 顧客番号
	public course: string;							// コース
	public equipmentProviding: string;				// 機器のご提供方法
	public courseCheck: boolean;					// 申込みコース
	public equipmentProvidingCheck: boolean;		// 機器のご提供方法
	public yesOtoku: boolean;						// お得パック希望
	public noOtoku: boolean;						// お得パック希望しない
	public otokuFlg: boolean;						// お得パック加入判定
	public otokuPlan: string;						// 現在のプラン名
	public serviceCd: string;						// serviceCd
	public curFee: string;							// 旧月額
	public newFee: string;							// 新月額
	public total: string;							// 合計
	public planCd: string;							// プランコード
	/** 移設 */
	public desiredDate1: string;					// 第一希望
	public desiredDate2: string;					// 第二希望
	public desiredDate3: string;					// 第三希望
	public selectTime1: string;						// 第一希望　時間帯
	public selectTime2: string;						// 第二希望　時間帯
	public selectTime3: string;						// 第三希望　時間帯
	public floor: string;							// 階数
	public location: string;						// 場所
	public comment: string;							// フリー入力
	public beforeContact: string;					// 前日連絡先
	public sameDayContact: string;					// 当日連絡先
	public name: string;							// 当日立ち合い者
	public dateFlg: boolean;						// 希望日時フラグ
	public reformFlg: boolean;						// リフォームフラグ
	public securityFlg: boolean;					// セキュリティフラグ
	public securityComment: string;					// セキュリティフリー入力
	public tvPortFlg: boolean;						// TV端子確認フラグ
	public cost: string;							// 工事費
	public payUnit: string;							// 金額単位
	public reformDisp: string;						// リフォーム(表示用)
	public securityDisp: string;					// セキュリティ(表示用)
	/** オプション */
	public check: boolean;							// 選択
	public optionName: string;						// オプション名
	public notes: string;							// 注釈
	public optionFee: string;						// オプション月額料金
	public generalCd: string;						// generalCd
	public subCd: string;							// サブCD
	public subCd2: string;							// サブCD(料金シミュのコード値)
	public prankCallFlg: boolean;					// 迷惑電話同意フラグ
	public callForwardingFlg: boolean;				// 着信転送フラグ
	/** iphone端末提供 */
	public moving: string;							// 引っ越し
	public phoneNumerSelect: string;				// 電話番号
	public lineType: string;						// 回線業者
	public otherLineComment: string;				// 回線業者その他フリー入力
	public phoneNumber: string;						// 電話番号
	public contractorNameKana: string;				// 契約者名(カナ)
	public contractorNameKanji: string;				// 契約者名(漢字)
	public auPhoneUsing: string;					// au携帯利用
	public auPhoneNumber: string;					// au電話番号
	public multiModularJacks: boolean;				// モジュラージャックを複数箇所利用
	// 各種サービス利用状況
	public noService: boolean;						// 利用なし
	public homeSecurity: boolean;					// ホームセキュリティ
	public emergencyCall: boolean;					// 緊急通報システム、福祉電話、ホームエレベータの利用
	public intercom: boolean;						// インターフォン利用
	public homeBusinessPhone: boolean;				// ホームテレフォン・ビジネスフォン利用
	public gasWater: boolean;						// ガス・水道等の検針サービス
	public phoneRental: boolean;					// 電話機を回線業者よりレンタルしている
	public otherService: boolean;					// その他
	public phoneServiceConfirm: boolean;			// 電話サービス注意事項				
	// ------------------------------------------------------------------------
}
