import { WprListData } from "../../../../../wpr-framework/view/deco/WprListData";
import { WprModel } from "../../../../../wpr-framework/view/deco/WprModel";
import { WprDI_ScopeModel } from "../../../../../wpr-framework/view/di/WprDI_ScopeModel";
import MypageDispdivcdConstants from "../../../../common/MypageDispdivcdConstants";
import { ObjectUtil } from "../../../../common/util/ObjectUtil";
import { Customer } from "../../../../models/bss/customer/Customer";
import { CourseListModel } from "../../../../models/mypage/net/CourseListModel";
import { NetFlowModel } from "../../../../models/mypage/net/NetFlowModel";
import { AddServiceModel } from "../../../../service/models/apimodels/AddServiceModel";
import { GetConstructionCostRequest } from "../../../../service/models/dto/mypagerenewal/GetConstructionCostRequest";
import { GetConstructionCostResponse } from "../../../../service/models/dto/mypagerenewal/GetConstructionCostResponse";
import { GetSimulationRequest } from "../../../../service/models/dto/mypagerenewal/GetSimulationRequest";
import { MCampaign } from "../../../../service/models/entity/primary/MCampaign";
import { MCampaignGroup } from "../../../../service/models/entity/primary/MCampaignGroup";
import { MypageBaseView } from "../../../base/MypageBaseView";
import { ProgressBarData } from "../../../common/progress_bar/ProgressBarView";
import { MypageWizardMngr } from "../../../wizard/models/MypageWizardMngr";

/**
 * ［サービス追加（変更）申込み］申込み内容の確認
 */
export class AddNetConfirmView extends MypageBaseView {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() { super('AddNetConfirmView'); }
	// --------------------------------------------------------------------------

	// モデル  ------------------------------------------------------------------
	@WprDI_ScopeModel('MypageWizardMngr')
	private m_MypageWizardMngr: MypageWizardMngr			= null;		// 画面ウィザード管理クラス
	@WprModel('NetInfo')
	private m_NetInfo: CourseListModel					= null;		// 画面遷移用
	@WprListData('ConfirmListModel')
	private m_ConfirmListModel: CourseListModel[]			= null;		// 申込み内容リスト
	@WprModel('CampaignGroup')
	private m_CampaignGroup: MCampaignGroup	= null;	// キャンペーングループモデル
	@WprListData('CampaignListModel')
	private m_CampaignListModel: MCampaign[]	= null;	// キャンペーンリストモデル
	// --------------------------------------------------------------------------

	// メンバ変数  ----------------------------------------------------------------
	private m_Customer:	Customer							= null;		// 顧客情報
	private m_NetFlowModel: NetFlowModel					= null;		// ネット遷移情報
	// --------------------------------------------------------------------------
	
	// override メソッド  --------------------------------------------------------
	/**
	 * コントロール定義設定
	 */
	public onRegisterConfig(): void {
		this.addConfig('curFee', '旧月額', 'NetInfo', { converter: '3桁カンマ' });
		this.addConfig('newFee', '新月額', 'NetInfo', { converter: '3桁カンマ' });
		this.addConfig('otokuPlan', '現在のプラン名', 'NetInfo');
		this.addListConfig('itemName', '項目名', 'ConfirmListModel');
		this.addListConfig('itemData', '項目データ', 'ConfirmListModel');
		this.addListConfig('comment', 'フリー', 'ConfirmListModel');
		this.addConfig('desiredDate1', '第一希望', 'NetInfo', { converter: '日付（yyyy/MM/dd）' });
		this.addConfig('selectTime1', '第一希望　時間帯', 'NetInfo', { disabled: true});
		this.addConfig('desiredDate2', '第二希望', 'NetInfo', { converter: '日付（yyyy/MM/dd）' });
		this.addConfig('selectTime2', '第二希望　時間帯', 'NetInfo', { disabled: true});
		this.addConfig('desiredDate3', '第三希望', 'NetInfo', { converter: '日付（yyyy/MM/dd）' });
		this.addConfig('selectTime3', '第三希望　時間帯', 'NetInfo', { disabled: true});
		this.addConfig('cost', '工事費', 'NetInfo', { converter: '3桁カンマ' });
		this.addConfig('payUnit', '料金単位', 'NetInfo');
		// this.addConfig('campaignName', '名称2', 'NetInfo.campaign', { bindName: 'name2' });
		// this.addConfig('campaignDiscount', '数値1', 'NetInfo.campaign', { bindName: 'num1' });
		this.addConfig('campaignName', 'キャンペーン名', 'CampaignGroup', { bindName: 'campaignGroupDispName' });
		this.addConfig('campaignExplanation', 'キャンペーン文言', 'CampaignGroup', { bindName: 'explanation' });
		this.addConfig('beforeContact', '前日連絡先', 'NetInfo');
		this.addConfig('sameDayContact', '当日連絡先', 'NetInfo');
		this.addConfig('name', '当日立ち合い者', 'NetInfo');
		this.addConfig('reformDisp', 'リフォーム', 'NetInfo');
		this.addConfig('securityDisp', 'セキュリティ会社', 'NetInfo');
	}

	/**
	 * アクション登録
	 */
	public onRegisterAction(): void {
		this.addAction('next', this.onNext);
		this.addAction('back', this.onBack);
	}

	/**
	 * ビュー表示通知
	 */
	public onShowView(): void {
		this.m_MypageWizardMngr.initView(this, (result) => {
			this.registAccessLog();
			this.setStorageItem('b_url', window.location.href)
			const sendData: ProgressBarData = new ProgressBarData();
			sendData.progressBarList = this.m_MypageWizardMngr.getProgressModelList(this);
			this.sendChildData('fP_ev_progressBarIntital', sendData);
			this.m_NetInfo = this.m_MypageWizardMngr.getCache().m_NetInfo;
			this.m_Customer = this.m_MypageWizardMngr.getViewInfo().customer;
			this.m_NetFlowModel = this.m_MypageWizardMngr.getCache().m_NetFlowModel;
			// 端末の種類
			const cable = this.m_MypageWizardMngr.getMCommonList(this, 'cable_modem_wired', null, null, null);
			const cableWifi = this.m_MypageWizardMngr.getMCommonList(this, 'cable_modem_wifi', null, null, null);
			if (this.m_NetInfo.cable && cable && cable.length > 0)
				this.m_NetInfo.equipmentSelect = cable[0].commonValue;
			else if (this.m_NetInfo.cableWifi && cableWifi && cableWifi.length > 0)
				this.m_NetInfo.equipmentSelect = cableWifi[0].commonValue;
			// 端末のご提供方法
			const deviceStatusList = this.m_MypageWizardMngr.getMDisplayList(this, MypageDispdivcdConstants.ITSCOMHOME_TERMINALSTATUS, '');
			const kattobi = this.m_MypageWizardMngr.getMCommonList(this,'namecd','course_net','value',null); 
			if (this.m_NetInfo.serviceCd === kattobi[0].commonValue) { // かっとびバリューの場合
				const deviceStatus = deviceStatusList.filter(data => data.namecd === '9');
				if (deviceStatus && deviceStatus.length > 0)
					this.m_NetInfo.deviceForm = deviceStatus[0].dispnamep;
				this.m_NetInfo.kattobiFlg = true;
			}
			else if (this.m_NetInfo.equipmentProvidingCheck)
				this.m_NetInfo.deviceForm = deviceStatusList.filter(data => data.namecd === '1')[0].dispnamep;
			this.setAddList();
			// 月額利用料出し分け
			if (this.m_NetInfo.otokuFlg) {
				this.setViewMode('お得パック', '加入済み');
				this.setViewMode('新月額利用料', '※');
			}
			else if (this.m_NetInfo.yesOtoku)
				this.setViewMode('新月額利用料', '※');
			else 
				this.setViewMode('新月額利用料', '料金');
			this.paymentSimulation();
			// 直接申込み判定
			const katValue = this.m_MypageWizardMngr.getMCommonList(this, 'namecd', 'course_net', 'value', null);
			if (this.m_NetInfo.serviceCd === katValue[0].commonValue)
				this.m_NetInfo.directflg = true;
			else
				this.m_NetInfo.directflg = false;
			//リフォーム
			if (this.m_NetInfo.reformFlg)
				this.m_NetInfo.reformDisp = 'あり';
			else
				this.m_NetInfo.reformDisp = 'なし';
			//セキュリティ会社
			if (this.m_NetInfo.securityFlg)
				this.m_NetInfo.securityDisp = this.m_NetInfo.securityComment;
			else
				this.m_NetInfo.securityDisp = 'なし';
			// 工事費
			if (this.m_NetInfo.constractionFlg) {
				this.setViewMode('工事情報', '表示');
				const req: GetConstructionCostRequest = new GetConstructionCostRequest();
				req.constructionCategoryCd = '02';
				req.applicationDetailsCd = '01';
				req.temporaryCostCategoryCd = '01';
				req.multipleFlg = false;
				req.freeFlg = false;
				this.m_MypageWizardMngr.getConstructionCost(this, req, null, false, false, result => {
					if (result) {
						if (result.temporaryCost.length > 0) {
							if (this.m_Customer.building[0].buildingTypeCD === '3')
								this.m_NetInfo.cost = 'お見積り';
							else {
								if (result.temporaryCost.length > 0) {
									this.m_NetInfo.cost = this.m_MypageWizardMngr.getTaxFee(this, result.temporaryCost[0].temporaryCost);
									this.m_NetInfo.payUnit = '円';
								}
							}	
						}
						else
							this.m_NetInfo.cost = 'お見積り';
						this.refreshView();
					}
				});
			}
			//キャンペーン
			if (this.m_NetInfo.campaignList) {
				this.setViewMode('キャンペーンコード', '有');
				this.m_CampaignGroup = this.m_NetInfo.campaignGroup;
				this.m_CampaignListModel = this.m_NetInfo.campaignList;
				this.setListData('CampaignListModel', this.m_CampaignListModel);
				// this.setViewMode('初期費用キャンペーン', this.m_TvBaseModel.campaign.subCd1 === '01' ? '有' : '無');
				// if (this.m_TvBaseModel.campaign.subCd1 === '01' && this.m_TvBaseModel.campaign.subCd2 === '03')
				// 	this.m_TvBaseModel.campaign.num1 = Number(this.m_TvBaseModel.constrAmount);
			}
			else
				this.setViewMode('キャンペーンコード', '無');
		});
	}
	// --------------------------------------------------------------------------

	// アクション  ---------------------------------------------------------------
	/**
	 * 申込む
	 */
	public onNext(param: any): void {
		this.refreshModel();
		this.m_MypageWizardMngr.goNext(this);
	}

	/**
	 * 戻る
	 */
	public onBack(param: any): void {
		// 確認画面情報のクリア
		this.m_MypageWizardMngr.getCache().m_ConfirmListModel = new Array();
		this.m_NetInfo.equipmentSelect = null;
		this.m_NetInfo.deviceForm = null;
		this.m_NetInfo.cost = null;
		this.m_NetInfo.payUnit = null;
		this.m_NetInfo.kattobiFlg = false;
		this.m_MypageWizardMngr.goBack(this);
	}
	// --------------------------------------------------------------------------
	// privateメソッド  ----------------------------------------------------------
	/**
	 * 追加申込み内容リスト作成
	 */
	private setAddList() {
		// 追加申込み内容リスト作成
		this.m_ConfirmListModel = this.m_MypageWizardMngr.getCache().m_ConfirmListModel;
		if (ObjectUtil.isNullOrUndefined(this.m_ConfirmListModel) || this.m_ConfirmListModel.length === 0) {
			const amount = new CourseListModel();
			amount.itemName = '申込みコースの月額';
			if (this.m_NetInfo.payAmount !== '-')
				amount.itemData = Number(this.m_NetInfo.payAmount).toLocaleString() + '円';
			else 
				amount.itemData = '0円';
			this.m_ConfirmListModel.push(amount);
			const course = new CourseListModel();
			course.itemName =  '申込みコース';
			course.itemData =  this.m_NetInfo.cDispnamep;
			this.m_ConfirmListModel.push(course);
			if (ObjectUtil.isNotNullOrUndefined(this.m_NetInfo.equipmentSelect)) {
				const type = new CourseListModel();
				type.itemName = '端末の種類';
				type.itemData = this.m_NetInfo.equipmentSelect;
				this.m_ConfirmListModel.push(type);
			}
			if (ObjectUtil.isNotNullOrUndefined(this.m_NetInfo.deviceForm)) {
				const form = new CourseListModel();
				form.itemName = '端末の形態';
				form.itemData = this.m_NetInfo.deviceForm;
				this.m_ConfirmListModel.push(form);
			}
			if (this.m_NetInfo.constractionFlg) {
				const location = new CourseListModel();
				location.itemName = '希望場所'
				if (ObjectUtil.isNullOrUndefined(this.m_NetInfo.floor))
					location.itemData = this.m_NetInfo.location;
				else
					location.itemData = this.m_NetInfo.floor + '　' + this.m_NetInfo.location;
				if (this.m_NetInfo.location === 'その他')
					location.itemData += '<br/>' + this.m_NetInfo.comment;
				this.m_ConfirmListModel.push(location);
			}
		}
		this.setListData('ConfirmListModel', this.m_ConfirmListModel);
	}

	/**
	 * 料金シミュレーション
	 */
	private paymentSimulation() {
		const req: GetSimulationRequest = new GetSimulationRequest();
		req.customerId = this.m_MypageWizardMngr.getParams().customerId;
		req.addServiceList = new Array();
		const feeModel = new AddServiceModel;
		if (this.m_NetInfo.kattobiFlg)
			feeModel.rentalFlg = '9';
		else if (this.m_NetInfo.equipmentProvidingCheck)
			feeModel.rentalFlg = '1';
		// 店子コースの場合
		if (ObjectUtil.isNotNullOrUndefined(this.m_NetInfo.tanakoServiceCd))
			feeModel.serviceCd = this.m_NetInfo.tanakoServiceCd;
		else
			feeModel.serviceCd = this.m_NetInfo.serviceCd;
		feeModel.optionCdList = new Array();
		// 端末の種類
		if (this.m_NetInfo.cableWifi) {
			const optionCd = this.m_MypageWizardMngr.getMCommonList(this, 'srvnet', 'wire_sv', null, null);
			if (this.m_Customer.building[0].buildingLineTypeCD === '2')
				feeModel.optionCdList.push(optionCd[0].commonValue);
			else {
				const hfcCableWifiList = this.m_MypageWizardMngr.getMCommonList(this, 'srvnet', 'hfc_wireless_sv', 'wireless_disp_sv', null);
				const cableWifi = hfcCableWifiList.filter(data => data.commonValue === this.m_NetInfo.serviceCd);
				if (ObjectUtil.isNullOrUndefined(cableWifi) || cableWifi.length === 0)
					feeModel.optionCdList.push(optionCd[0].commonValue);
			}
		}
		req.addServiceList.push(feeModel);
		this.m_CommonService.getPaymentSimulation(this, req, (result) => {
			this.m_NetInfo.curFee = result.breforeFee;
			// 新しい月額利用料
			if (result.minusFlg) {
				this.m_NetInfo.newFee = String(Number(result.afterFee) + 396);
			} else {
				this.m_NetInfo.newFee = result.afterFee;
			}
			this.refreshView();
		});
	}
	// --------------------------------------------------------------------------

}
