import React, { ReactNode } from 'react';
import { MatTypography } from '../../../../wpr-framework/component/controls/material/MatTypography';
import { WprContainer } from '../../../../wpr-framework/component/controls/WprContainer';
import { WprBaseViewComponent } from '../../../../wpr-framework/view/component/WprBaseViewComponent';
import { ProgressBar } from '../../common/progress_bar/ProgressBar';
import { NameChangeNewFormView } from './NameChangeNewFormView';
import { Grid } from '@mui/material';
import { MatTextField } from '../../../../wpr-framework/component/controls/material/MatTextField';
import { WprRowInfo } from '../../../../wpr-framework/component/props/WprRowInfo';
import { MatRadio } from '../../../../wpr-framework/component/controls/material/MatRadio';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { MatIconButton } from '../../../../wpr-framework/component/controls/material/MatIconButton';
import { MatButton } from '../../../../wpr-framework/component/controls/material/MatButton';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { MatRadioGroup } from '../../../../wpr-framework/component/controls/material/MatRadioGroup';
import itscomIcon from '/shared/image/common/itscomMyp_icon_blue.png';
import errorIcon from '../../../../../shared/image/common/icon_error.png'
/**
 * 新名義人受付フォーム用コンポーネント
 */
export class NameChangeNewForm extends WprBaseViewComponent {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: any) { super(new NameChangeNewFormView(), props); }
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** 新名義人受付フォームビュー情報 */
	public get viewInfo(): NameChangeNewFormView	{ return this.view as NameChangeNewFormView;	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * リストコントロール定義登録
	*/
	protected onRegisterListRender(): void {
		this.addListRender('ErrorModel', null, this.onErrorModelRender);
	}

	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		return (
			<>
				<div className='newNameChange'>
					<div className='nameChange-icon-hearder'>
						<img src={itscomIcon}/>
					</div>
					<div className='newNameChange-card'>
						<div className="pageHeader stepFlow">
							<ProgressBar name='progressBar' view={this.view} />
						</div>
						<div className='main'>
							<div className='pageTitle'>
								<div className='title_left'>
									<MatTypography name='title' className='title_text' view={this.view}/>
									<MatTypography name='titleComment' className='title_comment' view={this.view}/>
								</div>
							</div>
							<WprContainer mode='オプション.エラー' view={this.view}>
								<div className='errorArea'>
									<span className='parallel'>
										<img src={errorIcon} alt='' className='x_img'/>
										<div className='errorList'>
											{this.renderList('ErrorModel')}
										</div>
									</span>
								</div>
							</WprContainer>
							<div className='feeTable'>
								<Grid container className='feeRow'>
									<Grid item xs={12} sm={4} md={4} className='nameChangeTitle'>
										<div>名義変更区分</div>
									</Grid>
									<Grid item xs={12} sm={8} md={8} className='nameChangeVal'>
										<MatTypography name='nameChangeKbn' view={this.view}/>
									</Grid>
								</Grid>
							</div>
							<div className='subTitle-blueCircle'>
								<span>名義変更内容について</span>
							</div>
							<div className='feeTable'>
								<Grid container className='feeRow'>
									<Grid item xs={12} sm={4} md={4} className='nameChangeTitle'>
										<div>新契約名義人（漢字）</div>
									</Grid>
									<Grid item xs={12} sm={8} md={8} className='confirmVal'>
										<MatTypography name='c_NewName' view={this.view}/>
									</Grid>
								</Grid>
								<Grid container className='feeRow'>
									<Grid item xs={12} sm={4} md={4} className='nameChangeTitle'>
										<div style={{ fontSize: '14px' }}>新契約名義人（フリガナ）</div>
									</Grid>
									<Grid item xs={12} sm={8} md={8} className='confirmVal'>
										<MatTypography name='c_NewNameKana' view={this.view}/>
									</Grid>
								</Grid>
								<Grid container className='feeRow'>
									<Grid item xs={12} sm={4} md={4} className='nameChangeTitle'>
										<div style={{ fontSize: '14px' }}>現在の契約者さまとの続柄</div>
									</Grid>
									<Grid item xs={12} sm={8} md={8} className='confirmVal'>
										<MatTypography name='c_RelationShip' view={this.view}/>
									</Grid>
								</Grid>
								<Grid container className='feeRow'>
									<Grid item xs={12} sm={4} md={4} className='nameChangeTitle'>
										<div>性別</div>
									</Grid>
									<Grid item xs={12} sm={8} md={8} className='confirmVal'>
										<MatTypography name='c_Gender' view={this.view}/>
									</Grid>
								</Grid>
								<Grid container className='feeRow'>
									<Grid item xs={12} sm={4} md={4} className='nameChangeTitle'>
										<div>生年月日</div>
									</Grid>
									<Grid item xs={12} sm={8} md={8} className='confirmVal'>
										<MatTypography name='birthdayStr' view={this.view}/>
									</Grid>
								</Grid>
								<Grid container className='feeRow'>
									<Grid item xs={12} sm={4} md={4} className='nameChangeTitle'>
										<div>連絡先電話番号</div>
									</Grid>
									<Grid item xs={12} sm={8} md={8}>
										<Grid container textAlign='left'>
											<Grid item xs={12} sm={6} md={6} className='confirmVal'>
												電話番号1　
												<MatTypography name='c_PhoneNumber1' view={this.view}/>
											</Grid>
											<Grid item xs={12} sm={6} md={6} className='confirmVal'>
												電話番号2　
												<MatTypography name='c_PhoneNumber2' view={this.view}/>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
								<Grid container className='feeRow'>
									<Grid item xs={12} sm={4} md={4} className='nameChangeTitle'>
										<div>メールアドレス</div>
									</Grid>
									<Grid item xs={12} sm={8} md={8} className='confirmVal'>
										<MatTypography name='c_NewMail' view={this.view}/>
									</Grid>
								</Grid>
							</div>
							<div className='subTitle-blueCircle'>
								<span>支払方法について</span>
							</div>
							<div className='feeTable'>
								<Grid container className='feeRow'>
									<Grid item xs={12} sm={4} md={4} className='nameChangeTitle'>
										<div>支払い変更</div>
									</Grid>
									<Grid item xs={12} sm={8} md={8} className='nameChangeVal'>
										<div>
											<MatRadioGroup name='changePaymentFlg' view={this.view} >
												<Grid container>
													<Grid item xs={12} sm={6} md={6}>
														<MatRadio name='changePaymentFlg' id='changePaymentFlg_1' value={true} label='新しい支払方法を登録する' view={this.view} />
													</Grid>
													<Grid item xs={12} sm={6} md={6}>
														<MatRadio name='changePaymentFlg' id='changePaymentFlg_2' value={false} label='従来の支払方法を利用する' view={this.view} />
													</Grid>
												</Grid>
											</MatRadioGroup>
										</div>

										<div style={{ color: 'red', fontSize: '12px', margin: '10px', fontWeight: '500'}}>
											※お支払方法はご契約者本人名義もしくはご契約者と生計を同じくする親族（二親等以内）名義であれば利用可能です。
										</div>
										<div style={{ color: 'red', fontSize: '12px', margin: '10px', fontWeight: 'bold'}}>
											名義変更により、お支払い名義と新名義者が二親等以内でなくなる場合は必ずお支払方法を変更してください。
										</div>
									</Grid>
								</Grid>
								<WprContainer mode='新しい支払方法.表示' view={this.view}>
									<Grid container className='feeRow'>
										<Grid item xs={12} sm={4} md={4} className='nameChangeTitle'>
											<div>新しい支払方法</div>
										</Grid>
										<Grid item xs={12} sm={8} md={8} className='nameChangeVal' style={{ color: 'black' }}>
											<div>
												<MatRadioGroup name='creditFlg' view={this.view} >
													<MatRadio name='creditFlg' id='creditFlg_1' value={true} label='クレジットカードを登録する' view={this.view} />
													<div style={{ color: '#484848', fontSize: '12px', margin: '10px 0px 10px 25px',}}>
															クレジットカードは名義変更ご登録後、マイページにて変更手続きが可能です。
															<br />
															名義変更完了の通知にてご確認ください。
													</div>
													<MatRadio name='creditFlg' id='creditFlg_1' value={false} label='口座を登録する' view={this.view} />
												</MatRadioGroup>
												<div style={{ color: '#484848', fontSize: '12px', margin: '10px 0px 10px 25px',}}>
													支払承諾書を郵送いたします。
												</div>
												<WprContainer mode='送付先選択.表示' view={this.view}>
													<div className='paymentGrayArea'>
														<Grid container spacing={1} className='mnpItem' alignItems={'center'}>
															<Grid item xs={12} md={12}>
																<div>
																	<MatRadioGroup name='contractAddressFlg' view={this.view} >
																		<Grid container>
																			<MatRadio name='contractAddressFlg' id='contractAddressFlg_1' value={true} label='契約住所へ送付' view={this.view} />
																		</Grid>
																		<Grid container>
																			<MatRadio name='contractAddressFlg' id='contractAddressFlg_2' value={false} label='異なる住所へ送付' view={this.view} />
																		</Grid>
																	</MatRadioGroup>
																	<WprContainer mode='送付先住所.表示' view={this.view}>
																		<Grid container spacing={1} className='inputAddress' alignItems={'center'}>
																			<Grid item xs={12} md={12}>
																				<div className='comments'>
																					郵便番号
																				</div>
																				<MatTextField name='paymentPostCode' placeholder='1580097' view={this.view} />
																			</Grid>
																			<Grid item xs={12} md={12}>
																				<MatTextField name='paymentAddress' placeholder='世田谷区用賀４－１０－１' view={this.view} />
																			</Grid>
																			<Grid item xs={12} md={7}>
																				<div className='comments'>
																					建物名
																				</div>
																				<MatTextField name='paymentBuilding' placeholder='イッツコムマンション' view={this.view} />
																			</Grid>
																			<Grid item xs={12} md={5}>
																				<div className='comments'>
																					部屋番号
																				</div>
																				<MatTextField name='paymentRoomNum' placeholder='306' view={this.view} />
																			</Grid>
																		</Grid>
																	</WprContainer>
																</div>
															</Grid>
														</Grid>
													</div>
												</WprContainer>
											</div>
										</Grid>
									</Grid>
								</WprContainer>
							</div>
						</div>
						<div className='pageFooter footer-space'>
							<div>
								<MatButton name='next' view={this.view} className='orangeButton orangeButton_parts' >
									確認
									<NavigateNextIcon />
								</MatButton>
							</div>
							<div>
								<MatButton name='back' view={this.view} className='backButton' >
									<NavigateBeforeIcon />
									戻る
								</MatButton>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
	
	/**
	 * リスト名=ErrorModel
	 */
	 public onErrorModelRender(rowInfo: WprRowInfo): any {
		return (
			<div>
				<MatTypography name='errorMessage' view={this.view} row={rowInfo}/>
			</div>
		);
	}
	// --------------------------------------------------------------------------
}
