import { WprBaseValidator } from '../WprBaseValidator';

/**
 * 半角英数記号バリデータ
 */
export class WprHankakuKValidator extends WprBaseValidator {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() {
		super('半角英数記号');
	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * 入力値チェック
	 * @param value 入力値
	 * @param option チェックオプション
	 * @returns trueの場合、成功
	 */
	public checkValidValue(value: any, option?: any): boolean {
		if (!value)
			return true;
		if (typeof(value) === 'string' || value instanceof String) {
			if (value.match('^[a-zA-Z0-9!-/:-@¥[-`{-~]*$') != null)
				return true;
		}
		return false;
	}

	/**
	 * エラーメッセージ取得
	 * @param description エラーが発生したコントロールの説明
	 * @param option オプション
	 * @returns エラーメッセージ
	 */
	public getErrorMessage(description: string, option?: any): string {
		return '半角英数字又は記号を入力してください。';
	}
	// --------------------------------------------------------------------------
}
