import React, { ReactNode } from 'react';
import { WprBaseControlComponent } from '../../wpr-framework/component/controls/WprBaseControlComponent';
import { IWprControlProps } from '../../wpr-framework/component/props/IWprControlProps';
import { WprControlVisibility } from '../../wpr-framework/component/props/IWprControlState';

/**
 * SPANコントロールプロパティ情報
 */
interface IIdentificationLabelProps extends IWprControlProps {
	converter?: string;			// コンバータ
	useBr?: boolean;			// BR使用フラグ
}

/**
 * SPANコントロール
 */
export class IdentificationLabel extends WprBaseControlComponent<IIdentificationLabelProps, HTMLSpanElement> {
	// プロパティ  ---------------------------------------------------------------
	/** 編集フラグ */
	public get isEdit(): boolean	{ return false; 	}
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	constructor(props: IIdentificationLabelProps){
		super(props);
	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * 値取得
	 * @returns 値
	 */
	protected getValue(): any {
		return undefined;
	}

	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		if (this.state.visibility === WprControlVisibility.COLLAPSED)
			return <></>;
		const props = this.getProps();
		const {name, view, className, converter, children, ...other} = this.props;
		const state = this.getState(['style', 'disabled']);
		let label = null;
		if (this.state.visibility === WprControlVisibility.VISIBLE) {
			if (this.hasBr() === false)
				label = <label {...props} {...state} {...other}>{`${this.getViewValue(this.props.converter, this.props.row)}`}</label>;
			else
				label = <label {...props} {...state} {...other}>{this.getBrContent()}</label>;
		}
		else {
			label = <label hidden {...props} {...state} {...other}>{`${this.getViewValue(this.props.converter, this.props.row)}`}</label>;
		}
		return (
			<>
				{label}
			</>
		);
	}
	// --------------------------------------------------------------------------

	// private メソッド  --------------------------------------------------------
	/**
	 * <br>保持判定処理
	 * @returns trueの場合、br保持
	 */
	private hasBr(): boolean {
		let content = this.state.value as string;
		if (typeof(content) === 'string' && content != null && content.includes('<br') === true && content.includes('/>') === true)
			return true;
		return false;
	}

	/**
	 * <br>付きコンテント取得
	 * @returns <br>
	 */
	private getBrContent(): any[] {
		const contentList = new Array();
		let content = this.state.value as string;
		let key = 1;
		while(content.includes('<br') === true) {
			const sidx = content.indexOf('<br');
			const eidx = content.indexOf('/>');
			contentList.push(content.substring(0, sidx));
			if (this.props.useBr === undefined || this.props.useBr === true)
				contentList.push(this.getBr(content.substring(sidx, eidx), key));
			content = content.substring(eidx+2);
			key++;
		}
		contentList.push(content);
		return contentList;
	}

	/**
	 * <br>取得（classNameのみ判定）
	 * @returns <br>
	 */
	private getBr(content: string, key: number): ReactNode {
		if (content.includes('className') === true) {
			const idx = content.indexOf('className');
			const str = content.substring(idx);
			const list = str.split('\'');
			return <br className={list[1]} key={key}/>;
		}
		return <br key={key}/>;
	}
	// --------------------------------------------------------------------------
}