import { WprBaseViewMode } from './WprBaseViewMode';
import { WprControRefreshMap } from '../view/control/map/WprControRefreshMap';
import { WprBaseViewCore } from '../view/WprBaseViewCore';
import { WprMultiMode } from './WprMultiMode';

/**
 * ビュー複数モード基本クラス
 */
export abstract class WprBaseViewMultiMode extends WprBaseViewMode {
	// private 変数  ------------------------------------------------------------
	private m_CurMultiMode: WprMultiMode	= null;				// 現在のモード
	private m_MultiModeList: string[]		= new Array();		// 指定可能モードリスト
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	public constructor(name: string) {
		super(name);
	}
	// --------------------------------------------------------------------------

	// abstract メソッド  -------------------------------------------------------
	/**
	 * ビューの状態をチェックしてモードを変更する
	 * @param curMode 現在のモード
	 * @param newMode 新しいモード
	 */
	public abstract onCheckMultiMode(curMode: WprMultiMode, newMode: WprMultiMode): void;
	// --------------------------------------------------------------------------

	// virtual メソッド  ---------------------------------------------------------
	/**
	 * モード変更通知
	 * @param oldMode 変更前モード
	 * @param newMode 変更後モード
	 */
	public onChangeMultiMode(oldMode: WprMultiMode, newMode: WprMultiMode): void {}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * 現在のモード取得
	 * @return 現在のモード
	 */
	public getMode(): string {
		return this.m_CurMultiMode.getMode();
	}

	/**
	 * モードリスト設定処理
	 * @param modeList モードリスト
	 */
	public setModeList(modeList: string[]): void {
		this.m_MultiModeList = modeList;
		this.m_CurMultiMode = new WprMultiMode(this, this.m_MultiModeList);
	}

	/**
	 * モードクリア処理
	 */
	public clearMode(): void {
		this.m_CurMultiMode = new WprMultiMode(this, this.m_MultiModeList);
	}

	/**
	 * モードチェック処理
	 * @param isClear trueの場合現在モードをクリアする(モードが変わらなくて反応する)
	 */
	public checkMode(isClear?: boolean): void {
		if (isClear === true)
			this.clearMode();
		this.injection();
		const newMode = new WprMultiMode(this, this.m_MultiModeList);
		this.onCheckMultiMode(this.m_CurMultiMode, newMode);
		if (this.m_CurMultiMode.getMode() === newMode.getMode())
			return;
		const mode = newMode.getMode();
		if (this.modeStack.includes(mode) === true) {
			// ShowErrorMessage('モードの設定が循環しています。モードは変更できませんでした。modeName={0}, mode={1}', ModeName, mode);
			return;
		}
		this.modeStack.push(mode);
		try {
			const old = this.m_CurMultiMode;
			this.m_CurMultiMode = newMode;
			this.setControlMultiStatus(this.m_CurMultiMode);
			this.onChangeMultiMode(old, this.m_CurMultiMode);
			this.viewCore.changeViewMultiMode(this, old, this.m_CurMultiMode);
		}
		catch (ex) {
			this.addErrorLog('モードの変更処理で例外が発生しました。', ex);
		}
		finally {
			this.modeStack = this.modeStack.filter(m => m !== mode);
		}
	}

	/**
	 * ビューの状態をチェックしてモードを変更する
	 * @param curMode 現在のモード
	 * @return 新しいモード（変更ない場合は現在のモード）
	 */
	public onCheckMode(curMode: string): string {
		// 無効にするためにoverrideする
		return null;
	}

	/**
	 * モード変更通知
	 * @param oldMode 古いモード
	 * @param newMode 新しいモード
	 */
	public onChangeMode(oldMode: string, newMode: string): void {
		// 無効にするためにoverrideする
	}

	/**
	 * モードチェック
	 * @param mode モード
	 * @returns trueの場合、モードが含まれている
	 */
	public containsMode(mode: string): boolean {
		return this.m_CurMultiMode.includes(mode);
	}
	// --------------------------------------------------------------------------

	// protected メソッド  -------------------------------------------------------
	/**
	 * 現在のモード設定(TableMode用)
	 * @param mode 現在のモード
	 */
	protected setMultiMode(mode: WprMultiMode): void {
		this.m_CurMultiMode = mode;
	}

	/**
	 * 現在のモード取得(TableMode用)
	 * @retruns 現在のモード
	 */
	protected getMultiMode(): WprMultiMode {
		return this.m_CurMultiMode;
	}

	/**
	 * 初期モード情報取得
	 */
	protected getInitMode(): WprMultiMode {
		return new WprMultiMode(this, this.m_MultiModeList);
	}
	// --------------------------------------------------------------------------

	// private メソッド  ---------------------------------------------------------
	/**
	 * フィールドの状態を設定する
	 * @param mode モード
	 */
	private setControlMultiStatus(mode: WprMultiMode): void {
		this.controlList.forEach(cinfo => {
			cinfo.setMultiStatus(mode.getModeList(), this);
		});
	}
	// --------------------------------------------------------------------------
}

/**
 * ビュー複数モード基本情報
 */
export abstract class WprBaseViewMultiModeInfo<T extends WprBaseViewCore> extends WprBaseViewMultiMode {
	// private 変数  ------------------------------------------------------------
	private m_Parent: T	= null;			// 親ビュー情報
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** 親ビュー情報 */
	public get view():  T	{ return this.m_Parent; }
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	public constructor(name: string) {
		super(name);
	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * View情報設定処理
	 * @param Binder バインダ
	 */
	public setViewCore(viewCore: WprBaseViewCore): void {
		this.m_Parent = viewCore as T;
		super.setViewCore(viewCore);
	}

	/**
	 * DI用コントロール情報マップ取得
	 * @returns DI要コントロール情報マップ
	 */
	public getDIControlMap(): WprControRefreshMap {
		return this.viewCore.getDIControlMap();
	}

	/**
	 * DI元インスタンス取得
	 * @returns DI元インスタンス
	 */
	public getDIObjectFromInstance(): any {
		return this.viewCore;
	}
	// --------------------------------------------------------------------------
}