import { MDisplay } from "../../../service/models/entity/secondary/MDisplay";

/**
 * テレビ_コースリストモデル
 */
export class TvCourseListModel {
	// public 変数	-----------------------------------------------------------
	public select: boolean;		// 選択
	public serviceName: string;	// サービス名
	public terminal: string;	// 機器
	public amount: string;		// 金額
	public normalServiceCd: string;		// 店子用ノーマルサービスコード
	public detail: MDisplay;	// コード情報
	// ------------------------------------------------------------------------
}
