import { WprBaseViewInfo } from '../../view/WprBaseViewInfo';
import { IWprTabMode } from './IWprTabMode';

/**
 * タブモード状態
 */
export enum WprTabModeStatus {
	NONE 		= 1,	// 未設定
	INIT		= 2,	// 初期化済み（Tabs登録）
	ADD_LABEL 	= 3,	// ラベル追加
	START 		= 4,	// 開始
	UPDATE		= 5		// 更新
}

/**
 * タブモード情報
 */
export class WprTabModeInfo {
	// private 変数  ------------------------------------------------------------
	private m_ModeName: string					= null;						// モード名
	private m_TabCompList: IWprTabMode[]		= new Array();				// コンポーネントリスト
	private m_Mode: string						= null;						// モード
	private m_Index: number						= 0;						// タブインデクス
	private m_IsTabs: boolean					= null;						// Tabsフラグ
	private m_TabLabelList: string[]			= null;						// タブラベルリスト
	private m_TabPanelList: string[]			= new Array();				// タブパネルラベルリスト
	private m_ModeMap: Map<string, string>		= null;						// モードマップ
	private m_ViewInfo: WprBaseViewInfo			= null;						// ビュー情報
	private m_Status: WprTabModeStatus			= WprTabModeStatus.NONE;	// ステータス
	private m_SaveTabCompList: IWprTabMode[] 	= new Array();				// 保存コンポーネントリスト
	private m_SaveTabPanelList: string[] 		= new Array();				// 保存タブパネルラベルリスト
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** モード名 */
	public get name(): string			{ return this.m_ModeName;	}
	/** モード */
	public get mode(): string			{ return this.m_Mode;		}
	/** ビュー情報 */
	public get view(): WprBaseViewInfo	{ return this.m_ViewInfo;	}
	/** Tabsフラグ */
	public get isTabs(): boolean		{ return this.m_IsTabs;		} public set isTabs(isTabls: boolean) { this.m_IsTabs = isTabls;	}
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	public constructor(modeName: string) {
		this.m_ModeName = modeName;
	}
	// --------------------------------------------------------------------------

	// public メソッド  ----------------------------------------------------------
	/**
	 * タブコンポーネント追加処理
	 * @param comp タブコンポーネント
	 * @param plabel タブパネルラベル
	 * @param list タブラベルリスト
	 * @param modeMap タブモードマップ
	 * @param isUpdate 更新フラグ
	 */
	public addTabComponent(comp: IWprTabMode, plabel: string, list: string[], modeMap: Map<string, string>, isUpdate: boolean): void {
		if (isUpdate === false) {
			if (this.m_Status === WprTabModeStatus.START || this.m_Status === WprTabModeStatus.UPDATE) {
				this.m_SaveTabCompList.push(comp);
				this.m_SaveTabPanelList.push(plabel);
				return;
			}
			this.m_TabCompList.push(comp);
			if (plabel != null)
				this.m_TabPanelList.push(plabel);
			if (list != null) {
				this.m_TabLabelList = list;
				if (this.m_Mode === null)
					this.m_Mode = list[0];
				this.m_Status = WprTabModeStatus.INIT;
			}
			else {
				this.m_Status = WprTabModeStatus.ADD_LABEL;
			}
			if (modeMap != null)
				this.m_ModeMap = modeMap;
			for(const minfo of this.m_TabCompList)
				minfo.setTabMode(this.m_Mode);
		}
		else {
			const saveComp = this.m_TabCompList[0];
			this.m_TabCompList = new Array();
			this.m_TabPanelList = new Array();
			this.m_TabLabelList = list;
			if (this.m_Mode === null)
				this.m_Mode = list[0];
			this.m_Status = WprTabModeStatus.UPDATE;
			this.m_TabCompList.push(saveComp);
			this.m_SaveTabCompList.forEach(comp => {
				this.m_TabCompList.push(comp);
			});
			this.m_SaveTabPanelList.forEach(plabel => {
				this.m_TabPanelList.push(plabel);
			});
			this.m_SaveTabCompList = new Array();
			this.m_SaveTabPanelList = new Array();
			this.initialize(this.m_ViewInfo);
			for (const minfo of this.m_TabCompList)
				minfo.setTabMode(this.m_Mode);
		}
	}

	/**
	 * タブコンポーネント削除
	 * @param comp タブコンポーネント
	 * @param plabel タブパネルラベル
	 */
	public deleteTabComponent(comp: IWprTabMode, plabel:string): void {
		if (this.m_TabCompList.includes(comp) === true) {
			const idx = this.m_TabCompList.indexOf(comp);
			this.m_TabCompList.splice(idx, 1);
			if (plabel != null && this.m_TabPanelList.includes(plabel) === true) {
				const pidx = this.m_TabPanelList.indexOf(plabel);
				this.m_TabPanelList.splice(pidx, 1);
			}
			this.m_Status = WprTabModeStatus.INIT;
		}
	}

	/**
	 * タブコンポーネントレンダー通知
	 */
	public renderTabsComponent(): void {
		if (this.m_Status === WprTabModeStatus.INIT || this.m_Status === WprTabModeStatus.ADD_LABEL)
			this.m_Status = WprTabModeStatus.START;
	}

	/**
	 * モード設定処理
	 * @param mode モード 
	 */
	public setMode(mode: string): void {
		this.m_Mode = mode;
		this.m_Index = this.getTabIndex(mode);
		for(const minfo of this.m_TabCompList)
			minfo.setTabMode(mode);
	}

	/**
	 * タブ初期化処理
	 * @returns falseの場合エラーあり
	 */
	public initialize(view: WprBaseViewInfo): boolean {
		let ret = true;
		this.m_ViewInfo = view;
		if (this.m_TabLabelList == null) {
//			view.addErrorLog(`MatTabsがありません。TabPanelは使用できません。[${this.m_ModeName}]`);
			ret = false;
		}
		else {
			let list = new Array();
			this.m_TabLabelList.forEach((label) => {
				if (list.includes(label) == true) {
					view.addErrorLog(`Tabが重複しています。[${this.m_ModeName}][${label}]`);
					ret = false;
				}
				else {
					list.push(label);
					if (this.m_TabPanelList.includes(label) == false) {
						view.addErrorLog(`Tabに対応するTabPanelがありません。[${this.m_ModeName}][${label}]`);
						ret = false;
					}
				}
			});
			this.m_TabLabelList = list;
			list = new Array();
			this.m_TabPanelList.forEach((label) => {
				if (list.includes(label) == true) {
					view.addErrorLog(`TabPanelが重複しています。[${this.m_ModeName}][${label}]`);
					ret = false;
				}
				else {
					list.push(label);
					if (this.m_TabLabelList.includes(label) == false) {
						view.addErrorLog(`TabPanelのlabelがTabsにありません。[${this.m_ModeName}][${label}]`);
						ret = false;
					}
				}
			});
			this.m_TabPanelList = list;
			if (this.m_Mode == null && this.m_TabLabelList.length > 0)
				this.setMode(this.m_TabLabelList[0]);
		}
		return ret;
	}

	/**
	 * タブインデクス取得
	 * @param label ラベル
	 * @returns タブインデクス
	 */
	public getTabIndex(label: string): number {
		if (this.m_ViewInfo == null || this.m_TabLabelList == null)
			return 0;
		let index = 0;
		let check = false;
		for (const lstr of this.m_TabLabelList) {
			if (this.m_ModeMap.has(lstr) === true) {
				if (this.m_ViewInfo.checkMode(this.m_ModeMap.get(lstr)) === false) {
					index++;
					if (label === lstr)
						check = true;
					continue;
				}
			}
			if (label === lstr || check == true)
				return index;
			index++;
		}
		return index;
	}

	/**
	 * 再表示処理
	 */
	public refresh(viewInfo: WprBaseViewInfo): void {
		if (this.m_Mode && viewInfo) {
			let reset = true;
			if (this.m_ModeMap != null) {
				if (this.m_ModeMap.has(this.m_Mode) === true)
					reset = viewInfo.checkMode(this.m_ModeMap.get(this.m_Mode));
				if (reset == true) {
					this.setMode(this.m_Mode);
					return;
				}
			}
		}
		this.m_ViewInfo = viewInfo;
		const index = this.getTabIndex(this.m_Mode);
		let no = 0;
		if (this.m_TabLabelList) {
			for (const lstr of this.m_TabLabelList) {
				if (this.m_ModeMap.has(lstr) === true) {
					if (viewInfo.checkMode(this.m_ModeMap.get(lstr)) === false) {
						no++;
						continue;
					}
				}
				if (no >= index) {
					this.m_Index = no;
					if (lstr !== this.m_Mode)
						this.setMode(lstr);
					return;
				}
				no++;
			}
		}
	}
	// --------------------------------------------------------------------------
}