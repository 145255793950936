import { WprBaseDialogViewInfo } from '../../../../../wpr-framework/view/dialog/WprBaseDialogViewInfo';
import { UsageDetailsService } from '../../../../service/UsageDetailsService';
import { GetUsageDetailsYearRequest } from '../../../../service/models/dto/usagedetails/GetUsageDetailsYearRequest';
import { ConvertUtil } from '../../../../common/util/ConvertUtil';
import { WprModel } from '../../../../../wpr-framework/view/deco/WprModel';
import { WprDI_Object } from '../../../../../wpr-framework/view/di/WprDI_Object';
import { UsageGraphInfo } from '../../../../models/UsageGraphInfo';
import { ObjectUtil } from '../../../../common/util/ObjectUtil';
import { WprControlInfo } from '../../../../../wpr-framework/view/control/WprControlInfo';
import { WprDI_Control } from '../../../../../wpr-framework/view/di/WprDI_Control';
import { WprKeyValue } from '../../../../../wpr-framework/model/WprKeyValue';

/**
 * ケーブルプラス電話ご利用料金グラフダイアログ
 */
export class UsageCplusGraphDialogView extends WprBaseDialogViewInfo {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() { super('UsageCplusGraphDialogView'); }
	// --------------------------------------------------------------------------
	
	// private変数  ------------------------------------------------------------------
	private m_targetPhoneNumberList: string[]	= null;	// 電話番号リスト
	// --------------------------------------------------------------------------

	// DI情報  ------------------------------------------------------------------
	@WprDI_Control
	private m_phoneNumberSelect: WprControlInfo	= null;	// 回線番号コンボボックス
	// --------------------------------------------------------------------------
	
	// モデル  ------------------------------------------------------------------
	@WprDI_Object
	@WprModel('UsageGraphInfo')
	private m_UsageGraphInfo: UsageGraphInfo	= null;	// 利用明細グラフ情報
	@WprModel('phoneNumber')
	private m_phoneNumber: number	= null;	// 電話番号のインデックス
	// --------------------------------------------------------------------------

	// サービス  ----------------------------------------------------------------
	private m_UsageDetailsService: UsageDetailsService	= new UsageDetailsService();	// 利用明細サービス
	// --------------------------------------------------------------------------

	// private変数  -----------------------------------------------------------
	private m_monthList: string[]	= null;	// 年月リスト（ラベル）
	private m_callChargeList: number[]	= null;	// 通話料リスト（データ）
	private m_basicChargeList: number[]	= null;	// 基本料リスト（データ）
	private m_otherChargeList: number[]	= null;	// その他料金リスト（データ）
	private m_totalChargeList: number[]	= null;	// 月の合計料金リスト（ツールチップ表示用）
	// --------------------------------------------------------------------------

	// override プロパティ  ------------------------------------------------------
	/** ダイアログタイトル */
	public get title(): string { return 'ケーブルプラス電話ご利用料金'; }
	/** バリデーション */
	public get validation(): boolean { return false; }
	/** OKボタン使用フラグ */
	public get useOKButton(): boolean { return false; }
	/** Divider使用フラグ */
	public get useDividers(): boolean { return true; }
	/** フッタ使用フラグ */
	public get useFooter(): boolean { return true; }
	/** キャンセルボタン使用フラグ */
	public get useCancelButton(): boolean { return true; }	
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * コントロール定義設定
	 */
	public onRegisterConfig(): void {
		this.addConfig('phoneNumberSelect', '電話番号', 'phoneNumber', { bindModel: true, hissu: true });
	}

	/**
	 * アクション登録
	 */
	public onRegisterAction(): void {
		this.addAction('next', this.onNext);
		this.addAction('back', this.onBack);
		this.addAction('close', this.onClose);
	}

	/**
	 * WprDI_Objectの設定通知
	 * ※初期表示時、グラフのdataSetがうまくいかないためDI
	 * @param name WprDI_Objectメンバ名
	 * @param obj 設定値
	 */
	public onSetDIObject(name: string, obj: any) {
		if (ObjectUtil.isNotNullOrUndefined(this.m_UsageGraphInfo)) {
			this.m_monthList = new Array();
			this.m_UsageGraphInfo.monthList.forEach((value) => {
				this.m_monthList.push(ConvertUtil.convertMonthOnlyLabel(value));
			});
			this.m_callChargeList = this.m_UsageGraphInfo.callChargeList;
			this.m_basicChargeList = this.m_UsageGraphInfo.basicChargeList;
			this.m_otherChargeList = this.m_UsageGraphInfo.otherChargeList;
			this.m_totalChargeList = this.m_UsageGraphInfo.totalChargeList;
			this.setGraphData(true);
 			//vmの設定
			this.m_phoneNumberSelect.resetValueMap();
			// 初期表示時はデフォルトで0番目
			this.m_phoneNumber = 0;
			this.m_phoneNumberSelect.value = 0;

			this.setViewMode('矢印', '次へ');
		}
	}

	/**
	 * 月のリストをセットする
	 * @param beforeFlg 前半6ヵ月表示時「true」
	 * @returns 
	 */
	public getMonthList(beforeFlg: boolean): any {
		if (this.isMobile() && ObjectUtil.isNotNullOrEmptyArray(this.m_monthList)) {
			// スマホ時
			let firstIdx;
			let lastIdx;
			if (beforeFlg) {
				// 前半6ヵ月分表示
				firstIdx = 0;
				lastIdx = this.m_monthList.length > 5 ? 5 : this.m_monthList.length;
			} else {
				// 後半6ヵ月分表示
				firstIdx = 6;
				lastIdx = this.m_monthList.length-1;
			}
			return this.setGraphInfoList(firstIdx, lastIdx, this.m_monthList);
		}
		// PC時
		return this.m_monthList;
	}

	/**
	 * 合計料金のリストをセットする
	 * @param beforeFlg 前半6ヵ月表示時「true」
	 * @returns 
	 */
	public getTotalChargeData(beforeFlg: boolean): any {
		if (this.isMobile() && ObjectUtil.isNotNullOrEmptyArray(this.m_totalChargeList)) {
			// スマホ時
			let firstIdx;
			let lastIdx;
			if (beforeFlg) {
				// 前半6ヵ月分表示
				firstIdx = 0;
				lastIdx = this.m_totalChargeList.length > 5 ? 5 : this.m_totalChargeList.length;
			} else {
				// 後半6ヵ月分表示
				firstIdx = 6;
				lastIdx = this.m_totalChargeList.length-1;
			}
			return this.setGraphInfoList(firstIdx, lastIdx, this.m_totalChargeList);
		}
		// PC時
		return this.m_totalChargeList;
	}

	/**
	 * 基本料金のリストをセットする
	 * @param beforeFlg 前半6ヵ月表示時「true」
	 * @returns 
	 */
	public getBasicChargeData(beforeFlg: boolean): any {
		if (this.isMobile() && ObjectUtil.isNotNullOrEmptyArray(this.m_basicChargeList)) {
			// スマホ時
			let firstIdx;
			let lastIdx;
			if (beforeFlg) {
				// 前半6ヵ月分表示
				firstIdx = 0;
				lastIdx = this.m_basicChargeList.length > 5 ? 5 : this.m_basicChargeList.length;
			} else {
				// 後半6ヵ月分表示
				firstIdx = 6;
				lastIdx = this.m_basicChargeList.length-1;
			}
			return this.setGraphInfoList(firstIdx, lastIdx, this.m_basicChargeList);
		}
		// PC時
		return this.m_basicChargeList;
	}

	/**
	 * 通話料金のリストをセットする
	 * @param beforeFlg 前半6ヵ月表示時「true」
	 * @returns 
	 */
	public getCallChargeData(beforeFlg: boolean): any {
		if (this.isMobile() && ObjectUtil.isNotNullOrEmptyArray(this.m_callChargeList)) {
			// スマホ時
			let firstIdx;
			let lastIdx;
			if (beforeFlg) {
				// 前半6ヵ月分表示
				firstIdx = 0;
				lastIdx = this.m_callChargeList.length > 5 ? 5 : this.m_callChargeList.length;
			} else {
				// 後半6ヵ月分表示
				firstIdx = 6;
				lastIdx = this.m_callChargeList.length-1;
			}
			return this.setGraphInfoList(firstIdx, lastIdx, this.m_callChargeList);
		}
		// PC時
		return this.m_callChargeList;
	}

	/**
	 * その他料金のリストをセットする
	 * @param beforeFlg 前半6ヵ月表示時「true」
	 * @returns 
	 */
	public getOtherChargeData(beforeFlg: boolean): any {
		if (this.isMobile() && ObjectUtil.isNotNullOrEmptyArray(this.m_otherChargeList)) {
			// スマホ時
			let firstIdx;
			let lastIdx;
			if (beforeFlg) {
				// 前半6ヵ月分表示
				firstIdx = 0;
				lastIdx = this.m_otherChargeList.length > 5 ? 5 : this.m_otherChargeList.length;
			} else {
				// 後半6ヵ月分表示
				firstIdx = 6;
				lastIdx = this.m_otherChargeList.length-1;
			}
			return this.setGraphInfoList(firstIdx, lastIdx, this.m_otherChargeList);
		}
		// PC時
		return this.m_otherChargeList;
	}

	/**
	 * 値変更通知
	 * @param name 名前
	 * @param value 値
	 * @returns falseの場合、変更を取り消す
	 */
	public onChangeValue(name: string, value: any): boolean {
		if (name === 'phoneNumberSelect') {
			// 選択中の回線番号で明細を取得し、グラフの再描画を行う
			let req: GetUsageDetailsYearRequest = new GetUsageDetailsYearRequest();
			req.customerId = this.m_UsageGraphInfo.customerId;
			req.monthList = this.m_UsageGraphInfo.monthList;
			req.lineNo = this.m_UsageGraphInfo.phoneNumberList[value];
			this.m_UsageDetailsService.getDetailYear(this, req, (result) => {
				if (result) {
					this.m_basicChargeList = new Array();
					this.m_callChargeList = new Array();
					this.m_otherChargeList = new Array();
					this.m_totalChargeList = new Array();
					result.cPlusFeeList.forEach((value) => {
						this.m_basicChargeList.push(value.basicCharge);
						this.m_callChargeList.push(value.callCharge);
						this.m_otherChargeList.push(value.otherCharge);
						this.m_totalChargeList.push(value.totalCharge);
					});
					let mode = this.getViewMode('矢印');
					this.setGraphData(mode==='次へ' ? true : false);
				}
			});
		}
		this.refreshView();
		return true;
	}

	/**
	 * ValueMap再設定処理
	 * @param name コントロール名
	 * @param id ValueMapID
	 * @list ValueMapのKey-Valueリスト
	 * @returns 再設定したKey-Valueリスト
	 */
	public onResetValueMap(name: string, id: string, list: WprKeyValue[]): WprKeyValue[] {
		if (ObjectUtil.isNotNullOrEmptyArray(this.m_UsageGraphInfo.phoneNumberList)) {
			list = [];
			this.m_UsageGraphInfo.phoneNumberList.forEach((value, index) => {
				list.push(new WprKeyValue(index, value));
			})
		}
		return list;
	}

	/**
	 * ウインドウリサイズ通知
	 * @param width 幅
	 * @param height 高さ
	 */
	public onResizeWindow(width: number, height: number): void {
		let mode = this.getViewMode('矢印');
		this.setGraphData(mode==='次へ' ? true : false);
	}	
	// --------------------------------------------------------------------------

	// アクション  ---------------------------------------------------------------
	/**
	 * 次の月へ
	 */
	public onNext(param: any): void {
		this.setGraphData(false);
		this.setViewMode('矢印', '前へ');
	}

	/**
	 * 前の月へ
	 */
	public onBack(param: any): void {
		this.setGraphData(true);
		this.setViewMode('矢印', '次へ');
	}
	
	/**
	 * 閉じる
	 */
	public onClose(param: any): void {
		this.onDialogClose();
	}
	// --------------------------------------------------------------------------

	// privateメソッド  ---------------------------------------------------------
	/**
	 * グラフに表示するデータを切り替える
	 * @param beforeFlg スマホ時前半6ヵ月表示フラグ
	 */
	private setGraphData(beforeFlg: boolean) :void {
		this.setViewData('monthLabel', this.getMonthList(beforeFlg));
		this.setViewData('basicChargeData', this.getBasicChargeData(beforeFlg));
		this.setViewData('callChaegeData', this.getCallChargeData(beforeFlg));
		this.setViewData('otherChargeData', this.getOtherChargeData(beforeFlg));
		this.setViewData('totalChargeData', this.getTotalChargeData(beforeFlg));
	}

	/**
	 * ※スマホ表示時
	 * グラフに表示するリストを作成
	 * @param firstIdx	元のリスト切り出し開始インデクス
	 * @param lastIdx	元のリスト切り出し終了インデクス
	 * @param targetList	元のリスト
	 */
	private setGraphInfoList(firstIdx: number, lastIdx: number, targetList: any[]): any {
		let list = new Array();
		for (let i=firstIdx; i<=lastIdx; i++) {
			list.push(targetList[i]);
		}
		return list;
	}	
	// --------------------------------------------------------------------------

}
