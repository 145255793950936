import { WprBaseComponent } from './WprBaseComponent';
import { IWprModeProps } from '../props/IWprModeProps';
import { IWprModeState } from '../props/IWprModeState';
import { WprComponentModeInfo } from '../mode/WprComponentModeInfo';
import { IWprControlMode } from '../mode/IWprControlMode';

/**
 * モードコントロール基本コンポーネント
 */
export abstract class WprBaseModeComponent<T extends IWprModeProps> extends WprBaseComponent<T, IWprModeState> implements IWprControlMode {
	// private 変数  ------------------------------------------------------------
	private m_Visible: boolean						= false;			// 表示状態
	private m_ModeInfoList: WprComponentModeInfo[]	= new Array();		// コンポーネントモード情報リスト
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	constructor(props: T) {
		super(props);
		this.initMode();
		this.props.view.addModeComponet(this);
		this.state = {
			visible: this.m_Visible
		};
	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * フォーカス設定処理
	 */
	public setFocus() {
		this.props.view.addErrorLog('モードコンポーネントは、フォーカスの設定はできません。');
	}
	// --------------------------------------------------------------------------

	// private メソッド  --------------------------------------------------------
	/**
	 * モード情報初期化処理
	 */
	private initMode() {
		const mode = this.props.mode.trim();
		if (mode.includes(',') === true) {
			mode.split(',').forEach(mbuf => {
				this.addModeInfoList(mbuf.trim());
			});
		}
		else {
			this.addModeInfoList(mode);
		}
	}

	/**
	 * モード情報リスト追加
	 * @param mode モード
	 */
	private addModeInfoList(mode: string): void {
		if (mode.includes('.') === true) {
			const list = mode.split('.');
			this.m_ModeInfoList.push(new WprComponentModeInfo(list[0].trim(), list[1].trim()));
		}
		else {
			this.m_ModeInfoList.push(new WprComponentModeInfo(undefined, mode));
		}
	}
	
	/**
	 * マウント通知
	 */
	 public onDidMount() {
		super.onDidMount();
		this.props.view.mountModeComponent();
	}
	// --------------------------------------------------------------------------

	// IWprControlModeの実装  ----------------------------------------------------
	/**
	 * モード情報リスト取得
	 */
	 public getModeInfoList(): WprComponentModeInfo[] {
		return this.m_ModeInfoList;
	}

	/**
	 * 非表示フラグ取得
	 * @returns 非表示フラグ
	 */
	public getInvisible(): boolean {
		return this.props.invisible;
	}

	/**
	 * 表示状態設定処理
	 * @param visible 表示状態
	 */
	public setVisible(visible: boolean): void {
		if (this.isMount === false)
			return;
//		if (this.m_Visible !== visible) {
			this.m_Visible = visible;
			this.setState({
				visible: this.m_Visible
			});
//		}
	}
	// --------------------------------------------------------------------------
}
