import { MypageBaseView } from '../../../base/MypageBaseView';
import { WprModel } from '../../../../../wpr-framework/view/deco/WprModel';
import { WprDI_ScopeModel } from '../../../../../wpr-framework/view/di/WprDI_ScopeModel';
import { HMvnoTmpSaved } from '../../../../service/models/entity/HMvnoTmpSaved';
import { MvnoService } from '../../../../service/MvnoService';

import { WprListData } from '../../../../../wpr-framework/view/deco/WprListData';
import { MGeneral } from '../../../../service/models/entity/MGeneral';
import { GetCapacityChangeRequest } from '../../../../service/models/dto/mvno/GetCapacityChangeRequest';
import { MvnoCapacityChangeInfo } from '../../../../service/models/apimodels/MvnoCapacityChangeInfo';
import { WprViewMode } from '../../../../../wpr-framework/view/deco/WprViewMode';
import { NextButtonMode } from './mode/NextButtonMode';
import { ObjectUtil } from '../../../../common/util/ObjectUtil';
import { MypageLinkConstatns } from '../../../../common/constants/MypageLinkConstatns';
import { ProgressBarModel } from '../../../../models/ProgressBarModel';
import { ProgressBarData } from '../../../common/progress_bar/ProgressBarView';

/**
 * MVNO容量変更_申込
 */
export class MvnoCapacityChange1View extends MypageBaseView {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() { super('MvnoCapacityChange1'); }
	// --------------------------------------------------------------------------

	// モデル  ------------------------------------------------------------------
	// @WprDI_ScopeModel('EntryInfoBefore')
	// @WprModel('EntryInfoBefore')
	// private m_EntryInfoBefore: HMvnoTmpSaved	= null;	// MVNOプラン情報

	// @WprDI_ScopeModel('Contract')
	// @WprModel('Contract')
	// private m_contract: BssContractInfo = null;

	// @WprDI_ScopeModel('EntryInfoAfter')
	// @WprModel('EntryInfoAfter')
	// private m_EntryInfoAfter: HMvnoTmpSaved	= null;	// MVNOプラン情報

	@WprDI_ScopeModel('CapacityChangeInfo')
	@WprModel('CapacityChangeInfo')
	private m_CapacityChangeInfo: MvnoCapacityChangeInfo = null;	// MVNOプラン情報

	@WprDI_ScopeModel('CapacityRadioList')
	@WprListData('CapacityRadioList')
	private m_CapacityRadioList: MGeneral[] = null;	// 容量ラジオ選択肢リスト


	// @WprDI_ScopeModel('CapacityRadioList')
	// private m_isUnlimitedCOurse: boolean = false;	// かけ放題コースか否か
	// --------------------------------------------------------------------------

	// サービス  ----------------------------------------------------------------
	private m_MvnoService: MvnoService = new MvnoService();	// MyPageRenewalサービス
	// --------------------------------------------------------------------------

	// サブビュー/モード  ---------------------------------------------------------
	@WprViewMode
	private m_NextButtonMode = new NextButtonMode();	// 次へボタン用モード
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * コントロール定義設定
	 */
	public onRegisterConfig(): void {
		this.addConfig('capacityServiceCdAfter', '容量サービスコード（変更前）', 'CapacityChangeInfo');
		this.addConfig('capacityFeeBefore', '容量月額利用料（変更前）', 'CapacityChangeInfo');
		this.addConfig('capacityBefore', '容量（変更前）', 'CapacityChangeInfo.before', { bindName: 'capacity' });
		this.addConfig('phoneNumber', '回線番号', 'CapacityChangeInfo');
		this.addConfig('iccId', 'ICCID', 'CapacityChangeInfo');
	}

	/**
	 * アクション登録
	 */
	public onRegisterAction(): void {
		this.addAction('next', this.onNext);
		this.addAction('back', this.onBack);
		this.addAction('helpLink', this.onHelpLink);
	}

	/**
	 * ビュー表示通知
	 */
	public onShowView(): void {
		this.registAccessLog();
		this.setStorageItem('b_url', window.location.href);

		const customerId: string = this.getCustomerId();
		const contractId: string = this.getContractCd();

		if (ObjectUtil.isNotNullOrUndefined(this.m_CapacityChangeInfo)) {
			this.setListData('CapacityRadioList', this.m_CapacityRadioList);
			this.refreshView();
		}
		else {
			const req: GetCapacityChangeRequest = new GetCapacityChangeRequest();
			req.cusomerId = customerId;
			req.contractId = contractId;
			this.m_MvnoService.getCapacityChangeInfo(this, req, (result) => {
				this.m_CapacityChangeInfo = result.capacityChangeInfo;
				this.m_CapacityRadioList = result.capacityRadioList;
				this.setListData('CapacityRadioList', this.m_CapacityRadioList);
				this.setScopeModel('CapacityRadioList', this.m_CapacityRadioList);
				this.setScopeModel('CapacityChangeInfo', this.m_CapacityChangeInfo);
				this.refreshView();
			});
		}
		const sendData: ProgressBarData = new ProgressBarData();
		sendData.progressBarList = this.getProgressModelList();
		this.sendChildData('fP_ev_progressBarIntital', sendData);
	}

	/**
	 * 値変更通知
	 * @param name 名前
	 * @param value 値
	 * @returns falseの場合、変更を取り消す
	 */
	public onChangeValue(name: string, value: any): boolean {
		this.refreshModel();
		if (name === 'capacityServiceCdAfter') {
			this.m_CapacityChangeInfo.capacityAfter = this.m_CapacityRadioList.find(capacity => {
				return (capacity.generalCd === value)
			}).name2;

			this.setCookie('afterCapacityServiceCd', value);
			// this.setCookie('afterCapacity', this.m_CapacityChangeInfo.capacityAfter);
		}
		return true;
	}
	// --------------------------------------------------------------------------

	// アクション  ---------------------------------------------------------------
	/**
	 * 次へ
	 */
	public onNext(param: any): void {
		this.refreshModel();
		this.pushPath('/mvno_change_002');
	}

	/**
	 * 戻る
	 * 現行の契約内容一覧へ戻る
	 */
	public onBack(param: any): void {
		location.href = this.getOldUrl() + "Srvlist/Continfo_list/";
	}

	/**
	 * 問い合わせリンク
	 */
	public onHelpLink(param: any): void {
		window.open(MypageLinkConstatns.ITSCOM_TOIAWASE);
	}
	// --------------------------------------------------------------------------
	/**
	 * 
	 * @returns 
	 */
	public getCurrentCapcity(): string {
		return this.m_CapacityChangeInfo.before?.capacity;
	}

	/**
	 * 
	 * @returns 
	 */
	public isUnlimitedCourse(): boolean {
		// const isBefore0gb: boolean = this.m_CapacityChangeInfo.before.capacityServiceCd == '0704000014';
		return this.m_CapacityChangeInfo.isUnlimitedCourse;
	}


	private getProgressModelList(): ProgressBarModel[] {
		const list: ProgressBarModel[] = new Array();
		list.push(new ProgressBarModel('1', 'お申込み', 'current'));
		list.push(new ProgressBarModel('2', 'お申込み内容確認', 'next'));
		list.push(new ProgressBarModel('3', '事前確認事項', 'not'));
		list.push(new ProgressBarModel('4', 'お申込み完了', 'not'));
		return list;
	}
}
