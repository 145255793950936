import { Divider, Typography } from '@material-ui/core';
import React, { ReactNode } from 'react';
import { MatTypography } from '../../../../wpr-framework/component/controls/material/MatTypography';
import { WprBaseViewComponent } from '../../../../wpr-framework/view/component/WprBaseViewComponent';
import { ProgressBar } from '../../common/progress_bar/ProgressBar';
import { NameChangeFormView } from './NameChangeFormView';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { MatButton } from '../../../../wpr-framework/component/controls/material/MatButton';
import { MatTextField } from '../../../../wpr-framework/component/controls/material/MatTextField';
import { Grid } from '@mui/material';
import { MatRadioGroup } from '../../../../wpr-framework/component/controls/material/MatRadioGroup';
import { WprAnchor } from '../../../../wpr-framework/component/controls/WprAnchor';
import { WprContainer } from '../../../../wpr-framework/component/controls/WprContainer';
import { MatRadio } from '../../../../wpr-framework/component/controls/material/MatRadio';
import { MatSelect } from '../../../../wpr-framework/component/controls/material/MatSelect';
import { WprErrorContainer } from '../../../../wpr-framework/component/controls/WprErrorContainer';
import { WprRowInfo } from '../../../../wpr-framework/component/props/WprRowInfo';
import errorIcon from '../../../../../shared/image/common/icon_error.png'
/**
 * 名義変更受付フォーム用コンポーネント
 */
export class NameChangeForm extends WprBaseViewComponent {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: any) { super(new NameChangeFormView(), props); }
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** 名義変更受付フォームビュー情報 */
	public get viewInfo(): NameChangeFormView	{ return this.view as NameChangeFormView;	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * リストコントロール定義登録
	*/
	protected onRegisterListRender(): void {
		this.addListRender('ErrorModel', null, this.onErrorModelRender);
	}

	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		return (
			<>
			<div className='pageCard'>
				<div className="pageHeader stepFlow">
					<ProgressBar name='progressBar' view={this.view} />
				</div>
				<div className='main'>
					<div className='pageTitle'>
						<div className='title_left'>
							<MatTypography name='title' className='title_text' view={this.view}/>
							<MatTypography name='titleComment' className='title_comment' view={this.view}/>
						</div>
					</div>
					<WprContainer mode='オプション.エラー' view={this.view}>
						<div className='errorArea'>
							<span className='parallel'>
								<img src={errorIcon} alt='' className='x_img'/>
								<div className='errorList'>
									{this.renderList('ErrorModel')}
								</div>
							</span>
						</div>
					</WprContainer>
					<div className='textRight'>
						<span className='txtRequired'>*</span>
						は入力必須項目です
					</div>
					<div className='feeTable'>
						<Grid container className='feeRow'>
							<Grid item xs={12} sm={4} md={4} className='nameChangeTitle'>
								<div>名義変更区分</div>
							</Grid>
							<Grid item xs={12} sm={8} md={8} className='nameChangeVal'>
								<MatTypography name='nameChangeKbn' view={this.view}/>
							</Grid>
						</Grid>
					</div>
					<div className='subTitle-blueCircle'>
						<span>名義変更に関して以下のフォームへご入力ください。</span>
					</div>
					<div className='feeTable nameChangeTable'>
						<Grid container className='feeRow'>
							<Grid item xs={12} sm={4} md={4} className='nameChangeTitle'>
								<div>
									新契約名義人（漢字）
									<span className='txtRequired'>*</span>
								</div>
							</Grid>
							<Grid item xs={12} sm={8} md={8} className='nameChangeVal'>
								<Grid container textAlign='left' className='halfTextField'>
									<Grid item xs={12} sm={6} md={6}>
										<div>姓</div>
										<div>
											<MatTextField name='familyName' placeholder='逸' view={this.view} />
										</div>
									</Grid>
									<Grid item xs={12} sm={6} md={6}>
										<div>名</div>
										<div>
											<MatTextField name='firstName' placeholder='太郎' view={this.view} />
										</div>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
						<Grid container className='feeRow'>
							<Grid item xs={12} sm={4} md={4} className='nameChangeTitle'>
								<div>新契約名義人（フリガナ）
									<span className='txtRequired'>*</span>
								</div>
							</Grid>
							<Grid item xs={12} sm={8} md={8} className='nameChangeVal'>
								<Grid container textAlign='left' className='halfTextField'>
									<Grid item xs={12} sm={6} md={6}>
										<div>セイ</div>
										<div>
											<MatTextField name='familyNameKana' placeholder='イッツ' view={this.view} />
										</div>
									</Grid>
									<Grid item xs={12} sm={6} md={6}>
										<div>メイ</div>
										<div>
											<MatTextField name='firstNameKana' placeholder='タロウ' view={this.view} />
										</div>
									</Grid>
								</Grid>
								<div>（全角カタカナ）</div>
							</Grid>
						</Grid>
						<Grid container className='feeRow'>
							<Grid item xs={12} sm={4} md={4} className='nameChangeTitle'>
								<div>現在の契約者さまとの続柄
									<span className='txtRequired'>*</span>
								</div>
							</Grid>
							<Grid item xs={12} sm={8} md={8} className='nameChangeVal'>
								<WprContainer mode='名義変更区分.1' view={this.view}>
									<Typography>本人</Typography>
								</WprContainer>
								<WprContainer mode='名義変更区分.2,3' view={this.view}>
									<div className='nameChangeRadioGroup'>
										<MatRadioGroup name='relationShip' view={this.view} >
											<Grid container spacing={1}>
												<Grid item xs={4} sm={4} md={4}>
													<MatRadio name='relationShip' id='relationShip_1' value='配偶者' label='配偶者' view={this.view} />
												</Grid>
												<Grid item xs={4} sm={4} md={4}>
													<MatRadio name='relationShip' id='relationShip_2' value='子' label='子' view={this.view} />
												</Grid>
												<Grid item xs={4} sm={4} md={4}>
													<MatRadio name='relationShip' id='relationShip_3' value='孫' label='孫' view={this.view} />
												</Grid>
											</Grid>
											<Grid container spacing={1}>
												<Grid item xs={4} sm={4} md={4}>
													<MatRadio name='relationShip' id='relationShip_4' value='兄弟姉妹' label='兄弟姉妹' view={this.view} />
												</Grid>
												<Grid item xs={4} sm={4} md={4}>
													<MatRadio name='relationShip' id='relationShip_5' value='父母' label='父母' view={this.view} />
												</Grid>
												<Grid item xs={4} sm={4} md={4}>
													<MatRadio name='relationShip' id='relationShip_6' value='祖父母' label='祖父母' view={this.view} />
												</Grid>
											</Grid>
										</MatRadioGroup>
									</div>
								</WprContainer>
							</Grid>
						</Grid>
						<WprContainer mode='名義変更区分.2,3' view={this.view}>
							<Grid container className='feeRow'>
								<Grid item xs={12} sm={4} md={4} className='nameChangeTitle'>
									<div>性別
										<span className='txtRequired'>*</span>
									</div>
								</Grid>
								<Grid item xs={12} sm={8} md={8} className='nameChangeVal'>
									<div className='nameChangeRadioGroup'>
										<MatRadioGroup name='gender' view={this.view} >
											<Grid container spacing={1}>
												<Grid item xs={6} sm={4} md={4}>
													<MatRadio name='gender' id='gender_1' value='男' label='男' view={this.view} />
												</Grid>
												<Grid item xs={6} sm={4} md={4}>
													<MatRadio name='gender' id='gender_2' value='女' label='女' view={this.view} />
												</Grid>
											</Grid>
										</MatRadioGroup>
									</div>
								</Grid>
							</Grid>
							<Grid container className='feeRow'>
								<Grid item xs={12} sm={4} md={4} className='nameChangeTitle'>
									<div>生年月日
										<span className='txtRequired'>*</span>
									</div>
								</Grid>
								<Grid item xs={12} sm={8} md={8} className='nameChangeVal'>
									<Grid container spacing={1} textAlign='left'>
										<Grid item xs={12} sm={4} md={4} className='birthdayInput-nameChange'>
											<div className='birthdayItem'>
												<MatSelect name='birthdayYear' vm='year' view={this.view} />
											</div>
											<div>年　</div>
										</Grid>
										<Grid item xs={12} sm={4} md={4} className='birthdayInput-nameChange'>
											<div className='birthdayItem'>
												<MatSelect name='birthdayMonth' vm='month' view={this.view} />
											</div>
											<div>月　</div>
										</Grid>
										<Grid item xs={12} sm={4} md={4} className='birthdayInput-nameChange'>
											<div className='birthdayItem'>
												<MatSelect name='birthdayDay' vm='day' view={this.view} />
											</div>
											<div>日　</div>
										</Grid>
									</Grid>
									<WprErrorContainer name='under18Error' view={this.view} >
										<br />
										<div style={{ color: 'red' }}>
											未成年の場合は
											<WprAnchor name='' view={this.view} className='link_underline errorText'>こちら</WprAnchor>から変更できます。
										</div>
									</WprErrorContainer>
								</Grid>
							</Grid>
						</WprContainer>
						<Grid container className='feeRow'>
							<Grid item xs={12} sm={4} md={4} className='nameChangeTitle'>
								<div>連絡先電話番号
									<span className='txtRequired'>*</span>
								</div>
							</Grid>
							<Grid item xs={12} sm={8} md={8} className='nameChangeVal'>
								<Grid container textAlign='left' className='halfTextField'>
									<Grid item xs={12} sm={6} md={6}>
										<div>電話番号1</div>
										<div>
											<MatTextField name='phoneNumber1' placeholder='08012345678' view={this.view}/>
										</div>
									</Grid>
									<Grid item xs={12} sm={6} md={6}>
										<div>電話番号2</div>
										<div>
											<MatTextField name='phoneNumber2' placeholder='08012345678' view={this.view}/>
										</div>
									</Grid>
								</Grid>
								<div className='comments'>
									<span>「電話番号1」はご契約時にご登録いただいたお電話番号です。</span>
									<br />
									<span>「電話番号2」は日中連絡先としてご申告いただいているお電話番号です。</span>
								</div>
							</Grid>
						</Grid>
						<Grid container className='feeRow'>
							<Grid item xs={12} sm={4} md={4} className='nameChangeTitle'>
								<div>メールアドレス
									<span className='txtRequired'>*</span>
								</div>
							</Grid>
							<Grid item xs={12} sm={8} md={8} className='nameChangeVal'>
								<MatTextField name='newMail' placeholder='itstaro@itscom.jp' view={this.view} className='allTextField'/>
							</Grid>
						</Grid>
					</div>
					{/* 支払方法 注釈 */}
					<WprContainer mode='名義変更区分.2,3' view={this.view}>
						<div className='supportMsgArea-nameChange'>
							<div className='textcolor'>支払い方法の選択について</div>
							※新名義者様の申込情報受付フォームでのご入力となります。
							<br />
							　新名義様向け申込情報受付フォームは、本画面でのお申込み後、新名義者様にメールでご案内いたします。
							<br />
							<br />
							※お支払方法はご契約者本人名義もしくはご契約者と生計を同じくする親族（二親等以内）名義であれば利用可能です。
							<br />
							　名義変更により、お支払名義と新名義者が二親等以内でなくなる場合は必ずお支払方法を変更してください。
						</div>
					</WprContainer>
				</div>
				<div className='pageFooter footer-space'>
					<div>
						<MatButton name='next' view={this.view} className='orangeButton orangeButton_parts' >
							約款確認へ
							<NavigateNextIcon />
						</MatButton>
					</div>
					<div>
						<MatButton name='back' view={this.view} className='backButton' >
							<NavigateBeforeIcon />
							戻る
						</MatButton>
					</div>
				</div>
			</div>
			</>
		);
	}
	
	/**
	 * リスト名=ErrorModel
	 */
	 public onErrorModelRender(rowInfo: WprRowInfo): any {
		return (
			<div>
				<MatTypography name='errorMessage' view={this.view} row={rowInfo}/>
			</div>
		);
	}
	// --------------------------------------------------------------------------
}
