import React from 'react';
import { Route } from 'react-router-dom';
import { WprBaseFrameworkComponent } from '../component/base/WprBaseFrameworkComponent';
import { WprSwitch } from '../component/route/WprSwitch';
import { WprErrorMngr } from '../error/WprErrorMngr';
import { WprBaseMngr } from '../WprBaseMngr';
import { WprSettingInfo } from '../WprSettingInfo';
import { WprCommaConverter } from './convert/converter/WprCommaConverter';
import { WprGenderConverter } from './convert/converter/WprGenderConverter';
import { WprBaseConverter } from './convert/WprBaseConverter';
import { WprConverterSetter } from './convert/WprConverterSetter';
import { WprTabViewNameInfo } from './tab/WprTabViewNameInfo';
import { WprHankakuKanaValidator } from './validation/validator/WprHankakuKanaValidator';
import { WprHankakuKValidator } from './validation/validator/WprHankakuKValidator';
import { WprHankakuValidator } from './validation/validator/WprHankakuValidator';
import { WprAlphabetValidator } from './validation/validator/WprAlphabetValidator';
import { WprMailAddressValidator } from './validation/validator/WprMailAddressValidator';
import { WprMaxLengthValidator } from './validation/validator/WprMaxLengthValidator';
import { WprMaxValidator } from './validation/validator/WprMaxValidator';
import { WprMinLengthValidator } from './validation/validator/WprMinLengthValidator';
import { WprMinValidator } from './validation/validator/WprMinValidator';
import { WprNumberValidator } from './validation/validator/WprNumberValidator';
import { WprRequiredValidator } from './validation/validator/WprRequiredValidator';
import { WprZenkakuValidator } from './validation/validator/WprZenkakuValidator';
import { WprZHiraganaValidator } from './validation/validator/WprZHiraganaValidator';
import { WprZKanaValidator } from './validation/validator/WprZKanaValidator';
import { WprBaseValidator } from './validation/WprBaseValidator';
import { WprValidatorSetter } from './validation/WprValidatorSetter';
import { WprBaseViewInfo } from './WprBaseViewInfo';
import { WprXmlInfoCreator } from '../debug/WprXmlInfoCreator';
import { WprControlInfo } from './control/WprControlInfo';
import { IWprControlState } from '../component/props/IWprControlState';
import { IWprUrlParam } from './route/IWprUrlParam';

/**
 * ビュー管理機能
 */
export class WprViewMngr extends WprBaseMngr {
	// private 変数  ------------------------------------------------------------
	private m_ValidatorMap: Map<string, WprBaseValidator>	= new Map<string, WprBaseValidator>();	// バリデータマップ
	private m_ConverterMap: Map<string, WprBaseConverter>	= new Map<string, WprBaseConverter>();	// コンバータマップ
	private m_ErrorMngr: WprErrorMngr						= null;									// エラー管理機能
	private m_ActiveViewList: WprBaseViewInfo[]				= new Array();							// アクティブビュー情報リスト
	private m_ViewStack: string[]							= new Array();							// ビュー遷移スタック
	private m_CurRouteViewInfo: WprBaseViewInfo				= null;									// 現在のルートビュー情報
	private m_NoMoveList: string[]							= new Array();							// ブラウザバック禁止Pathリスト
	private m_NoUpdateList: string[]						= new Array();							// 更新禁止Pathリスト
	private m_StartValidate: string							= null;									// 開始バリデーション
	private m_ViewData: IWprUrlParam[]						= null;									// 設定ビューデータ
	private m_StopBlur: boolean								= false;								// Blurイベント停止フラグ
	private m_FrameworkComponent: WprBaseFrameworkComponent	= null;									// フレームワークコンポーネント
	private m_ShowViewList: WprBaseViewInfo[] 				= null;									// ShowView実行ビュー情報リスト（ステータスロック中）
	private m_TabChildViewList: WprTabViewNameInfo[]		= new Array();							// タブ子ビュー名称リスト
	private m_IsRadioClear: boolean							= false;								// ラジオ選択クリアフラグ（選択済みのラジオボタンをクリックし場合に選択をクリアする）
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	public constructor() {
		super('ビュー管理機能');
	}
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** アクティブビュー情報リスト */
	public get activeViewList(): WprBaseViewInfo[] 	{ return this.m_ActiveViewList; }
	/** 開始バリデーション */
	public get startValidate(): string			 	{ return this.m_StartValidate; 	}	public set startValidate(startValidate: string) { this.m_StartValidate = startValidate;	}
	/** 設定ビューデータ */
	public get viewData(): IWprUrlParam[]			{ return this.m_ViewData; 		}	public set viewData(viewData: IWprUrlParam[]) 	{ this.m_ViewData = viewData;			}
	/** Blurイベント停止フラグ */
	public get isStopBlurEvent(): boolean		 	{ return this.m_StopBlur; 		}
	/** ラジオ選択クリアフラグ（選択済みのラジオボタンをクリックし場合に選択をクリアする） */
	public get isRadioClear(): boolean 				{ return this.m_IsRadioClear; 	}
	// --------------------------------------------------------------------------

	// override メソッド	-----------------------------------------------------
	/**
	 * 初期化処理
	 * @param loader データローダ
	 * @param setting 設定情報
	 */
	public initialize(loader: WprBaseFrameworkComponent, setting: WprSettingInfo): void {
		this.m_FrameworkComponent = loader;
		this.m_IsRadioClear = setting.isRadioClear;
		this.addValidator(new WprRequiredValidator());
		this.addValidator(new WprMinValidator());
		this.addValidator(new WprMaxValidator());
		this.addValidator(new WprMinLengthValidator());
		this.addValidator(new WprMaxLengthValidator());
		this.addValidator(new WprNumberValidator());
		this.addValidator(new WprHankakuValidator());
		this.addValidator(new WprHankakuKValidator());
		this.addValidator(new WprHankakuKanaValidator());
		this.addValidator(new WprZKanaValidator());
		this.addValidator(new WprZHiraganaValidator());
		this.addValidator(new WprMailAddressValidator());
		this.addValidator(new WprZenkakuValidator());
		this.addValidator(new WprAlphabetValidator());
		loader.onRegisterValidator(new WprValidatorSetter(this));
		/* ATODE
		this.m_DefaultDialogConfig = setting.defaultDialogConfig;
		*/
		this.addConverter(new WprCommaConverter());
		this.addConverter(new WprGenderConverter());
		loader.onRegisterConverter(new WprConverterSetter(this));
		window.addEventListener('popstate', this.onPopState.bind(this), true);
		window.addEventListener('beforeunload', this.onBeforeUnload.bind(this), true);
		window.addEventListener('keydown', this.onKeydown.bind(this), true);
	}

	/**
	 * 他の管理機能設定処理
	 * @param mngrMap 管理機能マップ
	 */
	public setManager(mngrMap: Map<string, WprBaseMngr>): void {
		this.m_ErrorMngr = mngrMap.get('エラー管理機能') as WprErrorMngr;
	}

	/**
	 * 権限再設定処理
	 */
	public refreshAuth(): void {
		this.m_ActiveViewList.forEach(vinfo =>{
			vinfo.refreshAuth();
		});
	}
	// --------------------------------------------------------------------------

	// イベント	------------------------------------------------------------------
	/**
	 * ブラウザバックイベント処理
	 */
	private onPopState(): void {
		const x = window.pageXOffset;
		const y = window.pageYOffset;
		if (this.m_CurRouteViewInfo) {
			if (this.m_ViewStack.length > 0) {
				const path = this.m_ViewStack[0];
				if (this.m_NoMoveList.includes(path) === true) {
					history.pushState(null, null, null);
					this.m_CurRouteViewInfo.props.history.replace(path);
					let title = this.m_CurRouteViewInfo.getDocumentTitle();
					if (title != null) {
						document.title = '';
						document.title = title;
					}
					setTimeout(() => {
						window.scrollTo(x, y);
					}, 10);
				}
			}
		}
	}

	/**
	 * アンロードイベント処理
	 */
	private onBeforeUnload(e: any): void {
		if (this.m_ViewStack.length > 0) {
			if (this.m_NoUpdateList.includes(this.m_ViewStack[0]) === true)
				e.returnValue = '更新してよろしいですか？';
		}
	}

	/**
	 * キーイベント処理
	 * @param event イベント情報
	 */
	private onKeydown(event: any) {
		if (event.ctrlKey === true && event.keyCode === 123) {
			event.returnValue = false;
			// alert('viewer表示');
		}
	}
	// --------------------------------------------------------------------------

	// public メソッド	----------------------------------------------------------
	/**
	 * バリデータ登録
	 * @param validator バリデータ
	 */
	public addValidator(validator: WprBaseValidator): void {
		if (this.m_ValidatorMap.has(validator.name) === false)
			this.m_ValidatorMap.set(validator.name, validator);
		else
			this.m_ErrorMngr.addErrorLog('WprLoader', `同一のvalidatorが既に登録されています。[${validator.name}]`);
	}

	/**
	 * バリデータ取得処理
	 * @param name バリデータ名
	 */
	public getValidator(name: string): WprBaseValidator {
		if (this.m_ValidatorMap.has(name) === true)
			return this.m_ValidatorMap.get(name);
		this.m_ErrorMngr.addErrorLog('WprViewMngr', `登録されていないvalidatorです。[${name}]`);
		return null;
	}

	/**
	 * カスタムメッセージ設定(コントロールの説明を含める場合は「%desc%」を使用する)
	 * @param name バリデータ名
	 * @param message カスタムメッセージ
	 */
	public setCustomMessage(name: string, message: string): void {
		if (this.m_ValidatorMap.has(name) === true) {
			const validator = this.m_ValidatorMap.get(name);
			validator.customMessage = message;
			validator.isCustom = true;
		}
		else {
			this.m_ErrorMngr.addErrorLog('WprViewMngr', `setCustomMessage:登録されていないvalidatorです。[${name}]`);
		}
	}

	/**
	 * コンバータ登録
	 * @param converter コンバータ
	 */
	public addConverter(converter: WprBaseConverter): void {
		if (this.m_ConverterMap.has(converter.name) === false) {
			this.m_ConverterMap.set(converter.name, converter);
		}
		else {
			this.m_ErrorMngr.addErrorLog('WprLoader', `同一のコンバータが既に登録されています。[${converter.name}]`);
		}
	}

	/**
	 * コンバータ取得処理
	 * @param name コンバータ名
	 */
	public getConverter(name: string): WprBaseConverter {
		if (this.m_ConverterMap.has(name) === true) {
			return this.m_ConverterMap.get(name);
		}
		else {
			this.m_ErrorMngr.addErrorLog('WprViewMngr', `登録されていないコンバータです。[${name}]`);
		}
		return null;
	}

	/**
	 * アクティブビュー情報追加
	 * @param viewInfo ビュー情報
	 */
	public addViewInfo(viewInfo: WprBaseViewInfo): void {
		if (viewInfo.props.match && viewInfo.isRouteView === true) {
			this.m_CurRouteViewInfo = viewInfo;
			const path = viewInfo.props.match.path;
			if (this.m_ViewStack.length === 0 || this.m_ViewStack[0] !== path) {
				const vname = viewInfo.name;
				this.m_ErrorMngr.addInfoLog('WprViewMngr', `View遷移。[${path}][${vname}]`);
				this.m_ViewStack.unshift(path);
				/* log
				console.info(`push -> ${viewInfo.name}`);
				console.info(`count = ${this.m_ViewStack.length}`);
				this.m_ViewStack.forEach((svname) => {
					console.info(`  ${svname}`);
				});
				*/
			}
		}
		for(const vinfo of this.m_ActiveViewList) {
			if (vinfo === viewInfo)
				return;
			if (this.getViewCompreName(vinfo) === this.getViewCompreName(viewInfo)) {
//				this.m_ErrorMngr.addWarningLog('WprViewMngr', `同一のビュー名が指定されています。[${viewInfo.name}][${viewInfo.objectName}][${vinfo.objectName}]`);
				break;
			}
		}
		this.m_ActiveViewList.push(viewInfo);
	}

	/**
	 * アクティブビュー情報削除
	 * @param viewInfo ビュー情報
	 */
	public deleteViewInfo(viewInfo: WprBaseViewInfo, isDialog: boolean = false): void {
		this.m_ErrorMngr.addDebugLog('WprViewMngr', `*** View終了 *** [${viewInfo.objectName}]`);
		if (this.m_ActiveViewList.includes(viewInfo)) {
			const idx = this.m_ActiveViewList.indexOf(viewInfo);
			this.m_ActiveViewList.splice(idx, 1);
			if (viewInfo.isDialog === false) {
				viewInfo.ChildViewList.forEach(child =>{
					if (child.isDialog === true)
						this.deleteViewInfo(child, true);
				});
			}
		}
		if (isDialog === false) {
			let formId = null;
			if (viewInfo.getScopeInfo() != null)
				viewInfo.getScopeInfo().deleteDIInfo(viewInfo);
			if (viewInfo.globalScopeInfo != null)
				viewInfo.globalScopeInfo.deleteDIInfo(viewInfo);
			if (viewInfo === this.m_CurRouteViewInfo)
				this.m_CurRouteViewInfo = null;
		}
	}

	/**
	 * スコープ更新プロパティ情報設定処理
	 */
	public setScopeUpdateProperty(): void {
		this.m_ActiveViewList.forEach(viewInfo => {
			viewInfo.setScopeUpdateProperty();
		});
	}

	/**
	 * スイッチコンポーネント設定
	 * @param sw スイッチコンポーネント
	 */
	public setSwitchComponet(sw: WprSwitch): void {
		const firstRedirectMap = new Map<string, string>();
		React.Children.forEach(sw.props.children, (child) => {
			const route = child as Route;
			if (route) {
				const path = route.props.path as string;
				if (route.props['noMove'])
					this.m_NoMoveList.push(path);
				if (route.props['noUpdate'])
					this.m_NoUpdateList.push(path);
				if (route.props['firstRedirect'])
					firstRedirectMap.set(path, route.props['firstRedirect']);
			}
		});
		if (this.m_ViewStack.length > 0) {
			const path = this.m_ViewStack[0];
			if (firstRedirectMap.has(path) === true)
				this.m_CurRouteViewInfo.props.history.replace(firstRedirectMap.get(path));
		}
	}

	/**
	 * Blurイベント停止処理
	 */
	public stopBlurEvent() {
		this.m_StopBlur = true;
	}

	/**
	 * Blurイベント再開処理
	 */
	public restartBlurEvent() {
		this.m_StopBlur = false;
	}

	/**
	 * ValueMapロード完了通知
	 * @param name ValueMap名
	 */
	public onLoadEndValueMap(name: string) {
		this.m_ActiveViewList.forEach((viewInfo) => {
			viewInfo.onLoadEndValueMap(name);
		});
	}

	/**
	 * ステータスロック開始処理
	 */
	public startStateLock(): void {
		this.m_ShowViewList = new Array();
	}

	/**
	 * ステータスロック終了処理
	 */
	public endStateLock(): void {
		this.m_ShowViewList = null;
	}

	/**
	 * 表示ビュー情報追加
	 * @param view 表示ビュー情報
	 */
	public addShowViewInfo(view: WprBaseViewInfo): void {
		if (this.m_ShowViewList != null)
			this.m_ShowViewList.push(view);
	}

	/**
	 * ステータスロック中に表示されたビューか調べる
	 * @param view ビュー情報
	 * @returns trueの場合、ステータスロック中に表示されたビュー
	 */
	public checkStateLockShowView(view: WprBaseViewInfo): boolean {
		if (this.m_ShowViewList != null && this.m_ShowViewList.includes(view) == true)
			return true;
		return false;
	}

	/**
	 * タブ子ビュー名称追加処理
	 * @param vname タブ子ビュー名称
	 * @param name 名称
	 */
	public addTabChildView(vname: string, name: string): void {
		if (this.includesTabChildView(vname, name) === false)
			this.m_TabChildViewList.push(new WprTabViewNameInfo(vname, name));
	}

	/**
	 * タブ子ビューチェック
	 * @param vname タブ子ビュー名称
	 * @param name 名称
	 * @returns tureの場合、タブ子ビュー
	 */
	public isTabChildView(vname: string, name: string): boolean {
		if (this.includesTabChildView(vname, name) === true)
			return true;
		return false;
	}

	/**
	 * タブ子ビュー削除処理
	 * @param name タブ子ビュー名称
	 */
	public removeTabChildView(vname: string, name: string): void {
		if (this.includesTabChildView(vname, name) === true) {
			const tcInfo = this.getTabChildViewInfo(vname, name);
			const idx = this.m_TabChildViewList.indexOf(tcInfo);
			this.m_TabChildViewList.splice(idx, 1);
		}
	}

	/**
	 * デバッグ情報設定
	 * @param dc デバック情報作成情報
	 */
	public setDebugInfo(dc: WprXmlInfoCreator): void {
		const mngr = dc.addRootChildElement('view_mngr');
		this.m_ActiveViewList.forEach(vinfo => {
			const view = dc.addChildElement(mngr, 'view');
			view.setAttribute('name', this.getViewName(vinfo.name));
			if (vinfo.props.name)
				view.setAttribute('pname', vinfo.props.name);
			const state = dc.addChildElement(view, 'state');
			if (vinfo.state.mode)
				dc.addChildJsonElement(state, 'mode', vinfo.state.mode);
			if (vinfo.state.scopeMode)
				dc.addChildJsonElement(state, 'scopeMode', vinfo.state.scopeMode);
			if (vinfo.state.globalMode)
				dc.addChildJsonElement(state, 'globalMode', vinfo.state.globalMode);
			if (vinfo.state.data)
				dc.addChildJsonElement(state, 'data', vinfo.state.data);
			if (vinfo.state.listData)
				dc.addChildJsonElement(state, 'listData', vinfo.state.listData);
			const model = dc.addChildElement(view, 'model');
			const minfo = vinfo.getModelObject();
			if (minfo)
				dc.addChildJsonElement(model, 'model', minfo);
			this.setControlDebugInfo(dc, view, vinfo);
			this.setListControlDebugInfo(dc, view, vinfo);
			this.setControlDIDebugInfo(dc, view, vinfo);
			this.setLogDebugInfo(dc, view, vinfo);
		});
	}
	// --------------------------------------------------------------------------

	// private メソッド	----------------------------------------------------------
	/**
	 * ビュー比較名取得
	 * @param vinfo ビュー情報
	 * @returns ビュー比較名
	 */
	 private getViewCompreName(vinfo: WprBaseViewInfo) {
		let vname = vinfo.name;
		 if (vinfo.props && vinfo.props.name)
			vname += '@' + vinfo.props.name;
		return vname;
	}

	/**
	 * タブ子ビューチェック
	 * @param vname ビュー名
	 * @param name 名称
	 * @returns trueの場合、タブ子ビュー
	 */
	private includesTabChildView(vname: string, name: string): boolean {
		for (const tcInfo of this.m_TabChildViewList) {
			if (tcInfo.compareName(vname, name) === true)
				return true;
		}
		return false;
	}

	/**
	 * タブ子ビュー情報取得
	 * @param vname ビュー名
	 * @param name 名称
	 * @returns タブ子ビュー情報
	 */
	private getTabChildViewInfo(vname: string, name: string): WprTabViewNameInfo {
		for (const tcInfo of this.m_TabChildViewList) {
			if (tcInfo.compareName(vname, name) === true)
				return tcInfo;
		}
		return null;
	}

	/**
	 * ビュー名取得
	 * @param name ビュークラス名
	 * @returns ビュー名 
	 */
	private getViewName(name: string) {
		if (name.endsWith('View') === true)
			return name.substring(0, name.length-4);
		return name;
	}

	/**
	 * コントロールデバック情報設定
	 * @param dc デバック情報作成情報
	 * @param view ビュー要素
	 * @param vinfo ビュー情報
	 */
	private setControlDebugInfo(dc: WprXmlInfoCreator, view: Element, vinfo: WprBaseViewInfo): void {
		const clist = this.getControlInfoList(vinfo);
		const obj = {};
		for(const cinfo of clist)
			obj[cinfo.name] = this.getControDebuglInfo(cinfo);
		dc.addChildJsonElement(view, 'controls', obj);
	}

	/**
	 * コントロール情報リスト取得
	 * @param vinfo ビュー情報
	 * @returns コントロール情報リスト
	 */
	private getControlInfoList(vinfo: WprBaseViewInfo): any[] {
		const clist = new Array();
		vinfo.controlInfoMap.controlRefreshMap.forEach(rmap => {
			if (rmap.name === '@@all') {
				rmap.controlMap.forEach(rinfo => {
					clist.push(rinfo.controlInfo);
				});
			}
		});
		return clist;
	}

	/**
	 * コントロールデバック情報取得
	 * @param cinfo コントロール情報
	 * @returns コントロールデバック情報
	 */
	private getControDebuglInfo(cinfo: WprControlInfo): any {
		const rtn = {};
		rtn['description'] = cinfo.description;
		rtn['value'] = cinfo.value;
		rtn['dispValue'] = cinfo.dispValue;
//		rtn['modeClass'] = cinfo.modeClass;
		rtn['authClass'] = cinfo.authClass;
		rtn['hissu'] = cinfo.status.hissu;
		rtn['visible'] = (cinfo.status.display && cinfo.status.visibility);
		rtn['enable'] = !cinfo.status.disabled;
		rtn['readOnly'] = cinfo.status.readOnly;
		rtn['error'] = cinfo.status.inputError;
		return rtn;
	}

	/**
	 * リストコントロールデバック情報設定
	 * @param dc デバック情報作成情報
	 * @param view ビュー要素
	 * @param vinfo ビュー情報
	 */
	private setListControlDebugInfo(dc: WprXmlInfoCreator, view: Element, vinfo: WprBaseViewInfo): void {
		const list = dc.addChildElement(view, 'list');
		vinfo.listDataMap.forEach((val, key) => {
			const linfo = vinfo.getListInfo(key);
			linfo.controlMap.forEach((cval, ckey) => {
				if (ckey.includes('@@') === false) {
					const clist = cval.controlList;
					const listControls = dc.addChildElement(list, 'controls');
					dc.addAttribute(listControls, 'lname', linfo.name);
					dc.addAttribute(listControls, 'cname', ckey);
					clist.forEach(cinfo => {
						dc.addChildJsonElement(listControls, 'control', this.getControDebuglInfo(cinfo));
					});
				}
			});
		});
	}

	/**
	 * コントロールDIデバック情報設定
	 * @param dc デバック情報作成情報
	 * @param view ビュー要素
	 * @param vinfo ビュー情報
	 */
	private setControlDIDebugInfo(dc: WprXmlInfoCreator, view: Element, vinfo: WprBaseViewInfo): void {
		const list = dc.addChildElement(view, 'cdi');
		vinfo.controlDIMap.forEach((val, key) => {
			const control = dc.addChildElement(list, 'control');
			dc.addAttribute(control, 'name', key);
			dc.addAttribute(control, 'val', String(val));
		});
	}

	/**
	 * ログデバック情報設定
	 * @param dc デバック情報作成情報
	 * @param view ビュー要素
	 * @param vinfo ビュー情報
	 */
	private setLogDebugInfo(dc: WprXmlInfoCreator, view: Element, vinfo: WprBaseViewInfo): void {
		const logs = dc.addChildElement(view, 'logs');
		vinfo.debugLogList.forEach(log => {
			dc.addChildTextlement(logs, 'log', log.getLog());
		});
	}
	// --------------------------------------------------------------------------
}