import BigNumber from "bignumber.js";

/**
 * 端数処理
 */
export class RoundUtil {
	/**
	 * 切り上げ
	 * @param point 桁数
	 * @returns 切り上げられた数値
	 */
	public static roundUp(num: number, point?: number): number{
		if(!point)
			point = 0;
		let result =  new BigNumber(num).dp(point, BigNumber.ROUND_UP).toNumber();
		return result;
	}

	/**
	 * 切り下げ
	 * @param point 桁数
	 * @returns 切り下げられた数値
	 */
	public static roundDown(num: number, point?: number): number{
		if(!point)
			point = 0;
		let result = new BigNumber(num).dp(point, BigNumber.ROUND_DOWN).toNumber();
		return result;
	}

	/**
	 * 四捨五入
	 * @param point 桁数
	 * @returns 四捨五入された数値
	 */
	public static round(num: number, point?: number): number{
		if(!point)
			point = 0;
		let result = new BigNumber(num).dp(point, BigNumber.ROUND_HALF_UP).toNumber();
		return result;
	}
}