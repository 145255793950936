import { WizardModel } from '../../models/WizardModel';
import { MypageWizardFlow } from './MypageWizardFlow';
import { MypageWizardMngr } from './models/MypageWizardMngr';
import { CommonCompleteView } from '../common/common_complete/CommonCompleteView';
import { ModNhkConfirmView } from '../Srvother/mod_nhk_confirm/ModNhkConfirmView';
import { GetViewInfoRequest } from '../../service/models/dto/mypagerenewal/GetViewInfoRequest';
import { MypageBaseView } from '../base/MypageBaseView';
import { Nhk } from '../../models/bss/nhk/Nhk';
import { MDisplay } from '../../service/models/entity/secondary/MDisplay';
import MypageDispdivcdConstants from '../../common/MypageDispdivcdConstants';
import { RegistInfoRequest } from '../../service/models/dto/mypagerenewal/RegistInfoRequest';
import { InquiryMailInfo } from '../../service/models/apimodels/InquiryMailInfo';
import { MAIL_CONSTANTS } from '../../common/MyPageMailIdConstants';
/**
 * ITSCOM HOME_追加ウィザードクラス
 */
export class NhkCancelWizardFlow extends MypageWizardFlow {
	// private 変数  ------------------------------------------------------------
	private m_DefaultWizardFlow: WizardModel[]			= null;		// 画面ウィザードリスト
	private m_StartViewName: string						= null;
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	public constructor() {
		super();
		this.m_StartViewName = new ModNhkConfirmView().name;

		// 遷移パターン１
		this.m_DefaultWizardFlow = new Array();
		this.m_DefaultWizardFlow.push({stepNum: 0, title: '契約選択', viewName: null, path: this.getOldUrl() + 'Srvlist/Continfo_list/'});
		this.m_DefaultWizardFlow.push({stepNum: 1, title: '申込み内容の確認', viewName: this.m_StartViewName, path: 'mod_nhk_confirm'});
		this.m_DefaultWizardFlow.push({stepNum: 2, title: '申込み完了', viewName: new CommonCompleteView().name, path: 'mod_nhk_complete'});
	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * 画面ウィザードフロー名取得
	 */
	public getWizardFlowName(viewName: string): string {
		if (viewName === this.m_StartViewName)
			return this.constructor.name;
		return null;
	}
	
	/**
	 * 画面ウィザードリスト取得：契約状態で切り替えたり、選択肢で切り替えたり
	 */
	public getWizardFlow(mngr: MypageWizardMngr): WizardModel[] {
		return this.m_DefaultWizardFlow;
	}
	
	/**
	 * 画面ウィザード完了APIモデル取得
	 */
	public getRegistApiModel(mngr: MypageWizardMngr, view: MypageBaseView): RegistInfoRequest{
		const req: RegistInfoRequest = new RegistInfoRequest();
		const mailconstants = MAIL_CONSTANTS.find (mail => {
			return mail.view === 'NHK_解約';
		});
		req.customerId = mngr.getParams().customerId;
		req.systemInfo = new InquiryMailInfo();
		req.customerMailId = mailconstants.customerMail;
		// req.systemMailId = mailconstants.systemMail;
		req.inquiryId = mailconstants.inquiryMail;
		req.systemInfo.escalationFlg = true;
		return req;
	}
	/**
	 * 画面ウィザードAPI用モデル取得：契約状態で切り替えたり、選択肢で切り替えたり
	 */
	public getWizardApiModel(mngr: MypageWizardMngr, view: MypageBaseView): GetViewInfoRequest {
		const req = new GetViewInfoRequest();
		req.customerId = mngr.getParams().customerId;
		req.displayList = new Array();
		for(let i=0; i<4; i++){
			const filter = new MDisplay();
			filter.dispdivcd = MypageDispdivcdConstants.NHK_DISCOUNTTYPE;
			filter.namecd = String(i);
			req.displayList.push(filter);
		}
		return req;
	}

	/**
	 * 画面キャッシュの初期化データ取得
	 */
	// public getInitCacheData(mngr: MypageWizardMngr): NhkNewWizardModel {
	// 	let cacheData = new NhkNewWizardModel();
	// 	cacheData.discountInfo = new Nhk();
	// 	return cacheData;
	// }
	

	/**
	 * 画面ウィザードフローの中断判定：例：申し込みが完了していれば、完了済みの画面へ遷移
	 */
	public checkFlowBreak(mngr: MypageWizardMngr): string {
		let test: boolean = false;
		if (test) {
			return '/error';
		}
		return null;
	}
}

/**
 * 画面ウィザードAPI用モデル
 */
export class NhkNewWizardModel {
	// public 変数	-----------------------------------------------------------
		public discountInfo: Nhk = null;
	// ------------------------------------------------------------------------
}