import { WprBaseViewListMultiModeInfo } from '../../../../../../wpr-framework/mode/WprBaseViewListMultiModeInfo';
import { WprMultiMode } from '../../../../../../wpr-framework/mode/WprMultiMode';
import { TvOptionListModel } from '../../../../../models/mypage/tv/TvOptionListModel';
import { ModTvSelect1View } from '../ModTvSelect1View';
import { WprDI_Object } from '../../../../../../wpr-framework/view/di/WprDI_Object';

/**
 * OptionListMode
 */
export class OptionListMode extends WprBaseViewListMultiModeInfo<ModTvSelect1View, TvOptionListModel> {
	// DI情報  ------------------------------------------------------------------
	@WprDI_Object
	private m_BeforeTvOptionListModel: TvOptionListModel[]	= null;	// テレビオプションリストモデル
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	public constructor() {
		super('BeforeTvOptionListModel');
	}
	// --------------------------------------------------------------------------
	
	// override メソッド  --------------------------------------------------------
	/**
	 * モードリスト取得（この画面モードで使用する全てのモードを定義する）
	 * @param list モードリスト
	 */
	public useModeList(list: string[]): void {
		list.push('bottomNone');
		list.push('green');
	}

	/**
	 * フィールド定義を登録処理
	 */
	public onRegisterControl(): void {
		this.addControl('option', { class: [ { mode: 'bottomNone', class: 'borderBottom' }, { mode: 'other', class: '' } ] });
		this.addControl('status', { class: [ { mode: 'bottomNone&green', class: 'borderBottom greenFont' }, { mode: 'bottomNone', class: 'borderBottom' }, { mode: 'green', class: 'greenFont' }, { mode: 'other', class: '' } ] });
	}

	/**
	 * ビューの状態をチェックして行のモードを変更する
	 * @param curMode 現在のモード
	 * @param newMode 新しいモード
	 * @param row 行データ
	 */
	public onCheckListMultiMode(curMode: WprMultiMode, newMode: WprMultiMode, row: TvOptionListModel): void {
		const idx = this.m_BeforeTvOptionListModel.length;
		if (this.m_BeforeTvOptionListModel[idx-1].option === row.option)
			newMode.addMode('bottomNone');
		if (this.m_BeforeTvOptionListModel.length > 0)
			newMode.addMode('green');
	}
	// --------------------------------------------------------------------------
}
