import { BaseService } from './base/BaseService';
import { WprBaseViewCore } from '../../wpr-framework/view/WprBaseViewCore';
import { GetViewInfoRequest } from './models/dto/mypagerenewal/GetViewInfoRequest';
import { GetViewInfoResponse } from './models/dto/mypagerenewal/GetViewInfoResponse';
import { GetSimulationRequest } from './models/dto/mypagerenewal/GetSimulationRequest';
import { GetSimulationResponse } from './models/dto/mypagerenewal/GetSimulationResponse';
import { RegistInfoRequest } from './models/dto/mypagerenewal/RegistInfoRequest';
import { RegistInfoResponse } from './models/dto/mypagerenewal/RegistInfoResponse';
import { GetMGeneralRequest } from './models/dto/mypagerenewal/GetMGeneralRequest';
import { GetMGeneralResponse } from './models/dto/mypagerenewal/GetMGeneralResponse';
import { RegistContractRequest } from './models/dto/mypagerenewal/RegistContractRequest';
import { GetGradeRequest } from './models/dto/mypagerenewal/GetGradeRequest';
import { GetGradeResponse } from './models/dto/mypagerenewal/GetGradeResponse';
import { GetCampaignRequest } from './models/dto/mypagerenewal/GetCampaignRequest';
import { GetCampaignResponse } from './models/dto/mypagerenewal/GetCampaignResponse';
import { GetConstructionCostRequest } from './models/dto/mypagerenewal/GetConstructionCostRequest';
import { GetConstructionCostResponse } from './models/dto/mypagerenewal/GetConstructionCostResponse';
import { RegistAccessLogRequest } from './models/dto/common/RegistAccessLogRequest';
import { RegistAccessLogResponse } from './models/dto/common/RegistAccessLogResponse';

/**
 * 共通サービス
 */
export class CommonService extends BaseService {
	// サービス メソッド  --------------------------------------------------------
	/**
	 * 画面情報取得
	 * @param viewCore ビュー情報
	 * @param request リクエスト情報
	 * @param resMethod 結果処理メソッド
	 */
	public GetViewInfo(viewCore: WprBaseViewCore, request: GetViewInfoRequest, resMethod: (result: GetViewInfoResponse) => void): void {
		return this.doPost(viewCore, '/common/GetViewInfo', request, resMethod);
	}

	/**
	 * 料金シミュレーション取得処理
	 * @param viewCore ビュー情報
	 * @param request リクエスト情報
	 * @param resMethod 結果処理メソッド
	 */
	public getPaymentSimulation(viewCore: WprBaseViewCore, request: GetSimulationRequest, resMethod: (result: GetSimulationResponse) => void): void {
		return this.doPost(viewCore, '/common/getPaymentSimulation', request, resMethod);
	}

	/**
	 * 登録処理
	 * @param viewCore ビュー情報
	 * @param request リクエスト情報
	 * @param resMethod 結果処理メソッド
	 */
	public registInfo(viewCore: WprBaseViewCore, request: RegistInfoRequest, resMethod: (result: RegistInfoResponse) => void): void {
		return this.doPost(viewCore, '/common/registInfo', request, resMethod);
	}

	/**
	 * 約款情報取得
	 * @param viewCore ビュー情報
	 * @param request リクエスト情報
	 * @param resMethod 結果処理メソッド
	 */
	public getMGeneralList(viewCore: WprBaseViewCore, request: GetMGeneralRequest, resMethod: (result: GetMGeneralResponse) => void): void {
		return this.doPost(viewCore, '/common/getMGeneralList', request, resMethod);
	}

	/**
	 * 契約情報変更登録
	 * @param viewCore ビュー情報
	 * @param request リクエスト情報
	 * @param resMethod 結果処理メソッド
	 */
	public registContract(viewCore: WprBaseViewCore, request: RegistContractRequest, resMethod: (result: RegistInfoResponse) => void): void {
		return this.doPost(viewCore, '/common/registContract', request, resMethod);
	}

	/**
	 * グレード情報
	 * @param viewCore ビュー情報
	 * @param request リクエスト情報
	 * @param resMethod 結果処理メソッド
	 */
	public getGradeInfo(viewCore: WprBaseViewCore, request: GetGradeRequest, resMethod: (result: GetGradeResponse) => void): void {
		return this.doPost(viewCore, '/common/getGradeInfo', request, resMethod);
	}

	/**
	 * キャンペーン取得
	 * @param viewCore ビュー情報
	 * @param request リクエスト情報
	 * @param resMethod 結果処理メソッド
	 */
	public getCampaign(viewCore: WprBaseViewCore, request: GetCampaignRequest, resMethod: (result: GetCampaignResponse) => void): void {
		return this.doPost(viewCore, '/common/getCampaign', request, resMethod);
	}

	/**
	 * 工事費取得処理
	 * @param viewCore ビュー情報
	 * @param request リクエスト情報
	 * @param resMethod 結果処理メソッド
	 */
	public getConstructionCost(viewCore: WprBaseViewCore, request: GetConstructionCostRequest, resMethod: (result: GetConstructionCostResponse) => void): void {
		return this.doPost(viewCore, '/common/getConstructionCost', request, resMethod);
	}

	/**
	 * アクセス履歴登録
	 * @param viewCore ビュー情報
	 * @param request リクエスト情報
	 * @param resMethod 結果処理メソッド
	 */
	public registAccessLog(viewCore: WprBaseViewCore, request: RegistAccessLogRequest, resMethod: (result: RegistAccessLogResponse) => void): void {
		return this.doPost(viewCore, '/common/registAccessLog', request, resMethod);
	}
	// --------------------------------------------------------------------------
}
