/**
 * MVNO一時保存
 */
export class HMvnoTmpSaved {
	// public 変数	-----------------------------------------------------------
	public mvnoTmpSavedId: number;			// MVNO一時保存ID
	public customerId: string;				// 顧客ID
	public saveViewName: string;			// 保存画面名
	public saveTime: Date;					// 保存日時
	public expireTime: Date;				// 有効期限日時
	public plan: string;					// プラン
	public capacity: string;				// データ量
	public capacityServiceCd: string;		// データ量サービスコード
	public course: string;					// コース
	public courseServiceCd: string;			// コースサービスコード
	public fivegUseFlg: Boolean;			// 5G通信利用
	public rusubanUseFlg: Boolean;			// 留守番電話オプション利用
	public rusubanServiceCd: string;		// 留守番電話オプションサービスコード
	public terminalUseFlg: Boolean;			// 端末購入有無
	public terminal: string;				// 端末
	public terminalId: string;				// 端末ID
	public terminalColor: string;			// 端末カラー
	public terminalColorId: string;			// 端末カラーID
	public terminalGuaranteeFlg: Boolean;	// 端末延長保証利用
	public terminalGuaranteeServiceCd: string;	// 端末延長保証サービスコード
	public simSize: string;					// SIMサイズ
	public simOption: string;				// SIMオプション
	public initialSupportFlg: Boolean;		// 初期設定サポート申込
	public webFilteringFlg: Boolean;		// WEBフィルタリング申込
	public webFilteringServiceCd: string;	// WEBフィルタリングコード
	public familyName: string;				// 氏名（姓）
	public firstName: string;				// 氏名（名）
	public familyNameKana: string;			// 氏名（セイ）
	public firstNameKana: string;			// 氏名（メイ）
	public relationShip: string;			// 続柄
	public birthday: string;				// 生年月日
	public birthdayYear: string;				// 生年月日（年）
	public birthdayMonth: string;				// 生年月日（月）
	public birthdayDay: string;					// 生年月日（日）
	public phoneNumber1: string;			// 携帯電話番号1
	public phoneNumber2: string;			// 携帯電話番号2
	public phoneNumber3: string;			// 携帯電話番号3
	public numberingWay: string;			// 採番方法
	public mnpReqNumber: string;			// MNP予約番号
	public mnpReqExpire: string;			// MNP予約有効期限
	public mnpUseNumber: string;			// MNP予約番号
	public reqDay1: string;					// 第一希望（月日）
	public reqTime1: string;				// 第一希望（時間帯）
	public reqDay2: string;					// 第二希望（月日）
	public reqTime2: string;				// 第二希望（時間帯）
	public reqDay3: string;					// 第三希望（月日）
	public reqTime3: string;				// 第三希望（時間帯）
	public campaignCode: string;			// キャンペーンコード
	public entryDate: Date;					// 登録日時
	public entryCd: string;					// 登録担当者コード
	public updateDate: Date;				// 更新日時
	public updateCd: string;				// 更新担当者コード
	public deleteFlag: boolean;				// 削除フラグ
	public deleteDate: Date;				// 削除日時
	public deleteCd: string;				// 削除担当者コード
	// ------------------------------------------------------------------------
}
