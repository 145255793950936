import { AccordionDetails, AccordionSummary, Card, Divider, TableCell, Typography } from '@material-ui/core';
import { Grid } from '@mui/material';
import React, { ReactNode } from 'react';
import { WprBaseViewComponent } from '../../../../../wpr-framework/view/component/WprBaseViewComponent';
import { MenuBar } from '../../../common/menu_bar/MenuBar';
import { MvnoEntryPreConfirmView } from './MvnoEntryPreConfirmView';
import styles from './MvnoEntryPreConfirm.scss';
import { WprRadio } from '../../../../../wpr-framework/component/controls/WprRadio';
import { MatRadio } from '../../../../../wpr-framework/component/controls/material/MatRadio';
import { CheckMarkRadio } from '../../../../controls/checkMarkRadio/CheckMarkRadio';
import { MvnoPlanSendData, CheckMarkRadioContent } from '../../../../controls/checkMarkRadio/CheckMarkRadioContent';
import { MatButton } from '../../../../../wpr-framework/component/controls/material/MatButton';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { MatOneRowTable } from '../../../../../wpr-framework/component/controls/material/MatOneRowTable';
import { MatTextField } from '../../../../../wpr-framework/component/controls/material/MatTextField';
import { MatCheckBox } from '../../../../../wpr-framework/component/controls/material/MatCheckBox';
import { MatAccordion } from '../../../../../wpr-framework/component/controls/material/MatAccordion';
import { WprRowInfo } from '../../../../../wpr-framework/component/props/WprRowInfo';
import { WprSpan } from '../../../../../wpr-framework/component/controls/WprSpan';
import { WprAnchor } from '../../../../../wpr-framework/component/controls/WprAnchor';
import { MGeneral } from '../../../../service/models/entity/MGeneral';
import { WprContainer } from '../../../../../wpr-framework/component/controls/WprContainer';
import { ProgressBar } from '../../../common/progress_bar/ProgressBar';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

/**
 * MVNO申込_事前確認事項用コンポーネント
 */
export class MvnoEntryPreConfirm extends WprBaseViewComponent {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: any) { super(new MvnoEntryPreConfirmView(), props); }
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** MVNO申込_事前確認事項ビュー情報 */
	public get viewInfo(): MvnoEntryPreConfirmView { return this.view as MvnoEntryPreConfirmView; }
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * スタイルオブジェクト取得
	*/
	public getStylesObject(): any { return styles; }

	/**
	 * リストコントロール定義登録
	*/
	protected onRegisterListRender(): void {
		this.addListRender('ImportDocList', null, this.onImportDocListRender);
	}

	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		return (
			<>
				{/* Milosから追加 START */}

				{/* Milosから追加 END */}
				<div>
					<div className='pageCard'>
						<div className="pageHeader stepFlow">
							<ProgressBar name='progressBar' view={this.view} />
						</div>
						<div className='pageContent'>
							<div className='link_blue'>
								<WprAnchor name='helpLink' view={this.view}>申込みについてご不明な方はこちら＞</WprAnchor>
							</div>

							<div className='pageTitle'>
								<div className='title_normal'>
									<Typography className='title_text'>事前確認事項</Typography>
									<div className='title_memo'>
										以下の内容をご確認いただき、各項目に✓をお願いいたします。
									</div>

								</div>
							</div>
							<div>

								<div style={{ textAlign: 'left' }}>


									<div className='preConfirmList'>


										<div className='title_lineLeft'>
											<Typography>サービス内容・ご利用時の留意事項について</Typography>
										</div>
										<ul>
											<li>
												当社で提供するSIMカードは、NTTドコモの回線を使用した音声通話対応またはデータ通信対応のSIMカードとなります。
												<br />
												なお、イッツコム ＳＩＭではNTTドコモが提供する付加サービス（iモードやドコモメールなど）を利用することはできません。

											</li>
											<li>
												利用可能エリアはNTTドコモのFOMA（3G）、Xi（LTE）および5Gの利用可能エリアに準じます。
												<br />また、5G通信機能のご利用にあたり、以下の内容についてもご同意いただくものとします。

												<br />
												・ご契約時に、5G通信機能のON/OFFを選択していただきます。
												<br />
												・5G通信機能ONを選択する場合は、5Gに対応している端末をお使いいたく必要があります。5Gに対応していない端末は通信できない場合があります。
												<br />
												・5G通信機能ONを選択し、5G端末をお使いいただく場合は3Gサービスのご利用はできません。
												<br />
												・5G通信においても、現在は4Gで接続した場合の通信速度とあまり変わりません。
											</li>
											<li>
												緊急地震速報やJ-ALERT等は、お使いの端末によってはご利用いただけない場合があります。
											</li>
											<li>
												お客さまが利用されるエリアや端末、通信環境、ご利用状況によって、回線速度は異なります。また、ご利用状況によっては通信速度が制限される場合があります。
												<br />※1ヵ月で利用可能な通信容量を超過した場合、下り最大200Kbpsとなります。利用開始月のみ、利用開始日から月末までの日数で通信容量を比例換算した通信量が上限となります。
											</li>
											<li>
												当社またはNTTドコモのシステムメンテナンス時は、一部またはすべてのサービスがご利用いただけない場合があります。
											</li>
											<li>
												本サービスの国外でのご利用は、音声通話（国際ローミング）のみが可能で、データ通信はご利用いただけません。
											</li>
											<li>
												一部の端末でテザリングができません。
											</li>
											<li>
												一部の端末でアンテナピクトが表示されない、または「圏外」と表示される場合があります。
											</li>
											<li>
												通信量の超過や連続したデータを送受信した場合の速度制限によって生じた損害について、当社は一切その責任を負うことができません。
											</li>
											<li>
												天災地変時には、修理・故障等のサポート対応に時間がかかる場合があります。
											</li>
											<li>
												今回初めて当社サービスにお申し込みいただく場合、お支払い方法はクレジットカードのみとさせていただきます。
											</li>
											<li>
												現在当社サービスを利用中でイッツコム ＳＩＭを追加契約にてお申し込みをいただく場合は、お支払い方法はクレジットカードまたは口座振替のどちらかをお選びいただけます。ただし、口座振替の場合は当社にて 6 ヵ月間お支払い実績がある場合に限ります。
											</li>
											<li>
												エリア外へ転居をされた場合、通常料金に変更となる場合があります。
											</li>
											<WprContainer mode='プラン.音声' view={this.view}>
												<div className='itemTitle title_maru' style={{ marginTop: '1rem' }}>音声プランをお申し込みの方</div>
												<li>
													音声プランの契約期間はご利用開始月の翌月から12ヵ月間です。その後は12ヵ月毎の自動更新となります。
													<a href='https://www.itscom.co.jp/service/mobile/sim/#hdg1' target = '_blank' className='link_color'>イッツコム対象サービス</a>をご契約されている場合、契約期間内に解約しても、解約料金は発生いたしません。
													イッツコム対象サービスをご契約されていない場合、ご利用開始月の翌月から12ヵ月間以内に解約する場合は、音声プラン契約1回線につき1ヵ月分の月額利用料相当額の解約料金が別途発生いたします。
												</li>
												<li>
													法人名義での契約はできません。
												</li>
												<li>
													10分かけ放題コースをご契約の場合、回数にかかわらず、通話時間10分以内の料金はかかりません。10分を超える通話の場合、30秒11円の通話料がかかります。（国際通話・国際ローミングは除きます）

												</li>
												<li>
													音声プランを新規お申し込み時に初期設定サポートをご希望いただいた場合、1回線につき1回のみご利用いただけます。当社による初期設定サポートをご希望の場合、当日別途書面にて作業内容・注意事項に同意いただきます。
												</li>
												<li>
													音声プランをご契約の場合、危険なSMS（ショートメッセージサービス）を自動で拒否する機能が適用されます。詳しくは当社HPのFAQをご参照ください。
												</li>
												<li>
													かけ放題コースをご契約の場合、一部を除き一般の国内通話の通話料はかかりません。
												</li>
												<li>
													留守番電話オプションをお申し込みの場合、ご利用開始まで最大で6日かかります。
													<br />※月末の6日前以降のお申し込みは、翌日1日のお申し込みとなります。
													<br />※新規ご契約時に留守番電話もあわせてお申し込みいただいた場合でも、開通日がお申し込み日となります。
												</li>
												<li>
													イッツコム対象サービスの契約がない方は0GBのサービスはお申込みできません。
												</li>
											</WprContainer>
											<WprContainer mode='プラン.データ' view={this.view}>
												<div className='itemTitle title_maru' style={{ marginTop: '1rem' }}>データ専用プランをお申し込みの方</div>
												<li>
													当社で提供するデータ専用SIMカードには、SMS（ショートメッセージ）機能はありません。
												</li>
												<li>
													データ専用プランをご契約の場合は、同時に端末の契約がある場合に限り、初期設定をした状態での発送が可能です。
												</li>
												<li>
													新規のお申し込みで現在ご利用中のデータ専用プランをご契約の場合、携帯電話会社の解約手続きについてはお客さまご自身で行っていただく必要があります。
												</li>
											</WprContainer>

											<WprContainer mode='端末申込.無' view={this.view}>
												<div className='itemTitle title_maru' style={{ marginTop: '1rem' }}>SIMカードのみのお申し込みの方
													※ご自身で端末を用意する場合
												</div>
												<li>
													LTEまたは3Gに対応し、技術基準に適合した（技適マークの付いた）端末を別途ご用意ください。
												</li>
												<li>
													お手持ちの端末に対応するSIMカードの種類を今一度ご確認いただき、標準SIM・microSIM・nanoSIMのいずれかを選択してください。
												</li>
												<li>
													NTTドコモのネットワークに対応している周波数帯に対応した端末をご用意ください。また、一部の端末でSIMロック解除を行っていただく必要があります。SIMロック解除をする際の費用についてはお客さま負担となります。
												</li>
											</WprContainer>
											<WprContainer mode='端末申込.有' view={this.view}>
												{/* 端末.Androidの文言はiPhone以外の端末の申し込みがあった場合表示(ルーター含む) */}
												<WprContainer mode='端末.Android' view={this.view}>
													{/* Android用文言 */}
													<div className='itemTitle title_maru' style={{ marginTop: '1rem' }}>Android端末も同時にお申し込みの方</div>
													<li>
														Android端末のメーカー保証期間は、サービス開始日（お客さまへの端末お渡し日／端末発送日）より12ヵ月間となります。
													</li>
													<li>
														端末の契約期間はご利用開始日の翌月から24ヵ月間です。契約期間内に解約する場合は、利用期間に応じ、別途解約料金が発生します。
													</li>
													<li>
														解約される場合、端末が不要な方は返却できます。
													</li>
													<li>
														端末により、ご契約可能な通信容量（月間）は異なります。
													</li>
												</WprContainer>
												<WprContainer mode='端末.iPhone' view={this.view}>
													{/* iPhone用文言 */}
													<div className='itemTitle title_maru' style={{ marginTop: '1rem' }}>iPhone端末を同時にお申し込みの方</div>
													<li>
														iPhone端末の保証期間は、サービス開始日（お客さまへの端末お渡し日／端末発送日）より12か月間となります。
													</li>
													<li>
														メーカー保証は対象外となります。
													</li>
													<li>端末保証は、当社の契約事業者であるReYuu Japan株式会社のCertified端末保証利用規約に基づき提供いたします。
														<br />端末保証を行うにあたり、お客様の個人情報を当社から保証業務を委託しているReYuu Japan株式会社に連携し、お客さまの個人情報は、ReYuu Japan株式会社のCertified端末保証利用規約内における対応時においても当社の定める「個人情報保護方針」に基づき適正に取り扱います。
													</li>
													<li>
														端末の契約期間はご利用開始日の翌月から24ヵ月間です。契約期間内に解約する場合は、利用期間に応じ、別途解約料金が発生します。
													</li>
													<li>
														解約される場合、端末が不要な方は返却出来ます。
													</li>
												</WprContainer>
											</WprContainer>
										</ul>
									</div>

									{/*  */}
									<div className='preConfirmList'>

										<div className='title_lineLeft'>
											<Typography>ご契約時・番号ポータビリティの注意点</Typography>
										</div>
										<ul>
											<li>
												お申し込み可能な回線は、1 世帯または 1 法人につき 5 回線までとなります。(ただし、当社との契約実績によっては、複数回線のお申し込みをお断りする場合があります。）
											</li>
											<li>
												本サービスは未成年の方のお申し込みはできません。ただし、親権者のご契約時に利用者としてお申し込みいただけます。
											</li>
											<li>
												新規お申し込み時に申込者とイッツコム ＳＩＭの利用者が異なる場合は、利用者のお申し出により利用者情報を登録いたします。
												<br />利用者が変更になる場合は、お申し出が必要です。
											</li>
											<li>
												新規契約事務手数料として、1 契約につき3,733円がかかります。
											</li>
											<li>
												各種サポートを当社が行う場合は、本サービスとは別に料金が発生します。（音声プランを新規お申し込み時の初期設定サポートを除く）
											</li>
											<li>
												SIMカードの種類や通信容量（月間）を変更・再発行または番号ポータビリティ転出などの際に、手数料がかかります。
											</li>
											<li>
												通信容量（月間）を変更する場合、契約変更希望日の前月末日10日前までに当社にご連絡ください。
											</li>
											<li>
												SIMカードの紛失・盗難時は当社にご連絡ください。⼀時中断の手続きを行います。依頼を受付してから一定期間後に完了します。なお、⼀時中断中でも月額利用料は発生します。
												<br />※当社は契約管理にて停止・再開処理を実行している為、処理に一定期間かかっております。17:30以降の依頼については翌日以降の対応となっております。
											</li>
											<li>
												新規でお申し込みの場合、現在ご利用中の携帯電話会社の解約手続きについてはお客さまご自身で行っていただく必要があります。現在ご利用中の携帯電話会社のメールアドレス、およびその他サービスについても解約となり、ご利用いただけなくなります。「ご利用中の携帯電話会社」のクラウドサーバなどに保存しているデータなどは、事前にダウンロードするなど、十分ご確認のうえ、解約手続を行ってください。また、現在の携帯電話の契約状況により、現在ご利用中の携帯電話会社に対して解約違約金等が発生する場合があります。
											</li>
											<li>
												番号ポータビリティ転入の場合、現在ご利用中の携帯電話会社にて番号ポータビリティ予約番号の取得が必要です。
												<br />お申し込み時に有効期限が 9 日以上残っている必要があります。 ご契約前に既に取得されていた場合も、改めて取得いただく場合があります。
											</li>
											<li>
												番号ポータビリティ転入の際は、ご利用中の携帯電話会社に登録されているご契約者情報、当社への登録情報、本人確認書類に記載された情報は、すべてが一致している必要がありますのでご注意ください。
												<br />
												※ご利用中の携帯電話会社に登録されているご契約者情報と、当社の登録情報が異なる場合は、本サービスをお申し込み前にご利用中の携帯電話会社で名義変更を行った後に当社へお申し込みください。
											</li>
											<li>
												KDDI(au)やソフトバンクのスマートフォンについては、通信方式の違いやSIMロック解除ができないなどの理由から、現在ご利用中の端末を引き続きご利用いただけない場合があります。当社での動作確認は行いませんので、詳しくはご利用中の携帯電話会社の販売店までお問い合わせください。
											</li>
											<li>
												番号ポータビリティ予約番号の有効期限前日までに開通手続きが行われない場合は、有効期限の当日中に自動的に開通されます。
												<br />
												開通後は切り換え前までご利用されていたSIMカードはご利用いただけなくなります。
											</li>
										</ul>
									</div>

									{/*  */}
									<WprContainer mode='端末申込.有' view={this.view}>
										<div className='preConfirmList'>
											<div className='title_lineLeft'>
												<Typography>イッツコム モバイル端末延長保証サービスについて</Typography>
											</div>
											<ul>
												<li>
													イッツコム モバイルの端末タイプをお申し込みの時に限り、イッツコム モバイル端末延長保証サービスを申し込むことができます。
												</li>
												<li>
													イッツコム モバイル端末延長保証サービスを、イッツコム モバイルの契約後に申し込むことはできません。
												</li>
											</ul>

										</div>
									</WprContainer>

									{/*  */}
									<WprContainer mode='プラン.音声' view={this.view}>
										<div className='preConfirmList'>
											<div className='title_lineLeft'>
												<Typography>ご利用者が18歳未満の場合</Typography>
											</div>
											<ul>
												<li>
													本サービスを18歳未満の方がご利用される場合は、原則としてwebフィルタリングにお申し込みいただく必要があります。
													<WprContainer mode='端末.Android' view={this.view}>
														<br />webフィルタリングは 1 シリアルIDあたり220円がかかります。マイページからお申し込みが可能です。
													</WprContainer>
													<WprContainer mode='端末.iPhone' view={this.view}>
														<br />webフィルタリング(1アカウント用)は359円、(3アカウント用)は796円がかかります。マイページからお申し込みが可能です。
													</WprContainer>
													<WprContainer mode='端末.SIMのみ' view={this.view}>
														<br />Android版webフィルタリングは 1シリアル IDあたり220円、iPhone対応マルチデバイス版のwebフィルタリング(1台用)は1シリアルIDあたり359円、(3台用)は1シリアルIDあたり796円がかかります。マイページからお申し込みが可能です。
													</WprContainer>
													<br />当社のフィルタリングサービスを利用しない場合は、別途書面を提出のうえ、お客さまご自身で代替サービスをご用意ください。
												</li>
											</ul>

										</div>
									</WprContainer>
								</div>
								<div style={{ textAlign: 'center' }}>
									<MatCheckBox name='c_confirmList' view={this.view} label='上記の注意事項について確認しました。' />

								</div>
							</div>
							<div>
								{/* 重要事項説明等 */}
								<div>
									<div className='title_lineLeft'>
										<Typography>重要事項説明等</Typography>
									</div>
									<div style={{ margin: '1rem 1rem' }}>
										<div>
											下記リンクより「約款・利用規約」「重要事項説明」を全て閲覧いただくことで次の画面に進めます。
										</div>
										<div>
											<div className='itemTitle title_maru'>確認必須項目</div>
											<div className='confirmBoxParent'>
												<MatButton name='b_importantDoc1' view={this.view} className='confirmBox' style={{ height: '60px' }}>
													<div className='labelFlex'>
														<div>
															<WprContainer mode='重説.未確認' view={this.view}>	<NavigateNextIcon /></WprContainer>
															<WprContainer mode='重説.確認済' view={this.view}>	<ExpandMoreIcon /></WprContainer>
														</div>
														<div>イッツコムサービス重要説明事項/各種約款・規約</div>

														<div className='flexRight bluetext'>3,100<span className='blueunit'>kb</span></div>
													</div>

												</MatButton>
											</div>
											<div className='confirmBoxParent'>
												<WprContainer mode='端末.iPhone' view={this.view}>
													{/* iPhone選択時のみ */}
													<MatButton name='b_importantDoc2' view={this.view} className='confirmBox' style={{ height: '60px' }}>
														<div className='labelFlex'>
															<div>
																<WprContainer mode='iPhone重説.未確認' view={this.view}>	<NavigateNextIcon /></WprContainer>
																<WprContainer mode='iPhone重説.確認済' view={this.view}>	<ExpandMoreIcon /></WprContainer>
															</div>
															<div style={{ textTransform: 'none' }}>Certified端末保証利用規約</div>

															<div className='flexRight bluetext'>755<span className='blueunit'>kb</span></div>
														</div>
													</MatButton>
												</WprContainer>
											</div>
											<div className='confirmBoxParent'>
												<MatAccordion mode='重要説明事項2' name='importantDoc2' className='confirmBox' view={this.view}>
													<AccordionSummary style={{ height: '60px' }}>
														<div className='labelFlex'>
															<div>
																<WprContainer mode='約款.閉' view={this.view}>	<NavigateNextIcon /></WprContainer>
																<WprContainer mode='約款.開' view={this.view}>	<ExpandMoreIcon /></WprContainer>
															</div>
															<div>「約款」・「利用規約」の詳細を確認される方はこちら</div>
														</div>
													</AccordionSummary>
													<AccordionDetails className='confirmAccordionContent'>
														<div className='itemTitle title_maru'>
															共通
														</div>
														<div className='confirmList'>
															{this.renderList('ImportDocList')}
														</div>
													</AccordionDetails>
												</MatAccordion>
											</div>
										</div>
									</div>

									{/*  */}

								</div>
								<Divider />
								<ul className='confirmCheckList'>
									<li>
										<MatCheckBox name='c_confirm1' view={this.view} label='プラン変更お申込み後のキャンセルや、お申込み内容の変更はできません。' />
									</li>
									<li>
										<MatCheckBox name='c_confirm2' view={this.view} label='プラン変更の適用は翌月1日です。' />

									</li>
									<li>
										<MatCheckBox name='c_confirm3' view={this.view} label='上記申込内容・約款をご確認・同意のうえ、プランを申し込む' />

									</li>
								</ul>
								{/* <div className='confirmCheckList'>
									<div>
										<MatCheckBox name='c_confirm1' view={this.view} label='プラン変更お申込み後のキャンセルや、お申込み内容の変更はできません。' />
									</div>
									<div>
										<MatCheckBox name='c_confirm2' view={this.view} label='プラン変更の適用は翌月1日です。' />
									</div>
									<div>
										<MatCheckBox name='c_confirm3' view={this.view} label='上記申込内容・約款をご確認・同意のうえ、プランを申し込む' />
									</div>
								</div> */}
							</div>
						</div>

						<Divider />

						<div className='pageFooter'>
							<div>
								<MatButton name='next' view={this.view} className='nextButton' >
									次へ
									<NavigateNextIcon />
								</MatButton>

							</div>
							<div>
								<MatButton name='back' view={this.view} className='backButton' >
									<NavigateBeforeIcon />
									戻る
								</MatButton>

							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
	// --------------------------------------------------------------------------

	// リスト描画  ---------------------------------------------------------------
	/**
	 * リスト名=ImportDocList
	 */
	public onImportDocListRender(rowInfo: WprRowInfo): any {
		const data: MGeneral = rowInfo.rowData as MGeneral;
		const label: string = data.name1;
		return (
			<div>
				<WprAnchor name='importantDocLink' view={this.view} row={rowInfo} className='importantDocLink'>
					{label}
				</WprAnchor>
			</div>
		);
	}
	// --------------------------------------------------------------------------
}
