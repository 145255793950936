import { WprBaseViewCore } from '../../wpr-framework/view/WprBaseViewCore';
import { BaseService } from './base/BaseService';
import { GetNetInfoRequest } from './models/dto/mypagerenewal/GetNetInfoRequest';
import { GetNetInfoResponse } from './models/dto/mypagerenewal/GetNetInfoResponse';

/**
 * ItscomNetサービス
 */
export class ItscomNetService extends BaseService {
	// サービス メソッド  --------------------------------------------------------
	/**
	 * 一覧取得
	 * @param viewCore ビュー情報
	 * @param request リクエスト情報
	 * @param resMethod 結果処理メソッド
	 */
	public getOwnerList(viewCore: WprBaseViewCore, request: GetNetInfoRequest, resMethod: (result: GetNetInfoResponse) => void): void {
		return this.doPost(viewCore, '/itscomNet/getOwnerList', request, resMethod);
	}
	// --------------------------------------------------------------------------
}
