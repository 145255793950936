import { BaseService } from './base/BaseService';
import { WprBaseViewCore } from '../../wpr-framework/view/WprBaseViewCore';
import { GetMypWorkScheduleListRequest } from './models/dto/mypagerenewal/GetMypWorkScheduleListRequest';
import { GetMypWorkScheduleListResponse } from './models/dto/mypagerenewal/GetMypWorkScheduleListResponse';
import { GetVacantMypWorkScheduleRequest } from './models/dto/mypagerenewal/GetVacantMypWorkScheduleRequest';
import { GetVacantMypWorkScheduleResponse } from './models/dto/mypagerenewal/GetVacantMypWorkScheduleResponse';
import { SetMypWorkScheduleRequest } from './models/dto/mypagerenewal/SetMypWorkScheduleRequest';
import { SetMypWorkScheduleResponse } from './models/dto/mypagerenewal/SetMypWorkScheduleResponse';
import { CancelMypWorkScheduleRequest } from './models/dto/mypagerenewal/CancelMypWorkScheduleRequest';
import { CancelMypWorkScheduleResponse } from './models/dto/mypagerenewal/CancelMypWorkScheduleResponse';

/**
 * 工事日サービス
 */
export class ConstructionService extends BaseService {
	// サービス メソッド  --------------------------------------------------------
	/**
	 * 工事予定一覧取得
	 * @param viewCore ビュー情報
	 * @param request リクエスト情報
	 * @param resMethod 結果処理メソッド
	 */
	public getWorkScheduleList(viewCore: WprBaseViewCore, request: GetMypWorkScheduleListRequest, resMethod: (result: GetMypWorkScheduleListResponse) => void): void {
		return this.doPost(viewCore, '/construction/getWorkScheduleList', request, resMethod);
	}

	/**
	 * 工事枠候補一覧取得
	 * @param viewCore ビュー情報
	 * @param request リクエスト情報
	 * @param resMethod 結果処理メソッド
	 */
	public getVacantMypWorkSchedule(viewCore: WprBaseViewCore, request: GetVacantMypWorkScheduleRequest, resMethod: (result: GetVacantMypWorkScheduleResponse) => void): void {
		return this.doPost(viewCore, '/construction/getVacantMypWorkSchedule', request, resMethod);
	}

	/**
	 * 工事予定の設定
	 * @param viewCore ビュー情報
	 * @param request リクエスト情報
	 * @param resMethod 結果処理メソッド
	 */
	public setMypWorkSchedule(viewCore: WprBaseViewCore, request: SetMypWorkScheduleRequest, resMethod: (result: SetMypWorkScheduleResponse) => void): void {
		return this.doPost(viewCore, '/construction/setMypWorkSchedule', request, resMethod);
	}

	/**
	 * 工事予定のキャンセル
	 * @param viewCore ビュー情報
	 * @param request リクエスト情報
	 * @param resMethod 結果処理メソッド
	 */
	public cancelMypWorkSchedule(viewCore: WprBaseViewCore, request: CancelMypWorkScheduleRequest, resMethod: (result: CancelMypWorkScheduleResponse) => void): void {
		return this.doPost(viewCore, '/construction/cancelMypWorkSchedule', request, resMethod);
	}
	// --------------------------------------------------------------------------
}
