import { MvnoEntryInfo } from '../../apimodels/MvnoEntryInfo';

/**
 * 料金シミュレーション取得リクエスト
 */
export class GetTotalFeeRequest {
	// public 変数	-----------------------------------------------------------
	public entryInfo: MvnoEntryInfo;	// 申込情報
	public isIncludeContract: boolean;	// 現在の契約を料金シミュレーション計算に含むかどうか
	public includeContract: boolean;	// 
	// ------------------------------------------------------------------------
}
