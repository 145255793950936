import { WprBaseViewMultiModeInfo } from '../../../../../wpr-framework/mode/WprBaseViewMultiMode';
import { WprMultiMode } from '../../../../../wpr-framework/mode/WprMultiMode';
import { AddPhoneSelectView } from '../AddPhoneSelectView';
import { WprDI_Control } from '../../../../../wpr-framework/view/di/WprDI_Control';
import { WprControlInfo } from '../../../../../wpr-framework/view/control/WprControlInfo';

/**
 * フリー入力モード
 */
export class OtherLocationMode extends WprBaseViewMultiModeInfo<AddPhoneSelectView> {
	// DI情報  ------------------------------------------------------------------
	@WprDI_Control
	private m_location: WprControlInfo	= null;	// 場所
	@WprDI_Control
	private m_lineType: WprControlInfo	= null;	// 回線業者
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	public constructor() {
		super('OtherLocationMode');
	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * モードリスト取得（この画面モードで使用する全てのモードを定義する）
	 * @param list モードリスト
	 */
	public useModeList(list: string[]): void {
		list.push('フリー入力表示');
		list.push('フリー入力非表示');
		list.push('回線会社フリー入力表示');
		list.push('回線会社フリー入力非表示');
	}

	/**
	 * フィールド定義を登録処理
	 */
	public onRegisterControl(): void {
		this.addControl('comment', { display: [ { mode: 'フリー入力表示', value: true }, { mode: 'フリー入力非表示', value: false } ] });
		this.addControl('otherLineComment', { display: [ { mode: '回線会社フリー入力表示', value: true }, { mode: '回線会社フリー入力非表示', value: false } ] });
	}

	/**
	 * 画面の状態をチェックしてモードを変更する
	 * @param curMode 現在のモード
	 * @param newMode 新しいモード
	 */
	public onCheckMultiMode(curMode: WprMultiMode, newMode: WprMultiMode): void {
		// 希望場所「その他」
		if (this.m_location.value === 'その他')
			newMode.addMode('フリー入力表示');
		else
			newMode.addMode('フリー入力非表示');
		// 回線会社「その他」
		if (this.m_lineType.value === '7')
			newMode.addMode('回線会社フリー入力表示');
		else
			newMode.addMode('回線会社フリー入力非表示');
	}
	// --------------------------------------------------------------------------
}
