import { WprValueMap } from '../../../../wpr-framework/model/WprValueMap';
import { WprValueMapSetter } from '../../../../wpr-framework/model/WprValueMapSetter';

/**
 * 工事時間帯
 */
export class ConstrTimeValueMap {
	// static メソッド  --------------------------------------------------------
	public static addValueMap(vmSetter: WprValueMapSetter): void {
		let vm = new WprValueMap();
		vm.addValue('01', '09:00～11:00');
		vm.addValue('02', '11:00～14:00');
		vm.addValue('03', '13:00～17:00');
		vmSetter.addValueMap('工事時間帯', vm);
	}
	// --------------------------------------------------------------------------
}
