import { Card, Divider, TableCell, Typography } from '@material-ui/core';
import { Grid } from '@mui/material';
import React, { ReactNode } from 'react';
import styles from './MvnoComplete.scss';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { MatButton } from '../../../../wpr-framework/component/controls/material/MatButton';
import { WprBaseViewComponent } from '../../../../wpr-framework/view/component/WprBaseViewComponent';
import { MvnoPlanSendData } from '../../../controls/checkMarkRadio/CheckMarkRadioContent';
import { MvnoCompleteView } from './MvnoCompleteView';
import { MatOneRowTable } from '../../../../wpr-framework/component/controls/material/MatOneRowTable';
import { WprContainer } from '../../../../wpr-framework/component/controls/WprContainer';
import { MatTypography } from '../../../../wpr-framework/component/controls/material/MatTypography';
import { WprErrorContainer } from '../../../../wpr-framework/component/controls/WprErrorContainer';
import { WprAnchor } from '../../../../wpr-framework/component/controls/WprAnchor';
import { MvnoEntryTotalFeeView } from '../entry/mvno_entry_total_fee/MvnoEntryTotalFeeView';
import { MvnoEntryDetailParts } from '../entry/parts/mvno_entry_detail_parts/MvnoEntryDetailParts';
import { ProgressBar } from '../../common/progress_bar/ProgressBar';
import questionnaireIcon from '/shared/image/common/button_questionnaire_on.png';

/**
 * MVNO_完了用コンポーネント
 */
export class MvnoComplete extends WprBaseViewComponent {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: any) { super(new MvnoCompleteView(), props); }
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** MVNO_完了ビュー情報 */
	public get viewInfo(): MvnoCompleteView { return this.view as MvnoCompleteView; }
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * スタイルオブジェクト取得
	*/
	public getStylesObject(): any { return styles; }

	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		return (
			<>
				<div>
					<div className='pageCard'>
						<WprContainer mode={'MVNO.' + MvnoCompleteView.MVNO_ENTRY} view={this.view}>
							<div className="pageHeader stepFlow">
								<ProgressBar name='progressBar' view={this.view} />
							</div>
						</WprContainer>
						<WprContainer mode={'MVNO.' + MvnoCompleteView.MVNO_CAPACITY_CHANGE} view={this.view}>
							<div className="pageHeader stepFlow">
								<ProgressBar name='progressBar' view={this.view} />
							</div>
						</WprContainer>

						<WprContainer mode={'MVNO.' + MvnoCompleteView.MVNO_MNP} view={this.view}>
							<div className="pageHeader title">
								MNP開通問い合わせ
							</div>
							<Divider />
						</WprContainer>



						<div className='pageContent'>
							<div className='planContent'>
								<div className='complete'>
									<Typography className='completeMessage'>お申込みありがとうございます！</Typography>

									<div>
										<div className='customerName'>
											<MatTypography name='customerName' view={this.view} component='span' className='customerName' />さん
										</div>
									</div>
								</div>

								{/* MVNO */}
								<WprContainer mode={'MVNO.' + MvnoCompleteView.MVNO_ENTRY} view={this.view}>
									<div className='subText' style={{ textAlign: 'center', margin: '2rem' }}>
										イッツコムモバイルをお申込みいただき、誠にありがとうございます。<br />
										以下の内容にて、お申込みを受け付けました。<br />
										端末やSIMの到着まで、しばらくお待ちください。
									</div>
									{/* 申込内容 */}
									<div className=''>
										{/* <div className='entryDetailTitle'>
											<span>お申込み内容</span>
										</div>
										<Divider /> */}
										<div>
											<MvnoEntryDetailParts name='entryDetailParts' view={this.view} />
										</div>
									</div>

									{/* this.setScopeMode('MVNO', '・・・')でセットした値 */}

									<div style={{ margin: '1rem', textAlign: 'center' }}>
										<MatButton name='next' view={this.view} className='nextButton' >
											もう一台申し込む
											<NavigateNextIcon />
										</MatButton>
										<div className='comment' style={{ marginTop: '1rem' }}>
											<span>※既契約含め、イッツコム　スマホ（音声SIM）およびイッツコム　データSIM（データ専用SIM）は5回線まで申し込むことが出来ます。</span>
											<br /><span>それを超えてお申し込みが確認された場合には、お申込みキャンセルのご連絡を差し上げることがございますのでご了承ください。</span>

											<br /><span>※お申し込み内容によってお手続きが前後する場合がございます。</span>
										</div>
									</div>
								</WprContainer>

								<WprContainer mode={'MVNO.' + MvnoCompleteView.MVNO_CAPACITY_CHANGE} view={this.view}>
									<div style={{ textAlign: 'center', margin: '2rem' }}>
										プラン変更お申込み後のキャンセルや、お申込み内容の変更はできません。
										<br />
										あらかじめご了承ください。
									</div>
								</WprContainer>

								{/* MNP */}
								<WprContainer mode={'MVNO.' + MvnoCompleteView.MVNO_MNP} view={this.view}>
									<div style={{ textAlign: 'center', margin: '2rem' }}>

										<WprContainer mode='営業時間.営業時間外当日' view={this.view}>
											<div>
												開通手続きについて受付いたしました。当日午前中までの開通予定となります。
											</div>
										</WprContainer>
										<WprContainer mode='営業時間.営業時間内' view={this.view}>
											<div>
												開通手続きについて受付いたしました。2時間程度での開通予定となります。
											</div>
										</WprContainer>
										<WprContainer mode='営業時間.営業時間外前日' view={this.view}>
											<div>
												開通手続きについて受付いたしました。翌日午前中までの開通予定となります。
											</div>
										</WprContainer>
										{/* <div>1日たっても開通が確認できない場合にはフリーダイヤル（0120-109199）までお問い合わせください。</div> */}
									</div>

								</WprContainer>

								{/* データ容量追加 */}
								<WprContainer mode={'MVNO.' + MvnoCompleteView.MVNO_ADD_COUPON} view={this.view}>
									<div style={{ textAlign: 'center', margin: '2rem' }}>
										データ容量追加申込みについて受付いたしました。
									</div>
								</WprContainer>

							</div>
							<Divider />
						</div>
						<div className='pageFooter confirmFooter'>
							<div>
								<WprAnchor name='toQuestionnaire' view={this.view}  >
									<img src={questionnaireIcon} style={{maxWidth: '100%'}} />
								</WprAnchor>
							</div>
							<div>
								<MatButton name='back' view={this.view} className='nextButton nextButtonSub backContInfoList' >
									<NavigateBeforeIcon />
									<WprContainer mode={'戻る.容量追加'} view={this.view}>
										データ容量確認・追加申込へ
									</WprContainer>
									<WprContainer mode={'戻る.契約一覧'} view={this.view}>
										契約内容の確認・変更メニューへ
									</WprContainer>
								</MatButton>
							</div>
						</div>

					</div>
				</div>
			</>
		);
	}
	// --------------------------------------------------------------------------
}
