/**
 * リフレクションユーティリティ
 */
export class WprReflectUtil {
	/**
	 * プロパティ取得
	 * @param obj 対象オブジェクト
	 * @param prop プロパティ名
	 * @returns プロパティ値
	 */
	public static getProperty(obj: object, prop: string): any {
		return Reflect.get(obj, prop, obj);
	}

	/**
	 * プロパティ設定
	 * @param obj 対象オブジェクト
	 * @param prop プロパティ名
	 * @param value プロパティ値
	 */
	public static setProperty(obj: object, prop: string, value: any): void {
		Reflect.set(obj, prop, value);
	}

	/**
	 * プロパティ存在チェック
	 * @param obj 対象オブジェクト
	 * @param prop プロパティ名
	 * @returns trueの場合、存歳している
	 */
	public static checkProperty(obj: object, prop: string): any {
		if (obj)
			return (prop in obj);
		return false;
	}
}
