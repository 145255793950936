import { TableRow } from '@material-ui/core';
import React, { ReactNode } from 'react';
import { IWprTableRow } from '../../../mode/table/IWprTableRow';
import { WprControlVisibility } from '../../props/IWprControlState';
import { IWprNoNameLayoutProps } from '../../props/IWprLayoutProps';
import { WprRowInfo } from '../../props/WprRowInfo';
import { WprBaseNoNameLayoutComponent } from '../WprBaseNoNameLayoutComponent';

/**
 * テーブル行コントロールプロパティ情報
 */
interface IMatTableRowProps extends IWprNoNameLayoutProps {
	hover?: boolean;		// ホバーフラグ
	selectable?: boolean;	// 選択可能フラグ
	row: WprRowInfo;		// 繰返し行データ
	oddClass?: string;		// 奇数クラス
	evenClass?: string;		// 偶数クラス
}

/**
 * テーブル行コントロール
 */
export class MatTableRow extends WprBaseNoNameLayoutComponent<IMatTableRowProps, JSX.Element> implements IWprTableRow {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: IMatTableRowProps) {
		super(props);
		if (props.selectable)
			this.setClickEvent(this.onClick.bind(this));
	}
	// --------------------------------------------------------------------------

	// イベント  ----------------------------------------------------------------
	/**
	 * ボタンクリック処理
	 * @param event 変更イベント 
	 */
	public onClick(event: React.MouseEvent<JSX.Element>): void {
		this.addClickEventLog(event.target as HTMLElement);
		this.props.view.selectTableRow(this, this.props.row);
	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * マウント通知
	 */
	public onDidMount() {
		this.props.view.setTableRowComponent(this, this.props.row, this.props.selectable);
	}

	/**
	 * アンマウント通知
	 */
	public onWillUnmount() {
		this.props.view.deleteTableRowComponent(this, this.props.row);
	}

	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		// const props = this.getProps();
		const {view, children, selectable, row, oddClass, evenClass, ...other} = this.props;
		const events = this.getEvents();
		const options = this.getOptions();
		let tableRow = null;
		if (this.state.visibility === WprControlVisibility.VISIBLE) {
			tableRow = <TableRow {...other} {...options} {...events} ref={this.ref} >{this.props.children}</TableRow>;
		}
		else {
			tableRow = <TableRow hidden {...other} {...options} ref={this.ref}>{this.props.children}</TableRow>;
		}
		return (
			<>
				{tableRow}
			</>
		);
	}

	/**
	 * 要素固有XPath情報取得
	 * @param element 要素
	 * @returns 要素固有XPath情報
	 */
	protected getElementXPathInfo(element: HTMLElement): string {
		const trDiv = this.getParentElement(element, 'TR', 'name', this.props.row.listName);
		const idx = this.getChildIndex(trDiv);
		return `[${idx}]`;
	}
	// --------------------------------------------------------------------------

	// private メソッド  ---------------------------------------------------------
	/**
	 * オプション情報取得
	 * @returns オプション情報
	 */
	private getOptions(): any {
		const rtn: any = {};
		let className = null;
		if (this.props.row.index % 2 === 0 && this.props.oddClass)
			className = this.className(this.props.oddClass);
		else if (this.props.row.index % 2 === 1 && this.props.evenClass)
			className = this.className(this.props.evenClass);
		else
			className = this.className();
		const local = this.props.view.getLocalClassName(className);
		if (local != null)
			className = local;
		rtn['className'] = className;
		if (this.state.value) {
			if (this.props.row.rowData === this.state.value)
				rtn['selected'] = true;
		}
		if (this.props.row)
			rtn['name'] = this.props.row.listName;
		return rtn;
	}
	// --------------------------------------------------------------------------

	// IWprTableRowの実装  ------------------------------------------------------
	/**
	 * 選択状態設定処理
	 * @param rowData 選択行データ
	 */
	public setSelect(rowData: any): void {
		if (rowData != this.state.value) {
			this.setState({
				value: rowData
			});
		}
	}

	/**
	 * 行情報取得
	 * @returns 行情報取
	 */
	public getRowInfo(): WprRowInfo {
		return this.props.row;
	}
	// --------------------------------------------------------------------------
}