import React, { ReactNode } from 'react';
import { WprBaseViewComponent } from '../../../../wpr-framework/view/component/WprBaseViewComponent';
import { RelocateSelect2View } from './RelocateSelect2View';
import { Grid, Typography, Divider, TableCell, TableRow } from '@material-ui/core';
import { WprContainer } from '../../../../wpr-framework/component/controls/WprContainer';
import { MatButton } from '../../../../wpr-framework/component/controls/material/MatButton';
import { MatCheckBox } from '../../../../wpr-framework/component/controls/material/MatCheckBox';
import { MatDatePicker } from '../../../../wpr-framework/component/controls/material/MatDatePicker';
import { MatSelect } from '../../../../wpr-framework/component/controls/material/MatSelect';
import { MatTypography } from '../../../../wpr-framework/component/controls/material/MatTypography';
import { ProgressBar } from '../../common/progress_bar/ProgressBar';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { WprRowInfo } from '../../../../wpr-framework/component/props/WprRowInfo';
import { MatTextField } from '../../../../wpr-framework/component/controls/material/MatTextField';
import styles from './RelocateSelect2.scss';
import { WprViewError } from '../../../../wpr-framework/component/controls/WprViewError';
import { WprErrorContainer } from '../../../../wpr-framework/component/controls/WprErrorContainer';
import errorIcon from '../../../../../shared/image/common/icon_error.png'

/**
 * 移設申込選択2画面用コンポーネント
 */
export class RelocateSelect2 extends WprBaseViewComponent {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: any) { super(new RelocateSelect2View(), props); }
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** 移設申込選択2画面ビュー情報 */
	public get viewInfo(): RelocateSelect2View	{ return this.view as RelocateSelect2View;	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * スタイルオブジェクト取得
	*/
	public getStylesObject(): any { return styles; }

	/**
	 * リストコントロール定義登録
	*/
	protected onRegisterListRender(): void {
		this.addListRender('TvListModel', null, this.onTvListModelRender);
		this.addListRender('NetListModel', null, this.onNetListModelRender);
		this.addListRender('PhoneListModel', null, this.onPhoneListModelRender);
		this.addListRender('ErrorModel', null, this.onErrorModelRender);
	}
	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		return (
			<>
			<div className='pageCard'>
				<div className='pageHeader stepFlow'>
					<ProgressBar name='progressBar' view={this.view} />
				</div>
				<div className='main card'>
					<div className='title_left'>
						<MatTypography name='title' className='title_text' view={this.view} />
					</div>
					<div>
						<WprContainer mode='エラー.表示' view={this.view}>
							<div className='errorArea'>
								<span className='parallel'>
									<img src={errorIcon} alt='' className='x_img'/>
									<div className='errorList'>
										{this.renderList('ErrorModel')}
									</div>
								</span>
							</div>
						</WprContainer>
					</div>
					{/* テレビ */}
					<WprContainer mode={'テレビ.表示'} view={this.view}>
						<div className='serviceGroup'>
							<Typography className='serviceTitle'>テレビ</Typography>
							{this.renderList('TvListModel')}
						</div>
					</WprContainer>
					{/* ネット */}
					<WprContainer mode={'ネット.表示'} view={this.view}>
						<div className='serviceGroup'>
							<Typography className='serviceTitle'>インターネット</Typography>
							{this.renderList('NetListModel')}
						</div>
					</WprContainer>
					{/* 電話 */}
					<WprContainer mode={'電話.表示'} view={this.view}>
						<div className='serviceGroup'>
							<Typography className='serviceTitle'>ケーブルプラス電話</Typography>
							{this.renderList('PhoneListModel')}
						</div>	
					</WprContainer>
					<div className='title_space'>
						<div className='itemTitle title_maru'>TV端子の確認<span className='txtRequired'>*</span></div>
						<Typography className='title_comment' style={{marginLeft: '15px'}}>機器の移動の際に移動先の場所にTV端子があることが必要になります。</Typography>
					</div>
					<div className='title_center'>
						<div className='title_center labelSize'>
							<MatCheckBox name='tvPortFlg' label='移動先の場所にTV端子があることを確認しました' view={this.view}/>
						</div>
					</div>
					<div style={{marginBottom: '50px'}}>
						<div className='title_space'>
							<div className='itemTitle title_maru'>作業の希望日時</div>
						</div>
						<div style={{margin: '10px 0 25px 15px'}}>
							<Grid container spacing={1}>
								<Grid item xs={12} md={6} className='itemTitle2'>
									第一希望
									<span className='txtRequired'>*</span>
									<div className='customerInput'>
										<MatDatePicker name='desiredDate1' view={this.view} placeholder='年/月/日' use='mobile' />
									</div>
								</Grid>
								<Grid item xs={12} md={6} className='itemTitle2'>
									時間帯
									<span className='txtRequired'>*</span>
									<div className='customerInput'>
										<MatSelect name='selectTime1' vm='時間帯' view={this.view} />
									</div>
								</Grid>
								<Grid item xs={12} md={6} className='itemTitle2'>
									第二希望
									<span className='txtRequired'>*</span>
									<div className='customerInput'>
										<MatDatePicker name='desiredDate2' view={this.view} placeholder='年/月/日' use='mobile' />
									</div>
								</Grid>
								<Grid item xs={12} md={6} className='itemTitle2'>
									時間帯
									<span className='txtRequired'>*</span>
									<div className='customerInput'>
										<MatSelect name='selectTime2' vm='時間帯' view={this.view} />
									</div>
								</Grid>
								<Grid item xs={12} md={6} className='itemTitle2'>
									第三希望
									<span className='txtRequired'>*</span>
									<div className='customerInput'>
										<MatDatePicker name='desiredDate3' view={this.view} placeholder='年/月/日' use='mobile' />
									</div>
								</Grid>
								<Grid item xs={12} md={6} className='itemTitle2'>
									時間帯
									<span className='txtRequired'>*</span>
									<div className='customerInput'>
										<MatSelect name='selectTime3' vm='時間帯' view={this.view} />
									</div>
								</Grid>
							</Grid>
						</div>
						<div>
							※訪問担当が到着するおおよその時間帯目安となります。
							<br />※ご訪問日時については近日中にお電話にてご連絡いたします。
							<br />訪問担当の空き状況によってご希望に沿えない場合もございますことご了承ください。
							<br />お建物の状況等によっては工事前に別途調査が必要となる場合がございます。
						</div>
						<div className='feeTable textInputArea' style={{marginTop: '30px'}}>	
							<Grid container className='feeRow'>
								<Grid item xs={12} md={4} className='feeTitle'>
									<div>前日連絡先<span className='txtRequired'>*</span></div>
								</Grid>
								<Grid item xs={12} md={8} className='feeVal courseValue' style={{padding: '15px'}}>
									<MatTextField name='beforeContact' className='itemUnit' placeholder='電話番号ハイフンなし' view={this.view} />
								</Grid>
							</Grid>	
							<Grid container className='feeRow'>
								<Grid item xs={12} md={4} className='feeTitle'>
									<div>当日連絡先<span className='txtRequired'>*</span></div>
								</Grid>
								<Grid item xs={12} md={8} className='feeVal courseValue' style={{padding: '15px'}}>
									<MatTextField name='sameDayContact' className='itemUnit' placeholder='電話番号ハイフンなし' view={this.view} />
								</Grid>
							</Grid>
							<Grid container className='feeRow'>
								<Grid item xs={12} md={4} className='feeTitle'>
									<div>当日立ち合い者<span className='txtRequired'>*</span></div>
								</Grid>
								<Grid item xs={12} md={8} className='feeVal courseValue' style={{padding: '15px'}}>
									<MatTextField name='name' className='itemUnit' placeholder='当日立ち合い者' view={this.view} />
								</Grid>
							</Grid>
						</div>
					</div>
					<Divider />
					<div className='pageFooter footer-space'>
						<div>
							<MatButton name='next' view={this.view} className='orangeButton orangeButton_parts'>
								確認
								<NavigateNextIcon />
							</MatButton>
						</div>
						<div>
							<MatButton name='back' view={this.view} className='backButton'>
								<NavigateBeforeIcon />
								戻る
							</MatButton>
						</div>
					</div>
				</div>
			</div>
			</>
		);
	}
	// --------------------------------------------------------------------------

	// リスト描画  ---------------------------------------------------------------
	/**
	 * リスト名=TvListModel
	 */
	public onTvListModelRender(rowInfo: WprRowInfo): any {
		return (
			<>
				<div className='service'>
					<MatTypography name='no' row={rowInfo} view={this.view} className='noStyle'/>
					<MatTypography name='serviceInfo' row={rowInfo} view={this.view} style={{ fontSize: '15px'}}/>
				</div>
				<div className='feeTable inputArea'>
					<Grid container spacing={1} className='feeRow'>
						<Grid item xs={12} md={4} className='feeTitle'>
							<div style={{color: '#484848'}}>
								希望場所
								<span className='txtRequired'>*</span>
							</div>
						</Grid>
						<Grid item xs={12} md={8} className='feeVal courseValue value' style={{padding: '0.6rem'}}>
							<WprContainer mode={'物件.戸建'} view={this.view}>
								<div>
									<MatSelect placeholder='(階数)' name='floor' row={rowInfo} vm='floor' view={this.view} style={{width: '125px', marginRight: '5px'}}/>
									<MatSelect placeholder='(場所)' name='location' row={rowInfo} vm='location' view={this.view} style={{width: '175px'}}/>
								</div>
							</WprContainer>
							<WprContainer mode={'物件.集合'} view={this.view}>
								<div>
									<MatSelect placeholder='(場所)' name='location' row={rowInfo} vm='location' view={this.view} style={{width: '175px', marginLeft: '5px'}}/>
								</div>
							</WprContainer>
							<div><MatTextField placeholder='(場所)' name='comment' row={rowInfo} view={this.view} style={{width: '95%'}}></MatTextField></div>
						</Grid>
					</Grid>
				</div>
			</>
		);
	}
	/**
	 * リスト名=NetListModel
	 */
	public onNetListModelRender(rowInfo: WprRowInfo): any {
		return (
			<>
				<div className='service'>
					<MatTypography name='no' row={rowInfo} view={this.view} className='noStyle'/>
					<MatTypography name='serviceInfo' row={rowInfo} view={this.view} style={{ fontSize: '15px'}}/>
				</div>
				<div className='feeTable inputArea'>
					<Grid container spacing={1} className='feeRow'>
						<Grid item xs={12} md={4} className='feeTitle'>
							<div style={{color: '#484848'}}>
								希望場所
								<span className='txtRequired'>*</span>
							</div>
						</Grid>
						<Grid item xs={12} md={8} className='feeVal courseValue value' style={{padding: '0.6rem'}}>
							<WprContainer mode={'物件.戸建'} view={this.view}>
								<div>
									<MatSelect placeholder='(階数)' name='floor' row={rowInfo} vm='floor' view={this.view} style={{width: '125px', marginRight: '5px'}}/>
									<MatSelect placeholder='(場所)' name='location' row={rowInfo} vm='location' view={this.view} style={{width: '175px'}}/>
								</div>
							</WprContainer>
							<WprContainer mode={'物件.集合'} view={this.view}>
								<div>
									<MatSelect placeholder='(場所)' name='location' row={rowInfo} vm='location' view={this.view} style={{width: '175px'}}/>
								</div>
							</WprContainer>
							<div><MatTextField placeholder='(場所)' name='comment' row={rowInfo} view={this.view} style={{width: '95%'}}></MatTextField></div>
						</Grid>
					</Grid>
				</div>
			</>
		);
	}
	/**
	 * リスト名=PhoneListModel
	 */
	public onPhoneListModelRender(rowInfo: WprRowInfo): any {
		return (
			<>
				<div className='service'>
					<MatTypography name='no' row={rowInfo} view={this.view} className='noStyle'/>
					<MatTypography name='serviceInfo' row={rowInfo} view={this.view} style={{ fontSize: '15px'}}/>
				</div>
				<div className='feeTable inputArea'>
					<Grid container spacing={1} className='feeRow'>
						<Grid item xs={12} md={4} className='feeTitle'>
							<div style={{color: '#484848'}}>
								希望場所
								<span className='txtRequired'>*</span>
							</div>
						</Grid>
						<Grid item xs={12} md={8} className='feeVal courseValue value' style={{padding: '0.6rem'}}>
							<WprContainer mode={'物件.戸建'} view={this.view}>
								<div>
									<MatSelect placeholder='(階数)' name='floor' row={rowInfo} vm='floor' view={this.view} style={{width: '125px', marginRight: '5px'}}/>
									<MatSelect placeholder='(場所)' name='location' row={rowInfo} vm='location' view={this.view} style={{width: '175px'}}/>
								</div>
							</WprContainer>
							<WprContainer mode={'物件.集合'} view={this.view}>
								<div>
									<MatSelect placeholder='(場所)' name='location' row={rowInfo} vm='location' view={this.view} style={{width: '175px', marginLeft: '5px'}}/>
								</div>
							</WprContainer>
							<div><MatTextField placeholder='(場所)' name='comment' row={rowInfo} view={this.view} style={{width: '95%'}}></MatTextField></div>
						</Grid>
					</Grid>
				</div>
			</>
		);
	}
	/**
	 * リスト名=ErrorModel
	 */
	public onErrorModelRender(rowInfo: WprRowInfo): any {
		return (
			<div>
				<MatTypography name='errorMessage' view={this.view} row={rowInfo}/>
			</div>
		);
	}
	// --------------------------------------------------------------------------
}
