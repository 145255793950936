import { AppBar, Card, Grid, List, ListItemIcon, ListItemText } from '@material-ui/core';
import { Divider, ListItem, ListItemButton } from '@mui/material';
import React, { ReactNode } from 'react';
import { MatListItemButton } from '../../../../wpr-framework/component/controls/material/MatListItemButton';
import { WprBaseViewComponent } from '../../../../wpr-framework/view/component/WprBaseViewComponent';
import { MenuBarView } from './MenuBarView';

import HomeIcon from '@mui/icons-material/Home';
import TimelineIcon from '@mui/icons-material/Timeline';
import CurrencyYenIcon from '@mui/icons-material/CurrencyYen';
import EditIcon from '@mui/icons-material/Edit';
import SettingsIcon from '@mui/icons-material/Settings';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import styles from './MenuBar.scss';

import itscomIcon from '/shared/image/menu/menu_itscom.png';
import mypageIcon from '/shared/image/menu/menu_mypage.png';

import topIcon from '/shared/image/menu/menu_top.png';
import contractIcon from '/shared/image/menu/menu_contract.png';
import feeIcon from '/shared/image/menu/menu_fee.png';
import helpIcon from '/shared/image/menu/menu_help.png';
import optionIcon from '/shared/image/menu/menu_option.png';
import plusIcon from '/shared/image/menu/menu_plus.png';
import menuSelected from '/shared/image/menu/menu_selected.png';

import arrowRightIcon from '/shared/image/menu/menu_arrow_right.png';

import arrowCircleIcon from '/shared/image/menu/menu_arrow_circle.png';
import arrowCircleIconOrange from '/shared/image/menu/menu_arrow_circle_orange.png';

import selectedRight from '/shared/image/menu/selected_right.png';
import selectedLeft from '/shared/image/menu/selected_left.png';

import { MatButton } from '../../../../wpr-framework/component/controls/material/MatButton';
import { WprContainer } from '../../../../wpr-framework/component/controls/WprContainer';
import { WprDiv } from '../../../../wpr-framework/component/controls/WprDiv';
import { WprInput } from '../../../../wpr-framework/component/controls/WprInput';
import { WprAnchor } from '../../../../wpr-framework/component/controls/WprAnchor';


/**
 * メニューバー用コンポーネント
 */
export class MenuBar extends WprBaseViewComponent {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: any) { super(new MenuBarView(), props); }
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** メニューバービュー情報 */
	public get viewInfo(): MenuBarView { return this.view as MenuBarView; }
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * スタイルオブジェクト取得
	*/
	public getStylesObject(): any { return styles; }

	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		let netSpeedCheckURL: string = this.state.data.netSpeedCheckURL;
		return (
			<>
				<WprContainer mode='メニュー.開' view={this.view}>
					<WprDiv name='menuModal' view={this.view} className='backModal'>
					</WprDiv>
				</WprContainer>
				<WprContainer mode='メニューバー.左' view={this.view}>
					<div className='menuBarLeftPseudo'></div>
				</WprContainer>

				<div id='menuBarParent' className='menuBarParent'>
					<WprContainer mode='メニューバー.左' view={this.view}>
						<div className='menubar_left' id='menubar'>
							<div className='menuBarMain'>
								<WprAnchor name='toTop' view={this.view} className='itscomIcon'>
									<div>
										<img src={itscomIcon} style={{ maxWidth: '57%' }} />
									</div>
									<div>
										<img src={mypageIcon} style={{ maxWidth: '29%' }} />
									</div>
								</WprAnchor>

								<div className='menuMain'>
									<div className='menuCol'>
										<div>
											<MatButton name='toTop' variant='text' view={this.view} className='menuButton'>
												<span className='buttonIcon'>
													<img src={topIcon} style={{ width: '16.45px', height: '13.87px' }} />
												</span>
												<span className='buttonText'>トップ</span>
											</MatButton>
										</div>
									</div>

									<div className='menuCol'>
										<div>
											<MatButton name='openFee' variant='text' view={this.view} className='menuButton'>
												<span className='buttonIcon'>
													<img src={feeIcon} style={{ width: '9.18px', height: '15.29px' }} />
												</span>
												<span className='buttonText'>料金</span>
												<img src={plusIcon} className='buttonPlusIcon' />
											</MatButton>
											<img id='payamentMenu' src={menuSelected} className='menuSelected' />
										</div>
									</div>

									<div className='menuCol'>
										<div>
											<MatButton name='openContract' variant='text' view={this.view} className='menuButton'>
												<span className='buttonIcon'>
													<img src={contractIcon} style={{ width: '13.95px', height: '13.9px' }} />
												</span>
												<span className='buttonText'>ご契約・手続き</span>
												<img src={plusIcon} className='buttonPlusIcon' />
											</MatButton>
											<img id='contractMenu' src={menuSelected} className='menuSelected' />
										</div>
									</div>

									<div className='menuCol'>
										<div>
											<MatButton name='openOption' variant='text' view={this.view} className='menuButton'>
												<span className='buttonIcon'>
													<img src={optionIcon} style={{ width: '16.71px', height: '16.43px' }} />
												</span>
												<span className='buttonText'>各種設定</span>
												<img src={plusIcon} className='buttonPlusIcon' />
											</MatButton>
											<img id='settingMenu' src={menuSelected} className='menuSelected' />
										</div>
									</div>

									<div className='menuCol'>
										<div>
											<MatButton name='openHelp' variant='text' view={this.view} className='menuButton'>
												<span className='buttonIcon'>
													<img src={helpIcon} style={{ width: '16.16px', height: '16.16px' }} />
												</span>
												<span className='buttonText'>ヘルプ</span>
												<img src={plusIcon} className='buttonPlusIcon' />
											</MatButton>
											<img id='inquiryMenu' src={menuSelected} className='menuSelected' />
										</div>
									</div>
								</div>
								<Divider className='menuDivider' />
								<div className='menuSub'>
									<div>
										<MatButton name='toCompanyPage' variant='text' view={this.view} className='menuButton'>
											<span className='buttonText'>iTSCOM 会社概要</span>
											<img src={arrowRightIcon} className='buttonPlusIcon' />
										</MatButton>
									</div>

									<div>
										<MatButton name='toServicePage' variant='text' view={this.view} className='menuButton'>
											<span className='buttonText'>iTSCOM サービスページ</span>
											<img src={arrowRightIcon} className='buttonPlusIcon' />
										</MatButton>
									</div>
								</div>
							</div>

						</div>
					</WprContainer>
					<WprContainer mode='メニュー.開' view={this.view}>
						<div className='menuBarOpen'>
							<WprContainer mode='選択.料金' view={this.view}>
								<div className='openBarTitle'>
									<span className='buttonIcon'>
										<img src={feeIcon} className='menuIconImg' />
									</span>
									<span className='buttonText'>料金</span>
									<MatButton name='toFeeMenu' variant='text' view={this.view} className='arrowCircleIconOrange'>
										<img src={arrowCircleIconOrange} />
									</MatButton>
								</div>
							</WprContainer>

							<WprContainer mode='選択.ご契約・手続き' view={this.view}>
								<div className='openBarTitle'>
									<span className='buttonIcon'>
										<img src={contractIcon} className='menuIconImg' />
									</span>
									<span className='buttonText'>ご契約・手続き</span>
									<MatButton name='toContractMenu' variant='text' view={this.view} className='arrowCircleIconOrange'>
										<img src={arrowCircleIconOrange} />
									</MatButton>
								</div>
							</WprContainer>

							<WprContainer mode='選択.各種設定' view={this.view}>
								<div className='openBarTitle'>
									<span className='buttonIcon'>
										<img src={optionIcon} className='menuIconImg' />
									</span>
									<span className='buttonText'>各種設定</span>
									<MatButton name='toSettingMenu' variant='text' view={this.view} className='arrowCircleIconOrange'>
										<img src={arrowCircleIconOrange} />
									</MatButton>
								</div>
							</WprContainer>

							<WprContainer mode='選択.お問合せ・サポート' view={this.view}>
								<div className='openBarTitle'>
									<span className='buttonIcon'>
										<img src={helpIcon} className='menuIconImg' />
									</span>
									<span className='buttonText'>ヘルプ</span>
									<MatButton name='toCommonqueMenu' variant='text' view={this.view} className='arrowCircleIconOrange'>
										<img src={arrowCircleIconOrange} />
									</MatButton>
								</div>
							</WprContainer>

							<Divider className='menuOpenDivider' />

							<div className='openBarList'>
								<WprContainer mode='選択.料金' view={this.view}>
									<div>
										<MatButton name='toUsageDetail' variant='text' view={this.view} className='menuButton'>
											<span className='buttonText'>ご請求明細</span>
											<img src={arrowCircleIcon} className='buttonPlusIcon' />
										</MatButton>
									</div>
									{/* <div>
										<MatButton name='toPaymentHistory' variant='text' view={this.view} className='menuButton'>
											<span className='buttonText'>請求・支払い履歴</span>
											<img src={arrowCircleIcon} className='buttonPlusIcon' />
										</MatButton>
									</div> */}
									<div>
										<MatButton name='toChangePayment' variant='text' view={this.view} className='menuButton'>
											<span className='buttonText'>お支払い方法の変更</span>
											<img src={arrowCircleIcon} className='buttonPlusIcon' />
										</MatButton>
									</div>
								</WprContainer>

								<WprContainer mode='選択.ご契約・手続き' view={this.view}>
									<div>
										<MatButton name='toContractTop' variant='text' view={this.view} className='menuButton'>
											<span className='buttonText'>ご契約内容確認・変更</span>
											<img src={arrowCircleIcon} className='buttonPlusIcon' />
										</MatButton>
									</div>
									<div>
										<MatButton name='toServiceTop' variant='text' view={this.view} className='menuButton'>
											<span className='buttonText'>サービス加入・変更履歴</span>
											<img src={arrowCircleIcon} className='buttonPlusIcon' />
										</MatButton>
									</div>
									<div>
										<MatButton name='toContractConfirm' variant='text' view={this.view} className='menuButton'>
											<span className='buttonText textSS'>契約内容確認書のダウンロードはこちら</span>
											<img src={arrowCircleIcon} className='buttonPlusIcon' />
										</MatButton>
									</div>
									<div>
										<MatButton name='toConstrList' variant='text' view={this.view} className='menuButton'>
											<span className='buttonText'>予定日一覧</span>
											<img src={arrowCircleIcon} className='buttonPlusIcon' />
										</MatButton>
									</div>
									<div>
										<MatButton name='toAmbassador' variant='text' view={this.view} className='menuButton'>
											<span className='buttonText'>お友だち紹介</span>
											<img src={arrowCircleIcon} className='buttonPlusIcon' />
										</MatButton>
									</div>
								</WprContainer>

								<WprContainer mode='選択.各種設定' view={this.view}>
									<div>
										<MatButton name='toCustomerDetail' variant='text' view={this.view} className='menuButton'>
											<span className='buttonText'>お客さま情報確認・変更</span>
											<img src={arrowCircleIcon} className='buttonPlusIcon' />
										</MatButton>
									</div>
									<WprContainer mode='ネット契約.表示' view={this.view}>
										<div>
											<MatButton name='toMail' variant='text' view={this.view} className='menuButton'>
												<span className='buttonText'>メールアドレス</span>
												<img src={arrowCircleIcon} className='buttonPlusIcon' />
											</MatButton>
										</div>
									</WprContainer>
									<WprContainer mode='ネット契約契約中.表示' view={this.view}>
										<div>
											<MatButton name='toInternetOption' variant='text' view={this.view} className='menuButton'>
												<span className='buttonText textSmall'>インターネットセキュリティサービス</span>
												<img src={arrowCircleIcon} className='buttonPlusIcon' />
											</MatButton>
										</div>
										<div>
											<MatButton name='toHomepageDetail' variant='text' view={this.view} className='menuButton'>
												<span className='buttonText'>ホームページの設定・変更</span>
												<img src={arrowCircleIcon} className='buttonPlusIcon' />
											</MatButton>
										</div>
									</WprContainer>
								</WprContainer>

								<WprContainer mode='選択.お問合せ・サポート' view={this.view}>
									<div>
										<MatButton name='toSupport' variant='text' view={this.view} className='menuButton'>
											<span className='buttonText'>お問い合わせ・サポート</span>
											<img src={arrowCircleIcon} className='buttonPlusIcon' />
										</MatButton>
									</div>
									<WprContainer mode='ネット契約.表示' view={this.view}>
										<div>
											<form method="post" name="speed_check_menu_pc" action={netSpeedCheckURL} target="_blank">
												<WprInput type="hidden" name="session_id" view={this.view} />

												<MatButton name='toCheckNetSpeed' variant='text' view={this.view} className='menuButton'>
													<span className='buttonText'>イッツコム 通信速度測定</span>
													<img src={arrowCircleIcon} className='buttonPlusIcon' />
												</MatButton>
											</form>
										</div>
									</WprContainer>
								</WprContainer>
							</div>
						</div>
					</WprContainer>



					<WprContainer mode='メニューバー.下' view={this.view}>
						<div className='menubar_bottom' id='menubar'>
							<div className='menuBarMain'>
								<div className='menuMain'>
									<div className='menuCol'>
										<img id='openFee_selectedRight' src={selectedRight} className='menuSelected right' />
										<div>
											<MatButton name='toTop' variant='text' view={this.view} className='menuButton'>
												<div className='buttonIcon'>
													<img src={topIcon} style={{ width: '16.45px', height: '13.87px' }} />
												</div>
												<span className='buttonText'>トップ</span>
											</MatButton>
										</div>
									</div>

									<div className='menuCol'>
										<img id='openContract_selectedRight' src={selectedRight} className='menuSelected right' />
										<div>
											<MatButton name='openFee' variant='text' view={this.view} className='menuButton'>
												<div className='buttonIcon'>
													<img src={feeIcon} style={{ width: '9.18px', height: '15.29px' }} />
												</div>
												<span className='buttonText'>料金</span>
												{/* <img src={plusIcon} className='buttonPlusIcon' /> */}
											</MatButton>
										</div>
									</div>

									<div className='menuCol'>
										<img id='openFee_selectedLeft' src={selectedLeft} className='menuSelected left' />
										<img id='openOption_selectedRight' src={selectedRight} className='menuSelected right' />
										<div>
											<MatButton name='openContract' variant='text' view={this.view} className='menuButton'>
												<div className='buttonIcon'>
													<img src={contractIcon} style={{ width: '13.95px', height: '13.9px' }} />
												</div>
												<span className='buttonText'>ご契約・手続き</span>
												{/* <img src={plusIcon} className='buttonPlusIcon' /> */}
											</MatButton>
											{/* <img id='contractMenu' src={menuSelected} className='menuSelected' /> */}
										</div>
									</div>

									<div className='menuCol'>
										<img id='openContract_selectedLeft' src={selectedLeft} className='menuSelected left' />
										<img id='openHelp_selectedRight' src={selectedRight} className='menuSelected right' />
										<div>
											<MatButton name='openOption' variant='text' view={this.view} className='menuButton'>
												<div className='buttonIcon'>
													<img src={optionIcon} style={{ width: '16.71px', height: '16.43px' }} />
												</div>
												<span className='buttonText'>各種設定</span>
												{/* <img src={plusIcon} className='buttonPlusIcon' /> */}
											</MatButton>
										</div>
									</div>

									<div className='menuCol'>
										<img id='openOption_selectedLeft' src={selectedLeft} className='menuSelected left' />
										<div>
											<MatButton name='openHelp' variant='text' view={this.view} className='menuButton'>
												<div className='buttonIcon'>
													<img src={helpIcon} style={{ width: '16.16px', height: '16.16px' }} />
												</div>
												<span className='buttonText'>ヘルプ</span>
												{/* <img src={plusIcon} className='buttonPlusIcon' /> */}
											</MatButton>
											{/* <img id='inquiryMenu' src={menuSelected} className='menuSelected' /> */}
										</div>
									</div>
								</div>
							</div>
						</div>
					</WprContainer>
				</div>
			</>
		);
	}
	// --------------------------------------------------------------------------
}
