import { WprBaseViewListMultiModeInfo } from '../../../../../../wpr-framework/mode/WprBaseViewListMultiModeInfo';
import { WprMultiMode } from '../../../../../../wpr-framework/mode/WprMultiMode';
import { WprControlInfo } from '../../../../../../wpr-framework/view/control/WprControlInfo';
import { WprDI_Control } from '../../../../../../wpr-framework/view/di/WprDI_Control';
import { ItscomHomeSelect2View } from '../ItscomHomeSelect2View';
import { WprDI_Object } from '../../../../../../wpr-framework/view/di/WprDI_Object';
import { ItscomHomeOptionListModel } from '../../../../../models/mypage/smart/ItscomHomeOptionListModel';

/**
 * オプションリストモード
 */
export class OptListMode extends WprBaseViewListMultiModeInfo<ItscomHomeSelect2View, ItscomHomeOptionListModel> {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() {
		super('OptListModel');
	}
	// --------------------------------------------------------------------------
	
	// override メソッド  --------------------------------------------------------
	/**
	 * モードリスト取得（この画面モードで使用する全てのモードを定義する）
	 * @param list モードリスト
	 */
	public useModeList(list: string[]): void {
		list.push('オーナー契約IH(購入価格)');
		list.push('オーナー契約IH以外(購入価格)');
		list.push('オーナー契約IH(レンタル料)');
		list.push('オーナー契約IH以外(レンタル料)');
		list.push('購入可');
		list.push('購入不可');
	}

	/**
	 * フィールド定義を登録処理
	 */
	public onRegisterControl(): void {
		this.addControl('ownerIhPrice', { display: [ { mode: 'オーナー契約IH(購入価格)', value: true }, { mode: 'other', value: false } ] });
		this.addControl('otherPrice', { display: [ { mode: 'オーナー契約IH以外(購入価格)', value: true }, { mode: 'other', value: false } ] });
		this.addControl('ownerIhRental', { display: [ { mode: 'オーナー契約IH(レンタル料)', value: true }, { mode: 'other', value: false } ] });
		this.addControl('otherRental', { display: [ { mode: 'オーナー契約IH以外(レンタル料)', value: true }, { mode: 'other', value: false } ] });
		this.addControl('buyableUnit', { display: [ { mode: '購入可', value: true }, { mode: 'other', value: false } ] });
		this.addControl('notBuyableUnit', { display: [ { mode: '購入不可', value: true }, { mode: 'other', value: false } ] });
	}

	/**
	 * ビューの状態をチェックして行のモードを変更する
	 * @param curMode 現在のモード
	 * @param newMode 新しいモード
	 * @param row 行データ
	 */
	public onCheckListMultiMode(curMode: WprMultiMode, newMode: WprMultiMode, row: ItscomHomeOptionListModel): void {
		// 月額レンタル料、購入価格
		if(row.ownerIhFlag && row.buyUnit) {
			newMode.addMode('オーナー契約IH(購入価格)');
			newMode.addMode('オーナー契約IH(レンタル料)');
			newMode.addMode('購入可');
		}
		else if(row.ownerIhFlag && !row.buyFlag) {
			newMode.addMode('オーナー契約IH以外(購入価格)');
			newMode.addMode('オーナー契約IH(レンタル料)');
			newMode.addMode('購入不可');
		}
		else if(!row.ownerIhFlag && row.buyFlag) {
			newMode.addMode('オーナー契約IH以外(購入価格)');
			newMode.addMode('オーナー契約IH以外(レンタル料)');
			newMode.addMode('購入可');
		}
		else if(!row.ownerIhFlag && !row.buyFlag) {
			newMode.addMode('オーナー契約IH以外(購入価格)');
			newMode.addMode('オーナー契約IH以外(レンタル料)');
			newMode.addMode('購入不可');
		}
	}
	// --------------------------------------------------------------------------
}
