import React, { ReactNode } from 'react';
import { WprBaseViewComponent } from '../../../../wpr-framework/view/component/WprBaseViewComponent';
import { ReiAccesspassCompleteView } from './ReiAccesspassCompleteView';
import { Typography, Grid, Divider } from '@material-ui/core';
import { WprAnchor } from '../../../../wpr-framework/component/controls/WprAnchor';
import { MatButton } from '../../../../wpr-framework/component/controls/material/MatButton';
import { MatTypography } from '../../../../wpr-framework/component/controls/material/MatTypography';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import questionnaireIcon from '/shared/image/common/button_questionnaire_on.png';
import printIcon from '/shared/image/common/icon_print_on.png';
import warningIcon from '/shared/image/common/important_yellow.png';
import importantIcon from '/shared/image/common/important_purple.png';
import styles from './ReiAccesspassComplete.scss';


/**
 * アカウントパスワード発行完了用コンポーネント
 */
export class ReiAccesspassComplete extends WprBaseViewComponent {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: any) { super(new ReiAccesspassCompleteView(), props); }
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** アカウントパスワード発行完了ビュー情報 */
	public get viewInfo(): ReiAccesspassCompleteView	{ return this.view as ReiAccesspassCompleteView;	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * スタイルオブジェクト取得
	*/
	public getStylesObject(): any { return styles; }

	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		return (
			<>
			<div>
				<div className='pageCard'>
					<div className='main'>
						<div className='title_center'>
							<Typography className='title_complete'>アクセスアカウントのパスワード発行が完了しました。</Typography>
						</div>
						<div className='title_normal' style={{ fontWeight: 'bold' }}>
							<MatTypography name={'user'} view={this.view} className='title_border' />
						</div>
						<div className='subText'>あなたのアクセスアカウントのパスワードです。</div>
						<div className='subText2'>印刷やメモをするなどして大切に保管しておいてください。</div>
						<div className='gray'>
							<Grid container>
								<Grid item xs={12} md={4} className='grid-print-4'>
									<div>コース</div>
								</Grid>
								<Grid item xs={12} md={8} className='grid-print-8'>
									<MatTypography name={'course'} view={this.view} className='bold-black'></MatTypography>
								</Grid>
							</Grid>	
							<Grid container>
								<Grid item xs={12} md={4} className='grid-print-4'>
									<div>アクセスアカウント</div>
								</Grid>
								<Grid item xs={12} md={8} className='grid-print-8'>
									<MatTypography name={'accessAccount'} view={this.view} className='itemUnit bold-black'></MatTypography>
								</Grid>
							</Grid>	
							<Grid container>
								<Grid item xs={12} md={4} className='grid-print-4'>
									<div>パスワード</div>
								</Grid>
								<Grid item xs={12} md={8} className='grid-print-8'>
									<MatTypography name={'password'} view={this.view} className='itemUnit bold-black'></MatTypography>
								</Grid>
							</Grid>	
							<Grid container>
								<Grid item xs={12} md={4} className='grid-print-4a'>
									<div>パスワード読み仮名</div>
								</Grid>
								<Grid item xs={12} md={8} className='grid-print-8a'>
									<div dangerouslySetInnerHTML={{ __html: this.state.data.sentenceData }}  className='itemUnit bold-black'/>
								</Grid>
							</Grid>	
							<Grid container>
								<Grid item xs={12} md={4} className='grid-print-4'>
									<div>変更日</div>
								</Grid>
								<Grid item xs={12} md={8} className='grid-print-8'>
									<MatTypography name={'date'} view={this.view} className='itemUnit bold-black'></MatTypography>
								</Grid>
							</Grid>		
						</div>
						<div className='title_left'>
							<Typography className='title_memo' style={{ marginTop: '1px', fontSize:'14px', textAlign: 'left' }}>※パスワードの読み仮名にアンダーラインが引かれている文字は大文字を意味します。</Typography>
						</div>
						<div className='blueText_underline'>
							<WprAnchor name='print' view={this.view} style={{ display: 'flex' }} >
								<Typography style={{}}>印刷画面へ </Typography>
								<img src={printIcon}/>
							</WprAnchor>
						</div>
						<div className='warning_msg'>
							<img src={warningIcon} style={{maxWidth: '100%'}} />
							<Typography className='title_memo'>こちらの画面に表示された内容は、以後確認はできません。印刷する場合は右上の『印刷画面へ』をご利用ください。</Typography>
						</div>
						<div className='supportMsgArea2'>
							<span className='textcolor'><img src={importantIcon} alt="purple" style={{verticalAlign: 'middle'}}/> ルーター等へ設定する際には、以下のようにご入力いただく必要がございます。</span>
							<div style={{margin: '5px 0px', color: '#333333'}}>・アクセスアカウントの最後が「@a01」で終わる場合：「アクセスアカウント@itscom.net」と入力します。<br />
							 (例) a1234567@a01@itscom.net<br />
							<br />
							・それ以外の場合：「アクセスアカウント@246.ne.jp」と入力します。<br />
							 (例) 1234567@246.ne.jp
							</div>	
						</div>
						<div className='questionnaire' style={{marginTop:'60px'}}>
							<WprAnchor name='toQuestionnaire' view={this.view}  >
								<img src={questionnaireIcon} style={{maxWidth: '100%'}} />
							</WprAnchor>
						</div>
					</div>
					<Divider />
					<div className='pageFooter footer-space'>
						<div>
							<MatButton name='back' view={this.view} className='completeButton backContInfoList' >
								<NavigateBeforeIcon />
								<span className='screen'>契約内容の確認・変更メニューへ</span>
							</MatButton>
						</div>
					</div>
				</div>
			</div>
			</>
		);
	}
	// --------------------------------------------------------------------------
}
