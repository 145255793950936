import { WprBaseViewCore } from '../WprBaseViewCore';
import { WprFramework } from '../../WprFramework';

/**
 * リストデータデコレータ
 * @param listName テーブル名称
 */
export function WprListData(/* target: any, props: string,*/ listName?: string) {
	WprBaseViewCore.listNameList.push(listName);
	// return <TFunc extends Function> (target: any, props: string) => {
	return (target: any, props: string) => {
		const viewCore = target as WprBaseViewCore;
		WprFramework.getInstance().model.addDecoMapInfo('ListName', viewCore, WprBaseViewCore.listNameList.shift(), props);
		const baseName = viewCore.getBaseName();
		if (baseName)
			WprFramework.getInstance().model.addBaseDIInfo(baseName, viewCore);
	};
}
