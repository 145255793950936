import { WprControlInfo } from '../../../../wpr-framework/view/control/WprControlInfo';
import { WprModel } from '../../../../wpr-framework/view/deco/WprModel';
import { WprDI_Control } from '../../../../wpr-framework/view/di/WprDI_Control';
import { WprDI_ScopeModel } from '../../../../wpr-framework/view/di/WprDI_ScopeModel';
import MypageDispdivcdConstants from '../../../common/MypageDispdivcdConstants';
import { ServiceModel } from '../../../service/models/apimodels/ServiceModel';
import { GetSimulationRequest } from '../../../service/models/dto/mypagerenewal/GetSimulationRequest';
import { MypageBaseView } from '../../base/MypageBaseView';
import { ProgressBarData } from '../../common/progress_bar/ProgressBarView';
import { MypageWizardMngr } from '../../wizard/models/MypageWizardMngr';
import { TokotonSupportAppModel } from '../models/TokotonSupportAppModel';

/**
 * とことんサポート確認画面
 */
export class TokotonSupportConfirmView extends MypageBaseView {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() { super('TokotonSupportConfirmView'); }
	// --------------------------------------------------------------------------
	
	// モデル  ------------------------------------------------------------------
	@WprDI_ScopeModel('MypageWizardMngr')
	private m_MypageWizardMngr: MypageWizardMngr = null;	// 画面ウィザード用モデル
	@WprModel('TokotonSupportAppModel')
	private m_TokotonSupportAppModel: TokotonSupportAppModel	= null;	// とことんサポートモデル
	// --------------------------------------------------------------------------

	// private変数  -------------------------------------------------------------
	private m_selectedApplicationType: String	= null;	// 選択された申込み種別
	private m_ServiceList: ServiceModel[] 					= null;	// サービスリスト

	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * コントロール定義設定
	 */
	public onRegisterConfig(): void {
		this.addConfig('amount', '月額利用料', 'TokotonSupportAppModel');
		this.addConfig('applicationType', '申し込み種別', 'TokotonSupportAppModel');
	}

	public onShowView(): void {
		this.m_MypageWizardMngr.initView(this, (result) => {
			this.registAccessLog();
			this.setStorageItem('b_url', window.location.href);
			const sendData: ProgressBarData = new ProgressBarData();
			sendData.progressBarList = this.m_MypageWizardMngr.getProgressModelList(this);
			this.sendChildData('fP_ev_progressBarIntital', sendData);
			this.m_TokotonSupportAppModel = this.m_MypageWizardMngr.getCache().m_ApplicationInfo;
			this.m_ServiceList = this.m_MypageWizardMngr.getServiceList(this, MypageDispdivcdConstants.TOKOTON_SUPPORT_COURSE_NAME);
			// 税込の月額金額の取得
			this.m_TokotonSupportAppModel.amount = this.m_MypageWizardMngr.getTaxFee(this, String(this.m_ServiceList[0].service.payment[0].amount));
			// 料金シミュレーション
			const req: GetSimulationRequest = new GetSimulationRequest();
			req.customerId = this.m_MypageWizardMngr.getParams().customerId;
			this.m_CommonService.getPaymentSimulation(this, req, (result) => {
				if (result) {
					const payment = this.m_MypageWizardMngr.getViewInfo().customerPayment;
					if (result.breforeFee === '0' && payment && 
						payment[0].payment[0].paymentTypeCD === '2' && !this.m_MypageWizardMngr.isPaidService(this))
						this.setViewMode('請求書有料発行', '表示');
				}
				this.refreshView();
			});
			this.refreshView();
		});
	}
	/**
	* アクション登録
	*/
	public onRegisterAction(): void {
		this.addAction('next', this.onNext, true);
		this.addAction('back', this.onBack);
	}

	public onNext(param: any): void {
		this.m_MypageWizardMngr.goNext(this);
	}
	// --------------------------------------------------------------------------

	/**
	* 戻る
	*/
	public onBack(param: any): void {
		this.m_MypageWizardMngr.goBack(this);
	}
	// --------------------------------------------------------------------------
}
