
/**
 *  【MDisplay：DISPDIVCD】
 */
class MypageDispdivcdConstants {
    
	/** CD0001~CD0030 =========================================== */
	/** 支払い方法*/
	PAYMENT_DISPNAME:string = 'CD0005';
	/** ItscomHome_サービス契約ステータス*/
	ITSCOMHOME_SERVICESTATUS:string = 'CD0010';
	/** テレビ_オプションステータス*/
	TV_OPTIONSTATUS:string = 'CD0011';
	/** CD0031~CD0060 =========================================== */
	/** NHK割引種類*/
	NHK_DISCOUNTTYPE:string = 'CD0031';
	/** ItscomHome_端末タイプ*/
	ITSCOMHOME_TERMINALTYPE: string = 'CD0032';
	/** テレビ_オプション*/
	TV_OPTION: string = 'CD0033';
	/** テレビ_チャンネル*/
	TV_OPTION_CHANNEL: string = 'CD0034';
	/** ItscomHome_お得パック名称*/
	ITSCOMHOME_OTOKUNAME: string = 'CD0035';
	/** ItscomNet_回線種別*/
	ITSCOMNET_LINETYPE: string = 'CD0036';
	/** IPアドレス種別*/
	IPADDRESS_TYPE:string = 'CD0041';
	/** テレビ_サービス */
	TV_SERVICE:string = 'CD0050';
	/** アクセスアカウント_サービス */
	ACCESSPASS_SERVICE:string = 'CD0051';
	/** テレビ_その他サービス */
	TV_OTHER_SERVICE:string = 'CD0053';
	/** ItscomPhone_サービス */
	ITSCOMPHONE_SERVICE1:string = 'CD0056';
	/** ItscomNet_コース情報(FTTH以外)*/
	ITSCOMNET_COURSE_OTHER = 'CD0054';
	/** ItscomNet_その他コース情報*/
	ITSCOMNET_OTHERCOURSE_NAME = 'CD0055';
	/** CD0061~CD0090 =========================================== */
	/** ItscomHome_端末形態*/
	ITSCOMHOME_TERMINALSTATUS: string = 'CD0061';
	/** ItscomHome_サービス(変更) */
	ITSCOMHOME_CHENGE_SERVICE: string = 'CD0074';
	/** ItscomHome_サービス(追加) */
    ITSCOMHOME_ADD_SERVICE: string = 'CD0075';
	/** ItscomHome_オプション */
    ITSCOMHOME_OPTION:string = 'CD0076';
	/** tv_FTTHサービス */
    TV_FTTH_SERVICE:string = 'CD0087';
	/** ItscomNet_コース情報(FTTH/集合・戸建賃貸/物件プラン(その他))*/
	ITSCOMNET_COURSE_FTTH_OTHER: string = 'CD0089';
	/** ItscomNet_コース情報(FTTH/戸建)(FTTH/戸建賃貸/物件プラン(FTTH賃貸戸建用)*/
	ITSCOMNET_COURSE_FTTH_DETACH: string = 'CD0090';
	/** CD0091~ ================================================= */
	/** ItscomPhone_サービス */
    ITSCOMPHONE_SERVICE2:string = 'CD0091';
	/** ItscomPhone_サービス */
    ITSCOMPHONE_SERVICE3:string = 'CD0092';
	/** 動画配信サービス_サービス金額 */
    VIDEO_SERVICE:string = 'CD0096';
	/** ItsocomNet_コース情報(店子上書き対象) */
	ITSCOMNET_COURSE_TANAKO_OVERWRITE: string = 'CD0099';
	/** ItscomNet_コース情報(FTTH/集合/物件プラン(FTTH光回線orFTTH高速光配線))*/
	ITSCOMNET_COURSE_FTTH_APART_FTTH: string = 'CD0098';
	/** tv_毎日充実プランサービス*/
	TV_MAIJU_SERVICE:string  = 'CD0100';
	/** ItscomHome_インテリジェントサービス*/
	ITSCOMHOME_INTELLISERVICE:string  = 'CD0101';
	/** ItscomHome_インテリジェント端末*/
	ITSCOMHOME_INTELLIDEVICE:string = 'CD0102';
	/** テレビ_店子サービス*/
	TV_TANAKO_SERVICE:string = 'CD0104';
	/** ItscomNet_コース情報(店子追加対象)*/
	ITSCOMNET_COURSE_TANAKO_ADD = 'CD0105'
	/** テレビ_APサービス*/
	TV_AP_SERVICE = 'CD0106'
	/** テレビ_DVD-HitPot*/
	TV_DVDHITPOT_SERVICE = 'CD0107'
	/** ItscomNet_自動化停止対象コース情報*/
	ITSCOMNET_STOPAUTOMATIONCOURSE: string = 'CD0109';
	/** Mvos_自動化停止対象コース情報*/
	MVOS_STOPAUTOMATIONCOURSE: string = 'CD0110';
	/** メッシュWIFI*/
	MESH_WIFI: string = 'CD0111';
	/** とことんサポート申し込み種別*/
	TOKOTON_SUPPORT_APPLICATION_TYPE: string = 'CD0113';
	/** とことんサポートコース名*/
	TOKOTON_SUPPORT_COURSE_NAME: string = 'CD0114';
}

export default new MypageDispdivcdConstants();