import React, { ReactNode } from 'react';
import { IWprControlProps } from '../../props/IWprControlProps';
import { WprBaseControlComponent } from '../WprBaseControlComponent';
import { WprControlVisibility } from '../../props/IWprControlState';
import { Typography } from '@material-ui/core';

/**
 * タイポグラフィコントロールプロパティ情報
 */
interface IMatTypographyProps extends IWprControlProps {
	converter?: string;			// コンバータ
	variant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'subtitle1' | 'subtitle2' | 'body1' | 'body2' | 'caption' | 'button' | 'overline' | 'srOnly' | 'inherit';				// 形状
	color?: 'initial' | 'inherit' | 'primary' | 'secondary' | 'textPrimary' | 'textSecondary' | 'error';	// 色
	display?: 'initial' | 'block' | 'inline';
	gutterBottom?: boolean;
	noWrap?: boolean;
	paragraph?: boolean;
}

/**
 * タイポグラフィコントロール
 */
export class MatTypography extends WprBaseControlComponent<IMatTypographyProps, HTMLElement> {
	// プロパティ  ---------------------------------------------------------------
	/** 編集フラグ */
	public get isEdit(): boolean	{ return false; 	}
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	constructor(props: IMatTypographyProps){
		super(props);
	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * 値取得
	 * @returns 値
	 */
	protected getValue(): any {
		return undefined;
	}

	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		if (this.state.visibility === WprControlVisibility.COLLAPSED)
			return <></>;
		const props = this.getProps();
		const {name, view, className, converter, children, ...other} = this.props;
		const state = this.getState(['style', 'disabled']);
		let typography = null;
		if (this.state.visibility === WprControlVisibility.VISIBLE) {
			if (this.hasBr() === false)
				typography = <Typography {...props} {...state} {...other}>{`${this.getViewValue(this.props.converter, this.props.row)}`}</Typography>;
			else
				typography = <Typography {...props} {...state} {...other}>{this.getBrContent()}</Typography>;
		}
		else {
			typography = <></>;
		}
		return (
			<>
				{typography}
			</>
		);
	}
	// --------------------------------------------------------------------------

	// private メソッド  --------------------------------------------------------
	/**
	 * <br>保持判定処理
	 * @returns trueの場合、br保持
	 */
	private hasBr(): boolean {
		let content = this.state.value as string;
		if (typeof(content) === 'string' && content != null && content.includes('<br') === true && content.includes('/>') === true)
			return true;
		return false;
	}

	/**
	 * <br>付きコンテント取得
	 * @returns <br>
	 */
	private getBrContent(): any[] {
		const contentList = new Array();
		let content = this.state.value as string;
		let key = 1;
		while(content.includes('<br') === true) {
			const sidx = content.indexOf('<br');
			const eidx = content.indexOf('/>');
			contentList.push(content.substring(0, sidx));
			contentList.push(this.getBr(content.substring(sidx, eidx), key));
			content = content.substring(eidx+2);
			key++;
		}
		contentList.push(content);
		return contentList;
	}

	/**
	 * <br>取得（classNameのみ判定）
	 * @returns <br>
	 */
	private getBr(content: string, key: number): ReactNode {
		if (content.includes('className') === true) {
			const idx = content.indexOf('className');
			const str = content.substring(idx);
			const list = str.split('\'');
			return <br className={list[1]} key={key}/>;
		}
		return <br key={key}/>;
	}
	// --------------------------------------------------------------------------
}