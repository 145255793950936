

export class BssConstants {
	// static 変数 -------------------------------------------------
	// BSS_契約種別コード
	public static readonly BSS_KIND_TV: string = '010';
	public static readonly BSS_KIND_NET: string = '020';
	public static readonly BSS_KIND_TEL: string = '030';
	public static readonly BSS_KIND_MOBILE: string = '070';
	public static readonly BSS_KIND_MOBILE_EQUIP: string = '071';
	public static readonly BSS_KIND_IDPC: string = '080';
	public static readonly BSS_KIND_TAB: string = '090';
	public static readonly BSS_KIND_NHK: string = '110';
	public static readonly BSS_KIND_IH: string = '120';
	public static readonly BSS_KIND_TVPUSH: string = '125';
	public static readonly BSS_KIND_EARTHQUAKE: string = '130';
	public static readonly BSS_KIND_LUMPSUM: string = '210';
	public static readonly BSS_KIND_SUPPORT: string = '310';
	public static readonly BSS_KIND_ENERGY: string = '420';
	public static readonly BSS_KIND_FACILITY_USE: string = '500';


	// BSS契約：サービスタイプコード
	public static readonly BSS_CONTRACT_SERVICETYPECD_TV: string = '1';	
	public static readonly BSS_CONTRACT_SERVICETYPECD_NET: string = '2';
	public static readonly BSS_CONTRACT_SERVICETYPECD_PHONE: string = '4';
	public static readonly BSS_CONTRACT_SERVICETYPECD_KDDI: string = '5';
	public static readonly BSS_CONTRACT_SERVICETYPECD_OTHER: string = '6';
	public static readonly BSS_CONTRACT_SERVICETYPECD_IH: string = '7';
	public static readonly BSS_CONTRACT_SERVICETYPECD_ENERGY: string = '8';
	public static readonly BSS_CONTRACT_SERVICETYPECD_IDP: string = '9';
	public static readonly BSS_CONTRACT_SERVICETYPECD_TVF: string = '11';
	public static readonly BSS_CONTRACT_SERVICETYPECD_NETF: string = '12';
	public static readonly BSS_CONTRACT_SERVICETYPECD_PHONEF: string = '15';

	// BSS契約：ステータスコード
	public static readonly BSS_CONTRACT_STATUSCD_NOT: string = '0';	// 未契約
	public static readonly BSS_CONTRACT_STATUSCD_CONTRACT: string = '1';	// 契約中
	public static readonly BSS_CONTRACT_STATUSCD_STOP: string = '2';	// 停止中
	public static readonly BSS_CONTRACT_STATUSCD_CANCELED: string = '3';	// 解約済み
	public static readonly BSS_CONTRACT_STATUSCD_MOVING: string = '4';	// 転居中
	public static readonly BSS_CONTRACT_STATUSCD_BEFOREAPPROVAL: string = '5';	// 承認前

}