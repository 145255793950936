import { Divider, Typography } from '@material-ui/core';
import React, { ReactNode } from 'react';
import { MatButton } from '../../../../wpr-framework/component/controls/material/MatButton';
import { MatTypography } from '../../../../wpr-framework/component/controls/material/MatTypography';
import { WprAnchor } from '../../../../wpr-framework/component/controls/WprAnchor';
import { WprBaseViewComponent } from '../../../../wpr-framework/view/component/WprBaseViewComponent';
import { ProgressBar } from '../../common/progress_bar/ProgressBar';
import { NameChangeIdentificationView } from './NameChangeIdentificationView';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { MatCheckBox } from '../../../../wpr-framework/component/controls/material/MatCheckBox';
import { WprContainer } from '../../../../wpr-framework/component/controls/WprContainer';
import styles from './NameChangeIdentification.scss';
import { HelpIdentificationDocDialog } from '../../mvno/dialog/help_identification_doc_dialog/HelpIdentificationDocDialog';
import { IdentificationLabel } from '../../../controls/IdentificationLabel';

/**
 * 名義変更本人確認書類アップロード用コンポーネント
 */
export class NameChangeIdentification extends WprBaseViewComponent {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: any) { super(new NameChangeIdentificationView(), props); }
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** 名義変更本人確認書類アップロードビュー情報 */
	public get viewInfo(): NameChangeIdentificationView	{ return this.view as NameChangeIdentificationView;	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * スタイルオブジェクト取得
	*/
	public getStylesObject(): any { return styles; }

	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		return (
			<>
			<div>
				<div>
					<IdentificationLabel name='customerId' view={this.view} style={{ display: 'none' }} />
				</div>
				<div>
					<label style={{ display: 'none' }}>MyPage</label>
				</div>
				<div>
					<IdentificationLabel name='environment' view={this.view} style={{ display: 'none' }} />
				</div>
				<div>
					<label style={{ display: 'none' }}>旧名義人</label>
				</div>
			</div>
			<div className='pageCard'>
				<div className="pageHeader stepFlow">
					<ProgressBar name='progressBar' view={this.view} />
				</div>
				<div className='main'>
					<div className='link_blue'>
						<WprAnchor name='helpLink' view={this.view}>申込みについてご不明な方はこちら＞</WprAnchor>
					</div>
					<div className='pageTitle'>
						<div className='title_left'>
							<MatTypography name='title' className='title_text' view={this.view}/>
							<Typography className='title_comment'>
								本サービスをより安全にご利用になれるよう、証明書類　WEB提出にご協力をお願いします。
							</Typography>
						</div>
					</div>
					<br />
					<div>
						<div className='link_blue'>
							<WprAnchor name='helpIdentificationDoc' view={this.view}>本人確認書類について⊕</WprAnchor>
						</div>
						<div style={{ marginBottom: '2em' }}>
							証明書類のWEB提出を行います。
							<br />
							証明書類と、スマートフォンが必要です。
							<br />
							※一部カメラ機能付きパソコンでもご利用可能ですが、証明書類の情報が読み取れない場合には画像不鮮明で再対応となる可能性があるため、スマートフォンでの撮影を推奨しております。確認画面で不鮮明な箇所が場合には、別端末でお試しください。
							<br />
							本人確認を途中でやり直す場合には証明書類　WEB提出の画面から入り直しをお願いいたします。
							<br />
							「オンラインで証明書類提出をする」選択後の本人確認ページURLのみコピー&ペーストされると、最終画面でエラーになりますのでご注意ください。
							<br />
							以下の「証明書類　WEB提出における説明事項」を一読の上、ご本人さま確認へお進みください。
						</div>
					</div>
					<div className='box_blue2'>
						<div className='boxTitle'>
							証明書類 WEB提出における説明事項
						</div>
						<div className='boxContent identificationCheckList'>
							<div>
								{/* <div className='boxItemTitle'>
									名義変更に関する書類
								</div> */}
								<div>変更事由に応じて、以下の書類を提出いただく必要がございます。</div>
								<div className='itemContent'>
									①改称　個人：（婚姻、その他裁判所により改姓、改名が認められた場合）
									<div>
										・変更後のお名前がわかるご本人さま確認書類
									</div>
								</div>
								<div className='itemContent'>
									②承継　個人：（既契約者さまの逝去により、二親等以内の親族に引き継ぐ場合）
									<div>
										・ご家族関係証明書類
									</div>
									<ul className='ul_kome'>
										<li>
											提出いただくご家族関係証明書類内にご逝去者の死亡記載が無い場合、
											<br />
											以下いずれかの書類も併せてご提出ください。
											<br />
											死亡診断書/会葬礼状/新聞のお悔やみ欄/死亡届(受理印あり)/火葬許可証
											<br />
											埋葬許可証/香典返し/斎場使用料の領収書/死亡届記載事項証明書/公正証書としての遺贈書
										</li>
									</ul>
									・新しいご契約者さまのご本人様確認書類
								</div>
								<div className='itemContent'>
									③譲渡　個人：（二親等以内の親族間で、両者の合意による場合）
									<div>
										・ご家族関係証明書類
									</div>
									<div className='space'>
										・新しいご契約者さまのご本人様確認書類
									</div>
								</div>
								<div className='itemContent'>
								 【ご本人さま確認書類】
									<ul className='ul_ten'  style={{marginBottom:'0'}}>
										<li>運転免許証（含仮免）</li>
										<li>運転経歴証明書</li>
										<li>パスポート（日本国旅券）＊住所の記載がされているもの</li>
										<li>身体障がい者手帳 ＊住所の記載がされているもの</li>
										<li>健康保険被保険者証</li>
										<li>在留カード ＊顔写真のあるもの</li>
										<li>マイナンバーカード</li>
									</ul>
									<ul className='ul_kome'>
										<li>
											有効期限内のもの（在留カードに関しては、残存日数が90日以上あるもの）をご用意ください。
										</li>
									</ul>
									<div className='space'>
										健康保険被保険者証を提出いただく場合は、下記の補助書類についてもあわせてご用意ください。
									</div>
									<ul className='ul_ten' style={{marginBottom:'0'}}>
										<li>住民票 ＊住所の記載がされており、マイナンバーを含まないもの</li>
										<li>公共料金（電気、水道、都市ガス）の領収書 ＊住所の記載がされているもの</li>
										<li>NHK受信料の領収書 ＊住所の記載がされているもの</li>
										<li>学生証 ＊顔写真があるもの。未成年の場合のみ可</li>
									</ul>
									<ul className='ul_kome'>
										<li>
											発行から3か月以内のもの（学生証は当該年度のもの）をご用意ください。
										</li>
										<li>
											ご契約者さまが未成年者さまの場合は、親権者さまの「ご本人さま確認書類」を併せてご提出ください。
										</li>
									</ul>
								</div>
								<div className='itemContent'>
								【ご家族関係証明書類】
									<ul className='ul_ten'  style={{marginBottom:'0'}}>
										<li>戸籍謄本／戸籍全部事項証明書</li>
										<li>戸籍抄本／戸籍個人事項証明書</li>
										<li>除籍謄本／除籍全部事項証明書</li>
										<li>除籍抄本／除籍個人事項証明書</li>
										<li>住民票（世帯票／個人票／除票）</li>
									</ul>
									<ul className='ul_kome'>
										<li>
											発行から3か月以内のもの（学生証は当該年度のもの）をご用意ください。
										</li>
									</ul>
								</div>
							</div>
							<br />
							<div>
								<div className='boxItemTitle'>
									手順
								</div>
								<div>
									ブレたり、見切れたりしないよう注意してください。
									<ol className='ul_ten'>
										<li>
											本人確認書類を撮影（表面・うら面・厚み）※マイナンバーカードのみ、うら面のご提出は不要です。
										</li>
										<li>
											顔の撮影　※本人確認書類との類似性確認ができない場合、再撮影となります。他の本人確認書類もお試しください。
										</li>
										<li>
											かたむきチェック
										</li>
									</ol>
									<br />
									本人確認情報送信後、内容の確認ができ次第弊社よりご連絡をさせていただきます。
								</div>
							</div>
							<br />
							<div>
								<div className='boxItemTitle'>
									チェック項目
								</div>
								<div>
									以下の点に注意いただき撮影をお願い致します。<br />
									<ul className='ul_check'>
										<li>
											本人確認書類に光の反射がないか
										</li>
										<li>
											本人確認書類のガイドサイズにあっているか
										</li>
										<li>
											撮影モードの設定などで不鮮明でないか
										</li>
										<li>
											画面が暗いなどで本人確認書類を読み取れない状況ではないか
										</li>
										<li>
											指や文字などで写真が隠れていないか
										</li>
										<li>
											斜め撮影の際、厚みが確認できない角度になっていないか
										</li>
									</ul>
								</div>

							</div>
							<br />
							<div>
								<div className='boxItemTitle'>
									【注意事項】
								</div>
								<ul className='ul_ten'>
									<li>
										画像が不鮮明、本人確認情報が不一致等の場合は、再度の対応が必要となります。
									</li>
									<li>
										上記の本人確認書類をご送付いただいた場合でも、他の確認書類の送付をお願いする場合があります。
									</li>
									<li>
										撮影された画像データは返却いたしませんので、あらかじめご了承ください。
									</li>
									<li>
										いただいた画像データは、「電気通信事業法」、「携帯電話不正利用防止法」に基づいたご本人確認の目的のみに利用します。
									</li>
									<li>
										上記法令に基づき、ご契約時にご本人さまを特定させていただいた事項については、当社で記録し、保存させていただきます。
									</li>
								</ul>
							</div>
						</div>
					</div>
					<br />
					<div style={{ textAlign: 'center' }}>
						<MatCheckBox name='confirm' view={this.view} label='ご本人さま確認における説明事項について確認しました。' />
					</div>
					<div>
						<WprContainer mode='確認用チェックボックス.表示' view={this.view}>
							<div style={{ textAlign: 'center' }}>
								<MatCheckBox name='isCompleteIdentification' view={this.view} label='本人確認完了（テスト用）' />
							</div>
						</WprContainer>
					</div>
				</div>
				<div className='pageFooter footer-space'>
					<div>
						<MatButton name='next' view={this.view} className='orangeButton orangeButton_parts' >
							受付内容確認へ
							<NavigateNextIcon />
						</MatButton>
					</div>
					<div>
						<MatButton name='back' view={this.view} className='backButton' >
							<NavigateBeforeIcon />
							戻る
						</MatButton>
					</div>
				</div>
			</div>
			{/* ダイアログ ---------------------------------------------------------------- */}
			<HelpIdentificationDocDialog name='ヘルプ：本人確認書類について' view={this.view} className='dialog-medium' />
			</>
		);
	}
	// --------------------------------------------------------------------------
}
