import React, { ReactNode } from 'react';
import { WprBaseViewComponent } from '../../../../wpr-framework/view/component/WprBaseViewComponent';
import { ConstrLogView } from './ConstrLogView';
import { AccordionDetails, AccordionSummary, Grid, Table, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';
import { MatButton } from '../../../../wpr-framework/component/controls/material/MatButton';
import { MatTypography } from '../../../../wpr-framework/component/controls/material/MatTypography';
import orangeIcon from '/public/next_icon_orange.png';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import styles from './ConstrLog.scss';
import { WprRowInfo } from '../../../../wpr-framework/component/props/WprRowInfo';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { WprContainer } from '../../../../wpr-framework/component/controls/WprContainer';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { ExpandMore } from '@material-ui/icons';
import { MatAccordion } from '../../../../wpr-framework/component/controls/material/MatAccordion';
import { MatTabs } from '../../../../wpr-framework/component/controls/material/MatTabs';

/**
 * 履歴確認画面用コンポーネント
 */
export class ConstrLog extends WprBaseViewComponent {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: any) { super(new ConstrLogView(), props); }
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** 履歴確認画面ビュー情報 */
	public get viewInfo(): ConstrLogView	{ return this.view as ConstrLogView;	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * スタイルオブジェクト取得
	*/
	public getStylesObject(): any { return styles; }

	/**
	 * リストコントロール定義登録
	*/
	protected onRegisterListRender(): void {
		this.addListRender('ConstrDateListModel', null, this.onConstrDateListRender);
	}

	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		return (
			<>
			<div className='pageCard'>
				<div className='main' style={{minHeight: '500px'}}>
					<div className='pageTitle'>
						<div className='title_left'>
							<MatTypography name='title' className='title_text' view={this.view}/>
						</div>
					</div>
					<div style={{textAlign:'right'}}>
						<MatButton variant='text' name='back' view={this.view} style={{fontWeight: '600'}} >
							予定日一覧に戻る
							<img src={orangeIcon} style={{width:'20px',marginLeft:'5px'}}/>
						</MatButton>
					</div>
					<div className='viewGroup'>
						<div className='title_space'>
							<div className='itemTitle title_maru'>過去工事履歴一覧</div>
						</div>
						<WprContainer mode={'表示内容.あり'} view={this.view}>
							{this.renderList('ConstrDateListModel')}
						</WprContainer>
						<WprContainer mode={'表示内容.なし'} view={this.view}>
							<div className='viewGroup'>
								<Typography style={{padding: '15px 0'}}>過去予定された工事日はありません。</Typography>
							</div>
						</WprContainer>
					</div>
				</div>
			</div>
			</>
		);
	}
	// --------------------------------------------------------------------------

	// リスト描画  ---------------------------------------------------------------
	/**
	 * リスト名=ConstrAddList
	 */
	public onConstrDateListRender(rowInfo: WprRowInfo): any {
		const modeName = rowInfo.index + '.表示'
		const hiddenModeName = rowInfo.index + '.非表示'
		const accMode = rowInfo.index.toString();
		const accName = (rowInfo.index).toString() + '_name';
		return (
			<div className='feeDetailsAcc'>
				<MatAccordion mode={accMode} name={accName} view={this.view}>
					<AccordionSummary expandIcon={<ExpandMore />}>
						<Grid container>
							<Grid item md={12} xs={12}>
								<div className='tokyuNameHeader' style={{display: 'flex', alignItems: 'center', flexWrap: 'wrap'}}>
									<MatTypography name={'date'} row={rowInfo} view={this.view} className='body1'></MatTypography>
									<span className='body1'>（</span>
									<MatTypography name={'dayOfWeek'} row={rowInfo} view={this.view} className='body1'></MatTypography>
									<span className='body1'>）　</span>
									<MatTypography name={'time'} row={rowInfo} view={this.view} className='body1'></MatTypography>
									<Typography className='body1'>の間到着</Typography>
								</div>
							</Grid>
						</Grid>
					</AccordionSummary>
					<AccordionDetails className='itemDetails'>
						<TableContainer>
							<Table className='feeDetailsTable'>
								<colgroup>
									<col style={{ width: '100%' }} />
								</colgroup>
								<TableHead>
									<TableRow className='courseItemDetailsTable'>
										<TableCell className='tokyuSubItem courseItemName' style={{display: 'flex', flexWrap: 'wrap'}}>
											<span className='body2'>お申込み内容：</span>
											<MatTypography name={'constrType'} row={rowInfo} view={this.view} className='body2'></MatTypography>
										</TableCell>
									</TableRow>
								</TableHead>
							</Table>
						</TableContainer>
					</AccordionDetails>
				</MatAccordion>
			</div>
		);
	}
	// --------------------------------------------------------------------------
}
