import { WprListData } from '../../../../../wpr-framework/view/deco/WprListData';
import { WprModel } from '../../../../../wpr-framework/view/deco/WprModel';
import { WprDI_ScopeModel } from '../../../../../wpr-framework/view/di/WprDI_ScopeModel';
import { ObjectUtil } from '../../../../common/util/ObjectUtil';
import { HMvnoTmpSaved } from '../../../../service/models/entity/HMvnoTmpSaved';
import { MvnoTerminalModel } from '../../../../service/models/apimodels/MvnoTerminalModel';
import { GetTerminalStockRequest } from '../../../../service/models/dto/mvno/GetTerminalStockRequest';
import { MvnoService } from '../../../../service/MvnoService';
import { MypageBaseView } from '../../../base/MypageBaseView';
import { GetHMvnoTmpSavedRequest } from '../../../../service/models/dto/mvno/GetHMvnoTmpSavedRequest';
import { UpdateHMvnoTmpSavedRequest } from '../../../../service/models/dto/mvno/UpdateHMvnoTmpSavedRequest';
import { MvnoEntryInfo } from '../../../../service/models/apimodels/MvnoEntryInfo';
import { MvnoEntryFooterReceiveData } from '../mvno_entry_footer/MvnoEntryFooterView';
import { WprViewMode } from '../../../../../wpr-framework/view/deco/WprViewMode';
import { DateUtil } from '../../../../common/util/DateUtil';
import { MvnoBaseView } from '../../../base/MvnoBaseView';
import { GetCustomerRequest } from '../../../../service/models/dto/mvno/GetCustomerRequest';
import { WprValidError } from '../../../../../wpr-framework/view/validation/WprValidError';
import { EditMode } from './mode/EditMode';
import { MvnoHelpDialogData } from '../../dialog/mvno_help_dialog/MvnoHelpDialogView';
import { MypageLinkConstatns } from '../../../../common/constants/MypageLinkConstatns';
import { ProgressBarModel, ProgressBarStopModel } from '../../../../models/ProgressBarModel';
import { ProgressBarData } from '../../../common/progress_bar/ProgressBarView';
import { WprDI_Control } from '../../../../../wpr-framework/view/di/WprDI_Control';
import { WprControlInfo } from '../../../../../wpr-framework/view/control/WprControlInfo';
import { WprKeyValue } from '../../../../../wpr-framework/model/WprKeyValue';

/**
 * MVNO申込_ご利用者情報
 */
export class MvnoEntryCustomerView extends MvnoBaseView {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() { super('MvnoEntryCustomer'); }
	// --------------------------------------------------------------------------

	// DI情報  ------------------------------------------------------------------
	@WprDI_Control
	private m_reqDay1: WprControlInfo = null;	// 第一希望（月日）
	@WprDI_Control
	private m_reqDay2: WprControlInfo = null;	// 第二希望（月日）
	@WprDI_Control
	private m_reqDay3: WprControlInfo = null;	// 第三希望（月日）
	@WprDI_Control
	private m_birthdayDay: WprControlInfo = null;	// 生年月日_日
	// --------------------------------------------------------------------------

	// モデル  ------------------------------------------------------------------
	@WprDI_ScopeModel('MvnoEntryInfo')
	@WprModel('MvnoEntryInfo')
	private m_EntryInfo: MvnoEntryInfo = null;	// MVNOプラン情報
	// --------------------------------------------------------------------------
	// サブビュー/モード  ---------------------------------------------------------
	// --------------------------------------------------------------------------

	// サービス  ----------------------------------------------------------------
	private m_MvnoService: MvnoService = new MvnoService();	// MyPageRenewalサービス
	// --------------------------------------------------------------------------

	// サブビュー/モード  ---------------------------------------------------------
	@WprViewMode
	private m_EditMode = new EditMode();	// モード
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * コントロール定義設定
	 */
	public onRegisterConfig(): void {
		// 顧客情報
		this.addConfig('familyName', '姓', 'MvnoEntryInfo.entryInfo', { emptyValue: null, hissu: true });
		this.addConfig('firstName', '名', 'MvnoEntryInfo.entryInfo', { emptyValue: null, hissu: true });
		this.addConfig('familyNameKana', 'セイ', 'MvnoEntryInfo.entryInfo', { emptyValue: null, hissu: true, validation: '全角カナ' });
		this.addConfig('firstNameKana', 'メイ', 'MvnoEntryInfo.entryInfo', { emptyValue: null, hissu: true, validation: '全角カナ' });
		this.addConfig('relationShip', '続柄', 'MvnoEntryInfo.entryInfo', { emptyValue: null, hissu: true });
		// this.addConfig('birthday', '生年月日', 'MvnoEntryInfo.entryInfo', { emptyValue: null, hissu: true, converter: '日付（yyyy/MM/dd）', max: '9999-12-31' });
		this.addConfig('birthdayYear', '生年月日_月', 'MvnoEntryInfo.entryInfo', { emptyValue: null, hissu: true });
		this.addConfig('birthdayMonth', '生年月日_日', 'MvnoEntryInfo.entryInfo', { emptyValue: null, hissu: true });
		this.addConfig('birthdayDay', '生年月日_日', 'MvnoEntryInfo.entryInfo', { emptyValue: null, hissu: true });

		this.addConfig('phoneNumber1', '携帯電話番号', 'MvnoEntryInfo.entryInfo', { emptyValue: null, hissu: true, validation: '整数', maxLength: 11 });
		this.addConfig('address', '住所', 'MvnoEntryInfo.customerInfo');
		// 採番方法・MNP
		this.addConfig('numberingWay', '採番方法', 'MvnoEntryInfo.entryInfo', { emptyValue: null, hissu: true });
		this.addConfig('mnpReqNumber', 'MNP予約番号', 'MvnoEntryInfo.entryInfo', { emptyValue: null, hissu: true, minLength: 10, maxLength: 10, validation: '整数', action: 'next2' });
		this.addConfig('mnpReqExpire', 'MNP予約有効期限', 'MvnoEntryInfo.entryInfo', { emptyValue: null, hissu: true, converter: '日付（yyyy/MM/dd）', max: this.getMaxMnpDate(), min: this.getMinMnpDate(), action: 'next2' });
		this.addConfig('mnpUseNumber', 'MNP電話番号', 'MvnoEntryInfo.entryInfo', { emptyValue: null, hissu: true, minLength: 11, maxLength: 11, validation: '整数', action: 'next2' });
		// 希望日程
		this.addConfig('reqDay1', '第一希望（月日）', 'MvnoEntryInfo.entryInfo', { emptyValue: null, converter: '日付（yyyy/MM/dd）', hissu: true, max: this.getMaxReqDate(), min: this.getMinReqDate() });
		this.addConfig('reqTime1', '第一希望（時間帯）', 'MvnoEntryInfo.entryInfo', { emptyValue: null, hissu: true });
		this.addConfig('reqDay2', '第二希望（月日）', 'MvnoEntryInfo.entryInfo', { emptyValue: null, converter: '日付（yyyy/MM/dd）', max: this.getMaxReqDate(), min: this.getMinReqDate() });
		this.addConfig('reqTime2', '第二希望（時間帯）', 'MvnoEntryInfo.entryInfo', { emptyValue: null });
		this.addConfig('reqDay3', '第三希望（月日）', 'MvnoEntryInfo.entryInfo', { emptyValue: null, converter: '日付（yyyy/MM/dd）', max: this.getMaxReqDate(), min: this.getMinReqDate() });
		this.addConfig('reqTime3', '第三希望（時間帯）', 'MvnoEntryInfo.entryInfo', { emptyValue: null });

		// this.addConfig('sameReqTime', '希望（時間帯）', null);
		// その他
		this.addConfig('campaignCode', 'キャンペーンコード', 'MvnoEntryInfo.entryInfo', { emptyValue: null });
		
	}

	/**
	 * アクション登録
	 */
	public onRegisterAction(): void {
		this.addAction('next1', this.onNext, true);
		this.addAction('next2', this.onNext, true);
		this.addAction('back', this.onBack);
		this.addAction('aboutRelationShip', this.onShowHelpDialog);
		this.addAction('aboutNumbering', this.onShowHelpDialog);
		this.addAction('helpLink', this.onHelpLink);
		this.addAction('editWebFiltering', this.onWebFilteringLink);
		// this.addAction('tmpSave', this.onTmpSave);
	}

	/**
	 * ビュー表示通知
	 */
	public onShowView(): void {
		this.registAccessLog();
			this.setStorageItem('b_url', window.location.href)
		// this.getCookieValListForMvno(this.m_EntryInfo);
		const customerId: string = this.getCustomerId();
		// const customerId: string = '19901050190';
		this.getCookieValListForMvno(this.m_EntryInfo);
		// デフォルト値セット（生年月日の「年」のみ）
		if (ObjectUtil.isNullOrUndefined(this.m_EntryInfo.entryInfo.birthdayYear)) {
			this.m_EntryInfo.entryInfo.birthdayYear = '1990';
		}

		// 顧客情報取得
		if (!this.m_EntryInfo.customerInfo) {
			const req: GetCustomerRequest = new GetCustomerRequest();
			req.customerId = customerId;
			this.m_MvnoService.getCustomer(this, req, (result) => {
				this.m_EntryInfo.customerInfo = result.customerInfo;
				this.m_EntryInfo.campaignCodeList = result.campaignCodeList;
				
				this.setInit();
			});
		}
		else {
			this.setInit();
		}
	}

	/**
	 * 表示処理
	 */
	private setInit(): void {
		this.setViewMode('プラン', this.m_EntryInfo.entryInfo.plan);
		if (this.m_EntryInfo.entryInfo.plan === '音声プラン') {
			this.setViewMode('初期サポート', this.m_EntryInfo.entryInfo.initialSupportFlg ? '有' : '無');
			this.setViewMode('採番方法', this.m_EntryInfo.entryInfo.numberingWay === 'MNP' ? 'MNP' : 'MNPでない');
		}
		else {
			this.setViewMode('採番方法', 'MNPでない');
		}
		const sendData: ProgressBarData = new ProgressBarData();
		sendData.progressBarList = this.getProgressModelList();
		this.sendChildData('fP_ev_progressBarIntital', sendData);
		
		this.m_reqDay1.setMax(this.getMaxReqDate());
		this.m_reqDay2.setMax(this.getMaxReqDate());
		this.m_reqDay3.setMax(this.getMaxReqDate());
		this.refreshView();
	}

	/**
	 * 値変更通知
	 * @param name 名前
	 * @param value 値
	 * @returns falseの場合、変更を取り消す
	 */
	public onChangeValue(name: string, value: any): boolean {
		this.refreshModel();
		if (name === 'numberingWay') {
			this.setViewMode('採番方法', value === 'MNP' ? 'MNP' : 'MNPでない');

			this.m_reqDay1.setMax(this.getMaxReqDate());
			this.m_reqDay2.setMax(this.getMaxReqDate());
			this.m_reqDay3.setMax(this.getMaxReqDate());
		}
		else if (name === 'birthdayYear' || name === 'birthdayMonth') {
			this.m_birthdayDay.resetValueMap(false);
		}
		else if (name === 'mnpReqExpire') {
			// const mnpExpireDate: string = DateUtil.dateLengthEightFromStr(value, '-');
			// const maxMnp: string = DateUtil.convertToDateWithHyphen(DateUtil.getMinusDate(mnpExpireDate, 1));
			this.m_reqDay1.setMax(this.getMaxReqDate());
			this.m_reqDay2.setMax(this.getMaxReqDate());
			this.m_reqDay3.setMax(this.getMaxReqDate());
		}

		if (name.includes('birthday')) {
			const year: string = this.m_EntryInfo.entryInfo.birthdayYear;
			const month: string = ('00' + this.m_EntryInfo.entryInfo.birthdayMonth).slice(-2);
			const day: string = ('00' + this.m_EntryInfo.entryInfo.birthdayDay).slice(-2);
			this.m_EntryInfo.entryInfo.birthday = year + month + day;
		}
		this.setCookieValForMvno(this.m_EntryInfo);
		this.refreshView();
		return true;
	}

	/**
	 * ValueMap再設定処理
	 * @param name コントロール名
	 * @param id ValueMapID
	 * @list ValueMapのKey-Valueリスト
	 * @returns 再設定したKey-Valueリスト
	 */
	public onResetValueMap(name: string, id: string, list: WprKeyValue[]): WprKeyValue[] {
		if (id === 'day') {
			if (ObjectUtil.isNotNullOrUndefined(this.m_EntryInfo.entryInfo.birthdayMonth)) {
				list = this.getDateValueMapList(list);

				// もし選択していた日付が、新しい日付VMの選択肢になかった場合、「1」で設定し直し
				if (ObjectUtil.isNotNullOrUndefined(this.m_EntryInfo.entryInfo.birthdayDay)) {
					const selected = list.filter(dayVm => {
						return (dayVm.key === this.m_EntryInfo.entryInfo.birthdayDay);
					});
					if (selected.length == 0) {
						this.m_EntryInfo.entryInfo.birthdayDay = list[list.length - 1]?.value;
						const year: string = this.m_EntryInfo.entryInfo.birthdayYear;
						const month: string = ('00' + this.m_EntryInfo.entryInfo.birthdayMonth).slice(-2);
						const day: string = ('00' + this.m_EntryInfo.entryInfo.birthdayDay).slice(-2);
						this.m_EntryInfo.entryInfo.birthday = year + month + day;
						this.refreshView();
					}
				}
			}

		}
		return list;
	}

	/**
	 * 入力チェック（独自のValidationを実施する場合に使用する）
	 *   エラー追加例：validError.addError('エラーメッセージ');		// 相関チェックなどのエラー
	 * 				  this.setControlError('control', 'error');		// コントロールのエラー
	 * 		※コントロールの値をチェックする場合は、refreshModelは使用しないでください。
	 * 		　コントロールの値をチェックする場合は、コントロールをDIしてvalueを使用してください。
	 * @param actionName アクション名
	 * @param validError バリデーションエラー情報
	 * @param row 行情報
	 */
	protected onValid(actionName: string, validError: WprValidError, row?: any): void {
		const entryInfo: HMvnoTmpSaved = this.m_EntryInfo.entryInfo;
		if (actionName == 'next1' || actionName == 'next2') {
			const isOnsei: boolean = this.getViewMode('プラン') === '音声プラン';
			if (isOnsei && this.isUnder18()) {
				if (!entryInfo.webFilteringFlg) {
					validError.addError('18歳未満の場合、WEBフィルタリングのお申込みが原則必須となります。', 'under18WebFilteringError');
				}
			}
			// 初期設定サポート：必要　時のみ
			if (this.m_EntryInfo.entryInfo.initialSupportFlg == true) {
				// 
				const max: string = DateUtil.dateLengthEightFromStr(this.getMaxReqDate(), '-');
				const min: string = DateUtil.dateLengthEightFromStr(this.getMinReqDate(), '-');
				if (max < entryInfo.reqDay1 || max < entryInfo.reqDay2 || max < entryInfo.reqDay3) {
					validError.addError('MNP有効期限当日以降の日付は選択できません。別の日時を指定してください。', 'mnpMaxError');
				}
				if ((entryInfo.reqDay1 != null && min > entryInfo.reqDay1)
					|| (entryInfo.reqDay2 != null && min > entryInfo.reqDay2)
					|| (entryInfo.reqDay3 != null && min > entryInfo.reqDay3)) {
					validError.addError('本日より7日以降の日付を指定してください。', 'mnpMaxError');
				}

				const reqTimeHissuErrorFlg1: boolean = !this.isNotEmpty(entryInfo.reqDay1, entryInfo.reqTime1);
				const reqTimeHissuErrorFlg2: boolean = !this.isNotEmpty(entryInfo.reqDay2, entryInfo.reqTime2);
				const reqTimeHissuErrorFlg3: boolean = !this.isNotEmpty(entryInfo.reqDay3, entryInfo.reqTime3);
				if (reqTimeHissuErrorFlg1 || reqTimeHissuErrorFlg2 || reqTimeHissuErrorFlg3) {
					const itemStr: string = this.setErrorReqDateItem([{ flag: reqTimeHissuErrorFlg1, dispVal: '第一希望' }, { flag: reqTimeHissuErrorFlg2, dispVal: '第二希望' }, { flag: reqTimeHissuErrorFlg3, dispVal: '第三希望' },]);
					validError.addError(itemStr + '：日付、時間帯のいずれかが入力されていません。再度入力してください。', 'sameReqDateError');
				}
				else {
					const reqTime1: string = entryInfo.reqDay1 + entryInfo.reqTime1;
					const reqTime2: string = entryInfo.reqDay2 + entryInfo.reqTime2;
					const reqTime3: string = entryInfo.reqDay3 + entryInfo.reqTime3;
					let reqTimeErrorFlg1: boolean = false;
					let reqTimeErrorFlg2: boolean = false;
					let reqTimeErrorFlg3: boolean = false;
					if (ObjectUtil.isNotNullOrUndefined(entryInfo.reqDay1) && ObjectUtil.isNotNullOrUndefined(entryInfo.reqDay2) && reqTime1 === reqTime2) reqTimeErrorFlg1 = true;
					if (ObjectUtil.isNotNullOrUndefined(entryInfo.reqDay1) && ObjectUtil.isNotNullOrUndefined(entryInfo.reqDay3) && reqTime1 === reqTime3) reqTimeErrorFlg2 = true;
					if (ObjectUtil.isNotNullOrUndefined(entryInfo.reqDay2) && ObjectUtil.isNotNullOrUndefined(entryInfo.reqDay3) && reqTime2 === reqTime3) reqTimeErrorFlg3 = true;

					if (reqTimeErrorFlg1 || reqTimeErrorFlg2 || reqTimeErrorFlg3) {
						let itemStr: string;
						if (reqTimeErrorFlg1 === true && reqTimeErrorFlg3 == true) {
							itemStr = '第一希望と第二希望と第三希望';
						}
						else {
							itemStr = this.setErrorReqDateItem([{ flag: reqTimeErrorFlg1, dispVal: '第一希望と第二希望' }, { flag: reqTimeErrorFlg2, dispVal: '第一希望と第三希望' }, { flag: reqTimeErrorFlg3, dispVal: '第二希望と第三希望' },]);
						}
						validError.addError(itemStr + '：同じ希望日時が入力されています。別の日時を指定してください。', 'sameReqDateError');
					}

				}
			}

			if (ObjectUtil.isNotNullOrEmptyStr(entryInfo.campaignCode)) {
				// const campaignCode: string = this.m_EntryInfo.campaignCode.generalCd;
				// if (campaignCode !== entryInfo.campaignCode) {
				// 	validError.addError('キャンペーンコードに誤りがあります。再度入力してください。', 'campaignCodeError');
				// }
				// #61758 新キャンペーン追加
				let campaignFlg : boolean = false;
				if (ObjectUtil.isNotNullOrEmptyArray(this.m_EntryInfo.campaignCodeList)) {
					this.m_EntryInfo.campaignCodeList.forEach((value) => {
						if (entryInfo.campaignCode=== value.generalCd) {
							campaignFlg = true;
						}
					});
				}
				if (campaignFlg == false) {
					validError.addError('キャンペーンコードに誤りがあります。再度入力してください。', 'campaignCodeError');
				}
			}
		}
		if (ObjectUtil.isNotNullOrUndefined(validError.errorInfoList)) {
			let elements = document.getElementsByName(validError.errorInfoList[0].controlName);
			window.scroll({top: elements[0].getBoundingClientRect().top + window.pageYOffset - 50, behavior: 'smooth'});
			console.log(elements[0].getBoundingClientRect());
		}
	}

	/**
	 * エラー
	 */
	private setErrorReqDateItem(list: { flag: boolean, dispVal: string }[]): string {
		let itemList: string[] = new Array();
		list.forEach(val => {
			if (val.flag) itemList.push(val.dispVal);
		});
		let itemStr: string = '';
		for (let item of itemList) {
			if (itemStr != '') itemStr += '、';
			itemStr += item;
		}
		return itemStr;
	}

	private isNotEmpty(reqTime: string, reqDate: string): boolean {
		const valFlg1 = ObjectUtil.isNullOrEmptyStr(reqTime);
		const valFlg2 = ObjectUtil.isNullOrEmptyStr(reqDate);
		return (valFlg1 === valFlg2);
		// for (let val of valList) {
		// 	if (ObjectUtil.isNullOrEmptyStr(val)) {
		// 		return false;
		// 	}
		// }
		// return true;
	}

	/**
	 * 未成年かどうかの判定
	 * @returns 
	 */
	private isUnder18(): boolean {
		const year: string = this.m_EntryInfo.entryInfo.birthdayYear;
		const month: string = ('00' + this.m_EntryInfo.entryInfo.birthdayMonth).slice(-2);
		const day: string = ('00' + this.m_EntryInfo.entryInfo.birthdayDay).slice(-2);
		const birthday = year + month + day;
		const age: number = DateUtil.getAgeFromStr(birthday);
		return (age < 18);
	}

	/**
	 * バリデーションエラーメッセージ通知
	 * @param name コントロール名
	 * @param rowIndex 行数(Listの場合)
	 * @param validatorName バリデーション名
	 * @param msg エラーメッセージ
	 * @returns エラーメッセージ（変更する場合に指定する、メッセージを使用しない場合は、nullを返す）
	 */
	public onInvalidMessage(name: string, rowIndex: number, validatorName: string, msg: string): string {
		if (name === 'mnpReqNumber') {
			if (validatorName === '最小文字数' || validatorName === '最大文字数') {
				return '10桁で入力してください。';
			}
		}
		else if (name === 'mnpUseNumber') {
			if (validatorName === '最小文字数' || validatorName === '最大文字数') {
				return '11桁で入力してください。';
			}
		}
		return msg;
	}


	/**
	 * データ受信処理
	 * @param dataName データ名
	 * @param data データ
	 */
	public onReceiveData(dataName: string, data?: any): void {
		if (dataName === 'fC_ev_料金明細表示') {
			this.refreshModel();
			this.sendChildFortempSave();
		}
	}
	// --------------------------------------------------------------------------

	// アクション  ---------------------------------------------------------------
	/**
	 * 次へ
	 */
	public onNext(param: any): void {
		// // this.addUserLog('aaaaaaaa');
		const year: string = this.m_EntryInfo.entryInfo.birthdayYear;
		const month: string = ('00' + this.m_EntryInfo.entryInfo.birthdayMonth).slice(-2);
		const day: string = ('00' + this.m_EntryInfo.entryInfo.birthdayDay).slice(-2);
		this.m_EntryInfo.entryInfo.birthday = year + month + day;
		this.setCookieValForMvno(this.m_EntryInfo);
		this.pushPath('/mvno_006');
	}

	/**
	 * 戻る
	 */
	public onBack(param: any): void {
		this.pushPath('/mvno_004');
	}

	/**
	 * ヘルプページ表示
	 */
	public onShowHelpDialog(param: any): void {
		const sendData: MvnoHelpDialogData = new MvnoHelpDialogData();
		if (param === 'aboutRelationShip') {
			sendData.title = '続柄について';
		}
		else if (param === 'aboutNumbering') {
			sendData.title = '採番方法について';
		}
		this.showDialog('MVNO_ヘルプダイアログ', sendData);
	}


	/**
	 * 問い合わせリンク
	 */
	public onHelpLink(param: any): void {
		window.open(MypageLinkConstatns.ITSCOM_MVNOENTRY_HELP);
	}

	/**
	 * WEBフィルタリング
	 */
	public onWebFilteringLink(param: any): void {
		this.pushPath('/mvno_004');
	}
	// --------------------------------------------------------------------------

	// private ------------------------------------------------------------------

	/**
	 * MNP日付　MAX
	 * @returns 
	 */
	private getMaxMnpDate(): string {
		// todayから15日まで（15日を含む）がOK
		const today: string = DateUtil.getTodayStr();
		const max: string = DateUtil.getPlusDate(today, 14);
		return DateUtil.convertToDateWithHyphen(max);
	}

	/**
	 * MNO日付　MIN
	 * @returns 
	 */
	private getMinMnpDate(): string {
		// todayから9日以降がOK
		const today: string = DateUtil.getTodayStr();
		const max: string = DateUtil.getPlusDate(today, 8);
		return DateUtil.convertToDateWithHyphen(max);
	}

	/**
	 * 希望日時　MAX
	 * @returns 
	 */
	private getMaxReqDate(): string {
		// MNPである場合：MNO有効期限の前日までがOK（MNP有効期限当日はNG）
		// MNPでない場合：期限なし
		const max = '99991231';
		if (this.m_EntryInfo.entryInfo.numberingWay === 'MNP') {
			const mnpExpireDate = this.m_EntryInfo.entryInfo.mnpReqExpire;
			if (ObjectUtil.isNotNullOrEmptyStr(mnpExpireDate)) {
				const maxMnp: string = DateUtil.getMinusDate(mnpExpireDate, 1);
				return DateUtil.convertToDateWithHyphen(maxMnp);
			}
			else {
				return DateUtil.convertToDateWithHyphen(max);
			}
		}
		else {
			return DateUtil.convertToDateWithHyphen(max);
		}

	}

	/**
	 * 希望日時　MIN
	 * @returns 
	 */
	private getMinReqDate(): string {
		// todayから8日以降がOK
		const today: string = DateUtil.getTodayStr();
		const max: string = DateUtil.getPlusDate(today, 7);
		return DateUtil.convertToDateWithHyphen(max);
	}

	/**
	 * ステップバー情報セット
	 * @returns 
	 */
	private getProgressModelList(): ProgressBarModel[] {
		const list: ProgressBarModel[] = new Array();

		list.push(new ProgressBarModel('1', 'お申し込み手続き', 'complete', this.getStopModelList()));
		list.push(new ProgressBarModel('2', '合計金額ご確認', 'next'));
		list.push(new ProgressBarModel('3', '事前確認事項', 'not'));
		list.push(new ProgressBarModel('4', 'WEB本人確認', 'not'));
		list.push(new ProgressBarModel('5', 'お申込み完了', 'not'));
		return list;
	}

	/**
	 * ステップバー情報セット　：1-2の間の線の部分
	 * @returns 
	 */
	private getStopModelList(): ProgressBarStopModel[] {
		const list: ProgressBarStopModel[] = new Array();
		list.push(new ProgressBarStopModel('complete'));
		list.push(new ProgressBarStopModel('complete'));
		list.push(new ProgressBarStopModel('complete'));
		list.push(new ProgressBarStopModel('current'));
		return list;
	}

	/**
	  * 生年月日バリューマップ変更
	  * @returns 再設定したKey-Valueリスト
	  */
	private getDateValueMapList(list: WprKeyValue[]): WprKeyValue[] {
		// let today = new Date();
		// let today = new Date(this.m_AuthMailInfo.applicationDate);
		// let thisYear = today.getFullYear();
		// if (yearValue)
		// 	thisYear = yearValue;
		// let thisMonth = today.getMonth() + 1;
		// if (monthValue)
		// 	thisMonth = monthValue;
		const yearValue = this.m_EntryInfo.entryInfo.birthdayYear;
		const monthValue = ObjectUtil.isNotNullOrUndefined(this.m_EntryInfo.entryInfo.birthdayMonth) ? this.m_EntryInfo.entryInfo.birthdayMonth : 1;
		let isLeapYear = (year: number) => (year % 4 === 0) && (year % 100 !== 0) || (year % 400 === 0);
		let datesOfFebruary = isLeapYear(Number(yearValue)) ? 29 : 28;
		let datesOfYear = [31, datesOfFebruary, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
		const newList = new Array();
		for (let i = 0; i < datesOfYear[Number(monthValue) - 1]; i++) {
			newList.push(list[i]);
		}
		return newList;
	}
	// --------------------------------------------------------------------------

}
