import React, { ReactNode } from 'react';
import { WprBaseViewComponent } from '../../../../../wpr-framework/view/component/WprBaseViewComponent';
import { TvopCompleteView } from './TvopCompleteView';
import { Divider, Typography } from '@material-ui/core';
import { WprAnchor } from '../../../../../wpr-framework/component/controls/WprAnchor';
import { WprSpan } from '../../../../../wpr-framework/component/controls/WprSpan';
import { MatButton } from '../../../../../wpr-framework/component/controls/material/MatButton';
import { MatTypography } from '../../../../../wpr-framework/component/controls/material/MatTypography';
import questionnaireIcon from '/shared/image/common/button_questionnaire_on.png';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import styles from './TvopComplete.scss';
import { WprContainer } from '../../../../../wpr-framework/component/controls/WprContainer';
import importantIcon from '/shared/image/common/important_purple.png';

/**
 * テレビオプション完了用コンポーネント
 */
export class TvopComplete extends WprBaseViewComponent {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: any) { super(new TvopCompleteView(), props); }
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** テレビオプション完了ビュー情報 */
	public get viewInfo(): TvopCompleteView	{ return this.view as TvopCompleteView;	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * スタイルオブジェクト取得
	*/
	public getStylesObject(): any { return styles; }

	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		return (
			<>
			<div>
				<div className='pageCard'>
					<div className='main'>
						<div className='title_center'>
							<MatTypography className='title_complete' name={'title_complete'} view={this.view}/>
						</div>
						<div className='title_normal'>
							<MatTypography name={'user'} view={this.view} className='title_border'/>
						</div>
						<div className='title_normal' style={{ textAlign: 'center', margin: '2rem' }}>
							<WprSpan name={'msg1'} view={this.view} className='title_msg'></WprSpan>
						</div>
						<div className='title_normal' style={{ textAlign: 'center', margin: '2rem' }}>
							<MatTypography name={'msg2'} view={this.view} className='title_msg'/>
						</div>
						<div className='title_normal' style={{ textAlign: 'center', margin: '2rem' }}>
							<MatTypography name={'msg3'} view={this.view} className='title_msg'/>
						</div>
						<WprContainer mode={'詳細表示.申込み'} view={this.view}>
							<div className='blueArea' style={{marginTop: '50px'}}>
								<div className='header'>
									お申込み内容
								</div>
								<div className='body' style={{justifyContent: 'space-between'}}>
									<div>
										<MatTypography name='option' className='itemUnit' view={this.view} style={{display: 'inline'}}/>
										<MatTypography name='channel' className='itemUnit' view={this.view} style={{display: 'inline'}}/>
									</div>
									<div style={{display: 'flex'}}>
										<span className='feeUnit' style={{fontSize: '13px'}}>月額：</span>
										<MatTypography name='amount' className='itemUnit' view={this.view} style={{fontSize: '20px'}}/>
										<span className='feeUnit' style={{fontSize: '13px'}}>円（税込）</span>
									</div>
								</div>
							</div>
							<div className='blueArea'>
								<div className='header'>
									契約開始日
								</div>
								<div className='body'>
									<MatTypography name='applyDate' className='itemUnit' view={this.view} style={{display: 'inline'}}/>
								</div>
							</div>
						</WprContainer>
						<WprContainer mode={'詳細表示.解約'} view={this.view}>
							<div className='blueArea' style={{marginTop: '50px'}}>
								<div className='header'>
									解約内容
								</div>
								<div className='body' style={{justifyContent: 'space-between'}}>
									<div>
										<MatTypography name='option' className='itemUnit' view={this.view} style={{display: 'inline'}}/>
										<MatTypography name='channel' className='itemUnit' view={this.view} style={{display: 'inline'}}/>
									</div>
									<div style={{display: 'flex'}}>
										<span className='feeUnit' style={{fontSize: '13px'}}>月額：</span>
										<MatTypography name='amount' className='itemUnit' view={this.view} style={{fontSize: '20px'}}/>
										<span className='feeUnit' style={{fontSize: '13px'}}>円（税込）</span>
									</div>
								</div>
							</div>
						</WprContainer>
						<WprContainer mode={'help表示.申込み'} view={this.view}>
							<div className='comment'>
								<div className='header'>
									オプションチャンネル視聴開始をするためには？-視聴開始信号の受信-
								</div>
								<div className='body'>
									<Typography>オプションチャンネルは、各放送事業者からの信号をSTBが受信することで視聴可能となります。そのため、オプションをお申込み後、次の手順が必要となります。</Typography>
									<ul>
										<li>最初に視聴される際に、STBの追加したオプションチャンネルにあわせ、30分ほど待機してください。</li>
										<li>正常な信号の受信が完了すると、視聴が可能となります。</li>
									</ul>
									<Typography>上記手順を行っても視聴できない場合は<a href='https://faq.itscom.net/faq/show/487?site_domain=default' target = '_blank'>こちら</a> (WOWOW/スターチャンネルは<a href='https://faq.itscom.net/faq/show/127?site_domain=default' target = '_blank'>こちら</a>)にご連絡ください。</Typography>
								</div>
							</div>
						</WprContainer>
						<WprContainer mode={'help表示.仮申込み'} view={this.view}>
							<div className='comment'>
								<div className='header'>
									スター・チャンネルハイビジョンを視聴するには？
								</div>
								<div className='body'>
									<Typography>視聴開始日の午後7時以降、最初に視聴される際に、STBのチャンネルを「BS200ch」にあわせ、30分ほど待機してください。</Typography>
									<ul>
										<li>STBのチャンネルを「BS200ch」にあわせ、30分ほど待機してください。</li>
										<li>その間に放送事業者からのデータをSTBが受信し、視聴が可能となります。</li>
									</ul>
								</div>
							</div>
						</WprContainer>
						<WprContainer mode={'help表示.解約'} view={this.view}>
							<div className='gray'>
								<div className='flex'>
									<img src= {importantIcon} style={{width: '15px'}}></img>
									<Typography className='title'>解約手続き後のご視聴に関して</Typography> 
								</div>
								<div>
									今月末まではご視聴なれます。
								</div>
							</div>
						</WprContainer>
						<div className='questionnaire'>
							<WprAnchor name='toQuestionnaire' view={this.view}  >
								<img src={questionnaireIcon} style={{maxWidth: '100%'}} />
							</WprAnchor>
						</div>
					</div>
					<Divider />
					<div className='pageFooter footer-space'>
						<div>
							<MatButton name='next' view={this.view} className='orangeButton orangeButton_parts selectButton' >
								更に変更する
								<NavigateNextIcon />
							</MatButton>
						</div>
						<div>
							<MatButton name='back' view={this.view} className='completeButton backContInfoList' >
								<NavigateBeforeIcon />
								<span className='screen'>契約内容の確認・変更メニューへ</span>
							</MatButton>
						</div>
					</div>

				</div>
			</div>
			</>
		);
	}
	// --------------------------------------------------------------------------
}
