import { WprBaseViewCore } from '../view/WprBaseViewCore';
import { WprBaseViewModeInfo } from './WprBaseViewMode';

/**
 * ViewModeビューモード基本情報
 */
 export abstract class WprBaseViewModeModeInfo<T extends WprBaseViewCore> extends WprBaseViewModeInfo<T> {
	// private 変数  ------------------------------------------------------------
	private m_ViewModeName: string	= null;			// ViewMode名
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	public constructor(name: string, viewMode: string) {
		super(name);
		this.m_ViewModeName = viewMode;
	}
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** ViewMode名 */
	public get ViewModeName(): string	{ return this.m_ViewModeName;	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * モードリスト取得（この画面モードで使用する全てのモードを定義する）
	 * @param list モードリスト
	 */
	public useModeList(list: string[]): void {
	}
	
	/**
	 * 画面の状態をチェックしてモードを変更する
	 * @param curMode 現在のモード
	 * @return 新しいモード（変更ない場合は現在のモード）
	 */
	public onCheckMode(curMode: string): string {
		return this.view.getViewMode(this.m_ViewModeName);
	}
	// --------------------------------------------------------------------------
}
