import { WprBaseObject } from '../../WprBaseObject';
import { WprFramework } from '../../WprFramework';
import { WprBaseConverter } from '../convert/WprBaseConverter';
import { WprBaseViewCore } from '../WprBaseViewCore';
import { IWprConfigOption } from './IWprConfigOption';

/**
 * コントロール定義オプション
 */
export class WprControlConfig extends WprBaseObject {
	// private 変数  ------------------------------------------------------------
	private m_ViewCore: WprBaseViewCore		= null;				// View情報
	private m_Name: string					= null;				// コントロール名
	private m_Min: number | string			= undefined;		// 最小値
	private m_Max: number | string			= undefined;		// 最大値
	private m_MinLength: number				= undefined;		// 最小文字数
	private m_MaxLength: number				= undefined;		// 最大文字数
	private m_UseChangeEvent: boolean		= true;				// 値変更イベント使用フラグ
	private m_CategoryList: string[]		= null;				// カテゴリリスト
	private m_NotClear: boolean				= false;			// 値クリアフラグ
	private m_ActionList: string[]			= null;				// 入力チェック対象アクションリスト
	private m_Converter: WprBaseConverter	= null;				// コンバータ
	private m_DispAuthList: string[]		= null;				// 表示権限リスト
	private m_EnableAuthList: string[]		= null;				// 活性化権限リスト
	private m_EditClass?: string			= null;				// 編集時クラス名
	private m_NoEditClass?: string			= null;				// 未編集時クラス名
	private m_ErrorClass?: string			= null;				// 入力エラー時クラス名
	private m_ReadOnlyClass?: string		= null;				// 読取専用時クラス名
	private m_DisabledClass?: string		= null;				// 非活性時クラス名
	private m_EnableMode?: string			= null;				// 活性化モード
	private m_EmptyValue?: any				= undefined;		// 空の場合に設定する値(省略した場合は、''を設定する)
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** 最小文字数 */
	public get min(): number | string			{ return this.m_Min; 			}
	/** 最大文字数 */
	public get max(): number | string			{ return this.m_Max; 			}
	/** 最小文字数 */
	public get minLength(): number				{ return this.m_MinLength; 		}
	/** 最大文字数 */
	public get maxLength(): number				{ return this.m_MaxLength; 		}
	/** 値変更イベント使用フラグ */
	public get useChangeEvent(): boolean		{ return this.m_UseChangeEvent; }
	/** カテゴリリスト */
	public get categoryList(): string[]			{ return this.m_CategoryList; 	}
	/** 値クリアフラグ */
	public get notClear(): boolean				{ return this.m_NotClear; 		}
	/** 入力チェック対象アクション */
	public get actionList(): string[]			{ return this.m_ActionList; 	}
	/** コンバータ */
	public get converter(): WprBaseConverter	{ return this.m_Converter; 		}
	/** 表示権限リスト */
	public get dispAuthList(): string[]			{ return this.m_DispAuthList; 	}
	/** 活性化権限リスト */
	public get enableAuthList(): string[]		{ return this.m_EnableAuthList; }
	/** 編集時クラス名 */
	public get editClass(): string				{ return this.m_EditClass; 		}
	/** 未編集時クラス名 */
	public get noEditClass(): string			{ return this.m_NoEditClass; 	}
	/** 入力エラー時クラス名 */
	public get errorClass(): string				{ return this.m_ErrorClass; 	}
	/** 読取専用時クラス名 */
	public get readOnlyClass(): string			{ return this.m_ReadOnlyClass; 	}
	/** 非活性時クラス名 */
	public get disabledClass(): string			{ return this.m_DisabledClass; 	}
	/** 活性化モード */
	public get enableMode(): string				{ return this.m_EnableMode; 	}
	/** 空の場合に設定する値 */
	public get emptyValue(): any				{ return this.m_EmptyValue; 	}
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	public constructor(name: string, viewCore: WprBaseViewCore, config?: IWprConfigOption) {
		super();
		this.m_Name = name;
		this.m_ViewCore = viewCore;
		if (config) {
			if (config.min)
				this.m_Min = config.min;
			if (config.max)
				this.m_Max = config.max;
			if (config.minLength)
				this.m_MinLength = config.minLength;
			if (config.maxLength)
				this.m_MaxLength = config.maxLength;
			if (config.changeEvent)
				this.m_UseChangeEvent = config.changeEvent;
			if (config.category)
				this.m_CategoryList = this.getCategoryList(config.category);
			if (config.notClear)
				this.m_NotClear = config.notClear;
			if (config.action)
				this.m_ActionList = this.getActionList(config.action);
			if (config.converter)
				this.m_Converter = WprFramework.getInstance().view.getConverter(config.converter);
			if (config.dispAuth)
				this.m_DispAuthList = this.getAuthList(config.dispAuth);
			if (config.enableAuth)
				this.m_EnableAuthList = this.getAuthList(config.enableAuth);
			if (config.editClass)
				this.m_EditClass = config.editClass;
			if (config.noEditClass)
				this.m_NoEditClass = config.noEditClass;
			if (config.errorClass)
				this.m_ErrorClass = config.errorClass;
			if (config.readOnlyClass)
				this.m_ReadOnlyClass = config.readOnlyClass;
			if (config.disabledClass)
				this.m_DisabledClass = config.disabledClass;
			if (config.enableMode)
				this.m_EnableMode = config.enableMode;
			if (config.emptyValue !== undefined)
				this.m_EmptyValue = config.emptyValue;
		}
	}
	// --------------------------------------------------------------------------

	// public メソッド  ---------------------------------------------------------
	/**
	 * アクション名リスト取得
	 * @param action アクション名
	 * @returns アクション名リスト
	 */
	private getActionList(action: string): string[] {
		const list = new Array();
		if (action.includes(',') === false) {
			list.push(action);
			/*
			if (this.m_ViewCore.viewInfo.checkAction(action) === true)
				list.push(action);
			else
				this.m_ViewCore.addErrorLog(`コントロールのActionの指定に誤りがあります。[${this.m_Name}][${action}}]`);
			*/
		}
		else {
			action.split(',').forEach(aname => {
				list.push(aname.trim());
				/*
				if (this.m_ViewCore.viewInfo.checkAction(aname.trim()) === true)
					list.push(aname.trim());
				else
					this.m_ViewCore.addErrorLog(`コントロールのActionの指定に誤りがあります。[${this.m_Name}][${aname}}]`);
				*/
			});
		}
		return list;
	}

	/**
	 * 権限名リスト取得
	 * @param authority 権限名
	 * @returns 権限名リスト
	 */
	private getAuthList(authority: string): string[] {
		const list = new Array();
		if (authority.includes(',') === false) {
			if (this.FW.auth.checkAuthority(authority) === true)
				list.push(authority);
			else
				this.m_ViewCore.addErrorLog(`コントロールの権限の指定に誤りがあります。[${this.m_Name}][${authority}]`);
		}
		else {
			authority.split(',').forEach(aname => {
			if (this.FW.auth.checkAuthority(aname.trim()) === true)
				list.push(aname.trim());
			else
				this.m_ViewCore.addErrorLog(`コントロールの権限の指定に誤りがあります。[${this.m_Name}][${aname}]`);
			});
		}
		return list;
	}

	/**
	 * カテゴリリスト取得
	 * @param category カテゴリ
	 * @returns カテゴリリスト
	 */
	private getCategoryList(category: string): string[] {
		const list = new Array();
		if (category.includes(',') === false) {
			list.push(category.trim());
		}
		else {
			category.split(',').forEach(cname => {
				list.push(cname.trim());
			});
		}
		return list;
	}
	// --------------------------------------------------------------------------
}
