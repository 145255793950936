import { WprActionInfo } from '../../action/WprActionInfo';
import { WprControlInfo } from '../../view/control/WprControlInfo';
import { WprFramework } from '../../WprFramework';
import { IWprControlProps } from '../props/IWprControlProps';
import { WprBaseControlComponent } from './WprBaseControlComponent';

/**
 * コントロール基本コンポーネント
 */
export abstract class WprBaseInputControlComponent<T extends IWprControlProps, U> extends WprBaseControlComponent<T, U> {
	// private 変数  ------------------------------------------------------------
	private m_ActionInfo: WprActionInfo		= null;		// アクション情報
	private m_IsComposing: boolean			= false;	// 日本語入力中フラグ
	private m_SaveVale: string	 			= null;		// 日本語入力前値
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	constructor(props: T) {
		super(props);
		if (props.row)
			this.m_ActionInfo = props.view.getBlurActionInfo(props.name, props.row.listName);
		else
			this.m_ActionInfo = props.view.getBlurActionInfo(props.name);
		if (this.m_ActionInfo != null) {
			this.setFocusEvent(this.onFocus.bind(this));
			this.setBlureEvent(this.onBlur.bind(this));
		}
		this.setComposition(this.onCompositionStart.bind(this), this.onCompositionEnd.bind(this));
	}
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** 日本語入力中フラグ */
	public get isComposing(): boolean 	{ return this.m_IsComposing;	}
	// --------------------------------------------------------------------------

	// イベント  ----------------------------------------------------------------
	/**
	 * フォーカスOUT処理
	 * @param event フォーカスOUTイベント
	 */
	public onBlur(event: React.ChangeEvent<U>): void {
		if (this.m_ActionInfo != null) {
			if (this.props.row)
				this.m_ActionInfo.execute(false, event, this.props.row);
			else
				this.m_ActionInfo.execute(false, event);
		}
	}

	/**
	 * 日本語入力開始イベント処理
	 * @param event 変更イベント 
	 */
	public onCompositionStart(event: React.CompositionEvent<HTMLInputElement>): void {
		this.addInfoLog(`### 日本語入力開始 ### : ${event.type}`);
		this.m_IsComposing	= true;
		this.m_SaveVale = this.getValue();
	}

	/**
	 * 日本語入力終了イベント処理
	 * @param event 変更イベント 
	 */
	public onCompositionEnd(event: React.CompositionEvent<HTMLInputElement>): void {
		this.addInfoLog('### 日本語入力終了 ###');
		this.m_IsComposing	= false;
		const value = this.getValue();
		if (this.controlInfo != null) {
			if (this.controlInfo.useInputCheck() === true) {
				if (this.controlInfo.checkInput(value) === false) {
					setTimeout(() => {
						this.controlInfo.value = this.m_SaveVale;
					}, 10);
					return;
				}
			}
			this.changeValue(value);
		}
	}

	/**
	 * キーダウンイベント
	 * @param event キーイベント情報
	 */
	public onKeyDown(event: React.KeyboardEvent<HTMLInputElement>): void {
		this.addInfoLog(`key = ${event.key}`);
		if (this.controlInfo == null || this.controlInfo.useInputCheck() == false)
			return;
		if (event.ctrlKey === true && event.key === 'v') {
			if (this.state.readOnly === false && this.state.disabled === false) {
				if (!navigator.clipboard) {
				}
				else {
					event.preventDefault();
					navigator.clipboard.readText().then(text => {
						const val = this.getValue();
						const after = this.getAfterValue(val, text);
						const spos = this.getStartPos();
						// this.addErrorLog(`after = ${after}`)
						if (this.controlInfo.checkLength(after) === true && this.controlInfo.checkInput(after) === true) {
							this.controlInfo.value = after;
							const pos = spos + text.length;
							this.ref.current.selectionStart = pos;
							this.ref.current.selectionEnd = pos;
						}
					})
					.catch(err => {
						console.log('失敗: ' + err);
					});
				}
			}
		}
		else if (event.key.length == 1 && event.ctrlKey === false && event.altKey === false) {
			const val = this.getValue();
			const after = this.getAfterValue(val, event.key);
			if (this.controlInfo.checkInput(after) === false)
				event.preventDefault();
		}
	}
	// --------------------------------------------------------------------------

	// protected メソッド  ------------------------------------------------------
	/**
	 * キーダウンイベント設定
	 */
	protected setKeyEvent(): void {
		this.setKeyDownEvent(this.onKeyDown.bind(this));
	}

	/**
	 * 値変更
	 * @param value 変更値
	 */
	protected changeValue(value: any): void {
		WprControlInfo.isChangeValue = true;
		try {
			WprFramework.getInstance().view.setScopeUpdateProperty();
			if (this.isComposing === false) {
				this.controlInfo.dirty = true;
				if (this.props.row) {
					if (this.controlInfo.changeListValue(value, this.props.row) === false)
						return;
				}
				else {
					if (this.controlInfo.changeValue(value) === false)
						return;
				}
			}
			WprControlInfo.isChangeValue = true;
			this.controlInfo.value = value;
			this.controlInfo.viewCore.viewInfo.checkScopeModelValue();
		}
		finally {
			WprControlInfo.isChangeValue = false;
		}
	}
	// --------------------------------------------------------------------------

	// private メソッド  --------------------------------------------------------
	/**
	 * 編集後文字列取得
	 * @param val 値
	 * @param text テキスト
	 * @returns 編集後文字列
	 */
	 private getAfterValue(val: any, text: string) {
		let start = this.ref.current.selectionStart;
		let end = this.ref.current.selectionEnd;
		if (start > end) {
			start = this.ref.current.selectionEnd;
			end = this.ref.current.selectionStart;
		}
		if (isNaN(val) === false)
			val = String(val);
		return val.substring(0, start) + text + val.substring(end, val.length);
	}

	/**
	 * 開始位置取得
	 * @returns 開始位置
	 */
	private getStartPos(): number {
		let start = this.ref.current.selectionStart;
		let end = this.ref.current.selectionEnd;
		if (start > end)
			return end;
		return start;
	}
	// --------------------------------------------------------------------------
}