import { WprBaseViewListMultiModeInfo } from '../../../../../../wpr-framework/mode/WprBaseViewListMultiModeInfo';
import { WprMultiMode } from '../../../../../../wpr-framework/mode/WprMultiMode';
import { WprControlInfo } from '../../../../../../wpr-framework/view/control/WprControlInfo';
import { WprDI_Control } from '../../../../../../wpr-framework/view/di/WprDI_Control';
import { ItscomHomeCourseListModel } from '../../../../../service/models/apimodels/ItscomHomeCourseListModel';
import { ItscomHomeSelect1View } from '../ItscomHomeSelect1View';

/**
 * コースリストモード
 */
export class CouseListMode extends WprBaseViewListMultiModeInfo<ItscomHomeSelect1View, ItscomHomeCourseListModel> {

	// コンストラクタ  -----------------------------------------------------------
	public constructor() {
		super('CourseListModel');
	}
	// --------------------------------------------------------------------------
	
	// override メソッド  --------------------------------------------------------
	/**
	 * モードリスト取得（この画面モードで使用する全てのモードを定義する）
	 * @param list モードリスト
	 */
	public useModeList(list: string[]): void {
		list.push('オーナー契約IH(月額)');
		list.push('オーナー契約IH以外(月額)');
		list.push('切替可');
		list.push('切替不可');
	}

	/**
	 * フィールド定義を登録処理
	 */
	public onRegisterControl(): void {
		this.addControl('ownerIhPrice', { display: [ { mode: 'オーナー契約IH(月額)', value: true }, { mode: 'other', value: false } ] });
		this.addControl('otherPrice', { display: [ { mode: 'オーナー契約IH以外(月額)', value: true }, { mode: 'other', value: false } ] });
		this.addControl('check1', { 
			check: true,
			readOnly: [ { mode: '切替不可', value: true }, { mode: '切替可', value: false } ] });
	}

	/**
	 * ビューの状態をチェックして行のモードを変更する
	 * @param curMode 現在のモード
	 * @param newMode 新しいモード
	 * @param row 行データ
	 */
	public onCheckListMultiMode(curMode: WprMultiMode, newMode: WprMultiMode, row: ItscomHomeCourseListModel): void {
		if(row.ownerIhFlag) {
			newMode.addMode('オーナー契約IH(月額)');
		}
		else {
			newMode.addMode('オーナー契約IH以外(月額)');
		}
		if (row.check1) {
			newMode.addMode('切替不可');
		}
		else {
			newMode.addMode('切替可');
		}
	}
	// --------------------------------------------------------------------------
}
