import { WprBaseViewInfo } from '../../../../wpr-framework/view/WprBaseViewInfo';

/**
 * BS-other-001_その他_404エラー画面
 */
export class NotFoundView extends WprBaseViewInfo {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() { super('NotFoundView'); }
	// --------------------------------------------------------------------------
}
