import { WprBaseValidator } from '../WprBaseValidator';
import { WprBaseInputCheckValidator } from '../WprBaseInputCheckValidator';

/**
 * 整数バリデータ
 */
export class WprNumberValidator extends WprBaseInputCheckValidator {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() {
		super('整数');
	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * 入力値チェック
	 * @param value 入力値
	 * @param option チェックオプション
	 * @returns trueの場合、成功
	 */
	public checkValidValue(value: any, option?: any): boolean {
		if (!value)
			return true;
		if (value === ' ' || value === '　')
			return false;
		const nval = Number(value);
		if (isNaN(nval) === true)
			return false;
		return true;
	}

	/**
	 * エラーメッセージ取得
	 * @param description エラーが発生したコントロールの説明
	 * @param option オプション
	 * @returns エラーメッセージ
	 */
	public getErrorMessage(description: string, option?: any): string {
		return '整数を入力してください。';
	}
	// --------------------------------------------------------------------------
}
