import { WprBaseViewListMultiModeInfo } from '../../../../../../wpr-framework/mode/WprBaseViewListMultiModeInfo';
import { WprMultiMode } from '../../../../../../wpr-framework/mode/WprMultiMode';
import { TvCourseListModel } from '../../../../../models/mypage/tv/TvCourseListModel';
import { TvCourseListPartsView } from '../TvCourseListPartsView';
import { WprDI_Control } from '../../../../../../wpr-framework/view/di/WprDI_Control';
import { WprControlInfo } from '../../../../../../wpr-framework/view/control/WprControlInfo';

/**
 * TvCourseMode
 */
export class TvCourseMode extends WprBaseViewListMultiModeInfo<TvCourseListPartsView, TvCourseListModel> {
	// DI情報  ------------------------------------------------------------------
	@WprDI_Control
	private m_select: WprControlInfo	= null;	// 選択
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	public constructor() {
		super('TvCourseListModel');
	}
	// --------------------------------------------------------------------------
	
	// override メソッド  --------------------------------------------------------
	/**
	 * モードリスト取得（この画面モードで使用する全てのモードを定義する）
	 * @param list モードリスト
	 */
	public useModeList(list: string[]): void {
		list.push('切り替え可');
		list.push('切り替え不可');
	}

	/**
	 * フィールド定義を登録処理
	 */
	public onRegisterControl(): void {
		this.addControl('select', { check: true, readOnly: [ { mode: '切り替え不可', value: true }, { mode: '切り替え可', value: false } ] });
	}

	/**
	 * ビューの状態をチェックして行のモードを変更する
	 * @param curMode 現在のモード
	 * @param newMode 新しいモード
	 * @param row 行データ
	 */
	public onCheckListMultiMode(curMode: WprMultiMode, newMode: WprMultiMode, row: TvCourseListModel): void {
		if (this.m_select.value)
			newMode.addMode('切り替え不可');
		else 
			newMode.addMode('切り替え可');
	}
	// --------------------------------------------------------------------------
}
