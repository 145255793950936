import { WizardModel } from '../../models/WizardModel';
import { MypageWizardFlow } from './MypageWizardFlow';
import { MDisplay } from '../../service/models/entity/secondary/MDisplay';
import { CommonCompleteView } from '../common/common_complete/CommonCompleteView';
import { GetViewInfoRequest } from '../../service/models/dto/mypagerenewal/GetViewInfoRequest';
import { MypageBaseView } from '../base/MypageBaseView';
import { CommonAgreeView } from '../common/common_agree/CommonAgreeView';
import { MGeneral } from '../../service/models/entity/MGeneral';
import { MCommon } from '../../service/models/entity/primary/MCommon';
import MypageCodeTypeConstants from '../../common/MypageCodeTypeConstants';
import { MypageWizardMngr } from './models/MypageWizardMngr';
import { ContractServiceModel } from '../../service/models/apimodels/ContractServiceModel';
import { TvBaseModel } from '../../models/mypage/tv/TvBaseModel';
import { TvNewWizardModel } from './TvNewWizardFlow';
import { ModTvComfirmView } from '../tv/revise/mod_tv_comfirm/ModTvComfirmView';
import { ModTvSelect1View } from '../tv/revise/mod_tv_select1/ModTvSelect1View';
import { ModTvSelect2View } from '../tv/revise/mod_tv_select2/ModTvSelect2View';
import { RegistInfoRequest } from '../../service/models/dto/mypagerenewal/RegistInfoRequest';
import { Contract } from '../../models/bss/contract/Contract';
import { InquiryMailInfo } from '../../service/models/apimodels/InquiryMailInfo';
import { TvDirectModel } from '../../models/mypage/tv/TvDirectModel';
import { RegistContractRequest } from '../../service/models/dto/mypagerenewal/RegistContractRequest';
import { ObjectUtil } from '../../common/util/ObjectUtil';
import { RegistInfoResponse } from '../../service/models/dto/mypagerenewal/RegistInfoResponse';
import { RelocateSelect1View } from '../relocate/relocate_select1/RelocateSelect1View';
import { RelocateSelect2View } from '../relocate/relocate_select2/RelocateSelect2View';
import { RelocateComfirmView } from '../relocate/relocate_comfirm/RelocateComfirmView';
import MypageDispdivcdConstants from '../../common/MypageDispdivcdConstants';
import { RelocateBaseModel } from '../../models/mypage/relocate/RelocateBaseModel';
import { CustomerSupportView } from '../common/customer_support/CustomerSupportView';
import MypageYakkanConstants from '../../common/MypageYakkanConstants';
import { MAIL_CONSTANTS } from '../../common/MyPageMailIdConstants';
import { RelocateModel } from '../../service/models/apimodels/RelocateModel';

/**
 * 動画配信サービス_見放題パックプライム_ウィザードクラス
 */
export class RelocateWizardFlow extends MypageWizardFlow {
	// private 変数  ------------------------------------------------------------
	private m_DefaultWizardFlow: WizardModel[]			= null;		// 画面ウィザードリスト
	private m_TokosapoWizardFlow: WizardModel[]			= null;		// とことんサポート画面ウィザードリスト
	private m_StartViewName: string						= null;
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	public constructor() {
		super();
		this.m_StartViewName = new RelocateSelect1View().name;

		// 基本遷移パターン
		this.m_DefaultWizardFlow = new Array();
		this.m_DefaultWizardFlow.push({stepNum: 0, title: '契約選択', viewName: null, path: this.getOldUrl() + 'Srvlist/Continfo_list/'});
		this.m_DefaultWizardFlow.push({stepNum: 1, title: '移動機器の選択', viewName: new RelocateSelect1View().name, path: 'relocate_select1'});
		this.m_DefaultWizardFlow.push({stepNum: 2, title: '機器移動希望場所の選択', viewName: new RelocateSelect2View().name, path: 'relocate_select2'});
		this.m_DefaultWizardFlow.push({stepNum: 3, title: '申込内容の確認', viewName: new RelocateComfirmView().name, path: 'relocate_comfirm'});
		this.m_DefaultWizardFlow.push({stepNum: 4, title: '機器移動申込完了', viewName: new CommonCompleteView().name, path: 'relocate_complete'});

		// 約款遷移パターン
		this.m_TokosapoWizardFlow = new Array();
		this.m_TokosapoWizardFlow.push(this.m_DefaultWizardFlow[0]);
		this.m_TokosapoWizardFlow.push(this.m_DefaultWizardFlow[1]);
		this.m_TokosapoWizardFlow.push(this.m_DefaultWizardFlow[2]);
		this.m_TokosapoWizardFlow.push({stepNum: 3, title: '申込内容の確認', viewName: new RelocateComfirmView().name, path: 'relocate_comfirm'});
		this.m_TokosapoWizardFlow.push({stepNum: 4, title: '契約約款', viewName: new CommonAgreeView().name, path: 'relocate_agree'});
		this.m_TokosapoWizardFlow.push({stepNum: 5, title: '機器移動申込完了', viewName: new CommonCompleteView().name, path: 'relocate_complete'});
	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * 画面ウィザードフロー名取得
	 */
	public getWizardFlowName(viewName: string): string {
		if (viewName === this.m_StartViewName)
			return this.constructor.name;
		return null;
	}
	
	/**
	 * 画面ウィザードリスト取得：契約状態で切り替えたり、選択肢で切り替えたり
	 */
	public getWizardFlow(mngr: MypageWizardMngr): WizardModel[] {
		let cacheData: RelocateWizardModel = mngr.getCache();
		if (cacheData.m_RelocateBaseModel.tokotonAddFlg)
			return this.m_TokosapoWizardFlow;
		else 
			return this.m_DefaultWizardFlow;
	}
	
	/**
	 * 画面ウィザードAPI用モデル取得：契約状態で切り替えたり、選択肢で切り替えたり
	 */
	public getWizardApiModel(mngr: MypageWizardMngr, view: MypageBaseView): GetViewInfoRequest {
		const req = new GetViewInfoRequest();
		req.customerId = mngr.getParams().customerId;
		req.contractFlg = true;
		req.buildingFlg = true;
		req.displayList = new Array();
		req.displayList.push(new MDisplay(MypageDispdivcdConstants.TOKOTON_SUPPORT_COURSE_NAME, null));
		req.displayList.push(new MDisplay(MypageDispdivcdConstants.TV_SERVICE, null));
		req.displayList.push(new MDisplay(MypageDispdivcdConstants.ACCESSPASS_SERVICE, null));
		req.displayList.push(new MDisplay(MypageDispdivcdConstants.ITSCOMPHONE_SERVICE1, null));
		req.displayList.push(new MDisplay(MypageDispdivcdConstants.ITSCOMPHONE_SERVICE2, null));
		req.displayList.push(new MDisplay(MypageDispdivcdConstants.ITSCOMPHONE_SERVICE3, null));
		req.displayList.push(new MDisplay(MypageDispdivcdConstants.ITSCOMHOME_TERMINALTYPE, null));
		req.displayList.push(new MDisplay(MypageDispdivcdConstants.ITSCOMHOME_TERMINALSTATUS, null));
		// サービスマスタ取得
		req.masterFlg = true;
		const target = new ContractServiceModel();
		target.serviceCd = MypageDispdivcdConstants.TOKOTON_SUPPORT_COURSE_NAME;
		req.serviceKey = new Array();
		req.serviceKey.push(target);
		req.commonList = new Array();
		// 機器の種類(10000)
		const common1 = new MCommon('機器の種類(10000)', 'Add_net', 'equ', 'rep');
		req.commonList.push(common1);
		// equipmentTypeCD判定
		const common2 = new MCommon('equipmentTypeCD判定', 'Add_net', 'equ', 'req');
		req.commonList.push(common2);
		const common3 = new MCommon('equipmentTypeCD判定', 'Add_net', 'equ', 'req2');
		req.commonList.push(common3);
		const common34= new MCommon('DONU判定', 'srvnet', 'd_onu_code');
		req.commonList.push(common34);
		//約款
		req.yakkanList = new Array();
		req.yakkanList.push(new MGeneral(MypageCodeTypeConstants.CONTRACT_AGREE, MypageYakkanConstants.ITSCOM_SERVICE));
		req.yakkanList.push(new MGeneral(MypageCodeTypeConstants.CONTRACT_AGREE, MypageYakkanConstants.ITSCOM_PRICE));
		return req;
	}
	
	/**
	 * 画面ウィザード完了APIモデル取得
	 */
	public getRegistApiModel(mngr: MypageWizardMngr, view: MypageBaseView, resultCd: string): RegistInfoRequest{
		const req: RegistInfoRequest = new RegistInfoRequest();
		const cacheData = mngr.getCache();
		const customer = mngr.getViewInfo().customer;
		const mailconstants =  MAIL_CONSTANTS.find (mail => {
				return mail.view === '移設_申込み';
			});
		req.customerId = mngr.getParams().customerId;
		req.customerMailId = mailconstants.customerMail;
		req.systemMailId = mailconstants.systemMail;
		req.inquiryId = mailconstants.inquiryMail;
		req.systemInfo = new InquiryMailInfo();
		//とことんサポート
		if (cacheData.m_RelocateBaseModel.tokotonAddFlg)
			req.systemInfo.tokotonApp = '希望あり';
		else
			req.systemInfo.tokotonApp = '希望なし';
		//リフォーム
		if (cacheData.m_RelocateBaseModel.reformFlg)
			req.systemInfo.reform = 'あり';
		else
			req.systemInfo.reform = 'なし';
		//移設機器情報一覧
		const allList = cacheData.m_RelocateBaseModel.tvList.concat(cacheData.m_RelocateBaseModel.netList).concat(cacheData.m_RelocateBaseModel.phoneList);
		req.systemInfo.relocateList = new Array();
		for (const target of allList) {
			const model = new RelocateModel();
			//コース
			model.appService = target.serviceName;
			//機器名
			if (target.terminalName)
				model.stb = target.terminalName;
			else
				model.stb = '';
			//レンタル、購入
			if(target.appltype)
				model.rental = target.appltype;
			else 
				model.rental = '';
			//契約番号
			model.contractId = target.contract.contractID;
			//希望場所
			let location = '';
			if (customer.building[0].buildingTypeCD === '3')
				location += target.floor + ' ';
			location += target.location;
			if (target.location === 'その他')
				location += ' ' + target.comment;
			model.location = location;
			req.systemInfo.relocateList.push(model);
		}
		//希望日時
		req.systemInfo.desiredDate1 = cacheData.m_RelocateBaseModel.desiredDate1.replace(/-/g, '/');
		req.systemInfo.desiredDate2 = cacheData.m_RelocateBaseModel.desiredDate2.replace(/-/g, '/');
		req.systemInfo.desiredDate3 = cacheData.m_RelocateBaseModel.desiredDate3.replace(/-/g, '/');
		req.systemInfo.selectTime1 = view.getValueMap('時間帯').getValueFormKey(cacheData.m_RelocateBaseModel.selectTime1);
		req.systemInfo.selectTime2 = view.getValueMap('時間帯').getValueFormKey(cacheData.m_RelocateBaseModel.selectTime2);
		req.systemInfo.selectTime3 = view.getValueMap('時間帯').getValueFormKey(cacheData.m_RelocateBaseModel.selectTime3);
		//工事費
		if (customer.building[0].buildingTypeCD === '3' || cacheData.m_RelocateBaseModel.estimateFlg)
			req.systemInfo.constructionCost = 'お見積り';
		else
			req.systemInfo.constructionCost = cacheData.m_RelocateBaseModel.amount + '円';
		//連絡先等
		req.systemInfo.beforeContact = cacheData.m_RelocateBaseModel.beforeContact;
		req.systemInfo.sameDayContact = cacheData.m_RelocateBaseModel.sameDayContact;
		req.systemInfo.witness = cacheData.m_RelocateBaseModel.presentName;
		return req;
	}

	/**
	 * 画面ウィザードフローの中断判定：例：申し込みが完了していれば、完了済みの画面へ遷移
	 */
	public checkFlowBreak(mngr: MypageWizardMngr, view: MypageBaseView): string {
		const viewCode = '004004';
		if (view.m_Name === 'RelocateSelect1View') {
			const building = mngr.getViewInfo().buildingList[0];
			const customer = mngr.getViewInfo().customer;
			//戸建賃貸の場合:002
			if (customer.building[0].buildingTypeCD === '2') {
				return '/customer_support?no=002' + viewCode;
			}
			//2軸物件の対象の場合:003
			else if (building.ftthTokkiMethod === '3' && building.bunkatuMotoTatemonoNum) {
				return '/customer_support?no=003' + viewCode;
			}
		}
		else if (view.m_Name === 'RelocateComfirmView') {
			//設置場所：006
			const location = /応接室|職場|休憩室|社長室/;
			const allList = mngr.getCache().m_RelocateBaseModel.tvList.concat(mngr.getCache().m_RelocateBaseModel.netList).concat(mngr.getCache().m_RelocateBaseModel.phoneList);
			if (allList) {
				for (const model of allList) {
					if (location.test(model.location)) {
						return '/customer_support?no=006' + viewCode;
					}
				}
			}
		}
		return null;
	}

	/**
	 * 画面キャッシュの初期化データ取得
	 */
	public getInitCacheData(mngr: MypageWizardMngr): RelocateWizardModel {
		let cacheData = new RelocateWizardModel();
		cacheData.m_TvServiceList = new Array();
		cacheData.m_NetServiceList = new Array();
		cacheData.m_PhoneServiceList = new Array();
		cacheData.m_RelocateBaseModel = new RelocateBaseModel();
		return cacheData;
	}
	// --------------------------------------------------------------------------
}


/**
 * 画面ウィザードAPI用モデル
 */
export class RelocateWizardModel {
	public m_TvServiceList: Contract[]					= null; 	// tvサービスモデル
	public m_NetServiceList: Contract[]					= null; 	// netサービスモデル
	public m_PhoneServiceList: Contract[]				= null; 	// phoneサービスモデル
	public m_RelocateBaseModel: RelocateBaseModel		= null; 	// phoneサービスモデル
}