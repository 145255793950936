import { Contract } from "../../../models/bss/contract/Contract";

/**
 * ITSCOMマガジン
 */
export class MagazineModel {
	// public 変数	-----------------------------------------------------------
	public maxFlg: string;			// マックス判定フラグ
	public contract: Contract;
	public amount: string;
	// ------------------------------------------------------------------------
}