// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@charset \"UTF-8\";\n/*\r\n * 電子発行承諾用CSS\r\n */\n/*s002*/\n.S_01_002-contentText {\n  font-style: normal;\n  font-weight: 500;\n  font-size: 14px;\n  line-height: 160%;\n  text-align: center;\n  color: #000000;\n  margin: 1rem auto;\n  width: 76%; }\n\n.S_01_002-backButtonSub {\n  width: 215px; }\n\n.S_01_002-nextButtonSub {\n  width: 315px; }\n", ""]);
// Exports
exports.locals = {
	"contentText": "S_01_002-contentText",
	"backButtonSub": "S_01_002-backButtonSub",
	"nextButtonSub": "S_01_002-nextButtonSub"
};
module.exports = exports;
