import { BaseService } from './base/BaseService';
import { WprBaseViewCore } from '../../wpr-framework/view/WprBaseViewCore';
import { GetIdpRequest } from './models/dto/mypagerenewal/GetIdpRequest';
import { ResetPasswordResponse } from './models/dto/mypagerenewal/ResetPasswordResponse';
import { GetIdpResponse } from './models/dto/mypagerenewal/GetIdpResponse';

/**
 * 動画配信サービス
 */
export class VideoService extends BaseService {
	// サービス メソッド  --------------------------------------------------------
	/**
	 * パスワード再発行
	 * @param viewCore ビュー情報
	 * @param request リクエスト情報
	 * @param resMethod 結果処理メソッド
	 */
	public resetIdpPassword(viewCore: WprBaseViewCore, request: GetIdpRequest, resMethod: (result: ResetPasswordResponse) => void): void {
		return this.doPost(viewCore, '/video/resetIdpPassword', request, resMethod);
	}

	/**
	 * idp取得
	 * @param viewCore ビュー情報
	 * @param request リクエスト情報
	 * @param resMethod 結果処理メソッド
	 */
	public getIdp(viewCore: WprBaseViewCore, request: GetIdpRequest, resMethod: (result: GetIdpResponse) => void): void {
		return this.doPost(viewCore, '/video/getIdp', request, resMethod);
	}
	// --------------------------------------------------------------------------
}
