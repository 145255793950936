import { WprUserLogInfo } from './WprUserLogInfo';

/**
 * ローカルストレージユーザログ制御クラス
 */
export class WprLocalStrageUserLog {
	// private 変数  ------------------------------------------------------------
	private USER_LOG_MAX = 500;			// 最大ユーザログ数
	private m_Start: number;			// 開始インデクス
	private m_Max: number;				// 最大数
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	public constructor() {
		this.m_Start = this.getNumber('usr_log_start');
		this.m_Max = this.getNumber('usr_log_max');
	}
	// --------------------------------------------------------------------------

	// public メソッド	---------------------------------------------------------
	/**
	 * ログ追加処理
	 * @param log ログ情報
	 */
	public addLog(log: WprUserLogInfo): void {
		if (this.m_Max > this.USER_LOG_MAX)
			this.clearUserLog();
		const json = JSON.stringify(log);
		window.localStorage.setItem(`usr_log_${this.m_Start}`, json);
		if (this.m_Start < this.USER_LOG_MAX) {
			if (this.m_Start == this.m_Max)
				this.m_Max++;
			this.m_Start++;
		}
		else {
			this.m_Start = 0;
		}
		window.localStorage.setItem('usr_log_start', String(this.m_Start));
		window.localStorage.setItem('usr_log_max', String(this.m_Max));
	}

	/**
	 * ログ情報リスト取得
	 * @returns ログ情報リスト
	 */
	public getLogList(): WprUserLogInfo[] {
		const list = new Array();
		if (this.m_Start != this.m_Max) {
			for (let i = this.m_Start; i<this.m_Max+1; i++)
				this.setLogList(list, i);
		}
		for (let i = 0; i < this.m_Start; i++)
			this.setLogList(list, i);
		return list;
	}

	/**
	 * ユーザログ情報クリア
	 */
	public clearUserLog(): void {
		window.localStorage.removeItem('usr_log_start');
		window.localStorage.removeItem('usr_log_max');
		for (let i=0; i<this.m_Max; i++)
			window.localStorage.removeItem(`usr_log_${i}`);
		this.m_Start = 0;
		this.m_Max = 0;
	}
	// --------------------------------------------------------------------------

	// private メソッド	---------------------------------------------------------
	/**
	 * 数値をローカルストレージから取得
	 * @param key キー
	 * @returns 数値
	 */
	private getNumber(key: string): number {
		let val = window.localStorage.getItem(key);
		if (val)
			return Number(val);
		return 0;
	}

	/**
	 * ログ情報リスト設定
	 * @param list ログ情報リスト
	 * @param idx インデクス
	 */
	private setLogList(list: WprUserLogInfo[], idx: number): void {
		const json = window.localStorage.getItem(`usr_log_${idx}`);
		const obj = JSON.parse(json);
		const log = new WprUserLogInfo(obj.m_ClassName, obj.m_Message);
		log.date = new Date(obj.m_Date);
		list.push(log);
	}
	// --------------------------------------------------------------------------
}
