import { HMvnoTmpSaved } from '../../entity/HMvnoTmpSaved';

/**
 * MVNO一時保存更新リクエスト
 */
export class UpdateHMvnoTmpSavedRequest {
	// public 変数	-----------------------------------------------------------
	public detail: HMvnoTmpSaved;	// MVNO一時保存
	// ------------------------------------------------------------------------
}
