import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function PDFView(props) {
  const [numPages, setNumPages] = useState(null);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  function alterWidth():number{
    if (screen.width >= 1000){
      return 1000 -7
    }
    return screen.width-10-7
  }
  if (props.dataUrl != null) {
    return (
        <Document
          file={props.dataUrl}
          onLoadSuccess={onDocumentLoadSuccess}
        >
          {Array.from(
            new Array(numPages),
            (el, index) => (
  
              <Page
                key={`page_${index + 1}`}
                pageNumber={index + 1}
                // scale={alterScale()}
                width={alterWidth()}
              />
            ),
          )}
        </Document>
    );
  }
  else {
    return null;
  }
}