import { WprDI_ScopeModel } from '../../../../wpr-framework/view/di/WprDI_ScopeModel';
import { MypageBaseView } from '../../base/MypageBaseView';
import { ProgressBarData } from '../../common/progress_bar/ProgressBarView';
import { MypageWizardMngr } from '../../wizard/models/MypageWizardMngr';
import { WprModel } from '../../../../wpr-framework/view/deco/WprModel';
import { MeshWifiBaseModel } from '../../../models/mypage/meshWifi/MeshWifiBaseModel';
import { WprViewMode } from '../../../../wpr-framework/view/deco/WprViewMode';
import { truncate } from 'fs';
import { MDisplay } from '../../../service/models/entity/secondary/MDisplay';
import MypageDispdivcdConstants from '../../../common/MypageDispdivcdConstants';
import { EstablishmentCheck } from './mode/MultiCheck';
import { DateUtil } from '../../../common/util/DateUtil';
import { WprValidError } from '../../../../wpr-framework/view/validation/WprValidError';
import { ObjectUtil } from '../../../common/util/ObjectUtil';
import { WprListData } from '../../../../wpr-framework/view/deco/WprListData';
import { ErrorModel } from '../../Srvother/add_nhk_select/models/ErrorModel';
import { GetMGeneralRequest } from '../../../service/models/dto/mypagerenewal/GetMGeneralRequest';
import MypageCodeTypeConstants from '../../../common/MypageCodeTypeConstants';

/**
 * メッシュwifi 追加申込み
 */
export class AddMeshWifiSelectView extends MypageBaseView {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() { super('AddMeshWifiSelectView'); }
	// --------------------------------------------------------------------------

	// モデル  ------------------------------------------------------------------
	@WprDI_ScopeModel('MypageWizardMngr')
	private m_MypageWizardMngr: MypageWizardMngr			= null;		
	@WprModel('MeshWifiBaseModel')
	private m_MeshWifiBaseModel: MeshWifiBaseModel	= null;	// メッシュWifi
	@WprListData('ErrorModel')
	private m_ErrorModelList: ErrorModel[]	= null;	// エラーモデル
	// --------------------------------------------------------------------------

	// サブビュー/モード  --------------------------------------------------------
	@WprViewMode
	private m_EstablishmentCheck = new EstablishmentCheck();	// 設置方法
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * コントロール定義設定
	 */
	public onRegisterConfig(): void {
		this.addConfig('courseCheck', '申込みコース', 'MeshWifiBaseModel',{ disabled: true});
		this.addConfig('equipmentCheck', '機器のご提供方法', 'MeshWifiBaseModel',{ disabled: true});
		this.addConfig('check1', '設置方法１', 'MeshWifiBaseModel'); // 自身で設置
		this.addConfig('check2', '設置方法２', 'MeshWifiBaseModel'); // 訪問での設置
		this.addConfig('desiredDate1', '第一希望', 'MeshWifiBaseModel', { emptyValue: null, hissu: true, max: this.m_MypageWizardMngr.getMaxReqDate(), min: this.m_MypageWizardMngr.getMinReqDate() });
		this.addConfig('selectTime1', '第一希望　時間帯', 'MeshWifiBaseModel', { emptyValue: null, hissu: true });
		this.addConfig('desiredDate2', '第二希望', 'MeshWifiBaseModel', { emptyValue: null, max: this.m_MypageWizardMngr.getMaxReqDate(), min: this.m_MypageWizardMngr.getMinReqDate() });
		this.addConfig('selectTime2', '第二希望　時間帯', 'MeshWifiBaseModel', { emptyValue: null, hissu: true });
		this.addConfig('desiredDate3', '第三希望', 'MeshWifiBaseModel', { emptyValue: null, max: this.m_MypageWizardMngr.getMaxReqDate(), min: this.m_MypageWizardMngr.getMinReqDate() });
		this.addConfig('selectTime3', '第三希望　時間帯', 'MeshWifiBaseModel', { emptyValue: null, hissu: true });
		this.addConfig('confirmCheck', '同意確認', 'MeshWifiBaseModel');
		this.addConfig('beforeContact', '前日連絡先', 'MeshWifiBaseModel', { maxLength: 11, validation: '整数' });
		this.addConfig('sameDayContact', '当日連絡先', 'MeshWifiBaseModel', { maxLength: 11, validation: '整数' });
		this.addConfig('name', '立ち合い者名', 'MeshWifiBaseModel');
		// this.addConfig('tokotonAddFlg', 'とことんサポート加入フラグ', 'MeshWifiBaseModel');
		this.addConfig('tokosupCheck', 'とこサポ選択', 'MeshWifiBaseModel',{ disabled: true});
		this.addConfig('tokosupConfirmCheck', 'とこサポ同意確認', 'MeshWifiBaseModel');
		this.addListConfig('errorMessage', 'エラーメッセージ', 'ErrorModel');
	}

	/**
	 * アクション登録
	 */
	public onRegisterAction(): void {
		this.addAction('next', this.onNext, true); //日付選択がない場合の確認ボタン
		this.addAction('next1', this.onNext); // 日付選択がある場合の確認ボタン
		this.addAction('back', this.onBack);
	}

	public onChangeValue(name: string , value: any): boolean{
		if(name === 'check1'){
			this.m_MeshWifiBaseModel.check2 = false;
			this.m_MeshWifiBaseModel.check1 = true;
			this.m_MeshWifiBaseModel.constractionFlg = false;
			this.setViewMode('設置方法','自身');
			this.setViewMode('エラー','');
			this.refreshView();
		}else if(name === 'check2'){
			this.m_MeshWifiBaseModel.check1 = false;
			this.m_MeshWifiBaseModel.check2 = true;
			this.m_MeshWifiBaseModel.constractionFlg = true;
			this.setViewMode('設置方法','訪問');
			this.refreshView();
		}
		this.refreshModel();
		return true;
	}

	/**
	 * ビュー表示通知
	 */
	public onShowView(): void {
		this.m_MypageWizardMngr.initView(this, (result) => {
			this.registAccessLog();
			this.setStorageItem('b_url', window.location.href);
			const sendData: ProgressBarData = new ProgressBarData();
			sendData.progressBarList = this.m_MypageWizardMngr.getProgressModelList(this);
			this.sendChildData('fP_ev_progressBarIntital', sendData);
			this.m_MeshWifiBaseModel = this.m_MypageWizardMngr.getCache().meshWifi;
			this.m_MeshWifiBaseModel.tokosupCheck = true;
			if (this.m_MeshWifiBaseModel.check1 === undefined) {
				this.m_MeshWifiBaseModel.check1 = true;
				// 申込みコース、レンタルの初期表示
				this.m_MeshWifiBaseModel.courseCheck = true;
				this.m_MeshWifiBaseModel.equipmentCheck = true;
			}
			const contractList = this.m_MypageWizardMngr.getViewInfo().contractModel.contractList;
			const tokotonList = this.m_MypageWizardMngr.getMDisplayList(this, MypageDispdivcdConstants.TOKOTON_SUPPORT_COURSE_NAME, '');
			this.m_MeshWifiBaseModel.wifiFlg = false;
			for (const contract of contractList) {
				//とことんサポート加入判定
				for (const tokosupo of tokotonList) {
					if (contract.serviceCD === tokosupo.namecd && contract.statusCD === '1') {
						this.m_MeshWifiBaseModel.tokotonFlg = true;
					}
				}
				// 設置方法の出し分け
				const generalList = this.m_MypageWizardMngr.getMGenralList(this, MypageCodeTypeConstants.MESHWIFI_AMOUNT, '');
				let cableServiceCd = null;
				for (const cableMesh of generalList) {
					if (cableMesh.flag2) {
						cableServiceCd = cableMesh.generalCd;
					}
				}
				if (contract.serviceCD === cableServiceCd) {
					this.m_MeshWifiBaseModel.check2 = true;
					this.m_MeshWifiBaseModel.check1 = false;
					this.m_MeshWifiBaseModel.constractionFlg = true;
					this.m_MeshWifiBaseModel.wifiFlg = true;
				}
			}
			if (this.m_MeshWifiBaseModel.wifiFlg)
				this.setViewMode('お客様ご自身での設置', '非表示');
			else
				this.setViewMode('お客様ご自身での設置', '表示');
			if (this.m_MeshWifiBaseModel.check2 === true) {
				this.setViewMode('設置方法','訪問');
			} else {
				this.setViewMode('設置方法','自身');
			}
			if (!this.m_MeshWifiBaseModel.tokotonFlg) {
				this.setViewMode('とことんサポート', '表示');
				this.refreshView();
			} else {
				this.m_MeshWifiBaseModel.tokosupConfirmCheck = true;
			}
			this.refreshView();

		});
	}


	/**
	 * 入力チェック（独自のValidationを実施する場合に使用する）
	 *   エラー追加例：validError.addError('エラーメッセージ');		// 相関チェックなどのエラー
	 * 				  this.setControlError('control', 'error');		// コントロールのエラー
	 * 		※コントロールの値をチェックする場合は、refreshModelは使用しないでください。
	 * 		　コントロールの値をチェックする場合は、コントロールをDIしてvalueを使用してください。
	 * @param actionName アクション名
	 * @param validError バリデーションエラー情報
	 * @param row 行情報
	 */
	protected onValid(actionName: string, validError: WprValidError, row?: any): void {
		if (this.m_MeshWifiBaseModel.check2 === true) {
			if (actionName == 'next') {
				this.m_ErrorModelList = new Array();
				const desireDateTime: MeshWifiBaseModel = this.m_MeshWifiBaseModel;
				const reqTimeHissuErrorFlg1: boolean = !this.m_MypageWizardMngr.isNotEmpty(desireDateTime.desiredDate1, desireDateTime.selectTime1);
				const reqTimeHissuErrorFlg2: boolean = !this.m_MypageWizardMngr.isNotEmpty(desireDateTime.desiredDate2, desireDateTime.selectTime2);
				const reqTimeHissuErrorFlg3: boolean = !this.m_MypageWizardMngr.isNotEmpty(desireDateTime.desiredDate3, desireDateTime.selectTime3);
				if (reqTimeHissuErrorFlg1 || reqTimeHissuErrorFlg2 || reqTimeHissuErrorFlg3) {
					const itemStr: string = this.m_MypageWizardMngr.setErrorReqDateItem([{ flag: reqTimeHissuErrorFlg1, dispVal: '第一希望' }, { flag: reqTimeHissuErrorFlg2, dispVal: '第二希望' }, { flag: reqTimeHissuErrorFlg3, dispVal: '第三希望' },]);
					const model = new ErrorModel();
					model.errorMessage = itemStr + '：日付、時間帯のいずれかが入力されていません。再度入力してください。';
					this.m_ErrorModelList.push(model);
				}
				else {
					const selectTime1: string = desireDateTime.desiredDate1 + desireDateTime.selectTime1;
					const selectTime2: string = desireDateTime.desiredDate2 + desireDateTime.selectTime2;
					const selectTime3: string = desireDateTime.desiredDate3 + desireDateTime.selectTime3;
					let reqTimeErrorFlg1: boolean = false;
					let reqTimeErrorFlg2: boolean = false;
					let reqTimeErrorFlg3: boolean = false;
					if (ObjectUtil.isNotNullOrUndefined(desireDateTime.desiredDate1) && ObjectUtil.isNotNullOrUndefined(desireDateTime.desiredDate2) && selectTime1 === selectTime2) reqTimeErrorFlg1 = true;
					if (ObjectUtil.isNotNullOrUndefined(desireDateTime.desiredDate1) && ObjectUtil.isNotNullOrUndefined(desireDateTime.desiredDate3) && selectTime1 === selectTime3) reqTimeErrorFlg2 = true;
					if (ObjectUtil.isNotNullOrUndefined(desireDateTime.desiredDate2) && ObjectUtil.isNotNullOrUndefined(desireDateTime.desiredDate3) && selectTime2 === selectTime3) reqTimeErrorFlg3 = true;
					if (reqTimeErrorFlg1 || reqTimeErrorFlg2 || reqTimeErrorFlg3) {
						let itemStr: string;
						if (reqTimeErrorFlg1 === true && reqTimeErrorFlg3 == true) {
							itemStr = '第一希望と第二希望と第三希望';
						}
						else {
							itemStr = this.m_MypageWizardMngr.setErrorReqDateItem([{ flag: reqTimeErrorFlg1, dispVal: '第一希望と第二希望' }, { flag: reqTimeErrorFlg2, dispVal: '第一希望と第三希望' }, { flag: reqTimeErrorFlg3, dispVal: '第二希望と第三希望' },]);
						}
						const model = new ErrorModel();
						model.errorMessage = itemStr + '：同じ希望日時が入力されています。別の日時を指定してください。';
						this.m_ErrorModelList.push(model);
					}
				}
				const minDate = this.m_MypageWizardMngr.getMinReqDate();
				if (this.m_MeshWifiBaseModel.desiredDate1 < minDate) {
					const date = this.m_MypageWizardMngr.convertToDate(minDate);
					const model = new ErrorModel();
					model.errorMessage = '第一希望に' + date + '以降を入力してください。';
					this.m_ErrorModelList.push(model);
				}
				if (this.m_MeshWifiBaseModel.desiredDate2 < minDate) {
					const date = this.m_MypageWizardMngr.convertToDate(minDate);
					const model = new ErrorModel();
					model.errorMessage = '第二希望に' + date + '以降を入力してください。';
					this.m_ErrorModelList.push(model);
				}
				if (this.m_MeshWifiBaseModel.desiredDate3 < minDate) {
					const date = this.m_MypageWizardMngr.convertToDate(minDate);
					const model = new ErrorModel();
					model.errorMessage = '第三希望に' + date + '以降を入力してください。';
					this.m_ErrorModelList.push(model);
				}
				if (!this.m_MeshWifiBaseModel.beforeContact.match(/^[0-9]+$/)) {
					const model = new ErrorModel();
					model.errorMessage = '前日連絡先は半角数字で入力してください。';
					this.m_ErrorModelList.push(model);
				}
				if (!this.m_MeshWifiBaseModel.sameDayContact.match(/^[0-9]+$/)) {
					const model = new ErrorModel();
					model.errorMessage = '当日連絡先は半角数字で入力してください。';
					this.m_ErrorModelList.push(model);
				}
			}
			if (this.m_ErrorModelList.length !== 0) {
				this.setViewMode('エラー','表示');
				this.setListData('ErrorModel', this.m_ErrorModelList);
				validError.addError('エラー');	
			}
		}
	}

	/**
	 * バリデーションエラーメッセージ通知
	 * @param name コントロール名
	 * @param rowIndex 行数(Listの場合)
	 * @param validatorName バリデーション名
	 * @param msg エラーメッセージ
	 * @returns エラーメッセージ（変更する場合に指定する、メッセージを使用しない場合は、nullを返す）
	 */
	public onInvalidMessage(name: string, rowIndex: number, validatorName: string, msg: string): string {
		if (((name === 'desiredDate1' || name === 'desiredDate2' || name === 'desiredDate3' || name === 'selectTime1' || name === 'selectTime2' || name === 'selectTime3') && (validatorName === '最小値' || validatorName === '必須'))
			|| ((name === 'beforeContact' || name === 'sameDayContact') && (validatorName === '整数')))
			msg = null;
		return msg;
	}

	// --------------------------------------------------------------------------

	// アクション  ---------------------------------------------------------------
	/**
	 * 確認         <NavigateNextIcon />
	 */
	public onNext(param: any): void {
		this.refreshModel();
		this.m_MypageWizardMngr.goNext(this);
	}
	/**
	 * <NavigateBeforeIcon />         戻る
	 */
	public onBack(param: any): void {
		this.m_MypageWizardMngr.goBack(this);
	}

	// --------------------------------------------------------------------------

}
