import { WprBaseViewMultiModeInfo } from '../../../../../../wpr-framework/mode/WprBaseViewMultiMode';
import { WprMultiMode } from '../../../../../../wpr-framework/mode/WprMultiMode';
import { WprControlInfo } from '../../../../../../wpr-framework/view/control/WprControlInfo';
import { WprDI_Control } from '../../../../../../wpr-framework/view/di/WprDI_Control';
import { AddNetSettingView } from '../AddNetSettingView';

/**
 * ケーブルモード
 */
export class CableMode extends WprBaseViewMultiModeInfo<AddNetSettingView> {
	// DI情報  ------------------------------------------------------------------
	@WprDI_Control
	private m_cable: WprControlInfo	= null;	// お得パック希望する
	@WprDI_Control
	private m_cableWifi: WprControlInfo	= null;	// お得パック希望しない
	// --------------------------------------------------------------------------
	// コンストラクタ  -----------------------------------------------------------
	public constructor() {
		super('CableMode');
	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * モードリスト取得（この画面モードで使用する全てのモードを定義する）
	 * @param list モードリスト
	 */
	public useModeList(list: string[]): void {
		list.push('切替可(cable)');
		list.push('切替不可(cable)');
		list.push('切替可(cableWifi)');
		list.push('切替不可(cableWifi)');
	}

	/**
	 * フィールド定義を登録処理
	 */
	public onRegisterControl(): void {
		this.addControl('cable', {
			check: true,
			readOnly: [ { mode: '切替不可(cable)', value: true }, { mode: '切替可(cable)', value: false } ]
		});
		this.addControl('cableWifi', {
			check: true,
			readOnly: [ { mode: '切替不可(cableWifi)', value: true }, { mode: '切替可(cableWifi)', value: false } ]
		});
	}

	/**
	 * 画面の状態をチェックしてモードを変更する
	 * @param curMode 現在のモード
	 * @param newMode 新しいモード
	 */
	public onCheckMultiMode(curMode: WprMultiMode, newMode: WprMultiMode): void {
		if (this.m_cable.value && !this.m_cableWifi.value) {
			newMode.addMode('切替不可(cable)');
			newMode.addMode('切替可(cableWifi)');
		}
		else if (!this.m_cable.value && this.m_cableWifi.value) {
			newMode.addMode('切替可(cable)');
			newMode.addMode('切替不可(cableWifi)');
		}
	}
	// --------------------------------------------------------------------------
}

