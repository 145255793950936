import React, { RefObject } from 'react';
import { WprControlInfo } from '../../view/control/WprControlInfo';
import { WprControlVisibility } from '../props/IWprControlState';
import { IWprLayoutEvents } from '../props/IWprLayoutEvents';
import { IWprLayoutProps } from '../props/IWprLayoutProps';
import { WprBaseNoNameLayoutComponent } from './WprBaseNoNameLayoutComponent';

/**
 * レイアウト基本コンポーネント
 */
export abstract class WprBaseLayoutComponent<T extends IWprLayoutProps, U> extends WprBaseNoNameLayoutComponent<T, U> {
	// private 変数  ------------------------------------------------------------
	private m_ControlInfo: WprControlInfo 	= null;		// コントロール情報
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** コントロール情報 */
	public get controlInfo(): WprControlInfo 	{ return this.m_ControlInfo; 	}
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	constructor(props: T) {
		super(props);
		if (this.props.row)
			this.m_ControlInfo = this.props.view.getControlInfo(this.props.name, this.props.row.listName, this.props.row.index);
		else
			this.m_ControlInfo = this.props.view.getControlInfo(this.props.name);
		if (this.m_ControlInfo) {
			this.state = this.m_ControlInfo.getLayoutComponentState(props.row, this.getOption());
		}
		else {
			// this.props.view.addErrorLog(`コントロール定義が登録されていません。[${this.props.name}]`);
			this.state = {
				value: null,
				open: true,
				style: null,
				className: null,
				modeClassName: null,
				authClassName: null,
				visibility: WprControlVisibility.VISIBLE,
				option1: null,
				option2: null,
				option3: null,
				numOption1: 0,
				numOption2: 0,
				boolOption1: false,
				boolOption2: false
			};
		}
	}
	// --------------------------------------------------------------------------

	// override メソッド  -------------------------------------------------------
	/**
	 * マウント通知
	 */
	public onDidMount() {
		if (this.m_ControlInfo != null) {
			this.m_ControlInfo.setLayoutComponent(this);
			if (this.props.row)
				this.setState(this.m_ControlInfo.getLayoutComponentState(this.props.row, this.getOption()));
		}
	}

	/**
	 * アンマウント通知
	 */
	public onWillUnmount() {
		if (this.m_ControlInfo != null)
			this.m_ControlInfo.deleteLayoutComponent(this);
	}

	/**
	 * コントロール名称取得
	 * @returns コントロール名称
	 */
	 public getName(): string {
		return this.props.name;
	}

	/**
	 * イベント情報取得
	 * @returns イベント情報
	 */
	protected getEvents(): IWprLayoutEvents<U> {
		let event = super.getEvents();
		if (this.m_ControlInfo != null)
			return this.m_ControlInfo.viewCore.getEventsInfo(this.props.name, this.props.row, event);
		return event;
	}
	// --------------------------------------------------------------------------
}
