import { WizardModel } from '../../models/WizardModel';
import { MypageWizardFlow } from './MypageWizardFlow';
import { MypageBaseView } from '../base/MypageBaseView';
import { ItscomHomeReviseSelectView } from '../itscom_home/revise/itscom_home_revise_select/ItscomHomeReviseSelectView';
import { ItscomHomeReviceComfirmView } from '../itscom_home/revise/itscom_home_revise_comfirm/ItscomHomeReviceComfirmView';
import { CompleteModel } from '../common/common_complete/models/CompleteModel';
import { CommonCompleteView } from '../common/common_complete/CommonCompleteView';
import { GetViewInfoRequest } from '../../service/models/dto/mypagerenewal/GetViewInfoRequest';
import { MypageWizardMngr } from './models/MypageWizardMngr';
import { MDisplay } from '../../service/models/entity/secondary/MDisplay';
import { ContractPlanModel } from '../../service/models/apimodels/ContractPlanModel';
import { Contract } from '../../models/bss/contract/Contract';
import { MCommon } from '../../service/models/entity/primary/MCommon';
import { ContractServiceModel } from '../../service/models/apimodels/ContractServiceModel';
import MypageDispdivcdConstants from '../../common/MypageDispdivcdConstants';
import { RegistInfoRequest } from '../../service/models/dto/mypagerenewal/RegistInfoRequest';
import { InquiryMailInfo } from '../../service/models/apimodels/InquiryMailInfo';
import { ObjectUtil } from '../../common/util/ObjectUtil';
import { BaseInfo } from '../../models/mypage/smart/BaseInfo';
import { ItscomHomeOptRivComfirmList } from '../../models/mypage/smart/ItscomHomeOptRivComfirmList';
import { ItscomHomeOptionListModel } from '../../models/mypage/smart/ItscomHomeOptionListModel';
import { MAIL_CONSTANTS } from '../../common/MyPageMailIdConstants';

/**
 * ITSCOM HOME_追加ウィザードクラス
 */
export class ItscomHomeChangeWizardFlow extends MypageWizardFlow {
	// private 変数  ------------------------------------------------------------
	private m_DefaultWizardFlow: WizardModel[]			= null;		// 画面ウィザードリスト
	private m_StartViewName: string						= null;
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	public constructor() {
		super();
		this.m_StartViewName = new ItscomHomeReviseSelectView().name;

		// 遷移パターン１
		this.m_DefaultWizardFlow = new Array();
		this.m_DefaultWizardFlow.push({stepNum: 0, title: '契約選択', viewName: null, path: this.getOldUrl() + 'Srvlist/Continfo_list/'});
		this.m_DefaultWizardFlow.push({stepNum: 1, title: 'コース選択', viewName: new ItscomHomeReviseSelectView().name, path: 'itscom_home_revise_select'});
		this.m_DefaultWizardFlow.push({stepNum: 2, title: '申込み内容の確認', viewName: new ItscomHomeReviceComfirmView().name, path: 'itscom_home_revise_confirm'});
		this.m_DefaultWizardFlow.push({stepNum: 3, title: '変更申込み完了', viewName: new CommonCompleteView().name, path: '/itscom_home_revise_complete'});
	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * 画面ウィザードフロー名取得
	 */
	public getWizardFlowName(viewName: string): string {
		if (viewName === this.m_StartViewName)
			return this.constructor.name;
		return null;
	}
	
	/**
	 * 画面ウィザードリスト取得：契約状態で切り替えたり、選択肢で切り替えたり
	 */
	public getWizardFlow(mngr: MypageWizardMngr): WizardModel[] {
		return this.m_DefaultWizardFlow;
	}
	
	/**
	 * 画面ウィザードAPI用モデル取得：契約状態で切り替えたり、選択肢で切り替えたり
	 */
	public getWizardApiModel(mngr: MypageWizardMngr, view: MypageBaseView): GetViewInfoRequest {
		const req = new GetViewInfoRequest();
		req.displayList = new Array();
		req.commonList = new Array();
		req.statusCdList = new Array();
		req.statusCdList.push('0');
		req.statusCdList.push('1');
		req.statusCdList.push('2');
		req.statusCdList.push('5');
		req.serviceKey = new Array();
		req.contractFlg = true;
		req.masterFlg = true;
		req.ownerFlg = true;
		req.customerId = mngr.getParams().customerId;
		// コース
		req.displayList.push(new MDisplay(MypageDispdivcdConstants.ITSCOMHOME_CHENGE_SERVICE));
		const service1 = new ContractServiceModel();
		service1.serviceCd = MypageDispdivcdConstants.ITSCOMHOME_CHENGE_SERVICE;
		service1.contractId = mngr.getParams().contractId;
		req.serviceKey.push(service1);
		// 契約ステータス
		const filter2 = new MDisplay();
		filter2.dispdivcd = MypageDispdivcdConstants.ITSCOMHOME_SERVICESTATUS;
		req.displayList.push(filter2);
		// 端末タイプ
		req.displayList.push(new MDisplay(MypageDispdivcdConstants.ITSCOMHOME_TERMINALTYPE));
		const common1 = new MCommon('端末タイプ', 'Add_net', 'equ', 'rep');
		req.commonList.push(common1);
		// 端末形態
		req.displayList.push(new MDisplay(MypageDispdivcdConstants.ITSCOMHOME_TERMINALSTATUS));
		// インテリジェント判定
		req.displayList.push(new MDisplay(MypageDispdivcdConstants.ITSCOMHOME_INTELLIDEVICE));	// デバイス名
		req.displayList.push(new MDisplay(MypageDispdivcdConstants.ITSCOMHOME_INTELLISERVICE));	// サービス名
		// オプション
		req.displayList.push(new MDisplay(MypageDispdivcdConstants.ITSCOMHOME_OPTION));
		const service3 = new ContractServiceModel();
		service3.serviceCd = MypageDispdivcdConstants.ITSCOMHOME_OPTION;
		req.serviceKey.push(service3);
		// 購入可能オプション(判定用)
		const common2 = new MCommon('購入可能オプション', 'srvsmart', 'buy_opt');
		req.commonList.push(common2);
		// スマートライト数量単位(判定用)
		const common3 = new MCommon('スマートライト数量単位', 'srvsmart', 'count_type_ko');
		req.commonList.push(common3);
		// オーナー契約IH
		const common4 = new MCommon('オーナー契約IHリスト', 'IH', 'OWNER_SCD');
		req.commonList.push(common4);
		// 端末(10000)判定
		const common5 = new MCommon('端末(10000)', 'Add_net', 'equ', 'req');
		req.commonList.push(common5);
		const common6 = new MCommon('端末(10000)', 'Add_net', 'equ', 'req2');
		req.commonList.push(common6);
		return req;
	}

	/**
	 * 画面ウィザードフローの中断判定：例：申し込みが完了していれば、完了済みの画面へ遷移
	 */
	public checkFlowBreak(mngr: MypageWizardMngr): string {
		let test: boolean = false;
		if (test) {
			return '/error';
		}
		return null;
	}

	/**
	 * 画面キャッシュの初期化データ取得
	 */
	public getInitCacheData(mngr: MypageWizardMngr): ItscomHomeChangeWizardModel {
		let cacheData = new ItscomHomeChangeWizardModel();
		cacheData.m_ContractModel = new ContractPlanModel();
		cacheData.m_Contract = new Contract();
		cacheData.m_BaseInfo = new BaseInfo();
		cacheData.m_OptionListModel = new Array();
		return cacheData;
	}

	/**
	 * 画面ウィザード完了APIモデル取得
	 */
	public getRegistApiModel(mngr: MypageWizardMngr, view: MypageBaseView): RegistInfoRequest{
		const req: RegistInfoRequest = new RegistInfoRequest();
		req.customerId = mngr.getParams().customerId;
		let cacheData: ItscomHomeChangeWizardModel = mngr.getCache();
		// お客様メール
		const mailconstants = MAIL_CONSTANTS.find (mail => {
			return mail.view === 'IH_変更';
		});
		req.customerMailId = mailconstants.customerMail;
		req.systemInfo = new InquiryMailInfo();
		req.systemInfo.contractId = cacheData.m_BaseInfo.contractId;
		req.systemInfo.optionRentalList = new Array();
		const optionBuyList = cacheData.m_OptionListModel.filter(row => row.quantity2);
		if (ObjectUtil.isNotNullOrUndefined(optionBuyList) && optionBuyList.length > 0) {
			req.systemInfo.optionBuyName = optionBuyList[0].option + '  ' + optionBuyList[0].quantity2 + '個';
			// 業務部門メール,お問い合わせ
			const mailconstants = MAIL_CONSTANTS.find (mail => {
				return mail.view === 'IH_変更_購入';
			});
			req.systemMailId = mailconstants.systemMail;
			req.inquiryId = mailconstants.inquiryMail;
		}
		else {
			// 業務部門メール,お問い合わせ
			const mailconstants = MAIL_CONSTANTS.find (mail => {
				return mail.view === 'IH_変更_レンタル';
			});
			req.systemMailId = mailconstants.systemMail;
			req.inquiryId = mailconstants.inquiryMail;
		}
		const optionRentalList = cacheData.m_OptionListModel.filter(row => row.quantity1);
		if (optionRentalList && optionRentalList.length > 0) {
			// const smartLight = mngr.getMCommonList(view, 'srvsmart', 'count_type_ko', null, null);
			// for (const option of optionRentalList) {
			// 	if (option.childCd === smartLight[0].commonValue) 
			// 		req.systemInfo.optionRentalList.push(option.option + '  ' + option.quantity1 + '個');
			// 	else
			// 		req.systemInfo.optionRentalList.push(option.option + '  ' + option.quantity1 + '台');
			// }
			for (const option of optionRentalList)
				req.systemInfo.optionRentalList.push(option.option + '  ' + option.quantity1 + option.rentalUnit);
		}
		return req;
	}
	// --------------------------------------------------------------------------
}
/**
 * 画面ウィザードAPI用モデル
 */
export class ItscomHomeChangeWizardModel {
	// public 変数	-----------------------------------------------------------
	public m_ContractModel:	ContractPlanModel						= null;	// 契約情報(全量)※要らないかも
	public m_Contract: Contract										= null; // 契約情報(変更対象)
	public m_BaseInfo: BaseInfo										= null;	// 基本情報
	public m_OptionListModel: ItscomHomeOptionListModel[]			= null;	// ItscomHomeオプション選択モデル
	public m_OptRivComfirmListModel: ItscomHomeOptRivComfirmList[] 	= null;	// ItscomHomeオプション確認モデル
	public m_CompleteModel: CompleteModel 							= null; // 完了モデル
	// ------------------------------------------------------------------------
}

