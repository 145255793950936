import { WprBaseScopeViewInfo } from '../../../../wpr-framework/view/WprBaseScopeViewInfo';
import { WprViewMode } from '../../../../wpr-framework/view/deco/WprViewMode';
import { WprDI_ScopeModel } from '../../../../wpr-framework/view/di/WprDI_ScopeModel';
import { ReadCheckMode } from './mode/ReadCheckMode';
import { HDocConfirm } from '../../../service/models/entity/HDocConfirm';
import { WprListData } from '../../../../wpr-framework/view/deco/WprListData';
import { MGeneral } from '../../../service/models/entity/MGeneral';
import { FileUtil } from '../../../common/util/FileUtil';
import { WprModel } from '../../../../wpr-framework/view/deco/WprModel';
import { FileInfo } from '../../../service/base/models/common/FileInfo';
import { PDFDialogResultData, PDFDialogSendData } from '../../dialog/p_d_f_dialog/PDFDialogView';
import { CompleteDialogResultData, CompleteDialogSendData } from '../dialog/complete_dialog/CompleteDialogView';
import { Application } from '../../../service/models/apimodels/fuse/soap/Application';
import { ContractConfirmService } from '../../../service/ContractConfirmService';
import { GetConfirmDetailRequest } from '../../../service/models/dto/contractConfirm/GetConfirmDetailRequest';
import { UpdateDetailRequest } from '../../../service/models/dto/contractConfirm/UpdateDetailRequest';
import { MypageBaseView } from '../../base/MypageBaseView';
import { MGeneralCheckInfo } from '../../../models/MGeneralCheckInfo';
import { ObjectUtil } from '../../../common/util/ObjectUtil';

/**
 * 契約内容の確認
 */
export class S_01_004View extends MypageBaseView {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() { super('S_01_004View'); }
	// --------------------------------------------------------------------------

	// モデル  ------------------------------------------------------------------
	@WprListData('APList')
	private m_APList: MGeneralCheckInfo[] = null;	// AP利用規約
	@WprListData('IEList')
	private m_IEList: MGeneralCheckInfo[] = null;	// 重要事項確認
	// --------------------------------------------------------------------------

	private m_Application: Application = null;	// 書面確認履歴
	public m_document: string = null;	// PDFbase64
	// private m_cnt: number = 0;
	// private m_listLength: number = 0;

	// public pdfData = null;
	// private m_MGeneralList: MGeneral[]	= null;
	// private m_isVisibility: boolean = false;
	// private m_isHikari: boolean = false;
	// private m_isAp: boolean = false;

	// private m_dialogCnt: boolean = false;
	// --------------------------------------------------------------------------

	// サービス  ------------------------------------------------------------------
	private m_ContractConfirmService: ContractConfirmService = new ContractConfirmService();	// MyPageRenewalサービス
	// --------------------------------------------------------------------------

	// サブビュー/モード  ---------------------------------------------------------
	@WprViewMode
	private m_ReadCheckMode = new ReadCheckMode();	// 閲覧確認
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * コントロール定義設定
	 */
	public onRegisterConfig(): void {
		this.addConfig('c_Confirm', '契約内容に同意', null);
		this.addConfig('b_Next', '同意確認', null);
		this.addListConfig('name1', '名称1', 'APList.mGeneral');
		this.addListConfig('name1', '名称1', 'IEList.mGeneral');
	}
	/**
	 * アクション登録
	 */
	public onRegisterAction(): void {
		this.addListAction('b_Show', 'APList', this.onB_Show);
		this.addListAction('b_Show', 'IEList', this.onB_Show);
		this.addAction('b_Next', this.onB_Next);
		this.addAction('b_DialogPDF', this.onB_DialogPDF);
	}
	/**
	 * ビュー表示通知
	 */
	public onShowView(): void {
		this.registAccessLog();
		this.setStorageItem('b_url', window.location.href)
		// iframe
		let req: GetConfirmDetailRequest = new GetConfirmDetailRequest();
		const app: Application = new Application();
		app.customerID = this.getCookie("customerId");
		app.appID = this.getCookie("appID");
		req.application = app;
		this.m_Application = app;
		// req.application = new Application();
		// req.application.customerID = '20010200593';
		this.m_ContractConfirmService.getConfirmDetail(this, req, (result) => {
			if (result) {
				// 契約内容確認書
				if (result.confirmedFlg === true) {
					this.pushPath("/s003");
				}
				const file = new FileInfo();
				file.dataUrl = 'data:application/pdf;base64,' + result.document.data ;
				const dataUrl = FileUtil.createBlobUrl(file.dataUrl);
				this.m_document = dataUrl;

				// 重要事項説明
				this.m_APList = new Array();
				if (ObjectUtil.isNotNullOrEmptyArray(result.apList)) {
					result.apList.forEach(val => {
						const info: MGeneralCheckInfo = new MGeneralCheckInfo();
						info.mGeneral = val;
						this.m_APList.push(info);
					});
				}
				// AP利用規約
				this.m_IEList = new Array();
				if (ObjectUtil.isNotNullOrEmptyArray(result.importantList)) {
					result.importantList.forEach(val => {
						const info: MGeneralCheckInfo = new MGeneralCheckInfo();
						info.mGeneral = val;
						this.m_IEList.push(info);
					});
				}
				this.setViewMode('IEリスト', this.m_IEList.length > 0 ? '有' : '無');
				this.setViewMode('APリスト', this.m_APList.length > 0 ? '有' : '無');
				this.setListData('IEList', this.m_IEList);
				this.setListData('APList', this.m_APList);
				this.setViewMode('契約内容確認書', '未表示');
				// this.m_listLength = result.importantList?.length + result.apList?.length;
			}
		})
	}
	// --------------------------------------------------------------------------

	// アクション  ---------------------------------------------------------------
	/**
	 * 重要事項確認等出力
	 */
	public onB_Show(param: any, item: any): void {
		const data: MGeneralCheckInfo = item as MGeneralCheckInfo;
		data.checked = true;

		const mgeneral: MGeneral = data.mGeneral;
		// 重要事項説明の場合：リンク別タブ表示
		if (mgeneral.codeTypeCd === '010') {
			window.open(mgeneral.name2);
		}
		// AP利用規約の場合：PDF
		else if (mgeneral.codeTypeCd === '020') {
			FileUtil.createPDF(mgeneral.name1, mgeneral.name2);

		}

		// const data: MGeneral = item as MGeneral;
		// let button1 = document.getElementById(data.generalId.toString());
		// button1.style.backgroundColor = "#0D259F";
		// button1.style.color = "#f9f9f9"
		// this.m_cnt++;
		// if (this.m_cnt === this.m_listLength)
		// 	this.setViewMode('確認事項', '全チェック済');
		

		this.setViewMode('確認事項', this.isAllCheckedList() ? '全チェック済' : null);
		this.refreshView();
	}



	/**
	 * 同意
	 */
	public onB_Next(param: any): void {
		const req: UpdateDetailRequest = new UpdateDetailRequest();
		const hdoc: HDocConfirm = new HDocConfirm()
		hdoc.customerId = this.m_Application.customerID;
		hdoc.applicationFormId = this.m_Application.appID;
		hdoc.rootApplicationFormId = this.m_Application.appID;
		req.hDocConfirm = hdoc;
		this.m_ContractConfirmService.updateDetail(this, req, (result) => {
			if (result) {
				if (result.paymentFlg === false) {
					this.pushPath('/s003');
				}
				else {
					const sendData: CompleteDialogSendData = new CompleteDialogSendData();
					sendData.url = this.getOldUrl();
					this.showDialog('ダイアログ', sendData, (status, result) => {
						const resultData: CompleteDialogResultData = result as CompleteDialogResultData;
					});
				}
			}
		});
	}
	/**
	 * 契約内容確認書の確認はこちら
	 */
	public onB_DialogPDF(param: any): void {
		const sendData: PDFDialogSendData = new PDFDialogSendData();
		sendData.base64Data = this.m_document;
		this.showDialog('pdfダイアログ', sendData, (status, result) => {
			const resultData: PDFDialogResultData = result as PDFDialogResultData;
			if (status === true) {

			}
			else {
				const isVisibilityMode: string = this.getViewMode('PDFスクロール');
				if (isVisibilityMode !== '済') {
					const isVisibility: boolean = resultData.isVisibility;
					this.setViewMode('PDFスクロール', isVisibility ? '済' : '未');
					this.refreshView();
				}
			}
		});
	}

	// --------------------------------------------------------------------------

	/**
	 * 重要事項説明・AP利用規約　全て確認済みであるか
	 * @returns 
	 */
	private isAllCheckedList(): boolean {
		// 重要事項説明
		this.m_IEList.forEach(val => {
			if (!val.checked) return false;
		});

		// AP利用規約
		this.m_APList.forEach(val => {
			if (!val.checked) return false;
		});
		// 全てチェックされていた場合、true
		return true;
	}
}
