import { BaseService } from './base/BaseService';
import { WprBaseViewCore } from '../../wpr-framework/view/WprBaseViewCore';
import { GetNhkRequest } from './models/dto/mypagerenewal/GetNhkRequest';
import { GetNhkResponse } from './models/dto/mypagerenewal/GetNhkResponse';

/**
 * Nhk団体一括サービス
 */
export class NhkDiscountService extends BaseService {
	// サービス メソッド  --------------------------------------------------------
	/**
	 * NHK団体一括情報取得
	 * @param viewCore ビュー情報
	 * @param request リクエスト情報
	 * @param resMethod 結果処理メソッド
	 */
	public getNhk(viewCore: WprBaseViewCore, request: GetNhkRequest, resMethod: (result: GetNhkResponse) => void): void {
		return this.doPost(viewCore, '/nhk/getNhk', request, resMethod);
	}
	// --------------------------------------------------------------------------
}
