import { TRUE } from 'node-sass';
import { ReactNode } from 'react';
import { WprActionInfo } from '../action/WprActionInfo';
import { IWprControlAuth } from '../component/auth/IWprControlAuth';
import { MatTab } from '../component/controls/material/MatTab';
import { IWprError, IWprServiceError } from '../component/error/IWprError';
import { IWprMessage } from '../component/message/IWprMessage';
import { IWprSnackbar } from '../component/message/IWprSnackbar';
import { IWprControlMode } from '../component/mode/IWprControlMode';
import { IWprLoadWait } from '../component/mode/IWprLoadWait';
import { WprRowInfo } from '../component/props/WprRowInfo';
import { WprSwitch } from '../component/route/WprSwitch';
import { IWprAccordionMode } from '../mode/accordion/IWprAccordionMode';
import { WprAccordionModeInfo } from '../mode/accordion/WprAccordionModeInfo';
import { IWprTabMode } from '../mode/tab/IWprTabMode';
import { WprTabModeInfo } from '../mode/tab/WprTabModeInfo';
import { IWprTableContainer } from '../mode/table/IWprTableContainer';
import { IWprTablePagination } from '../mode/table/IWprTablePagination';
import { IWprTableRow } from '../mode/table/IWprTableRow';
import { IWprTableSortMode } from '../mode/table/IWprTableSortMode';
import { WprSortModeInfo, WprTableSortModeInfo } from '../mode/table/WprTableSortModeInfo';
import { WprBaseViewMode } from '../mode/WprBaseViewMode';
import { WprBaseViewModeModeInfo } from '../mode/WprBaseViewModeModeInfo';
import { WprBaseViewMultiMode } from '../mode/WprBaseViewMultiMode';
import { WprMultiMode } from '../mode/WprMultiMode';
import { WprKeyValue } from '../model/WprKeyValue';
import { WprKeyValueMap } from '../model/WprKeyValueMap';
import { WprBaseGlobalScopeInfo } from '../scope/WprBaseGlobalScopeInfo';
import { WprBaseScopeInfo } from '../scope/WprBaseScopeInfo';
import { WprScopeUpdatePropertyInfo } from '../scope/WprScopeUpdatePropertyInfo';
import { WprReflectUtil } from '../util/WprReflectUtil';
import { WprBaseViewCoreComponent } from './component/WprBaseViewCoreComponent';
import { WprControlInfoMap } from './control/map/WprControlInfoMap';
import { WprControRefreshMap } from './control/map/WprControRefreshMap';
import { WprControlInfo } from './control/WprControlInfo';
import { WprBaseDIManage } from './di/WprBaseDIManage';
import { IWprDialog } from './dialog/IWprDialog';
import { WprBaseDialogViewInfo } from './dialog/WprBaseDialogViewInfo';
import { WprDialogInfo } from './dialog/WprDialogInfo';
import { IWprViewState } from './props/IWprViewState';
import { IWprUrlParam } from './route/IWprUrlParam';
import { WprTabChildViewMapInfo } from './tab/WprTabChildViewMapInfo';
import { WprTabReceiveDataInfo } from './tab/WprTabReceiveDataInfo';
import { WprValidErrorInfo } from './validation/WprValidErrorInfo';
import { WprBaseSubView } from './WprBaseSubView';
import { WprBaseViewCore } from './WprBaseViewCore';

/**
 * ビュー情報基本クラス
 */
export abstract class WprBaseViewInfo extends WprBaseViewCore {
	// private 変数  ------------------------------------------------------------
	private m_Name: string											= null;										// ビュー名称
	private m_IsMount: boolean 										= false;									// なぜか2個作られてエラーになる現象を回避するために使用する
	private m_IsScopeInit: boolean 									= false;									// スコープ初期化フラグ
	private m_Parent: WprBaseViewInfo								= null;										// 親ビュー情報						
	private m_ControlInfoMap: WprControlInfoMap						= new WprControlInfoMap();					// コントロールマップ
	private m_Component: WprBaseViewCoreComponent<any>				= null;										// ビューコンポーネント情報
	private m_ViewDataMap: Map<string, any>							= new Map<string, any>();					// ビューデータマップ
	private m_ViewModeDataMap: Map<string, any>						= new Map<string, any>();					// ビューモードマップ
	private m_TabModeMap: Map<string, WprTabModeInfo>				= new Map<string, WprTabModeInfo>();		// タブモードマップ
	private m_AccordionModeMap: Map<string, WprAccordionModeInfo>	= new Map<string, WprAccordionModeInfo>();	// アコーディオンモードマップ
	private m_TableSortModeMap: Map<string, WprTableSortModeInfo>	= new Map<string, WprTableSortModeInfo>();	// テーブルソートモードマップ
	private m_TablePainationMap: Map<string, IWprTablePagination[]>	= new Map<string, IWprTablePagination[]>();	// ページングコンポーネントマップ
	private m_TableContainerMap: Map<string, IWprTableContainer[]>	= new Map<string, IWprTableContainer[]>();	// テーブルコンテナコンポーネントマップ
	private m_KeyValueMap: Map<string, WprKeyValueMap>				= new Map<string, WprKeyValueMap>();		// KeyValueリストマップ
	private m_SubViewMap: Map<string, WprBaseSubView> 				= new Map<string, WprBaseSubView>();		// サブビュー情報マップ
	private m_UpdateProList: WprScopeUpdatePropertyInfo[] 			= new Array();								// スコープ更新プロパティ情報リスト
	private m_GlobalScopeInfo: WprBaseGlobalScopeInfo				= null;										// グローバルスコープ情報
	private m_UpdateGlobalProList: WprScopeUpdatePropertyInfo[]		= new Array();								// グローバルスコープ更新プロパティ情報リスト
	private m_ModeComponentList: IWprControlMode[]					= new Array();								// モードコンポーネントリスト
	private m_AuthComponentList: IWprControlAuth[]					= new Array();								// 権限コンポーネントリスト
	private m_LoadWaitComponentMap: Map<string, IWprLoadWait[]>		= new Map<string, IWprLoadWait[]>();		// ロード待ちコンポーネントマップ
	private m_ChildViewList: WprBaseViewInfo[]						= new Array();								// 子ビュー情報リスト
	private m_ChildViewMap: Map<string, WprBaseViewInfo>			= new Map<string, WprBaseViewInfo>();		// 子ビュー情報マップ
	private m_ResizeEventMethod: any								= null;										// リサイズイベントメソッド
	private m_ScrollEventMethod: any								= null;										// スクロールイベントメソッド
	private m_IsRouteView: boolean									= true;										// ルートビューフラグ(ルートとして使用しない場合はfalse)
	private m_DialogInfoMap: Map<string, WprDialogInfo>				= new Map<string, WprDialogInfo>();			// ダイアログ情報マップ
	private m_MessageComponent: IWprMessage 						= null;										// メッセージコンポーネント
	private m_SnackbarComponent: IWprSnackbar						= null;										// スナックバー
	private m_IsInit: boolean 										= false;									// 初期化中フラグ
	private m_SendDataMap: Map<string, WprViewSendDataInfo>			= new Map<string, WprViewSendDataInfo>();	// 送信データマップ
	private m_RowData: any											= null;										// 行データ
	private m_UserAgentRender: string								= null;										// UserAgentレンダ―
	private m_IsStateLock: IWprViewState							= null;										// ステータス更新ロックオブジェクト
	private m_StateLockCnt: number 									= 0;										// ステータスロック数
	private m_IsWaitChlidShowView: boolean							= false;									// 子ビュー表示待ちフラグ
	private m_TabChildViewMap: WprTabChildViewMapInfo				= new WprTabChildViewMapInfo(this);			// タブ子ビュー情報マップ情報
	private m_IsWaitReder: boolean									= false;									// レンダー待ちフラグ
	private m_TabDispCount											= 0;										// タブ表示数
	private m_ChildsetModeList: WprViewSetChildModeInfo[]			= new Array();								// 子要素モード設定情報リスト
	private m_WaitShowChildList: WprBaseViewInfo[]					= new Array();								// ShowView待ち子要素情報リスト
	private m_WaitAction: () => void								= null;										// stateLock待ちアクション
	private m_TabMap: Map<string, MatTab> 							= new Map<string, MatTab>();				// Tabマップ
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** ビュー名称 */
	public get name(): string 								{ return this.m_Name; 					}
	/** マウントフラグ */
	public get isMount(): boolean 							{ return this.m_IsMount; 				}	public set isMount(isMount: boolean) 			{ this.m_IsMount = isMount; }
	/** 親ビュー情報 */
	public get parentView(): WprBaseViewInfo 				{ return this.m_Parent; 				}	public set parentView(parent: WprBaseViewInfo) 	{ this.m_Parent = parent; 	}
	/** メインビュー情報 */
	public get viewInfo(): WprBaseViewInfo 					{ return this; 							}
	/** コントロールマップ */
	public get controlInfoMap(): WprControlInfoMap 			{ return this.m_ControlInfoMap; 		}
	/** サブビュー情報マップ */
	public get subViewMap(): Map<string, WprBaseSubView> 	{ return this.m_SubViewMap; 			}
	/** グローバルスコープ情報 */	
	public get globalScopeInfo(): WprBaseGlobalScopeInfo 	{ return this.m_GlobalScopeInfo; 		}	public set globalScopeInfo(sinfo: WprBaseGlobalScopeInfo) { this.m_GlobalScopeInfo = sinfo;		}
	/** ビュープロパティ */	
	public get props(): any 								{ return this.getProps(); 				}
	/** ビューステート */	
	public get state(): any 								{ return this.m_Component.state; 		}
	/** ルートビューフラグ */	
	public get isRouteView(): boolean 						{ return this.m_IsRouteView; 			}	public set isRouteView(isRoute: boolean) 	{ this.m_IsRouteView = isRoute;		}
	/** 初期化中フラグ */	
	public get isInit(): boolean 							{ return this.m_IsInit; 				}	public set isInit(isInit: boolean) 			{ this.m_IsInit = isInit;			}
	/** 子ビュー情報リスト */	
	public get ChildViewList(): WprBaseViewInfo[]			{ return this.m_ChildViewList;			}
	/** ステータスロックフラグ */
	public get isStateLock(): boolean 						{ return (this.m_IsStateLock != null); 	}
	/** ダイアログフラグ */
	public get isDialog(): boolean 							{ return false; 						}
	/** 変更フラグ */
	public get dirty(): boolean 							{ return this.getDirty(); 				}
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	public constructor(name: string) {
		super(name);
		this.m_Name = name;
	}
	// --------------------------------------------------------------------------

	// public メソッド  ----------------------------------------------------------
	/**
	 * 初期化処理
	 */
	public initialize(): void {
		this.m_IsInit = true;
		try {
			this.initSubViewInfo();
			this.registerConfig();
			this.registerAction();
			this.m_SubViewMap.forEach((subView, key, map) => {
				subView.registerConfig();
				subView.registerAction();
			});
			this.setInjection();
			this.setControlList();
			this.initViewModeInfo();
			this.initExtentionInfo();
			this.m_SubViewMap.forEach((subView, key, map) => {
				subView.initViewModeInfo();
				subView.initExtentionInfo();
			});
			if (this.parentView) {
				const sendData = this.parentView.getWaitSendData(this.props.name);
				if (sendData)
					this.receiveData(sendData.dataName, sendData.data);
			}
		}
		catch (ex) {
			this.addErrorLog(`ビューの初期化で例外が発生しました。[${this.objectName}]`, ex);
		}
		finally {
			this.m_IsInit = false;
			// this.FW.view.addViewInfo(this);
		}
	}

	/**
	 * ビュー表示処理
	 * @param isMount マウントフラグ
	 */
	public showView(isMount: boolean = true): void {
		if (isMount === true) {
			this.startWindowEvent();
			this.setScopeInfo();
			this.FW.view.addViewInfo(this);
		}
		this.setInjection();
		this.scrollToTop();
//		this.setStartFocus();
		this.clearWaitSendData();
		this.setMoveViewData();
		try {
			if (this.parentView) {
				const sendData = this.parentView.getWaitSendData(this.props.name);
				if (sendData)
					this.receiveData(sendData.dataName, sendData.data);
			}
			this.onBaseShowView();
			this.addDebugInfoLog('onShowView()', '== View onShowView() ==');
			this.onShowView();
			this.FW.view.addShowViewInfo(this);
			this.setInjection();
			this.onEndShowView();
			this.m_SubViewMap.forEach((subView, key, map) => {
				subView.onBaseShowView();
				subView.onShowView();
			});
			if (this.m_IsStateLock === null) {
				this.setChildDIObject();
				this.m_ChildViewList.forEach(child => {
					child.showView(false);
				});
			}
			else {
				this.m_IsWaitChlidShowView = true;
				this.addInfoLog('== Wait child ShowView ==');
			}
		}
		catch (ex) {
			this.addErrorLog(`ビューの表示処理(showView)で例外が発生しました。[${this.objectName}]`, ex);
		}
		this.initOtherMode();
		if (this.getDocumentTitle() != null)
			document.title = this.getDocumentTitle();
		this.showMode();
		if (this.FW.view.startValidate)
			this.checkValidate(this.FW.view.startValidate, true, false, true);
	}
	// --------------------------------------------------------------------------

	// virtual メソッド  ---------------------------------------------------------
	/**
	 * スコープ情報取得
	 * @returns スコープ情報
	 */
	public getScopeInfo(): WprBaseScopeInfo {
		return null;
	}

	/**
	 * ビュースコープ通知受信処理
	 * @param notifyName 通知名
	 * @param info 通知情報
	 */
	public onNotifyScopeView(notifyName: string, info: any): void {
	}

	/**
	 * 編集チェック判定
	 * @param name アクション又はリスト名又はタブモード
	 * @param parent アクション又はリスト名又はタブモードの親
	 * @returns falseの場合、編集中のチェックを行わない
	 */
	public onCheckEdit(name: string, parent: string): boolean {
		return true;
	}

	/**
	 * ドキュメントタイトル取得
	 * @returns ドキュメントタイトル
	 */
	public getDocumentTitle() : string {
		return null;
	}

	/**
	 * 表示状態取得
	 */
	public isShow(): boolean {
		return true;
	}
	// --------------------------------------------------------------------------

	// override メソッド  -------------------------------------------------------
	/**
	 * 終了処理
	 */
	public terminate(): void {
		if (this.isDialog === false) {
			this.stopWindowResizeEvent();
			this.stopWindowScrollEvent();
		}
		/* ATODE
		this.clearTableInfo();
		this.m_SubViewMap.forEach((subView, key, map) => {
			subView.clearTableInfo();
		});
		*/
		this.FW.view.deleteViewInfo(this);
		if (this.isDialog === true)
			return;
		super.terminate();
		this.m_Component = null;
		this.m_Parent	= null;
		this.m_ControlInfoMap = null;
		this.m_ViewDataMap.clear();
		this.m_ViewModeDataMap.clear();
		this.m_TabModeMap.clear();
		this.m_AccordionModeMap.clear();
		this.m_TableSortModeMap.clear();
		this.m_TablePainationMap.clear();
		this.m_TableContainerMap.clear();
		this.m_KeyValueMap.clear();
		this.m_SubViewMap.clear();
		this.m_UpdateProList = new Array();
		this.m_GlobalScopeInfo = null;
		this.m_UpdateGlobalProList = new Array();
		this.m_ModeComponentList = new Array();
		this.m_AuthComponentList = new Array();
		this.m_LoadWaitComponentMap.clear();
		this.m_ChildViewList = new Array();
		this.m_ChildViewMap.clear();
		this.m_DialogInfoMap.clear();
		this.m_MessageComponent = null;
		this.m_SnackbarComponent = null;
		this.m_SendDataMap.clear();
		this.m_RowData = null;
		this.m_IsStateLock = null;
//		this.m_TabChildViewMap = null;
		this.m_ChildsetModeList = new Array();
		this.m_WaitShowChildList = new Array();
		this.m_WaitAction = null;
	}

	/**
	 * モデル更新処理
	 * @param name コントロール名称
	 */
	public refreshModel(name?: string): void {
		if (this.isStartValid === true) {
			this.addErrorLog('validationチェック中にrefreshModel()が呼び出されました。refreshModel()は、無効になります。');
			return;
		}
		if (name === undefined) {
			this.addDebugInfoLog('refreshModel()');
			this.m_ControlInfoMap.refreshModel(this, '@@all');
			this.refreshListModel();
		}
		else {
			this.addDebugInfoLog(`refreshModel('${name}')`);
			this.m_ControlInfoMap.refreshModel(this, name);
		}
		this.updateMode();
		this.refreshReadOnlyControl(name);
	}

	/**
	 * View(FormControlの値)更新処理
	 * @param name コントロール名称
	 */
	public refreshView(name?: string): void {
		if (this.isStartValid === true) {
			this.addErrorLog('validationチェック中にrefreshView()が呼び出されました。refreshView()は、無効になります。');
			return;
		}
		if (name === undefined) {
			this.addDebugInfoLog('refreshView()');
			this.m_ControlInfoMap.refreshView(this, '@@all');
			this.refreshListView();
		}
		else {
			this.addDebugInfoLog(`refreshView('${name}')`);
			this.m_ControlInfoMap.refreshView(this, name);
		}
		this.updateMode();
		this.refreshReadOnlyControl(name);
	}

	/**
	 * 権限再設定処理
	 * @param name コントロール名
	 */
	public refreshAuth(name?: string): void {
		if (name === undefined) {
			this.m_ControlInfoMap.refreshAuth(this, '@@all');
		}
		else {
			this.addDebugInfoLog(`refreshAuth('${name}')`);
			this.m_ControlInfoMap.refreshAuth(this, name);
		}
		this.refreshListAuth();
	}

	/**
	 * コントロール値変更処理
	 * @param name コントロール名
	 * @param val 値
	 */
	public changeValue(name: string, val: any): void {
		super.changeModeValue(name, val);
		super.changeExtentionValue(name, val);
		this.m_SubViewMap.forEach((subView, key, map) => {
			subView.changeModeValue(name, val);
			subView.changeExtentionValue(name, val);
		});
	}

	/**
	 * リストコントロール値変更処理
	 * @param name コントロール名
	 * @param row 行データ
	 * @param val 値
	 */
	public changeListValue(name: string, row: any, val: any): void {
		super.changeModeValue(name, val);
		// super.changeExtentionValue(name, val);
		this.m_SubViewMap.forEach((subView, key, map) => {
			subView.changeModeValue(name, val);
			// subView.changeExtentionValue(name, val);
		});
	}

	/**
	 * ビューモード変更処理
	 * @param mode モード情報
	 * @param oldMode 古いモード
	 * @param newMode 新しいモード
	 */
	public changeViewMode(mode: WprBaseViewMode, oldMode: string, newMode: string): void {
		super.changeMode(mode, oldMode, newMode);
		this.m_SubViewMap.forEach((subView, key, map) => {
			subView.changeMode(mode, oldMode, newMode);
		});
		this.setViewModeData(mode.name, newMode);
	}

	/**
	 * 複数ビューモード変更処理
	 * @param oldMode 変更前モード
	 * @param newMode 変更後モード
	 */
	public changeViewMultiMode(mode: WprBaseViewMultiMode, oldMode: WprMultiMode, newMode: WprMultiMode): void {
		super.changeMultiMode(mode, oldMode, newMode);
		this.m_SubViewMap.forEach((subView, key, map) => {
			subView.changeMultiMode(mode, oldMode, newMode);
		});
		this.setViewModeData(mode.name, newMode.getModeList());
	}

	/**
	 * ビューデータ設定処理
	 * @param name: ビューデータ名
	 * @param value: ビューデータ
	 */
	public setViewData(name: string, value: any): void {
		this.addDebugInfoLog(`setViewData('${name}')`);
		this.m_ViewDataMap.set(name, value);
		if (this.isMount === false)
			return;
		if (!this.m_IsStateLock) {
			this.m_Component.setState({
				data: this.getViewDataState()
			});
		}
		else {
			this.m_IsStateLock.data = this.getViewDataState();
			this.addInfoLog('== Wait data ==');
		}
	}

	/**
	 * ビューデータ取得処理
	 * @param name ビューデータ名
	 * @returns ビューデータ
	 */
	public getViewData(name: string): any {
		if (this.m_ViewDataMap.has(name) === true)
			return this.m_ViewDataMap.get(name);
		return null;
	}

	/**
	 * ビューモード設定処理
	 * @param name ビューモード名
	 * @param mode モード
	 * @param setChild 子Viewモード設定フラグ
	 * @returns falseの場合、モードは変化がない
	 */
	public setViewMode(name: string, mode: any, setChild: boolean = false): boolean {
		if (this.checkModeName(name) === true) {
			this.addErrorLog(`setViewMode：ビューモード名が重複しています。[${name}]`);
			return false;
		}
		this.addDebugInfoLog(`setViewMode('${name}'. '${mode}')`);
		let rtn = this.setViewModeData(name, mode);
		if (setChild === true) {
			if (this.setChildViewMode(name, mode) === false)
				rtn = false;
		}
		this.viewModeMap.forEach((minfo) => {
			const mmode = minfo as WprBaseViewModeModeInfo<any>;
			if (mmode) {
				if (mmode.ViewModeName === name)
					this.updateMode();
			}
		});
		return rtn;
	}

	/**
	 * 子ビューモード設定処理
	 * @param name ビューモード名
	 * @param mode モード
	 * @param childName 子ビュー名
	 * @returns falseの場合、モードは変化がない（一部変化がない）
	 */
	public setChildViewMode(name: string, mode: any, childName?: string): boolean {
		if (this.m_IsStateLock != null) {
			this.m_ChildsetModeList.push(new WprViewSetChildModeInfo(name, mode, childName));
			return;
		}
		if (childName) {
			if (this.m_ChildViewMap.has(childName) === false) {
				this.addErrorLog(`refreshChildView：子ビュー名に誤りがあります。[${name}]`);
				return false;
			}
			return this.m_ChildViewMap.get(childName).setViewMode(name, mode, true);
		}
		else {
			let rtn = true;
			this.m_ChildViewList.forEach(child => {
				if (child.setViewMode(name, mode, true) == false)
				rtn = false;
			});
			return true;
		}
	}

	/**
	 * 親ビューモード設定処理
	 * @param name ビューモード名
	 * @param mode モード
	 * @returns falseの場合、モードは変化がない
	 */
	public setParentViewMode(name: string, mode: any): boolean {
		if (this.m_Parent) {
			this.m_Parent.setViewMode(name, mode);
			return true;
		}
		return false;
	}

	/**
	 * 親ビューモード取得処理
	 * @param name ビューモード名
	 * @returns 
	 */
	public getParentViewMode(name: string): any {
		if (this.m_Parent) {
			const rtn = this.m_Parent.getViewMode(name);
			if (rtn)
				return rtn;
			return this.m_Parent.getParentViewMode(name);
		}
		return null;
	}

	/**
	 * ビューモード取得処理
	 * @param name ビューモード名
	 * @returns ビューモード
	 */
	public getViewMode(name: string): any {
		if (this.m_ViewModeDataMap.has(name) === true)
			return this.m_ViewModeDataMap.get(name);
		return null;
	}

	/**
	 * タブモード設定処理
	 * @param name: タブモード名
	 * @param mode モード
	 * @returns falseの場合、モードは変化がない
	 */
	public setTabMode(name: string, mode: string): boolean {
		this.addDebugInfoLog(`setTabMode('${name}'. '${mode}')`);
		if (this.m_TabChildViewMap.checkChangeTab(this, name, mode) === true) {
			if (this.FW.edit.checkEdit(mode, name, () => {
					this.setTabMode(name, mode);
				}) === false) {
				return true; 
			}
			this.addInfoLog(`== setTabMode == [${name}:${mode}]`);
			this.m_TabChildViewMap.changeTab(this, name, mode);
			if (this.m_TabModeMap.has(name) === false)
				this.m_TabModeMap.set(name, new WprTabModeInfo(mode));
			const info = this.m_TabModeMap.get(name);
			if (info.view == null)
				info.initialize(this);
			if (info.mode !== mode) {
				const oldMode = info.mode;
				info.setMode(mode);
				this.onChangeTabMode(info.name, oldMode, mode);
				this.m_SubViewMap.forEach((subView, key, map) => {
					subView.onChangeTabMode(info.name, oldMode, mode);
				});
				return true;
			}
		}
		return false;
	}

	/**
	 * タブインデクス取得
	 * @param name: タブモード名
	 * @param mode モード
	 * @returns タブインデクス
	 */
	public getTabIndex(name: string, mode: string): number {
		let index = 0;
		if (this.m_TabModeMap.has(name) === true) {
			const info = this.m_TabModeMap.get(name);
			return info.getTabIndex(mode);
		}
		else {
			this.addErrorLog(`setTabIndex：タブモード名に誤りがあります。[${name}]`);
		}
		return 0;
	}

	/**
	 * タブモード取得処理
	 * @param name タブモード名
	 * @returns タブモード
	 */
	public getTabMode(name: string): string {
		if (this.m_TabModeMap.has(name) === true) {
			const info = this.m_TabModeMap.get(name);
			return info.mode;
		}
		else {
			this.addErrorLog(`getTabMode：タブモード名に誤りがあります。[${name}]`);
		}
		return null;
	}

	/**
	 * アコーディオンモード設定処理
	 * @param name: アコーディオンモード名
	 * @param mode モード
	 * @param expanded 展開状態
	 * @returns falseの場合、モードは変化がない
	 */
	public setAccordionMode(name: string, mode: string, expanded: boolean): boolean {
		if (this.m_AccordionModeMap.has(name) === true) {
			if (expanded === false)
				mode = '';
			const info = this.m_AccordionModeMap.get(name);
			if (info.mode !== mode) {
				const oldMode = info.mode;
				info.setMode(mode);
				this.onChangeAccordionMode(info.name, oldMode, mode);
				this.m_SubViewMap.forEach((subView, key, map) => {
					subView.onChangeAccordionMode(info.name, oldMode, mode);
				});
				return true;
			}
		}
		else {
			this.addErrorLog(`setAccordionMode：アコーディオンモード名に誤りがあります。[${name}]`);
		}
		return false;
	}

	/**
	 * アコーディオンモード取得処理
	 * @param name アコーディオンモード名
	 * @returns アコーディオンモード
	 */
	public getAccordionMode(name: string): string {
		if (this.m_AccordionModeMap.has(name) === true) {
			const info = this.m_AccordionModeMap.get(name);
			return info.mode;
		}
		else {
			this.addErrorLog(`getAccordionMode：アコーディオンモード名に誤りがあります。[${name}]`);
		}
		return null;
	}

	/**
	 * テーブルソートモード設定処理
	 * @param name: テーブルソートモード名
	 * @param mode モード
	 * @param isAsc 昇順フラグ
	 * @param isNotify 通知フラグ
	 * @returns falseの場合、モードは変化がない
	 */
	public setTableSortMode(name: string, mode: string, isAsc: boolean, isNotify: boolean=false): boolean {
		this.addDebugInfoLog(`setTableSortMode('${name}'. '${mode}', ${isAsc})`);
		const subModelList = this.getSubModelList(name);
		name = this.getListName(name);
		if (this.m_TableSortModeMap.has(name) === true) {
			const info = this.m_TableSortModeMap.get(name);
			if (info.mode !== mode || isAsc !== info.asc) {
				if (isNotify === true) {
					this.onChangeTableSort(info.name, mode, subModelList, isAsc);
					this.m_SubViewMap.forEach((subView, key, map) => {
						subView.onChangeTableSort(info.name, mode, subModelList, isAsc);
					});
				}
				info.setMode(mode, isAsc);
				return true;
			}
		}
		else {
			this.addErrorLog(`setTableSortMode：テーブルソート名に誤りがあります。[${name}]`);
		}
		return false;
	}

	/**
	 * テーブルソートモード取得処理
	 * @param name テーブルソートモード名
	 * @returns テーブルソートモード
	 */
	public getTableSortMode(name: string): WprSortModeInfo {
		if (this.m_TableSortModeMap.has(name) === true) {
			const info = this.m_TableSortModeMap.get(name);
			return info.getSortModeInfo();
		}
		else {
			this.addErrorLog(`getTableSortMode：テーブルソートモード名に誤りがあります。[${name}]`);
		}
		return null;
	}

	/**
	 * View(FormControlの値)クリア処理
	 * @param name コントロール名称
	 */
	public clearView(name?: string): void {
		if (name === undefined) {
			this.addDebugInfoLog('clearView()');
			this.m_ControlInfoMap.clearValue(this, '@@all');
		}
		else {
			this.addDebugInfoLog(`clearView('${name}')`);
			this.m_ControlInfoMap.clearValue(this, name);
		}
		this.clearListValue();
		this.setInputErrorList(null);
		this.m_SubViewMap.forEach((subView, key, map) => {
			subView.setInputErrorList(null);
		});
		this.updateMode();
	}

	/**
	 * 入力状態取得
	 * @param name コントロール名称
	 * @return trueの場合、入力済みでエラーなし
	 */
	public isInput(name?: string): boolean {
		if (name === undefined) {
			if (this.m_ControlInfoMap.isInput(this, '@@all') === false)
				return false;
		}
		else {
			if (this.m_ControlInfoMap.isInput(this, name) === false)
				return false;
		}
		return this.isInputList();
	}

	/**
	 * コントロール情報取得処理
	 * @param name コントロール名
	 * @param listName リスト名
	 * @param index 行インデクス
	 * @returns コントロール情報
	 */
	public getControlInfo(name: string, listName?: string, index?: number): WprControlInfo {
		let cinfo = super.getControlInfo(name, listName, index);
		if (cinfo == null) {
			this.m_SubViewMap.forEach((subView, key, map) => {
				if (cinfo == null)
					cinfo = subView.getControlInfo(name, listName, index);
			});
		}
		/*
		if (cinfo != null && index) {
			if (cinfo instanceof WprListControlInfo) {
				const lcinfo = cinfo as WprListControlInfo;
				lcinfo.setRowIndex(index);
				return lcinfo.getRowControlInfo();
			}
		}
		*/
 		return cinfo;
	}

	/**
	 * カテゴリコントロールリスト取得
	 * @param name カテゴリ名
	 * @param listName リスト名
	 * @param index インデクス
	 * @returns カテゴリコントロールリスト
	 */
	public getCategoryControlList(name: string, listName?: string, index?: number): WprControlInfo[] {
		const list = new Array();
		let clist = super.getCategoryControlList(name, listName, index);
		clist.forEach(cinfo => {
			list.push(cinfo);
		});
		this.m_SubViewMap.forEach(subView => {
			clist = subView.getCategoryControlList(name, listName, index);
			clist.forEach(cinfo => {
				list.push(cinfo);
			});
		});
		return list;
	}

	/**
	 * アクション情報取得
	 * @param controlName コントロール名
	 * @param listName リスト名
	 * @return アクション情報
	 */
	public getActionInfo(controlName: string, listName?: string): WprActionInfo {
		let ainfo = super.getActionInfo(controlName, listName);
		if (ainfo == null) {
			this.m_SubViewMap.forEach((subView, key, map) => {
				if (ainfo == null)
					ainfo = subView.getActionInfo(controlName, listName);
			});
		}
 		return ainfo;
	}

	/**
	 * ロストフォーカスアクション情報取得
	 * @param controlName コントロール名
	 * @param listName リスト名
	 * @return アクション情報
	 */
	public getBlurActionInfo(controlName: string, listName?: string): WprActionInfo {
		const aname = `${controlName}@@blur`;
		let ainfo = super.getActionInfo(aname, listName);
		if (ainfo == null) {
			this.m_SubViewMap.forEach((subView, key, map) => {
				if (ainfo == null)
					ainfo = subView.getActionInfo(aname, listName);
			});
		}
		 return ainfo;
	}

	/**
	 * エラー情報登録処理
	 * @param error エラーインターフェース
	 * @param subView サブビュー名
	 */
	public addErrorControl(error: IWprError, subView?: string): void {
		if (!subView)
			super.addErrorControl(error);
		else if (this.m_SubViewMap.has(subView) === true)
			this.m_SubViewMap.get(subView).addErrorControl(error);
		else
			this.addErrorLog(`addViewErrorControl：SubView名に誤りがあります。[${subView}]`);
	}

	/**
	 * エラー情報削除処理
	 * @param error エラーインターフェース
	 * @param subView サブビュー名
	 */
	public deleteErrorControl(error: IWprError, subView?: string): void {
		if (!subView)
			super.deleteErrorControl(error);
		else if (this.m_SubViewMap.has(subView) === true)
			this.m_SubViewMap.get(subView).deleteErrorControl(error);
//		else
//			this.addErrorLog(`deleteErrorControl：SubView名に誤りがあります。[${subView}]`);
	}

	/**
	 * サービスエラー情報登録処理
	 * @param error エラーインターフェース
	 * @param subView サブビュー名
	 */
	public addServiceErrorControl(error: IWprServiceError, subView?: string): void {
		if (!subView)
			super.addServiceErrorControl(error);
		else if (this.m_SubViewMap.has(subView) === true)
			this.m_SubViewMap.get(subView).addServiceErrorControl(error);
		else
			this.addErrorLog(`addServiceErrorControl：SubView名に誤りがあります。[${subView}]`);
	}

	/**
	 * サービスエラー情報削除処理
	 * @param error エラーインターフェース
	 * @param subView サブビュー名
	 */
	public deleteServiceErrorControl(error: IWprServiceError, subView?: string): void {
		if (!subView)
			super.deleteServiceErrorControl(error);
		else if (this.m_SubViewMap.has(subView) === true)
			this.m_SubViewMap.get(subView).deleteServiceErrorControl(error);
		//		else
		//			this.addErrorLog(`deleteErrorControl：SubView名に誤りがあります。[${subView}]`);
	}

	/**
	 * スコープモデル設定処理
	 * @param name モデル名称
	 * @param model モデル
	 */
	public setScopeModel(name: string, model: any): void {
		if (this.getScopeInfo() != null) {
			this.addDebugInfoLog(`setScopeModel('${name}')`);
			this.getScopeInfo().setScopeModel(name, model);
		}
		else {
			this.addErrorLog('setScopeModel: WprBaseScopeViewInfoを継承する必要があります。');
		}
	}

	/**
	 * スコープモード設定処理
	 * @param name モード名称
	 * @param mode モード
	 * @returns falseの場合、モードは変化がない
	 */
	public setScopeMode(name: string, mode: any): boolean {
		if (this.getScopeInfo() != null) {
			this.addDebugInfoLog(`setScopeMode('${name}'. '${mode}')`);
			return this.getScopeInfo().setScopeMode(name, mode);
		}
		this.addErrorLog('setScopeMode: WprBaseScopeViewInfoを継承する必要があります。');
		return false;
	}

	/**
	 * スコープモード取得処理
	 * @param name スコープモード名
	 * @returns スコープモード
	 */
	public getScopeMode(name: string): any {
		if (this.getScopeInfo() != null)
			return this.getScopeInfo().getScopeMode(name);
		this.addErrorLog('getScopeMode: WprBaseScopeViewInfoを継承する必要があります。');
		return null;
	}

	/**
	 * グローバルスコープモデル設定処理
	 * @param name モデル名称
	 * @param model モデル
	 * @returns falseの場合、モードは変化がない
	 */
	public setGlobalScopeModel(name: string, model: any): void {
		if (this.globalScopeInfo != null) {
			this.addDebugInfoLog(`setGlobalScopeModel('${name}')`);
			this.globalScopeInfo.setGlobalScopeModel(name, model);
		}
		else {
			this.addErrorLog('setGlobalScopeModel: グローバルスコープが登録されていません。');
		}
	}

	/**
	 * グローバルモード設定処理
	 * @param name モード名称
	 * @param mode モード
	 * @returns falseの場合、モードは変化がない
	 */
	public setGlobalMode(name: string, mode: any): boolean {
		if (this.globalScopeInfo != null) {
			this.addDebugInfoLog(`setGlobalMode('${name}'. '${mode}')`);
			return this.globalScopeInfo.setGlobalScopeMode(name, mode);
		}
		this.addErrorLog('setGlobalScopeMode: グローバルスコープが登録されていません。');
		return false;
	}

	/**
	 * グローバルモード取得処理
	 * @param name モード名
	 * @returns モード
	 */
	public getGlobalMode(name: string): any {
		if (this.globalScopeInfo != null)
			return this.globalScopeInfo.getScopeMode(name);
		this.addErrorLog('getGlobalMode: グローバルスコープが登録されていません。');
		return null;
	}

	/**
	 * アクションチェック処理
	 * @param name アクション名
	 * @returns falseの場合はアクションは登録されていない
	 */
	public checkAction(action: string): boolean {
		let ret = super.checkAction(action);
		if (ret === false) {
			this.m_SubViewMap.forEach((subView, key, map) => {
				if (ret === false)
					ret = subView.checkAction(action);
			});
		}
		return ret;
	}

	/**
	 * モードチェック
	 * @param mode モード
	 * @returns trueの場合、モードは含まれている
	 */
	public checkMode(mode: string): boolean {
		let modeName;
		if (mode.includes('.') === true) {
			const list = mode.split('.');
			modeName = list[0].trim();
			mode = list[1].trim();
		}
		if (this.checkComponentMode(modeName, mode) === true ||
			this.checkViewModeData(modeName, mode) === true)
			return true;
		return false;
	}

	/**
	 * サービスエラー通知処理
	 * @param url URL
	 * @param status ステータス
	 * @param message エラーメッセージ 
	 * @param error エラー情報
	 */
	public onServiceError(url: string, status: string, message: string, error?: Error): void {
	}

	/**
	 * 次のPathに移動する
	 * @param path 移動するパス
	 * @param params パラメータ情報リスト
	 * @param isValid バリデーション実行フラグ
	 * @param useViewData ビューデータ使用フラグ(trueの場合、urlパラメータは使用せずにsetViewDataを使用する)
	 */
	public pushPath(path: string, params?: IWprUrlParam[], isValid: boolean=false, useViewData: boolean=false): void {
		this.addDebugInfoLog(`pushPath('${path}')`);
		this.cancelStateLock();
		if (this.props.match) {
			if (params && useViewData == false)
				path = this.addUrlParam(path, params);
			this.props.history.push(path);
			this.setMoveViewInfo(params, isValid, useViewData);
		}
		else if (this.m_Parent != null) {
			this.m_Parent.pushPath(path, params, isValid);
		}
		else {
			for (const vinfo of this.FW.view.activeViewList) {
				if (vinfo.props.match) {
					vinfo.pushPath(path, params, isValid);
					return;
				}
			}
			this.addErrorLog(`pushPath：withRouterではないViewからは実行できません。[${path}][${name}]`);
		}
	}
	
	/**
	 * 現在のパスを上書きして次のPathに移動する
	 * @param path 移動するパス
	 * @param params パラメータ情報リスト
	 * @param isValid バリデーション実行フラグ
	 * @param useViewData ビューデータ使用フラグ(trueの場合、urlパラメータは使用せずにsetViewDataを使用する)
	 */
	public replacePath(path: string, params?: IWprUrlParam[], isValid: boolean=false, useViewData: boolean=false): void {
		this.addDebugInfoLog(`replacePath('${path}')`);
		if (this.props.match) {
			if (params && useViewData == false)
				path = this.addUrlParam(path, params);
			this.props.history.replace(path);
			this.setMoveViewInfo(params, isValid, useViewData);
		}
		else if (this.m_Parent != null) {
			this.m_Parent.replacePath(path, params, isValid);
		}
		else {
			this.addErrorLog(`replacePath：withRouterではないViewからは実行できません。[${path}][${name}]`);
		}
	}

	/**
	 * 前のPathに戻る
	 * @param isValid バリデーション実行フラグ
	 */
	public goBack(isValid?: boolean): void {
		this.addDebugInfoLog('goBack()');
		if (this.props.match) {
			this.props.history.goBack();
			if (isValid === true)
				this.FW.view.startValidate = 'goBack';
			else
				this.FW.view.startValidate = null;
		}
		else if (this.m_Parent != null) {
			this.m_Parent.goBack(isValid);
		}
		else {
			this.addErrorLog('goBack：withRouterではないViewからは実行できません。');
		}
	} 
	
	/**
	* リンク遷移（タブ遷移）
	* @param path 遷移するパス
	* @param params パラメータ情報リスト
	*/
   	public openNewTab(path: string, params?: IWprUrlParam[]): void {
	  	if (params)
			path = this.addUrlParam(path, params);
	   	window.open(path);
  	}

	/**
	 * urlパラメータ取得
	 * @param name パラメータ名
	 * @returns urlパラメータ
	 */
	public getUrlParam(name: string): string {
		if (this.FW.isUnitTest === false) {
			let val = null;
			if (this.props.location && this.props.location.search) {
				if (this.props.location.search.startsWith('?') === true) {
					this.props.location.search.substr(1).split('&').forEach(param => {
						const list = param.split('=');
						if (list[0] === name)
							val = list[1];
					});
				}
			}
			else if (this.m_Parent != null) {
				return this.m_Parent.getUrlParam(name);
			}
			return val;
		}
		else {
			return this.FW.testInfo.getUrlParam(name);
		}
	}

	/**
	 * DI元インスタンス取得
	 * @returns DI元インスタンス
	 */
	public getDIObjectFromInstance(): any {
		return this.m_Parent;
	}

	/**
	 * 質問メッセージ表示処理
	 * @param message　メッセージ 
	 * @param resultMethod 結果通知メソッド
	 */
	public showQuestionMessage(message: string, resultMethod: (result: boolean) => void): void {
		this.addDebugInfoLog(`showQuestionMessage('${message}')`);
		if (this.m_MessageComponent)
			this.m_MessageComponent.show('question', message, resultMethod);
		else
			this.addErrorLog('showQuestionMessage：MessageComponentが登録されていません。');
	}

	/**
	 * 確認メッセージ表示処理
	 * @param message　メッセージ 
	 * @param confirm　確認フラグ 
	 * @param resultMethod 結果通知メソッド
	 */
	public showInfoMessage(message: string, confirm?: boolean, resultMethod?: (result: boolean) => void): void {
		this.addDebugInfoLog(`showInfoMessage('${message}')`);
		if (confirm === true) {
			if (this.m_MessageComponent)
				this.m_MessageComponent.show('info', message, resultMethod);
			else
				this.addErrorLog('showInfoMessage：MessageComponentが登録されていません。');
		}
		else {
			if (this.m_SnackbarComponent)
				this.m_SnackbarComponent.show('info', message);
			else
				this.addErrorLog('showInfoMessage：SnackbarComponentが登録されていません。');
		}
	}

	/**
	 * 警告メッセージ表示処理
	 * @param message　メッセージ 
	 * @param confirm　確認フラグ 
	 * @param resultMethod 結果通知メソッド
	 */
	public showWarningMessage(message: string, confirm?: boolean, resultMethod?: (result: boolean) => void): void {
		this.addDebugInfoLog(`showWarningMessage('${message}')`);
		if (confirm === true) {
			if (this.m_MessageComponent)
				this.m_MessageComponent.show('warning', message, resultMethod);
			else
				this.addErrorLog('showWarningMessage：MessageComponentが登録されていません。');
		}
		else {
			if (this.m_SnackbarComponent)
				this.m_SnackbarComponent.show('warning', message);
			else
				this.addErrorLog('showWarningMessage：SnackbarComponentが登録されていません。');
		}
	}

	/**
	 * エラーメッセージ表示処理
	 * @param message　メッセージ 
	 * @param confirm　確認フラグ 
	 * @param resultMethod 結果通知メソッド
	 */
	public showErrorMessage(message: string, confirm?: boolean, resultMethod?: (result: boolean) => void): void {
		this.addDebugInfoLog(`showErrorMessage('${message}')`);
		if (confirm === true) {
			if (this.m_MessageComponent)
				this.m_MessageComponent.show('error', message, resultMethod);
			else
				this.addErrorLog('showErrorMessage：MessageComponentが登録されていません。');
		}
		else {
			if (this.m_SnackbarComponent)
				this.m_SnackbarComponent.show('error', message);
			else
				this.addErrorLog('showErrorMessage：SnackbarComponentが登録されていません。');
		}
	}

	/**
	 * ダイアログ表示処理
	 * @param name ダイアログ名
	 * @param sendData 送信データ
	 * @param resultMethod 結果通知メソッド
	 * @param title タイトル
	 */
	public showDialog(name: string, sendData?: any, resultMethod?: (status: boolean, result?: any) => void, title?: string): void {
		if (this.m_DialogInfoMap.has(name) === true) {
			this.addDebugInfoLog(`showDialog('${name}')`);
			let dinfo = this.m_DialogInfoMap.get(name);
			if (dinfo.isOpen === false)
				dinfo.show(sendData, resultMethod, title);
			else
				this.addErrorLog(`showDialog：ダイアログは表示中です。[${name}]`);
		}
		else {
			this.addErrorLog(`showDialog：ダイアログが見つかりませんでした。[${name}]`);
		}
	}

	/**
	 * ダイアログクローズ処理
	 * @param sutatus 結果
	 * @param resultData 結果データ
	 */
	public closeDialog(name: string, status: boolean, resultData: any = null): void {
		if (this.m_DialogInfoMap.has(name) === true) {
			this.addDebugInfoLog(`closeDialog('${name}')`);
			let dinfo = this.m_DialogInfoMap.get(name);
			if (dinfo.isOpen === true || dinfo.isStart === true)
				dinfo.close(status, resultData);
			else
				this.addErrorLog('closeDialog：ダイアログは、表示されていません。');
		}
		else {
			this.addErrorLog(`closeDialog：ダイアログが見つかりませんでした。[${name}]`);
		}
	}

	/**
	 * ダイアログ再開処理
	 * @param name ダイアログ名
	 */
	public restartWaitDialog(name: string): void {
		if (this.m_DialogInfoMap.has(name) === true) {
			let dinfo = this.m_DialogInfoMap.get(name);
			dinfo.restartDialog();
		}
		else {
			this.addErrorLog(`restartDialog：ダイアログが見つかりませんでした。[${name}]`);
		}
	}
	
	/**
	 * 子ビュー入力チェック処理
	 * @param name アクション名又はコントロール名
	 * @param isView メインビューフラグ
	 * @param isControl コントロールチェックフラグ
	 * @param isEdit 編集フラグ
	 * @param row 行情報
	 * @returns falseの場合入力エラー
	 */
	public checkChildValidate(name: string, isView: boolean = false, isControl: boolean, isEdit: boolean, row?: any): boolean {
		let rtn = true;
		this.m_ChildViewList.forEach(child => {
			if (child.checkValidate(name, isView, isControl, isEdit, row) === false)
			rtn = false;
		});
		return rtn;
	}

	/**
	 * ビューエラーメッセージリスト取得
	 * @param msgList メッセージ情報リスト
	 */
	public getViewErrorList(msgList: WprValidErrorInfo[]): void {
		this.setViewErrorList(msgList);
		this.m_SubViewMap.forEach(subView => {
			subView.setViewErrorList(msgList);
		});
	}

	/**
	 * サービスエラーメッセージリスト取得
	 * @param msgList メッセージリスト
	 */
	public getServiceErrorList(msgList: string[]): void {
		this.setServiceErrorList(msgList);
		this.m_SubViewMap.forEach(subView => {
			subView.setServiceErrorList(msgList);
		});
	}

	/**
	 * ビューエラー数取得
	 * @returns ビューエラー数
	 */
	public getViewErrorCount(): number {
		let msgList = new Array();
		this.getViewErrorList(msgList);
		return msgList.length;
	}

	/**
	 * ビューエラーメッセージ再表示
	 * @param error エラーインターフェース
	 * @param msgList メッセージ情報リスト
	 */
	public refreshViewErrorMessage(msgList: WprValidErrorInfo[], error?: IWprError): void {
		this.errorInterList.forEach(einfo => {
			if (error === undefined || error === einfo)
				einfo.setErrorList(msgList);
		});
	}

	/**
	 * サービスエラーメッセージ再表示
	 * @param error エラーインターフェース
	 * @param msgList メッセージリスト
	 */
	public refreshServiceErrorMessage(msgList: string[], error?: IWprServiceError): void {
		this.serviceErrorInterList.forEach(einfo => {
			if (error === undefined || error === einfo)
				einfo.setErrorList(msgList);
		});
	}

	/**
	 * 予約ビューモード設定（設定は、次のRenderの後に行われる）
	 * @param name モード名
	 * @param mode モード
	 */
	public setReserveViewMode(name: string, mode: string): void {
		super.setReserveViewMode(name, mode);
		this.m_Component.setState({
			reserve: true
		});
	}

	/**
	 * 予約ビューモードの更新を行う
	 * @returns trueの場合、予約あり
	 */
	public updateReserveViewMode(): boolean {
		if (super.updateReserveViewMode() == true) {
			this.m_Component.setState({
				reserve: false
			});
			return true;
		}
		return false;
	}

	/**
	 * UserAgentレンダ―取得
	 * @returns UserAgentレンダ―
	 */
	public getUserAgentRender(): string {
		return this.m_UserAgentRender;
	}

	/**
	 * ステータス更新ロック開始
	 */
	public startStateLock(): void {
		if (this.m_StateLockCnt == 0) {
			this.m_IsStateLock = {};
			this.m_ChildsetModeList = new Array();
			this.m_WaitShowChildList = new Array();
			this.FW.view.startStateLock();
			this.m_WaitAction = null;
		}
		this.m_StateLockCnt++;
		this.addInfoLog(`== startStateLock == [${this.m_StateLockCnt}]`);
	}

	/**
	 * ステータス更新ロック終了
	 */
	public endStateLock(): void {
		this.m_StateLockCnt--;
		this.addInfoLog(`== endStateLock == [${this.m_StateLockCnt}]`);
		if (this.m_StateLockCnt < 0)
			this.m_StateLockCnt = 0;
		if (this.m_IsStateLock && this.m_StateLockCnt == 0) {
			if (this.m_IsStateLock.mode || this.m_IsStateLock.data || this.m_IsStateLock.listData) {
				const slock = this.m_IsStateLock;
				this.m_IsStateLock = null;
				this.addInfoLog('== SetState ==');
				this.m_Component.setState(slock);
				if (slock.mode)
					this.updateComponentMode();
			}
			this.m_IsStateLock = null;
			this.m_ChildsetModeList.forEach(minfo => {
				this.addInfoLog('== setChildViewMode ==');
				this.setChildViewMode(minfo.name, minfo.mode, minfo.childName);
			});
			this.m_ChildsetModeList = new Array();
			this.FW.view.endStateLock();
			if (this.m_IsWaitChlidShowView === true) {
				this.m_IsWaitChlidShowView = false;
//				this.addInfoLog('== ChildShowView ==');
				this.setChildDIObject();
				this.m_ChildViewList.forEach(child => {
					if (this.FW.view.checkStateLockShowView(child) === false) {
						if (this.m_TabChildViewMap.isShowTabChildView(child) === false) {
							this.addInfoLog(`== ChildShowView == [${child.name}]`);
							child.showView(false);
							child.restart();
						}
					}
				});
			}
			this.m_WaitShowChildList.forEach(child => {
				this.addInfoLog('== Child ShowView ==');
				child.showView(false);
			});
			this.m_ChildViewList.forEach(child => {
				child.sendWaitData();
			});
			this.m_WaitShowChildList = new Array();
			if (this.m_WaitAction) {
				if (this.parentView != null && this.parentView.isStateLock === true)
					this.parentView.setStateWaiteAction(this.m_WaitAction);
				else
					this.m_WaitAction();
			}
		}
	}

	/**
	 * ステータス更新ロックキャンセル
	 */
	public cancelStateLock(): void {
		if (this.m_IsStateLock) {
			this.addInfoLog(`== cancelStateLock == [${this.m_StateLockCnt}]`);
			this.m_IsStateLock = null;
			this.m_StateLockCnt = 0;
			this.m_IsWaitChlidShowView = false;
			this.FW.view.endStateLock();
		}
	}

	/**
	 * 編集開始処理
	 * @param message メッセージ
	 * @param cancelMethod キャンセルメソッド
	 */
	public startEdit(message: string, cancelMethod?: (name: string, parent: string) => void): boolean {
		this.addDebugInfoLog('startEdit()');
		return this.FW.edit.startEdit(this, message, cancelMethod);
	}

	/**
	 * 編集終了処理
	 * @returns falseの場合、編集中ではない
	 */
	public endEdit(): boolean {
		this.addDebugInfoLog('endEdit()');
		return this.FW.edit.endEdit();
	}

	/**
	 * 編集キャンセル処理
	 * @returns falseの場合、編集中ではない
	 */
	public cancelEdit(): boolean {
		this.addDebugInfoLog('cancelEdit()');
		return this.FW.edit.cancelEdit();
	}

	/**
	 * CSSモジュールで解決したクラス名取得
	 * @param clsName 元のクラス名 
	 * @returns CSSモジュールで解決したクラス名
	 */
	public getLocalClassName(clsName: string): string {
		if (this.m_Component)
			return this.m_Component.getLocalClassName(clsName);
		return null;
	}
	// --------------------------------------------------------------------------

	// public メソッド  ---------------------------------------------------------
	/**
	 * コンポーネント設定処理&
	 * @param component コンポーネント
	 */
	public setComponent(component: WprBaseViewCoreComponent<any>): void {
		this.m_Component = component;
	}

	/**
	 * スコープモデルの変更チェック処理
	 */
	public checkScopeModelValue(): void {
		if (this.getScopeInfo() != null)
			this.getScopeInfo().checkModelValue();
		if (this.globalScopeInfo != null)
			this.globalScopeInfo.checkModelValue();
	}

	/**
	 * モデル値チェック通知
	 * @param name モデル名
	 * @param model モデル
	 * @param isGlobal グローバルフラグ
	 * @param prop プロパティ名
	 */
	public onCheckModelValue(name: string, model: any, isGlobal: boolean, prop?: string): void {
		super.updateModel(name, model, isGlobal, prop);
		this.m_SubViewMap.forEach((subView, key, map) => {
			subView.updateModel(name, model, isGlobal, prop);
		});
	}

	/**
	 * DI用コントロール情報マップ取得
	 * @returns DI要コントロール情報マップ
	 */
	public getDIControlMap(): WprControRefreshMap {
		return this.m_ControlInfoMap.get('@@all');
	}

	/**
	 * スコープモデル更新時にチェックするプロパティをチェックする
	 * @param obj 対象オブジェクト
	 * @param name モデル名
	 * @param prop プロパティ名
	 */
	public setScopeModelUpdateProperty(obj: WprBaseDIManage, name: string, prop: string): void {
		if (this.getScopeInfo() != null)
			this.m_UpdateProList.push(new WprScopeUpdatePropertyInfo(obj, name, prop));
	}

	/**
	 * グローバルスコープモデル更新時にチェックするプロパティをチェックする
	 * @param obj 対象オブジェクト
	 * @param name モデル名
	 * @param prop プロパティ名
	 */
	public setGlobalScopeModelUpdateProperty(obj: WprBaseDIManage, name: string, prop: string): void {
		this.m_UpdateGlobalProList.push(new WprScopeUpdatePropertyInfo(obj, name, prop));
	}

	/**
	 * スコープ更新プロパティ設定
	 */
	public setScopeUpdateProperty() {
		if (this.m_IsScopeInit === false) {
			this.m_IsScopeInit = true;
			if (this.getScopeInfo() != null) {
				for (const uinfo of this.m_UpdateProList)
					this.getScopeInfo().setUpdateProperty(uinfo);
			}
			if (this.globalScopeInfo != null) {
				for (const uinfo of this.m_UpdateGlobalProList)
					this.globalScopeInfo.setUpdateProperty(uinfo);
			}
		}
	}

	/**
	 * モードコンポーネント追加処理
	 * @param component モードコンポーネント
	 */
	public addModeComponet(component: IWprControlMode): void {
		this.m_ModeComponentList.push(component);
	}

	/**
	 * モードコンポーネントマウント通知
	 */
	public mountModeComponent(): void {
		this.updateComponentMode();
	}

	/**
	 * 権限コンポーネント追加処理
	 * @param component 権限コンポーネント
	 */
	public addAuthComponet(component: IWprControlAuth): void {
		this.m_AuthComponentList.push(component);
	}

	/**
	 * 権限コンポーネントマウント通知
	 */
	public mountAuthComponent(): void {
		this.updateComponentAuth();
	}

	/**
	 * ロード待ちコンポーネント設定
	 * @param name ロード待ち名称
	 * @param component ロード待ちコンポーネント
	 */
	public addLoadWaitComponent(name: string, component: IWprLoadWait): void {
		if (this.m_LoadWaitComponentMap.has(name) === false)
			this.m_LoadWaitComponentMap.set(name, new Array());
		this.m_LoadWaitComponentMap.get(name).push(component);
	}

	/**
	 * スコープモデルステータス設定
	 * @param model データ
	 */
	public setScopeModelState(model: any): void {
		if (this.isMount === false)
			return;
		this.m_Component.setState({
			scopeModel: model
		});
	}

	/**
	 * スコープモードステータス設定
	 * @param mode データ
	 */
	public setScopeModeState(mode: any): void {
		if (this.isMount === false)
			return;
		this.m_Component.setState({
			scopeMode: mode
		});
		this.updateComponentMode();
	}

	/**
	 * グローバルモデルステータス設定
	 * @param model モデル
	 */
	public setGlobalModelState(model: any): void {
		if (this.isMount === false)
			return;
		this.m_Component.setState({
			globalModel: model
		});
	}

	/**
	 * グローバルモードステータス設定
	 * @param mode データ
	 */
	public setGlobalModeState(mode: any): void {
		if (this.isMount === false)
			return;
		this.m_Component.setState({
			globalMode: mode
		});
		this.updateComponentMode();
	}

	/**
	 * ダイアログタイトルステータス設定
	 * @param title ダイアログタイトル
	 */
	public setDialogTitleState(title: string): void {
		if (this.isMount === false)
			return;
		this.m_Component.setState({
			dialogTitle: title
		});
	}

	/**
	 * スコープデータステータス取得
	 * @returns スコープデータ
	 */
	public getScopeModelState(): any {
		if (this.getScopeInfo() != null)
			return this.getScopeInfo().getScopeModelState();
		return {};
	}

	/**
	 * スコープモードステータス取得
	 * @returns スコープモード
	 */
	public getScopeModeState(): any {
		if (this.getScopeInfo() != null)
			return this.getScopeInfo().getScopeModeState();
		return {};
	}

	/**
	 * グローバルモデルステータス取得
	 */
	public getGlobalModelState(): any {
		if (this.m_GlobalScopeInfo != null)
			return this.m_GlobalScopeInfo.getScopeModelState();
		return {};
	}

	/**
	 * グローバルモードステータス取得
	 * @returns グローバルモード
	 */
	public getGlobalModeState(): any {
		if (this.m_GlobalScopeInfo != null)
			return this.m_GlobalScopeInfo.getScopeModeState();
		return {};
	}

	/**
	 * モードリストデータ設定
	 * @param listName リスト名
	 * @param listData リストデータ
	 */
	public setViewModeListData(listName: string, listData: Array<any>): void {
		super.setViewModeListData(listName, listData);
		this.m_SubViewMap.forEach((subView, key, map) => {
			subView.setViewModeListData(listName, listData);
		});
	}

	/**
	 * ビュー表示モード処理
	 */
	public showMode(): void {
		super.showMode();
		this.m_SubViewMap.forEach((subView, key, map) => {
			subView.showMode();
		});
		this.updateComponentMode();
	}

	/**
	 * ビューモード更新処理
	 */
	public updateMode(isClear?: boolean): void {
		super.updateMode(isClear);
		this.m_SubViewMap.forEach((subView, key, map) => {
			subView.updateMode(isClear);
		});
		this.updateComponentMode();
		this.m_ChildViewList.forEach(child => {
			child.updateMode(isClear);
		});
		this.refreshAuth();
	}

	/**
	 * コントロールモード更新処理
	 * @param name コントロール名
	 */
	public updateControlMode(name: string): void {
		super.updateControlMode(name);
		this.m_SubViewMap.forEach((subView, key, map) => {
			subView.updateControlMode(name);
		});
	}

	/**
	 * コンポーネントモード更新処理
	 */
	public updateComponentMode(): void {
		this.m_ModeComponentList.forEach(cmode => {
			let visible = false;
			if (cmode.getModeInfoList().length > 0) {
				for(const minfo of cmode.getModeInfoList()) {
					if (this.checkComponentMode(minfo.viewModeName, minfo.mode) === true ||
						this.checkViewModeData(minfo.viewModeName, minfo.mode) === true) {
						visible = true;
						break;
					}
				}
				if (cmode.getInvisible() === true)
					visible = !visible;
			}
			else {
				visible = true;
			}
			cmode.setVisible(visible);
		});
		for(const cinfo of this.m_ControlInfoMap.getControlInfoList()) {
			if (cinfo.config.enableMode)
				cinfo.updateComponentMode();
		}
		this.m_TabModeMap.forEach(tinfo => {
			tinfo.refresh(this);
		});
	}

	/**
	 * コンポーネント権限更新処理
	 */
	public updateComponentAuth(): void {
		this.m_AuthComponentList.forEach(cauth => {
			let visible = false;
			if (cauth.getAuthList().length > 0) {
				for(const auth of cauth.getAuthList()) {
					if (this.checkAuth(auth) === true) {
						visible = true;
						break;
					}
				}
				if (cauth.getInvisible() === true)
					visible = !visible;
			}
			else {
				visible = true;
			}
			cauth.setVisible(visible);
		});
	}

	/**
	 * スイッチコンポーネント設定
	 * @param sw スイッチコンポーネント
	 */
	public setSwitchComponet(sw: WprSwitch): void {
		this.FW.view.setSwitchComponet(sw);
	}

	/**
	 * タブコンポーネント設定処理
	 * @param tab タブコンポーネント
	 * @param mode タブモード名
	 * @param plabel タブパネルラベル
	 * @param list タブラベルリスト
	 * @param modeMap タブモードマップ
	 * @param isUpdate 更新フラグ
	 */
	public setTabModeComponent(tab: IWprTabMode, mode: string, plabel: string, list: string[], modeMap: Map<string, string>, isUpdate: boolean=false): void {
		if (this.m_TabModeMap.has(mode) === false)
			this.m_TabModeMap.set(mode, new WprTabModeInfo(mode));
		const minfo = this.m_TabModeMap.get(mode);
		minfo.addTabComponent(tab, plabel, list, modeMap, isUpdate);
	}

	/**
	 * タブコンポーネント削除処理
	 * @param tab タブコンポーネント
	 * @param mode タブモード名
	 * @param plabel タブパネルラベル
	 */
	public deleteTabModeComponent(tab: IWprTabMode, mode: string, plabel: string): void {
		if (this.m_TabModeMap.has(mode) === true) {
			const minfo = this.m_TabModeMap.get(mode);
			minfo.deleteTabComponent(tab, plabel);
		}
	}

	/**
	 * タブコンポーネントレンダー通知
	 * @param mode タブモード名
	 */
	public renderTabsComponent(mode: string) {
		if (this.m_TabModeMap.has(mode) === true) {
			const minfo = this.m_TabModeMap.get(mode);
			minfo.renderTabsComponent();
		}
	}

	/**
	 * アコーディオンコンポーネント設定処理
	 * @param accordion アコーディオンコンポーネント
	 * @param mode アコーディオンモード名
	 * @param name アコーディオン名
	 * @param expand 初期展開フラグ
	 */
	public setAccordionModeComponent(accordion: IWprAccordionMode, mode: string, name: string, expand?: boolean): void {
		if (this.m_AccordionModeMap.has(mode) === false)
			this.m_AccordionModeMap.set(mode, new WprAccordionModeInfo(mode));
		const minfo = this.m_AccordionModeMap.get(mode);
		minfo.addAccordionComponent(accordion);
		if (expand)
			minfo.setMode(name);
	}

	/**
	 * テーブルソートコンポーネント設定処理
	 * @param sort ソートコンポーネント
	 * @param mode テーブルソートモード名
	 * @param item ソートアイテム
	 */
	public setTableSortModeComponent(sort: IWprTableSortMode, mode: string, item: string): void {
//		const subModelList = this.getSubModelList(mode);
		mode = this.getListName(mode);
		if (this.m_TableSortModeMap.has(mode) === false)
			this.m_TableSortModeMap.set(mode, new WprTableSortModeInfo(mode));
		const minfo = this.m_TableSortModeMap.get(mode);
		minfo.addTableSortComponent(sort, item);
	}

	/**
	 * ページングコンポーネント設定処理
	 * @param pagination ページングコンポーネント
	 * @param mode リスト名
	 */
	public setTablePaginationComponent(pagination: IWprTablePagination, listName: string): void {
		if (this.m_TablePainationMap.has(listName) === false)
			this.m_TablePainationMap.set(listName, new Array());
		this.m_TablePainationMap.get(listName).push(pagination);
		const listInfo = this.listInfoMap.getListInfo(listName);
		if (listInfo) {
			this.m_TablePainationMap.get(listName).forEach((page) => {
				page.setTotalCount(listInfo.totalCount, true);
			});
		}
	}

	/**
	 * ページングコンポーネント削除処理
	 * @param pagination ページングコンポーネント
	 * @param listName リスト名
	 */
	public removeTablePaginationComponent(pagination: IWprTablePagination, listName: string) {
		if (this.m_TablePainationMap.has(listName) === true) {
			const plist = this.m_TablePainationMap.get(listName);
			if (plist.includes(pagination) === true) {
				const idx = plist.indexOf(pagination);
				plist.splice(idx, 1);
			}
		}
	}

	/**
	 * テーブルコンテナコンポーネント設定処理
	 * @param container テーブルコンテナコンポーネント
	 * @param listName リスト名
	 */
	public setTableContainerComponent(container: IWprTableContainer, listName: string): void {
		if (this.m_TableContainerMap.has(listName) === false)
			this.m_TableContainerMap.set(listName, new Array());
		this.m_TableContainerMap.get(listName).push(container);
	}

	/**
	 * テーブル行コンポーネント設定
	 * @param tableRow テーブル行コンポーネント
	 * @param row 行情報
	 * @param selectable 選択可能フラグ
	 */
	public setTableRowComponent(tableRow: IWprTableRow, row: WprRowInfo, selectable: boolean): void {
		if (selectable === true) {
			const listInfo = this.listInfoMap.getListInfo(row.listName);
			listInfo.selectable = true;
			listInfo.setRowComponent(tableRow, row);
		}
	}

	/**
	 * テーブル行コンポーネント削除処理
	 * @param tableRow テーブル行コンポーネント
	 * @param row 行情報
	 */
	public deleteTableRowComponent(tableRow: IWprTableRow, row: WprRowInfo): void {
		const listInfo = this.listInfoMap.getListInfo(row.listName);
		if (listInfo.selectable === true)
			listInfo.deleteRowComponent(tableRow);
	}

	/**
	 * テーブル行選択処理
	 * @param tableRow テーブル行コンポーネント
	 * @param row 行情報
	 */
	public selectTableRow(tableRow: IWprTableRow, row: WprRowInfo): void {
		const listInfo = this.listInfoMap.getListInfo(row.listName);
		if (listInfo.isChangeSelectRow(tableRow, row) === true) {
			if (this.FW.edit.checkEdit(row.listName, this.m_Name, () => {
					this.selectTableRow(tableRow, row);
				}) === false) {
				return; 
			}
			if (listInfo.setSelectRow(tableRow, row) === true) {
				this.onChangeListSelection(row.listName, row.rowData, row.index);
				this.m_SubViewMap.forEach((subView, key, map) => {
					subView.onChangeListSelection(row.listName, row.rowData, row.index);
				});
			}
		}
	}

	/**
	 * ダイアログ設定処理
	 * @param dialog ダイアログ
	 * @param name ダイアログ名
	 * @param dialogViewInfo ダイアログビュー情報
	 */
	public setDialog(dialog: IWprDialog, name: string, dialogViewInfo: WprBaseDialogViewInfo) {
		if (this.m_DialogInfoMap.has(name) === false) {
			const dinfo = new WprDialogInfo(this, dialog, name, dialogViewInfo);
			this.m_DialogInfoMap.set(name, dinfo);
		}
		else {
			this.addErrorLog(`同一のダイアログが既に登録されています。[${name}]`);
		}
	}

	/**
	 * ダイアログ削除処理
	 * @param name ダイアログ名
	 */
	public deleteDialog(name: string) {
		if (this.m_DialogInfoMap.has(name) === true)
			this.m_DialogInfoMap.delete(name);
	}
	
	/**
	 * メッセージコンポーネント設定
	 * @param msgComp メッセージコンポーネント
	 */
	public setMessageComponent(msgComp: IWprMessage) {
		this.m_MessageComponent = msgComp;
	}

	/**
	 * スナックバーコンポーネント設定
	 * @param snbComp スナックバーコンポーネント
	 */
	public setSnackbarComponent(snbComp: IWprSnackbar) {
		this.m_SnackbarComponent = snbComp;
	}

	/**
	 * 子ビュー情報追加
	 * @param name 子ビュー名
	 * @param child 子ビュー情報
	 * @returns trueの場合、showViewを行う
	 */
	public addChildViewInfo(name: string, child: WprBaseViewInfo): boolean {
		this.addInfoLog(`== addChildViewInfo == : [${name}:${this.m_Name}]`);
		if (name) {
			if (this.m_ChildViewMap.has(name) == true) {
				const prev = this.m_ChildViewMap.get(name);
				if (child.name !== prev.name) {
					this.addErrorLog(`addChildView：子ビューのnameが重複しています。[${this.m_Name}][${child.name}]`);
					return;
				}
				const idx = this.m_ChildViewList.indexOf(prev);
				this.m_ChildViewList.splice(idx, 1);
			}
			this.m_ChildViewMap.set(name, child);
		}
		child.parentView = this;
		this.m_ChildViewList.push(child);
		if (this.m_IsStateLock !== null) {
			this.m_WaitShowChildList.push(child);
			return false;
		}
		return true;
	}

	/**
	 * 子ビュー情報削除
	 * @param name 子ビュー名
	 * @param child 子ビュー情報
	 */
	public deleteChildViewInfo(name: string, child: WprBaseViewInfo): void {
		this.addInfoLog(`== deleteChildViewInfo == : [${name}:${this.m_Name}]`);
		if (this.m_ChildViewMap.has(name) == true)
			this.m_ChildViewMap.delete(name);
		if (this.m_ChildViewList.includes(child) === true) {
			const idx = this.m_ChildViewList.indexOf(child);
			this.m_ChildViewList.splice(idx, 1);
		}
	}

	/**
	 * 子ビュー情報取得
	 * @param name 子ビュー名
	 * @returns 子ビュー情報
	 */
	public getChildViewInfo(name: string): WprBaseViewInfo {
		if (this.m_ChildViewMap.has(name) == false) {
			this.addErrorLog(`getChildView：子ビュー名に誤りがあります。[${name}]`);
			return null;
		}
		return this.m_ChildViewMap.get(name);
	}

	/**
	 * 子ビューDI_Object設定
	 * @param name 子ビュー名
	 * @returns trueの場合、成功
	 */
	public setChildDIObject(name?: string): boolean {
		if (name) {
			if (this.m_ChildViewMap.has(name) == false) {
				this.addErrorLog(`setChildDIObject：子ビュー名に誤りがあります。[${name}]`);
				return false;
			}
			this.m_ChildViewMap.get(name).injectionObject();
		}
		else {
			this.m_ChildViewList.forEach(child => {
				child.injectionObject();
			});
		}
		return true;
	}

	/**
	 * 子モデル更新処理
	 * @param name 子ビュー名
	 * @returns trueの場合、成功
	 */
	public refreshChildModel(name?: string): boolean {
		if (name) {
			if (this.m_ChildViewMap.has(name) == false) {
				this.addErrorLog(`refreshChildModel：子ビュー名に誤りがあります。[${name}]`);
				return false;
			}
			this.m_ChildViewMap.get(name).refreshModel();
		}
		else {
			this.m_ChildViewList.forEach(child => {
				child.refreshModel();
			});
		}
		return true;
	}

	/**
	 * 子ビュー再表示処理
	 * @param name 子ビュー名
	 * @returns trueの場合、成功
	 */
	public refreshChildView(name?: string): boolean {
		if (name) {
			if (this.m_ChildViewMap.has(name) == false) {
				this.addErrorLog(`refreshChildView：子ビュー名に誤りがあります。[${name}]`);
				return false;
			}
			this.addDebugInfoLog(`refreshChildView('${name}')`);
			if (this.m_ChildViewMap.get(name).isShow() === true) {
				this.setChildDIObject(name);
				this.m_ChildViewMap.get(name).refreshView();
				this.m_ChildViewMap.get(name).refreshChildView();
			}
		}
		else {
			this.addDebugInfoLog('refreshChildView()');
			this.m_ChildViewList.forEach(child => {
				if (child.isShow() === true) {
					child.injectionObject();
					child.refreshView();
					child.refreshChildView();
				}
			});
		}
		return true;
	}

	/**
	 * 親ビュー再表示処理
	 * @returns trueの場合、成功
	 */
	public refreshParentView(): boolean {
		this.addDebugInfoLog('refreshParentView()');
		if (this.m_Parent) {
			this.m_Parent.refreshView();
			this.m_Parent.refreshParentView();
			return true;
		}
		return false;
	}

	/**
	 * 子ビューへデータを送信
	 * @param dataName 送信データ名
	 * @param data 送信データ
	 * @param childName 子ビュー名(省略時は全ての子が対象)
	 * @returns trueの場合、成功　falseの場合、非同期に実行
	 */
	public sendChildData(dataName: string, data?: any, childName?: string): boolean {
		if (this.m_IsInit == true) {
			if (childName) {
				this.addDebugInfoLog(`sendChildData('${dataName}', '${childName}')`);
				if (this.m_ChildViewMap.has(childName) === false) {
					this.saveSendData(dataName, data, childName);
					return false;
				}
			}
			else {
				this.addDebugInfoLog(`sendChildData('${dataName}')`);
				this.saveSendData(dataName, data, childName);
				if (this.m_ChildViewList.length === 0)
					return false;
			}
		}
		if (childName) {
			this.addDebugInfoLog(`sendChildData('${dataName}', '${childName}')`);
			if (this.m_ChildViewMap.has(childName) == false) {
				this.saveSendData(dataName, data, childName);
				// this.addErrorLog(`refreshChildView：子ビュー名に誤りがあります。[${childName}]`);
				return false;
			}
			this.m_ChildViewMap.get(childName).receiveData(dataName, data);
		}
		else {
			this.addDebugInfoLog(`sendChildData('${dataName}')`);
			this.m_ChildViewList.forEach(child => {
				child.receiveData(dataName, data);
			});
		}
		return true;
	}

	/**
	 * 親ビューへデータを送信
	 * @param dataName 送信データ名
	 * @param data 送信データ
	 * @returns trueの場合、成功　falseの場合
	 */
	public sendParentData(dataName: string, data?: any): boolean {
		this.addDebugInfoLog(`sendParentData('${dataName}')`);
		if (this.m_Parent) {
			this.m_Parent.receiveData(dataName, data);
			return true;
		}
		return false;
	}

	/**
	 * データ受信処理
	 * @param dataName データ名
	 * @param data データ
	 */
	public receiveData(dataName: string, data?: any) {
		this.addDebugInfoLog(`receiveData('${dataName}')`);
		if (this.parentView != null && this.parentView.isStateLock === true) {
			this.m_TabChildViewMap.receiveData(dataName, data);
			return;
		}
		this.onReceiveData(dataName, data);
		this.m_SubViewMap.forEach((subView, key, map) => {
			subView.onReceiveData(dataName, data);
		});
	}

	/**
	 * ロード中ValueMapチェック
	 * @param name Valuemap名
	 * @returns trueの場合、ロード中
	 */
	public isLoadValueMap(name: string): boolean {
		return this.FW.model.isLoadValueMap(name);
	}

	/**
	 * ValueMapロード完了通知
	 * @param name ValueMap名
	 */
	public onLoadEndValueMap(name: string) {
		if (this.m_LoadWaitComponentMap.has(name) === true) {
			this.m_LoadWaitComponentMap.get(name).forEach((comp) => {
				comp.onLoadEnd();
			});
		}
	}

	/**
	 * 送信待ちデータクリア
	 */
	public clearWaitSendData() {
		this.m_SendDataMap.clear();
	}

	/**
	 * 送信待ちデータ取得
	 * @param name 子要素名
	 * @returns 送信待ちデータ
	 */
	public getWaitSendData(name: string): WprViewSendDataInfo {
		if (this.m_SendDataMap.has(name) === true)
			return this.m_SendDataMap.get(name);
		if (this.m_SendDataMap.has('') === true)
			return this.m_SendDataMap.get('');
		return null;
	}
	
	/**
	 * 行データ設定
	 * @param data 行データ
	 */
	public setRowData(data: any): void {
		this.m_RowData = data;
		this.onSetRowData(data);
		this.m_SubViewMap.forEach(subView => {
			subView.onSetRowData(data);
		});
	}

	/**
	 * 行データ更新処理
	 * @param data 行データ
	 */
	public updateRowData(data: any): void {
		if (this.m_RowData && this.m_RowData !== data) {
			setTimeout(() => {
				this.setRowData(data);
			}, 10);
		}
	}

	/**
	 * ビュープロパティオプション取得
	 * @returns ビュープロパティオプション
	 */
	public getViewOption(): any {
		if (this.props)
			return this.props.option;
		return null;
	}

	/**
	 * UserAgentレンダ―設定
	 * @param render UserAgentレンダ―
	 */
	public setUserAgentRender(render: string) {
		this.m_UserAgentRender = render;
	}

	/**
	 * レンダー待ち開始
	 */
	public startRenderWait() {
		this.addDebugInfoLog('startRenderWait()');
		this.m_Component.setState({
			renderWait: true
		});
	}

	/**
	 * レンダー待ち終了
	 */
	public endRenderWait() {
		this.addDebugInfoLog('endRenderWait()');
		this.m_Component.setState({
			renderWait: false
		});
	}

	/**
	 * タブ表示制御子ビュー名追加
	 * @param vname タブ表示制御子ビュー名
	 * @param name 名称
	 * @param mode タブモード
	 * @param label タブラベル
	 */
	public addTabChildViewName(vname: string, name: string, mode: string, label: string): void {
		this.addInfoLog(`== addTabShowChildView == [${vname}:${name}:${label}]`);
		this.m_TabChildViewMap.addChildView(this, vname, name, mode, label);
		this.FW.view.addTabChildView(vname, name);
	}

	/**
	 * タブ子ビュー設定処理
	 * @param vinfo ビュー情報
	 * @returns trueの場合、設定
	 */
	public setTabChildView(vinfo: WprBaseViewInfo): boolean {
		return this.m_TabChildViewMap.setTabChildView(vinfo);
	}

	/**
	 * タブ子ビューチェック
	 * @param name ビュー名
	 * @returns falseの場合、タブの子ビューは、非表示
	 */
	public isTabChildView(): boolean {
		return this.FW.view.isTabChildView(this.name, this.props.name);
	}

	/**
	 * タブ子ビュー削除処理
	 */
	public removeTabChildView(): void {
		this.FW.view.removeTabChildView(this.name, this.props.name);
	}

	/**
	 * レンダー待ち処理
	 * @param parent 親ビュー
	 * @returns レンダー待ち状態
	 */
	public waitRender(parent: WprBaseViewInfo): boolean {
		if (this.isDialog === true)
			return false;
		if (parent) {
			if (parent.isStateLock === true) {
				this.addInfoLog('== Wait onRender ==');
				this.m_IsWaitReder = true;
				return true;
			}
		}
		this.m_IsWaitReder = false;
		return false;
	}

	/**
	 * レンダー待ち再開処理
	 */
	public restart(): void {
		if (this.m_IsWaitReder === true) {
			this.m_Component.setState({
				restart: true
			});
		}
	}

	/**
	 * タブ表示処理
	 * @param tab 表示状態
	 */
	public setTabDisp(tab: boolean, dataList: WprTabReceiveDataInfo[]=null): void {
		if (this.parentView != null && this.parentView.m_IsStateLock == null) {
			if (tab === true) {
				if (this.m_TabChildViewMap.isFirstDisp === false) {
					this.addInfoLog('== tab disp 2回目以降 ==');
//					this.showView();
//					this.m_TabChildViewMap.sendData();
					setTimeout(() => {
						this.showView();
						this.m_TabChildViewMap.sendData();
					}, 10);
				}
				else {
					this.m_TabChildViewMap.isFirstDisp = false;
					setTimeout(() => {
						this.addInfoLog('== tab disp 初回 ==');
						this.m_Component.tabViewMount();
						if (this.props.row)
							this.setRowData(this.props.row.rowData);
						this.showView();
						this.m_TabChildViewMap.sendData();
					}, 10);
				}
			}
			this.m_TabDispCount++;
			this.m_Component.setState({
				tab: tab,
				tabDisp: this.m_TabDispCount
			});
		}
	}

	/**
	 * 送信待ちデータ送信処理
	 */
	public sendWaitData(): void {
		this.m_TabChildViewMap.sendData();
	}

	/**
	 * モデルオブジェクト取得
	 * @returns モデルオブジェクト
	 */
	public getModelObject(): any {
		if (this.modelMap.size > 0) {
			const obj = {};
			this.modelMap.forEach((val, mname) => {
				obj[mname] = WprReflectUtil.getProperty(this, val);
			});
			return obj;
		}
		return null;
	}

	/**
	 * タブ設定
	 * @param tab タブコンポーネント
	 */
	public setTab(tab: MatTab): void {
		let label = tab.props.label;
		if (tab.props.labelMode)
			label = tab.props.labelMode;
		this.m_TabMap.set(label, tab);
	}

	/**
	 * タブ幅設定
	 * @param label ラベル
	 * @param cnt 表示タブ数
	 */
	public setTabWidth(label: string, cnt: number) {
		if (this.m_TabMap.has(label) === true) {
			this.m_TabMap.get(label).setState({
				numOption1: cnt
			});
		}
	}

	/**
	 * モバイルフラグ取得
	 * @returns モバイルフラグ
	 */
	public isMobile(): boolean {
		return this.FW.isMobile();
	}
	// --------------------------------------------------------------------------

	// html使用メソッド	  --------------------------------------------------------
	/**
	 * ValueMapから候補リスト取得
	 * @param name コントロール名
	 * @param id ValueMapID
	 * @returns Key-Valueリスト
	 */
	public vm(name: string, id: string, row?: WprRowInfo): WprKeyValue[] {
		let list = this.FW.model.getValueList(id, false);
		if (list != null) {
			if (row) {
				list = this.onResetListValueMap(name, id, row, list);
				this.m_SubViewMap.forEach((subView, key, map) => {
					list = subView.onResetListValueMap(name, id, row, list);
				});
			}
			else {
				list = this.onResetValueMap(name, id, list);
				this.m_SubViewMap.forEach((subView, key, map) => {
					list = subView.onResetValueMap(name, id, list);
				});
			}
		}
		if (this.m_KeyValueMap.has(id) === false)
			this.m_KeyValueMap.set(id, new WprKeyValueMap());
		return this.m_KeyValueMap.get(id).getKeyValueList(list);
	}

	/**
	 * Null選択付きのValueMapから候補リスト取得
	 * @param name コントロール名
	 * @param id ValueMapID
	 * @returns Key-Valueリスト
	 */
	/* 廃止
	public vmn(name: string, id: string, row?: WprRowInfo): WprKeyValue[] {
		return this.vm(name, id, true);
	}
	*/
	// --------------------------------------------------------------------------

	// リスト関連 メソッド  -------------------------------------------------------
	/**
	 * リストデータ設定処理
	 * @param listName リスト名
	 * @param listData リストデータ
	 * @param total リストデータ総数
	 */
	public setListData(listName: string, listData: Array<any>, total?: number): void {
		this.addDebugInfoLog(`setListData('${listName}')`);
		let ret = this.listInfoMap.setListData(listName, listData);
		this.m_SubViewMap.forEach((subView, key, map) => {
			if (ret === false)
				ret = subView.listInfoMap.setListData(listName, listData);
		});
		if (ret === true) {
			if (!this.m_IsStateLock) {
				this.m_Component.setState({
					listData: this.getListDataState()
				});
			}
			else {
				this.m_IsStateLock.listData = this.getListDataState();
				this.addInfoLog('== Wait listData ==');
			}
			if (total)
				this.setListTotalCount(listName, total);
			if (this.m_TableContainerMap.has(listName) === true) {
				this.m_TableContainerMap.get(listName).forEach(container => {
					container.setDataCount(listData.length);
				});
			}
		}
		else {
			this.addErrorLog(`setListData：リスト名に誤りがあります。[${listName}]`);
		}
	}

	/**
	 * リストデータ追加処理
	 * @param listName リスト名
	 * @param data 追加データ
	 */
	public pushListData(listName: string, data: any): void {
		this.addDebugInfoLog(`pushListData('${listName}')`);
		const listInfo = this.listInfoMap.getListInfo(listName);
		if (listInfo) {
			const listData = listInfo.listData;
			listData.push(data);
			this.setListData(listName, listData);
		}
		else {
			this.addErrorLog(`pushListData：リスト名に誤りがあります。[${listName}]`);
		}
	}

	/**
	 * リストデータ挿入処理
	 * @param listName リスト名
	 * @param index 挿入位置
	 * @param data 追加データ
	 */
	public insertListData(listName: string, index: number, data: any): void {
		this.addDebugInfoLog(`insertListData('${listName}')`);
		const listInfo = this.listInfoMap.getListInfo(listName);
		if (listInfo) {
			const listData = listInfo.listData;
			if (listData.length < index) {
				this.addErrorLog(`insertListData：挿入位置に誤りがあります。[${listName}][${index}]`);
			}
			else {
				listData.splice(index, 0, data);
				this.setListData(listName, listData);
			}	
		}
		else {
			this.addErrorLog(`insertListData：リスト名に誤りがあります。[${listName}]`);
		}
	}

	/**
	 * リストデータ置換え処理
	 * @param listName リスト名
	 * @param before 置換え前データ
	 * @param after 置換え後データ
	 */
	public replaceListData(listName: string, before: any, after: any): void {
		this.addDebugInfoLog(`replaceListData('${listName}')`);
		const listInfo = this.listInfoMap.getListInfo(listName);
		if (listInfo) {
			const listData = listInfo.listData;
			const idx = listData.indexOf(before);
			if (idx >= 0) {
				listData[idx] = after;
				this.setListData(listName, listData);
			}
			else {
				this.addErrorLog(`replaceListData：置換え前データがリストにありません。[${listName}]`);
			}
		}
		else {
			this.addErrorLog(`replaceListData：リスト名に誤りがあります。[${listName}]`);
		}
	}

	/**
	 * リストデータ削除処理
	 * @param listName リスト名
	 * @param delData 削除データ
	 */
	public deleteListData(listName: string, delData: any): void {
		this.addDebugInfoLog(`deleteListData('${listName}')`);
		const listInfo = this.listInfoMap.getListInfo(listName);
		if (listInfo) {
			const listData = listInfo.listData;
			const idx = listData.indexOf(delData);
			if (idx >= 0) {
				listData.splice(idx, 1);
				this.setListData(listName, listData);
			}
			else {
				this.addErrorLog(`deleteListData：削除前データがリストにありません。[${listName}]`);
			}
		}
		else {
			this.addErrorLog(`deleteListData：リスト名に誤りがあります。[${listName}]`);
		}
	}

	/**
	 * リストデータ更新処理
	 * @param listName リスト名
	 */
	public updateListData(listName: string): void {
		this.listInfoMap.refreshListView(listName);
		/*
		const listInfo = this.listInfoMap.getListInfo(listName);
		if (listInfo) {
			listInfo.refreshView();
		}
		else {
			this.addErrorLog(`updateListData：リスト名に誤りがあります。[${listName}]`);
		}
		*/
		/*
		if (listName !== undefined && this.listInfoMap.getListInfo(listName) === null) {
			this.addErrorLog(`updateListData：リスト名に誤りがあります。[${listName}]`);
		}
		else {
			this.m_Component.setState({
				listData: this.getListDataState()
			});
		}
		*/
	}

	/**
	 * 行再表示処理
	 * @param listName リスト名
	 * @param row 行データ 
	 * @returns trueの場合成功
	 */
	public refreshRowView(listName: string, row: any): boolean {
		const listInfo = this.listInfoMap.getListInfo(listName);
		if (listInfo) {
			if (listInfo.listData.includes(row) === false) {
				this.addErrorLog(`refreshRowView：行データに誤りがあります。[${listName}]`);
			}
			else {
				listInfo.refreshView(row);
				return true;
			}
		}
		else {
			this.addErrorLog(`refreshRowView：リスト名に誤りがあります。[${listName}]`);
		}
		return false;
	}

	/**
	 * ValueMapリセット処理
	 * @param listName リスト名
	 * @param row 行データ
	 * @param name 名前 
	 * @param clearValue 値クリアフラグ
	 * @returns trueの場合成功
	 */
	public resetListValueMap(listName: string, row: any, name: string, clearValue: boolean=true): boolean {
		const listInfo = this.listInfoMap.getListInfo(listName);
		if (listInfo) {
			if (listInfo.listData.includes(row) === false) {
				this.addErrorLog(`resetListValueMap：行データに誤りがあります。[${listName}]`);
			}
			else {
				listInfo.resetValueMap(row, name, clearValue);
				return true;
			}
		}
		else {
			this.addErrorLog(`resetListValueMap：リスト名に誤りがあります。[${listName}]`);
		}
		return false;
	}

	/**
	 * 行データ選択処理
	 * @param listName リスト名
	 * @param rowData 行データ
	 * @returns falseの場合、選択失敗
	 */
	public selectListData(listName: string, rowData: any): boolean {
		let listInfo = this.listInfoMap.getListInfo(listName);
		if (listInfo) {
			setTimeout(() => {
				listInfo = this.listInfoMap.getListInfo(listName);
				if (listInfo.selectable === false) {
					this.addErrorLog(`selectListData：SelectableのListではありません。選択するには、MatTableRowをselectableにする必要があります。[${listName}]`);
				}
				else if (listInfo.listData.includes(rowData) === false) {
					this.addErrorLog(`selectListData：行データに誤りがあります。選択できません。[${listName}]`);
				}
				else {
					if (listInfo.selectLock === false) {
						const rowInfo = listInfo.selectListData(rowData);
						if (rowInfo != null) {
							this.onChangeListSelection(rowInfo.listName, rowInfo.rowData, rowInfo.index);
							this.m_SubViewMap.forEach((subView, key, map) => {
								subView.onChangeListSelection(rowInfo.listName, rowInfo.rowData, rowInfo.index);
							});
							return true;
						}
						this.addErrorLog(`selectListData：選択に失敗しました。[${listName}]`);
					}
				}
			}, 10);
			return true;
		}
		else {
			this.addErrorLog(`selectListData：リスト名に誤りがあります。[${listName}]`);
		}
		return false;
	}

	/**
	 * 行データ選択ロック開始
	 * @param listName リスト名
	 */
	public startSelectLock(listName: string): void {
		const listInfo = this.listInfoMap.getListInfo(listName);
		if (listInfo) {
			listInfo.setSelecctLock(true);
		}
		else {
			this.addErrorLog(`startSelectLock：リスト名に誤りがあります。[${listName}]`);
		}
	}

	/**
	 * 行データ選択ロック終了
	 * @param listName リスト名
	 */
	public endSelectLock(listName: string): void {
		const listInfo = this.listInfoMap.getListInfo(listName);
		if (listInfo) {
			listInfo.setSelecctLock(false);
		}
		else {
			this.addErrorLog(`endSelectLock：リスト名に誤りがあります。[${listName}]`);
		}
	}

	/**
	 * リストデータをビューに反映する
	 * @param listName リスト名
	 * @param isErrorCheck エラーチェックフラグ
	 */
	public refreshListView(listName?: string, isErrorCheck: boolean = true): void {
		let ret = this.listInfoMap.refreshListView(listName);
		this.m_SubViewMap.forEach((subView, key, map) => {
			if (ret === false || listName === undefined)
				ret = subView.listInfoMap.refreshListView(listName);
		});
		if (isErrorCheck === true && ret === false && listName !== undefined)
			this.addErrorLog(`refreshListView：リスト名に誤りがあります。[${listName}]`);
	}

	/**
	 * リストデータをビューに反映する
	 * @param listName リスト名
	 * @param isErrorCheck エラーチェックフラグ
	 */
	public refreshListModel(listName?: string, isErrorCheck: boolean = true): void {
		let ret = this.listInfoMap.refreshListModel(listName);
		this.m_SubViewMap.forEach((subView, key, map) => {
			if (ret === false || listName === undefined)
				ret = subView.listInfoMap.refreshListModel(listName);
		});
		if (isErrorCheck === true && ret === false && listName !== undefined)
			this.addErrorLog(`refreshListModel：リスト名に誤りがあります。[${listName}]`);
		this.updateListData(listName);
	}

	/**
	 * リスト値をクリアする
	 * @param listName リスト名
	 * @param isErrorCheck エラーチェックフラグ
	 */
	public clearListValue(listName?: string, isErrorCheck: boolean = true): void {
		let ret = this.listInfoMap.clearListValue(listName);
		this.m_SubViewMap.forEach((subView, key, map) => {
			if (ret === false || listName === undefined)
				ret = subView.listInfoMap.clearListValue(listName);
		});
		if (isErrorCheck === true && ret === false && listName !== undefined)
			this.addErrorLog(`clearListValue：リスト名に誤りがあります。[${listName}]`);
	}

	/**
	 * リスト入力チェック
	 * @param listName リスト名
	 * @return trueの場合、入力済みでエラーなし
	 */
	public isInputList(listName?: string): boolean {
		if (this.listInfoMap.isInputList(listName) === false)
			return false;
		this.m_SubViewMap.forEach((subView, key, map) => {
			if (subView.listInfoMap.isInputList(listName) === false)
				return false;
		});
		return true;
	}

	/**
	 * リスト情報クリア処理
	 */
	public clearListInfo(): void {
		this.listInfoMap.clearListInfo();
		this.m_SubViewMap.forEach((subView, key, map) => {
			subView.listInfoMap.clearListInfo();
		});
	}

	/**
	 * 行情報のインデクス取得
	 * @param listName リスト名
	 * @param row 行情報
	 * @returns 行情報のインデクス
	 */
	public getListRowIndex(listName: string, row: any): number {
		const listInfo = this.listInfoMap.getListInfo(listName);
		if (listInfo) {
			return listInfo.listData.indexOf(row);
		}
		else {
			this.addErrorLog(`sortListData：リスト名に誤りがあります。[${listName}]`);
		}
		return -1;
	}

	/**
	 * リストデータソート処理
	 * @param listName リスト名
	 * @param item ソートアイテム
	 * @param subModelList サブモデルリスト
	 * @param isAsc 昇順フラグ
	 */
	public sortListData(listName: string, item: string, subModelList: string[], isAsc: boolean): void {
		const listInfo = this.listInfoMap.getListInfo(listName);
		if (listInfo) {
			const listData = listInfo.listData;
			if (listData.length <= 1)
				return;
			let model = this.getSubModel(listData[0], subModelList);
			if (model != null && WprReflectUtil.getProperty(model, item)) {
				let asc = 1;
				if (isAsc === false)
					asc = -1;
				const sortedList = listData.slice().sort((a, b) => {
					const model1 = this.getSubModel(a, subModelList);
					const model2 = this.getSubModel(b, subModelList);
					if (model1[item] > model2[item])
						return 1 * asc;
					if (model1[item] < model2[item])
						return -1 * asc;
					return 0;
				});
				this.setListData(listName, sortedList);
			}
			else {
				this.addErrorLog(`sortListData：ソートするitemに誤りがあります。[${listName}][${item}]`);
			}
		}
		else {
			this.addErrorLog(`sortListData：リスト名に誤りがあります。[${listName}]`);
		}
	}

	/**
	 * サブモデル取得
	 * @param model モデル情報
	 * @param subModelList サブモデル名リスト
	 * @returns サブモデル
	 */
	public getSubModel(model: any, subModelList: string[]): any {
		if (subModelList != null) {
			subModelList.forEach(mname =>{
				if (model != null)
					model = WprReflectUtil.getProperty(model, mname);
			});
		}
		return model;
	}

	/**
	 * リストデータ総数設定（ページング時に使用）
	 * @param listName リスト名
	 * @param total リストデータ総数
	 * @param isPagnationEvent ページングイベントフラグ
	 */
	public setListTotalCount(listName: string, total: number, isPagnationEvent: boolean = false, isRetry: boolean = false) {
		if (!listName || this.listInfoMap.getListInfo(listName) === null) {
			this.addErrorLog(`setListTotalCount：リスト名に誤りがあります。[${listName}]`);
		}
		else {
			this.listInfoMap.getListInfo(listName).totalCount = total;
			if (this.m_TablePainationMap.has(listName) === false) {
				if (isRetry === false) {
					setTimeout(() => {
						this.setListTotalCount(listName, total, isPagnationEvent, true);
					}, 10);
				}
				else {
					this.addErrorLog(`setListTotalCount：ページングコンポーネントがみつかりませんでした。[${listName}]`);
				}
			}
			else {
				this.m_TablePainationMap.get(listName).forEach((page) => {
					page.setTotalCount(total, isPagnationEvent);
				});
			}
		}
		return true;
	}

	/**
	 * リストのページを設定する
	 * @param listName リスト名
	 * @param page ページ番号
	 * @param rowsPerPage 1ページの行数
	 * @param isPagnationEvent ページング変更通知発生フラグ
	 * @param isRetry リトライフラグ
	 * @returns falseの場合失敗
	 */
	public setListPage(listName: string, page: number, rowsPerPage?: number, isPagnationEvent: boolean = false, isRetry: boolean = true): boolean {
		if (!listName || this.listInfoMap.getListInfo(listName) === null) {
			this.addErrorLog(`setListTotalCount：リスト名に誤りがあります。[${listName}]`);
			return false;
		}
		else {
			if (this.m_TablePainationMap.has(listName) === false) {
				if (isRetry === true) {
					setTimeout(() => {
						this.setListPage(listName, page, rowsPerPage, isPagnationEvent, false);
					}, 10);
				}
				else {
					this.addErrorLog(`setListTotalCount：ページングコンポーネントがみつかりませんでした。[${listName}]`);
				}
			}
			else {
				this.m_TablePainationMap.get(listName).forEach((pinfo) => {
					pinfo.setPage(page, rowsPerPage, isPagnationEvent);
				});
			}
		}
		return true;
	}

	/**
	 * リストデータ総数取得（ページング時に使用）
	 * @param listName リスト名
	 * @returns リストデータ総数
	 */
	public getListTotalCount(listName: string): number {
		if (!listName || this.listInfoMap.getListInfo(listName) === null) {
			this.addErrorLog(`getListTotalCount：リスト名に誤りがあります。[${listName}]`);
			return 0;
		}
		return this.listInfoMap.getListInfo(listName).totalCount;
	}
	
	/**
	 * リスト権限再設定
	 * @param listName リスト名
	 * @param isErrorCheck エラーチェックフラグ
	 */
	public refreshListAuth(listName?: string, isErrorCheck: boolean = true): void {
		let ret = this.listInfoMap.refreshListAuth(listName);
		this.m_SubViewMap.forEach((subView, key, map) => {
			if (ret === false || listName === undefined)
				ret = subView.listInfoMap.refreshListAuth(listName);
		});
		if (isErrorCheck === true && ret === false && listName !== undefined)
			this.addErrorLog(`refreshListAuth：リスト名に誤りがあります。[${listName}]`);
	}

	/**
	 * stateLock待ちアクション設定
	 * @param action アクション
	 * @returns falseのばあい、stateLock中ではない
	 */
	public setStateWaiteAction(action: () => void): boolean {
		if (this.isStateLock === true) {
			this.m_WaitAction = action;
			return true;
		}
		return false;
	}
	// --------------------------------------------------------------------------

	// private メソッド  ---------------------------------------------------------
	/**
	 * プロパティ取得
	 * @returns プロパティ
	 */
	private getProps(): any {
		if (this.m_Component)
			return this.m_Component.props;
		return null;
	}

	/**
	 * サブビュー情報初期化
	 */
	private initSubViewInfo() {
		this.FW.model.getDecoNameList('SubView', this).forEach((sname) => {
			const subView = WprReflectUtil.getProperty(this, sname) as WprBaseSubView;
			if (subView) {
				if (this.m_SubViewMap.has(subView.name) === false) {
					subView.viewInfo = this;
					this.m_SubViewMap.set(subView.name, subView);
				}
				else {
					this.addErrorLog(`同一の@WprSubViewが既に登録されています。[${subView.name}][${subView.objectName}]`);
				}
			}
		});
	}

	/**
	 * コントロールリスト設定
	 */
	private setControlList(): void {
		this.m_ControlInfoMap.addControlList(this);
		this.m_SubViewMap.forEach((subView, key, map) => {
			this.m_ControlInfoMap.addControlList(subView, subView.name);
		});
	}

	/**
	 * ビューデータ取得
	 */
	private getViewDataState(): any {
		const data: any = {};
		this.m_ViewDataMap.forEach((value, name, map) => {
			data[name] = value;
		});
		return data;
	}

	/**
	 * ビューモード取得
	 */
	private getViewModeState(): any {
		const mode: any = {};
		this.m_ViewModeDataMap.forEach((value, name, map) => {
			mode[name] = value;
		});
		return mode;
	}

	/**
	 * リストデータステータス取得
	 * @returns リストデータステータス
	 */
	private getListDataState(): any {
		const listData: any = {};
		this.listInfoMap.setListDataState(listData);
		this.m_SubViewMap.forEach((subView, key, map) => {
			subView.listInfoMap.setListDataState(listData);
		});
		return listData;
	}

	/**
	 * スコープ情報設定
	 */
	public setScopeInfo(): void {
		this.globalScopeInfo = this.FW.scope.globalScopeInfo;
		if (this.getScopeInfo() != null)
			this.getScopeInfo().setDIModelInfo(this, this.FW.model.getDecoNameMap('ScopeModel', this));
		if (this.globalScopeInfo != null)
			this.globalScopeInfo.setDIModelInfo(this, this.FW.model.getDecoNameMap('GlobalScopeModel', this));
		this.m_SubViewMap.forEach(subView => {
			subView.setScopeInfo(this.globalScopeInfo, this.getScopeInfo());
		});
	}

	/**
	 * コンポーネントモードチェック処理
	 * @param viewMode ビューモード名
	 * @param mode モード
	 * @returns tureの場合、モードは含まれている
	 */
	private checkComponentMode(viewMode: string, mode: string): boolean {
		if (this.checkViewMode(viewMode, mode) === true)
			return true;
		for(const subView of this.m_SubViewMap.values()) {
			if (subView.checkViewMode(viewMode, mode) === true)
				return true;
		}
		if (this.getScopeInfo() != null) {
			if (this.getScopeInfo().checkMode(viewMode, mode) === true)
				return true;
		}
		if (this.m_GlobalScopeInfo != null) {
			if (this.m_GlobalScopeInfo.checkMode(viewMode, mode) === true)
				return true;
		}
		if (this.FW.getUrlParam(viewMode) != null)
			return true;
		return false;
	}

	/**
	 * ビューモードデータチェック
	 * @param viewMode ビューモード名
	 * @param mode モード
	 * @returns tureの場合、モードは含まれている
	 */
	private checkViewModeData(viewMode: string, mode: string): boolean {
		for(const key of this.m_ViewModeDataMap.keys()) {
			if (!viewMode || viewMode === key) {
				if (this.m_ViewModeDataMap.get(key) === mode)
					return true;
			}
		}
		return false;
	}

	/**
	 * 権限チェック
	 * @param auth 権限
	 * @returns trueの場合、権限あり
	 */
	private checkAuth(auth: string) {
		return this.FW.auth.checkUserAuthority([auth]);
	}

	/**
	 * ビューモード名チェック
	 * @param name ビューモード名
	 * @returns falseの場合、ビューモード名は重複している
	 */
	private checkModeName(name: string): boolean {
		if (this.checkViewModeName(name) === true)
			return true;
		for(const subView of this.m_SubViewMap.values()) {
			if (subView.checkViewModeName(name) === true)
				return true;
		}
		return false;
	}

	/**
	 * ビューモードステータス設定処理
	 */
	private setViewModeState() {
		if (this.isMount === false)
			return;
		if (!this.m_IsStateLock) {
			this.m_Component.setState({
				mode: this.getViewModeState()
			});
			this.updateComponentMode();
		}
		else {
			this.m_IsStateLock.mode = this.getViewModeState();
			this.addInfoLog('== Wait mode ==');
		}
	}

	/**
	 * ビューモードデータ設定処理
	 * @param name: ビューモード名
	 * @param mode モード
	 * @returns falseの場合、モードは変化がない
	 */
	private setViewModeData(name: string, mode: any): boolean {
		if (this.m_ViewModeDataMap.has(name) === true && this.m_ViewModeDataMap.get(name) === mode)
			return false;
		this.m_ViewModeDataMap.set(name, mode);
		this.setViewModeState();
		return true;
	}

	/**
	 * 読取り専用コントロール再表示処理
	 * @param name コントロール名称
	 */
	private refreshReadOnlyControl(name?: string): void {
		WprControlInfo.isReadonlyRefresh = true;
		try {
			if (name === undefined)
				this.m_ControlInfoMap.refreshView(this, '@@all');
			else
				this.m_ControlInfoMap.refreshView(this, name);
			this.refreshListView();
		}
		finally {
			WprControlInfo.isReadonlyRefresh = false;
		}
	}

	/**
	 * スクロールイベント処理
	 */
	private onScroll(): void {
		window.setTimeout(this.onScrollEvent.bind(this), 10);
	}
	private onScrollEvent(): void {
		this.onScrollWindow(window.pageXOffset, window.pageYOffset);
		this.m_SubViewMap.forEach(subView => {
			subView.onScrollWindow(window.innerWidth, window.innerHeight);
		});
	}

	/**
	 * リサイズイベント処理
	 */
	private onResize(): void {
		window.setTimeout(this.onResizeEvent.bind(this), 10);
	}
	private onResizeEvent(): void {
		this.onResizeWindow(window.innerWidth, window.innerHeight);
		this.m_SubViewMap.forEach(subView => {
			subView.onResizeWindow(window.innerWidth, window.innerHeight);
		});
	}

	/**
	 * ウインドウイベント処理
	 */
	private startWindowEvent(): void {
		this.setScrollEvent();
		this.setResizeEvent();
		this.ChildViewList.forEach(child => {
			if (child.props.name)
				child.startWindowEvent();
		});
	}

	/**
	 * スクロールイベント設定処理
	 */
	private setScrollEvent(): void {
		let useScroll = false;
		this.onScrollWindow(window.pageXOffset, window.pageYOffset);
		if (this.useScrollEvent === true)
			useScroll = true;
		this.m_SubViewMap.forEach(subView => {
			subView.onScrollWindow(window.innerWidth, window.innerHeight);
			if (subView.useScrollEvent === true)
				useScroll = true;
		});
		if (useScroll === true) {
			this.m_ScrollEventMethod = this.onScroll.bind(this);
			window.addEventListener('scroll', this.m_ScrollEventMethod, true);
		}
	}

	/**
	 * リサイズイベント設定処理
	 */
	private setResizeEvent(): void {
		let useResize = false;
		this.onResizeWindow(window.innerWidth, window.innerHeight);
		if (this.useResizeEvent === true)
			useResize = true;
		this.m_SubViewMap.forEach(subView => {
			subView.onResizeWindow(window.innerWidth, window.innerHeight);
			if (subView.useResizeEvent === true)
				useResize = true;
		});
		if (useResize === true) {
			this.m_ResizeEventMethod = this.onResize.bind(this);
			window.addEventListener('resize', this.m_ResizeEventMethod, true);
		}
	}

	/**
	 * ウインドウスクロールイベント停止処理
	 */
	private stopWindowScrollEvent(): void {
		if (this.m_ScrollEventMethod != null) {
			window.removeEventListener('scroll', this.m_ScrollEventMethod, true);
			this.m_ScrollEventMethod = null;
		}
	}

	/**
	 * ウインドウリサイズイベント停止処理
	 */
	private stopWindowResizeEvent(): void {
		if (this.m_ResizeEventMethod != null) {
			window.removeEventListener('resize', this.m_ResizeEventMethod, true);
			this.m_ResizeEventMethod = null;
		}
	}

	/**
	 * モード初期化処理
	 */
	protected initOtherMode(): void {
		this.m_TabModeMap.forEach((tinfo, key, map) => {
			tinfo.initialize(this);
		});
		this.m_TableSortModeMap.forEach((tinfo, key, map) => {
			tinfo.initialize(this);
		});
	}

	/**
	 * 開始フォーカス設定
	 */
	private setStartFocus() {
		for(const cinfo of this.controlMap.values()) {
			if (cinfo.isInput() === true) {
				cinfo.setFocus();
				break;
			}
		}
	}

	/**
	 * サブモデル名設定処理
	 * @param modelName モデル名
	 */
	private getSubModelList(modelName: string): string[] {
		if (modelName && modelName.includes('.') === true) {
			const mlist = new Array();
			modelName.split('.').forEach((model, index) => {
				if (index > 0)
					mlist.push(model);
			});
			return mlist;
		}
		return null;
	}

	/**
	 * リスト名取得
	 * @param listlName 
	 * @returns 
	 */
	private getListName(listlName: string): string {
		if (listlName && listlName.includes('.') === true) {
			const idx = listlName.indexOf('.');
			return listlName.substr(0, idx);
		}
		return listlName;
	}

	/**
	 * 送信データ保存処理
	 * @param dataName データ名
	 * @param data データ
	 * @param childName 子要素名
	 */
	private saveSendData(dataName: string, data: any, childName?: string): void {
		if (!childName)
			childName = '';
		this.m_SendDataMap.set(childName, new WprViewSendDataInfo(dataName, data));
	}

	/**
	 * インジェクション設定処理
	 */
	private setInjection(): void {
		this.execInjection();
		this.m_SubViewMap.forEach((subView, key, map) => {
			subView.execInjection();
		});
	}

	/**
	 * ウインドの先頭へ移動
	 */
	private scrollToTop(): void {
		if (this.props.match)
			window.scroll(0, 0);
	}

	/**
	 * 変更フラグ取得
	 * @param name コントロール名
	 * @return 変更フラグ
	 */
	private getDirty(name?: string): boolean {
		if (name === undefined)
			return this.m_ControlInfoMap.getDirty(this, '@@all');
		else
			return this.m_ControlInfoMap.getDirty(this, name);
	}

	/**
	 * URLパラメータ追加
	 * @param path URLパス
	 * @param params URLパラメータ情報リスト
	 * @returns URLパス
	 */
	private addUrlParam(path: string, params?: IWprUrlParam[]): string {
		if (params) {
			let cnt = 0;
			params.forEach(param => {
				if (cnt === 0)
					path += '?' + param.name + '=' + param.value;
				else
					path += '&' + param.name + '=' + param.value;
				cnt++;
			});
			return path;
		}
	}

	/**
	 * 移動先ビュー情報設定
	 * @param params パラメータ情報リスト
	 * @param isValid バリデーション実行フラグ
	 * @param useViewData ビューデータ使用フラグ(trueの場合、urlパラメータは使用せずにsetViewDataを使用する)
	 */
	private setMoveViewInfo(params: IWprUrlParam[], isValid: boolean, useViewData: boolean): void {
		if (isValid === true)
			this.FW.view.startValidate = 'pushPath';
		else
			this.FW.view.startValidate = null;
		if (params && useViewData === true)
			this.FW.view.viewData = params;
		else
			this.FW.view.viewData = null;
	}

	/**
	 * 移動元ビューデータ設定
	 */
	private setMoveViewData() {
		if (this.FW.view.viewData) {
			this.FW.view.viewData.forEach(param => {
				this.setViewData(param.name, param.value);
			});
		}
	}
	// --------------------------------------------------------------------------
}

/**
 * 送信データ情報
 */
class WprViewSendDataInfo {
	// private 変数  ------------------------------------------------------------
	private m_DataName: string		= null;		// データ名
	private m_Data: any 			= false;	// データ
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** データ名 */
	public get dataName(): string 	{ return this.m_DataName; 	}
	/** データ */
	public get data(): any	 		{ return this.m_Data; 		}
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	public constructor(dataName: string, data: any) {
		this.m_DataName = dataName;
		this.m_Data = data;
	}
	// --------------------------------------------------------------------------
}

/**
 * 子要素モード設定情報
 */
class WprViewSetChildModeInfo {
	// private 変数  ------------------------------------------------------------
	private m_Name: string			= null;		// モード名
	private m_Mode: any 			= false;	// モード
	private m_ChildName: string		= null;		// 子要素名
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** デーモード名タ名 */
	public get name(): string 		{ return this.m_Name; 		}
	/** モード */
	public get mode(): any	 		{ return this.m_Mode; 		}
	/** 子要素名 */
	public get childName(): string	 { return this.m_ChildName; }
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	public constructor(name: string, mode: any, childName: string) {
		this.m_Name = name;
		this.m_Mode = mode;
		this.m_ChildName = childName;
	}
	// --------------------------------------------------------------------------
}
