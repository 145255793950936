/**
 * サービスエラー情報
 */
export class WprServiceErrorInfo {
	// private 変数  ------------------------------------------------------------
	private		m_Status: string;		// ステータス
	private		m_Message: string;		// メッセージ
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	public get status(): string 	{ return this.m_Status; 	}
	public get message(): string 	{ return this.m_Message; 	}
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	constructor(status: string, message: string) {
		this.m_Status = status;
		this.m_Message = message;
	}
	// --------------------------------------------------------------------------
}