import { Contract } from '../../../models/bss/contract/Contract';
import { Plan } from '../../../models/bss/contract/Plan';

/**
 * 契約モデル
 */
export class ContractPlanModel {
	// public 変数	-----------------------------------------------------------
	public planList: Plan[];			// 
	public contractList: Contract[];	// 
	// ------------------------------------------------------------------------
}
