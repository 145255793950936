import React, { ReactNode } from 'react';
import { WprBaseViewComponent } from '../../../../wpr-framework/view/component/WprBaseViewComponent';
import { ConstrChangeView } from './ConstrChangeView';
import { Typography } from '@material-ui/core';
import { MatButton } from '../../../../wpr-framework/component/controls/material/MatButton';
import { MatTypography } from '../../../../wpr-framework/component/controls/material/MatTypography';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import styles from './ConstrChange.scss';

/**
 * 工事予定日変更用コンポーネント
 */
export class ConstrChange extends WprBaseViewComponent {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: any) { super(new ConstrChangeView(), props); }
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** 工事予定日変更ビュー情報 */
	public get viewInfo(): ConstrChangeView	{ return this.view as ConstrChangeView;	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * スタイルオブジェクト取得
	*/
	public getStylesObject(): any { return styles; }

	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		return (
			<>
			<div className='pageCard'>
				<div className='main' style={{minHeight: '500px'}}>
					<div className='pageTitle'>
						<div className='title_left'>
							<MatTypography name='title' className='title_text' view={this.view}/>
						</div>
					</div>
					<div className='viewGroup'>
						<Typography className='subTitle'>工事予定日</Typography>
						<div className='dateList'>
							<div className='flex'>
								<MatTypography name={'date'} view={this.view} ></MatTypography>
								<span style={{fontWeight: '500'}}>（</span>
								<MatTypography name={'dayOfWeek'} view={this.view} style={{fontWeight: '500'}}></MatTypography>
								<span style={{fontWeight: '500'}}>）　</span>
								<MatTypography name={'time'} view={this.view} ></MatTypography>
								<Typography style={{fontWeight: '500'}}>の間到着</Typography>
							</div>
						</div>
						<ul className='ul_kome attention_fontSize attention'>
							上記の工事予定日の変更申込を行います。<br/>
							変更後は元の予定日に戻すことができません。
						</ul>
						<div className='buttonArea'>
							<div>
								<MatButton name='nextChange' view={this.view} className='orangeButton orangeButton_parts' >
									次へ
									<NavigateNextIcon />
								</MatButton>
							</div>
							<div>
								<MatButton name='cancel' view={this.view} className='backButton' >
									<NavigateBeforeIcon />
									戻る
								</MatButton>

							</div>
						</div>
					</div>
					<div style={{marginBottom: '100px'}}></div>
				</div>
			</div>
			</>
		);
	}
	// --------------------------------------------------------------------------
}
