import { WprKeyValue } from './WprKeyValue';

/**
 * KeyValueリストマップ情報
 */
export class WprKeyValueMap {
	// private 変数  ------------------------------------------------------------
	private m_KeyValueMap: Map<string, WprKeyValue[]>	= new Map<string, WprKeyValue[]>();		// KeyValueリストマップ
	// --------------------------------------------------------------------------

	// public メソッド  ---------------------------------------------------------
	/**
	 * KeyValueリスト取得
	 * @param list KeyValueリスト
	 * @returns KeyValueリスト
	 */
	public getKeyValueList(list: WprKeyValue[]): WprKeyValue[] {
		const key = JSON.stringify(list);
		if (this.m_KeyValueMap.has(key) === false)
			this.m_KeyValueMap.set(key, list);
		return this.m_KeyValueMap.get(key);
	}
	// --------------------------------------------------------------------------
}