import { MGeneral } from '../../entity/MGeneral';
import { MGeneralListModel } from '../../apimodels/MGeneralListModel';

/**
 * 事前確認事項一覧取得リクエスト
 */
export class GetMGeneralListRequest {
	// public 変数	-----------------------------------------------------------
	public mGeneral: MGeneral;				// filter
	public calcTaxFlg: boolean;				// 
	public filterList: MGeneralListModel[];	// 
	// ------------------------------------------------------------------------
}
