import { FormControlLabel, Radio } from '@material-ui/core';
import React, { ReactNode } from 'react';
import { WprFramework } from '../../../WprFramework';
import { IWprInputControlProps } from '../../props/IWprControlProps';
import { WprControlVisibility } from '../../props/IWprControlState';
import { WprBaseInputControlComponent } from '../WprBaseInputControlComponent';

/**
 * ラジオボタンプロパティ情報
 */
interface IMatRadioProps extends IWprInputControlProps {
	value: any;										// 値
	label?: string;									// ラベル
	color?: 'primary' | 'secondary' | 'default';	// 色
	clear?: boolean;								// 値クリアフラグ（選択済みをクリックした場合にクリアする）
}

/**
 * ラジオボタンコントロール
 */
export class MatRadio extends WprBaseInputControlComponent<IMatRadioProps, HTMLInputElement> {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: IMatRadioProps) {
		super(props);
		this.setChangeValueEvent(this.onChangeValue.bind(this));
		let isClear = WprFramework.getInstance().view.isRadioClear;
		if (this.props.clear !== undefined)
			isClear = this.props.clear;
		if (isClear === true)
			this.setClickEvent(this.onClick.bind(this));
	}
	// --------------------------------------------------------------------------

	// イベント  ----------------------------------------------------------------
	/**
	 * 値変更処理
	 * @param event 変更イベント 
	 */
	public onChangeValue(event: React.ChangeEvent<HTMLInputElement>): void {
		if (event != null && event.target.checked === false)
			return;
		if (this.controlInfo != null) {
			let value = this.props.value;
			if (event != null)
				this.addChangeValueEventLog(event.target, value);
			else
				value = this.controlInfo.value;
			this.changeValue(value);
		}
	}

	/**
	 * クリックイベント処理
	 * @param event クリックイベント 
	 */
	public onClick(event: React.MouseEvent<HTMLInputElement>): void {
		const pval = this.props.value;
		const val =this.controlInfo.value;
		if (pval === val) {
			this.addClickEventLog(event.target as HTMLInputElement);
			this.controlInfo.clearValue(null);
			this.onChangeValue(null);
		}
	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * コントロール名称取得
	 * @returns コントロール名称
	 */
	public getName(): string {
		if (this.props.row)
			return this.props.name + this.props.row.index;
		else
			return this.props.name;
	}

	/**
	 * 値取得
	 * @returns 値
	 */
	protected getValue(): any {
		return this.props.value;
	}

	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		if (this.state.visibility === WprControlVisibility.COLLAPSED)
			return <></>;
		const props = this.getProps('wpr-radio');
		const { name, view, className, clear, label, children, ...other} = this.props;
		const state = this.getState(['style', 'disabled', 'readOnly']);
		const checked = (this.props.value === this.state.value);
		const options = this.getOptions();
		const events = this.getEvents();
		if (state.readOnly === true && state.disabled === false && checked === false)
			state.disabled = true;
		let radio = null;
		if (this.state.visibility === WprControlVisibility.VISIBLE) {
			radio = <Radio checked={checked} {...props} {...state} {...other} {...options} {...events} ref={this.ref}/>;
		}
		else {
			state['style'] = {...this.state.style, visibility: 'hidden'};
			radio = <input type='radio' checked={checked} {...props} {...state} {...other} {...options} ref={this.ref}/>;
		}
		if (this.props.label) {
			if (this.state.visibility === WprControlVisibility.VISIBLE)
				radio = <FormControlLabel className='wpr-radio-label' control={radio} label={this.props.label}/>;
			else
				radio = <FormControlLabel className='mat-checkbox-label' control={radio} label={this.props.label} style={{visibility: 'hidden'}}/>;
		}
		return (
			<>
				{radio}
			</>
		);
	}
	// --------------------------------------------------------------------------
	
	// private メソッド  ---------------------------------------------------------
	/**
	 * オプション情報取得
	 * @returns オプション情報
	 */
	private getOptions(): any {
		const rtn: any = {};
		if (this.props.color)
			rtn['color'] = this.props.color;
		else
			rtn['color'] = 'primary';
		return rtn;
	}
	// --------------------------------------------------------------------------
}