import { WprModel } from '../../../../../wpr-framework/view/deco/WprModel';
import { WprDI_ScopeModel } from '../../../../../wpr-framework/view/di/WprDI_ScopeModel';
import { Ipaddress } from '../../../../models/bss/ip/Ipaddress';
import { MypageBaseView } from '../../../base/MypageBaseView';
import { MypageWizardMngr } from '../../../wizard/models/MypageWizardMngr';

/**
 * IPアドレス種別（変更申し込み完了）
 */
export class ModIpopFinishView extends MypageBaseView {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() { super('ModIpopFinishView'); }
	// --------------------------------------------------------------------------
	
	// モデル  ------------------------------------------------------------------
	@WprDI_ScopeModel('MypageWizardMngr')
	private m_MypageWizardMngr: MypageWizardMngr			= null;		// 画面ウィザード管理クラス
	@WprModel('ipaddress')
	private m_ipaddress: Ipaddress	= null;	// nhkモデル
	// --------------------------------------------------------------------------
	private m_CustomerName: string = null;	// 顧客名
	// override メソッド  --------------------------------------------------------
	/**
	 * コントロール定義設定
	 */
	public onRegisterConfig(): void {
		this.addConfig('user', 'ユーザー名', 'ipaddress');
	}

	/**
	 * アクション登録
	 */
	public onRegisterAction(): void {
		this.addAction('toQuestionnaire', this.onToQuestionnaire);
		this.addAction('back', this.onBack);
	}

	public onShowView(): void {
		this.m_MypageWizardMngr.initView(this, (result) => {
			this.registAccessLog();
			this.setStorageItem('b_url', window.location.href);
			const customerInfo = this.m_MypageWizardMngr.getViewInfo().customer;
			if (customerInfo.sexCD === '3' || customerInfo.sexCD === '')
				this.m_CustomerName = customerInfo.corporateName;
			else
				this.m_CustomerName = customerInfo.surName + ' ' + customerInfo.givenName;

			this.m_ipaddress = this.m_MypageWizardMngr.getCache().ipaddress;
			this.m_ipaddress.user = this.m_CustomerName + 'さん';
			this.setViewMode('ipaddress',this.m_ipaddress.ip);
			this.refreshView();
		});
		
		
	}
	// --------------------------------------------------------------------------

	// アクション  ---------------------------------------------------------------
	/**
	 * アンケート
	 */
	public onToQuestionnaire(param: any): void {
		window.open('https://questant.jp/q/B7KS5E4S?id=' + this.getCustomerId());
	}

	/**
	 * 契約内容の確認・変更メニューへ
	 */
	public onBack(param: any): void {
		location.href = this.getOldUrl() + 'Srvlist/Continfo_list/';
	}

	// --------------------------------------------------------------------------
}
