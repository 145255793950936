import { WprBaseObject } from '../../WprBaseObject';
import { WprReflectUtil } from '../../util/WprReflectUtil';
import { WprControRefreshMap } from '../control/map/WprControRefreshMap';
import { WprControlInfo } from '../control/WprControlInfo';

/**
 * DI管理
 */
export abstract class WprBaseDIManage extends WprBaseObject {
	// static 変数  -------------------------------------------------------------
	public static	modelNameList: string[]		= new Array();		// @WprDI_ScopeModel設定用
	public static	gmodelNameList: string[]	= new Array();		// @WprDI_GlobalScopeModel設定用
	public static	listNameList: string[]		= new Array();		// @WprListData設定用
	// --------------------------------------------------------------------------

	// private 変数  ------------------------------------------------------------
	private m_ParentBaseNameList: string[]			= new Array();					// 親ベース名リスト
	private m_ControlDIMap: Map<string, boolean>	= new Map<string, boolean>();	// コントロールDIマップ
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** マウントフラグ */
	public get isMount(): boolean 					{ return true; 					}
	/** コントロールDIマップ */
	public get controlDIMap(): Map<string, boolean> { return this.m_ControlDIMap; 	}
	// --------------------------------------------------------------------------

	// virtual メソッド  --------------------------------------------------------
	/**
	 * ベースクラス名取得
	 * @returns ベースクラス名
	 */
	public getBaseName() {
		return null;
	}

	/**
	 * DI初回設定フラグ取得
	 * @returns DI初回設定フラグ
	 */
	public getIsSetOnece(): boolean {
		return false;
	}

	/**
	 * DI要コントロール情報マップ取得
	 * @returns DI要コントロール情報マップ
	 */
	public getDIControlMap(): WprControRefreshMap {
		return null;
	}

	/**
	 * DI元インスタンス取得
	 * @returns DI元インスタンス
	 */
	public getDIObjectFromInstance(): any {
		return null;
	}

	/**
	 * モデル設定通知
	 * @param name モデル名
	 * @param model モデル
	 */
	public onSetScopeModel(name: string, model: any): void {}

	/**
	 * モデル値チェック通知
	 * @param name モデル名
	 * @param model モデル
	 * @param isGlobal グローバルフラグ
	 * @param prop プロパティ名
	 */
	public onCheckModelValue(name: string, model: any, isGlobal: boolean, prop?: string): void {}

	/**
	 * リストコントロール情報取得
	 * @param listName リスト名
	 * @param controlName コントロール名
	 * @param idx リストインデクス
	 */
	public getListControlInfo(listName: string, controlName: string, idx: number): WprControlInfo {
		return null;
	}

	/**
	 * WprDI_Objectの設定通知
	 * @param name WprDI_Objectメンバ名
	 * @param obj 設定値
	 */
	public onSetDIObject(name: string, obj: any) {}
	// --------------------------------------------------------------------------

	// public メソッド  ---------------------------------------------------------
	/**
	 * インジェクション実行
	 */
	public execInjection(): void {
		this.injectionControl();
		this.injectionObject();
	}

	/**
	 * リストインジェクション実行
	* @param listName リスト名
	 * @param idx インデクス
	 */
	public execListInjection(listName: string, idx: number): void {
		this.injectionListControl(listName, idx);
		this.injectionObject();
	}

	/**
	 * モデル値チェック処理
	 * @param name モデル名
	 * @param model モデル
	 * @param isGlobal グローバルフラグ
	 * @param prop プロパティ名
	 */
	public checkModelValue(name: string, model: any, isGlobal: boolean, prop?: string): void {
		if (this.isMount === true)
			this.onCheckModelValue(name, model, isGlobal, prop);
	}

	/**
	 * 親ベース名リスト取得
	 * @returns 
	 */
	public getBaseNameList(): string[] {
		const list = new Array();
		const bname = this.getBaseName();
		if (bname != null) {
			list.push(bname);
			this.m_ParentBaseNameList.forEach(name => {
				list.push(name);
			});
		}
		return list;
	}
	// --------------------------------------------------------------------------

	// protected メソッド  -------------------------------------------------------
	/**
	 * 親ベース名リスト設定
	 * @param name 親ベース名
	 */
	protected setParentBaseName(name: string) {
		if (name != null && this.m_ParentBaseNameList && this.m_ParentBaseNameList.includes(name) == false)
			this.m_ParentBaseNameList.push(name);
	}

	/**
	 * オブジェクトインジェクト処理
	 * @param instance インジェクト元オブジェクト
	 */
	protected injectionObject(instance?: object): void {
		if (this.isMount === false)
			return;
		if (!instance)
			instance = this.getDIObjectFromInstance();
		if (instance != null) {
			this.FW.model.getDecoNameList('Object', this).forEach((name) => {
				const obj = WprReflectUtil.getProperty(instance, name);
				const prev = WprReflectUtil.getProperty(this, name);
				if (obj != null) {
					this.setDIObject(name, obj);
					WprReflectUtil.setProperty(this, name, obj);
					if (prev !== obj)
						this.onSetDIObject(name, obj);
				}
				else {
					this.setDIObject(name, null);
					WprReflectUtil.setProperty(this, name, null);
//					if (prev != null)
						this.onSetDIObject(name, null);
					// this.addErrorLog(`オブジェクトのDI(WprDI_Object)に失敗しました。[${name}]`);
				}
			});
		}
	}

	/**
	 * WprDI_Objectの設定処理
	 * @param name WprDI_Objectメンバ名
	 * @param obj 設定値
	 */
	protected setDIObject(name: string, obj: any) {
	}

	/**
	 * WprDI_Controlの設定処理
	 * @param name WprDI_Controlメンバ名
	 * @param cinfo コントロール情報
	 */
	protected setDIControl(name: string, cinfo: WprControlInfo) {
	}
	// --------------------------------------------------------------------------

	// private メソッド  --------------------------------------------------------
	/**
	 * コントロール情報インジェクト処理
	 */
	private injectionControl(): void {
		const map = this.getDIControlMap();
		if (map != null) {
			this.FW.model.getDecoNameList('Control', this).forEach((name) => {
				let dname = name;
				if (dname.startsWith('m_') === true)
					dname = dname.substring(2);
				let cinfo: WprControlInfo = null;
				if (map.has(dname) === true)
					cinfo = map.getControlInfo(dname);
				if (cinfo == null)
					cinfo = map.getControlInfo(dname.charAt(0).toLowerCase() + dname.slice(1));
				let status = true;
				if (cinfo != null) {
					WprReflectUtil.setProperty(this, name, cinfo);
					this.setDIControl(name, cinfo);
				}
				else {
					this.addErrorLog(`コントロールのDI(WprDI_Control)に失敗しました。[${name}]`);
					status = false;
				}
				this.m_ControlDIMap.set(name, status);
			});
		}
	}

	/**
	 * リストコントロール情報インジェクト処理
	* @param listName リスト名
	 * @param idx インデクス
	 */
	private injectionListControl(listName: string, idx: number): void {
		this.FW.model.getDecoNameList('Control', this).forEach((name) => {
			let dname = name;
			if (dname.startsWith('m_') === true)
				dname = dname.substring(2);
			let cinfo: WprControlInfo = this.getListControlInfo(listName, dname, idx);
			if (cinfo == null) {
				dname = dname.charAt(0).toLowerCase() + dname.slice(1);
				cinfo = this.getListControlInfo(listName, dname, idx);
			}
			if (cinfo != null) {
				WprReflectUtil.setProperty(this, name, cinfo);
				this.setDIControl(name, cinfo);
			}
			else {
				this.addErrorLog(`コントロールのDI(WprDI_Control)に失敗しました。[${name}]`);
			}
		});
	}
	// --------------------------------------------------------------------------
}
