/**
 * デバックログ情報
 */
 export class WprDebugLogInfo {
	// private 変数  ------------------------------------------------------------
	private m_Date: string			= null;		// 発生時刻
	private m_Level: string			= null;		// レベル
	private m_Message: string		= null;		// メッセージ
	private m_Count: number			= 1;		// 繰り返し数
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** 発生時刻 */
	public get date(): string 		{ return this.m_Date; 		}
	/** レベル */
	public get level(): string		{ return this.m_Level; 		}
	/** メッセージ */
	public get message(): string 	{ return this.m_Message; 	}
	/** 繰り返し数 */
	public get count(): number 		{ return this.m_Count; 		}	public set count(count: number) { this.m_Count = count;		}
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	public constructor(date: string, level: string, msg: string) {
		this.m_Date = date;
		this.m_Level = level;
		this.m_Message = msg;
	}
	// --------------------------------------------------------------------------

	// public メソッド  ----------------------------------------------------------
	/**
	 * ログ文字列取得
	 * @returns ログ文字列
	 */
	public getLog(): string {
		return `${this.m_Date} ${this.m_Level} ${this.m_Count} ${this.m_Message}`;
	}
	// --------------------------------------------------------------------------
}