import { Button } from '@material-ui/core';
import React, { ReactNode } from 'react';
import { WprActionInfo } from '../../../action/WprActionInfo';
import { IWprControlProps } from '../../props/IWprControlProps';
import { WprControlVisibility } from '../../props/IWprControlState';
import { WprBaseControlComponent } from '../WprBaseControlComponent';

/**
 * ボタンコントロールプロパティ情報
 */
interface IMatButtonProps extends IWprControlProps {
	variant?: 'text' | 'outlined' | 'contained';				// 形状
	color?: 'inherit' | 'primary' | 'secondary' | 'default';	// 色
	submit?: boolean;											// サブミットフラグ
}

/**
 * ボタンコントロール
 */
export class MatButton extends WprBaseControlComponent<IMatButtonProps, HTMLButtonElement> {
	// private 変数  ------------------------------------------------------------
	private m_ActionInfo: WprActionInfo		= null;		// アクション情報
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** 編集フラグ */
	public get isEdit(): boolean	{ return false; 	}
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	constructor(props: IMatButtonProps) {
		super(props);
		if (this.props.row)
			this.m_ActionInfo = this.props.view.getActionInfo(this.props.name, this.props.row.listName);
		else
			this.m_ActionInfo = this.props.view.getActionInfo(this.props.name);
		if (this.m_ActionInfo == null) {
			if (this.props.row)
				this.props.view.addErrorLog(`アクションが登録されていません。[${this.props.name}][${this.props.row.listName}]`);
			else
				this.props.view.addErrorLog(`アクションが登録されていません。[${this.props.name}]`);
		}
		else {
			this.setClickEvent(this.onClick.bind(this));
		}
	}
	// --------------------------------------------------------------------------

	// イベント  ----------------------------------------------------------------
	/**
	 * ボタンクリック処理
	 * @param event 変更イベント 
	 */
	public onClick(event: React.MouseEvent<HTMLButtonElement>): void {
		if (this.m_ActionInfo != null) {
			this.addClickEventLog(event.target as HTMLButtonElement);
			if (this.props.row)
				this.m_ActionInfo.execute(true, event, this.props.row);
			else
				this.m_ActionInfo.execute(true, event);
		}
		else {
			this.props.view.addErrorLog(`アクションが登録されていません。[${this.props.name}]`);
		}
	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * 値取得
	 * @returns 値
	 */
	protected getValue(): any {
		return undefined;
	}

	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		if (this.state.visibility === WprControlVisibility.COLLAPSED)
			return <></>;
		const props = this.getProps();
		const {name, view, className, submit, children, ...other} = this.props;
		const state = this.getState(['style', 'disabled']);
		const events = this.getEvents();
		const options = this.getOptions();
		let button = null;
		if (this.state.visibility === WprControlVisibility.VISIBLE) {
			button = <Button {...props} {...state} {...other} {...options} {...events} ref={this.ref}>{this.props.children}</Button>;
		}
		else {
			state['style'] = {...this.state.style, visibility: 'hidden'};
			button = <Button {...props} {...state} {...other} {...options} ref={this.ref}>{this.props.children}</Button>;
		}
		return (
			<>
				{button}
			</>
		);
	}
	// --------------------------------------------------------------------------

	// private メソッド  ---------------------------------------------------------
	/**
	 * オプション情報取得
	 * @returns オプション情報
	 */
	private getOptions(): any {
		const rtn: any = {};
		if (this.props.variant)
			rtn['variant'] = this.props.variant;
		else
			rtn['variant'] = 'contained';
		if (this.props.color)
			rtn['color'] = this.props.color;
		if (this.props.submit)
			rtn['type'] = 'submit';
		return rtn;
	}
	// --------------------------------------------------------------------------
}