/**
 * テレビ契約開始日取得リクエスト
 */
export class GetTvContractDateRequest {
	// public 変数	-----------------------------------------------------------
	public serviceTypeCd: string;	// サービス種類
	public befServiceCd: string;	// 変更前サービスCD
	public befRentalFlg: string;	// 変更前レンタルフラグ
	public aftServiceCd: string;	// 変更後サービスCD
	public aftRentalFlg: string;	// 変更後レンタルフラグ
	public befEquipmentCd: string;	//変更前端末CD
	public aftEquipmentCd: string;	//変更後端末CD
	// ------------------------------------------------------------------------
}
