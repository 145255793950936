	/**
	 * 定数クラス
	 */
	export class Phoneconst{
		//BUILDING_LINE_TYPE_CD
		public static readonly FTTH_PLAN: string = '2';			//FTTH
		//BUILLDING_TYPE_CD
		public static readonly APARTMENT_BUILDING: string = '1';			//集合
		public static readonly DETACHED_HOUSE_RENTAL: string = '2';		//戸建賃貸
		public static readonly DETACHED_HOUSE: string= '3';						//戸建
		//BUILDING_PLAN
		public static readonly FTTH_OPTICAL_WIRING: string = '1';			//FTTH光配線
		public static readonly FTTH_HIGH_OPTICAL_WIRING: string = '2';			//FTTH高速光配線
		public static readonly FTTH_DETACHED_HOUSE_PLAN: string = '3';	//FTTH賃貸戸建用のプラン
	}