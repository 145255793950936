// import { stringNumberComparer } from '@material-ui/data-grid';
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import React, { ReactNode } from 'react';
import { WprControlVisibility } from '../../props/IWprControlState';
import { IMatTextFieldProps, MatBaseTextField } from './MatBaseTextField';
import jaLocale from 'date-fns/locale/ja';
import { format } from 'date-fns';
import { IconButton } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';

/**
 * 日付プロパティ情報
 */
export interface IMatDatePickerProps extends IMatTextFieldProps {
	format?: string				// 日付ファーマっと
	use?: 'mobile' | 'all';		// 使用範囲
}

/**
 * 日付コントロール
 */
export class MatDatePicker extends MatBaseTextField<IMatDatePickerProps> {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: IMatDatePickerProps) {
		super(props);
		this.setClickEvent(this.onClear.bind(this));
	}
	// --------------------------------------------------------------------------

	// イベント  ----------------------------------------------------------------
	/**
	 * 日付変更イベント
	 * @param newDate 新しい日付
	 */
	private onDateChange(newDate: Date | null): void {
		let value = null;
		if (newDate) {
			const date = newDate as Date;
			const y = date.getFullYear();
			const m = ('00' + (date.getMonth()+1)).slice(-2);
			const d = ('00' + date.getDate()).slice(-2);
			value = y + '-' + m + '-' + d;
		}
		this.changeInputValue(this.ref.current, value);
	}

	/**
	 * クリアイベント
	 * @param event イベント情報
	 */
	private onClear(event: React.MouseEvent<HTMLButtonElement>): void {
		if (this.useDatePicker() === true) {
			event.stopPropagation();
			this.changeInputValue(this.ref.current, null);
		}
	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		if (this.state.visibility === WprControlVisibility.COLLAPSED)
			return <></>;
		if (this.useDatePicker() === true) {
			const props = this.getDateProps();
			const {name, view, children, className, placeholder, autoComplete, type, tooltip, align, ...other} = this.props;
			const state = this.getState(['style', 'disabled', 'readOnly']);
			const events = this.getDateEvents();
			const pholder = this.getPlaceholder();
			const options = this.getDateOptions();
			const inputProps = this.getDateInputProps();
			const error = this.getError();
			let datePicker = null;
			if (this.state.visibility !== WprControlVisibility.VISIBLE)
				state['style'] = {...this.state.style, visibility: 'hidden'};
			datePicker =
				<MuiPickersUtilsProvider utils={JaLocalizedUtils} locale={jaLocale}>
					<DatePicker {...props} {...state} {...other} {...options} {...pholder} {...error} {...events} {...inputProps} inputRef={this.ref}/>
				</MuiPickersUtilsProvider>;
			return this.getControlNode(datePicker);
		}
		return super.onRender();
	}

	/**
	 * propsのtype取得
	 * @returns propsのtype
	 */
	protected getPropsType(): string {
		return 'date';
	}
	// --------------------------------------------------------------------------

	// private メソッド  --------------------------------------------------------
	/**
	 * DatePicker使用フラグ取得
	 * @returns DatePicker使用フラグ
	 */
	private useDatePicker(): boolean {
		if (!this.props.use || this.props.use === 'mobile')
			return this.props.view.isMobile();
		return true;
	}

	/**
	 * オプション情報取得
	 * @returns オプション情報
	 */
	private getDateOptions(): any {
		const rtn: any = {};
		if (this.state.hissu)
			rtn['required'] = true;
		if (this.props.variant)
			rtn['inputVariant'] = this.props.variant;
		else
			rtn['inputVariant'] = 'outlined';
		rtn['size'] = 'small';
		rtn['margin'] = 'dense';
		if (this.state.min)
			rtn['minDate'] = this.state.min;
		if (this.state.max)
			rtn['maxDate'] = this.state.max;
		return rtn;
	}

	/**
	 * プロパティ情報取得
	 * @param cls オリジナルクラス
	 * @returns プロパティ情報
	 */
	private getDateProps(cls?: string, setClassName: boolean = true): any {
		const props = this.getProps(cls, setClassName);
		if (this.state.value)
			props['value'] = this.getEditValue(this.props.converter);
		else
			props['value'] = null;
		if (this.props.format)
			props['format'] = this.props.format;
		else
			props['format'] = 'yyyy/MM/dd';
		props['minDateMessage'] = '';
		props['maxDateMessage'] = '';
		return props;
	}

	/**
	 * イベント情報取得
	 * @returns イベント情報
	 */
	private getDateEvents(): any {
		const rtn: any = {};
		rtn['onChange'] = this.onDateChange.bind(this);
		return rtn;
	}

	/**
	 * 入力プロパティ取得
	 * @returns 入力プロパティ
	 */
	protected getDateInputProps(): any {
		const rtn: any = {};
		if (this.state.value) {
			const obj: any = {};
			const events = this.getEvents();
			obj['endAdornment'] = <IconButton {...events}><ClearIcon /></IconButton>;
			rtn['InputProps'] = obj;
		}
		return rtn;
	}
	// --------------------------------------------------------------------------
}

/**
 * ヘッダ部分のテキスト用
 */
class JaLocalizedUtils extends DateFnsUtils {
	getDatePickerHeaderText(date: Date) {
	  return format(date, 'M月d日(E)', { locale: this.locale });
	}
}
 