import { WprModel } from '../../../../wpr-framework/view/deco/WprModel';
import { WprDI_ScopeModel } from '../../../../wpr-framework/view/di/WprDI_ScopeModel';
import { CryptRijndaelUtil } from '../../../common/util/CryptRijndaelUtil';
import { AccesspassModel } from '../../../service/models/apimodels/AccesspassModel';
import { MypageBaseView } from '../../base/MypageBaseView';
import { MypageWizardMngr } from '../../wizard/models/MypageWizardMngr';
import { PasswordModel } from './models/PasswordModel';

/**
 * アクセスアカウントパスワード印刷
 */
export class AccesspassPrintView extends MypageBaseView {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() { super('AccesspassPrintView'); }
	// --------------------------------------------------------------------------

	@WprDI_ScopeModel('MypageWizardMngr')
	private m_MypageWizardMngr: MypageWizardMngr = null;		// 画面ウィザード管理クラス
	@WprModel('AccesspassModel')
	private m_AccesspassModel: AccesspassModel = null;	// コース
	@WprModel('PasswordModel')
	private m_PasswordModel: PasswordModel = new PasswordModel;	// コース
	// override メソッド  --------------------------------------------------------
	/**
	 * コントロール定義設定
	 */
	public onRegisterConfig(): void {
		this.addConfig('course', 'コース', 'AccesspassModel');
		this.addConfig('radiusAccount', 'アクセスアカウント', 'AccesspassModel');
		this.addConfig('newPassword', '新パスワード', 'PasswordModel');
		this.addConfig('date', '変更日', 'AccesspassModel', { converter: '日付（yyyy年MM月dd日）' });
	}

	/**
	 * アクション登録
	 */

	public onRegisterAction(): void {
		this.addAction('print', this.onPrint);
		this.addAction('close', this.onClose);
	}

	/**
	 * ビュー表示通知
	 */

	public onShowView(): void {
		this.m_MypageWizardMngr.initView(this, (result) => {
			this.registAccessLog();
			this.setStorageItem('b_url', window.location.href);
			this.m_AccesspassModel = this.m_MypageWizardMngr.getCache().m_AccesspassInfo
			this.m_PasswordModel.newPassword = CryptRijndaelUtil.decodePW(this.m_AccesspassModel.crptkey, this.m_AccesspassModel.newPassword);

			this.refreshView();
		});
	}

	public onPrint(param: any): void {
		window.print();
	}

	/**
	 * 閉じる
	 */
	public onClose(param: any): void {
		window.close();
	}
	// --------------------------------------------------------------------------
}
