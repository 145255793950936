import { MvnoEntryInfo } from '../../apimodels/MvnoEntryInfo';

/**
 * 料金シミュレーション取得リクエスト
 */
export class GetPaymentSimulationRequest {
	// public 変数	-----------------------------------------------------------
	public entryInfo: MvnoEntryInfo;	// }
	public isIncludeContract: boolean;
	// ------------------------------------------------------------------------
}
