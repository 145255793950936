import { WprValueMap } from '../../../../wpr-framework/model/WprValueMap';
import { WprValueMapSetter } from '../../../../wpr-framework/model/WprValueMapSetter';

/**
 * 年
 */
export class YearValueMap {
	// static メソッド  --------------------------------------------------------
	public static addValueMap(vmSetter: WprValueMapSetter): void {
		let vm = new WprValueMap();
		const start: number = 1900;
		const last: number = new Date().getFullYear();
		for (let i: number=start; i<last; i++) {
			vm.addValue(i.toString(), i.toString());
		}
		vmSetter.addValueMap('year', vm);
	}
	// --------------------------------------------------------------------------
}
