import { WprBaseViewMultiModeInfo } from '../../../../../../wpr-framework/mode/WprBaseViewMultiMode';
import { WprMultiMode } from '../../../../../../wpr-framework/mode/WprMultiMode';
import { AddNetSettingView } from '../AddNetSettingView';
import { WprDI_Object } from '../../../../../../wpr-framework/view/di/WprDI_Object';
import { Customer } from '../../../../../models/bss/customer/Customer';

/**
 * 必須モード
 */
export class HissuMode extends WprBaseViewMultiModeInfo<AddNetSettingView> {
	// DI情報  ------------------------------------------------------------------
	@WprDI_Object
	private m_Customer: Customer	= null;	// 顧客情報
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	public constructor() {
		super('HissuMode');
	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * モードリスト取得（この画面モードで使用する全てのモードを定義する）
	 * @param list モードリスト
	 */
	public useModeList(list: string[]): void {
		list.push('入力必須');
		list.push('入力不要');
		list.push('階数必須');
		list.push('階数不要');
	}

	/**
	 * フィールド定義を登録処理
	 */
	public onRegisterControl(): void {
		this.addControl('selectTime1', { hissu: [ { mode: '入力必須', value: true }, { mode: '入力不要', value: false } ] });
		this.addControl('selectTime2', { hissu: [ { mode: '入力必須', value: true }, { mode: '入力不要', value: false } ] });
		this.addControl('selectTime3', { hissu: [ { mode: '入力必須', value: true }, { mode: '入力不要', value: false } ] });
		this.addControl('location', { hissu: [ { mode: '入力必須', value: true }, { mode: '入力不要', value: false } ] });
		this.addControl('floor', { hissu: [ { mode: '階数必須', value: true }, { mode: '階数不要', value: false } ] });
	}

	/**
	 * 画面の状態をチェックしてモードを変更する
	 * @param curMode 現在のモード
	 * @param newMode 新しいモード
	 */
	public onCheckMultiMode(curMode: WprMultiMode, newMode: WprMultiMode): void {
		if (this.getViewMode('端末の種類') === '表示') {
			newMode.addMode('入力必須');
			if (this.m_Customer.building[0].buildingTypeCD === '3')
				newMode.addMode('階数必須');
			else
				newMode.addMode('階数不要');
		}
		else
			newMode.addMode('入力不要');
	}
	// --------------------------------------------------------------------------
}
