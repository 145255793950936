import { WprModel } from "../../../../wpr-framework/view/deco/WprModel";
import { WprDI_ScopeModel } from "../../../../wpr-framework/view/di/WprDI_ScopeModel";
import { MvnoEntryInfo } from "../../../service/models/apimodels/MvnoEntryInfo";
import { NameChangeAppModel } from "../../../service/models/apimodels/NameChangeAppModel";
import { HMvnoTmpSaved } from "../../../service/models/entity/HMvnoTmpSaved";
import { MypageBaseView } from "../../base/MypageBaseView";
import { MypageWizardMngr } from "../../wizard/models/MypageWizardMngr";

/**
 * 名義変更受付エラー
 */
export class NameChangeErrorView extends MypageBaseView {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() { super('NameChangeErrorView'); }
	// --------------------------------------------------------------------------
	// モデル  ------------------------------------------------------------------
	// @WprModel('errorMessage')
	@WprDI_ScopeModel('errorMessage')
	private m_errorMessage: string = null;
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * コントロール定義設定
	 */
	public onRegisterConfig(): void {
	}

	/**
	 * アクション登録
	 */
	public onRegisterAction(): void {
		this.addAction('toMypageTop', this.onToMypageTop);
	}

	/**
	 * ビュー表示通知
	 */
	public onShowView(): void {
		this.registAccessLog();
			this.setStorageItem('b_url', window.location.href)
		let changeStatus = this.getCookie('changeStatus');
		this.m_errorMessage = 'エラーが起きました。\n';
		if (changeStatus !== null) {
			if (changeStatus === '1') {
				this.m_errorMessage = '名義変更のお手続き中です。<br />'
					+ '完了まで今しばらくお待ちください。<br />'
					+ '改めてお申し込みが必要名場合には、フリーダイヤルまでお問合せください。\n';
			} else if (changeStatus === '2') {
				this.m_errorMessage = 'URLの有効期限が切れています。\n';
			}
		}
		this.setViewData('errorMessage', this.m_errorMessage);

		this.refreshView();
	}
	// --------------------------------------------------------------------------

	// アクション  ---------------------------------------------------------------
	/**
	 * 次へ
	 */
	public onToMypageTop(param: any): void {
		// location.href = "https://stage.mypage.itscom.net/Top/My_top/";
		location.href = this.getOldUrl() + "Top/My_top/";
	}
	// --------------------------------------------------------------------------
}