import { WprValueMap } from '../../../../wpr-framework/model/WprValueMap';
import { WprValueMapSetter } from '../../../../wpr-framework/model/WprValueMapSetter';
import { WprBaseServiceValueMap } from '../../../../wpr-framework/model/WprBaseServiceValueMap';

/**
 * 利用明細対象月vm
 */
export class UsageDetailsTargerMonthValueMap extends WprBaseServiceValueMap {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() {
		super('usageDetailsTargerMonth');
	}
	// --------------------------------------------------------------------------

	// override メソッド  -------------------------------------------------------
	/**
	 * ValueMap登録処理
	 * @param vmSetter ValueMap設定
	 */
	public registerValueMap(vmSetter: WprValueMapSetter): void {
		vmSetter.addValueMap(this.valueMapName, new WprValueMap());
	}
	// --------------------------------------------------------------------------
}
