import { BaseService } from './base/BaseService';
import { WprBaseViewCore } from '../../wpr-framework/view/WprBaseViewCore';
import { GetCertificationRequest } from './models/dto/auth/GetCertificationRequest';
import { GetCertificationResponse } from './models/dto/auth/GetCertificationResponse';
import { DeleteSessionInfoRequest } from './models/dto/auth/DeleteSessionInfoRequest';
import { DeleteSessionInfoResponse } from './models/dto/auth/DeleteSessionInfoResponse';
import { GetLineInfoResponse } from './models/dto/auth/GetLineInfoResponse';
import { GetLineInfoRequest } from './models/dto/auth/GetLineInfoRequest';

/**
 * MyPageRenewalサービス
 */
export class AuthService extends BaseService {
	// サービス メソッド  --------------------------------------------------------
	/**
	 * 認証情報取得
	 * @param viewCore ビュー情報
	 * @param request リクエスト情報
	 * @param resMethod 結果処理メソッド
	 */
	public getCertification(viewCore: WprBaseViewCore, request: GetCertificationRequest, resMethod: (result: GetCertificationResponse) => void): void {
		return this.doPost(viewCore, '/auth/getCertification', request, resMethod);
	}

	/**
	 * セッション情報の物理削除処理
	 * @param viewCore ビュー情報
	 * @param request リクエスト情報
	 * @param resMethod 結果処理メソッド
	 */
	public deleteSessionInfo(viewCore: WprBaseViewCore, request: DeleteSessionInfoRequest, resMethod: (result: DeleteSessionInfoResponse) => void): void {
		return this.doPost(viewCore, '/auth/deleteSessionInfo', request, resMethod);
	}

	/**
	 * LINE情報取得
	 * @param viewCore ビュー情報
	 * @param request リクエスト情報
	 * @param resMethod 結果処理メソッド
	 */
	public getLineInfo(viewCore: WprBaseViewCore, request: GetLineInfoRequest, resMethod: (result: GetLineInfoResponse) => void): void {
		return this.doPost(viewCore, '/auth/getLineInfo', request, resMethod);
	}
	// --------------------------------------------------------------------------
}
