import { WprBaseDIManage } from './WprBaseDIManage';
import { WprFramework } from '../../WprFramework';

/**
 * コントロールDIデコレータ
 * @param target ビュー情報
 * @param props プロパティ名
 */
export function WprDI_Control(target: any, props: string) {
	const diManage = target as WprBaseDIManage;
	if (diManage) {
		WprFramework.getInstance().model.addDecoListInfo('Control', diManage, props);
		const baseName = diManage.getBaseName();
		if (baseName)
			WprFramework.getInstance().model.addBaseDIInfo(baseName, diManage);
	}
}
