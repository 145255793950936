import { WprModel } from '../../../../wpr-framework/view/deco/WprModel';
import { WprDI_ScopeModel } from '../../../../wpr-framework/view/di/WprDI_ScopeModel';
import { ObjectUtil } from '../../../common/util/ObjectUtil';
import { Contract } from '../../../models/bss/contract/Contract';
import { Equipment } from '../../../models/bss/contract/Equipment';
import { ContractPlanModel } from '../../../service/models/apimodels/ContractPlanModel';
import { MypageBaseView } from '../../base/MypageBaseView';
import { ProgressBarData } from '../../common/progress_bar/ProgressBarView';
import { MypageWizardMngr } from '../../wizard/models/MypageWizardMngr';
import { MCommon } from '../../../service/models/entity/primary/MCommon';

/**
 * かっとびモバイル［解約申込み］内容確認
 */
export class MobileConfirmView extends MypageBaseView {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() { super('MobileConfirmView'); }
	// --------------------------------------------------------------------------

	// モデル  ------------------------------------------------------------------
	@WprDI_ScopeModel('MypageWizardMngr')
	private m_MypageWizardMngr: MypageWizardMngr = null;		// 画面ウィザード管理クラス
	private m_ContractModel: ContractPlanModel				= null;	// 契約情報
	private m_Contract: Contract				= null;	// 契約情報
	@WprModel('EquipmentModel')
	private m_EquipmentModel: Equipment	= null;	// Equipmentモデル
	@WprModel('MCommon')
	private m_MCommon: MCommon	= null;	// MCommonモデル
	@WprModel('Amount')
	private m_Amount: string	= null;	// 金額
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * コントロール定義設定
	 */
	public onRegisterConfig(): void {
		this.addConfig('equipmentID', 'シリアル番号', 'EquipmentModel');
		this.addConfig('amount', '月額利用料', 'Amount', { bindModel: true});
	}

	/**
	 * アクション登録
	 */
	public onRegisterAction(): void {
		this.addAction('next', this.onNext);
		this.addAction('back', this.onBack);
	}

	/**
	 * ビュー表示通知
	 */
	public onShowView(): void {
		this.m_MypageWizardMngr.initView(this, (result) => {
			this.registAccessLog();
			this.setStorageItem('b_url', window.location.href)
			const sendData: ProgressBarData = new ProgressBarData();
			sendData.progressBarList = this.m_MypageWizardMngr.getProgressModelList(this);
			this.sendChildData('fP_ev_progressBarIntital', sendData);
			let contractId: string = this.m_MypageWizardMngr.getParams().contractId;
			// MCommonから月額利用料取得
			const commonList = this.m_MypageWizardMngr.getMCommonList(this, 'srvnet_info', 'value', 'charge', null);
			const amount = this.m_MypageWizardMngr.getTaxFee(this, commonList[0].commonValue);
			this.m_Amount = amount;
			// 契約情報からシリアル番号取得
			if (ObjectUtil.isNotNullOrEmptyStr(contractId)) {
				this.m_Contract = this.m_MypageWizardMngr.getCache().m_Contract;
				this.m_Contract = this.m_MypageWizardMngr.getContract(this, contractId);
				if (ObjectUtil.isNotNullOrUndefined(this.m_Contract) && this.m_Contract.serviceTypeCD === '2') {
					if (ObjectUtil.isNotNullOrEmptyArray(this.m_Contract.equipment)) {
						this.m_EquipmentModel = this.m_MypageWizardMngr.getCache().m_EquipmentModel;
						this.m_EquipmentModel = this.m_Contract.equipment[0];
					}
				}
			}
			this.refreshView();
		});
	}
	// --------------------------------------------------------------------------

	// アクション  ---------------------------------------------------------------
	/**
	 * 解約        <NavigateNextIcon />
	 */
	public onNext(param: any): void {
		this.refreshModel();
		this.m_MypageWizardMngr.goNext(this);
	}

	/**
	 * <NavigateBeforeIcon />         戻る
	 */
	public onBack(param: any): void {
		this.m_MypageWizardMngr.goBack(this);
	}
	// --------------------------------------------------------------------------
}
