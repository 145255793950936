import { WprBaseDIManage } from '../view/di/WprBaseDIManage';
import { WprScopeModelDIInfo } from './WprScopeModelDIInfo';
import { WprReflectUtil } from '../util/WprReflectUtil';
import { WprWaitModelDIInfo } from './WprWaitModelDIInfo';

/**
 * スコープモデルDI情報リスト
 */
export class WprScopeModelDIList {
	// private 変数  ------------------------------------------------------------
	private m_Name: string						= null;							// スコープ名
	private m_Model: any						= null;							// モデル
	private m_ModelValue: string				= null;							// モデル値
	private m_DIInfoList: WprScopeModelDIInfo[]	= new Array();					// スコープモデルDI情報リスト
	private m_PropValueMap: Map<string, string>	= new Map<string, string>();	// プロパティ値マップ
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	public constructor(name: string) {
		this.m_Name = name;
	}
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** スコープ名 */
	public get name(): string	{ return this.m_Name;	}
	// --------------------------------------------------------------------------

	// public メソッド  ---------------------------------------------------------
	/**
	 * スコープモデルDI情報追加
	 * @param diInfo スコープモデルDI情報
	 */
	public addDIInfo(diInfo: WprScopeModelDIInfo) {
		this.m_DIInfoList.push(diInfo);
		if (this.m_Model != null)
			diInfo.setModel(this.m_Model);
	}

	/**
	 * モデル設定処理
	 * @param model モデル
	 */
	public setModel(model: any): void {
		this.m_Model = model;
		this.setModelValue();
		for (const dinfo of this.m_DIInfoList)
			dinfo.setModel(model);
	}

	/**
	 * モデル値チェック処理
	 */
	public checkModelValue(): void {
		const val = this.getModelValue();
		if (this.m_ModelValue !== val) {
			const waitList = new Array();
			for (const dinfo of this.m_DIInfoList)
				waitList.push(new WprWaitModelDIInfo(dinfo, undefined, this.m_Model));
			this.m_PropValueMap.forEach((value, key, map) => {
				if (value !== this.getModelPropertyValue(key)) {
					const pval = this.getModelProperty(key);
					for (const dinfo of this.m_DIInfoList)
						waitList.push(new WprWaitModelDIInfo(dinfo, key, pval));
				}
			});
			this.setModelValue();
			for (const winfo of waitList) {
				// dinfo.checkModelValue(this.m_Model);
				winfo.diInfo.checkModelValue(winfo.value, winfo.propName);
			}
		}
	}

	/**
	 * DI情報削除処理
	 * @param obj 対象オブジェクト
	 */
	public deleteDIInfo(obj: WprBaseDIManage): void {
		const list = new Array();
		for (const dinfo of this.m_DIInfoList) {
			if (dinfo.object !== obj && dinfo.object.isMount === true)
				list.push(dinfo);
		}
		this.m_DIInfoList = list;
	}

	/**
	 * モデル値更新通知
	 */
	public updateModelValue(): void {
		for (const dinfo of this.m_DIInfoList)
			dinfo.checkModelValue(this.m_Model);
		this.m_PropValueMap.forEach((value, key, map) => {
			const pval = this.getModelProperty(key);
			for (const dinfo of this.m_DIInfoList)
				dinfo.checkModelValue(pval, key);
		});
	}

	/**
	 * スコープモデル更新時にチェックするプロパティをチェックする
	 * @param obj 対象オブジェクト
	 * @param prop プロパティ名
	 * @param isGlobal グローバルフラグ
	 */
	public setUpdateProperty(obj: WprBaseDIManage, prop: string, isGlobal: boolean): void {
		if (this.m_PropValueMap.has(prop) === false)
			this.m_PropValueMap.set(prop, '');
		this.addDIInfo(new WprScopeModelDIInfo(obj, this.m_Name, null, isGlobal));
	}
	// --------------------------------------------------------------------------

	// private メソッド  --------------------------------------------------------
	/**
	 * モデル値設定処理
	 */
	private setModelValue(): void {
		const pmap = new Map<string, string>();
		if (this.m_Model != null) {
			this.m_ModelValue = this.getModelValue();
			this.m_PropValueMap.forEach((value, key, map) => {
				pmap.set(key, this.getModelPropertyValue(key));
			});
		}
		else {
			this.m_ModelValue = '';
			this.m_PropValueMap.forEach((value, key, map) => {
				pmap.set(key, '');
			});
		}
		this.m_PropValueMap = pmap;
	}

	/**
	 * モデル値取得
	 * @returns モデル値
	 */
	private getModelValue(): string {
		if (this.m_Model)
			return JSON.stringify(this.m_Model);
		return '';
	}

	/**
	 * モデルプロパティ値取得
	 * @returns モデルプロパティ値
	 */
	public getModelPropertyValue(prop: string): string {
		if (this.m_Model != null) {
			const data = WprReflectUtil.getProperty(this.m_Model, prop);
			if (data)
				return JSON.stringify(data);
		}
		return '';
	}

	/**
	 * モデルプロパティ取得
	 * @returns モデルプロパティ
	 */
	public getModelProperty(prop: string): any {
		return WprReflectUtil.getProperty(this.m_Model, prop);
	}
	// --------------------------------------------------------------------------
}
