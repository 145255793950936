import React, { ReactNode } from 'react';
import { WprBaseViewComponent } from '../../../../../wpr-framework/view/component/WprBaseViewComponent';
import { ModOthernetSelectView } from './ModOthernetSelectView';
import { Typography, Grid, Divider, TableCell, Table, TableBody, TableHead, TableRow } from '@material-ui/core';
import { MatButton } from '../../../../../wpr-framework/component/controls/material/MatButton';
import { MatCheckBox } from '../../../../../wpr-framework/component/controls/material/MatCheckBox';
import { ProgressBar } from '../../../common/progress_bar/ProgressBar';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { MatTableRow } from '../../../../../wpr-framework/component/controls/material/MatTableRow';
import { MatTypography } from '../../../../../wpr-framework/component/controls/material/MatTypography';
import { WprRowInfo } from '../../../../../wpr-framework/component/props/WprRowInfo';
import { MatTableContainer } from '../../../../../wpr-framework/component/controls/material/MatTableContainer';
import { WprContainer } from '../../../../../wpr-framework/component/controls/WprContainer';
import netIcon from '/shared/image/common/logo_iTSCOMNET.png';
import orangeIcon from '/shared/image/common/next_icon_orange.png';

/**
 * 変更その他サービス設定用コンポーネント
 */
export class ModOthernetSelect extends WprBaseViewComponent {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: any) { super(new ModOthernetSelectView(), props); }
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** 変更その他サービス設定ビュー情報 */
	public get viewInfo(): ModOthernetSelectView	{ return this.view as ModOthernetSelectView;	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	
	/**
	 * リストコントロール定義登録
	*/
	protected onRegisterListRender(): void {
		this.addListRender('OtherCourseListModel', 'PC', this.onOtherCourseListPcRender);
		this.addListRender('OtherCourseListModel', 'SP', this.onOtherCourseListSpRender);
		this.addListRender('BeforeInfoList', null, this.onBeforeInfoListRender);
	}
	
	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		return (
			<>
				<div className='pageCard'>
					<div className='pageHeader stepFlow'>
						<ProgressBar name='progressBar' view={this.view} />
					</div>
					<div className='sub-logo'>
						<img src={netIcon}/>
					</div>
					<div className='main'>
						<div className='pageTitle'>
							<div className='title_left'>
								<MatTypography name='title' className='title_text' view={this.view}/>
								<Typography className='title_sentence'>ご希望のコースを選択してください。</Typography>
							</div>
							<div className='itscomNet_img whitespace'>
								<a href='https://www.itscom.co.jp/service/internet/course/hikari/' target='_blank' className='courseUrl'>
									<b>その他のインターネットのコース変更について　</b>
									<img src={orangeIcon} alt='' className='orange_circleArrow'/>
								</a>
							</div>
							<div className='itscomNet_img'>
								<a href='http://www.itscom.net/service/internet/index.html' target='_blank' className='courseUrl'>
									<b>コース一覧　</b>
									<img src={orangeIcon} alt='' className='orange_circleArrow'/>
								</a>
							</div>
							<div className='title_maru' style={{marginTop:'30px'}}>現在のご契約</div>
							<div className='constractTable'>
								<Grid container className='feeRow'>
									<Grid item xs={12} md={5} className='option'>
										<MatTypography name='contractCourse' className='courseName' view={this.view}/>
										{/* <Typography className='courseName'>（月額<MatTypography name='contractAmount' className='courseName' view={this.view}/>円）</Typography> */}
									</Grid>
									<Grid item xs={12} md={7} className='optstatus'>
										<MatTypography name='contractStatus' className='itemUnit_bold' view={this.view}/>
									</Grid>
								</Grid>	
							</div>
							<Grid container>
								<Grid item xs={12}>
									<MatTableContainer listName={'BeforeInfoList'} view={this.view}>
										<Table className ='optionTable'>
											<colgroup>
												<col/>
												<col/>
											</colgroup>
											<TableBody className='optionBody'>
												{this.renderList('BeforeInfoList')}
											</TableBody>
										</Table>
									</MatTableContainer>
								</Grid>	
							</Grid>
							<div className='title_maru'>変更申込み内容</div>
							<Grid container>
								<Grid item xs={12}>
									<MatTableContainer listName={'OtherCourseListModel'} view={this.view} noDataUseHeader noDataMessage=' '>
										{/* PC用 */}
										<WprContainer mode='その他コース.PC' view={this.view}>
											<Table className='optionSelsctPcTable'> 
												<colgroup>
													<col style={{width: '10%'}}/>
													<col style={{width: '30%'}}/>
													<col style={{width: '20%'}}/>
													<col style={{width: '40%'}}/>
												</colgroup>
												<TableHead className='hedderrow'>
													<TableRow className='toptable'>
														<TableCell align='center' className='border_right font_bold'>選択</TableCell>
														<TableCell align='center' className='border_right font_bold'>コース名</TableCell>
														<TableCell align='center' className='border_right font_bold'>月額（税込）</TableCell>
														<TableCell align='center' className='font_bold'>回線種別</TableCell>
													</TableRow>
												</TableHead>
												<TableBody>
													{this.renderList('OtherCourseListModel', 'PC')}
												</TableBody>
											</Table>
										</WprContainer>
										{/* Sp用 */}
										<WprContainer mode='その他コース.SP' view={this.view}>
											<Table className='optionSelsctSpTable'>
												<colgroup>
													<col style={{width: '20%'}}/>
													<col style={{width: '80%'}}/>
												</colgroup>
												<TableHead className='hedderrow'>
													<TableRow className='toptable'>
														<TableCell rowSpan={4} align='center' className='border_right font_bold'>選択</TableCell>
														<TableCell rowSpan={1} align='center' className='font_bold'>コース名</TableCell>
													</TableRow>
													<TableRow className='toptable'>
														<TableCell rowSpan={1} align='center' className='font_bold'>月額（税込）</TableCell>
													</TableRow>
													<TableRow className='toptable'>
														<TableCell rowSpan={1} align='center' className='font_bold'>回線種別</TableCell>
													</TableRow>
												</TableHead>
												<TableBody>
													{this.renderList('OtherCourseListModel', 'SP')}
												</TableBody>
											</Table>
										</WprContainer>
									</MatTableContainer>
								</Grid>
							</Grid>
							<div className='attention_fontSize'>
								<ul className='ul_kome'>
									<li>
										お客さまのご住所および建物の設備状況によっては、ご利用になれない場合ございます。
									</li>
									<li>
										auスマートバリューをご利用中の場合、かっとびメガ１６０、かっとびワイド、かっとびMANSION LAN以外は割引対象外となりますので、コース変更の際は、ご注意ください。<br/>
										契約期間中にお得パック対象サービスの一部または全部をコース変更または解約する場合、契約違約金がかかります（更新月を除く）。
									</li>
									<li>
										かっとびADSLフレッツサービスは、新規登録の受付を終了いたしました。
									</li>
									<li>
										お得パックご利用中のお客さまにおかれましては、お得パックの内容を組みかえて、契約変更させていただきます。<br/>
										その際、お得パックの利用期間は、組みかえさせていただきました翌月からの起算に変更となりますので、ご了承ください。 なお、利用機器に変更が必要な場合や、別途お得パックの適用が外れる場合など、確認事項が発生いたしました際には、別途ご連絡いたします。
									</li>
								</ul>
							</div>
						</div>
					</div>
					<Divider className='select1_underSpace'/>
					<div className='pageFooter footer-space'>
						<div>
							<MatButton name='next' view={this.view} className='orangeButton orangeButton_parts' >
								次へ
								<NavigateNextIcon />
							</MatButton>
						</div>
						<div>
							<MatButton name='back' view={this.view} className='backButton' >
								<NavigateBeforeIcon />
								戻る
							</MatButton>
						</div>
					</div>
				</div>
			</>
		);
	}

	// --------------------------------------------------------------------------
	
	// リスト描画  ---------------------------------------------------------------
	/**
	 * リスト名=OtherCourseListModel
	 * PC用
	 */
	public onOtherCourseListPcRender(rowInfo: WprRowInfo): any{
		return(
			<MatTableRow view={this.view} row={rowInfo}>
				<TableCell align='center' className='border_right service'>
					<MatCheckBox name='check' color='primary' view={this.view} row={rowInfo}/>
				</TableCell>
				<TableCell className='border_right service' align='left'>
					<MatTypography name='cDispnamep' className='courseSize' view={this.view} row={rowInfo}/>
				</TableCell>
				<TableCell className='border_right service' align='left'>
					<MatTypography name='payAmount' className='courseFee' view={this.view} row={rowInfo}/>
					<MatTypography name='payUnit' className='courseUnit' view={this.view} row={rowInfo}/>
				</TableCell>
				<TableCell className='service' align='left'>
					<MatTypography name='nDispnamep' className='courseSize' view={this.view} row={rowInfo}/>
				</TableCell>
			</MatTableRow>
		);
	}

	/**
	 * リスト名=OptListModel
	 * SP用
	 */
	public onOtherCourseListSpRender(rowInfo: WprRowInfo): any {
		return (
			<>
				<TableRow>
					<TableCell className='border_right' align='center' rowSpan ={3}> 
						<MatCheckBox name='check' view={this.view} row={rowInfo}/>
					</TableCell>
					<TableCell rowSpan ={1}>
						<MatTypography name='cDispnamep' className='courseSize' view={this.view} row={rowInfo}/>
					</TableCell>
				</TableRow>
				<TableRow className='optionRow'>
					<TableCell rowSpan ={1}>
						<MatTypography name='payAmount' className='courseFee' view={this.view} row={rowInfo}/>
						<MatTypography name='payUnit' className='courseUnit' view={this.view} row={rowInfo}/>
						{/* <span className='courseUnit'>円</span> */}
					</TableCell>
				</TableRow>
				<TableRow>
					<TableCell rowSpan ={1}>
						<MatTypography name='nDispnamep' className='courseSize' view={this.view} row={rowInfo}/>
					</TableCell>
				</TableRow>
			</>
		);
	}

	/**
	 * リスト名=BeforeInfoList
	 */
	public onBeforeInfoListRender(rowInfo: WprRowInfo): any {
		return (
			<MatTableRow view={this.view} row={rowInfo} className='hedderrow'>
				<TableCell>
					<MatTypography name='itemName' view={this.view} row={rowInfo}/>
				</TableCell>
				<TableCell className='optionDetail'>
					<MatTypography name='itemData' className='itemData' view={this.view} row={rowInfo}/>
				</TableCell>
			</MatTableRow>
		);
	}
	// --------------------------------------------------------------------------
}
