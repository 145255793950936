import { Divider, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import React, { ReactNode } from 'react';
import { MatButton } from '../../../../wpr-framework/component/controls/material/MatButton';
import { MatTypography } from '../../../../wpr-framework/component/controls/material/MatTypography';
import { WprBaseViewComponent } from '../../../../wpr-framework/view/component/WprBaseViewComponent';
import { ProgressBar } from '../../common/progress_bar/ProgressBar';
import { NameChangeConfirmView } from './NameChangeConfirmView';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { Grid } from '@mui/material';
import { WprContainer } from '../../../../wpr-framework/component/controls/WprContainer';
/**
 * 名義変更申込み内容確認用コンポーネント
 */
export class NameChangeConfirm extends WprBaseViewComponent {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: any) { super(new NameChangeConfirmView(), props); }
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** 名義変更申込み内容確認ビュー情報 */
	public get viewInfo(): NameChangeConfirmView	{ return this.view as NameChangeConfirmView;	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		return (
			<>
			<div className='pageCard'>
				<div className="pageHeader stepFlow">
					<ProgressBar name='progressBar' view={this.view} />
				</div>
				<div className='main'>
					<div className='pageTitle'>
						<div className='title_left'>
							<MatTypography name='title' className='title_text' view={this.view}/>
							<Typography className='title_comment'>以下の内容でよろしければ「申込む」ボタンをクリックしてください。</Typography>
						</div>
					</div>
					<div className='feeTable'>
						<Grid container className='feeRow'>
							<Grid item xs={12} sm={4} md={4} className='nameChangeTitle'>
								<div>名義変更区分</div>
							</Grid>
							<Grid item xs={12} sm={8} md={8} className='confirmVal'>
								<MatTypography name='nameChangeKbn' view={this.view}/>
							</Grid>
						</Grid>
					</div>
					<div className='subTitle-blueCircle'>
						<span>名義変更内容について</span>
					</div>
					<div className='feeTable'>
						<Grid container className='feeRow'>
							<Grid item xs={12} sm={4} md={4} className='nameChangeTitle'>
								<div>新契約名義人（漢字）</div>
							</Grid>
							<Grid item xs={12} sm={8} md={8} className='confirmVal'>
								<MatTypography name='newName' view={this.view}/>
							</Grid>
						</Grid>
						<Grid container className='feeRow'>
							<Grid item xs={12} sm={4} md={4} className='nameChangeTitle'>
								<div>新契約名義人（フリガナ）</div>
							</Grid>
							<Grid item xs={12} sm={8} md={8} className='confirmVal'>
								<MatTypography name='newNameKana' view={this.view}/>
							</Grid>
						</Grid>
						<Grid container className='feeRow'>
							<Grid item xs={12} sm={4} md={4} className='nameChangeTitle'>
								<div>現在の契約者さまとの続柄</div>
							</Grid>
							<Grid item xs={12} sm={8} md={8} className='confirmVal'>
								<MatTypography name='relationShip' view={this.view}/>
							</Grid>
						</Grid>
						<WprContainer mode='名義変更区分.2,3' view={this.view}>
							<Grid container className='feeRow'>
								<Grid item xs={12} sm={4} md={4} className='nameChangeTitle'>
									<div>性別</div>
								</Grid>
								<Grid item xs={12} sm={8} md={8} className='confirmVal'>
									<MatTypography name='gender' view={this.view}/>
								</Grid>
							</Grid>
							<Grid container className='feeRow'>
								<Grid item xs={12} sm={4} md={4} className='nameChangeTitle'>
									<div>生年月日</div>
								</Grid>
								<Grid item xs={12} sm={8} md={8} className='confirmVal'>
									<MatTypography name='birthdayStr' view={this.view}/>
								</Grid>
							</Grid>
						</WprContainer>
						<Grid container className='feeRow'>
							<Grid item xs={12} sm={4} md={4} className='nameChangeTitle'>
								<div>連絡先電話番号</div>
							</Grid>
							<Grid item xs={12} sm={8} md={8}>
								<Grid container textAlign='left'>
									<Grid item xs={12} sm={6} md={6} className='confirmVal'>
										電話番号1　
										<MatTypography name='phoneNumber1' view={this.view}/>
									</Grid>
									<Grid item xs={12} sm={6} md={6} className='confirmVal'>
										電話番号2　
										<MatTypography name='phoneNumber2' view={this.view}/>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
						<Grid container className='feeRow'>
							<Grid item xs={12} sm={4} md={4} className='nameChangeTitle'>
								<div>メールアドレス</div>
							</Grid>
							<Grid item xs={12} sm={8} md={8} className='confirmVal'>
								<MatTypography name='newMail' view={this.view}/>
							</Grid>
						</Grid>
					</div>
				</div>
				<Divider />
				<div className='pageFooter footer-space'>
					<div>
						<MatButton name='next' view={this.view} className='orangeButton orangeButton_parts' >
							この内容で申込む
							<NavigateNextIcon />
						</MatButton>
					</div>
					<div>
						<MatButton name='back' view={this.view} className='backButton' >
							<NavigateBeforeIcon />
							戻る
						</MatButton>
					</div>
				</div>
			</div>
			</>
		);
	}
	// --------------------------------------------------------------------------
}
