import React, { ReactNode } from 'react';
import { WprBaseViewComponent } from '../../../../wpr-framework/view/component/WprBaseViewComponent';
import { VideoPassCompleteView } from './VideoPassCompleteView';
import { Typography, Divider, Grid } from '@material-ui/core';
import { WprAnchor } from '../../../../wpr-framework/component/controls/WprAnchor';
import { MatButton } from '../../../../wpr-framework/component/controls/material/MatButton';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import questionnaireIcon from '/shared/image/common/button_questionnaire_on.png';
import printIcon from '/shared/image/common/icon_print_on.png';
import warningIcon from '/shared/image/common/important_yellow.png';
import { MatTypography } from '../../../../wpr-framework/component/controls/material/MatTypography';
import styles from './VideoPassComplete.scss';

/**
 * 動画配信サービスパスワード再発行完了画面用コンポーネント
 */
export class VideoPassComplete extends WprBaseViewComponent {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: any) { super(new VideoPassCompleteView(), props); }
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** 動画配信サービスパスワード再発行完了画面ビュー情報 */
	public get viewInfo(): VideoPassCompleteView	{ return this.view as VideoPassCompleteView;	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * スタイルオブジェクト取得
	*/
	public getStylesObject(): any { return styles; }

	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		return (
			<>
			<div>
				<div className='pageCard'>
					<div className='main'>
						<div className='title_center'>
							<Typography className='title_complete'>動画配信サービス パスワード再発行完了</Typography>
						</div>
						<div className='subText' style={{ textAlign: 'center', margin: '2rem' }}>
							下記の契約者IDのパスワードが再発行されました。<br />
							15～20分経過してからログインして下さい。
						</div>
						<div className='feeTable' style={{ marginBottom: '0' }}>
							<Grid container className='feeRow'>
								<Grid item xs={12} md={4} className='feeTitle'>
									<div>契約者ID：</div>
								</Grid>
								<Grid item xs={12} md={8} className='feeVal' style={{ color: 'black' }}>
									<MatTypography name={'id'} view={this.view} className='itemUnit' style={{ color: '#484848' }}></MatTypography>
								</Grid>
							</Grid>	
							<Grid container className='feeRow'>
								<Grid item xs={12} md={4} className='feeTitle'>
									<div>パスワード：</div>
								</Grid>
								<Grid item xs={12} md={8} className='feeVal' style={{ color: 'black' }}>
									<MatTypography name={'password'} view={this.view} className='itemUnit' style={{ color: '#484848' }}></MatTypography>
								</Grid>
							</Grid>	
							<Grid container className='feeRow'>
								<Grid item xs={12} md={4} className='feeTitle'>
									<div>パスワード読み仮名：</div>
								</Grid>
								<Grid item xs={12} md={8} className='feeVal' style={{ color: 'black' }}>
									<div dangerouslySetInnerHTML={{ __html: this.state.data.sentenceData }}  className='itemUnit'/>
								</Grid>
							</Grid>	
							<Grid container className='feeRow'>
								<Grid item xs={12} md={4} className='feeTitle'>
									<div>変更日：</div>
								</Grid>
								<Grid item xs={12} md={8} className='feeVal' style={{ color: 'black' }}>
									<MatTypography name={'date'} view={this.view} className='itemUnit' style={{ color: '#484848' }}></MatTypography>
								</Grid>
							</Grid>	
						</div>
						<div className='title_left'>
							<Typography className='titleComplete_memo' style={{ marginTop: '1px', fontSize:'14px' }}>※パスワードの読み仮名にアンダーラインが引かれている文字は大文字を意味します。</Typography>
						</div>
						<div className='blueText_underline'>
							<WprAnchor name='print' view={this.view} style={{ display: 'flex' }} >
								<Typography >印刷画面へ</Typography>
								<img src={printIcon} style={{ maxWidth: '20px' }} />
							</WprAnchor>
						</div>
						<div className='warning_msg'>
							<img src={warningIcon} style={{maxWidth: '100%'}} />
							<Typography className='title_memo'>こちらの画面に表示された内容は、以後確認はできません。印刷する場合は右上の『印刷画面へ』をご利用ください。</Typography>
						</div>
						<div className='questionnaire' style={{marginTop:'60px'}}>
							<WprAnchor name='toQuestionnaire' view={this.view}  >
								<img src={questionnaireIcon} style={{maxWidth: '100%'}} />
							</WprAnchor>
						</div>
					</div>
					<Divider />
					<div className='pageFooter footer-space'>
						<div>
							<MatButton name='back' view={this.view} className='completeButton backContInfoList' >
								<NavigateBeforeIcon />
								<span className='screen'>契約内容の確認・変更メニューへ</span>
							</MatButton>
						</div>
					</div>

				</div>
			</div>
			</>
		);
	}
	// --------------------------------------------------------------------------
}
