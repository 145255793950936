import { WizardModel } from '../../models/WizardModel';
import { MypageWizardFlow } from './MypageWizardFlow';
import { MypageWizardMngr } from './models/MypageWizardMngr';
import { CommonCompleteView } from '../common/common_complete/CommonCompleteView';
import { ModIpopConfirmView } from '../Srvnet/revise/Mod_ipop_confirm/ModIpopConfirmView';
import { GetViewInfoRequest } from '../../service/models/dto/mypagerenewal/GetViewInfoRequest';
import { MypageBaseView } from '../base/MypageBaseView';
import { MDisplay } from '../../service/models/entity/secondary/MDisplay';
import { MCommon } from '../../service/models/entity/primary/MCommon';
import { ModIpopFinishView } from '../Srvnet/revise/Mod_ipop_finish/ModIpopFinishView';
import { Ipaddress } from '../../models/bss/ip/Ipaddress';
import { Nhk } from '../../models/bss/nhk/Nhk';
import MypageDispdivcdConstants from '../../common/MypageDispdivcdConstants';
import { InquiryMailInfo } from '../../service/models/apimodels/InquiryMailInfo';
import { RegistInfoRequest } from '../../service/models/dto/mypagerenewal/RegistInfoRequest';
import { BaseInfo } from '../../models/mypage/smart/BaseInfo';
import { Contract } from '../../models/bss/contract/Contract';
import { MAIL_CONSTANTS } from '../../common/MyPageMailIdConstants';
/**
 * ITSCOM HOME_追加ウィザードクラス
 */
export class IpaddressChangeWizardFlow extends MypageWizardFlow {
	// private 変数  ------------------------------------------------------------
	private m_DefaultWizardFlow: WizardModel[]			= null;		// 画面ウィザードリスト
	private m_StartViewName: string						= null;
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	public constructor() {
		super();
		this.m_StartViewName = new ModIpopConfirmView().name;

		// 遷移パターン１
		this.m_DefaultWizardFlow = new Array();
		this.m_DefaultWizardFlow.push({stepNum: 0, title: '契約選択', viewName: null, path: this.getOldUrl() + 'Srvlist/Continfo_list/'});
		this.m_DefaultWizardFlow.push({stepNum: 1, title: '申込み内容の確認', viewName: this.m_StartViewName, path: 'mod_ipop_confirm'});
		this.m_DefaultWizardFlow.push({stepNum: 2, title: '変更申込み完了', viewName: new ModIpopFinishView().name, path: 'mod_ipop_finish'});
	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * 画面ウィザードフロー名取得
	 */
	public getWizardFlowName(viewName: string): string {
		if (viewName === this.m_StartViewName)
			return this.constructor.name;
		return null;
	}
	
	/**
	 * 画面ウィザードリスト取得：契約状態で切り替えたり、選択肢で切り替えたり
	 */
	public getWizardFlow(mngr: MypageWizardMngr): WizardModel[] {
		return this.m_DefaultWizardFlow;
	}
	
	/**
	 * 画面ウィザードAPI用モデル取得：契約状態で切り替えたり、選択肢で切り替えたり
	 */
	public getWizardApiModel(mngr: MypageWizardMngr, view: MypageBaseView): GetViewInfoRequest {
		const req = new GetViewInfoRequest();
		req.customerId = mngr.getParams().customerId;
		req.contractFlg = true;
		req.displayList = new Array();
		req.commonList = new Array();
		req.displayList.push(new MDisplay(MypageDispdivcdConstants.IPADDRESS_TYPE, '0202000110'));
		req.displayList.push(new MDisplay(MypageDispdivcdConstants.IPADDRESS_TYPE, '9999999999'));
		req.displayList.push(new MDisplay(MypageDispdivcdConstants.IPADDRESS_TYPE, 'OP00000009'));	
		req.commonList.push(new MCommon('グローバルIP FTTH ','namecd', 'ip_type','glbl_ip_f'));
		req.commonList.push(new MCommon('プライベートIP','namecd', 'ip_type','prvt_ip'));
		req.commonList.push(new MCommon('グローバルIP','namecd', 'ip_type','glbl_ip'));
		return req;
	}

	/**
	 * 画面ウィザード完了APIモデル取得
	 */
	public getRegistApiModel(mngr: MypageWizardMngr, view: MypageBaseView): RegistInfoRequest{
		const req: RegistInfoRequest = new RegistInfoRequest();
		let cacheData: IpaddressChangeWizardModel = mngr.getCache();
		const mailconstants = MAIL_CONSTANTS.find (mail => {
			return mail.view === 'IPアドレス_変更';
		});
		const contractInfo = mngr.getContract(view,mngr.getParams().contractId);
		req.customerId = mngr.getParams().customerId;
		req.systemInfo = new InquiryMailInfo();
		req.systemInfo.contractId = contractInfo.contractID;
		req.customerMailId = mailconstants.customerMail;
		req.systemMailId = mailconstants.systemMail;
		req.inquiryId = mailconstants.inquiryMail;
		req.systemInfo.content = 'IP種別変更申込み：' + cacheData.ipaddress.ip + 'ＩＰ';
		req.systemInfo.ipOption = cacheData.ipaddress.ip + 'ＩＰ';
		return req;
	}

	/**
	 * 画面キャッシュの初期化データ取得
	 */
	public getInitCacheData(mngr: MypageWizardMngr): IpaddressChangeWizardModel {
		let cacheData = new IpaddressChangeWizardModel();
		cacheData.ipaddress = new Ipaddress();
		cacheData.m_BaseInfo = new BaseInfo();
		cacheData.m_Contract = new Contract();
		return cacheData;
	}

	/**
	 * 画面ウィザードフローの中断判定：例：申し込みが完了していれば、完了済みの画面へ遷移
	 */
	public checkFlowBreak(mngr: MypageWizardMngr): string {
		let test: boolean = false;
		if (test) {
			return '/error';
		}
		return null;
	}
	
}
/**
 * 画面ウィザードAPI用モデル
 */
export class IpaddressChangeWizardModel {
	// public 変数	-----------------------------------------------------------
		public ipaddress: Ipaddress = null;
		public m_BaseInfo: BaseInfo					= null;	// 基本情報
		public m_Contract: Contract										= null; // 契約情報(変更対象)
	// ------------------------------------------------------------------------
}
