import { ReactNode } from 'react';
import { WprBaseViewComponent } from '../../../../wpr-framework/view/component/WprBaseViewComponent';
import { S_01_002View } from './S_01_002View';
import { MatButton } from '../../../../wpr-framework/component/controls/material/MatButton';
import { Divider, Grid, Typography } from '@material-ui/core';
import { Header } from '../../common/header/Header';
import { Footer } from '../../common/footer/Footer';
import styles from './S_01_002.scss';
import { MatTextField } from '../../../../wpr-framework/component/controls/material/MatTextField';
import { WprAnchor } from '../../../../wpr-framework/component/controls/WprAnchor';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';

/**
 * 電子発行承諾用コンポーネント
 */
export class S_01_002 extends WprBaseViewComponent {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: any) { super(new S_01_002View(), props); }
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** 電子発行承諾ビュー情報 */
	public get viewInfo(): S_01_002View { return this.view as S_01_002View; }
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * スタイルオブジェクト取得
	*/
	public getStylesObject(): any { return styles; }

	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		return (
			<>
				{/* <div className='display'>
					<Header/>
					<div className='common'> */}
				{/* <h1>電子発行承諾確認事項</h1>
				<div>
					<Typography className='text'>イッツコムサービスに関するお申し込みにおいて、契約内容確認書(法令等の定めにより契約者へ通知する必要がある書面)の通知方法について、電子交付での手続きをご希望いただきましたので、以下の承諾ボタンを押下のうえ契約内容確認書の確認にお進みください。</Typography>
				</div>
				<div className='childCenter'>
					<MatButton name='b_Next' variant='text' view={this.view} className='NextButton'>マイページで手続き<br />(電子交付に承諾)</MatButton>
				</div> */}


				<div>
					<div className='pageCard'>
						<div className="pageHeader title">
							電子発行承諾確認事項
						</div>
						<Divider />
						<div className='pageContent'>
							<div>
								<div className='contentText'>
									イッツコムサービスに関するお申込みにおいて、電子発行承諾確認事項（法令等の定めにより契約者へ通知する必要がある書面）の通知方法について、電子交付での手続きをご希望いただきましたので、以下の承諾ボタンを押下のうえ電子発行承諾確認事項の確認にお進みください。
								</div>
							</div>

							<div className='pageFooter'>
								<div>
									<MatButton name='b_Next' view={this.view} className='nextButton nextButtonSub' >
										マイページでお手続き<br />
										（電子交付に承諾）
										<NavigateNextIcon />
									</MatButton>
								</div>
							</div>
						</div>
					</div>
				</div>


			</>
		);
	}
	// --------------------------------------------------------------------------
}
