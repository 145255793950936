import { Grid, Typography } from "@mui/material";
import { info } from "node-sass";


// データ ----------------------------------------------------------------------
export class MvnoPlanSendData {

}
export class MvnoTerminalSendData {
    val: string;
    imgBase64: string;
    price: string;
    // stockNum: number;
}
export class MvnoTerminalColorSendData {
    val: string;
    imgBase64: string;
    stockNum: number;
}
export class MvnoCapacitySendData {
    val: string;
    price: string;
    decription: string;
    comment: string;

    // selected: boolean;
}
export class MvnoCourseSendData {
    val: string;
    decription: string;
}

export class MvnoTerminalUmuSendData {
    val: string;
    decription: string;
}

export class MvnoSimSizeSendData {
    val: string;
    img: any;	// 画像
    tate: string;
    yoko: string;
}
export class MvnoInitialSupportSendData {
    val: string;
    decription: string;
}

export class UsagePDFDispMethodSendData {
    decription1: string;// 説明1
    decription2: string;// 説明2
}
// ------------------------------------------------------------------------------------------

export class UsageDetailsRadioContent {
    public static readonly USAGE_PDF_DISP_LIST: string = '一覧表示';
    public static readonly USAGE_PDF_DISP_LIST_1: string = '月ごとのご利用料金を';
    public static readonly USAGE_PDF_DISP_LIST_2: string = '一覧で出力します。';
    public static readonly USAGE_PDF_DISP_DETAIL: string = '詳細表示';
    public static readonly USAGE_PDF_DISP_DETAIL_1: string = '各サービスの詳細料金が表示された';
    public static readonly USAGE_PDF_DISP_DETAIL_2: string = '明細データを出力します。';
    public static readonly USAGE_PDF_DISP_INVOICE: string = 'インボイス表示';
    public static readonly USAGE_PDF_DISP_INVOICE_1: string = '適格請求書として';
    public static readonly USAGE_PDF_DISP_INVOICE_2: string = 'ご利用いただけます。';

    // public ----------------------------------------------------------------
    /**
     * ラジオの内容を取得する
     * @param radioType 
     * @param data 
     * @returns 
     */
    public static getContent(dispType: string): any {
        let data: UsagePDFDispMethodSendData = new UsagePDFDispMethodSendData();
        switch (dispType) {
            case UsageDetailsRadioContent.USAGE_PDF_DISP_LIST:  // 一覧表示
                data.decription1 = UsageDetailsRadioContent.USAGE_PDF_DISP_LIST_1;
                data.decription2 = UsageDetailsRadioContent.USAGE_PDF_DISP_LIST_2
                return this.getPDFDispMethod(data);
            case UsageDetailsRadioContent.USAGE_PDF_DISP_DETAIL:    // 詳細表示
                data.decription1 = UsageDetailsRadioContent.USAGE_PDF_DISP_DETAIL_1;
                data.decription2 = UsageDetailsRadioContent.USAGE_PDF_DISP_DETAIL_2;
                return this.getPDFDispMethod(data);
            case UsageDetailsRadioContent.USAGE_PDF_DISP_INVOICE:    // インボイス表示
                data.decription1 = UsageDetailsRadioContent.USAGE_PDF_DISP_INVOICE_1;
                data.decription2 = UsageDetailsRadioContent.USAGE_PDF_DISP_INVOICE_2;
                return this.getPDFDispMethod(data);
            default:
                break;
        }
    }

    // private ---------------------------------------------------------------
    /**
     * PDF出力方法
     * @param data UsagePDFDispMethodSendData
     * @returns 
     */
    private static getPDFDispMethod(data: UsagePDFDispMethodSendData): any {
        const decription1 = data.decription1;
        const decription2 = data.decription2;
        return (
            <>
                <Grid container>
                    <Grid item xs={12} style={{marginBottom:'0.5rem'}}>
                        <div>{decription1}</div>
                        <div>{decription2}</div>
                    </Grid>
                </Grid>
            </>
        );

    }

}