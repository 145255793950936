import { WprSettingInfo } from './WprSettingInfo';
import { WprBaseMngr } from './WprBaseMngr';
import { WprViewMngr } from './view/WprViewMngr';
import { WprModelMngr } from './model/WprModelMngr';
import { WprScopeMngr } from './scope/WprScopeMngr';
import { WprAuthorityMngr } from './authority/WprAuthorityMngr';
import { WprErrorMngr } from './error/WprErrorMngr';
import { WprBaseFrameworkComponent } from './component/base/WprBaseFrameworkComponent';
import { WprUnitTestInfo } from './test/WprUnitTestInfo';
import { WprEditMngr } from './edit/WprEditMngr';
import { WprDebugMngr } from './debug/WprDebugMngr';

/**
 * WPRフレームワーク
 */
export class WprFramework {
	// private static 変数  -----------------------------------------------------
	private static m_Instance: WprFramework	= null;
	// --------------------------------------------------------------------------

	// private 変数  ------------------------------------------------------------
	private m_IsInit: boolean							= false;							// 初期化フラグ
	private m_WaitCount: number							= 0;								// 待ち数
	private m_IsWaitContinue: boolean					= false;							// 待ち継続フラグ
	private m_Setting: WprSettingInfo					= new WprSettingInfo();				// 設定情報
	private m_ViewMngr: WprViewMngr						= new WprViewMngr();				// ビュー管理機能
	private m_ModelMngr: WprModelMngr					= new WprModelMngr();				// モデル管理機能
	private m_ScopeMngr: WprScopeMngr					= new WprScopeMngr();				// スコープ管理機能
	private m_AuthMngr: WprAuthorityMngr				= new WprAuthorityMngr();			// 権限管理機能
	private m_EditMngr: WprEditMngr						= new WprEditMngr();				// 編集管理機能
	private m_ErrorMngr: WprErrorMngr					= new WprErrorMngr();				// エラー管理機能
	private m_DebugMngr: WprDebugMngr					= new WprDebugMngr();				// デバック管理機能
	private m_MngrMap: Map<string, WprBaseMngr>			= new Map<string, WprBaseMngr>();	// 管理機能リスト
	private m_UrlParamMap: Map<string, string>			= new Map<string, string>();		// Urlパラメータマップ
	private m_FWComponent: WprBaseFrameworkComponent	= null;								// フレームワークコンポーネント
	private m_TestInfo: WprUnitTestInfo					= new WprUnitTestInfo();			// 単体テスト情報
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	private constructor() {}
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** ビュー管理機能 */
	public get view():	WprViewMngr			{ return this.m_ViewMngr;				}
	/** モデル管理機能 */
	public get model():	WprModelMngr		{ return this.m_ModelMngr;				}
	/** スコープ管理機能 */
	public get scope():	WprScopeMngr		{ return this.m_ScopeMngr;				}
	/** 権限管理機能 */
	public get auth(): WprAuthorityMngr		{ return this.m_AuthMngr;				}
	/** 編集管理機能 */
	public get edit(): WprEditMngr			{ return this.m_EditMngr;				}
	/** エラー管理機能 */
	public get error():	WprErrorMngr		{ return this.m_ErrorMngr;				}
	/** デバック管理機能 */
	public get debug():	WprDebugMngr		{ return this.m_DebugMngr;				}
	/** ログインユーザ */
	public get loginUser():	string			{ return this.m_AuthMngr.loginUser;		}
	/** 単体テスト情報 */
	public get testInfo(): WprUnitTestInfo	{ return this.m_TestInfo;				}
	/** 単体テストフラグ */
	public get isUnitTest(): boolean		{ return this.m_TestInfo.isUnitTest;	}
	// --------------------------------------------------------------------------

	// static メソッド	----------------------------------------------------------
	/**
	 * Singletonインスタンス取得
	 */
	public static getInstance(): WprFramework {
		if (WprFramework.m_Instance == null)
			WprFramework.m_Instance = new WprFramework();
		return WprFramework.m_Instance;
	}

	/**
	 * 初期化処理
	 * @param loader データローダ
	 */
	public static initialize(loader: WprBaseFrameworkComponent): void {
		WprFramework.getInstance().loadInfo(loader);
	}
	// --------------------------------------------------------------------------

	// public メソッド	----------------------------------------------------------
	/**
	 * データロード処理
	 * @param loader データローダ
	 */
	public loadInfo(loader: WprBaseFrameworkComponent): void {
		if (this.m_IsInit === true)
			return;
		this.m_IsInit = true;
		this.addMngr(this.m_ErrorMngr);
		this.addMngr(this.m_EditMngr);
		this.addMngr(this.m_AuthMngr);
		this.addMngr(this.m_ViewMngr);
		this.addMngr(this.m_ModelMngr);
		this.addMngr(this.m_ScopeMngr);
		this.addMngr(this.m_DebugMngr);
		this.m_MngrMap.forEach((mngr, key, map) => {
			mngr.setManager(this.m_MngrMap);
		});
		loader.onSetting(this.m_Setting);
		this.m_MngrMap.forEach((mngr, key, map) => {
			mngr.initialize(loader, this.m_Setting);
		});
		if (location.search.startsWith('?') === true) {
			location.search.substr(1).split('&').forEach(param => {
				const list = param.split('=');
				this.setUrlParam(list[0], list[1]);
			});
		}
	}

	/**
	 * コンポーネント設定処理
	 * @param comp コンポーネント
	 */
	public setComponent(comp: WprBaseFrameworkComponent): void {
		this.m_FWComponent = comp;
	}

	/**
	 * ログイン処理
	 * @param userId ユーザID
	 * @param authList 権限リスト
	 */
	public login(userId: string, authList?: string[]): void {
		this.m_AuthMngr.login(userId, authList);
		this.m_ViewMngr.refreshAuth();
	}

	/**
	 * ログアウト処理
	 */
	public logout(): void {
		this.m_AuthMngr.logout();
	}

	/**
	 * Urlパラ―メタ設定
	 * @param key キー
	 * @param value 値
	 */
	public setUrlParam(key: string, value: string): void {
		this.m_UrlParamMap.set(key, value);
	}

	/**
	 * Urlパラ―メタ取得
	 * @param key キー
	 * @returns パラ―メタ値
	 */
	public getUrlParam(key: string): string {
		if (this.m_UrlParamMap.has(key) === true)
			return this.m_UrlParamMap.get(key);
		return null;
	}

	/**
	 * 待機表示開始
	 */
	public startWait(): boolean {
		if (this.m_FWComponent && this.m_Setting.useWaitComponent === true) {
			this.m_WaitCount++;
			this.m_FWComponent.setWait(true);
			return true;
		}
		return false;
	}

	/**
	 * 待機表示終了
	 */
	public endWait(): void {
		if (this.m_FWComponent && this.m_Setting.useWaitComponent === true) {
			this.m_WaitCount--;
			if (this.m_WaitCount == 0) {
				if (this.m_IsWaitContinue === true)
					this.m_IsWaitContinue = false;
				else
					this.m_FWComponent.setWait(false);
			}
		}
	}

	/**
	 * 待機表示継続
	 * @returns fakseの場合、待機継続中ではない
	 */
	public continueWait(): boolean {
		if (this.m_FWComponent && this.m_Setting.useWaitComponent === true) {
			if (this.m_IsWaitContinue === false && this.m_WaitCount > 0) {
				this.m_IsWaitContinue = true;
				return true;
			}
		}
		return false;
	}

	/**
	 * メッセージコンポーネント使用フラグ取得
	 * @returns メッセージコンポーネント使用フラグ
	 */
	public useMessageComponent(): boolean {
		return this.m_Setting.useMessageComponent;
	}

	/**
	 * モバイルフラグ取得
	 * @returns モバイルフラグ
	 */
	public isMobile(): boolean {
		if (this.m_FWComponent != null) {
			const isMobile = this.m_FWComponent.isMobile();
			if (isMobile === undefined) {
				const ua = window.navigator.userAgent.toLowerCase();
				if (ua.indexOf('iphone') > 0 || ua.indexOf('ipod') > 0 || ua.indexOf('android') > 0 && ua.indexOf('mobile') > 0)
					return true;
				return false;
			}
			return isMobile;
		}
		return false;
	}
	// --------------------------------------------------------------------------

	// private メソッド	----------------------------------------------------------
	/**
	 * 管理機能追加処理
	 * @param mngr 管理機能
	 */
	private addMngr(mngr: WprBaseMngr) {
		this.m_MngrMap.set(mngr.name, mngr);
	}
	// --------------------------------------------------------------------------
}
