import { Divider, Grid, Table, TableBody, TableCell, TableHead, Typography } from '@material-ui/core';
import React, { ReactNode } from 'react';
import { WprBaseViewComponent } from '../../../wpr-framework/view/component/WprBaseViewComponent';
import { AmbassadorView } from './AmbassadorView';
import styles from './Ambassador.scss';
import { MatButton } from '../../../wpr-framework/component/controls/material/MatButton';
import { WprContainer } from '../../../wpr-framework/component/controls/WprContainer';
import { MatTypography } from '../../../wpr-framework/component/controls/material/MatTypography';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { MatIconButton } from '../../../wpr-framework/component/controls/material/MatIconButton';
import ShareIcon from '@mui/icons-material/Share';
import {
	FacebookShareButton,
	FacebookIcon,
	TwitterShareButton,
	TwitterIcon,
	LineShareButton,
	LineIcon,
	EmailShareButton,
	EmailIcon,
	FacebookMessengerIcon,
	FacebookMessengerShareButton
} from "react-share";
import { MatLink } from '../../../wpr-framework/component/controls/material/MatLink';

/**
 * アンバサダー制度用コンポーネント
 */
export class Ambassador extends WprBaseViewComponent {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: any) { super(new AmbassadorView(), props); }
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** アンバサダー制度ビュー情報 */
	public get viewInfo(): AmbassadorView	{ return this.view as AmbassadorView;	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * スタイルオブジェクト取得
	*/
	public getStylesObject(): any { return styles; }

	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		return (
			<>
				<div className='pageCard'>
					<div className="pageHeader title">
						お友だち紹介
					</div>
					<Divider />
					<div className='pageContent'>
						{/* キャンペーン */}
						<div className='campaign-box'>
							下記のキャンペーン・特典をご利用いただけます。<br />
							・お友だち紹介特典<br />
							{/* <div>ご紹介者さま(既加入者)：TV,NETの月額料金1ヶ月無料！</div>
							<div>被紹介者さま(新規加入者)：TV,NETの月額料金1ヶ月無料！</div> */}
							<MatTypography name='introducerCampaignOverview' view={this.view} component='div' />
							<MatTypography name='applicantCampaignOverview' view={this.view} component='div' />
						</div>
						{/* URL作成前 */}
						<WprContainer mode='URL発行.未' view={this.view}>
							<p className='childCenter'>
								<MatButton name='createUrlBtn' variant='text' view={this.view} className='NextButton createUrlBtn'>
									URLを発行する<br />(ご紹介専用のお申し込みページ)
								</MatButton>
							</p>
						</WprContainer>
						{/* URL作成後 */}
						<WprContainer mode='URL発行.済' view={this.view}>
							<h2>ご紹介専用のお申し込みページURLを発行しました</h2>
								<p>ご紹介される方が下記URLから新規お申し込みいただきますと、お客さま紹介特典がご利用いただけます。</p>
							<div className='info_tbl'>
								<dl>
									<dt className='tblTitle'>
										専用URL
									</dt>
									<dd className='data'>
										<div className='urlBox'>
											<MatTypography name='url' view={this.view} className='urlValue'/>
										</div>
									</dd>
								</dl>
							</div>
							<p className='childCenter'>
								<b className='validFlex'>
									本URLは
									<MatTypography name='validDate' view={this.view} className='validFont'/>
									まで有効となります。
								</b>
							</p>
							<div className='childCenter iconList'>
								<div>
									<MatIconButton name='copyIcon' view={this.view} className='otherIcon copyIcon'><ContentCopyIcon titleAccess='コピー' className='roundIcon'/></MatIconButton>
									<br /><span>コピー</span>
								</div>
								{/* <div>
									<LineShareButton url={this.viewInfo.getInfoUrl()} title={this.viewInfo.getShareText()}><LineIcon className='aaa' size={50} round /></LineShareButton>
									<br /><span>Line</span>
								</div>
								<div>
									<EmailShareButton url={this.viewInfo.getInfoUrl()} body={this.viewInfo.getShareText() + '\n'}><EmailIcon className='aaa' size={50} round /></EmailShareButton>
									<br /><span>Email</span>
								</div>
								<div>
									<TwitterShareButton url={this.viewInfo.getInfoUrl()} title={this.viewInfo.getShareText()}><TwitterIcon className='aaa' size={50} round /></TwitterShareButton>
									<br /><span>Twitter</span>
								</div> */}
								<div>
									<MatIconButton name='webShareIcon' view={this.view} className='otherIcon'><ShareIcon titleAccess='共有' className='roundIcon'/></MatIconButton>
									<br /><span>共有</span>
								</div>
							</div>
						</WprContainer>
						<div className='notes'>
							<h2>進呈</h2>
							<p>
								<b>【お友だち紹介特典（実施開始日：</b>
								<MatTypography name='campaignPeriod' view={this.view} component='span' style={{fontWeight: 'bold' }}/> 
								<b>）】</b>
							</p>
							<p>
								<b>（１）特典内容</b><br/>
								当社サービスに加入中の紹介者が発行した専用URL等 より、当社サービスに未加入の被紹介者が下記のいずれかを新規に申し込みされ、申し込みから６ヵ月以内に開通した場合、紹介者・被紹介者の対象サービス月額利用料をそれぞれ１ヵ月間無料とします。
								<ul>
									<li>ケーブルテレビジョンサービス</li>
									<li>ケーブルインターネットサービス（かっとびプラス、かっとびジャストを除く）</li>
									<li>イッツコムひかり テレビジョンサービス</li>
									<li>イッツコムひかり インターネットサービス</li>
									<li>かっとび光</li>
								</ul>
							</p>
							<p>
								<b>（２）対象外</b>
								<ul>
									<li>管理組合経由で利用料金を請求しているサービス</li>
									<li>専用フォームでの受付ができない一部の物件</li>
									<li> オプションサービス</li>
								</ul>
							</p>
							<p>
								<b>（３）割引期間</b>
								<ul>
									<li>紹介者は、被紹介者の対象サービスの提供が開始された日の属する暦日の翌月を１として起算して１ヵ月間（割引期間中に対象サービスの変更、解約等をされた場合、割引は終了します。）</li>
									<li>被紹介者は、対象サービスの提供が開始された日の属する暦日の翌月を１として起算して１ヵ月間（割引期間中に対象サービスの変更、解約等をされた場合、割引は終了します。）</li>
								</ul>
							</p>
							<p>
								<b>（４）特記事項</b>
								<ul>
									<li>紹介者と被紹介者が、ともに「お友だち紹介特典」の適用を選択する場合に適用</li>
									<li>紹介者は、割引期間が６ヵ月以内の制度特典およびキャンペーンに限り併用可能</li>
									<li>ケーブルフレンズ(お友達紹介制度)との併用不可  </li>
								</ul>
							</p>
							{/* #70035対応↓ */}
							<p>
								<b>【サーティワンギフト券プレゼントキャンペーンについて】</b>
							</p>
							<p>
								キャンペーン内容：キャンペーン期間内にお友だち紹介制度を利用していただいた方に、サーティワンギフト券1,000円分をプレゼント致します。
							</p>
							<p>
								キャンペーン期間：2024年8月1日～2024年9月30日
							</p>
							<p>
								適用条件：
								<br/>①キャンペーン期間内にお友だち紹介制度でご紹介した方(紹介者)、ご紹介された方(被紹介者)
								<br/>②サーティワンギフト券の配布時点で、ご紹介された方(被紹介者)がイッツコムサービスをご利用中であること
								<br/>サーティワンギフト券配布日：ご紹介された方(被紹介者)のイッツコムサービス開通月を1ヵ月目として7ヵ月目の月
								<br/>(例：利用開始月/8月→配布日/2月中)
							</p>
							<p>
								<ul>
									<li>紹介者さま：配布時点でマイページにご登録のメールアドレス宛に受取用メールを送付致します。</li>
									<li>被紹介者さま：お申込み時に入力頂いたメールアドレス宛に受取用メールを送付致します。</li>
									<li>メールのドメイン指定受信など、受信制限機能を利用している場合は「@itscom.jp」からのメールを受信できるように設定をお願い致します。</li>
									<li>ギフト券は受取り後、使用期限がございます。使用期限が切れたギフトの再発行は承りかねますのでご注意ください。</li>
									<li>本キャンペーンはやむを得ない事情により、予告なく中止または内容が変更となる場合がございますので予めご了承下さい。</li>
								</ul>
							</p>	
						</div>
					</div>
				</div>
			</>
		);
	}
	// --------------------------------------------------------------------------
}
