import { Tab } from '@material-ui/core';
import React, { ReactNode } from 'react';
import { WprControlVisibility } from '../../props/IWprControlState';
import { IWprNoNameLayoutModeProps } from '../../props/IWprLayoutProps';
import { WprBaseNoNameLayoutModeComponent } from '../WprBaseLayoutModeComponent';

/**
 * タブプロパティ情報
 */
interface IMatTabProps extends IWprNoNameLayoutModeProps {
	label: string;			// ラベル
	labelMode?: string;		// ラベル以外をモードにする場合に使用する
}

/**
 * タブ
 */
export class MatTab extends WprBaseNoNameLayoutModeComponent<IMatTabProps, JSX.Element> {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: IMatTabProps) {
		super(props);
		this.props.view.setTab(this);
	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * 描画
	 */
	public onRender(): ReactNode {
//		if (this.state.visible === false)
//			return <></>;
		const props = this.getProps();
		const {view, className, children, ...other} = this.props;
		const style = this.getStyle();
		return <Tab {...props} {...other}  {...style} ref={this.ref} />;
	}
	// --------------------------------------------------------------------------

	// private メソッド  ---------------------------------------------------------
	/**
	 * スタイル情報取得
	 * @returns スタイル情報
	 */
	private getStyle(): any {
		const rtn: any = {};
		if (this.state.visibility === WprControlVisibility.VISIBLE && this.state.visible === true) {
			if (this.state.numOption1) {
				const width = `${100 / this.state.numOption1}%`;
				rtn['style'] = { width: width, minWidth: 'unset' };
			}
		}
		else {
			rtn['style'] = { display: 'none' };
		}
		return rtn;
	}
	// --------------------------------------------------------------------------
}
