/**
 * 
 */
export class MGeneral {
	// public 変数	-----------------------------------------------------------
	public generalId: number;		// 汎用項目ID
	public codeTypeCd: string;	// 汎用項目種類コード
	public generalCd: string;	// 汎用項目コード
	public subCd1: string;		// サブコード1
	public subCd2: string;		// サブコード2
	public name1: string;		// 名称1
	public name2: string;		// 名称2
	public name3: string;		// 名称3
	public name4: string;		// 名称4
	public num1: number;			// 数値1
	public num2: number;			// 数値2
	public num3: number;	// 数値3
	public num4: number;	// 数値4
	public flag1: boolean;		// 汎用フラグ1
	public flag2: boolean;		// 汎用フラグ2
	public flag3: boolean;		// 汎用フラグ3
	public flag4: boolean;		// 汎用フラグ4
	public date1: Date;			// 日付1
	public date2: Date;			// 日付2
	public date3: Date;			// 日付3
	public date4: Date;			// 日付4
	public text1: string;		// テキスト1
	public text2: string;		// テキスト2
	public sort: number;			// 順序
	public entryDate: Date;		// 登録日時
	public entryCd: string;		// 登録担当者コード
	public updateDate: Date;	// 更新日時
	public updateCd: string;	// 更新担当者コード
	public deleteFlag: boolean;	// 削除フラグ
	public deleteDate: Date;	// 削除日時
	public deleteCd: string;	// 削除担当者コード
	// ------------------------------------------------------------------------

	// コンストラクタ ----------------------------------------------------------
	constructor(codeTypeCd?: string, generalCd?: string)  {
		this.codeTypeCd =codeTypeCd;
		this.generalCd = generalCd;
	}
	// ------------------------------------------------------------------------

	public setData(codeTypeCd:string, generalCd:string):MGeneral {
		this.codeTypeCd = codeTypeCd;
		this.generalCd = generalCd;
		return this;
	}
}