import { WprModel } from '../../../../wpr-framework/view/deco/WprModel';
import { WprDI_ScopeModel } from '../../../../wpr-framework/view/di/WprDI_ScopeModel';
import { CryptRijndaelUtil } from '../../../common/util/CryptRijndaelUtil';
import { MCommon } from '../../../service/models/entity/primary/MCommon';
import { MypageBaseView } from '../../base/MypageBaseView';
import { CompleteModel } from '../../common/common_complete/models/CompleteModel';
import { ProgressBarData } from '../../common/progress_bar/ProgressBarView';
import { MypageWizardMngr } from '../../wizard/models/MypageWizardMngr';
import { ReiAccessAccountModel } from '../rei_accesspass_confirm/models/ReiAccessAccountModel';

/**
 * アカウントパスワード発行完了
 */
export class ReiAccesspassCompleteView extends MypageBaseView {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() { super('ReiAccesspassCompleteView'); }
	// --------------------------------------------------------------------------
	
	// モデル  ------------------------------------------------------------------
	@WprDI_ScopeModel('MypageWizardMngr')
	private m_MypageWizardMngr: MypageWizardMngr			= null;		// 画面ウィザード管理クラス
	@WprModel('ReiAccessAccountModel')
	private m_ReiAccessAccountModel: ReiAccessAccountModel			= null;		// アクセスアカウントパスワード発行モデル
	@WprModel('ReiAccessAccountPwModel')
	private m_ReiAccessAccountPwModel: ReiAccessAccountModel			= new ReiAccessAccountModel();		// アクセスアカウント複合化パスワードモデル
	@WprModel('CompleteModel')
	private m_CompleteModel: CompleteModel			= new CompleteModel();		// 
	// --------------------------------------------------------------------------

	// メンバ変数 ---------------------------------------------------------------
	private m_CustomerName: string = null;	// 顧客名
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * コントロール定義設定
	 */
	public onRegisterConfig(): void {
		this.addConfig('course', 'コース', 'ReiAccessAccountModel');
		this.addConfig('accessAccount', 'アクセスアカウント', 'ReiAccessAccountModel');
		this.addConfig('password', 'パスワード', 'ReiAccessAccountPwModel');
		this.addConfig('date', '変更日', 'ReiAccessAccountModel', {converter: '日付（yyyy年MM月dd日）' });
		this.addConfig('user', 'ユーザー', 'CompleteModel');
		this.addConfig('passwordKana', 'パスワード読み仮名', 'ReiAccessAccountModel');
	}

	/**
	 * アクション登録
	 */
	public onRegisterAction(): void {
		this.addAction('toQuestionnaire', this.onToQuestionnaire);
		this.addAction('back', this.onBack);
		this.addAction('print', this.onPrintView);
	}

	/**
	 * ビュー表示通知
	 */
	public onShowView(): void {
		this.m_MypageWizardMngr.initView(this, (result) => {
			this.registAccessLog();
			this.setStorageItem('b_url', window.location.href)
			this.m_ReiAccessAccountModel = this.m_MypageWizardMngr.getCache().m_ReiAccessAccountModel;
			// ユーザーの取得
			const customerInfo = this.m_MypageWizardMngr.getViewInfo().customer;
			if (customerInfo.sexCD === '3' || customerInfo.sexCD === '') {
				this.m_CustomerName = customerInfo.corporateName;
			} else {
				this.m_CustomerName = customerInfo.surName + ' ' +customerInfo.givenName + 'さん';
			}
			this.m_CompleteModel.user = this.m_CustomerName;
			// 複合化
			this.getDecodePassword();
			this.setViewData('sentenceData', this.m_ReiAccessAccountPwModel.passwordKana)
			const sendData: ProgressBarData = new ProgressBarData();
			sendData.progressBarList = this.m_MypageWizardMngr.getProgressModelList(this);
			this.sendChildData('fP_ev_progressBarIntital', sendData);
			this.refreshView();
		});
	}
	// --------------------------------------------------------------------------

	// アクション  ---------------------------------------------------------------
	/**
	 * アンケート
	 */
	public onToQuestionnaire(param: any): void {
		window.open('https://questant.jp/q/B7KS5E4S?id=' + this.m_MypageWizardMngr.getParams().customerId);
	}

	/**
	 * 契約内容の確認・変更メニューへ
	 */
	public onBack(param: any): void {
		location.href = this.getOldUrl() + 'Srvlist/Continfo_list/';
	}

	/**
	 * 印刷画面へ
	 */
	public onPrintView(param: any): void {
		this.openNewTab('rei_accesspass_print');
}
	// --------------------------------------------------------------------------
	// privateメソッド  ----------------------------------------------------------

	private getDecodePassword(): void{
		const mCommonList: MCommon[] = this.m_MypageWizardMngr.getMCommonList(this,'cryptkey', null, null, null);
		const decrypted: string = mCommonList[0].commonValue;
		this.m_ReiAccessAccountPwModel.password = CryptRijndaelUtil.decodePW(decrypted, this.m_ReiAccessAccountModel.password);
		this.m_ReiAccessAccountPwModel.passwordKana = CryptRijndaelUtil.decodePW(decrypted, this.m_ReiAccessAccountModel.passwordKana);
	}
	// --------------------------------------------------------------------------
}
