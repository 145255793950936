/**
 * 工事遷移情報
 */
export class ConstrFlowModel {
	// public 変数	-----------------------------------------------------------
	public defaultFlg: boolean;			// 新規申込フラグ
	public changeFlg: boolean;			// 変更フラグ
	public cancelFlg: boolean; 			// キャンセルフラグ
	public logFlg: boolean; 			// 履歴フラグ
	// ------------------------------------------------------------------------
}
