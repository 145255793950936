import React, { ReactNode } from 'react';
import { WprBaseViewComponent } from '../../../../wpr-framework/view/component/WprBaseViewComponent';
import { HeaderView } from './HeaderView';
import itscom_icon from "../../../../../shared/image/itscom_icon.png";
import question_icon from "../../../../../shared/image/common/question_icon.png"
import logout_icon from "../../../../../shared/image/common/logout_icon.png"
import styles from './Header.scss';
import itscomIcon from '/shared/image/menu/menu_itscom.png';
import mypageIcon from '/shared/image/menu/menu_mypage.png';
import itscomMypageIcon from '/shared/image/common/itscomMyp_icon.png';

import logoutWhite_icon from "../../../../../shared/image/common/logout_white.png"
import help_icon from "../../../../../shared/image/common/help_icon.png"
import { Grid } from '@material-ui/core';
import { WprContainer } from '../../../../wpr-framework/component/controls/WprContainer';
import { FontSizeRadio } from '../../../controls/fontSizeRadio/FontSizeRadio';
import { MatButton } from '../../../../wpr-framework/component/controls/material/MatButton';
import { WprAnchor } from '../../../../wpr-framework/component/controls/WprAnchor';


/**
 * ヘッダー用コンポーネント
 */
export class Header extends WprBaseViewComponent {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: any) { super(new HeaderView(), props); }
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** ヘッダービュー情報 */
	public get viewInfo(): HeaderView { return this.view as HeaderView; }
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * スタイルオブジェクト取得
	*/
	public getStylesObject(): any { return styles; }

	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		return (
			<>
				{/* スマホ用 */}
				<WprContainer mode='ヘッダー.小' view={this.view}>
					<div className='headerSmall' >
						<Grid container className='header'>
							<Grid item >
								<div>
									<WprAnchor name='toTop' view={this.view}>
										<img src={itscomMypageIcon} className='headerLogoIcon' />
									</WprAnchor>
									{/* <img src={mypageIcon} style={{ maxWidth: '50px', marginLeft: '8px' }} /> */}
								</div>
							</Grid>
							<Grid item className='headerRight'>
								<div className='headerButton'>
									{/* <div className='headerItem'> */}
									<WprAnchor name='toCustomerSupport' view={this.view} className='headerItem'>
										<div className='headerItemIcon'>
											<img src={help_icon} style={{ width: '20px', height: '20px' }} />
										</div>
										<span className='headerItemText'>ヘルプ</span>

									</WprAnchor>
									{/* </div>
									<div className='headerItem'> */}
									<WprAnchor name='logout' view={this.view} className='headerItem'>

										<div className='headerItemIcon'>
											<img src={logoutWhite_icon} style={{ width: '15px', height: '12.53px' }} />
										</div>
										<span className='headerItemText'>ログアウト</span>
									</WprAnchor>
									{/* </div> */}
								</div>
							</Grid>
						</Grid>
					</div>
				</WprContainer>

				{/* PC用 */}
				<WprContainer mode='ヘッダー.大' view={this.view}>
					<div className='headerBig' >
						<div className='header'>
							<Grid container style={{ justifyContent: 'end' }}>
								<Grid item >
									<div className='fontSize'>
										<div className='fontText'>文字サイズ</div>
										<div className='fontRadio'>
											<FontSizeRadio name='fontSize' value='小' afterLabel='小' id='fontSize_1' view={this.view} />
											<FontSizeRadio name='fontSize' value='中' afterLabel='中' id='fontSize_2' view={this.view} />
											<FontSizeRadio name='fontSize' value='大' afterLabel='大' id='fontSize_3' view={this.view} />
										</div>
									</div>
								</Grid>
								<Grid item>
									<div className='box_white'>
										<Grid container style={{ height: '100%' }}>
											<Grid item className='boxItem'>
												<MatButton name='toCustomerSupport' view={this.view} variant='text' className='headerButton'>
													<img src={question_icon} className='itemIcon' />
													<div className='itamText'>お問い合わせ・サポート</div>
												</MatButton>
											</Grid>
											<Grid item className='boxItem'>
												<div style={{ border: '1px solid #B0B6C5', height: '18px' }}></div>
											</Grid>
											<Grid item className='boxItem'>
												<MatButton name='logout' view={this.view} variant='text' className='headerButton'>
													<img src={logout_icon} className='itemIcon' />
													<div className='itamText'>ログアウト</div>
												</MatButton>
											</Grid>
										</Grid>
									</div>
								</Grid>
							</Grid>
						</div>
					</div>
				</WprContainer>
			</>
		);
	}
	// --------------------------------------------------------------------------
}
