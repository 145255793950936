import { TvCourseListModel } from './TvCourseListModel';

/**
 * テレビ_コースモデル
 */
export class TvCourseModel {
	// public 変数	-----------------------------------------------------------
	public course: string;					// コース名
	public detailList: TvCourseListModel[];	// テレビ_コースリストモデルリスト
	// ------------------------------------------------------------------------
}
