import React, { ReactNode } from 'react';
import { WprAnchor } from '../../../../../wpr-framework/component/controls/WprAnchor';
import { WprContainer } from '../../../../../wpr-framework/component/controls/WprContainer';
import { WprBaseDialogViewComponent } from '../../../../../wpr-framework/view/component/WprBaseDialogViewComponent';
import { MvnoHelpDialogView } from './MvnoHelpDialogView';
import styles from './MvnoHelpDialog.scss';
import { Divider, Grid, Table, TableBody, TableCell, Typography } from '@material-ui/core';
import { MatOneRowTable } from '../../../../../wpr-framework/component/controls/material/MatOneRowTable';

/**
 * MVNOヘルプダイアログ用コンポーネント
 */
export class MvnoHelpDialog extends WprBaseDialogViewComponent {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: any) { super(new MvnoHelpDialogView(), props); }
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** MVNOヘルプダイアログビュー情報 */
	public get viewInfo(): MvnoHelpDialogView { return this.view as MvnoHelpDialogView; }
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * スタイルオブジェクト取得
	*/
	public getStylesObject(): any { return styles; }

	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		return (
			<>
				{/* SIMサイズについて ------------------------------------------------------------------------------------------------------ */}
				<WprContainer mode='ダイアログ.SIMサイズについて' view={this.view}>
					<div className='dialogHeader'>
						<Typography className='title'>SIMサイズについて</Typography>
					</div>
					<div className='dialogContent'>
						<div>
							{/* nanoSIM */}
							<div>
								<div className='itemTitle title_maru'>nanoSIM</div>
								<div className=''>
									3種類のSIMカードの中で最も小さいサイズです。<br />2012年発売のiPhone 5以降のiPhoneと近年発売された端末の大多数にnanoSIMが採用されています。現在発売中のスマホはこのサイズが主流です。

								</div>
							</div>
							{/* マイクロSIM */}
							<div>
								<div className='itemTitle title_maru'>マイクロSIM</div>
								<div className=''>
									iPhone 4 や2012~2014年頃に発売されたAndroid端末で利用されていることが多いです。現在はガラケー（ガラホ）などで採用されています。
								</div>
							</div>
							{/* 標準SIM */}
							<div>
								<div className='itemTitle title_maru'>標準SIM</div>
								<div className=''>
									一番大きいサイズのSIMカードになります。<br />ガラケーやiPhone 3G、初期の3G端末で利用されています。現在はほとんど利用されていません。
								</div>
							</div>
						</div>
						<div className=''>
							一部機種については<WprAnchor name='simFreeLink' view={this.view} className='link_underline_blue'>「動作確認済み端末一覧」（PDF)</WprAnchor>でも対応SIMサイズの確認ができます。
						</div>
					</div>

				</WprContainer>

				{/* 続柄について ------------------------------------------------------------------------------------------------------ */}
				<WprContainer mode='ダイアログ.続柄について' view={this.view}>
					<div className='dialogHeader'>
						<Typography className='title'>続柄について</Typography>
					</div>
					<div className='dialogContent'>
						ご契約者本人以外のお申込みの場合には、利用者は契約者の同居家族に限りお申込みが可能です。
					</div>
				</WprContainer>

				{/* 採番方法について ------------------------------------------------------------------------------------------------------ */}
				<WprContainer mode='ダイアログ.採番方法について' view={this.view}>
					<div className='dialogHeader'>
						<Typography className='title'>採番方法について</Typography>
					</div>
					<div className='dialogContent'>
						<div>
							<div>
								<Typography className='itemTitle title_maru' >新規電話番号の発番</Typography>
								<ul>
									<li>新しく電話番号を発行します。</li>
								</ul>
							</div>
							<div>
								<Typography className='itemTitle title_maru' >モバイルナンバーポータビリティ(MNP)</Typography>
								<ul>
									<li>他社でご利用中の電話番号を引継ぎます。</li>
									<li>イッツコム契約手続き開始の時点で有効期限が9日以上残っている必要がありますので、ご契約前にすでに取得されていた場合でも、改めて取得いただくことがあります。</li>
									<li>番号ポータビリティ転入の際は、「イッツコムのご契約者またはご利用者の情報」と、「ご利用中の携帯電話会社に登録されているご契約者情報」および「本人確認書類に記載された情報」すべてが一致している必要があります。</li>
									<li>
										KDDI（au）やソフトバンクのスマートフォンについては、通信方式の違いやSIMロックを解除できないことから利用できないケースが多く、当社での動作確認は行っておりません。詳しくはご利用中の携帯電話会社の販売店またはカスタマーサポート窓口までお問い合わせください。
									</li>
								</ul>
							</div>
						</div>
					</div>

					<Divider />
					<div className='dialogHeader'>
						<Typography className='title'>MNP予約番号</Typography>
					</div>
					<div className='dialogContent'>
						<div>

							<div>
								電話番号を引き継いで利用する場合、MNP（番号ポータビリティ）予約番号が必要です<br />
								ご利用中の携帯電話会社からMNP予約番号の取得をお願いいたします。<br />
								予約番号の有効期間は、取得日を含む15日間です
							</div>
							<div className='box_blue1'>
								<div className='boxTitle'>
									<Typography>各携帯会社での番号ポータビリティ予約番号（MNP予約番号）の取得電話窓口</Typography>
								</div>
								<div className='boxContent'>
									<div className='table_gray'>
										<Grid container className='tableRow'>
											<Grid item xs={12} md={6} className='rowTitle'>
												<div>docomoの方</div>
											</Grid>
											<Grid item xs={12} md={6}  className='rowContent'>
												<div>
													携帯電話(151)<br />
													一般電話(0120-800-000)<br />
													（受付時間：9:00～20:00）<br />
												</div>
											</Grid>
										</Grid>
										<Grid container className='tableRow'>
											<Grid item xs={12} md={6}  className='rowTitle'>
												<div>softbankの方</div>
											</Grid>
											<Grid item xs={12} md={6}  className='rowContent'>
												<div>
													携帯電話(*5533)<br />
													一般電話(0800-100-5533)<br />
													（受付時間：9:00～20:00）<br />
												</div>
											</Grid>
										</Grid>
										<Grid container className='tableRow'>
											<Grid item xs={12} md={6}  className='rowTitle'>
												<div>auの方</div>
											</Grid>
											<Grid item xs={12} md={6}  className='rowContent'>
												<div>
													0077-75470（無料）<br />
													（受付時間：9:00～20:00）
												</div>
											</Grid>
										</Grid>
									</div>
									<div style={{marginTop: '1rem'}}>
										{/* ※現在ご利用中の携帯電話会社の解約手続きについてはお客さまご自身で行っていただく必要があります。<br /> */}
										※現在の携帯電話の契約状況により、ご利用中の携帯電話会社に対して解約違約金などが発生する場合があります。
									</div>
								</div>
							</div>

						</div>
					</div>
				</WprContainer>
			</>
		);
	}
	// --------------------------------------------------------------------------
}
