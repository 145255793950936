import { WizardModel } from '../../models/WizardModel';
import { MypageWizardFlow } from './MypageWizardFlow';
import { MDisplay } from '../../service/models/entity/secondary/MDisplay';
import { CommonCompleteView } from '../common/common_complete/CommonCompleteView';
import { GetViewInfoRequest } from '../../service/models/dto/mypagerenewal/GetViewInfoRequest';
import { MypageBaseView } from '../base/MypageBaseView';
import { MGeneral } from '../../service/models/entity/MGeneral';
import { MCommon } from '../../service/models/entity/primary/MCommon';
import MypageDispdivcdConstants from '../../common/MypageDispdivcdConstants';
import MypageCodeTypeConstants from '../../common/MypageCodeTypeConstants';
import { MypageWizardMngr } from './models/MypageWizardMngr';
import { TvBaseModel } from '../../models/mypage/tv/TvBaseModel';
import { ModTvequipSelectView } from '../tv/equipment/mod_tvequip_select/ModTvequipSelectView';
import { ModTvequipConfirmView } from '../tv/equipment/mod_tvequip_confirm/ModTvequipConfirmView';
import { TvNewWizardModel } from './TvNewWizardFlow';
import { RegistInfoRequest } from '../../service/models/dto/mypagerenewal/RegistInfoRequest';
import { Contract } from '../../models/bss/contract/Contract';
import { ConstrConfirmView } from '../construction/constr_confirm/ConstrConfirmView';
import { ConstrSearchView } from '../construction/constr_search/ConstrSearchView';
import { ContsrNotesView } from '../construction/contsr_notes/ContsrNotesView';
import { ConstrListView } from '../construction/constr_list/ConstrListView';
import { ConstrDateListModel } from '../../models/mypage/Construction/ConstrDateListModel';
import { ConstrBaseModel } from '../../models/mypage/Construction/ConstrBaseModel';
import { DayOfWeekModel } from '../../models/mypage/Construction/DayOfWeekModel';
import { ConstrFlowModel } from '../../models/mypage/Construction/ConstrFlowModel';
import { ConstrChangeView } from '../construction/constr_change/ConstrChangeView';
import { ConstrCancelView } from '../construction/constr_cancel/ConstrCancelView';
import { ConstrLogView } from '../construction/constr_log/ConstrLogView';
import { MAIL_CONSTANTS } from '../../common/MyPageMailIdConstants';
import { InquiryMailInfo } from '../../service/models/apimodels/InquiryMailInfo';
import { DateUtil } from '../../common/util/DateUtil';

/**
 * 工事_ウィザードクラス
 */
export class ConstructionWizardFlow extends MypageWizardFlow {
	// private 変数  ------------------------------------------------------------
	private m_DefaultWizardFlow: WizardModel[]			= null;		// 基本画面ウィザードリスト
	private m_ChangeWizardFlow: WizardModel[]			= null;		// 変更画面ウィザードリスト
	private m_CancelWizardFlow: WizardModel[]			= null;		// キャンセル画面ウィザードリスト
	private m_LogWizardFlow: WizardModel[]				= null;		// 履歴画面ウィザードリスト
	private m_StartViewName: string						= null;
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	public constructor() {
		super();
		this.m_StartViewName = new ConstrListView().name;

		// 基本遷移パターン
		this.m_DefaultWizardFlow = new Array();
		this.m_DefaultWizardFlow.push({stepNum: 0, title: 'トップ', viewName: null, path: this.getOldUrl() + 'Top/My_top/'});
		this.m_DefaultWizardFlow.push({stepNum: 1, title: '工事予定日一覧', viewName: new ConstrListView().name, path: 'constr_list'});
		this.m_DefaultWizardFlow.push({stepNum: 2, title: '注意事項説明', viewName: new ContsrNotesView().name, path: 'contsr_notes'});
		this.m_DefaultWizardFlow.push({stepNum: 3, title: '希望予定日の検索', viewName: new ConstrSearchView().name, path: 'constr_search'});
		this.m_DefaultWizardFlow.push({stepNum: 4, title: '希望予定日の確認', viewName: new ConstrConfirmView().name, path: 'constr_confirm'});
		this.m_DefaultWizardFlow.push({stepNum: 5, title: '申込み完了', viewName: new CommonCompleteView().name, path: 'constr_complete'});

		// 変更遷移パターン
		this.m_ChangeWizardFlow = new Array();
		this.m_ChangeWizardFlow.push({stepNum: 0, title: 'トップ', viewName: null, path: this.getOldUrl() + 'Top/My_top/'});
		this.m_ChangeWizardFlow.push({stepNum: 1, title: '工事予定日一覧', viewName: new ConstrListView().name, path: 'constr_list'});
		this.m_ChangeWizardFlow.push({stepNum: 2, title: '工事予定日変更', viewName: new ConstrChangeView().name, path: 'constr_change'});
		this.m_ChangeWizardFlow.push({stepNum: 3, title: '注意事項説明', viewName: new ContsrNotesView().name, path: 'contsr_notes'});
		this.m_ChangeWizardFlow.push({stepNum: 4, title: '希望予定日の検索', viewName: new ConstrSearchView().name, path: 'constr_search'});
		this.m_ChangeWizardFlow.push({stepNum: 5, title: '希望予定日の確認', viewName: new ConstrConfirmView().name, path: 'constr_confirm'});
		this.m_ChangeWizardFlow.push({stepNum: 6, title: '申込み完了', viewName: new CommonCompleteView().name, path: 'constr_change_complete'});

		// キャンセル遷移パターン
		this.m_CancelWizardFlow = new Array();
		this.m_CancelWizardFlow.push({stepNum: 0, title: 'トップ', viewName: null, path: this.getOldUrl() + 'Top/My_top/'});
		this.m_CancelWizardFlow.push({stepNum: 1, title: '工事予定日一覧', viewName: new ConstrListView().name, path: 'constr_list'});
		this.m_CancelWizardFlow.push({stepNum: 2, title: '工事予定キャンセル', viewName: new ConstrCancelView().name, path: 'contsr_cancel'});
		this.m_CancelWizardFlow.push({stepNum: 3, title: 'キャンセル完了', viewName: new CommonCompleteView().name, path: 'constr_cancel_complete'});

		// 履歴遷移パターン
		this.m_LogWizardFlow = new Array();
		this.m_LogWizardFlow.push({stepNum: 0, title: 'トップ', viewName: null, path: this.getOldUrl() + 'Top/My_top/'});
		this.m_LogWizardFlow.push({stepNum: 1, title: '工事予定日一覧', viewName: new ConstrListView().name, path: 'constr_list'});
		this.m_LogWizardFlow.push({stepNum: 2, title: '工事予定日履歴', viewName: new ConstrLogView().name, path: 'contsr_log'});
		this.m_LogWizardFlow.push({stepNum: 3, title: '申込み完了', viewName: new CommonCompleteView().name, path: 'constr_complete'});
	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * 画面ウィザードフロー名取得
	 */
	public getWizardFlowName(viewName: string): string {
		if (viewName === this.m_StartViewName)
			return this.constructor.name;
		return null;
	}
	
	/**
	 * 画面ウィザードリスト取得：契約状態で切り替えたり、選択肢で切り替えたり
	 */
	public getWizardFlow(mngr: MypageWizardMngr): WizardModel[] {
		let cacheData: ConstructionWizardModel = mngr.getCache();
		// 新規追加
		if (cacheData.m_FlowModel.defaultFlg) 
			return this.m_DefaultWizardFlow;
		// 変更
		if (cacheData.m_FlowModel.changeFlg)
			return this.m_ChangeWizardFlow;
		// キャンセル
		if (cacheData.m_FlowModel.cancelFlg)
			return this.m_CancelWizardFlow;
		// 履歴
		if (cacheData.m_FlowModel.logFlg)
			return this.m_LogWizardFlow;
		return this.m_DefaultWizardFlow;
	}
	
	/**
	 * 画面ウィザードAPI用モデル取得：契約状態で切り替えたり、選択肢で切り替えたり
	 */
	public getWizardApiModel(mngr: MypageWizardMngr, view: MypageBaseView): GetViewInfoRequest {
		const req = new GetViewInfoRequest();
		req.customerId = mngr.getParams().customerId;
		req.buildingFlg = true;
		// req.commonList = new Array();
		// const common1 = new MCommon('オーナー契約IHリスト', 'IH', 'OWNER_SCD');
		// req.commonList.push(common1);
		// const common2 = new MCommon('物件プラン取得', 'building_plan');
		// req.commonList.push(common2);
		return req;
	}
	
	/**
	 * 画面ウィザード完了APIモデル取得
	 */
	public getRegistApiModel(mngr: MypageWizardMngr, view: MypageBaseView): RegistInfoRequest{
		const req: RegistInfoRequest = new RegistInfoRequest();
		const cacheData = mngr.getCache();
		const customer = mngr.getViewInfo().customer;
		let mailconstants = null;
		// 新規追加
		if (cacheData.m_FlowModel.defaultFlg) {
			mailconstants = MAIL_CONSTANTS.find (mail => {
				return mail.view === '工事_予約';
			});
		}
		// 変更
		else if (cacheData.m_FlowModel.changeFlg) {
			mailconstants = MAIL_CONSTANTS.find (mail => {
				return mail.view === '工事_変更';
			});
		}
		// キャンセル
		else if (cacheData.m_FlowModel.cancelFlg) {
			mailconstants = MAIL_CONSTANTS.find (mail => {
				return mail.view === '工事_キャンセル';
			});
		}
		req.customerId = mngr.getParams().customerId;
		req.customerMailId = mailconstants.customerMail;
		req.systemMailId = mailconstants.systemMail;
		req.inquiryId = mailconstants.inquiryMail;
		req.systemInfo = new InquiryMailInfo();
		req.systemInfo.workScheduleId = cacheData.m_ConstrBaseModel.workScheduleList.workScheduleList.work_schedule_id;
		if (cacheData.m_FlowModel.cancelFlg) {
			req.systemInfo.desiredDate = DateUtil.convertToDateWithSlash(cacheData.m_ConstrBaseModel.workScheduleList.workScheduleList.schedule_date);
			req.systemInfo.selectTime1 = cacheData.m_ConstrBaseModel.workScheduleList.preferredTime;
			req.systemInfo.startTime = cacheData.m_ConstrBaseModel.workScheduleList.workScheduleList.schedule_start_time;
			req.systemInfo.endTime = cacheData.m_ConstrBaseModel.workScheduleList.workScheduleList.schedule_end_time;
		}
		else {
			req.systemInfo.desiredDate = DateUtil.convertToDateWithSlash(cacheData.m_ConstrBaseModel.workPossibleList.workPossibleList.possible_date);
			req.systemInfo.startTime = cacheData.m_ConstrBaseModel.workPossibleList.workPossibleList.possible_start_time;
			req.systemInfo.endTime = cacheData.m_ConstrBaseModel.workPossibleList.workPossibleList.possible_end_time;
			req.systemInfo.desiredDate1 = req.systemInfo.desiredDate;
			req.systemInfo.selectTime1 = cacheData.m_ConstrBaseModel.workPossibleList.preferredTime;
			req.systemInfo.beforeContact = cacheData.m_ConstrBaseModel.beforeContact;
			req.systemInfo.sameDayContact = cacheData.m_ConstrBaseModel.sameDayContact;
			req.systemInfo.witness = cacheData.m_ConstrBaseModel.name;
		}
		return req;
	}

	/**
	 * 画面ウィザードフローの中断判定：例：申し込みが完了していれば、完了済みの画面へ遷移
	 */
	public checkFlowBreak(mngr: MypageWizardMngr): string {
		let test: boolean = false;
		if (test) {
			return '/error';
		}
		return null;
	}

	/**
	 * 画面キャッシュの初期化データ取得
	 */
	public getInitCacheData(mngr: MypageWizardMngr): ConstructionWizardModel {
		let cacheData = new ConstructionWizardModel();
		cacheData.m_ConstrBaseModel = new ConstrBaseModel();
		cacheData.m_ConstrBaseModel.selectDateList = new Array();
		cacheData.m_ConstrBaseModel.searchDayOfWeek = new DayOfWeekModel();
		cacheData.m_ConstrAddListModel = new Array();
		cacheData.m_ConstrEditListModel = new Array();
		cacheData.m_ConstrDecisionListModel = new Array();
		cacheData.m_FlowModel = new ConstrFlowModel();
		return cacheData;
	}
	// --------------------------------------------------------------------------
}

/**
 * 画面ウィザードAPI用モデル
 */
export class ConstructionWizardModel {
	// public 変数	-----------------------------------------------------------
	public m_ConstrBaseModel: ConstrBaseModel						= null; 	// 工事基本情報モデル
	public m_ConstrAddListModel: ConstrDateListModel[]				= null; 	// 工事基本情報モデル(新規追加)
	public m_ConstrEditListModel: ConstrDateListModel[]				= null; 	// 工事基本情報モデル(変更)
	public m_ConstrDecisionListModel: ConstrDateListModel[]			= null; 	// 工事基本情報モデル(変更不可)
	public m_FlowModel: ConstrFlowModel 							= null;		// 工事画面遷移モデル
	// サービス  ----------------------------------------------------------------
}