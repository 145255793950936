/**
 * 利用明細PDFリクエスト
 */
export class GetUsagePDFRequest {
	// public 変数	-----------------------------------------------------------
	public customerId: string;	// お客様番号
	public customerName: string;	// お客様氏名
	public monthList: string[];	// 対象年月リスト
	// public listFlag: boolean;	// 一覧表示フラグ
	public templateType: string;	// テンプレートタイプ
	// ------------------------------------------------------------------------
}
