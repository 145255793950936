import React, { ReactNode } from 'react';
import { WprAuthoritySetter } from '../../authority/WprAuthoritySetter';
import { WprValueMapSetter } from '../../model/WprValueMapSetter';
import { WprScopeSetter } from '../../scope/WprScopeSetter';
import { WprConverterSetter } from '../../view/convert/WprConverterSetter';
import { WprValidatorSetter } from '../../view/validation/WprValidatorSetter';
import { WprFramework } from '../../WprFramework';
import { WprSettingInfo } from '../../WprSettingInfo';
import { WprWaitComponent } from './wait/WprWaitComponent';

/**
 * フレームワークコンポーネントステータス情報
 */
interface IWprFrameworkState {
	wait?: boolean;		// 待機表示状態
}

/**
 * フレームワークコンポーネント基本クラス
 */
export abstract class WprBaseFrameworkComponent extends React.Component<{}, IWprFrameworkState> {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: any) {
		super(props);
		this.state = {
			wait: false
		};
	}
	// --------------------------------------------------------------------------

	// abstract メソッド  --------------------------------------------------------
	/**
	 * WPRFramework設定処理
	 * @param setter 設定情報
	 */
	public abstract onSetting(settingInfo: WprSettingInfo): void;

	/**
	 * ValueMap設定処理
	 * @param vmSetter ValueMapセッター
	 */
	public abstract onRegisterValueMap(vmSetter: WprValueMapSetter): void;

	/**
	 * バリデータ設定処理
	 * @param validSetter ValueMapセッター
	 */
	public abstract onRegisterValidator(validSetter: WprValidatorSetter): void;

	/**
	 * コンバータ設定処理
	 * @param convertSetter ValueMapセッター
	 */
	public abstract onRegisterConverter(convertSetter: WprConverterSetter): void;

	/**
	 * スコープ情報登録
	 * @param scopeSetter スコープ情報セッター
	 */
	public abstract onRegisterScope(scopeSetter: WprScopeSetter): void;

	/**
	 * 権限設定処理
	 * @param authoritySetter 権限セッター
	 */
	public abstract onRegisterAuthority(authoritySetter: WprAuthoritySetter): void;
	// --------------------------------------------------------------------------

	// virtual メソッド  ---------------------------------------------------------
	/**
	 * モバイルフラグ取得
	 */
	public isMobile(): boolean {
		return undefined;
	}
	// --------------------------------------------------------------------------

	// public メソッド  ----------------------------------------------------------
	/**
	 * 待機表示状態設定
	 * @param wait 待機表示状態
	 */
	public setWait(wait: boolean): void {
		this.setState({
			wait : wait
		});
	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * 描画
	 */
	public render(): ReactNode {
		WprFramework.initialize(this);
		return <WprWaitComponent visibility={this.state.wait}/>;
	}
		
	/**
	 * マウント通知
	 */
	public componentDidMount() {
		WprFramework.getInstance().setComponent(this);
	}
	// --------------------------------------------------------------------------
}