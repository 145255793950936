import { WorkDetailList } from '../../../../models/iss/getVacantMypWorkSchedule/WorkDetailList';
import { TerminalList } from '../../../../models/iss/SetMypInstallTerminal/TerminalList';

/**
 * 端末登録リクエスト
 */
export class SetMypInstallTerminalRequest {
	// public 変数	-----------------------------------------------------------
	public customer_id: string;				// 
	public terminal_list: TerminalList[];	// 
	// ------------------------------------------------------------------------
}