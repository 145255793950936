import React, { ReactNode } from 'react';
import { WprBaseViewComponent } from '../../../../../wpr-framework/view/component/WprBaseViewComponent';
import { ItscomHomeReviseSelectView } from './ItscomHomeReviseSelectView';
import { Grid, Typography, TableCell, Table, TableBody, TableHead, TableRow, Divider } from '@material-ui/core';
import { ProgressBar } from '../../../common/progress_bar/ProgressBar';
import { MatCheckBox } from '../../../../../wpr-framework/component/controls/material/MatCheckBox';
import { MatTypography } from '../../../../../wpr-framework/component/controls/material/MatTypography';
import { MatTextField } from '../../../../../wpr-framework/component/controls/material/MatTextField';
import { WprRowInfo } from '../../../../../wpr-framework/component/props/WprRowInfo';
import { MatTableRow } from '../../../../../wpr-framework/component/controls/material/MatTableRow';
import { MatTableContainer } from '../../../../../wpr-framework/component/controls/material/MatTableContainer';
import { MatButton } from '../../../../../wpr-framework/component/controls/material/MatButton';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { WprContainer } from '../../../../../wpr-framework/component/controls/WprContainer';
import { WprSpan } from '../../../../../wpr-framework/component/controls/WprSpan';
import { WprDiv } from '../../../../../wpr-framework/component/controls/WprDiv';
import helpOrangeIcon from '/shared/image/common/icon_Help_bgLigntOrange.png';
import errorIcon from '/shared/image/common/icon_error.png';



/**
 * ITSCOMHOME_オプション変更(コース選択)用コンポーネント
 */
export class ItscomHomeReviseSelect extends WprBaseViewComponent {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: any) { super(new ItscomHomeReviseSelectView(), props); }
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** ITSCOMHOME_オプション変更(コース選択)ビュー情報 */
	public get viewInfo(): ItscomHomeReviseSelectView	{ return this.view as ItscomHomeReviseSelectView;	}
	// --------------------------------------------------------------------------

	// override メソッド  ---------------s-----------------------------------------
	/**
	 * リストコントロール定義登録
	*/
	protected onRegisterListRender(): void {
		this.addListRender('OptionListModel', 'PC', this.onOptionListPcModelRender);
		this.addListRender('OptionListModel', 'SP', this.onOptListSpModelRender);
		this.addListRender('ErrorModel', null, this.onErrorModelRender);
	}

	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		return (
			<>
			<div>
				<div className='pageCard'>
					<div className="pageHeader stepFlow">
						<ProgressBar name='progressBar' view={this.view}/>
					</div>
					<div className='main'>
						<div className='pageTitle'>
							<div className='title_left'>
								<MatTypography name='title' className='title_text' view={this.view}/>
								<Typography className='title_sentence'>ご希望の変更オプションを入力してください。</Typography>
							</div>
							<WprContainer mode='エラー.表示' view={this.view}>
								<div className='errorArea'>
									<span className='parallel'>
										<img src={errorIcon} alt='' className='x_img'/>
										<div className='errorList'>
											{this.renderList('ErrorModel')}
										</div>
									</span>
								</div>
							</WprContainer>
							<div className='title_maru' style={{marginTop:'41px'}}>基本情報</div>
							<div className='optioni_introduce'>
								<a href='https://www.itscom.co.jp/service/itscomhome/device.html' target='_blank' style={{ color: 'black', textDecoration: 'none' }}>
									<img src={helpOrangeIcon} style={{marginRight: '3px', verticalAlign: 'bottom', width: '20px' }}/>オプションのご紹介
								</a>
							</div>
						</div>
						<div className='constractTable'>
							<Grid container className='feeRow'>
								<Grid item xs={12} md={5} className='option'>
									<MatTypography name='course' className='courseName' view={this.view}/>
								</Grid>
								<Grid item xs={12} md={7} className='optstatus'>
									<MatTypography name='contractStatus' className='itemUnit_bold' view={this.view}/>
								</Grid>
							</Grid>	
						</div>
						<div className='deviceTable'>
							<Grid container className='deviceRow'>
								<Grid item xs={12} md={4} className='deviceTitle'>
									<div>端末タイプ</div>
								</Grid>
								<Grid item xs={12} md={8} className='deviceVal'>
									<MatTypography name='terminalType' className='itemUnit' view={this.view}/>
									<span>　</span>
									<MatTypography name='terminalForm' className='itemUnit' view={this.view}/>
								</Grid>
							</Grid>	
							<Grid container className='deviceRow'>
								<Grid item xs={12} md={4} className='deviceTitle'>
									<div>MACアドレス</div>
								</Grid>
								<Grid item xs={12} md={8} className='deviceVal'>
									<MatTypography name='MacAddress' className='itemUnit' view={this.view}/>
								</Grid>
							</Grid>	
						</div>
						<div className='title_maru' style={{marginTop:'30px'}}>オプション</div>
						<Grid container>
							<Grid item xs={12}>
								<MatTableContainer listName={'OptionListModel'} view={this.view}>
									{/* PC用 */}
									<WprContainer mode='オプション.PC' view={this.view}>
										<Table className = 'optionSelsctPcTable'>
											<colgroup>
												<col style={{width: '33%'}}/>
												<col style={{width: '23%'}}/>
												<col style={{width: '10%'}}/>
												<col style={{width: '20%'}}/>
												<col style={{width: '10%'}}/>
											</colgroup>
											<TableHead className='hedderrow'>
												<TableRow className='toptable'>
													<TableCell className='border_right font_bold'>オプション名</TableCell>
													<TableCell align='center' className='border_right font_bold mobile'>月額レンタル料(税込)</TableCell>
													<TableCell align='center' className='border_right font_bold mobile'>数量</TableCell>
													<TableCell align='center' className='border_right font_bold mobile'>購入価格(税込)</TableCell>
													<TableCell align='center' className='font_bold mobile'>数量</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												{this.renderList('OptionListModel', 'PC')}
												<WprContainer mode='オプション.なし' view={this.view}>
													<TableRow>
														<TableCell className='non_list' colSpan={5}>申込み可能なオプションはございません。</TableCell>
													</TableRow>
												</WprContainer>
											</TableBody>
										</Table>
									</WprContainer>
									{/* SP用 */}
									<WprContainer mode='オプション.SP' view={this.view}>
										<Table className = 'optionSelsctSpTable'> 
											<colgroup>
												<col style={{width: '55%'}}/>
												<col style={{width: '45%'}}/>
											</colgroup>
											<TableHead className='hedderrow'>
												<TableRow className='toptable'>
													<TableCell rowSpan={4} align='center' className='border_right font_bold'>オプション名</TableCell>
													<TableCell rowSpan={1} align='center' className='font_bold'>月額レンタル料(税込)</TableCell>
												</TableRow>
												<TableRow className='toptable'>
													<TableCell rowSpan={1} align='center' className='font_bold'>数量</TableCell>
												</TableRow>
												<TableRow className='toptable'>
													<TableCell rowSpan={1} align='center' className='font_bold'>購入価格(税込)</TableCell>
												</TableRow>
												<TableRow className='toptable'>
													<TableCell rowSpan={1} align='center' className='font_bold'>数量</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												{this.renderList('OptionListModel', 'SP')}
												<WprContainer mode='オプション.なし' view={this.view}>
													<TableRow>
														<TableCell className='non_list' colSpan={5}>申込み可能なオプションはございません。</TableCell>
													</TableRow>
												</WprContainer>
											</TableBody>
										</Table>
									</WprContainer>
								</MatTableContainer>
							</Grid>
						</Grid>
						<div className='attention_fontSize'>
							<ul className='ul_kome'>
								<WprContainer mode='注釈.OwnerIH' view={this.view}>
									<li className='ownerIh_kome'  style={{color: '#FF0000'}}>
										1 アパートメント契約のお客さまの料金については、ご連絡にてご案内致します。
									</li>
								</WprContainer>
								<li>
									表示されている月額は定価となります。実際の請求金額とは異なる場合があります。
								</li>
								<li>
									スマートライトオプションのみレンタルのほか購入も、お選びいただけます。
								</li>
								<li>
									各端末の月額利用料等については<a href='https://www.itscom.co.jp/info/covenant/gjslkq0000003us8-att/itscom_price.pdf' target = '_blank' className='link_color'>こちら</a>をご確認ください。
								</li>
								<li>
									スマートスピーカーオプションは家電コントローラーのレンタルが必須となります。
								</li>
							</ul>
						</div>
					</div>
					<Divider className='select2_underSpace'/>
					<div className='pageFooter footer-space'>
						<div>
							<MatButton name='next' view={this.view} className='orangeButton orangeButton_parts' >
								申込む
								<NavigateNextIcon />
							</MatButton>
						</div>
						<div>
							<MatButton name='back' view={this.view} className='backButton' >
								<NavigateBeforeIcon />
								戻る
							</MatButton>
						</div>
					</div>
				</div>
			</div>
			</>
		);
	}
	// --------------------------------------------------------------------------

	// リスト描画  ---------------------------------------------------------------
	/**
	 * リスト名=OptionListModel
	 */
	public onOptionListPcModelRender(rowInfo: WprRowInfo): any {
		return (
			<MatTableRow view={this.view} row={rowInfo} className='optionRow'>
				<TableCell className='border_right'>
					<MatTypography name='option' className = 'font_bold' view={this.view} row={rowInfo}/>
				</TableCell>
				<TableCell align ='center' className='border_right'>
					{/* オーナー契約IH */}
					<WprDiv name='ownerIhRental' view={this.view} row={rowInfo}>
						<Typography className='feeUnit'>-円<span className='ownerIh_kome'> ※1</span></Typography>
					</WprDiv>
					{/* その他 */}
					<WprDiv name='otherRental' view={this.view} row={rowInfo}>
						<MatTypography name='rental' className = 'font_bold' view={this.view} row={rowInfo}/>
						<span className='quanUnit_bold'>円</span>
					</WprDiv>
				</TableCell>
				<TableCell align='center' className='border_right optQuantity'>
					<div className = 'optionUnit'>
						<MatTextField name='quantity1' className = 'font_bold'view={this.view} row={rowInfo}/>
						<MatTypography name='rentalUnit' className='feeUnit' view={this.view} row={rowInfo}/>
					</div>
				</TableCell>
				<TableCell align ='center' className='border_right'>
					{/* オーナー契約IH */}
					<WprDiv name='ownerIhPrice' view={this.view} row={rowInfo}>
						<Typography className='feeUnit'>-円<span className='ownerIh_kome'> ※1</span></Typography>
					</WprDiv>
					{/* その他 */}
					<WprDiv name='otherPrice' view={this.view} row={rowInfo}>
						<MatTypography name='price' className = 'font_bold' view={this.view} row={rowInfo}/>
						<MatTypography name='priceUnit' className='feeUnit' view={this.view} row={rowInfo}/>
					</WprDiv>
				</TableCell>
				<TableCell align='center' className='optQuantity'>
					{/* 購入可能 */}
					<WprDiv name='buyableUnit' view={this.view} row={rowInfo}>
						<div className = 'optionUnit'>
							<MatTextField name='quantity2' className = 'font_bold' view={this.view} row={rowInfo}/>
							<MatTypography name='buyUnit' className='feeUnit'view={this.view} row={rowInfo}/>
						</div>
					</WprDiv>
					{/* 購入不可 */}
					<WprDiv name='notBuyableUnit' view={this.view} row={rowInfo}>
						<Typography className='optHyp'>-</Typography>
					</WprDiv>
				</TableCell>
			</MatTableRow>
		);
	}

		/**
	 * リスト名=OptListModel
	 * SP用
	 */
	public onOptListSpModelRender(rowInfo: WprRowInfo): any {
		return (
			<>
				<TableRow>
					<TableCell className='border_right' align='center' rowSpan ={4}> 
						<MatTypography name='option' className='font_bold' view={this.view} row={rowInfo}/>
					</TableCell>
					<TableCell align='center' rowSpan ={1}>
						{/* オーナー契約IH */} 
						<WprDiv name='ownerIhRental' view={this.view} row={rowInfo}>
							<Typography className='feeUnit'>-円<span className='ownerIh_kome'> ※1</span></Typography>
						</WprDiv> 
						{/* その他 */}
						<WprDiv name='otherRental' view={this.view} row={rowInfo}>
							<MatTypography name='rental' className='font_bold' view={this.view} row={rowInfo}/>
							<span className='feeUnit'>円</span>
						</WprDiv>
					</TableCell>
				</TableRow>
				<TableRow className='optionRow'>
					<TableCell align='center' rowSpan ={1}>
						<div className = 'optionUnit'>
							<MatTextField name='quantity1' className='font_bold' view={this.view} row={rowInfo}/>
							<MatTypography name='rentalUnit' className='feeUnit' view={this.view} row={rowInfo}/>
						</div>
					</TableCell>
				</TableRow>
				<TableRow>
					<TableCell align='center' rowSpan ={1}>
						{/* オーナー契約IH */}
						<WprDiv name='ownerIhPrice' view={this.view} row={rowInfo}>
							<Typography className='feeUnit'>-円<span className='ownerIh_kome'> ※1</span></Typography>
						</WprDiv>
						{/* その他 */}
						<WprDiv name='otherPrice' view={this.view} row={rowInfo}>
							<MatTypography name='price' className='font_bold' view={this.view} row={rowInfo}/>
							<MatTypography name='priceUnit' className='feeUnit' view={this.view} row={rowInfo}/>
						</WprDiv>
					</TableCell>
				</TableRow>
				<TableRow className='optionRow'>
					<TableCell align='center' rowSpan ={1}>
						{/* 購入可能 */}
						<WprDiv name='buyableUnit' view={this.view} row={rowInfo}>
							<div className = 'optionUnit'>
								<MatTextField name='quantity2' className='font_bold' view={this.view} row={rowInfo}/>
								<MatTypography name='buyUnit' className='feeUnit' view={this.view} row={rowInfo}/>
							</div>
						</WprDiv>
						{/* 購入不可 */}
						<WprDiv name='notBuyableUnit' view={this.view} row={rowInfo} >
							<Typography className='optHyp'>-</Typography>
						</WprDiv>
					</TableCell>
				</TableRow>
			</>
		);
	}

	/**
	 * リスト名=ErrorModel
	 */
	public onErrorModelRender(rowInfo: WprRowInfo): any {
		return (
			<div>
				<MatTypography name='errorMessage' view={this.view} row={rowInfo}/>
			</div>
		);
	}
	// --------------------------------------------------------------------------
}
