// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@charset \"UTF-8\";\n/*\r\n * テレビ変更選択画面用CSS\r\n */\n.ModTvSelect1-tableSP {\n  border: 1px solid #E1E4ED;\n  border-radius: 12px;\n  margin-top: 13px;\n  margin-bottom: 35px;\n  border-collapse: separate !important; }\n  .ModTvSelect1-tableSP p {\n    min-height: 15px; }\n  .ModTvSelect1-tableSP td:has(.ModTvSelect1-borderBottom) {\n    border-bottom: none; }\n  .ModTvSelect1-tableSP td .ModTvSelect1-borderBottom {\n    min-height: 15px; }\n", ""]);
// Exports
exports.locals = {
	"tableSP": "ModTvSelect1-tableSP",
	"borderBottom": "ModTvSelect1-borderBottom"
};
module.exports = exports;
