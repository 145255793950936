import { MypageBaseView } from '../../../base/MypageBaseView';
import { WprModel } from '../../../../../wpr-framework/view/deco/WprModel';
import { WprDI_ScopeModel } from '../../../../../wpr-framework/view/di/WprDI_ScopeModel';
import { HMvnoTmpSaved } from '../../../../service/models/entity/HMvnoTmpSaved';
import { MvnoEntryInfo } from '../../../../service/models/apimodels/MvnoEntryInfo';
import { WprViewMode } from '../../../../../wpr-framework/view/deco/WprViewMode';
import { NextButtonMode } from './mode/NextButtonMode';
import { MvnoService } from '../../../../service/MvnoService';
import { MGeneral } from '../../../../service/models/entity/MGeneral';
import { WprListData } from '../../../../../wpr-framework/view/deco/WprListData';
import { MypageLinkConstatns } from '../../../../common/constants/MypageLinkConstatns';

import { ProgressBarModel, ProgressBarStopModel } from '../../../../models/ProgressBarModel';
import { ProgressBarData } from '../../../common/progress_bar/ProgressBarView';
import { GetPreConfirmListRequest } from '../../../../service/models/dto/mvno/GetPreConfirmListRequest';
import { MvnoBaseView } from '../../../base/MvnoBaseView';
/**
 * MVNO申込_事前確認事項
 */
export class MvnoEntryPreConfirmView extends MvnoBaseView {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() { super('MvnoEntryPreConfirm'); }
	// --------------------------------------------------------------------------

	// モデル  ------------------------------------------------------------------
	// @WprModel('MvnoEntryInfo')
	@WprDI_ScopeModel('MvnoEntryInfo')
	private m_EntryInfo: MvnoEntryInfo = null;	// MVNOプラン情報
	@WprModel('confirm')
	private m_confirm: boolean = null;	// 確認
	@WprModel('confirm1')
	private m_confir1: boolean = null;	// 確認
	@WprModel('confirm2')
	private m_confirm2: boolean = null;	// 確認
	@WprModel('confirm3')
	private m_confirm3: boolean = null;	// 確認
	@WprListData('ImportantExplainList')
	private m_ImportantExplainList: MGeneral[] = null;	// 事前確認事項：「約款」・「利用規約」リスト
	@WprListData('ImportDocList')
	private m_ImportDocList: MGeneral[] = null;	// 事前確認事項：「約款」・「利用規約」リスト


	// private m_importantDoc: boolean = null;	// 確認
	// public get importantDoc(): boolean { return this.m_importantDoc }

	private m_flg1: boolean = null;
	private m_flg2: boolean = null;

	private m_openFLg: boolean = null;

	private m_importantDocLink1: string = null;
	private m_importantDocLink2: string = null;	// iPhone用約款リンク
	// --------------------------------------------------------------------------

	// サービス  ----------------------------------------------------------------
	private m_MvnoService: MvnoService = new MvnoService();	// MyPageRenewalサービス
	// --------------------------------------------------------------------------
	// サブビュー/モード  ---------------------------------------------------------
	@WprViewMode
	private m_NextButtonMode = new NextButtonMode();	// 次へボタン用モード
	// --------------------------------------------------------------------------
	// override メソッド  --------------------------------------------------------
	/**
	 * コントロール定義設定
	 */
	public onRegisterConfig(): void {
		this.addConfig('c_confirmList', '確認', 'confirm', { bindModel: true });
		this.addConfig('c_confirm1', '確認', 'confirm1', { bindModel: true });
		this.addConfig('c_confirm2', '確認', 'confirm2', { bindModel: true });
		this.addConfig('c_confirm3', '確認', 'confirm3', { bindModel: true });
	}

	/**
	 * アクション登録
	 */
	public onRegisterAction(): void {
		this.addAction('next', this.onNext);
		this.addAction('back', this.onBack);
		this.addAction('b_importantDoc1', this.onB_OpenConfirm1);
		// iPhone用約款
		this.addAction('b_importantDoc2', this.onB_OpenConfirm2);
		this.addAction('importantDoc2', this.onB_OpenConfirm3);
		this.addListAction('importantDocLink', 'ImportDocList', this.onImportantDocLink);
		this.addAction('helpLink', this.onHelpLink);
		// this.addListAction('importantDocLink', 'ImportDocList' , this.onB_OpenConfirm2);
	}

	/**
	 * ビュー表示通知
	 */
	public onShowView(): void {
		this.getCookieValListForMvno(this.m_EntryInfo);
		this.registAccessLog();
		this.setStorageItem('b_url', window.location.href);
		this.getPreConfirmList();
		const sendData: ProgressBarData = new ProgressBarData();
		sendData.progressBarList = this.getProgressModelList();

		this.setViewMode('重説', '未確認');
		this.setViewMode('iPhone重説', '未確認');
		this.setViewMode('約款', '閉');
		this.sendChildData('fP_ev_progressBarIntital', sendData);
	}

	/**
	 * アコーディオンモード変更通知
	 * @param mode アコーディオンモード
	 * @param oldLabel 古いラベル
	 * @param newLabel 新しいラベル
	 */
	public onChangeAccordionMode(mode: string, oldName: string, newName: string): void {
		if (mode = '重要説明事項2') {
			this.m_openFLg = !this.m_openFLg;
			this.setViewMode('約款', this.m_openFLg ? '開' : '閉');
			// this.setViewMode();
		}
	}
	// --------------------------------------------------------------------------

	// アクション  ---------------------------------------------------------------
	/**
	 * 次へ
	 */
	public onNext(param: any): void {
		this.refreshModel();
		this.pushPath('/mvno_008');
	}

	/**
	 * 戻る
	 */
	public onBack(param: any): void {
		this.refreshModel();
		this.pushPath('/mvno_006');
	}

	/**
	 * イッツコムサービス重要説明事項/各種約款・規約
	 */
	public onB_OpenConfirm1(param: any): void {
		// this.m_importantDoc = true;
		this.setViewMode('重説', '確認済');
		window.open(this.m_importantDocLink1);
	}

	/**
	 * Certified端末保証利用規約
	 */
	public onB_OpenConfirm2(param: any): void {
		// this.m_importantDoc = true;
		this.setViewMode('iPhone重説', '確認済');
		// DBのdeleteFlg戻したら戻す
		// window.open(this.m_importantDocLink2);
		window.open(this.m_importantDocLink2);
	}

	/**
	 * イッツコムサービス契約約款
	 */
	public onB_OpenConfirm3(param: any): void {
		this.m_openFLg = !this.m_openFLg;
		this.setViewMode('約款', this.m_openFLg ? '開' : '閉');
	}

	/**
	 * {label}
	 */
	public onImportantDocLink(param: any, item: MGeneral): void {
		window.open(item.text1);
	}


	/**
	 * 問い合わせリンク
	 */
	public onHelpLink(param: any): void {
		window.open(MypageLinkConstatns.ITSCOM_MVNOENTRY_HELP);
	}
	// --------------------------------------------------------------------------
	/**
	 * 
	 */
	private getPreConfirmList(): void {
		const req: GetPreConfirmListRequest = new GetPreConfirmListRequest();
		req.customerId = this.getCustomerId();
		this.m_MvnoService.getPreConfirmList(this, req, (result) => {
			// this.m_importantDocLink = result.importantDoc.name2;
			this.m_ImportantExplainList = result.importantExplainList;
			for (const link of this.m_ImportantExplainList) {
				if (!link.flag2) {
					this.m_importantDocLink1 = link.name2;
				} else {
					this.m_importantDocLink2 = link.name2;
				}
			}
			this.m_ImportDocList = result.importantDocList;
			this.setListData('ImportDocList', this.m_ImportDocList);

			this.setViewMode('プラン', this.m_EntryInfo.entryInfo.plan.includes('音声') ? '音声' : 'データ');
			this.setViewMode('端末申込', this.m_EntryInfo.entryInfo.terminalUseFlg ? '有' : '無');
			if (this.m_EntryInfo.entryInfo.terminalUseFlg) {
				if (this.m_EntryInfo.iPhoneFlg) {
					this.setViewMode('端末','iPhone');
				} else {
					this.setViewMode('端末','Android');
				}
			} else {
				this.setViewMode('端末','SIMのみ');
			} 
			// this.setViewMode('端末', this.m_EntryInfo.iPhoneFlg ? 'iPhone' : 'Android');
			this.refreshView();
		});
	}

	protected getProgressModelList(): ProgressBarModel[] {
		const list: ProgressBarModel[] = new Array();

		list.push(new ProgressBarModel('1', 'お申し込み手続き', 'complete', this.getStopModelList()));
		list.push(new ProgressBarModel('2', '合計金額ご確認', 'complete', null));
		list.push(new ProgressBarModel('3', '事前確認事項', 'current', null));
		list.push(new ProgressBarModel('4', 'WEB本人確認', 'next', null));
		list.push(new ProgressBarModel('5', 'お申込み完了', 'not', null));
		return list;
	}

	private getStopModelList(): ProgressBarStopModel[] {
		const list: ProgressBarStopModel[] = new Array();
		list.push(new ProgressBarStopModel('complete'));
		list.push(new ProgressBarStopModel('complete'));
		list.push(new ProgressBarStopModel('complete'));
		list.push(new ProgressBarStopModel('complete'));
		return list;
	}
}
