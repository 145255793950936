import { MvnoMnpInfo } from '../../apimodels/MvnoMnpInfo';
import { MvnoCapacityChangeInfo } from '../../apimodels/MvnoCapacityChangeInfo';
import { MvnoEntryInfo } from '../../apimodels/MvnoEntryInfo';
import { MvnoCouponInfo } from '../../apimodels/MvnoCouponInfo';

/**
 * MVNOお問い合わせ作成リクエスト
 */
export class CreateMvnoInquiryRequest {
	// public 変数	-----------------------------------------------------------
	public mnpInfo: MvnoMnpInfo;				// 
	public changeInfo: MvnoCapacityChangeInfo;	// 
	public customerId: string;					// 顧客ID
	public contractCd: string;					// 
	public mvnoEntryInfo: MvnoEntryInfo;		// MVNO申込情報
	public mvnoInquiryType: string;				// 
	public couponInfo: MvnoCouponInfo;			// 
	// ------------------------------------------------------------------------
}
