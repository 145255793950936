import { Checkbox, FormControlLabel } from '@material-ui/core';
import React, { ReactNode } from 'react';
import { IWprInputControlProps } from '../../props/IWprControlProps';
import { WprControlVisibility } from '../../props/IWprControlState';
import { WprBaseInputControlComponent } from '../WprBaseInputControlComponent';

/**
 * チェックボックスプロパティ情報
 */
interface IMatCheckBoxProps extends IWprInputControlProps {
	label?: string
	color?: 'primary' | 'secondary' | 'default';	// 色
}

/**
 * チェックボックスコントロール
 */
export class MatCheckBox extends WprBaseInputControlComponent<IMatCheckBoxProps, HTMLInputElement> {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: IMatCheckBoxProps) {
		super(props);
		this.setChangeValueEvent(this.onChangeValue.bind(this));
	}
	// --------------------------------------------------------------------------

	// イベント  ----------------------------------------------------------------
	/**
	 * 値変更処理
	 * @param event 変更イベント 
	 */
	public onChangeValue(event: React.ChangeEvent<HTMLInputElement>): void {
		const value = event.target.checked;
		// const name = event.target.name;
		if (this.controlInfo != null) {
			this.addChangeValueEventLog(event.target, value);
			this.changeValue(value);
		}
	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		if (this.state.visibility === WprControlVisibility.COLLAPSED)
			return <></>;
		const props = this.getProps('mat-checkbox');
		const {name, view, className, label, children, ...other} = this.props;
		let clsName = this.className('mat-checkbox');
		const state = this.getState(['style', 'disabled', 'readOnly']);
		let events = this.getEvents();
		const options = this.getOptions();
		let checkBox = null;
		if (state.readOnly === true && state.disabled === false) {
			const {onChange, ...newEvents} = events;
			events = newEvents;
		}
		if (this.state.visibility === WprControlVisibility.VISIBLE) {
			checkBox = <Checkbox {...props} {...state} {...other} {...options} {...events} inputRef={this.ref}/>;
		}
		else {
			state['style'] = {...this.state.style, visibility: 'hidden'};
			checkBox = <Checkbox {...props} {...state} {...options} {...other} inputRef={this.ref}/>;
		}
		if (this.props.label) {
			if (this.state.visibility === WprControlVisibility.VISIBLE)
				checkBox = <FormControlLabel className='wpr-checkbox-label' control={checkBox} label={this.props.label}/>;
			else
				checkBox = <FormControlLabel className='mat-checkbox-label' control={checkBox} label={this.props.label} style={{visibility: 'hidden'}}/>;
		}
		return (
			<>
				{checkBox}
			</>
		);
	}

	/**
	 * プロパティ情報取得
	 * @param cls オリジナルクラス
	 * @returns プロパティ情報
	 */
	protected getProps(cls?: string): any {
		const rtn: any = {};
		rtn['name'] = this.getName();
		rtn['className'] = this.className(cls);
		const value = this.getValue();
		rtn['checked'] = false;
		if (value !== undefined) {
			if (value === true)
				rtn['checked'] = true;
		}
		return rtn;
	}
	// --------------------------------------------------------------------------

	// private メソッド  ---------------------------------------------------------
	/**
	 * オプション情報取得
	 * @returns オプション情報
	 */
	private getOptions(): any {
		const rtn: any = {};
		if (this.props.color)
			rtn['color'] = this.props.color;
		else
			rtn['color'] = 'primary';
		return rtn;
	}
	// --------------------------------------------------------------------------
}