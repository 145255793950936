import React, { ReactNode } from 'react';
import { IWprControlProps } from '../props/IWprControlProps';
import { WprControlVisibility } from '../props/IWprControlState';
import { WprBaseInputControlComponent } from './WprBaseInputControlComponent';

/**
 * 入力コントロールプロパティ情報
 */
interface IWprInputProps extends IWprControlProps {
	type?: string;				// タイプ
	placeholder?: string;		// placeholder
	title?: string;				// タイトル
	converter?: string;			// コンバータ
	multiple?: boolean;			// 複数選択
	inputmode?: 'none' | 'text' | 'tel' | 'url' | 'email' | 'numeric' | 'decimal' | 'search';	// 入力モード
}

/**
 * 入力コントロール
 */
export class WprInput extends WprBaseInputControlComponent<IWprInputProps, HTMLInputElement> {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: IWprInputProps) {
		super(props);
		this.setChangeValueEvent(this.onChangeValue.bind(this));
		this.setKeyEvent();
	}
	// --------------------------------------------------------------------------

	// イベント  ----------------------------------------------------------------
	/**
	 * 値変更処理
	 * @param event 変更イベント 
	 */
	public onChangeValue(event: React.ChangeEvent<HTMLInputElement>): void {
		// console.info(`Change = ${this.m_SelectionStart}`);
		let value: any = event.target.value;
		if (this.props.type === 'file')
			value = this.ref.current.files;
		if (this.controlInfo != null) {
			this.addChangeValueEventLog(event.target, value);
			this.changeValue(value);
		}
	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * 値取得
	 * @returns 値
	 */
	protected getValue(): any {
		if (this.state.value == null)
			return '';
		return this.state.value;
	}

	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		if (this.state.visibility === WprControlVisibility.COLLAPSED)
			return <></>;
		const props = this.getProps();
		const {name, view, children, className, placeholder, autoComplete, ...other} = this.props;
		const state = this.getState(['style', 'disabled', 'readOnly', 'min', 'max', 'minLength', 'maxLength']);
		const events = this.getEvents();
		const pholder = this.getPlaceholder();
		const aComplete = this.getAutoComplete();
		let input = null;
		if (this.state.visibility === WprControlVisibility.VISIBLE) {
			if (this.state.readOnly == true)
				props.value = this.getViewValue(this.props.converter, this.props.row);
			input = <input {...props} {...state} {...other} {...events} {...pholder} {...aComplete} ref={this.ref}/>;
		}
		else {
			state['style'] = {...this.state.style, visibility: 'hidden'};
			input = <input {...props} {...state} {...other} ref={this.ref}/>;
		}
		return (
			<>
				{input}
			</>
		);
	}

	/**
	 * プロパティ情報取得
	 * @param cls オリジナルクラス
	 * @returns プロパティ情報
	 */
	 protected getProps(cls?: string, setClassName: boolean = true): any {
		let props = super.getProps(cls, setClassName);
		if (this.props.type === 'file')
			props.value = undefined;
		return props;
	}
	// --------------------------------------------------------------------------

	// private メソッド  ---------------------------------------------------------
	/**
	 * placeholder情報取得
	 * @returns placeholder情報
	 */
	private getPlaceholder(): any {
		const rtn: any = {};
		if (this.state.placeholder)
			rtn['placeholder'] = this.state.placeholder;
		if (this.props.placeholder)
			rtn['placeholder'] = this.props.placeholder;
		return rtn;
	}

	/**
	 * autoComplete情報取得
	 * @returns autoComplete情報
	 */
	private getAutoComplete() {
		const rtn: any = {};
		if (this.props.autoComplete)
			rtn['autoComplete'] = this.props.autoComplete;
		else
			rtn['autoComplete'] = 'on';
		return rtn;
	}
	// --------------------------------------------------------------------------
}