import { MypageBaseView } from '../../../base/MypageBaseView';
import { WprModel } from '../../../../../wpr-framework/view/deco/WprModel';
import { TvCourseModel } from '../../../../models/mypage/tv/TvCourseModel';
import { WprListData } from '../../../../../wpr-framework/view/deco/WprListData';
import { TvCourseListModel } from '../../../../models/mypage/tv/TvCourseListModel';
import { WprViewMode } from '../../../../../wpr-framework/view/deco/WprViewMode';
import { TvCourseMode } from './mode/TvCourseMode';

/**
 * テレビ_コースパーツ
 */
export class TvCourseListPartsView extends MypageBaseView {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() { super('TvCourseListPartsView'); }
	// --------------------------------------------------------------------------

	// モデル  ------------------------------------------------------------------
	@WprModel('TvCourseModel')
	private m_TvCourseModel: TvCourseModel	= null;	// テレビ_コースモデル
	@WprListData('TvCourseListModel')
	private m_TvCourseListModel: TvCourseListModel[]	= null;	// テレビ_コースリストモデル
	
	// --------------------------------------------------------------------------

	// サブビュー/モード  --------------------------------------------------------
	@WprViewMode
	private m_TvCourseMode = new TvCourseMode();	// TvCourseMode
	// --------------------------------------------------------------------------

	private m_Row: TvCourseListModel = null;

	// override メソッド  --------------------------------------------------------
	/**
	 * コントロール定義設定
	 */
	public onRegisterConfig(): void {
		this.addListConfig('select', '選択', 'TvCourseListModel');
		this.addListConfig('terminal', '機器', 'TvCourseListModel');
		this.addListConfig('amount', '金額', 'TvCourseListModel', { converter: '3桁カンマ' });
		this.addConfig('course', 'コース名', 'TvCourseModel');
	}

	/**
	 * リスト値変更通知
	 * @param name 名前
	 * @param listName リスト名
	 * @param row 行データ
	 * @param value 値
	 * @returns falseの場合、変更を取り消す
	 */
	public onChangeListValue(name: string, listName: string, row: any, value: any): boolean {
		let flg = true;
		if (!row.select) {
			this.m_Row = row;
			this.sendParentData('checkData', true);
			this.refreshModel();
		}
		else 
			flg = false;
		return flg;
	}

	/**
	 * データ受信処理
	 * @param dataName データ名
	 * @param data データ
	 */
	public onReceiveData(dataName: string, data?: any): void {
		if (dataName === 'serviceList') {
			const receiveData: TvCourseModel = data;
			this.m_TvCourseModel = receiveData;
			this.m_TvCourseListModel = receiveData.detailList;
			this.setViewData('sentenceData', this.m_TvCourseListModel.length + 1);
			this.setListData('TvCourseListModel', this.m_TvCourseListModel);
			//画面サイズによるレイアウトの設定
			if (window.outerWidth > 440) {
				this.setViewMode('オプション','PC');
			}
			else {
				this.setViewMode('オプション','SP');
			}
			this.refreshView();
		}
		if (dataName === 'checkBox') {
			for (const model of this.m_TvCourseListModel) {
				if (this.m_Row && this.m_Row.terminal === model.terminal) {
					model.select = true;
					this.m_Row = null;
				}
				else
					model.select = false;
			}
			this.refreshView();
		}
	}
	// --------------------------------------------------------------------------
}
