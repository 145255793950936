import { MypageBaseView } from '../../../base/MypageBaseView';
import { WprModel } from '../../../../../wpr-framework/view/deco/WprModel';
import { WprDI_ScopeModel } from '../../../../../wpr-framework/view/di/WprDI_ScopeModel';
import { HMvnoTmpSaved } from '../../../../service/models/entity/HMvnoTmpSaved';
import { MvnoEntryInfo } from '../../../../service/models/apimodels/MvnoEntryInfo';
import { MvnoBaseView } from '../../../base/MvnoBaseView';
import { MypageLinkConstatns } from '../../../../common/constants/MypageLinkConstatns';

import { ProgressBarModel, ProgressBarStopModel } from '../../../../models/ProgressBarModel';
import { ProgressBarData } from '../../../common/progress_bar/ProgressBarView';


/**
 * MVNO申込プラン選択
 */
export class MvnoEntryPlan1View extends MvnoBaseView {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() { super('MvnoEntryPlan1'); }
	// --------------------------------------------------------------------------

	// モデル  ------------------------------------------------------------------
	@WprDI_ScopeModel('MvnoEntryInfo')
	@WprModel('MvnoEntryInfo')
	private m_EntryInfo: MvnoEntryInfo = null;	// MVNOプラン情報
	// --------------------------------------------------------------------------

	// サービス  ----------------------------------------------------------------
	// private m_MvnoService: MvnoService	= new MvnoService();	// MyPageRenewalサービス
	// --------------------------------------------------------------------------

	// サブビュー/モード  ---------------------------------------------------------
	// --------------------------------------------------------------------------

	// サブビュー/モード  ---------------------------------------------------------

	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * コントロール定義設定
	 */
	public onRegisterConfig(): void {
		this.addConfig('plan', 'プラン名', 'MvnoEntryInfo.entryInfo');
		// this.addConfig('entryTerminalFlg', '端末申込', 'MvnoEntryInfo.entryInfo');
	}

	/**
	 * アクション登録
	 */
	public onRegisterAction(): void {
		this.addAction('next', this.onNext);
		this.addAction('dataPlanAnchor', this.onDataPlanAnchor);
		this.addAction('helpLink', this.onHelpLink);
	}

	/**
	 * ビュー表示通知
	 */
	public onShowView(): void {
		this.registAccessLog();
			this.setStorageItem('b_url', window.location.href)
		this.getCookieValListForMvno(this.m_EntryInfo);
		this.m_EntryInfo.entryInfo.plan = '音声プラン';
		this.refreshView();
		const sendData: ProgressBarData = new ProgressBarData();
		sendData.progressBarList = this.getProgressModelList();
		this.sendChildData('fP_ev_progressBarIntital', sendData);
	}
	protected getProgressModelList(): ProgressBarModel[] {
		const list: ProgressBarModel[] = new Array();

		list.push(new ProgressBarModel('1', 'お申し込み手続き', 'complete', this.getStopModelList()));
		list.push(new ProgressBarModel('2', '合計金額ご確認', 'not', null));
		list.push(new ProgressBarModel('3', '事前確認事項', 'not', null));
		list.push(new ProgressBarModel('4', 'WEB本人確認', 'not', null));
		list.push(new ProgressBarModel('5', 'お申込み完了', 'not', null));
		return list;
	}

	private getStopModelList(): ProgressBarStopModel[] {
		const list: ProgressBarStopModel[] = new Array();
		list.push(new ProgressBarStopModel('next'));
		list.push(new ProgressBarStopModel('not'));
		list.push(new ProgressBarStopModel('not'));
		list.push(new ProgressBarStopModel('not'));
		return list;
	}
	/**
	 * データ受信処理
	 * @param dataName データ名
	 * @param data データ
	 */
	public onReceiveData(dataName: string, data?: any): void {
		if (dataName === 'fC_ev_料金明細表示') {
			this.refreshModel();
			// this.sendChildFortempSave('プラン選択');
			this.sendChildFortempSave();
		}
	}
	// --------------------------------------------------------------------------

	// アクション  ---------------------------------------------------------------
	/**
	 * 次へ
	 */
	public onNext(param: any): void {
		if (this.getCookie('plan') !== '音声プラン') {
			this.resetValueCommon();
		}
		this.setCookieValForMvno(this.m_EntryInfo);
		this.pushPath('/mvno_003');
	}

	/**
	 * データプラン
	 */
	public onDataPlanAnchor(param: any): void {
		if (this.getCookie('plan') !== 'データプラン') {
			this.resetValueCommon();

			// 音声コース
			this.m_EntryInfo.entryInfo.course = null;
			this.m_EntryInfo.entryInfo.courseServiceCd = null;
			
			// 留守番電話オプション
			this.m_EntryInfo.entryInfo.rusubanUseFlg = null;
			this.m_EntryInfo.entryInfo.rusubanServiceCd = null;
			
			// 初期設定サポート希望日
			this.m_EntryInfo.entryInfo.initialSupportFlg = null;
			this.m_EntryInfo.entryInfo.reqDay1 = null;
			this.m_EntryInfo.entryInfo.reqTime1 = null;
			this.m_EntryInfo.entryInfo.reqDay2 = null;
			this.m_EntryInfo.entryInfo.reqTime2 = null;
			this.m_EntryInfo.entryInfo.reqDay3 = null;
			this.m_EntryInfo.entryInfo.reqTime3 = null;

			// WEBフィルタリング
			this.m_EntryInfo.entryInfo.webFilteringFlg = null;
			this.m_EntryInfo.entryInfo.webFilteringServiceCd = null;

		}
		this.m_EntryInfo.entryInfo.plan = 'データプラン';
		this.setCookieValForMvno(this.m_EntryInfo);
		this.pushPath('/mvno_003');
	}

	/**
	 * 問い合わせリンク
	 */
	public onHelpLink(param: any): void {
		window.open(MypageLinkConstatns.ITSCOM_MVNOENTRY_HELP);
	}
	// --------------------------------------------------------------------------
	/**
	 * プラン変更時　値初期化（共通の値）
	 */
	private resetValueCommon(): void {
		// 容量
		this.m_EntryInfo.entryInfo.capacityServiceCd = null;
		this.m_EntryInfo.entryInfo.capacity = null;

		// 端末、カラー
		this.m_EntryInfo.entryInfo.terminalId = null;
		this.m_EntryInfo.entryInfo.terminal = null;
		this.m_EntryInfo.entryInfo.terminalColorId = null;
		this.m_EntryInfo.entryInfo.terminalColor = null;
		// 端末オプション
		this.m_EntryInfo.entryInfo.simOption = null;

		// 採番方法
		this.m_EntryInfo.entryInfo.numberingWay = null;
		
		
		// 合計
		this.m_EntryInfo.total = '-';
	}
}
