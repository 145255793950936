import { WprListData } from '../../../../../wpr-framework/view/deco/WprListData';
import { WprModel } from '../../../../../wpr-framework/view/deco/WprModel';
import { WprDI_ScopeModel } from '../../../../../wpr-framework/view/di/WprDI_ScopeModel';
import { ObjectUtil } from '../../../../common/util/ObjectUtil';
import { HMvnoTmpSaved } from '../../../../service/models/entity/HMvnoTmpSaved';
import { MvnoTerminalModel } from '../../../../service/models/apimodels/MvnoTerminalModel';
import { GetTerminalStockRequest } from '../../../../service/models/dto/mvno/GetTerminalStockRequest';
import { MvnoService } from '../../../../service/MvnoService';
import { MypageBaseView } from '../../../base/MypageBaseView';
import { MvnoEntryInfo } from '../../../../service/models/apimodels/MvnoEntryInfo';
import { MvnoEntryFooterReceiveData } from '../mvno_entry_footer/MvnoEntryFooterView';
import { WprViewMode } from '../../../../../wpr-framework/view/deco/WprViewMode';
import { NextButtonMode } from './mode/NextButtonMode';
import { MvnoBaseView } from '../../../base/MvnoBaseView';
import { MvnoHelpDialogData } from '../../dialog/mvno_help_dialog/MvnoHelpDialogView';
import { GetPaymentSimulationRequest } from '../../../../service/models/dto/mvno/GetPaymentSimulationRequest';
import { MypageLinkConstatns } from '../../../../common/constants/MypageLinkConstatns';
import { ProgressBarModel, ProgressBarStopModel } from '../../../../models/ProgressBarModel';
import { ProgressBarData } from '../../../common/progress_bar/ProgressBarView';
import { MGeneral } from '../../../../service/models/entity/MGeneral';
import { ThreeSixty } from '@material-ui/icons';
import { MvnoTerminalColorModel } from '../../../../service/models/apimodels/MvnoTerminalColorModel';
/**
 * MVNO申込_端末・SIM
 */
export class MvnoEntryPlan3View extends MvnoBaseView {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() { super('MvnoEntryPlan3'); }
	// --------------------------------------------------------------------------

	// モデル  ------------------------------------------------------------------
	@WprDI_ScopeModel('MvnoEntryInfo')
	@WprModel('MvnoEntryInfo')
	private m_EntryInfo: MvnoEntryInfo = null;	// MVNOプラン情報

	@WprDI_ScopeModel('TerminalRadioList')
	@WprListData('TerminalRadioList')
	@WprListData('TerminalColorList')
	private m_TerminalRadioList: MvnoTerminalModel[] = null;	// 端末ラジオ選択肢リスト

	// @WprDI_ScopeModel('WebFiltering')
	// private m_WebFiltering: MGeneral = null;
	// -----------------------------------------
	@WprDI_ScopeModel('WebFiltering')
	private m_WebFilteringList: MGeneral[] = null;	// Webフィルタリングリスト
	@WprModel('WebfilteringCd')
	private m_webfilteringCd: string	= null;	// ラジオボタン選択モデル
	// -----------------------------------------
	@WprDI_ScopeModel('TermianlGuaranteeList')
	private m_TermianlGuaranteeList: MGeneral[] = null;

	// private変数  -----------------------------------------------------------
	// Webフィルタリングサービスコード
	public m_androidCd: string	= null;	// android
	public m_iPhoneCd1: string	= null;	// iPhone1
	public m_iPhoneCd2: string	= null;	// iPhone2
	// --------------------------------------------------------------------------


	// --------------------------------------------------------------------------
	// サブビュー/モード  ---------------------------------------------------------
	@WprViewMode
	private m_NextButtonMode = new NextButtonMode();	// 次へボタン用モード
	// --------------------------------------------------------------------------
	// サービス  ----------------------------------------------------------------
	private m_MvnoService: MvnoService = new MvnoService();	// MyPageRenewalサービス
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * コントロール定義設定
	 */
	public onRegisterConfig(): void {
		// 端末
		this.addConfig('terminalUseFlg', '端末申込有無', 'MvnoEntryInfo.entryInfo');
		this.addConfig('terminalId', '端末ID', 'MvnoEntryInfo.entryInfo');
		this.addConfig('terminalColorId', '端末カラーID', 'MvnoEntryInfo.entryInfo');
		this.addConfig('terminalGuaranteeFlg', '端末延長保証申込', 'MvnoEntryInfo.entryInfo');
		// SIM
		this.addConfig('simSize', 'SIMサイズ', 'MvnoEntryInfo.entryInfo');
		this.addConfig('simOption', 'SIMオプション', 'MvnoEntryInfo.entryInfo');
		// 初期設定サポート
		this.addConfig('initialSupportFlg', '初期設定サポート', 'MvnoEntryInfo.entryInfo');
		// WEBフィルタリング申込
		// this.addConfig('webFilteringServiceCd', 'WEBフィルタリング申込', 'MvnoEntryInfo.entryInfo');
		this.addConfig('webFilteringServiceCd', 'WEBフィルタリング申込', 'WebfilteringCd',{ bindModel: true });
	}

	/**
	 * アクション登録
	 */
	public onRegisterAction(): void {
		// ボタン
		this.addAction('next', this.onNext);
		this.addAction('back', this.onBack);
		// リンク
		this.addAction('terminalDetailLink', this.onTerminalDetailLink);
		this.addAction('terminalGuaranteeLink', this.onTerminalGuaranteeLink);
		// ダイアログ表示
		this.addAction('simDialog', this.onSimDialog);
		this.addAction('helpLink', this.onHelpLink);
		this.addAction('helpIntiialSupport', this.onHelpIntiialSupport);
		this.addAction('helpWebFiltering', this.onHelpWebFiltering);

	}

	/**
	 * ビュー表示通知
	 */
	public onShowView(): void {
		this.registAccessLog();
			this.setStorageItem('b_url', window.location.href)
		this.getCookieValListForMvno(this.m_EntryInfo);
		// 一番初めの描画時以外は選択した内容で表示
		if (ObjectUtil.isNotNullOrUndefined(this.m_EntryInfo.entryInfo.webFilteringFlg)) {
			this.m_webfilteringCd = this.m_EntryInfo.entryInfo.webFilteringFlg ? this.m_EntryInfo.entryInfo.webFilteringServiceCd : '不要';
		}
		const isData: boolean = this.m_EntryInfo.entryInfo.plan === 'データプラン';
		this.getTerminalStockList();
		// 端末情報がスコープにない場合：サーバ接続処理
		// if (ObjectUtil.isNullOrUndefined(this.m_TerminalRadioList) && ObjectUtil.isNullOrEmptyArray(this.m_TerminalRadioList)) {
		// 	this.getTerminalStockList();
		// }
		// // 端末情報はスコープにあるが、プランが変更された場合：サーバ接続処理
		// else if (this.m_TerminalRadioList[0].terminalM.flag2 !== isData) {
		// 	this.getTerminalStockList();
		// }
		// // 端末情報はスコープにあり、また、プランも変更されていない場合：サーバ接続処理なし
		// else {
		// 	this.setShowview();
		// }

		// ステップバー情報セット
		const sendData: ProgressBarData = new ProgressBarData();
		sendData.progressBarList = this.getProgressModelList();
		this.sendChildData('fP_ev_progressBarIntital', sendData);
	}

	/**
	 * 値変更通知
	 * @param name 名前
	 * @param value 値
	 * @returns falseの場合、変更を取り消す
	 */
	public onChangeValue(name: string, value: any): boolean {
		this.refreshModel();
		// 端末 -----------------------------------------------------
		if (name === 'terminalUseFlg') {
			
			// if (!value) {
			// 	this.m_EntryInfo.entryInfo.terminalGuaranteeServiceCd = null;
			// 	this.m_EntryInfo.entryInfo.terminalGuaranteeFlg = null;
			// 	this.m_EntryInfo.entryInfo.initialSupportFlg = null;
				
			// }
			// iPhoneを選択・Webフィルタリング必要を選択していた場合はwebFilteringServiceCdをnullにする
			// if (this.judgeTerminal() && this.m_EntryInfo.entryInfo.webFilteringFlg) {
			// 	this.m_EntryInfo.entryInfo.webFilteringServiceCd =null;
			// 	this.m_EntryInfo.entryInfo.webFilteringFlg = false;
			// 	this.m_webfilteringCd = null;
			// }
			// SIMのみから端末有に変更する際にiPhone用WEBフィルタリング必要を選択していた場合はwebFilteringServiceCdをnullにする
			if (this.m_EntryInfo.entryInfo.terminalUseFlg && this.m_EntryInfo.entryInfo.webFilteringFlg && (this.m_EntryInfo.entryInfo.webFilteringServiceCd === this.m_iPhoneCd1 || this.m_EntryInfo.entryInfo.webFilteringServiceCd === this.m_iPhoneCd2)) {
				this.m_EntryInfo.entryInfo.webFilteringServiceCd =null;
				this.m_EntryInfo.entryInfo.webFilteringFlg = false;
				this.m_webfilteringCd = null;
			}
			// 端末申込有無変更時：端末・端末延長保証・SIMの値を初期化
			this.m_EntryInfo.entryInfo.terminal = null;
			this.m_EntryInfo.entryInfo.terminalId = null;
			this.m_EntryInfo.entryInfo.terminalColor = null;
			this.m_EntryInfo.entryInfo.terminalColorId = null;
			this.m_EntryInfo.entryInfo.terminalGuaranteeServiceCd = null;
			this.m_EntryInfo.entryInfo.terminalGuaranteeFlg = null;
			this.m_EntryInfo.entryInfo.simOption = null;
			this.m_EntryInfo.iPhoneFlg = false;

			this.setPlanViewMode();
			this.getPaymentSimulation();
		}
		// terminalId　端末の種類
		// this.m_EntryInfo.entryInfoは選択したもののデータが入っている
		else if (name === 'terminalId') {
			// 変更前の端末
			const beforeTerminal:boolean = this.judgeTerminal();
			const selectedTerminal: MvnoTerminalModel = this.m_TerminalRadioList?.find(terminalm => {
				return (terminalm.terminalM.generalCd === this.m_EntryInfo.entryInfo.terminalId)
			});
			this.m_EntryInfo.entryInfo.terminal = selectedTerminal?.terminalM.name2;
			// 変更後の端末
			const afterTerminal:boolean = this.judgeTerminal();
			// Webフィルタリング必要が選択されていた場合に、iPhoneからAndroidへ変更された場合はWebフィルタリングのサービスコードをnullにする
			if (beforeTerminal !== afterTerminal && this.m_EntryInfo.entryInfo.webFilteringFlg) {
				this.m_EntryInfo.entryInfo.webFilteringServiceCd = null;
				this.m_EntryInfo.entryInfo.webFilteringFlg = false;
				this.m_webfilteringCd = null;
			}
			// 選択された端末がiPhoneかどうか
			if (this.judgeTerminal()) {
				this.m_EntryInfo.iPhoneFlg = true;
			} else {
				this.m_EntryInfo.iPhoneFlg = false;;
			}
			//もし既に色が選択されていた場合、初期化する
			this.m_EntryInfo.entryInfo.terminalColorId = null;
			this.m_EntryInfo.entryInfo.terminalColor = null;
			// 端末延長保証のサービスコード設定（端末とルーターでサービスコードが違うため）
			if (this.m_EntryInfo.entryInfo.terminalGuaranteeFlg) {
				const terminalM: MGeneral = this.m_TerminalRadioList.find(terminalm => {
					return (terminalm.terminalM.generalCd === this.m_EntryInfo.entryInfo.terminalId)
				}).terminalM;
				const guaranteeMgeneral: MGeneral = this.m_TermianlGuaranteeList.find(terminalm => {
					return (terminalm.flag1 === terminalM.flag1 && terminalm.flag2 === this.judgeTerminal())
				});
				this.m_EntryInfo.entryInfo.terminalGuaranteeServiceCd = guaranteeMgeneral.generalCd;
			}
			
			// SIMサイズをデフォルトで「nanoSIM」に設定（flag3=trueの場合）
			if (selectedTerminal.terminalM.flag3) {
				this.m_EntryInfo.entryInfo.simSize = 'nanoSIM';
			}
			else if (selectedTerminal.terminalM.flag4) {
				this.m_EntryInfo.entryInfo.simSize = 'マイクロSIM';
			}
			
			this.setPlanViewMode();
			this.getPaymentSimulation();
		}
		// 端末の色
		else if (name === 'terminalColorId') {
			// this.setViewMode('端末', '選択済');
			const teminal: MvnoTerminalModel = this.m_TerminalRadioList.find(terminalm => {
				return (terminalm.terminalM.generalCd === this.m_EntryInfo.entryInfo.terminalId)
			});

			this.m_EntryInfo.entryInfo.terminalColor = teminal.mvnoTerminalColorModelList.find(terminalColorm => {
				return (terminalColorm.terminalColorM.generalCd === this.m_EntryInfo.entryInfo.terminalColorId)
			}).terminalColorM.name2;
			this.setPlanViewMode();
		}
		// 端末延長保証
		else if (name === 'terminalGuaranteeFlg') {
			const selectedTerminal: MvnoTerminalModel[] = this.m_TerminalRadioList;
			// this.setViewMode('端末全項目', '選択済');
			if (value) {
				const terminalM: MGeneral = this.m_TerminalRadioList.find(terminalm => {
					return (terminalm.terminalM.generalCd === this.m_EntryInfo.entryInfo.terminalId)
				}).terminalM;
				const guaranteeMgeneral: MGeneral = this.m_TermianlGuaranteeList.find(terminalm => {
					return (terminalm.flag1 === terminalM.flag1 && terminalm.flag2 === this.judgeTerminal())
				});
				this.m_EntryInfo.entryInfo.terminalGuaranteeServiceCd = guaranteeMgeneral.generalCd;
			}
			else {
				this.m_EntryInfo.entryInfo.terminalGuaranteeServiceCd = null;
			}
			this.setPlanViewMode();
			this.getPaymentSimulation();
		}
		// SIM ---------------------------------------------------------
		else if (name === 'simSize') {
			// this.setViewMode('SIM', '選択済');
			this.setPlanViewMode();
			// this.getPaymentSimulation();
		}
		// 初期設定サポート -----------------------------------------------
		else if (name === 'initialSupportFlg') {
			// this.getPaymentSimulation();
		}
		// Webフィルタリング -----------------------------------------------
		else if (name === 'webFilteringServiceCd') {
			// this.m_EntryInfo.entryInfo.webFilteringServiceCd = value ? this.m_WebFiltering.generalCd : null;
			if (value !== '不要') {
				this.m_EntryInfo.entryInfo.webFilteringServiceCd = value;
				this.m_EntryInfo.entryInfo.webFilteringFlg = true;
			} else {
				this.m_EntryInfo.entryInfo.webFilteringServiceCd = null;
				this.m_EntryInfo.entryInfo.webFilteringFlg = false;
			}
			this.m_webfilteringCd = value;
			this.getPaymentSimulation();
		}
		this.setCookie('iPhoneFlg', String(this.m_EntryInfo.iPhoneFlg));
		this.setCookieValForMvno(this.m_EntryInfo);
		this.refreshView();
		return true;
	}

	/**
	 * ウインドウリサイズ通知
	 * @param width 幅
	 * @param height 高さ
	 */
	public onResizeWindow(width: number, height: number): void {
		const currentMode: string = this.getViewMode('画面サイズ');
		if (width >= 960) {
			if (currentMode != '大') {
				// this.setChildViewMode('画面サイズ', '大');
				this.setViewMode('画面サイズ', '大');
			}
		}
		else {
			if (currentMode != '中') {
				// this.setChildViewMode('画面サイズ', '中');
				this.setViewMode('画面サイズ', '中');
			}
		}
	}

	/**
	 * データ受信処理
	 * @param dataName データ名
	 * @param data データ
	 */
	public onReceiveData(dataName: string, data?: any): void {
		if (dataName === 'fC_ev_料金明細表示') {
			this.refreshModel();
			// this.sendChildFortempSave('端末選択');
			this.sendChildFortempSave();
		}
	}
	// --------------------------------------------------------------------------

	// アクション  ---------------------------------------------------------------
	/**
	 * 次へ
	 */
	public onNext(param: any): void {
		// this.setCookie('iPhoneFlg', String(this.m_EntryInfo.iPhoneFlg));
		this.pushPath('/mvno_005');
	}

	/**
	 * 戻る
	 */
	public onBack(param: any): void {
		this.pushPath('/mvno_003');
	}

	/**
	 * SIMダイアログ
	 */
	public onSimDialog(param: any): void {
		this.refreshModel();
		const sendData: MvnoHelpDialogData = new MvnoHelpDialogData();
		sendData.title = 'SIMサイズについて';
		this.showDialog('MVNO_SIMサイズダイアログ', sendData);
	}

	/**
	 * 問い合わせリンク
	 */
	public onHelpLink(param: any): void {
		window.open(MypageLinkConstatns.ITSCOM_MVNOENTRY_HELP);
	}

	/**
	 * 
	 */
	public onTerminalDetailLink(param: any): void {
		window.open(MypageLinkConstatns.ITSCOM_TERMINAL_DETAIL);
	}

	/**
	 * 
	 */
	public onTerminalGuaranteeLink(param: any): void {
		window.open(MypageLinkConstatns.ITSCOM_TERMINAL_GUARANTEE);
	}

	/**
	 * 初期設定サポートについて
	 */
	public onHelpIntiialSupport(param: any): void {
		this.showDialog('初期設定サポートについて');
	}

	/**
	 * Webフィルタリングについて
	 */
	public onHelpWebFiltering(param: any): void {
		this.showDialog('Webフィルタリングについて');
	}
	// --------------------------------------------------------------------------

	public getTerminalListLength(): number {
		return this.m_TerminalRadioList.length;
	}

	public isTerminalColor(): boolean {
		const selectedTerminal: MvnoTerminalModel = this.m_TerminalRadioList?.find(terminalm => {
			return (terminalm.terminalM.generalCd === this.m_EntryInfo.entryInfo.terminalId)
		});
		return selectedTerminal?.terminalM.flag1;
	}
	// private ------------------------------------------------------------------
	/**
	 * 端末在庫情報取得処理
	 */
	private getTerminalStockList(): void {
		const req: GetTerminalStockRequest = new GetTerminalStockRequest();
		req.entry = this.m_EntryInfo;
		this.m_MvnoService.getTerminalStock(this, req, (result) => {
			this.m_TerminalRadioList = result.terminalBase64List;
			// 消すやつ-------------------------------------------------
			// if (this.m_TerminalRadioList.length > 2) {
			// 	this.m_TerminalRadioList[1].mvnoTerminalColorModelList[0].stockNum = 100;
			// }
			// ---------------------------------------------------------
			// ------------------------------------------------------------------------------
			this.setScopeModel('TerminalRadioList', this.m_TerminalRadioList);
			// this.m_WebFiltering = result.webFiltering;
			// this.setScopeModel('WebFiltering', this.m_WebFiltering);
			this.m_TermianlGuaranteeList = result.termianlGuaranteeList;
			// WEBフィルタリング変更-------------------------------------------------------------------
			this.m_WebFilteringList = result.webFilteringList;
			this.setScopeModel('WebFiltering', this.m_WebFilteringList);
			const android = this.m_WebFilteringList.filter(data => data.flag2 == false);
			this.m_androidCd = android[0].generalCd;
			this.setViewData('android', this.m_androidCd);
			const iPhone = this.m_WebFilteringList.filter(data => data.flag2 == true).sort(function(a, b) {return Number(a.generalCd) - Number(b.generalCd);});
			this.m_iPhoneCd1 = iPhone[0].generalCd;
			this.m_iPhoneCd2 = iPhone[1].generalCd;
			this.setViewData('iPhone1', this.m_iPhoneCd1);
			this.setViewData('iPhone2', this.m_iPhoneCd2);
			// -------------------------------------------------------------
			this.setScopeModel('TermianlGuaranteeList', this.m_TermianlGuaranteeList);
			this.setShowview();
		});
	}

	/**
	 * 表示共通処理
	 */
	private setShowview(): void {
		this.setPlanViewMode();

		this.setListData('TerminalRadioList', this.m_TerminalRadioList);
		this.setListData('TerminalColorList', this.m_TerminalRadioList);
		
		this.refreshView();
	}

	/**
	 * 料金シミュレーション取得
	 */
	private getPaymentSimulation(): void {
		const req: GetPaymentSimulationRequest = new GetPaymentSimulationRequest();
		req.entryInfo = this.m_EntryInfo;
		req.isIncludeContract = false;
		this.m_MvnoService.getPaymentSimulation(this, req, (result) => {
			this.m_EntryInfo.total = result.total;
			this.setCookieValForMvno(this.m_EntryInfo);
			this.refreshView();
			this.sendChildData('fP_ev_料金シミュレーション取得');
		});
	}

	/**
	 * モードセット
	 */
	private setPlanViewMode(): void {
		this.setViewMode('プラン', this.m_EntryInfo.entryInfo.plan.includes('音声') ? '音声' : 'データ');
		
		if (this.m_EntryInfo.entryInfo.terminalUseFlg === false) {
			this.setViewMode('端末全項目',  '選択済');
			this.setViewMode('端末申込', '無');
			this.setViewMode('端末名', '無');
			// this.setPlanViewMode();
		}
		else if (this.m_EntryInfo.entryInfo.terminalUseFlg === true) {
			this.setViewMode('端末申込', '有');
			const isterminalFlg:boolean = this.isNull('terminalUseFlg') && this.isNull('terminalColorId') ;
			const isterminalAllflg: boolean = isterminalFlg && this.isNull('terminalGuaranteeFlg');

			// 色有
			if (this.isTerminalColor()) {
				this.setViewMode('端末', this.isNull('terminalColor') ? '選択済' : '未選択');
				this.setViewMode('端末全項目', this.isNull('terminalColor') && this.isNull('terminalGuaranteeFlg') ? '選択済' : '未選択');
				this.setViewMode('端末名', this.m_EntryInfo?.entryInfo.terminal);
			}
			// 色なし
			else {
				this.setViewMode('端末', this.isNull('terminal')  ? '選択済' : '未選択');
				this.setViewMode('端末全項目', this.isNull('terminal') && this.isNull('terminalGuaranteeFlg') ? '選択済' : '未選択');
			}
		}
		this.setViewMode('SIM', this.isNull('simSize') ? '選択済' : '未選択');


		const selectedTerminal: MvnoTerminalModel = this.m_TerminalRadioList?.find(terminalm => {
			return (terminalm?.terminalM?.generalCd === this.m_EntryInfo.entryInfo.terminalId)
		});

		// nanoSIMのみ
		if (selectedTerminal?.terminalM.flag3) {
			this.setViewMode('SIMサイズ', 'nanoSIMのみ');
			this.setViewMode('nanoSIM', '表示');
			this.setViewMode('マイクロSIM', '非表示');
			this.setViewMode('標準SIM', '非表示');
		}
		// 標準SIMのみ
		else if (selectedTerminal?.terminalM.flag4) {
			this.setViewMode('SIMサイズ', 'マイクロSIMのみ');
			this.setViewMode('標準SIM', '非表示');
			this.setViewMode('マイクロSIM', '表示');
			this.setViewMode('nanoSIM', '非表示');
		}
		// 全部
		else {
			this.setViewMode('SIMサイズ', '全部');
			this.setViewMode('標準SIM', '表示');
			this.setViewMode('マイクロSIM', '表示');
			this.setViewMode('nanoSIM', '表示');
		}
		// Webフィルタリング
		if (!this.m_EntryInfo.entryInfo.terminalUseFlg) {
			this.setViewMode('選択端末',  'SIMのみ');
		} else if (!this.judgeTerminal()) {
			this.setViewMode('選択端末',  'Android');
		} else {
			this.setViewMode('選択端末', 'iPhone');
		}
		// Webフィルタリング下文言
		if (!this.judgeTerminal()) {
			this.setViewMode('WEBフィルタリング文言',  '必要');
		} else {
			this.setViewMode('WEBフィルタリング文言', '不要');
		}
	}

	private isNull(column: string): boolean {
		const val: any = this.m_EntryInfo.entryInfo[column];
		return ObjectUtil.isNotNullOrUndefined(val);
	}

	protected getProgressModelList(): ProgressBarModel[] {
		const list: ProgressBarModel[] = new Array();

		list.push(new ProgressBarModel('1', 'お申し込み手続き', 'complete', this.getStopModelList()));
		list.push(new ProgressBarModel('2', '合計金額ご確認', 'not', null));
		list.push(new ProgressBarModel('3', '事前確認事項', 'not', null));
		list.push(new ProgressBarModel('4', 'WEB本人確認', 'not', null));
		list.push(new ProgressBarModel('5', 'お申込み完了', 'not', null));
		return list;
	}

	
	private getStopModelList(): ProgressBarStopModel[] {
		const list: ProgressBarStopModel[] = new Array();
		list.push(new ProgressBarStopModel('complete'));
		list.push(new ProgressBarStopModel('current'));
		list.push(new ProgressBarStopModel('next'));
		list.push(new ProgressBarStopModel('not'));
		return list;
	}

	// Webフィルタリング金額取得
	public getWebFilteringPrice(cd:string): number {
		// if (!this.judgeTerminal()) {
		// 	for (const filter of this.m_WebFilteringList) {
		// 		if (!filter.flag2) {
		// 			return filter.num2;
		// 		}
		// 	}
		// } else {
		// 	return this.m_TermianlGuaranteeList[2].num2;
		// }
		if (ObjectUtil.isNotNullOrEmptyArray(this.m_WebFilteringList)) {
			for (const filter of this.m_WebFilteringList) {
				if (!this.m_EntryInfo.entryInfo.terminalUseFlg) {
					if (filter.generalCd === cd) {
						return filter.num2;
					}
				}else {
					if (!this.judgeTerminal() && !filter.flag2) {
						this.m_EntryInfo.webFilteringPrice = String(filter.num2);
						return filter.num2;
					} else if (this.judgeTerminal() && filter.flag2 && filter.generalCd == cd){
						this.m_EntryInfo.webFilteringPrice = String(filter.num2);
						return filter.num2;
					}
				}
			}
		}

		// return this.m_WebFiltering?.num2;
	}

	public getWebFilteringAccount(cd: string): string {
		let account: string = null;
		switch (cd) {
			case this.m_androidCd:
				account = '(Android対応)';
				break;
			case this.m_iPhoneCd1:
				account = '(iPhone対応・1アカウント用)';
				break;
			case this.m_iPhoneCd2:
				account = '(iPhone対応・3アカウント用)';
				break;
			default:
				break;
		}
		return account;
	}

	// モバイル端末延長保証金額取得
	public getTerminalGuaranteePrice(): number {
		if (ObjectUtil.isNotNullOrEmptyArray(this.m_TermianlGuaranteeList)) {
			if (!this.judgeTerminal()) {
				return this.m_TermianlGuaranteeList[0].num2;
			} else {
				return this.m_TermianlGuaranteeList[2].num2;
			}
		}
		// if (ObjectUtil.isNotNullOrEmptyArray(this.m_TermianlGuaranteeList)) {
		// 	return this.m_TermianlGuaranteeList[0].num2;
		// }
	}

	// 端末判定(iPhoneならtrue)
	public judgeTerminal(): boolean {
		if (ObjectUtil.isNotNullOrUndefined(this.m_EntryInfo.entryInfo)) {
			if (this.m_EntryInfo.entryInfo.terminal !== 'iPhoneSE3') {
				return false;
			} else {
				return true;
			}
		}
	}
	// --------------------------------------------------------------------------
}
