import { WprModel } from "../../../../../../wpr-framework/view/deco/WprModel";
import { WprDI_ScopeModel } from "../../../../../../wpr-framework/view/di/WprDI_ScopeModel";
import { MypageLinkConstatns } from "../../../../../common/constants/MypageLinkConstatns";
import { ObjectUtil } from "../../../../../common/util/ObjectUtil";
import { MvnoEntryInfo } from "../../../../../service/models/apimodels/MvnoEntryInfo";
import { GetMvnoContractPriceListRequest } from "../../../../../service/models/dto/mvno/GetMvnoContractPriceListRequest";
import { GetTotalFeeRequest } from "../../../../../service/models/dto/mvno/GetTotalFeeRequest";
import { MvnoService } from "../../../../../service/MvnoService";
import { MvnoBaseView } from "../../../../base/MvnoBaseView";
import { MypageBaseView } from "../../../../base/MypageBaseView";

export class MvnoEntryDetailPartsData {
	public entryInfo: MvnoEntryInfo;
}

/**
 * MVNO申込内容パーツ
 */
export class MvnoEntryDetailPartsView extends MypageBaseView {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() { super('MvnoEntryDetailParts'); }
	// --------------------------------------------------------------------------

	// モデル  ------------------------------------------------------------------
	// @WprDI_ScopeModel('MvnoEntryInfo')
	@WprModel('MvnoEntryInfo')
	private m_EntryInfo: MvnoEntryInfo = null;	// MVNOプラン情報

	// --------------------------------------------------------------------------

	// サービス  ----------------------------------------------------------------
	// private m_MvnoService: MvnoService = new MvnoService();	// MyPageRenewalサービス
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * コントロール定義設定
	 */
	public onRegisterConfig(): void {
		// プラン1
		// プラン2
		this.addConfig('capacity', 'データ量', 'MvnoEntryInfo.entryInfo');
		this.addConfig('course', 'コース', 'MvnoEntryInfo.entryInfo');
		this.addConfig('fivegUseFlg', '5G通信利用', 'MvnoEntryInfo.entryInfo', { converter: '利用する/利用しない' });
		this.addConfig('rusubanUseFlg', '留守番電話オプション', 'MvnoEntryInfo.entryInfo', { converter: '利用する/利用しない' });
		
		// プラン3
		this.addConfig('terminal', '端末', 'MvnoEntryInfo.entryInfo');
		this.addConfig('terminalColor', '端末カラー', 'MvnoEntryInfo.entryInfo');
		this.addConfig('terminalGuaranteeFlg', '端末延長保証', 'MvnoEntryInfo.entryInfo', { converter: '必要/不要' });

		this.addConfig('simSize', 'SIMサイズ', 'MvnoEntryInfo.entryInfo');
		this.addConfig('simOption', 'SIMオプション', 'MvnoEntryInfo.entryInfo');
		
		this.addConfig('initialSupportFlg', '初期設定サポート申込', 'MvnoEntryInfo.entryInfo', { converter: '必要/不要' });
		this.addConfig('webFilteringFlg', 'WEBフィルタリング', 'MvnoEntryInfo.entryInfo', { converter: '必要/不要' });

		// 合計
		this.addConfig('total', '合計', 'MvnoEntryInfo', { converter: '3桁カンマ' });

	}

	/**
	 * アクション登録
	 */
	public onRegisterAction(): void {
		
	}

	/**
	 * データ受信処理
	 * @param dataName データ名
	 * @param data データ
	 */
	public onReceiveData(dataName: string, data?: any): void {
		if (dataName === 'fP_vP_MvnoFeeDialogView' ||
			dataName === 'fP_vP_MvnoCompleteView'
		) {
			const receiveData: MvnoEntryDetailPartsData = data as MvnoEntryDetailPartsData;
			this.m_EntryInfo = receiveData.entryInfo;
			this.setViewMode('プラン', this.m_EntryInfo.entryInfo.plan);
			this.setViewMode('端末申込', this.m_EntryInfo.entryInfo.terminalUseFlg ? '有' : '無');
			this.setViewMode('端末色', ObjectUtil.isNotNullOrUndefined(this.m_EntryInfo.entryInfo.terminalColorId) ? '有' : '無');
			this.refreshView();
		}
		
	}
	// --------------------------------------------------------------------------
}
