import { CustomerInfo } from '../../../models/CustomerInfo';
import { MypageBaseView } from '../../base/MypageBaseView';
import { WprModel } from '../../../../wpr-framework/view/deco/WprModel';
import { CustomerModel } from '../../../view/videoService/video_pass_complete/models/CustomerModel';
import { WprDI_ScopeModel } from '../../../../wpr-framework/view/di/WprDI_ScopeModel';
import { ProgressBarData } from '../../common/progress_bar/ProgressBarView';
import { MypageWizardMngr } from '../../wizard/models/MypageWizardMngr';
import { ResetPasswordModel } from '../../../service/models/apimodels/ResetPasswordModel';

/**
 * 動画配信サービスパスワード再発行完了画面
 */
export class VideoPassCompleteView extends MypageBaseView {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() { super('VideoPassCompleteView'); }
	// --------------------------------------------------------------------------

	// モデル  ------------------------------------------------------------------
	@WprModel('PasswordInfo')
	private m_PasswordInfo: ResetPasswordModel	= null;	// 顧客モデル
	@WprDI_ScopeModel('MypageWizardMngr')
	private m_MypageWizardMngr: MypageWizardMngr			= null;		// 画面ウィザード管理クラス
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * コントロール定義設定
	 */
	public onRegisterConfig(): void {
		this.addConfig('id', 'idpid', 'PasswordInfo', { bindName: 'idpID' });
		this.addConfig('password', 'パスワード', 'PasswordInfo', { bindName: 'password' });
		this.addConfig('date', '変更日', 'PasswordInfo', { bindName: 'date', converter: '日付（yyyy年MM月dd日）' });
	}

	/**
	 * アクション登録
	 */
	public onRegisterAction(): void {
		this.addAction('toQuestionnaire', this.onToQuestionnaire);
		this.addAction('back', this.onBack);
		this.addAction('print', this.onPrintView);
	}

	/**
	 * ビュー表示通知
	 */
	public onShowView(): void {
		this.m_MypageWizardMngr.initView(this, (result) => {
			this.registAccessLog();
			this.setStorageItem('b_url', window.location.href);
			const sendData: ProgressBarData = new ProgressBarData();
			sendData.progressBarList = this.m_MypageWizardMngr.getProgressModelList(this);
			this.sendChildData('fP_ev_progressBarIntital', sendData);

			this.m_PasswordInfo = this.m_MypageWizardMngr.getCache().passwordInfo;
			this.setViewData('sentenceData', this.m_PasswordInfo.passwordKana)
			this.refreshView();
		});
	}
	// --------------------------------------------------------------------------

	// アクション  ---------------------------------------------------------------
	/**
	 * アンケート
	 */
	public onToQuestionnaire(param: any): void {
		window.open('https://questant.jp/q/B7KS5E4S?id=' + this.m_MypageWizardMngr.getParams().customerId);
	}

	/**
	 * 契約内容の確認・変更メニューへ
	 */
	public onBack(param: any): void {
		location.href = this.getOldUrl() + 'Srvlist/Continfo_list/';
	}

	/**
	 * 印刷画面へ
	 */
	public onPrintView(param: any): void {
		this.openNewTab('video_pass_print');
	}
	// --------------------------------------------------------------------------
}
