import { Card, Divider } from '@material-ui/core';
import { Grid } from '@mui/material';
import React, { ReactNode } from 'react';
import { WprBaseViewComponent } from '../../../../../wpr-framework/view/component/WprBaseViewComponent';
import { MenuBar } from '../../../common/menu_bar/MenuBar';
import { MvnoEntryPlan1View } from './MvnoEntryPlan1View';
import styles from './MvnoEntryPlan1.scss';
import { WprRadio } from '../../../../../wpr-framework/component/controls/WprRadio';
import { MatRadio } from '../../../../../wpr-framework/component/controls/material/MatRadio';
import { CheckMarkRadio } from '../../../../controls/checkMarkRadio/CheckMarkRadio';
import { MvnoPlanSendData, CheckMarkRadioContent } from '../../../../controls/checkMarkRadio/CheckMarkRadioContent';
import { MatButton } from '../../../../../wpr-framework/component/controls/material/MatButton';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { WprAnchor } from '../../../../../wpr-framework/component/controls/WprAnchor';
import { MvnoEntryFooter } from '../mvno_entry_footer/MvnoEntryFooter';
import { ProgressBar } from '../../../common/progress_bar/ProgressBar';

/**
 * MVNO申込プラン選択用コンポーネント
 */
export class MvnoEntryPlan1 extends WprBaseViewComponent {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: any) { super(new MvnoEntryPlan1View(), props); }
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** MVNO申込プラン選択ビュー情報 */
	public get viewInfo(): MvnoEntryPlan1View { return this.view as MvnoEntryPlan1View; }
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * スタイルオブジェクト取得
	*/
	public getStylesObject(): any { return styles; }

	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		return (
			<>
				<div>
					<div className='pageCard' id='mvnoEntryPlan1ID'>
						<div className="pageHeader stepFlow">
							<ProgressBar name='progressBar' view={this.view} />
						</div>
						<div className='pageContent'>
							<div className='planContent'>
								<div className='link_blue'>
									<WprAnchor name='helpLink' view={this.view}>申込みについてご不明な方はこちら＞</WprAnchor>
								</div>
								<div className='title_gray'>プランをお選びください</div>
								<div className='planItem'>
									<Grid container className='entryRadio' spacing={1}>
										<Grid item xs={12}>
											<CheckMarkRadio name='plan' id='plan_1' value='音声プラン' itemType={CheckMarkRadioContent.MVNO_PLAN} radioContentData={this.getPlanContentData()} view={this.view} />
										</Grid>
									</Grid>
									<div style={{ textAlign: 'right', marginTop: '0.5rem' }}>
										<WprAnchor name='dataPlanAnchor' view={this.view} className='link_underline'>電話番号の付与が無いデータSIMをお申込みの方はこちら＞</WprAnchor>
									</div>
								</div>

							</div>

						</div>
						<Divider />
						<div className='pageFooter'>
							<div>
								<MatButton name='next' view={this.view} className='nextButton' >
									次へ
									<NavigateNextIcon />
								</MatButton>

							</div>
							<div className='stepNumber'>1/4</div>
						</div>

					</div>
				</div>
				<MvnoEntryFooter name='mvnoFooter' view={this.view} />
			</>
		);
	}
	// --------------------------------------------------------------------------
	private getPlanContentData(): MvnoPlanSendData {
		const data: MvnoPlanSendData = new MvnoPlanSendData();
		return data;
	}
}
