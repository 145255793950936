import { WprBaseViewMultiModeInfo } from '../../../../../../wpr-framework/mode/WprBaseViewMultiMode';
import { WprMultiMode } from '../../../../../../wpr-framework/mode/WprMultiMode';

import { WprDI_Object } from '../../../../../../wpr-framework/view/di/WprDI_Object';
import { MvnoEntryInfo } from '../../../../../service/models/apimodels/MvnoEntryInfo';
import { ObjectUtil } from '../../../../../common/util/ObjectUtil';
import { WprDI_Control } from '../../../../../../wpr-framework/view/di/WprDI_Control';
import { WprControlInfo } from '../../../../../../wpr-framework/view/control/WprControlInfo';

import { MvnoCapacityChange1View } from '../MvnoCapacityChange1View';

/**
 * 次へボタン用モード
 */
export class NextButtonMode extends WprBaseViewMultiModeInfo<MvnoCapacityChange1View> {
	// DI情報  ------------------------------------------------------------------
	@WprDI_Control
	private m_capacityServiceCdAfter: WprControlInfo	= null;	// 容量

	// --------------------------------------------------------------------------


	// コンストラクタ  -----------------------------------------------------------
	public constructor() {
		super('nextButtonMode');
	}
	// --------------------------------------------------------------------------
	/**
	 * モードリスト取得（この画面モードで使用する全てのモードを定義する）
	 * @param list モードリスト
	 */
	public useModeList(list: string[]): void {
		list.push('次へ活性');
		list.push('次へ非活性');
	}
	// override メソッド  --------------------------------------------------------
	/**
	 * フィールド定義を登録処理
	 */
	public onRegisterControl(): void {
		
		this.addControl('capacityServiceCdAfter', { check: true });
		this.addControl('next', { enabled: [ { mode: '次へ活性', value: true }, { mode: 'other', value: false } ] });
	}
	// --------------------------------------------------------------------------
	/**
	 * ビューの状態をチェックしてモードを変更する
	 * @param curMode 現在のモード
	 * @param newMode 新しいモード
	 */
	public onCheckMultiMode(curMode: WprMultiMode, newMode: WprMultiMode): void {
		const isNotNullVal: boolean = this.checkIsNull([this.m_capacityServiceCdAfter]);
		const isEnabled: boolean = (isNotNullVal);
		if (isEnabled) {
			newMode.addMode('次へ活性');
		}
		else {
			newMode.addMode('次へ非活性');
		}
	}

	/**
	 * 値が入っているかチェック
	 * @param controlList 
	 * @returns 
	 */
	private checkIsNull(controlList: WprControlInfo[]): boolean {
		for (let control of controlList) {
			const val: any = control.value;
			if (ObjectUtil.isNullOrUndefined(val))  
				return false;
		}
		return true;
	}
	
}
