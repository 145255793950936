// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@charset \"UTF-8\";\n/*\r\n * 契約内容の確認用CSS\r\n */\n/*s004*/\n.S_01_005-card.S_01_005-card_padding {\n  padding: 1em; }\n", ""]);
// Exports
exports.locals = {
	"card": "S_01_005-card",
	"card_padding": "S_01_005-card_padding"
};
module.exports = exports;
