import { WprBaseObject } from '../../WprBaseObject';
import { WprBaseViewInfo } from '../WprBaseViewInfo';
import { IWprDialog } from './IWprDialog';
import { WprBaseDialogViewInfo } from './WprBaseDialogViewInfo';

/**
 * ダイアログ情報
 */
export class WprDialogInfo extends WprBaseObject {
	// private 変数  ------------------------------------------------------------
	private m_ViewInfo: WprBaseViewInfo								= null;		// ビュー情報
	private m_DialogViewInfo: WprBaseDialogViewInfo					= null;		// ダイアログビュー情報
	private m_Name: string											= null;		// 名称
	private m_Component: IWprDialog									= null;		// コンポーネント
	private m_Open: boolean											= false;	// オープン状態
	private m_IsStart: boolean		 								= false;	// ダイアログ開始中フラグ
	private m_IsStartClose: boolean									= false;	// ダイアログ開始中クローズフラグ
	private m_Title: string											= null;		// タイトル
	private m_SendData: any											= null;		// 送信データ
	private m_ResultMethod: (status: boolean, result?: any) => void	= null;		// 結果通知メソッド
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** 名称 */
	public get name(): string 		{ return this.m_Name; 	}
	/** オープン状態 */
	public get isOpen(): boolean	{ return this.m_Open; 	}
	/** オープン状態 */
	public get isStart(): boolean	{ return this.m_IsStart; }
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	public constructor(view: WprBaseViewInfo, comp: IWprDialog, name: string, dialogViewInfo: WprBaseDialogViewInfo) {
		super();
		this.m_ViewInfo = view;
		this.m_Name = name;
		this.m_Component = comp;
		this.m_DialogViewInfo = dialogViewInfo;
	}
	// --------------------------------------------------------------------------

	// public メソッド  ---------------------------------------------------------
	/**
	 * ダイアログ表示処理
	 * @param sendData 送信データ
	 * @param resultMethod 結果通知メソッド
	 * @param title タイトル
	 */
	public show(sendData: any, resultMethod?: (status: boolean, result?: any) => void, title?: string): void {
		this.m_ResultMethod = resultMethod;
		this.m_SendData = sendData;
		this.m_Title = title;
		this.m_IsStart = true;
		this.m_IsStartClose = false;
		let wait = false;
		try {
			wait = this.m_DialogViewInfo.onStartDialog(sendData);
		}
		finally {
			if (wait === false)
				this.m_IsStart = false;
		}
		if (wait === false && this.m_IsStartClose === false)
			this.startDialog();
	}

	/**
	 * ダイアログ再開処理
	 */
	public restartDialog(): void {
		if (this.m_IsStart === true) {
			this.m_IsStart = false;
			this.startDialog();
		}
	}

	/**
	 * ダイアログクローズ処理
	 * @param status 送信データ
	 * @param resultData 結果データ
	 */
	public close(status: boolean, resultData: any) {
		if (this.m_IsStart === false) {
			this.FW.edit.closeDialog(this.m_Name);
			this.m_Component.close();
			this.m_Open = false;
		}
		else {
			this.m_IsStartClose = true;
			this.m_IsStart = false;
		}
		if (this.m_ResultMethod)
			this.m_ResultMethod.bind(this.m_ViewInfo)(status, resultData);
	}
	// --------------------------------------------------------------------------

	// private メソッド  ---------------------------------------------------------
	/**
	 * ダイアログ開始処理
	 */
	private startDialog(): void {
		this.FW.edit.showDialog(this.m_Name);
		this.m_Component.show(this.m_SendData, this.m_Title);
		this.m_Open = true;
	}
	// --------------------------------------------------------------------------
}