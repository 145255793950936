import { WprResonseInfo } from '../service/detail/WprBaseServiceD';
import { WprBaseViewJest } from './WprBaseViewJest';

/**
 * 単体テスト情報
 */
export class WprUnitTestInfo {
	// private 変数  ------------------------------------------------------------
	private m_IsUnitTest: boolean 			= false;											// 単体テストフラグ
	private m_JestInstance: WprBaseViewJest	= null;												// 単体テスト用インスタンス
	private m_IsPC: boolean 				= true;												// PCレンダー使用フラグ
	private m_GetResposeData: (isPost: boolean, url: string, request?: any) => WprResonseInfo;	// UnitTest用レスポンスデータ取得メソッド
	private m_GetUrlParam: (name: string) => string;											// urlパラメータ取得メソッド
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** 単体テストフラグ */
	public get isUnitTest():	boolean		{ return this.m_IsUnitTest;		}
	/** PCレンダー使用フラグ */
	public get isPC():	boolean				{ return this.m_IsPC;			}
	// --------------------------------------------------------------------------

	// public メソッド  ----------------------------------------------------------
	/**
	 * 単体テスト情報設定
	 * @param instance 単体テストインスタンス
	 * @param getResponseData 受信データ取得メソッド
	 * @param getUrlParam Urlパラメータ取得メソッド
	 * @param isPC PCレンダーフラグ
	 */
	public setUnitTestInfo(instance: WprBaseViewJest, getResponseData: (isPost: boolean, url: string, request?: any) => WprResonseInfo,  getUrlParam: (name: string) => string, isPC: boolean=false) {
		this.m_IsUnitTest  = true;
		this.m_JestInstance = instance;
		this.m_GetResposeData = getResponseData;
		this.m_GetUrlParam = getUrlParam;
		this.m_IsPC = isPC;
	}

	/**
	 * 受信データ取得
	 * @param isPost postフラグ trueの場合POST、falseの場合GET
	 * @param url URL
	 * @param request リクエストデータ
	 * @returns レスポンスデータ
	 */
	public getResposeData(isPost: boolean, url: string, request?: any): WprResonseInfo {
		return this.m_GetResposeData.bind(this.m_JestInstance)(isPost, url, request);
	}

	/**
	 * urlパラメータ取得
	 * @param name パラメータ名
	 * @returns urlパラメータ
	 */
	public getUrlParam(name: string): string {
		return this.m_GetUrlParam.bind(this.m_JestInstance)(name);
	}
	// --------------------------------------------------------------------------
}