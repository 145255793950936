import { WprBaseViewMultiModeInfo } from '../../../../../../wpr-framework/mode/WprBaseViewMultiMode';
import { WprMultiMode } from '../../../../../../wpr-framework/mode/WprMultiMode';
import { MvnoEntryBaseMode } from '../../../../base/mode/MvnoEntryBaseMode';
import { MvnoEntryPlan3View } from '../MvnoEntryPlan3View';
import { WprDI_Object } from '../../../../../../wpr-framework/view/di/WprDI_Object';
import { MvnoEntryInfo } from '../../../../../service/models/apimodels/MvnoEntryInfo';
import { HMvnoTmpSaved } from '../../../../../service/models/entity/HMvnoTmpSaved';
import { ObjectUtil } from '../../../../../common/util/ObjectUtil';
import { WprDI_Control } from '../../../../../../wpr-framework/view/di/WprDI_Control';
import { WprControlInfo } from '../../../../../../wpr-framework/view/control/WprControlInfo';
import { runInThisContext } from 'vm';

/**
 * 次へボタン用モード
 */
export class NextButtonMode extends WprBaseViewMultiModeInfo<MvnoEntryPlan3View> {
	// DI情報  ------------------------------------------------------------------
	@WprDI_Control
	private m_terminalUseFlg: WprControlInfo	= null;	// 端末申込有無
	@WprDI_Control
	private m_terminalId: WprControlInfo	= null;	// 端末
	@WprDI_Control
	private m_terminalColorId: WprControlInfo	= null;	// 端末カラー
	@WprDI_Control
	private m_simSize: WprControlInfo	= null;	// SIMサイズ
	@WprDI_Control
	private m_simOption: WprControlInfo	= null;	// SIMオプション
	@WprDI_Control
	private m_initialSupportFlg: WprControlInfo	= null;	// 初期設定サポート
	@WprDI_Control
	private m_terminalGuaranteeFlg: WprControlInfo	= null;	// 初期設定サポート
	@WprDI_Control
	private m_webFilteringServiceCd: WprControlInfo	= null;	// 初期設定サポート
	@WprDI_Object
	private m_EntryInfo: MvnoEntryInfo	= null;	// MVNOプラン情報
	// --------------------------------------------------------------------------


	// コンストラクタ  -----------------------------------------------------------
	public constructor() {
		super('nextButtonMode');
	}
	// --------------------------------------------------------------------------
	/**
	 * モードリスト取得（この画面モードで使用する全てのモードを定義する）
	 * @param list モードリスト
	 */
	public useModeList(list: string[]): void {
		list.push('次へ活性');
		list.push('次へ非活性');
	}
	// override メソッド  --------------------------------------------------------
	/**
	 * フィールド定義を登録処理
	 */
	public onRegisterControl(): void {
		// this.addControl('fivegUseFlg', { check: true });
		this.addControl('terminalId', { check: true });
		this.addControl('terminalColorId', { check: true });
		this.addControl('simSize', { check: true });
		this.addControl('simOption', { check: true });
		this.addControl('initialSupportFlg', { check: true });
		this.addControl('terminalGuaranteeFlg', { check: true });
		this.addControl('webFilteringServiceCd', { check: true });
		this.addControl('next', { enabled: [ { mode: '次へ活性', value: true }, { mode: 'other', value: false } ] });
	}
	// --------------------------------------------------------------------------
	/**
	 * ビューの状態をチェックしてモードを変更する
	 * @param curMode 現在のモード
	 * @param newMode 新しいモード
	 */
	public onCheckMultiMode(curMode: WprMultiMode, newMode: WprMultiMode): void {
		const checkList: WprControlInfo[] = new Array();
		checkList.push(this.m_terminalUseFlg);
		checkList.push(this.m_simSize);
		const onseiFlg: boolean = this.m_EntryInfo.entryInfo.plan === '音声プラン';
		const terminalUseFlg: boolean = this.m_terminalUseFlg?.value === true;
		if (onseiFlg) {
			checkList.push(this.m_webFilteringServiceCd);
			checkList.push(this.m_initialSupportFlg);
			if (terminalUseFlg) {
				checkList.push(this.m_simOption);
			}
		}
		
		if (terminalUseFlg) {
			checkList.push(this.m_terminalId);
			checkList.push(this.m_terminalGuaranteeFlg);
			if (this.view.isTerminalColor()) {
				checkList.push(this.m_terminalId);
			}
		}
		const isNotNullVal: boolean = this.checkIsNull(checkList);
		// let isNotNullTerminal: boolean = true;

		// if (this.m_EntryInfo.entryInfo.plan === '音声プラン') {
		// 	isNotNullTerminal = this.checkIsNull([this.m_initialSupportFlg]);
		// }
		// if (this.m_terminalUseFlg?.value === true) {
		// 	isNotNullTerminal = this.checkIsNull([this.m_terminalId, this.m_terminalGuaranteeFlg]);
		// 	if (this.view.isTerminalColor()) {
		// 		isNotNullTerminal = this.checkIsNull([this.m_terminalColorId]);
		// 	}
		// }
		const isEnabled: boolean = (isNotNullVal);
		if (isEnabled) {
			newMode.addMode('次へ活性');
		}
		else {
			newMode.addMode('次へ非活性');
		}
	}

	/**
	 * 値が入っているかチェック
	 * @param controlList 
	 * @returns 
	 */
	private checkIsNull(controlList: WprControlInfo[]): boolean {
		for (let control of controlList) {
			const val: any = control.value;
			if (ObjectUtil.isNullOrUndefined(val))  
				return false;
		}
		return true;
	}
	
}
