import { WprModel } from '../../../../wpr-framework/view/deco/WprModel';
import { WprDI_ScopeModel } from '../../../../wpr-framework/view/di/WprDI_ScopeModel';
import { MypageLinkConstatns } from '../../../common/constants/MypageLinkConstatns';
import { MypageBaseView } from '../../base/MypageBaseView';
import { ProgressBarData } from '../../common/progress_bar/ProgressBarView';
import { MypageWizardMngr } from '../../wizard/models/MypageWizardMngr';
import { WprViewMode } from '../../../../wpr-framework/view/deco/WprViewMode';
import { NextButtonMode } from './mode/NextButtonMode';
import { UrlUtil } from '../../../common/util/UrlUtil';
import { NameChangeAppModel } from '../../../service/models/apimodels/NameChangeAppModel';
import { WprDI_Control } from '../../../../wpr-framework/view/di/WprDI_Control';
import { WprControlInfo } from '../../../../wpr-framework/view/control/WprControlInfo';

/**
 * 名義変更本人確認書類アップロード
 */
export class NameChangeNewIdentificationView extends MypageBaseView {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() { super('NameChangeNewIdentificationView'); }
	// --------------------------------------------------------------------------
	
	// DI情報  ------------------------------------------------------------------
	@WprDI_Control
	private m_confirm: WprControlInfo	= null;	// 確認チェック
	
	// 後で消す
	@WprModel('isCompleteIdentification')
	private m_isCompleteIdentification: boolean = false;
	public get isCompleteIdentification(): boolean { return this.m_isCompleteIdentification; }
	// --------------------------------------------------------------------------
	
	// モデル  ------------------------------------------------------------------
	@WprDI_ScopeModel('MypageWizardMngr')
	private m_MypageWizardMngr: MypageWizardMngr = null;	// 画面ウィザード管理クラス
	@WprModel('NameChangeAppModel')
	private m_EntryInfo: NameChangeAppModel = null;			// 名義変更申込み情報モデル
	@WprModel('environment')
	private m_environment: string = null;	// 開発環境

	// @WprModel('confirm')
	// private m_confirm: boolean = null;	// 確認


	// --------------------------------------------------------------------------

	// サブビュー/モード  --------------------------------------------------------
	@WprViewMode
	private m_NextButtonMode = new NextButtonMode();	// 次へボタンモード
	// --------------------------------------------------------------------------
	
	// override メソッド  --------------------------------------------------------
	/**
	 * コントロール定義設定
	 */
	public onRegisterConfig(): void {
		this.addConfig('customerId', '顧客番号', 'NameChangeAppModel');
		this.addConfig('environment', '開発環境', 'environment', { bindModel: true });
		// this.addConfig('c_confirm', '確認', 'confirm', { bindModel: true });
		this.addConfig('confirm', '確認チェック', null);
		this.addConfig('isCompleteIdentification', '本人確認完了', 'isCompleteIdentification', { bindModel: true });
	}
	/**
	 * アクション登録
	 */
	public onRegisterAction(): void {
		this.addAction('next', this.onNext);
		this.addAction('back', this.onBack);
		this.addAction('helpLink', this.onHelpLink);
		this.addAction('helpIdentificationDoc', this.onHelpIdentificationDoc);
	}

	/**
	 * ビュー表示通知
	 */
	public onShowView(): void {
		this.m_MypageWizardMngr.initView(this, (result) => {
			this.registAccessLog();
			this.setStorageItem('b_url', window.location.href);
			const sendData: ProgressBarData = new ProgressBarData();
			sendData.progressBarList = this.m_MypageWizardMngr.getProgressModelList(this);
			this.sendChildData('fP_ev_progressBarIntital', sendData);
			this.m_EntryInfo = this.m_MypageWizardMngr.getCache().nameChangeAppInfo;
			// this.m_EntryInfo.customerId = this.m_MypageWizardMngr.getParams().customerId;
			// 本人確認用スクリプト：element作成
			const script = document.createElement('script');
			script.src = 'https://app.protechidchecker.com/apps/org7915';
			script.async = true;
			script.charset = 'utf-8';
			script.id = 'identificationScript'
			document.head.appendChild(script);
			// 本人確認API完了時の処理を登録
			document.addEventListener('completed.pic', event => {
				this.rcNext();
			});
			this.m_environment = UrlUtil.getEnvironmentName();
			
			this.setViewMode('確認用チェックボックス', this.m_environment === '本番' ? '非表示' : '表示');
			this.refreshView();
		});
	}


	/**
	 * 値変更通知
	 * @param name 名前
	 * @param value 値
	 * @returns falseの場合、変更を取り消す
	 */
	public onChangeValue(name: string, value: any): boolean {
		if (name === 'isCompleteIdentification')
			this.refreshModel();
		return true;
	}
	// --------------------------------------------------------------------------
	
	// アクション  ---------------------------------------------------------------
	/**
	 * 次へ
	 */
	public onNext(param: any): void {
		// this.refreshModel();
		// 本人確認用スクリプト：element削除
		const identificationScript = document.getElementById("identificationScript");
		identificationScript.remove();
		this.m_MypageWizardMngr.goNext(this);
	}

	/**
	 * 戻る
	 */
	public onBack(param: any): void {
		this.m_MypageWizardMngr.goBack(this);
	}

	/**
	 * 問い合わせリンク
	 */
	public onHelpLink(param: any): void {
		window.open('https://www.itscom.co.jp/support/contract_change/contract-name/');
	}

	/**
	 * 本人確認書類について
	 */
	public onHelpIdentificationDoc(param: any): void {
		this.showDialog('ヘルプ：本人確認書類について');
	}
	// --------------------------------------------------------------------------

	// privateメソッド -----------------------------------------------------------
	/**
	 * 本人確認API完了時に実行される処理
	 */
	private rcNext(): void {
		this.m_isCompleteIdentification = true;
		this.refreshView();
	}
	// --------------------------------------------------------------------------
}
