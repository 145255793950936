import { WprValueMap } from '../../../../wpr-framework/model/WprValueMap';
import { WprValueMapSetter } from '../../../../wpr-framework/model/WprValueMapSetter';
import { WprModel } from '../../../../wpr-framework/view/deco/WprModel';
import { WprDI_ScopeModel } from '../../../../wpr-framework/view/di/WprDI_ScopeModel';
import { MypageWizardMngr } from '../../wizard/models/MypageWizardMngr';

/**
 * 割引種類
 */
export class DiscountSelectValueMap {

	// static メソッド  --------------------------------------------------------
	public static addValueMap(vmSetter: WprValueMapSetter): void {
		let vm = new WprValueMap();
		vmSetter.addValueMap('割引種類', vm);
	}
	// --------------------------------------------------------------------------
}