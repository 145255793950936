import React, { ReactNode } from 'react';
import { WprBaseViewComponent } from '../../../../wpr-framework/view/component/WprBaseViewComponent';
import { AddMeshWifiConfirmView } from './AddMeshWifiConfirmView';
import { Typography, Grid, Divider } from '@material-ui/core';
import { WprSpan } from '../../../../wpr-framework/component/controls/WprSpan';
import { MatButton } from '../../../../wpr-framework/component/controls/material/MatButton';
import { MatTypography } from '../../../../wpr-framework/component/controls/material/MatTypography';
import { ProgressBar } from '../../common/progress_bar/ProgressBar';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import important from '/shared/image/common/important_purple.png'
import { WprContainer } from '../../../../wpr-framework/component/controls/WprContainer';
import styles from './AddMeshWifiConfirm.scss';
import { MatSelect } from '../../../../wpr-framework/component/controls/material/MatSelect';

/**
 * メッシュWi-Fi 追加申込み内容の確認用コンポーネント
 */
export class AddMeshWifiConfirm extends WprBaseViewComponent {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: any) { super(new AddMeshWifiConfirmView(), props); }
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** 申込み内容の確認ビュー情報 */
	public get viewInfo(): AddMeshWifiConfirmView	{ return this.view as AddMeshWifiConfirmView;	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * スタイルオブジェクト取得
	 */
	public getStylesObject(): any { return styles; }

	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		return (
			<>
			<div className='pageCard mainPage'>
				<div className="pageHeader stepFlow">
					<ProgressBar name='progressBar' view={this.view} />
				</div>
				<div className='main message'>
					<div className='pageTitle'>
						<div className='title_left'>
						<MatTypography name='title' className='title_text' view={this.view}/>
							<Typography className='title_comment'>以下の内容でよろしければ、「申込む」ボタンをクリックしてください。</Typography>
						</div>
					</div>
					<div className='feeTable' style={{paddingTop:'18px'}}>
						<div className='feeTable'>
							<Grid container className='feeRow'>
								<Grid item xs={12} md={4} className='feeTitle' >
									<div>現在の月額利用料の合計</div>
								</Grid>
								<Grid item xs={12} md={8} className='feeVal courseValue'>
									<MatTypography name='curFee' className='itemUnit unit' view={this.view}/>
									<span className='itemUnit unit' style={{fontWeight: '500'}}>円</span>
								</Grid>
							</Grid>
							<Grid container className='feeRow'>
								<Grid item xs={12} md={4} className='feeTitle' >
									<div>新しい月額利用料の合計</div>
								</Grid>
								<Grid item xs={12} md={8} className='feeVal courseValue' style={{ color: 'black' }}>
									<MatTypography name={'newFee'} className='newFeeUnit' view={this.view} />
									<span className='feeUnit_bold'>円</span>
								</Grid>
							</Grid>
						</div>
					</div>
					<div className='itemTitle title_maru' style={{marginBottom: '10px'}}>追加申込み内容</div>
					<div className='feeTable' style={{marginTop:'0px'}}>
						<Grid container className='feeRow'>
							<Grid item xs={12} md={4} className='feeTitle' >
								<div>申込みコースの月額</div>
							</Grid>
							<Grid item xs={12} md={8} className='feeVal courseValue'>
								<MatTypography name='courseFee' className='itemUnit unit' view={this.view}/>
								<span className='itemUnit unit' style={{fontWeight: '500'}}>円</span>
							</Grid>
						</Grid>
						<Grid container className='feeRow tableBorder'>
							<Grid item xs={12} md={4} className='feeTitle' >
								<div>申込みコース</div>
							</Grid>
							<Grid item xs={12} md={8} className='feeVal courseValue'>
								<span className='itemUnit unit' style={{fontWeight: '500'}}>メッシュWi-Fi</span>
							</Grid>
						</Grid>
						<Grid container className='feeRow tableBorder'>
							<Grid item xs={12} md={4} className='feeTitle' >
								<div>機器のご提供方法</div>
							</Grid>
							<Grid item xs={12} md={8} className='feeVal courseValue'>
								<span className='itemUnit unit' style={{fontWeight: '500'}}>レンタル</span>
							</Grid>
						</Grid>
						<Grid container className='feeRow tableBorder'>
							<Grid item xs={12} md={4} className='feeTitle' >
								<div>設置方法</div>
							</Grid>
							<Grid item xs={12} md={8} className='feeVal courseValue'>
								<MatTypography name='establishment' className='itemUnit unit' view={this.view}/>
							</Grid>
						</Grid>
						<Grid container className='feeRow'>
							<Grid item xs={12} md={4} className='feeTitle' >
								<div>工事費・機器発送費</div>
							</Grid>
							<Grid item xs={12} md={8} className='feeVal courseValue'>
								<MatTypography name='countructionCost' className='itemUnit unit' view={this.view}/>
								<WprContainer mode='工事費単位.表示' view={this.view}>
									<span className='itemUnit unit' style={{fontWeight: '500'}}>円</span>
								</WprContainer>
							</Grid>
						</Grid>
					</div>
					<WprContainer mode={'とことんサポート.表示'} view={this.view}>
						<div className='feeTable' style={{marginBottom: '0px'}}>
							<Grid container className='feeRow'>
								<Grid item xs={12} md={4} className='feeTitle table_header'>
									<div>とことんサポート</div>
								</Grid>
								<Grid item xs={12} md={8} className='feeVal courseValue'>
									<span className='itemUnit unit' style={{fontWeight: '500'}}>月額：<MatTypography className='itemUnit unit' name='amount' view={this.view}/>円</span>
								</Grid>
							</Grid>
						</div>
						<div className='supportMsgArea'>
							<ul className='aster messageText'>
								<span className='textcolor'>
									<img src={important}></img>
									ご請求について			
								</span>
								<li className='comment' style={{marginLeft: '5px'}}>とことんサポートは利用開始日の翌月分より請求が発生いたします。</li>
							</ul>
						</div>
					</WprContainer>
					<WprContainer mode='工事エリア.表示' view={this.view}>
						<div className='title_space'>
							<div className='itemTitle title_maru'>作業の希望日時</div>
						</div>
						<div className='feeTable'>
							<Grid container className='feeRow'>
								<Grid item xs={12} md={4} className='feeTitle'>
									<div>第一希望</div>
								</Grid>
								<Grid item xs={12} md={8} className='feeVal courseValue value'>
									<MatTypography name='desiredDate1' view={this.view} className='itemUnit' style={{marginRight: '20px'}}/>
									<MatSelect name='selectTime1' vm='時間帯' view={this.view} className='itemSelect'/>
								</Grid>
							</Grid>	
							<Grid container className='feeRow'>
								<Grid item xs={12} md={4} className='feeTitle'>
									<div>第二希望</div>
								</Grid>
								<Grid item xs={12} md={8} className='feeVal courseValue value'>
									<MatTypography name='desiredDate2' view={this.view} className='itemUnit' style={{marginRight: '20px'}}/>
									<MatSelect name='selectTime2' vm='時間帯' view={this.view} className='itemSelect'/>
								</Grid>
							</Grid>	
							<Grid container className='feeRow'>
								<Grid item xs={12} md={4} className='feeTitle'>
									<div>第三希望</div>
								</Grid>
								<Grid item xs={12} md={8} className='feeVal courseValue value'>
									<MatTypography name='desiredDate3' view={this.view} className='itemUnit' style={{marginRight: '20px'}}/>
									<MatSelect name='selectTime3' vm='時間帯' view={this.view} className='itemSelect'/>
								</Grid>
							</Grid>	
						</div>
						<div className='feeTable'>
							<Grid container className='feeRow'>
								<Grid item xs={12} md={4} className='feeTitle'>
									<div>前日連絡先</div>
								</Grid>
								<Grid item xs={12} md={8} className='feeVal courseValue value'>
									<MatTypography name='beforeContact' view={this.view} className='itemUnit' style={{marginRight: '20px'}}/>
								</Grid>
							</Grid>	
							<Grid container className='feeRow'>
								<Grid item xs={12} md={4} className='feeTitle'>
									<div>当日連絡先</div>
								</Grid>
								<Grid item xs={12} md={8} className='feeVal courseValue value'>
									<MatTypography name='sameDayContact' view={this.view} className='itemUnit' style={{marginRight: '20px'}}/>
								</Grid>
							</Grid>	
							<Grid container className='feeRow'>
								<Grid item xs={12} md={4} className='feeTitle'>
									<div>当日立ち合い者</div>
								</Grid>
								<Grid item xs={12} md={8} className='feeVal courseValue value'>
									<MatTypography name='name' view={this.view} className='itemUnit' style={{marginRight: '20px'}}/>
								</Grid>
							</Grid>	
						</div>
					</WprContainer>
					<div>
						<ul className='ul_kome attention_fontSize'>
							<li>
								表示されている月額は定価となります。実際の請求金額とは異なる場合があります。
							</li>
							<li>
								合計月額には割引も適用されています。
							</li>
							<WprContainer mode='請求書有料発行.表示' view={this.view}>
								<li>
									現在お支払い方法が未設定または現金振込となっております。<br/>
									お支払いが発生した際は、請求書（発行手数料330円［税込］/通）をお送りいたします。<br/>
									クレジットカード、口座振替へお支払い方法の変更が可能です。詳細はこちらをご確認ください。
								</li>
							</WprContainer>
						</ul>
					</div>
				</div>
				<Divider />
				<div className='pageFooter footer-space'>
					<div>
						<MatButton name='next' view={this.view} className='orangeButton orangeButton_parts' >
							この内容で申込む
							<NavigateNextIcon />
						</MatButton>
					</div>
					<div>
						<MatButton name='back' view={this.view} className='backButton' >
							<NavigateBeforeIcon />
							戻る
						</MatButton>
					</div>
				</div>
			</div>
			</>
		);
	}
	// --------------------------------------------------------------------------
}
