import { MypageBaseView } from '../../../base/MypageBaseView';
import { WprModel } from '../../../../../wpr-framework/view/deco/WprModel';
import { WprListData } from '../../../../../wpr-framework/view/deco/WprListData';
import { ProgressBarData } from '../../../common/progress_bar/ProgressBarView';
import { WprViewMode } from '../../../../../wpr-framework/view/deco/WprViewMode';
import { OptListMode } from './mode/OptListMode';
import { MypageWizardMngr } from '../../../wizard/models/MypageWizardMngr';
import { WprDI_ScopeModel } from '../../../../../wpr-framework/view/di/WprDI_ScopeModel';
import { MCommon } from '../../../../service/models/entity/primary/MCommon';
import { OtokuMode } from './mode/OtokuMode';
import { WprValidError } from '../../../../../wpr-framework/view/validation/WprValidError';
import { WprDI_Control } from '../../../../../wpr-framework/view/di/WprDI_Control';
import { WprControlInfo } from '../../../../../wpr-framework/view/control/WprControlInfo';
import { ObjectUtil } from '../../../../common/util/ObjectUtil';
import MypageDispdivcdConstants from '../../../../common/MypageDispdivcdConstants';
import { ItscomHomeCourseListModel } from '../../../../models/mypage/smart/ItscomHomeCourseListModel';
import { ItscomHomeOptionListModel } from '../../../../models/mypage/smart/ItscomHomeOptionListModel';
import { BaseInfo } from '../../../../models/mypage/smart/BaseInfo';
import { ItscomHomeNewWizardModel } from '../../../wizard/ItscomHomeNewWizardFlow';
import { ErrorModel } from '../../../../models/mypage/smart/ErrorModel';

/**
 * ITSCOM HOME_追加申し込み(コース内容の選択)
 */
export class ItscomHomeSelect2View extends MypageBaseView {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() { super('ItscomHomeSelect2View'); }
	// --------------------------------------------------------------------------

	// DI情報  ------------------------------------------------------------------
	@WprDI_Control
	private m_rental: WprControlInfo	= null;	// レンタル
	@WprDI_Control
	private m_purchase: WprControlInfo	= null;	// 購入
	@WprDI_Control
	private m_yesOtoku: WprControlInfo	= null;	// 希望する
	@WprDI_Control
	private m_noOtoku: WprControlInfo	= null;	// 希望しない
	// --------------------------------------------------------------------------

	// モデル  ------------------------------------------------------------------
	@WprDI_ScopeModel('MypageWizardMngr')
	private m_MypageWizardMngr: MypageWizardMngr			= null;	// 画面ウィザード管理クラス
	@WprModel('BaseInfo')
	private m_BaseInfo: BaseInfo							= null;	// 基本情報
	@WprListData('CourseListModel')
	private m_CourseListModel: ItscomHomeCourseListModel[]	= null;	// ItscomHomeコースモデル
	@WprListData('OptListModel')
	private m_OptionListModel: ItscomHomeOptionListModel[]	= null;	// ItscomHomeオプションモデル
	@WprListData('ErrorModel')
	private m_ErrorModelList: ErrorModel[]					= null;	// エラーモデル
	// --------------------------------------------------------------------------
	
	// private変数 ---------------------------------------------------------------
	private m_BuyOptionList: ItscomHomeOptionListModel[] 	= null;	// 購入可能オプションリスト
	// --------------------------------------------------------------------------

	// サブビュー/モード  --------------------------------------------------------
	@WprViewMode
	private m_OptListMode = new OptListMode();	// オプションリストモード
	@WprViewMode
	private m_OtokuMode = new OtokuMode();	// お得パックモード
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * コントロール定義設定
	 */
	public onRegisterConfig(): void {
		// オプションリスト項目
		this.addListConfig('option', 'オプション', 'OptListModel');
		this.addListConfig('rental', '月額レンタル料', 'OptListModel', { converter: '3桁カンマ' });
		this.addListConfig('rentalUnit', '単位(レンタル)', 'OptListModel');
		this.addListConfig('quantity1', '数量1', 'OptListModel', { maxLength: 2 });
		this.addListConfig('price', '購入価格', 'OptListModel', { converter: '3桁カンマ' });
		this.addListConfig('priceUnit', '価格単位(購入)', 'OptListModel');
		this.addListConfig('quantity2', '数量2', 'OptListModel', { maxLength: 2 });
		this.addListConfig('buyUnit', '数量単位(購入)', 'OptListModel');
		// 基本情報
		this.addConfig('course', 'コース', 'BaseInfo.courseList');
		this.addConfig('amount', '金額', 'BaseInfo.courseList', { converter: '3桁カンマ' });
		this.addConfig('rental', 'レンタル', 'BaseInfo');
		this.addConfig('purchase', '購入', 'BaseInfo');
		this.addConfig('yesOtoku', '希望する', 'BaseInfo');
		this.addConfig('noOtoku', '希望しない', 'BaseInfo');
		// オプションモード制御
		this.addListConfig('ownerIhPrice', '購入金額(オーナーIH)', 'OptListModel');
		this.addListConfig('otherPrice', '購入金額(オーナーIH以外)', 'OptListModel');
		this.addListConfig('ownerIhRental', 'レンタル金額(オーナーIH)', 'OptListModel');
		this.addListConfig('otherRental', 'レンタル金額(オーナーIH以外)', 'OptListModel');
		this.addListConfig('buyableUnit', '数量(購入可能)', 'OptListModel');
		this.addListConfig('notBuyableUnit', '数量(購入不可)', 'OptListModel');
		// エラーメッセージ
		this.addListConfig('errorMessage', 'エラーメッセージ', 'ErrorModel');
	}

	/**
	 * アクション登録
	 */
	public onRegisterAction(): void {
		this.addAction('next', this.onNext, true);
		this.addAction('back', this.onBack);
	}

	/**
	 * ビュー表示通知
	 */
	public onShowView(): void {
		this.m_MypageWizardMngr.initView(this, (result) => {
			this.registAccessLog();
			this.setStorageItem('b_url', window.location.href);
			const sendData: ProgressBarData = new ProgressBarData();
			sendData.progressBarList = this.m_MypageWizardMngr.getProgressModelList(this);
			this.sendChildData('fP_ev_progressBarIntital', sendData);
			const WizardModel = new ItscomHomeNewWizardModel();
			WizardModel.setOptionDisplay(this);	// オプションリストPC/SP判定
			// 基本情報
			this.m_BaseInfo = this.m_MypageWizardMngr.getCache().m_BaseInfo;
			this.m_CourseListModel = this.m_MypageWizardMngr.getCache().m_CourseListModel;
			const courseList  = this.m_CourseListModel.filter(row => row.check1 === true);
			this.m_BaseInfo.courseList = courseList[0];
			if (!this.m_BaseInfo.purchase)
				this.m_BaseInfo.rental = true;
			// コース
			const courseInfoList = this.m_MypageWizardMngr.getMCommonList(this, 'srvsmart', 'srv_smart_kind', null, null);
			const tvPushCourseList = courseInfoList.filter(row => row.category3 === 'srv_tv_push');	
			this.checkSelectCourse(tvPushCourseList);	// 前画面で選択されたコースIH/TVか判定(※現行側に合わせて実装)
			// 購入可能オプションリスト
			const buyOptionList = this.m_MypageWizardMngr.getMCommonList(this, 'srvsmart', 'buy_opt', null, null);
			this.setBuyOptionList(buyOptionList);	// 判定用モデルに詰替え
			this.setOptionList(); 					// オプションリスト作成
			this.setOtokuArea();					// お得パック判定
			this.refreshView();
		});
	}

	/**
	 * 値変更通知
	 * @param name 名前
	 * @param value 値
	 * @returns falseの場合、変更を取り消す
	 */
	public onChangeValue(name: string, value: any): boolean {
		// 機器提供方法
		if (name === 'rental')
			this.m_purchase.value = false;
		if (name === 'purchase') {
			this.m_rental.value = false;
			// 購入の場合、お得パックは申込み不可
			if (this.m_yesOtoku.value) {
				this.m_yesOtoku.value = false;
				this.m_noOtoku.value = true;
			}
		}
		// お得パック適用
		if (name === 'yesOtoku')
			this.m_noOtoku.value = false;
		if (name === 'noOtoku')
			this.m_yesOtoku.value = false;
		return true;
	}

	/**
	 * リスト値変更通知
	 * @param name 名前
	 * @param listName リスト名
	 * @param row 行データ
	 * @param value 値
	 * @returns falseの場合、変更を取り消す
	 */
	public onChangeListValue(name: string, listName: string, row: any, value: any): boolean {
		if (listName === 'OptListModel')
			this.refreshListModel('OptListModel');
		return true;
	}

	/**
	 * 入力チェック（独自のValidationを実施する場合に使用する）
	 *	エラー追加例：validError.addError('エラーメッセージ');				// 相関チェックなどのエラー
	 * 				 this.setControlError('control', 'error');			 // コントロールのエラー
	 * 				 validError.showErrorMessage.('エラーメッセージ');	   // エラーメッセージ表示
	 * 		※上記のエラーを追加した場合は、アクションは実行しません。
	 * 		※コントロールの値をチェックする場合は、refreshModelは使用しないでください。
	 * 		　コントロールの値をチェックする場合は、コントロールをDIしてvalueを使用してください。
	 * 		　コントロールが双方向のコンバータを使用している場合は、dataValueを使用してください。
	 * @param actionName アクション名
	 * @param validError バリデーションエラー情報
	 * @param row 行情報
	 */
	protected onValid(actionName: string, validError: WprValidError, row?: any): void {
		if (actionName === 'next') {
			this.m_ErrorModelList = new Array();
			for (const option of this.m_OptionListModel) {
				// レンタル数量
				if (option.quantity1) {
					// 数字チェック
					let numFlg = false;
					option.quantity1.match(/^([0-9０-９]*|0)$/)? numFlg = true : numFlg = false;
					if (!numFlg) {
						const model = new ErrorModel();
						model.errorMessage = '「' + option.option + '」のレンタル数量は正数で入力して下さい';
						this.m_ErrorModelList.push(model);
					}
					else {
						// 全角→半角変換
						option.quantity1 = option.quantity1.replace(/[０-９]/g, function(s) {
							return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
						});
					}
				}
				// 購入数量
				if (option.quantity2) {
					let numFlg = false;
					option.quantity2.match(/^([0-9０-９]*|0)$/)? numFlg = true : numFlg = false;
					if (!numFlg) {
						const model = new ErrorModel();
						model.errorMessage = '「' + option.option + '」の購入数量は正数で入力して下さい';
						this.m_ErrorModelList.push(model);
					}
					else {
						// 全角→半角変換
						option.quantity2 = option.quantity2.replace(/[０-９]/g, function(s) {
							return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
						});
					}
				}
			}
			if (this.m_ErrorModelList.length !== 0) {
				this.setViewMode('エラー','表示')
				this.setListData('ErrorModel', this.m_ErrorModelList);
				validError.addError('エラー');		
			}
			this.refreshListView('OptListModel');
		}
	}
	// --------------------------------------------------------------------------

	// アクション  ---------------------------------------------------------------
	/**
	 * 次へ
	 */
	public onNext(param: any): void {
		this.refreshModel();
		this.m_MypageWizardMngr.goNext(this);
	}

	/**
	 * 戻る
	 */
	public onBack(param: any): void {
		// 初期化(入力内容)
		this.m_MypageWizardMngr.getCache().m_OptionListModel = new Array();
		this.m_MypageWizardMngr.getCache().m_BaseInfo.purchase = false;
		this.m_MypageWizardMngr.getCache().m_BaseInfo.yesOtoku = false;
		this.m_MypageWizardMngr.goBack(this);
	}
	// --------------------------------------------------------------------------
	// privateメソッド  ----------------------------------------------------------
	/**
	 * オプションリスト表示処理
	 * 注釈だし分け
	 */
	private setOptionList() {
		this.m_OptionListModel = this.m_MypageWizardMngr.getCache().m_OptionListModel;
		// IHの場合のみ、リスト表示
		if (this.m_OptionListModel.length === 0 && this.m_BaseInfo.ihFlag) {
			const optionServiceList = this.m_MypageWizardMngr.getServiceList(this, MypageDispdivcdConstants.ITSCOMHOME_OPTION);
			// オプションリスト絞り込み：genecdがnullのリスト
			const optionInfoList = optionServiceList.filter(row => ObjectUtil.isNullOrUndefined(row.display.genecd));
			const smartLigntUnit = this.m_MypageWizardMngr.getMCommonList(this, 'srvsmart', 'count_type_ko', null, null);
			for (const optionInfo of optionInfoList) {
				const model = new ItscomHomeOptionListModel()
				model.option = optionInfo.display.dispnamep;		
				model.rental = this.m_MypageWizardMngr.getTaxFee(this, String(optionInfo.service.payment[0].amount));
				model.rentalUnit = '台';
				model.price = '-';									// 購入金額
				model.buyFlag = false;								// 購入可能フラグ
				model.ownerIhFlag = false;							// オーナーIHフラグ
				model.childCd = optionInfo.service.serviceCD;
				// 購入可能判定
				for (const list of this.m_BuyOptionList) {
					if (list.childCd === optionInfo.service.serviceCD) {
						model.buyFlag = true;
						model.priceUnit = '円';
						model.price = this.m_MypageWizardMngr.getTaxFee(this, String(list.price));
						// スマートライトの場合、数量単位(レンタル)は「個」、数量単位(購入)は「個」設定
						if (optionInfo.service.serviceCD ===smartLigntUnit[0].commonValue) {
							model.buyUnit = '個';
							model.rentalUnit = '個';
						}
					}
				}
				// オーナー契約がIHの場合
				if (this.m_BaseInfo.ownerIhflag)
					model.ownerIhFlag = true;
				this.m_OptionListModel.push(model);
			}
		}
		// TVの場合、リスト非表示
		else if (this.m_BaseInfo.tvFlag) {
			this.setViewMode('オプションなし','表示');
			this.m_OptionListModel = new Array(); // 初期化
		}
		this.setListData('OptListModel', this.m_OptionListModel);
	}

	/**
	 * 判定用オプションリスト詰替え(codeとamountを1つのリストに格納)
	 * @param buyOptionList 
	 */
	private setBuyOptionList(buyOptionList: MCommon[]) {
		this.m_BuyOptionList = this.m_MypageWizardMngr.getCache().m_BuyOptionList;
		const model = new ItscomHomeOptionListModel();
		for (const list of buyOptionList) {
			// コードの場合
			if (list.category4 === 'code')
				model.childCd = list.commonValue;
			// 料金の場合
			if (list.category4 === 'amount')
				model.price = list.commonValue;
		}
		this.m_BuyOptionList.push(model);
	}

	/**
	 * 選択コース判定
	 */
	private checkSelectCourse(tvCourseList: MCommon[]) {
		for (const list of tvCourseList) {	// TV判定
			if (this.m_BaseInfo.courseList.serviceCd === list.commonValue) {
				this.m_BaseInfo.tvFlag = true;
				this.m_BaseInfo.ihFlag = false;
				break;
			}
			else {
				this.m_BaseInfo.ihFlag = true;
				this.m_BaseInfo.tvFlag = false;	
			}
		}
		// IHの場合
		if (this.m_BaseInfo.ihFlag) {
			this.setViewMode('ヘルプ','IH');
			this.setViewMode('注釈IH','表示');
		}
		// TVの場合
		else {
			this.setViewMode('ヘルプ','TV');
			this.setViewMode('注釈TV','表示');
		}
		// オーナー契約IHの場合
		if (this.m_BaseInfo.ownerIhflag) {
			this.setViewMode('注釈OwnerIH','表示');
			this.setViewMode('申込みコース','IH');
		}
		// オーナー契約IH以外の場合
		else
			this.setViewMode('申込みコース','IH以外');		
	}
	
	/**
	 * お得パック表示判定
	 */
	private setOtokuArea() {
		const viewInfo = this.m_MypageWizardMngr.getViewInfo();
		this.m_BaseInfo.otokuFlag = false;
		// 申込み可
		if (this.m_MypageWizardMngr.showOtokuPlan(this, viewInfo.customerPayment[0].payment[0], viewInfo.contractModel.planList)) {
			this.setViewMode('お得パックエリア','表示');
			if (!this.m_BaseInfo.yesOtoku && !this.m_BaseInfo.noOtoku)
				this.m_BaseInfo.noOtoku = true;	// デフォルト表示
		}
		// 申込み不可
		else {
			const otokuPack = this.m_MypageWizardMngr.getOtokuPlanName(this, viewInfo.contractModel.planList);
			if (ObjectUtil.isNotNullOrUndefined(otokuPack)) {
				this.m_BaseInfo.otokuFlag = true;
				this.m_BaseInfo.otokuName = '(※【' + otokuPack.dispnamep + '】割引適用)'
			}
		}
	}
	// --------------------------------------------------------------------------
}
