import { WprValueMap } from '../../../../wpr-framework/model/WprValueMap';
import { WprValueMapSetter } from '../../../../wpr-framework/model/WprValueMapSetter';

/**
 * 日
 */
export class DayValueMap {
	// static メソッド  --------------------------------------------------------
	public static addValueMap(vmSetter: WprValueMapSetter): void {
		let vm = new WprValueMap();
		for (let i: number=0; i<31; i++) {
			vm.addValue((i+1).toString(), (i+1).toString());
		}
		vmSetter.addValueMap('day', vm);
	}
	// --------------------------------------------------------------------------
}
