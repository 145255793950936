import { WprBaseViewCore } from '../WprBaseViewCore';
import { WprFramework } from '../../WprFramework';

/**
 * モデル参照デコレータ
 * @param modelName モデル名称
 */
export function WprModel(/* target: any, props: string,*/ modelName?: string) {
	WprBaseViewCore.modelNameList.push(modelName);
	// return <TFunc extends Function> (target: any, props: string) => {
	return (target: any, props: string) => {
		const viewCore = target as WprBaseViewCore;
		WprFramework.getInstance().model.addDecoMapInfo('ModelName', viewCore, WprBaseViewCore.modelNameList.shift(), props);
		const baseName = viewCore.getBaseName();
		if (baseName)
			WprFramework.getInstance().model.addBaseDIInfo(baseName, viewCore);
	};
}
