import { WprBaseObject } from '../../../WprBaseObject';
import { WprBaseViewCore } from '../../WprBaseViewCore';
import { IWprConfigOption } from '../IWprConfigOption';
import { WprReflectUtil } from '../../../util/WprReflectUtil';
import { WprControlInfo } from '../WprControlInfo';
import { WprValidError } from '../../validation/WprValidError';
import { WprListControlInfo } from './WprListControlInfo';
import { WprRowInfo } from '../../../component/props/WprRowInfo';
import { IWprTableRow } from '../../../mode/table/IWprTableRow';
import { WprEventActionInfo, WprEventName } from '../../../action/WprEventActionInfo';

/**
 * リスト情報
 */
export class WprListInfo extends WprBaseObject {
	// private 変数  ------------------------------------------------------------
	private m_Name: string									= null;										// リスト名
	private m_TotalCount: number							= null;										// 総行数（ページング時に使用）
	private m_ListData: Array<any>							= null;										// リストデータ
	private m_ViewCore: WprBaseViewCore						= null;										// View情報
	private m_ControlMap: Map<string, WprListControlInfo>	= new Map<string, WprListControlInfo>();	// コントロール情報マップ
	private m_SaveListData: Array<any>						= null;										// 保存リストデータ
	private m_Selectable: boolean							= false;									// 選択可能フラグ
	private m_SelectRow: any								= null;										// 選択行データ
	private m_SelectLock: boolean							= false;									// 選択ロックフラグ
	private m_CompList: IWprTableRow[]						= new Array();								// 行コンポーネントリスト
	private m_EventActionMap: Map<string, WprEventActionInfo> = new Map<string, WprEventActionInfo>();	// イベントアクション情報マップ
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	public constructor(viewCore: WprBaseViewCore, name: string) {
		super();
		this.m_Name = name;
		this.m_ViewCore = viewCore;
	}
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** リスト名 */
	public get name(): string 									{ return this.m_Name; 		}
	/** 総行数（ページング時に使用） */
	public get totalCount(): number 							{ return this.m_TotalCount; }	public set totalCount(page: number) { this.m_TotalCount = page;	}
	/** リストデータ */
	public get listData(): Array<any> 							{ return this.m_ListData; 	}
	/** ビュー情報 */
	public get viewCore(): WprBaseViewCore 						{ return this.m_ViewCore; 	}
	/** コントロール情報マップ */
	public get controlMap(): Map<string, WprListControlInfo> 	{ return this.m_ControlMap; }
	/** データタイプ */
	public get dataType(): string 								{ return 'リスト'; 			}
	/** 選択可能フラグ */
	public get selectable(): boolean 							{ return this.m_Selectable; }	public set selectable(selectable: boolean) { this.m_Selectable = selectable;	}
	/** 選択ロックフラグ */
	public get selectLock(): boolean 							{ return this.m_SelectLock; }
	// --------------------------------------------------------------------------

	// public メソッド  ---------------------------------------------------------
	/**
	 * コントロール定義追加処理
	 * @param name コントロール名
	 * @param description 説明
	 * @param option オプション
	 */
	public addConfig(name: string, description: string, option?: IWprConfigOption): WprListControlInfo {
		if (this.m_ControlMap.has(name) === false) {
			const cinfo = new WprListControlInfo(this, this.m_ViewCore, name, description, option, null);
			this.m_ControlMap.set(name, cinfo);
			return cinfo;
		}
		else {
			if (this.m_ControlMap.get(name).useFormControl === true)
				this.m_ViewCore.addErrorLog(`同一のコントロール定義が既に登録されています。[${name}]`);
		}
		return null;
	}

	/**
	 * リストデータ設定
	 * @param ldata リストデータ
	 */
	public setListData(ldata: Array<any>) {
		// ATODE
		this.m_ListData = ldata;
		// this.clear();
		for(const info of ldata) {
			if (this.m_SaveListData == null || this.m_SaveListData.includes(info) == false) {
				this.m_ControlMap.forEach((cinfo, key, map) => {
					cinfo.addControl();
				});
			}
		}
		this.m_SaveListData = new Array();
		for(const info of ldata)
			this.m_SaveListData.push(info);
		this.refreshView();
	}

	/**
	 * リスト権限再設定
	 */
	public refreshAuth() {
		if (this.m_ListData) {
			let idx: number = 0;
			for(const row of this.m_ListData) {
				for(const cinfo of this.m_ControlMap.values()) {
					cinfo.rowIndex = idx;
					cinfo.setControlCustumAuth(idx);
				}
				idx++;
			}
		}
		this.updateSelect();
	}

	/**
	 * ビューをリストデータに反映する
	 */
	public refreshModel() {
		if (this.m_ListData) {
			let idx: number = 0;
			for(const row of this.m_ListData) {
				for(const cinfo of this.m_ControlMap.values()) {
					cinfo.rowIndex = idx;
					if (cinfo.useFormControl === true) {
						let modelInfo = row;
						if (cinfo.subModelList != null) {
							modelInfo = this.getSubModel(modelInfo, cinfo.subModelList);
							if (modelInfo == null) {
								this.m_ViewCore.addErrorLog(`MODELが見つかりませんでした。[${this.m_Name}][${cinfo.configModel}]`);
								continue;
							}
						}
						if (cinfo.getRowControlInfo().isReadOnly() === false) {
							let val = cinfo.getControlValue(idx);
							if (this.checkEmpty(val) === true && cinfo.config.emptyValue !== undefined)
								val = cinfo.config.emptyValue;
							else if (cinfo.config.converter != null)
								val = cinfo.config.converter.convertValue(val);
							if (WprReflectUtil.checkProperty(modelInfo, cinfo.bindName) === true)
								WprReflectUtil.setProperty(modelInfo, cinfo.bindName, val);
							else
								modelInfo[cinfo.bindName] = val;
						}
						/*
						if (WprReflectUtil.checkProperty(modelInfo, cinfo.bindName) === true) {
							let val = cinfo.getControlValue(idx);
							if (cinfo.config.converter != null)
								val = cinfo.config.converter.convertValue(val);
							WprReflectUtil.setProperty(modelInfo, cinfo.bindName, val);
						}
						else {
							this.m_ViewCore.addErrorLog(`${this.dataType}データのプロパティが見つかりませんでした。[${this.m_Name}}][${cinfo.bindName}}]`);
						}
						*/
					}
				}
				idx++;
			}
		}
	}

	/**
	 * 値をクリアする
	 * @param listName リスト名
	 * @param rowData 行データ 
	 * @returns trueの場合、成功
	 */
	public clearValue(listName?: string, rowData?: any): void {
		if (this.m_ListData) {
			let idx: number = 0;
			for(const row of this.m_ListData) {
				for(const cinfo of this.m_ControlMap.values()) {
					let isSet = true;
					if (row !== undefined) {
						 if (rowData !== row)
							continue;
						else
							isSet = false;
					}
					cinfo.rowIndex = idx;
					if (cinfo.useFormControl === true) {
						if (cinfo.config.notClear === false) {
							cinfo.setControlValue(idx, '');
							if (isSet === true)
								cinfo.value = this.m_ViewCore.onGetListClearValue(cinfo.name, this.m_Name, row, cinfo.value);
						}
					}
				}
				idx++;
			}
		}
	}

	/**
	 * 入力チェック
	 * @param listName リスト名
	 * @return trueの場合、入力済みでエラーなし
	 */
	public isInput(listName?: string): boolean {
		if (this.m_ListData) {
			let idx: number = 0;
			for(const row of this.m_ListData) {
				for(const cinfo of this.m_ControlMap.values()) {
					cinfo.rowIndex = idx;
					if (cinfo.useFormControl === true) {
						const rcinfo = cinfo.getRowControlInfo();
						if (rcinfo.isReadOnly() === false) {
							if (!rcinfo.value || rcinfo.isInvalid === true && rcinfo.validError !== null)
								return false;
						}
					}
				}
				idx++;
			}
		}
		return true;
	}

	/**
	 * クリア処理
	 */
	public clear(): void {
		this.m_ControlMap.forEach((cinfo, key, map) => {
			cinfo.clearControl();
		});
	}

	/**
	 * 行インデクス設定
	 * @param idx 行インデクス
	 */
	public setRowIndex(idx: number): void {
		this.m_ControlMap.forEach((cinfo, key, map) => {
			cinfo.rowIndex = idx;
		});
	}

	/**
	 * コントロール情報取得
	 * @param name コントロール名
	 * @returns コントロール情報
	 */
	public getRowControlInfo(name: string, index?: number): WprControlInfo {
		if (this.m_ControlMap.has(name) === true) {
			if (index !== undefined)
				this.m_ControlMap.get(name).rowIndex = index;
			return this.m_ControlMap.get(name).getRowControlInfo();
		}
		return null;
	}

	/**
	 * カテゴリコントロール情報リスト取得
	 * @param category カテゴリ名
	 * @returns コントロール情報リスト
	 */
	public getRowCategoryControlList(category: string, index?: number): WprControlInfo[] {
		const list = new Array();
		this.m_ControlMap.forEach(cinfo => {
			if (cinfo.config.categoryList) {
				if (cinfo.config.categoryList.includes(category) === true) {
					if (index !== undefined)
						cinfo.rowIndex = index;
					list.push(cinfo.getRowControlInfo());
				}
			}
		});
		return list;
	}

	/**
	 * 入力チェック処理
	 * @param actionName アクション名称
	 * @param validError バリデーションエラー情報
	 * @param isControl コントロールチェックフラグ
	 * @param row 行情報
	 */
	public checkValidate(actionName: string, validError: WprValidError, isControl: boolean, row?: any) {
		if (this.m_ListData) {
			let idx: number = 0;
			for(const rowData of this.m_ListData) {
				if (!row || row === rowData) {
					this.m_ControlMap.forEach((cinfo, key, map) => {
						cinfo.rowIndex = idx;
						const rcinfo = cinfo.getRowControlInfo();
						rcinfo.rowIndex = idx;
						rcinfo.checkValidate(actionName, validError, isControl, true);
					});
				}
				idx++;
			}
		}
	}

	/**
	 * 入力エラーリスト取得
	 * @param errorList 入力エラーリスト
	 */
	public getValidErrorList(errorList: WprValidError[]): void {
		if (this.m_ListData) {
			let idx: number = 0;
			for(const row of this.m_ListData) {
				this.m_ControlMap.forEach(cinfo => {
					cinfo.rowIndex = idx;
					const rcinfo = cinfo.getRowControlInfo();
					rcinfo.rowIndex = idx;
					if (rcinfo.validError != null)
						errorList.push(rcinfo.validError);
				});
				idx++;
			}
		}
	}

	/**
	 * テーブル行コンポーネント設定
	 * @param tableRow テーブル行コンポーネント
	 * @param row 行情報
	 * @param selectable 選択可能フラグ
	 */
	public setRowComponent(tableRow: IWprTableRow, row: WprRowInfo) {
		this.m_CompList.push(tableRow);
	}

	/**
	 * テーブル行コンポーネント削除
	 * @param tableRow テーブル行コンポーネント
	 */
	public deleteRowComponent(tableRow: IWprTableRow) {
		if (this.m_CompList.includes(tableRow) === true) {
			const idx = this.m_CompList.indexOf(tableRow);
			this.m_CompList.splice(idx, 1);
		}
	}

	/**
	 * テーブル行選択変更チェック
	 * @param tableRow テーブル行コンポーネント
	 * @param row 行情報
	 * @returns trueの場合、選択変更
	 */
	public isChangeSelectRow(tableRow: IWprTableRow, row: WprRowInfo): boolean {
		if (this.m_SelectLock === false && this.m_SelectRow !== row.rowData)
			return true;
		return false;
	}

	/**
	 * テーブル行選択処理
	 * @param tableRow テーブル行コンポーネント
	 * @param row 行情報
	 * @returns trueの場合、選択変更
	 */
	public setSelectRow(tableRow: IWprTableRow, row: WprRowInfo): boolean {
		if (this.m_SelectLock === false) {
			this.m_SelectRow = row.rowData;
			this.updateSelect();
			return true;
		}
		return false;
	}

	/**
	 * 選択状態更新処理
	 */
	public updateSelect() {
		if (this.m_SelectRow != null && this.m_ListData.includes(this.m_SelectRow) === false)
			this.m_SelectRow = null;
		this.m_CompList.forEach(trow => {
			trow.setSelect(this.m_SelectRow);
		});
	}

	/**
	 * 行データ選択処理
	 * @param rowData 行データ
	 * @returns 行情報
	 */
	public selectListData(rowData: any): WprRowInfo {
		for (const trow of this.m_CompList) {
			const rowInfo = trow.getRowInfo();
			if (rowInfo.rowData === rowData) {
				this.setSelectRow(trow, rowInfo);
				return rowInfo;
			}
		}
		return null;
	}

	/**
	 * 行データ選択ロック設定
	 * @param lock ロックフラグ
	 */
	public setSelecctLock(lock: boolean) {
		if (this.m_SelectLock != lock)
			this.m_SelectLock = lock;
	}

	/**
	 * リストデータをビューに反映する
	 */
	 public refreshView(rdata?: any): void {
		if (this.m_ListData) {
			let idx: number = 0;
			for(const row of this.m_ListData) {
				if (rdata !== undefined && rdata !== row) {
					idx++;
					continue;
				}
				for(const cinfo of this.m_ControlMap.values()) {
					cinfo.rowIndex = idx;
					if (cinfo.useFormControl === true) {
						let modelInfo = row;
						if (cinfo.subModelList != null) {
							modelInfo = this.getSubModel(modelInfo, cinfo.subModelList);
							if (modelInfo == null) {
								this.m_ViewCore.addErrorLog(`MODELが見つかりませんでした。[${this.m_Name}][${cinfo.configModel}]`);
								continue;
							}
						}
						let val = null;
						if (WprReflectUtil.checkProperty(modelInfo, cinfo.bindName) === true)
							val = WprReflectUtil.getProperty(modelInfo, cinfo.bindName);
						else if (cinfo.getIsSetModeValue(idx) === true)
							continue;
//						if (cinfo.config.converter != null)
//							val = cinfo.config.converter.convertView(val);
						cinfo.setControlValue(idx, val);
						/*
						if (WprReflectUtil.checkProperty(modelInfo, cinfo.bindName) === true) {
							let val = WprReflectUtil.getProperty(modelInfo, cinfo.bindName);
							if (cinfo.config.converter != null)
								val = cinfo.config.converter.convertView(val);
							cinfo.setControlValue(idx, val);
						}
						else {
							this.m_ViewCore.addErrorLog(`${this.dataType}データのプロパティが見つかりませんでした。[${this.m_Name}}][${cinfo.bindName}}]`);
						}
						*/
					}
				}
				idx++;
			}
		}
		this.updateSelect();
	}

	/**
	 * ValueMapリセット処理
	 * @param rdata 行データ
	 * @param name 名前 
	 * @param clearValue 値クリアフラグ
	 * @returns trueの場合成功
	 */
	public resetValueMap(rdata: any, name: string, clearValue: boolean=true): void {
		if (this.m_ListData) {
			let idx: number = 0;
			for(const row of this.m_ListData) {
				if (rdata !== undefined && rdata !== row) {
					idx++;
					continue;
				}
				for(const cinfo of this.m_ControlMap.values()) {
					if (cinfo.name === name)
						cinfo.resetListValueMap(idx, clearValue);
				}
				idx++;
			}
		}
	}
	
	/**
	 * リストイベントアクション登録処理
	 * @param name アクション名
	 * @param eventName イベント名
	 * @param actionMethod リストアクションメソッド
	 */
	public addListEventAction(name: string, eventName: WprEventName, actionMethod: (name: any, row: WprRowInfo, event: any) => void): void {
		let ainfo = null;
		if (this.m_EventActionMap.has(name) === true) {
			ainfo = this.m_EventActionMap.get(name);
		}
		else {
			if (this.m_ControlMap.has(name) === true) {
				ainfo = new WprEventActionInfo(name, this.m_ViewCore, this.m_Name);
				this.m_EventActionMap.set(name, ainfo);
			}
			else {
				this.addErrorLog(`addEventAction:コントロール定義が見つかりません。[${name}]`);
			}
		}
		if (ainfo != null)
			ainfo.addEventMethod(eventName, actionMethod);
	}

	/**
	 * イベント情報取得
	 * @param name コントロール名
	 * @param events イベント情報
	 * @returns イベント情報
	 */
	public getEventsInfo(name: string, events?: any): any {
		if (this.m_EventActionMap.has(name) === true) {
			const ainfo = this.m_EventActionMap.get(name);
			return ainfo.getEvents(events);
		}
		else {
			if (events === undefined)
				this.addErrorLog(`getEventsInfo:コントロール名に誤りがあります。[${name}]`);
			else
				return events;
		}
		return null;
	}
	// --------------------------------------------------------------------------

	// private メソッド  --------------------------------------------------------
	/**
	 * サブモデル情報取得
	 * @param modelInfo モデル情報
	 * @param subModelList サブモデル名リスト
	 * @returns サブモデル情報
	 */
	 private getSubModel(modelInfo: any, subModelList: string[]): any {
		subModelList.forEach(model => {
			if (modelInfo != null) {
				let minfo = WprReflectUtil.getProperty(modelInfo, model);
				if (minfo == null)
					minfo = {};
				modelInfo = minfo;
			}
		});
		return modelInfo;
	}

	/**
	 * 空チェック
	 * @param val 入力値
	 * @returns trueの場合、空
	 */
	private checkEmpty(val: any): boolean {
		if (val === '')
			return true;
		if (Array.isArray(val) === true) {
			const array = val as Array<any>;
			if (array.length == 0)
				return true;
		}
		return false;
	}
	// --------------------------------------------------------------------------
}
