import { IWprModeBool } from '../inter/IWprModeBool';
import { WprBaseValueModeSetter } from './WprBaseValueModeSetter';
import { IWprConfigModeOption } from '../inter/IWprConfigModeOption';
import { WprBaseViewMode } from '../WprBaseViewMode';

/**
 * 読取専用状態設定
 */
export class WprReadOnlyModeSetter extends WprBaseValueModeSetter<IWprModeBool> {
	// コンストラクタ  -----------------------------------------------------------
	public constructor(option: IWprConfigModeOption, viewMoode: WprBaseViewMode, id?: string) {
		super('readOnly', option, viewMoode, id);
	}
	// --------------------------------------------------------------------------

	// override メソッド	-----------------------------------------------------
	/**
	 * 初期化処理
	 * @param option モード定義オプション
	 */
	public initialize(): void {
		this.initializeModeList(this.option.readOnly);
	}

	/**
	 * コントロールステータス設定処理
	 * @param vinfo 値情報
	 * @param id コントロールID
	 */
	public setControlStatus(vinfo: IWprModeBool, id?: string): void {
		this.controlInfo.setReadOnly(vinfo.value, id);
	}
	// --------------------------------------------------------------------------
}
