import RijndaelBlock from "rijndael-js";
import { encode } from "querystring";
import { ObjectUtil } from "./ObjectUtil";
// const RijndaelBlock = require("rijndael-js");

/**
 * rijndael-jsを使用した暗号化・複合化のユーティリティ
 */
export class CryptRijndaelUtil {
    private static rijndaelMode: string = 'ecb';
    private static rijndaelAlgo: string = '128';
    private static rijndaelEncode: BufferEncoding = 'base64';

    /**
     * テスト処理
     */
    public static test(): void {
        // 32 length : new MCommon('暗号化キー', 'cryptkey')
        let key = "12345678901234567890123456789012"; 
        // 16 length 単位 : パスワード
        const text = "aaaaa";

        // 暗号化した結果
		const hostDev: boolean = location.host.includes("localhost");
        const encryptedValueBase64 = this.encodePW(key, text);
        // encryptedValueBase64 = '+3gzIsN0rFgE6z/8shCQifJs/1o8WB/8FzS0ljPbUpH1XjGXpsWLwQnxw1s9qrzGvPzRoAaeuHSTovZzpOWE8op0X0SS+7uiCjnBlZTAowkXY/gR8Lei6XvtTEz3LQzuTEbgyoDXi26wSeKxIhFxJg==';
        const encryptedValueBase64URL = encodeURIComponent(encryptedValueBase64);
        if (hostDev) console.log('TEST ===> encryptedValueBase64URL : ' + encryptedValueBase64URL);

        // 複合化した結果
        const decryptedValueBase64 = decodeURIComponent(encryptedValueBase64URL);
        if (hostDev) console.log('TEST ===> decryptedValueBase64 : ' + decryptedValueBase64);
        const decryptedResult = this.decodePW(key, decryptedValueBase64);
    }

    /**
     * 暗号化処理：PW
     * @param key cryptkey
     * @param text パスワード
     * @returns string
     */
    public static encodePW(key: string, text: string): string {
        return this.encode(key, text, 16, ' ');
    }

    /**
     * 複合化処理：PW
     * @param key cryptkey
     * @param text パスワード
     * @returns string
     */
    public static decodePW(key: string, text: string): string {
        return this.decode(key, text, true);
    }

    /**
     * 暗号化処理
     * @param key cryptkey
     * @param text PWなどの変換テキスト
     * @param digit 指定桁数(16の倍数など)
     * @param fillChar 指定桁数まで埋める文字
     * @returns string
     */
    public static encode(key: string, text: string, digit?: number, fillChar?: string): string {
        let hostDev: boolean = location.host.includes("localhost");
        hostDev = false;
        if (hostDev) console.log('TEST ===> encode key : ' + key);
        if (hostDev) console.log('TEST ===> encode text : ' + text);
        if (ObjectUtil.isNotNullOrEmptyNum(digit) && ObjectUtil.isNotNullOrEmptyStr(fillChar)) {
            const mod = text.length % digit;
            for (let i = 0; i < digit - mod; i++) {
                text = text.concat(fillChar);
            }
            if (hostDev) console.log('TEST ===> encode text filled : ' + text.split(fillChar).join('@'));
        }
        const cipher = new RijndaelBlock(key, CryptRijndaelUtil.rijndaelMode);
        const encryptedBuffer: Buffer = cipher.encrypt(text, CryptRijndaelUtil.rijndaelAlgo, key);
        // if (hostDev) console.log('TEST ===> encryptedBuffer : ' + encryptedBuffer.toString());
        const encryptedValue = Buffer.from(encryptedBuffer);
        if (hostDev) console.log('TEST ===> encryptedValue : ' + encryptedValue.toString());
        const encryptedValueBase64 = encryptedValue.toString(CryptRijndaelUtil.rijndaelEncode);
        if (hostDev) console.log('TEST ===> encryptedValueBase64 : ' + encryptedValueBase64);
        return encryptedValueBase64;
    }

    /**
     * 複合化処理
     * @param key cryptkey
     * @param text PWなどの変換テキスト
     * @param isTrim 空文字を削除するか
     * @returns string
     */
    public static decode(key: string, text: string, isTrim = false): string {
        let hostDev: boolean = location.host.includes("localhost");
        hostDev = false;
        if (hostDev) console.log('TEST ===> decode key : ' + key);
        if (hostDev) console.log('TEST ===> decode text : ' + text);
        const cipher = new RijndaelBlock(key, CryptRijndaelUtil.rijndaelMode);
        const decryptedValue = Buffer.from(text, 'base64');
        if (hostDev) console.log('TEST ===> decryptedValue : ' + decryptedValue.toString());
        const decryptedBuffer: Buffer = cipher.decrypt(decryptedValue, CryptRijndaelUtil.rijndaelAlgo, key);
        // if (hostDev) console.log('TEST ===> decryptedBuffer : ' + decryptedBuffer.toString());
        let decryptedResult = Buffer.from(decryptedBuffer).toString();
        if (isTrim) decryptedResult = decryptedResult.trim()
        if (hostDev) console.log('TEST ===> decryptedResult : ' + decryptedResult);
        return decryptedResult;
    }
}