/**
 * モード結合条件
 */
export enum WprModeJoinCondition {
	/** OR条件 */
	OR		= '|',
	/** AND条件 */
	AND		= '&'
}

/**
 * モード情報インターフェース
 */
export interface IWprBaseModeInfo {
	mode: string;						// モード
	condition?: WprModeJoinCondition;	// モード結合条件
	modeList?: string[];				// モードリスト
}
