import { FormControlLabel, Grid, Radio, Typography } from '@material-ui/core';
import React, { ReactNode } from 'react';
import { WprBaseInputControlComponent } from '../../../wpr-framework/component/controls/WprBaseInputControlComponent';
import { IWprInputControlProps } from '../../../wpr-framework/component/props/IWprControlProps';
import { WprControlVisibility } from '../../../wpr-framework/component/props/IWprControlState';
import { WprFramework } from '../../../wpr-framework/WprFramework';

import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import styles from './UsageDetailsRadio.scss';
import { UsageDetailsRadioContent } from './UsageDetailsRadioContent';

/**
 * ラジオボタンプロパティ情報
 */
interface UsageDetailsRadioProps extends IWprInputControlProps {
	value: any;										// 値
	label?: string;									// ラベル
	color?: 'primary' | 'secondary' | 'default';	// 色
	clear?: boolean;								// 値クリアフラグ（選択済みをクリックした場合にクリアする）

	disabled?: boolean;
	dispType: string;	// 項目種別
}

/**
 * ラジオボタンコントロール
 */
export class UsageDetailsRadio extends WprBaseInputControlComponent<UsageDetailsRadioProps, HTMLInputElement> {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: UsageDetailsRadioProps) {
		super(props);
		this.setChangeValueEvent(this.onChangeValue.bind(this));
		let isClear = WprFramework.getInstance().view.isRadioClear;
		if (this.props.clear !== undefined)
			isClear = this.props.clear;
		if (isClear === true)
			this.setClickEvent(this.onClick.bind(this));
	}
	// --------------------------------------------------------------------------

	// イベント  ----------------------------------------------------------------
	/**
	 * 値変更処理
	 * @param event 変更イベント 
	 */
	public onChangeValue(event: React.ChangeEvent<HTMLInputElement>): void {
		if (event != null && event.target.checked === false)
			return;
		if (this.controlInfo != null) {
			let value = this.props.value;
			if (event != null)
				this.addChangeValueEventLog(event.target, value);
			else
				value = this.controlInfo.value;
			this.changeValue(value);
		}
	}

	/**
	 * クリックイベント処理
	 * @param event クリックイベント 
	 */
	public onClick(event: React.MouseEvent<HTMLInputElement>): void {
		const pval = this.props.value;
		const val = this.controlInfo.value;
		if (pval === val) {
			this.addClickEventLog(event.target as HTMLInputElement);
			this.controlInfo.clearValue(null);
			this.onChangeValue(null);
		}
	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * スタイルオブジェクト取得
	*/
	public getStylesObject(): any { return styles; }

	/**
	 * コントロール名称取得
	 * @returns コントロール名称
	 */
	public getName(): string {
		if (this.props.row)
			return this.props.name + this.props.row.index;
		else
			return this.props.name;
	}

	/**
	 * 値取得
	 * @returns 値
	 */
	protected getValue(): any {
		return this.props.value;
	}

	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		if (this.state.visibility === WprControlVisibility.COLLAPSED)
			return <></>;
		const props = this.getProps('wpr-radio');
		const { name, view, className, label, children, ...other } = this.props;
		const state = this.getState(['style', 'disabled', 'readOnly']);
		const checked = (this.props.value === this.state.value);
		const options = this.getOptions();
		const events = this.getEvents();
		if (state.readOnly === true && state.disabled === false && checked === false)
			state.disabled = true;
		let radio = null;
		if (this.state.visibility === WprControlVisibility.VISIBLE) {
			radio = <Radio checked={checked} {...props} {...state} {...other} {...options} {...events} ref={this.ref} />;
		}
		else {
			state['style'] = { ...this.state.style, visibility: 'hidden' };
			radio = <input type='radio' checked={checked} {...props} {...state} {...other} {...options} ref={this.ref} />;
		}
		// if (this.props.label) {
		if (this.state.visibility === WprControlVisibility.VISIBLE) {
			let disabled: boolean = false;
			radio = <FormControlLabel className='mvno-radio-label' control={radio} label={this.getLabel(checked)} disabled={this.props.disabled} />;
		}
		else {
			radio = <FormControlLabel className='mat-checkbox-label' control={radio} label={this.props.label} style={{ visibility: 'hidden' }} />;
		}
		// }
		return (
			<>
				{radio}
			</>
		);
	}
	// --------------------------------------------------------------------------

	// private メソッド  ---------------------------------------------------------
	/**
	 * オプション情報取得
	 * @returns オプション情報
	 */
	private getOptions(): any {
		const rtn: any = {};
		if (this.props.color)
			rtn['color'] = this.props.color;
		else
			rtn['color'] = 'primary';
		return rtn;
	}

	private getLabel(isSelected: boolean): any {
		const icon: any = (isSelected) ? <CheckCircleIcon /> : <RadioButtonUncheckedIcon />;
		const dispType = this.props.dispType;
		return (
			<Grid container>
				<Grid item xs={12}>
					<div className='radioHeader'>
						<Typography>{dispType}</Typography>
						<div style={{ textAlign: 'right' }}>{icon}</div>
					</div>
				</Grid>
				<Grid item>
					<div className='decription'>
						{this.getLabelContent()}
					</div>
				</Grid>
			</Grid>
		);
	}

	private getLabelContent(): any {
		const dispType: string = this.props.dispType;
		return UsageDetailsRadioContent.getContent(dispType);
	}
	// --------------------------------------------------------------------------
}