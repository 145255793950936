import { WprBaseConverter } from '../../../../wpr-framework/view/convert/WprBaseConverter';
import { ObjectUtil } from '../../../common/util/ObjectUtil';

/**
 * 値がない場合に「-」にする
 */
export class HyphenConverter extends WprBaseConverter {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() {
		super('ハイフン（-）');
	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * 値を表示用の値に変換する (value -> viewValue)
	 * @param value 値
	 * @returns 表示用の値
	 */
	public convertView(value: any): string {
		if (ObjectUtil.isNullOrUndefined(value)) {
			return '-';
		}
		return value;
	}
	// --------------------------------------------------------------------------
}
