// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@charset \"UTF-8\";\n/*=================== 変数管理ファイル =================== */\n/* ------- 色 ------- */\n/* ------- width ------- */\n/*\r\n * 追加確認用CSS\r\n */\n/* タイトル：青まるポチ（前（左側）） */\n.AddPhoneConfirm-title_maru {\n  font-weight: 700;\n  font-size: 18px;\n  line-height: 160%;\n  color: #000000; }\n  .AddPhoneConfirm-title_maru::before {\n    /* 空の要素作成 */\n    content: \"\";\n    width: 12px;\n    height: 12px;\n    background: #6169B8;\n    border-radius: 50%;\n    /* インラインブロックにする */\n    display: inline-block;\n    margin-right: 0.3rem; }\n\n.AddPhoneConfirm-ul_kome {\n  padding-inline-start: 0; }\n  @media screen and (max-width: 450px) {\n    .AddPhoneConfirm-ul_kome {\n      line-height: 18px;\n      padding-bottom: 0px; } }\n  .AddPhoneConfirm-ul_kome li {\n    list-style: none;\n    font-size: 12px;\n    color: #333333; }\n    @media screen and (max-width: 450px) {\n      .AddPhoneConfirm-ul_kome li {\n        line-height: 18px; } }\n  .AddPhoneConfirm-ul_kome li:before {\n    content: \"※\"; }\n\n/*\r\n * ［サービス追加（変更）申込み］申込み内容の確認用CSS\r\n */\n.AddPhoneConfirm-value {\n  display: flex;\n  align-items: baseline; }\n  .AddPhoneConfirm-value .AddPhoneConfirm-itemSelect .MuiSelect-root {\n    width: auto !important;\n    height: auto !important;\n    background-color: #FFFFFF !important;\n    color: #333333 !important;\n    font-weight: 500 !important;\n    font-size: 14px;\n    padding: 0; }\n  .AddPhoneConfirm-value .AddPhoneConfirm-itemSelect svg {\n    display: none; }\n  .AddPhoneConfirm-value .AddPhoneConfirm-itemSelect fieldset {\n    border: 0px; }\n\n.AddPhoneConfirm-flex {\n  display: flex; }\n  .AddPhoneConfirm-flex .MuiTypography-root {\n    margin-right: 10px; }\n", ""]);
// Exports
exports.locals = {
	"title_maru": "AddPhoneConfirm-title_maru",
	"ul_kome": "AddPhoneConfirm-ul_kome",
	"value": "AddPhoneConfirm-value",
	"itemSelect": "AddPhoneConfirm-itemSelect",
	"flex": "AddPhoneConfirm-flex"
};
module.exports = exports;
