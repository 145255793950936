import { WprBaseViewCore } from '../WprBaseViewCore';
import { WprFramework } from '../../WprFramework';
import { WprBaseDIManage } from './WprBaseDIManage';

/**
 * スコープモデルDIデコレータ
 * @param modelName モデル名称
 */
export function WprDI_ScopeModel(/* target: any, props: string,*/ modelName?: string) {
	WprBaseDIManage.modelNameList.push(modelName);
	// return <TFunc extends Function> (target: any, props: string) => {
	return (target: any, props: string) => {
		const diManage = target as WprBaseDIManage;
		if (diManage) {
			WprFramework.getInstance().model.addDecoMapInfo('ScopeModel', diManage, WprBaseViewCore.modelNameList.shift(), props);
			const baseName = diManage.getBaseName();
			if (baseName)
				WprFramework.getInstance().model.addBaseDIInfo(baseName, diManage);
		}
	};
}
