import { WprBaseDialogViewInfo } from '../../../../../wpr-framework/view/dialog/WprBaseDialogViewInfo';

/**
 * MVNO一時保存完了ダイアログ
 */
export class MvnoTmpSaveDialogView extends WprBaseDialogViewInfo {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() { super('MvnoTmpSaveDialog'); }
	// --------------------------------------------------------------------------

	// override プロパティ  ------------------------------------------------------
	/** ダイアログタイトル */
	public get title(): string { return ''; }
	/** バリデーション */
	public get validation(): boolean { return false; }
	/** Divider使用フラグ */
	public get useDividers(): boolean { return false; }
	/** フッタ使用フラグ */
	public get useFooter(): boolean { return false; }
	/** OKボタン使用フラグ */
	public get useOKButton(): boolean { return false; }
	/** キャンセルボタン使用フラグ */
	public get useCancelButton(): boolean { return false; }
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * コントロール定義設定
	 */
	public onRegisterConfig(): void {
	}
	// --------------------------------------------------------------------------
}
