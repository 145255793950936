/**
 * アクセスアカウント
 */
export class AccesspassModel {
	// public 変数	-----------------------------------------------------------
	public contractID: string;
	public course: string;			// コース
	public radiusAccount: string;	// アクセスアカウント
	public password: string;		// パスワード
	public newPassword: string;
	public reEnterPassword: string;
	public date: string;
	public crptkey: string;
	// ------------------------------------------------------------------------
}
