import React, { ReactNode } from 'react';
import { WprBaseViewComponent } from '../../../../wpr-framework/view/component/WprBaseViewComponent';
import { CustomerSupportView } from './CustomerSupportView';
import { Divider, Typography } from '@material-ui/core';
import { MatButton } from '../../../../wpr-framework/component/controls/material/MatButton';
import { MatTypography } from '../../../../wpr-framework/component/controls/material/MatTypography';
import { WprSpan } from '../../../../wpr-framework/component/controls/WprSpan';
import styles from './CustomerSupport.scss';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { WprContainer } from '../../../../wpr-framework/component/controls/WprContainer';

/**
 * お問い合わせ用コンポーネント
 */
export class CustomerSupport extends WprBaseViewComponent {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: any) { super(new CustomerSupportView(), props); }
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** お問い合わせビュー情報 */
	public get viewInfo(): CustomerSupportView	{ return this.view as CustomerSupportView;	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * スタイルオブジェクト取得
	*/
	public getStylesObject(): any { return styles; }

	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		return (
			<>
			<div className='pageCard'>
				<div className='main'>
					<div className='pageTitle' style={{marginBottom: '50px'}}>
						<div className='title_left'>
							<MatTypography name='title' className='title_text' view={this.view}/>
						</div>
						<div className='titleComplete_normal fontBold'>
							<MatTypography name='msg1' view={this.view}></MatTypography>
						</div>
						<div className='titleComplete_normal fontBold' style={{fontWeight: '500'}}>
							<Typography>お電話でのお問い合わせ：0120-109199(9:30～18:00)</Typography>
						</div>
					</div>
					<WprContainer mode={'支払い方法.表示'} view={this.view}>
						<div className='pageFooter footer-space'>
							<div>
								<MatButton name='fd' view={this.view} className='orangeButton orangeButton_parts'>
									お問い合わせフォーム
									<NavigateNextIcon />
								</MatButton>
							</div>
							<div>
								<MatButton name='payment' view={this.view} className='orangeButton orangeButton_parts' >
								お支払い方法を変更する
									<NavigateNextIcon />
								</MatButton>

							</div>
						</div>
					</WprContainer>
				</div>
				<Divider />
				<div className='pageFooter footer-space'>
					<div>
						<MatButton name='fd' view={this.view} className='orangeButton orangeButton_parts'>
							お問い合わせフォーム
							<NavigateNextIcon />
						</MatButton>
					</div>
					<div>
						<MatButton name='back' view={this.view} className='backButton' >
							<NavigateBeforeIcon />
							マイページトップへ戻る
						</MatButton>
					</div>
				</div>
			</div>
			</>
		);
	}
	// --------------------------------------------------------------------------
}
