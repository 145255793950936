import { WprBaseConverter } from '../../../../wpr-framework/view/convert/WprBaseConverter';

/**
 * 名義変更区分コンバータ
 */
export class NameChangeConverter extends WprBaseConverter {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() {
		super('名義変更区分');
	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * 値を表示用の値に変換する (value -> viewValue)
	 * @param value 値
	 * @returns 表示用の値
	 */
	public convertView(value: any): string {
		if(value === '1') {
			return '苗字の変更（改姓）';
		}
		if(value === '2') {
			return 'ご契約者さま逝去';
		}
		if(value === '3') {
			return '同居のご家族への契約者変更';
		}
		return '';
	}
	// --------------------------------------------------------------------------
}
