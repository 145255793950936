/**
 * バリデータ基本クラス
 */
export abstract class WprBaseValidator {
	// private 変数  ------------------------------------------------------------
	private m_Name: string				= null;			// バリデータ名称
	private m_IsCustom: boolean			= false;		// カスタムフラグ
	private m_CustomMessage: string		= null;			// カスタムメッセージ
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** バリデータ名称 */
	public get name():	string				{ return this.m_Name;			}
	/** カスタムフラグ */
	public get isCustom(): boolean 			{ return this.m_IsCustom;		}	public set isCustom(custom: boolean) 	{ this.m_IsCustom = custom;		}
	/** カスタムメッセージ */
	public get customMessage(): string 		{ return this.m_CustomMessage;	}	public set customMessage(msg: string) 	{ this.m_CustomMessage = msg;	}
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	public constructor(name: string) {
		this.m_Name = name;
	}
	// --------------------------------------------------------------------------

	// abstract メソッド  --------------------------------------------------------
	/**
	 * 入力値チェック
	 * @param value 入力値
	 * @param option チェックオプション
	 * @param dispValue 表示値
	 * @returns trueの場合、成功
	 */
	public abstract checkValidValue(value: any, option?: any, dispValue?: any): boolean

	/**
	 * エラーメッセージ取得
	 * @param description エラーが発生したコントロールの説明
	 * @param option オプション
	 * @returns エラーメッセージ
	 */
	public abstract getErrorMessage(description: string, option?: any): string;
	// --------------------------------------------------------------------------

	// virtual メソッド  --------------------------------------------------------
	/**
	 * 入力チェック処理
	 * @param value 入力値
	 * @return falseの場合、入力無効
	 */
	public checkInput(value: any, option?: any): boolean {
		return true;
	}

	/**
	 * 入力チェック処理
	 * @returns falseの場合、入力チェックは行なわない
	 */
	public useInputCheck(): boolean {
		return false;
	}

	/**
	 * オプションパラメータ名取得
	 * @returns オプションパラメータ名
	 */
	public getOptionParam(): string {
		return null;
	}
	// --------------------------------------------------------------------------

	// public メソッド  ---------------------------------------------------------
	/**
	 * カスタムメッセージ取得
	 * @param description コントロールの説明
	 * @returns カスタムメッセージ
	 */
	public getCustomMessage(description: string) {
		if (this.m_CustomMessage) {
			if (this.m_CustomMessage.includes('%desc%') === true)
				return this.m_CustomMessage.replace('%desc%', description);
			return this.m_CustomMessage;
		}
		return null;
	}
	// --------------------------------------------------------------------------
}
