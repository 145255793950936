import { MDisplay } from '../../entity/secondary/MDisplay';
import { MGeneral } from '../../entity/MGeneral';
import { ContractServiceModel } from '../../apimodels/ContractServiceModel';
import { MCommon } from '../../entity/primary/MCommon';

/**
 * 画面情報取得リクエスト
 */
export class GetViewInfoRequest {
	// public 変数	-----------------------------------------------------------
	public customerId: string;					// 
	public masterFlg: boolean;					// 
	public statusCdList: String[];				// 
	public displayList: MDisplay[];				// リスト
	public generalList: MGeneral[];				// リスト
	public serviceKey: ContractServiceModel[];	// 
	public contractFlg: boolean;				// 
	public ownerFlg: boolean;					// 
	public paymentFlg: boolean;					// 
	public yakkanList: MGeneral[];				// 
	public commonList: MCommon[];				// 
	public buildingFlg: boolean;				//
	public serviceLineFlg: boolean;				// 
	// public areaFlg: boolean;		
	// ------------------------------------------------------------------------
}
