import React, { ReactNode } from 'react';
import { PDFDialogView } from './PDFDialogView';
import styles from './PDFDialog.scss';
import { WprBaseDialogViewComponent } from '../../../../wpr-framework/view/component/WprBaseDialogViewComponent';
import { WprPdfView } from '../../../../wpr-framework/component/controls/WprPdfView';
import PDFView from '../../PDFView';

// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

/**
 * pdfダイアログ用コンポーネント
 */
export class PDFDialog extends WprBaseDialogViewComponent {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: any) { super(new PDFDialogView(), props); }
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** pdfダイアログビュー情報 */
	public get viewInfo(): PDFDialogView	{ return this.view as PDFDialogView;	}
	// --------------------------------------------------------------------------

	//---------------------------------------------------------------------------
	//---------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * スタイルオブジェクト取得
	*/
	public getStylesObject(): any { return styles; }

	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		const pdf = this.state.data.pdfData;
		return (
			<>
				<div view={this.view} className='reactPdf' {...this.getEvents('pdfScroll')}>
					<PDFView dataUrl={pdf} />
					{/* <WprPdfView name='pdf' view={this.view} /> */}
				</div>
				{/* <WprContainer mode='pdf.セット' view={this.view}> */}
				{/* </WprContainer> */}
			</>
		);
	}
	// --------------------------------------------------------------------------
}
