

export class MypageLinkConstatns {
	// static 変数 -------------------------------------------------
	// BSS_契約種別コード
	public static readonly ITSCOM_TOIAWASE: string = 'https://www.itscom.co.jp/info/faq/';

	public static readonly ITSCOM_MVNOENTRY_HELP: string = 'https://www.itscom.co.jp/service/mobile/';

	public static readonly ITSCOM_IMPORTANT_DOC: string = 'https://www.itscom.co.jp/info/covenant/gjslkq0000003us8-att/important.pdf';

	public static readonly ITSCOM_TERMINAL_DETAIL: string = 'https://www.itscom.co.jp/service/mobile/sim/terminal.html';

	public static readonly ITSCOM_TERMINAL_GUARANTEE: string = 'https://www.itscom.co.jp/support/mobile/guarantee.html';

	public static readonly ITSCOM_SIM_FREE: string = 'https://www.itscom.co.jp/service/mobile/sim/gjslkq00000022d0-att/simfree.pdf';
}