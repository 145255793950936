import { WprBaseViewInfo } from '../../../../wpr-framework/view/WprBaseViewInfo';
import { MypageBaseView } from '../../base/MypageBaseView';
import { WprModel } from '../../../../wpr-framework/view/deco/WprModel';
import { GetUsageDetailsMonthRequest } from '../../../service/models/dto/usagedetails/GetUsageDetailsMonthRequest';
import { UsageDetailsService } from '../../../service/UsageDetailsService';
import { GetUsageDetailsRequest } from '../../../service/models/dto/usagedetails/GetUsageDetailsRequest';
import { WprListData } from '../../../../wpr-framework/view/deco/WprListData';
import { UsageMonthlyInfo } from '../../../models/UsageMonthlyInfo';
import { ObjectUtil } from '../../../common/util/ObjectUtil';
import { ConvertUtil } from '../../../common/util/ConvertUtil';

/**
 * ご利用明細画面
 */
export class UsageDetails_001View extends MypageBaseView {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() { super('UsageDetails_001'); }
	// --------------------------------------------------------------------------

	// モデル  ------------------------------------------------------------------
	@WprListData('UsageMonthlyInfoList')
	private m_UsageMonthlyInfoList: UsageMonthlyInfo[]	= null;	// 利用明細情報(このリストの分だけタブを表示する)
	@WprListData('EnergyMonthList')
	private m_energyMonthList: string[]	= null;	// でんきガス一覧用年月リスト
	// --------------------------------------------------------------------------

	// private変数  -----------------------------------------------------------
	private m_customerId: string		= null;	// お客様番号
	private m_maxDispTab: number		= null;	// 表示タブ最大数
	private m_maxMobileDispTab: number	= null;	// モバイル表示タブ最大数
	// --------------------------------------------------------------------------

	// サービス  ----------------------------------------------------------------
	private m_UsageDetailsService: UsageDetailsService	= new UsageDetailsService();	// 利用明細サービス
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * ビュー表示通知
	 */
	public onShowView(): void {
		this.registAccessLog();
		this.setStorageItem('b_url', window.location.href);
		this.m_customerId = this.getCustomerId();
		// 対象年月を取得する
		const req: GetUsageDetailsMonthRequest = new GetUsageDetailsMonthRequest();
		req.customerId = this.m_customerId;
		this.m_UsageDetailsService.getMonth(this, req, (result) => {
			if (result) {
				if (ObjectUtil.isNotNullOrEmptyArray(result.monthList)) {
					let usageInfoList: UsageMonthlyInfo[] = new Array();
					if (ObjectUtil.isNotNullOrEmptyArray(result.monthList)) {
						result.monthList.forEach((value, index) => {
							const info: UsageMonthlyInfo = new UsageMonthlyInfo();
							info.customerId = this.m_customerId;
							info.customerName = result.customerName;
							info.month = value;
							info.yearLabel = ConvertUtil.convertYearOnlyLabel(value);	// YY年
							info.monthLabel = ConvertUtil.convertMonthOnlyLabel(value);	// MM月
							info.monthIdx = index;
							usageInfoList.push(info);
						});
						this.setListData('UsageMonthlyInfoList', usageInfoList);
						this.setListData('EnergyMonthList', result.energyMonthList);
						// タブの表示最大数を設定
						let size: number = usageInfoList.length;
						this.m_maxDispTab = size < 6 ? size : 6;
						this.m_maxMobileDispTab = size < 4 ? size : 4;
						this.setViewData('maxDispTab', this.getMaxDispTab());
						this.setViewData('maxMobileDispTab', this.getMaxMobileDispTab());
					}
				}
			}
			this.refreshView();
			if (ObjectUtil.isNotNullOrEmptyArray(this.m_UsageMonthlyInfoList)) {
				// 初期表示：最新の料金明細
				this.setViewMode('明細', '有');
				const defaultTab: string = this.m_UsageMonthlyInfoList[(this.m_UsageMonthlyInfoList.length) -1].month;
				this.setTabMode('usageFeeTab', defaultTab);
			} else {
				// 請求データ0件
				this.setViewMode('明細', '無');
			}
		});
	}
	
	// --------------------------------------------------------------------------

	// getter -------------------------------------------------------------------
	public getMaxDispTab(): any {
		return this.m_maxDispTab;
	}
	public getMaxMobileDispTab(): any {
		return this.m_maxMobileDispTab;
	}
	// --------------------------------------------------------------------------

}
