import { WprDI_ScopeModel } from '../../../../../wpr-framework/view/di/WprDI_ScopeModel';
import MypageDispdivcdConstants from '../../../../common/MypageDispdivcdConstants';
import { MypageBaseView } from '../../../base/MypageBaseView';
import { ProgressBarData } from '../../../common/progress_bar/ProgressBarView';
import { MypageWizardMngr } from '../../../wizard/models/MypageWizardMngr';
import { WprModel } from '../../../../../wpr-framework/view/deco/WprModel';
import { WprListData } from '../../../../../wpr-framework/view/deco/WprListData';
import { TvCourseModel } from '../../../../models/mypage/tv/TvCourseModel';
import { TvCourseListModel } from '../../../../models/mypage/tv/TvCourseListModel';
import { ItscomTvService } from '../../../../service/ItscomTvService';
import { GetTvInfoRequest } from '../../../../service/models/dto/mypagerenewal/GetTvInfoRequest';
import { MDisplay } from '../../../../service/models/entity/secondary/MDisplay';
import { ViewInfoModel } from '../../../../service/models/apimodels/ViewInfoModel';
import { TvBaseModel } from '../../../../models/mypage/tv/TvBaseModel';
import { ObjectUtil } from '../../../../common/util/ObjectUtil';
import { TvNewWizardModel } from '../../../wizard/TvNewWizardFlow';

/**
 * テレビ追加
 */
export class AddTvSelect1View extends MypageBaseView {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() { super('AddTvSelect1View'); }
	// --------------------------------------------------------------------------

	// モデル  ------------------------------------------------------------------
	@WprDI_ScopeModel('MypageWizardMngr')
	private m_MypageWizardMngr: MypageWizardMngr			= null;			// 画面ウィザード管理クラス
	@WprModel('courseMessage')	
	private m_courseMessage: string	= null;									// 警告用コース名
	@WprListData('TvCourseModel')
	private m_TvCourseModel: TvCourseModel[]	= null;						// テレビ_コースモデル
	// --------------------------------------------------------------------------

	// private変数 --------------------------------------------------------------
	private m_TvBaseModel: TvBaseModel = null;								//tv基本情報モデル
	private m_TvNewWizardModel: TvNewWizardModel = new TvNewWizardModel();
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** ウィザード定義 */
	public get mngr(): MypageWizardMngr 				{ return this.m_MypageWizardMngr; 		}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * コントロール定義設定
	 */
	public onRegisterConfig(): void {
		this.addConfig('course', '警告用コース名', 'courseMessage', { bindModel: true });
	}

	/**
	 * アクション登録
	 */
	public onRegisterAction(): void {
		this.addAction('next', this.onNext);
		this.addAction('back', this.onBack);
	}

	/**
	 * ビュー表示通知
	 */
	public onShowView(): void {
		this.m_MypageWizardMngr.initView(this, (result) => {
			this.registAccessLog();
			this.setStorageItem('b_url', window.location.href);
			const sendData: ProgressBarData = new ProgressBarData();
			sendData.progressBarList = this.m_MypageWizardMngr.getProgressModelList(this);
			this.sendChildData('fP_ev_progressBarIntital', sendData);
			this.m_TvBaseModel = this.m_MypageWizardMngr.getCache().m_TvBaseModel;
			this.m_TvCourseModel = this.m_MypageWizardMngr.getCache().m_TvCourseModel;
			if (this.m_TvCourseModel.length === 0) {
				const viewInfo = this.m_MypageWizardMngr.getViewInfo();
				this.m_TvNewWizardModel.getTvViewInfo(this, viewInfo, null, null, (result) => {
					//画面表示用に変換
					if (result) {
						//表示対象サービスの絞り込み
						let displayList: TvCourseListModel[] = new Array();
						const nameList = result.srvtvCourseName.reverse();
						//店子サービスの場合
						if (result.tanakoFlg) {
							displayList = result.mstInfo;
						}
						else {
							for (const name of nameList) {
								let targetList = result.mstInfo.filter(item => item.detail.dispnamep === name.commonValue);
								//まいにち充実プラン
								if (result.type === MypageDispdivcdConstants.TV_MAIJU_SERVICE) {
									const firstService = this.mngr.getMDisplayList(this, MypageDispdivcdConstants.TV_FTTH_SERVICE, '').filter(item => item.namecd === result.tanako.firstService);
									if (targetList.length > 0) {
										for (const target of targetList) {
											if (firstService.length > 0 && firstService[0].genecd != '4' && target.detail.genecd === '4') {
												//何もしない
											}
											else
												displayList.push(target);
										}
									}
								}
							}
						}
						const map = new Map<string, TvCourseListModel[]>();
						this.m_courseMessage = '';
						for (const data of displayList) {
							if (result.tanakoFlg) {
								if (!map.has(data.serviceName))
									map.set(data.serviceName, []);
								map.get(data.serviceName).push(data);
								const message = this.m_TvNewWizardModel.checkMessage1(this, data);
								if (message !== '')
									this.m_courseMessage += '「' + message + '」';
							}
							else {
								if (!map.has(data.detail.dispnamep))
									map.set(data.detail.dispnamep, []);
								map.get(data.detail.dispnamep).push(data);
							}
						}
						for (const key of map.keys()) {
							this.m_TvNewWizardModel.checkMessage2(this, key);
							const model = new TvCourseModel();
							model.course = key;
							const list  =  map.get(key).sort(function(a, b) { 
								if (a.amount !== b.amount) return Number(b.amount) - Number(a.amount);
								else return -1 });
							model.detailList = list;
							this.m_TvCourseModel.push(model);
						}
						this.m_TvCourseModel[0].detailList[0].select = true;
						this.setList();
					}

				});
			}
			else 
				this.setList();
			this.refreshView();
		});
	}

	/**
	 * データ受信処理
	 * @param dataName データ名
	 * @param data データ
	 */
	public onReceiveData(dataName: string, data?: any): void {
		if (dataName === 'checkData') {
			for (const data of this.m_TvCourseModel) {
				this.sendChildData('checkBox', 'true', data.course);
				for (const listData of data.detailList) {
					if (listData.select)
						this.setViewMode('check', data.course + ':' + listData.terminal);
				}
			}
		}
	}
	// --------------------------------------------------------------------------

	// アクション  ---------------------------------------------------------------
	/**
	 * 次へ
	 */
	public onNext(param: any): void {
		this.setBaseInfo();
		this.m_MypageWizardMngr.goNext(this);
	}

	/**
	 * 戻る
	 */
	public onBack(param: any): void {
		this.m_MypageWizardMngr.goBack(this);
	}
	// --------------------------------------------------------------------------

	// privateメソッド -----------------------------------------------------------
	/**
	 * リストセット
	 */
	private setList(): void {
		this.setListData('TvCourseModel', this.m_TvCourseModel);
		for (const data of this.m_TvCourseModel) {
			this.sendChildData('serviceList', data, data.course);
		}
		this.refreshView();
	}

	/**
	 * 選択情報セット
	 */
	private setBaseInfo(): void {
		this.refreshModel();
		for (const model of this.m_TvCourseModel) {
			for (const course of model.detailList) {
				if (course.select) {
					this.m_TvBaseModel.course = model.course;
					this.m_TvBaseModel.courseCd = course.detail.namecd;
					this.m_TvBaseModel.amount = course.amount;
					this.m_TvBaseModel.terminalCd = course.detail.genecd;
					if (!course.detail.genecd)
						this.m_TvBaseModel.terminal = course.terminal;
					this.m_TvBaseModel.rental = true;
					this.m_TvBaseModel.magazineApply = true;
					this.m_TvBaseModel.otokuUnapply = true;
					this.m_TvBaseModel.type = course.detail.dispdivcd;
					this.m_TvBaseModel.constractionFlg = true;
				}
			}
		}
		this.refreshModel();
	}
	// --------------------------------------------------------------------------
}
