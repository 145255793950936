import { HAccessLog } from '../../entity/primary/HAccessLog';

/**
 * アクセス履歴登録リクエスト
 */
export class RegistAccessLogRequest {
	// public 変数	-----------------------------------------------------------
	public accessLog: HAccessLog;	// アクセスログ履歴
	// ------------------------------------------------------------------------
}
