// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@charset \"UTF-8\";\n/*\r\n * メニュー（仮）用CSS\r\n */\n.Menu-select {\n  width: 250px;\n  margin-top: 0 !important;\n  margin-right: 10px !important; }\n", ""]);
// Exports
exports.locals = {
	"select": "Menu-select"
};
module.exports = exports;
