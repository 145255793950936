import { MypageBaseView } from '../../../base/MypageBaseView';
import { WprModel } from '../../../../../wpr-framework/view/deco/WprModel';
import { WprDI_ScopeModel } from '../../../../../wpr-framework/view/di/WprDI_ScopeModel';
import { HMvnoTmpSaved } from '../../../../service/models/entity/HMvnoTmpSaved';
import { MvnoEntryInfo } from '../../../../service/models/apimodels/MvnoEntryInfo';
import { MvnoService } from '../../../../service/MvnoService';
import { GetMvnoContractPriceListRequest } from '../../../../service/models/dto/mvno/GetMvnoContractPriceListRequest';
import { PriceModel } from '../../../../service/models/apimodels/PriceModel';
import { WprListData } from '../../../../../wpr-framework/view/deco/WprListData';
import { MvnoBaseView } from '../../../base/MvnoBaseView';
import { MypageLinkConstatns } from '../../../../common/constants/MypageLinkConstatns';
import { GetPaymentSimulationRequest } from '../../../../service/models/dto/mvno/GetPaymentSimulationRequest';
import { MGeneral } from '../../../../service/models/entity/MGeneral';
import { GetTotalFeeRequest } from '../../../../service/models/dto/mvno/GetTotalFeeRequest';
import { ProgressBarModel, ProgressBarStopModel } from '../../../../models/ProgressBarModel';
import { ProgressBarData } from '../../../common/progress_bar/ProgressBarView';
import { ObjectUtil } from '../../../../common/util/ObjectUtil';

/**
 * MVNO申込_合計金額確認
 */
export class MvnoEntryTotalFeeView extends MvnoBaseView {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() { super('MvnoEntryTotalFee'); }
	// --------------------------------------------------------------------------

	// モデル  ------------------------------------------------------------------
	@WprDI_ScopeModel('MvnoEntryInfo')
	@WprModel('MvnoEntryInfo')
	private m_EntryInfo: MvnoEntryInfo = null;	// MVNOプラン情報

	// @WprListData('PriceList')
	// private m_PriceList: PriceModel[] = null;

	@WprModel('totalBefore')
	private m_totalBefore: string = null;
	// @WprListData('PriceList')
	// private m_PriceList: MGeneral[]	= null;	// PriceList
	// --------------------------------------------------------------------------

	// サービス  ----------------------------------------------------------------
	private m_MvnoService: MvnoService = new MvnoService();	// MyPageRenewalサービス
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * コントロール定義設定
	 */
	public onRegisterConfig(): void {

		// this.addConfig('entryTerminalFlg', '端末申込', 'MvnoEntryInfo.entryInfo');
		// this.addListConfig('name', '名称', 'PriceList');
		// this.addListConfig('priceTaxin', '価格（税込）', 'PriceList');
		this.addConfig('totalBefore', '変更前合計金額（税込）', 'totalBefore', { bindModel: true, converter: '3桁カンマ' });

		this.addConfig('plan', 'プラン', 'MvnoEntryInfo.entryInfo');
		this.addConfig('capacity', 'データ量', 'MvnoEntryInfo.entryInfo');
		// this.addConfig('capacityServiceCd', 'データ量サービスコード', 'MvnoEntryInfo.entryInfo');
		this.addConfig('course', 'コース', 'MvnoEntryInfo.entryInfo');
		this.addConfig('fivegUseFlg', '5G通信利用', 'MvnoEntryInfo.entryInfo', { converter: '利用する/利用しない' });
		this.addConfig('terminal', '端末', 'MvnoEntryInfo.entryInfo');
		this.addConfig('terminalColor', '端末カラー', 'MvnoEntryInfo.entryInfo');
		this.addConfig('simSize', 'SIMサイズ', 'MvnoEntryInfo.entryInfo');
		this.addConfig('simOption', 'SIMオプション', 'MvnoEntryInfo.entryInfo');
		this.addConfig('initialSupportFlg', '初期設定サポート申込', 'MvnoEntryInfo.entryInfo', { converter: '必要/不要' });
		this.addConfig('webFilteringFlg', 'WEBフィルタリング', 'MvnoEntryInfo.entryInfo', { converter: '必要/不要' });
		this.addConfig('terminalGuaranteeFlg', '端末延長保証', 'MvnoEntryInfo.entryInfo', { converter: '必要/不要' });
		this.addConfig('rusubanUseFlg', '留守番電話オプション', 'MvnoEntryInfo.entryInfo', { converter: '利用する/利用しない' });
		this.addConfig('total', '変更前合計金額（税込）', 'MvnoEntryInfo', { converter: '3桁カンマ' });
		this.addConfig('initialCostItemPrice', '初期費用_内訳', 'MvnoEntryInfo', { converter: '3桁カンマ' });
		this.addConfig('coursePrice', 'コース価格', 'MvnoEntryInfo', { converter: '3桁カンマ' });
		this.addConfig('terminalPrice', '端末価格', 'MvnoEntryInfo', { converter: '3桁カンマ' });
		this.addConfig('capacityPrice', '容量価格', 'MvnoEntryInfo', { converter: '3桁カンマ' });
		this.addConfig('simOptionPrice', 'SIMオプション価格', 'MvnoEntryInfo', { converter: '3桁カンマ' });
		this.addConfig('terminalGuaranteePrice', '端末延長保証価格', 'MvnoEntryInfo', { converter: '3桁カンマ' });
		this.addConfig('webFilteringPrice', 'WEBフィルタリング価格', 'MvnoEntryInfo', { converter: '3桁カンマ' });
		this.addConfig('rusubanPrice', '留守番電話オプション価格', 'MvnoEntryInfo', { converter: '3桁カンマ' });
		this.addConfig('campaignDiscount', 'キャンペーン割引', 'MvnoEntryInfo.campaignCode', { bindName: 'num1', converter: '3桁カンマ' });
		this.addConfig('campaignName', 'キャンペーン名称', 'MvnoEntryInfo.campaignCode', { bindName: 'name2'});
		// this.addConfig('fivegPrice', '5G通信利用価格', 'MvnoEntryInfo', { converter: '3桁カンマ' });
		// this.addConfig('initialSupportPrice', '初期設定サポート価格', 'MvnoEntryInfo', { converter: '3桁カンマ' });
		// this.addListConfig('name2', '名称2', 'PriceList');
		// this.addListConfig('num1', '数値1', 'PriceList');
	}

	/**
	 * アクション登録
	 */
	public onRegisterAction(): void {
		this.addAction('next', this.onNext);
		this.addAction('back', this.onBack);
		// this.addListAction('editPlan',  this.onEdit);
		this.addAction('editPlan1', this.onEditPlan);
		this.addAction('editPlan2', this.onEditPlan);
		this.addAction('editPlan3', this.onEditPlan);
		this.addAction('helpLink', this.onHelpLink);
	}

	/**
	 * ビュー表示通知
	 */
	public onShowView(): void {
		this.registAccessLog();
		this.setStorageItem('b_url', window.location.href);
		this.getCookieValListForMvno(this.m_EntryInfo);
		const req: GetMvnoContractPriceListRequest = new GetMvnoContractPriceListRequest();
		req.cunstomerId = this.getCustomerId();

		this.getPaymentSimulation();
		const sendData: ProgressBarData = new ProgressBarData();
		sendData.progressBarList = this.getProgressModelList();
		this.sendChildData('fP_ev_progressBarIntital', sendData);
	}


	/**
	 * データ受信処理
	 * @param dataName データ名
	 * @param data データ
	 */
	public onReceiveData(dataName: string, data?: any): void {
		if (dataName === 'fC_ev_料金明細表示') {
			this.refreshModel();
			// this.m_EntryInfo.entryInfo.capacity = this.m_CapacityList.find(capacity => {
			// 	return (capacity.generalCd === this.m_EntryInfo.entryInfo.capacityServiceCd) 
			// }).name2;
			// this.sendChildFortempSave('データ量選択');
			this.sendChildFortempSave();
		}
	}
	// --------------------------------------------------------------------------

	// アクション  ---------------------------------------------------------------
	/**
	 * 次へ
	 */
	public onNext(param: any): void {
		this.refreshModel();
		this.pushPath('/mvno_007');
	}

	/**
	 * 戻る
	 */
	public onBack(param: any): void {
		this.refreshModel();
		this.pushPath('/mvno_005');
	}

	/**
	 * 編集
	 */
	public onEditPlan(param: any): void {
		switch (param) {
			case 'editPlan1':
				this.pushPath('/mvno_002');
				break;
			case 'editPlan2':
				this.pushPath('/mvno_003');
				break;
			case 'editPlan3':
				this.pushPath('/mvno_004');
				break;
			default:
				break;
		}
	}


	/**
	 * 問い合わせリンク
	 */
	public onHelpLink(param: any): void {
		window.open(MypageLinkConstatns.ITSCOM_MVNOENTRY_HELP);
	}

	public getEntry(): MvnoEntryInfo {
		return this.m_EntryInfo;
	}
	// --------------------------------------------------------------------------
	/**
	 * 料金シミュレーション取得
	 */
	private getPaymentSimulation(): void {
		const req: GetTotalFeeRequest = new GetTotalFeeRequest();
		req.entryInfo = this.m_EntryInfo;
		req.isIncludeContract = true;
		this.m_MvnoService.getTotalFee(this, req, (result) => {
			this.m_EntryInfo = result.entryinfo;
			this.m_EntryInfo.total = result.total;
			this.m_totalBefore = result.totalBefore;

			// this.setCookieValForMvno(this.m_EntryInfo);
			// this.sendChildData('fP_ev_料金シミュレーション取得');
			// モードセット
			this.setViewMode('端末申込', this.m_EntryInfo.entryInfo.terminalUseFlg ? '有' : '無');
			this.setViewMode('プラン', this.m_EntryInfo.entryInfo.plan);
			this.setViewMode('5G通信', this.m_EntryInfo.entryInfo.fivegUseFlg ? '利用する' : '利用しない');
			this.setViewMode('SIMオプション', this.m_EntryInfo.entryInfo.simOption === 'オプション無し' ? '無' : '有');
			this.setViewMode('初期設定サポート', this.m_EntryInfo.entryInfo.initialSupportFlg ? '必要' : '不要');
			this.setViewMode('コース', this.m_EntryInfo.entryInfo.course === '基本' ? '基本' : 'かけ放題');
			this.setViewMode('キャンペーンコード', ObjectUtil.isNotNullOrEmptyStr(this.m_EntryInfo.entryInfo.campaignCode) ? '有' : '無');
			this.setViewMode('初期費用キャンペーン', this.m_EntryInfo.initialCampaignFlg ? '有' : '無');
			this.refreshView();
		});
	}

	/**
	 * ステップバー情報セット
	 */
	private getProgressModelList(): ProgressBarModel[] {
		const list: ProgressBarModel[] = new Array();

		list.push(new ProgressBarModel('1', 'お申し込み手続き', 'complete', this.getStopModelList()));
		list.push(new ProgressBarModel('2', '合計金額ご確認', 'current', null));
		list.push(new ProgressBarModel('3', '事前確認事項', 'next', null));
		list.push(new ProgressBarModel('4', 'WEB本人確認', 'not', null));
		list.push(new ProgressBarModel('5', 'お申込み完了', 'not', null));
		return list;
	}

	/**
	 * ステップバー情報セット：1-2の間の線の部分
	 * @returns 
	 */
	private getStopModelList(): ProgressBarStopModel[] {
		const list: ProgressBarStopModel[] = new Array();
		list.push(new ProgressBarStopModel('complete'));
		list.push(new ProgressBarStopModel('complete'));
		list.push(new ProgressBarStopModel('complete'));
		list.push(new ProgressBarStopModel('complete'));
		return list;
	}
}
