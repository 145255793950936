/**
 * 追加サービスモデル
 */
export class AddServiceModel {
	// public 変数	-----------------------------------------------------------
	public serviceCd: string;								// サービスコード(親)
	public contractId: string;								// 契約ID
	public rentalFlg: string;								// レンタルフラグ
	public optionCdList: String[];							// オプションサービスモデルリスト
	// ------------------------------------------------------------------------
}
