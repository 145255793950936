import { WizardModel } from '../../models/WizardModel';
import { MypageWizardFlow } from './MypageWizardFlow';
import { VideoPassComfirmView } from '../videoService/video_pass_comfirm/VideoPassComfirmView';
import { VideoPassCompleteView } from '../videoService/video_pass_complete/VideoPassCompleteView';
import { GetViewInfoRequest } from '../../service/models/dto/mypagerenewal/GetViewInfoRequest';
import { MypageBaseView } from '../base/MypageBaseView';
import { VideoPassPrintView } from '../videoService/video_pass_print/VideoPassPrintView';
import { ResetPasswordModel } from '../../service/models/apimodels/ResetPasswordModel';
import { MypageWizardMngr } from './models/MypageWizardMngr';
import { RegistInfoRequest } from '../../service/models/dto/mypagerenewal/RegistInfoRequest';
import { Mail } from '../../service/models/apimodels/Mail';

/**
 * 動画配信サービス_見放題パックプライム_ウィザードクラス
 */
export class VideoPassWizardFlow extends MypageWizardFlow {
	// private 変数  ------------------------------------------------------------
	private m_DefaultWizardFlow: WizardModel[]			= null;		// 画面ウィザードリスト
	private m_StartViewName: string						= null;
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	public constructor() {
		super();
		this.m_StartViewName = new VideoPassComfirmView().name;

		// 遷移パターン１
		this.m_DefaultWizardFlow = new Array();
		this.m_DefaultWizardFlow.push({stepNum: 0, title: '契約選択', viewName: null, path: this.getOldUrl() + 'Srvlist/Continfo_list/'});
		this.m_DefaultWizardFlow.push({stepNum: 1, title: '申込み内容の確認', viewName: new VideoPassComfirmView().name, path: 'video_pass_comfirm'});
		this.m_DefaultWizardFlow.push({stepNum: 2, title: '変更申込み完了', viewName: new VideoPassCompleteView().name, path: 'video_pass_complete'});
		this.m_DefaultWizardFlow.push({stepNum: 0, title: '印刷', viewName: new VideoPassPrintView().name, path: 'video_pass_print'});
	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * 画面ウィザードフロー名取得
	 */
	public getWizardFlowName(viewName: string): string {
		if (viewName === this.m_StartViewName)
			return this.constructor.name;
		return null;
	}
	
	/**
	 * 画面ウィザードリスト取得：契約状態で切り替えたり、選択肢で切り替えたり
	 */
	public getWizardFlow(mngr: MypageWizardMngr): WizardModel[] {
		return this.m_DefaultWizardFlow;
	}
	
	/**
	 * 画面ウィザードAPI用モデル取得：契約状態で切り替えたり、選択肢で切り替えたり
	 */
	public getWizardApiModel(mngr: MypageWizardMngr, view: MypageBaseView): GetViewInfoRequest {
		const req = new GetViewInfoRequest();
		req.customerId = mngr.getParams().customerId;
		return req;
	}

	/**
	 * 画面ウィザードフローの中断判定：例：申し込みが完了していれば、完了済みの画面へ遷移
	 */
	public checkFlowBreak(mngr: MypageWizardMngr): string {
		let test: boolean = false;
		if (test) {
			return '/error';
		}
		return null;
	}

	/**
	 * 画面キャッシュの初期化データ取得
	 */
	public getInitCacheData(mngr: MypageWizardMngr): VideoPassWizardModel {
		let cacheData = new VideoPassWizardModel();
		cacheData.passwordInfo = new ResetPasswordModel();
		return cacheData;
	}
	// --------------------------------------------------------------------------
}
/**
 * 画面ウィザードAPI用モデル
 */
export class VideoPassWizardModel {
	// public 変数	-----------------------------------------------------------
	public passwordInfo: ResetPasswordModel = null;
	// ------------------------------------------------------------------------
}

