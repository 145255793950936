import { WprBaseValidator } from '../WprBaseValidator';
import { findAllByDisplayValue } from '@testing-library/react';

/**
 * メールアドレスバリデータ
 */
export class WprMailAddressValidator extends WprBaseValidator {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() {
		super('メールアドレス');
	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * 入力値チェック
	 * @param value 入力値
	 * @param option チェックオプション
	 * @returns trueの場合、成功
	 */
	public checkValidValue(value: any, option?: any): boolean {
		if (!value)
			return true;
		if (typeof(value) === 'string' || value instanceof String) {
			if (value.match('^[a-zA-Z0-9.!#$%&\'*+\\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$') != null) {
				const domain = value.split('@')[1];
				if (domain.includes('.') === false || domain.startsWith('.') === true || domain.endsWith('.') === true || domain.includes('..') === true)
					return false;
				return true;
			}
		}
		return false;
	}

	/**
	 * エラーメッセージ取得
	 * @param description エラーが発生したコントロールの説明
	 * @param option オプション
	 * @returns エラーメッセージ
	 */
	public getErrorMessage(description: string, option?: any): string {
		return 'メールアドレスを入力してください。';
	}
	// --------------------------------------------------------------------------
}
