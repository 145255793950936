import { MypageBaseView } from '../../base/MypageBaseView';

/**
 * ヘッダー
 */
export class FooterView extends MypageBaseView {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() { super('Footer'); }
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * コントロール定義設定
	 */
	public onRegisterConfig(): void {
	}

	/**
	 * アクション登録
	 */
	public onRegisterAction(): void {
		this.addAction('toServicePage', this.onToServicePage);
		this.addAction('toCompanyPage', this.onToCompanyPage);
		this.addAction('privacyLink', this.onPrivacyLink);
	}
	/**
	* ウインドウリサイズ通知
	* @param width 幅
	* @param height 高さ
	*/
	public onResizeWindow(width: number, height: number): void {
		if (width <= 700) {
			this.setViewMode('画面サイズ', '小');
		}
		else {
			this.setViewMode('画面サイズ', '大');
		}
	}
	// --------------------------------------------------------------------------

	// アクション  ---------------------------------------------------------------
	/**
	* 会社概要
	*/
	public onToCompanyPage(): void {
		window.open('https://www.itscom.co.jp/corporate/');
	}

	/**
	 * サービスページ
	 */
	public onToServicePage(): void {
		window.open('https://www.itscom.co.jp/');
	}

	/**
	 * アクセス情報の取り扱いリンク
	 */
	public onPrivacyLink(param: any): void {
		window.open('https://www.itscom.co.jp/info/privacy/cookie.html');
	}
	// --------------------------------------------------------------------------
}
