import { WprBaseServiceValueMap } from '../../../../wpr-framework/model/WprBaseServiceValueMap';
import { WprValueMap } from '../../../../wpr-framework/model/WprValueMap';
import { WprValueMapSetter } from '../../../../wpr-framework/model/WprValueMapSetter';

/**
 * 利用明細：ケーブルプラス電話回線番号vm
 */
export class UsageDetailsPhoneNumberValueMap extends WprBaseServiceValueMap {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() {
		super('UsageDetailsPhoneNumber');
	}

	// override メソッド  -------------------------------------------------------
	/**
	 * ValueMap登録処理
	 * @param vmSetter ValueMap設定
	 */
	public registerValueMap(vmSetter: WprValueMapSetter): void {
		vmSetter.addValueMap(this.valueMapName, new WprValueMap());
	}
	// --------------------------------------------------------------------------
}
