import { WprBaseMngr } from '../WprBaseMngr';
import { WprErrorMngr } from '../error/WprErrorMngr';
import { WprSettingInfo } from '../WprSettingInfo';
import { WprAuthoritySetter } from './WprAuthoritySetter';
import { WprBaseFrameworkComponent } from '../component/base/WprBaseFrameworkComponent';

/**
 * 権限管理機能
 */
export class WprAuthorityMngr extends WprBaseMngr {
	// private 変数  ------------------------------------------------------------
	private m_AuthMap: Map<string, string>	= new Map<string, string>();	// 権限マップ
	private m_ErrorMngr: WprErrorMngr		= null;							// エラー管理機能
	private m_UserId: string				= null;							// ユーザID
	private m_UserAuthList: string[]		= null;							// ユーザ権限リスト
	private m_OneTimeAuthList: string[]		= null;							// OneTime権限リスト
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** ログインユーザ */
	public get loginUser():	string		{ return this.m_UserId;	}
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	public constructor() {
		super('権限管理機能');
	}
	// --------------------------------------------------------------------------

	// override メソッド	-----------------------------------------------------
	/**
	 * 初期化処理
	 * @param loader データローダ
	 * @param setting 設定情報
	 */
	public initialize(loader: WprBaseFrameworkComponent, setting: WprSettingInfo): void {
		loader.onRegisterAuthority(new WprAuthoritySetter(this));
	}

	/**
	 * 他の管理機能設定処理
	 * @param mngrMap 管理機能マップ
	 */
	public setManager(mngrMap: Map<string, WprBaseMngr>): void {
		this.m_ErrorMngr = mngrMap.get('エラー管理機能') as WprErrorMngr;
	}
	// --------------------------------------------------------------------------

	// public メソッド	----------------------------------------------------------
	/**
	 * 権限登録
	 * @param name 権限名
	 * @param description 権限説明
	 */
	public addAuthority(name: string, description: string): void {
		if (this.m_AuthMap.has(name) === false)
			this.m_AuthMap.set(name, description);
		else
			this.m_ErrorMngr.addErrorLog('WprLoader', `同一の権限が既に登録されています。[${name}]`);
	}

	/**
	 * 権限一覧登録
	 * @param authList 権限名一覧
	 */
	public addAuthorityList(authList: string[]): void {
		for (const name of authList)
			this.addAuthority(name, '');
	}

	/**
	 * 全権限名取得
	 * @returns 権限名リスト
	 */
	public getAushList(): string[] {
		const list = new Array();
		this.m_AuthMap.forEach((name) => {
			list.push(name);
		});
		return list;
	}

	/**
	 * 権限チェック
	 * @param name 権限名
	 * @returns trueの場合、権限は登録されている
	 */
	public checkAuthority(name: string): boolean {
		return this.m_AuthMap.has(name);
	}

	/**
	 * ログイン処理
	 * @param userId ユーザID
	 * @param authList 権限リスト
	 */
	public login(userId: string, authList?: string[]) {
		if (this.m_UserId != null) {
			this.m_ErrorMngr.addErrorLog('WprAuthorityMngr', '既にログインされています。');
		}
		else if (!userId) {
			this.m_ErrorMngr.addErrorLog('WprAuthorityMngr', 'ユーザIDを指定してください。');
		}
		else {
			this.m_UserId = userId;
			if (authList) {
				const list = new Array();
				for (const name of authList) {
					if (this.checkAuthority(name) === true)
						list.push(name);
					else
						this.m_ErrorMngr.addErrorLog('WprAuthorityMngr', `登録されていない権限が指定されました。[${name}]`);
				}
				this.m_UserAuthList = list;
			}
			else {
				this.m_UserAuthList = null;
			}
		}
	}

	/**
	 * ログアウト処理
	 */
	public logout(): void {
		if (this.m_UserId == null) {
			this.m_ErrorMngr.addErrorLog('WprAuthorityMngr', 'ログインされていません。');
		}
		else {
			this.m_UserId = null;
			this.m_UserAuthList = null;
			this.m_OneTimeAuthList = null;
		}
	}

	/**
	 * 権限追加処理
	 * @param auth 権限
	 */
	public addAuth(auth: string) {
		if (this.checkAuthority(auth) === true) {
			if (this.m_UserAuthList == null)
				this.m_UserAuthList = new Array();
			if (this.m_UserAuthList.includes(auth) === false)
				this.m_UserAuthList.push(auth);
		}
		else {
			this.m_ErrorMngr.addErrorLog('addAuth', `登録されていない権限が指定されました。[${auth}]`);
		}
	}

	/**
	 * 権限削除処理
	 * @param auth 権限
	 */
	public removeAuth(auth: string) {
		if (this.checkAuthority(auth) === true) {
			if (this.m_UserAuthList != null && this.m_UserAuthList.includes(auth) === true) {
				const idx = this.m_UserAuthList.indexOf(auth);
				this.m_UserAuthList.splice(idx, 1);
			}
		}
		else {
			this.m_ErrorMngr.addErrorLog('removeAuth', `登録されていない権限が指定されました。[${auth}]`);
		}
	}

	/**
	 * ユーザ権限チェック
	 * @authList チェックする権限リスト
	 * @returns tureの場合、有効な権限（ユーザ権限に１つでも含まれいればtrue）
	 */
	public checkUserAuthority(authList: string[]): boolean {
		if (this.m_UserAuthList != null) {
			for (const auth of authList) {
				if (this.m_UserAuthList.includes(auth) === true)
					return true;
				if (this.checkOneTimeAuthority(auth) === true)
					return true;
			}
			return false;
		}
		return false;
	}

	/**
	 * OneTime権限追加処理
	 * @param auth 権限
	 * @returns falseの場合、失敗
	 */
	public addOneTimeAuthority(auth: string): boolean {
		if (this.m_UserId == null) {
			this.m_ErrorMngr.addErrorLog('addOneTimeAuthority', 'ログインされていません。');
			return false;
		}
		if (this.m_OneTimeAuthList == null)
			this.m_OneTimeAuthList = new Array();
		if (this.m_OneTimeAuthList.includes(auth) === true)
			return false;
		this.m_OneTimeAuthList.push(auth);
		return true;
	}

	/**
	 * OneTime権限リスト設定
	 * @param authList OneTime権限リスト
	 * @returns falseの場合、失敗
	 */
	public setOneTimeAuthority(authList: string[]): boolean {
		if (this.m_UserId == null) {
			this.m_ErrorMngr.addErrorLog('addOneTimeAuthority', 'ログインされていません。');
			return false;
		}
		this.m_OneTimeAuthList = authList;
		return true;
	}
	// --------------------------------------------------------------------------

	// private メソッド	----------------------------------------------------------
	/**
	 * OneTime権限チェック
	 * @param auth 権限
	 * @returns trueの場合、権限あり
	 */
	private checkOneTimeAuthority(auth: string) {
		if (this.m_OneTimeAuthList != null && this.m_OneTimeAuthList.includes(auth) === true) {
			const idx = this.m_OneTimeAuthList.indexOf(auth);
			this.m_OneTimeAuthList.splice(idx, 1);
			return true;
		}
		return false;
	}
	// --------------------------------------------------------------------------
}
