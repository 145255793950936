import { Card, Divider, TableCell, Typography } from '@material-ui/core';
import { Grid } from '@mui/material';
import React, { ReactNode } from 'react';
import { WprBaseViewComponent } from '../../../../../wpr-framework/view/component/WprBaseViewComponent';
import { MenuBar } from '../../../common/menu_bar/MenuBar';
import { MvnoCapacityChange2View } from './MvnoCapacityChange2View';
import styles from './MvnoCapacityChange2.scss';
import { WprRadio } from '../../../../../wpr-framework/component/controls/WprRadio';
import { MatRadio } from '../../../../../wpr-framework/component/controls/material/MatRadio';
import { CheckMarkRadio } from '../../../../controls/checkMarkRadio/CheckMarkRadio';
import { MvnoPlanSendData, CheckMarkRadioContent } from '../../../../controls/checkMarkRadio/CheckMarkRadioContent';
import { MatButton } from '../../../../../wpr-framework/component/controls/material/MatButton';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { MatOneRowTable } from '../../../../../wpr-framework/component/controls/material/MatOneRowTable';
import { MatTextField } from '../../../../../wpr-framework/component/controls/material/MatTextField';
import { MatTypography } from '../../../../../wpr-framework/component/controls/material/MatTypography';
import { ProgressBar } from '../../../common/progress_bar/ProgressBar';

/**
 * MVNO容量変更_確認用コンポーネント
 */
export class MvnoCapacityChange2 extends WprBaseViewComponent {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: any) { super(new MvnoCapacityChange2View(), props); }
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** MVNO容量変更_確認ビュー情報 */
	public get viewInfo(): MvnoCapacityChange2View { return this.view as MvnoCapacityChange2View; }
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * スタイルオブジェクト取得
	*/
	public getStylesObject(): any { return styles; }

	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		return (
			<>
				<div className='pageCard'>
					<div className="pageHeader stepFlow">
						<ProgressBar name='progressBar' view={this.view} />
					</div>
					<div className='pageContent'>
						<div className='pageTitle'>
							<div className='title_normal'>
								<Typography className='title_text'>お申込み内容確認</Typography>
							</div>
						</div>
					</div>
					<div className='pageContent'>
						<div>
							{/* 料金明細 */}
							<div className='title_gray'>料金明細</div>
							<div className='feeTable'>
								<Grid container className='feeRow'>
									<Grid item xs={12} md={3} className='feeTitle'>
										<div>現在の月額利用料の合計</div>
									</Grid>
									<Grid item xs={12} md={9} className='feeVal' style={{ color: 'black' }}>
										<MatTypography name='totalBefore' style={{ color: 'black' }} view={this.view} component='span' /><span className='itemUnit'>円</span>
									</Grid>
								</Grid>
								<Grid container className='feeRow'>
									<Grid item xs={12} md={3} className='feeTitle'>
										<div>申込みサービス</div>
									</Grid>
									<Grid item xs={12} md={9} className='feeVal'>
										<MatTypography name='planBefore' view={this.view} component='span' style={{ color: 'black' }} />
										<MatTypography name='capacityBefore' view={this.view} component='span' style={{ color: 'black' }} />
									</Grid>
								</Grid>
							</div>
							<div style={{ textAlign: 'center' }}>
								▼
							</div>
							<div className='feeTable'>
								<Grid container className='feeRow'>
									<Grid item xs={12} md={3} className='feeTitle'>
										<div>新しい月額利用料の合計</div>
									</Grid>
									<Grid item xs={12} md={9} className='feeVal'>
										<MatTypography name='totalAfter' view={this.view} component='span' /><span className='itemUnit'>円</span>
									</Grid>
								</Grid>
								<Grid container className='feeRow'>
									<Grid item xs={12} md={3} className='feeTitle'>
										<div>申込みサービス</div>
									</Grid>
									<Grid item xs={12} md={9} className='feeVal'>
										<MatTypography name='planBefore' view={this.view} component='span' />
										<MatTypography name='capacityAfter' view={this.view} component='span' />
									</Grid>
								</Grid>
							</div>
						</div>


						{/* 申込内容 */}
						<div>
							<div className='title_gray'>
								<span>お申込み内容のご確認</span>
							</div>
							<div>
								<div className='box_gray'>

									<div className='boxContent onlyPcColGroup'>
										<MatOneRowTable cols={[3, 2]} className='detailItem'>
											<TableCell className='itemTitle'>
												<Typography>変更するプラン</Typography>
											</TableCell>
											<TableCell align='right' className='itemText'>
												{/* <Typography component='span'>6GB（月額：0,000円）</Typography> */}
												<MatTypography name='capacityBefore' view={this.view} component='span' />
											</TableCell>
										</MatOneRowTable>

										<MatOneRowTable cols={[3, 2]} className='detailItem'>
											<TableCell className='itemTitle'>
												<Typography>変更後のプラン</Typography>
											</TableCell>
											<TableCell align='right' className='itemText'>
												{/* <Typography component='span'>10GB</Typography> */}
												<MatTypography name='capacityAfter' view={this.view} component='span' />
											</TableCell>
										</MatOneRowTable>

										<MatOneRowTable cols={[3, 2]} className='detailItem'>
											<TableCell className='itemTitle'>
												<Typography>回線番号</Typography>
											</TableCell>
											<TableCell align='right' className='itemText'>
												<MatTypography name='phoneNumber' view={this.view} component='span' />
											</TableCell>
										</MatOneRowTable>
										<MatOneRowTable cols={[3, 2]} className='detailItem'>
											<TableCell className='itemTitle'>
												<Typography>ICCID</Typography>
											</TableCell>
											<TableCell align='right' className='itemText'>
												<MatTypography name='iccId' view={this.view} component='span' />
											</TableCell>
										</MatOneRowTable>
										{/* <MatOneRowTable cols={[3, 2]} className='detailItem'>
											<TableCell className='itemTitle'>
												<Typography>変更手数料</Typography>
											</TableCell>
											<TableCell align='right' className='itemText'>
												<MatTypography name='changeFee' view={this.view} component='span' />円
											</TableCell>
										</MatOneRowTable> */}
									</div>
								</div>
								<div className='comment'>
									<ul>
										<li>
											表示されている月額は定価となります。実際の請求額とは異なる場合があります。
										</li>
										<li>
											月額利用料の合計には割引も適用されています（キャンペーン等の期間限定割引を除く）
										</li>
										<li>
											キャンペーン等の期間限定割引につきましては、契約内容の変更により割引が継続されない場合がございます。
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>

					<Divider />
					<div className='pageFooter'>
						<div>
							<MatButton name='next' view={this.view} className='nextButton' >
								次へ
								<NavigateNextIcon />
							</MatButton>
						</div>
						<div>
							<MatButton name='back' view={this.view} className='backButton' >
								<NavigateBeforeIcon />
								内容を修正する
							</MatButton>
						</div>
					</div>
				</div>
			</>
		);
	}
	// --------------------------------------------------------------------------
}

