import React, { ReactNode } from 'react';
import { WprBaseViewComponent } from '../../../../wpr-framework/view/component/WprBaseViewComponent';
import { NotFoundView } from './NotFoundView';

/**
 * BS-other-001_その他_404エラー画面用コンポーネント
 */
export class NotFound extends WprBaseViewComponent {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: any) { super(new NotFoundView(), props); }
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		return (
			<div>
				<h3>404 page not found</h3>
			</div>
		);
	}
	// --------------------------------------------------------------------------
}
