import React, { ReactNode } from 'react';
import { WprBaseViewComponent } from '../../../../wpr-framework/view/component/WprBaseViewComponent';
import { MobileConfirmView } from './MobileConfirmView';
import styles from './MobileConfirm.scss';
import { Typography, Grid, Divider } from '@material-ui/core';
import { MatButton } from '../../../../wpr-framework/component/controls/material/MatButton';
import { ProgressBar } from '../../common/progress_bar/ProgressBar';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { MatTypography } from '../../../../wpr-framework/component/controls/material/MatTypography';
import netIcon from '/shared/image/common/logo_iTSCOMNET.png';
import important from '/shared/image/common/important_purple.png';

/**
 * かっとびモバイル［解約申込み］内容確認用コンポーネント
 */
export class MobileConfirm extends WprBaseViewComponent {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: any) { super(new MobileConfirmView(), props); }
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** かっとびモバイル［解約申込み］内容確認ビュー情報 */
	public get viewInfo(): MobileConfirmView	{ return this.view as MobileConfirmView;	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * スタイルオブジェクト取得
	*/
	public getStylesObject(): any { return styles; }

	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		return (
			<>
			<div className='pageCard'>
				<div className="pageHeader stepFlow">
					<ProgressBar name='progressBar' view={this.view}/>
				</div>
				<div className='sub-logo'>
					<img src={netIcon}></img>
				</div>
				<div className='main table_margin'>
					<div className='pageTitle'>
						<div className='title_left'>
							<Typography className='title_text title_margin'>iTSCOM NET かっとびモバイル 解約申込み確認</Typography>
							<Typography className='title_comment'>以下のかっとびモバイル（EM）を解約します。<br/>よろしければ、「解約」ボタンをクリックしてください。</Typography>
						</div>
					</div>
					<div className='feeTable'>
						<Grid container className='feeRow'>
							<Grid item xs={12} sm={4} md={4} className='feeTitle table_title'>
								<p className=''>かっとびモバイル（EM）</p>
								<p className=''>シリアル番号</p>
							</Grid>
							<Grid item xs={12} sm={4} md={8} className='feeVal' style={{ color: 'black' }}>
								<MatTypography name='equipmentID' view={this.view}/>
							</Grid>
						</Grid>
					</div>	
					<div className='supportMsgArea'>
						<ul className='aster' style={{ listStyle: 'none'}}>
							<span className='textcolor'>
							<img src={important}></img>
								解約に関して
							</span>
								<li className='comment'>※ 25ヶ月以内のご解約の場合、契約解約料金が発生いたします。
									<br/>
									<div className='text'>※ かっとびバリューの月額利用料が<MatTypography name='amount' view={this.view} component='span'/>円（税込）に変更となります。</div>
								</li>
							
						</ul>
					</div>
				</div>
				<Divider />
				<div className='pageFooter footer-space'>
					<div>
						<MatButton name='next' className='orangeButton orangeButton_parts' view={this.view}>解約
							<NavigateNextIcon />
						</MatButton>
					</div>
					<div>
						<MatButton name='back' view={this.view} className='backButton' >
							<NavigateBeforeIcon />
								戻る
						</MatButton>
					</div>
				</div>
			</div>

			</>
		);
	}
	// --------------------------------------------------------------------------
}
