/**
 * HTTP通信エラー情報
 */
export class WprHttpErrorInfo {
	// private 変数  ------------------------------------------------------------
	private m_Url: string		= null;		// URL
	private m_Status: string	= null;		// エラーステータス
	private m_Message: string	= null;		// エラーメッセージ
	private m_Error: Error		= null;		// エラー情報
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** URL */
	public get url(): string		{ return this.m_Url;		}
	/** エラーステータス */
	public get status(): string		{ return this.m_Status;		}
	/** エラーメッセージ */
	public get message(): string	{ return this.m_Message;	}
	/** エラー情報 */
	public get error(): Error		{ return this.m_Error;		}
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	constructor(url: string, status: string, message: string, error?: Error) {
		this.m_Url = url;
		this.m_Status = status;
		this.m_Message = message;
		this.m_Error = error;
	}
	// --------------------------------------------------------------------------
}