import { MypageBaseView } from "../../base/MypageBaseView";


/**
 * 電子発行承諾
 */
export class S_01_002View extends MypageBaseView {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() { super('S_01_002'); }
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * アクション登録
	 */
	public onRegisterAction(): void {
		this.addAction('b_Next', this.onB_Next);
	}

	/**
	 * ビュー表示通知
	 */
	public onShowView(): void {
		this.registAccessLog();
		this.setStorageItem('b_url', window.location.href)
	}
	// --------------------------------------------------------------------------

	// アクション  --------------------------------------------------------------
	/**
	 * 同意
	 */
	public onB_Next(param: any): void {
		this.pushPath('/s004');
	}
	
	// --------------------------------------------------------------------------
}
