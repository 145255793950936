

import { WprModel } from "../../../../wpr-framework/view/deco/WprModel";
import { WprBaseDialogViewInfo } from "../../../../wpr-framework/view/dialog/WprBaseDialogViewInfo";
import { WprEventName } from "../../../../wpr-framework/action/WprEventActionInfo";

export class PDFDialogSendData {
	public base64Data: string;	// PDFデータ（エンコード）
}
export class PDFDialogResultData {
	public isVisibility: boolean;	// 最下位までスクロール済か
}

/**
 * pdfダイアログ
 */
export class PDFDialogView extends WprBaseDialogViewInfo {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() { super('PDFDialog'); }
	// --------------------------------------------------------------------------

	// モデル  ------------------------------------------------------------------
	// @WprListData('PDFInfo')
	// private m_PDFInfo: String[]	= null;	// base64PDF
	@WprModel('pdf')
	private m_PDFInfo: string	= null;	// base64PDF
	private m_IsScrollBottom: boolean = false;
	public get PDFInfo(): string {return this.m_PDFInfo};
	// --------------------------------------------------------------------------

	// DI情報  ------------------------------------------------------------------
	// @WprDI_Control
	// private m_pdfScroll: WprControlInfo	= null;	// PDFスクロール用
	// --------------------------------------------------------------------------
	// override プロパティ  ------------------------------------------------------
	/** ダイアログタイトル */
	public get title(): string { return '契約内容確認書'; }
	/** バリデーション */
	public get validation(): boolean { return false; }
	/** キャンセルボタン使用フラグ */
	public get useCancelButton(): boolean 	{ return false; }
	/** OKボタン使用フラグ */
	public get useOKButton(): boolean 		{ return false; }
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * コントロール定義設定
	 */
	public onRegisterConfig(): void {
		this.addConfig('pdf', 'pdf', 'pdf', { bindModel: true });
	}

	/**
	 * アクション登録
	 */
	 public onRegisterAction(): void {
		this.addEventAction('pdfScroll', WprEventName.onScroll, this.onPdfScroll, false);
	}

	/**
	 * ダイアログ表示通知
	 * @param sendData 送信データ
	 */
	public onShowDialog(sendData: PDFDialogSendData) {
		this.m_PDFInfo = sendData.base64Data;
		this.setViewData('pdfData', this.m_PDFInfo);
		this.refreshView();
	}

	/**
	 * ダイアログキャンセル
	 */
	public onDialogCancel(): void{
		const resultData: PDFDialogResultData = new PDFDialogResultData();
		resultData.isVisibility = this.m_IsScrollBottom;
		this.close(false, resultData);
	}
	// --------------------------------------------------------------------------

	// アクション  ---------------------------------------------------------------
	/**
	 * PDFスクロール
	 */
	public onPdfScroll(name: string, event: UIEvent): void {
		const pdfScroll = document.getElementsByClassName('reactPdf');
		// const pdfScroll = this.m_pdfScroll;
		if (pdfScroll != null) {
			const allHeight = pdfScroll[0].scrollHeight;
			const scrollHeight = pdfScroll[0].scrollTop;
			const frameHeight = pdfScroll[0].clientHeight;
			const isVisibility = allHeight <= scrollHeight + frameHeight + 50? true: false;
			// ↑「+50」はスクロール判定に余裕を持たせるため、ブラウザ等によって判定が想定通りにいかない場合に備えて。
			if (isVisibility) {
				this.m_IsScrollBottom = true;
			}
		}
	}
	// --------------------------------------------------------------------------
}
