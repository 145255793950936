import { createTheme, responsiveFontSizes } from '@material-ui/core';
import { color } from '@mui/system';

const theme = createTheme({
    typography: {
        // fontFamily: '"游ゴシック", "Yu Gothic","ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro","メイリオ", "Meiryo", "ＭＳ Ｐゴシック", "MS PGothic", sans-serif'
        fontFamily: '"游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", "ヒラギノ角ゴ ProN W3","Hiragino Kaku Gothic ProN", メイリオ, Meiryo, sans-serif !important',
        fontSize: 14
    }
});

export default theme;