import { WprBaseViewListMultiModeInfo } from '../../../../../../wpr-framework/mode/WprBaseViewListMultiModeInfo';
import { WprMultiMode } from '../../../../../../wpr-framework/mode/WprMultiMode';
import { ItscomHomeOptionListModel } from '../../../../../service/models/apimodels/ItscomHomeOptionListModel';
import { ItscomHomeComfirmView } from '../ItscomHomeComfirmView';
import { WprDI_Control } from '../../../../../../wpr-framework/view/di/WprDI_Control';
import { WprControlInfo } from '../../../../../../wpr-framework/view/control/WprControlInfo';

/**
 * オプションリストモード
 */
export class OptionListMode extends WprBaseViewListMultiModeInfo<ItscomHomeComfirmView, ItscomHomeOptionListModel> {
	// DI情報  ------------------------------------------------------------------
	@WprDI_Control
	private m_quantity1: WprControlInfo	= null;	// 数量1
	@WprDI_Control
	private m_quantity2: WprControlInfo	= null;	// 数量2
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	public constructor() {
		super('OptionComfirmListModel');
	}
	// --------------------------------------------------------------------------
	
	// override メソッド  --------------------------------------------------------
	/**
	 * モードリスト取得（この画面モードで使用する全てのモードを定義する）
	 * @param list モードリスト
	 */
	public useModeList(list: string[]): void {
		list.push('レンタルあり');
		list.push('レンタルなし');
		list.push('購入あり');
		list.push('購入なし');
	}

	/**
	 * フィールド定義を登録処理
	 */
	public onRegisterControl(): void {
		this.addControl('withRental', { display: [ { mode: 'レンタルあり', value: true }, { mode: 'other', value: false } ] });
		this.addControl('withoutRental', { display: [ { mode: 'レンタルなし', value: true }, { mode: 'other', value: false } ] });
		this.addControl('withBuy', { display: [ { mode: '購入あり', value: true }, { mode: 'other', value: false } ] });
		this.addControl('withoutBuy', { display: [ { mode: '購入なし', value: true }, { mode: 'other', value: false } ] });
	}

	/**
	 * ビューの状態をチェックして行のモードを変更する
	 * @param curMode 現在のモード
	 * @param newMode 新しいモード
	 * @param row 行データ
	 */
	public onCheckListMultiMode(curMode: WprMultiMode, newMode: WprMultiMode, row: ItscomHomeOptionListModel): void {
		if (row.quantity1) {
			newMode.addMode('レンタルあり');
		}
		else {
			newMode.addMode('レンタルなし');
		}
		if (row.buyFlag && row.quantity2) {
			newMode.addMode('購入あり');
		}
		else {
			newMode.addMode('購入なし');
		}
	}
	// --------------------------------------------------------------------------
}
