import { WprKeyValue } from '../../../wpr-framework/model/WprKeyValue';
import { ObjectUtil } from '../../common/util/ObjectUtil';
import { ProgressBarModel } from '../../models/ProgressBarModel';
import { MvnoEntryInfo } from '../../service/models/apimodels/MvnoEntryInfo';
import { HMvnoTmpSaved } from '../../service/models/entity/HMvnoTmpSaved';
import { ProgressBarData } from '../common/progress_bar/ProgressBarView';
import { MvnoEntryFooterReceiveData } from '../mvno/entry/mvno_entry_footer/MvnoEntryFooterView';
import { MypageBaseView } from './MypageBaseView';

/**
 * MVNOベースクラス
 */
export abstract class MvnoBaseView extends MypageBaseView {
	// protected getProgressModelList(): ProgressBarModel[] {
	// 	return new Array();
	// }
	// override メソッド  --------------------------------------------------------
	/**
	 * ベースクラス名取得
	 * @returns ベースクラス名
	 */
	public getBaseName(): string {
		this.setParentBaseName(super.getBaseName());
		return 'MvnoBaseViewView';
	}


	// public onResizeWindow(width: number, height: number): void {
	// 	// const sendData: ProgressBarData = new ProgressBarData();
	// 	// sendData.progressBarList = this.getProgressModelList();
	// 	this.sendChildData('fP_ev_progressResize', null);
	// }
	// --------------------------------------------------------------------------
	protected sendChildFortempSave(): void {
		const sendData: MvnoEntryFooterReceiveData = new MvnoEntryFooterReceiveData();
		sendData.viewNmae = this.name;
		this.sendChildData('fP_ev_料金明細表示', sendData);
	}

	protected setCookieValForMvno(entryInfo: MvnoEntryInfo): void {
		let fieldList: string[] = new Array();
		for (let key in entryInfo.entryInfo) {
			this.setCookie(key, entryInfo.entryInfo[key]);
			fieldList.push(key);
		}
		const fieldListStr: string = fieldList.toString();
		this.setCookie('entryFieldList', fieldListStr);
		// this.setCookie('total', entryInfo.total);

		this.setScopeModel('MvnoEntryInfo', entryInfo);
	}

	protected getCookieValListForMvno(entryInfo: MvnoEntryInfo): void {
		const fieldListStr: string = this.getCookie('entryFieldList');
		if (ObjectUtil.isNotNullOrUndefined(fieldListStr) && ObjectUtil.isNotNullOrEmptyStr(fieldListStr)) {
			const fieldList: string[] = fieldListStr.split(',');
			for (let key of fieldList) {
				let val: any = this.getCookie(key);
				if (val === 'true' || val === 'false') {
					val = ObjectUtil.stringToBoolean(val);
				}
				else if (val === 'null') {
					val = null;
				}
				entryInfo.entryInfo[key] = val;
			}
		}
		entryInfo.entryInfo.customerId = this.getCustomerId();
		entryInfo.total = this.getCookie('total');
		const iPhoneFlg = this.getCookie('iPhoneFlg');
		if (iPhoneFlg == 'true') {
			entryInfo.iPhoneFlg = true;
		}
		const noJoinFlg = this.getCookie('noJoinFlg');
		if (noJoinFlg == 'true') {
			entryInfo.noJoinFlg = true;
		} else {
			entryInfo.noJoinFlg = false;
		}
	}
}
