import { Card, Divider, Typography } from '@material-ui/core';
import { Grid } from '@mui/material';
import React, { ReactNode } from 'react';
import { WprBaseViewComponent } from '../../../../../wpr-framework/view/component/WprBaseViewComponent';
import { MenuBar } from '../../../common/menu_bar/MenuBar';
import { MvnoEntryCustomerView } from './MvnoEntryCustomerView';
import styles from './MvnoEntryCustomer.scss';
import { MatRadio } from '../../../../../wpr-framework/component/controls/material/MatRadio';
import { WprRadio } from '../../../../../wpr-framework/component/controls/WprRadio';
import { MatButton } from '../../../../../wpr-framework/component/controls/material/MatButton';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { WprContainer } from '../../../../../wpr-framework/component/controls/WprContainer';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';

import { MatTextField } from '../../../../../wpr-framework/component/controls/material/MatTextField';
import ErrorIcon from '@mui/icons-material/Error';
import { MatSelect } from '../../../../../wpr-framework/component/controls/material/MatSelect';
import { MvnoEntryFooter } from '../mvno_entry_footer/MvnoEntryFooter';
import { MatTypography } from '../../../../../wpr-framework/component/controls/material/MatTypography';
import { MatRadioGroup } from '../../../../../wpr-framework/component/controls/material/MatRadioGroup';
import { MvnoHelpDialog } from '../../dialog/mvno_help_dialog/MvnoHelpDialog';
import { WprAnchor } from '../../../../../wpr-framework/component/controls/WprAnchor';
import { WprError } from '../../../../../wpr-framework/component/controls/WprError';
import { WprViewError } from '../../../../../wpr-framework/component/controls/WprViewError';
import { CheckMarkRadioContent, MvnoTerminalUmuSendData } from '../../../../controls/checkMarkRadio/CheckMarkRadioContent';
import { CheckMarkRadio } from '../../../../controls/checkMarkRadio/CheckMarkRadio';
import { ProgressBar } from '../../../common/progress_bar/ProgressBar';
import { WprControlErrorMap } from '../../../../../wpr-framework/view/validation/control/WprControlErrorMap';
import { WprErrorContainer } from '../../../../../wpr-framework/component/controls/WprErrorContainer';
import { MatDatePicker } from '../../../../../wpr-framework/component/controls/material/MatDatePicker';

/**
 * MVNO申込_ご利用者情報用コンポーネント
 */
export class MvnoEntryCustomer extends WprBaseViewComponent {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: any) { super(new MvnoEntryCustomerView(), props); }
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** MVNO申込_ご利用者情報ビュー情報 */
	public get viewInfo(): MvnoEntryCustomerView { return this.view as MvnoEntryCustomerView; }
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * スタイルオブジェクト取得
	*/
	public getStylesObject(): any { return styles; }

	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		return (
			<>
				{/* ツールから追加 START */}




				{/* ツールから追加 END */}
				<div>
					<div className='pageCard' id='mvnoEntryCustomerID'>
						<div className="pageHeader stepFlow">
							<ProgressBar name='progressBar' view={this.view} />
						</div>

						<div className='pageContent'>
							<div className='link_blue'>
								<WprAnchor name='helpLink' view={this.view}>申込みについてご不明な方はこちら＞</WprAnchor>
							</div>

							<div className='pageTitle'>
								<div className='title_normal'>
									<Typography className='title_text'>ご利用者さま情報を入力</Typography>
									<div className='title_memo'>
										※ご契約者さまと同じ住所にお住まいである必要がございます
									</div>
								</div>
							</div>


							{/* ご利用者名（漢字） */}
							<Divider />
							<div>
								<div className='customerItem'>
									<div>
										<Typography className='itemTitle'>ご利用者名（漢字）</Typography>
									</div>
									<Grid container spacing={1} textAlign='left'>
										<Grid item xs={12} md={6}>
											<div className='customerInput'>
												<Grid container justifyContent='center' alignItems='center'>
													<Grid item xs={2} md={1} className='customerInputLabel'>
														<span>姓</span>
													</Grid>
													<Grid item xs={10} md={11}>
														<MatTextField name='familyName' placeholder='田中' view={this.view} />
													</Grid>
												</Grid>
											</div>
										</Grid>
										<Grid item xs={12} md={6}>
											<div className='customerInput'>
												<Grid item xs={2} md={1} className='customerInputLabel'>
													<span>名</span>
												</Grid>
												<Grid item xs={10} md={11}>
													<MatTextField name='firstName' placeholder='太郎' view={this.view} />
												</Grid>
											</div>
										</Grid>
									</Grid>
								</div>

								{/* フリガナ */}
								<Divider />
								<div className='customerItem'>
									<div>
										<Typography className='itemTitle'>フリガナ（全角）</Typography>
									</div>
									<Grid container spacing={1} textAlign='left'>
										<Grid item xs={12} md={6}>
											<div className='customerInput'>
												<Grid container>
													<Grid item xs={2} md={1} className='customerInputLabel'>
														<span>セイ</span>
													</Grid>
													<Grid item xs={10} md={11}>
														<MatTextField name='familyNameKana' placeholder='タナカ' view={this.view} />
													</Grid>
												</Grid>
											</div>
											{/* <WprViewError name='familyNameKana' view={this.view} /> */}
										</Grid>
										<Grid item xs={12} md={6}>
											<div className='customerInput'>
												<Grid item xs={2} md={1} className='customerInputLabel'>
													<span>メイ</span>
												</Grid>
												<Grid item xs={10} md={11}>
													<MatTextField name='firstNameKana' placeholder='タロウ' view={this.view} />
												</Grid>
											</div>
										</Grid>
									</Grid>
								</div>

								{/* 続柄 */}
								<Divider />
								<div className='customerItem'>
									<div>
										<Typography className='itemTitle'>イッツコムご契約者さまとの続柄</Typography>
									</div>
									<div className='link_blue'>
										<WprAnchor name='aboutRelationShip' view={this.view}>続柄について⊕</WprAnchor>
									</div>
									<Grid container spacing={1} textAlign='left' >
										<Grid item xs={12}>
											<div className='radioGroup'>
												<MatRadioGroup name='relationShip' view={this.view} >
													<Grid container spacing={1}>
														<Grid item xs={4} md={2}>
															<MatRadio name='relationShip' id='relationShip_1' value='本人' label='本人' view={this.view} />
														</Grid>
														<Grid item xs={4} md={2}>
															<MatRadio name='relationShip' id='relationShip_2' value='配偶者' label='配偶者' view={this.view} />
														</Grid>
														<Grid item xs={4} md={2}>
															<MatRadio name='relationShip' id='relationShip_3' value='子' label='子' view={this.view} />
														</Grid>
														<Grid item xs={4} md={2}>
															<MatRadio name='relationShip' id='relationShip_4' value='父母' label='父母' view={this.view} />
														</Grid>
														<Grid item xs={4} md={2}>
															<MatRadio name='relationShip' id='relationShip_5' value='祖父母' label='祖父母' view={this.view} />
														</Grid>
														<Grid item xs={4} md={2}>
															<MatRadio name='relationShip' id='relationShip_6' value='孫' label='孫' view={this.view} />
														</Grid>
													</Grid>
												</MatRadioGroup>
											</div>
										</Grid>
									</Grid>
								</div>

								{/* 生年月日 */}
								<Divider />
								<div className='customerItem'>
									<div>
										<Typography className='itemTitle'>生年月日</Typography>
									</div>
									<Grid container spacing={1} textAlign='left'>
										<Grid item xs={12} md={2} className='birthdayInput'>
											<div className='customerInput'>
												<MatSelect name='birthdayYear' vm='year' view={this.view} />
											</div>
											<div className='unit'>年</div>
										</Grid>
										<Grid item xs={12} md={2} className='birthdayInput'>
											<div className='customerInput'>
												<MatSelect name='birthdayMonth' vm='month' view={this.view} />
											</div>
											<div className='unit'>月</div>
										</Grid>
										<Grid item xs={12} md={2} className='birthdayInput'>
											<div className='customerInput'>
												<MatSelect name='birthdayDay' vm='day' view={this.view} />
											</div>
											<div className='unit'>日</div>
										</Grid>

									</Grid>
									<WprContainer mode='プラン.音声プラン' view={this.view}>
										<WprErrorContainer name='under18WebFilteringError' view={this.view} >
											<div style={{ color: 'red' }}>
												Webフィルタリングのお申込みは
												<WprAnchor name='editWebFiltering' view={this.view} className='link_underline errorText'>こちら</WprAnchor>から変更できます。
											</div>
										</WprErrorContainer>
										<div className='comments'>
											<span>※ご利用者が18歳未満の場合原則としてWebフィルタリングにお申込みいただく必要があります。</span><br />
											<span>Webフィルタリングは1シリアルIDあたり220円がかかります。</span>
										</div>
									</WprContainer>
								</div>

								{/* 住所 */}
								<Divider />
								<div className='customerItem'>
									<div>
										<Typography className='itemTitle'>住所</Typography>

									</div>
									<Grid container spacing={1} textAlign='left'>
										<Grid item xs={12}>
											<div className='disabledText'>
												<MatTypography name='address' view={this.view} />
											</div>
										</Grid>
									</Grid>
									<div className='comments'>
										<span>※お申込みには契約者と同住所である必要があります</span>
									</div>
								</div>

								{/* 携帯電話番号 */}
								<Divider />
								<div className='customerItem'>
									<div>

										<Typography className='itemTitle'>携帯電話番号</Typography>
									</div>
									<Grid container spacing={1} textAlign='left'>
										<Grid item xs={12} md={6}>
											<div className='customerInput'>
												<MatTextField name='phoneNumber1' placeholder='08012345678' view={this.view} />
												{/* -
												<MatTextField name='phoneNumber2' placeholder='1234' view={this.view} />
												-
												<MatTextField name='phoneNumber3' placeholder='5678' view={this.view} /> */}
											</div>
										</Grid>
									</Grid>
									<div className='comments'>
										<span>※本人確認書類に不備があった場合のご連絡先となります。</span>
									</div>
								</div>

								{/* 採番方法 */}
								<WprContainer mode='プラン.音声プラン' view={this.view}>


									<Divider />
									<div className='customerItem'>
										<div>
											<Typography className='itemTitle'>採番方法</Typography>
										</div>
										<div className='link_blue'>
											<WprAnchor name='aboutNumbering' view={this.view}>採番方法について⊕</WprAnchor>
										</div>
										<Grid container spacing={1} textAlign='left'>
											{/* <Grid item xs={12}>
										</Grid> */}
											<Grid item xs={12}>
												<div className='radioGroup'>
													<MatRadioGroup name='numberingWay' view={this.view}>
														<Grid container spacing={1} className='entryRadio'>
															<Grid item xs={12} md={6}>
																{/* <MatRadio name='numberingWay' id='numberingWay_1' value='新規電話番号' label='新規電話番号の発番' view={this.view} /> */}
																<CheckMarkRadio name='numberingWay' id='numberingWay_1' value={'新規電話番号'} itemType={CheckMarkRadioContent.MVNO_TERMINAL_UMU} radioContentData={this.getTerminalUmuData('新規電話番号の発番')} view={this.view} />
															</Grid>

															<Grid item xs={12} md={6}>
																{/* <MatRadio name='numberingWay' id='numberingWay_2' value='MNP' label='他社利用中電話番号のモバイルナンバーポータビリティ（MNP）' view={this.view} /> */}
																<CheckMarkRadio name='numberingWay' id='numberingWay_2' value={'MNP'} itemType={CheckMarkRadioContent.MVNO_TERMINAL_UMU} radioContentData={this.getTerminalUmuData('他社利用中電話番号のモバイルナンバーポータビリティ（MNP）')} view={this.view} />
															</Grid>
														</Grid>
													</MatRadioGroup>
												</div>
											</Grid>


											{/* MNP */}
											<WprContainer mode='採番方法.MNP' view={this.view}>
												<Grid item xs={12}>
													<div className='mnpContent'>
														<Grid container spacing={1} className='mnpItem' alignItems={'center'}>
															<Grid item xs={12} md={6}>
																<Typography className='itemTitle'>MNP予約番号</Typography>
																<span className='comments'>（ハイフン不要）</span>
															</Grid>
															<Grid item xs={12} md={6}>
																<MatTextField name='mnpReqNumber' placeholder='10桁の数字' view={this.view} />
																<div className='comments'>
																	※10桁の数字になります。
																</div>
															</Grid>
															<Grid item xs={12} md={6}>
																<Typography className='itemTitle'>MNP予約有効期限</Typography>
															</Grid>
															<Grid item xs={12} md={6}>
																{/* <MatTextField name='mnpReqExpire' type='date' view={this.view} /> */}

																<MatDatePicker name='mnpReqExpire' view={this.view} placeholder='yyyy/MM/dd' use='mobile' />
																{/* <DatePicker */}
																<div className='comments'>
																	※MNP予約番号の有効期間は予約当日を含めて15日間です。
																</div>
															</Grid>
															<Grid item xs={12} md={6}>
																<Typography className='itemTitle'>MNP電話番号</Typography>
																<span className='comments'>（ハイフン不要）</span>
															</Grid>
															<Grid item xs={12} md={6}>
																<MatTextField name='mnpUseNumber' placeholder='08012345678' view={this.view} />
																{/* <div className='comments'>半角数字で入力してください。</div> */}
															</Grid>
														</Grid>
														<Divider />
														<div className='mnpInfo'>
															<div className='mnpInfoTitle'>
																<ErrorIcon />MNP利用時のご注意
															</div>
															<ul style={{ paddingInlineStart: '1rem' }}>
																<li>
																	MNP情報は正しく入力してください。入力情報に誤りがあった場合、MNP転入処理が正しく行われない場合があります。
																</li>
																<li>
																	契約者名義は、主契約の名義と同一であることが必須です。主契約名義が不明の場合は、現在契約している事業者にお問い合わせください。
																</li>
															</ul>
														</div>
													</div>
												</Grid>
											</WprContainer>
										</Grid>
									</div>
								</WprContainer>

								{/* 初期設定サポートの希望日時 */}
								<WprContainer mode='初期サポート.有' view={this.view}>
									<Divider />
									<div className='customerItem'>
										<div>
											<Typography className='itemTitle'>初期設定サポートの希望日時</Typography>
										</div>
										<div>
											<Grid container spacing={1} textAlign='left'>
												<Grid item xs={12} md={6} className='itemTitle2'>
													第一希望
													<div className='customerInput'>
														<MatDatePicker name='reqDay1' view={this.view} placeholder='yyyy/MM/dd' use='mobile' />
														{/* <MatTextField name='reqDay1' type='date' view={this.view} /> */}
													</div>
												</Grid>
												<Grid item xs={12} md={6} className='itemTitle2'>
													時間帯
													<div className='customerInput'>
														<MatSelect name='reqTime1' vm='mvnoRequestTime' view={this.view} />
													</div>
												</Grid>
												<Grid item xs={12} md={6} className='itemTitle2'>
													第二希望
													<div className='customerInput'>
														<MatDatePicker name='reqDay2' view={this.view} placeholder='yyyy/MM/dd' use='mobile' />
														{/* <MatTextField name='reqDay2' type='date' view={this.view} /> */}
													</div>
												</Grid>
												<Grid item xs={12} md={6} className='itemTitle2'>
													時間帯
													<div className='customerInput'>
														<MatSelect name='reqTime2' vm='mvnoRequestTime' view={this.view} />
													</div>
												</Grid>
												<Grid item xs={12} md={6} className='itemTitle2'>
													第三希望
													<div className='customerInput'>
														<MatDatePicker name='reqDay3' view={this.view} placeholder='yyyy/MM/dd' use='mobile' />
														{/* <MatTextField name='reqDay3' type='date' view={this.view} /> */}
													</div>
												</Grid>
												<Grid item xs={12} md={6} className='itemTitle2'>
													時間帯
													<div className='customerInput'>
														<MatSelect name='reqTime3' vm='mvnoRequestTime' view={this.view} />
													</div>
												</Grid>
											</Grid>
											<WprViewError name='mnpMaxError' view={this.view} />
											<WprViewError name='sameReqDateError' view={this.view} />
										</div>
										<div>
											※訪問担当が到着するおおよその時間帯目安となります。
											<br />※ご訪問日時については近日中にお電話にてご連絡いたします。
											<br />訪問担当の空き状況によってご希望に沿えない場合もございますことご了承ください。
										</div>
									</div>
								</WprContainer>

								{/* キャンペーンコード */}
								<Divider />
								<div className='customerItem'>
									<div>
										<Typography className='itemTitle'>キャンペーンコード</Typography>
									</div>
									<Grid container spacing={1} textAlign='left'>
										<Grid item xs={12} md={6}>
											<div className='customerInput'>
												<MatTextField name='campaignCode' placeholder='0123456789' view={this.view} />
											</div>
											<div className='comments'>
												※キャンペーンコードをお持ちの場合はご入力ください
											</div>
										</Grid>
									</Grid>
									<WprViewError name='campaignCodeError' view={this.view} />
								</div>
							</div>
						</div>
						<Divider />
						<div className='pageFooter'>
							<div>
								<WprContainer mode='採番方法.MNPでない' view={this.view}>
									<MatButton name='next1' view={this.view} className='nextButton' >
										次へ
										<NavigateNextIcon />
									</MatButton>
								</WprContainer>
								<WprContainer mode='採番方法.MNP' view={this.view}>
									<MatButton name='next2' view={this.view} className='nextButton' >
										次へ
										<NavigateNextIcon />
									</MatButton>
								</WprContainer>
							</div>
							<div>
								<MatButton name='back' view={this.view} className='backButton' >
									<NavigateBeforeIcon />
									戻る
								</MatButton>

							</div>
							<div className='stepNumber'>4/4</div>
						</div>
					</div>
				</div>
				<MvnoEntryFooter name='mvnoFooter' view={this.view} />

				{/* ダイアログ */}
				<MvnoHelpDialog name='MVNO_ヘルプダイアログ' view={this.view} className='dialog-medium' />
			</>
		);
	}

	private getTerminalUmuData(val: string): MvnoTerminalUmuSendData {
		const data: MvnoTerminalUmuSendData = new MvnoTerminalUmuSendData();
		data.val = val;
		return data;
	}
	// --------------------------------------------------------------------------
}
