import { ProgressBarModel } from '../../../../models/ProgressBarModel';
import { MypageBaseView } from '../../../base/MypageBaseView';
import { ProgressBarData } from '../../../common/progress_bar/ProgressBarView';
import { WprModel } from '../../../../../wpr-framework/view/deco/WprModel';
import { WprDI_ScopeModel } from '../../../../../wpr-framework/view/di/WprDI_ScopeModel';
import { MypageWizardMngr } from '../../../wizard/models/MypageWizardMngr';
import { WprListData } from '../../../../../wpr-framework/view/deco/WprListData';
import MypageDispdivcdConstants from '../../../../common/MypageDispdivcdConstants';
import { NetServiceNewWizardModel } from '../../../wizard/NetServiceNewWizardFlow';
import { ObjectUtil } from '../../../../common/util/ObjectUtil';
import { BaseInfoListModel } from '../mod_net_select/models/BaseInfoListModel';
import { ContractInfo } from '../mod_net_select/models/ContractInfo';
import { WprViewMode } from '../../../../../wpr-framework/view/deco/WprViewMode';
import { CourseListMode } from './mode/CourseListMode';
import { CourseListModel } from '../../../../models/mypage/net/CourseListModel';
import { MCommon } from '../../../../service/models/entity/primary/MCommon';
import { NetFlowModel } from '../../../../models/mypage/net/NetFlowModel';

/**
 * 変更その他サービス設定
 */
export class ModOthernetSelectView extends MypageBaseView {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() { super('ModOthernetSelectView'); }
	// --------------------------------------------------------------------------
	
	// モデル  ------------------------------------------------------------------
	@WprDI_ScopeModel('MypageWizardMngr')
	private m_MypageWizardMngr: MypageWizardMngr			= null;		// 画面ウィザード管理クラス
	@WprListData('OtherCourseListModel')
	private m_OtherCourseListModel: CourseListModel[]		= null;		// 画面表示用
	@WprModel('NetInfo')
	private m_NetInfo: CourseListModel						= null;		// ネット画面情報
	@WprModel('ContractInfo')
	private m_ContractInfo: ContractInfo					= null;		// 契約情報
	@WprListData('BeforeInfoList')
	private m_BeforeInfoList: BaseInfoListModel[]			= null;		// 変更前契約リスト
	// --------------------------------------------------------------------------
	
	// メンバ変数 ---------------------------------------------------------------
	private m_NetServiceNewModel: NetServiceNewWizardModel  = null;		// ネット追加モデル
	private m_BaseInfoList: BaseInfoListModel[]				= null;
	private m_KattobiList :MCommon[];
	private m_NetFlowModel: NetFlowModel					= null;	// ネット遷移情報
	// --------------------------------------------------------------------------

	// サブビュー/モード  --------------------------------------------------------
	@WprViewMode
	private m_CourseListMode = new CourseListMode();				// コースリストモード
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** ウィザード定義 */
	public get mngr(): MypageWizardMngr 					{ return this.m_MypageWizardMngr; }
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * コントロール定義設定
	 */
	public onRegisterConfig(): void {
		this.addListConfig('check','選択','OtherCourseListModel');
		this.addListConfig('serviceCd', '変更前サービスコード', 'OtherCourseListModel');
		this.addListConfig('cDispnamep', 'コース名', 'OtherCourseListModel');
		this.addListConfig('nDispnamep', '回線種別', 'OtherCourseListModel');
		this.addListConfig('payAmount', '料金', 'OtherCourseListModel', { converter: '3桁カンマ' });
		this.addListConfig('payUnit', '料金単位', 'OtherCourseListModel');
		this.addConfig('macAddress', 'MACアドレス', 'NetInfo');
		this.addConfig('device', '端末', 'NetInfo');
		this.addConfig('accessAccount', 'アクセスアカウント', 'NetInfo');
		this.addConfig('contractCourse', '契約コース名', 'ContractInfo');
		this.addConfig('contractAmount', '月額', 'ContractInfo', { converter: '3桁カンマ' });
		this.addConfig('contractStatus', '契約ステータス', 'ContractInfo');
		this.addListConfig('itemName', '項目名', 'BeforeInfoList');
		this.addListConfig('itemData', '項目値', 'BeforeInfoList');
	}
	
	/**
	 *9 アクション登録
	 */
	public onRegisterAction(): void {
		this.addAction('next', this.onNext);
		this.addAction('back', this.onBack);
	}
	// --------------------------------------------------------------------------
	
	// --------------------------------------------------------------------------
	/**
	 * ビュー表示通知
	 */
	public onShowView(): void {
		this.m_MypageWizardMngr.initView(this, (result) => {
			this.registAccessLog();
			this.setStorageItem('b_url', window.location.href)
			const sendData: ProgressBarData = new ProgressBarData();
			sendData.progressBarList = this.m_MypageWizardMngr.getProgressModelList(this);
			this.sendChildData('fP_ev_progressBarIntital', sendData);
			// 画面情報
			this.m_NetServiceNewModel =  new NetServiceNewWizardModel();
			this.m_NetServiceNewModel.setCourseDisplay(this);
			this.m_NetInfo = this.m_MypageWizardMngr.getCache().m_NetInfo;
			this.m_ContractInfo = this.m_MypageWizardMngr.getCache().m_ContractInfo;
			this.m_ContractInfo.dupFlg = false;
			const beforeInfo = this.m_MypageWizardMngr.getCache().m_BaseInfoList;
			this.m_BeforeInfoList = new Array();
			let before = JSON.stringify(beforeInfo);
			this.m_BeforeInfoList = JSON.parse(before);
			for (const info of this.m_BeforeInfoList) {
				if (info.itemName === '機器の種類')
					info.itemName = '端末の種類'
			}
			this.setListData('BeforeInfoList', this.m_BeforeInfoList);
			this.setOtherCourseList();
			this.setOtoku();
			// 遷移情報
			this.m_NetFlowModel = this.m_MypageWizardMngr.getCache().m_NetFlowModel;
			// かっとび光取得
			this.m_KattobiList = new Array();
			const commonList = this.m_MypageWizardMngr.getMCommonList(this, 'namecd', 'course_net', null, null);
			const kattobiKeyList: string[] = ['fG', 'fHS', 'fT', 'mG', 'mHS', 'mT'];
			for (const key of kattobiKeyList) {
				const kattobi = commonList.filter(data => data.category3 === key);
				if (kattobi && kattobi.length > 0)
					this.m_KattobiList.push(kattobi[0]);
			}
			this.refreshView();
		});
		
	}
	// --------------------------------------------------------------------------
	// アクション  ---------------------------------------------------------------
	/**
	 * 申込む
	 */
	public onNext(param: any): void {
		this.setOtherInfo();
		if (this.m_ContractInfo.dupFlg) {
			this.m_MypageWizardMngr.getCache().m_OtherCourseListModel = new Array();
			this.m_MypageWizardMngr.goBack(this);
		}
		else {
			for (const kattobi of this.m_KattobiList) {
				if (this.m_NetInfo.aftServiceCd === kattobi.commonValue) {
					this.m_NetFlowModel.kattobiFlg = true;
					this.m_NetFlowModel.otherFlg = false;
					break;
				}
				else {
					this.m_NetFlowModel.kattobiFlg = false;
					this.m_NetFlowModel.otherFlg = true;
				}
			}
			this.refreshModel();
			this.m_MypageWizardMngr.goNext(this);
		}
	}

	/**
	 * 戻る
	 */
	public onBack(param: any): void {
		this.m_MypageWizardMngr.getCache().m_OtherCourseListModel = new Array();
		this.m_MypageWizardMngr.goBack(this);
	}

	/**
	 * リスト値変更通知
	 * @param name 名前
	 * @param listName リスト名
	 * @param row 行データ
	 * @param value 値
	 * @returns falseの場合、変更を取り消す
	 */
	public onChangeListValue(name: string, listName: string, row: any, value: any): boolean {
		if (name === 'check') {
			for (const list of this.m_OtherCourseListModel)
				list.check = false;
			row.check = true;
			this.refreshListView();
		}
		return true;
	}

	// privateメソッド  ----------------------------------------------------------
	/**
	 * その他コースリスト作成
	 */
	public setOtherCourseList() {
		this.m_OtherCourseListModel = this.m_MypageWizardMngr.getCache().m_OtherCourseListModel;
		if (ObjectUtil.isNullOrUndefined(this.m_OtherCourseListModel) || this.m_OtherCourseListModel.length === 0) {
			const courseServiceList = this.m_MypageWizardMngr.getServiceList(this, MypageDispdivcdConstants.ITSCOMNET_OTHERCOURSE_NAME);
			const lineTypeList = this.m_MypageWizardMngr.getMDisplayList(this, MypageDispdivcdConstants.ITSCOMNET_LINETYPE, '');
			for (const courseService of courseServiceList) {
				const model = new CourseListModel();
				model.cDispnamep = courseService.display.dispnamep;
				model.serviceCd = courseService.service.serviceCD;
				const amount = courseService.service.payment[0].amount;
				if (ObjectUtil.isNullOrUndefined(amount) || amount === 0)
					model.payAmount = '-';
				else {
					model.payAmount = this.m_MypageWizardMngr.getTaxFee(this, String(courseService.service.payment[0].amount));
					model.payUnit = '円';
				}
				const lineType = lineTypeList.filter(data => data.namecd === courseService.display.genecd);
				if (ObjectUtil.isNotNullOrUndefined(lineType))
					model.nDispnamep = lineType[0].dispnamep;
				this.m_OtherCourseListModel.push(model);
			}
		}
		if (this.m_OtherCourseListModel && this.m_OtherCourseListModel.length > 0) {
			// デフォルトチェック
			const checkCourse = this.m_OtherCourseListModel.filter(data => data.check === true);
			if (ObjectUtil.isNullOrUndefined(checkCourse) || checkCourse.length === 0)
				this.m_OtherCourseListModel[0].check = true;
		}
		this.setListData('OtherCourseListModel',this.m_OtherCourseListModel);
	}

	/**
	 * お得パック判定※後ほどWizar側に移動検討
	 */
	private setOtoku() {
		const planList = this.m_MypageWizardMngr.getViewInfo().contractModel.planList;
		// お得パック加入者
		const otokuPlanName = this.m_MypageWizardMngr.getOtokuPlanName(this, planList);
		if (ObjectUtil.isNotNullOrUndefined(otokuPlanName)) {
			this.m_NetInfo.otokuFlg = true;
			this.m_NetInfo.otokuPlan = '※【' + otokuPlanName.dispnamep + '】割引適用)';	// 名称
		}
		else 
			this.m_NetInfo.otokuFlg = false;
	}

	/**
	 * その他コース情報※後ほどWizar側に移動検討
	 */
	private setOtherInfo() {
		// 変更前申込み内容
		const deviceInfo = this.m_BeforeInfoList.filter(data => data.itemName === '端末の種類');
		if (deviceInfo && deviceInfo.length > 0) {
			this.m_NetInfo.deviceType = deviceInfo[0].terminalType;
			this.m_NetInfo.deviceForm = deviceInfo[0].terminalForm;
		}
		// 変更後申込み内容
		const otherList = this.m_OtherCourseListModel.filter(data => data.check === true);
		if (otherList && otherList.length > 0) {
			this.m_NetInfo.cDispnamep = otherList[0].cDispnamep;
			this.m_NetInfo.nDispnamep = otherList[0].nDispnamep;
			this.m_NetInfo.payAmount = otherList[0].payAmount;
			this.m_NetInfo.aftServiceCd = otherList[0].serviceCd;
		}
		// 申込み判定
		if (this.m_ContractInfo.serviceCd === this.m_NetInfo.aftServiceCd)
			this.m_ContractInfo.dupFlg = true;
	}
}
