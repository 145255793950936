import { WprModel } from '../../../../wpr-framework/view/deco/WprModel';
import { WprDI_ScopeModel } from '../../../../wpr-framework/view/di/WprDI_ScopeModel';
import { ConstrBaseModel } from '../../../models/mypage/Construction/ConstrBaseModel';
import { MypageBaseView } from '../../base/MypageBaseView';
import { ProgressBarData } from '../../common/progress_bar/ProgressBarView';
import { MypageWizardMngr } from '../../wizard/models/MypageWizardMngr';
import { ConstructionService } from '../../../service/ConstructionService';
import { SetMypWorkScheduleRequest } from '../../../service/models/dto/mypagerenewal/SetMypWorkScheduleRequest';
import { ApplicationInfo } from '../../../models/iss/setMypWorkSchedule/ApplicationInfo';
import { ConstrFlowModel } from '../../../models/mypage/Construction/ConstrFlowModel';

/**
 * 工事日程確認画面
 */
export class ConstrConfirmView extends MypageBaseView {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() { super('ConstrConfirmView'); }
	// --------------------------------------------------------------------------

	// モデル  ------------------------------------------------------------------
	@WprModel('ConstrBaseModel')
	private m_ConstrBaseModel: ConstrBaseModel	= null;	// 工事日基本情報モデル
	@WprDI_ScopeModel('MypageWizardMngr')
	private m_MypageWizardMngr: MypageWizardMngr			= null;		// 画面ウィザード管理クラス
	// --------------------------------------------------------------------------

	// private変数 --------------------------------------------------------------
	private m_FlowModel: ConstrFlowModel 	= null;	// 工事画面遷移モデル
	// --------------------------------------------------------------------------

	// サービス  ----------------------------------------------------------------
	private m_ConstructionService: ConstructionService	= new ConstructionService();	// 工事日サービス
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * コントロール定義設定
	 */
	public onRegisterConfig(): void {
		this.addConfig('date', '希望日', 'ConstrBaseModel.workPossibleList.workPossibleList', { bindName: 'possible_date', converter: '日付（yyyy/MM/dd）' });
		this.addConfig('dayOfWeek', '曜日', 'ConstrBaseModel.workPossibleList', { bindName: 'dayOfWeek' });
		this.addConfig('startTime', '開始時間', 'ConstrBaseModel.workPossibleList.workPossibleList', { bindName: 'possible_start_time' });
		this.addConfig('endTime', '終了時間', 'ConstrBaseModel.workPossibleList.workPossibleList', { bindName: 'possible_end_time' });
		this.addConfig('time', '時間', 'ConstrBaseModel.workPossibleList', { bindName: 'preferredTime' });
		this.addConfig('beforeContact', '前日連絡先', 'ConstrBaseModel');
		this.addConfig('sameDayContact', '当日連絡先', 'ConstrBaseModel');
		this.addConfig('name', '立ち合い者名', 'ConstrBaseModel');
	}

	/**
	 * アクション登録
	 */
	public onRegisterAction(): void {
		this.addAction('next', this.onNext);
		this.addAction('back', this.onBack);
	}
	
	/**
	 * ビュー表示通知
	 */
	public onShowView(): void {
		this.m_MypageWizardMngr.initView(this, (result) => {
			this.registAccessLog();
			this.setStorageItem('b_url', window.location.href);
			const sendData: ProgressBarData = new ProgressBarData();
			sendData.progressBarList = this.m_MypageWizardMngr.getProgressModelList(this);
			this.sendChildData('fP_ev_progressBarIntital', sendData);
			this.m_ConstrBaseModel = this.m_MypageWizardMngr.getCache().m_ConstrBaseModel;
			this.m_FlowModel = this.m_MypageWizardMngr.getCache().m_FlowModel;
			this.refreshView();
		});
	}
	// --------------------------------------------------------------------------

	// アクション  ---------------------------------------------------------------
	/**
	 * この予定日で申し込む        <NavigateNextIcon />
	 */
	public onNext(param: any): void {
		const req: SetMypWorkScheduleRequest = new SetMypWorkScheduleRequest();
		req.application_info = new Array();
		const model = new ApplicationInfo();
		const possibleData = this.m_ConstrBaseModel.workPossibleList.workPossibleList;
		model.customer_id = this.getCustomerId();
		model.property_id = this.m_MypageWizardMngr.getViewInfo().customer.building[0].buildingID;
		model.system_id = '01';
		model.work_partner_company_id = possibleData.work_partner_company_id;
		model.work_team_id = possibleData.work_group_id;
		model.schedule_date = possibleData.possible_date;
		model.start_time = possibleData.possible_start_time;
		model.end_time = possibleData.possible_end_time;
		model.work_schedule_id = this.m_ConstrBaseModel.workScheduleList.workScheduleList.work_schedule_id;
		model.adjust_group_id = possibleData.adjust_group_id;
		model.work_area_cd = this.m_ConstrBaseModel.workScheduleList.workScheduleList.work_area_cd;
		model.work_detail_info = this.m_ConstrBaseModel.workScheduleList.workScheduleList.work_detail_info;
		model.preferred_time_cd = possibleData.preferred_time_cd;
		req.application_info.push(model);
		this.m_ConstructionService.setMypWorkSchedule(this, req, (result) => {
			this.m_MypageWizardMngr.goNext(this);
		});
	}

	/**
	 * <NavigateBeforeIcon />        戻る
	 */
	public onBack(param: any): void {
		this.m_MypageWizardMngr.goBack(this);
	}
	// --------------------------------------------------------------------------
}
