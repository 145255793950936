import { WprControlInfo } from '../../../../wpr-framework/view/control/WprControlInfo';
import { WprListData } from '../../../../wpr-framework/view/deco/WprListData';
import { WprModel } from '../../../../wpr-framework/view/deco/WprModel';
import { WprDI_Control } from '../../../../wpr-framework/view/di/WprDI_Control';
import { WprDI_ScopeModel } from '../../../../wpr-framework/view/di/WprDI_ScopeModel';
import { WprValidError } from '../../../../wpr-framework/view/validation/WprValidError';
import MypageErrorConstants from '../../../common/MypageErrorConstants';
import { ErrorModel } from '../../../models/mypage/smart/ErrorModel';
import { ContractPlanModel } from '../../../service/models/apimodels/ContractPlanModel';
import { NameChangeAppModel } from '../../../service/models/apimodels/NameChangeAppModel';
import { GetNameChangeRequest } from '../../../service/models/dto/mypagerenewal/GetNameChangeRequest';
import { NameChangeService } from '../../../service/NameChangeService';
import { MypageBaseView } from '../../base/MypageBaseView';
import { ProgressBarData } from '../../common/progress_bar/ProgressBarView';
import { MypageWizardMngr } from '../../wizard/models/MypageWizardMngr';

/**
 * 名義変更受付の案内
 */
export class NameChangeInformationView extends MypageBaseView {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() { super('NameChangeInformationView'); }
	// --------------------------------------------------------------------------

	// DI情報  ------------------------------------------------------------------
	@WprDI_Control
	private m_NameChangeKbn: WprControlInfo	= null;	// 名義変更区分
	// --------------------------------------------------------------------------

	// モデル  ------------------------------------------------------------------
	@WprDI_ScopeModel('MypageWizardMngr')
	private m_MypageWizardMngr: MypageWizardMngr = null;	// 画面ウィザード管理クラス
	@WprModel('NameChangeAppModel')
	private m_EntryInfo: NameChangeAppModel = null;	// 名義変更申込み情報モデル
	@WprListData('ErrorModel')
	private m_ErrorModelList: ErrorModel[]	= null;			// エラーモデル
	// --------------------------------------------------------------------------
	private m_NameChangeService: NameChangeService	= new NameChangeService();	// 名義変更サービス


	// override メソッド  --------------------------------------------------------
	/**
	 * コントロール定義設定
	 */
	public onRegisterConfig(): void {
		this.addConfig('nameChangeKbn', '名義変更区分', 'NameChangeAppModel.nameChangeInfo');
		this.addListConfig('errorMessage', 'エラーメッセージ', 'ErrorModel');
	}

	/**
	 * ビュー表示通知
	 */
	public onShowView(): void {
		this.m_MypageWizardMngr.initView(this, (result) => {
			this.registAccessLog();
			this.setStorageItem('b_url', window.location.href)
			const sendData: ProgressBarData = new ProgressBarData();
			sendData.progressBarList = this.m_MypageWizardMngr.getProgressModelList(this);
			this.sendChildData('fP_ev_progressBarIntital', sendData);
			this.m_EntryInfo = this.m_MypageWizardMngr.getCache().nameChangeAppInfo;

			// 受付済み判定処理--------------------------
			const req: GetNameChangeRequest = new GetNameChangeRequest();
			req.customerId = this.m_EntryInfo.customerId;
			this.m_NameChangeService.getNameChange(this, req, (result) => {
				if(result.changeStatus !== '0') {
					// 受付済みのためエラー遷移
					this.pushPath('/error?errorCd=' + MypageErrorConstants.NAMECHANGE_ERROR);
				}
			});
		});
		this.m_ErrorModelList = new Array();
		const model = new ErrorModel();
		model.errorMessage = 'エラーリスト';
		this.m_ErrorModelList.push(model);
		this.setListData('ErrorModel', this.m_ErrorModelList);
		this.refreshView();
	}
	
	/**
	 * アクション登録
	 */
	public onRegisterAction(): void {
		this.addAction('document', this.onDocument);
		this.addAction('inquiryOther', this.onInquiryOther);
		this.addAction('next', this.onNext, true);
		this.addAction('back', this.onBack);
	}
	/**
	 * 入力チェック（独自のValidationを実施する場合に使用する）
	 *	エラー追加例：validError.addError('エラーメッセージ');				// 相関チェックなどのエラー
	 * 				 this.setControlError('control', 'error');			 // コントロールのエラー
	 * 				 validError.showErrorMessage.('エラーメッセージ');	   // エラーメッセージ表示
	 * 		※上記のエラーを追加した場合は、アクションは実行しません。
	 * 		※コントロールの値をチェックする場合は、refreshModelは使用しないでください。
	 * 		　コントロールの値をチェックする場合は、コントロールをDIしてvalueを使用してください。
	 * 		　コントロールが双方向のコンバータを使用している場合は、dataValueを使用してください。
	 * @param actionName アクション名
	 * @param validError バリデーションエラー情報
	 * @param row 行情報
	 */
	protected onValid(actionName: string, validError: WprValidError, row?: any): void {
		if (actionName === 'next') {
			this.m_ErrorModelList = new Array();
			if (!this.m_NameChangeKbn.value) {
				const model = new ErrorModel();
				model.errorMessage = '名義変更区分を選択してください';
				this.m_ErrorModelList.push(model);
			}
			if (this.m_ErrorModelList.length !== 0) {
				this.setViewMode('オプション','エラー')
				this.setListData('ErrorModel', this.m_ErrorModelList);
				validError.addError('エラー');		
			}
			this.refreshListView('ErrorModel');
		}
	}
	// --------------------------------------------------------------------------

	// アクション  ---------------------------------------------------------------
	/**
	 * 本人確認書類確認画面へ
	 */
	// ★リンク先保留
	public onDocument(param: any): void {
		window.open('https://www.itscom.co.jp/support/contract_change/contract-name/');
	}

	/**
	 * その他のお問い合わせ
	 */
	 public onInquiryOther(param: any): void {
		location.href = this.getOldUrl() + "Inquiry/Inquiry_othe_entry/";
	}

	/**
	 * 次へ
	 */
	public onNext(param: any): void {
		this.refreshModel();
		if(this.m_EntryInfo.nameChangeInfo.nameChangeKbn === '1')
			this.m_EntryInfo.nameChangeInfo.relationShip = '本人';
		else
			this.m_EntryInfo.nameChangeInfo.relationShip = null;
		this.m_MypageWizardMngr.goNext(this);
	}

	/**
	 * 戻る
	 */
	public onBack(param: any): void {
		this.m_MypageWizardMngr.goBack(this);
	}
	// --------------------------------------------------------------------------
}
