import { Application } from '../../apimodels/fuse/soap/Application';
import { CertificationInfo } from '../../apimodels/CertificationInfo';

/**
 * 書面一覧取得リクエスト
 */
export class GetCertificationRequest {
	// public 変数	-----------------------------------------------------------
	public applicationList: Application[];			// 書面リスト
	public electronicConfirmFlg: boolean;			// 電子確認有無
	public certificationInfo: CertificationInfo;	// 認証情報
	// ------------------------------------------------------------------------
}
