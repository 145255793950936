import { WprValueMap } from '../../../../wpr-framework/model/WprValueMap';
import { WprValueMapSetter } from '../../../../wpr-framework/model/WprValueMapSetter';
import { WprModel } from '../../../../wpr-framework/view/deco/WprModel';
import { WprDI_ScopeModel } from '../../../../wpr-framework/view/di/WprDI_ScopeModel';
import { MypageWizardMngr } from '../../wizard/models/MypageWizardMngr';

/**
 * 時間帯
 */
export class MeshWifiTimeValueMap {

	// static メソッド  --------------------------------------------------------
	public static addValueMap(vmSetter: WprValueMapSetter): void {
		let vm = new WprValueMap();
		vm.addValue('2', '09:00 - 11:00');
		vm.addValue('3', '11:00 - 14:00');
        vm.addValue('4', '13:00 - 17:00');
		vmSetter.addValueMap('時間帯', vm);
	}
	// --------------------------------------------------------------------------
}