// import { stringNumberComparer } from '@material-ui/data-grid';
import { IMatTextFieldProps, MatBaseTextField } from './MatBaseTextField';

/**
 * テキストフィールドコントロール
 */
export class MatTextField extends MatBaseTextField<IMatTextFieldProps> {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: IMatTextFieldProps) {
		super(props);
	}
	// --------------------------------------------------------------------------
}