import React, { ReactNode } from 'react';
import { WprBaseViewComponent } from '../../../../wpr-framework/view/component/WprBaseViewComponent';
import { Typography, Grid, Divider } from '@material-ui/core';
import { MatButton } from '../../../../wpr-framework/component/controls/material/MatButton';
import { MatCheckBox } from '../../../../wpr-framework/component/controls/material/MatCheckBox';
import { MatTypography } from '../../../../wpr-framework/component/controls/material/MatTypography';
import { ProgressBar } from '../../common/progress_bar/ProgressBar';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { AddMeshWifiSelectView } from './AddMeshWifiSelectView';
import styles from './AddMeshWifiSelect.scss';
import { WprInput } from '../../../../wpr-framework/component/controls/WprInput';
import { MatSelect } from '../../../../wpr-framework/component/controls/material/MatSelect';
import { WprContainer } from '../../../../wpr-framework/component/controls/WprContainer';
import { MatDatePicker } from '../../../../wpr-framework/component/controls/material/MatDatePicker';
import { WprViewError } from '../../../../wpr-framework/component/controls/WprViewError';
import nextIcon from '/shared/image/common/next_icon_orange.png';
import { WprErrorContainer } from '../../../../wpr-framework/component/controls/WprErrorContainer';
import { WprRowInfo } from '../../../../wpr-framework/component/props/WprRowInfo';
import errorIcon from '/shared/image/common/icon_error.png';
import { MatTextField } from '../../../../wpr-framework/component/controls/material/MatTextField';

/**
 * メッシュwifi 追加申込み用コンポーネント
 */
export class AddMeshWifiSelect extends WprBaseViewComponent {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: any) { super(new AddMeshWifiSelectView(), props); }
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** メッシュwifiビュー情報 */
	public get viewInfo(): AddMeshWifiSelectView	{ return this.view as AddMeshWifiSelectView;	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * スタイルオブジェクト取得
	*/
	public getStylesObject(): any { return styles; }
	/**
	 * リストコントロール定義登録
	*/
	protected onRegisterListRender(): void {
		this.addListRender('ErrorModel', null, this.onErrorModelRender);
	}
	
	

	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		return (
			<>
				<div className='pageCard'>
					<div className='pageHeader stepFlow'>
						<ProgressBar name='progressBar' view={this.view} />
					</div>
					<div className='main'>
						<div className='pageTitle confirmButton'>
							<div className='title_left'>
								<MatTypography name='title' className='title_text' view={this.view} />
							</div>
							<div>
								<WprContainer mode='エラー.表示' view={this.view}>
									<div className='errorArea'>
										<span className='parallel'>
											<img src={errorIcon} alt='' className='x_img'/>
											<div className='errorList'>
												{this.renderList('ErrorModel')}
											</div>
										</span>
									</div>
								</WprContainer>
							</div>
							<p style={{textAlign: 'right'}}>
								<span className = 'txtRequired'>*</span>
								は必須入力です
							</p>
							<div className='feeTable'>
								<Grid container className='feeRow'>
									<Grid item xs={12} md={4} className='feeTitle'>
										<div>申込みコース</div>
									</Grid>
									<Grid item xs={12} md={8} className='currentFeeArea courseValue'>
										<Grid item xs={12} md={8} className='feeVal courseValue'>
											<MatCheckBox name='courseCheck' style={{height:'8px',width:'8px',padding:'0px'}} className='checkbox_size' view={this.view} /><span style={{fontWeight:'500'}} className = 'font_black'>　メッシュWi-Fi</span>
										</Grid>
									</Grid>
								</Grid>	
								<Grid container className='feeRow tableBorder'>
									<Grid item xs={12} md={4} className='feeTitle'>
										<div>機器のご提供方法</div>
									</Grid>
									<Grid item xs={12} md={8} className='feeVal courseValue'>
										<MatCheckBox name='equipmentCheck' style={{height:'8px',width:'8px',padding:'0px'}} className='checkbox_size' view={this.view} /><span style={{fontWeight:'500'}} className = 'font_black'>　レンタル</span>
									</Grid>
								</Grid>	
								<Grid container className='feeRow'>
									<WprContainer mode='お客様ご自身での設置.非表示' view={this.view}>
										<Grid item xs={12} md={4} className='feeTitle'>
											<div>設置方法</div>
										</Grid>
										<Grid item xs={12} md={8} className='feeVal courseValue'>
											<MatCheckBox name='check2' style={{height:'8px',width:'8px',padding:'0px'}} className='checkbox_size' view={this.view} /><span style={{fontWeight:'500'}} className = 'font_black'>　訪問での設置を希望する</span>
											<ul className='ul_kome' style={{marginBottom:'0'}}>
												<li className = 'notes'>「Wi-Fi（有線モデムセット用）」を１台ご利用中のお客様につきましては、「快適メッシュWi-Fi」をご利用いただく際に、設置済Wi-Fiの撤去が必要になります。</li>
											</ul>
										</Grid>
									</WprContainer>
									<WprContainer mode='お客様ご自身での設置.表示' view={this.view}>
										<Grid item xs={12} md={4} className='feeTitle'>
											<div>設置方法</div>
										</Grid>
										<Grid item xs={12} md={8} className='feeVal courseValue'>
											<MatCheckBox name='check1' style={{height:'8px',width:'8px',padding:'0px'}} className='checkbox_size' view={this.view} /><span style={{fontWeight:'500'}} className = 'font_black'>　お客様ご自身での設置を希望する（推奨）</span>
											<br/>
											<MatCheckBox name='check2' style={{height:'8px',width:'8px',padding:'0px'}} className='checkbox_size' view={this.view} /><span style={{fontWeight:'500'}} className = 'font_black'>　訪問での設置を希望する</span>
										</Grid>
									</WprContainer>
								</Grid>	
							</div>
							<WprContainer mode={'とことんサポート.表示'} view={this.view}>
								<div className='title_space tokosupoMessage'>
									<Typography className='title_comment' style={{marginBottom:'4px'}}>メッシュWi-Fiのお申込みにはとことんサポートの加入が必須となります。</Typography>
								</div>
								{/* <br/> */}
								<div style={{textAlign:'right'}}>
									<a href="https://www.itscom.co.jp/support/assistance/tokoton/" target="_brank" style={{color:'black' ,textDecoration:'none'}}>
										<b>とことんサポートの詳細はこちら　</b>
										<img src={nextIcon} style={{ width: '20px', verticalAlign: 'bottom' }} />
									</a>
								</div>
								<div className='feeTable'>
									<Grid container className='feeRow'>
										<Grid item xs={12} md={4} className='feeTitle'>
											<div>申込みコース</div>
										</Grid>
										<Grid item xs={12} md={8} className='currentFeeArea courseValue'>
											<Grid item xs={12} md={8} className='feeVal courseValue'>
												<MatCheckBox name='tokosupCheck' style={{height:'8px',width:'8px',padding:'0px'}} className='checkbox_size' view={this.view} /><span style={{fontWeight:'500'}} className = 'font_black'>　とことんサポート</span>
											</Grid>
										</Grid>
									</Grid>
								</div>
								<div className='supportMsgArea'>
									<ul className='aster messageText'>
									<span style = {{color:'#6169B8', fontWeight:'700'}}>
										事前確認項目
									</span>
										<li className='comment' style={{marginLeft: '5px'}}>ご利用開始日の属する月の翌月より6カ月以内に「イッツコムとことんサポート」を解約された場合、解約料金として
											3,300円（税込）がかかります。また、解約後6カ月間は「イッツコムとことんサポート」への再加入はできません。
											<br/>
										</li>
									</ul>
								</div>
								<div style={{textAlign:'center', marginBottom:'30px'}}>
									<MatCheckBox name='tokosupConfirmCheck'  className='checkbox_size' label='確認しました' view={this.view}/>
									<span className = 'txtRequired'>*</span>
								</div>
							</WprContainer>
						</div>
						<div>
							<div>[説明事項]</div>	
							<WprContainer mode='お客様ご自身での設置.表示' view={this.view}>
								<div>■お客様ご自身での設置を希望する</div>
								<div>イッツコムより機器と説明書をご自宅宛てに配送しお客様にて設置・設定いただきます。</div>
								<div>4～7日以内に配送いたします。</div>
								<br/>
								<div>機器発送費は以下の通りとなります。</div>
								<span className='costMessage'>NETサービス新規加入（設置）から7カ月目以降：</span><span>無料</span><br />
								<span className='costMessage'>NETサービス新規加入（設置）から6カ月以内：</span><span>2,750円</span>
								<div>3台目以降追加の場合：2,750円</div>
								<br/>
								<div>※イッツコムインターネットサービスをサービス開始翌月から6カ月以上ご利用の上、快適メッシュWi-Fiお申し込み時に、イッツコムインターネットサービス160メガ以上のコースへご変更、またはすでにご利用中の場合、2台1セットに限り機器発送費が無料となります。</div>
								<div>年末年始や大型連休期間は機器の発送日が遅れる場合がございます。</div>
								<br/>
							</WprContainer>
							<div>■訪問での設置を希望する</div>
							<div>工事費は以下の通りとなります。</div>
							<span className='costMessage'>NETサービス新規加入（設置）から7カ月目以降：</span><span>無料</span><br />
							<span className='costMessage'>NETサービス新規加入（設置）から6カ月以内：</span><span>7,700円</span>
							<div>3台目以降追加の場合：7,700円</div>
							<br/>
							<div>イッツコムスタッフがご自宅に訪問し機器の設置・設定を行います。</div>
							<div>設置日および設置費について後日ご連絡いたします。</div>
							<div>[注意事項]</div>
							<div>■快適メッシュWi-Fiは、インターネットモデムとは別に、親機1台、子機1台（合計2台）が基本セットとなります。</div>
							<div>■快適メッシュWi-Fiの設置設定には、お客様のスマートフォン・タブレット等、メールアドレスが必要となります。</div>
							<div>■メッシュWi-Fiのお申し込みはイッツコムのサービスエリアにお住まいの方に限られます。</div>
							<div>ご案内事項がある場合は、担当よりご連絡差し上げます。</div>
							<div>イッツコムのサービスエリアにつきましては、<a href='https://www.itscom.co.jp/info/areamap/' target = '_blank'>サービスエリアマップ</a>をご覧ください。</div>
						</div>
						{/* 無いと切り替えられない */}
						<WprContainer mode='設置方法.自身' view={this.view}></WprContainer>
						<WprContainer mode='設置方法.訪問' view={this.view}>
							<div className='customerItem contentsArea'>
							<div className='title_space title_maru' style={{marginBottom: '10px'}}>作業の希望日時</div>
								<div className='dateArea'>
									<Grid container spacing={1}>
										<Grid item xs={12} md={6} className='itemTitle2'>
											第一希望<span className = 'txtRequired'>*</span>
											<div className='customerInput'>
												<MatDatePicker name='desiredDate1' view={this.view} placeholder='年/月/日' use='mobile' />
											</div>
										</Grid>
										<Grid item xs={12} md={6} className='itemTitle2'>
											時間帯<span className = 'txtRequired'>*</span>
											<div className='customerInput'>
												<MatSelect name='selectTime1' vm='時間帯' view={this.view} />
											</div>
										</Grid>
										<Grid item xs={12} md={6} className='itemTitle2'>
											第二希望<span className = 'txtRequired'>*</span>
											<div className='customerInput'>
												<MatDatePicker name='desiredDate2' view={this.view} placeholder='年/月/日' use='mobile' />
											</div>
										</Grid>
										<Grid item xs={12} md={6} className='itemTitle2'>
											時間帯<span className = 'txtRequired'>*</span>
											<div className='customerInput'>
												<MatSelect name='selectTime2' vm='時間帯' view={this.view} />
											</div>
										</Grid>
										<Grid item xs={12} md={6} className='itemTitle2'>
											第三希望<span className = 'txtRequired'>*</span>
											<div className='customerInput'>
												<MatDatePicker name='desiredDate3' view={this.view} placeholder='年/月/日' use='mobile' />
											</div>
										</Grid>
										<Grid item xs={12} md={6} className='itemTitle2'>
											時間帯<span className = 'txtRequired'>*</span>
											<div className='customerInput'>
												<MatSelect name='selectTime3' vm='時間帯' view={this.view} />
											</div>
										</Grid>
									</Grid>
								</div>
								<ul className='ul_kome'>
									<li>訪問担当が到着するおおよその時間帯目安となります。</li>
									<li>ご訪問日時については近日中にお電話にてご連絡いたします。<br/>訪問担当の空き状況によってご希望に沿えない場合もございますことご了承ください。<br/>お建物の状況等によっては工事前に別途調査が必要となる場合がございます。</li>
								</ul>
							</div>
							<div className='feeTable inputArea' style={{marginTop: '30px'}}>	
									<Grid container className='feeRow'>
										<Grid item xs={12} md={4} className='feeTitle'>
											<div>前日連絡先<span className='txtRequired'>*</span></div>
										</Grid>
										<Grid item xs={12} md={8} className='feeVal courseValue' style={{padding: '15px'}}>
											<MatTextField name='beforeContact' className='itemUnit' placeholder='電話番号ハイフンなし' view={this.view} style={{color: '#484848'}}/>
										</Grid>
									</Grid>	
									<Grid container className='feeRow textField'>
										<Grid item xs={12} md={4} className='feeTitle'>
											<div>当日連絡先<span className='txtRequired'>*</span></div>
										</Grid>
										<Grid item xs={12} md={8} className='feeVal courseValue' style={{padding: '15px'}}>
											<MatTextField name='sameDayContact' className='itemUnit' placeholder='電話番号ハイフンなし' view={this.view} />
										</Grid>
									</Grid>
									<Grid container className='feeRow'>
										<Grid item xs={12} md={4} className='feeTitle'>
											<div>当日立ち合い者<span className='txtRequired'>*</span></div>
										</Grid>
										<Grid item xs={12} md={8} className='feeVal courseValue' style={{padding: '15px'}}>
											<MatTextField name='name' className='itemUnit' placeholder='当日立ち合い者' view={this.view} />
										</Grid>
									</Grid>
								</div>
						</WprContainer>
						<br />
						<span className='confirmText'>
							個人情報の取扱いへの同意
						</span>
						<span className = 'txtRequired' style={{fontSize: '20px'}}>*</span>
						<div className='supportMsgArea2'>	
							<div>ご入力いただきましたお客さまの個人情報は、このお問合せにご回答するために利用いたします。また、当社の「個人情報保護方針」および「個人情報の取り扱いについて」［http://www.itscom.co.jp/info/privacy/］に基づき適正に取り扱います。</div>
							<br/>
							<div>本登録システムは株式会社フューチャースピリッツのシステム「Form Mailer」を利用しており、送信されたデータは暗号化された通信（SSL）で保護されています。</div>
							
						</div>
						<div className='checkBox'>
							<MatCheckBox name='confirmCheck'  className='checkbox_size' label='同意する' view={this.view}/>
						</div>
					</div>
					<Divider />
					<div className='pageFooter footer-space'>
						<WprContainer mode='設置方法.訪問' view={this.view}>
							<div>
								<MatButton name='next' view={this.view} className='orangeButton orangeButton_parts'>
									確認
									<NavigateNextIcon />
								</MatButton>
							</div>
						</WprContainer>
						<WprContainer mode='設置方法.自身' view={this.view}>
							<div>
								<MatButton name='next1' view={this.view} className='orangeButton orangeButton_parts'>
									確認
									<NavigateNextIcon />
								</MatButton>
							</div>
						</WprContainer>
						
						<div>
							<MatButton name='back' view={this.view} className='backButton'>
								<NavigateBeforeIcon />
								戻る
							</MatButton>
						</div>
					</div>
				</div>
			</>
		);
	}
	
	// --------------------------------------------------------------------------

	// リスト描画  ---------------------------------------------------------------
	/**
	 * リスト名=ErrorModel
	 */
	public onErrorModelRender(rowInfo: WprRowInfo): any {
		return (
			<div>
				<MatTypography name='errorMessage' view={this.view} row={rowInfo}/>
			</div>
		);
	}
	// --------------------------------------------------------------------------
}
