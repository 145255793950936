import { WprBaseValidator } from '../../../../wpr-framework/view/validation/WprBaseValidator';

/**
 * 0-9のみ
 */
export class HankakuNumberValidator extends WprBaseValidator {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() {
		super('半角数字');
	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * 入力値チェック
	 * @param value 入力値
	 * @param option チェックオプション
	 * @returns trueの場合、成功
	 */
	public checkValidValue(value: any, option?: any): boolean {
		return false;
	}

	/**
	 * エラーメッセージ取得
	 * @param option オプション
	 * @returns エラーメッセージ
	 */
	public getErrorMessage(option?: any): string {
		return '半角数字を入力してください。';
	}
	// --------------------------------------------------------------------------
}
