import { WizardModel } from '../../models/WizardModel';
import { ItscomHomeSelect1View } from '../itscom_home/new/itscom_home_select1/ItscomHomeSelect1View';
import { ItscomHomeSelect2View } from '../itscom_home/new/itscom_home_select2/ItscomHomeSelect2View';
import { ItscomHomeComfirmView } from '../itscom_home/new/itscom_home_comfirm/ItscomHomeComfirmView';
import { MypageWizardFlow } from './MypageWizardFlow';
import { MypageBaseView } from '../base/MypageBaseView';
import { YakkanListModel } from '../../service/models/apimodels/YakkanListModel';
import { CommonCompleteView } from '../common/common_complete/CommonCompleteView';
import { CompleteModel } from '../common/common_complete/models/CompleteModel';
import { CommonAgreeView } from '../common/common_agree/CommonAgreeView';
import { GetViewInfoRequest } from '../../service/models/dto/mypagerenewal/GetViewInfoRequest';
import { MDisplay } from '../../service/models/entity/secondary/MDisplay';
import { MCommon } from '../../service/models/entity/primary/MCommon';
import { Contract as OwnerContract} from '../../models/bss/customer/Contract';
import { MypageWizardMngr } from './models/MypageWizardMngr';
import { MGeneral } from '../../service/models/entity/MGeneral';
import { ContractServiceModel } from '../../service/models/apimodels/ContractServiceModel';
import MypageDispdivcdConstants from '../../common/MypageDispdivcdConstants';
import { RegistInfoRequest } from '../../service/models/dto/mypagerenewal/RegistInfoRequest';
import { InquiryMailInfo } from '../../service/models/apimodels/InquiryMailInfo';
import { ObjectUtil } from '../../common/util/ObjectUtil';
import MypageCodeTypeConstants from '../../common/MypageCodeTypeConstants';
import { BaseInfo } from '../../models/mypage/smart/BaseInfo';
import { ItscomHomeCourseListModel } from '../../models/mypage/smart/ItscomHomeCourseListModel';
import { ItscomHomeOptionListModel } from '../../models/mypage/smart/ItscomHomeOptionListModel';
import { ItscomHomeOptComfirmListModel } from '../../models/mypage/smart/ItscomHomeOptComfirmListModel';
import { MAIL_CONSTANTS } from '../../common/MyPageMailIdConstants';
import MypageYakkanConstants from '../../common/MypageYakkanConstants';

/**
 * ITSCOM HOME_追加ウィザードクラス
 */
export class ItscomHomeNewWizardFlow extends MypageWizardFlow {
	// private 変数  ------------------------------------------------------------
	private m_DefaultWizardFlow: WizardModel[]			= null;		// 画面ウィザードリスト
	private m_StartViewName: string						= null;
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	public constructor() {
		super();
		this.m_StartViewName = new ItscomHomeSelect1View().name;

		// 遷移パターン１
		this.m_DefaultWizardFlow = new Array();
		this.m_DefaultWizardFlow.push({stepNum: 0, title: '契約選択', viewName: null, path: this.getOldUrl() + 'Srvlist/Continfo_list/'});
		this.m_DefaultWizardFlow.push({stepNum: 1, title: 'コース選択', viewName: this.m_StartViewName, path: 'itscom_home_select1'});
		this.m_DefaultWizardFlow.push({stepNum: 2, title: 'コース内容の選択', viewName: new ItscomHomeSelect2View().name, path: 'itscom_home_select2'});
		this.m_DefaultWizardFlow.push({stepNum: 3, title: '申込み内容の確認', viewName: new ItscomHomeComfirmView().name, path: 'itscom_home_confirm'});
		this.m_DefaultWizardFlow.push({stepNum: 4, title: '契約約款', viewName: new CommonAgreeView().name, path: 'itscom_home_agree'});
		this.m_DefaultWizardFlow.push({stepNum: 5, title: '申込み完了', viewName: new CommonCompleteView().name, path: 'itscom_home_complete'});
	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * 画面ウィザードフロー名取得
	 */
	public getWizardFlowName(viewName: string): string {
		if (viewName === this.m_StartViewName)
			return this.constructor.name;
		return null;
	}
	
	/**
	 * 画面ウィザードリスト取得：契約状態で切り替えたり、選択肢で切り替えたり
	 */
	public getWizardFlow(mngr: MypageWizardMngr): WizardModel[] {
		return this.m_DefaultWizardFlow;
	}
	
	/**
	 * 画面ウィザードAPI用モデル取得：契約状態で切り替えたり、選択肢で切り替えたり
	 */
	public getWizardApiModel(mngr: MypageWizardMngr, view: MypageBaseView): GetViewInfoRequest {
		const req = new GetViewInfoRequest();
		req.customerId = mngr.getParams().customerId;
		req.displayList = new Array();
		req.commonList = new Array();
		req.yakkanList = new Array();
		req.serviceKey = new Array();
		req.masterFlg = true;	// サービスマスタ
		req.ownerFlg = true;	// オーナー契約
		req.paymentFlg = true;	// 支払方法
		req.contractFlg = true; // 契約情報
		// コース情報
		req.displayList.push(new MDisplay(MypageDispdivcdConstants.ITSCOMHOME_ADD_SERVICE));
		const service1 = new ContractServiceModel();
		service1.serviceCd = MypageDispdivcdConstants.ITSCOMHOME_ADD_SERVICE;
		req.serviceKey.push(service1);
		// IHサービスコードリスト
		const common1 = new MCommon('オーナー契約IHリスト', 'IH', 'OWNER_SCD');
		req.commonList.push(common1);
		// オプション情報
		req.displayList.push(new MDisplay(MypageDispdivcdConstants.ITSCOMHOME_OPTION));
		const service2 = new ContractServiceModel();
		service2.serviceCd = MypageDispdivcdConstants.ITSCOMHOME_OPTION;
		req.serviceKey.push(service2);
		// 購入可能オプション
		const common2 = new MCommon('購入可能オプションリスト', 'srvsmart', 'buy_opt');
		req.commonList.push(common2);
		// コース情報(テレビプッシュ),コース毎の購入金額
		const common3 = new MCommon('コース情報(テレビプッシュ),コース毎の購入金額', 'srvsmart', 'srv_smart_kind');
		req.commonList.push(common3);
		// スマートライト数量単位
		const common4 = new MCommon('スマートライト数量単位', 'srvsmart', 'count_type_ko');
		req.commonList.push(common4);
		// お得パックリスト
		const common5 = new MCommon('お得パックリスト', 'otoku', 'plan_cd');
		req.commonList.push(common5);
		// お得パック名称
		req.displayList.push(new MDisplay(MypageDispdivcdConstants.ITSCOMHOME_OTOKUNAME));
		// 約款
		req.yakkanList.push(new MGeneral(MypageCodeTypeConstants.CONTRACT_AGREE, MypageYakkanConstants.ITSCOM_SERVICE_IMPORTANT));
		req.yakkanList.push(new MGeneral(MypageCodeTypeConstants.CONTRACT_AGREE, MypageYakkanConstants.ITSCOM_SERVICE));
		req.yakkanList.push(new MGeneral(MypageCodeTypeConstants.CONTRACT_AGREE, MypageYakkanConstants.ITSCOM_PRICE));
		const cacheData = mngr.getCache();
		if (cacheData && cacheData.m_BaseInfo.courseList) {
			// テレビ・プッシュ
			if (cacheData.m_BaseInfo.tvFlag)
				req.yakkanList.push(new MGeneral(MypageCodeTypeConstants.CONTRACT_AGREE, MypageYakkanConstants.TV_PUSH));
			// iTSCOM HOME
			else if (cacheData.m_BaseInfo.ihFlag)
				req.yakkanList.push(new MGeneral(MypageCodeTypeConstants.CONTRACT_AGREE, MypageYakkanConstants.IMPORTANT_ITSCOMHOME));
		}
		// -----------------------------------------------
		return req;
	}

	/**
	 * 画面ウィザードフローの中断判定：例：申し込みが完了していれば、完了済みの画面へ遷移
	 */
	public checkFlowBreak(mngr: MypageWizardMngr): string {
		let test: boolean = false;
		if (test) {
			return '/error';
		}
		return null;
	}

	/**
	 * 画面キャッシュの初期化データ取得
	 */
	public getInitCacheData(mngr: MypageWizardMngr): ItscomHomeNewWizardModel {
		let cacheData = new ItscomHomeNewWizardModel();
		cacheData.m_BaseInfo = new BaseInfo();					// 基本情報
		cacheData.m_CourseListModel = new Array(); 				// コースリスト
		cacheData.m_OwnerContractList = new Array();			// オーナー契約リスト
		cacheData.m_OptionListModel = new Array();				// オプションリスト
		cacheData.m_BuyOptionList = new Array();				// 購入可能オプションリスト(検索用)※不要？
		return cacheData;
	}

	/**
	 * 画面ウィザード完了APIモデル取得
	 */
	public getRegistApiModel(mngr: MypageWizardMngr, view: MypageBaseView): RegistInfoRequest{
		const req: RegistInfoRequest = new RegistInfoRequest();
		let cacheData: ItscomHomeNewWizardModel = mngr.getCache();
		// お客様メール
		req.customerId = mngr.getParams().customerId;
		const mailconstants = MAIL_CONSTANTS.find (mail => {
			return mail.view === 'IH_追加';
		});
		req.customerMailId = mailconstants.customerMail;
		// 業務部門メール,問い合わせ登録
		req.systemInfo = new InquiryMailInfo();
		req.systemInfo.appService = cacheData.m_BaseInfo.courseList.course;
		// オプション
		req.systemInfo.optionRentalList = new Array();
		const optionBuyList = cacheData.m_OptionListModel.filter(row => row.quantity2);
		if (ObjectUtil.isNotNullOrUndefined(optionBuyList) && optionBuyList.length > 0) {
			req.systemInfo.optionBuyName = optionBuyList[0].option + '  ' + optionBuyList[0].quantity2 + '個';
			const mailconstants = MAIL_CONSTANTS.find (mail => {
				return mail.view === 'IH_追加_購入';
			});
			req.systemMailId = mailconstants.systemMail;
			req.inquiryId = mailconstants.inquiryMail;
		}
		else {
			const mailconstants = MAIL_CONSTANTS.find (mail => {
				return mail.view === 'IH_追加_レンタル';
			});
			req.systemMailId = mailconstants.systemMail;
			req.inquiryId = mailconstants.inquiryMail;
		}
		const optionRentalList = cacheData.m_OptionListModel.filter(row => row.quantity1);
		if (optionRentalList && optionRentalList.length > 0) {
			const smartLight = mngr.getMCommonList(view, 'srvsmart', 'count_type_ko', null, null);
			// for (const option of optionRentalList) {
			// 	if (option.childCd === smartLight[0].commonValue)
			// 		req.systemInfo.optionRentalList.push(option.option + '  ' + option.quantity1 + '個');
			// 	else
			// 		req.systemInfo.optionRentalList.push(option.option + '  ' + option.quantity1 + '台');
			// }
			// for (const option of optionRentalList) {
			// 	if (ObjectUtil.isNullOrUndefined(req.systemInfo.optionName))
			// 		req.systemInfo.optionName = option.option + '　' + option.quantity1 + option.rentalUnit;
			// 	else
			// 		req.systemInfo.optionName += '/r' + option.option + '　' + option.quantity1 + option.rentalUnit;
			// }
			for (const option of optionRentalList)
				req.systemInfo.optionRentalList.push(option.option + '  ' + option.quantity1 + option.rentalUnit);
		}
		// お得パック状況
		if (cacheData.m_BaseInfo.otokuFlag) {
			req.systemInfo.otokuStatus = '契約中';
		}
		else if (!cacheData.m_BaseInfo.otokuFlag) {
			if (cacheData.m_BaseInfo.yesOtoku)
				req.systemInfo.otokuStatus = '未契約(お得パック希望する)';
			else if (cacheData.m_BaseInfo.noOtoku)
				req.systemInfo.otokuStatus = '未契約(お得パック希望しない)';
			else
				req.systemInfo.otokuStatus = '';
		}
		// レンタル/購入
		if (cacheData.m_BaseInfo.rental)
			req.systemInfo.rental = 'レンタル';
		else if (cacheData.m_BaseInfo.purchase)
			req.systemInfo.rental = '購入';
		return req;
	}
	// --------------------------------------------------------------------------
}
/**
 * 画面ウィザードAPI用モデル
 */
export class ItscomHomeNewWizardModel {
	// public 変数	-----------------------------------------------------------
	public m_CourseListModel: ItscomHomeCourseListModel[]			= null;	// ItscomHomeコースモデル
	public m_OptionListModel: ItscomHomeOptionListModel[]			= null;	// ItscomHomeオプションモデル
	public m_BaseInfo: BaseInfo										= null;	// 基本情報
	public m_YakkanListModel: YakkanListModel[]						= null;	// 約款リストモデル
	public m_OptComfirmListModel: ItscomHomeOptComfirmListModel[]	= null;	// ItscomHomeオプション確認モデル
	public m_CompleteModel: CompleteModel 							= null; // 完了モデル
	public m_OwnerContractList: OwnerContract[]						= null;	// オーナー契約リスト
	public m_BuyOptionList: ItscomHomeOptionListModel[]				= null; // 購入可能オプションリスト(検索用)
	// ------------------------------------------------------------------------

	// public メソッド	-------------------------------------------------------
	/**
	 * オプションリスト(PC/SP)判定
	 */
	public setOptionDisplay(view: MypageBaseView) {
		if (window.outerWidth > 440)
			view.setViewMode('オプション','PC');
		else
			view.setViewMode('オプション','SP');
	}
	// ------------------------------------------------------------------------

}

