import { WprBaseViewCore } from '../view/WprBaseViewCore';
import { WprHttpErrorInfo } from './WprHttpErrorInfo';
import { WprFramework } from '../WprFramework';
import { WprBaseObject } from '../WprBaseObject';

/**
 * サービス基本クラス
 */
export abstract class WprBaseService extends WprBaseObject {
	// abstract メソッド  --------------------------------------------------------
	/**
	 * Post通信実行処理
	 * @param url URL
	 * @param request リクエスト情報
	 * @returns 通信結果Promiseオブジェクト 
	 */
	protected abstract executePost(url: string, request: any): Promise<any>;

	/**
	 * Post通信実行処理
	 * @param url URL
	 * @returns 通信結果Promiseオブジェクト 
	 */
	 protected abstract executeGet(url: string): Promise<any>;
	 // --------------------------------------------------------------------------

	// public メソッド  ----------------------------------------------------------
	/**
	 * Post通信処理
	 * @param viewCore ビュー情報
	 * @param url URL
	 * @param request リクエスト情報 
	 * @param resMethod 結果処理メソッド
	 */
	public doPost(viewCore: WprBaseViewCore, url: string, request: any, resMethod: (result: any) => void): void {
		const status = WprFramework.getInstance().startWait();
		this.addInfoLog(`サービス(doPost)：開始[${url}][${viewCore.objectName}]`);
		viewCore.clearServiceError();
		viewCore.startStateLock();
		this.executePost(url, request).then((result) => {
			if (result['status']) {
				const status = result['status'] as string;
				if (status) {
					if (status.replace(/0/g, '') !== '') {
						let message = result['message'] as string;
						this.addErrorLog(`サービス(doPost)：エラー[${url}][${status}][${message}][${viewCore.objectName}]`);
						viewCore.onServiceError(url, status, message);
						viewCore.onServiceErrorResult(url, status, message, resMethod);
						return;
					} 
				}
			}
			this.addInfoLog(`サービス(doPost)：成功[${url}][${viewCore.objectName}]`);
			resMethod.bind(viewCore)(result);
			viewCore.updateMode();
			viewCore.viewInfo.checkScopeModelValue();
		}).catch((error) => {
			const einfo = error as WprHttpErrorInfo;
			if (einfo) {
				viewCore.addErrorLog(`サービス(doPost)：通信エラー${einfo.message}[${einfo.url}]`);
				viewCore.onServiceError(einfo.url, einfo.status, einfo.message, einfo.error);
				viewCore.onServiceErrorResult(einfo.url, einfo.status, einfo.message, resMethod);
			}
			else {
				viewCore.addErrorLog(`サービス(doPost)：通信エラー発生時にWprHttpErrorInfo以外の情報が返されました。[${url}]`);
				viewCore.onServiceError(url, '', '未定義エラー');
				viewCore.onServiceErrorResult(url, '', '未定義エラー', resMethod);
			}
		}).finally(() => {
			if (status === true)
				WprFramework.getInstance().endWait();
			viewCore.endStateLock();
		});
	}

	/**
	 * Get通信処理
	 * @param viewCore ビュー情報
	 * @param url URL
	 * @param request リクエスト情報 
	 * @param resMethod 結果処理メソッド
	 */
	 public doGet(viewCore: WprBaseViewCore, url: string, resMethod: (result: any) => void): void {
		const status = WprFramework.getInstance().startWait();
		this.addInfoLog(`サービス(doGet)：開始[${url}][${viewCore.objectName}]`);
		viewCore.clearServiceError();
		this.executeGet(url).then((result) => {
			if (result['status']) {
				const status = result['status'] as string;
				if (status) {
					if (status.replace(/0/g, '') !== '') {
						let message = result['message'] as string;
						this.addErrorLog(`サービス(doGet)：エラー[${url}][${status}][${message}][${viewCore.objectName}]`);
						viewCore.onServiceError(url, status, message);
						return;
					} 
				}
			}
			this.addInfoLog(`サービス(doGet)：成功[${url}][${viewCore.objectName}]`);
			resMethod.bind(viewCore)(result);
			viewCore.updateMode();
			viewCore.viewInfo.checkScopeModelValue();
		}).catch((error) => {
			const einfo = error as WprHttpErrorInfo;
			if (einfo) {
				viewCore.addErrorLog(`サービス(doGet)：通信エラー${einfo.message}[${einfo.url}]`);
				viewCore.onServiceError(einfo.url, '', einfo.message, einfo.error);
			}
			else {
				viewCore.addErrorLog(`サービス(doGet)：通信エラー発生時にWprHttpErrorInfo以外の情報が返されました。[${url}]`);
				viewCore.onServiceError(url, '', '未定義エラー');
			}
		}).finally(() => {
			if (status === true)
				WprFramework.getInstance().endWait();
		});
	}
	// --------------------------------------------------------------------------
}