import { ProgressBarModel } from '../../../models/ProgressBarModel';
import { MypageBaseView } from '../../base/MypageBaseView';
import { ProgressBarData } from '../../common/progress_bar/ProgressBarView';
import { WprModel } from '../../../../wpr-framework/view/deco/WprModel';
import { CustomerInfo } from '../../../models/CustomerInfo';
import { WprDI_ScopeModel } from '../../../../wpr-framework/view/di/WprDI_ScopeModel';
import { MypageWizardMngr } from '../../wizard/models/MypageWizardMngr';
import { VideoService } from '../../../service/VideoService';
import { IdpInfoModel } from '../../../service/models/apimodels/IdpInfoModel';
import { GetIdpRequest } from '../../../service/models/dto/mypagerenewal/GetIdpRequest';
import { ResetPasswordModel } from '../../../service/models/apimodels/ResetPasswordModel';

/**
 * 動画配信サービス　パスワード再発行確認画面
 */
export class VideoPassComfirmView extends MypageBaseView {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() { super('VideoPassComfirmView'); }
	// --------------------------------------------------------------------------

	// モデル  ------------------------------------------------------------------
	@WprDI_ScopeModel('MypageWizardMngr')
	private m_MypageWizardMngr: MypageWizardMngr			= null;		// 画面ウィザード管理クラス
	@WprModel('IdpInfo')
	private m_idpInfo: IdpInfoModel	= null;	// 
	// --------------------------------------------------------------------------

	// メンバ変数 ----------------------------------------------------------------
	private m_PasswordInfo: ResetPasswordModel
	// --------------------------------------------------------------------------

	// サービス  ----------------------------------------------------------------
	private m_VideoService: VideoService	= new VideoService();	// 動画配信サービス
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * コントロール定義設定
	 */
	public onRegisterConfig(): void {
		this.addConfig('id', 'id', 'IdpInfo', { bindName: 'idpID' });
	}

	/**
	 * アクション登録
	 */
	public onRegisterAction(): void {
		this.addAction('next', this.onNext);
		this.addAction('back', this.onBack);
	}

	/**
	 * ビュー表示通知
	 */
	public onShowView(): void {
		this.m_MypageWizardMngr.initView(this, (result) => {
			this.registAccessLog();
			this.setStorageItem('b_url', window.location.href)
			const sendData: ProgressBarData = new ProgressBarData();
			sendData.progressBarList = this.m_MypageWizardMngr.getProgressModelList(this);
			this.sendChildData('fP_ev_progressBarIntital', sendData);

			this.m_PasswordInfo = this.m_MypageWizardMngr.getCache().passwordInfo;
			this.refreshView();
		});
		const req: GetIdpRequest = new GetIdpRequest();
		req.customerID = this.m_MypageWizardMngr.getParams().customerId;
		this.m_VideoService.getIdp(this, req, (result) => {
			this.m_idpInfo = result.idpInfoModel;
			this.refreshView();
		});
	}
	// --------------------------------------------------------------------------

	// アクション  ---------------------------------------------------------------
	/**
	 * 再発行
	 */
	public onNext(param: any): void {
		const req: GetIdpRequest = new GetIdpRequest();
		req.customerID = this.m_MypageWizardMngr.getParams().customerId;
		this.m_VideoService.resetIdpPassword(this, req, (result) => {
			this.m_PasswordInfo.idpID = result.resetPasswordModel.idpID;
			this.m_PasswordInfo.password = result.resetPasswordModel.password;
			this.m_PasswordInfo.passwordKana = result.resetPasswordModel.passwordKana;
			this.m_PasswordInfo.date = result.resetPasswordModel.date;
			this.refreshModel();
			this.m_MypageWizardMngr.goNext(this);
		});
	}

	/**
	 * 戻る
	 */
	public onBack(param: any): void {
		this.m_MypageWizardMngr.goBack(this);
	}
	// --------------------------------------------------------------------------
}
