import { Divider, Typography } from '@material-ui/core';
import { Grid } from '@mui/material';
import React, { ReactNode } from 'react';
import { MatTypography } from '../../../../wpr-framework/component/controls/material/MatTypography';
import { WprAnchor } from '../../../../wpr-framework/component/controls/WprAnchor';
import { WprBaseViewComponent } from '../../../../wpr-framework/view/component/WprBaseViewComponent';
import { ProgressBar } from '../../common/progress_bar/ProgressBar';
import { NameChangeInformationView } from './NameChangeInformationView';
import styles from './NameChangeInformation.scss';
import { MatRadioGroup } from '../../../../wpr-framework/component/controls/material/MatRadioGroup';
import { MatRadio } from '../../../../wpr-framework/component/controls/material/MatRadio';
import { MatButton } from '../../../../wpr-framework/component/controls/material/MatButton';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { WprContainer } from '../../../../wpr-framework/component/controls/WprContainer';
import { WprRowInfo } from '../../../../wpr-framework/component/props/WprRowInfo';
import errorIcon from '../../../../../shared/image/common/icon_error.png'
/**
 * 名義変更受付の案内用コンポーネント
 */
export class NameChangeInformation extends WprBaseViewComponent {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: any) { super(new NameChangeInformationView(), props); }
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** 名義変更受付の案内ビュー情報 */
	public get viewInfo(): NameChangeInformationView	{ return this.view as NameChangeInformationView;	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * リストコントロール定義登録
	*/
	protected onRegisterListRender(): void {
		this.addListRender('ErrorModel', null, this.onErrorModelRender);
	}

	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		return (
			<>
			<div className='pageCard'>
				<div className="pageHeader stepFlow">
					<ProgressBar name='progressBar' view={this.view} />
				</div>
				<div className='main'>
					<div className='pageTitle'>
						<div className='title_left'>
							<MatTypography name='title' className='title_text' view={this.view}/>
							<Typography className='title_comment'>お手続きの前にご確認ください。</Typography>
						</div>
					</div>
					<WprContainer mode='オプション.エラー' view={this.view}>
						<div className='errorArea'>
							<span className='parallel'>
								<img src={errorIcon} alt='' className='x_img'/>
								<div className='errorList'>
									{this.renderList('ErrorModel')}
								</div>
							</span>
						</div>
					</WprContainer>
					<br />
					<div>
						{/* 名義変更に関する注意事項 */}
						<div className='title_lineBottom'>
							<Typography className='font18'>マイページでの名義変更に関する注意事項</Typography>
						</div>
						<br />
						<div className='box_blue1'>
							<div className='boxTitle'>
								<Typography>名義変更の受付可能範囲について</Typography>
							</div>
							<div className='boxContent'>
								<div className='needThings'>
									<div className='needItem'>
										<div className='itemTitle'>
											以下の場合、マイページでの名義変更が可能です。
										</div>
										<br />
										<div className='itemContent'>
											①改称
											<br />
											　個人・・・婚姻などにより改名した場合
										</div>
										<div className='itemContent'>
											②承継
											<br />
											　個人・・・契約者の死亡により親族（二親等以内かつ居住地が現契約者と同一）へ引き継ぐ場合
										</div>
										<div className='itemContent'>
											③譲渡
											<br />
											　個人・・・自己都合により親族（二親等以内かつ居住地が現契約者と同一）へ譲渡する場合
										</div>
										<br />
										<div className='itemTitle'>
											以下の場合、マイページでの名義変更を承ることができません。
										</div>
										<div className='itemContent'>
											<ul>
												<li>未成年への変更</li>
												<li>改姓　法人：会社・組織名を変更した場合</li>
												<li>譲渡　法人：個人から同人が代表を務める法人への変更、またはその逆</li>
												<li>物件契約者の名義変更</li>
											</ul>
										</div>
										<div className='itemContent linkStyle'>
											書面でのお手続きが必要となります。
											<MatButton name='inquiryOther' view={this.view}>
												こちら
											</MatButton>
											よりお問い合わせください。
										</div>
										<div className='itemContent'>
											<ul>
												<li>イッツコムスマホ（音声SIM）利用者の名義変更</li>
											</ul>
										</div>
										<div className='itemContent linkStyle'>
											フリーダイヤルまでお問合せください。
										</div>
										<br />
										<div className='itemTitle'>
											以下の場合、名義変更を承ることができません。
										</div>
										<div className='itemContent'>
											<ul>
												<li>離婚(離婚予定も含む)に伴う継承</li>
												<li>法人契約の継承</li>
												<li>異なる法人間での譲渡</li>
												<li>内縁、事実婚、同棲パートナー等への変更</li>
											</ul>
										</div>
										<div className='itemContent'>
											現契約者様による解約のうえ、新契約者さまによる新たなお申込みが必要となります。
											<br />
											なお、解約→新規契約の場合、ケーブルプラス電話の電話番号、メールアドレス、イッツコムアカウント等は引継ぎができません。
											<br />
											また、ケーブルプラス電話をご利用の法人名義のお客さまは、名義変更を承れない場合がございます。
											<br />
											現契約者様のお申し込みから1か月以内に新名義人様のお手続きが完了しない場合にはお申し込みをキャンセルとさせていただきます。
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<br />
					<div>
						{/* 本人確認書類について */}
						<div className='title_lineBottom'>
							<Typography className='font18'>本人確認書類について</Typography>
						</div>
						<br />
						<div className='boxContent'>
							<div className='needThings'>
								<div className='needItem linkStyle'>
									<div className='itemContent'>
										お手続き区分ごとに必要な本人確認書類・名義変更に関する必要書類は
										<MatButton name='document' view={this.view}>
											こちら
										</MatButton>
										からご確認ください。
									</div>
									<div className='itemContent'>
										名義変更のお手続きには各種書類の提出が必要になります。
									</div>
									<div className='itemContent'>
										お手元にご準備の上、お手続きをお願いいたします。
									</div>
									<div className='itemContent'>
										お手続きの内容を選択してください。以下に該当しない場合は
										<MatButton name='inquiryOther' view={this.view}>
											こちら
										</MatButton>
										よりお問合せください。
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className='feeTable'>
						<Grid container className='feeRow'>
							<Grid item xs={12} sm={4} md={4} className='nameChangeTitle'>
								<div>名義変更区分</div>
							</Grid>
							<Grid item xs={12} sm={8} md={8} className='feeVal2'>
								<MatRadioGroup name='nameChangeKbn' view={this.view} >
									<div>
										<MatRadio name='nameChangeKbn' id='nameChangeKbn_1' value='1' label='苗字の変更（改姓）' view={this.view} />
									</div>
									<div>
										<MatRadio name='nameChangeKbn' id='nameChangeKbn_2' value='2' label='ご契約者さま逝去' view={this.view} />
									</div>
									<div>
										<MatRadio name='nameChangeKbn' id='nameChangeKbn_3' value='3' label='同居のご家族への契約者変更' view={this.view} />
									</div>
								</MatRadioGroup>
							</Grid>
						</Grid>
					</div>
				</div>
				<Divider />
				<div className='pageFooter footer-space'>
					<div>
						<MatButton name='next' view={this.view} className='orangeButton orangeButton_parts' >
							注意事項を確認のうえ
							<br />
							名義変更のお手続きに進む
							<NavigateNextIcon />
						</MatButton>
					</div>
					<div>
						<MatButton name='back' view={this.view} className='backButton' >
							<NavigateBeforeIcon />
							戻る
						</MatButton>
					</div>
				</div>
			</div>
			</>
		);
	}
	
	/**
	 * リスト名=ErrorModel
	 */
	public onErrorModelRender(rowInfo: WprRowInfo): any {
		return (
			<div>
				<MatTypography name='errorMessage' view={this.view} row={rowInfo}/>
			</div>
		);
	}
	// --------------------------------------------------------------------------
}
