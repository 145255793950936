import React, { ReactNode } from 'react';
import { WprBaseInputControlComponent } from '../../../wpr-framework/component/controls/WprBaseInputControlComponent';
import { IWprControlProps } from '../../../wpr-framework/component/props/IWprControlProps';
import { WprControlVisibility } from '../../../wpr-framework/component/props/IWprControlState';
import styles from './FontSizeRadio.scss';

/**
 * ラジオボタンプロパティ情報
 */
interface IFontSizeRadioProps extends IWprControlProps {
	value: any;					// 値
	beforeLabel?: string;		// 前ラベル
	afterLabel?: string;		// 後ラベル
}

/**
 * ラジオボタンコントロール
 */
export class FontSizeRadio extends WprBaseInputControlComponent<IFontSizeRadioProps, HTMLInputElement> {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: IFontSizeRadioProps) {
		super(props);
		this.setChangeValueEvent(this.onChangeValue.bind(this));
	}
	// --------------------------------------------------------------------------

	// イベント  ----------------------------------------------------------------
	/**
	 * 値変更処理
	 * @param event 変更イベント 
	 */
	public onChangeValue(event: React.ChangeEvent<HTMLInputElement>): void {
		if (event.target.checked === false)
			return;
		const value = this.props.value;
		if (this.controlInfo != null) {
			this.addChangeValueEventLog(event.target, value);
			this.changeValue(value);
		}
	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * スタイルオブジェクト取得
	*/
	public getStylesObject(): any { return styles; }
	/**
	 * コントロール名称取得
	 * @returns コントロール名称
	 */
	public getName(): string {
		if (this.props.row)
			return this.props.name + this.props.row.index;
		else
			return this.props.name;
	}

	/**
	 * 値取得
	 * @returns 値
	 */
	protected getValue(): any {
		return this.props.value;
	}

	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		if (this.state.visibility === WprControlVisibility.COLLAPSED)
			return <></>;
		const props = this.getProps('fontSize-radio');
		const { name, view, className, beforeLabel, afterLabel, children, ...other } = this.props;
		const state = this.getState(['style', 'disabled', 'readOnly']);
		const checked = (this.props.value === this.state.value);
		const events = this.getEvents();
		let radio = null;
		if (state.readOnly === true && state.disabled === false && checked === false)
			state.disabled = true;
		if (this.state.visibility === WprControlVisibility.VISIBLE) {
			radio = <input type='radio' checked={checked} {...props} {...state} {...other} {...events} ref={this.ref} />;
		}
		else {
			state['style'] = { ...this.state.style, visibility: 'hidden' };
			radio = <input type='radio' checked={checked} {...props} {...state} {...other} ref={this.ref} />;
		}
		if ((this.props.beforeLabel || this.props.afterLabel) && this.props.id) {
			const className = checked ? 'fontSize-radio-label checked' : 'fontSize-radio-label';
			if (this.state.visibility === WprControlVisibility.VISIBLE)
				radio = <label className={className} htmlFor={this.props.id}>{this.props.beforeLabel}{radio}{this.props.afterLabel}</label>;
			else
				radio = <label className={className} htmlFor={this.props.id} style={{ visibility: 'hidden' }}>{this.props.beforeLabel}{radio}{this.props.afterLabel}</label>;
		}
		return (
			<>
				{radio}
			</>
		);
	}

	/**
	 * プロパティチェック処理
	 */
	public onCheckProps(): void {
		if ((this.props.beforeLabel || this.props.afterLabel) && !this.props.id) {
			if (this.props.beforeLabel)
				this.addErrorLog(`beforeLabel(${this.props.beforeLabel})を指定した場合は、idを指定してください。[${this.props.name}]`);
			else
				this.addErrorLog(`afterLabel(${this.props.afterLabel})を指定した場合は、idを指定してください。[${this.props.name}]`);
		}
	}

	/**
	 * プロパティ情報取得
	 * @param cls オリジナルクラスx
	 * @returns プロパティ情報
	 */
	protected getProps(cls?: string, setClassName: boolean = true): any {
		const rtn = super.getProps(cls, setClassName);
		if (this.props.id)
			rtn['aria-label'] = this.props.id;
		return rtn;
	}
	// --------------------------------------------------------------------------
}