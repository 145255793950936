/**
 * HDocConfirm generated by hbm2java
 */
export class HDocConfirm {
	// public 変数	-----------------------------------------------------------
	public docConfirmId: number;			// 書面確認履歴ID
	public customerId: string;				// お客様番号
	public rootApplicationFormId: string;	// 元申込書ID
	public applicationFormId: string;		// 申込書ID
	public entryDate: string;					// 登録日時
	public entryCd: string;					// 登録担当者コード
	public updateDate: Date;				// 更新日時
	public updateCd: string;				// 更新担当者コード
	public deleteFlg: boolean;				// 削除フラグ
	public deleteDate: Date;				// 削除日時
	public deleteCd: string;				// 削除担当者コード
	// ------------------------------------------------------------------------
}
