import { WprBaseViewMultiModeInfo } from '../../../../../wpr-framework/mode/WprBaseViewMultiMode';
import { WprMultiMode } from '../../../../../wpr-framework/mode/WprMultiMode';
import { WprControlInfo } from '../../../../../wpr-framework/view/control/WprControlInfo';
import { WprDI_Control } from '../../../../../wpr-framework/view/di/WprDI_Control';
import { AddPhoneSelectView } from '../AddPhoneSelectView';

/**
 * お得パックモード
 */
export class PhoneOtokuMode extends WprBaseViewMultiModeInfo<AddPhoneSelectView> {
	// DI情報  ------------------------------------------------------------------
	@WprDI_Control
	private m_yesOtoku: WprControlInfo	= null;	// お得パック希望する
	@WprDI_Control
	private m_noOtoku: WprControlInfo	= null;	// お得パック希望しない
	// --------------------------------------------------------------------------

	
	// コンストラクタ  -----------------------------------------------------------
	public constructor() {
		super('PhoneOtokuMode');
	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * モードリスト取得（この画面モードで使用する全てのモードを定義する）
	 * @param list モードリスト
	 */
	public useModeList(list: string[]): void {
		list.push('切替可(yesOtoku)');
		list.push('切替不可(yesOtoku)');
		list.push('切替可(noOtoku)');
		list.push('切替不可(noOtoku)');
	}

	/**
	 * フィールド定義を登録処理
	 */
	public onRegisterControl(): void {
		this.addControl('yesOtoku', {
			check: true,
			readOnly: [ { mode: '切替不可(yesOtoku)', value: true }, { mode: '切替可(yesOtoku)', value: false } ]
		});
		this.addControl('noOtoku', {
			check: true,
			readOnly: [ { mode: '切替不可(noOtoku)', value: true }, { mode: '切替可(noOtoku)', value: false } ]
		});
	}

	/**
	 * 画面の状態をチェックしてモードを変更する
	 * @param curMode 現在のモード
	 * @param newMode 新しいモード
	 */
	public onCheckMultiMode(curMode: WprMultiMode, newMode: WprMultiMode): void {
		if (this.m_yesOtoku.value && !this.m_noOtoku.value) {
			newMode.addMode('切替不可(yesOtoku)');
			newMode.addMode('切替可(noOtoku)');
		}
		else if (!this.m_yesOtoku.value && this.m_noOtoku.value) {
			newMode.addMode('切替可(yesOtoku)');
			newMode.addMode('切替不可(noOtoku)');
		}
	}
	// --------------------------------------------------------------------------
}
