import { WprModel } from '../../../../../wpr-framework/view/deco/WprModel';
import { WprDI_ScopeModel } from '../../../../../wpr-framework/view/di/WprDI_ScopeModel';
import { ObjectUtil } from '../../../../common/util/ObjectUtil';
import { Customer } from '../../../../models/bss/customer/Customer';
import { ViewInfoModel } from '../../../../service/models/apimodels/ViewInfoModel';
import { MypageBaseView } from '../../../base/MypageBaseView';
import { ProgressBarData } from '../../../common/progress_bar/ProgressBarView';
import { MypageWizardMngr } from '../../../wizard/models/MypageWizardMngr';
import { WprViewMode } from '../../../../../wpr-framework/view/deco/WprViewMode';
import { OtokuMode } from './mode/OtokuMode';
import { CableMode } from './mode/CableMode';
import { ContractPlanModel } from '../../../../service/models/apimodels/ContractPlanModel';
import { WprDI_Control } from '../../../../../wpr-framework/view/di/WprDI_Control';
import { WprControlInfo } from '../../../../../wpr-framework/view/control/WprControlInfo';
import { NetServiceNewWizardModel } from '../../../wizard/NetServiceNewWizardFlow';
import { InputComfirmMode } from './mode/InputComfirmMode';
import { WprValidError } from '../../../../../wpr-framework/view/validation/WprValidError';
import { DateUtil } from '../../../../common/util/DateUtil';
import { OtherLocationMode } from './mode/OtherLocationMode';
import { CourseListModel } from '../../../../models/mypage/net/CourseListModel';
import { NetFlowModel } from '../../../../models/mypage/net/NetFlowModel';
import { HissuMode } from './mode/HissuMode';
import MypageCodeTypeConstants from '../../../../common/MypageCodeTypeConstants';
import { WprListData } from '../../../../../wpr-framework/view/deco/WprListData';
import { ErrorModel } from '../../../../models/mypage/smart/ErrorModel';
import { GetCampaignRequest } from '../../../../service/models/dto/mypagerenewal/GetCampaignRequest';
import { keys } from '@material-ui/core/styles/createBreakpoints';

/**
 * コース内容の選択
 */
export class AddNetSettingView extends MypageBaseView {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() { super('AddNetSettingView'); }
	// --------------------------------------------------------------------------

	// DI情報  ------------------------------------------------------------------
	@WprDI_Control
	private m_yesOtoku: WprControlInfo	= null;						// お得パック希望する
	@WprDI_Control
	private m_noOtoku: WprControlInfo	= null;						// お得パック希望しない
	@WprDI_Control
	private m_cable: WprControlInfo	= null;							// ケーブルモデム
	@WprDI_Control
	private m_cableWifi: WprControlInfo	= null;						// ケーブルモデムwifi
	@WprDI_Control
	private m_desiredDate1: WprControlInfo	= null;					// 第一希望
	@WprDI_Control
	private m_selectTime1: WprControlInfo	= null;					// 第一希望　時間帯
	@WprDI_Control
	private m_desiredDate2: WprControlInfo	= null;					// 第二希望
	@WprDI_Control
	private m_selectTime2: WprControlInfo	= null;					// 第二希望　時間帯
	@WprDI_Control
	private m_desiredDate3: WprControlInfo	= null;					// 第三希望
	@WprDI_Control
	private m_selectTime3: WprControlInfo	= null;					// 第三希望　時間帯
	@WprDI_Control
	private m_beforeContact: WprControlInfo	= null;	// 前日連絡先
	@WprDI_Control
	private m_sameDayContact: WprControlInfo	= null;	// 前日連絡先
	@WprDI_Control
	private m_location: WprControlInfo		= null;					// 場所
	@WprDI_Control
	private m_comment: WprControlInfo		= null;					// フリー入力
	@WprDI_Control
	private m_securityFlg: WprControlInfo	= null;					// セキュリティフラグ
	@WprDI_Control
	private m_campaignCode: WprControlInfo	= null;	// 入力キャンペーンコード
	// --------------------------------------------------------------------------

	// モデル  ------------------------------------------------------------------
	@WprDI_ScopeModel('MypageWizardMngr')
	private m_MypageWizardMngr: MypageWizardMngr = null;			// 画面ウィザード管理クラス
	@WprModel('NetInfo')
	private m_NetInfo: CourseListModel	= null;					// 画面遷移用
	@WprModel('NetFlowModel')
	private m_NetFlowModel: NetFlowModel	= null;					// ネット遷移情報
	@WprListData('ErrorListModel')
	private m_ErrorListModel: ErrorModel[]	= null;	// エラーモデル
	// --------------------------------------------------------------------------
	
	// メンバ変数  --------------------------------------------------------------
	private m_Customer:	Customer							= null;	// 顧客情報
	private m_ContractModel: ContractPlanModel				= null;	// 契約情報
	private m_NetServiceNewModel: NetServiceNewWizardModel  = null;	// ネット追加モデル
	// --------------------------------------------------------------------------

	// サブビュー/モード  --------------------------------------------------------
	@WprViewMode
	private m_OtokuMode = new OtokuMode();							// お得パックモード
	@WprViewMode
	private m_CableMode = new CableMode();							// ケーブルモード
	@WprViewMode
	private m_InputComfirmMode = new InputComfirmMode();			// 入力確認モード
	@WprViewMode
	private m_OtherLocationMode = new OtherLocationMode();			// その他モード
	@WprViewMode
	private m_HissuMode = new HissuMode();	// 必須モード
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** ウィザード定義 */
	public get mngr(): MypageWizardMngr 					{ return this.m_MypageWizardMngr; }
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * コントロール定義設定
	 */
	public onRegisterConfig(): void {
		this.addConfig('cDispnamep', 'コース', 'NetInfo');
		this.addConfig('noOtoku', 'お得パック希望しない', 'NetInfo');
		this.addConfig('yesOtoku', 'お得パック希望', 'NetInfo');
		this.addConfig('equipmentProvidingCheck', '機器のご提供方法', 'NetInfo', { readOnly: true, disabled: true });
		this.addConfig('cable', 'ケーブルモデム', 'NetInfo');
		this.addConfig('cableWifi', 'ケーブルモデムwifi', 'NetInfo');
		this.addConfig('floor', '階数', 'NetInfo');
		this.addConfig('location', '場所', 'NetInfo');
		this.addConfig('comment', 'フリー入力', 'NetInfo', { maxLength: 100 });
		this.addConfig('desiredDate1', '第一希望', 'NetInfo', { emptyValue: null, max: this.m_MypageWizardMngr.getMaxReqDate(), min: this.m_MypageWizardMngr.getMinReqDate() });
		this.addConfig('selectTime1', '第一希望　時間帯', 'NetInfo', { emptyValue: null });
		this.addConfig('desiredDate2', '第二希望', 'NetInfo', { emptyValue: null, max: this.m_MypageWizardMngr.getMaxReqDate(), min: this.m_MypageWizardMngr.getMinReqDate() });
		this.addConfig('selectTime2', '第二希望　時間帯', 'NetInfo', { emptyValue: null });
		this.addConfig('desiredDate3', '第三希望', 'NetInfo', { emptyValue: null, max: this.m_MypageWizardMngr.getMaxReqDate(), min: this.m_MypageWizardMngr.getMinReqDate() });
		this.addConfig('selectTime3', '第三希望　時間帯', 'NetInfo', { emptyValue: null });
		this.addConfig('reformFlg', 'リフォームフラグ', 'NetInfo');
		this.addConfig('tvPortFlg', 'TV端子確認フラグ', 'NetInfo');
		this.addConfig('campaignCode', '入力キャンペーンコード', 'NetInfo');
		this.addConfig('securityFlg', 'セキュリティフラグ', 'NetInfo');
		this.addConfig('securityComment', 'セキュリティフリー入力', 'NetInfo');
		this.addListConfig('errorMessage', 'エラーメッセージ', 'ErrorListModel');
		this.addConfig('beforeContact', '前日連絡先', 'NetInfo', { maxLength: 11, validation: '整数' });
		this.addConfig('sameDayContact', '当日連絡先', 'NetInfo', { maxLength: 11, validation: '整数' });
		this.addConfig('name', '当日立ち合い者', 'NetInfo');
	}
	// --------------------------------------------------------------------------

	/**
	 * アクション登録
	 */
	public onRegisterAction(): void {
		this.addAction('next', this.onNext, true);
		this.addAction('back', this.onBack);
	}

	/**
	 * 値変更通知
	 * @param name 名前
	 * @param value 値
	 * @returns falseの場合、変更を取り消す
	 */
	public onChangeValue(name: string, value: any): boolean {
		this.refreshModel();
		if (name === 'yesOtoku') 
			this.m_noOtoku.value = false;
		if (name === 'noOtoku') 
			this.m_yesOtoku.value = false;
		if (name === 'cable')
			this.m_cableWifi.value = false;
		if (name === 'cableWifi')
			this.m_cable.value = false;
		if (name === 'securityFlg') {
			if (value)
				this.setViewMode('セキュリティ会社', '表示');
			else
				this.setViewMode('セキュリティ会社', '非表示');
		}
		this.updateMode();
		return true;
	}

	/**
	 * 入力チェック（独自のValidationを実施する場合に使用する）
	 *	エラー追加例：validError.addError('エラーメッセージ');				// 相関チェックなどのエラー
	 * 				 this.setControlError('control', 'error');			 // コントロールのエラー
	 * 				 validError.showErrorMessage.('エラーメッセージ');	   // エラーメッセージ表示
	 * 		※上記のエラーを追加した場合は、アクションは実行しません。
	 * 		※コントロールの値をチェックする場合は、refreshModelは使用しないでください。
	 * 		　コントロールの値をチェックする場合は、コントロールをDIしてvalueを使用してください。
	 * 		　コントロールが双方向のコンバータを使用している場合は、dataValueを使用してください。
	 * @param actionName アクション名
	 * @param validError バリデーションエラー情報
	 * @param row 行情報
	 */
	protected onValid(actionName: string, validError: WprValidError, row?: any): void {
		this.m_ErrorListModel = new Array();
		if (actionName == 'next' && this.m_NetInfo.constractionFlg) {
			const hisssu1: boolean = !this.m_MypageWizardMngr.isNotEmpty(this.m_desiredDate1.value, this.m_selectTime1.value);
			const hisssu2: boolean = !this.m_MypageWizardMngr.isNotEmpty(this.m_desiredDate2.value, this.m_selectTime2.value);
			const hisssu3: boolean = !this.m_MypageWizardMngr.isNotEmpty(this.m_desiredDate3.value, this.m_selectTime3.value);
			if (hisssu1 || hisssu2 || hisssu3) {
				const itemStr: string = this.m_MypageWizardMngr.setErrorReqDateItem([{ flag: hisssu1, dispVal: '第一希望' }, { flag: hisssu2, dispVal: '第二希望' }, { flag: hisssu3, dispVal: '第三希望' },]);
				const model = new ErrorModel();
				model.errorMessage = itemStr + '：日付、時間帯のいずれかが入力されていません。再度入力してください。';
				this.m_ErrorListModel.push(model);
			}
			else {
				const selectTime1: string = this.m_desiredDate1.value + this.m_selectTime1.value;
				const selectTime2: string = this.m_desiredDate2.value + this.m_selectTime2.value;
				const selectTime3: string = this.m_desiredDate3.value + this.m_selectTime3.value;
				let reqTimeErrorFlg1: boolean = false;
				let reqTimeErrorFlg2: boolean = false;
				let reqTimeErrorFlg3: boolean = false;
				if (ObjectUtil.isNotNullOrUndefined(this.m_desiredDate1.value) && ObjectUtil.isNotNullOrUndefined(this.m_selectTime1.value) && selectTime1 === selectTime2) reqTimeErrorFlg1 = true;
				if (ObjectUtil.isNotNullOrUndefined(this.m_desiredDate2.value) && ObjectUtil.isNotNullOrUndefined(this.m_selectTime2.value) && selectTime1 === selectTime3) reqTimeErrorFlg2 = true;
				if (ObjectUtil.isNotNullOrUndefined(this.m_desiredDate3.value) && ObjectUtil.isNotNullOrUndefined(this.m_selectTime3.value) && selectTime2 === selectTime3) reqTimeErrorFlg3 = true;
				if (reqTimeErrorFlg1 || reqTimeErrorFlg2 || reqTimeErrorFlg3) {
					let itemStr: string;
					if (reqTimeErrorFlg1 === true && reqTimeErrorFlg3 == true) 
						itemStr = '第一希望と第二希望と第三希望';
					else 
						itemStr = this.m_MypageWizardMngr.setErrorReqDateItem([{ flag: reqTimeErrorFlg1, dispVal: '第一希望と第二希望' }, { flag: reqTimeErrorFlg2, dispVal: '第一希望と第三希望' }, { flag: reqTimeErrorFlg3, dispVal: '第二希望と第三希望' },]);
					const model = new ErrorModel();
					model.errorMessage = itemStr + '：同じ希望日時が入力されています。別の日時を指定してください。';
					this.m_ErrorListModel.push(model);
				}
			}
			if (this.m_desiredDate1.value < this.m_MypageWizardMngr.getMinReqDate()) {
				const date = this.m_MypageWizardMngr.convertToDate(this.m_MypageWizardMngr.getMinReqDate());
				const model = new ErrorModel();
				model.errorMessage = '第一希望に' + date + '以降を入力してください。';
				this.m_ErrorListModel.push(model);
			}
			if (this.m_desiredDate2.value < this.m_MypageWizardMngr.getMinReqDate() ) {
				const date = this.m_MypageWizardMngr.convertToDate(this.m_MypageWizardMngr.getMinReqDate());
				const model = new ErrorModel();
				model.errorMessage = '第二希望に' + date + '以降を入力してください。';
				this.m_ErrorListModel.push(model);
			}
			if (this.m_desiredDate3.value < this.m_MypageWizardMngr.getMinReqDate()) {
				const date = this.m_MypageWizardMngr.convertToDate(this.m_MypageWizardMngr.getMinReqDate());
				const model = new ErrorModel();
				model.errorMessage = '第三希望に' + date + '以降を入力してください。';
				this.m_ErrorListModel.push(model);
			}
			if (!this.m_beforeContact.value.match(/^[0-9]+$/)) {
				const model = new ErrorModel();
				model.errorMessage = '前日連絡先は半角数字で入力してください。';
				this.m_ErrorListModel.push(model);
			}
			if (!this.m_sameDayContact.value.match(/^[0-9]+$/)) {
				const model = new ErrorModel();
				model.errorMessage = '当日連絡先は半角数字で入力してください。';
				this.m_ErrorListModel.push(model);
			}
		}
		if (this.m_ErrorListModel.length > 0) {
			validError.addError('Error');
			this.setViewMode('エラー','表示');
			this.setListData('ErrorListModel', this.m_ErrorListModel);
		}
	}

	/**
	 * バリデーションエラーメッセージ通知
	 * @param name コントロール名
	 * @param rowIndex 行数(Listの場合)
	 * @param validatorName バリデーション名
	 * @param msg エラーメッセージ
	 * @returns エラーメッセージ（変更する場合に指定する、メッセージを使用しない場合は、nullを返す）
	 */
	public onInvalidMessage(name: string, rowIndex: number, validatorName: string, msg: string): string {
		if (((name === 'desiredDate1' || name === 'desiredDate2' || name === 'desiredDate3') && validatorName === '最小値')
			|| ((name === 'beforeContact' || name === 'sameDayContact') && (validatorName === '整数')))
			msg = null;
		return msg;
	}

	/**
	 * ビュー表示通知
	*/
	public onShowView(): void {
		this.m_MypageWizardMngr.initView(this, (result: ViewInfoModel) => {
			this.registAccessLog();
			this.setStorageItem('b_url', window.location.href);
			const sendData: ProgressBarData = new ProgressBarData();
			sendData.progressBarList = this.m_MypageWizardMngr.getProgressModelList(this);
			this.sendChildData('fP_ev_progressBarIntital', sendData);
			// 確認画面の情報クリア
			this.m_MypageWizardMngr.getCache().m_ConfirmListModel = new Array();
			this.m_NetServiceNewModel = new NetServiceNewWizardModel();
			this.m_NetInfo = this.m_MypageWizardMngr.getCache().m_NetInfo;
			// セキュリティコメント
			if (this.m_NetInfo.securityFlg)
				this.setViewMode('セキュリティ会社', '表示');
			this.m_ContractModel = this.m_MypageWizardMngr.getViewInfo().contractModel; // 契約情報
			const course = this.m_MypageWizardMngr.getCache().m_CourseListModel.filter(row => row.check === true);
			this.m_NetFlowModel = this.m_MypageWizardMngr.getCache().m_NetFlowModel;
			// 申込みコース
			if (course && course.length > 0 && ObjectUtil.isNullOrUndefined(this.m_NetInfo.cDispnamep)) {
				this.m_NetInfo.cDispnamep = course[0].cDispnamep;
				this.m_NetInfo.payAmount = course[0].payAmount;
				this.m_NetInfo.serviceCd = course[0].serviceCd;				
				this.m_NetInfo.tanakoServiceCd = course[0].tanakoServiceCd; // 店子コースの場合
			}
			// 機器のご提供方法
			this.m_NetInfo.equipmentProvidingCheck = true;
			this.m_Customer = this.m_MypageWizardMngr.getViewInfo().customer;
			const apartCourseList = this.m_MypageWizardMngr.getMCommonList(this, 'srvnet', 'ftth_wire_sv', 'wire_disp_sv', null); 
			// FTTHの場合
			if (this.m_Customer.building[0].buildingLineTypeCD === '2') {
				this.setViewMode('インターネットのコース変更', 'FTTH');
				// 端末の種類、表示判定
				for (const apart of apartCourseList) {
					if (apart.commonValue === this.m_NetInfo.serviceCd) {
						this.setViewMode('端末の種類', '表示');
						this.setViewMode('FTTH(マンションコース)の場合', '補足');
						if (!this.m_NetInfo.cable && !this.m_NetInfo.cableWifi)
							this.m_NetInfo.cable = true;
						break;
					}
				}
			}
			else {
				this.setViewMode('端末の種類', '表示');
				if (!this.m_NetInfo.cable && !this.m_NetInfo.cableWifi)
					this.m_NetInfo.cable = true;
				this.setViewMode('FTTH以外の場合', '補足');
				this.setViewMode('インターネットのコース変更', 'FTTH以外')
			}
			if (this.m_Customer.building[0].buildingTypeCD === '3')
				this.setViewMode('階数', '表示');
			this.m_NetInfo.constractionFlg = true;
			this.setOtoku();
			this.refreshView();
		});
	}
	// アクション  ---------------------------------------------------------------
	/**
	 * 次へ
	 */
	public onNext(param: any): void {
		this.refreshModel();
		//キャンペーンコードが入力されているとき
		if (this.m_NetInfo.campaignCode) {
			const req: GetCampaignRequest = new GetCampaignRequest();
			req.campaignGroupCd = this.m_NetInfo.campaignCode;
			this.m_CommonService.getCampaign(this, req, (result) => {
				if (result.mcampaignList && result.mcampaignList.length > 0) {
					this.m_NetInfo.campaignGroup = result.mCampaignGroup;
					this.m_NetInfo.campaignList = result.mcampaignList;
					this.next()
				}
				else {
					const model = new ErrorModel();
					model.errorMessage = 'キャンペーンコード：キャンペーンコードに誤りがあります。再度入力してください。';
					this.m_ErrorListModel.push(model);
				}
				if (this.m_ErrorListModel.length !== 0) {
					this.setViewMode('エラー','表示');
					this.setListData('ErrorListModel', this.m_ErrorListModel);
					window.scroll({top: 0, behavior: 'smooth'});	
				}
			});
		}
		else {
			this.m_NetInfo.campaignList = null;
			this.next()
		}
	}

	/**
	 * 戻る
	 */
	public onBack(param: any): void {
		// 申し込み情報クリア
		this.m_MypageWizardMngr.getCache().m_NetInfo = new CourseListModel();
		this.m_NetFlowModel.defaultFlg = false;
		this.m_MypageWizardMngr.goBack(this);
	}
	// --------------------------------------------------------------------------
	
	// privateメソッド  ----------------------------------------------------------
	/**
	 * お得パック申込み判定(※Wizard側に移せないか後ほど検討)
	 */
	private setOtoku() {
		const planList = this.m_MypageWizardMngr.getViewInfo().contractModel.planList;
		const customerPayment =this.m_MypageWizardMngr.getViewInfo().customerPayment;
		if (customerPayment && this.m_MypageWizardMngr.showOtokuPlan(this, customerPayment[0].payment[0], planList)) {
			this.setViewMode('お得パック', '申込み');
			// デフォルトチェック
			if (!this.m_NetInfo.yesOtoku && !this.m_NetInfo.noOtoku) 
				this.m_NetInfo.noOtoku = true;
		}
		else {
			// お得パック加入者
			const otokuPlanName = this.m_MypageWizardMngr.getOtokuPlanName(this, this.m_ContractModel.planList);
			if (ObjectUtil.isNotNullOrUndefined(otokuPlanName)) {
				this.m_NetInfo.otokuFlg = true;
				this.m_NetInfo.otokuPlan = '※【' + otokuPlanName.dispnamep + '】割引適用)';	// 名称
			}
		}
	}

	/**
	 * 次へ処理
	 */
	private next() {
		// this.m_NetFlowModel.otherFlg = true;
		this.refreshModel();
		if (this.m_location.value !== 'その他')
			this.m_NetInfo.comment = null;
		if (!this.m_securityFlg.value)
			this.m_NetInfo.securityComment = null;
		this.m_MypageWizardMngr.goNext(this);
	}
}
