import { WprScopeMngr } from '../scope/WprScopeMngr';
import { WprModelMngr } from './WprModelMngr';
import { WprValueMap } from './WprValueMap';

/**
 * ValueMap設定
 */
export class WprValueMapSetter {
	// private 変数  ------------------------------------------------------------
	private m_ModelMngr: WprModelMngr		= null;			// モデル管理機能
	private m_ScopeMngr: WprScopeMngr		= null;			// モデル管理機能
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	public constructor(mmngr: WprModelMngr, smngr: WprScopeMngr) {
		this.m_ModelMngr = mmngr;
		this.m_ScopeMngr = smngr;
	}
	// --------------------------------------------------------------------------

	// public メソッド	----------------------------------------------------------
	/**
	 * ValueMap登録
	 * @param id ValueMapのID
	 * @param vm ValueMap
	 */
	public addValueMap(id: string, vm: WprValueMap): void {
		this.m_ModelMngr.addValueMap(id, vm);
	}

	/**
	 * ロード開始(サービスを呼び出してValueMapを登録する場合に使用する)
	 * @param id ValueMapのID
	 */
	public startLoad(id: string) {
		this.m_ModelMngr.addLoadWaitValueMap(id);
	}

	/**
	 * グローバルスコープモデル取得
	 * @param name グローバルモデル名
	 * @param getModel モデル取得メソッド
	 */
	public getGlobalScopeModel(name: string, getModel: (mode: object) => void) {
		this.m_ScopeMngr.addGlobalScopeModelMethod(name, getModel);
	}
	// --------------------------------------------------------------------------
}
