import { Card, Divider, Typography } from '@material-ui/core';
import { Grid } from '@mui/material';
import React, { ReactNode } from 'react';
import { WprBaseViewComponent } from '../../../../../wpr-framework/view/component/WprBaseViewComponent';
import { MenuBar } from '../../../common/menu_bar/MenuBar';
import { MvnoEntryProcessView } from './MvnoEntryProcessView';
import styles from './MvnoEntryProcess.scss';
import { WprRadio } from '../../../../../wpr-framework/component/controls/WprRadio';
import { MatRadio } from '../../../../../wpr-framework/component/controls/material/MatRadio';
import { CheckMarkRadio } from '../../../../controls/checkMarkRadio/CheckMarkRadio';
import { MvnoPlanSendData, CheckMarkRadioContent } from '../../../../controls/checkMarkRadio/CheckMarkRadioContent';
import { MatButton } from '../../../../../wpr-framework/component/controls/material/MatButton';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';

import step1 from '/shared/image/mvno/entry_process/step1.png';
import step2 from '/shared/image/mvno/entry_process/step2.png';
import step3 from '/shared/image/mvno/entry_process/step3.png';

import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import SystemSecurityUpdateGoodIcon from '@mui/icons-material/SystemSecurityUpdateGood';
import { WprAnchor } from '../../../../../wpr-framework/component/controls/WprAnchor';
import { ConvertViewUtil } from '../../../../common/util/ConvertViewUtil';
import { HelpIdentificationDocDialog } from '../../dialog/help_identification_doc_dialog/HelpIdentificationDocDialog';
import { MvnoHelpDialog } from '../../dialog/mvno_help_dialog/MvnoHelpDialog';
import { MvnoTmpGetDialog } from '../../dialog/mvno_tmp_get_dialog/MvnoTmpGetDialog';
import { WprContainer } from '../../../../../wpr-framework/component/controls/WprContainer';

/**
 * MVNO申込_手続き選択用コンポーネント
 */
export class MvnoEntryProcess extends WprBaseViewComponent {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: any) { super(new MvnoEntryProcessView(), props); }
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** MVNO申込_手続き選択ビュー情報 */
	public get viewInfo(): MvnoEntryProcessView { return this.view as MvnoEntryProcessView; }
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * スタイルオブジェクト取得
	*/
	public getStylesObject(): any { return styles; }

	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		return (
			<>
				{/* <div> */}
				<div className='pageCard'>
					<div className="pageHeader title">
						イッツコムモバイル お申込み
					</div>
					<Divider />

					<div className='pageContent'>
						{/* お申込みの流れ */}
						<div>
							<div>
								<div className='title_lineBottom'>
									<Typography>お申込みの流れ</Typography>
									{/* <Grid container alignItems={'flex-end'}>
										<Grid item xs={6}>
										</Grid>
										<Grid item xs={6} >
											<span>

											</span>
										</Grid>
									</Grid> */}
								</div>
								<div className='link_blue'>
									<WprAnchor name='skipToNext' view={this.view}>
										今すぐお申込みする方はこちら＞
									</WprAnchor>
								</div>

							</div>

							<div className='entryflow'>
								<div>
									{this.setEntryFlowContent('1', 'お申し込み手続き', <>
										<div className='itemTitle title_maru'>音声プラン</div>
										<div>通信料・端末料金を押さえたい方やガラケーからの乗り換えや、初めてスマートフォンをご利用される方におすすめ！</div>
										<br />
										<div className='itemTitle title_maru'>データプラン</div>
										<div>
											タブレット端末用に利用される方におすすめ！スマホ端末でも利用可能です。電話回線での音声通話機能が無いため、音声通話SIMよりお安く利用できます。
											<br />
											※ネット回線でのアプリ通話は可能
										</div>
									</>)}
								</div>
								<div>
									{this.setEntryFlowContent('2', '事前確認事項', <>
										<div>
											契約にあたっての事前確認事項についてご確認いただきます。<br />
											同意いただけない場合にはお申込みをすることはできません。
										</div>
									</>
									)}
								</div>
								<div>
									{this.setEntryFlowContent('3', '合計金額のご確認', null)}
								</div>
								<div>
									{this.setEntryFlowContent('4', 'WEB本人確認', <>
										<div>
											画面に従ってオンライン確認もしくはオンライン補助書類提出を行なってください。
										</div>
									</>
									)}
								</div>
								<div>
									{this.setEntryFlowContent('5', 'お申込み完了', <>
										<div>
											当社での本人確認書類の確認が取れ次第、端末・SIM等の発送準備をいたします。<br />
											土・日・祝日を含む場合、交通事情等により発送にお時間をいただくことがあります。
										</div>
									</>)}
								</div>
							</div>
						</div>

						{/* お申込み前のご確認 */}
						<div>
							<div className='title_lineBottom'>
								<Typography>お申込み前のご確認</Typography>
							</div>

							{/* 必要なもの */}
							<div className='box_blue1'>
								<div className='boxTitle'>
									<Typography>必要なもの</Typography>
								</div>
								<div className='boxContent'>
									<div className='needThings'>
										<div className='needItem'>
											<div className='itemTitle'>●ご本人さま確認書類</div>
											<div className='itemContent'>
												次のうちいずれかをご準備ください。
												<br />
												{/* 運転免許証、マイナンバーカード、健康保険証＋補助書類、在留カード＋補助書類 */}
												運転免許証、運転経歴証明書、パスポート(日本国旅券)、在留カード、マイナンバーカード
												<div>
													<WprAnchor name='aboutIdentifiction' view={this.view} className='link_underline'>本人確認書類について{'>'}</WprAnchor>

												</div>
											</div>
										</div>
										<div className='needItem'>
											<div className='itemTitle'>●MNP番号</div>
											<div className='itemComment'>※今の電話番号をそのまま使用する方</div>
											<div className='itemContent'>
												電話番号をそのまま利用するためにはMNP番号が必要です。
												<br />
												<div>
													<WprAnchor name='aboutMnp' view={this.view} className='link_underline'>MNP番号の取得方法はこちら{'>'}</WprAnchor>

												</div>
											</div>
										</div>
										<div className='needItem'>
											<div className='itemTitle'>●ご連絡のつく携帯電話番号</div>
											<div className='itemContent'>
												WEB本人確認の結果についてSMSでご連絡いたします。
												<br />
												SMSでご連絡のとれる携帯電話番号をご準備ください。
											</div>
										</div>
									</div>
								</div>
							</div>

							{/* 必要なもの */}
							<div className='box_blue1'>
								<div className='boxTitle'>
									<Typography>ご利用開始までの流れ</Typography>
								</div>
								<div className='boxContent'>
									<div className='flowToStart'>
										<Grid container textAlign={'center'} spacing={1}>
											{/* <Grid item md={0.5}></Grid> */}
											<Grid item md={3.5} xs={12}>
												<Grid container>
													<Grid item xs={3} md={12} className='flowImageParent'>
														<div className='flowTop'>
															<div className='flowStep'>STEP1</div>
															<div className='flowImage'>
																<img src={step1} />
															</div>

														</div>
													</Grid>
													<Grid item xs={9} md={12} className='flowDetailParent'>
														<div className='flowTitle'>
															お申し込み手続き
														</div>
														<div className='flowDetail'>
															下の「申込み手続きに進む」ボタン、または店舗にて<br />お申込み手続きをする
														</div>
													</Grid>
												</Grid>
											</Grid>
											<Grid item md={0.5} xs={3} className='flowArrow'>
												<WprContainer mode='画面サイズ.大' view={this.view}>▶</WprContainer>
												<WprContainer mode='画面サイズ.中' view={this.view}>▼</WprContainer>
											</Grid>
											<Grid item md={3.5} xs={12}>
												<Grid container>
													<Grid item xs={3} md={12} className='flowImageParent'>
														<div className='flowTop'>
															<div className='flowStep'>STEP2</div>
															<div className='flowImage'>
																<img src={step2} />
															</div>
														</div>
													</Grid>
													<Grid item xs={9} md={12} className='flowDetailParent'>
														<div className='flowTitle'>
															SIMカードが届く
														</div>
														<div className='flowDetail'>
															申し込み完了後、<br />
															3日～5日でSIMカードが<br />
															発送されます。
														</div>
													</Grid>
												</Grid>
											</Grid>
											<Grid item md={0.5} xs={3} className='flowArrow'>
												<WprContainer mode='画面サイズ.大' view={this.view}>▶</WprContainer>
												<WprContainer mode='画面サイズ.中' view={this.view}>▼</WprContainer>
											</Grid>
											<Grid item md={3.5} xs={12}>
												<Grid container>
													<Grid item xs={3} md={12} className='flowImageParent'>
														<div className='flowTop'>
															<div className='flowStep'>STEP3</div>
															<div className='flowImage'>
																<img src={step3} />
															</div>
														</div>
													</Grid>
													<Grid item xs={9} md={12} className='flowDetailParent'>
														<div className='flowTitle'>
															利用開始
														</div>
														<div className='flowDetail'>
															通信をするための設定<br />
															（APN設定）を行い、利用を開始する。（約5分）
														</div>
													</Grid>
												</Grid>
											</Grid>
											<Grid item md={0.5} xs={12}></Grid>
											{/* <Grid item md={0.5}></Grid> */}
										</Grid>
									</div>
								</div>
							</div>
						</div>

						{/*  */}
						<div className='pageFooter'>
							<Grid container alignItems='center' className='nextButtonCenter'>
								<Grid item xs={12} md={6}>
									<div className='nextButtonComment'>
										<div className='nextButtonCommentText'>
											申込内容はイメージできている方、<br />
											すぐに申込みを完了させたい方にオススメ
										</div>
									</div>
									<MatButton name='next' view={this.view} className='nextButton orange' >
										このままMyPageで<br />申し込み手続きに進む
										<NavigateNextIcon />
									</MatButton>
								</Grid>
							</Grid>
							<Grid container alignItems='center'>
								<Grid item xs={12} md={6}>
									<div className='nextButtonComment'>
										<div className='nextButtonCommentText'>
											相談しながら申込を進めたい方に<br />
											オススメ
										</div>
									</div>
									<MatButton name='toShop' view={this.view} className='nextButton white' >
										訪問＆店舗で<br />申し込み手続きをする
										<NavigateNextIcon />
									</MatButton>
								</Grid>
								<Grid item xs={12} md={6}>
									<div className='nextButtonComment'>
										<div className='nextButtonCommentText'>
											プランの詳細を知りたい<br/>
											・一緒に申込手続きをしたい方に<br/>
											オススメ
										</div>
									</div>
									<MatButton name='toOnline' view={this.view} className='nextButton white' >
										オンラインで相談する
										<NavigateNextIcon />
									</MatButton>
								</Grid>
							</Grid>
							<div>
								<MatButton name='back' view={this.view} className='backButton' >
									<NavigateBeforeIcon />
									戻る
								</MatButton>
							</div>
						</div>
					</div>
				</div>
				{/* </div> */}
				<HelpIdentificationDocDialog name='ヘルプ：本人確認書類について' view={this.view} className='dialog-medium' />
				<MvnoHelpDialog name='MVNOヘルプダイアログ' view={this.view} className='dialog-medium' />
				<MvnoTmpGetDialog name='MVNO一時保存データ取得ダイアログ' view={this.view} />
			</>
		);
	}
	// --------------------------------------------------------------------------

	/**
	 * お申込みの流れ　各内容
	 * @param num 
	 * @param title 
	 * @param content 
	 * @returns 
	 */
	private setEntryFlowContent(num: string, title: string, content: any): any {
		const lastClassName: string = num === '5' ? 'last' : '';
		// const contentList = ConvertViewUtil.convertIndention(content);

		return (
			<>
				<div className='entryFlowTitle'>
					<div>
						<span className='num'>
							<span className='numSpan'>{num}</span>
						</span>
					</div>
					<div style={{ marginLeft: '0.5rem' }}>
						<span>{title}</span>
					</div>
					{/* <Grid container alignItems="center">
						<Grid item xs={1} md={0.6}>
						</Grid>
						<Grid item xs={11} md={11.4}>
						</Grid>
					</Grid> */}
				</div>
				<div className={'entryFlowContentFrame ' + lastClassName}>
					<div className='entryFlowContent'>
						{content != null &&
							<div className='contentDetail'>
								{content}
							</div>
						}
						{/* <Grid container alignItems="center">
							<Grid item xs={1} md={0.6}>
								<div style={{ borderLeft: '1px dash black' }}></div>
							</Grid>
							<Grid item xs={11} md={11.4}>
							</Grid>
						</Grid> */}
					</div>
				</div>
			</>
		);

	}
}
