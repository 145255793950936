import React, { ReactNode } from 'react';
import { WprBaseDialogViewComponent } from '../../../../../wpr-framework/view/component/WprBaseDialogViewComponent';
import { UsageToukyuGraphDialogView } from './UsageToukyuGraphDialogView';
import styles from './UsageToukyuGraphDialog.scss';
import { Grid, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';
import { MatTabs } from '../../../../../wpr-framework/component/controls/material/MatTabs';
import { MatTabPanel } from '../../../../../wpr-framework/component/controls/material/MatTabPanel';
import { WprRowInfo } from '../../../../../wpr-framework/component/props/WprRowInfo';
import { MatTypography } from '../../../../../wpr-framework/component/controls/material/MatTypography';
import { WprContainer } from '../../../../../wpr-framework/component/controls/WprContainer';
import { MatTableContainer } from '../../../../../wpr-framework/component/controls/material/MatTableContainer';
import { MatTableSortLabel } from '../../../../../wpr-framework/component/controls/material/MatTableSortLabel';
import { MatTableRow } from '../../../../../wpr-framework/component/controls/material/MatTableRow';
import { UsageCplusGraphDialogViewComponent } from '../../../../controls/usageDetailsDialog/UsageCplusGraphDialogViewComponent';
import { MatCheckBox } from '../../../../../wpr-framework/component/controls/material/MatCheckBox';
import { MatSelect } from '../../../../../wpr-framework/component/controls/material/MatSelect';
import { MatTab } from '../../../../../wpr-framework/component/controls/material/MatTab';

/**
 * 東急でんき＆ガス利用明細グラフダイアログ用コンポーネント
 */
export class UsageToukyuGraphDialog extends WprBaseDialogViewComponent {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: any) { super(new UsageToukyuGraphDialogView(), props); }
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** 東急でんき＆ガス利用明細グラフダイアログビュー情報 */
	public get viewInfo(): UsageToukyuGraphDialogView	{ return this.view as UsageToukyuGraphDialogView;	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * スタイルオブジェクト取得
	*/
	public getStylesObject(): any { return styles; }
	
	/**
	 * リストコントロール定義登録
	*/
	protected onRegisterListRender(): void {
		this.addListRender('SelectedTokyuDetailList', null, this.onSelectedTokyuDetailListRender);
	}

	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		return (
			<>
				<div className='period'>
					<div className='periodHeader'>
						<Typography>期間を選択</Typography>
					</div>
					<Grid container>
						<Grid item md={5} xs={12}>
							<MatSelect name='fromMonthSelect' vm='usageDetailsTargerMonth' variant='standard' view={this.view}/>
						</Grid>
						<Grid item md={2} xs={12}>
							<Typography align='center' style={{marginTop: 'revert'}}>から</Typography>
						</Grid>
						<Grid item md={5} xs={12}>
							<MatSelect name='toMonthSelect' vm='usageDetailsTargerMonth' variant='standard' view={this.view} />
						</Grid>
					</Grid>
				</div>

				<Grid container>
					<Grid item xs={12}>
						<MatTabs mode='グラフ' indicatorColor='primary' textColor='primary' variant='scrollable' scrollButtons='auto' view={this.view} className='tabPanel'>
							<MatTab label='でんき' view={this.view}/>
							<MatTab label='ガス' view={this.view}/>
							<MatTab label='その他' view={this.view}/>
						</MatTabs>
						<MatTabPanel mode='東急グラフ' label='でんき' className='detailsTabPanel' view={this.view}>
							{this.onGraphRender()}
						</MatTabPanel>
						<MatTabPanel mode='東急グラフ' label='ガス' className='detailsTabPanel' view={this.view}>
							{this.onGraphRender()}
						</MatTabPanel>
						<MatTabPanel mode='東急グラフ' label='その他' className='detailsTabPanel' view={this.view}>
							{this.onGraphRender()}
						</MatTabPanel>
					</Grid>
				</Grid>
			</>
		);
	}
	// --------------------------------------------------------------------------

	// リスト描画  ---------------------------------------------------------------
	/**
	 * リスト名=SelectedTokyuDetailList
	 */
	public onSelectedTokyuDetailListRender(rowInfo: WprRowInfo): any {
		let background;
		if ((rowInfo.index % 2 === 0) ) {
			background = 'oddRow'
		} else{
			background = 'evenRow'
		}
		return (
			<>
				<MatTableRow view={this.view} row={rowInfo} className={background}>
					<TableCell>
						{/* 請求月 */}
						<MatTypography name='month' view={this.view} row={rowInfo}/>
					</TableCell>
					<WprContainer mode={'期間.表示'} view={this.view}>
						<TableCell>
							{/* 期間 */}
							<MatTypography name='fromMonth' view={this.view} row={rowInfo}/>
							<span>～</span>
							<MatTypography name='toMonth' view={this.view} row={rowInfo}/>
						</TableCell>
					</WprContainer>
					<WprContainer mode={'日数.表示'} view={this.view}>
						<TableCell>
							{/* 日数 */}
							<MatTypography name='day' view={this.view} row={rowInfo} />
							<span>日</span>
						</TableCell>
					</WprContainer>
					<TableCell>
						<WprContainer mode={'使用量.表示'} view={this.view}>
							{/* 使用量 */}
							<MatTypography name='consumption' view={this.view} row={rowInfo}/>
							<WprContainer mode={'単位.でんき'} view={this.view}>
								<Typography component='span'>kWh</Typography>
							</WprContainer>
							<WprContainer mode={'単位.ガス'} view={this.view}>
								<Typography component='span'>㎥</Typography>
							</WprContainer>
						</WprContainer>
						<WprContainer mode={'項目.表示'} view={this.view}>
							{/* 項目 */}
							<MatTypography name='item' view={this.view} row={rowInfo}/>
						</WprContainer>
					</TableCell>
					<TableCell>
						{/* 料金 */}
						<MatTypography name='taxIn' view={this.view} row={rowInfo}/>
						円
					</TableCell>
				</MatTableRow>
			</>
		);
	}
	// --------------------------------------------------------------------------
	/**
	 * グラフ部分の描画
	 * @returns 
	 */
	private onGraphRender(): any {
		return (
			<>
				<MatTableContainer listName='SelectedTokyuDetailList' view={this.view} noDataMessage='明細がありません。'>
					<Table className='graph'>
						<colgroup>
						</colgroup>
						<TableHead className='graphHeader'>
							<TableRow className='graphHeader'>
								<TableCell>
									<Typography>請求月</Typography>
								</TableCell>
								<WprContainer mode={'期間.表示'} view={this.view}>
									<TableCell>
										<Typography>期間</Typography>
									</TableCell>
								</WprContainer>
								<WprContainer mode={'日数.表示'} view={this.view}>
									<TableCell>
										<Typography>日数</Typography>
									</TableCell>
								</WprContainer>
								<TableCell>
									<WprContainer mode={'使用量.表示'} view={this.view}>
										<Typography>使用量</Typography>
									</WprContainer>
									<WprContainer mode={'項目.表示'} view={this.view}>
										<Typography>項目</Typography>
									</WprContainer>
								</TableCell>
								<TableCell>
									<Typography>料金</Typography>
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody className='graphBody'>
							{this.renderList('SelectedTokyuDetailList')}
						</TableBody>
					</Table>
				</MatTableContainer>
			</>
		);
	}
	// --------------------------------------------------------------------------

}
