import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Paper, Typography } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import HelpIcon from '@material-ui/icons/Help';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';
import React, { ReactNode } from 'react';
import Draggable from 'react-draggable';
import { IWprDialogEvents } from '../../view/component/WprBaseDialogViewComponent';
import { IWprControlEvents } from '../props/IWprControlEvents';
import { IWprMessageProps } from '../props/IWprMessageProps';
import { IWprMessageState } from '../props/IWprMessageState';
import { IWprMessage } from './IWprMessage';
import { WprBaseMessagePartsComponent } from './WprBaseMessagePartsComponent';

/**
 * メッセージ基本コンポーネント
 */
export class WprMessageComponent extends WprBaseMessagePartsComponent<IWprMessageProps, IWprMessageState> implements IWprMessage {
	// private 変数  ------------------------------------------------------------
	private m_OKEvents: IWprControlEvents<HTMLButtonElement> 		= {};		// OKボタンイベント
	private m_CancelEvents: IWprControlEvents<HTMLButtonElement> 	= {};		// キャンセルボタンイベント
	private m_CloseEvents: IWprDialogEvents							= {};		// クローズイベント
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	constructor(props: IWprMessageProps) {
		super(props);
		this.state = {
			open: false,
			message: '',
			type: 'info',
			okLabel: 'OK',
			cancelLabel: 'キャンセル',
			resultMethod: null
		};
	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * マウント通知
	 */
	public componentDidMount() {
		this.m_OKEvents.onClick = this.onOK.bind(this);
		this.m_CancelEvents.onClick = this.onCancel.bind(this);
		this.props.view.setMessageComponent(this);
		this.m_CloseEvents.onClose = this.onClose.bind(this);
	}

	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		const title = this.getTitle();
		let cancel = null;
		if (this.state.type === 'question')
			cancel = <Button name='wpr-message-cancel' variant='contained' color='primary' {...this.m_CancelEvents}>{this.state.cancelLabel}</Button>;
		return (
			<Dialog aria-labelledby='customized-dialog-title' open={this.state.open} PaperComponent={PaperComponent} scroll='paper'>
				<DialogTitle disableTypography id='draggable-dialog-title'>
					<Typography variant='subtitle1'>{title}</Typography>
				</DialogTitle>
				<DialogContent dividers>
					<div className='wpr-msg-line' style={{display: 'flex', alignItems: 'center'}}>{this.getMark()}<Typography variant='body2' component='span' className='wpr-msg-body' style={{marginLeft: 5}}>{this.state.message}</Typography></div>
				</DialogContent>
				<DialogActions>
					<Button name='wpr-message-ok' variant='contained' color='primary' {...this.m_OKEvents}>{this.state.okLabel}</Button>
					{cancel}
				</DialogActions>
			</Dialog>
		);
	}
	// --------------------------------------------------------------------------

	// イベント  -----------------------------------------------------------------
	/**
	 * OK処理
	 * @param event イベント 
	 */
	public onOK(event: React.MouseEvent<HTMLButtonElement>): void {
		this.addClickEventLog(event.target as HTMLButtonElement);
		this.close(true);
	 }

	/**
	 * キャンセル処理
	 * @param event イベント 
	 */
	public onCancel(event: React.MouseEvent<HTMLButtonElement>): void {
		this.addClickEventLog(event.target as HTMLButtonElement);
		this.close(false);
	}
	
	/**
	 * クローズイベント処理
	 * @param event 変更イベント 
	 */
	public onClose(): void {
		if (this.state.type === 'question')
			return;
		this.close(true);
	}
	// --------------------------------------------------------------------------

	// private メソッド  --------------------------------------------------------
	/**
	 * タイトル取得
	 * @returns タイトル
	 */
	private getTitle(): string {
		const type = this.state.type;
		if (type === 'question')
			return '確認';
		else if (type === 'info')
			return '確認';
		else if (type === 'warning')
			return '警告';
		else if (type === 'error')
			return 'エラー';
		return '';
	}
	
	/**
	 * マーク取得
	 * @returns マーク
	 */
	private getMark(): ReactNode {
		let mark = null;
		const type =this.state.type;
		if (type === 'question')
			mark = <HelpIcon color='primary' className='wpr-msg-icon'/>;
		else if (type === 'info')
			mark = <InfoIcon color='primary' className='wpr-msg-icon'/>;
		else if (type === 'warning')
			mark = <WarningIcon color='error' className='wpr-msg-icon'/>;
		else if (type === 'error')
			mark = <ErrorIcon color='error' className='wpr-msg-icon'/>;
		return (
			<>
				{mark}
			</>
		);
	}

	/**
	 * クローズ処理
	 * @param status ステータス
	 */
	private close(status: boolean): void {
		this.setState({
			open: false
		});
		if (this.state.resultMethod)
			this.state.resultMethod.bind(this.props.view)(status);
	}
	// --------------------------------------------------------------------------
	
	// IWprMessageの実装  --------------------------------------------------------
	/**
	 * 表示処理
	 */
	public show(type: string, message: string, resultMethod: (result: boolean) => void): void {
		this.setState({
			open: true,
			type: type,
			message: message,
			resultMethod: resultMethod
		});
	}
	// --------------------------------------------------------------------------
}

function PaperComponent(props: any) {
	return (
	<Draggable handle='#draggable-dialog-title' cancel={'[class*="MuiDialogContent-root"]'} bounds='parent'>
		<Paper {...props} />
	  </Draggable>
	);
}
