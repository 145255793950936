// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@charset \"UTF-8\";\n/*=================== 変数管理ファイル =================== */\n/* ------- 色 ------- */\n/* ------- width ------- */\n/*\r\n * 移設申込選択1画面用CSS\r\n */\n.RelocateSelect1-body .RelocateSelect1-noStyle {\n  font-size: 15px;\n  background-color: #E3E3E3;\n  width: fit-content;\n  margin: 0 auto;\n  padding: 3px;\n  font-weight: 500;\n  border-radius: 5px; }\n\n@media screen and (max-width: 440px) {\n  .RelocateSelect1-labelSize .wpr-checkbox-label .MuiFormControlLabel-label {\n    font-size: 12px; } }\n\n.RelocateSelect1-card {\n  /* タイトルコメント */ }\n  .RelocateSelect1-card .RelocateSelect1-title_comment {\n    font-style: normal;\n    font-size: 14px;\n    line-height: 140%;\n    margin-top: 0.8rem;\n    font-weight: 500;\n    color: #333; }\n    @media screen and (max-width: 700px) {\n      .RelocateSelect1-card .RelocateSelect1-title_comment {\n        font-style: normal;\n        font-weight: 700;\n        font-size: 18px;\n        line-height: 27px; } }\n    @media screen and (max-width: 450px) {\n      .RelocateSelect1-card .RelocateSelect1-title_comment {\n        font-size: 14px;\n        font-weight: 500;\n        line-height: 140%;\n        margin-top: 12px;\n        margin-left: 1px; } }\n", ""]);
// Exports
exports.locals = {
	"body": "RelocateSelect1-body",
	"noStyle": "RelocateSelect1-noStyle",
	"labelSize": "RelocateSelect1-labelSize",
	"card": "RelocateSelect1-card",
	"title_comment": "RelocateSelect1-title_comment"
};
module.exports = exports;
