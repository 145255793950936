import React from 'react';
import { WprReflectUtil } from '../../util/WprReflectUtil';
import { WprBaseEventLogComponent } from './WprBaseEventLogComponent';

/**
 * コントロール基本コンポーネント
 */
export abstract class WprBaseCssModuleComponent<T, U> extends WprBaseEventLogComponent<T, U> {
	// virtual メソッド  --------------------------------------------------------
	/**
	 * スタイルオブジェクト取得
	 * @returns スタイルオブジェクト
	 */
	public getStylesObject(): any {
		return null;
	}
	// --------------------------------------------------------------------------

	// public メソッド  ----------------------------------------------------------
	/**
	 * CSSモジュールで解決したクラス名取得
	 * @param clsName 元のクラス名 
	 * @returns CSSモジュールで解決したクラス名
	 */
	public getLocalClassName(clsName: string): string {
		const cssModlue = this.getStylesObject();
		if (clsName != null && this.getStylesObject() != null) {
			const list = new Array();
			let isLocal = false;
			clsName.split(' ').forEach(param => {
				if (param) {
					if (WprReflectUtil.checkProperty(cssModlue, param) === true) {
						list.push(WprReflectUtil.getProperty(cssModlue, param));
						isLocal = true;
					}
					else {
						list.push(param);
					}
				}
			});
			if (isLocal) {
				let clsName = '';
				list.forEach(param => {
					if (clsName.length > 0)
						clsName += ' ';
					clsName += param;
				});
				return clsName;
			}
		}
		return clsName;
	}
	// --------------------------------------------------------------------------

	// protected メソッド  -------------------------------------------------------
	/**
	 * 子要素のclassNameチェック
	 * @param rnode チェックする要素
	 * @param hissu 必ず子要素を変える場合true
	 * @returns classNameを書きかえた子要素（nullの場合、書換がなかった）
	 */
	protected checkChildClassName(rnode: any, hissu: boolean = false): any {
		const childList = new Array();
		let isChange = false;
		let idx = 1;
		for (const child of this.getChildList(rnode)) {
			if (child) {
				let clsName = this.getLocalClassName(this.getClassName(child));
				if (clsName != null) {
					let props = this.getNodeProps(child.props, clsName);
					let nc = this.checkChildClassName(child, true);
					if (!props['key'])
						props['key'] = 'wpr_key' + String(idx);
					let node = React.createElement(child.type, props, nc);
					childList.push(node);
					isChange = true;
				}
				else {
					let nc = this.checkChildClassName(child);
					if (nc != null) {
						let props = this.getNodeProps(child.props, null);
						if (!props['key'])
							props['key'] = 'wpr_key' + String(idx);
						let node = React.createElement(child.type, props, nc);
						childList.push(node);
						isChange = true;
					}
					else {
						childList.push(child);
					}
				}
			}
			idx++;
		}
		if (childList.length > 0 && (isChange || hissu === true))
			return childList;
		return null;
	}

	/**
	 * クラス名取得
	 * @param rnode 要素情報 
	 * @returns クラス名
	 */
	protected getClassName(rnode: any): string {
		if (rnode.props) {
			if (WprReflectUtil.checkProperty(rnode.props, 'className') === true)
				return rnode.props.className;
		}
		return null;
	}

	/**
	 * 要素のprops取得（childrenを取り除く）
	 * @param nodeProps 要素のprops
	 * @param clsName クラス名
	 * @returns 要素のprops
	 */
	protected getNodeProps(nodeProps: any, clsName: string): any {
		if (clsName != null) {
			let { children, className, ...props } = nodeProps;
			props['className'] = clsName;
			return props;
		}
		else {
			let { children, ...props } = nodeProps;
			return props;
		}
	}

	/**
	 * 子要素リスト取得
	 * @param rnode 要素情報
	 * @returns 子要素リスト
	 */
	protected getChildList(rnode: any): any[] {
		const clist = new Array();
		if (WprReflectUtil.checkProperty(rnode.props, 'children') === true) {
			React.Children.forEach(rnode.props.children, (child) => {
				clist.push(child);
			});
		}
		return clist;
	}
	// --------------------------------------------------------------------------
}
