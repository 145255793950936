import { WprErrorInfo } from './WprErrorInfo';

/**
 * エラー情報
 */
export class WprUserLogInfo {
	// private 変数  ------------------------------------------------------------
	private m_Date: Date				= null;		// 発生時刻
	private m_ClassName: string			= null;		// 発生クラス名
	private m_Message: string			= null;		// メッセージ
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** 発生時刻 */
	public get date(): Date 		{ return this.m_Date; 			}	set date(date: Date) { this.m_Date = date;	}
	/** 発生時刻文字列 */
	public get dateString(): string { return this.getDateString(); 	}
	/** 発生クラス名 */
	public get className(): string 	{ return this.m_ClassName; 		}
	/** メッセージ */
	public get message(): string 	{ return this.m_Message; 		}
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	public constructor(clsName: string, msg: string) {
		this.m_Date = new Date();
		this.m_ClassName = clsName;
		this.m_Message = msg;
	}
	// --------------------------------------------------------------------------

	// public メソッド	----------------------------------------------------------
	/**
	 * 発生時刻文字列
	 * @returns 発生時刻文字列
	 */
	public getDateString(): string {
		return WprErrorInfo.getDateString(this.m_Date);
	}

	/**
	 * ログメッセージ取得
	 * @returns ログメッセージ
	 */
	public getLogMessage(): string {
		return `${this.getDateString()} ${this.m_Message} : ${this.m_ClassName}`;
	}
	// --------------------------------------------------------------------------
}
