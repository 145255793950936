import { MypageBaseView } from '../../base/MypageBaseView';
import { WprModel } from '../../../../wpr-framework/view/deco/WprModel';
import { ConstrBaseModel } from '../../../models/mypage/Construction/ConstrBaseModel';
import { WprViewMode } from '../../../../wpr-framework/view/deco/WprViewMode';
import { ConfirmMode } from './mode/ConfirmMode';
import { WprDI_ScopeModel } from '../../../../wpr-framework/view/di/WprDI_ScopeModel';
import { MypageWizardMngr } from '../../wizard/models/MypageWizardMngr';
import { ConstrDateListModel } from '../../../models/mypage/Construction/ConstrDateListModel';
import { ProgressBarData } from '../../common/progress_bar/ProgressBarView';

/**
 * 工事_注意事項
 */
export class ContsrNotesView extends MypageBaseView {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() { super('ContsrNotesView'); }
	// --------------------------------------------------------------------------

	// モデル  ------------------------------------------------------------------
	@WprModel('ConstrBaseModel')
	private m_ConstrBaseModel: ConstrBaseModel	= null;	// 工事日基本情報モデル
	@WprDI_ScopeModel('MypageWizardMngr')
	private m_MypageWizardMngr: MypageWizardMngr			= null;		// 画面ウィザード管理クラス
	// --------------------------------------------------------------------------

	// サブビュー/モード  --------------------------------------------------------
	@WprViewMode
	private m_ConfirmMode = new ConfirmMode();	// 確認モード
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * コントロール定義設定
	 */
	public onRegisterConfig(): void {
		this.addConfig('confirmFlg', '確認フラグ', 'ConstrBaseModel');
	}

	/**
	 * アクション登録
	 */
	public onRegisterAction(): void {
		this.addAction('next', this.onNext);
		this.addAction('back', this.onBack);
	}

	/**
	 * ビュー表示通知
	 */
	public onShowView(): void {
		this.m_MypageWizardMngr.initView(this, (result) => {
			this.registAccessLog();
			this.setStorageItem('b_url', window.location.href)
			const sendData: ProgressBarData = new ProgressBarData();
			sendData.progressBarList = this.m_MypageWizardMngr.getProgressModelList(this);
			this.sendChildData('fP_ev_progressBarIntital', sendData);
			this.m_ConstrBaseModel = this.m_MypageWizardMngr.getCache().m_ConstrBaseModel;
			this.setViewData('sentenceData', this.getOldUrl() + 'Inquiry/Inquiry_entry/');
			this.refreshView();
		});
	}

	/**
	 * 値変更通知
	 * @param name 名前
	 * @param value 値
	 * @returns falseの場合、変更を取り消す
	 */

	public onChangeValue(name: string, value: any): boolean {
		this.refreshModel();
		this.updateMode();
		return true;
	}
	// --------------------------------------------------------------------------

	// アクション  ---------------------------------------------------------------
	/**
	 * 次へ         <NavigateNextIcon />
	 */
	public onNext(param: any): void {
		this.m_MypageWizardMngr.goNext(this);
	}

	/**
	 * <NavigateBeforeIcon />         戻る
	 */
	public onBack(param: any): void {
		this.m_ConstrBaseModel.confirmFlg = false;
		this.m_MypageWizardMngr.goBack(this);
	}
	// --------------------------------------------------------------------------
}
