// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@charset \"UTF-8\";\n/*\r\n * テレビ追加確認用CSS\r\n */\n.AddTvComfirm-value {\n  display: flex;\n  align-items: baseline; }\n  .AddTvComfirm-value .AddTvComfirm-itemSelect .MuiSelect-root {\n    width: auto !important;\n    height: auto !important;\n    background-color: #FFFFFF !important;\n    color: #484848 !important;\n    font-weight: 500 !important;\n    font-size: 14px;\n    padding: 0; }\n  .AddTvComfirm-value .AddTvComfirm-itemSelect svg {\n    display: none; }\n  .AddTvComfirm-value .AddTvComfirm-itemSelect fieldset {\n    border: 0px; }\n\n.AddTvComfirm-campaignArea {\n  margin: 1em 0;\n  border: 1px solid #15468b;\n  border-radius: 8px;\n  padding: 1rem;\n  background: #d9dcf3;\n  color: '#484848'; }\n  .AddTvComfirm-campaignArea .AddTvComfirm-campaignTitle {\n    font-weight: '700'; }\n  .AddTvComfirm-campaignArea .AddTvComfirm-campaignText {\n    font-size: 13px; }\n\n.AddTvComfirm-otokuName {\n  border-top: 1px solid #E1E4ED;\n  display: block;\n  padding: 0.8rem 1.2rem; }\n  .AddTvComfirm-otokuName .AddTvComfirm-MuiTypography-body1 {\n    font-size: 14px; }\n    @media screen and (max-width: 440px) {\n      .AddTvComfirm-otokuName .AddTvComfirm-MuiTypography-body1 {\n        font-size: 13px; } }\n", ""]);
// Exports
exports.locals = {
	"value": "AddTvComfirm-value",
	"itemSelect": "AddTvComfirm-itemSelect",
	"campaignArea": "AddTvComfirm-campaignArea",
	"campaignTitle": "AddTvComfirm-campaignTitle",
	"campaignText": "AddTvComfirm-campaignText",
	"otokuName": "AddTvComfirm-otokuName",
	"MuiTypography-body1": "AddTvComfirm-MuiTypography-body1"
};
module.exports = exports;
