import { WprBaseValidator } from './WprBaseValidator';

/**
 * 入力チェック付きバリデータ基本クラス
 */
export abstract class WprBaseInputCheckValidator extends WprBaseValidator {
	// override メソッド  --------------------------------------------------------
	/**
	 * 入力チェック処理
	 * @param value 入力値
	 * @return falseの場合、入力無効
	 */
	public checkInput(value: any, option?: any): boolean {
		return this.checkValidValue(value, option);
	}

	/**
	 * 入力チェック処理
	 * @returns falseの場合、入力チェックは行なわない
	 */
	public useInputCheck(): boolean {
		return true;
	}
	// --------------------------------------------------------------------------
}
