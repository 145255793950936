import { WizardModel } from '../../models/WizardModel';
import { MypageWizardFlow } from './MypageWizardFlow';
import { MypageBaseView } from '../base/MypageBaseView';
import { CommonCompleteView } from '../common/common_complete/CommonCompleteView';
import { GetViewInfoRequest } from '../../service/models/dto/mypagerenewal/GetViewInfoRequest';
import { MypageWizardMngr } from './models/MypageWizardMngr';
import { MGeneral } from '../../service/models/entity/MGeneral';
import { RegistInfoRequest } from '../../service/models/dto/mypagerenewal/RegistInfoRequest';
import MypageCodeTypeConstants from '../../common/MypageCodeTypeConstants';
import { CallForwardingSelectView } from '../Phone/call_forwarding_select/CallForwardingSelectView';
import { callForwardingModel } from '../../models/mypage/callForwarding/callForwardingModel';
import { MDisplay } from '../../service/models/entity/secondary/MDisplay';
import MypageDispdivcdConstants from '../../common/MypageDispdivcdConstants';
import { CallForwardingConfirmView } from '../Phone/call_forwarding_confirm/CallForwardingConfirmView';
import { InquiryMailInfo } from '../../service/models/apimodels/InquiryMailInfo';
import { MAIL_CONSTANTS } from '../../common/MyPageMailIdConstants';
import { ObjectUtil } from '../../common/util/ObjectUtil';
import { CallForwardingCompleteView } from '../Phone/call_forwarding_complete/CallForwardingCompleteView';

/**
 * ITSCOM HOME_追加ウィザードクラス
 */
export class CallForwardingWizardFlow extends MypageWizardFlow {
	// private 変数  ------------------------------------------------------------
	private m_DefaultWizardFlow: WizardModel[]			= null;		// 画面ウィザードリスト
	private m_StartViewName: string						= null;
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	public constructor() {
		super();
		this.m_StartViewName = new CallForwardingSelectView().name;

		// 遷移パターン１
		this.m_DefaultWizardFlow = new Array();
		this.m_DefaultWizardFlow.push({stepNum: 0, title: '契約選択', viewName: null, path: this.getOldUrl() + 'Srvlist/Continfo_list/'});
		this.m_DefaultWizardFlow.push({stepNum: 1, title: 'コース選択', viewName: this.m_StartViewName, path: 'call_forwarding_select'});
		this.m_DefaultWizardFlow.push({stepNum: 2, title: 'WEB本人確認', viewName: new CallForwardingConfirmView().name, path: 'call_forwarding_confirm'});
		// this.m_DefaultWizardFlow.push({stepNum: 3, title: '申込み完了', viewName: new CommonCompleteView().name, path: 'call_forwarding_complete'});
		this.m_DefaultWizardFlow.push({stepNum: 3, title: '申込み完了', viewName: new CallForwardingCompleteView().name, path: 'call_forwarding_complete'});
	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * 画面ウィザードフロー名取得
	 */
	public getWizardFlowName(viewName: string): string {
		if (viewName === this.m_StartViewName)
			return this.constructor.name;
		return null;
	}
	
	/**
	 * 画面ウィザードリスト取得：契約状態で切り替えたり、選択肢で切り替えたり
	 */
	public getWizardFlow(mngr: MypageWizardMngr): WizardModel[] {
		return this.m_DefaultWizardFlow;
	}
	
	/**
	 * 画面ウィザードAPI用モデル取得：契約状態で切り替えたり、選択肢で切り替えたり
	 */
	public getWizardApiModel(mngr: MypageWizardMngr, view: MypageBaseView): GetViewInfoRequest {
		const req = new GetViewInfoRequest();
		req.customerId = mngr.getParams().customerId;
		req.displayList = new Array();
		req.commonList = new Array();
		req.generalList = new Array();
		req.contractFlg = true;
		const general1 = new MGeneral();
		general1.codeTypeCd = MypageCodeTypeConstants.PHONE_OPTION;
		req.generalList.push(general1);
		// 契約ステータス
		req.displayList.push(new MDisplay(MypageDispdivcdConstants.ITSCOMHOME_SERVICESTATUS));
		// 端末の種類
		req.displayList.push(new MDisplay(MypageDispdivcdConstants.ITSCOMHOME_TERMINALTYPE));
		// 端末の形態
		req.displayList.push(new MDisplay(MypageDispdivcdConstants.ITSCOMHOME_TERMINALSTATUS));
		// -----------------------------------------------
		return req;
	}

	/**
	 * 画面ウィザードフローの中断判定：例：申し込みが完了していれば、完了済みの画面へ遷移
	 */
	public checkFlowBreak(mngr: MypageWizardMngr): string {
		let test: boolean = false;
		if (test) {
			return '/error';
		}
		return null;
	}

	/**
	 * 画面キャッシュの初期化データ取得
	 */
	public getInitCacheData(mngr: MypageWizardMngr): CallForwardingWizardModel {
		let cacheData = new CallForwardingWizardModel();
		cacheData.m_ContractInfoList = new Array();
		cacheData.m_CallOptionList = new Array();
		cacheData.m_CallForwardingInfo = new callForwardingModel();
		return cacheData;
	}

	/**
	 * 画面ウィザード完了APIモデル取得
	 */
	public getRegistApiModel(mngr: MypageWizardMngr, view: MypageBaseView): RegistInfoRequest{
		const req: RegistInfoRequest = new RegistInfoRequest();
		let cacheData: CallForwardingWizardModel = mngr.getCache();
		const mailconstants = MAIL_CONSTANTS.find (mail => {
			return mail.view === '着信転送_追加';
		});
		// お客様メール
		req.customerId = mngr.getParams().customerId;
		req.customerMailId = mailconstants.customerMail;
		// 業務部門メール、お問い合わせ登録
		// req.systemMailId = mailconstants.systemMail;
		req.inquiryId = mailconstants.inquiryMail;
		req.systemInfo = new InquiryMailInfo();
		req.systemInfo.contractId = cacheData.m_CallForwardingInfo.contractId;
		const optionList = cacheData.m_CallOptionList.filter(data => data.check)
		// if (optionList && optionList.length > 0) {
		// 	req.systemInfo.optionRentalList = new Array();
		// 	for (const option of optionList)
		// 		req.systemInfo.optionRentalList.push(option.optionName);
		// }
		if (optionList && optionList.length > 0) {
			for (const option of optionList) {
				if (ObjectUtil.isNullOrUndefined(req.systemInfo.optionName))
					req.systemInfo.optionName = option.optionName;
				else 
					req.systemInfo.optionName += '/r' + option.optionName;
			}
		}
		req.systemInfo.escalationFlg = true;
		return req;
	}
	// --------------------------------------------------------------------------
}
/**
 * 画面ウィザードAPI用モデル
 */
export class CallForwardingWizardModel {
	// public 変数	-----------------------------------------------------------
	public m_ContractInfoList:	callForwardingModel[]		= null;	// 契約情報リスト
	public m_CallOptionList:	callForwardingModel[]		= null;	// 着信転送オプションリスト
	public m_CallForwardingInfo: callForwardingModel		= null; // 着信転送画面情報
	// ------------------------------------------------------------------------
}

