import { IWprConfigModeOption } from '../inter/IWprConfigModeOption';
import { IWprModeClass } from '../inter/IWprModeClass';
import { WprBaseViewMode } from '../WprBaseViewMode';
import { WprBaseValueModeSetter } from './WprBaseValueModeSetter';

/**
 * モードクラス設定
 */
export class WprClassModeSetter extends WprBaseValueModeSetter<IWprModeClass> {
	// コンストラクタ  -----------------------------------------------------------
	public constructor(option: IWprConfigModeOption, viewMoode: WprBaseViewMode, id?: string) {
		super('class', option, viewMoode, id);
	}
	// --------------------------------------------------------------------------

	// override メソッド	-----------------------------------------------------
	/**
	 * 初期化処理
	 * @param option モード定義オプション
	 */
	public initialize(): void {
		this.initializeModeList(this.option.class);
	}

	/**
	 * コントロールステータス設定処理
	 * @param id コントロールID
	 * @param vinfo 値情報
	 */
	public setControlStatus(vinfo: IWprModeClass, id?: string): void {
		this.controlInfo.setModeClass(vinfo.class, id);
	}
	// --------------------------------------------------------------------------
}
