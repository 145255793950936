import { WprKeyValue } from '../../../../../wpr-framework/model/WprKeyValue';
import { WprControlInfo } from '../../../../../wpr-framework/view/control/WprControlInfo';
import { WprModel } from '../../../../../wpr-framework/view/deco/WprModel';
import { WprDI_Control } from '../../../../../wpr-framework/view/di/WprDI_Control';
import { WprBaseDialogViewInfo } from '../../../../../wpr-framework/view/dialog/WprBaseDialogViewInfo';
import { ConvertUtil } from '../../../../common/util/ConvertUtil';
import { FileUtil } from '../../../../common/util/FileUtil';
import { ObjectUtil } from '../../../../common/util/ObjectUtil';
import { GetUsagePDFRequest } from '../../../../service/models/dto/usagedetails/GetUsagePDFRequest';
import { UsageDetailsService } from '../../../../service/UsageDetailsService';
import { WprViewMode } from '../../../../../wpr-framework/view/deco/WprViewMode';
import { PDFDialogDisplayMode } from './mode/PDFDialogDisplayMode';

// ReceiveData
export class UsagePDFReceiveData {
	public customerId: string;								// お客様ID
	public customerName: string;							// お客様氏名
	public monthList: string[];								// 対象年月のリスト
	public selectedIdx: number;								// 選択月のインデックス
}
/**
 * 利用明細PDFダウンロードダイアログ
 */
export class UsagePDFDownloadDialogView extends WprBaseDialogViewInfo {
	// private変数  ------------------------------------------------------------------
	private m_targetMonthList: string[] = null;	// 対象年月リスト
	private m_customerId: string = null; // お客様ID
	private m_customerName: string = null; // お客様氏名
	// --------------------------------------------------------------------------

	// DI情報  ------------------------------------------------------------------
	@WprDI_Control
	private m_fromMonthSelect: WprControlInfo = null;	// PDF出力開始年月コンボボックス
	@WprDI_Control
	private m_toMonthSelect: WprControlInfo = null;	// PDF出力終了年月コンボボックス
	@WprDI_Control
	private m_listFlg: WprControlInfo = null;	// PDF出力終了年月コンボボックス
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	public constructor() { super('UsagePDFDownloadDialog'); }
	// --------------------------------------------------------------------------

	// モデル  ------------------------------------------------------------------
	@WprModel('fromMonth')
	private m_fromMonth: number = null;	// 出力開始月のインデックス
	@WprModel('toMonth')
	private m_toMonth: number = null;	// 出力終了月のインデックス
	@WprModel('displayMethod')
	private m_display: string = null;	// 表示方法
	private m_downloadMode: string = null;	// ダウンロードボタンのモード（imageの切替に使う）
	// --------------------------------------------------------------------------

	// サービス  ----------------------------------------------------------------
	private m_UsageDetailsService: UsageDetailsService = new UsageDetailsService();	// 利用明細サービス
	// --------------------------------------------------------------------------

	// サブビュー/モード  ---------------------------------------------------------
	@WprViewMode
	private m_PDFDialogDisplayMode = new PDFDialogDisplayMode();	// PDF表示モード
	// --------------------------------------------------------------------------

	// override プロパティ  ------------------------------------------------------
	/** ダイアログタイトル */
	public get title(): string { return 'PDFダウンロード'; }
	/** バリデーション */
	public get validation(): boolean { return false; }
	/** OKボタン使用フラグ */
	public get useOKButton(): boolean { return false; }
	/** Divider使用フラグ */
	public get useDividers(): boolean { return true; }
	/** フッタ使用フラグ */
	public get useFooter(): boolean { return false; }
	/** キャンセルボタン使用フラグ */
	public get useCancelButton(): boolean { return false; }

	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * コントロール定義設定
	 */
	public onRegisterConfig(): void {
		this.addConfig('fromMonthSelect', 'PDF出力開始月', 'fromMonth', { bindModel: true, hissu: true });
		this.addConfig('display', '表示方法', 'displayMethod', { bindModel: true });
		this.addConfig('listFlg', '一覧表示フラグ', null);
		this.addConfig('toMonthSelect', 'PDF出力終了月', 'toMonth', { bindModel: true, disabled: true });
	}

	/**
	 * アクション登録
	 */
	public onRegisterAction(): void {
		this.addAction('pdfDownload', this.onPdfDownload);
	}

	/**
	 * ダイアログ表示通知
	 * @param sendData 送信データ
	 */
	public onShowDialog(sendData: any) {
		const receiveData: UsagePDFReceiveData = sendData as UsagePDFReceiveData;

		this.m_customerId = receiveData.customerId;
		this.m_targetMonthList = receiveData.monthList;
		this.m_customerName = receiveData.customerName;
		// 年月vmを親から受け取った対象年月に再設定
		this.m_fromMonthSelect.resetValueMap();
		this.m_toMonthSelect.resetValueMap();
		// デフォルト：表示中の年月の詳細表示
		this.m_fromMonth = receiveData.selectedIdx;
		this.m_fromMonthSelect.value = receiveData.selectedIdx;
		this.m_toMonth = receiveData.selectedIdx;
		this.m_toMonthSelect.value = receiveData.selectedIdx;

		this.m_display = '詳細';

		this.setViewMode('toMonthList', '詳細');
		this.setViewMode('download', 'ダウンロード可');
		this.setViewData('downloadBtn', this.getDownloadMode());

		this.refreshView();
	}

	/**
	 * 値変更通知
	 * @param name 名前
	 * @param value 値
	 * @returns falseの場合、変更を取り消す
	 */
	public onChangeValue(name: string, value: any): boolean {
		// 期間選択フラグで終了月の活性を切替
		if (name === 'listFlg') {
			this.setViewMode('toMonthList', value ? '一覧' : '詳細');
			this.refreshModel('toMonthList');
		}
		if (name === 'display') {
			this.m_display = value;
		}
		if (this.getViewMode('download') !== 'ダウンロード可') {
			// ダウンロードが非活性の場合、再度ダウンロード可能な状態か判定
			if (this.m_display === '一覧') {
				if (this.m_fromMonthSelect.value < this.m_toMonthSelect.value) {
					this.setViewMode('download', 'ダウンロード可');
					this.refreshModel('download');
					this.setViewData('downloadBtn', this.getDownloadMode());
				}
			} else {
				// 詳細表示を選択した場合は活性化
				this.setViewMode('download', 'ダウンロード可');
				this.refreshModel('download');
				this.setViewData('downloadBtn', this.getDownloadMode());
			}
		}
		return true;
	}

	/**
	 * ValueMap再設定処理
	 * @param name コントロール名
	 * @param id ValueMapID
	 * @list ValueMapのKey-Valueリスト
	 * @returns 再設定したKey-Valueリスト
	 */
	public onResetValueMap(name: string, id: string, list: WprKeyValue[]): WprKeyValue[] {
		if (ObjectUtil.isNotNullOrEmptyArray(this.m_targetMonthList)) {
			list = [];
			this.m_targetMonthList.forEach((value, index) => {
				const monthLabel: string = ConvertUtil.convertYearMonthLabel(value);	// yyyy年MM月
				list.push(new WprKeyValue(index, monthLabel));
			});
		}
		return list;
	}
	// --------------------------------------------------------------------------

	// アクション  ---------------------------------------------------------------
	/**
	 * PDFダウンロード
	 */
	public onPdfDownload(param: any): void {
		let endIndex: number;
		let startIndex: number = this.m_fromMonthSelect.value;
		
		// 「から」チェックボックスの値
		let listFlg: boolean = this.m_listFlg.value;	
		// 「から」がチェックされている場合（終了月が入力されている場合）
		if (listFlg) {
			// 開始月＞終了月　の場合エラー（2021/12~2021/11：NG、2021/12~2021/12：OK）
			const start: number = this.m_fromMonthSelect.value;
			const end: number = this.m_toMonthSelect.value;
			if (start > end) {
				this.showErrorMessage('終了月は、開始月よりも後の年月を選択してください。');
				// ダウンロードボタンの非活性
				this.setViewMode('download', 'ダウンロード不可');
				this.refreshModel('download');
				this.setViewData('downloadBtn', this.getDownloadMode());
				return;
			}
			// 終了月インデックスの設定
			endIndex = this.m_toMonthSelect.value;	
		}
		// 「から」がチェックされていない場合（ひと月文のみの場合）
		else {
			// 終了月インデックスの設定（開始月分のみとなるようよう）
			endIndex = startIndex;	
		}

		const req: GetUsagePDFRequest = new GetUsagePDFRequest();
		req.customerId = this.m_customerId;
		req.monthList = this.setPdfTargetMonthList(startIndex, endIndex);
		req.customerName = this.m_customerName;
		// req.listFlag = (this.m_display === '一覧');	// 一覧出力の場合 true
		req.templateType = this.m_display;	// 一覧出力の場合 true

		this.m_UsageDetailsService.getPDFData(this, req, (result) => {
			if (result) {
				if (ObjectUtil.isNullOrUndefined(result.fileInfo.dataUrl)) {
					this.showErrorMessage('PDFのダウンロードに失敗しました。');
					return;
				}
				FileUtil.downloadFile(result.fileInfo);
			}
		})
	}

	private setPdfTargetMonthList(startIndex: number, endIndex: number): string[] {
		let monthList: string[] = new Array();

		// PDF出力対象となる年月をセット
		for (let idx = startIndex; idx <= endIndex; idx++) {
			monthList.push(this.m_targetMonthList[idx]);
		}
		return monthList;
	}

	/**
	 * ダウンロードボタンの活性or非活性を判定
	 * @returns 
	 */
	public getDownloadMode(): boolean {
		if (this.getViewMode('download') === 'ダウンロード可') {
			return true;
		}
		return false;
	}

	// --------------------------------------------------------------------------
}
