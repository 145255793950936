import { MypageBaseView } from '../../../base/MypageBaseView';
import { WprModel } from '../../../../../wpr-framework/view/deco/WprModel';
import { WprListData } from '../../../../../wpr-framework/view/deco/WprListData';
import { ProgressBarData } from '../../../common/progress_bar/ProgressBarView';
import { WprViewMode } from '../../../../../wpr-framework/view/deco/WprViewMode';
import { OptionListMode } from './mode/OptionListMode';
import { WprDI_ScopeModel } from '../../../../../wpr-framework/view/di/WprDI_ScopeModel';
import { MypageWizardMngr } from '../../../wizard/models/MypageWizardMngr';
import { ContractPlanModel } from '../../../../service/models/apimodels/ContractPlanModel';
import { ObjectUtil } from '../../../../common/util/ObjectUtil';
import { Contract } from '../../../../models/bss/contract/Contract';
import { Contract as OwnerContract } from '../../../../models/bss/customer/Contract';
import { MCommon } from '../../../../service/models/entity/primary/MCommon';
import { WprValidError } from '../../../../../wpr-framework/view/validation/WprValidError';
import MypageDispdivcdConstants from '../../../../common/MypageDispdivcdConstants';
import { ItscomHomeOptionListModel } from '../../../../models/mypage/smart/ItscomHomeOptionListModel';
import { BaseInfo } from '../../../../models/mypage/smart/BaseInfo';
import { ErrorModel } from '../../../../models/mypage/smart/ErrorModel';
import { ItscomHomeNewWizardModel } from '../../../wizard/ItscomHomeNewWizardFlow';

/**
 * ITSCOMHOME_オプション変更(コース選択)
 */
export class ItscomHomeReviseSelectView extends MypageBaseView {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() { super('ItscomHomeReviseSelectView'); }
	// --------------------------------------------------------------------------

	// モデル  ------------------------------------------------------------------
	@WprDI_ScopeModel('MypageWizardMngr')
	private m_MypageWizardMngr: MypageWizardMngr			= null;	// 画面ウィザード管理クラス
	@WprListData('OptionListModel')
	private m_OptionListModel: ItscomHomeOptionListModel[]	= null;	// ItscomHomeオプションモデル
	@WprModel('BaseInfo')
	private m_BaseInfo: BaseInfo	= null;	// 基本情報
	@WprListData('ErrorModel')
	private m_ErrorModelList: ErrorModel[]	= null;	// エラーモデル
	// --------------------------------------------------------------------------

	// メンバ変数  ---------------------------------------------------------------
	private m_ContractModel: ContractPlanModel				= null;	// 契約情報(全量)※不要？
	private m_Contract: Contract							= null;	// 契約情報(変更対象)
	private m_BuyOptionList: ItscomHomeOptionListModel[]	= null; // 購入可能オプションリスト(判定用)
	private m_SmartLigntUnit: MCommon[]						= null; // スマートライト数量単位(判定用)
	// --------------------------------------------------------------------------

	// サブビュー/モード  --------------------------------------------------------
	@WprViewMode
	private m_OptionListMode = new OptionListMode();	// オプションリストモード
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * コントロール定義設定
	 */
	public onRegisterConfig(): void {
		// 新規
		this.addConfig('course', 'コース名', 'BaseInfo');
		this.addConfig('contractStatus', '契約ステータス', 'BaseInfo');
		this.addConfig('terminalType', '端末タイプ', 'BaseInfo');
		this.addConfig('terminalForm', '端末形態', 'BaseInfo');
		this.addConfig('MacAddress', 'MACアドレス', 'BaseInfo');
		// オプション
		this.addListConfig('rentalUnit', '単位(レンタル)', 'OptionListModel');
		this.addListConfig('priceUnit', '価格単位(購入)', 'OptionListModel');
		this.addListConfig('buyUnit', '数量単位(購入)', 'OptionListModel');
		this.addListConfig('option', 'オプション', 'OptionListModel');
		this.addListConfig('rental', '月額レンタル料', 'OptionListModel', { converter: '3桁カンマ' });
		this.addListConfig('quantity1', '数量1', 'OptionListModel', { maxLength: 2 });
		this.addListConfig('price', '購入価格', 'OptionListModel', { converter: '3桁カンマ' });
		this.addListConfig('quantity2', '数量2', 'OptionListModel', { maxLength: 2 });
		// オプションモード制御
		this.addListConfig('ownerIhPrice', '購入金額(オーナーIH)', 'OptionListModel');
		this.addListConfig('otherPrice', '購入金額(オーナーIH以外)', 'OptionListModel');
		this.addListConfig('ownerIhRental', 'レンタル金額(オーナーIH)', 'OptionListModel');
		this.addListConfig('otherRental', 'レンタル金額(オーナーIH以外)', 'OptionListModel');
		this.addListConfig('buyableUnit', '数量あり', 'OptionListModel');
		this.addListConfig('notBuyableUnit', '数量なし', 'OptionListModel');
		this.addListConfig('errorMessage', 'エラーメッセージ', 'ErrorModel');
	}

	/**
	 * アクション登録
	 */
	public onRegisterAction(): void {
		this.addAction('next', this.onNext, true);
		this.addAction('back', this.onBack);
	}

	/**
	 * ビュー表示通知
	 */
	public onShowView(): void {
		this.m_MypageWizardMngr.initView(this, (result) => {
			this.registAccessLog();
			this.setStorageItem('b_url', window.location.href);
			const sendData: ProgressBarData = new ProgressBarData();
			sendData.progressBarList = this.m_MypageWizardMngr.getProgressModelList(this);
			this.sendChildData('fP_ev_progressBarIntital', sendData);
			const NewWizardModel = new ItscomHomeNewWizardModel();
			// オプションリストPC/SP判定
			NewWizardModel.setOptionDisplay(this);
			// 基本情報
			this.m_BaseInfo = this.m_MypageWizardMngr.getCache().m_BaseInfo;
			// 契約情報
			this.m_ContractModel = this.m_MypageWizardMngr.getViewInfo().contractModel;
			// 契約情報
			const contractId = this.m_MypageWizardMngr.getParams().contractId;
			if (ObjectUtil.isNotNullOrEmptyStr(contractId)) {
				this.m_Contract = this.m_MypageWizardMngr.getContract(this, contractId);
				this.setBaseInfo();
			}
			// オーナー契約
			const ownerContractList = this.m_MypageWizardMngr.getViewInfo().ownerContractList;
			if (ObjectUtil.isNotNullOrUndefined(ownerContractList))
				this.checkOwnerIh(ownerContractList);	// オーナーIH判定
			// 購入可能オプションリスト
			const buyOptionList = this.m_MypageWizardMngr.getMCommonList(this, 'srvsmart', 'buy_opt', null, null);
			this.setBuyOptionList(buyOptionList);	// 検索用モデルに詰替え
			// スマートライト数量単位
			this.setOptionList();	// 画面表示用リストに詰替え
			this.refreshView();
		});
	}

	/**
	 * リスト値変更通知
	 * @param name 名前
	 * @param listName リスト名
	 * @param row 行データ
	 * @param value 値
	 * @returns falseの場合、変更を取り消す
	 */
	public onChangeListValue(name: string, listName: string, row: any, value: any): boolean {
		if (listName === 'OptionListModel') 
			this.refreshListModel('OptionListModel');
		return true;
	}

	/**
	 * 入力チェック（独自のValidationを実施する場合に使用する）
	 *	エラー追加例：validError.addError('エラーメッセージ');				// 相関チェックなどのエラー
	 * 				 this.setControlError('control', 'error');			 // コントロールのエラー
	 * 				 validError.showErrorMessage.('エラーメッセージ');	   // エラーメッセージ表示
	 * 		※上記のエラーを追加した場合は、アクションは実行しません。
	 * 		※コントロールの値をチェックする場合は、refreshModelは使用しないでください。
	 * 		　コントロールの値をチェックする場合は、コントロールをDIしてvalueを使用してください。
	 * 		　コントロールが双方向のコンバータを使用している場合は、dataValueを使用してください。
	 * @param actionName アクション名
	 * @param validError バリデーションエラー情報
	 * @param row 行情報
	 */
	protected onValid(actionName: string, validError: WprValidError, row?: any): void {
		if (actionName === 'next') {
			this.m_ErrorModelList = new Array();
			const optionList = this.m_OptionListModel.filter(row => row.quantity1 || row.quantity2);
			// 未入力
			if (ObjectUtil.isNullOrUndefined(optionList) || optionList.length === 0) {
				const model = new ErrorModel();
				model.errorMessage = '台数を入力して下さい';
				this.m_ErrorModelList.push(model);
			}
			else {
				for (const option of this.m_OptionListModel) {
					// レンタル数量
					if (option.quantity1) {
						// 数字チェック
						let numFlg = false;
						option.quantity1.match(/^([0-9０-９]*|0)$/)? numFlg = true : numFlg = false;
						if (!numFlg) {
							const model = new ErrorModel();
							model.errorMessage = '「' + option.option + '」のレンタル数量は正数で入力して下さい';
							this.m_ErrorModelList.push(model);
						}
						else {
							// 全角→半角変換
							option.quantity1 = option.quantity1.replace(/[０-９]/g, function(s) {
								return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
							});
						}
					}
					// 購入数量
					if (option.quantity2) {
						let numFlg = false;
						option.quantity2.match(/^([0-9０-９]*|0)$/)? numFlg = true : numFlg = false;
						if (!numFlg) {
							const model = new ErrorModel();
							model.errorMessage = '「' + option.option + '」の購入数量は正数で入力して下さい';
							this.m_ErrorModelList.push(model);
						}
						else {
							// 全角→半角変換
							option.quantity2 = option.quantity2.replace(/[０-９]/g, function(s) {
								return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
							});
						}
					}
				}
			}
			if (this.m_ErrorModelList.length !== 0) {
				this.setViewMode('エラー','表示')
				this.setListData('ErrorModel', this.m_ErrorModelList);
				validError.addError('エラー');		
			}
			this.refreshListView('OptionListModel');
		}
	}
	// --------------------------------------------------------------------------

	// アクション  ---------------------------------------------------------------
	/**
	 * 申し込む
	 */
	public onNext(param: any): void {
		this.refreshModel();
		this.m_MypageWizardMngr.goNext(this);
	}

	/**
	 * 戻る
	 */
	public onBack(param: any): void {
		this.m_MypageWizardMngr.goBack(this);
	}
	// --------------------------------------------------------------------------
	// privateメソッド -----------------------------------------------------------
	/**
	 * 基本情報の設定
	 */
	private setBaseInfo() {
		// コース名
		const courseNameList = this.m_MypageWizardMngr.getMDisplayList(this, MypageDispdivcdConstants.ITSCOMHOME_CHENGE_SERVICE, '');
		for (const courseName of courseNameList) {
			if (this.m_Contract.serviceCD === courseName.namecd) {
				this.m_BaseInfo.course = courseName.dispnamep;
				break;
			}
		}
		// ステータス名
		const courseStatusList = this.m_MypageWizardMngr.getMDisplayList(this, MypageDispdivcdConstants.ITSCOMHOME_SERVICESTATUS, '');
		for (const statusName of courseStatusList) {
			if (this.m_Contract.statusCD === statusName.namecd) {
				this.m_BaseInfo.contractStatus = statusName.dispnamep;
				break;
			}
		}
		// 端末タイプ名
		const noEqu = this.m_MypageWizardMngr.getMCommonList(this, 'Add_net', 'equ', 'req', null);
		const noEquStb = this.m_MypageWizardMngr.getMCommonList(this, 'Add_net', 'equ', 'req2', null);
		if (ObjectUtil.isNullOrUndefined(this.m_Contract.equipment[0].equipmentTypeCD) ||
			 this.m_Contract.equipment[0].equipmentTypeCD === noEqu[0].commonValue || this.m_Contract.equipment[0].equipmentTypeCD === noEquStb[0].commonValue) {
			const terminalCommon = this.m_MypageWizardMngr.getMCommonList(this, 'Add_net', 'equ', 'rep', null);
			this.m_BaseInfo.equipmentTypeCD = terminalCommon[0].commonValue;	// 端末10000用の端末CDを保持
			this.m_BaseInfo.terminalType = '';
			this.m_BaseInfo.terminalForm = '';
		}
		else {
			this.m_BaseInfo.equipmentTypeCD = this.m_Contract.equipment[0].equipmentTypeCD;
			// 端末の種類
			const terminalTypeList = this.m_MypageWizardMngr.getMDisplayList(this, MypageDispdivcdConstants.ITSCOMHOME_TERMINALTYPE, '');
			for (const terminalType of terminalTypeList) {
				if (this.m_Contract.equipment[0].equipmentTypeCD === terminalType.namecd) {
					this.m_BaseInfo.terminalType = terminalType.dispnamep;
					break;
				}
			}
			// 端末形態
			const terminalFormList = this.m_MypageWizardMngr.getMDisplayList(this, MypageDispdivcdConstants.ITSCOMHOME_TERMINALSTATUS, '');
			for (const terminalForm of terminalFormList) {
				if (this.m_Contract.equipment[0].rentalFlag === terminalForm.namecd) {
					this.m_BaseInfo.terminalForm = terminalForm.dispnamep;
					break;
				}
			}
		}
		// MACアドレス
		this.m_BaseInfo.MacAddress = this.m_Contract.equipment[0].equipmentID;
		// インテリジェントホーム ※利用中の契約がないためグレーアウト
		// const intelliTerminalList = this.m_MypageWizardMngr.getMDisplayList(this, MypageDispdivcdConstants.ITSCOMHOME_INTELLIDEVICE, '');
		// let intelliFlg = false;
		// for (const terminalType of intelliTerminalList) { // 端末タイプ判定
		// 	if (this.m_Contract.equipment[0].equipmentTypeCD === terminalType.namecd) {
		// 		this.m_BaseInfo.terminalType = terminalType.dispnamep; // デバイス名変更
		// 		intelliFlg = true;	
		// 		break;
		// 	}
		// }
		// if (intelliFlg) {	// サービス判定
		// 	const intelliCourseList = this.m_MypageWizardMngr.getMDisplayList(this, MypageDispdivcdConstants.ITSCOMHOME_INTELLISERVICE, '');
		// 	for (const course of intelliCourseList) {
		// 		if (this.m_Contract.serviceCD === course.namecd) {
		// 			this.m_BaseInfo.course = course.dispnamep; // コース名変更
		// 			break;
		// 		}
		// 	}
		// }
		// 契約Id
		this.m_BaseInfo.contractId = this.m_Contract.contractID;
	}

	/**
	 * 判定用オプションリスト詰替え(codeとamountを1つのリストに格納)
	 * @param buyOptionList 
	 */
	private setBuyOptionList(buyOptionList: MCommon[]) {
		this.m_BuyOptionList = new Array;
		const model = new ItscomHomeOptionListModel();
		for (const list of buyOptionList) {
			// コードの場合
			if (list.category4 === 'code')
				model.childCd = list.commonValue;
			// 料金の場合
			if (list.category4 === 'amount')
				model.price = list.commonValue;
		}
		this.m_BuyOptionList.push(model);
	}

	/**
	 * 画面用オプションリスト詰替え
	 * 注釈だし分け
	 */
	private setOptionList() {
		this.m_OptionListModel = this.m_MypageWizardMngr.getCache().m_OptionListModel;
		if (this.m_OptionListModel.length === 0) {
			const optionServiceList = this.m_MypageWizardMngr.getServiceList(this, MypageDispdivcdConstants.ITSCOMHOME_OPTION);
			const optionInfoList = optionServiceList.filter(row => ObjectUtil.isNullOrUndefined(row.display.genecd));
			const smartLightUnit = this.m_SmartLigntUnit = this.m_MypageWizardMngr.getMCommonList(this, 'srvsmart', 'count_type_ko', null, null);
			for (const optionInfo of optionInfoList) {
				const model = new ItscomHomeOptionListModel();
				model.option = optionInfo.display.dispnamep;			
				model.rental = this.m_MypageWizardMngr.getTaxFee(this, String(optionInfo.service.payment[0].amount));	//月額レンタル料
				model.rentalUnit = '台';							// 数量単位(レンタル)
				model.price = '-';									// 購入金額
				model.buyFlag = false;								// 購入可能フラグ
				model.ownerIhFlag = false;							// オーナーIHフラグ
				model.childCd = optionInfo.service.serviceCD;
				// 購入可能判定
				for (const list of this.m_BuyOptionList) {
					if (list.childCd === optionInfo.service.serviceCD) {
						model.buyFlag = true;
						model.priceUnit = '円';
						model.price = this.m_MypageWizardMngr.getTaxFee(this, list.price);
						// スマートライトの場合、数量単位(レンタル)は「個」、数量単位(購入)は「個」設定
						if (optionInfo.service.serviceCD === smartLightUnit[0].commonValue) {
							model.buyUnit = '個';
							model.rentalUnit = '個';
						}
					}
				}
				// オーナー契約がIHの場合
				if (this.m_BaseInfo.ownerIhflag) {
					model.ownerIhFlag = true;
					this.setViewMode('注釈','OwnerIH');
				}
				this.m_OptionListModel.push(model);
			}
		}
		this.setListData('OptionListModel', this.m_OptionListModel);
	}

	/**
	 * オーナーIH判定
	 */
	private checkOwnerIh(ownerContractList: OwnerContract[]) {
		const ownerIhList = this.m_MypageWizardMngr.getMCommonList(this, 'IH', 'OWNER_SCD', '', '');
		this.m_BaseInfo.ownerIhflag = false;
		for (const owner of ownerContractList) {
			for (const ownerList of ownerIhList) {
				if (owner.serviceCD === ownerList.commonValue) {
					this.m_BaseInfo.ownerIhflag = true;
					this.setViewMode('オーナー契約','IH');
					break;
				}
			}
		}
		// this.refreshModel();
	}
	// --------------------------------------------------------------------------
}
