import React, { ReactNode } from 'react';
import { WprBaseViewComponent } from '../../../../../wpr-framework/view/component/WprBaseViewComponent';
import { ModIpopFinishView } from './ModIpopFinishView';

import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { Divider, Grid, Typography } from '@material-ui/core';
import { WprAnchor } from '../../../../../wpr-framework/component/controls/WprAnchor';
import { WprSpan } from '../../../../../wpr-framework/component/controls/WprSpan';
import { MatButton } from '../../../../../wpr-framework/component/controls/material/MatButton';
import { MatTypography } from '../../../../../wpr-framework/component/controls/material/MatTypography';
import { WprContainer } from '../../../../../wpr-framework/component/controls/WprContainer';
import questionnaireIcon from '/shared/image/common/button_questionnaire_on.png';
import styles from './ModIpopFinish.scss';
import importantIcon from '/shared/image/common/important_purple.png';

/**
 * IPアドレス種別（変更申し込み完了）用コンポーネント
 */
export class ModIpopFinish extends WprBaseViewComponent {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: any) { super(new ModIpopFinishView(), props); }
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** IPアドレス種別（変更申し込み完了）ビュー情報 */
	public get viewInfo(): ModIpopFinishView	{ return this.view as ModIpopFinishView;	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * スタイルオブジェクト取得
	*/
	public getStylesObject(): any { return styles; }

	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		return (
			<>
				<div className='pageCard'>
					<WprContainer  mode='ipaddress.グローバル' view={this.view}>
						<div className='main' style={{paddingTop:'63px'}}>
							<div className='title_center'>
								<Typography className='title_complete'>お申込みありがとうございます！</Typography>
							</div>
							<div className='title_center'>
								<MatTypography name={'user'} style={{ fontSize: '18px' ,fontWeight: '700', marginTop:'33px' , marginBottom:'20px'}} view={this.view}></MatTypography>
							</div>
							<div className='subText' style={{ textAlign: 'center', margin: '4px 0 48px 0' }}>
								iTSCOM NETをお申込みいただき、まことにありがとうございます。<br/>
								変更の申し込みを受け付けました。<br/><br/>
								連絡先メールアドレスに確認メールを送信しました。
							</div>
							<div className='finish-table'>
								<div className='finish-table-tb'>
									<div className='finish-table-tb-tr'>
										<div className='finish-table-tb-tr-th'>
											<div className='finish-table-tb-tr-th-top'>IPアドレス種別：</div>
											<div className='finish-table-tb-tr-th-bottom'>グローバルＩＰ</div>
										</div>
									</div>
								</div>
							</div>
							<div className='supportMsgArea' style = {{textAlign:'center'}}>
								<span style = {{color:'#6169B8', fontWeight:'700'}}>
									<img style={{ marginRight: '5px',verticalAlign: 'bottom',marginBottom: '3px'}} src={importantIcon}></img>
									グローバルIP、プライベートIP変更のタイミングについて
								</span>
								<br/>	
								<div style={{margin:'5px'}}>
									お申込みいただいた翌日に変更いたします。ただし、17:30以降のお申込みは翌々日の変更となります。
								</div>
							</div>
							<div className='questionnaire' style={{ marginTop: '60px' }}>
								<WprAnchor name='toQuestionnaire' view={this.view}  >
									<img src={questionnaireIcon} style={{ maxWidth: '100%' }} />
								</WprAnchor>
							</div>
						</div>
						<Divider />
						<div className='pageFooter footer-space'>
							<div>
								<MatButton name='back' view={this.view} className='completeButton backContInfoList' >
									<NavigateBeforeIcon />
									契約内容の確認・変更メニューへ
								</MatButton>
							</div>
						</div>
					</WprContainer>
					<WprContainer mode='ipaddress.プライベート' view={this.view}>
						<div className='main' style={{paddingTop:'63px'}}>
							<div className='title_center'>
								<Typography className='title_complete'>お申込みありがとうございます！</Typography>
							</div>
							<div className='title_center'>
								<MatTypography name={'user'} style={{ fontSize: '18px' ,fontWeight: '700' , marginTop:'33px' , marginBottom:'20px'}} view={this.view}></MatTypography>
							</div>
							<div className='subText' style={{ textAlign: 'center', margin: '4px 0 48px 0' }}>
								iTSCOM NETをお申込みいただき、誠にありがとうございます。<br/>
								変更の申し込みを受け付けました。<br/><br/>
								連絡先メールアドレスに確認メールを送信しました。
							</div>
							<div className='finish-table'>
								<div className='finish-table-tb'>
									<div className='finish-table-tb-tr'>
										<div className='finish-table-tb-tr-th'>
											<div className='finish-table-tb-tr-th-top'>IPアドレス種別：</div>
											<div className='finish-table-tb-tr-th-bottom'>プライベートＩＰ</div>
										</div>
									</div>
								</div>
							</div>
							<div className='supportMsgArea' style = {{textAlign:'center'}}>
								<span style = {{color:'#6169B8', fontWeight:'700'}}>
									<img style={{ marginRight: '5px',verticalAlign: 'bottom',marginBottom: '3px'}} src={importantIcon}></img>
									グローバルIP、プライベートIP変更のタイミングについて
								</span>
								<br/>
								<div style={{margin:'5px'}}>
									お申込みいただいた翌日に変更いたします。ただし、17:30以降のお申込みは翌日の変更となります。
								</div>	
							</div>
							<div className='questionnaire' style={{ marginTop: '60px' }}>
								<WprAnchor name='toQuestionnaire' view={this.view}  >
									<img src={questionnaireIcon} style={{ maxWidth: '100%' }} />
								</WprAnchor>
							</div>
						</div>
						<Divider />
						<div className='pageFooter footer-space'>
							<div>
								<MatButton name='back' view={this.view} className='completeButton backContInfoList' >
									<NavigateBeforeIcon />
									契約内容の確認・変更メニューへ
								</MatButton>
							</div>
						</div>
					</WprContainer>	
				</div>		
			</> 
		);
	}
	// --------------------------------------------------------------------------
}
