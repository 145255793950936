import { WprListData } from '../../../../../wpr-framework/view/deco/WprListData';
import { WprModel } from '../../../../../wpr-framework/view/deco/WprModel';
import { WprDI_ScopeModel } from '../../../../../wpr-framework/view/di/WprDI_ScopeModel';
import { TvBaseModel } from '../../../../models/mypage/tv/TvBaseModel';
import { TvOptionListModel } from '../../../../models/mypage/tv/TvOptionListModel';
import { TvWizardModel } from '../../../../models/mypage/tv/TvWizardModel';
import { MypageBaseView } from '../../../base/MypageBaseView';
import { ProgressBarData } from '../../../common/progress_bar/ProgressBarView';
import { MypageWizardMngr } from '../../../wizard/models/MypageWizardMngr';
import { ItscomTvService } from '../../../../service/ItscomTvService';
import { RegistTvOptionRequest } from '../../../../service/models/dto/mypagerenewal/RegistTvOptionRequest';
import { ObjectUtil } from '../../../../common/util/ObjectUtil';
import { Contract } from '../../../../models/bss/contract/Contract';
import MypageDispdivcdConstants from '../../../../common/MypageDispdivcdConstants';

/**
 * テレビオプション申込確認
 */
export class AddTvopConfirmView extends MypageBaseView {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() { super('AddTvopConfirmView'); }
	// --------------------------------------------------------------------------
	// モデル  ------------------------------------------------------------------
	@WprDI_ScopeModel('MypageWizardMngr')
	private m_MypageWizardMngr: MypageWizardMngr			= null;		// 画面ウィザード管理クラス
	@WprModel('TvOptionModel')
	private m_TvOptionModel: TvOptionListModel	= null;	// テレビ基本情報モデル
	// --------------------------------------------------------------------------

	// サービス  ----------------------------------------------------------------
	private m_ItscomTvService: ItscomTvService	= new ItscomTvService();	// ItscomTvサービス
	// --------------------------------------------------------------------------

	// private変数 --------------------------------------------------------------
	private m_Contract: Contract = null;
	private m_TvOptionListModel: TvOptionListModel[]	= null;	// テレビオプションリストモデル
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * コントロール定義設定
	 */
	public onRegisterConfig(): void {
		this.addConfig('option', 'オプション', 'TvOptionModel');
		this.addConfig('amount', '月額', 'TvOptionModel', { converter: '3桁カンマ' });
		this.addConfig('applyDate', '契約区分 ( 翌月、翌日 )', 'TvOptionModel');
		this.addConfig('channel', 'チャンネル', 'TvOptionModel');
	}

	/**
	 * アクション登録
	 */
	public onRegisterAction(): void {
		this.addAction('next', this.onNext);
		this.addAction('back', this.onBack);
	}
	/**
	 * ビュー表示通知
	 */
	public onShowView(): void {
		this.m_MypageWizardMngr.initView(this, (result) => {
			this.registAccessLog();
			this.setStorageItem('b_url', window.location.href);
			const sendData: ProgressBarData = new ProgressBarData();
			sendData.progressBarList = this.m_MypageWizardMngr.getProgressModelList(this);
			this.sendChildData('fP_ev_progressBarIntital', sendData);
			this.m_TvOptionListModel = this.m_MypageWizardMngr.getCache().m_TvOptionListModel;
			//契約情報取得
			const contractId = this.m_MypageWizardMngr.getParams().contractId;
			if (ObjectUtil.isNotNullOrEmptyStr(contractId)) {
				this.m_Contract = this.m_MypageWizardMngr.getContract(this, contractId);
			}
			const channel = this.m_MypageWizardMngr.getMDisplayList(this, MypageDispdivcdConstants.TV_OPTION_CHANNEL, '');
			for (const option of this.m_TvOptionListModel) {
				if (option.select) {
					if (option.detail.optionCD === '0102000173')
						this.setViewMode('宝塚', '表示');
					const channelNo = channel.filter(item => item.namecd === option.detail.optionCD);
					if (channelNo.length > 0)
						option.channel = '（' + channelNo[0].dispnamep + '）';
					this.m_TvOptionModel = option;
				}
			}
			this.refreshView();
		});
	}
	// --------------------------------------------------------------------------

	// アクション  ---------------------------------------------------------------
	/**
	 * この内容で申込む         <NavigateNextIcon />
	 */
	public onNext(param: any): void {
		const req: RegistTvOptionRequest = new RegistTvOptionRequest();
		req.contractId = this.m_Contract.contractID;
		req.optionCd = this.m_TvOptionModel.detail.optionCD;
		req.startDate = this.m_TvOptionModel.applyDate;
		this.m_ItscomTvService.registOption(this, req, (result) => {
			if (result) 
				this.m_MypageWizardMngr.goNext(this);
		});
	}

	/**
	 * <NavigateBeforeIcon />         戻る
	 */
	public onBack(param: any): void {
		this.m_MypageWizardMngr.goBack(this);
	}
	// --------------------------------------------------------------------------
}
