import React, { ReactNode } from 'react';
import { WprBaseViewComponent } from '../../../../wpr-framework/view/component/WprBaseViewComponent';
import { ConstrSearchView } from './ConstrSearchView';
import { Divider, Grid, Typography } from '@material-ui/core';
import { MatButton } from '../../../../wpr-framework/component/controls/material/MatButton';
import { MatTypography } from '../../../../wpr-framework/component/controls/material/MatTypography';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { MatDatePicker } from '../../../../wpr-framework/component/controls/material/MatDatePicker';
import { MatSelect } from '../../../../wpr-framework/component/controls/material/MatSelect';
import styles from './ConstrSearch.scss';
import { MatCheckBox } from '../../../../wpr-framework/component/controls/material/MatCheckBox';
import { WprContainer } from '../../../../wpr-framework/component/controls/WprContainer';
import { MatTextField } from '../../../../wpr-framework/component/controls/material/MatTextField';
import { WprRowInfo } from '../../../../wpr-framework/component/props/WprRowInfo';
import errorIcon from '../../../../../shared/image/common/icon_error.png'

/**
 * 工事日程検索画面用コンポーネント
 */
export class ConstrSearch extends WprBaseViewComponent {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: any) { super(new ConstrSearchView(), props); }
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** 工事日程検索画面ビュー情報 */
	public get viewInfo(): ConstrSearchView	{ return this.view as ConstrSearchView;	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * スタイルオブジェクト取得
	*/
	public getStylesObject(): any { return styles; }

	/**
	 * リストコントロール定義登録
	*/
	protected onRegisterListRender(): void {
		this.addListRender('ConstrDateListModel', null, this.onConstrDateListRender);
		this.addListRender('ErrorModel', null, this.onErrorModelRender);
	}


	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		return (
			<>
			<div className='pageCard page'>
				<div className='main card'>
					<div className='pageTitle'>
						<div className='title_left'>
							<MatTypography name='title' className='title_text' view={this.view}/>
						</div>
					</div>
					<WprContainer mode='エラー.表示' view={this.view}>
						<div className='errorArea'>
							<span className='parallel'>
								<img src={errorIcon} alt='' className='x_img'/>
								<div className='errorList'>
									{this.renderList('ErrorModel')}
								</div>
							</span>
						</div>
					</WprContainer>
					<div className='contentsArea'>
						<Grid container spacing={1}>
							<Grid item xs={12} md={6} className='itemTitle2'>
								希望日
								<div className='customerInput'>
									<MatDatePicker name='searchDate' view={this.view} placeholder='年/月/日' use='mobile' />
								</div>
							</Grid>
							<Grid item xs={12} md={6} className='itemTitle2'>
								時間帯
								<div className='customerInput'>
									<MatSelect name='searchTime' vm='工事時間帯' view={this.view} />
								</div>
							</Grid>
							<Grid item xs={12} md={8} className='itemTitle2'>
								希望曜日
								<div className='customerInput'>
									<MatCheckBox name='monday' label='月' view={this.view}/>
									<MatCheckBox name='tuesday' label='火' view={this.view}/>
									<MatCheckBox name='wednesday' label='水' view={this.view}/>
									<MatCheckBox name='thursday' label='木' view={this.view}/>
									<MatCheckBox name='friyday' label='金' view={this.view}/>
									<MatCheckBox name='saturday' label='土' view={this.view}/>
									<MatCheckBox name='sunday' label='日' view={this.view}/>
								</div>
							</Grid>
						</Grid>
						<ul className='ul_kome attention_fontSize'>
							<li>
								条件未設定の場合最短でご案内できる日時を検索致します。
							</li>
							<li>
								弊社担当が到着するおおよその時間帯目安となります。
							</li>
							空き状況によってご希望に沿えない場合もございますことご了承ください。
						</ul>
					</div>
					<WprContainer mode={'検索結果.表示'} view={this.view}>
						<div style={{textAlign: 'center'}}>
							<MatButton name='research' view={this.view} className='orangeButton orangeButton_parts' >
								条件に一致する工事可能日時を再検索　
								<NavigateNextIcon />
							</MatButton>
						</div>
						<div className='title_space'>
							<div className='itemTitle title_maru'>工事可能日時</div>
						</div>
						<div className='contentsArea'>
							<div className='listArea'>
								{this.renderList('ConstrDateListModel')}
							</div>
							<ul className='ul_kome attention_fontSize'>
								<li>
									上記よりご希望に合う時間を１つ選択ください。
								</li>
							</ul>
							<div className='feeTable inputArea' style={{marginTop: '30px'}}>	
								<Grid container className='feeRow'>
									<Grid item xs={12} md={4} className='feeTitle'>
										<div>前日連絡先<span className='txtRequired'>*</span></div>
									</Grid>
									<Grid item xs={12} md={8} className='feeVal courseValue' style={{padding: '15px'}}>
										<MatTextField name='beforeContact' className='itemUnit' placeholder='電話番号ハイフンなし' view={this.view} />
									</Grid>
								</Grid>	
								<Grid container className='feeRow'>
									<Grid item xs={12} md={4} className='feeTitle'>
										<div>当日連絡先<span className='txtRequired'>*</span></div>
									</Grid>
									<Grid item xs={12} md={8} className='feeVal courseValue' style={{padding: '15px'}}>
										<MatTextField name='sameDayContact' className='itemUnit' placeholder='電話番号ハイフンなし' view={this.view} />
									</Grid>
								</Grid>
								<Grid container className='feeRow'>
									<Grid item xs={12} md={4} className='feeTitle'>
										<div>当日立ち合い者<span className='txtRequired'>*</span></div>
									</Grid>
									<Grid item xs={12} md={8} className='feeVal courseValue' style={{padding: '15px'}}>
										<MatTextField name='name' className='itemUnit' placeholder='当日立ち合い者' view={this.view} />
									</Grid>
								</Grid>
							</div>
						</div>
						<div style={{textAlign: 'center'}}>
							<MatButton name='next' view={this.view} className='orangeButton orangeButton_parts' style={{marginBottom: '15px'}}>
								選択した工事可能日時で申込
								<NavigateNextIcon />
							</MatButton>
						</div>
						<div style={{textAlign: 'center'}}>
							<MatButton name='backTop' view={this.view} className='orangeButton orangeButton_parts' >
								希望に合う工事可能日時が<br/>ないためトップページに戻る
								<NavigateNextIcon />
							</MatButton>
						</div>
						<div className='pageFooter footer-space'>
							<MatButton name='back' view={this.view} className='backButton' style={{minWidth: '200px'}}>
								<NavigateBeforeIcon />
								戻る
							</MatButton>
						</div>
						
					</WprContainer>
				</div>
				<WprContainer mode={'検索結果.非表示'} view={this.view}>
					<Divider />
					<div style={{textAlign: 'center', margin: '20px 15px -30px'}}>
						<MatButton name='search' view={this.view} className='orangeButton orangeButton_parts' >
							条件に一致する工事可能日時を検索
							<NavigateNextIcon />
						</MatButton>
					</div>
					<div className='pageFooter footer-space'>
						<div>
							<MatButton name='back' view={this.view} className='backButton' style={{minWidth: '200px'}}>
								<NavigateBeforeIcon />
								戻る
							</MatButton>

						</div>
					</div>
				</WprContainer>
			</div>
			</>
		);
	}
	// --------------------------------------------------------------------------

	// リスト描画  ---------------------------------------------------------------
	/**
	 * リスト名=OptListModel
	 */
	public onConstrDateListRender(rowInfo: WprRowInfo): any {
		return (
			<div className='listParts'>
				<MatCheckBox name='select' row={rowInfo} view={this.view}/>
				<div style={{display: 'flex', flexWrap: 'wrap', alignItems: 'center'}}>
					<MatTypography name={'date'} row={rowInfo} view={this.view}></MatTypography>
					<span style={{fontWeight: '500', fontSize: '16px'}}>（</span>
					<MatTypography name={'dayOfWeek'} row={rowInfo} view={this.view} style={{fontWeight: '500'}}></MatTypography>
					<span style={{fontWeight: '500', fontSize: '16px'}}>）　</span>
					<MatTypography name={'time'} row={rowInfo} view={this.view} ></MatTypography>
					<Typography style={{fontWeight: '500'}}>の間到着</Typography>
				</div>
			</div>
		);
	}

	/**
	 * リスト名=ErrorModel
	 */
	public onErrorModelRender(rowInfo: WprRowInfo): any {
		return (
			<div>
				<MatTypography name='errorMessage' view={this.view} row={rowInfo}/>
			</div>
		);
	}
	// --------------------------------------------------------------------------
}
