import { MypageBaseView } from '../../base/MypageBaseView';
import { ProgressBarData } from '../../common/progress_bar/ProgressBarView';
import { WprModel } from '../../../../wpr-framework/view/deco/WprModel';
import { WprDI_ScopeModel } from '../../../../wpr-framework/view/di/WprDI_ScopeModel';
import { MypageWizardMngr } from '../../wizard/models/MypageWizardMngr';
import { WprDI_Control } from '../../../../wpr-framework/view/di/WprDI_Control';
import { WprControlInfo } from '../../../../wpr-framework/view/control/WprControlInfo';
import { ViewInfoModel } from '../../../service/models/apimodels/ViewInfoModel';
import { AccessAccountService } from '../../../service/AccessAccountService';
import { GetIsContractRadiusAccountPasswordRequest } from '../../../service/models/dto/mypagerenewal/GetIsContractRadiusAccountPasswordRequest';
import MypageDispdivcdConstants from '../../../common/MypageDispdivcdConstants';
import { AccesspassModel } from '../../../service/models/apimodels/AccesspassModel';
import { WprValidError } from '../../../../wpr-framework/view/validation/WprValidError';
import { WprListData } from '../../../../wpr-framework/view/deco/WprListData';
import { CryptRijndaelUtil } from '../../../common/util/CryptRijndaelUtil';
import { ErrorModel } from '../../../models/mypage/smart/ErrorModel';
/**
 * アクセスアカウントパスワード変更
 */
export class AddAccesspassSelectView extends MypageBaseView {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() { super('AddAccesspassSelectView'); }
	// --------------------------------------------------------------------------

	// DI情報  ------------------------------------------------------------------
	@WprDI_Control
	private m_password: WprControlInfo = null;	// 現在のパスワード
	@WprDI_Control
	private m_newPassword: WprControlInfo = null;	// 新パスワード
	@WprDI_Control
	private m_reEnterPassword: WprControlInfo = null;	// 再入力パスワード
	// --------------------------------------------------------------------------

	// モデル  ------------------------------------------------------------------
	@WprDI_ScopeModel('MypageWizardMngr')
	private m_MypageWizardMngr: MypageWizardMngr = null;	// 画面ウィザード用モデル
	@WprModel('AccesspassModel')
	private m_AccesspassModel: AccesspassModel = null;	// アクセスアカウントモデル
	@WprListData('ErrorModel')
	private m_ErrorModelList: ErrorModel[] = null;	// エラーモデル
	// --------------------------------------------------------------------------

	// サービス  ----------------------------------------------------------------
	private m_AccessAccountService: AccessAccountService = new AccessAccountService();	// アクセスアカウントサービス
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * コントロール定義設定
	 */
	public onRegisterConfig(): void {
		this.addConfig('course', 'コース', 'AccesspassModel');
		this.addConfig('radiusAccount', 'アクセスアカウント', 'AccesspassModel');
		this.addConfig('password', 'パスワード', 'AccesspassModel', { hissu: true, minLength: 5, maxLength: 16, validation: '文字種チェック' });
		this.addConfig('newPassword', '新パスワード', 'AccesspassModel', { hissu: true, minLength: 5, maxLength: 16, validation: '文字種チェック' });
		this.addConfig('reEnterPassword', '再入力パスワード', 'AccesspassModel', { hissu: true, minLength: 5, maxLength: 16, validation: '文字種チェック' });
		this.addListConfig('errorMessage', 'エラーメッセージ', 'ErrorModel');
	}

	/**
	 * アクション登録
 */
	public onRegisterAction(): void {
		this.addAction('next', this.onNext, true);
		this.addAction('back', this.onBack);
	}

	/**
	* バリデーションエラーメッセージ通知
	* @param name コントロール名
	* @param rowIndex 行数(Listの場合)
	* @param validatorName バリデーション名
	* @param msg エラーメッセージ
	* @returns エラーメッセージ（変更する場合に指定する、メッセージを使用しない場合は、nullを返す）
	*/
	public onInvalidMessage(name: string, rowIndex: number, validatorName: string, msg: string): string {
		if (name === 'password') {
			// 現在のパスワード入力チェック
			if (validatorName === '必須') {
				return '現在のパスワードを入力してください';
			}
			// 現在のパスワード文字数チェック
			else if (validatorName === '最小文字数') {
				return '現在のパスワードは5文字以上、16文字以内で入力してください';
			}
			// 現在のパスワード文字種チェック
			else if ((validatorName === '文字種チェック'))
				return '現在のパスワードはパスワードとして正しくありません';
		}
		else if (name === 'newPassword') {
			// 新しいパスワード入力チェック
			if (validatorName === '必須') {
				return '新しいパスワードを入力してください';
			}
			// 新しいパスワード文字数チェック
			else if (validatorName === '最小文字数') {
				return '新しいパスワードは5文字以上、16文字以内で入力してください';
			}
			// 新しいパスワード文字種チェック
			else if (validatorName === '文字種チェック')
				return '新しいパスワードはパスワードとして正しくありません';
		}
		else if (name === 'reEnterPassword') {
			// 新しいパスワード（再入力）入力チェック
			if (validatorName === '必須') {
				return '新しいパスワード（再入力）を入力してください';
			}
			// 新しいパスワード（再入力）文字数チェック
			else if (validatorName === '最小文字数') {
				return '新しいパスワード（再入力）は5文字以上、16文字以内で入力してください';
			}
			// 新しいパスワード（再入力）文字種チェック
			else if (validatorName === '文字種チェック')
				return '新しいパスワード（再入力）はパスワードとして正しくありません';
		}
		return msg;
	}
	/**
 * 入力チェック（独自のValidationを実施する場合に使用する）
 *	エラー追加例：validError.addError('エラーメッセージ');				// 相関チェックなどのエラー
 * 				 this.setControlError('control', 'error');			 // コントロールのエラー
 * 				 validError.showErrorMessage.('エラーメッセージ');	   // エラーメッセージ表示
 * 		※上記のエラーを追加した場合は、アクションは実行しません。
 * 		※コントロールの値をチェックする場合は、refreshModelは使用しないでください。
 * 		　コントロールの値をチェックする場合は、コントロールをDIしてvalueを使用してください。
 * 		　コントロールが双方向のコンバータを使用している場合は、dataValueを使用してください。
 * @param actionName アクション名
 * @param validError バリデーションエラー情報
 * @param row 行情報
 */
	//新パスワードと再入力パスワードが一致するか判定
	protected onValid(actionName: string, validError: WprValidError, row?: any): void {
		if (actionName === 'next') {
			if (!(this.m_newPassword.value === this.m_reEnterPassword.value)) {
				validError.addError('再入力された新しいパスワード（再入力）が新しいパスワードと一致しません')
			}
			if(validError.isError){
				this.m_password.value = null 
				this.m_reEnterPassword.value = null
				this.m_newPassword.value = null
			}
			this.setViewMode('オプション', 'エラーなし')
		}
	}

	/**
	 * ビュー表示通知
	*/
	public onShowView(): void {
		this.m_MypageWizardMngr.initView(this, (result) => {
			this.registAccessLog();
			this.setStorageItem('b_url', window.location.href)
			const sendData: ProgressBarData = new ProgressBarData();
			sendData.progressBarList = this.m_MypageWizardMngr.getProgressModelList(this);
			this.sendChildData('fP_ev_progressBarIntital', sendData);
			this.m_AccesspassModel = this.m_MypageWizardMngr.getCache().m_AccesspassInfo
			// ブラウザバック時入力したパスワードを複合化し保持
			if(this.m_AccesspassModel.password && this.m_AccesspassModel.newPassword && this.m_AccesspassModel.reEnterPassword){
				this.m_AccesspassModel.password = this.m_AccesspassModel.password = CryptRijndaelUtil.decodePW(this.m_AccesspassModel.crptkey, this.m_AccesspassModel.password);
				this.m_AccesspassModel.newPassword = this.m_AccesspassModel.newPassword = CryptRijndaelUtil.decodePW(this.m_AccesspassModel.crptkey, this.m_AccesspassModel.newPassword);
			}
			// コース名,アクセスアカウント取得
			if (!(this.m_AccesspassModel.course || this.m_AccesspassModel.radiusAccount)) {
				const viewInfo: ViewInfoModel = this.m_MypageWizardMngr.getViewInfo();
				this.getContractInfo(viewInfo);
			}
			this.refreshView();
		});
	}

	// --------------------------------------------------------------------------

	// アクション  ---------------------------------------------------------------

	public onNext(param: any): void {
		this.checkPassword();
	}

	/**
	 * 戻る
	*/
	public onBack(param: any): void {
		this.m_MypageWizardMngr.goBack(this);
	}
	// --------------------------------------------------------------------------
	// privateメソッド -----------------------------------------------------------
	/**
	 */
	private getContractInfo(viewInfo: ViewInfoModel): void {
		const serviceList = this.m_MypageWizardMngr.getMDisplayList(this, MypageDispdivcdConstants.ACCESSPASS_SERVICE, '');
		const contractList = viewInfo.contractModel.contractList;
		// 契約番号取得(5ケタ)
		const contractID = this.m_MypageWizardMngr.getParams().contractId
		this.m_AccesspassModel.contractID = this.m_MypageWizardMngr.getParams().customerId + contractID;
		// アクセスアカウント取得
		this.m_AccesspassModel.radiusAccount = decodeURIComponent(this.m_MypageWizardMngr.getParams().radiusAccount);
		// パスワード暗号化キー取得
		this.m_AccesspassModel.crptkey = viewInfo.commonList[0].commonList[0].commonValue;
		let data = null;
		// 契約情報から契約番号が一致するものを取得する
		for (const contract of contractList) {
			if (contract.contractID.slice(11) === contractID) {
				if (this.m_AccesspassModel.contractID && contractList) {
					data = serviceList.filter(value => value.namecd === contract.serviceCD);
					// コース名取得
					this.m_AccesspassModel.course = data[0].dispnamep;
					break;
				}
			}
		}
	}
	private checkPassword(): void {
		this.refreshModel();
		const req: GetIsContractRadiusAccountPasswordRequest = new GetIsContractRadiusAccountPasswordRequest();
		// パスワード暗号化
		this.m_AccesspassModel.password = CryptRijndaelUtil.encodePW(this.m_AccesspassModel.crptkey, this.m_AccesspassModel.password);
		this.m_AccesspassModel.newPassword= CryptRijndaelUtil.encodePW(this.m_AccesspassModel.crptkey, this.m_AccesspassModel.newPassword);
		req.contractID =  this.m_AccesspassModel.contractID
		req.radiusAccount = this.m_AccesspassModel.radiusAccount;
		req.password = this.m_AccesspassModel.password;
		// BSSシステム連携パスワードチェック
		this.m_AccessAccountService.isContractRadiusAccountPassword(this, req, (result) => {
			// アクセスアカウントが無効の場合
			if (result.resultCD === '3110000') {
				this.m_ErrorModelList = new Array();
				const model = new ErrorModel();
				model.errorMessage = 'アクセスアカウントが見つかりません';
				this.m_ErrorModelList.push(model);
			}
			// 現在のパスワードが不一致の場合
			else if (result.resultCD === '3110001') {
				this.m_ErrorModelList = new Array();
				const model = new ErrorModel();
				model.errorMessage = '現在のパスワードが正しくありません';
				this.m_ErrorModelList.push(model);
			}
			// 正常な場合画面遷移
			else if (result.resultCD === '0') {
				this.m_MypageWizardMngr.goNext(this);
			}
			else if (this.m_ErrorModelList.length !== 0) {
				this.setViewMode('オプション', 'エラー')
				this.setListData('ErrorModel', this.m_ErrorModelList);
			}
			this.refreshListView('ErrorModel');
		});
	}
}