import React, { ReactNode } from 'react';
import { WprReflectUtil } from '../../../util/WprReflectUtil';
import styles from './WprWaitComponent.css';

/**
 * 待機表示コンポーネントプロパティ情報
 */
interface IWprWaitProps {
	visibility?: boolean;		// 表示状態
}

/**
 * 待機表示コンポーネント
 */
export class WprWaitComponent extends React.Component<IWprWaitProps> {
	// プロパティ  ---------------------------------------------------------------
	/** 編集フラグ */
	public get isEdit(): boolean	{ return false; 	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * 描画
	 */
	public render(): ReactNode {
		if (!this.props.visibility)
			return <></>;
		return (
			<>
				<div className={this.getClass('popup')} id='js-popup'>
					<div className={this.getClass('popup-inner')}>
						<div className={this.getClass('wrapper')}>
							<div className={this.getClass('balls-guruguru')}>
								<span className={this.getClass('ball ball-1')}></span>
								<span className={this.getClass('ball ball-2')}></span>
								<span className={this.getClass('ball ball-3')}></span>
								<span className={this.getClass('ball ball-4')}></span>
								<span className={this.getClass('ball ball-5')}></span>
								<span className={this.getClass('ball ball-6')}></span>
								<span className={this.getClass('ball ball-7')}></span>
								<span className={this.getClass('ball ball-8')}></span>
							</div>
						</div>	
					</div>
					<div className={this.getClass('black-background')} id='js-black-bg'></div>
				</div>
			</>
		);
	}
	// --------------------------------------------------------------------------

	// private メソッド  ---------------------------------------------------------
	/**
	 * CSSModule解決済みクラス名
	 * @param clsName クラス名
	 * @returns CSSModule解決済みクラス名
	 */
	private getClass(clsName: string): string {
		if (clsName != null) {
			const list = new Array();
			let isLocal = false;
			clsName.split(' ').forEach(param => {
				if (param) {
					if (WprReflectUtil.checkProperty(styles, param) === true) {
						list.push(WprReflectUtil.getProperty(styles, param));
						isLocal = true;
					}
					else {
						list.push(param);
					}
				}
			});
			if (isLocal) {
				let clsName = '';
				list.forEach(param => {
					if (clsName.length > 0)
						clsName += ' ';
					clsName += param;
				});
				return clsName;
			}
		}
		return clsName;
	}
	// --------------------------------------------------------------------------
}