import { Address } from "cluster";
import { Payment } from "./Payment";
import { Equipment } from "./Equipment";
import { Infrastructure } from "./Infrastructure";
import { Suspend } from "./Suspend";
import { Option } from "./Option";
import { Phone } from "./Phone";
import { RadiusAccount } from "./RadiusAccount";

/**
 * BSS契約情報
 */
export class Contract {
	// public 変数	-----------------------------------------------------------
    public contractID: string;
    public parentContractID: string;
    public serviceCD: string;
    public serviceTypeCD: string;
    public statusCD: string;
    public startDate: string;
    public cancelDate: string;
    public roomName: string;
    public limitMonth: number;
    public payment: Payment[];
    public equipment: Equipment[];
    public infrastructure: Infrastructure[];
    public suspend: Suspend[];
    public option: Option[];
    public phone: Phone[];
    public radiusAccount: RadiusAccount[];
	// ------------------------------------------------------------------------
}