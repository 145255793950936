import { WprBaseObject } from '../WprBaseObject';
import { WprBaseViewCore } from '../view/WprBaseViewCore';
import { WprRowInfo } from '../component/props/WprRowInfo';

/**
 * アクション情報
 */
export class WprActionInfo extends WprBaseObject {
	// private 変数  ------------------------------------------------------------
	private m_Name: string							= null;		// アクション名称
	private m_IsValid: boolean						= null;		// バリデーションフラグ
	private m_IsChildValid: boolean					= null;		// 子ビューバリデーションフラグ
	private m_IsEvent: boolean						= null;		// イベントフラグ
	private m_IsBlur: boolean						= false;	// ロストフォーカスイベント
	private m_ViewCore: WprBaseViewCore				= null;		// ビュー情報
	private m_ActionMethod: (param: any) => void	= null;		// コールバックメソッド
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** アクション名称 */
	public get name(): string 				{ return this.m_Name;			}
	/** ビュー情報 */
	public get viewCore(): WprBaseViewCore 	{ return this.m_ViewCore;		}
	/** バリデーションフラグ */
	public get isValid(): boolean 			{ return this.m_IsValid;		}
	/** 子ビューバリデーションフラグ */
	public get isChildValid(): boolean 		{ return this.m_IsChildValid;	}
	/** イベントフラグ */
	public get isEvent(): boolean 			{ return this.m_IsEvent;		}
	/** リストアクションフラグ */
	public get isList(): boolean 			{ return false;					}
	/** リストアクションフラグ */
	public get isBlur(): boolean 			{ return this.m_IsBlur;			}	public set isBlur(blur: boolean) { this.m_IsBlur = blur;	}
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	public constructor(name: string, viewCore: WprBaseViewCore, actionMethod: (param: any) => void, isValid: boolean, isChildValid: boolean, isEvent: boolean) {
		super();
		this.m_Name = name;
		this.m_ViewCore = viewCore;
		this.m_IsValid = isValid;
		this.m_IsChildValid = isChildValid;
		this.m_IsEvent = isEvent;
		this.m_ActionMethod = actionMethod;
	}
	// --------------------------------------------------------------------------

	// public メソッド  ----------------------------------------------------------
	/**
	 * アクション実行処理
	 * @param isEditCheck 編集チェックフラグ
	 * @param event イベント情報
	 * @param item アイテム
	 * @returns falseの場合、バリデーションエラー
	 */
	public execute(isEditCheck: boolean, event: any, item?: WprRowInfo): boolean {
		if (this.m_IsBlur === true && this.FW.view.isStopBlurEvent === true)
			return;
		if (isEditCheck === true && this.m_IsBlur === false) {
			if (this.FW.edit.checkEdit(this.m_Name, this.viewCore.objectName, () => {
					this.execute(false, event, item);
				}) === false) {
				return true; 
			}
		}
		this.addInfoLog(`アクション実行 開始[${this.m_Name}][${this.m_ViewCore.objectName}]`);
		if (this.m_IsBlur === true) {
			const cinfo = this.m_ViewCore.getControlInfo(this.m_Name);
//			if (cinfo != null && cinfo.value != null) {		// nullも許容する
			if (cinfo != null) {
				try {
					const val = this.m_ViewCore.onBlur(this.m_Name, cinfo.value, cinfo.isBlurChange);
					if (val !== undefined && cinfo.value !== val)
						cinfo.value = val;
				}
				catch (ex) {
					this.addErrorLog(`Blurアクションの実行中に例外が発生しました。[${this.m_Name}]`, ex);
				}
			}
		}
		if (this.m_IsValid === true) {
			this.m_ViewCore.customValidErrorCount = 0;
			const isView = (this.m_ViewCore.viewInfo === this.m_ViewCore);
			let sts = this.m_ViewCore.checkValidate(this.m_Name, isView, this.m_IsBlur, false);
			if (this.isChildValid === true) {
				if (this.m_ViewCore.checkChildValidate(this.m_Name, isView, this.m_IsBlur, false) === false)
					sts = false;
			}
			if (sts === false || this.m_ViewCore.customValidErrorCount > 0) {
				this.addInfoLog(`入力チェックエラー[${this.m_Name}][${this.m_ViewCore.objectName}]`);
				this.m_ViewCore.updateMode();
				return false;
			}
		}
		this.FW.view.setScopeUpdateProperty();
		if (this.m_ActionMethod) {
			try {
				if (this.m_IsEvent === false)
					this.m_ActionMethod.bind(this.m_ViewCore)(this.m_Name);
				else
					this.m_ActionMethod.bind(this.m_ViewCore)(event);
			}
			catch (ex) {
				this.addErrorLog(`アクションの実行中に例外が発生しました。[${this.m_Name}]`, ex);
			}
		}
		this.m_ViewCore.updateMode();
		this.m_ViewCore.viewInfo.checkScopeModelValue();
		this.addInfoLog(`アクション実行 終了[${this.m_Name}][${this.m_ViewCore.objectName}]`);
		return true;
	}
	// --------------------------------------------------------------------------
}
