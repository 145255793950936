import React, { ReactNode } from 'react';
import { WprBaseViewComponent } from '../../../../wpr-framework/view/component/WprBaseViewComponent';
import { AccesspassPrintView } from './AccesspassPrintView';
import { Typography, Grid } from '@material-ui/core';
import { MatButton } from '../../../../wpr-framework/component/controls/material/MatButton';
import { MatTypography } from '../../../../wpr-framework/component/controls/material/MatTypography';
import itscomIcon from '/shared/image/common/itscomMyp_icon_blue.png';
import CancelIcon from '@mui/icons-material/Cancel';
import netIcon from '/shared/image/common/logo_iTSCOMNET.png';
import styles from './AccesspassPrint.scss';

/**
 * アクセスアカウントパスワード印刷用コンポーネント
 */
export class AccesspassPrint extends WprBaseViewComponent {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: any) { super(new AccesspassPrintView(), props); }
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** アクセスアカウントパスワード印刷ビュー情報 */
	public get viewInfo(): AccesspassPrintView { return this.view as AccesspassPrintView; }
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * スタイルオブジェクト取得
	*/
	public getStylesObject(): any { return styles; }

	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		return (
			<>
				<div>
					<div className='icon-hearder'>
						<img src={itscomIcon} style={{ maxWidth: '57%' }} />
					</div>
					<div className='card'>
						<div className='inContractArea'>
							<div className='sub-logo'>
								<img src={netIcon} alt="iTSCOM NET"></img>
							</div>
						</div>
						<div className='main'>
							<div className='pageTitle'>
								<div className='title_left'>
									<MatTypography name='title' className='title_text' view={this.view} />
									<Typography className='title_comment'>あなたの登録情報です。大切に保管してください。</Typography>
								</div>
							</div>
							<div className='gray'>
								<Grid container>
									<Grid item xs={12} md={4} className='grid-print-4'>
										<div>コース</div>
									</Grid>
									<Grid item xs={12} md={8} style={{ color: 'black' }} className='grid-print-8'>
										<MatTypography name={'course'} view={this.view} className='itemUnit bold-black'></MatTypography>
									</Grid>
								</Grid>
								<Grid container>
									<Grid item xs={12} md={4} className='grid-print-4'>
										<div>アクセスアカウント</div>
									</Grid>
									<Grid item xs={12} md={8} style={{ color: 'black' }} className='grid-print-8'>
										<MatTypography name={'radiusAccount'} view={this.view} className='itemUnit bold-black'></MatTypography>
									</Grid>
								</Grid>
								<Grid container>
									<Grid item xs={12} md={4} className='grid-print-4'>
										<div>パスワード</div>
									</Grid>
									<Grid item xs={12} md={8} style={{ color: 'black' }} className='grid-print-8'>
										<MatTypography name={'newPassword'} view={this.view} className='itemUnit bold-black'></MatTypography>
									</Grid>
								</Grid>
								<Grid container>
									<Grid item xs={12} md={4} className='grid-print-4'>
										<div>変更日</div>
									</Grid>
									<Grid item xs={12} md={8} style={{ color: 'black' }} className='grid-print-8'>
										<MatTypography name={'date'} view={this.view} className='itemUnit bold-black'></MatTypography>
									</Grid>
								</Grid>
							</div>
						</div>
						<div className='pageFooter footer-space printButton'>
							<div>
								<MatButton name='print' view={this.view} className='orangeButton orangeButton_parts' >
									印刷
								</MatButton>
							</div>
							<div>
								<MatButton name='close' view={this.view} className='backButton' >
									<CancelIcon />
									閉じる
								</MatButton>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
	// --------------------------------------------------------------------------
}
