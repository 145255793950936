import { WprBaseViewMultiModeInfo } from '../../../../../../wpr-framework/mode/WprBaseViewMultiMode';
import { WprMultiMode } from '../../../../../../wpr-framework/mode/WprMultiMode';
import { AddNetSettingView } from '../AddNetSettingView';
import { WprDI_Object } from '../../../../../../wpr-framework/view/di/WprDI_Object';
import { WprDI_Control } from '../../../../../../wpr-framework/view/di/WprDI_Control';
import { WprControlInfo } from '../../../../../../wpr-framework/view/control/WprControlInfo';

/**
 * その他モード
 */
export class OtherLocationMode extends WprBaseViewMultiModeInfo<AddNetSettingView> {
	// DI情報  ------------------------------------------------------------------
	@WprDI_Control
	private m_location: WprControlInfo	= null;	// 場所
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	public constructor() {
		super('OtherLocationMode');
	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * モードリスト取得（この画面モードで使用する全てのモードを定義する）
	 * @param list モードリスト
	 */
	public useModeList(list: string[]): void {
		list.push('フリー入力表示');
		list.push('フリー入力非表示');
	}

	/**
	 * フィールド定義を登録処理
	 */
	public onRegisterControl(): void {
		this.addControl('comment', {
			display: [ { mode: 'フリー入力表示', value: true }, { mode: 'フリー入力非表示', value: false } ]
		});
	}

	/**
	 * 画面の状態をチェックしてモードを変更する
	 * @param curMode 現在のモード
	 * @param newMode 新しいモード
	 */
	public onCheckMultiMode(curMode: WprMultiMode, newMode: WprMultiMode): void {
		if (this.m_location.value === 'その他')
			newMode.addMode('フリー入力表示');
		else
			newMode.addMode('フリー入力非表示');
	}
	// --------------------------------------------------------------------------
}
