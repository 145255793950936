
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { Typography, Grid, Table, TableHead, TableRow, TableCell, TableBody, Divider } from '@material-ui/core';
import { ReactNode } from 'react';
import { AddOthernetSelectView } from './AddOthernetSelectView';
import netIcon from '/shared/image/common/logo_iTSCOMNET.png';
import nextIcon from '/shared/image/common/next_icon_orange.png';
import important from '/shared/image/common/important_purple.png';
import { WprContainer } from '../../../../../wpr-framework/component/controls/WprContainer';
import { MatButton } from '../../../../../wpr-framework/component/controls/material/MatButton';
import { MatCheckBox } from '../../../../../wpr-framework/component/controls/material/MatCheckBox';
import { MatTableContainer } from '../../../../../wpr-framework/component/controls/material/MatTableContainer';
import { MatTableRow } from '../../../../../wpr-framework/component/controls/material/MatTableRow';
import { MatTypography } from '../../../../../wpr-framework/component/controls/material/MatTypography';
import { WprRowInfo } from '../../../../../wpr-framework/component/props/WprRowInfo';
import { WprBaseViewComponent } from '../../../../../wpr-framework/view/component/WprBaseViewComponent';
import { ProgressBar } from '../../../common/progress_bar/ProgressBar';

/**
 * 追加その他サービス選択用コンポーネント
 */
export class AddOthernetSelect extends WprBaseViewComponent {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: any) { super(new AddOthernetSelectView(), props); }
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** 追加その他サービス選択ビュー情報 */
	public get viewInfo(): AddOthernetSelectView	{ return this.view as AddOthernetSelectView;	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * リストコントロール定義登録
	*/
	protected onRegisterListRender(): void {
		this.addListRender('OtherCourseListModel', 'PC', this.onOtherCourseListPcRender);
		this.addListRender('OtherCourseListModel', 'SP', this.onOtherCourseListSpRender);
	}

	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		return (
			<>
			<div className='pageCard'>
				<div className="pageHeader stepFlow">
					<ProgressBar name='progressBar' view={this.view} />
				</div>
				<div className='sub-logo2'>
					<img src={netIcon} />
				</div>
				<div className='main net'>
					<div className='pageTitle'>
						<div className='title_left'>
							<MatTypography name='title' className='title_text' view={this.view}/>
							<Typography className='title_comment'>ご希望のコースを選択してください。</Typography>
						</div>
						<div className='itscomNet_img whitespace'>
							<a href='https://www.itscom.co.jp/service/internet/course/hikari/' target='_blank' className='courseUrl'>
								<b>その他のインターネットのコース変更について　</b>
								<img src={nextIcon} className='orange_circleArrow'/>
							</a>
						</div>
						<div className='itscomNet_img'>
							<a href='https://www.itscom.co.jp/service/internet/' target='_blank' className='courseUrl'>
								<b>コース一覧　</b>
								<img src={nextIcon} className='orange_circleArrow'/>
							</a>
						</div>
					</div>
					<Grid container>
						<Grid item xs={12}>
							<MatTableContainer listName={'OtherCourseListModel'} view={this.view} noDataUseHeader noDataMessage=' '>
								{/* PC用 */}
								<WprContainer mode='その他コース.PC' view={this.view}>
									<Table className = 'optionSelsctPcTable'> 
										<colgroup>
											<col style={{width: '10%'}}/>
											<col style={{width: '30%'}}/>
											<col style={{width: '20%'}}/>
											<col style={{width: '40%'}}/>
										</colgroup>
										<TableHead className='hedderrow'>
											<TableRow className='toptable'>
												<TableCell align='center' className='border_right font_bold'>選択</TableCell>
												<TableCell align='center' className='border_right font_bold'>コース名</TableCell>
												<TableCell align='center' className='border_right font_bold'>月額（税込）</TableCell>
												<TableCell align='center' className='font_bold'>回線種別</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{this.renderList('OtherCourseListModel', 'PC')}
											<WprContainer mode='データなし.表示' view={this.view}>
												<TableRow>
													<TableCell className='non_list' colSpan={5}>データが存在しませんでした</TableCell>
												</TableRow>
											</WprContainer>
										</TableBody>
									</Table>
								</WprContainer>
								{/* SP用 */}
								<WprContainer mode='その他コース.SP' view={this.view}>
									<Table className ='optionSelsctSpTable'>
										<colgroup>
											<col style={{width: '10%'}}/>
											<col style={{width: '90%'}}/>
										</colgroup>
										<TableHead className='hedderrow'>
											<TableRow className='toptable'>
												<TableCell rowSpan={4} align='center' className='border_right font_bold'>選択</TableCell>
												<TableCell rowSpan={1} align='center' className='font_bold'>コース名</TableCell>
											</TableRow>
											<TableRow className='toptable'>
												<TableCell rowSpan={1} align='center' className='font_bold'>月額（税込）</TableCell>
											</TableRow>
											<TableRow className='toptable'>
												<TableCell rowSpan={1} align='center' className='font_bold'>回線種別</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{this.renderList('OtherCourseListModel', 'SP')}
											<WprContainer mode='データなし.表示' view={this.view}>
												<TableRow>
													<TableCell className='non_list' colSpan={5}>データが存在しませんでした</TableCell>
												</TableRow>
											</WprContainer>
										</TableBody>
									</Table>
								</WprContainer>
							</MatTableContainer>
						</Grid>
					</Grid>
					<div className='supportMsgArea-nameChange' style = {{marginBottom:'30px'}}>
						<span style = {{color:'#6169B8', fontWeight:'700'}}>
							<img style={{ marginRight: '8px',verticalAlign: 'bottom',marginBottom: '3px'}} src={important}></img>
								プロパイダ契約について
						</span>
						<br/>	
							イッツコムとプロバイダ契約をされた場合、フレッツサービスの開通前でも、プロバイダ料が発生いたします。フレッツサービスのお申込み・開通後に、イッツコムとプロバイダ契約をされることをおすすめいたします。
					</div>
					<div className='attention_fontSize'>
						<ul className='ul_kome'>
							<li>
								お客さまのご住所および建物の設備状況によっては、ご利用になれない場合がございます。
							</li>
							<li>
								かっとびADSLフレッツサービスは、新規登録の受付を終了いたしました。
							</li>
						</ul>
					</div>
				</div>
				<Divider className='select1_underSpace'/>
				<div className='pageFooter footer-space'>
					<div>
						<MatButton name='next' view={this.view} className='orangeButton orangeButton_parts' >
							次へ
							<NavigateNextIcon />
						</MatButton>
					</div>
					<div>
						<MatButton name='back' view={this.view} className='backButton' >
							<NavigateBeforeIcon />
							戻る
						</MatButton>
					</div>
				</div>
			</div>
			</>
		);
	}
	// --------------------------------------------------------------------------

	// リスト描画  ---------------------------------------------------------------
	/**
	 * リスト名=OtherCourseListModel
	 * PC用
	 */
	public onOtherCourseListPcRender(rowInfo: WprRowInfo): any{
		return(
			<MatTableRow view={this.view} row={rowInfo}>
				<TableCell align='center' className='border_right service'>
					<MatCheckBox name='check' color='primary' view={this.view} row={rowInfo}/>
				</TableCell>
				<TableCell className='border_right service' align='left'>
					<MatTypography name='cDispnamep' className='courseSize' view={this.view} row={rowInfo}/>
				</TableCell>
				<TableCell className='border_right service' align='left'>
					<MatTypography name='payAmount' className='courseFee' view={this.view} row={rowInfo}/>
				</TableCell>
				<TableCell className='service' align='left'>
					<MatTypography name='nDispnamep' className='courseSize' view={this.view} row={rowInfo}/>
				</TableCell>
			</MatTableRow>
		);
	}

	/**
	 * リスト名=OtherCourseListModel
	 * PC用
	 */
	public onOtherCourseListSpRender(rowInfo: WprRowInfo): any{
		return (
			<>
				<TableRow>
					<TableCell className='border_right' align='center' rowSpan ={3}> 
						<MatCheckBox name='check' view={this.view} row={rowInfo}/>
					</TableCell>
					<TableCell rowSpan ={1}>
						<MatTypography name='cDispnamep' className='courseSize' view={this.view} row={rowInfo}/>
					</TableCell>
				</TableRow>
				<TableRow className='optionRow'>
					<TableCell rowSpan ={1}>
						<MatTypography name='payAmount' className='courseFee' view={this.view} row={rowInfo}/>
						<MatTypography name='payUnit' className='courseUnit' view={this.view} row={rowInfo}/>
					</TableCell>
				</TableRow>
				<TableRow>
					<TableCell rowSpan ={1}>
						<MatTypography name='nDispnamep' className='courseSize' view={this.view} row={rowInfo}/>
					</TableCell>
				</TableRow>
			</>
		);
	}
	// --------------------------------------------------------------------------
}
