/**
 * とことんサポート申込モデル
 */
export class TokotonSupportAppModel {
	// public 変数	-----------------------------------------------------------
	public applicationType: string;	// 申し込み種別
	public amount: string;			// 月額利用料
	public courseNm: string;		// コース名
	public supportFlg: boolean;		// サポート対応を希望する
	// ------------------------------------------------------------------------
}
