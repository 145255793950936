import { WprScopeMngr } from './WprScopeMngr';
import { WprBaseScopeInfo } from './WprBaseScopeInfo';

/**
 * スコープ設定
 */
export class WprScopeSetter {
	// private 変数  ------------------------------------------------------------
	private m_WprScopeMngr: WprScopeMngr		= null;			// スコープ管理機能
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	public constructor(mngr: WprScopeMngr) {
		this.m_WprScopeMngr = mngr;
	}
	// --------------------------------------------------------------------------

	// public メソッド	----------------------------------------------------------
	/**
	 * スコープ情報登録
	 * @param scope スコープ情報
	 */
	public addScope(scope: WprBaseScopeInfo): void {
		this.m_WprScopeMngr.addScope(scope);
	}
	// --------------------------------------------------------------------------
}
