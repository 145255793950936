import { WprBaseConverter } from '../../../../wpr-framework/view/convert/WprBaseConverter';
import { ObjectUtil } from '../../../common/util/ObjectUtil';

/**
 * ヘッダー
 */
export class UsageHeaderConverter extends WprBaseConverter {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() {
		super('利用明細ヘッダー');
	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * 値を表示用の値に変換する (value -> viewValue)
	 * @param value 値
	 * @returns 表示用の値
	 */
	public convertView(value: any): string {
		const str: string = value as string;
		if (ObjectUtil.isNotNullOrUndefined(str)) {
			if (str === "割引" || str === "その他" || str === "イッツコム モバイル") {
				return value;
			}
			return str + "ご利用料金";
		}
		return value;
	}
	// --------------------------------------------------------------------------
}
