import { WprModel } from '../../../../wpr-framework/view/deco/WprModel';
import { ObjectUtil } from '../../../common/util/ObjectUtil';
import { MypageBaseView } from '../../base/MypageBaseView';

/**
 * メニューバー
 */
export class MenuBarView extends MypageBaseView {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() { super('MenuBar'); }
	// --------------------------------------------------------------------------

	// モデル  ------------------------------------------------------------------
	@WprModel('session_id')
	private m_session_id: string = null;	// fontSize
	// --------------------------------------------------------------------------


	// private変数  -----------------------------------------------------------
	private m_preMenu: string = null;	// 前回表示したメニュー
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * コントロール定義設定
	 */
	public onRegisterConfig(): void {
		this.addConfig('session_id', 'セッションID', 'session_id', { bindModel: true });
	}

	/**
	 * アクション登録
	 */
	public onRegisterAction(): void {
		this.addAction('menuModal', this.onCloseModal);
		// トップ
		this.addAction('toTop', this.onToPage);
		// 料金
		this.addAction('openFee', this.onClickMenu);
		this.addAction('toFeeMenu', this.onToPage);
		this.addAction('toUsageDetail', this.onToPage);
		// this.addAction('toPaymentHistory', this.onToPage);
		this.addAction('toChangePayment', this.onToPage);

		// ご契約・手続き
		this.addAction('openContract', this.onClickMenu);
		this.addAction('toContractMenu', this.onToPage);
		this.addAction('toContractTop', this.onToPage);
		this.addAction('toServiceTop', this.onToPage);
		this.addAction('toContractConfirm', this.onToPage);
		this.addAction('toConstrList', this.onToPage);
		this.addAction('toAmbassador', this.onToPage);

		// 各種設定
		this.addAction('openOption', this.onClickMenu);
		this.addAction('toSettingMenu', this.onToPage);
		this.addAction('toCustomerDetail', this.onToPage);
		this.addAction('toInternetOption', this.onToPage);
		this.addAction('toHomepageDetail', this.onToPage);
		this.addAction('toMail', this.onToPage);

		// お問合せ・サポート
		this.addAction('openHelp', this.onClickMenu);
		this.addAction('toCommonqueMenu', this.onToPage);
		this.addAction('toSupport', this.onToPage);
		this.addAction('toCheckNetSpeed', this.onToSpeedCheck);


		// 共通
		// this.addAction('toCustomerDetail', this.onToPage);
		// this.addAction('toServiceTop', this.onToPage);
		// this.addAction('toTvOption', this.onToPage);
		// this.addAction('toMypageOption', this.onToPage);
		// this.addAction('toQuestion', this.onToPage);
		// this.addAction('toHelp', this.onToPage);

		this.addAction('toServicePage', this.onToServicePage);
		this.addAction('toCompanyPage', this.onToCompanyPage);
	}

	/**
	 * ビュー表示通知
	 */
	public onShowView(): void {
		this.m_session_id = this.getCookie('sessionId');
		this.setViewData('netSpeedCheckURL', this.getNetSpeedCheckUrl());
		this.refreshView();
	}

	/**
	 * ウインドウリサイズ通知
	 * @param width 幅
	 * @param height 高さ
	 */
	public onResizeWindow(width: number, height: number): void {
		if (width <= 700) {
			this.setViewMode('メニューバー', '下');
		}
		else {
			this.setViewMode('メニューバー', '左');
		}
	}
	// --------------------------------------------------------------------------

	// アクション  ---------------------------------------------------------------
	/**
	 * メニューボタン押下
	 */
	public onClickMenu(param: any): void {
		const parent = document.getElementById('menuBarParent');

		const isBottomMenu: boolean = this.isMenuBottom();
		this.setMenuMode();
		// メニューバー下の場合
		if (isBottomMenu) {
			if (this.m_preMenu !== param) {
				// 
				this.closeMenuBarBottom();

				// メニューバーを開く・選択されたメインメニューをモードでセット
				this.setViewMode('メニュー', '開');
				this.setViewMode('選択', this.getSelectedMenuName(param));

				// メニューバー親に、クラス名を付与（openDetail）
				parent.classList.add('MenuBar-openDetail');

				// 
				const menuColList = document.getElementsByClassName('MenuBar-menuCol');
				for (let menuCol of menuColList) {
					const selected = document.getElementsByName(param)[0];
					const selectedParent = selected.closest(".MenuBar-menuCol");
					selectedParent.classList.add('MenuBar-menuColSelected');
				}


				this.setSelectedSideElementStyle(param, true, true);
				this.setSelectedSideElementStyle(param, false, true);

				this.refreshView();
			}
			this.m_preMenu = param;
		}
		// メニューバー左の場合
		else {
			// メインメニューバー情報取得
			const menubar = document.getElementsByClassName('MenuBar-menuBarMain');
			const menuBarRect = menubar[0].getBoundingClientRect();

			// 前に選択したメインメニュー　と新しく選択したメインメニューが異なる場合
			if (this.m_preMenu !== param) {
				// メニューバーを開く・選択されたメインメニューをモードでセット
				this.setViewMode('メニュー', '開');
				this.setViewMode('選択', this.getSelectedMenuName(param));

				// メニューバー親に、クラス名を付与（openDetail）
				parent.classList.add('MenuBar-openDetail');
				// メニューバー親のwidth,heightをセット
				const menuBarOpenWifth = 340;	// scss内のmenuBarOpenWifthのwidth
				parent.style.width = (menuBarRect.width + menuBarOpenWifth) + 'px';
				parent.style.height = (menuBarRect.height) + 'px';
				
				parent.style.position = 'absolute';
				// メインメニューバーのwidthを明示的に再度セット
				menubar[0].style.width = menuBarRect.width + 'px';


				// 選択したメインメニューに選択された状態を表す画像を表示させる処理
				// 料金
				if (param === 'openFee') {
					this.setSelectedMenuZindex('payamentMenu');
				}
				// ご契約・手続き
				else if (param === 'openContract') {
					this.setSelectedMenuZindex('contractMenu');
				}
				// 各種設定 
				else if (param === 'openOption') {
					this.setSelectedMenuZindex('settingMenu');
				}
				// ヘルプ
				else if (param === 'openHelp') {
					this.setSelectedMenuZindex('inquiryMenu');
				}
				this.m_preMenu = param;
			}
			// 前に選択したメインメニューをもう一度選択した場合
			else {
				this.closeMenueBar();
			}
			this.refreshView();
			setTimeout(() => {
				// 開いたメニューバーの高さを時間差で設定
				const menubarOpen = document.getElementsByClassName('MenuBar-menuBarOpen');
				menubarOpen[0].style.height = (menuBarRect.height) + 'px';
			}, 350);
		}
	}

	/**
	 * モーダルをクリックしたときに、メニューバーを閉じる
	 */
	public onCloseModal(): void {
		const isBottomMenu: boolean = this.isMenuBottom();
		if (isBottomMenu) {
			this.closeMenuBarBottom();
		}
		else {
			this.closeMenueBar();
		}
	}

	/**
	 * 会社概要
	 */
	public onToCompanyPage(): void {
		window.open('https://www.itscom.co.jp/corporate/');
	}

	/**
	 * サービスページ
	 */
	public onToServicePage(): void {
		window.open('https://www.itscom.co.jp/');
	}

	/**
	 * 通信速度測定
	 */
	public onToSpeedCheck(): void {
		location.href = "javascript:speed_check_menu_pc.submit()"
	}

	/**
	 * メニューボタン押下
	 */
	public onToPage(param: any): void {
		const isBottomMenu: boolean = this.isMenuBottom();
		if (isBottomMenu) {
			this.closeMenuBarBottom();
		}
		else {
			this.closeMenueBar();
		}
		// this.setViewMode('メニュー', '閉');
		// this.setViewMode('選択', '無');
		// const parent = document.getElementById('menuBarParent');
		// parent.classList.remove('MenuBar-openDetail');

		const path: string = this.getPath(param);

		// 新マイページに画面がある場合
		if (ObjectUtil.isNotNullOrUndefined(path)) {
			this.pushPath(path);
		}
		// 現行側に飛ばす場合
		else {
			const oldPath: string = this.getOldPath(param);
			location.href = this.getOldUrl() + oldPath;
		}
	}
	// --------------------------------------------------------------------------

	// private メソッド  ---------------------------------------------------------
	/**
	 * メニューバー下かどうか
	 * @returns 
	 */
	private isMenuBottom(): boolean {
		const isBottomMenu: boolean = this.getViewMode('メニューバー') === '下';
		return isBottomMenu;
	}
	/**
	 * スマホ用：選択解除
	 */
	private closeMenuBarBottom(): void {
		const menuColList = document.getElementsByClassName('MenuBar-menuCol');
		for (let menuCol of menuColList) {
			if (menuCol.classList.contains('MenuBar-menuColSelected')) {
				menuCol.classList.remove('MenuBar-menuColSelected');
			}
		}
		// 
		if (this.m_preMenu != null) {
			this.setSelectedSideElementStyle(this.m_preMenu, true, false);
			this.setSelectedSideElementStyle(this.m_preMenu, false, false);
		}
		this.closeMenueBar();
	}

	/**
	 * スマホ用：メニュー選択時再度の画像のdisplayを変更させる
	 * @param param 
	 * @param rightFlg 
	 * @param displayFlg 
	 */
	private setSelectedSideElementStyle(param: string, rightFlg: boolean, displayFlg: boolean): void {
		const selctedLeft = document.getElementById(param + '_selected' + (rightFlg ? 'Right' : 'Left'));
		if (ObjectUtil.isNotNullOrUndefined(selctedLeft)) {
			selctedLeft.style.display = displayFlg ? 'initial' : 'none';
		}
	}

	/**
	 * 
	 * @param controlName 
	 * @returns 
	 */
	private getSelectedMenuName(controlName: string): string {
		switch (controlName) {
			case 'openHelp':
				return 'お問合せ・サポート';
			case 'openFee':
				return '料金';
			case 'openContract':
				return 'ご契約・手続き';
			case 'openOption':
				return '各種設定';
			default:
				break;
		}
	}

	/**
	 * 新マイページ　パス
	 * @param controlName 
	 * @returns 
	 */
	private getPath(controlName: string): string {
		switch (controlName) {
			// トップ
			case 'toTop':
				return null;

			// 料金
			case 'toFeeMenu':
				return null;
			case 'toUsageDetail':
				return '/usage_details_001';
			case 'toPaymentHistory':
				return null;
			case 'toChangePayment':
				return null;

			// ご契約・手続き
			case 'toContractTop':
				return null;
			case 'toServiceTop':
				return null;
			case 'toContractConfirm':
				return '/';
			case 'toConstrList':
				return '/constr_list';
			case 'toAmbassador':
				return '/ambassador_001';

			// 各種設定
			case 'toCustomerDetail':
				return null;
			case 'toInternetOption':
				return null;
			case 'toHomepageDetail':
				return null;

			// お問合せ・サポート
			case 'toSupport':
				return null;

			// 
			case 'toServiceTop':
				return null;
			case 'toTvOption':
				return null;
			case 'toMail':
				return null;
			case 'toMypageOption':
				return null;
			case 'toQuestion':
				return null;
			case 'toHelp':
				return null;
			default:
				break;
		}
	}

	/**
	 * 旧マイページ　パス
	 * @param controlName 
	 * @returns 
	 */
	private getOldPath(controlName: string): string {
		switch (controlName) {
			// トップ
			case 'toTop':
				return 'Top/My_top/';

			// 料金
			// case 'toUsageDetail':
			// 	return '/UsageDetails_001';
			case 'toFeeMenu':
				return 'Menu/Fee_menu_list/';
			case 'toPaymentHistory':
				return null;
			case 'toChangePayment':
				return 'Cust_mod/Paymethod_disp/';

			// ご契約・手続き
			case 'toContractMenu':
				return 'Menu/Contract_menu_list/';
			case 'toContractTop':
				return 'Srvlist/Continfo_list/';
			case 'toServiceTop':
				return 'Srvlist/Cancel_continfo_list/';
			// case 'toContractConfirm':
			// 	return null;

			// 各種設定
			case 'toSettingMenu':
				return 'Menu/Setting_menu_list/';
			case 'toMail':
				return 'Mail/Mail_list/';
			case 'toCustomerDetail':
				return 'Cust_mod/Continfo_disp/';
			case 'toInternetOption':
				return 'Syman/Syman_menu/';
			case 'toHomepageDetail':
				return 'Home/Home_list/';

			// お問合せ・サポート
			case 'toCommonqueMenu':
				return 'Inquiry/Commonque_menu/';
			case 'toSupport':
				return 'Inquiry/Inquiry_entry/';
			case 'toCheckNetSpeed':
				return null;

			// 
			// case 'toServiceTop':
			// 	return 'Srvmenu/Srvmod_menu/';
			// case 'toTvOption':
			// 	return 'Opchannel/Add_tvop_select/';

			// case 'toMypageOption':
			// 	return 'Mod_my/Idpass_mod_menu/';
			// case 'toQuestion':
			// 	return 'Inquiry/Commonque_menu/';
			// case 'toHelp':
			// 	return 'Help/Help_menu/';
			default:
				break;
		}
	}

	/**
	 * メニューバーを閉じる
	 */
	private closeMenueBar(): void {
		this.setViewMode('メニュー', '閉');
		this.setViewMode('選択', '無');
		this.setSelectedMenuZindex(null);
		this.m_preMenu = null;
		// const parent2 = document.getElementById('menuBarParent');
		// const parent = document.getElementById(parent2.classList[0]);
		const parent = document.getElementById('menuBarParent');
		parent.style.width = '';
		parent.style.position = '';
		parent.classList.remove('MenuBar-openDetail');
		// const payamentMenu = document.getElementById('payamentMenu');
		// const contractMenu = document.getElementById('contractMenu');
		// const settingMenu = document.getElementById('settingMenu');
		// const inquiryMenu = document.getElementById('inquiryMenu');
		// payamentMenu.style.zIndex = '-1';
		// contractMenu.style.zIndex = '-1';
		// settingMenu.style.zIndex = '-1';
		// inquiryMenu.style.zIndex = '-1';
	}



	/**
	 * 選択されたメインメニューに、選択されたことを表す画像を表示する処理（zindex）
	 * @param selectedMenuId 
	 */
	private setSelectedMenuZindex(selectedMenuId: string): void {
		const notSelectedMenuIdList: string[] = ['payamentMenu', 'contractMenu', 'settingMenu', 'inquiryMenu'];

		// 各メインメニュー情報取得
		if (ObjectUtil.isNotNullOrUndefined(selectedMenuId)) {
			const selectedMenu = document.getElementById(selectedMenuId);
			selectedMenu.style.zIndex = '10';
		}

		for (let notSelectedMenuId of notSelectedMenuIdList) {
			// もし選択されたIDだった場合飛ばす
			if (selectedMenuId === notSelectedMenuId) continue;

			const selectedMenu = document.getElementById(notSelectedMenuId);
			if (selectedMenu != null)
				selectedMenu.style.zIndex = '-1';
		}
	}

	/**
	 * メニューの制御モード設定
	 */
	private setMenuMode(): void {
		const netFlg: boolean = ObjectUtil.stringToBoolean(this.getCookie('netFlg'));
		const netContractFlg: boolean = ObjectUtil.stringToBoolean(this.getCookie('netContractFlg'));

		this.setViewMode('ネット契約', netFlg ? '表示' : '非表示');
		this.setViewMode('ネット契約契約中', netContractFlg ? '表示' : '非表示');
	}
	// --------------------------------------------------------------------------
}
