import { MypageBaseView } from '../../../base/MypageBaseView';
import { WprModel } from '../../../../../wpr-framework/view/deco/WprModel';
import { WprDI_ScopeModel } from '../../../../../wpr-framework/view/di/WprDI_ScopeModel';
import { HMvnoTmpSaved } from '../../../../service/models/entity/HMvnoTmpSaved';
import { MvnoMnpInfo } from '../../../../service/models/apimodels/MvnoMnpInfo';
import { MvnoService } from '../../../../service/MvnoService';
import { CreateMvnoInquiryRequest } from '../../../../service/models/dto/mvno/CreateMvnoInquiryRequest';
import { WprViewMode } from '../../../../../wpr-framework/view/deco/WprViewMode';
import { NextButtonMode } from './mode/NextButtonMode';
import { MvnoCompleteView } from '../../mvno_complete/MvnoCompleteView';
import { ConvertViewUtil } from '../../../../common/util/ConvertViewUtil';

/**
 * MVNOMNP開通問い合わせ_確認
 */
export class MvnoMnpInquiryConfirmView extends MypageBaseView {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() { super('MvnoMnpInquiryConfirm'); }
	// --------------------------------------------------------------------------

	// モデル  ------------------------------------------------------------------
	@WprDI_ScopeModel('MvnoMnpInfo')
	@WprModel('MvnoMnpInfo')
	private m_MvnoMnpInfo: MvnoMnpInfo = null;	// MVNOプラン情報
	@WprModel('confirm')
	private m_confirm: boolean	= null;	// confirm
	// --------------------------------------------------------------------------

	
	// サービス  ----------------------------------------------------------------
	private m_MvnoService: MvnoService	= new MvnoService();	// MyPageRenewalサービス
	// --------------------------------------------------------------------------

	// サブビュー/モード  ---------------------------------------------------------
	@WprViewMode
	private m_NextButtonMode = new NextButtonMode();	// 次へボタン用モード
	// --------------------------------------------------------------------------


	// override メソッド  --------------------------------------------------------
	/**
	 * コントロール定義設定
	 */
	public onRegisterConfig(): void {
		this.addConfig('iccId', 'ICCID', 'MvnoMnpInfo');
		this.addConfig('phoneNumber', '回線番号', 'MvnoMnpInfo');
		this.addConfig('familyName', '姓漢字', 'MvnoMnpInfo.customerInfo');
		this.addConfig('firstName', '名漢字', 'MvnoMnpInfo.customerInfo');
		this.addConfig('familyNameKana', '姓カナ', 'MvnoMnpInfo.customerInfo');
		this.addConfig('firstNameKana', '名カナ', 'MvnoMnpInfo.customerInfo');
		this.addConfig('confirm', '確認', 'confirm', { bindModel: true });

	}

	/**
	 * アクション登録
	 */
	public onRegisterAction(): void {
		this.addAction('next', this.onNext);
		this.addAction('back', this.onBack);
		this.addAction('supportlink', this.onSupportLink);
	}

	/**
	 * ビュー表示通知
	 */
	public onShowView(): void {
		this.registAccessLog();
		this.setStorageItem('b_url', window.location.href);
		// this.setScopeMode('営業時間', this.getTimeForDisp());
		this.refreshView();
	}
	// --------------------------------------------------------------------------

	// アクション  ---------------------------------------------------------------
	/**
	 * 次へ
	 */
	public onNext(param: any): void {
		// お問い合わせ作成
		const req: CreateMvnoInquiryRequest = new CreateMvnoInquiryRequest();
		req.customerId = this.getCustomerId();
		req.contractCd = this.getContractCd();
		req.mnpInfo = this.m_MvnoMnpInfo;
		req.mvnoInquiryType = 'MNP開通問い合わせ';
		this.m_MvnoService.createMvnoInquiry(this, req, (result) => {
			this.setScopeMode('MVNO', MvnoCompleteView.MVNO_MNP);
			this.setScopeMode('営業時間', this.getTimeForDisp());
			this.setScopeModel('customerName', ConvertViewUtil.convertCustName(this.m_MvnoMnpInfo.customerInfo.familyName, this.m_MvnoMnpInfo.customerInfo.firstName));
			this.pushPath('/mvno_mnp_003');
		});

	}

	
	
	/**
	 * 戻る
	 */
	public onBack(param: any): void {
		this.pushPath('/mvno_mnp_001');
	}
	/**
	 * サポートリンク
	 */
	public onSupportLink(param: any): void {
		window.open('https://www.itscom.co.jp/support/mobile/setup/');
	}
	// --------------------------------------------------------------------------

	/**
	 * 時刻によって表示文言切替のため
	 */	
	private getTimeForDisp(): string {
		const date1: Date = new Date(); // 現在時刻
		const hour = date1.getHours();
		const minute = date1.getMinutes();
		// 00:00~09:29
		if (hour >= 0 && (hour < 9 || (hour == 9 && minute < 30))) 
			return '営業時間外当日';
		// 09:30~17:29
		else if ((hour >= 10 || (hour == 9 && minute >= 30)) && (hour < 17 || (hour == 17 && minute < 30))) 
			return '営業時間内';
		// 17:30~24:00
		else 
			return '営業時間外前日';
	}
}
