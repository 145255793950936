import React, { ReactNode } from 'react';
import { WprBaseViewComponent } from '../../../../wpr-framework/view/component/WprBaseViewComponent';
import { AddAccesspassConfirmView } from './AddAccesspassConfirmView';
import { Typography, Grid, Divider } from '@material-ui/core';
import { MatButton } from '../../../../wpr-framework/component/controls/material/MatButton';
import { ProgressBar } from '../../common/progress_bar/ProgressBar';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { MatTypography } from '../../../../wpr-framework/component/controls/material/MatTypography';
import netIcon from '/shared/image/common/logo_iTSCOMNET.png';
import styles from './AddAccesspassConfirm.scss';
/**
 * アクセスアカウントパスワード変更確認画面用コンポーネント
 */
export class AddAccesspassConfirm extends WprBaseViewComponent {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: any) { super(new AddAccesspassConfirmView(), props); }
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** アクセスアカウントパスワード変更確認画面ビュー情報 */
	public get viewInfo(): AddAccesspassConfirmView { return this.view as AddAccesspassConfirmView; }
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * スタイルオブジェクト取得
	*/
	public getStylesObject(): any { return styles; }

	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		return (
			<>
				<div className='pageCard'>
					<div className="pageHeader stepFlow">
						<ProgressBar name='progressBar' view={this.view} />
					</div>
					<div className='sub-logo'>
						<img src={netIcon} />
					</div>
					<div className='main'>
						<div className='pageTitle'>
							<div className='title_left'>
								<Typography className='title_text'>iTSCOM NET アクセスアカウント パスワード 変更確認</Typography>
								<Typography className='title_comment' style={{ fontWeight: '500' }}>以下でよろしいですか？</Typography>
								<Typography className='title_comment2' style={{ fontWeight: '500' }}>よろしければ、「変更」ボタンをクリックしてください。</Typography>
							</div>
						</div>
						<div className='feeTable'>
							<Grid container className='feeRow'>
								<Grid item xs={12} md={4} className='feeTitle'>
									<div>コース</div>
								</Grid>
								<Grid item xs={12} md={8} className='feeVal courseValue' style={{ color: 'black' }}>
									<MatTypography name={'course'} view={this.view} className='itemUnit' style={{ color: '#484848' }}></MatTypography>
								</Grid>
							</Grid>
							<Grid container className='feeRow'>
								<Grid item xs={12} md={4} className='feeTitle'>
									<div>アクセスアカウント</div>
								</Grid>
								<Grid item xs={12} md={8} className='feeVal courseValue' style={{ color: 'black' }}>
									<MatTypography name={'radiusAccount'} view={this.view} className='itemUnit' style={{ color: '#484848' }}></MatTypography>
								</Grid>
							</Grid>
							<Grid container className='feeRow'>
								<Grid item xs={12} md={4} className='feeTitle'>
									<div>パスワード</div>
								</Grid>
								<Grid item xs={12} md={8} className='feeVal courseValue' style={{ color: 'black' }}>
									<Typography style={{ color: '#484848' }}>●●●●●●●●</Typography>
								</Grid>
							</Grid>
						</div>
					</div>
					<Divider />
					<div className='pageFooter footer-space'>
						<div>
							<MatButton name='next' view={this.view} className='orangeButton orangeButton_parts' >
								変更
								<NavigateNextIcon />
							</MatButton>
						</div>
						<div>
							<MatButton name='back' view={this.view} className='backButton' >
								<NavigateBeforeIcon />
								戻る
							</MatButton>
						</div>
					</div>
				</div>
			</>
		);
	}
	// --------------------------------------------------------------------------
}
