import { WprListData } from '../../../../../wpr-framework/view/deco/WprListData';
import { WprModel } from '../../../../../wpr-framework/view/deco/WprModel';
import { WprDI_ScopeModel } from '../../../../../wpr-framework/view/di/WprDI_ScopeModel';
import { ObjectUtil } from '../../../../common/util/ObjectUtil';
import { Contract } from '../../../../models/bss/contract/Contract';
import { TvBaseModel } from '../../../../models/mypage/tv/TvBaseModel';
import { TvOptionListModel } from '../../../../models/mypage/tv/TvOptionListModel';
import { GetIsContractRadiusAccountPasswordResponse } from '../../../../service/models/dto/mypagerenewal/GetIsContractRadiusAccountPasswordResponse';
import { MypageBaseView } from '../../../base/MypageBaseView';
import { ProgressBarData } from '../../../common/progress_bar/ProgressBarView';
import { MypageWizardMngr } from '../../../wizard/models/MypageWizardMngr';
import { WprViewMode } from '../../../../../wpr-framework/view/deco/WprViewMode';
import { OpotionButtonMode } from './mode/OpotionButtonMode';
import { TvWizardModel } from '../../../../models/mypage/tv/TvWizardModel';
import { OptionButtonSPMode } from './mode/OptionButtonSPMode';
import { WprValidError } from '../../../../../wpr-framework/view/validation/WprValidError';
import { ErrorModel } from '../../../Srvother/add_nhk_select/models/ErrorModel';
import MypageDispdivcdConstants from '../../../../common/MypageDispdivcdConstants';
/**
 * テレビオプション申込選択
 */
export class AddTvopSelectView extends MypageBaseView {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() { super('AddTvopSelectView'); }
	// --------------------------------------------------------------------------
	// モデル  ------------------------------------------------------------------
	@WprDI_ScopeModel('MypageWizardMngr')
	private m_MypageWizardMngr: MypageWizardMngr			= null;		// 画面ウィザード管理クラス
	@WprListData('TvOptionListModel')
	@WprListData('TvOptionListSPModel')
	private m_TvOptionListModel: TvOptionListModel[]	= null;	// テレビオプションリストモデル
	@WprModel('TvBaseModel')
	private m_TvBaseModel: TvBaseModel	= null;	// テレビ基本情報モデル
	@WprListData('ErrorModel')
	private m_ErrorModelList: ErrorModel[]					= null;	// エラーモデル
	// --------------------------------------------------------------------------

	// サブビュー/モード  --------------------------------------------------------
	@WprViewMode
	private m_OpotionButtonMode = new OpotionButtonMode();	// オプションボタンモード
	@WprViewMode
	private m_OptionButtonSPMode = new OptionButtonSPMode();	// スマホ用オプションボタンモード
	// --------------------------------------------------------------------------

	// private変数 --------------------------------------------------------------
	private m_Contract: Contract = null;
	private m_TvWizardModel: TvWizardModel = null;
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * コントロール定義設定
	 */
	public onRegisterConfig(): void {
		this.addConfig('course', 'コース', 'TvBaseModel');
		this.addConfig('terminal', '機器名', 'TvBaseModel');
		this.addConfig('contractStatus', 'コースステータス', 'TvBaseModel');
		this.addConfig('aCas', 'A-CAS番号', 'TvBaseModel');
		this.addConfig('bCas', 'B-CAS番号', 'TvBaseModel');
		this.addConfig('cCas', 'C-CAS番号', 'TvBaseModel');
		this.addConfig('applyType', '購入、レンタル ( 表示用 )', 'TvBaseModel');
		this.addConfig('magazine', 'マガジン ( 表示用 )', 'TvBaseModel');
		this.addListConfig('option', 'オプション', 'TvOptionListModel');
		this.addListConfig('amount', '月額', 'TvOptionListModel', { converter: '3桁カンマ' });
		this.addListConfig('status', 'ステータス', 'TvOptionListModel', { bindName: 'status' });
		this.addListConfig('uncontract', 'ステータス', 'TvOptionListModel', { bindName: 'status' });
		this.addListConfig('option', 'オプション', 'TvOptionListSPModel');
		this.addListConfig('amount', '月額', 'TvOptionListSPModel', { converter: '3桁カンマ' });
		this.addListConfig('status', 'ステータス', 'TvOptionListSPModel', { bindName: 'status' });
		this.addListConfig('uncontract', 'ステータス', 'TvOptionListSPModel', { bindName: 'status' });
	}

	/**
	 * アクション登録
	 */
	public onRegisterAction(): void {
		this.addAction('back', this.onBack);
		this.addListAction('nextDay', 'TvOptionListModel', this.onNextDay);
		this.addListAction('nextMonth', 'TvOptionListModel', this.onNextMonth);
		this.addListAction('nextCancel', 'TvOptionListModel', this.onNextCancel, true);
		this.addListAction('nextDaySP', 'TvOptionListSPModel', this.onNextDay);
		this.addListAction('nextMonthSP', 'TvOptionListSPModel', this.onNextMonth);
		this.addListAction('nextCancelSP', 'TvOptionListSPModel', this.onNextCancel, true);
	}

	/**
	 * ビュー表示通知
	 */
	public onShowView(): void {
		this.m_MypageWizardMngr.initView(this, (result) => {
			this.registAccessLog();
			this.setStorageItem('b_url', window.location.href);
			const sendData: ProgressBarData = new ProgressBarData();
			sendData.progressBarList = this.m_MypageWizardMngr.getProgressModelList(this);
			this.sendChildData('fP_ev_progressBarIntital', sendData);

			//画面サイズによるレイアウトの設定
			if (window.outerWidth > 440) {
				this.setViewMode('オプション','PC');
			}
			else {
				this.setViewMode('オプション','SP');
			}
			this.m_TvBaseModel = this.m_MypageWizardMngr.getCache().m_TvBaseModel;
			this.m_TvOptionListModel = this.m_MypageWizardMngr.getCache().m_TvOptionListModel;
			this.m_TvWizardModel = this.m_MypageWizardMngr.getCache().m_TvWizardModel;
			//契約情報取得
			const contractId = this.m_MypageWizardMngr.getParams().contractId;
			if (ObjectUtil.isNotNullOrEmptyStr(contractId)) {
				// this.m_MypageWizardMngr.getCache().m_Contract = this.m_MypageWizardMngr.getContract(this, contractId);
				this.m_Contract = this.m_MypageWizardMngr.getContract(this, contractId);
			}
			//equipmentTypeCDがZZZCM、ZZSTB、nullの場合10000に変更
			const equipment = this.m_Contract.equipment[0];
			const equ = this.m_MypageWizardMngr.getMCommonList(this, 'Add_net', 'equ', null, null);
			const zzzcm = equ.filter(item => item.category3 === 'req')[0].commonValue;
			const zzstb = equ.filter(item => item.category3 === 'req2')[0].commonValue;
			const rep = equ.filter(item => item.category3 === 'rep')[0].commonValue;
			if (equipment.equipmentTypeCD) {
				if (equipment.equipmentTypeCD === zzzcm || equipment.equipmentTypeCD === zzstb)
					equipment.equipmentTypeCD = rep;
			}
			else 
				equipment.equipmentTypeCD = rep;
			//ダミー端末チェック
			this.m_TvBaseModel.dummy = true;
			const dummy = this.m_MypageWizardMngr.getMCommonList(this, 'Dummy_Equipment', 'dummyid', null, null)[0].commonValue;
			if (equipment.equipmentKindCD === 'S') {
				if ((equipment.equipmentID && equipment.equipmentID.includes(dummy)) || (equipment.equipmentID2 && equipment.equipmentID2.includes(dummy)))
					this.m_TvBaseModel.dummy = false;
			}
			if (equipment.equipmentKindCD === 'C' && equipment.equipmentID.includes(dummy)) 
					this.m_TvBaseModel.dummy = false;
				
			//基本情報表示
			// const serviceList = this.m_MypageWizardMngr.getServiceList(this, MypageDispdivcdConstants.TV_FTTH_SERVICE).concat(this.m_MypageWizardMngr.getServiceList(this, MypageDispdivcdConstants.TV_OTHER_SERVICE).concat(this.m_MypageWizardMngr.getServiceList(this, MypageDispdivcdConstants.TV_MAIJU_SERVICE).concat(this.m_MypageWizardMngr.getServiceList(this, MypageDispdivcdConstants.TV_TANAKO_SERVICE).concat(this.m_MypageWizardMngr.getServiceList(this, MypageDispdivcdConstants.TV_AP_SERVICE)))));
			const serviceList = this.m_MypageWizardMngr.getServiceList(this, MypageDispdivcdConstants.TV_SERVICE);
			const service = serviceList.filter(item => item.service.serviceCD === this.m_Contract.serviceCD);
			if (service.length > 0)
				this.m_TvBaseModel.amount = this.m_MypageWizardMngr.getTaxFee(this, service[0].service.payment[0].amount.toString());
			this.m_TvBaseModel.course = this.m_MypageWizardMngr.getMDisplayList(this, MypageDispdivcdConstants.TV_SERVICE, '').filter(item => item.namecd === this.m_Contract.serviceCD)[0].dispnamep;
			this.m_TvBaseModel.contractStatus = this.m_MypageWizardMngr.getMDisplayList(this, MypageDispdivcdConstants.ITSCOMHOME_SERVICESTATUS, '').filter(item => item.namecd === this.m_Contract.statusCD)[0].dispnamep;
			for (const eqp of this.m_Contract.equipment) {
				if (eqp.equipmentTypeCD === '10059') {
					if (eqp.equipmentID.slice(0, 4) === '0100')
						this.m_TvBaseModel.aCas = eqp.equipmentID;
					else
						this.m_TvBaseModel.bCas = eqp.equipmentID;
				}
				else if (eqp.equipmentTypeCD === '10060')
					this.m_TvBaseModel.cCas = eqp.equipmentID;
				else if (eqp.equipmentTypeCD !== rep) {
					this.m_TvBaseModel.terminal = this.m_MypageWizardMngr.getMDisplayList(this, MypageDispdivcdConstants.ITSCOMHOME_TERMINALTYPE, '').filter(item => item.namecd === eqp.equipmentTypeCD)[0].dispnamep;
					this.m_TvBaseModel.applyType = this.m_MypageWizardMngr.getMDisplayList(this, MypageDispdivcdConstants.ITSCOMHOME_TERMINALSTATUS, '').filter(item => item.namecd === eqp.rentalFlag)[0].dispnamep;
				}
			}
			this.m_TvBaseModel.magazine = '未契約';
			const magazineCd = this.m_MypageWizardMngr.getMCommonList(this, 'srvtv', 'magazine_op', null, null)[0].commonValue;
			for (const option of this.m_Contract.option) {
				if (option.optionCD === magazineCd && this.m_Contract.statusCD !== '0' && this.m_Contract.statusCD !== '3' ) 
					this.m_TvBaseModel.magazine = '契約中';
			}
			//オプション取得
			const useOptionList = this.m_Contract.option;
			if (this.m_TvOptionListModel.length > 0)
				this.m_TvOptionListModel.length = 0;
			const optionList = serviceList.filter(item => item.service.serviceCD === this.m_Contract.serviceCD )[0].service.option;
			const optionNameList = this.m_MypageWizardMngr.getMDisplayList(this, MypageDispdivcdConstants.TV_OPTION, '');
			for (const option of optionList) {

				const newOpt = optionNameList.filter(item =>item.namecd === option.optionCD && item.genecd);
				let newFlg = false;
				if (newOpt.length > 0)
					newFlg = true;
				const button = optionNameList.filter(item =>item.namecd === option.optionCD);
				let applyCd = '0';
				if (button.length > 0)
					applyCd = button[0].genecd;
				else 
					applyCd = '0';
				const optionName = optionNameList.filter(item =>item.namecd === option.optionCD);
				if (!newFlg && applyCd === '1')
					this.setViewMode('変更受付', '表示');
				//名称の取得できたもののみ表示
				if (optionName.length > 0) {
					const model = new TvOptionListModel();
					model.option = optionName[0].dispnamep;
					model.detail = option;
					//wowow：金額非表示
					const wowwowFlg = this.m_MypageWizardMngr.getMCommonList(this, 'opt', 'digital_wowow', null, null).filter(item => item.commonValue === option.optionCD);
					if (wowwowFlg.length > 0) {
						model.wowwowFlg = true;
						model.contractType = '申込み不可';
						this.setViewMode('wowow', '表示');
					}
					//wowow以外は金額表示
					else {
						model.wowwowFlg = false;
						model.amount = this.m_MypageWizardMngr.getTaxFee(this, option.amount.toString());
						const tunerFlg = this.m_MypageWizardMngr.getMCommonList(this, 'srvtv', 'tuner_op', null, null).filter(item => item.commonValue === option.optionCD);
						//チューナーオプションはステータス非表示
						if (tunerFlg.length > 0) {
							model.tunerFlg = true;
							model.contractType = '申込み不可';
						}
						else {
							//geneCd2はステータス固定値
							model.tunerFlg = false;
							if (optionName[0].genecd === '2') {
								model.status = '新規受付は終了いたしました。';
								model.contractType = '申込み不可';
								model.royalFlg = false;
							}
							//ロイヤル会員はステータス非表示
							else if (optionName[0].dispnamep.includes('ロイヤル')) {
								const idx = optionName[0].dispnamep.indexOf('ロ');
								model.option = optionName[0].dispnamep.substring(0, idx-1);
								model.royalFlg = true;
								model.contractType = '申込み不可';
								this.setViewMode('ロイヤル会員', '表示');
							}
							//その他はステータス表示
							else {
								let contractOption = null;
								let cnt = 0;
								model.royalFlg = false;
								if (this.m_Contract.option.length > 0) {
									contractOption = useOptionList.filter(item => item.optionCD === option.optionCD)
									contractOption = contractOption.sort(function(a, b) { return Number(b.startDate) - Number(a.startDate)});
									if (contractOption.length > 0) {
										model.status =  this.m_MypageWizardMngr.getMDisplayList(this, MypageDispdivcdConstants.TV_OPTIONSTATUS, '').filter(item => item.namecd === contractOption[0].statusCD)[0].dispnamep;
										model.statusCd = contractOption[0].statusCD;
									}
								}
								if (!model.status) {
									model.status = '未契約';
								}
								//未契約の場合,HVスターチャンネル、ダミー端末、解約日が空の場合は仮申込。その他は申込。
								const starCd = this.m_MypageWizardMngr.getMCommonList (this, 'opt', 'hv_channel', null, null)[0].commonValue;
								if (!model.statusCd) {
									if (starCd === option.optionCD || !this.m_TvBaseModel.dummy || (contractOption[0] && !contractOption[0].cancelDate))
										model.contractType = '仮申込み';
									else
										model.contractType = '申込み';
								}
								//契約中の場合,HVスターチャンネル、ダミー端末、解約日が空の場合は仮解約。その他は解約。
								else if (model.statusCd === '1') {
									const royalList = this.m_MypageWizardMngr.getMCommonList(this, 'srvtv', 'royal_op', null, null);
									const royalCd = royalList.filter(item => item.category3.substring(3) === option.optionCD);
									if (!optionName[0].genecd || optionName[0].genecd === '2') {
										if (contractOption[0] && contractOption[0].cancelDate) 
											model.contractType = '申込み不可';
										else if (starCd === option.optionCD || !this.m_TvBaseModel.dummy)
											model.contractType = '仮解約';
										else
											model.contractType = '解約';
									}
									else if (royalCd.length > 0) 
										model.contractType = '解約';
								}
								else 
									model.contractType = '申込み不可';
							}
						}
					}
					this.m_TvOptionListModel.push(model);
				}
			}
			if (this.m_TvBaseModel.error === 'optionNone')
				this.setViewMode('エラー', 'オプション');
			this.setListData('TvOptionListModel', this.m_TvOptionListModel);
			this.setListData('TvOptionListSPModel', this.m_TvOptionListModel);
			this.refreshView();
		});
	}
	
	/**
	 * 入力チェック（独自のValidationを実施する場合に使用する）
	 *	エラー追加例：validError.addError('エラーメッセージ');				// 相関チェックなどのエラー
	 * 				 this.setControlError('control', 'error');			 // コントロールのエラー
	 * 				 validError.showErrorMessage.('エラーメッセージ');	   // エラーメッセージ表示
	 * 		※上記のエラーを追加した場合は、アクションは実行しません。
	 * 		※コントロールの値をチェックする場合は、refreshModelは使用しないでください。
	 * 		　コントロールの値をチェックする場合は、コントロールをDIしてvalueを使用してください。
	 * 		　コントロールが双方向のコンバータを使用している場合は、dataValueを使用してください。
	 * @param actionName アクション名
	 * @param validError バリデーションエラー情報
	 * @param row 行情報
	 */
	protected onValid(actionName: string, validError: WprValidError, row?: any): void {
		if (actionName === 'nextCancel' || actionName === 'nextCancelSP') {
			let flg = false;
			for (const option of this.m_TvOptionListModel) {
				if (option.option === row.option && option.detail.optionCD === '0102000167') {
					const star = this.m_Contract.option.filter(item => item.optionCD === option.detail.optionCD);
					const cnt = star.length;
					if (star.length > 0) {
						const month = star[cnt-1].startDate.slice(4, 6);
						const date = new Date();
						if (Number(month) === date.getMonth()+1) 
							flg = true;
					}
				}
			}
			this.m_ErrorModelList = new Array();
			if (flg)	{
				const model = new ErrorModel();
				model.errorMessage = 'スターチャンネルはご契約月にご解約いただくことができません。';
				this.m_ErrorModelList.push(model);
				model.errorMessage = 'ご不明点がございましたらイッツコムカスタマーセンターへご連絡ください。';
				this.m_ErrorModelList.push(model);
			}
			if (this.m_ErrorModelList.length !== 0) {
				this.setViewMode('エラー','表示')
				this.setListData('ErrorModel', this.m_ErrorModelList);
				validError.addError('エラー');		
			}
			this.refreshListView('OptListModel');
		}
	}
	// --------------------------------------------------------------------------

	// アクション  ---------------------------------------------------------------
	/**
	 * 戻る
	 */
	public onBack(param: any): void {
		this.m_MypageWizardMngr.goBack(this);
	}

	/**
	 * 翌日開始
	 */
	public onNextDay(param: any, item: any): void {
		for (const option of this.m_TvOptionListModel) {
			if (option.option === item.option) {
				option.select = true;
				option.applyDate = '翌日';
			}
			else
				option.select = false;
		}
		if (item.contractType === '仮申込み') {
			this.m_TvWizardModel.provisionalFlg = true;
			this.m_TvWizardModel.cancelFlg = false;
			this.m_TvWizardModel.defaultFlg = false;
			this.m_TvWizardModel.proCancelFlg = false;
		}
		else {
			this.m_TvWizardModel.defaultFlg = true;
			this.m_TvWizardModel.cancelFlg = false;
			this.m_TvWizardModel.provisionalFlg = false;
			this.m_TvWizardModel.proCancelFlg = false;
		}
		this.refreshModel();
		this.m_TvBaseModel.error = null;
		this.m_MypageWizardMngr.goNext(this);
	}

	/**
	 * 来月開始
	 */
	public onNextMonth(param: any, item: any): void {
		for (const option of this.m_TvOptionListModel) {
			if (option.option === item.option) {
				option.select = true;
				option.applyDate = '翌月';
			}
			else
				option.select = false;
		}
		if (item.contractType === '仮申込み') {
			this.m_TvWizardModel.provisionalFlg = true;
			this.m_TvWizardModel.cancelFlg = false;
			this.m_TvWizardModel.defaultFlg = false;
			this.m_TvWizardModel.proCancelFlg = false;
		}
		else {
			this.m_TvWizardModel.defaultFlg = true;
			this.m_TvWizardModel.cancelFlg = false;
			this.m_TvWizardModel.provisionalFlg = false;
			this.m_TvWizardModel.proCancelFlg = false;
		}
		this.m_TvBaseModel.error = null;
		this.m_MypageWizardMngr.goNext(this);
	}

	/**
	 * 解約
	 */
	public onNextCancel(param: any, item: any): void {
		for (const option of this.m_TvOptionListModel) {
			if (option.option === item.option) 
				option.select = true;
			else
				option.select = false;
		}
		if (item.contractType === '解約') {
			this.m_TvWizardModel.cancelFlg = true;
			this.m_TvWizardModel.provisionalFlg = false;
			this.m_TvWizardModel.defaultFlg = false;
			this.m_TvWizardModel.proCancelFlg = false;
		}
		else {
			this.m_TvWizardModel.proCancelFlg = true;
			this.m_TvWizardModel.defaultFlg = false;
			this.m_TvWizardModel.cancelFlg = false;
			this.m_TvWizardModel.provisionalFlg = false;
		}
		this.m_TvBaseModel.error = null;
		this.m_MypageWizardMngr.goNext(this);
	}
	// --------------------------------------------------------------------------

	// privateメソッド -----------------------------------------------------------
	/**
	 * 機器名称取得
	 */
	private getTerminal(data: TvBaseModel): string {
		if (data.terminalCd === '1')
			return this.m_MypageWizardMngr.getMCommonList(this, 'hanyo1', null, null, null)[0].commonValue;
		else if (data.terminalCd === '2')
			return this.m_MypageWizardMngr.getMCommonList(this, 'hanyo2', null, null, null)[0].commonValue;
		else if (data.terminalCd === '3')
			return this.m_MypageWizardMngr.getMCommonList(this, 'hanyo3', null, null, null)[0].commonValue;
		else if (data.terminalCd === '4')
			return this.m_MypageWizardMngr.getMCommonList(this, 'hanyo4', null, null, null)[0].commonValue;
		else if (data.terminalCd === '5')
			return this.m_MypageWizardMngr.getMCommonList(this, 'hanyo5', null, null, null)[0].commonValue;
		return null;
	}
	
	/**
	 * お得パック表示判定
	 */
	private setOtokuArea() {
		const contract = this.m_MypageWizardMngr.getViewInfo().contractModel;
		const paymentList  = this.m_MypageWizardMngr.getViewInfo().customerPayment;
		if (paymentList && this.m_MypageWizardMngr.showOtokuPlan(this, paymentList[0].payment[0], contract.planList)) 
			this.setViewMode('お得パックエリア','表示');
	}
	// --------------------------------------------------------------------------
}
