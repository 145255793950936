import { WprModel } from '../../../../wpr-framework/view/deco/WprModel';
import { WprDI_ScopeModel } from '../../../../wpr-framework/view/di/WprDI_ScopeModel';
import { WizardPostModel } from '../../../models/WizardModel';
import { NameChangeAppModel } from '../../../service/models/apimodels/NameChangeAppModel';
import { GetNameChangeRequest } from '../../../service/models/dto/mypagerenewal/GetNameChangeRequest';
import { NameChangeService } from '../../../service/NameChangeService';
import { MypageBaseView } from '../../base/MypageBaseView';
import { ProgressBarData } from '../../common/progress_bar/ProgressBarView';
import { MypageWizardMngr } from '../../wizard/models/MypageWizardMngr';
import { WprViewMode } from '../../../../wpr-framework/view/deco/WprViewMode';
import { AgreeMode } from './mode/AgreeMode';

/**
 * 新名義人受付の案内
 */
export class NameChangeNewInformationView extends MypageBaseView {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() { super('NameChangeNewInformationView'); }
	// --------------------------------------------------------------------------
	// モデル  ------------------------------------------------------------------
	@WprDI_ScopeModel('MypageWizardMngr')
	private m_MypageWizardMngr: MypageWizardMngr = null;	// 画面ウィザード管理クラス
	@WprModel('NameChangeAppModel')
	private m_EntryInfo: NameChangeAppModel = null;	// 名義変更申込み情報モデル
	@WprModel('yakkanName')
	private m_YakkanName: string = null;					// タイトルコメント

	private m_NameChangeService: NameChangeService	= new NameChangeService();	// 名義変更サービス
	// --------------------------------------------------------------------------

	// サブビュー/モード  --------------------------------------------------------
	@WprViewMode
	private m_AgreeMode = new AgreeMode();	// 承認モード
	// --------------------------------------------------------------------------
	
	// override メソッド  --------------------------------------------------------
	/**
	 * コントロール定義設定
	 */
	public onRegisterConfig(): void {
		this.addConfig('confirm', '承諾', null);
		this.addConfig('yakkanName', 'タイトルコメント', 'yakkanName', { bindModel: true });
	}
	/**
	 * アクション登録
	 */
	public onRegisterAction(): void {
		this.addAction('next', this.onNext);
		this.addAction('yakkan', this.onYakkan);
	}
	/**
	 * ビュー表示通知
	 */
	 public onShowView(): void {
		let urlTokenCd = this.getUrlParam(WizardPostModel.URL_NCTOKEN);
		if (urlTokenCd !== null) {
			this.setCookie('urlTokenCd', urlTokenCd);
		} else {
			urlTokenCd = this.getCookie('urlTokenCd');
		}
		this.m_MypageWizardMngr.setFlow(this, 'name_change_new_information', (result) => {
			if (result) {
				this.m_MypageWizardMngr.initView(this, (result) => {
					this.registAccessLog();
			this.setStorageItem('b_url', window.location.href)
					this.m_YakkanName = 'マイページ利用規約'
					this.m_EntryInfo = this.m_MypageWizardMngr.getCache().nameChangeAppInfo;
					// 旧名義人入力情報の取得--------------------------
					const req: GetNameChangeRequest = new GetNameChangeRequest();
					req.tokenCd = urlTokenCd;
					this.m_NameChangeService.getNameChange(this, req, (result) => {
						if(result.changeStatus === '0') {	// トークンが有効なら'0'
							this.m_EntryInfo.nameChangeId = result.nameChangeId;
							this.m_EntryInfo.customerId = result.customerId;
							this.m_EntryInfo.nameChangeInfo = result.nameChangeInfo;
							this.setCookie(WizardPostModel.COOKIE_CUSTOMER_ID, this.m_EntryInfo.customerId, '/', 3600);
							const postData: WizardPostModel = new WizardPostModel();
							postData.customerId = this.getCookie(WizardPostModel.COOKIE_CUSTOMER_ID);
							this.setStorageItem('c_params', postData);
						} else {
							this.setCookie('changeStatus', result.changeStatus);
							this.pushPath('/nameChangeError');
						}
					});
					this.refreshView();
				});
			} else {
				this.showLogMessage('viewDefineが定義されていない');
			}
		});
		this.setViewMode('agree', '未承諾');
	}

	/**
	 * 値変更通知
	 * @param name 名前
	 * @param value 値
	 * @returns falseの場合、変更を取り消す
	 */
		 public onChangeValue(name: string, value: any): boolean {
			if (name === 'confirm') {
				if (value === true) {
					this.setViewMode('agree', '承諾');
				}
				else {
					this.setViewMode('agree', '未承諾');
				}
			}
			return true;
		}
	// --------------------------------------------------------------------------

	// アクション  ---------------------------------------------------------------
	/**
	 * 新名義人受付フォームへ
	 */
	public onNext(param: any): void {
		this.m_MypageWizardMngr.goNext(this);
	}
	
	/**
	 * 約款名
	 */
	 public onYakkan(param: any): void {
		window.open('https://www.itscom.co.jp/info/covenant/pdf/gjslkq000000x1dk-att/mypage.pdf');
	}
	// --------------------------------------------------------------------------
}
