import React, { ReactNode } from 'react';
import { IWprControlProps } from '../props/IWprControlProps';
import { WprActionInfo } from '../../action/WprActionInfo';
import { WprBaseControlComponent } from './WprBaseControlComponent';
import { WprControlVisibility } from '../props/IWprControlState';

/**
 * アンカーコントロールプロパティ情報
 */
interface IWprAnchorProps extends IWprControlProps {
	href?: string;		// href
	target?: string;	// ターゲット
}

/**
 * アンカーコントロール
 */
export class WprAnchor extends WprBaseControlComponent<IWprAnchorProps, HTMLAnchorElement> {
	// private 変数  ------------------------------------------------------------
	private m_ActionInfo: WprActionInfo		= null;		// アクション情報
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	constructor(props: IWprAnchorProps) {
		super(props);
		if (this.props.row)
			this.m_ActionInfo = this.props.view.getActionInfo(this.props.name, this.props.row.listName);
		else
			this.m_ActionInfo = this.props.view.getActionInfo(this.props.name);
		if (this.m_ActionInfo == null) {
			if (this.props.row)
				this.props.view.addErrorLog(`アクションが登録されていません。[${this.props.name}][${this.props.row.listName}]`);
			else
				this.props.view.addErrorLog(`アクションが登録されていません。[${this.props.name}]`);
		}
		else {
			this.setClickEvent(this.onClick.bind(this));
		}
	}
	// --------------------------------------------------------------------------

	// イベント  ----------------------------------------------------------------
	/**
	 * ボタンクリック処理
	 * @param event 変更イベント 
	 */
	public onClick(event: React.MouseEvent<HTMLAnchorElement>): void {
		if (this.props.href === '#')
			event.preventDefault();
		if (this.m_ActionInfo != null) {
			this.addClickEventLog(event.target as HTMLAnchorElement);
			if (this.props.row)
				this.m_ActionInfo.execute(true, event, this.props.row);
			else
				this.m_ActionInfo.execute(true, event);
		}
		else {
			this.props.view.addErrorLog(`アクションが登録されていません。[${this.props.name}]`);
		}
	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * 値取得
	 * @returns 値
	 */
	protected getValue(): any {
		return undefined;
	}

	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		if (this.state.visibility === WprControlVisibility.COLLAPSED)
			return <></>;
		const props = this.getProps();
		const {name, view, className, children, ...other} = this.props;
		const state = this.getState(['style', 'disabled']);
		const events = this.getEvents();
		let anchor = null;
		if (this.state.visibility === WprControlVisibility.VISIBLE) {
			anchor = <a {...props} {...state} {...other} {...events}>{this.props.children}</a>;
		}
		else {
			anchor = <a hidden {...props} {...state} {...other}>{this.props.children}</a>;
		}
		return (
			<>
				{anchor}
			</>
		);
	}
	// --------------------------------------------------------------------------
}