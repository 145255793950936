import { ProgressBarModel } from '../../../../models/ProgressBarModel';
import { MypageBaseView } from '../../../base/MypageBaseView';
import { ProgressBarData } from '../../../common/progress_bar/ProgressBarView';
import { WprListData } from '../../../../../wpr-framework/view/deco/WprListData';
import { MypageWizardMngr } from '../../../wizard/models/MypageWizardMngr';
import { WprDI_ScopeModel } from '../../../../../wpr-framework/view/di/WprDI_ScopeModel';
import { WprModel } from '../../../../../wpr-framework/view/deco/WprModel';
import { Contract as OwnerContract } from '../../../../models/bss/customer/Contract';
import { WprViewMode } from '../../../../../wpr-framework/view/deco/WprViewMode';
import { CouseListMode } from './mode/CouseListMode';
import { ObjectUtil } from '../../../../common/util/ObjectUtil';
import MypageDispdivcdConstants from '../../../../common/MypageDispdivcdConstants';
import { ItscomHomeCourseListModel } from '../../../../models/mypage/smart/ItscomHomeCourseListModel';
import { BaseInfo } from '../../../../models/mypage/smart/BaseInfo';

/**
 * ITSCOM HOME_追加申し込み(コース選択)
 */
export class ItscomHomeSelect1View extends MypageBaseView {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() { super('ItscomHomeSelect1View'); }
	// --------------------------------------------------------------------------

	// モデル  ------------------------------------------------------------------
	@WprDI_ScopeModel('MypageWizardMngr')
	private m_MypageWizardMngr: MypageWizardMngr			= null;	// 画面ウィザード管理クラス
	@WprListData('CourseListModel')
	private m_CourseListModel: ItscomHomeCourseListModel[]	= null;	// ItscomHomeコースモデル
	@WprModel('BaseInfo')
	private m_BaseInfo: BaseInfo							= null;	// 基本情報
	// --------------------------------------------------------------------------

	// サブビュー/モード  --------------------------------------------------------
	@WprViewMode
	private m_CouseListMode = new CouseListMode();	// コースリストモード
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * コントロール定義設定
	 */
	public onRegisterConfig(): void {
		this.addListConfig('course', 'コース', 'CourseListModel');
		this.addListConfig('amount', '金額', 'CourseListModel', { converter: '3桁カンマ' });
		this.addListConfig('check1', 'チェックボックス', 'CourseListModel');
		this.addListConfig('ownerIhPrice', 'オーナー契約IH', 'CourseListModel');
		this.addListConfig('otherPrice', 'オーナー契約IH以外', 'CourseListModel');
		this.addListConfig('IHflag', 'オーナーフラグ', 'BaseInfo');
	}

	/**
	 * アクション登録
	 */
	public onRegisterAction(): void {
		this.addAction('next', this.onNext);
		this.addAction('back', this.onBack);
	}

	/**
	 * ビュー表示通知
	 */
	public onShowView(): void {
		this.m_MypageWizardMngr.initView(this, (result) => {
			this.registAccessLog();
			this.setStorageItem('b_url', window.location.href)
			const sendData: ProgressBarData = new ProgressBarData();
			sendData.progressBarList = this.m_MypageWizardMngr.getProgressModelList(this);
			this.sendChildData('fP_ev_progressBarIntital', sendData);
			// 前画面の入力内容をクリア
			this.m_MypageWizardMngr.getCache().m_OptionListModel = new Array();
			this.m_MypageWizardMngr.getCache().m_BaseInfo.purchase = false;
			this.m_MypageWizardMngr.getCache().m_BaseInfo.yesOtoku = false;
			this.m_BaseInfo = this.m_MypageWizardMngr.getCache().m_BaseInfo;
			const ownerContractList = this.m_MypageWizardMngr.getViewInfo().ownerContractList;
			if (ObjectUtil.isNotNullOrUndefined(ownerContractList))
				this.checkOwnerIh(ownerContractList);	//オーナーIH判定
			this.setCourseList();
		});
	}

	/**
	 * リスト値変更通知
	 * @param name 名前
	 * @param listName リスト名
	 * @param row 行データ
	 * @param value 値
	 * @returns falseの場合、変更を取り消す
	 */
	public onChangeListValue(name: string, listName: string, row: any, value: any): boolean {
		if (name === 'check1') {
			// check全て外す
			for (const list of this.m_CourseListModel) {
				list.check1 = false;
			}
			row.check1 = true;
			this.refreshListView();
		}
		return true;
	}
	// --------------------------------------------------------------------------

	// アクション  ---------------------------------------------------------------
	/**
	 * 次へ
	 */
	public onNext(param: any): void {
		this.refreshModel();
		this.m_MypageWizardMngr.goNext(this);
	}

	/**
	 * 戻る
	 */
	public onBack(param: any): void {
		this.m_MypageWizardMngr.goBack(this);
	}
	// --------------------------------------------------------------------------

	// Privateメソッド  ---------------------------------------------------------
	/**
	 * コースリスト作成
	 */
	private setCourseList() {
		this.m_CourseListModel = this.m_MypageWizardMngr.getCache().m_CourseListModel;
		if (this.m_CourseListModel.length === 0) {
			const serviceList = this.m_MypageWizardMngr.getServiceList(this, MypageDispdivcdConstants.ITSCOMHOME_ADD_SERVICE);
			for (const list of serviceList) {
				let model = new ItscomHomeCourseListModel();
				model.course = list.display.dispnamep;	
				model.amount = this.m_MypageWizardMngr.getTaxFee(this, String(list.service.payment[0].amount)); 
				model.serviceCd = list.service.serviceCD;
				if (this.m_BaseInfo.ownerIhflag)
					model.ownerIhFlag = true;
				this.m_CourseListModel.push(model);
			}
			// デフォルトで一番上のコースにチェック
			this.m_CourseListModel[0].check1 = true;
		}
		// this.refreshListModel('CourseListModel');
		this.setListData('CourseListModel', this.m_CourseListModel);
	}

	/**
	 * オーナーIH判定
	 */
	private checkOwnerIh(ownerContractList: OwnerContract[]) {
		this.m_BaseInfo.ownerIhflag = false;
		const ownerIhList = this.m_MypageWizardMngr.getMCommonList(this,'IH', 'OWNER_SCD', null, null);
		for (const owner of ownerContractList) {
			for (const ownerList of ownerIhList) {
				if (owner.serviceCD === ownerList.commonValue) {
					this.m_BaseInfo.ownerIhflag = true;
					this.setViewMode('オーナー契約','IH');
					break;
				}
			}
		}
		// this.refreshModel();
	}

	// --------------------------------------------------------------------------
}
