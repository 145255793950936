import React, { ReactNode } from 'react';
import { WprBaseEventLogComponent } from '../controls/WprBaseEventLogComponent';

/**
 * メッセージ基本コンポーネント
 */
export abstract class WprBaseMessagePartsComponent<T, U> extends WprBaseEventLogComponent<T, U> {
	// private 変数  ------------------------------------------------------------
	private m_IsMount: boolean 			= false;	// なぜか2個作られてエラーになる現象を回避するために使用する
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** マウントフラグ */
	public get isMount(): boolean 		{ return this.m_IsMount;		}
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	constructor(props: T) {
		super(props);
	}
	// --------------------------------------------------------------------------

	// abstract メソッド  --------------------------------------------------------
	/**
	 * 描画
	 */
	public abstract onRender(): ReactNode;
	// --------------------------------------------------------------------------

	// virtual メソッド  --------------------------------------------------------
	/**
	 * マウント通知
	 */
	public onDidMount() {}
	// --------------------------------------------------------------------------

	// ライフサイクル  -----------------------------------------------------------
	/**
	 * マウント通知
	 */
	public componentDidMount() {
		this.m_IsMount = true;
		this.onDidMount();
	}

	/**
	 * アンマウント通知
	 */
	public componentWillUnmount() {
		this.m_IsMount = false;
	}

	/**
	 * 描画
	 */
	public render(): ReactNode {
		return this.onRender();
	}
	// --------------------------------------------------------------------------
}
