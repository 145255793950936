import React, { ReactNode } from 'react';
import { WprFramework } from '../../WprFramework';
import { WprBaseCssModuleComponent } from './WprBaseCssModuleComponent';

/**
 * コントロール基本コンポーネント
 */
export abstract class WprBaseComponent<T, U> extends WprBaseCssModuleComponent<T, U> {
	// private 変数  ------------------------------------------------------------
	private m_IsMount: boolean 		= false;	// なぜか2個作られてエラーになる現象を回避するために使用する
	private m_IsPropsCheck 			= false;	// プロパティチェックフラグ	
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** マウントフラグ */
	public get isMount(): boolean 		{ return this.m_IsMount;		}
	// --------------------------------------------------------------------------

	// abstract メソッド  --------------------------------------------------------
	/**
	 * 描画
	 */
	public abstract onRender(): ReactNode;

	/**
	 * コントロール名称取得
	 * @returns コントロール名称
	 */
	public abstract getName(): string;
	// --------------------------------------------------------------------------

	// virtual メソッド  --------------------------------------------------------
	/**
	 * マウント通知
	 */
	public onDidMount() {}

	/**
	 * アンマウント通知
	 */
	public onWillUnmount() {}

	/**
	 * プロパティチェック処理
	 */
	public onCheckProps(): void {}
	// --------------------------------------------------------------------------

	// ライフサイクル  -----------------------------------------------------------
	/**
	 * マウント通知
	 */
	public componentDidMount() {
		this.m_IsMount = true;
		this.onDidMount();
	}

	/**
	 * アンマウント通知
	 */
	public componentWillUnmount() {
		this.m_IsMount = false;
		this.onWillUnmount();
	}

	/**
	 * 描画
	 */
	public render(): ReactNode {
		this.addInfoLog(`Control render() [${this.constructor.name}]`);
		let rnode = null;
		if (this.m_IsMount === true) {
			if (this.m_IsPropsCheck === false) {
				this.m_IsPropsCheck = true;
				this.onCheckProps();
			}
			rnode = this.onRender() as any;
		}
		else {
			// return React.createElement(Fragment);
			rnode = this.onRender() as any;
		}
		if (this.getStylesObject() != null) {
			const child = this.checkChildClassName(rnode);
			let clsName = this.getLocalClassName(this.getClassName(rnode));
			if (child != null || clsName != null) {
				let props = this.getNodeProps(rnode.props, clsName);
				return React.createElement(rnode.type, props, child);
			}
		}
		return rnode;
	}
	// --------------------------------------------------------------------------

	// protected メソッド  -------------------------------------------------------
	/**
	 * デバックログ出力
	 * @param msg メッセージ
	 */
	protected addDebugLog(msg: string): void {
		WprFramework.getInstance().error.addDebugLog(this.getName(), msg);
	}

	/**
	 * 情報ログ出力
	 * @param msg メッセージ
	 */
	protected addInfoLog(msg: string): void {
		WprFramework.getInstance().error.addInfoLog(this.getName(), msg);
	}

	/**
	 * 警告ログ出力
	 * @param msg メッセージ
	 */
	protected addWarningLog(msg: string): void {
		WprFramework.getInstance().error.addWarningLog(this.getName(), msg);
	}

	/**
	 * エラーログ出力
	 * @param msg メッセージ
	 * @param ex 例外情報
	 */
	protected addErrorLog(msg: string, ex?: Error): void {
		WprFramework.getInstance().error.addErrorLog(this.getName(), msg, ex);
	}
	// --------------------------------------------------------------------------
}
