import { Divider, TableCell, Typography } from '@material-ui/core';
import React, { ReactNode } from 'react';
import { MatOneRowTable } from '../../../../../wpr-framework/component/controls/material/MatOneRowTable';
import { MatTypography } from '../../../../../wpr-framework/component/controls/material/MatTypography';
import { WprContainer } from '../../../../../wpr-framework/component/controls/WprContainer';
import { WprBaseDialogViewComponent } from '../../../../../wpr-framework/view/component/WprBaseDialogViewComponent';
import { MvnoFeeDialogView } from './MvnoFeeDialogView';

import styles from './MvnoFeeDialog.scss';
import { MvnoEntryDetailParts } from '../../entry/parts/mvno_entry_detail_parts/MvnoEntryDetailParts';

/**
 * MVNO_料金明細ダイアログ用コンポーネント
 */
export class MvnoFeeDialog extends WprBaseDialogViewComponent {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: any) { super(new MvnoFeeDialogView(), props); }
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** MVNO_料金明細ダイアログビュー情報 */
	public get viewInfo(): MvnoFeeDialogView { return this.view as MvnoFeeDialogView; }
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * スタイルオブジェクト取得
	*/
	public getStylesObject(): any { return styles; }

	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		return (
			<>
				<div className='dialogHeader'>
					<Typography className='title'>現在の料金</Typography>
				</div>
				<div className='dialogContent'>
					<MvnoEntryDetailParts name='entryDetailParts' view={this.view} />
				</div>
			</>
		);
	}
	// --------------------------------------------------------------------------
}
