import { WprBaseViewCore } from '../../wpr-framework/view/WprBaseViewCore';
import { BaseService } from './base/BaseService';
import { GetNameChangeRequest } from './models/dto/mypagerenewal/GetNameChangeRequest';
import { GetNameChangeResponse } from './models/dto/mypagerenewal/GetNameChangeResponse';
import { RegistNameChangeRequest } from './models/dto/mypagerenewal/RegistNameChangeRequest';
import { RegistNameChangeResponse } from './models/dto/mypagerenewal/RegistNameChangeResponse';
import { UpdateNameChangePaymentRequest } from './models/dto/mypagerenewal/UpdateNameChangePaymentRequest';
import { UpdateNameChangeResponse } from './models/dto/mypagerenewal/UpdateNameChangeResponse';

/**
 * 名義変更サービス
 */
export class NameChangeService extends BaseService {
	// サービス メソッド  --------------------------------------------------------
	/**
	 * 旧名義人入力情報 登録
	 * @param viewCore ビュー情報
	 * @param request リクエスト情報
	 * @param resMethod 結果処理メソッド
	 */
	public registNameChange(viewCore: WprBaseViewCore, request: RegistNameChangeRequest, resMethod: (result: RegistNameChangeResponse) => void): void {
		return this.doPost(viewCore, '/nameChange/RegistNameChange', request, resMethod);
	}
	/**
	 * 旧名義人入力情報 取得
	 * @param viewCore ビュー情報
	 * @param request リクエスト情報
	 * @param resMethod 結果処理メソッド
	 */
	public getNameChange(viewCore: WprBaseViewCore, request: GetNameChangeRequest, resMethod: (result: GetNameChangeResponse) => void): void {
		return this.doPost(viewCore, '/nameChange/GetNameChange', request, resMethod);
	}
	/**
	 * 新名義人支払情報 登録
	 * @param viewCore ビュー情報
	 * @param request リクエスト情報
	 * @param resMethod 結果処理メソッド
	 */
	public updateNameChange(viewCore: WprBaseViewCore, request: UpdateNameChangePaymentRequest, resMethod: (result: UpdateNameChangeResponse) => void): void {
		return this.doPost(viewCore, '/nameChange/UpdateNameChange', request, resMethod);
	}
	// --------------------------------------------------------------------------
}
