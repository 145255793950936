import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Paper, Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import React, { ReactNode } from 'react';
import Draggable from 'react-draggable';
import { WprMessageComponent } from '../../../wpr-framework/component/message/WprMessageComponent';
import { WprSnackbarComponent } from '../../../wpr-framework/component/message/WprSnackbarComponent';
import { IWprControlEvents } from '../../../wpr-framework/component/props/IWprControlEvents';
import { IWprDialog } from '../../../wpr-framework/view/dialog/IWprDialog';
import { WprBaseDialogViewInfo } from '../../../wpr-framework/view/dialog/WprBaseDialogViewInfo';
import { IWprDialogViewProps } from '../../../wpr-framework/view/props/IWprDialogViewProps';
import { WprBaseViewCoreComponent } from '../../../wpr-framework/view/component/WprBaseViewCoreComponent';
import { WprBaseViewInfo } from '../../../wpr-framework/view/WprBaseViewInfo';
import { WprBaseDialogViewComponentCore } from '../../../wpr-framework/view/component/WprBaseDialogViewComponentCore';
import closeIcon from '/shared/image/usagedetails/cancelBlack.png';

/**
 * ダイアログイベント情報
 */

interface CPlusDialogProps extends IWprDialogViewProps {
	subtitle: string;		//サブタイトル
}

/**
 * 利用明細：グラフダイアログビューコンポーネント基本クラス
 * ・ケーブルプラス電話グラフ
 * ・東急でんき＆ガス　グラフ
 * ・PDFダウンロードダイアログ
 */
export abstract class UsageCplusGraphDialogViewComponent extends WprBaseDialogViewComponentCore<CPlusDialogProps> {
	// コンストラクタ  -----------------------------------------------------------
	constructor(viewInfo: WprBaseViewInfo , props: CPlusDialogProps) {
		super(viewInfo, props);
	}
	// --------------------------------------------------------------------------

	// override メソッド  ---------------------------------------------------------
	/**
	 * ダイアログタイトル取得
	 * @returns ダイアログタイトル
	 */
	protected getDialogTitle(): ReactNode {
		if (this.m_DialogViewInfo != null && this.m_DialogViewInfo.useHeader === true) {
			let title = this.getTitle();
			let subtitle = this.getSubTitle();
			if (!title)
				title = '　';
			if (!subtitle)
				subtitle = '　';
			return (
				<DialogTitle disableTypography style={{cursor: 'default'}}>
					<Typography variant='subtitle1' id='draggable-dialog-title'>{title}</Typography>
					<Typography variant='body1'>{subtitle}</Typography>
					<IconButton aria-label='close' {...this.m_CancelEvents} style={this.m_CloseButtonStyle}>
						<CloseIcon />
					</IconButton>
				</DialogTitle>
			);
		}
		else {
			return <></>;
		}
	}

	/**
	 * ボタンコンポーネント取得
	 * @returns ボタンコンポーネント
	 */
	protected getButtons(): ReactNode {
		let cancel = '閉じる';
		if (this.m_DialogViewInfo) {
			cancel = this.m_DialogViewInfo.cancelButtonLabel;
			if (this.m_DialogViewInfo.useOKButton === false) {
				if (this.m_DialogViewInfo.useCancelButton === true) {
					if (cancel == null)
						cancel = '閉じる';
					return (
						<>
							<Button name='wpr-dialog-close' variant='contained' color='primary' {...this.m_CancelEvents}>{cancel}
								<div className='cancelImg'>
									<img src={closeIcon} />
								</div>
							</Button>

						</>
					);
				}
				return <></>;
			}
			if (cancel == null)
				cancel = 'キャンセル';
		}
		return (
			<>
				<Button name='wpr-dialog-cancel' variant='contained' color='primary' {...this.m_CancelEvents}>{cancel}</Button>
			</>
		);
	}

	// --------------------------------------------------------------------------
	
	/**
	 * ダイアログサブタイトル取得
	 * @returns ダイアログタイトル
	 */
	private getSubTitle(): string {
		if (!this.props)
			return '';
		if (this.props.subtitle)
			return this.props.subtitle;
		return this.props.subtitle;
	}
	// --------------------------------------------------------------------------

}