import { WprModel } from '../../../../wpr-framework/view/deco/WprModel';
import { WprDI_ScopeModel } from '../../../../wpr-framework/view/di/WprDI_ScopeModel';
import { AccesspassModel } from '../../../service/models/apimodels/AccesspassModel';
import { MypageBaseView } from '../../base/MypageBaseView';
import { CompleteModel } from '../../common/common_complete/models/CompleteModel';
import { ProgressBarData } from '../../common/progress_bar/ProgressBarView';
import { MypageWizardMngr } from '../../wizard/models/MypageWizardMngr';

/**
 * アクセスアカウントパスワード変更完了画面
 */
export class AddAccesspassCompleteView extends MypageBaseView {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() { super('AddAccesspassCompleteView'); }
	// --------------------------------------------------------------------------
	@WprDI_ScopeModel('MypageWizardMngr')
	@WprModel('MypageWizardMngr')
	private m_MypageWizardMngr: MypageWizardMngr = null;		// 画面ウィザード管理クラス
	@WprModel('AccesspassModel')
	private m_AccesspassModel: AccesspassModel = null;	// コース
	@WprModel('CompleteModel')
	private m_CompleteModel: CompleteModel = null;	// 完了情報モデル

	// メンバ変数 ---------------------------------------------------------------
	private m_CustomerName: string = null;	// 顧客名
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------

	/**
	 * コントロール定義設定
	 */
	public onRegisterConfig(): void {
		this.addConfig('user', 'ユーザー', 'CompleteModel', { bindName: 'user' });
		this.addConfig('course', 'コース', 'AccesspassModel');
		this.addConfig('radiusAccount', 'アクセスアカウント', 'AccesspassModel');
		this.addConfig('date', '変更日', 'AccesspassModel', { converter: '日付（yyyy年MM月dd日）' });
	}

	/**
 * アクション登録
 */
	public onRegisterAction(): void {
		this.addAction('toQuestionnaire', this.onToQuestionnaire);
		this.addAction('back', this.onBack);
		this.addAction('print', this.onPrintView);
	}

	/**
	 * ビュー表示通知
	 */
	public onShowView(): void {
		this.m_MypageWizardMngr.initView(this, (result) => {
			this.registAccessLog();
			this.setStorageItem('b_url', window.location.href)
			const customerInfo = this.m_MypageWizardMngr.getViewInfo().customer;
			const sendData: ProgressBarData = new ProgressBarData();
			sendData.progressBarList = this.m_MypageWizardMngr.getProgressModelList(this);
			this.sendChildData('fP_ev_progressBarIntital', sendData);
			this.m_AccesspassModel = this.m_MypageWizardMngr.getCache().m_AccesspassInfo
			if (customerInfo.sexCD === '3' || customerInfo.sexCD === '') {
				this.m_CustomerName = customerInfo.corporateName;
			} else {
				this.m_CustomerName = customerInfo.surName + ' ' + customerInfo.givenName;
			}
			const model = new CompleteModel();
			model.user = this.m_CustomerName + 'さん';
			this.m_CompleteModel = model;
			this.refreshView();
		});
		this.refreshView();
	}
	// --------------------------------------------------------------------------

	// アクション  ---------------------------------------------------------------
	/**
	 * アンケート
	 */
	public onToQuestionnaire(param: any): void {
		window.open('https://questant.jp/q/B7KS5E4S?id=' + this.getCustomerId());
	}

	/**
	 * 契約内容の確認・変更メニューへ
	 */
	public onBack(param: any): void {
		location.href = this.getOldUrl() + 'Srvlist/Continfo_list/';
	}

	/**
	 * 印刷画面へ
	 */
	public onPrintView(param: any): void {
		window.open('accesspass_print');
	}

	// --------------------------------------------------------------------------
}
