import React from "react";
import { ObjectUtil } from "./ObjectUtil";

export class ConvertViewUtil {
    /**
     * 改行変換
	 * 「\n」→「<br/>」
     * @param str 
     * @returns 
     */
	public static convertIndention(str: string): any {
		const contentList = new Array();
		if (str != null) {
			const slist = str.split('\n');
			for (const line of slist) {
				if (contentList.length > 0)
					contentList.push(<br/>);
				contentList.push(line);
			}
		}
		return contentList;
	}

	/**
	 * お客様氏名変換
	 * @param sei 
	 * @param mei 
	 */
	public static convertCustName(sei: string, mei: string): string {
		if (ObjectUtil.isNullOrEmptyStr(mei)) {
			// 名が空の場合(法人)
			return sei;
		} 
		// 個人
		return sei + '　' + mei;
	}

}