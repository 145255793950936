import { WizardModel } from '../../models/WizardModel';
import { MypageWizardFlow } from './MypageWizardFlow';
import { MypageWizardMngr } from './models/MypageWizardMngr';
import { CommonCompleteView } from '../common/common_complete/CommonCompleteView';
import { ModNetConfirmView } from '../Srvnet/revise/mod_net_confirm/ModNetConfirmView';
import { ModOthernetSelectView } from '../Srvnet/revise/mod_othernet_select/ModOthernetSelectView';
import { GetViewInfoRequest } from '../../service/models/dto/mypagerenewal/GetViewInfoRequest';
import { MypageBaseView } from '../base/MypageBaseView';
import { ModNetSelectView } from '../Srvnet/revise/mod_net_select/ModNetSelectView';
import { MDisplay } from '../../service/models/entity/secondary/MDisplay';
import MypageDispdivcdConstants from '../../common/MypageDispdivcdConstants';
import { ContractInfo } from '../Srvnet/revise/mod_net_select/models/ContractInfo';
import { MCommon } from '../../service/models/entity/primary/MCommon';
import { ContractServiceModel } from '../../service/models/apimodels/ContractServiceModel';
import { ModNetSettingView } from '../Srvnet/revise/mod_net_setting/ModNetSettingView';
import { BaseInfoListModel } from '../Srvnet/revise/mod_net_select/models/BaseInfoListModel';
import { MGeneral } from '../../service/models/entity/MGeneral';
import { CommonAgreeView } from '../common/common_agree/CommonAgreeView';
import { YakkanModel, NetServiceNewWizardModel } from './NetServiceNewWizardFlow';
import { RegistInfoRequest } from '../../service/models/dto/mypagerenewal/RegistInfoRequest';
import { InquiryMailInfo } from '../../service/models/apimodels/InquiryMailInfo';
import { ObjectUtil } from '../../common/util/ObjectUtil';
import { RegistContractRequest } from '../../service/models/dto/mypagerenewal/RegistContractRequest';
import MypageCodeTypeConstants from '../../common/MypageCodeTypeConstants';
import { CourseListModel } from '../../models/mypage/net/CourseListModel';
import { NetFlowModel } from '../../models/mypage/net/NetFlowModel';
import { ModOthernetConfirmView } from '../Srvnet/revise/mod_othernet_confirm/ModOthernetConfirmView';
import { CustomerSupportView } from '../common/customer_support/CustomerSupportView';
import { MAIL_CONSTANTS } from '../../common/MyPageMailIdConstants';
import MypageYakkanConstants from '../../common/MypageYakkanConstants';
import { MCampaign } from '../../service/models/entity/primary/MCampaign';
/**
 * ITSCOM HOME_追加ウィザードクラス
 */
export class NetServiceChangeWizardFlow extends MypageWizardFlow {
	// private 変数  ------------------------------------------------------------
	private m_DefaultWizardFlow: WizardModel[]			= null;		// 画面ウィザードリスト
	private m_OtherWizardFlow: WizardModel[]			= null;		// 画面ウィザードリスト(その他サービス(その他))
	private m_KattobiWizardFlow: WizardModel[]			= null;		// 画面ウィザードリスト(その他サービス(かっとびプラン))
	private m_StartViewName: string						= null;
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	public constructor() {
		super();
		this.m_StartViewName = new ModNetSelectView().name;
		// 遷移パターン(通常)
		this.m_DefaultWizardFlow = new Array();
		this.m_DefaultWizardFlow.push({stepNum: 0, title: '契約選択', viewName: null, path: this.getOldUrl() + 'Srvlist/Continfo_list/'});
		this.m_DefaultWizardFlow.push({stepNum: 1, title: 'コース選択', viewName: this.m_StartViewName, path: 'mod_net_select'});
		this.m_DefaultWizardFlow.push({stepNum: 2, title: 'コース内容の選択', viewName: new ModNetSettingView().name, path: 'mod_net_setting'});
        this.m_DefaultWizardFlow.push({stepNum: 3, title: '申込み内容の確認', viewName: new ModNetConfirmView().name, path: 'mod_net_confirm'});
		this.m_DefaultWizardFlow.push({stepNum: 4, title: '契約約款', viewName: new CommonAgreeView().name, path: 'service_net_useagree'});
        this.m_DefaultWizardFlow.push({stepNum: 5, title: '申込み完了', viewName: new CommonCompleteView().name, path: 'mod_net_complete'});
		//　遷移パターン(その他サービス)
		this.m_OtherWizardFlow = new Array();
		this.m_OtherWizardFlow.push(this.m_DefaultWizardFlow[0]);
		this.m_OtherWizardFlow.push(this.m_DefaultWizardFlow[1]);
		this.m_OtherWizardFlow.push({stepNum: 2, title: 'コース内容の選択', viewName: new ModOthernetSelectView().name, path: 'mod_othernet_select'});
		this.m_OtherWizardFlow.push(this.m_DefaultWizardFlow[3]);
		this.m_OtherWizardFlow.push(this.m_DefaultWizardFlow[4]);
		this.m_OtherWizardFlow.push(this.m_DefaultWizardFlow[5]);
		// 遷移パターン(その他サービス(かっとびプラン))
		this.m_KattobiWizardFlow = new Array();
		this.m_KattobiWizardFlow.push(this.m_DefaultWizardFlow[0]);
		this.m_KattobiWizardFlow.push(this.m_DefaultWizardFlow[1]);
		this.m_KattobiWizardFlow.push({stepNum: 2, title: 'コース内容の選択', viewName: new ModOthernetSelectView().name, path: 'mod_othernet_select'});
		this.m_KattobiWizardFlow.push({stepNum: 3, title: '申込み内容の確認', viewName: new ModOthernetConfirmView().name, path: 'mod_othernet_confirm'});
		this.m_KattobiWizardFlow.push(this.m_DefaultWizardFlow[4]);
		this.m_KattobiWizardFlow.push({stepNum: 5, title: '申込み完了', viewName: new CommonCompleteView().name, path: 'mod_kattobi_complete'});
	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * 画面ウィザードフロー名取得
	 */
	public getWizardFlowName(viewName: string): string {
		if (viewName === this.m_StartViewName)
			return this.constructor.name;
		return null;
	}
	
	/**
	 * 画面ウィザードリスト取得：契約状態で切り替えたり、選択肢で切り替えたり
	 */
	public getWizardFlow(mngr: MypageWizardMngr): WizardModel[] {
		let cacheData: NetServiceChangeWizardModel = mngr.getCache();
		//　コース内容選択
		if (cacheData.m_NetFlowModel.defaultFlg) 
			return this.m_DefaultWizardFlow;
		// その他サービス(通常)
		if (cacheData.m_NetFlowModel.otherFlg)
			return this.m_OtherWizardFlow;
		// その他サービス(かっとびプラン)
		if (cacheData.m_NetFlowModel.kattobiFlg)
			return this.m_KattobiWizardFlow;
		return this.m_DefaultWizardFlow;
	}
	
	/**
	 * 画面ウィザードAPI用モデル取得：契約状態で切り替えたり、選択肢で切り替えたり
	 */
	public getWizardApiModel(mngr: MypageWizardMngr, view: MypageBaseView): GetViewInfoRequest {
		const req = new GetViewInfoRequest();
		req.customerId = mngr.getParams().customerId;
		req.displayList = new Array();
		req.commonList = new Array();
		req.serviceKey = new Array();
		req.statusCdList = new Array();
		req.statusCdList.push('0');
		req.statusCdList.push('1');
		req.statusCdList.push('2');
		req.statusCdList.push('5');
		req.masterFlg = true;
		req.paymentFlg = true;
		req.contractFlg = true;
		req.ownerFlg = true;
		req.buildingFlg = true;
		req.serviceLineFlg = true;
		// 約款
		const commonY = new MCommon('コース(約款)', 'namecd', 'course_net', null);
		req.commonList.push(commonY);
		if (ObjectUtil.isNotNullOrUndefined(mngr.getViewInfo())) {
			req.yakkanList = new Array();
			let yakkan_1 : YakkanModel = new YakkanModel(MypageYakkanConstants.ITSCOM_SERVICE);
			let yakkan_2 : YakkanModel = new YakkanModel(MypageYakkanConstants.ITSCOM_PRICE);
			let yakkan_7 : YakkanModel = new YakkanModel(MypageYakkanConstants.INTENET_CATV);
			let yakkan_8 : YakkanModel = new YakkanModel(MypageYakkanConstants.IMPORTANT_HIKARI);
			let yakkan_9 : YakkanModel = new YakkanModel(MypageYakkanConstants.ITSCOM_HIKARI_INTERNET);
			let yakkan_19 : YakkanModel = new YakkanModel(MypageYakkanConstants.ITSCOM_SERVICE_IMPORTANT);
			let cacheData: NetServiceNewWizardModel = mngr.getCache();
			let yakkanList = mngr.getMCommonList(view, 'namecd', 'course_net', null, null);
			yakkan_1.select = true;	// 共通約款
			yakkan_2.select = true;	// 料金表
			yakkan_19.select = true;// 重要説明事項
			let noHirariFlg = false;
			// 通常コース判定
			const normalList = new Array();
			const normalKeyList: string[] = ['m160', 'wide', 'plus', 'just', 'wide2year', 'm1602year'];
			for (const key of normalKeyList) {
				const normal = yakkanList.filter(data => data.category3 === key);
				if (normal && normal.length > 0)
					normalList.push(normal[0]);
			}
			for (const normal of normalList) {
				if (cacheData.m_NetInfo.aftServiceCd === normal.commonValue) {
					yakkan_7.select = true;
					noHirariFlg = true;
					break;
				}
			}
			// かっとびコース判定
			const kattobiList = new Array();
			const kattobiKeyList: string[] = ['fG', 'fHS', 'fT', 'mG', 'mHS', 'mT'];
			for (const key of kattobiKeyList) {
				const kattobi = yakkanList.filter(data => data.category3 === key);
				if (kattobi && kattobi.length)
					kattobiList.push(kattobi[0]);
			}
			for (const kattobi of kattobiList) {
				if (cacheData.m_NetInfo.aftServiceCd === kattobi.commonValue) {
					yakkan_8.select = true;
					noHirariFlg = true;
					break;
				}
			}
			// 通常コース、かっとびコース以外でFTTHの場合、イッツコム光約款を表示
			const customer = mngr.getViewInfo().customer;
			if (!noHirariFlg && customer.building[0].buildingLineTypeCD === '2')
				yakkan_9.select = true;
			// 非表示リスト判定
			const hiddenList = new Array();
			const hiddenKeyList: string[] = ['fl_ADSL', 'fl_FTTH', 'fl_DIAL', 'au_FTTH', 'value'];
			for (const key of hiddenKeyList) {
				const hidden = yakkanList.filter(data => data.category3 === key);
				if (hidden && hidden.length > 0) 
					hiddenList.push(hidden[0]);
			}
			for (const hidden of hiddenList) {
				if (cacheData.m_NetInfo.aftServiceCd === hidden.commonValue) {
					yakkan_7.select = false;
					yakkan_8.select = false;
					yakkan_9.select = false;
					break;
				}
			}
			const yakkanModelList : YakkanModel[] = new Array();
			yakkanModelList.push(yakkan_19);
			yakkanModelList.push(yakkan_1);
			yakkanModelList.push(yakkan_7);
			yakkanModelList.push(yakkan_8);
			yakkanModelList.push(yakkan_9);
			yakkanModelList.push(yakkan_2);
			for (const yakkanModel of yakkanModelList) {
				if (yakkanModel.select)
					req.yakkanList.push(new MGeneral('040', yakkanModel.yakkanNum));	// ★'040'の定数探す
			}
		}
		/** 変更申込み コース選択 */
		// 契約中のコース名(月額)《CD0051》
		req.displayList.push(new MDisplay(MypageDispdivcdConstants.ACCESSPASS_SERVICE));
		const service5 = new ContractServiceModel();
		service5.serviceCd = MypageDispdivcdConstants.ACCESSPASS_SERVICE;	// 契約中のコースの月額金額取得のため
		service5.contractId = mngr.getParams().contractId;
		req.serviceKey.push(service5);
		// 契約ステータス《CD0010》
		req.displayList.push(new MDisplay(MypageDispdivcdConstants.ITSCOMHOME_SERVICESTATUS));
		// 機器の種類《CD0032》
		req.displayList.push(new MDisplay(MypageDispdivcdConstants.ITSCOMHOME_TERMINALTYPE));
		// 機器の提供方法《CD0061》
		req.displayList.push(new MDisplay(MypageDispdivcdConstants.ITSCOMHOME_TERMINALSTATUS));
		// 機器の種類(10000)
		const common1 = new MCommon('機器の種類(10000)', 'Add_net', 'equ', 'rep');
		req.commonList.push(common1);
		// アクセスアカウント
		const common2 = new MCommon('アクセスアカウント表示サービス', 'srvnet', 'accessaccount', 'serviceid');
		req.commonList.push(common2);
		// ダミー端末
		const common3 = new MCommon('ダミー端末チェック', 'Dummy_Equipment', 'dummyid');
		req.commonList.push(common3);
		// 物件プラン
		const common4 = new MCommon('物件プラン取得', 'building_plan');
		req.commonList.push(common4);
		// オーナー契約IH
		const common5 = new MCommon('オーナー契約IHリスト', 'IH', 'OWNER_SCD');
		req.commonList.push(common5);
		// コース情報
		req.displayList.push(new MDisplay(MypageDispdivcdConstants.ITSCOMNET_COURSE_FTTH_APART_FTTH));	// FTTH/集合/物件プラン(FTTH)
		const service1 = new ContractServiceModel();
		service1.serviceCd = MypageDispdivcdConstants.ITSCOMNET_COURSE_FTTH_APART_FTTH;
		req.serviceKey.push(service1);
		req.displayList.push(new MDisplay(MypageDispdivcdConstants.ITSCOMNET_COURSE_FTTH_DETACH));		// FTTH/戸建賃貸/物件プラン(FTTH) または FTTH/戸建賃貸
		const service2 = new ContractServiceModel();
		service2.serviceCd = MypageDispdivcdConstants.ITSCOMNET_COURSE_FTTH_DETACH;
		req.serviceKey.push(service2);
		req.displayList.push(new MDisplay(MypageDispdivcdConstants.ITSCOMNET_COURSE_FTTH_OTHER));		// FTTH/集合・戸建賃貸/物件プラン(その他)	
		const service3 = new ContractServiceModel();
		service3.serviceCd = MypageDispdivcdConstants.ITSCOMNET_COURSE_FTTH_OTHER;
		req.serviceKey.push(service3);
		req.displayList.push(new MDisplay(MypageDispdivcdConstants.ITSCOMNET_COURSE_OTHER));			// FTTH以外
		const service4 = new ContractServiceModel();
		service4.serviceCd = MypageDispdivcdConstants.ITSCOMNET_COURSE_OTHER;
		req.serviceKey.push(service4);
		// 回線種別
		req.displayList.push(new MDisplay(MypageDispdivcdConstants.ITSCOMNET_LINETYPE));
		// 光配線方式コース
		const common6 = new MCommon('光配線方式コース', 'srvnet', 'hikari_line_course');
		req.commonList.push(common6);
		// お得パック
		const common7 = new MCommon('お得パック判定', 'otoku', 'plan_cd');
		req.commonList.push(common7);
		req.displayList.push(new MDisplay(MypageDispdivcdConstants.ITSCOMHOME_OTOKUNAME));
		// equipmentTypeCD判定
		const common15 = new MCommon('equipmentTypeCD判定', 'Add_net', 'equ', 'req');
		req.commonList.push(common15);
		const common16 = new MCommon('equipmentTypeCD判定', 'Add_net', 'equ', 'req2');
		req.commonList.push(common16);
		// その他コース
		req.displayList.push(new MDisplay(MypageDispdivcdConstants.ITSCOMNET_OTHERCOURSE_NAME));
		const service6 = new ContractServiceModel();
		service6.serviceCd = MypageDispdivcdConstants.ITSCOMNET_OTHERCOURSE_NAME;
		req.serviceKey.push(service6);
		const common = new MCommon('かっとびバリュー','namecd', 'course_net','value');
		req.commonList.push(common);
		const common14 = new MCommon('','srvnet', 'ftth_wire_sv','wire_disp_sv');
		req.commonList.push(common14);
		const common17 = new MCommon('ケーブルモデム（wi-fi）','cable_modem_wifi');
		req.commonList.push(common17);
		const common18 = new MCommon('ケーブルモデム','cable_modem_wired');
		req.commonList.push(common18);
		const common19 = new MCommon('ケーブルモデム','srvnet','cable_modem');
		req.commonList.push(common19);
		const common20 = new MCommon('ケーブルモデム','srvnet','d_onu_code');
		req.commonList.push(common20);
		/** 完了処理 */
		// IPオプション
		const ipList: string[] = ['fl_ADSL', 'fl_FTTH', 'fl_DIAL', 'value'];
		for(const ip of ipList) {
			const common = new MCommon('IPオプション判定', 'namecd', 'course_net', ip);
			req.commonList.push(common);
		}
		// IPアドレスタイプ名
		req.displayList.push(new MDisplay(MypageDispdivcdConstants.IPADDRESS_TYPE));
		// グローバルIP・プライベートIP判定
		const common21 = new MCommon('プライベートIP', 'namecd', 'ip_type', 'prvt_ip');
		req.commonList.push(common21);
		const common22 = new MCommon('グローバルIP', 'namecd', 'ip_type', 'glbl_ip');	//※現行側、使用してない？？
		req.commonList.push(common22);
		const common23 = new MCommon('グローバルIP(FTTH版)', 'namecd', 'ip_type', 'glbl_ip_f');
		req.commonList.push(common23);
		// 自動化停止対象
		req.displayList.push(new MDisplay(MypageDispdivcdConstants.ITSCOMNET_STOPAUTOMATIONCOURSE));
		req.displayList.push(new MDisplay(MypageDispdivcdConstants.MVOS_STOPAUTOMATIONCOURSE));
		// とことんサポート
		req.displayList.push(new MDisplay(MypageDispdivcdConstants.TOKOTON_SUPPORT_COURSE_NAME));
		// ケーブルモデム判定
		const common24 = new MCommon('FTTHケーブルモデム(Wi-Fi)コース', 'srvnet', 'ftth_wireless_sv', 'wireless_disp_sv');
		req.commonList.push(common24);
		const common25 = new MCommon('HFCケーブルモデム(Wi-Fi)コース', 'srvnet', 'hfc_wireless_sv', 'wireless_disp_sv');
		req.commonList.push(common25);
		const common26 = new MCommon('optionCd', 'srvnet', 'wire_sv');
		req.commonList.push(common26);
		const common27 = new MCommon('コースプラス', 'namecd', 'course_net', 'plus');
		req.commonList.push(common27);
		const common28 = new MCommon('コースジャスト', 'namecd', 'course_net', 'just');
		req.commonList.push(common28);
		// 支払い方法
		req.displayList.push(new MDisplay(MypageDispdivcdConstants.PAYMENT_DISPNAME));
		req.generalList = new Array();
		req.generalList.push(new MGeneral(MypageCodeTypeConstants.INTRODUCTION_CODE, null));
		// 有料サービス（料金シミュでは0円）
		req.generalList.push(new MGeneral(MypageCodeTypeConstants.PAID_SERVICE, null));
		// AP無料サービス
		req.generalList.push(new MGeneral(MypageCodeTypeConstants.AP_FREE_CODE, null));
		req.contractFlg = true;
		return req;
	}

	/**
	 * 画面キャッシュの初期化データ取得
	 */
	public getInitCacheData(mngr: MypageWizardMngr): NetServiceChangeWizardModel {
		let cacheData = new NetServiceChangeWizardModel();
		cacheData.m_NetFlowModel = new NetFlowModel();
		cacheData.m_NetInfo = new CourseListModel();
		cacheData.m_ContractInfo = new ContractInfo();
		cacheData.m_OtherCourseListModel = new Array();
		cacheData.m_BaseInfoList = new Array();
		cacheData.m_CourseListModel = new Array();
		cacheData.m_TanakoList = new Array();
		cacheData.m_BefConfirmList = new Array();
		cacheData.m_ConfirmListModel = new Array();
		cacheData.m_BeforeContractList = new Array();
		return cacheData;
	}

	/**
	 * 画面ウィザードフローの中断判定：例：申し込みが完了していれば、完了済みの画面へ遷移
	 */
	public checkFlowBreak(mngr: MypageWizardMngr, view: MypageBaseView): string {
		if (view.m_Name === 'ModNetSelectView') {
			//トリプルの場合：追加判定
			const viewCode = '002002';
			const customer = mngr.getViewInfo().customer;
			const building = mngr.getViewInfo().buildingList[0];
			//特定導入方式の場合:001
			if (customer.building[0].buildingTypeCD !== '3' && mngr.getViewInfo().searchBuildingList && mngr.getViewInfo().searchBuildingList.length > 0) {
				let flg = false;
				for (const introCode of mngr.getMGenralList(view, MypageCodeTypeConstants.INTRODUCTION_CODE, '')) {
					if (mngr.getViewInfo().searchBuildingList.length > 0 && mngr.getViewInfo().searchBuildingList[0].buildingImplementMethodCD === introCode.name1)
						flg = true;
				}
				if (!flg)
					return '/customer_support?no=001' + viewCode;
			}
			//戸建賃貸の場合:002
			if (customer.building[0].buildingTypeCD === '2') {
				return '/customer_support?no=002' + viewCode;
			}
			//2軸物件の対象の場合:003
			else if (building.ftthTokkiMethod === '3' && building.bunkatuMotoTatemonoNum) {
				return '/customer_support?no=003' + viewCode;
			}
			//回線種別：その他の場合:004
			else if (customer.building[0].buildingLineTypeCD === '3') {
				return '/customer_support?no=004' + viewCode;
			}
			//マイグレ
			else if (mngr.isMigration(view)) {
				return '/customer_support?no=007' + viewCode;
			}
			// //無料のコースの変更の場合:005
			// let contract = null;
			// if (ObjectUtil.isNotNullOrEmptyStr(mngr.getParams().contractId)) 
			// 	contract = mngr.getContract(view, mngr.getParams().contractId);
			// const serviceList = mngr.getServiceList(view, MypageDispdivcdConstants.ACCESSPASS_SERVICE);
			// const serviceFee = serviceList.filter(item => item.service.serviceCD === contract.serviceCD)[0].service.payment[0].amount;
			// const amount = mngr.getTaxFee(view, serviceFee.toString());
			// if (amount === '0') 
			// 	return '/customer_support?no=005' + viewCode;
		}
	}

	/**
	 * 画面ウィザード完了APIモデル取得
	 */
	public getRegistDataApiModel(mngr: MypageWizardMngr, view: MypageBaseView): RegistContractRequest{
		const cacheData: NetServiceChangeWizardModel = mngr.getCache();
		const contract = mngr.getContract(view, mngr.getParams().contractId);
		const customer = mngr.getViewInfo().customer;
		const contractInfo = mngr.getViewInfo().contractModel;
		const req: RegistContractRequest = new RegistContractRequest();
		// サービスCD設定
		let serviceCd = null;
		if (ObjectUtil.isNotNullOrUndefined(cacheData.m_NetInfo.tanakoServiceCd))
			serviceCd = cacheData.m_NetInfo.tanakoServiceCd;
		else
			serviceCd = cacheData.m_NetInfo.aftServiceCd;
		// 自動化停止コース判定
		const netAutoStopCourseList = mngr.getMDisplayList(view, MypageDispdivcdConstants.ITSCOMNET_STOPAUTOMATIONCOURSE, '');
		let netStopFlg = false;
		for (const netAutoStopCourse of netAutoStopCourseList) { // 追加コース判定
			if (netAutoStopCourse.namecd === serviceCd) {
				netStopFlg = true;
				break;
			}
		}
		let mvosStopFlg = false;
		const mvosAutoStopCourseList = mngr.getMDisplayList(view, MypageDispdivcdConstants.MVOS_STOPAUTOMATIONCOURSE, '');
		for (const mvosAutoStopCourse of mvosAutoStopCourseList) {
			for (const contract of contractInfo.contractList) {
				if (contract.serviceCD === mvosAutoStopCourse.namecd) {
					mvosStopFlg = true;
					break;
				}
			}
		}
		let autoStopFlg = false;
		if (netStopFlg && mvosStopFlg)
			autoStopFlg = true;
		// コース解約日判定
		let nonCancelFlg = false; 
		if (!contract.cancelDate)
			nonCancelFlg = true;
		// 工事なし　かつ　申込み判定true かつ　端末がダミーではない　かつ変更前コースの解約日がない　かつ　自動化停止判定false
		if (!cacheData.m_NetInfo.constractionFlg && cacheData.m_NetInfo.directflg && !cacheData.m_NetInfo.otokuFlg 
			&& !cacheData.m_ContractInfo.dummyFlg && nonCancelFlg && !autoStopFlg) {
			// 登録処理
			req.customerId = mngr.getParams().customerId;
			let serviceCd = null;
			// 店子コースの場合
			if (ObjectUtil.isNotNullOrUndefined(cacheData.m_NetInfo.tanakoServiceCd))
				serviceCd = cacheData.m_NetInfo.tanakoServiceCd;
			else
				serviceCd = cacheData.m_NetInfo.aftServiceCd;
			req.serviceCd = serviceCd;
			req.startDate = cacheData.m_NetInfo.startDate;
			req.oldContractId = contract.contractID;
			req.paymentCustomerId = contract.payment[0].paymentCustomerID;
			req.chargeTypeCd =  contract.payment[0].chargeTypeCD;
			req.infrastructureTypeCd = contract.infrastructure[0].infrastructureTypeCD;
			// equipmentTypeCdがcommon["Add_net"]["equ"]["rep"]（10000）の場合
			if (ObjectUtil.isNotNullOrUndefined(cacheData.m_ContractInfo.equipmentTypeCd))
				req.equipmentTypeCd = cacheData.m_ContractInfo.equipmentTypeCd;
			else
				req.equipmentTypeCd = contract.equipment[0].equipmentTypeCD;
			// レンタル
			if (cacheData.m_NetInfo.afterProvision === 'レンタル')
				req.rentalFlag = '1';
			// 購入
			else if (cacheData.m_NetInfo.afterProvision === '購入')
				req.rentalFlag = '0';
			else
				req.rentalFlag = contract.equipment[0].rentalFlag;
			// ipオプションチェック
			req.optionList = new Array();
			let nonIpoFlg = false;
			const nonIpoList: string[] = ['fl_ADSL', 'fl_FTTH', 'fl_DIAL', 'value'];
			for (const nonIpo of nonIpoList) {
				const ipoCommon = mngr.getMCommonList(view, 'namecd', 'course_net', nonIpo, null, null);
				if (ipoCommon[0].commonValue === serviceCd) {
					nonIpoFlg = true;
					break;
				}
			}
			// IPオプションの場合
			if (nonIpoFlg) {
				// プライベートIP・グローバルIP判定
				const prvtIp = mngr.getMCommonList(view, 'namecd', 'ip_type', 'prvt_ip', null);
				const glblIpF = mngr.getMCommonList(view, 'namecd', 'ip_type', 'glbl_ip_f', null);
				let ipTypeCd = prvtIp[0].commonValue;
				if (ObjectUtil.isNotNullOrUndefined(contract.option) && contract.option.length > 0) {
					for (const option of contract.option) {
						if (option.statusCD !== '3' && option.optionCD === glblIpF[0].commonValue) {
							ipTypeCd = glblIpF[0].commonValue;
							break;
						}
					}
				}
				// グローバルIPの場合のみ、ipTypeCd連携
				if (ipTypeCd = glblIpF[0].commonValue)
					req.optionList.push(ipTypeCd);
			}
			const cableWifi = mngr.getMCommonList(view,'cable_modem_wifi',null, null, null);
			// ケーブルモデム(Wi-Fi)の場合
			if (ObjectUtil.isNotNullOrUndefined(cacheData.m_NetInfo.afterEquipment) && cacheData.m_NetInfo.afterEquipment === cableWifi[0].commonValue) {
				const ftthCableList =  mngr.getMCommonList(view, 'srvnet', 'ftth_wireless_sv', 'wireless_disp_sv', null);
				const hfcCableList = mngr.getMCommonList(view, 'srvnet', 'hfc_wireless_sv', 'wireless_disp_sv', null);
				const wireSv = mngr.getMCommonList(view, 'srvnet', 'wire_sv', null, null);
				// FTTH
				if (customer.building[0].buildingLineTypeCD === '2') {
					const ftthCable = ftthCableList.filter(row => row.commonValue === serviceCd);
					if (ObjectUtil.isNullOrUndefined(ftthCable) || ftthCable.length === 0)
						req.optionList.push(wireSv[0].commonValue);
				}
				// HFC
				else {
					const hfcCable = hfcCableList.filter(row => row.commonValue === serviceCd);
					if (ObjectUtil.isNullOrUndefined(hfcCable) || hfcCable.length === 0) 
						req.optionList.push(wireSv[0].commonValue);
				}
			}
			return req;
		}
		else
			return null;
	}

	/**
	 * 画面ウィザード完了APIモデル取得
	 */
	public getRegistApiModel(mngr: MypageWizardMngr, view: MypageBaseView, resultCd: string): RegistInfoRequest{
		const req: RegistInfoRequest = new RegistInfoRequest();
		req.customerId = mngr.getParams().customerId;
		const cacheData: NetServiceChangeWizardModel = mngr.getCache();
		const modContract = mngr.getContract(view, mngr.getParams().contractId);	// 契約情報(変更対象)
		mngr.getCache().m_NetInfo.directResult;
		let errorMsg = null;
		let directResult = '仮申込み';
		//createContractの結果より直接申込判定
		if (resultCd) {
			const contenueList = new Array('2010000', '3010000', '3010002', '3030000', '3050000', '3150000', '3090003');
			if (resultCd === '0') {
				directResult = '直接申込';
			}
			else if (contenueList.includes(resultCd)) {
				switch (resultCd) {
					case '2010000': //customerID＝無効
					errorMsg = "お客様IDが無効エラー";
						break;
					case '3010000': //oldContractID＝無効
					errorMsg = "契約番号が無効エラー";
						break;
					case '3010002': //serviceCD＝登録不可
					errorMsg = "サービス登録不可エラー";
						break;
					case '3030000': //chargeTypeCD＝登録不可
					errorMsg = "料金種別登録不可エラー";
						break;
					case '3050000': //equipmentTypeCD＝登録不可
					errorMsg = "端末種別登録不可エラー";
						break;
					case '3150000': //infrastructureTypeCD＝登録不可
					errorMsg = "インフラ種別登録不可エラー";
						break;
					case '3090003': //optionCD＝登録不可
					errorMsg = "オプション登録不可エラー";
						break;
				}
				directResult = '仮申込み';
			}
			else 
				view.showLogMessage('createContractエラー：' + resultCd);
		}
		// サービスCD設定
		let serviceCd = null;
		if (ObjectUtil.isNotNullOrUndefined(cacheData.m_NetInfo.tanakoServiceCd))
			serviceCd = cacheData.m_NetInfo.tanakoServiceCd;
		else
			serviceCd = cacheData.m_NetInfo.aftServiceCd;
		const netFletsDirectList: string[] = ['fl_ADSL', 'fl_FTTH', 'fl_DIAL'];
		// 直接申込み
		if (directResult === '直接申込') {
			mngr.getCache().m_NetInfo.directResult = '直接申込み';
			// 顧客メール
			for (const netFletsDirect of netFletsDirectList) {
				const common = mngr.getMCommonList(view, 'namecd', 'course_net', netFletsDirect, null, null);
				if (common[0].commonValue === serviceCd) {
					const mailconstants = MAIL_CONSTANTS.find (mail => {
						return mail.view === 'フレッツNET_変更_直接';
					});
					req.customerMailId = mailconstants.customerMail;
					break;
				}
			}
			if (ObjectUtil.isNullOrUndefined(req.customerMailId)) {
				const mailconstants = MAIL_CONSTANTS.find (mail => {
					return mail.view === 'NET_変更_直接';
				});
				req.customerMailId = mailconstants.customerMail;
			}
		}
		// 仮申込み
		else {
			mngr.getCache().m_NetInfo.directResult = '仮申込み';
			// 顧客メール
			for (const netFletsDirect of netFletsDirectList) {
				const common = mngr.getMCommonList(view, 'namecd', 'course_net', netFletsDirect, null, null);
				if (common[0].commonValue === serviceCd) {
					const mailconstants = MAIL_CONSTANTS.find (mail => {
						return mail.view === 'フレッツNET_変更_仮申込み';
					});
					req.customerMailId = mailconstants.customerMail;
					break;
				}
			}
			if (ObjectUtil.isNullOrUndefined(req.customerMailId)) {
				const mailconstants = MAIL_CONSTANTS.find (mail => {
					return mail.view === 'NET_変更_仮申込み';
				});
				req.customerMailId = mailconstants.customerMail;
			}
			// 業務部門メール/問い合わせ
			req.systemInfo = new InquiryMailInfo();
			// ダミー端末
			if (cacheData.m_ContractInfo.dummyFlg) {
				const mailconstants = MAIL_CONSTANTS.find (mail => {
					return mail.view === 'NET_変更_ダミー';
				});
				req.systemMailId = mailconstants.systemMail;
				req.inquiryId = mailconstants.inquiryMail;
				req.systemInfo.equipmentInfo = 'ダミーの端末情報が登録されているため、お客様へ、STB-ID、CASカード番号、またはMACアドレスのヒアリングをお願いいたします。';
			}
			else {
				const mailconstants = MAIL_CONSTANTS.find (mail => {
					return mail.view === 'NET_変更';
				});
				req.systemMailId = mailconstants.systemMail;
				req.inquiryId = mailconstants.inquiryMail;
				req.systemInfo.equipmentInfo = '';
			}
			// 契約番号
			req.systemInfo.contractId = cacheData.m_ContractInfo.contractId;
			// 変更コース
			req.systemInfo.appService = cacheData.m_NetInfo.cDispnamep;
			// 変更前コース
			req.systemInfo.beforeAppService = cacheData.m_ContractInfo.contractCourse;
			// 端末の種類(変更前)
			if (ObjectUtil.isNotNullOrUndefined(cacheData.m_NetInfo.deviceType) ) {
				if (ObjectUtil.isNotNullOrUndefined(cacheData.m_NetInfo.deviceForm))
					req.systemInfo.beforeEquipment = cacheData.m_NetInfo.deviceType + ' ' + cacheData.m_NetInfo.deviceForm;
				else
					req.systemInfo.beforeEquipment = cacheData.m_NetInfo.deviceType;
			}
			else {
				if (ObjectUtil.isNotNullOrUndefined(cacheData.m_NetInfo.deviceForm))
					req.systemInfo.beforeEquipment = cacheData.m_NetInfo.deviceForm;
				else
					req.systemInfo.beforeEquipment = '';
			}
			// 端末の種類(変更後)
			if (ObjectUtil.isNotNullOrUndefined(cacheData.m_NetInfo.afterEquipment)) {
				if (ObjectUtil.isNotNullOrUndefined(cacheData.m_NetInfo.afterProvision))
					req.systemInfo.equipment = cacheData.m_NetInfo.afterEquipment + ' ' + cacheData.m_NetInfo.afterProvision;
				else
					req.systemInfo.equipment = cacheData.m_NetInfo.afterEquipment
			}
			else {
				if (ObjectUtil.isNotNullOrUndefined(cacheData.m_NetInfo.afterProvision))
					req.systemInfo.equipment = cacheData.m_NetInfo.afterProvision;
				else
					req.systemInfo.equipment = '';
			}
			// お得パック
			if (cacheData.m_NetInfo.otokuFlg) {
				req.systemInfo.otokuStatus = '契約中';
			}
			else {
				if (cacheData.m_NetInfo.yesOtoku)
					req.systemInfo.otokuStatus = '未契約(お得パック希望する)';
				else if (cacheData.m_NetInfo.noOtoku)
					req.systemInfo.otokuStatus = '未契約(お得パック希望しない)';
				else
					req.systemInfo.otokuStatus = '';
			}
			// ipオプションチェック
			let nonIpoFlg = false;
			const nonIpoList: string[] = ['fl_ADSL', 'fl_FTTH', 'fl_DIAL', 'value'];
			for (const nonIpo of nonIpoList) {
				const ipoCommon = mngr.getMCommonList(view, 'namecd', 'course_net', nonIpo, null, null);
				if (ipoCommon[0].commonValue === serviceCd) {
					nonIpoFlg = true;
					break;
				}
			}
			// IPオプションでない場合
			if (nonIpoFlg) {
				req.systemInfo.ipOption = '';
			}
			// IPオプションの場合
			else {
				// プライベートIP・グローバルIP判定
				const prvtIp = mngr.getMCommonList(view, 'namecd', 'ip_type', 'prvt_ip', null);
				const glblIpF = mngr.getMCommonList(view, 'namecd', 'ip_type', 'glbl_ip_f', null);
				let ipTypeCd = prvtIp[0].commonValue;
				if (ObjectUtil.isNotNullOrUndefined(modContract.option) && modContract.option.length > 0) {
					for (const option of modContract.option) {
						if (option.statusCD !== '3' && option.optionCD === glblIpF[0].commonValue) {
							ipTypeCd = glblIpF[0].commonValue;
							break;
						}
					}
				}
				// グローバルIPの場合のみ、IPアドレス名称取得
				if (ipTypeCd === glblIpF[0].commonValue) {
					const ipTypeList = mngr.getMDisplayList(view, MypageDispdivcdConstants.IPADDRESS_TYPE, '');
					const ipType = ipTypeList.filter(data => data.namecd === ipTypeCd);
					if (ObjectUtil.isNotNullOrUndefined(ipType)) {
						req.systemInfo.ipOption = ipType[0].dispnamep;
					}
				}
				else
					req.systemInfo.ipOption = '';
			}
			// 申込み開始日
			if (ObjectUtil.isNotNullOrUndefined(cacheData.m_NetInfo.startDate))
				req.systemInfo.startDate = cacheData.m_NetInfo.startDate;
			else {
				if (cacheData.m_NetInfo.constractionFlg)
					req.systemInfo.startDate = '工事日より変更';
				else
					req.systemInfo.startDate = '';
			}
			// 直接申込みエラー内容
			if (ObjectUtil.isNotNullOrUndefined(errorMsg))
				req.systemInfo.other = '※システムINFO\nコース変更登録に失敗した為、仮申し込み登録に切り替えられました。【' + errorMsg + '】';
			// 支払い方法
			const payment = mngr.getMDisplayList(view, MypageDispdivcdConstants.PAYMENT_DISPNAME, '').filter(data => data.namecd === mngr.getViewInfo().customerPayment[0].payment[0].paymentTypeCD);
			if (payment && payment.length > 0)
				req.systemInfo.paymentDispname = payment[0].dispnamep;
			else
				req.systemInfo.paymentDispname = '';
			// 工事
			if (cacheData.m_NetInfo.constractionFlg) {
				req.systemInfo.constractionFlg = true;
				if (cacheData.m_NetInfo.securityComment)
					req.systemInfo.securityComment = cacheData.m_NetInfo.securityComment;
				else
					req.systemInfo.securityComment = 'なし';
				req.systemInfo.desiredDate1 = cacheData.m_NetInfo.desiredDate1.replace(/-/g, '/');
				req.systemInfo.desiredDate2 = cacheData.m_NetInfo.desiredDate2.replace(/-/g, '/');
				req.systemInfo.desiredDate3 = cacheData.m_NetInfo.desiredDate3.replace(/-/g, '/');
				req.systemInfo.selectTime1 = view.getValueMap('時間帯').getValueFormKey(cacheData.m_NetInfo.selectTime1);
				req.systemInfo.selectTime2 = view.getValueMap('時間帯').getValueFormKey(cacheData.m_NetInfo.selectTime2);
				req.systemInfo.selectTime3 = view.getValueMap('時間帯').getValueFormKey(cacheData.m_NetInfo.selectTime3);
				if (cacheData.m_NetInfo.payUnit)
					req.systemInfo.constructionCost = Number(cacheData.m_NetInfo.cost).toLocaleString() + cacheData.m_NetInfo.payUnit;
				else 
					req.systemInfo.constructionCost = cacheData.m_NetInfo.cost;
				req.systemInfo.beforeContact = cacheData.m_NetInfo.beforeContact;
				req.systemInfo.sameDayContact = cacheData.m_NetInfo.sameDayContact;
				req.systemInfo.witness = cacheData.m_NetInfo.name;
			}
			// キャンペーン
			if (cacheData.m_NetInfo.campaignCode) {
				req.systemInfo.campaignFlg = true;
				req.systemInfo.campaignGroupName = cacheData.m_NetInfo.campaignGroup.campaignGroupName;
				req.systemInfo.campaignList = cacheData.m_NetInfo.campaignList;
			}
		}
		return req;
	}
}

/**
 * 画面ウィザードAPI用モデル
 */
export class NetServiceChangeWizardModel {
	public m_ContractInfo: ContractInfo = null;					// 契約情報(画面表示用)
	public m_BaseInfoList: BaseInfoListModel[] = null;			// 基本情報リスト
	public m_CourseListModel: CourseListModel[] = null;			// コースリスト
	public m_TanakoList: CourseListModel[] = null;				// 店子コースリスト
	public m_NetInfo: CourseListModel = null;					// ネット画面情報
	public m_BeforeContractList: CourseListModel[] = null;		// 変更前リスト
	public m_OtherCourseListModel: CourseListModel[] = null;	// その他コースリスト
	public m_BefConfirmList: CourseListModel[] = null;			// 変更後確認リスト
	public m_ConfirmListModel: CourseListModel[] = null;		// 変更後確認リスト
	public m_NetFlowModel: NetFlowModel = null;
}

