import { WprBaseConverter } from '../../../../wpr-framework/view/convert/WprBaseConverter';
import { ObjectUtil } from '../../../common/util/ObjectUtil';

/**
 * 年月
 * 利用明細の項目で使用
 */
export class YearMonthConverter extends WprBaseConverter {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() {
		super('年月（yyyy年MM月）');
	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * 値を表示用の値に変換する (value -> viewValue)
	 * @param value 値
	 * @returns 表示用の値
	 */
	public convertView(value: any): string {
		const strVal: string = value as string;
		if (ObjectUtil.isNotNullOrUndefined(strVal) && (strVal.length === 8 || strVal.length === 6)) {
			const year: string = strVal.substring(0,4);
			let month: string = strVal.substring(4,6);
			if (month.charAt(0) === '0') {
				month = month.substring(1);
			}
			return year + '年' + month + '月';
		}
		return value;
	}
	// --------------------------------------------------------------------------
}
