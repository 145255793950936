import { Contract } from "../../bss/contract/Contract";

/**
 * 移設サービス一覧モデル
 */
export class RelocateServiceListModel {
	// public 変数	-----------------------------------------------------------
	public equipType: string;					// サービス区分
	public select: boolean;						// 選択
	public no: string;							// 台目
	public contract: Contract;					// 契約情報
	public serviceName: string;					// サービス名
	public terminalName: string;				// 機器名
	public appltype: string;					// 提供方法
	public serviceInfo: string;					// サービス等(表示用)
	public floor: string;						// 階数
	public location: string;					// 場所
	public comment: string;						// フリー入力
	// ------------------------------------------------------------------------
}
