import { MypageBaseView } from '../../../base/MypageBaseView';
import { WprModel } from '../../../../../wpr-framework/view/deco/WprModel';
import { WprDI_ScopeModel } from '../../../../../wpr-framework/view/di/WprDI_ScopeModel';
import { HMvnoTmpSaved } from '../../../../service/models/entity/HMvnoTmpSaved';
import { MvnoMnpInfo } from '../../../../service/models/apimodels/MvnoMnpInfo';
import { WprKeyValue } from '../../../../../wpr-framework/model/WprKeyValue';
import { ObjectUtil } from '../../../../common/util/ObjectUtil';
import { MGeneral } from '../../../../service/models/entity/MGeneral';
import { WprControlInfo } from '../../../../../wpr-framework/view/control/WprControlInfo';
import { WprDI_Control } from '../../../../../wpr-framework/view/di/WprDI_Control';
import { MvnoCouponInfo } from '../../../../service/models/apimodels/MvnoCouponInfo';
import { MvnoService } from '../../../../service/MvnoService';
import { GetMGeneralListRequest } from '../../../../service/models/dto/mvno/GetMGeneralListRequest';
import { MGeneralListModel } from '../../../../service/models/apimodels/MGeneralListModel';
import { MypageConstants } from '../../../../common/constants/MypageConstants';

/**
 * データ容量追加申込
 */
export class MvnoAddCapacityView extends MypageBaseView {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() { super('MvnoAddCapacity'); }
	// --------------------------------------------------------------------------

	// DI情報  ------------------------------------------------------------------
	@WprDI_Control
	private m_addCouponCapacity: WprControlInfo = null;	// 回線番号コンボボックス
	// --------------------------------------------------------------------------

	// モデル  ------------------------------------------------------------------
	// @WprDI_ScopeModel('MvnoMnpInfo')
	// @WprModel('MvnoMnpInfo')
	// private m_MvnoMnpInfo: MvnoMnpInfo = null;	// MVNOプラン情報


	private m_CouponList: MGeneral[] = null;

	@WprDI_ScopeModel('MvnoCouponInfo')
	@WprModel('MvnoCouponInfo')
	private m_AddCouponInfo: MvnoCouponInfo = null;
	// --------------------------------------------------------------------------

	// サービス  ----------------------------------------------------------------
	private m_MvnoService: MvnoService = new MvnoService();	// MyPageRenewalサービス
	// --------------------------------------------------------------------------


	// override メソッド  --------------------------------------------------------
	/**
	 * コントロール定義設定
	 */
	public onRegisterConfig(): void {
		this.addConfig('phoneNumber', '回線番号', 'MvnoCouponInfo');
		this.addConfig('addCouponCapacity', '追加クーポン容量', 'MvnoCouponInfo', { hissu: true });
		this.addConfig('addCouponPrice', '追加クーポン価格', 'MvnoCouponInfo', { converter: '3桁カンマ' });
	}

	/**
	 * アクション登録
	 */
	public onRegisterAction(): void {
		this.addAction('next', this.onNext, true);
		this.addAction('back', this.onBack);
	}

	/**
	 * ビュー表示通知
	 */
	public onShowView(): void {
		this.registAccessLog();
			this.setStorageItem('b_url', window.location.href)

		const req: GetMGeneralListRequest = new GetMGeneralListRequest();
		req.filterList = new Array();

		{
			// クーポン容量
			const filter: MGeneralListModel = new MGeneralListModel();
			filter.mgeneral = new MGeneral();
			filter.mgeneral.codeTypeCd = MypageConstants.MVNO_COUPON_CAPACITY;
			filter.isCalcTaxIn = true;
			req.filterList.push(filter);
		}
		this.m_MvnoService.getMGeneralList(this, req, (result) => {
			let map: Map<string, MGeneral[]> = new Map<string, any>(Object.entries(result.listMap));
			map.forEach((value, key) => {
				let map2: Map<string, string> = new Map<string, any>(Object.entries(value));
				switch (key) {
					case MypageConstants.MVNO_COUPON_CAPACITY:
						this.m_CouponList = value;
						this.m_addCouponCapacity.resetValueMap();
						break;
					default:
						break;
				}
			});

			this.m_AddCouponInfo.addCouponCapacity = '1GB';
			this.m_AddCouponInfo.addCouponPrice = String(this.m_CouponList.find(couponM => {
				return (couponM.generalCd === this.m_AddCouponInfo.addCouponCapacity)
			}).num2);
			this.refreshView();
		});
	}


	/**
	 * 値変更通知
	 * @param name 名前
	 * @param value 値
	 * @returns falseの場合、変更を取り消す
	 */
	public onChangeValue(name: string, value: any): boolean {
		// 
		if (name === 'addCouponCapacity') {
			this.m_AddCouponInfo.addCouponPrice = String(this.m_CouponList.find(couponM => {
				return (couponM.generalCd === value)
			}).num2);
		}
		return true;
	}

	/**
	 * ValueMap再設定処理
	 * @param name コントロール名
	 * @param id ValueMapID
	 * @list ValueMapのKey-Valueリスト
	 * @returns 再設定したKey-Valueリスト
	 */
	public onResetValueMap(name: string, id: string, list: WprKeyValue[]): WprKeyValue[] {
		if (name === 'addCouponCapacity') {
			if (ObjectUtil.isNotNullOrEmptyArray(this.m_CouponList)) {
				list = [];
				this.m_CouponList.forEach((value, index) => {
					list.push(new WprKeyValue(value.generalCd, value.generalCd));
				})
			}
		}
		return list;
	}
	// --------------------------------------------------------------------------

	// アクション  ---------------------------------------------------------------
	/**
	 * 次へ
	 */
	public onNext(param: any): void {
		this.refreshModel();

		this.pushPath('/mvno_add_capacity_002');
	}

	/**
	 * 戻る
	 */
	public onBack(param: any): void {
		this.pushPath('/mvno_capacity_001');
	}

	// --------------------------------------------------------------------------
}
