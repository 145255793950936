import { WizardModel } from '../../models/WizardModel';
import { GetViewInfoRequest } from '../../service/models/dto/mypagerenewal/GetViewInfoRequest';
import { MDisplay } from '../../service/models/entity/secondary/MDisplay';
import { MypageBaseView } from '../base/MypageBaseView';
import { ReiAccesspassCompleteView } from '../internet/rei_accesspass_complete/ReiAccesspassCompleteView';
import { ReiAccesspassConfirmView } from '../internet/rei_accesspass_confirm/ReiAccesspassConfirmView';
import { ReiAccessAccountModel } from '../internet/rei_accesspass_confirm/models/ReiAccessAccountModel';
import { ReiAccesspassPrintView } from '../internet/rei_accesspass_print/ReiAccesspassPrintView';
import { MypageWizardFlow } from './MypageWizardFlow';
import { MypageWizardMngr } from './models/MypageWizardMngr';
import MypageDispdivcdConstants from '../../common/MypageDispdivcdConstants';
import { MCommon } from '../../service/models/entity/primary/MCommon';
import { ReiAccessAccountPwModel } from '../internet/rei_accesspass_complete/models/ReiAccessAccountPwModel';


/**
 * ITSCOM HOME_追加ウィザードクラス
 */
export class ItscomNetIssueWizardFlow extends MypageWizardFlow {
	// private 変数  ------------------------------------------------------------
	private m_DefaultWizardFlow: WizardModel[]			= null;		// 画面ウィザードリスト
	private m_StartViewName: string						= null;
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	public constructor() {
		super();
		this.m_StartViewName = new ReiAccesspassConfirmView().name;

		// 遷移パターン１
		this.m_DefaultWizardFlow = new Array();
		this.m_DefaultWizardFlow.push({stepNum: 0, title: '契約選択', viewName: null, path: this.getOldUrl() + 'Srvlist/Continfo_list/'});
		this.m_DefaultWizardFlow.push({stepNum: 1, title: '内容の確認', viewName: this.m_StartViewName, path: 'rei_accesspass_confirm'});
		this.m_DefaultWizardFlow.push({stepNum: 2, title: '発行完了', viewName: new ReiAccesspassCompleteView().name, path: 'rei_accesspass_complete'});
		this.m_DefaultWizardFlow.push({stepNum: 0, title: '印刷', viewName: new ReiAccesspassPrintView().name, path: 'rei_accesspass_print'});

	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * 画面ウィザードフロー名取得
	 */
	public getWizardFlowName(viewName: string): string {
		if (viewName === this.m_StartViewName)
			return this.constructor.name;
		return null;
	}
	
	/**
	 * 画面ウィザードリスト取得：契約状態で切り替えたり、選択肢で切り替えたり
	 */
	public getWizardFlow(mngr: MypageWizardMngr): WizardModel[] {
		return this.m_DefaultWizardFlow;
	}
	
	/**
	 * 画面ウィザードAPI用モデル取得：契約状態で切り替えたり、選択肢で切り替えたり
	 */
	public getWizardApiModel(mngr: MypageWizardMngr, view: MypageBaseView): GetViewInfoRequest {
		const req = new GetViewInfoRequest();
		req.customerId = mngr.getParams().customerId;
		req.contractFlg = true;
		req.displayList = new Array();
		req.displayList.push(new MDisplay(MypageDispdivcdConstants.ACCESSPASS_SERVICE));	// アクセスアカウント_サービス
		req.commonList = new Array();
		req.commonList.push(new MCommon('暗号化キー', 'cryptkey'));
		return req;
	}

	/**
	 * 画面ウィザードフローの中断判定：例：申し込みが完了していれば、完了済みの画面へ遷移
	 */
	public checkFlowBreak(mngr: MypageWizardMngr): string {
		let test: boolean = false;
		if (test) {
			return '/error';
		}
		return null;
	}

	/**
	 * 画面キャッシュの初期化データ取得
	 */
	public getInitCacheData(mngr: MypageWizardMngr): ItscomNetIssueWizardModel {
		let cacheData = new ItscomNetIssueWizardModel();
		cacheData.m_ReiAccessAccountModel = new ReiAccessAccountModel();
		return cacheData;
	}
	// --------------------------------------------------------------------------
}
/**
 * 画面ウィザードAPI用モデル
 */
export class ItscomNetIssueWizardModel {
	// public 変数	-----------------------------------------------------------
	public m_ReiAccessAccountModel: ReiAccessAccountModel = null;	// Phoneサービス追加情報モデル
	// ------------------------------------------------------------------------
}

