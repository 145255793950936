import { ApplicationInfo } from '../../../../models/iss/setMypWorkSchedule/ApplicationInfo';

/**
 * 工事j予定一覧取得リクエスト
 */
export class SetMypWorkScheduleRequest {
	// public 変数	-----------------------------------------------------------
	public application_info: ApplicationInfo[];	// 
	// ------------------------------------------------------------------------
}
