import React, { ReactNode } from 'react';
import { WprBaseViewComponent } from '../../../../wpr-framework/view/component/WprBaseViewComponent';
import { MobileCompleteView } from './MobileCompleteView';
import styles from './MobileComplete.scss';
import { Typography, Grid, Divider } from '@material-ui/core';
import { WprSpan } from '../../../../wpr-framework/component/controls/WprSpan';
import { WprViewError } from '../../../../wpr-framework/component/controls/WprViewError';
import { MatButton } from '../../../../wpr-framework/component/controls/material/MatButton';
import { MatTextField } from '../../../../wpr-framework/component/controls/material/MatTextField';
import { ProgressBar } from '../../common/progress_bar/ProgressBar';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { MatTypography } from '../../../../wpr-framework/component/controls/material/MatTypography';
import { WprAnchor } from '../../../../wpr-framework/component/controls/WprAnchor';
import questionnaireIcon from '/shared/image/common/button_questionnaire_on.png';

/**
 * かっとびモバイル解約申込み完了用コンポーネント
 */
export class MobileComplete extends WprBaseViewComponent {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: any) { super(new MobileCompleteView(), props); }
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** かっとびモバイル解約申込み完了ビュー情報 */
	public get viewInfo(): MobileCompleteView	{ return this.view as MobileCompleteView;	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * スタイルオブジェクト取得
	*/
	public getStylesObject(): any { return styles; }

	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		return (
			<>
				<div>
				<div className='pageCard'>
					<div className='main table_margin question'>
						<div className='title_center'>
								<Typography className='title_complete complete_title'>解約手続きが完了しました。</Typography>
						</div>
						<div className=' user'>
							<MatTypography name='user' view={this.view} className = 'user'/>
						</div>
						<div className='subText'>
							<p className='title_comment'>かっとびモバイル（EM）の解約を受付ました。</p>
							<p className='title_comment'>連絡先メールアドレスに確認メールを送信しました。</p>
						</div>
						<div className='feeTable'>
							<Grid container className='feeRow'>
								<Grid item xs={12} md={4} className='feeTitle table_title'>
									<p>かっとびモバイル（EM）</p>
									<p>シリアル番号</p>
								</Grid>
								<Grid item xs={12} md={8} className='feeVal' style={{ color: 'black' }}>
									<MatTypography name='equipmentID' view={this.view}/>
								</Grid>
							</Grid>
						</div>	
						<div className='supportMsgArea'>
							<ul className='aster' style={{ listStyle: 'none', paddingLeft: '1rem'}}>
								<span className='textcolor'>
									解約手続き後のご利用に関して
								</span>
									<li className='text'>今月末まではご利用になります。
									</li>
							</ul>
						</div>
						<div className='questionnaire'>
							<WprAnchor name='toQuestionnaire' view={this.view}  >
								<img src={questionnaireIcon} style={{maxWidth: '100%'}} />
							</WprAnchor>
						</div>
					</div>
					<Divider />
					<div className='pageFooter footer-space'>
						<div>
							<MatButton name='back' view={this.view} className='completeButton backContInfoList' >
								<NavigateBeforeIcon />
								<span className='screen'>契約内容の確認・変更メニューへ</span>
							</MatButton>
						</div>
					</div>

				</div>
			</div>
			</>
		);
	}
	// --------------------------------------------------------------------------
}
