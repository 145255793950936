/**
 * バリデーションエラー情報
 */
export class WprInvalidInfo {
	// private 変数  ------------------------------------------------------------
	private m_ValidatorName: string	= null;			// バリデータ名称
	private m_ValidatorOption: any	= undefined;	// バリデータオプション
	private m_ErrorMessage: string	= null;			// エラーメッセージ
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** バリデータ名称 */
	public get validatorName(): string 		{ return this.m_ValidatorName;			}
	/** バリデータオプション */
	public get validatorOption(): string 	{ return this.m_ValidatorOption;		}
	/** エラーメッセージ */
	public get errorMessage(): string 		{ return this.m_ErrorMessage;			}
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	public constructor(validName: string, option: any, message: string) {
		this.m_ValidatorName = validName;
		this.m_ValidatorOption = option;
		this.m_ErrorMessage = message;
	}
	// --------------------------------------------------------------------------
}
