import { MypageBaseView } from '../../../base/MypageBaseView';
import { WprModel } from '../../../../../wpr-framework/view/deco/WprModel';
import { WprDI_ScopeModel } from '../../../../../wpr-framework/view/di/WprDI_ScopeModel';
import { MvnoCapacityChangeInfo } from '../../../../service/models/apimodels/MvnoCapacityChangeInfo';
import { MvnoService } from '../../../../service/MvnoService';
import { GetCapacityChangeRequest } from '../../../../service/models/dto/mvno/GetCapacityChangeRequest';
import { ProgressBarModel } from '../../../../models/ProgressBarModel';
import { ProgressBarData } from '../../../common/progress_bar/ProgressBarView';

/**
 * MVNO容量変更_確認
 */
export class MvnoCapacityChange2View extends MypageBaseView {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() { super('MvnoCapacityChange2'); }
	// --------------------------------------------------------------------------

	// モデル  ------------------------------------------------------------------
	// @WprDI_ScopeModel('EntryInfoBefore')
	// @WprModel('EntryInfoBefore')
	// private m_EntryInfoBefore: HMvnoTmpSaved	= null;	// MVNOプラン情報

	// @WprDI_ScopeModel('Contract')
	// @WprModel('Contract')
	// private m_contract: BssContractInfo = null;

	// @WprDI_ScopeModel('EntryInfoAfter')
	// @WprModel('EntryInfoAfter')
	// private m_EntryInfoAfter: HMvnoTmpSaved	= null;	// MVNOプラン情報

	
	@WprDI_ScopeModel('CapacityChangeInfo')
	@WprModel('CapacityChangeInfo')
	private m_CapacityChangeInfo: MvnoCapacityChangeInfo	= null;	// MVNOプラン情報

	// @WprModel('changeFee')
	// private m_ChangeFee: string = null;
	// --------------------------------------------------------------------------

	// サービス  ----------------------------------------------------------------
	private m_MvnoService: MvnoService = new MvnoService();	// MyPageRenewalサービス
	// --------------------------------------------------------------------------


	// override メソッド  --------------------------------------------------------
	/**
	 * コントロール定義設定
	 */
	public onRegisterConfig(): void {
		this.addConfig('totalBefore', '合計（変更前）', 'CapacityChangeInfo', { converter: '3桁カンマ' });
		this.addConfig('totalAfter', '合計（変更後）', 'CapacityChangeInfo', { converter: '3桁カンマ' });
		this.addConfig('capacityAfter', '容量', 'CapacityChangeInfo');
		this.addConfig('planBefore', 'プラン', 'CapacityChangeInfo.before', { bindName: 'plan' });
		this.addConfig('capacityBefore', '容量', 'CapacityChangeInfo.before', { bindName: 'capacity' });
		this.addConfig('phoneNumber', '回線番号', 'CapacityChangeInfo');
		this.addConfig('iccId', 'ICCID', 'CapacityChangeInfo');
		// this.addConfig('changeFee', '変更手数料', 'CapacityChangeInfo', { converter: '3桁カンマ' });
	}

	/**
	 * アクション登録
	 */
	public onRegisterAction(): void {
		this.addAction('next', this.onNext);
		this.addAction('back', this.onBack);
	}

	/**
	 * ビュー表示通知
	 */
	public onShowView(): void {
		this.registAccessLog();
			this.setStorageItem('b_url', window.location.href)
		const customerId: string = this.getCustomerId();
		const contractId: string = this.getContractCd();


		const req: GetCapacityChangeRequest = new GetCapacityChangeRequest();
		req.cusomerId = customerId;
		req.contractId = contractId;
		req.capacityServiceCdAfter = this.getCookie('afterCapacityServiceCd');
		this.m_MvnoService.getCapacityChangeInfo(this, req, (result) => {
			this.m_CapacityChangeInfo = result.capacityChangeInfo;
			this.setScopeModel('CapacityChangeInfo', this.m_CapacityChangeInfo);
			this.setScopeModel('CapacityRadioList', result.capacityRadioList);
			// this.m_CapacityChangeInfo.totalBefore = result.capacityChangeInfo.totalBefore;
			this.refreshView();
		});
		const sendData: ProgressBarData = new ProgressBarData();
		sendData.progressBarList = this.getProgressModelList();
		this.sendChildData('fP_ev_progressBarIntital', sendData);
	}
	// --------------------------------------------------------------------------

	// アクション  ---------------------------------------------------------------
	/**
	 * 次へ
	 */
	 public onNext(param: any): void {
		this.refreshModel();
		this.pushPath('/mvno_change_003');
	}

	/**
	 * 戻る
	 */
	public onBack(param: any): void {
		this.refreshModel();
		this.pushPath('/mvno_change_001');
	}
	// --------------------------------------------------------------------------

	private getProgressModelList(): ProgressBarModel[] {
		const list: ProgressBarModel[] = new Array();
		
		list.push(new ProgressBarModel('1', 'お申込み', 'complete'));
		list.push(new ProgressBarModel('2', 'お申込み内容確認', 'current'));
		list.push(new ProgressBarModel('3', '事前確認事項', 'next'));
		list.push(new ProgressBarModel('4', 'お申込み完了', 'not'));
		return list;
	}
}
