import React, { ReactNode } from 'react';
import { WprBaseViewComponent } from '../../../../wpr-framework/view/component/WprBaseViewComponent';
import { ContsrNotesView } from './ContsrNotesView';
import { Typography, Grid, Divider } from '@material-ui/core';
import { WprSpan } from '../../../../wpr-framework/component/controls/WprSpan';
import { MatButton } from '../../../../wpr-framework/component/controls/material/MatButton';
import { MatTypography } from '../../../../wpr-framework/component/controls/material/MatTypography';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { MatCheckBox } from '../../../../wpr-framework/component/controls/material/MatCheckBox';
import styles from './ContsrNotes.scss';

/**
 * 工事_注意事項用コンポーネント
 */
export class ContsrNotes extends WprBaseViewComponent {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: any) { super(new ContsrNotesView(), props); }
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** 工事_注意事項ビュー情報 */
	public get viewInfo(): ContsrNotesView	{ return this.view as ContsrNotesView;	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * スタイルオブジェクト取得
	*/
	public getStylesObject(): any { return styles; }

	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		const url = this.state.data.sentenceData;
		return (
			<>
				<div className='pageCard'>
					<div className='main'>
						<div className='pageTitle'>
							<div className='title_left'>
								<MatTypography name='title' className='title_text' view={this.view}/>
								<Typography className='title_comment'>以下の注意事項説明をご承諾の上、「次へ」ボタンをクリックしてください。</Typography>
							</div>
						</div>
						<div className='attentionArea'>
							<ul className='ul_ten'>
								■工事に関する注意事項
								<li>
									MyPageから最短で申込できる予定日は本日より7日後からの予定日となります。
								</li>
								<li>
									予定日の変更やキャンセルは、工事予定日の7日前までマイページにて承ります。
								</li>
								<li>
									お客様のお住まいの地域、空き状況によっては予定日が表示されない場合がございます。
								</li>
								<li>
									工事内容や天候不順によって、工事が中止/延期になる場合がございます。
								</li>
								<li>
									月額料金に変更がある工事内容の場合、工事当月は現在の月額・工事翌月から変更後料金となります。
									<br/>当月1日に工事が予定される場合は、当月から変更後の月額料金となりますのでご注意ください。
								</li>
							</ul>
						</div>
						<div className='attentionArea'>
							<ul className='ul_ten'>
								■その他注意事項
								<li>
									でんきサービスとガスサービスの再点日・開栓日の変更/キャンセル/確認は<a href={url} target = '_blank'>こちら</a>へお問い合わせください。
								</li>
							</ul>
						</div>
						<div className='title_center'>
							<div className='title_center'>
								<MatCheckBox name='confirmFlg' label='注意事項に承諾しました。' view={this.view}/>
							</div>
						</div>
					</div>
					<Divider />
					<div className='pageFooter footer-space'>
						<div>
							<MatButton name='next' view={this.view} className='orangeButton orangeButton_parts' >
								次へ
								<NavigateNextIcon />
							</MatButton>
						</div>
						<div>
							<MatButton name='back' view={this.view} className='backButton' >
								<NavigateBeforeIcon />
								戻る
							</MatButton>

						</div>
					</div>
				</div>
			</>
		);
	}
	// --------------------------------------------------------------------------
}
