/**
 * XML情報作成クラス
 */
export class WprXmlInfoCreator {
	// private 変数  ------------------------------------------------------------
	private m_Doc: Document		= null;		// XMLドキュメント情報
	private m_Root: Element		= null;		// ルート要素
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	public constructor(root: string) {
		this.m_Doc = new Document();
		this.m_Root = this.m_Doc.createElement(root);
	}
	// --------------------------------------------------------------------------

	// private メソッド	----------------------------------------------------------
	/**
	 * ルート子要素追加
	 * @param name 要素名
	 * @returns 追加した要素
	 */
	public addRootChildElement(name: string): Element {
		const element = this.m_Doc.createElement(name);
		this.m_Root.appendChild(element);
		return element;
	}

	/**
	 * ルート子要素追加
	 * @param name 要素名
	 * @returns 追加した要素
	 */
	public addRootChildTextElement(name: string, text: string): Element {
		return this.addChildTextlement(this.m_Root, name, text);
	}

	/**
	 * 子要素追加
	 * @param element 親要素
	 * @param name 要素名
	 * @returns 追加した要素
	 */
	public addChildElement(element: Element, name: string): Element {
		const add = this.m_Doc.createElement(name);
		element.appendChild(add);
		return add;
	}

	/**
	 * Text子要素追加
	 * @param element 親要素
	 * @param name 要素名
	 * @param test テキスト
	 * @returns 追加した要素
	 */
	 public addChildTextlement(element: Element, name: string, text: string): Element {
		const add = this.addChildElement(element, name);
		const tnode = this.m_Doc.createTextNode(text);
		add.appendChild(tnode);
		return add;
	}

	/**
	 * Json子要素追加
	 * @param element 親要素
	 * @param name 要素名
	 * @param obj Jsonに変換するオブジェクト
	 * @returns 追加した要素
	 */
	public addChildJsonElement(element: Element, name: string, obj: any): Element {
		const add = this.addChildElement(element, name);
		const json = JSON.stringify(obj);
		const text = this.m_Doc.createTextNode(json);
		add.appendChild(text);
		return add;
	}

	/**
	 * 属性追加
	 * @param element 要素
	 * @param name 属性名
	 * @param val 属性値
	 */
	public addAttribute(element: Element, name: string, val: string) {
		const attr = this.m_Doc.createAttribute(name);
		attr.value = val;
		element.setAttribute(name, val);
	}

	/**
	 * HTMLコード種痘k
	 * @returns HTMLコード
	 */
	public getHtmlCode(): string {
		return this.m_Root.outerHTML;
	}
	// --------------------------------------------------------------------------
}
