import { WprBaseValidator } from '../WprBaseValidator';

/**
 * 最小値バリデータ
 */
export class WprMinValidator extends WprBaseValidator {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() {
		super('最小値');
	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * 入力値チェック
	 * @param value 入力値
	 * @param option チェックオプション
	 * @param dispValue 表示値
	 * @returns trueの場合、成功
	 */
	public checkValidValue(value: any, option?: any, dispValue?: any): boolean {
		if (!value)
			return true;
		if (!option)
			return false;
		if (typeof(option) === 'number') {
			const nval = Number(value);
			if (isNaN(nval) === true)
				return false;
			if (nval < option)
				return false;
			return true;
		}
		else {
			if (dispValue && dispValue.includes('/') === true)
				dispValue = dispValue.replace(/\//g, '-');
			const dval = new Date(dispValue);
			const oval = new Date(option);
			if (dval < oval)
				return false;
			return true;
		}
	}

	/**
	 * エラーメッセージ取得
	 * @param description エラーが発生したコントロールの説明
	 * @param option オプション
	 * @returns エラーメッセージ
	 */
	public getErrorMessage(description: string, option?: any): string {
		if (option) {
			if (typeof(option) === 'number')
				return option + '以上を入力してください。';
			else
				return option + '以降を入力してください。';
		}
		return '最小値エラー';
	}

	/**
	 * オプションパラメータ名取得
	 * @returns オプションパラメータ名
	 */
	public getOptionParam(): string {
		return 'min';
	}
	// --------------------------------------------------------------------------
}
