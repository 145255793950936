import { Table, TableBody, TableRow } from '@material-ui/core';
import React, { ReactNode } from 'react';
import { WprReflectUtil } from '../../../util/WprReflectUtil';
import { WprBaseComponent } from '../WprBaseComponent';

/**
 * １行テーブルコントロールプロパティ情報
 */
interface IMatOneRowTableProps {
	cols: number[];			// カラム幅配列
	id?: string;			// id
	className?: string;		// クラス
}

/**
 * １行テーブルコントロールステータス情報
 */
 interface IMatOneRowTableState {
 }

/**
 * 1行テーブルコントロール
 */
export class MatOneRowTable extends WprBaseComponent<IMatOneRowTableProps, IMatOneRowTableState> {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: IMatOneRowTableProps) {
		super(props);
	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * コントロール名称取得
	 * @returns コントロール名称
	 */
	 public getName(): string {
		 return null;
	 }

	 /**
	 * 描画
	 */
	public onRender(): ReactNode {
		// const props = this.getProps();
		const {children, cols, ...other} = this.props;
		return (
			<Table {...other}>
				<colgroup>
					{this.getCols()}
				</colgroup>
				<TableBody className='wpr-oneRowTable-body'>
					<TableRow className='wpr-oneRowTable-row'>
						{this.props.children}
					</TableRow>
				</TableBody>
			</Table>
		);
	}
	// --------------------------------------------------------------------------

	// private メソッド  ---------------------------------------------------------
	/**
	 * カラムノードリスト取得
	 * @returns オプション情報
	 */
	private getCols(): ReactNode[] {
		let total = 0;
		this.props.cols.forEach(col =>{
			total += col;
		});
		let idx = 0;
		let cols = this.props.cols.map(col => {
			idx++;
			return <col style={this.getWidthStyle(col, total)} key={'wpr_key' + String(idx)}/>;
		});
		return cols;
	}

	/**
	 * 幅スタイル取得
	 * @param col カラム番号
	 * @param total トータル数
	 * @returns 幅スタイル
	 */
	private getWidthStyle(col: number, total: number) {
		const rtn: any = {};
		rtn['width'] = `${col*100/total}%`;
		return rtn;

	}
	// --------------------------------------------------------------------------
}