import { BaseService } from './base/BaseService';
import { WprBaseViewCore } from '../../wpr-framework/view/WprBaseViewCore';
import { GetTvInfoRequest } from './models/dto/mypagerenewal/GetTvInfoRequest';
import { GetTvInfoResponse } from './models/dto/mypagerenewal/GetTvInfoResponse';
import { GetTvContractDateRequest } from './models/dto/itscomTv/GetTvContractDateRequest';
import { GetTvContractDateResponse } from './models/dto/itscomTv/GetTvContractDateResponse';
import { RegistTvOptionRequest } from './models/dto/mypagerenewal/RegistTvOptionRequest';
import { RegistTvOptionResponse } from './models/dto/mypagerenewal/RegistTvOptionResponse';

/**
 * ItscomTvサービス
 */
export class ItscomTvService extends BaseService {
	// サービス メソッド  --------------------------------------------------------
	/**
	 * 一覧取得
	 * @param viewCore ビュー情報
	 * @param request リクエスト情報
	 * @param resMethod 結果処理メソッド
	 */
	public getOwnerList(viewCore: WprBaseViewCore, request: GetTvInfoRequest, resMethod: (result: GetTvInfoResponse) => void): void {
		return this.doPost(viewCore, '/itsconTv/getOwnerList', request, resMethod);
	}

	/**
	 * 契約開始日取得
	 * @param viewCore ビュー情報
	 * @param request リクエスト情報
	 * @param resMethod 結果処理メソッド
	 */
	public getContractDate(viewCore: WprBaseViewCore, request: GetTvContractDateRequest, resMethod: (result: GetTvContractDateResponse) => void): void {
		return this.doPost(viewCore, '/itsconTv/getContractDate', request, resMethod);
	}

	/**
	 * オプションチャンネル登録
	 * @param viewCore ビュー情報
	 * @param request リクエスト情報
	 * @param resMethod 結果処理メソッド
	 */
	public registOption(viewCore: WprBaseViewCore, request: RegistTvOptionRequest, resMethod: (result: RegistTvOptionResponse) => void): void {
		return this.doPost(viewCore, '/itsconTv/registOption', request, resMethod);
	}
	// --------------------------------------------------------------------------
}
