import { Divider, Grid, TableCell, Typography } from '@material-ui/core';
import React, { ReactNode } from 'react';
import { MatOneRowTable } from '../../../../../wpr-framework/component/controls/material/MatOneRowTable';
import { MatTypography } from '../../../../../wpr-framework/component/controls/material/MatTypography';
import { WprContainer } from '../../../../../wpr-framework/component/controls/WprContainer';
import { WprBaseDialogViewComponent } from '../../../../../wpr-framework/view/component/WprBaseDialogViewComponent';
import { HelpWebFilteringDialogView } from './HelpWebFilteringDialogView';

import image1 from '/shared/image/help/webfilterring/image1.png';
import image2 from '/shared/image/help/webfilterring/image2.png';
import image3 from '/shared/image/help/webfilterring/image3.png';
import image4 from '/shared/image/help/webfilterring/image4.png';

import styles from './HelpWebFilteringDialog.scss';

/**
 * ヘルプ：Webフィルタリングについて用コンポーネント
 */
export class HelpWebFilteringDialog extends WprBaseDialogViewComponent {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: any) { super(new HelpWebFilteringDialogView(), props); }
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** ヘルプ：Webフィルタリングについてビュー情報 */
	public get viewInfo(): HelpWebFilteringDialogView { return this.view as HelpWebFilteringDialogView; }
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * スタイルオブジェクト取得
	*/
	public getStylesObject(): any { return styles; }

	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		return (
			<>
				<div className='dialogHeader'>
					<Typography className='title'>Webフィルタリングについて</Typography>
				</div>
				<div className='dialogContent'>
					<div>
						<div>
							有害サイトフィルタリングソフトWebフィルタリング（i-フィルター）は、何気ない書き込みなどから思わぬいじめにつながる掲示板、子どもの心に傷を残すような犯罪や自殺、出会い系のサイト、不用意な入力が個人情報漏えいにいたるプロフや詐欺まがいのサイトなど、これらインターネット上に無数に広がる危険を未然にブロックすることでインターネットアクセスを制御して“お子さまを守る”フィルタリングソフトウェアです。<br />
							本サービスを18歳未満の方がご利用される場合は、原則としてwebフィルタリングにお申し込みいただく必要があります。
						</div>
					</div>

					<div>
						<div className='itemTitle'>Webフィルタリング（i-フィルター）で回避！インターネット上の危険</div>
						<div style={{margin: '0 0.5rem'}}>
							<Grid container>
								<Grid item xs={9}>
									<div className='itemTitle title_maru'>不適切サイト</div>

									<div>
										インターネットで学校の宿題をしていました。<br />
										ただ、普通の言葉で検索をしていただけなのに、突然、変なサイトが出てきました。
									</div>
								</Grid>
								<Grid item xs={3}>
									<img src={image1} style={{ width: '100%' }} />
								</Grid>

								<Grid item xs={9}>
									<div className='itemTitle title_maru'>いじめ・悪口
									</div>

									<div>
										クラスの友だちみんなと掲示板で話をしています。<br />
										いつもどおり冗談まじりで書き込みをしただけなのに、友だちにどんどん悪口を書き込まれてしまいました。
									</div>
								</Grid>
								<Grid item xs={3}>
									<img src={image2} style={{ width: '100%' }} />
								</Grid>

								<Grid item xs={9}>
									<div className='itemTitle title_maru'>遊び過ぎ・ゲーム中毒</div>

									<div>
										インターネットってすごく楽しいです。<br />
										つい夢中になってしまい、遅い時間まで遊んでしまいました。学校でも眠くて授業に身が入りません。
									</div>
								</Grid>
								<Grid item xs={3}>
									<img src={image3} style={{ width: '100%' }} />
								</Grid>

								<Grid item xs={9}>
									<div className='itemTitle title_maru'>ネット詐欺</div>

									<div>
										商品が当たる楽しそうなイベントサイトを見ました。<br />
										名前と住所、メールアドレスを書いて応募しました。<br />
										知らない相手から、変なメールが届くようになってしまいました。
									</div>
								</Grid>
								<Grid item xs={3}>
									<img src={image4} style={{ width: '100%' }} />
								</Grid>
							</Grid>

						</div>
					</div>
				</div>
			</>
		);
	}
	// --------------------------------------------------------------------------
}
