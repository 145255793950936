import { WprModel } from '../../../../../wpr-framework/view/deco/WprModel';
import { WprDI_ScopeModel } from '../../../../../wpr-framework/view/di/WprDI_ScopeModel';
import { ObjectUtil } from '../../../../common/util/ObjectUtil';
import { TvBaseModel } from '../../../../models/mypage/tv/TvBaseModel';
import { TvOptionListModel } from '../../../../models/mypage/tv/TvOptionListModel';
import { MypageBaseView } from '../../../base/MypageBaseView';
import { ProgressBarData } from '../../../common/progress_bar/ProgressBarView';
import { MypageWizardMngr } from '../../../wizard/models/MypageWizardMngr';
import { EquipmentModel } from '../../../../view/tv/equipment/mod_tvequip_select/models/EquipmentModel';
import { WprViewMode } from '../../../../../wpr-framework/view/deco/WprViewMode';
import { TvEquipCheckMode } from './mode/TvEquipCheckMode';
import { Contract } from '../../../../models/bss/contract/Contract';
import { WprValidError } from '../../../../../wpr-framework/view/validation/WprValidError';
import { DateUtil } from '../../../../common/util/DateUtil';
import MypageDispdivcdConstants from '../../../../common/MypageDispdivcdConstants';
import { WprListData } from '../../../../../wpr-framework/view/deco/WprListData';
import { ErrorModel } from '../../../Srvother/add_nhk_select/models/ErrorModel';
import { GetGradeRequest } from '../../../../service/models/dto/mypagerenewal/GetGradeRequest';

/**
 * 機器変更選択
 */
export class ModTvequipSelectView extends MypageBaseView {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() { super('ModTvequipSelectView'); }
	// --------------------------------------------------------------------------

	// モデル  ------------------------------------------------------------------
	@WprDI_ScopeModel('MypageWizardMngr')
	private m_MypageWizardMngr: MypageWizardMngr			= null;			// 画面ウィザード管理クラス
	@WprModel('EquipmentModel')
	private m_EquipmentModel: EquipmentModel	= null;	// 機器モデル
	@WprModel('TvBaseModel')
	private m_TvBaseModel: TvBaseModel	= null;	// テレビ基本情報モデル
	@WprListData('ErrorModel')
	private m_ErrorModelList: ErrorModel[]	= null;	// エラーモデル
	// --------------------------------------------------------------------------

	// サブビュー/モード  --------------------------------------------------------
	@WprViewMode
	private m_TvEquipCheckMode = new TvEquipCheckMode();	// 機器変更チェックモード
	// --------------------------------------------------------------------------

	// private変数 --------------------------------------------------------------
	private m_Contract: Contract = null;
	private m_FtthFlg: boolean = false;
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * コントロール定義設定
	 */
	public onRegisterConfig(): void {
		this.addConfig('bdHitpot', 'BD-HitPot', 'EquipmentModel', { bindName: 'bdHitPot' });
		this.addConfig('hitpot', 'HitPot', 'EquipmentModel', { bindName: 'hitPot' });
		this.addConfig('stb', 'STB', 'EquipmentModel');
		this.addConfig('rental', 'レンタル', 'EquipmentModel');
	}

	/**
	 * アクション登録
	 */
	public onRegisterAction(): void {
		this.addAction('next', this.onNext, true);
		this.addAction('back', this.onBack);
	}

	/**
	 * ビュー表示通知
	 */
	public onShowView(): void {
		this.m_MypageWizardMngr.initView(this, (result) => {
			this.registAccessLog();
			this.setStorageItem('b_url', window.location.href);
			const sendData: ProgressBarData = new ProgressBarData();
			sendData.progressBarList = this.m_MypageWizardMngr.getProgressModelList(this);
			this.sendChildData('fP_ev_progressBarIntital', sendData);
			this.m_TvBaseModel = this.m_MypageWizardMngr.getCache().m_TvBaseModel;
			this.m_EquipmentModel = new EquipmentModel();
			//FTTH判定
			this.m_FtthFlg = false;
			const contractId = this.m_MypageWizardMngr.getParams().contractId;
			if (ObjectUtil.isNotNullOrEmptyStr(contractId)) 
				this.m_Contract = this.m_MypageWizardMngr.getContract(this, contractId);
			this.m_TvBaseModel.courseCd = this.m_Contract.serviceCD;
			this.m_TvBaseModel.course = this.m_MypageWizardMngr.getMDisplayList(this, MypageDispdivcdConstants.TV_SERVICE, '').filter(item => item.namecd === this.m_Contract.serviceCD)[0].dispnamep;
			this.m_TvBaseModel.constractionFlg = true;
			const courseList = this.m_MypageWizardMngr.getMCommonList(this, 'srvtv', 'srvtv_ftth_course', 'course', null);
			for (const course of courseList) {
				if (course.commonValue === this.m_Contract.serviceCD)
					this.m_FtthFlg = true;
			}
			//初期値設定
			this.m_EquipmentModel.rental = true;
			this.m_TvBaseModel.applyType = 'レンタル';
			if (this.m_FtthFlg) {
				this.m_EquipmentModel.hitPot = true;
				this.m_TvBaseModel.terminal = 'HitPot';
				this.setViewMode('区分', 'FTTH');
			}
			else {
				this.m_EquipmentModel.bdHitPot = true;
				this.m_TvBaseModel.terminal = 'BD-HitPot';
				this.setViewMode('区分','FTTH以外');
			}
			this.refreshView();
		});
	}

	/**
	 * 値変更通知
	 * @param name 名前
	 * @param value 値
	 * @returns falseの場合、変更を取り消す
	 */
	public onChangeValue(name: string, value: any): boolean {
		this.refreshModel();
		if (name === 'bdHitpot') {
			this.m_EquipmentModel.hitPot = false;
			this.m_EquipmentModel.stb = false;
			this.m_TvBaseModel.terminal = 'BD-HitPot';
		}
		else if (name === 'hitpot') {
			this.m_EquipmentModel.bdHitPot = false;
			this.m_EquipmentModel.stb = false;
			this.m_TvBaseModel.terminal = 'HitPot';
		}
		else if (name === 'stb') {
			this.m_EquipmentModel.bdHitPot = false;
			this.m_EquipmentModel.hitPot = false;
			this.m_TvBaseModel.terminal = 'STB';
		}
		this.refreshView();
		return true;
	}
	// --------------------------------------------------------------------------

	// アクション  ---------------------------------------------------------------
	/**
	 * 次へ         <NavigateNextIcon />
	 */
	public onNext(param: any): void {
		this.m_MypageWizardMngr.goNext(this);
	}

	/**
	 * <NavigateBeforeIcon />         戻る
	 */
	public onBack(param: any): void {
		this.m_MypageWizardMngr.goBack(this);
	}
	// --------------------------------------------------------------------------
}
