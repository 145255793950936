import { WprValueMap } from '../../../../wpr-framework/model/WprValueMap';
import { WprValueMapSetter } from '../../../../wpr-framework/model/WprValueMapSetter';

/**
 * 月
 */
export class MonthValueMap {
	// static メソッド  --------------------------------------------------------
	public static addValueMap(vmSetter: WprValueMapSetter): void {
		let vm = new WprValueMap();
		vm.addValue('1', '1');
		vm.addValue('2', '2');
		vm.addValue('3', '3');
		vm.addValue('4', '4');
		vm.addValue('5', '5');
		vm.addValue('6', '6');
		vm.addValue('7', '7');
		vm.addValue('8', '8');
		vm.addValue('9', '9');
		vm.addValue('10', '10');
		vm.addValue('11', '11');
		vm.addValue('12', '12');
		vmSetter.addValueMap('month', vm);
	}
	// --------------------------------------------------------------------------
}
