import { WprModel } from '../../../../wpr-framework/view/deco/WprModel';
import { WprDI_ScopeModel } from '../../../../wpr-framework/view/di/WprDI_ScopeModel';
import { ConstrBaseModel } from '../../../models/mypage/Construction/ConstrBaseModel';
import { MypageBaseView } from '../../base/MypageBaseView';
import { ProgressBarData } from '../../common/progress_bar/ProgressBarView';
import { MypageWizardMngr } from '../../wizard/models/MypageWizardMngr';

/**
 * 工事予定日変更
 */
export class ConstrChangeView extends MypageBaseView {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() { super('ConstrChangeView'); }
	// --------------------------------------------------------------------------

	// モデル  ------------------------------------------------------------------
	@WprDI_ScopeModel('MypageWizardMngr')
	private m_MypageWizardMngr: MypageWizardMngr			= null;		// 画面ウィザード管理クラス
	@WprModel('ConstrBaseModel')
	private m_ConstrBaseModel: ConstrBaseModel	= null;	// 工事日基本情報モデル
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * コントロール定義設定
	 */
	public onRegisterConfig(): void {
		this.addConfig('date', '予定日', 'ConstrBaseModel.workScheduleList.workScheduleList', { bindName: 'schedule_date', converter: '日付（yyyy/MM/dd）' });
		this.addConfig('dayOfWeek', '曜日', 'ConstrBaseModel.workScheduleList');
		this.addConfig('time', '時間', 'ConstrBaseModel.workScheduleList', { bindName: 'preferredTime' });
	}

	/**
	 * アクション登録
	 */
	public onRegisterAction(): void {
		this.addAction('nextChange', this.onNextChange);
		this.addAction('cancel', this.onCancel);
	}

	/**
	 * ビュー表示通知
	 */
	public onShowView(): void {
		this.m_MypageWizardMngr.initView(this, (result) => {
			this.registAccessLog();
			this.setStorageItem('b_url', window.location.href);
			const sendData: ProgressBarData = new ProgressBarData();
			sendData.progressBarList = this.m_MypageWizardMngr.getProgressModelList(this);
			this.sendChildData('fP_ev_progressBarIntital', sendData);
			this.m_ConstrBaseModel = this.m_MypageWizardMngr.getCache().m_ConstrBaseModel;
			this.refreshView();
		});
	}
	// --------------------------------------------------------------------------

	// アクション  ---------------------------------------------------------------
	/**
	 * 次へ          <NavigateNextIcon />
	 */
	public onNextChange(param: any): void {
		this.m_MypageWizardMngr.goNext(this);
	}

	/**
	 * <NavigateBeforeIcon />          戻る
	 */
	public onCancel(param: any): void {
		this.m_MypageWizardMngr.goBack(this);
	}
	// --------------------------------------------------------------------------
}
