import React, { Fragment, ReactNode } from 'react';
import { WprBaseCssModuleComponent } from '../../component/controls/WprBaseCssModuleComponent';
import { WprMessageComponent } from '../../component/message/WprMessageComponent';
import { WprSnackbarComponent } from '../../component/message/WprSnackbarComponent';
import { WprRowInfo } from '../../component/props/WprRowInfo';
import { WprReflectUtil } from '../../util/WprReflectUtil';
import { IWprViewProps } from '../props/IWprViewProps';
import { IWprViewState } from '../props/IWprViewState';
import { WprBaseViewInfo } from '../WprBaseViewInfo';
import { WprOverrideCheckView } from '../WprOverrideCheckView';
import { WprListRenderInfo } from './render/WprListRenderInfo';

/**
 * ビューコンポーネント基本クラス
 */
export abstract class WprBaseViewCoreComponent<T extends IWprViewProps> extends WprBaseCssModuleComponent<T, IWprViewState> {
	// static private 変数  -----------------------------------------------------
	private static m_IsStart: boolean = true;												// 初回フラグ
	private static m_OverrideCheck: WprOverrideCheckView = new WprOverrideCheckView();		// オーバーライドチェック用ビュー情報
	// --------------------------------------------------------------------------

	// private 変数  ------------------------------------------------------------
	private m_ViewInfo: WprBaseViewInfo 					= null;										// ビュー情報
	private m_ListRenderMap: Map<string, WprListRenderInfo>	= new Map<string, WprListRenderInfo>();		// リスト描画情報マップ
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** ビュー情報 */
	public get view(): WprBaseViewInfo 	{ return this.m_ViewInfo; 	}
	/** メッセージフラグ */
	public get isMessage(): boolean		{ return false; 			}
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	constructor(viewInfo: WprBaseViewInfo, props: any) {
		super(props);
		this.m_ViewInfo = viewInfo;
		viewInfo.setScopeInfo();
		viewInfo.setComponent(this);
		viewInfo.initialize();
		this.state = {
			mode: {},
			data: {},
			listData: {},
			scopeMode: viewInfo.getScopeModeState(),
			scopeModel: viewInfo.getScopeModelState(),
			globalMode: viewInfo.getGlobalModeState(),
			globalModel: viewInfo.getGlobalModelState(),
			open: false,
			reserve: false,
			renderWait: false,
			helmet: false,
			title: null,
			restart: false,
			tab: false,
			tabDisp: 0
		};
	}
	// --------------------------------------------------------------------------

	// abstract/virtual メソッド  ------------------------------------------------
	/**
	 * マウント通知
	 */
	protected onDidMount(): void {
	}

	/**
	 * Helmet描画
	 */
	public onHelmetRender(): ReactNode {
		return null;
	}

	/**
	 * 描画
	 */
	public abstract onRender(): ReactNode;

	/**
	 * パラメータチェック
	 */
	public onCheckParam(): void {}
	// --------------------------------------------------------------------------

	// ライフサイクル  -----------------------------------------------------------
	/**
	 * マウント通知
	 */
	public componentDidMount() {
//		this.m_ViewInfo.addInfoLog('== View componentDidMount() ==');
		this.m_ViewInfo.isMount = true;
		let showView = true;
//		if (WprBaseViewCoreComponent.m_IsStart === true) {
//			WprBaseViewCoreComponent.m_IsStart = false;
//			return;
		if (this.props.view) {
			showView = this.props.view.isMount;
			if (this.props.view.setTabChildView(this.m_ViewInfo) === true)
				return;
			if (this.props.view.addChildViewInfo(this.props.name, this.m_ViewInfo) === false) {
				this.m_ViewInfo.addInfoLog('== Wait show View ==');
				showView = false;
			}
		}
		this.onDidMount();
		this.onRegisterListRender();
		if (this.props.row)
			this.m_ViewInfo.setRowData(this.props.row.rowData);
		this.m_ViewInfo.endRenderWait();
		if (showView)
			this.m_ViewInfo.showView();
		else if (this.m_ViewInfo.isDialog === false)
			this.m_ViewInfo.FW.view.addViewInfo(this.m_ViewInfo);
	}

	/**
	 * 描画
	 */
	public render(): ReactNode {
		this.onCheckParam();
		if (this.props.row)
			this.m_ViewInfo.updateRowData(this.props.row.rowData);
		this.view.updateReserveViewMode();
		if (this.state.renderWait === true)
			return null;
		const helmet = this.onHelmetRender();
		if (helmet != null && this.state.helmet === false) {
			this.setHelmetState();
			return (
				<>{helmet}</>
			);
		}
		if (this.state.tab === true)
			this.m_ViewInfo.removeTabChildView();
		if (this.state.tab === false && this.m_ViewInfo.isTabChildView() === true)
			return <></>;
//		if (this.m_ViewInfo.waitRender(this.props.view) === true)
//			return <></>;
		const message = this.getMessageComponent();
		this.m_ViewInfo.addDebugInfoLog('onRender()', '== View onRender() ==');
		if (this.props.name) {
			const props = this.getProps();
			return (
				<div {...props}>
					{helmet}
					{this.getRenderComponent()}
					{message}
				</div>
			);
		}
		else {
			return (
				<>
					{helmet}
					{this.getRenderComponent()} 
					{message}
				</>
			);
		}
	}

	/**
	 * アンマウント通知
	 */
	public componentWillUnmount() {
		this.m_ViewInfo.isMount = false;
		this.m_ViewInfo.terminate();
		if (this.props.view)
			this.props.view.deleteChildViewInfo(this.props.name, this.m_ViewInfo);
		this.terminate();
	}
	// --------------------------------------------------------------------------

	// virtual メソッド  ---------------------------------------------------------
	/**
	 * リスト描画設定
	 */
	protected onRegisterListRender(): void {}
	// --------------------------------------------------------------------------

	// public メソッド  ---------------------------------------------------------
	/**
	 * タブビューマウント処理
	 */
	public tabViewMount() {
		this.onDidMount();
		this.onRegisterListRender();
	}
	// --------------------------------------------------------------------------

	// protected メソッド  ------------------------------------------------------
	/**
	 * レンダーコンポーネント取得
	 * @returns 
	 */
	protected getRenderComponent(): ReactNode {
		const cssObj = this.getStylesObject();
		let rnode = this.onRender() as any;
		if (cssObj != null) {
			const child = this.checkChildClassName(rnode);
			let clsName = this.getLocalClassName(this.getClassName(rnode));
			if (child != null || clsName != null) {
				let props = this.getNodeProps(rnode.props, clsName);
				this.endRender();
				return React.createElement(rnode.type, props, child);
			}
		}
		this.endRender();
		return rnode;
	}
	
	/**
	 * リスト描画メソッド追加
	 * @param listName リスト名
	 * @param type 描画種別
	 * @param renderMethod 描画メソッド
	 */
	protected addListRender(listName: string, type: string, renderMethod: (row: WprRowInfo) => any) {
		const key = this.getListKey(listName, type);
		if (this.m_ListRenderMap.has(key) === false) {
			const rinfo = new WprListRenderInfo(listName, type, this, renderMethod);
			this.m_ListRenderMap.set(key, rinfo);
		}
	}

	/**
	 * リスト描画処理
	 * @param listName リスト名
	 * @param type 描画種別(同一リストで異なる描画をする場合に使用する)
	 */
	protected renderList(listName: string, type: string = null): any {
		if (WprReflectUtil.checkProperty(this.state.listData, listName) === true) {
			const listData = WprReflectUtil.getProperty(this.state.listData, listName) as any[];
			if (listData != null) {
				let list = new Array();
				listData.map((row, index) => {
					const rinfo = new WprRowInfo(listName, type, index, row, this.m_ViewInfo);
					const key = this.getListKey(listName, type);
					if (this.m_ListRenderMap.has(key) === true) {
						const child = this.m_ListRenderMap.get(key).render(rinfo);
						if (child.props && !child.props['key']) {
							let { ...props } = child.props;
							let key = listName + '_' + String(index);
							if (type)
								key = listName + '_' + type + '_' + String(index);
							props['key'] = key;
							let node = React.createElement(child.type, props);
							list.push(node);
						}
						else {
							list.push(child);
						}
						/*
						const props = { key: index };
						const child = this.m_ListRenderMap.get(key).render(rinfo);
						if (child.props && child.props.label) {
							list.push(child);
						}
						else {
							const fragment = React.createElement(Fragment, props, child);
							list.push(fragment);
						}
						*/
					}
				});
				return list;
			}
		}
		else {
			// error
		}
		return undefined;
	}

	/**
	 * ビューへデータを送信する
	 * @param dataName データ名
	 * @param data データ
	 */
	protected sendViewData(dataName: string, data?: any): void {
		this.view.receiveData(dataName, data);
	}

	/**
	 * Helmetステータス設定
	 */
	protected setHelmetState() {
		setTimeout(() => {
			this.setState({
				helmet: true
			});
		}, 10);
	}

	/**
	 * <br>付きコンテント取得
	 * @returns <br>付きコンテント
	 */
	protected getBrContent(content: string): any[] {
		const contentList = new Array();
		let key = 1;
		while(content.includes('<br') === true) {
			const sidx = content.indexOf('<br');
			const eidx = content.indexOf('/>');
			contentList.push(content.substring(0, sidx));
			contentList.push(this.getBr(content.substring(sidx, eidx), key));
			content = content.substring(eidx+2);
			key++;
		}
		contentList.push(content);
		return contentList;
	}

	/**
	 * イベント情報取得
	 * @param name コントロール名
	 * @returns イベント情報
	 */
	protected getEvents(name: string): any {
		return this.view.getEventsInfo(name);
	}
	// --------------------------------------------------------------------------

	// private メソッド  ---------------------------------------------------------
	/**
	 * リストキー取得
	 * @param listName リスト名
	 * @param type 描画種別
	 * @returns リストキー
	 */
	private getListKey(listName: string, type: string) {
		if (!type)
			type = '';
		return listName + '@' + type;
	}

	/**
	 * プロパティ情報取得
	 * @param cls オリジナルクラス
	 * @returns プロパティ情報
	 */
	protected getProps(cls?: string, setClassName: boolean = true): any {
		const rtn: any = {};
		if (this.props.name)
			rtn['name'] = this.props.name;
		return rtn;
	}

	/**
	 * メッセージコンポーネント取得
	 */
	 private getMessageComponent() {
		if (this.m_ViewInfo.useMessageComponent() === true) {
			return (
				<>
					<WprMessageComponent view={this.view}/>
					<WprSnackbarComponent view={this.view}/>
				</>
			);
		}
		return (
			<>
			</>
		);
	}

	/**
	 * <br>取得（classNameのみ判定）
	 * @returns <br>
	 */
	 private getBr(content: string, key: number): ReactNode {
		if (content.includes('className') === true) {
			const idx = content.indexOf('className');
			const str = content.substring(idx);
			const list = str.split('\'');
			return <br className={list[1]} key={key}/>;
		}
		return <br key={key}/>;
	}

	/**
	 * レンダー終了処理
	 */
	private endRender() {
		if (this.m_ViewInfo.onEndRender !== WprBaseViewCoreComponent.m_OverrideCheck.onEndRender) {
			// renderが終わってもすぐにDOMに反映されないので、遅延で実行する
			setTimeout(() => {
				this.m_ViewInfo.onEndRender();
			}, 0);
		}
	}

	/**
	 * 終了処理
	 */
	private terminate(): void {
		this.m_ViewInfo = null;
		this.m_ListRenderMap = null;
	}
	// --------------------------------------------------------------------------
}
