import { IconButton } from '@material-ui/core';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import React, { ReactNode } from 'react';
import { IWprTableSortMode } from '../../../mode/table/IWprTableSortMode';
import { IWprNoNameLayoutProps } from '../../props/IWprLayoutProps';
import { WprBaseNoNameLayoutComponent } from '../WprBaseNoNameLayoutComponent';

/**
 * テーブルソートラベルコントロールプロパティ情報
 */
interface IMatTableSortProps extends IWprNoNameLayoutProps {
	listName: string;					// リスト名
	item: string;						// アイテム
	label?: string;						// ラベル
	buttonAlign?: 'left' | 'right'; 	// ボタン配置（デフォルトはright）
}

/**
 * テーブルソートラベルコントロール
 */
export class MatTableSortLabel extends WprBaseNoNameLayoutComponent<IMatTableSortProps, HTMLButtonElement> implements IWprTableSortMode {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: IMatTableSortProps) {
		super(props);
	}
	// --------------------------------------------------------------------------

	// イベント  ----------------------------------------------------------------
	/**
	 * 昇順ソート処理
	 * @param event 変更イベント 
	 */
	public onAsc(event: React.MouseEvent<HTMLButtonElement>): void {
		this.addClickEventLog(event.target as HTMLButtonElement);
		this.props.view.setTableSortMode(this.props.listName, this.props.item, true, true);
	}

	/**
	 * 降順ソート処理
	 * @param event 変更イベント 
	 */
	 public onDesc(event: React.MouseEvent<HTMLButtonElement>): void {
		this.addClickEventLog(event.target as HTMLButtonElement);
		this.props.view.setTableSortMode(this.props.listName, this.props.item, false, true);
	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * マウント通知
	 */
	public onDidMount() {
		this.props.view.setTableSortModeComponent(this, this.props.listName, this.props.item);
	}

	/**
	 * 描画
	 */
	public onRender(): ReactNode {
//		if (this.isMount == false)
//			return <></>;
		let button = null;
		let label = null;
		if (this.props.label != null)
			label = <span className='wpr-tsort-label'>{this.props.label}</span>;
		let style = {};
		if (this.state.value === null || this.state.value !== this.props.item)
			style = {opacity: 0.3, padding: 0, margin: 3};
		else
			style = {padding: 0, margin: 3};
		if (this.state.option1 === true)
			button = <IconButton onClick={this.onDesc.bind(this)} className='wpr-tsort-up' style={style}><ArrowDropUpIcon/></IconButton>;
		else
			button = <IconButton onClick={this.onAsc.bind(this)} className='wpr-tsort-down' style={style}><ArrowDropDownIcon/></IconButton>;
		if (this.props.buttonAlign === 'left') {
			return (
				<>
					{button}{label}
				</>
			);
		}
		else {
			return (
				<>
					{label}{button}
				</>
			);
		}
	}

	/**
	 * 要素固有XPath情報取得
	 * @param element 要素
	 * @returns 要素固有XPath情報
	 */
	protected getElementXPathInfo(element: HTMLElement): string {
		const th = this.getParentElement(element, 'TH');
		const idx = this.getChildIndex(th);
		return `//${th.tagName}[${idx}]//BUTTON`;
	}
	// --------------------------------------------------------------------------

	// IWprTableSortModeの実装  -------------------------------------------------
	/**
	 * テーブルソートモード設定処理
	 * @param mode モード
	 * @param isAsc 昇順フラグ
	 */
	 public setSortMode(mode: string, isAsc: boolean): void {
		if (mode === this.props.item) {
			this.setState({
				value: mode,
				option1: isAsc
			});
		}
		else {
			this.setState({
				value: mode
			});
		}
	}
	// --------------------------------------------------------------------------
}