import { TableContainer, Typography } from '@material-ui/core';
import React, { ReactNode } from 'react';
import { IWprTableContainer } from '../../../mode/table/IWprTableContainer';
import { IWprNoNameLayoutProps } from '../../props/IWprLayoutProps';
import { WprBaseNoNameLayoutComponent } from '../WprBaseNoNameLayoutComponent';

/**
 * ページングプロパティ情報
 */
interface IMatTableContainerProps extends IWprNoNameLayoutProps {
	listName: string;			// リスト名
	noDataMessage?: string;		// データ無しメッセージ
	noDataUseHeader?: boolean;	// データ無しヘッダー使用フラグ
	noDataVariant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'subtitle1' | 'subtitle2' | 'body1' | 'body2' | 'caption' | 'button' | 'overline' | 'srOnly' | 'inherit';		// データ無し形状
	noDataColor?: 'initial' | 'inherit' | 'primary' | 'secondary' | 'textPrimary' | 'textSecondary' | 'error';																// データ無し色
}

/**
 * ページング
 */
export class MatTableContainer extends WprBaseNoNameLayoutComponent<IMatTableContainerProps, JSX.Element> implements IWprTableContainer {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: IMatTableContainerProps) {
		super(props);
	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * マウント通知
	 */
	 public onDidMount() {
		this.props.view.setTableContainerComponent(this, this.props.listName);
	 }

	 /**
	 * 描画
	 */
	public onRender(): ReactNode {
		const listInfo = this.props.view.getListInfo(this.props.listName);
		const props = this.getProps();
		const {view, children, listName, noDataMessage, noDataVariant, noDataColor, ...other} = this.props;
		const option = this.getOptions();
		let noDataMsg = '検索条件に一致するデータはありません。';
		if (this.props.noDataMessage)
			noDataMsg = this.props.noDataMessage;
		if (this.state.value == null)
			return <></>;
		let tableContainer = null;
		if (this.state.value > 0) {
			tableContainer = <TableContainer {...props} {...other}>{this.props.children}</TableContainer>;
		}
		else {
			tableContainer = <div className={this.getNoClassName('wpr-table-no-data')}><Typography {...option} className={this.getNoClassName('wpr-table-no-data-msg')} component='span'>{noDataMsg}</Typography></div>;
			if (this.props.noDataUseHeader) {
				tableContainer = (
					<>
						<TableContainer {...props} {...other}>{this.props.children}</TableContainer>
						{tableContainer}
					</>);
			}
		}
		return (
			<>
				{tableContainer}
			</>
		);
	}

	/**
	 * プロパティ情報取得
	 * @param cls オリジナルクラス
	 * @returns プロパティ情報
	 */
	protected getProps(cls?: string, setClassName: boolean = true): any {
		const rtn = super.getProps();
		if (this.props.listName)
			rtn['name'] = this.props.listName;
		return rtn;
	}
	// --------------------------------------------------------------------------

	// private メソッド  ---------------------------------------------------------
	/**
	 * オプション情報取得
	 * @returns オプション情報
	 */
	 private getOptions(): any {
		const rtn: any = {};
		if (this.props.noDataVariant)
			rtn['variant'] = this.props.noDataVariant;
		else
			rtn['variant'] = 'h6';
		if (this.props.noDataColor)
			rtn['color'] = this.props.noDataColor;
		return rtn;
	}

	/**
	 * 行なしクラス取得（localを解決）
	 * @param cls クラス 
	 * @returns クラス
	 */
	private getNoClassName(cls: string): string {
		const local = this.props.view.getLocalClassName(cls);
		if (local != null)
			return local;
		return cls;
	}
	// --------------------------------------------------------------------------

	// IWprTableContainerの実装  ------------------------------------------------
	/**
	 * データ数設定
	 * @param cnt データ数
	 */
	public setDataCount(cnt: number): void {
		this.setState({
			value: cnt
		});
	}
	// --------------------------------------------------------------------------
}
