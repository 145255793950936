import { WprBaseObject } from '../../WprBaseObject';
import { IWprConfigOption } from '../control/IWprConfigOption';
import { WprControlInfo } from '../control/WprControlInfo';
import { WprValidatorInfo } from './WprValidatorInfo';
import { WprValidError } from './WprValidError';
import { WprInvalidInfo } from './WprInvalidInfo';

/**
 * コントロールバリデータ
 */
export class WprControlValidator extends WprBaseObject {
	// private 変数  ------------------------------------------------------------
	private m_Description: string							= null;									// コントロール説明
//	private m_ValidatorInfoList: WprValidatorInfo[]			= null;									// バリデーション情報リスト
	private m_ValidatorMap: Map<string, WprValidatorInfo>	= new Map<string, WprValidatorInfo>();	// バリデーション情報マップ
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	public constructor(description: string, config?: IWprConfigOption) {
		super();
		this.m_Description = description;
		if (config)
			this.initialize(config);
	}
	// --------------------------------------------------------------------------

	// public メソッド  ----------------------------------------------------------
	/**
	 * 入力値チェック
	 * @param value 入力値
	 * @returns バリデーションエラー情報リスト
	 */
	public checkValue(value: any): WprInvalidInfo[] {
		let errorList: WprInvalidInfo[] = null;
		this.m_ValidatorMap.forEach(vinfo => {
			if (vinfo.checkValidValue(value) === false) {
				if (errorList === null)
					errorList = new Array();
				errorList.push(vinfo.getInvalidInfo());
			}
		});
		return errorList;
	}
		
	/**
	 * 入力チェック処理
	 * @param cinfo コントロール情報
	 * @param validError 入力エラー情報
	 * @returns falseの場合入力エラー
	 */
	public checkValidate(cinfo: WprControlInfo, validError: WprValidError): boolean {
		let ret = true;
		this.m_ValidatorMap.forEach(vinfo => {
			if (vinfo.checkValidValue(cinfo.value, cinfo.dispValue) === false) {
				ret = false;
				vinfo.setErrorInfo(cinfo, validError);
			}
		});
		return ret;
	}

	/**
	 * 入力チェック処理
	 * @param value 入力値
	 * @return falseの場合、入力無効
	 */
	public checkInput(value: any): boolean {
		let errorList: WprInvalidInfo[] = null;
		for (const vinfo of this.m_ValidatorMap.values()) {
			if (vinfo.checkInput(value) === false)
				return false;
		}
		return true;
	}

	/**
	 * 入力チェック処理
	 * @returns falseの場合、入力チェックは行なわない
	 */
	public useInputCheck(): boolean {
		for (const vinfo of this.m_ValidatorMap.values()) {
			if (vinfo.useInputCheck() === true)
				return true;
		}
		return false;
	}
	
	/**
	 * バリデータ追加処理
	 * @param name バリデータ名
	 */
	public addValidator(name: string, option?: any): void {
		if (this.m_ValidatorMap.has(name) === false) {
			const validator = this.FW.view.getValidator(name);
			if (validator != null) {
				this.m_ValidatorMap.set(name, new WprValidatorInfo(this.m_Description, validator, option));
			}
			else {
				//
			}
		}
	}

	/**
	 * バリデータ削除処理
	 * @param name バリデータ名
	 */
	public deleteValidator(name: string): void {
		if (this.m_ValidatorMap.has(name) === true)
			this.m_ValidatorMap.delete(name);
	}

	/**
	 * 最小値設定
	 * @param min 最小値
	 */
	public setMin(min: any) {
		this.m_ValidatorMap.forEach(vinfo => {
			vinfo.setMin(min);
		});
	}

	/**
	 * 最大値設定
	 * @param max 最大値
	 */
	public setMax(max: any) {
		this.m_ValidatorMap.forEach(vinfo => {
			vinfo.setMax(max);
		});
	}
	// --------------------------------------------------------------------------

	// private メソッド  ---------------------------------------------------------
	/**
	 * 初期化処理
	 * @param config コントロール定義
	 */
	private initialize(config: IWprConfigOption): void {
		if (config.validation) {
			config.validation.split(',').forEach(valid => {
				const vstr = valid.trim();
				if (vstr.endsWith(')') === true && vstr.includes('(') === true) {
					const vname = vstr.substring(0, vstr.indexOf('(')).trim();
					const option = vstr.substring(vstr.indexOf('(')+1, vstr.length-1).trim();
					this.addValidator(vname, option);
				}
				else {
					this.addValidator(vstr);
				}
			});
		}
		if (config.hissu)
			this.addValidator('必須');
		if (config.min)
			this.addValidator('最小値', config.min);
		if (config.max)
			this.addValidator('最大値', config.max);
		if (config.minLength)
			this.addValidator('最小文字数', config.minLength);
		if (config.maxLength)
			this.addValidator('最大文字数', config.maxLength);
	}
	// --------------------------------------------------------------------------
}
