import { WprBaseViewMultiModeInfo } from '../../../../../../wpr-framework/mode/WprBaseViewMultiMode';
import { WprMultiMode } from '../../../../../../wpr-framework/mode/WprMultiMode';
import { WprControlInfo } from '../../../../../../wpr-framework/view/control/WprControlInfo';
import { WprDI_Control } from '../../../../../../wpr-framework/view/di/WprDI_Control';
import { ModNetSettingView } from '../ModNetSettingView';
import { WprDI_Object } from '../../../../../../wpr-framework/view/di/WprDI_Object';
import { CourseListModel } from '../../../../../models/mypage/net/CourseListModel';

/**
 * ケーブルモード
 */
export class CableMode extends WprBaseViewMultiModeInfo<ModNetSettingView> {
	// DI情報  ------------------------------------------------------------------
	@WprDI_Control
	private m_cable: WprControlInfo	= null;	// お得パック希望する
	@WprDI_Control
	private m_cableWifi: WprControlInfo	= null;	// お得パック希望しない
	@WprDI_Control
	private m_noChangeCable: WprControlInfo	= null;	// お得パック希望する
	@WprDI_Control
	private m_equipmentProvidingCheck: WprControlInfo	= null;	//
	@WprDI_Control
	private m_buyCheck: WprControlInfo	= null;	// 
	@WprDI_Control
	private m_noChange: WprControlInfo	= null;	// 
	@WprDI_Object
	private m_NetInfo: CourseListModel	= null;	// 画面遷移用
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	public constructor() {
		super('CableMode');
	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * モードリスト取得（この画面モードで使用する全てのモードを定義する）
	 * @param list モードリスト
	 */
	public useModeList(list: string[]): void {
		list.push('切替可(cable)');
		list.push('切替不可(cable)');
		list.push('切替可(cableWifi)');
		list.push('切替不可(cableWifi)');
		list.push('切替可(noChangeCable)');
		list.push('切替不可(noChangeCable)');
		list.push('切替可(equipmentProvidingCheck)');
		list.push('切替不可(equipmentProvidingCheck)');
		list.push('切替可(buyCheck)');
		list.push('切替不可(buyCheck)');
		list.push('切替可(noChange)');
		list.push('切替不可(noChange)');
	}

	/**
	 * フィールド定義を登録処理
	 */
	public onRegisterControl(): void {
		this.addControl('cable', { check: true, readOnly: [ { mode: '切替可(cable)', value: false }, { mode: '切替不可(cable)', value: true } ] });
		this.addControl('cableWifi', { check: true, readOnly: [ { mode: '切替可(cableWifi)', value: false }, { mode: '切替不可(cableWifi)', value: true } ] });
		this.addControl('noChangeCable', { check: true, readOnly: [ { mode: '切替可(noChangeCable)', value: false }, { mode: '切替不可(noChangeCable)', value: true } ] });
		this.addControl('equipmentProvidingCheck', { check: true, readOnly: [ { mode: '切替可(equipmentProvidingCheck)', value: false }, { mode: '切替不可(equipmentProvidingCheck)', value: true } ] });
		this.addControl('buyCheck', { check: true, readOnly: [ { mode: '切替可(buyCheck)', value: false }, { mode: '切替不可(buyCheck)', value: true } ] });
		this.addControl('noChange', { check: true, readOnly: [ { mode: '切替可(noChange)', value: false }, { mode: '切替不可(noChange)', value: true } ] });
	}

	/**
	 * 画面の状態をチェックしてモードを変更する
	 * @param curMode 現在のモード
	 * @param newMode 新しいモード
	 */
	public onCheckMultiMode(curMode: WprMultiMode, newMode: WprMultiMode): void {
		if (this.m_cable.value && !this.m_cableWifi.value && !this.m_noChangeCable.value) {
			newMode.addMode('切替不可(cable)');
			newMode.addMode('切替可(cableWifi)');
			newMode.addMode('切替可(noChangeCable)');
			if (this.m_equipmentProvidingCheck.value && !this.m_noChange.value) {
				newMode.addMode('切替不可(equipmentProvidingCheck)');
				newMode.addMode('切替可(noChange)');
			}
			else if (!this.m_equipmentProvidingCheck.value && this.m_noChange.value) {
				newMode.addMode('切替可(equipmentProvidingCheck)');
				newMode.addMode('切替不可(noChange)');
			}
		}
		else if (!this.m_cable.value && !this.m_cableWifi.value && this.m_noChangeCable.value) {
			newMode.addMode('切替可(cable)');
			newMode.addMode('切替可(cableWifi)');
			newMode.addMode('切替不可(noChangeCable)');
			if (this.m_equipmentProvidingCheck.value && !this.m_noChange.value) {
				newMode.addMode('切替不可(equipmentProvidingCheck)');
				newMode.addMode('切替可(noChange)');
			}
			else if (!this.m_equipmentProvidingCheck.value && this.m_noChange.value) {
				newMode.addMode('切替可(equipmentProvidingCheck)');
				newMode.addMode('切替不可(noChange)');
			}
		}
		else if (!this.m_cable.value && this.m_cableWifi.value && !this.m_noChangeCable.value) {
			newMode.addMode('切替可(cable)');
			newMode.addMode('切替不可(cableWifi)');
			newMode.addMode('切替可(noChangeCable)');
			// 変更前が[レンタル]以外の場合
			if (this.m_NetInfo.deviceForm !== 'レンタル') {
				newMode.addMode('切替不可(equipmentProvidingCheck)');
				newMode.addMode('切替不可(noChange)');
			}
			else {
				if (this.m_equipmentProvidingCheck.value && !this.m_noChange.value) {
					newMode.addMode('切替不可(equipmentProvidingCheck)');
					newMode.addMode('切替可(noChange)');
				}
				else if (!this.m_equipmentProvidingCheck.value && this.m_noChange.value) {
					newMode.addMode('切替可(equipmentProvidingCheck)');
					newMode.addMode('切替不可(noChange)');
				}
			}
		}
		else if (!this.m_cable.value && !this.m_cableWifi.value && !this.m_noChangeCable.value) {
			if (!this.m_equipmentProvidingCheck.value && this.m_noChange.value) {
				newMode.addMode('切替可(equipmentProvidingCheck)');
				newMode.addMode('切替不可(noChange)');
			}
			else if (this.m_equipmentProvidingCheck.value && !this.m_noChange.value) {
				newMode.addMode('切替不可(equipmentProvidingCheck)');
				newMode.addMode('切替可(noChange)');
			}
		}
	}
	// --------------------------------------------------------------------------
}
