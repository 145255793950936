import { Divider, Grid, Typography } from '@material-ui/core';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { ReactNode } from 'react';
import { MatButton } from '../../../../wpr-framework/component/controls/material/MatButton';
import { MatTypography } from '../../../../wpr-framework/component/controls/material/MatTypography';
import { WprBaseViewComponent } from '../../../../wpr-framework/view/component/WprBaseViewComponent';
import { ProgressBar } from '../../common/progress_bar/ProgressBar';
import { ReiAccesspassConfirmView } from './ReiAccesspassConfirmView';
import netIcon from '/shared/image/common/logo_iTSCOMNET.png';
import styles from './ReiAccesspassConfirm.scss';


/**
 * アカウントパスワード発行確認用コンポーネント
 */
export class ReiAccesspassConfirm extends WprBaseViewComponent {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: any) { super(new ReiAccesspassConfirmView(), props); }
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** ITSCOM NET_追加申込み(申し込み内容の確認)ビュー情報 */
	public get viewInfo(): ReiAccesspassConfirmView { return this.view as ReiAccesspassConfirmView; }
	// --------------------------------------------------------------------------		
	/**
	 * スタイルオブジェクト取得
	*/
	public getStylesObject(): any { return styles; }

	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		return (
			<>
				<div className='pageCard'>
					<div className='pageHeader stepFlow'>
						<ProgressBar name='progressBar' view={this.view} />
					</div>
					<div className='sub-logo'>
						<img src={netIcon}></img>
					</div>
					<div className='main'>
						<div className='pageTitle'>
							<div className='title_left'>
								<MatTypography name='title' className='title_text' view={this.view}/>
								<Typography className='title_comment' style={{ lineHeight: '140%',fontWeight: '500' }}>以下のアクセスアカウントパスワードを発行します。<br />よろしければ、「発行」ボタンをクリックしてください。</Typography>
							</div>
						</div>
						<div className='feeTable'>
							<Grid container className='feeRow'>
								<Grid item xs={12} md={4} className='feeTitle'>
									<div>コース</div>
								</Grid>
								<Grid item xs={12} md={8} className='feeVal courseValue' style={{ color: 'black' }}>
									<MatTypography name={'course'} className='itemUnit' view={this.view} />
								</Grid>
							</Grid>
							<Grid container className='feeRow'>
								<Grid item xs={12} md={4} className='feeTitle'>
									<div>アクセスアカウント</div>
								</Grid>
								<Grid item xs={12} md={8} className='feeVal courseValue' style={{ color: 'black' }}>
									<MatTypography name={'accessAccount'} className='itemUnit' view={this.view} />
								</Grid>
							</Grid>
						</div>
					</div>
					<Divider />
					<div className='pageFooter footer-space'>
						<div>
							<MatButton name='next' view={this.view} className='orangeButton orangeButton_parts' >
								発行
								<NavigateNextIcon />
							</MatButton>
						</div>
						<div>
							<MatButton name='back' view={this.view} className='backButton' >
								<NavigateBeforeIcon />
								戻る
							</MatButton>
						</div>
					</div>
				</div>
			</>
		);
	}
	// --------------------------------------------------------------------------
}
