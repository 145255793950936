import React, { RefObject } from 'react';
import { WprControlInfo } from '../../view/control/WprControlInfo';
import { WprBaseConverter } from '../../view/convert/WprBaseConverter';
import { WprFramework } from '../../WprFramework';
import { IWprControlEvents } from '../props/IWprControlEvents';
import { IWprControlProps } from '../props/IWprControlProps';
import { IWprControlState, WprControlVisibility } from '../props/IWprControlState';
import { WprRowInfo } from '../props/WprRowInfo';
import { WprBaseComponent } from './WprBaseComponent';

/**
 * コントロール基本コンポーネント
 */
export abstract class WprBaseControlComponent<T extends IWprControlProps, U> extends WprBaseComponent<T, IWprControlState> {
	// private 変数  ------------------------------------------------------------
	private m_ControlInfo: WprControlInfo 	= null;		// コントロール情報
	private m_Events: IWprControlEvents<U>	= {};		// イベント情報
	private m_Ref: RefObject<any>			= null;		// DOM参照
	private m_ClassName: any				= '';		// クラス名
	private m_Invalid: boolean				= false;	// エラーフラグ
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** コントロール情報 */
	public get controlInfo(): WprControlInfo 	{ return this.m_ControlInfo; 	}
	/** 編集フラグ */
	public get isEdit(): boolean			 	{ return true; 					}
	/** ValueMap保持フラグ */
	public get hasValueMap(): boolean			{ return false; 				}
	/** DOM参照 */
	public get ref(): RefObject<any> 			{ return this.m_Ref;			}
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	constructor(props: T) {
		super(props);
		if (this.props.row)
			this.m_ControlInfo = this.props.view.getControlInfo(this.props.name, this.props.row.listName, this.props.row.index);
		else
			this.m_ControlInfo = this.props.view.getControlInfo(this.props.name);
		if (this.m_ControlInfo) {
			this.state = this.m_ControlInfo.getComponentState(props.row, this.getOption(), this.getStartFocus());
		}
		else {
			this.props.view.addErrorLog(`コントロール定義が登録されていません。[${this.props.name}]`);
			this.state = {
				value: '',
				disabled: false,
				style: null,
				dirty: false,
				invalid: false,
				visibility: WprControlVisibility.VISIBLE,
				loadWait: false,
				refresh: 0,
				numOption1: 0,
				numOption2: 0,
				boolOption1: false,
				boolOption2: false
			};
		}
		this.m_Ref = React.createRef();
	}
	// --------------------------------------------------------------------------

	// override メソッド  -------------------------------------------------------
	/**
	 * マウント通知
	 */
	public onDidMount() {
		if (this.m_ControlInfo != null) {
			this.m_ControlInfo.setComponent(this);
			if (this.props.row)
				this.setState(this.m_ControlInfo.getComponentState(this.props.row, this.getOption(), this.getStartFocus()));
		}
	}

	/**
	 * アンマウント通知
	 */
	 public onWillUnmount() {
		if (this.m_ControlInfo != null)
			this.m_ControlInfo.deleteComponent(this);
		this.terminate();
	}
	// --------------------------------------------------------------------------

	// イベント  -----------------------------------------------------------------
	/**
	 * フォーカスOUT処理
	 * @param event フォーカスOUTイベント
	 */
	public onFocus(event: React.ChangeEvent<U>): void {
		if (this.m_ControlInfo != null)
			this.m_ControlInfo.onFocus();
	}
	// --------------------------------------------------------------------------

	// virtual メソッド  --------------------------------------------------------
	/**
	 * コントロール名称取得
	 * @returns コントロール名称
	 */
	public getName(): string {
		return this.props.name;
	}

	/**
	 * オプション値取得
	 */
	public getOption(): any {
		return null;
	}

	/**
	 * フォーカス設定処理
	 */
	public setFocus(): void {
		try {
			if (this.m_Ref && this.m_Ref.current)
				this.m_Ref.current.focus();
		}
		catch(e) {
			// 無視
		}
	}
	
	/**
	 * 開始フォーカス状態取得
	 * @returns フォーカス
	 */
	 protected getStartFocus(): boolean {
		return null;
	}
	// --------------------------------------------------------------------------

	// protected メソッド  -------------------------------------------------------
	/**
	 * クラス取得
	 * @param cls 固定クラス 
	 * @returns クラス
	 */
	protected className(cls?: string): string {
		if (!this.props.className && !this.state.className && !cls && !this.state.modeClassName && !this.state.authClassName)
			return cls;
		let className = '';
		if (this.props.className)
			className += this.props.className;
		if (this.state.className) {
			if (className.length > 0)
				className += ' ';
			className += this.state.className;
		}
		if (this.state.modeClassName) {
			if (className.length > 0)
				className += ' ';
			className += this.state.modeClassName;
		}
		if (this.state.authClassName) {
			if (className.length > 0)
				className += ' ';
			className += this.state.authClassName;
		}
		if (cls) {
			if (className.length > 0)
				className += ' ';
			className += cls;
		}
		const local = this.props.view.getLocalClassName(className);
		if (local != null)
			return local;
		return className;
	}

	/**
	 * 値取得
	 * @returns 値
	 */
	protected getValue(): any {
		return this.state.value;
	}

	/**
	 * ステータス情報抽出
	 * @param list 抽出リスト
	 * @returns ステータス情報
	 */
	protected getState(list: string[]): any {
		const rtn: any = {};
		const state: any = this.state;
		list.forEach(name => {
			rtn[name] = state[name];
		});
		return rtn;
	}

	/**
	 * プロパティ情報取得
	 * @param cls オリジナルクラス
	 * @returns プロパティ情報
	 */
	protected getProps(cls?: string, setClassName: boolean = true): any {
		const rtn: any = {};
		rtn['name'] = this.getName();
		if (setClassName === true)
			rtn['className'] = this.className(cls);
		const value = this.getValue();
		if (value !== undefined)
			rtn['value'] = value;
		rtn['aria-label'] = this.getName();
		return rtn;
	}

	/**
	 * イベント情報取得
	 * @returns イベント情報
	 */
	protected getEvents(): any {
		let event = this.m_Events;
		if (this.m_ControlInfo != null)
			return this.m_ControlInfo.viewCore.getEventsInfo(this.m_ControlInfo.name, this.props.row, event);
		return event;
	}

	/**
	 * 入力プロパティ取得
	 * @param list 抽出リスト
	 */
	protected getInputProps(list: string[]): any {
		const rtn: any = {};
		const state = this.getState(list);
		list.forEach(name => {
			rtn['inputProps'] = state;
		});
		return rtn;
	}
	
	/**
	 * クリックイベントハンドラ登録
	 * @param click クリックイベントハンドラ
	 */
	protected setClickEvent(click: (event: React.MouseEvent<U>) => void) {
		this.m_Events.onClick = click;
	}

	/**
	 * 値変更通知イベントハンドラ登録
	 * @param changeValue 値変更通知イベントハンドラ
	 */
	protected setChangeValueEvent(changeValue: (event: React.ChangeEvent<U>) => void) {
		this.m_Events.onChange = changeValue;
	}

	/**
	 * フォーカスINイベントハンドラ登録
	 * @param blur フォーカスINイベントハンドラ
	 */
	 protected setFocusEvent(focus: (event: React.ChangeEvent<U>) => void) {
		this.m_Events.onFocus = focus;
	}

	/**
	 * フォーカスOUTイベントハンドラ登録
	 * @param blur フォーカスOUTイベントハンドラ
	 */
	protected setBlureEvent(blur: (event: React.ChangeEvent<U>) => void) {
		this.m_Events.onBlur = blur;
	}

	/**
	 * 選択変更イベントハンドラ登録
	 * @param select 選択変更イベントハンドラ
	 */
	protected setSelectionChangeEvent(select: (event: React.ChangeEvent<U>) => void) {
		this.m_Events.onSelect = select;
	}

	/**
	 * キー押下イベントハンドラ登録
	 * @param select 選択変更イベントハンドラ
	 */
	protected setKeyDownEvent(keyDown: (event: React.KeyboardEvent<U>) => void) {
		this.m_Events.onKeyDown = keyDown;
	}

	/**
	 * 日本語変換モードベントハンドラ登録
	 * @param start 日本語入力開始イベントハンドラ
	 * @param end 日本語入力終了イベントハンドラ
	 */
	protected setComposition(start: (event: React.CompositionEvent<U>) => void, end: (event: React.CompositionEvent<U>) => void) {
		this.m_Events.onCompositionStart = start;
		this.m_Events.onCompositionEnd = end;
	}

	/**
	 * 表示値を取得する
	 * @param converter コンバータ名
	 * @param row 行情報
	 * @returns 表示値
	 */
	protected getViewValue(converter: string, row: WprRowInfo): string {
		let val = null;
		if (converter)
			val = this.convertView(converter, this.state.value, row);
		else if (this.state.viewValue)
			val = this.state.viewValue;
		else if (this.state.viewValue === 0)
			return '0';
		if (val)
			return val;
		return '';
	}

	/**
	 * 表示値を取得する
	 * @param converter コンバータ名
	 * @returns 表示値
	 */
	 protected getEditValue(converter: string): string {
		let val = null;
		if (converter)
			val = this.convertEdit(converter, this.state.value);
		else if (this.state.editValue)
			val = this.state.editValue;
		if (val)
			return val;
		return '';
	}
	// --------------------------------------------------------------------------

	// public メソッド  ----------------------------------------------------------
	/**
	 * 値設定処理
	 * @param value 値
	 * @param editValue 変種値
	 * @param viewValue 表示値
	 */
	public setValue(value: any, editValue: any, viewValue: any): void {
		if (this.isMount === false)
			return;
		if (this.state.value === value && this.state.editValue === editValue && this.state.viewValue === viewValue)
			return;
		this.setState({
			value : value,
			editValue : editValue,
			viewValue : viewValue
		});
//		this.setTooltipOpen(value);
	}

	/**
	 * 値更新処理
	 * @param value 値
	 * @param editValue 変種値
	 * @param viewValue 表示値
	 * @param converter コンバータ
	 */
	public updateValue(value: any, editValue: any, viewValue: any, converter: WprBaseConverter): void {
		if (this.isMount === false)
			return;
		if (converter == null) {
			if (this.state.value === value && this.state.editValue === editValue && this.state.viewValue === viewValue)
				return;
			if (this.state.value === value)
				return;
		}
		this.setState({
			value : value,
			editValue : editValue,
			viewValue : viewValue
		});
//		this.setTooltipOpen(value);
	}

	/**
	 * 変更フラグ設定処理
	 * @param dirty 変更フラグ
	 */
	public setDirty(dirty: boolean): void {
		if (this.isMount === false)
			return;
		if (this.state.dirty === dirty)
			return;
		this.setState({
			dirty : dirty
		});
	}

	/**
	 * 非活性状態設定処理
	 * @param disabled 非活性状態
	 */
	public setDisabled(disabled: boolean): void {
		if (this.isMount === false)
			return;
		this.setState({
			disabled : disabled
		});
	}

	/**
	 * 読取り専用設定処理
	 * @param readOnly 読取り専用フラグ
	 */
	public setReadOnly(readOnly: boolean): void {
		if (this.isMount === false)
			return;
		this.setState({
			readOnly : readOnly
		});
	}

	/**
	 * 表示状態設定処理
	 * @param visibility 表示状態
	 * @param display 表示状態
	 */
	public setVisibility(visibility: boolean, display: boolean): void {
		if (this.isMount === false)
			return;
		let visible = WprControlVisibility.VISIBLE;
		if (display === false)
			visible = WprControlVisibility.COLLAPSED;
		else if (visibility === false)
			visible = WprControlVisibility.HIDDEN;
		this.setState({
			visibility : visible
		});
	}

	/**
	 * 最小値設定処理
	 * @param min 最小値
	 */
	public setMin(min: number | string) {
		if (this.isMount === false)
			return;
		if (this.state.min === min)
			return;
		this.setState({
			min: min
		});
	}

	/**
	 * 最大値設定処理
	 * @param max 最大値
	 */
	public setMax(max: number | string) {
		if (this.isMount === false)
			return;
		if (this.state.max === max)
			return;
		this.setState({
			max: max
		});
	}

	/**
	 * スタイル設定処理
	 * @param style スタイル
	 */
	public setStyle(style: any) {
		if (this.isMount === false)
			return;
		if (this.state.style === style)
			return;
		this.setState({
			style: style
		});
	}

	/**
	 * クラス設定処理
	 * @param cls クラス
	 */
	public setClass(cls: any) {
		if (this.isMount === false)
			return;
		if (this.m_ClassName === cls)
			return;
		this.m_ClassName = cls;
		this.setState({
			className: cls
		});
	}

	/**
	 * モードクラス設定処理
	 * @param cls クラス
	 */
	 public setModeClass(cls: any) {
		if (this.isMount === false)
			return;
		if (this.state.modeClassName === cls)
			return;
		this.setState({
			modeClassName: cls
		});
	}

	/**
	 * 権限クラス設定処理
	 * @param cls クラス
	 */
	 public setAuthClass(cls: any) {
		if (this.isMount === false)
			return;
		if (this.state.authClassName === cls)
			return;
		this.setState({
			authClassName: cls
		});
	}

	/**
	 * placeholder設定処理
	 * @param placeholder placeholder値
	 */
	public setPlaceholder(placeholder: string) {
		if (this.isMount === false)
			return;
		if (this.state.placeholder === placeholder)
			return;
		this.setState({
			placeholder: placeholder
		});
	}

	/**
	 * 入力エラー設定処理
	 * @param invalid 入力エラー
	 */
	public setInvalid(invalid: boolean): void {
		if (this.isMount === false)
			return;
		if (this.m_Invalid === invalid)
			return;
		this.m_Invalid = invalid;
		this.setState({
			invalid : invalid
		});
	}

	/**
	 * エラーメッセージ設定処理
	 * @param errMessage エラーメッセージリスト 
	 */
	public setErrorMessage(errMessage: string[]): void {
		if (this.isMount === false)
			return;
		if (this.state.errMessage === errMessage)
			return;
		/*
		if (this.state.errMessage != null) {
			if (this.state.errMessage.length === errMessage.length) {
				let same = true;
				for (let i=0; i<errMessage.length; i++) {
					if (this.state.errMessage[i] !== errMessage[i]) {
						same = false;
						break;
					}
				}
				if (same == true)
					return;
			}
		}
		*/
		this.setState({
			errMessage : errMessage.concat()
		});
	}

	/**
	 * 表示状態取得
	 * @returns trueの場合表示
	 */
	public isDisplay(): boolean {
		if (this.m_Ref.current != null)
			return this.checkDisplay(this.m_Ref.current);
		return true;
	}

	/**
	 * 再表示カウンタ設定
	 * @param refresh 再表示カウンタ
	 */
	public setRefresh(refresh: number): void {
		if (this.isMount === false)
			return;
		this.setState({
			refresh : refresh
		});
	} 
	// --------------------------------------------------------------------------

	// private メソッド  ---------------------------------------------------------
	/**
	 * 表示チェック処理
	 * @param node チェック要素
	 * @returns trueの場合、表示状態
	 */
	private checkDisplay(node: HTMLElement): boolean {
		if (document.defaultView.getComputedStyle(node, null).display === 'none')
			return false;
		if (!node.parentElement)
			return true;
		return this.checkDisplay(node.parentElement);
	}

	/**
	 * 表示値に変換する
	 * @param converterName 変換名
	 * @param value 値
	 * @returns 変換後の値
	 */
	protected convertView(converterName: string, value: string, row: WprRowInfo): string {
		const converter = WprFramework.getInstance().view.getConverter(converterName);
		if (converter != null ) {
			if (row)
				converter.rowData = row.rowData;
			return converter.convertView(value);
		}
		return value;
	}

	/**
	 * 編集値に変換する
	 * @param converterName 変換名
	 * @param value 値
	 * @returns 変換後の値
	 */
	protected convertEdit(converterName: string, value: string): string {
		const converter = WprFramework.getInstance().view.getConverter(converterName);
		if (converter != null )
			return converter.convertEdit(value);
		return value;
	}

	/**
	 * ツールチップオープン状態設定
	 * @param value 入力値
	 */
	/*
	private setTooltipOpen(value: any): void {
		let isOpen = false;
		if (value) {
			if (typeof(value) === 'string' || value instanceof String) {
				const str = value as string;
				if (str.length > 0)
					isOpen = true;
			}
		}
		this.setState({
			tooltipOpen : isOpen
		});
	}
	*/

	/**
	 * 終了処理
	 */
	private terminate(): void {
		this.m_ControlInfo = null;
	}
	// --------------------------------------------------------------------------
}