import { BaseService } from './base/BaseService';
import { WprBaseViewCore } from '../../wpr-framework/view/WprBaseViewCore';
import { GetIsContractRadiusAccountPasswordRequest } from './models/dto/mypagerenewal/GetIsContractRadiusAccountPasswordRequest';
import { GetIsContractRadiusAccountPasswordResponse } from './models/dto/mypagerenewal/GetIsContractRadiusAccountPasswordResponse';
import { GetSetContractRadiusAccountRequest } from './models/dto/mypagerenewal/GetSetContractRadiusAccountRequest';
import { GetSetContractRadiusAccountResponse } from './models/dto/mypagerenewal/GetSetContractRadiusAccountResponse';
import { ResetContractRadiusAccountRequest } from './models/dto/mypagerenewal/ResetContractRadiusAccountRequest';
import { ResetContractRadiusAccountResponse } from './models/dto/mypagerenewal/ResetContractRadiusAccountResponse';

/**
 * アクセスアカウントサービス
 */
export class AccessAccountService extends BaseService {
	// サービス メソッド  --------------------------------------------------------
	/**
	 * パスワードチェック
	 * @param viewCore ビュー情報
	 * @param request リクエスト情報
	 * @param resMethod 結果処理メソッド
	 */
	public isContractRadiusAccountPassword(viewCore: WprBaseViewCore, request: GetIsContractRadiusAccountPasswordRequest, resMethod: (result: GetIsContractRadiusAccountPasswordResponse) => void): void {
		return this.doPost(viewCore, '/AccessAccount/isContractRadiusAccountPassword', request, resMethod);
	}
	
	/**
	 * アクセスアカウントパスワード発行
	 * @param viewCore ビュー情報
	 * @param request リクエスト情報
	 * @param resMethod 結果処理メソッド
	 */
	public setContractRadiusAccount(viewCore: WprBaseViewCore, request: GetSetContractRadiusAccountRequest, resMethod: (result: GetSetContractRadiusAccountResponse) => void): void {
		return this.doPost(viewCore, '/AccessAccount/setContractRadiusAccount', request, resMethod);
	}
	
	/**
	 * アクセスアカウントパスワード再発行
	 * @param viewCore ビュー情報
	 * @param request リクエスト情報
	 * @param resMethod 結果処理メソッド
	 */
	public resetContractRadiusAccount(viewCore: WprBaseViewCore, request: ResetContractRadiusAccountRequest, resMethod: (result: ResetContractRadiusAccountResponse) => void): void {
		return this.doPost(viewCore, '/AccessAccount/resetContractRadiusAccount', request, resMethod);
	}
	// --------------------------------------------------------------------------
}
