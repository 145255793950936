import React, { ReactNode } from 'react';
import { WprActionInfo } from '../../action/WprActionInfo';
import { WprControlVisibility } from '../props/IWprControlState';
import { IWprLayoutErrorProps } from '../props/IWprLayoutProps';
import { WprBaseErrorLayoutComponent } from './WprBaseErrorLayoutComponent';

/**
 * DIVプロパティ情報
 */
interface IWprDivProps extends IWprLayoutErrorProps {
}

/**
 * DIVコントロール
 */
export class WprDiv extends WprBaseErrorLayoutComponent<IWprDivProps, HTMLDivElement> {
	// private 変数  ------------------------------------------------------------
	private m_ActionInfo: WprActionInfo	= null;		// アクション情報
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	constructor(props: IWprDivProps){
		super(props);
		if (this.props.row)
			this.m_ActionInfo = this.props.view.getActionInfo(this.props.name, this.props.row.listName);
		else
			this.m_ActionInfo = this.props.view.getActionInfo(this.props.name);
		if (this.m_ActionInfo != null)
			this.setClickEvent(this.onClick.bind(this));
	}
	// --------------------------------------------------------------------------

	// イベント  ----------------------------------------------------------------
	/**
	 * クリック処理
	 * @param event 変更イベント 
	 */
	 public onClick(event: React.MouseEvent<HTMLDivElement>): void {
		if (this.m_ActionInfo != null) {
			this.addClickEventLog(event.target as HTMLDivElement);
			if (this.props.row)
				this.m_ActionInfo.execute(false, event, this.props.row);
			else
				this.m_ActionInfo.execute(false, event);
		}
		else {
			this.props.view.addErrorLog(`アクションが登録されていません。[${this.props.name}]`);
		}
	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		if (this.state.visibility === WprControlVisibility.COLLAPSED)
			return <></>;
		const props = this.getProps();
		const events = this.getEvents();
		const {name, view, className, children, errorClass, normalClass, validControl, ...other} = this.props;
		const state = this.getState(['style', 'disabled']);
		let div = null;
		if (this.state.visibility === WprControlVisibility.VISIBLE) {
			div = <div {...props} {...state} {...other} {...events} ref={this.ref}>{this.props.children}</div>;
		}
		else {
			div = <div hidden {...props} {...state} {...other} ref={this.ref}>{this.props.children}</div>;
		}
		return (
			<>
				{div}
			</>
		);
	}
	// --------------------------------------------------------------------------
}