import React, { ReactNode } from 'react';
import { WprBaseViewComponent } from '../../../../../wpr-framework/view/component/WprBaseViewComponent';
import { ModNetSettingView } from './ModNetSettingView';
import { Typography, Grid, Divider, TableCell, Table, TableBody } from '@material-ui/core';
import { WprContainer } from '../../../../../wpr-framework/component/controls/WprContainer';
import { WprSpan } from '../../../../../wpr-framework/component/controls/WprSpan';
import { MatButton } from '../../../../../wpr-framework/component/controls/material/MatButton';
import { MatCheckBox } from '../../../../../wpr-framework/component/controls/material/MatCheckBox';
import { MatTypography } from '../../../../../wpr-framework/component/controls/material/MatTypography';
import { ProgressBar } from '../../../common/progress_bar/ProgressBar';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import netIcon from '/shared/image/common/logo_iTSCOMNET.png';
import importantIcon from '/shared/image/common/important_gray.png';
import nextIcon from '/shared/image/common/next_icon_orange.png';
import orangeIcon from '/shared/image/common/icon_Help_bgLigntOrange.png';
import errorIcon from '/shared/image/common/icon_error.png';
import { WprRowInfo } from '../../../../../wpr-framework/component/props/WprRowInfo';
import { MatTableRow } from '../../../../../wpr-framework/component/controls/material/MatTableRow';
import { MatTextField } from '../../../../../wpr-framework/component/controls/material/MatTextField';
import { MatTableContainer } from '../../../../../wpr-framework/component/controls/material/MatTableContainer';
import { MatDatePicker } from '../../../../../wpr-framework/component/controls/material/MatDatePicker';
import { MatSelect } from '../../../../../wpr-framework/component/controls/material/MatSelect';
import styles from './ModNetSetting.scss';
import { WprDiv } from '../../../../../wpr-framework/component/controls/WprDiv';
import { WprErrorContainer } from '../../../../../wpr-framework/component/controls/WprErrorContainer';
import { WprViewError } from '../../../../../wpr-framework/component/controls/WprViewError';

/**
 * サービス変更申込み用コンポーネント
 */
export class ModNetSetting extends WprBaseViewComponent {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: any) { super(new ModNetSettingView(), props); }
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** サービス変更申込みビュー情報 */
	public get viewInfo(): ModNetSettingView	{ return this.view as ModNetSettingView;	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * スタイルオブジェクト取得
	*/
	public getStylesObject(): any { return styles; }

	/**
	 * リストコントロール定義登録
	*/
	protected onRegisterListRender(): void {
		this.addListRender('BeforeContractList', null, this.onBeforeContractListRender);
		this.addListRender('ErrorListModel', null, this.onErrorListModelRender);
	}

	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		return (
			<>
			<div className='pageCard'>
				<div className='pageHeader stepFlow'>
					<ProgressBar name='progressBar' view={this.view} />
				</div>
				<div>
					<div className='sub-logo2'>
						<img src={netIcon} />
					</div>
					<div className='main net'>
						<div className='pageTitle'>
							<div className='title_left'>
								<MatTypography name='title' className='title_text' view={this.view}/>
								<Typography className='title_sentence'>ご希望のコースを選択してください。</Typography>
							</div>
							<WprContainer mode='エラー.表示' view={this.view}>
								<div className='errorArea'>
									<span className='parallel'>
										<img src={errorIcon} alt='' className='x_img' />
										<div className='errorList' >
											{this.renderList('ErrorListModel')}
										</div>
									</span>
								</div>
							</WprContainer>
							<div className='itscomNet_img whitespace'>
								{/* FTTHの場合 */}
								<WprContainer mode='インターネットのコース変更.FTTH' view={this.view}>
									<a href='https://www.itscom.co.jp/support/contract_change/change_course/internet.html' target='_blank' className='courseUrl'>
										<b>インターネットのコース変更について　</b>
										<img src={nextIcon} className='orange_circleArrow'/>
									</a>
								</WprContainer>
								{/* FTTH以外の場合 */}
								<WprContainer mode='インターネットのコース変更.FTTH以外' view={this.view}>
									<a href='https://www.itscom.co.jp/support/contract_change/change_course/internet_cable.html' target='_blank' className='courseUrl'>
										<b>インターネットのコース変更について　</b>
										<img src={nextIcon} className='orange_circleArrow'/>
									</a>
								</WprContainer>
							</div>
							<div className='itscomNet_img'>
								{/* FTTHの場合 */}
								<WprContainer mode='コース一覧.FTTH' view={this.view}>
									<a href='http://www.itscom.net/hikari/service/net/' target='_blank' className='courseUrl'>
										<b>コース一覧　</b>
										<img src={nextIcon} className='orange_circleArrow'/>
									</a>
								</WprContainer>
								{/* FTTH以外の場合 */}
								<WprContainer mode='コース一覧.FTTH以外' view={this.view}>
									<a href='http://www.itscom.net/service/internet/index.html' target='_blank' className='courseUrl'>
										<b>コース一覧　</b>
										<img src={nextIcon} className='orange_circleArrow'/>
									</a>
								</WprContainer>
							</div>
						</div>
						<div className='title_maru' style={{marginTop:'30px'}}>現在のご契約</div>
						<div className='feeTable'>
							<Grid container>
								<Grid item xs={12}>
									<MatTableContainer listName={'BeforeContractList'} view={this.view}>
										<Table className ='optionTable' style={{marginBottom: '0px'}}>
											<colgroup>
												<col style={{width: '42%'}}/>
												<col/>
											</colgroup>
											<TableBody className='optionBody'  style={{marginBottom: '0px'}}>
												{this.renderList('BeforeContractList')}
											</TableBody>
										</Table>
									</MatTableContainer>
								</Grid>
							</Grid>
						</div>
						<div className='title_maru' style={{marginTop:'30px'}}>変更申込み内容</div>
						<div className='feeTable settingBottom'>
							<Grid container className='feeRow'>
								<Grid item xs={12} md={4} className='feeTitle'>
									<div>申込みコース</div>
								</Grid>
								<Grid item xs={12} md={8} className='feeVal courseValue'>
									<MatTypography name={'cDispnamep'} view={this.view} className='itemUnit'/>
								</Grid>
							</Grid>
							<WprContainer mode='端末の種類.全種' view={this.view}>
								<Grid container className='feeRow'>
									<Grid item xs={12} md={4} className='feeTitle'>
										<div>端末の種類 <img src= {importantIcon} className='impIcon'/><span className='kome'>※1</span></div>
									</Grid>
									<Grid item xs={12} md={8} className='feeVal courseValue checkArea'>
										<MatCheckBox name='cable' color='primary' className='checkbox_size' view={this.view} /><span className='font_black'> ケーブルモデム</span>
										<br/>
										<MatCheckBox name='cableWifi' color='primary' className='checkbox_size' view={this.view} /><span className='font_black'> ケーブルモデム(Wi-Fi)</span>
										<br/>
										<MatCheckBox name='noChangeCable' color='primary' className='checkbox_size' view={this.view} /><span className='font_black'> 変更なし</span>
										<br/>
										<ul className='ul_kome'>
											<li className = 'notes'>ケーブルモデム(Wi-Fi)はレンタル提供のみ</li>
										</ul>
									</Grid>
								</Grid>
							</WprContainer>
							<WprContainer mode='端末の種類.変更なし非表示' view={this.view}>
								<Grid container className='feeRow'>
									<Grid item xs={12} md={4} className='feeTitle'>
										<div>端末の種類 <img src= {importantIcon} className='impIcon'/><span className='kome'>※1</span></div>
									</Grid>
									<Grid item xs={12} md={8} className='feeVal courseValue checkArea'>
										<MatCheckBox name='cable' color='primary' className='checkbox_size' view={this.view} /><span className='font_black'> ケーブルモデム</span>
										<br/>
										<MatCheckBox name='cableWifi' color='primary' className='checkbox_size' view={this.view} /><span className='font_black'> ケーブルモデム(Wi-Fi)</span>
										<br/>
										<ul className='ul_kome'>
											<li className = 'notes'>ケーブルモデム(Wi-Fi)はレンタル提供のみ</li>
										</ul>
									</Grid>
								</Grid>
							</WprContainer>
							<Grid container className='feeRow'>
								<Grid item xs={12} md={4} className='feeTitle'>
									<div>機器のご提供方法 <img src= {importantIcon} className='impIcon'/><span className='kome'>※2</span></div>
								</Grid>
								{/* <Grid item xs={12} md={8} className='feeVal courseValue provCheckArea'> */}
									<WprContainer mode='機器のご提供方法.変更なしのみ' view={this.view}>
										<Grid item xs={12} md={8} className='feeVal courseValue' style={{paddingLeft: '1.1rem'}}>
											<MatCheckBox name='noChange' color='primary' className='checkbox_size' view={this.view}/><span className='font_black'> 変更なし</span>
										</Grid>
									</WprContainer>
									<WprContainer mode='機器のご提供方法.レンタルのみ' view={this.view}>
										<Grid item xs={12} md={8} className='feeVal courseValue' style={{paddingLeft: '1.1rem'}}>
											<MatCheckBox name='equipmentProvidingCheck' color='primary' className='checkbox_size' view={this.view}/><span className='font_black'> レンタル</span>
										</Grid>
									</WprContainer>
									<WprContainer mode='機器のご提供方法.レンタル、変更なし' view={this.view}>
										<Grid item xs={12} md={8} className='feeVal courseValue provCheckArea'>
											<MatCheckBox name='equipmentProvidingCheck' color='primary' className='checkbox_size' view={this.view} /><span className='font_black'> レンタル</span>
											<br/>
											<MatCheckBox name='noChange' color='primary' className='checkbox_size' view={this.view} /><span className='font_black'>  変更なし</span>
										</Grid>
									</WprContainer>
									{/* <WprContainer mode='機器のご提供方法.全種類' view={this.view}>
										<Grid item xs={12} md={8} className='feeVal courseValue provCheckArea'>
											<MatCheckBox name='equipmentProvidingCheck' color='primary' className='checkbox_size' view={this.view} /><span className='font_black'> レンタル</span>
											<br/>
											モデムの購入はできなくなったため、削除
											<MatCheckBox name='buyCheck' color='primary' className='checkbox_size' view={this.view} /><span className='font_black'> 購入</span>
											<br/>
											<MatCheckBox name='noChange' color='primary' className='checkbox_size' view={this.view} /><span className='font_black'> 変更なし</span>
										</Grid>
									</WprContainer> */}
								</Grid>
							{/* </Grid> */}
							<WprContainer mode='お得パック.申込み' view={this.view}>
								<Grid container className='feeRow'>
									<Grid item xs={12} md={4} className='feeTitle'>
										<div>お得パック
											<a href='https://www.itscom.co.jp/service/profit/' target='_blank'><img src={orangeIcon} alt='' style={{marginLeft: '3px'}}/></a>
										</div>
									</Grid>
									<Grid item xs={12} md={8} className='feeVal courseValue provCheckArea'>
										<MatCheckBox name='yesOtoku' color='primary' className='checkbox_size'  view={this.view}/><span className='font_black'> 希望する</span>
										<br/>
										<MatCheckBox name='noOtoku' color='primary' className='checkbox_size' view={this.view}/><span className='font_black'> 希望しない</span>
									</Grid>
								</Grid>
							</WprContainer>
							<Grid container className='feeRow campaignArea'>
								<Grid item xs={12} md={4} className='feeTitle'>
									<div>
										キャンペーンコード
									</div>
								</Grid>
								<Grid item xs={12} md={8} className='feeVal courseValue' style={{padding: '10px'}}>
									<div>
										<MatTextField placeholder='0123456789' name='campaignCode' view={this.view} style={{maxWidth: '200px'}}></MatTextField>
										<Typography style={{fontSize: '10px', color: 'black'}}>※キャンペーンコードをお持ちの場合はご入力ください</Typography>
									</div>
								</Grid>
							</Grid>
						</div>
						<WprContainer mode='補足※1.FTTHマンションコース' view={this.view}> 
							<div className='komeArea'>
								<img src= {importantIcon} className='importantIcon'/><span className='kome'>※1</span>
								<Typography className='comment'>ケーブルモデム(Wi-Fi)の場合は、別途月額264円かかります。</Typography>
							</div>
						</WprContainer>
						<WprContainer mode='補足※1.FTTH以外の場合' view={this.view}> 
							<div className='komeArea'>
								<img src= {importantIcon} className='importantIcon'/><span className='kome'>※1</span>
								<Typography className='comment'>ケーブルモデム(Wi-Fi)の場合は、別途月額264円かかります。<br/>かっとびメガ160、かっとびワイド、イッツコムひかり30メガ以上のコースは無料でご利用いただけます。</Typography>
							</div>
						</WprContainer>	
						<WprContainer mode='補足※2.FTTH以外の場合' view={this.view}>
							<div className='komeArea'>
								<img src= {importantIcon} className='importantIcon'/><span className='kome'>※2</span>
								<Typography className='comment'>端末を購入される場合、お得パック適用対象外となります。</Typography>
							</div>
						</WprContainer>
						<WprContainer mode='希望日時.表示' view={this.view}>
							<div className='title_space'>
								<div className='itemTitle title_maru'>セキュリティ会社加入の確認</div>
								<Typography className='title_comment' style={{marginLeft: '15px'}}>ホームセキュリティにご加入している場合に工事の際にご連絡が必要な場合があります。</Typography>
							</div>
							<div className='title_center'>
								<MatCheckBox name='securityFlg' label='セキュリティ会社に加入しています' view={this.view}/>
								<WprContainer mode={'セキュリティ会社.表示'} view={this.view}>
									<div className='flex'>
										<Typography className='title_comment' style={{marginRight: '15px'}}>	セキュリティ会社</Typography>
										<MatTextField name='securityComment' view={this.view} style={{width: '50%'}}></MatTextField>
									</div>
								</WprContainer>
							</div>
							<div className='title_space itemTitle title_maru'>作業の希望日時</div>
							<div className='dateArea'>
								<Grid container spacing={1}>
									<Grid item xs={12} md={6} className='itemTitle2'>
										第一希望
										<span className='txtRequired'>*</span>
										<div className='customerInput'>
											<MatDatePicker name='desiredDate1' view={this.view} placeholder='年/月/日' use='mobile' />
										</div>
									</Grid>
									<Grid item xs={12} md={6} className='itemTitle2'>
										時間帯
										<span className='txtRequired'>*</span>
										<div className='customerInput'>
											<MatSelect name='selectTime1' vm='時間帯' view={this.view} />
										</div>
									</Grid>
									<Grid item xs={12} md={6} className='itemTitle2'>
										第二希望
										<span className='txtRequired'>*</span>
										<div className='customerInput'>
											<MatDatePicker name='desiredDate2' view={this.view} placeholder='年/月/日' use='mobile' />
										</div>
									</Grid>
									<Grid item xs={12} md={6} className='itemTitle2'>
										時間帯
										<span className='txtRequired'>*</span>
										<div className='customerInput'>
											<MatSelect name='selectTime2' vm='時間帯' view={this.view} />
										</div>
									</Grid>
									<Grid item xs={12} md={6} className='itemTitle2'>
										第三希望
										<span className='txtRequired'>*</span>
										<div className='customerInput'>
											<MatDatePicker name='desiredDate3' view={this.view} placeholder='年/月/日' use='mobile' />
										</div>
									</Grid>
									<Grid item xs={12} md={6} className='itemTitle2'>
										時間帯
										<span className='txtRequired'>*</span>
										<div className='customerInput'>
											<MatSelect name='selectTime3' vm='時間帯' view={this.view} />
										</div>
									</Grid>
								</Grid>
							</div>
							<ul className='bikou'>
								<li>訪問担当が到着するおおよその時間帯目安となります。</li>
								<li>ご訪問日時については近日中にお電話にてご連絡いたします。<br/>訪問担当の空き状況によってご希望に沿えない場合もございますことご了承ください。<br/>お建物の状況等によっては工事前に別途調査が必要となる場合がございます。</li>
							</ul>
							<div className='feeTable inputArea' style={{marginTop: '30px'}}>	
							<Grid container className='feeRow'>
								<Grid item xs={12} md={4} className='feeTitle'>
									<div>前日連絡先<span className='txtRequired'>*</span></div>
								</Grid>
								<Grid item xs={12} md={8} className='feeVal courseValue' style={{padding: '15px'}}>
									<MatTextField name='beforeContact' className='itemUnit' placeholder='電話番号ハイフンなし' view={this.view} />
								</Grid>
							</Grid>	
							<Grid container className='feeRow'>
								<Grid item xs={12} md={4} className='feeTitle'>
									<div>当日連絡先<span className='txtRequired'>*</span></div>
								</Grid>
								<Grid item xs={12} md={8} className='feeVal courseValue' style={{padding: '15px'}}>
									<MatTextField name='sameDayContact' className='itemUnit' placeholder='電話番号ハイフンなし' view={this.view} />
								</Grid>
							</Grid>
							<Grid container className='feeRow'>
								<Grid item xs={12} md={4} className='feeTitle'>
									<div>当日立ち合い者<span className='txtRequired'>*</span></div>
								</Grid>
								<Grid item xs={12} md={8} className='feeVal courseValue' style={{padding: '15px'}}>
									<MatTextField name='name' className='itemUnit' placeholder='当日立ち合い者' view={this.view} />
								</Grid>
							</Grid>
						</div>
						</WprContainer>
					</div>
					<Divider className='select1_underSpace'/>
					<div className='pageFooter footer-space'>
						<div>
							<MatButton name='next' view={this.view} className='orangeButton orangeButton_parts' >
								確認
								<NavigateNextIcon />
							</MatButton>
						</div>
						<div>
							<MatButton name='back' view={this.view} className='backButton' >
								<NavigateBeforeIcon />
								戻る
							</MatButton>
						</div>
					</div>
				</div>
			</div>
			</>
		);
	}
	// --------------------------------------------------------------------------

	// リスト描画  ---------------------------------------------------------------
	/**
	 * リスト名=BeforeContractList
	 */
	public onBeforeContractListRender(rowInfo: WprRowInfo): any {
		return (
			<MatTableRow view={this.view} row={rowInfo} className='hedderrow'>
				<TableCell>
					<MatTypography name='itemName' view={this.view} row={rowInfo}/>
				</TableCell>
				<TableCell className='courseDetail'>
					<MatTypography name='itemData' className='itemData' view={this.view} row={rowInfo}/>
				</TableCell>
			</MatTableRow>
		);
	}

	/**
	 * リスト名=ErrorListModel
	 */
	public onErrorListModelRender(rowInfo: WprRowInfo): any {
		return (
			<div>
				<MatTypography name='errorMessage' view={this.view} row={rowInfo}/>
			</div>
		);
	}
	// --------------------------------------------------------------------------
}
