import { WprBaseDIManage } from '../view/di/WprBaseDIManage';
import { WprControRefreshMap } from '../view/control/map/WprControRefreshMap';
import { WprBaseViewCore } from '../view/WprBaseViewCore';
import { WprConfigModeControl } from './WprConfigModeControl';
import { IWprConfigModeOption } from './inter/IWprConfigModeOption';
import { WprControlInfo } from '../view/control/WprControlInfo';
import { IWprBaseModeInfo } from './inter/IWprBaseModeInfo';

/**
 * ビューモード基本クラス
 */
export abstract class WprBaseViewMode extends WprBaseDIManage {
	// private 変数  ------------------------------------------------------------
	private m_Name: string									= null;					// ビューモード名
	private m_CurMode: string								= '【初期モード】';		// 現在のモード
	private m_ModeList: string[]							= new Array();			// モードリスト
	private m_ModeStack: string[]							= new Array();			// モードスタック（循環設定チェック用）
	private m_CheckStart: boolean							= false;				// モードチェック開始フラグ
	private m_ViewCore: WprBaseViewCore						= null;					// ビュー情報
	private m_ConfigControlList: WprConfigModeControl[]		= new Array();			// フィールド定義情報マップ
	private m_CheckControlList: string[]					= new Array();			// モードチェックコントロールリスト
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** ビューモード名 */
	public get name(): string 							{ return this.m_Name; 				}
	/** モードリスト */
	public get modeList(): string[]		 				{ return this.m_ModeList; 			}
	/** ビュー情報 */
	public get viewCore(): WprBaseViewCore 				{ return this.m_ViewCore; 			}
	/** モードスタック（循環設定チェック用） */
	public get modeStack(): string[]					{ return this.m_ModeStack; 			}	public set modeStack(modeStack: string[]) { this.m_ModeStack = modeStack;	}
	/** フィールド定義情報マップ */
	public get controlList(): WprConfigModeControl[] 	{ return this.m_ConfigControlList; 	}
	/** モードチェックコントロールリスト */
	public get checkControlList(): string[]				{ return this.m_CheckControlList; 	}
	/** リスト名 */
	public get listName(): string 						{ return ''; 						}
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	public constructor(name: string) {
		super(name);
		this.m_Name = name;
	}
	// --------------------------------------------------------------------------

	// abstract メソッド  --------------------------------------------------------
	/**
	 * 使用するモードリスト取得（このビューモードで使用する全てのモードを定義する）
	 * @param list モードリスト
	 */
	public abstract useModeList(list: string[]): void;

	/**
	 * コントロール定義を登録処理
	 */
	public abstract onRegisterControl(): void;

	/**
	 * ビューの状態をチェックしてモードを変更する
	 * @param curMode 現在のモード
	 * @return 新しいモード（変更ない場合は現在のモード）
	 */
	public abstract onCheckMode(curMode: string): string;
	// --------------------------------------------------------------------------

	// virtual メソッド  ---------------------------------------------------------
	/**
	 * ベースクラスコントロール定義を登録処理
	 */
	public onBaseRegisterControl(): void {
	}

	/**
	 * モード変更通知
	 * @param oldMode 古いモード
	 * @param newMode 新しいモード
	 */
	public onChangeMode(oldMode: string, newMode: string): void {}

	/**
	 * 現在のモード取得
	 * @return 現在のモード
	 */
	public getMode(): string {
		return this.m_CurMode;
	}

	/**
	 * 現在のモード設定(TableMode用)
	 * @param mode 現在のモード
	 */
	protected setMode(mode: string): void {
		this.m_CurMode = mode;
	}

	/**
	 * モードリスト設定処理
	 * @param modeList モードリスト
	 */
	public setModeList(modeList: string[]): void {}

	/**
	 * モードクリア処理
	 */
	public clearMode(): void {
		this.m_CurMode = '【初期モード】';
	}

	/**
	 * モードチェック処理
	 * @param isClear trueの場合現在モードをクリアする(モードが変わらなくて反応する)
	 */
	public checkMode(isClear?: boolean): void {
		let mode: string = null;
		try {
			if (isClear === true)
				this.clearMode();
			this.injection();
			mode = this.onCheckMode(this.m_CurMode);
		}
		catch (ex) {
			this.addErrorLog('モードのチェック処理で例外が発生しました。', ex);
		}
		if (this.m_ModeList.includes(mode) === false) {
			this.addErrorLog(`モード（onCheckModの戻り値）に誤りがあります。[${mode}]`);
			return;
		}
		this.m_CheckStart = true;
		if (mode === this.m_CurMode)
			return;				// モードは変わらなかった
		if (this.m_ModeStack.includes(mode) === true) {
			this.addWarningLog(`モードの設定が循環しています。モードは変更できませんでした。[${mode}]`);
			return;
		}
		this.m_ModeStack.push(mode);
		try {
			const old = this.m_CurMode;
			this.m_CurMode = mode;
			this.setControlStatus(this.m_CurMode);
			this.onChangeMode(old, this.m_CurMode);
			this.m_ViewCore.changeViewMode(this, old, this.m_CurMode);
		}
		catch (ex) {
			this.addErrorLog('モードの変更処理で例外が発生しました。', ex);
		}
		finally {
			this.m_ModeStack = this.m_ModeStack.filter(m => m !== mode);
		}
	}

	/**
	 * モードチェック
	 * @param mode モード
	 * @returns trueの場合、モードが含まれている
	 */
	public containsMode(mode: string): boolean {
		if (mode === this.m_CurMode)
			return true;
		return false;
	}
 
	/**
	 * モードステータス設定通知
	 * @param mode モード
	 * @param name 設定名
	 * @param controlName コントロール名
	 * @param modeInfo モード情報
	 * @returns モード情報
	 */
	public onSetModeStatus(mode: string, name: string, controlName: string, modeInfo: IWprBaseModeInfo): IWprBaseModeInfo {
		return modeInfo;
	}

	/**
	 * モードステータス設定通知
	 * @param modeList 複数モード
	 * @param name 設定名
	 * @param controlName コントロール名
	 * @param modeInfo モード情報
	 * @returns モード情報
	 */
	 public onSetMultiModeStatus(modeList: string[], name: string, controlName: string, modeInfo: IWprBaseModeInfo): IWprBaseModeInfo {
		return modeInfo;
	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * リストコントロール情報取得
	 * @param listName リスト名
	 * @param controlName コントロール名
	 * @param idx リストインデクス
	 */
	 public getListControlInfo(listName: string, controlName: string, idx: number): WprControlInfo {
		return this.m_ViewCore.getListControlInfo(listName, controlName, idx);
	} 
	// --------------------------------------------------------------------------

	// public メソッド  ----------------------------------------------------------
	/**
	 * コントロール定義リスト取得
	 * @return コントロール定義リスト
	 */
	public getConfigControlList(): WprConfigModeControl[] {
		return this.m_ConfigControlList;
	}

	/**
	 * View情報設定処理
	 * @param Binder バインダ
	 */
	public setViewCore(viewCore: WprBaseViewCore): void {
		if (this.m_ViewCore == null) {
			this.m_ViewCore = viewCore;
			try {
				this.useModeList(this.m_ModeList);
				this.setModeList(this.m_ModeList);
				this.checkModeList();
				this.onBaseRegisterControl();
				this.onRegisterControl();
			}
			catch (ex) {
				this.addErrorLog('モードの初期化処理で例外が発生しました。', ex);
			}
		}
		else {
			this.m_ViewCore = viewCore;
		}
	}

	/**
	 * ビュー表示通知（初期化）
	 */
	public onShowView(): void {
		this.clearMode();			// ビュー表示時にモードはクリアする
		this.checkMode();
	}

	/**
	 * 値変更通知（コントロールの変更を受けてモードを変更する）
	 * @param id フィールドID
	 */
	public onChangeValue(id: string): void {
		if (this.m_CheckControlList.includes(id) === true)
			this.checkMode();
	}

	/**
	 * アクションの実行終了通知（アクションの実行結果によってモードを変更する）
	 * @param id アクションID
	 */
	public onActionComplete(id: string): void {
		this.checkMode();
	}

	/**
	 * モードの更新を強制的に行う
	 * @param isClear trueの場合現在モードをクリアする(モードが変わらなくて反応する)
	 */
	public updateMode(isClear?: boolean): void {
		this.checkMode(isClear);
	}

	/**
	 * コントロールモード更新処理
	 * @param name コントロール名
	 */
	public updateControlMode(name: string): void {
		if (this.m_CheckStart === true) {
			this.m_ConfigControlList.forEach(cinfo => {
				if (cinfo.name === name)
					cinfo.setStatus(this.m_CurMode);
			});
		}
	}
	
	/**
	 * 終了処理
	 */
	public terminate(): void {
		this.m_ViewCore = null;
		this.m_ConfigControlList = new Array();
		this.m_CheckControlList = null;
	}
	// --------------------------------------------------------------------------

	// protected メソッド  -------------------------------------------------------
	/**
	 * インジェクション実行処理
	 */
	 protected injection() {
		this.execInjection();
	}

	/**
	 * モードリスト取得
	 * @return モードリスト
	 */
	protected getModeList(): string[] {
		return this.m_ModeList;
	}

	/**
	 * コントロール定義を追加する
	 * @param name コントロール名
	 * @param option 定義オプション
	 * @param id コントロールID
	 */
	protected addControl(name: string, option: IWprConfigModeOption, id?: string): void {
		if (this.m_ViewCore.viewInfo.getControlInfo(name) == null) {
			this.addErrorLog(`モード定義に対するコントロールが見つかりませんでした。[${name}]`);
			this.m_ViewCore.viewInfo.addConfig(name, '', null);
		}
		if (this.checkControlName(name, id) === false) {
			this.addErrorLog(`モード定義が重複しています。[${name}]`);
		}
		else {
			const cinfo = new WprConfigModeControl(name, option, this, id);
			cinfo.setControl(this.m_ViewCore.viewInfo.getControlInfo(name), this.m_ModeList);
			this.m_ConfigControlList.push(cinfo);
			if (cinfo.isCheck === true)
				this.m_CheckControlList.push(name);
		}
	}

	/**
	 * カテゴリ定義を追加する
	 * @param name カテゴリ名
	 * @param option 定義オプション
	 */
	protected addCategory(name: string, option: IWprConfigModeOption): void {
		this.m_ViewCore.viewInfo.getCategoryControlList(name).forEach(info => {
			if (this.checkControlName(info.name) === false) {
				this.addErrorLog(`モード定義が重複しています。[${info.name}]`);
			}
			else {
				name = info.name;
				const cinfo = new WprConfigModeControl(name, option, this);
				cinfo.setControl(this.m_ViewCore.viewInfo.getControlInfo(name), this.m_ModeList);
				this.m_ConfigControlList.push(cinfo);
				if (cinfo.isCheck === true)
					this.m_CheckControlList.push(name);
			}
		});
	}

	/**
	 * コントロール名チェック
	 * @param name コントロール名
	 * @param id コントロールID
	 * @returns falseの場合、重複している
	 */
	protected checkControlName(name: string, id?: string): boolean {
		for (const cinfo of this.m_ConfigControlList) {
			if (cinfo.name === name) {
				if (id) {
					if (cinfo.id) {
						if (id === cinfo.id)
							return false;
					}
					else {
						return false;
					}
				}
				else {
					return false;
				}
			}
		}
		return true;
	}

	/**
	 * グローバルモード設定処理
	 * @param name モード名称
	 * @param mode モード
	 */
	protected getGlobalMode(name: string): string {
		return this.m_ViewCore.getGlobalMode(name);
	}

	/**
	 * スコープモード取得処理
	 * @param name スコープモード名
	 * @returns スコープモード
	 */
	 public getScopeMode(name: string): any {
		return this.m_ViewCore.getScopeMode(name);
	}

	/**
	 * ビューモード取得処理
	 * @param name ビューモード名
	 * @returns ビューモード
	 */
	protected getViewMode(name: string): string {
		return this.m_ViewCore.getViewMode(name);
	}

	/**
	 * ビューモードを設定する（Viewが次のRenderを行った後に設定する）
	 * ※モードでstyle等を変更し、Render後の状態で判定したい場合に使用する
	 * @param name ビューモード名
	 * @param mode モード
	 */
	protected setViewMode(name: string, mode: string): void {
		this.m_ViewCore.setReserveViewMode(name, mode);
	}
	// --------------------------------------------------------------------------

	// private メソッド  ---------------------------------------------------------
	/**
	 * モードリストチェック処理
	 */
	private checkModeList(): void {
		const list: string[] = new Array();
		this.m_ModeList.forEach((mode) => {
			if (list.includes(mode) === true)
				this.addWarningLog(`モードが重複しています。[${mode}]`);
			else
				list.push(mode);
		});
		this.m_ModeList = list;
	}

	/**
	 * フィールドの状態を設定する
	 * @param mode モード
	 */
	private setControlStatus(mode: string): void {
		this.m_ConfigControlList.forEach(cinfo => {
			if (this.checkRender(cinfo.render) === true)
				cinfo.setStatus(mode);
		});
	}

	/**
	 * レンダーチェック
	 * @param render レンダー
	 * @returns trueの場合は、対象レンダー
	 */
	private checkRender(render: string): boolean {
		if (render) {
			if (this.m_ViewCore.getUserAgentRender() !== render)
				return false;
		}
		return true;
	}
	// --------------------------------------------------------------------------
}

/**
 * ビューモード基本情報
 */
export abstract class WprBaseViewModeInfo<T extends WprBaseViewCore> extends WprBaseViewMode {
	// private 変数  ------------------------------------------------------------
	private m_Parent: T	= null;			// 親ビュー情報
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** 親ビュー情報 */
	public get view():  T	{ return this.m_Parent; }
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	public constructor(name: string) {
		super(name);
	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * View情報設定処理
	 * @param Binder バインダ
	 */
	public setViewCore(viewCore: WprBaseViewCore): void {
		this.m_Parent = viewCore as T;
		super.setViewCore(viewCore);
	}

	/**
	 * DI用コントロール情報マップ取得
	 * @returns DI要コントロール情報マップ
	 */
	public getDIControlMap(): WprControRefreshMap {
		return this.viewCore.getDIControlMap();
	}

	/**
	 * DI元インスタンス取得
	 * @returns DI元インスタンス
	 */
	public getDIObjectFromInstance(): any {
		return this.viewCore;
	}
	// --------------------------------------------------------------------------
}