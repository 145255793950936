import React, { ReactNode } from 'react';
import { WprBaseViewComponent } from '../../../../wpr-framework/view/component/WprBaseViewComponent';
import { S_01_001View } from './S_01_001View';

/**
 * リダイレクトページ用コンポーネント
 */
export class S_01_001 extends WprBaseViewComponent {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: any) { super(new S_01_001View(), props); }
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** リダイレクトページビュー情報 */
	public get viewInfo(): S_01_001View	{ return this.view as S_01_001View;	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		return (
			<>
			</>
		);
	}
	// --------------------------------------------------------------------------
}
