import { Grid } from '@material-ui/core';
import React, { ReactNode } from 'react';
import { WprBaseDialogViewComponent } from '../../../../../wpr-framework/view/component/WprBaseDialogViewComponent';
import { CompleteDialogView } from './CompleteDialogView';
import { MatButton } from '../../../../../wpr-framework/component/controls/material/MatButton';

/**
 * 完了ダイアログ用コンポーネント
 */
export class CompleteDialog extends WprBaseDialogViewComponent {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: any) { super(new CompleteDialogView(), props); }
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** 完了ダイアログビュー情報 */
	public get viewInfo(): CompleteDialogView	{ return this.view as CompleteDialogView;	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		return (
			<>
				<Grid container alignItems='center'>
					<Grid item xs={12} style={{textAlign: 'center'}}>
						<div>
							契約内容をご確認頂きありがとうございます。<br/>
							加入されたサービスは、クレジットカードでのお支払いが必要となります。<br/>
							以下からお支払方法の変更をお願い致します。
						</div>
					</Grid>
					<Grid item xs={12} style={{textAlign: 'center', marginTop: '1em'}} className='top_margin'>
						<MatButton name='b_' color='primary' view={this.view}>マイページ 支払方法変更へ</MatButton>
					</Grid>
				</Grid>
			</>
		);
	}
	// --------------------------------------------------------------------------
}
