import { WprBaseViewCore } from '../WprBaseViewCore';
import { WprFramework } from '../../WprFramework';

/**
 * ビューモデルデコレータ
 * @param target ビュー情報
 * @param props プロパティ名
 */
export function WprViewMode(target: any, props: string) {
	const viewCore = target as WprBaseViewCore;
	if (viewCore)
		WprFramework.getInstance().model.addDecoListInfo('ViewMode', viewCore, props);
}
