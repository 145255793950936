import { Box, Chip, FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import React, { ReactNode } from 'react';
import { WprControlInfo } from '../../../view/control/WprControlInfo';
import { WprFramework } from '../../../WprFramework';
import { IWprLoadWait } from '../../mode/IWprLoadWait';
import { IWprInputControlProps } from '../../props/IWprControlProps';
import { WprControlVisibility } from '../../props/IWprControlState';
import { WprBaseInputControlComponent } from '../WprBaseInputControlComponent';

/**
 * 選択コントロールプロパティ情報
 */
interface IMatSelectProps extends IWprInputControlProps {
	vm: string;										// ValueMap名
	placeholder?: string;							// placeholder
	variant?: 'standard' | 'filled' | 'outlined';	// 形状
	multiple?: boolean;								// 複数選択
	bind?: 'string' | 'array';						// bind
	valueLabel?: boolean;							// 値ラベルフラグ(値をselectのラベルに使用する)
}

/**
 * 選択コントロール
 */
export class MatSelect extends WprBaseInputControlComponent<IMatSelectProps, HTMLSelectElement> implements IWprLoadWait {
	// プロパティ  ---------------------------------------------------------------
	/** ValueMap保持フラグ */
	public get hasValueMap(): boolean	{ return true; 	}
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	constructor(props: IMatSelectProps) {
		super(props);
		this.setChangeValueEvent(this.onChangeValue.bind(this));
	}
	// --------------------------------------------------------------------------

	// イベント  ----------------------------------------------------------------
	/**
	 * 値取得
	 * @returns 値
	 */
	protected getValue(): any {
	if (this.state.value == null)
			return '';
		return this.state.value;
	}

	/**
	 * 値変更処理
	 * @param event 変更イベント 
	 */
	public onChangeValue(event: React.ChangeEvent<HTMLSelectElement>): void {
		const value = this.getInputValue(event.target.value);
		// const name = event.target.name;
		if (this.controlInfo != null) {
			const ref = this.ref.current.node as HTMLElement;
			this.addChangeValueEventLog(ref, value);
			this.changeValue(value);
		}
	}

	/**
	 * selectのラベルを値にする
	 * @param val 値 
	 * @returns 値
	 */
	private onRenderValue(val: any): any {
		return val;
	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		if (this.state.visibility === WprControlVisibility.COLLAPSED)
			return <></>;
		const props = this.getProps('mat-select', false);
		const {name, view, vm, className, placeholder, children, ...other} = this.props;
		const state = this.getState(['style', 'disabled']);
		const events = this.getEvents();
		const items = this.getItemList();
		const options = this.getOptions();
		const soptions = this.getSelectOptions();
		const menuProps = this.getMenuProps();
		const error = this.getError();
		const helperText = this.getHelperText();
		let select = null;
		if (this.state.visibility === WprControlVisibility.VISIBLE) {
			if (this.props.view.isLoadValueMap(this.props.vm) === false) {
				if (!this.state.readOnly) {
					select = <FormControl {...options}><Select {...soptions} {...props} {...state} {...other} {...events} {...menuProps} {...error} inputRef={this.ref} >{items}</Select>{helperText}</FormControl>;
				}
				else if (this.props.multiple === true) {
					select = <FormControl {...options}><Select readOnly {...soptions} {...props} {...state} {...other} {...events} {...menuProps} {...error} inputRef={this.ref} >{items}</Select>{helperText}</FormControl>;
				}
				else {
					const inputProps = this.getInputProps(['readOnly']);
					props.value = this.getDispValue(props.value);
					select = <TextField {...options} {...props} {...state} {...other} {...inputProps} {...error} ref={this.ref}/>;
				}
			}
			else {
				if (!this.state.readOnly) {
					select = <FormControl {...options}><Select {...soptions} {...props} {...state} {...other} {...events} {...menuProps} {...error}  inputRef={this.ref}/>{helperText}</FormControl>;
				}
				else {
					const inputProps = this.getInputProps(['readOnly']);
					props.value = '';
					select = <TextField {...options} {...props} {...state} {...other} {...inputProps} {...error} ref={this.ref}/>;
				}
				this.props.view.addLoadWaitComponent(this.props.vm, this);
			}
		}
		else {
			state['style'] = {...this.state.style, visibility: 'hidden'};
			select = <FormControl variant='outlined'><Select {...props} {...state} {...other} {...error}  ref={this.ref}>{items}</Select></FormControl>;
		}
		return(
			<>
				{select}
			</>
		);
	}

	/**
	 * プロパティ情報取得
	 * @param cls オリジナルクラス
	 * @returns プロパティ情報
	 */
	protected getProps(cls?: string, setClassName: boolean = true): any {
		let isArrayValue = false;
		if (this.props.bind === 'array')
			isArrayValue = true;
		const props = super.getProps('mat-select', false);
		if (this.props.view.isLoadValueMap(this.props.vm) === false) {
			if (this.props.multiple === true) {
				if (isArrayValue === true) {
					if (Array.isArray(props.value) === false)
						props.value = new Array();
				}
				else {
					if (Array.isArray(props.value) === false) {
						const array = new Array();
						if (props.value) {
							props.value.split(',').forEach(val => {
								array.push(val);
							});
						}
						props.value = array;
					}
				}
			}
		}
		else {
			if (this.props.multiple === true)
				props.value = new Array();
			else
				props.value = null;
		}
		if (this.props.multiple === true && !this.state.readOnly)
			props['renderValue'] = this.getRenderValue();
		return props;
	}

	/**
	 * フォーカス設定処理
	 */
	 public setFocus(): void {
		try {
			if (this.ref && this.ref.current)
				this.ref.current.focus();
		}
		catch(e) {
			// 無視
		}
	}
	// --------------------------------------------------------------------------

	// private メソッド  ---------------------------------------------------------
	/**
	 * アイテムリスト取得
	 * @returns アイテムリスト取得
	 */
	private getItemList(): any[] {
		let items = this.props.view.vm(this.props.name, this.props.vm, this.props.row).map((kvInfo, index, array) => {
			if (this.state.readOnly)
				return <MenuItem className='wpr-select-option' key={index+1} value={kvInfo.key} disabled>{kvInfo.value}</MenuItem>;
			else
				return <MenuItem className='wpr-select-option' key={index+1} value={kvInfo.key}>{kvInfo.value}</MenuItem>;
		});
		const pholder = this.getPlaceholder();
		if (this.isErrorValue() === true)
			items.unshift(<MenuItem className='mat-select-option' key={0} value={this.state.value} disabled style={{display: 'none'}}>{pholder}</MenuItem>);
		else if (!this.state.hissu && this.state.value && !this.props.multiple)
			items.unshift(<MenuItem className='mat-select-option' key={0} value=''><em>　</em></MenuItem>);
		else
			items.unshift(<MenuItem className='mat-select-option' key={0} value='' disabled style={{display: 'none'}}>{pholder}</MenuItem>);
		return items;
	}

	/**
	 * 表示値取得
	 * @param value 入力値
	 * @returns 表示値取得
	 */
	private getDispValue(value: any): string {
		for(const kinfo of this.props.view.vm(this.props.name, this.props.vm, this.props.row).values()) {
			if (kinfo.key === value)
				return kinfo.value;
		}
		return '';
	}

	/**
	 * オプション情報取得
	 * @returns オプション情報
	 */
	private getOptions(): any {
		const rtn: any = {};
//		if (this.controlInfo.description)
//			rtn['label'] = this.controlInfo.description;
		rtn['className'] = this.className();

		const pholder = this.getPlaceholder();
//		if (pholder && !this.state.value) 
//			rtn['label'] = pholder;
		if (this.props.variant)
			rtn['variant'] = this.props.variant;
		else
			rtn['variant'] = 'outlined';
		rtn['size'] = 'small';
		rtn['margin'] = 'dense';
		return rtn;
	}

	/**
	 * 値レンダー取得
	 * @returns 値レンダー
	 */
	private getRenderValue(): any {
		return (
			(selected) => (
				<Box className='wpr-multiple-select' sx={{ display: 'flex', flexWrap: 'wrap' }}>
					{selected.map((value) => (
						<Chip key={value} label={this.getDispValue(value)} />
					))}
				</Box>
			)
		);
	}

	/**
	 * 入力ラベル取得
	 * @returns 入力ラベル
	 */
	private getInputLable(): any {
		if (this.controlInfo.description) {
			if (this.getPlaceholder() !== ' ')
				return <InputLabel shrink id={this.props.name+'_label'}>{this.controlInfo.description}</InputLabel>;
			else
				return <InputLabel id={this.props.name+'_label'}>{this.controlInfo.description}</InputLabel>;
		}
	}

	/**
	 * 選択オプション情報取得
	 * @returns 選択オプション情報
	 */
	private getSelectOptions(): any {
		const rtn: any = {};
//		if (this.controlInfo.description) {
//			rtn['labelId'] = this.props.name+'_label';
//			rtn['label'] = this.controlInfo.description;
//		}
//		if (this.getPlaceholder() !== ' ')
		rtn['displayEmpty'] = true;
		if (this.props.valueLabel)
			rtn['renderValue'] = this.onRenderValue.bind(this);
		return rtn;
	}

	/**
	 * placeholder情報取得
	 * @returns placeholder情報
	 */
	private getPlaceholder(): string {
		if (this.state.placeholder)
			return this.state.placeholder;
		if (this.props.placeholder)
			return this.props.placeholder;
		return ' ';
	}

	/**
	 * エラー値取得
	 * @return trueの場合、エラー値
	 */
	private isErrorValue(): boolean {
		if (this.state.value === '' || this.state.value === undefined || this.state.value === null)
			return false;
		for(const info of this.props.view.vm(this.props.name, this.props.vm, this.props.row)) {
			if (info.key == this.state.value)
				return false;
		}
		return true;
	}

	/**
	 * メニュープロパティ取得
	 * @param list 抽出リスト
	 */
	protected getMenuProps(): any {
		const state: any = {};
		state['getContentAnchorEl'] = null;
		state['anchorOrigin'] = {vertical: 'bottom', horizontal: 'left'};
		state['transformOrigin'] = {vertical: 'top', horizontal: 'left'};
		const rtn: any = {};
		rtn['MenuProps'] = state;
		return rtn;
	}

	/**
	 * エラー情報取得処理
	 * @returns エラー情報
	 */
	private getError(): any {
		const rtn: any = {};
		if (this.state.errMessage && this.state.errMessage.length > 0) {
			rtn['error'] = true;
//			rtn['helperText'] = this.state.errMessage[0];	// とりあえず最初の一行
		}
		return rtn;
	}

	/**
	 * ヘルパーテキスト情報取得処理
	 * @returns エラー情報
	 */
	private getHelperText(): ReactNode {
		if (this.state.errMessage && this.state.errMessage.length > 0) {
			return (<FormHelperText className={this.getErrorClassName('wpr-select-error')}>{this.state.errMessage[0]}</FormHelperText>);
		}
		return null;
	}

	/**
	 * 入力値取得
	 * @param input 入力値
	 * @returns 入力値
	 */
	private getInputValue(input: any): any {
		if (this.props.multiple === true && this.props.bind !== 'array') {
			if (input && Array.isArray(input) === true) {
				const array = input as [];
				let value = '';
				array.forEach(val => {
					if (value.length > 0)
						value += ',';
					value += val;
				});
				return value;
			}
		}
		else {
			if (input == null)
				return '';
		}
		return input;
	}

	/**
	 * エラークラス取得（localを解決）
	 * @param cls クラス 
	 * @returns クラス
	 */
	private getErrorClassName(cls: string): string {
		const local = this.props.view.getLocalClassName(cls);
		if (local != null)
			return local;
		return cls;
	}
	// --------------------------------------------------------------------------

	// IWprLoadWaitの実装  ------------------------------------------------------
	/**
	 * ロード終了通知
	 */
	public onLoadEnd(): void {
		this.controlInfo.resetValueMap(false);
//		this.setState({
//			loadWait: false
//		});
	}
	// --------------------------------------------------------------------------
}