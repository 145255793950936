
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { Typography, Grid, Divider } from '@material-ui/core';
import { ReactNode } from 'react';
import { MatButton } from '../../../../wpr-framework/component/controls/material/MatButton';
import { MatTypography } from '../../../../wpr-framework/component/controls/material/MatTypography';
import { WprBaseViewComponent } from '../../../../wpr-framework/view/component/WprBaseViewComponent';
import { ProgressBar } from '../../common/progress_bar/ProgressBar';
import { ModNhkConfirmView } from './ModNhkConfirmView';
import styles from './ModNhkConfirm.scss';

/**
 * 解約申込内容の確認用コンポーネント
 */
export class ModNhkConfirm extends WprBaseViewComponent {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: any) { super(new ModNhkConfirmView(), props); }
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** 申込内容の確認ビュー情報 */
	public get viewInfo(): ModNhkConfirmView	{ return this.view as ModNhkConfirmView;	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * スタイルオブジェクト取得
	*/
	public getStylesObject(): any { return styles; }

	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		return (
			<>
				<div>
				<div className='pageCard'>
					<div className="pageHeader stepFlow">
						<ProgressBar name='progressBar' view={this.view}/>
					</div>
					<div className='main'>
						<div className='pageTitle'>
							<div className='title_left'>
								<MatTypography name='title' className='title_text' view={this.view} />
								<Typography className='title_comment'>N H K団体一括支払いの解約申込みを行いますか？</Typography>
							</div>
						</div>
						<div className='feeTable' style={{ marginTop: '40px', marginBottom: '35px'}}>
							<Grid container className='feeRow'>
								<Grid item xs={12} md={4} className='feeTitle table_header'>
									<div>契約開始日</div>
								</Grid>
								<Grid item xs={12} md={8} className='feeVal courseValue'>
								<MatTypography name='discountSelect' className='itemUnit' style={{ color: 'black' , fontWeight: '500'}} view={this.view}/>
								</Grid>
							</Grid>
						</div>
						<div style = {{marginBottom: '50px'}}>
							<ul className='ul_kome attention_fontSize'>
								<li>
									現在の支払期間満期後に利用停止案内となります。
								</li>
								<li>
									停止後はNHKから通常請求となります。
								</li>
								<li>
									今後のNHK受信料のお支払いについては、別途NHKからご案内が書類で届きますのでそちらをご参照ください。
								</li>
							</ul>
						</div>
					</div>
					<Divider />
					<div className='pageFooter footer-space'>
						<div>
							<MatButton name='next' view={this.view} className='orangeButton orangeButton_parts' >
								この内容で申込む
								<NavigateNextIcon />
							</MatButton>
						</div>
						<div>
							<MatButton name='back' view={this.view} className='backButton' >
								<NavigateBeforeIcon />
								戻る
							</MatButton>

						</div>
					</div>

				</div>
			</div>
			</>
		);
	}
	// --------------------------------------------------------------------------

}
