// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@charset \"UTF-8\";\n/*\r\n * 機器変更確認用CSS\r\n */\n.ModTvequipConfirm-value {\n  display: flex;\n  align-items: baseline; }\n  .ModTvequipConfirm-value .ModTvequipConfirm-itemSelect .MuiSelect-root {\n    width: auto !important;\n    height: auto !important;\n    background-color: #FFFFFF !important;\n    color: #484848 !important;\n    font-weight: 500 !important;\n    font-size: 14px;\n    padding: 0; }\n  .ModTvequipConfirm-value .ModTvequipConfirm-itemSelect svg {\n    display: none; }\n  .ModTvequipConfirm-value .ModTvequipConfirm-itemSelect fieldset {\n    border: 0px; }\n", ""]);
// Exports
exports.locals = {
	"value": "ModTvequipConfirm-value",
	"itemSelect": "ModTvequipConfirm-itemSelect"
};
module.exports = exports;
