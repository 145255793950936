/**
 * タブ受信データ情報
 */
export class WprTabReceiveDataInfo {
	// private 変数  ------------------------------------------------------------
	private m_DataName: string		= null;		// データ名
	private m_Data: any 			= false;	// データ
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** データ名 */
	public get dataName(): string 	{ return this.m_DataName; 	}
	/** データ */
	public get data(): any	 		{ return this.m_Data; 		}
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	public constructor(dataName: string, data: any) {
		this.m_DataName = dataName;
		this.m_Data = data;
	}
	// --------------------------------------------------------------------------
}
