import { TextField } from '@material-ui/core';
// import { stringNumberComparer } from '@material-ui/data-grid';
import React, { ReactNode } from 'react';
import { WprControlInfo } from '../../../view/control/WprControlInfo';
import { WprFramework } from '../../../WprFramework';
import { IWprInputControlProps } from '../../props/IWprControlProps';
import { WprControlVisibility } from '../../props/IWprControlState';
import { WprBaseMatInputControlComponent } from '../WprBaseMatInputControlComponent';

/**
 * テキストフィールドプロパティ情報
 */
export interface IMatTextFieldProps extends IWprInputControlProps {
	type?: string;									// タイプ
	placeholder?: string;							// placeholder
	variant?: 'standard' | 'filled' | 'outlined';	// 形状
	multiline?: boolean;							// テキストエリア化
	rows?: number;									// 行数
	minRows?: number;								// 最小行数
	maxRows?: number;								// 最大行数
	converter?: string;								// コンバータ
	align?: 'left' | 'center' | 'right';			// 揃え位置
}

/**
 * テキストフィールド基本コントロール
 */
export abstract class MatBaseTextField<T extends IMatTextFieldProps> extends WprBaseMatInputControlComponent<T, HTMLInputElement> {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: T) {
		super(props);
		this.setChangeValueEvent(this.onChangeValue.bind(this));
		if (this.checkFocus()) {
			this.setFocusEvent(this.onFocus.bind(this));
			this.setBlureEvent(this.onBlur.bind(this));
		}
		this.setKeyEvent();
	}
	// --------------------------------------------------------------------------

	// virtual メソッド  --------------------------------------------------------
	/**
	 * propsのtype取得
	 * @returns propsのtype
	 */
	protected getPropsType(): string {
		return this.props.type;
	}
	// --------------------------------------------------------------------------

	// イベント  ----------------------------------------------------------------
	/**
	 * 値変更処理
	 * @param event 変更イベント 
	 */
	public onChangeValue(event: React.ChangeEvent<HTMLInputElement>): void {
		this.changeInputValue(event.target, event.target.value);
	}

	/**
	 * フォーカスIN処理
	 * @param event フォーカスINイベント
	 */
	public onFocus(event: React.ChangeEvent<HTMLInputElement>): void {
		super.onFocus(event);
		this.setState({
			focus: true
		});
//		console.log('focus : ' + this.props.name);
	}

	/**
	 * フォーカスOUT処理
	 * @param event フォーカスOUTイベント
	 */
	public onBlur(event: React.ChangeEvent<HTMLInputElement>): void {
		super.onBlur(event);
		this.setState({
			focus: false
		});
//		console.log('blur : ' + this.props.name);
	}

	/**
	 * キーダウンイベント
	 * @param event キーイベント情報
	 */
	public onKeyDown(event: React.KeyboardEvent<HTMLInputElement>): void {
		if (event.ctrlKey == true && event.key === 'v') {
			if (this.props.type === 'date' || this.props.type === 'month') {
				if (this.state.readOnly === false && this.state.disabled === false) {
					if (!navigator.clipboard) {
					}
					else {
						navigator.clipboard.readText().then(text => {
							const rep = text.replace(/\//g, '').replace(/-/g, '');
							const nval = Number(rep);
							if (isNaN(nval) === false) {
								if ((this.props.type === 'date' && rep.length === 8) ||
									(this.props.type === 'month' && rep.length === 6)) {
									this.controlInfo.value = rep;
									// return;
								}
							}
						})
						.catch(err => {
							console.log('失敗: ' + err);
						});
					}
				}
			}
		}
		super.onKeyDown(event);
	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * 開始フォーカス状態取得
	 * @returns フォーカス
	 */
	protected getStartFocus(): boolean {
		if (this.checkFocus()) {
//			console.log('start : ' + this.props.name);
			return false;
		}
		return null;
	}

	/**
	 * 値取得
	 * @returns 値
	 */
	protected getValue(): any {
		if (this.state.value == null)
			return '';
		return this.state.value;
	}

	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		if (this.state.visibility === WprControlVisibility.COLLAPSED)
			return <></>;
		const props = this.getProps();
		const {name, view, children, className, placeholder, autoComplete, type, tooltip, align, ...other} = this.props;
		const state = this.getState(['style', 'disabled']);
		const inputProps = this.getInputProps(['min', 'max', 'minLength', 'maxLength', 'readOnly']);
		const events = this.getEvents();
		const pholder = this.getPlaceholder();
		const options = this.getOptions();
		const error = this.getError();
		this.setAlign(inputProps.inputProps);
		let textField = null;
		if (this.state.visibility === WprControlVisibility.VISIBLE) {
			textField = <TextField {...props} {...state} {...other} {...options} {...pholder} {...inputProps} {...error} {...events} inputRef={this.ref}/>;
		}
		else {
			state['style'] = {...this.state.style, visibility: 'hidden'};
			textField = <TextField {...props} {...state} {...other} {...options} {...pholder} {...inputProps} inputRef={this.ref}/>;
		}
		return this.getControlNode(textField);
		// return (
		// 	<>
		// 		{textFiled}
		// 	</>
		// );
	}

	/**
	 * プロパティ情報取得
	 * @param cls オリジナルクラス
	 * @returns プロパティ情報
	 */
	protected getProps(cls?: string, setClassName: boolean = true): any {
		let props = super.getProps(cls, setClassName);
		if (this.getPropsType() === 'file') {
			props.value = undefined;
		}
		else if (this.state.readOnly === true || this.state.focus == false) {
//			if (this.getPropsType() === 'date' || this.getPropsType() === 'month')
//				props.value = this.getEditValue(this.props.converter);
//			else
				props.value = this.getViewValue(this.props.converter, this.props.row);
		}
		else if (this.state.disabled === true) {
//			if (this.getPropsType() === 'date' || this.getPropsType() === 'month')
//				props.value = this.getEditValue(this.props.converter);
//			else
				props.value = this.getViewValue(this.props.converter, this.props.row);
		}
		else {
			props.value = this.getEditValue(this.props.converter);
		}
		return props;
	}
	// --------------------------------------------------------------------------

	// protected メソッド  -------------------------------------------------------
	/**
	 * 入力値変更処理
	 * @param target ターゲット要素
	 * @param value 入力値
	 */
	protected changeInputValue(target: HTMLElement, value: any): void {
		if (this.controlInfo != null) {
			if (target)
				this.addChangeValueEventLog(target, value);
			WprControlInfo.isChangeValue = true;
			try {
				WprFramework.getInstance().view.setScopeUpdateProperty();
				this.controlInfo.dirty = true;
				if (this.props.row) {
					if (this.controlInfo.changeListValue(value, this.props.row) === false)
						return;
				}
				else {
					if (this.controlInfo.changeValue(value) === false)
						return;
				}
				WprControlInfo.isChangeValue = true;
				this.controlInfo.value = value;
				this.controlInfo.viewCore.viewInfo.checkScopeModelValue();
				this.setTooltipOpen(value);
			}
			finally {
				WprControlInfo.isChangeValue = false;
			}
		}
	}

	/**
	 * placeholder情報取得
	 * @returns placeholder情報
	 */
	protected getPlaceholder(): any {
		const rtn: any = {};
		if (this.state.placeholder)
			rtn['placeholder'] = this.state.placeholder;
		if (this.props.placeholder)
			rtn['placeholder'] = this.props.placeholder;
		return rtn;
	}

	/**
	 * エラー情報取得処理
	 * @returns エラー情報
	 */
	protected getError(): any {
		const rtn: any = {};
		if (this.state.errMessage && this.state.errMessage.length > 0) {
			rtn['error'] = true;
			rtn['helperText'] = this.state.errMessage[0];	// とりあえず最初の一行
		}
		return rtn;
	}
	// --------------------------------------------------------------------------

	// private メソッド  ---------------------------------------------------------
	/**
	 * フォーカス制御チェック
	 * @returns trueの場合、フォーカス制御を行う
	 */
	private checkFocus() {
		if (this.getPropsType() !== 'date' && this.getPropsType() !== 'month') {
			if (this.props.converter || this.getPropsType() === 'number')
				return true;
			if (this.controlInfo && this.controlInfo.config.converter)
				return true;
		}
		return false;
	}

	/**
	 * オプション情報取得
	 * @returns オプション情報
	 */
	private getOptions(): any {
		const rtn: any = {};
		if (this.state.hissu)
			rtn['required'] = true;
		//		if (this.controlInfo.description)
//			rtn['label'] = this.controlInfo.description;
		if (this.props.variant)
			rtn['variant'] = this.props.variant;
		else
			rtn['variant'] = 'outlined';
		rtn['size'] = 'small';
		rtn['margin'] = 'dense';
		let type = this.getPropsType();
		if (this.getPropsType() !== 'date' && this.getPropsType() !== 'month') {
			if (this.getPropsType() === 'number') {
				if (this.state.readOnly === true || this.state.disabled === true)
					type = null;
				if (this.state.focus == false)
					type = null;
			}
		}
		else if (this.state.readOnly === true || this.state.disabled === true) {
			type = null;
		}
		/*
		if ((this.getPropsType() !== 'date' && this.getPropsType() !== 'month') || this.state.readOnly === false) {
			if (this.getPropsType() !== 'number' || (this.state.readOnly === false && this.state.disabled === false))
				type = this.getPropsType();
		}
		else if (this.getPropsType() === 'date' || this.getPropsType() !== 'month') {
			type = this.getPropsType();
		}
		if (type != null && type === 'number' && this.state.focus == false)
			type = null;
		*/
		if (type != null)
			rtn['type'] = type;
		return rtn;
	}

	/**
	 * autoComplete情報取得
	 * @returns autoComplete情報
	 */
	private getAutoComplete() {
		const rtn: any = {};
		if (this.props.autoComplete)
			rtn['autoComplete'] = this.props.autoComplete;
		else
			rtn['autoComplete'] = 'on';
		return rtn;
	}

	/**
	 * 揃え位置設定
	 * @param obj オブジェクト
	 */
	private setAlign(obj: any): void {
		if (this.props.align)
			obj['style'] = {textAlign: this.props.align};
	}
	// --------------------------------------------------------------------------
}