import { WprListData } from '../../../../wpr-framework/view/deco/WprListData';
import { WprDI_ScopeModel } from '../../../../wpr-framework/view/di/WprDI_ScopeModel';
import { DateUtil } from '../../../common/util/DateUtil';
import { ConstrDateListModel } from '../../../models/mypage/Construction/ConstrDateListModel';
import { MypageBaseView } from '../../base/MypageBaseView';
import { ProgressBarData } from '../../common/progress_bar/ProgressBarView';
import { MypageWizardMngr } from '../../wizard/models/MypageWizardMngr';
import { ConstructionService } from '../../../service/ConstructionService';
import { GetMypWorkScheduleListRequest } from '../../../service/models/dto/mypagerenewal/GetMypWorkScheduleListRequest';
import { WorkDetailInfo } from '../../../models/iss/getMypWorkScheduleList/WorkDetailInfo';
import { sub } from 'date-fns';
import { WorkScheduleList } from '../../../models/iss/getMypWorkScheduleList/WorkScheduleList';
import { GetMypWorkScheduleListResponse } from '../../../service/models/dto/mypagerenewal/GetMypWorkScheduleListResponse';
import { ConstrBaseModel } from '../../../models/mypage/Construction/ConstrBaseModel';
import { ConstructionWizardModel } from '../../wizard/ConstructionWizardFlow';
import { ConstrFlowModel } from '../../../models/mypage/Construction/ConstrFlowModel';

/**
 * 工事予定日一覧
 */
export class ConstrListView extends MypageBaseView {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() { super('ConstrListView'); }
	// --------------------------------------------------------------------------

	// モデル  ------------------------------------------------------------------
	@WprDI_ScopeModel('MypageWizardMngr')
	private m_MypageWizardMngr: MypageWizardMngr			= null;		// 画面ウィザード管理クラス
	@WprListData('ConstrAddListModel')
	private m_ConstrAddListModel: ConstrDateListModel[]	= null;	// 工事予約追加可能リストモデル
	@WprListData('ConstrEditListModel')
	private m_ConstrEditListModel: ConstrDateListModel[]	= null;	// 工事予約変更可能リストモデル
	@WprListData('ConstrDecisionListModel')
	private m_ConstrDecisionListModel: ConstrDateListModel[]	= null;	// 工事予約変更不可リストモデル
	// --------------------------------------------------------------------------
	// private変数 --------------------------------------------------------------
	private m_ConstrBaseModel: ConstrBaseModel	= null;	// 工事予約変更不可リストモデル
	private m_FlowModel: ConstrFlowModel	= null;	// 工事予約変更不可リストモデル
	// --------------------------------------------------------------------------

	// サービス  ----------------------------------------------------------------
	private m_ConstructionService: ConstructionService	= new ConstructionService();	// 工事日サービス
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * コントロール定義設定
	 */
	public onRegisterConfig(): void {
		this.addListConfig('constrTypeA', '工事区分 ( テレビ、ネット等 )', 'ConstrAddListModel', { bindName: 'type' });
		this.addListConfig('constrTypeE', '工事区分 ( テレビ、ネット等 )', 'ConstrEditListModel', { bindName: 'type' });
		this.addListConfig('dateE', '日付', 'ConstrEditListModel.workScheduleList', { bindName: 'schedule_date', converter: '日付（yyyy/MM/dd）' });
		this.addListConfig('dayOfWeekE', '曜日', 'ConstrEditListModel', { bindName: 'dayOfWeek' });
		this.addListConfig('timeE', '開始予定時間', 'ConstrEditListModel', { bindName: 'preferredTime' });
		this.addListConfig('constrTypeD', '工事区分 ( テレビ、ネット等 )', 'ConstrDecisionListModel', { bindName: 'type' });
		this.addListConfig('dateD', '日付', 'ConstrDecisionListModel.workScheduleList', { bindName: 'schedule_date', converter: '日付（yyyy/MM/dd）' });
		this.addListConfig('dayOfWeekD', '曜日', 'ConstrDecisionListModel', { bindName: 'dayOfWeek' });
		this.addListConfig('timeD', '開始予定時間', 'ConstrDecisionListModel', { bindName: 'preferredTime' });
	}

	/**
	 * アクション登録
	 */
	public onRegisterAction(): void {
		this.addListAction('nextNew', 'ConstrAddListModel', this.onNextNew);
		this.addListAction('nextChange', 'ConstrEditListModel', this.onNextChange);
		this.addListAction('cancel', 'ConstrEditListModel', this.onCancel);
		this.addAction('log', this.onLog);
	}

	/**
	 * ビュー表示通知
	 */
	public onShowView(): void {
		this.m_MypageWizardMngr.initView(this, (result) => {
			this.registAccessLog();
			this.setStorageItem('b_url', window.location.href);
			const sendData: ProgressBarData = new ProgressBarData();
			sendData.progressBarList = this.m_MypageWizardMngr.getProgressModelList(this);
			this.sendChildData('fP_ev_progressBarIntital', sendData);
			this.m_ConstrAddListModel = this.m_MypageWizardMngr.getCache().m_ConstrAddListModel;
			this.m_ConstrEditListModel = this.m_MypageWizardMngr.getCache().m_ConstrEditListModel;
			this.m_ConstrDecisionListModel = this.m_MypageWizardMngr.getCache().m_ConstrDecisionListModel;
			this.m_ConstrBaseModel = this.m_MypageWizardMngr.getCache().m_ConstrBaseModel;
			this.m_FlowModel = this.m_MypageWizardMngr.getCache().m_FlowModel;
			this.m_MypageWizardMngr.getCache().m_ConstrAddListModel = new Array();
			this.m_MypageWizardMngr.getCache().m_ConstrEditListModel = new Array();
			this.m_MypageWizardMngr.getCache().m_ConstrDecisionListModel = new Array();
			const req: GetMypWorkScheduleListRequest = new GetMypWorkScheduleListRequest();
			req.customer_id = this.getCustomerId();
			req.application_id = '1';
			this.m_ConstructionService.getWorkScheduleList(this, req, (result) => {
				if (result) {
					if (result.work_schedule_list && result.work_schedule_list.length > 0) {
						const today = DateUtil.getTodayStr();
						const date: string = DateUtil.getPlusDate(today, 6);
						for (const schedule of result.work_schedule_list) {
							const model = new ConstrDateListModel();
							model.workScheduleList = schedule;
							model.type = '';
							if (!model.workScheduleList.preferred_time_cd) {
								if (Number(model.workScheduleList.schedule_start_time.substring(0, 2)) < 11) 
									model.workScheduleList.preferred_time_cd = '01';
								else if (Number(model.workScheduleList.schedule_start_time.substring(0, 2)) < 14)
									model.workScheduleList.preferred_time_cd = '02';
								else
									model.workScheduleList.preferred_time_cd = '03';
							}
							model.preferredTime = this.getValueMap('工事時間帯').getValueFormKey(model.workScheduleList.preferred_time_cd);
							const map = new Map<string, WorkDetailInfo[]>();
							for (const data of schedule.work_detail_info) {
								if (!map.has(data.mypage_nm))
									map.set(data.mypage_nm, []);
								map.get(data.mypage_nm).push(data);
							}
							for (const key of map.keys()) {
								if (key)
									model.type += '【' + key + '】';
							}
							if (!model.type)
								model.type = '【その他】';
							if (schedule.schedule_date)
								model.dayOfWeek = this.getDayOfWeek(schedule.schedule_date);
							if (schedule.status === '1' && schedule.schedule_status === '01' && DateUtil.isAfter(new Date(DateUtil.convertToDateWithSlash(date)), new Date(DateUtil.convertToDateWithSlash(schedule.schedule_date))))
								this.m_ConstrEditListModel.push(model);
							else if (schedule.status === '1' && (schedule.schedule_status === '01' || schedule.schedule_status === '02')) 
								this.m_ConstrDecisionListModel.push(model);
							else if (schedule.status === '9' || schedule.status === '10' || schedule.status === '11')
								this.m_ConstrAddListModel.push(model);	
						}
						this.setViewMode('表示内容', 'あり');
						if (this.m_ConstrAddListModel.length > 0)
							this.setViewMode('追加', '表示');
						if (this.m_ConstrEditListModel.length > 0)
							this.setViewMode('変更', '表示');
						if (this.m_ConstrDecisionListModel.length > 0)
							this.setViewMode('確定', '表示');
					}
					else
						this.setViewMode('表示内容', 'なし');
					this.setViewData('sentenceData', this.getOldUrl() + 'Inquiry/Inquiry_entry/');
					this.setListData('ConstrAddListModel', this.m_ConstrAddListModel);
					this.setListData('ConstrEditListModel', this.m_ConstrEditListModel);
					this.setListData('ConstrDecisionListModel', this.m_ConstrDecisionListModel);
					//未設定が1つかつ、パラメータが存在した場合
					const scheduleId = this.m_MypageWizardMngr.getParams().workScheduleId;
					if (this.m_ConstrAddListModel.length === 1 && scheduleId) {
						const target = this.m_ConstrAddListModel.filter(item => item.workScheduleList.work_schedule_id === scheduleId);
						if (target.length > 0) {
							this.m_ConstrBaseModel.workScheduleList = target[0];
							this.m_FlowModel.defaultFlg = true;
							this.m_FlowModel.changeFlg = false;
							this.m_FlowModel.cancelFlg = false;
							this.m_FlowModel.logFlg = false;
							this.m_MypageWizardMngr.getParams().workScheduleId = null;
							this.m_MypageWizardMngr.goNext(this);
						}
					}

				}
			});
			this.refreshView();
		});
	}
	// --------------------------------------------------------------------------

	// アクション  ---------------------------------------------------------------
	/**
	 * 予定日未設定の方はこちら
	 */
	public onNextNew(param: any, item: any): void {
		this.m_ConstrBaseModel.workScheduleList = item;
		this.m_FlowModel.defaultFlg = true;
		this.m_FlowModel.changeFlg = false;
		this.m_FlowModel.cancelFlg = false;
		this.m_FlowModel.logFlg = false;
		this.m_MypageWizardMngr.goNext(this);
	}

	/**
	 * 予定日変更希望の方はこちら
	 */
	public onNextChange(param: any, item: any): void {
		this.m_ConstrBaseModel.workScheduleList = item;
		this.m_FlowModel.defaultFlg = false;
		this.m_FlowModel.changeFlg = true;
		this.m_FlowModel.cancelFlg = false;
		this.m_FlowModel.logFlg = false;
		this.m_MypageWizardMngr.goNext(this);
	}

	/**
	 * お申込みキャンセル
	 */
	public onCancel(param: any, item: any): void {
		this.m_ConstrBaseModel.workScheduleList = item;
		this.m_FlowModel.defaultFlg = false;
		this.m_FlowModel.changeFlg = false;
		this.m_FlowModel.cancelFlg = true;
		this.m_FlowModel.logFlg = false;
		this.m_MypageWizardMngr.goNext(this);
	}

	/**
	 * 工事予定日履歴 
	 */
	public onLog(param: any): void {
		this.m_FlowModel.defaultFlg = false;
		this.m_FlowModel.changeFlg = false;
		this.m_FlowModel.cancelFlg = false;
		this.m_FlowModel.logFlg = true;
		this.m_MypageWizardMngr.goNext(this);
	}
	// --------------------------------------------------------------------------
	// privateメソッド -----------------------------------------------------------

	/**
	 * 曜日取得
	 */
	public getDayOfWeek(date: string): string {
		let dayOfWeekStrJP = [ "日", "月", "火", "水", "木", "金", "土" ] ;
		return dayOfWeekStrJP[new Date(DateUtil.convertToDateWithSlash(date)).getDay()];
	}
	// --------------------------------------------------------------------------
}
