import { WprBaseViewInfo } from '../WprBaseViewInfo';
import { WprTabChildViewInfo } from './WprTabChildViewInfo';
import { WprTabReceiveDataInfo } from './WprTabReceiveDataInfo';

/**
 * タブ子ビュー情報マップ情報
 */
export class WprTabChildViewMapInfo {
	// private 変数  ------------------------------------------------------------
	private m_ViewInfo: WprBaseViewInfo								= null;										// ビュー情報
	private m_IsFirstDisp: boolean									= true;										// 初回表示フラグ
	private m_TabChildViewMap: Map<string, WprTabChildViewInfo>		= new Map<string, WprTabChildViewInfo>();	// タブ子ビュー情報マップ
	private m_ReceiveDataList: WprTabReceiveDataInfo[]				= new Array();								// 受信データリスト
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	public constructor(view: WprBaseViewInfo) {
		this.m_ViewInfo = view;
	}
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** 初回表示フラグ */
	public get isFirstDisp(): boolean 	{ return this.m_IsFirstDisp; }	set isFirstDisp(isFirst: boolean) { this.m_IsFirstDisp = isFirst; }
	// --------------------------------------------------------------------------

	// public メソッド  ---------------------------------------------------------
	/**
	 * タブ変更チェック
	 * @param name タブ表示制御子ビュー名
	 * @param mode タブモード
	 * @param label タブラベル
	 * @returns trueの場合、変更できる
	 */
	public checkChangeTab(view: WprBaseViewInfo, mode: string, label: string): boolean {
		if (this.m_TabChildViewMap.has(mode) === false)
			this.m_TabChildViewMap.set(mode, new WprTabChildViewInfo(mode, view));
		return this.m_TabChildViewMap.get(mode).checkChangeTab(label);
	}

	/**
	 * タブ変更処理
	 * @param name タブ表示制御子ビュー名
	 * @param mode タブモード
	 * @param label タブラベル
	 */
	public changeTab(view: WprBaseViewInfo, mode: string, label: string): void {
		if (this.m_TabChildViewMap.has(mode) === false)
			this.m_TabChildViewMap.set(mode, new WprTabChildViewInfo(mode, view));
		this.m_TabChildViewMap.get(mode).changeTab(label);
	}

	/**
	 * タブ表示制御子ビュー名追加
	 * @param vname タブ表示制御子ビュー名
	 * @param name 名称
	 * @param mode タブモード
	 * @param label タブラベル
	 */
	 public addChildView(view: WprBaseViewInfo, vname: string, name: string, mode: string, label: string): void {
		if (this.m_TabChildViewMap.has(mode) === false)
			this.m_TabChildViewMap.set(mode, new WprTabChildViewInfo(mode, view));
		this.m_TabChildViewMap.get(mode).addView(label, vname, name);
	}

	/**
	 * タブ子ビュー設定処理
	 * @param child 子ビュー情報
	 * @returns trueの場合、設定
	 */
	public setTabChildView(child: WprBaseViewInfo): boolean {
		for (const tcInfo of this.m_TabChildViewMap.values()) {
			if (tcInfo.setChildViewInfo(child.name, child) === true)
				return true;
		}
		return false;
	}

	/**
	 * タブ子ビューチェック
	 * @param view ビュー情報
	 * @returns falseの場合、タブの子ビューは、非表示
	 */
	public isShowTabChildView(view: WprBaseViewInfo): boolean {
		for (const tcInfo of this.m_TabChildViewMap.values()) {
			if (tcInfo.isShowChildView(view) === false)
				return false;
			if (tcInfo.isChildView(view) === true)
				return true;
		}
		return false;
	}

	/**
	 * データ受信処理
	 * @param dataName データ名 
	 * @param data データ
	 */
	public receiveData(dataName: string, data: any): void {
		this.m_ViewInfo.addInfoLog(`== Wait send Data == [${dataName}]`);
		this.m_ReceiveDataList.push(new WprTabReceiveDataInfo(dataName, data));
	}

	/**
	 * データ送信処理
	 */
	public sendData(): void {
		if (this.m_ReceiveDataList.length > 0) {
			this.m_ReceiveDataList.forEach(data => {
				this.m_ViewInfo.addInfoLog(`== send wait Data == [${data.dataName}]`);
				this.m_ViewInfo.receiveData(data.dataName, data.data);
			});
			this.m_ReceiveDataList = new Array();
		}
	}
	// --------------------------------------------------------------------------
}
