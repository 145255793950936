import { Card, Divider, TableCell, Typography } from '@material-ui/core';
import { Grid } from '@mui/material';
import React, { ReactNode } from 'react';
import { WprBaseViewComponent } from '../../../../../wpr-framework/view/component/WprBaseViewComponent';
import { MenuBar } from '../../../common/menu_bar/MenuBar';
import { MvnoEntryTotalFeeView } from './MvnoEntryTotalFeeView';
import styles from './MvnoEntryTotalFee.scss';
import { MatButton } from '../../../../../wpr-framework/component/controls/material/MatButton';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { MatOneRowTable } from '../../../../../wpr-framework/component/controls/material/MatOneRowTable';
import { MatTextField } from '../../../../../wpr-framework/component/controls/material/MatTextField';
import { WprAnchor } from '../../../../../wpr-framework/component/controls/WprAnchor';
import { WprRowInfo } from '../../../../../wpr-framework/component/props/WprRowInfo';
import { MatTypography } from '../../../../../wpr-framework/component/controls/material/MatTypography';
import { PriceModel } from '../../../../service/models/apimodels/PriceModel';
import { WprContainer } from '../../../../../wpr-framework/component/controls/WprContainer';
import { ProgressBar } from '../../../common/progress_bar/ProgressBar';
import { MvnoEntryFooter } from '../mvno_entry_footer/MvnoEntryFooter';

/**
 * MVNO申込_合計金額確認用コンポーネント
 */
export class MvnoEntryTotalFee extends WprBaseViewComponent {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: any) { super(new MvnoEntryTotalFeeView(), props); }
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** MVNO申込_合計金額確認ビュー情報 */
	public get viewInfo(): MvnoEntryTotalFeeView { return this.view as MvnoEntryTotalFeeView; }
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * スタイルオブジェクト取得
	*/
	public getStylesObject(): any { return styles; }

	/**
	 * リストコントロール定義登録
	*/
	protected onRegisterListRender(): void {
		// this.addListRender('PriceList', '1', this.onPriceListRender);
		// this.addListRender('PriceList', '2', this.onPriceListRender);
		// this.addListRender('PriceList', '4', this.onPriceListRender);
		// this.addListRender('PriceList', '5', this.onPriceListRender);
		// this.addListRender('PriceList', '6', this.onPriceListRender);
		// this.addListRender('PriceList', '7', this.onPriceListRender);
		// this.addListRender('PriceList', '8', this.onPriceListRender);
		// this.addListRender('PriceList', '9', this.onPriceListRender);
		// this.addListRender('PriceList', '11', this.onPriceListRender);
		// this.addListRender('PriceList', '12', this.onPriceListRender);
		// this.addListRender('PriceList', '15', this.onPriceListRender);
		// this.addListRender('PriceList', '割引', this.onPriceListRender);
		// this.addListRender('PriceList', null, this.onPriceListRender);
	}

	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		return (
			<>
				{/* Milosから追加 START */}



				{/* Milosから追加 END */}
				<div>
					<div className='pageCard' id='mvnoEntryTotalFeeID'>
						<div className="pageHeader stepFlow">
							<ProgressBar name='progressBar' view={this.view} />
						</div>
						<div className='pageContent'>
							<div className='link_blue'>
								<WprAnchor name='helpLink' view={this.view}>申込みについてご不明な方はこちら＞</WprAnchor>
							</div>

							<div className='pageTitle'>
								<div className='title_normal'>
									<Typography className='title_text'>合計金額のご確認</Typography>
									<div className='title_memo'>
										選択されたプランと現在契約しているサービスの合計金額をご確認ください。
									</div>
								</div>
							</div>
							<div>
								<WprContainer mode='キャンペーンコード.有' view={this.view}>
									<div className='campaignArea'>
										<span className='campaignTitle' style={{ color: '#484848', fontWeight:'700'}}>適用キャンペーン</span><br/>
										<MatTypography name='campaignName' style={{ color: '484848'}} view={this.view} component='span' className='campaignText'/>
									</div>
								</WprContainer>
								{/* 合計金額内容 */}
								<div className='feeTable'>
									{/* <div className='feeRow'>
									
									<div className='feeVal'>
										
									</div>
								</div> */}

									<Grid container className='feeRow'>
										<Grid item xs={12} md={3} className='feeTitle'>
											<div>現在の月額利用料の合計</div>
										</Grid>
										<Grid item xs={12} md={9} className='feeVal' style={{ color: 'black' }}>
											<MatTypography name='totalBefore' style={{ color: 'black' }} view={this.view} component='span' /><span className='itemUnit' style={{ color: '#484848' }}>円</span>
										</Grid>
									</Grid>
									<Grid container className='feeRow'>
										<Grid item xs={12} md={3} className='feeTitle'>
											<div>新しい月額利用料の合計</div>
										</Grid>
										<Grid item xs={12} md={9} className='feeVal'>
											<MatTypography name='total' view={this.view} component='span' /><span className='itemUnit'>円</span>
										</Grid>

									</Grid>

									<Grid container className='feeRow'>
										<Grid item xs={12} md={3} className='feeTitle'>
											<div>初期費用</div>
										</Grid>


										<Grid item xs={12} md={9} className='feeVal'>
											<WprContainer mode='プラン.音声プラン' view={this.view}>
												<WprContainer mode='端末申込.有' view={this.view}>
													<Grid container>
														<Grid item md={4} xs={12}>
															<div className='itemTitle'>SIMオプション</div>
														</Grid>
														<Grid item md={5} xs={12}>
															<MatTypography name='simOption' view={this.view} component='span' />
														</Grid>
														<Grid item md={2} xs={10} className='gridItemPrice'>
															<WprContainer mode='SIMオプション.有' view={this.view}>
																<MatTypography name='simOptionPrice' view={this.view} component='span' />
																<span className='itemUnit'>円</span>
															</WprContainer>
														</Grid>
														<Grid item md={1} xs={2} textAlign='right'>
															<WprAnchor name='editPlan3' view={this.view} className='link_orange' >変更</WprAnchor>
														</Grid>
													</Grid>
												</WprContainer>
											</WprContainer>

											<Grid container>
												<Grid item md={4} xs={12}>
													<div className='itemTitle'>5G通信利用</div>
												</Grid>
												<Grid item md={5} xs={12}>
													<MatTypography name='fivegUseFlg' view={this.view} component='span' />
												</Grid>
												<Grid item md={2} xs={10} >

												</Grid>
												<Grid item md={1} xs={2} textAlign='right'>
													<WprAnchor name='editPlan2' view={this.view} className='link_orange' >変更</WprAnchor>
												</Grid>
											</Grid>
											<WprContainer mode='プラン.音声プラン' view={this.view}>
												{/* <WprContainer mode='端末申込.有' view={this.view}> */}
												<Grid container>
													<Grid item md={4} xs={12}>
														<div className='itemTitle'>初期設定サポート</div>
													</Grid>
													<Grid item md={5} xs={12}>
														<MatTypography name='initialSupportFlg' view={this.view} component='span' />
													</Grid>
													<Grid item md={2} xs={10} >

													</Grid>
													<Grid item md={1} xs={2} textAlign='right'>
														<WprAnchor name='editPlan3' view={this.view} className='link_orange' >変更</WprAnchor>
													</Grid>
												</Grid>
												{/* </WprContainer> */}
											</WprContainer>
											<Divider style={{ margin: '0.5rem 0' }} />

											<Grid container>
												<Grid item md={9} xs={12}>
													<div className='itemTitle'>契約事務手数料</div>
												</Grid>
												<Grid item md={2} xs={10} className='gridItemPrice'>
													<MatTypography name='initialCostItemPrice' view={this.view} component='span' /><span className='itemUnit'>円</span>
												</Grid>
												<Grid item md={1} xs={2} textAlign='right'>

												</Grid>
											</Grid>
											{/* <WprContainer mode='キャンペーンコード.有' view={this.view}> */}
											<WprContainer mode='初期費用キャンペーン.有' view={this.view}>
												<Grid container>
													<Grid item md={9} xs={12}>
														<div className='itemTitle'>割引</div>
													</Grid>
													<Grid item md={2} xs={10} className='gridItemPrice'>
														-<MatTypography name='campaignDiscount' view={this.view} component='span' /><span className='itemUnit'>円</span>
													</Grid>
													<Grid item md={1} xs={2} textAlign='right'>

													</Grid>
												</Grid>
											</WprContainer>
										</Grid>
									</Grid>

								</div>
							</div>
							<div>
								<div className='itemTitle title_maru'>
									基本情報
								</div>
								<div className='feeTable'>
									<Grid container className='feeRow'>
										<Grid item xs={12} md={3} className='feeTitle'>
											<div>プラン</div>
										</Grid>
										<Grid item xs={12} md={9} className='feeVal' style={{ color: 'black' }}>
											<Grid container>
												<Grid item xs={12} md={9}>
													<MatTypography name='plan' view={this.view} />
												</Grid>
												<Grid item xs={10} md={2} className='gridItemPrice'></Grid>
												<Grid item xs={2} md={1} textAlign='right'>
													<WprAnchor name='editPlan1' view={this.view} className='link_orange'>変更</WprAnchor>
												</Grid>
											</Grid>
										</Grid>
									</Grid>

									<WprContainer mode='プラン.音声プラン' view={this.view}>
										<Grid container className='feeRow'>
											<Grid item xs={12} md={3} className='feeTitle'>
												<div>音声プランコース</div>
											</Grid>
											<Grid item xs={12} md={9} className='feeVal' style={{ color: 'black' }}>
												<Grid container>
													<Grid item xs={12} md={9}>
														<MatTypography name='course' view={this.view} />
													</Grid>
													<Grid item xs={10} md={2} className='gridItemPrice'>
														<WprContainer mode='コース.かけ放題' view={this.view}>
															<MatTypography name='coursePrice' view={this.view} component='span' /><span className='itemUnit'>円</span>
														</WprContainer>
													</Grid>
													<Grid item xs={2} md={1} textAlign='right'>
														<WprAnchor name='editPlan2' view={this.view} className='link_orange' >変更</WprAnchor>
													</Grid>
												</Grid>
											</Grid>
										</Grid>

										<Grid container className='feeRow'>
											<Grid item xs={12} md={3} className='feeTitle'>
												<div>留守番電話オプション</div>
											</Grid>
											<Grid item xs={12} md={9} className='feeVal' style={{ color: 'black' }}>
												<Grid container>
													<Grid item xs={12} md={9}>
														<MatTypography name='rusubanUseFlg' view={this.view} component='span' />
													</Grid>
													<Grid item xs={10} md={2} className='gridItemPrice'>
														{
															(this.view as MvnoEntryTotalFeeView).getEntry().rusubanPrice != null &&
															<>
																<MatTypography name='rusubanPrice' view={this.view} component='span' /><span className='itemUnit'>円</span>
															</>
														}
													</Grid>
													<Grid item xs={2} md={1} textAlign='right'>
														<WprAnchor name='editPlan2' view={this.view} className='link_orange' >変更</WprAnchor>
													</Grid>
												</Grid>

											</Grid>
										</Grid>
									</WprContainer>

									<WprContainer mode='端末申込.有' view={this.view}>
										<Grid container className='feeRow'>
											<Grid item xs={12} md={3} className='feeTitle'>
												<div>端末</div>
											</Grid>
											<Grid item xs={12} md={9} className='feeVal' style={{ color: 'black' }}>
												<Grid container>
													<Grid item xs={12} md={9}>
														<MatTypography name='terminal' view={this.view} component='span' />
														{
															(this.view as MvnoEntryTotalFeeView).getEntry().entryInfo.terminalColor != null &&
															<>
																（<MatTypography name='terminalColor' view={this.view} component='span' />）
															</>
														}
													</Grid>
													<Grid item xs={10} md={2} className='gridItemPrice'>
														{
															(this.view as MvnoEntryTotalFeeView).getEntry().terminalPrice != null &&
															<>
																<MatTypography name='terminalPrice' view={this.view} component='span' /><span className='itemUnit'>円</span>
															</>
														}

													</Grid>
													<Grid item xs={2} md={1} textAlign='right'>
														<WprAnchor name='editPlan3' view={this.view} className='link_orange' >変更</WprAnchor>
													</Grid>
												</Grid>


											</Grid>
										</Grid>


										<Grid container className='feeRow'>
											<Grid item xs={12} md={3} className='feeTitle'>
												<div>端末延長保証</div>
											</Grid>
											<Grid item xs={12} md={9} className='feeVal' style={{ color: 'black' }}>
												<Grid container>
													<Grid item xs={12} md={9}>
														<MatTypography name='terminalGuaranteeFlg' view={this.view} component='span' />
													</Grid>
													<Grid item xs={10} md={2} className='gridItemPrice'>
														{
															(this.view as MvnoEntryTotalFeeView).getEntry().terminalGuaranteePrice != null &&
															<>
																<MatTypography name='terminalGuaranteePrice' view={this.view} component='span' /><span className='itemUnit'>円</span>
															</>

														}
													</Grid>
													<Grid item xs={2} md={1} textAlign='right'>
														<WprAnchor name='editPlan3' view={this.view} className='link_orange' >変更</WprAnchor>
													</Grid>
												</Grid>
											</Grid>
										</Grid>
									</WprContainer>

									<Grid container className='feeRow'>
										<Grid item xs={12} md={3} className='feeTitle'>
											<div>データ容量</div>
										</Grid>
										<Grid item xs={12} md={9} className='feeVal' style={{ color: 'black' }}>
											<Grid container>
												<Grid item xs={12} md={9}>
													<MatTypography name='capacity' view={this.view} component='span' />
												</Grid>
												<Grid item xs={10} md={2} className='gridItemPrice'>
													<MatTypography name='capacityPrice' view={this.view} component='span' /><span className='itemUnit'>円</span>
												</Grid>
												<Grid item xs={2} md={1} textAlign='right'>
													<WprAnchor name='editPlan2' view={this.view} className='link_orange' >変更</WprAnchor>
												</Grid>
											</Grid>
										</Grid>
									</Grid>

									<Grid container className='feeRow'>
										<Grid item xs={12} md={3} className='feeTitle'>
											<div>SIM</div>
										</Grid>
										<Grid item xs={12} md={9} className='feeVal' style={{ color: 'black' }}>
											<Grid container>
												<Grid item xs={12} md={9}>
													<MatTypography name='simSize' view={this.view} component='span' />
												</Grid>
												<Grid item xs={10} md={2}>
												</Grid>
												<Grid item xs={2} md={1} textAlign='right'>
													<WprAnchor name='editPlan3' view={this.view} className='link_orange' >変更</WprAnchor>
												</Grid>
											</Grid>

										</Grid>
									</Grid>

									<WprContainer mode='プラン.音声プラン' view={this.view}>
										<Grid container className='feeRow'>
											<Grid item xs={12} md={3} className='feeTitle'>
												<div>Webフィルタリング</div>
											</Grid>
											<Grid item xs={12} md={9} className='feeVal' style={{ color: 'black' }}>
												<Grid container>
													<Grid item xs={12} md={9}>
														<MatTypography name='webFilteringFlg' view={this.view} component='span' />
													</Grid>
													<Grid item xs={10} md={2} className='gridItemPrice'>
														{
															(this.view as MvnoEntryTotalFeeView).getEntry().webFilteringPrice != null &&
															<>
																<MatTypography name='webFilteringPrice' view={this.view} component='span' /><span className='itemUnit'>円</span>
															</>
														}
													</Grid>
													<Grid item xs={2} md={1} textAlign='right'>
														<WprAnchor name='editPlan3' view={this.view} className='link_orange' >変更</WprAnchor>
													</Grid>
												</Grid>

											</Grid>
										</Grid>
									</WprContainer>



								</div>
							</div>
						</div>
						<Divider />
						<div className='pageFooter'>
							<div>
								<MatButton name='next' view={this.view} className='nextButton' >
									次へ
									<NavigateNextIcon />
								</MatButton>

							</div>
							<div>
								<MatButton name='back' view={this.view} className='backButton' >
									<NavigateBeforeIcon />
									戻る
								</MatButton>

							</div>
						</div>
					</div>
				</div>
				<MvnoEntryFooter name='mvnoFooter' view={this.view} />
			</>
		);
	}
	// --------------------------------------------------------------------------

	// リスト描画  ---------------------------------------------------------------

	// --------------------------------------------------------------------------
}
