import { WprReflectUtil } from '../util/WprReflectUtil';
import { WprBaseDIManage } from '../view/di/WprBaseDIManage';

/**
 * スコープモデルDI情報
 */
export class WprScopeModelDIInfo {
	// private 変数  ------------------------------------------------------------
	private m_Object: WprBaseDIManage	= null;		// オブジェクト
	private m_Name: string				= null;		// モデル名
	private m_IsGlobal: boolean			= false;	// グローバルフラグ
	private m_PropName: string			= null;		// プロパティ名
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** モデル名 */
	public get name(): string				{ return this.m_Name;		}
	/** グローバルフラグ */
	public get isGlobal(): boolean			{ return this.m_IsGlobal;	}
	/** オブジェクト */
	public get object(): WprBaseDIManage	{ return this.m_Object;		}
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	public constructor(obj: WprBaseDIManage, name: string, propName: string, isGlobal: boolean) {
		this.m_Object = obj;
		this.m_Name = name;
		this.m_IsGlobal = isGlobal;
		this.m_PropName = propName;
	}
	// --------------------------------------------------------------------------

	// public メソッド  ---------------------------------------------------------
	/**
	 * モデル設定処理
	 * @param model モデル
	 */
	public setModel(model: any): void {
		if (this.m_PropName != null) {
			WprReflectUtil.setProperty(this.m_Object, this.m_PropName, model);
			this.m_Object.onSetScopeModel(this.m_Name, model);
		}
	}

	/**
	 * モデル値チェック処理
	 * @param model モデル
	 * @param prop プロパティ名
	 */
	public checkModelValue(model: any, prop?: string): void {
		this.m_Object.checkModelValue(this.m_Name, model, this.m_IsGlobal, prop);
	}
	// --------------------------------------------------------------------------
}
