import { WprBaseDialogViewInfo } from '../../../../../wpr-framework/view/dialog/WprBaseDialogViewInfo';

export class CompleteDialogSendData {
	url: string;	// URL
}

export class CompleteDialogResultData {
}

/**
 * 完了ダイアログ
 */
export class CompleteDialogView extends WprBaseDialogViewInfo {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() { super('CompleteDialog'); }
	// --------------------------------------------------------------------------

	// override プロパティ  ------------------------------------------------------
	/** ダイアログタイトル */
	public get title(): string { return '契約確認完了'; }
	/** バリデーション */
	public get validation(): boolean { return false; }
	// --------------------------------------------------------------------------

	// virtual プロパティ  ------------------------------------------------------
	/** OKボタン使用フラグ */
	public get useOKButton(): boolean 		{ return null; 		}
	/** ×ボタン表示フラグ */
	public get dispCloseButton(): boolean 		{ return false; 		}
	// --------------------------------------------------------------------------

	private url: string = null;

	// override メソッド  --------------------------------------------------------
	/**
	 * コントロール定義設定
	 */
	public onRegisterConfig(): void {
	}

	/**
	 * アクション登録
	 */
	public onRegisterAction(): void {
		this.addAction('b_', this.onB_);
	}

	/**
	 * ダイアログ表示通知
	 * @param sendData 送信データ
	 */
	public onShowDialog(sendData: CompleteDialogSendData) {
		this.url = sendData.url;
		this.refreshView();
	}
	// --------------------------------------------------------------------------

	// アクション  ---------------------------------------------------------------
	/**
	 * ボタン
	 */
	public onB_(param: any): void {
		const resultData: CompleteDialogResultData = new CompleteDialogResultData();
		// location.href = "https://119.224.199.206/Cust_mod/Paymethod_disp/";
		location.href = this.url + "Cust_mod/Paymethod_disp/";
		this.close(true,resultData);
	}
	// --------------------------------------------------------------------------
}
