import { MGeneral } from '../service/models/entity/MGeneral';

/**
 * 汎用マスタチェック情報
 */
export class MGeneralCheckInfo {
	// public 変数	-----------------------------------------------------------
	public mGeneral: MGeneral;	// 汎用マスタ
	public checked: boolean;	// チェックかどうか
	// ------------------------------------------------------------------------
}
