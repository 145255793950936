import { WprViewMngr } from '../WprViewMngr';
import { WprBaseValidator } from './WprBaseValidator';

/**
 * バリデータ設定
 */
export class WprValidatorSetter {
	// private 変数  ------------------------------------------------------------
	private m_ViewMngr: WprViewMngr		= null;			// ビュー管理クラス
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	public constructor(viewMngr: WprViewMngr) {
		this.m_ViewMngr = viewMngr;
	}
	// --------------------------------------------------------------------------

	// public メソッド	----------------------------------------------------------
	/**
	 * バリデータ登録
	 * @param validator バリデータ
	 */
	public addValidator(validator: WprBaseValidator): void {
		validator.isCustom = true;
		this.m_ViewMngr.addValidator(validator);
	}

	/**
	 * カスタムメッセージ設定(コントロールの説明を含める場合は「%desc%」を使用する)
	 * @param name バリデータ名
	 * @param message カスタムメッセージ
	 */
	public setCustomMessage(name: string, message: string): void {
		this.m_ViewMngr.setCustomMessage(name, message);
	}
	// --------------------------------------------------------------------------
}
