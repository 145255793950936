import { NameChangeInfo } from "../../apimodels/NameChangeInfo";

/**
 * 名義変更登録リクエスト
 */
 export class RegistNameChangeRequest {
	// public 変数	-----------------------------------------------------------
	public customerId: string;				// 顧客ID
	public oldName: string;					// 旧名義人名
	public oldPhone: string;				// 旧名義人電話番号
	public oldMail: string;					// 旧名義人メールアドレス
	public nameChangeInfo: NameChangeInfo;	// 旧名義人入力情報
	// ------------------------------------------------------------------------
}