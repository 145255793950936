import { WprBaseViewMultiModeInfo } from '../../../../../../wpr-framework/mode/WprBaseViewMultiMode';
import { WprMultiMode } from '../../../../../../wpr-framework/mode/WprMultiMode';
import { ItscomHomeSelect2View } from '../ItscomHomeSelect2View';
import { WprDI_Control } from '../../../../../../wpr-framework/view/di/WprDI_Control';
import { WprControlInfo } from '../../../../../../wpr-framework/view/control/WprControlInfo';

/**
 * お得パックモード
 */
export class OtokuMode extends WprBaseViewMultiModeInfo<ItscomHomeSelect2View> {
	// DI情報  ------------------------------------------------------------------
	@WprDI_Control
	private m_rental: WprControlInfo	= null;	// レンタル
	@WprDI_Control
	private m_purchase: WprControlInfo	= null;	// 購入
	@WprDI_Control
	private m_yesOtoku: WprControlInfo	= null;	// 希望する
	@WprDI_Control
	private m_noOtoku: WprControlInfo	= null;	// 希望しない
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	public constructor() {
		super('OtokuMode');
	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * モードリスト取得（この画面モードで使用する全てのモードを定義する）
	 * @param list モードリスト
	 */
	public useModeList(list: string[]): void {
		list.push('切替可(yesOtoku)');
		list.push('切替不可(yesOtoku)');
		list.push('切替可(noOtoku)');
		list.push('切替不可(noOtoku)');
		list.push('切替可(rental)');
		list.push('切替不可(rental)');
		list.push('切替可(purchase)');
		list.push('切替不可(purchase)');
		list.push('非活性(yesOtoku)');
		list.push('非活性(noOtoku)');
	}

	/**
	 * フィールド定義を登録処理
	 */
	public onRegisterControl(): void {
		this.addControl('yesOtoku', {
			check: true,
			readOnly: [ { mode: '切替不可(yesOtoku)', value: true }, { mode: '切替可(yesOtoku)', value: false } ],
			class: [ { mode: '非活性(yesOtoku)', class: 'disabled' }, { mode: 'other', class: '' } ]
		});
		this.addControl('noOtoku', {
			check: true,
			readOnly: [ { mode: '切替不可(noOtoku)', value: true }, { mode: '切替可(noOtoku)', value: false } ],
			class: [ { mode: '非活性(noOtoku)', class: 'disabled' }, { mode: 'other', class: '' } ]
		});
		this.addControl('rental', { 
			check: true,
			readOnly: [ { mode: '切替不可(rental)', value: true }, { mode: '切替可(rental)', value: false } ] });
		this.addControl('purchase', { 
			check: true,
			readOnly: [ { mode: '切替不可(purchase)', value: true }, { mode: '切替可(purchase)', value: false } ] });
	}

	/**
	 * 画面の状態をチェックしてモードを変更する
	 * @param curMode 現在のモード
	 * @param newMode 新しいモード
	 */
	public onCheckMultiMode(curMode: WprMultiMode, newMode: WprMultiMode): void {
		if (!this.m_rental.value && this.m_purchase.value) {
			newMode.addMode('切替可(rental)');
			newMode.addMode('切替不可(purchase)');
			newMode.addMode('切替不可(yesOtoku)');
			newMode.addMode('切替不可(noOtoku)');
			newMode.addMode('非活性(yesOtoku)');
			newMode.addMode('非活性(noOtoku)');
		}
		else if(this.m_rental.value && !this.m_purchase.value) {
			newMode.addMode('切替可(purchase)');
			newMode.addMode('切替不可(rental)');
			if (this.m_yesOtoku.value && !this.m_noOtoku.value) {
				newMode.addMode('切替不可(yesOtoku)');
				newMode.addMode('切替可(noOtoku)');
			}
			else if (!this.m_yesOtoku.value && this.m_noOtoku.value) {
				newMode.addMode('切替可(yesOtoku)');
				newMode.addMode('切替不可(noOtoku)');
			}
		}
	}
	// --------------------------------------------------------------------------
}
