import { FormControl, FormHelperText } from '@material-ui/core';
import React, { ReactNode } from 'react';
import { IWprControlProps } from '../../props/IWprControlProps';
import { WprControlVisibility } from '../../props/IWprControlState';
import { WprBaseControlComponent } from '../WprBaseControlComponent';

/**
 * ラジオボタングループプロパティ情報
 */
interface IMatRadioGroupProps extends IWprControlProps {
}

/**
 * ラジオボタングループコントロール
 */
export class MatRadioGroup extends WprBaseControlComponent<IMatRadioGroupProps, HTMLSpanElement> {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: IMatRadioGroupProps) {
		super(props);
	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * マウント通知
	 */
	public onDidMount() {
		super.onDidMount();
		let rcnt = 0;
		React.Children.forEach(this.props.children, (child) => {
			const radio = child as any;
			const name = radio.type.name;
			if (radio) {
				if (name === 'MatRadio') {
					rcnt++;
					if (radio.props['name']) {
						if (this.props.name !== radio.props.name)
							this.addErrorLog(`MatRadioGroupとその子要素でnameが異なります。[${this.props.name}][${radio.props.name}]`);
					}
				}
				else {
					this.addErrorLog(`MatRadioGroupの子要素にMatRadio以外が含まれています。[${name}]`);
				}
			}
		});
		if (rcnt === 0)
			this.addErrorLog(`MatRadioGroupの子要素がありません。[${this.props.name}]`);
	}
	
	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		if (this.state.visibility === WprControlVisibility.COLLAPSED)
			return <></>;
		const props = this.getProps('wpr-radio-group');
		const {name, view, className, children, ...other} = this.props;
		const options = this.getOptions();
		const error = this.getError();
		const helperText = this.getHelperText();
		let tr = null;
		if (this.state.visibility === WprControlVisibility.VISIBLE) {
			tr = <FormControl {...options} {...error}><span {...props} {...other} ref={this.ref}>{this.props.children}</span>{helperText}</FormControl>;
		}
		else {
			tr = <span hidden {...props} {...other} ref={this.ref}>{this.props.children}</span>;
		}
		return (
			<>
				{tr}
			</>
		);
	}
	// --------------------------------------------------------------------------

	// private メソッド  ---------------------------------------------------------
	/**
	 * オプション情報取得
	 * @returns オプション情報
	 */
	 private getOptions(): any {
		const rtn: any = {};
//		rtn['className'] = this.className();
		rtn['variant'] = 'outlined';
		rtn['size'] = 'small';
		return rtn;
	}

	/**
	 * エラー情報取得処理
	 * @returns エラー情報
	 */
	 private getError(): any {
		const rtn: any = {};
		if (this.state.errMessage && this.state.errMessage.length > 0) {
			rtn['error'] = true;
//			rtn['helperText'] = this.state.errMessage[0];	// とりあえず最初の一行
		}
		return rtn;
	}

	/**
	 * ヘルパーテキスト情報取得処理
	 * @returns エラー情報
	 */
	 private getHelperText(): ReactNode {
		if (this.state.errMessage && this.state.errMessage.length > 0) {
			return (<FormHelperText className='wpr-radio-group-error'>{this.state.errMessage[0]}</FormHelperText>);
		}
		return null;
	}
	// --------------------------------------------------------------------------
}