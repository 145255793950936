import React, { ReactNode } from 'react';
import { IWprTabMode } from '../../../mode/tab/IWprTabMode';
import { IWprNoNameLayoutProps } from '../../props/IWprLayoutProps';
import { WprBaseNoNameLayoutComponent } from '../WprBaseNoNameLayoutComponent';

/**
 * タブパネルプロパティ情報
 */
interface IMatTabPanelProps extends IWprNoNameLayoutProps {
	mode: string;		// タブモード
	label: string;		// ラベル
}

/**
 * タブパネル
 */
export class MatTabPanel extends WprBaseNoNameLayoutComponent<IMatTabPanelProps, JSX.Element> implements IWprTabMode {
	// private 変数  ------------------------------------------------------------
	private m_CurrentMode: string 		= '';			// 現在のモード
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	constructor(props: IMatTabPanelProps) {
		super(props);
	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * マウント通知
	 */
	public onDidMount() {
		this.props.view.setTabModeComponent(this, this.props.mode, this.props.label, null, null);
		React.Children.forEach(this.props.children, (child) => {
			const cinfo = child as any;
			if (cinfo.props['view']) {
				const vname = cinfo.type.name;
				this.props.view.addTabChildViewName(vname, cinfo.props.name, this.props.mode, this.props.label);
			}
		});
	}

	/**
	 * アンマウント通知
	 */
	 public onWillUnmount() {
		this.props.view.deleteTabModeComponent(this, this.props.mode, this.props.label);
	}

	 /**
	 * 描画
	 */
	public onRender(): ReactNode {
		if (this.isMount == false)
			return <></>;
		const props = this.getProps();
		const {view, className, children, ...other} = this.props;
		let tabPanel = null;
		const state = this.getState(['style']);
		if (this.state.value === this.props.label) {
			tabPanel = <div {...props} {...state} {...other} ref={this.ref} >{this.props.children}</div>;
		}
		else {
			tabPanel = <div hidden {...props} {...state} {...other} ref={this.ref}>{this.props.children}</div>;
		}
		return (
			<>
				{tabPanel}
			</>
		);
	}
	// --------------------------------------------------------------------------

	// private メソッド  ---------------------------------------------------------
	/**
	 * オプション情報取得
	 * @returns オプション情報
	 */
	private getOptions(): any {
		const rtn: any = {};
		rtn['role'] = 'tabpanel';
		rtn['id'] = this.props.mode;
		return rtn;
	}
	// --------------------------------------------------------------------------

	// IWprTabModeの実装  --------------------------------------------------------
	/**
	 * タブモード設定処理
	 * @param mode モード
	 */
	 public setTabMode(mode: string): void {
		if (this.m_CurrentMode !== mode)
			this.m_CurrentMode = mode;
		this.setState({
			value : mode
		});
	}
	// --------------------------------------------------------------------------
}
