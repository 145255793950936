/**
 * ItscomHomeオプション変更確認モデル
 */
export class ItscomHomeOptRivComfirmList {
	// public 変数	-----------------------------------------------------------
	public option: string;	// オプション
	public detail: string;	// 詳細
	public number: string;	// 個数
	// ------------------------------------------------------------------------
}
