// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".NameChangeNewInformation-loginHeader {\n  text-align: center;\n  color: #003894;\n  font-size: 32px;\n  line-height: 100%;\n  margin: 0 0 45px 0; }\n\n.NameChangeNewInformation-ColumText {\n  text-align: center;\n  margin-top: 48px;\n  font-size: 13px;\n  font-weight: 500;\n  border-bottom: none;\n  line-height: 23px;\n  margin-bottom: 48px; }\n\n.NameChangeNewInformation-userpolicyLink {\n  margin: 10px 0 40px 0; }\n  @media screen and (max-width: 440px) {\n    .NameChangeNewInformation-userpolicyLink .MuiButton-root {\n      width: 100%; } }\n  .NameChangeNewInformation-userpolicyLink .MuiButton-outlined {\n    padding: 15px 24px;\n    border: 1px solid #E3E3E3; }\n  .NameChangeNewInformation-userpolicyLink button {\n    width: 100%;\n    border-radius: 12px !important;\n    height: 62px; }\n    @media screen and (max-width: 450px) {\n      .NameChangeNewInformation-userpolicyLink button {\n        height: 70px; } }\n    .NameChangeNewInformation-userpolicyLink button .MuiButton-label {\n      justify-content: left; }\n    .NameChangeNewInformation-userpolicyLink button .MuiTypography-root {\n      justify-content: left;\n      margin-left: 45px;\n      font-style: normal;\n      font-weight: 700;\n      font-size: 14px;\n      line-height: 140%;\n      color: #000000; }\n      @media screen and (max-width: 450px) {\n        .NameChangeNewInformation-userpolicyLink button .MuiTypography-root {\n          text-align: left; } }\n    .NameChangeNewInformation-userpolicyLink button .MuiSvgIcon-root {\n      position: absolute;\n      border-radius: 20px;\n      height: 18px;\n      width: 18px;\n      padding: 7px;\n      background-color: #F87619;\n      color: #FFFFFF; }\n", ""]);
// Exports
exports.locals = {
	"loginHeader": "NameChangeNewInformation-loginHeader",
	"ColumText": "NameChangeNewInformation-ColumText",
	"userpolicyLink": "NameChangeNewInformation-userpolicyLink"
};
module.exports = exports;
