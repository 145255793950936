import { WprBaseValidator } from '../WprBaseValidator';

/**
 * 最大長バリデータ
 */
export class WprMaxLengthValidator extends WprBaseValidator {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() {
		super('最大文字数');
	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * 入力値チェック
	 * @param value 入力値
	 * @param option チェックオプション
	 * @returns trueの場合、成功
	 */
	public checkValidValue(value: any, option?: any): boolean {
		if (!value)
			return true;
		if (!option)
			return false;
		if (typeof(value) === 'string' || value instanceof String) {
			if (value.length <= option)
				return true;
		}
		return false;
	}

	/**
	 * エラーメッセージ取得
	 * @param description エラーが発生したコントロールの説明
	 * @param option オプション
	 * @returns エラーメッセージ
	 */
	public getErrorMessage(description: string, option?: any): string {
		if (option) {
			const num = option as number;
			if (num)
				return num + '文字以内で入力してください。';
		}
		return '最大文字数エラー';
	}
	// --------------------------------------------------------------------------
}
