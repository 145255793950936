import { WorkDetailList } from '../../../../models/iss/getVacantMypWorkSchedule/WorkDetailList';

/**
 * 工事予定一覧取得リクエスト
 */
export class GetVacantMypWorkScheduleRequest {
	// public 変数	-----------------------------------------------------------
	public possible_date: string;				// 
	public day_of_week: string;					// 
	public preferred_time_cd: string;			// 
	public city: string;						// 
	public town: string;						// 
	public work_area_cd: string;				// 
	public access_cd: string;					// 
	public work_detail_list: WorkDetailList[];	// 
	// ------------------------------------------------------------------------
}