import { IWprConfigModeOption } from '../../../../../../wpr-framework/mode/inter/IWprConfigModeOption';
import { WprBaseViewMultiModeInfo } from '../../../../../../wpr-framework/mode/WprBaseViewMultiMode';
import { WprMultiMode } from '../../../../../../wpr-framework/mode/WprMultiMode';
import { MvnoEntryCustomerView } from '../MvnoEntryCustomerView';
import { WprDI_Control } from '../../../../../../wpr-framework/view/di/WprDI_Control';
import { WprControlInfo } from '../../../../../../wpr-framework/view/control/WprControlInfo';
import { WprDI_Object } from '../../../../../../wpr-framework/view/di/WprDI_Object';
import { MvnoEntryInfo } from '../../../../../service/models/apimodels/MvnoEntryInfo';

/**
 * モード
 */
export class EditMode extends WprBaseViewMultiModeInfo<MvnoEntryCustomerView> {
	// DI情報  ------------------------------------------------------------------
	@WprDI_Control
	private m_numberingWay: WprControlInfo	= null;	// 採番方法
	@WprDI_Object
	private m_EntryInfo: MvnoEntryInfo	= null;	// MVNOプラン情報
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	public constructor() {
		super('editMode');
	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * モードリスト取得（この画面モードで使用する全てのモードを定義する）
	 * @param list モードリスト
	 */
	public useModeList(list: string[]): void {
		list.push('MNP');
		list.push('新規電話番号');
		list.push('初期設定サポート必要');
		list.push('初期設定サポート不要');
		list.push('音声プラン');
		list.push('データプラン');
	}

	/**
	 * フィールド定義を登録処理
	 */
	public onRegisterControl(): void {
		const mnpMode = { hissu: [ { mode: 'MNP', value: true }, { mode: 'other', value: false } ] };
		this.addControl('numberingWay', { check: true, hissu: [ { mode: '音声プラン', value: true }, { mode: 'other', value: false } ] });
		this.addControl('mnpReqNumber', mnpMode);
		this.addControl('mnpReqExpire', mnpMode);
		this.addControl('mnpUseNumber', mnpMode);

		const reqDayMode = { hissu: [ { mode: '初期設定サポート必要', value: true }, { mode: 'other', value: false } ] };
		this.addControl('reqDay1', reqDayMode);
		this.addControl('reqTime1', reqDayMode);
		this.addControl('reqDay2', reqDayMode);
		this.addControl('reqTime2', reqDayMode);
		this.addControl('reqDay3', reqDayMode);
		this.addControl('reqTime3', reqDayMode);
	}

	/**
	 * 画面の状態をチェックしてモードを変更する
	 * @param curMode 現在のモード
	 * @param newMode 新しいモード
	 */
	public onCheckMultiMode(curMode: WprMultiMode, newMode: WprMultiMode): void {
		newMode.addMode(this.getViewMode('プラン'));	// 音声プラン,データプラン
		if (this.m_numberingWay.value === 'MNP') {
			newMode.addMode('MNP');
		}
		else {
			newMode.addMode('新規電話番号');
		}
		if (this.m_EntryInfo.entryInfo.initialSupportFlg) {
			newMode.addMode('初期設定サポート必要');
		}
		else {
			newMode.addMode('初期設定サポート不要');
		}
		// newMode.addMode('新規電話番号発番');
	}
	// --------------------------------------------------------------------------
}
