import { WprBaseViewCore } from "../../../wpr-framework/view/WprBaseViewCore";
import { FileInfo } from "../../service/base/models/common/FileInfo";


/**
 * ファイルユーティリティ
 */
export class FileUtil {
	// サービス  ------------------------------------------------------------------
	// private static m_CommonService: CommonService	= new CommonService();	// 共通サービス
	// --------------------------------------------------------------------------

	// public static メソッド  ---------------------------------------------------
	// *********************** プレビュー/ダウンロード ***********************
	/**
	 * 指定されたファイルパス（複数可能）から、プレビュー
	 * @param viewCore 
	 * @param filePathList 
	 */
	public static preview(viewCore: WprBaseViewCore, fileInfoList: FileInfo[]): void {
		// this.fileOperation(viewCore, fileInfoList, 'preview');
	}

	/**
	 * 指定されたファイルパス（複数可能）から、ダウンロード
	 * @param viewCore 
	 * @param filePathList 
	 */
	public static download(viewCore: WprBaseViewCore, fileInfoList: FileInfo[]): void {
		// this.fileOperation(viewCore, fileInfoList, 'download');
	}

	/**
	 * ファイルダウンロード(複数)
	 * @param file 
	 */
	public static downloadList(fileInfoList: FileInfo[]): void {
		fileInfoList.forEach(fileInfo => {
			this.downloadFile(fileInfo);
		});
	}

	/**
	 * ファイルダウンロード(1件)
	 * @param file 
	 */
	public static downloadFile(fileInfo: FileInfo): void {
		const url = this.createBlobUrl(fileInfo.dataUrl);
		const a = document.createElement('a');
		a.href = url;
		a.download = fileInfo.fileName;
		a.click();
		window.URL.revokeObjectURL(url);
	}

	/**
	 * ファイルプレビュー(複数)
	 * @param fileInfoList 
	 */
	public static previewList(fileInfoList: FileInfo[]){
		fileInfoList.forEach(fileInfo => {
			this.openNewTab(fileInfo);
		});
	}

	/**
	 * 別タブで開く
	 * @param file 
	 */
	public static openNewTab(file: FileInfo): void {
		// PDFファイルは以外プレビューせずダウンロードする
		if (file.extension === 'pdf') {
			// let w = window.open();
			// w.focus();
			// w.document.open();
			// const dataUrl = this.createBlobUrl(file.dataUrl);
			// let t = 
			// 	`<html>
			// 		<head>
			// 			<title>${file.fileName}</title>
			// 		</head>
			// 		<body>
			// 			<iframe src="${dataUrl}#toolbar=0&navpanes=0" width="100%" height="100%"></iframe>
			// 		</body>
			// 	</html>`;
			// w.document.write(t);
			// w.document.close();
			const url = this.createBlobUrl(file.dataUrl);
			const newWindow = window.open(url, '_blank');
			newWindow.document.title = file.fileName;	
		} else {
			this.downloadFile(file);
		}
		// const url = this.createBlobUrl(file.dataUrl);
		// const newWindow = window.open(url, '_blank');
		// newWindow.document.title = file.fileName;
	}

	/**
	 * PDF出力：別タブ表示
	 * @param txt 
	 */
	public static createPDF(name: string, txt: String) : void{
		const text = txt;
		const file = new FileInfo();
		file.fileName = name;
		file.dataUrl = 'data:application/pdf;base64,' + text;
		file.extension = 'pdf';
		FileUtil.openNewTab(file);
	}

	// *********************** File情報生成/取得 ***********************
	/**
	 * ファイルデータ（DataURL）から BlobURLを作成
	 * @param dataUrl 
	 * @returns url
	 */
	public static createBlobUrl(dataUrl: string): string {
		const fileData = dataUrl.replace(/^data:\w+\/[\w\.\-]+;base64,/, '');
		const fileType = dataUrl.match(/(:)([a-zA-Z\0-9\/\.\-]+)(;)/)[2];
		const decodedFile = atob(decodeURIComponent(fileData));
		// バイナリからBlobを作成
		const decodedArray = new Uint8Array(Array.prototype.map.call(decodedFile, (c:any) => c.charCodeAt()));
		const blob = new Blob([decodedArray], {type: fileType});
		// ダウンロード用リンク生成
		const url = window.URL.createObjectURL(blob);
		return url;
	}

	/**
	 * ファイルデータ（Blob）からDataURLを生成
	 * @param fileData 
	 * @param fileInfo 
	 */
	public static createDataUrl(fileData: File, fileInfo: FileInfo, resMethod?: (result: any) => void) {
		fileInfo.fileName = fileData.name;
		const fileReader = new FileReader();
		// ファイルをDataURLとして読み込む
		fileReader.readAsDataURL(fileData);
		fileReader.onload = ()  => {
			fileInfo.dataUrl = fileReader.result as string;
			if (resMethod != null)
				resMethod.bind(this)();
		}
	}

	/**
	 * ファイル名から拡張子を取得
	 * @param fileName 
	 * @returns 
	 */
	public static getExtension(fileName: string): string {
		return fileName.split(/(?=\.[^.]+$)/)[1];
	}

	// --------------------------------------------------------------------------

	// private static メソッド	--------------------------------------------------
	/**
	 * ファイル操作
	 * @param viewCore 
	 * @param filePathList 
	 * @param operation preview / download
	 */
	// private static fileOperation(viewCore: WprBaseViewCore, fileInfoList: FileInfo[], operation: string): void {
	// 	const req = new FileOperationRequest();
	// 	req.fileInfoList = fileInfoList;
	// 	// this.m_CommonService.getFile(viewCore, req, (result) => {
	// 		// プレビュー
	// 		if(operation === 'preview')
	// 			this.previewList(result.fileInfoList);
	// 		// ダウンロード
	// 		else if(operation === 'download')
	// 			this.downloadList(result.fileInfoList);
	// 	});
	// }
	// --------------------------------------------------------------------------
}