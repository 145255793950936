import { MvnoEntryInfo } from "../../apimodels/MvnoEntryInfo";

/**
 * 端末在庫情報取得リクエスト
 */
export class GetTerminalStockRequest {
	// public 変数	-----------------------------------------------------------
	public entry: MvnoEntryInfo;	// aaaa
	// ------------------------------------------------------------------------
}
