import { WprBaseObject } from '../../WprBaseObject';
import { WprBaseViewCore } from '../WprBaseViewCore';
import { IWprConfigOption } from './IWprConfigOption';
import { IWprLayoutConfigOption } from './IWprLayoutConfigOption';

/**
 * レイアウト定義オプション
 */
export class WprLayoutConfig extends WprBaseObject {
	// private 変数  ------------------------------------------------------------
	private m_ViewCore: WprBaseViewCore	= null;		// View情報
	private m_Name: string				= null;		// コントロール名
	private m_Open?: boolean			= true;		// オープン状態
	private m_ValidControl?: string		= null;		// エラーを判定するコントロール名
	private m_ErrorClass: string		= null;		// エラークラス名
	private m_NormarlClass: string		= null;		// 通常クラス名
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** オープン状態 */
	public get open(): boolean			{ return this.m_Open; 			}
	/** エラーを判定するコントロール名 */
	public get validControl(): string	{ return this.m_ValidControl; 	}
	/** エラークラス名 */
	public get errorClass(): string		{ return this.m_ErrorClass; 	}
	/** 通常クラス名 */
	public get normarlClass(): string	{ return this.m_NormarlClass; 	}
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	public constructor(name: string, viewCore: WprBaseViewCore, config?: IWprLayoutConfigOption) {
		super();
		this.m_Name = name;
		this.m_ViewCore = viewCore;
		if (config) {
			if (config.open)
				this.m_Open = config.open;
		}
	}
	// --------------------------------------------------------------------------
}
