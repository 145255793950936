import { WprBaseViewInfo } from '../../view/WprBaseViewInfo';
import { IWprAccordionMode } from './IWprAccordionMode';

/**
 * アコーディオンモードモード情報
 */
export class WprAccordionModeInfo {
	// private 変数  ------------------------------------------------------------
	private m_ModeName: string							= null;				// モード名
	private m_AccordionCompList: IWprAccordionMode[]	= new Array();		// コンポーネントリスト
	private m_Mode: string								= null;				// モード
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** モード名 */
	public get name(): string		{ return this.m_ModeName;	}
	/** モード */
	public get mode(): string		{ return this.m_Mode;		}
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	public constructor(modeName: string) {
		this.m_ModeName = modeName;
	}
	// --------------------------------------------------------------------------

	// public メソッド  ----------------------------------------------------------
	/**
	 * アコーディオンコンポーネント追加処理
	 * @param comp アコーディオンコンポーネント
	 */
	public addAccordionComponent(comp: IWprAccordionMode): void {
		this.m_AccordionCompList.push(comp);
	}

	/**
	 * モード設定処理
	 * @param mode モード 
	 */
	public setMode(mode: string): void {
		this.m_Mode = mode;
		for(const ainfo of this.m_AccordionCompList)
			ainfo.setAccordionMode(mode);
	}

	/**
	 * タブ初期化処理
	 * @returns falseの場合エラーあり
	 */
	public initialize(view: WprBaseViewInfo): boolean {
		return true;
	}
	// --------------------------------------------------------------------------
}