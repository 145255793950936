import { WprListData } from '../../../../../wpr-framework/view/deco/WprListData';
import { WprModel } from '../../../../../wpr-framework/view/deco/WprModel';
import { WprViewMode } from '../../../../../wpr-framework/view/deco/WprViewMode';
import { WprDI_ScopeModel } from '../../../../../wpr-framework/view/di/WprDI_ScopeModel';
import { ObjectUtil } from '../../../../common/util/ObjectUtil';
import { Contract } from '../../../../models/bss/contract/Contract';
import { TvBaseModel } from '../../../../models/mypage/tv/TvBaseModel';
import { TvOptionListModel } from '../../../../models/mypage/tv/TvOptionListModel';
import { AddServiceModel } from '../../../../service/models/apimodels/AddServiceModel';
import { GetSimulationRequest } from '../../../../service/models/dto/mypagerenewal/GetSimulationRequest';
import { MypageBaseView } from '../../../base/MypageBaseView';
import { AgreeMode } from '../../../common/common_agree/mode/AgreeMode';
import { ProgressBarData } from '../../../common/progress_bar/ProgressBarView';
import { MypageWizardMngr } from '../../../wizard/models/MypageWizardMngr';
import { BeforeOptionMode } from './mode/BeforeOptionMode';
import { AfterOptionMode } from './mode/AfterOptionMode';
import { ItscomTvService } from '../../../../service/ItscomTvService';
import { GetTvContractDateRequest } from '../../../../service/models/dto/itscomTv/GetTvContractDateRequest';
import { TvDirectModel } from '../../../../models/mypage/tv/TvDirectModel';
import { CommonService } from '../../../../service/CommonService';
import { RegistContractRequest } from '../../../../service/models/dto/mypagerenewal/RegistContractRequest';
import { MCampaign } from '../../../../service/models/entity/primary/MCampaign';
import { GetConstructionCostRequest } from '../../../../service/models/dto/mypagerenewal/GetConstructionCostRequest';
import { MCampaignGroup } from '../../../../service/models/entity/primary/MCampaignGroup';
import MypageCodeTypeConstants from '../../../../common/MypageCodeTypeConstants';

/**
 * テレビ変更確認画面
 */
export class ModTvComfirmView extends MypageBaseView {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() { super('ModTvComfirmView'); }
	// --------------------------------------------------------------------------

	// モデル  ------------------------------------------------------------------
	@WprDI_ScopeModel('MypageWizardMngr')
	private m_MypageWizardMngr: MypageWizardMngr			= null;		// 画面ウィザード管理クラス
	@WprModel('BeforeTvBaseModel')
	private m_BeforeTvBaseModel: TvBaseModel	= null;	// テレビ基本情報モデル
	@WprModel('AfterTvBaseModel')
	private m_AfterTvBaseModel: TvBaseModel	= null;	// テレビ基本情報モデル
	@WprListData('BeforeTvOptionListModel')
	private m_BeforeTvOptionListModel: TvOptionListModel[]	= null;	// テレビオプションリストモデル(画面表示分)
	@WprListData('AfterTvOptionListModel')
	private m_AfterTvOptionListModel: TvOptionListModel[]	= null;	// テレビオプションリストモデル(画面表示分)
	@WprModel('CampaignGroup')
	private m_CampaignGroup: MCampaignGroup	= null;	// キャンペーングループモデル
	@WprListData('CampaignListModel')
	private m_CampaignListModel: MCampaign[]	= null;	// キャンペーンリストモデル
	@WprModel('checkBox')
	private m_checkBox: string	= null;	// checkBox
	// --------------------------------------------------------------------------

	// サービス  ----------------------------------------------------------------
	private m_ItscomTvService: ItscomTvService	= new ItscomTvService();	// ItscomTvサービス
	// --------------------------------------------------------------------------

	// private変数 ---------------------------------------------------------------
	private m_AllOptionListModel: TvOptionListModel[]	= null;	// テレビオプションリストモデル(全件)
	private m_Contract: Contract = null;
	private m_BefOption = false;
	private m_AftOption = false;
	// --------------------------------------------------------------------------

	// サブビュー/モード  --------------------------------------------------------
	@WprViewMode
	private m_AgreeMode = new AgreeMode();	// 約款モード
	@WprViewMode
	private m_BeforeOptionMode = new BeforeOptionMode();	// BeforeOptionMode
	@WprViewMode
	private m_AfterOptionMode = new AfterOptionMode();	// AfterOptionMode
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * コントロール定義設定
	 */
	public onRegisterConfig(): void {
		this.addConfig('breforeFee', '変更前料金', 'AfterTvBaseModel.monthlyFee', { converter: '3桁カンマ' });
		this.addConfig('otokuName', 'お得パック ( 表示用 )', 'AfterTvBaseModel');
		this.addConfig('afterFee', '変更後料金', 'AfterTvBaseModel.monthlyFee', { converter: '3桁カンマ' });
		this.addConfig('amountA', '金額', 'AfterTvBaseModel', { bindName: 'amount' ,converter: '3桁カンマ'});
		this.addConfig('courseA', 'コース', 'AfterTvBaseModel', { bindName: 'course' });
		this.addConfig('terminalA', '機器名', 'AfterTvBaseModel', { bindName: 'terminal' });
		this.addConfig('applyTypeA', '購入、レンタル ( 表示用 )', 'AfterTvBaseModel', { bindName: 'applyType' });
		this.addConfig('amountB', '金額', 'BeforeTvBaseModel', { bindName: 'amount' ,converter: '3桁カンマ'});
		this.addConfig('courseB', 'コース', 'BeforeTvBaseModel', { bindName: 'course' });
		this.addConfig('terminalB', '機器名', 'BeforeTvBaseModel', { bindName: 'terminal' });
		this.addConfig('applyTypeB', '購入、レンタル ( 表示用 )', 'BeforeTvBaseModel', { bindName: 'applyType' });
		this.addConfig('confirm', '確認', 'checkBox', { bindModel: true });
		this.addListConfig('option', 'オプション', 'BeforeTvOptionListModel', { bindName: 'option' });
		this.addListConfig('option', 'オプション', 'AfterTvOptionListModel', { bindName: 'option' });
		this.addConfig('contractDate', '直接申込', 'AfterTvBaseModel', { bindName: 'startDate' });
		this.addConfig('desiredDate1', '第一希望', 'AfterTvBaseModel', { converter: '日付（yyyy/MM/dd）' });
		this.addConfig('selectTime1', '第一希望　時間帯', 'AfterTvBaseModel');
		this.addConfig('desiredDate2', '第二希望', 'AfterTvBaseModel', { converter: '日付（yyyy/MM/dd）' });
		this.addConfig('selectTime2', '第二希望　時間帯', 'AfterTvBaseModel');
		this.addConfig('desiredDate3', '第三希望', 'AfterTvBaseModel', { converter: '日付（yyyy/MM/dd）' });
		this.addConfig('selectTime3', '第三希望　時間帯', 'AfterTvBaseModel');
		this.addConfig('constrAmount', '工事費', 'AfterTvBaseModel', { converter: '3桁カンマ' });
		this.addConfig('payUnit', '工事費', 'AfterTvBaseModel', { bindName: 'payUnit' });
		// this.addConfig('campaignName', '名称2', 'AfterTvBaseModel.campaign', { bindName: 'name2' });
		// this.addConfig('campaignDiscount', '数値1', 'AfterTvBaseModel.campaign', { bindName: 'num1' });
		this.addConfig('campaignName', 'キャンペーン名', 'CampaignGroup', { bindName: 'campaignGroupDispName' });
		this.addConfig('campaignExplanation', 'キャンペーン文言', 'CampaignGroup', { bindName: 'explanation' });
		this.addConfig('beforeContact', '前日連絡先', 'AfterTvBaseModel');
		this.addConfig('sameDayContact', '当日連絡先', 'AfterTvBaseModel');
		this.addConfig('name', '立ち合い者名', 'AfterTvBaseModel', { bindName: 'presentName' });
		this.addConfig('securityDisp', 'セキュリティ会社', 'AfterTvBaseModel');
	}

	/**
	 * アクション登録
	 */
	public onRegisterAction(): void {
		this.addAction('next', this.onNext);
		this.addAction('back', this.onBack);
	}

	/**
	 * ビュー表示通知
	 */
	public onShowView(): void {
		this.m_MypageWizardMngr.initView(this, (result) => {
			this.registAccessLog();
			this.setStorageItem('b_url', window.location.href);
			const sendData: ProgressBarData = new ProgressBarData();
			sendData.progressBarList = this.m_MypageWizardMngr.getProgressModelList(this);
			this.sendChildData('fP_ev_progressBarIntital', sendData);
			//キャッシュデータ取得
			this.m_AfterTvBaseModel = this.m_MypageWizardMngr.getCache().m_TvBaseModel;
			this.m_BeforeTvBaseModel = this.m_MypageWizardMngr.getCache().m_BeforeTvBaseModel;
			this.m_AllOptionListModel = this.m_MypageWizardMngr.getCache().m_TvOptionListModel;
			this.m_BeforeTvOptionListModel = this.m_MypageWizardMngr.getCache().m_BeforeTvOptionListModel;
			//表示用データ設定
			this.getDisplayData();
			//月額取得
			this.paymentSimulation();
			this.refreshView()
		});
	}
	
	/**
	 * 値変更通知
	 * @param name 名前
	 * @param value 値
	 * @returns falseの場合、変更を取り消す
	 */
	public onChangeValue(name: string, value: any): boolean {
		if(name === 'confirm')	{
			if(value === true) {
				this.setViewMode('agree','承諾');
			}
			else{
				this.setViewMode('agree','未承諾');
			}
		}
		return true;
	}
	// --------------------------------------------------------------------------

	// アクション  ---------------------------------------------------------------
	/**
	 * 次へ
	 */
	public onNext(param: any): void {
		this.m_MypageWizardMngr.goNext(this);
	}

	/**
	 * 戻る
	 */
	public onBack(param: any): void {
		if (this.m_AftOption)
			this.m_AfterTvOptionListModel.length = 0;
		if (this.m_BefOption)
			this.m_BeforeTvOptionListModel.length = 0;
		this.m_MypageWizardMngr.goBack(this);
	}
	// --------------------------------------------------------------------------
	// privateメソッド  ----------------------------------------------------------
	/**
	 * 料金シミュレーション
	 */
	private paymentSimulation() {
		const req: GetSimulationRequest = new GetSimulationRequest();
		req.customerId = this.m_MypageWizardMngr.getParams().customerId;
		req.addServiceList = new Array();	// 追加サービスモデルリスト
		// コース
		const service = new AddServiceModel;
		service.serviceCd = this.m_AfterTvBaseModel.courseCd;
		req.addServiceList.push(service);
		req.addServiceList[0].optionCdList = new Array();
		// オプション
		for (const option of this.m_AfterTvOptionListModel) {
			if (option.select) {
				req.addServiceList[0].optionCdList.push(option.detail.optionCD);
			}
		}
		req.addServiceList[0].contractId = this.m_Contract.contractID;
		this.m_CommonService.getPaymentSimulation(this, req, (result) => {
			this.m_AfterTvBaseModel.monthlyFee = result;
			// 新しい月額利用料
			if (result.minusFlg) {
				this.m_AfterTvBaseModel.monthlyFee.afterFee = String(Number(this.m_AfterTvBaseModel.monthlyFee.afterFee) + 396);
			}
			// 変更前の月額
			if (result.discountFlg) {
				this.m_BeforeTvBaseModel.amount = String(Number(this.m_BeforeTvBaseModel.amount) - 396);
			}
			const payment = this.m_MypageWizardMngr.getViewInfo().customerPayment;
			if (result.breforeFee === '0' && payment &&
				payment[0].payment[0].paymentTypeCD === '2' && !this.m_MypageWizardMngr.isPaidService(this))
				this.setViewMode('請求書有料発行', '表示')
			this.refreshModel();
		});
	}

	/**
	 * 表示用設定
	 */
	private getDisplayData() {
		// お得パック加入判定
		const contract = this.m_MypageWizardMngr.getViewInfo().contractModel;
		const paymentList  = this.m_MypageWizardMngr.getViewInfo().customerPayment;
		if (paymentList && !this.m_MypageWizardMngr.showOtokuPlan(this, paymentList[0].payment[0], contract.planList)) {
			if (ObjectUtil.isNotNullOrUndefined(contract.planList ) && contract.planList.length !== 0) {
				this.setViewMode('お得パック','加入済み');
				this.setViewMode('新月額利用料','※');
				// 適用中お得パック名取得
				this.m_AfterTvBaseModel.otokuName = this.m_MypageWizardMngr.getOtokuPlanName(this, contract.planList).dispnamep;
			}
			else if (this.m_AfterTvBaseModel.otokuApply)
				this.setViewMode('新月額利用料','※');
			else
				this.setViewMode('新月額利用料','料金');
		}
		else if (this.m_AfterTvBaseModel.otokuApply)
			this.setViewMode('新月額利用料','※');
		else
			this.setViewMode('新月額利用料','料金');
		//直接申込判定
		const req: GetTvContractDateRequest = new GetTvContractDateRequest();
		const contractId = this.m_MypageWizardMngr.getParams().contractId;
		if (ObjectUtil.isNotNullOrEmptyStr(contractId)) {
			this.m_Contract = this.m_MypageWizardMngr.getContract(this, contractId);
		}
		req.serviceTypeCd = '1';
		req.befServiceCd = this.m_BeforeTvBaseModel.courseCd;
		req.befRentalFlg = this.m_Contract.equipment[0].rentalFlag;
		req.aftServiceCd = this.m_AfterTvBaseModel.courseCd;
		if (this.m_AfterTvBaseModel.rental)
			req.aftRentalFlg = '1';
		else if (this.m_AfterTvBaseModel.noRental)
			req.aftRentalFlg = this.m_Contract.equipment[0].rentalFlag;
		this.m_ItscomTvService.getContractDate(this, req, (result) => {
			if (result && result.directFlg) {
				this.m_AfterTvBaseModel.directFlg = true;
				this.setViewMode('直接申込', '表示');
				if (result.courseUp === '1')
					this.m_AfterTvBaseModel.startDate = '翌日';
				else
					this.m_AfterTvBaseModel.startDate = '翌月';
			}
		});
		//オプション一覧
		this.m_AfterTvOptionListModel = this.m_AllOptionListModel.filter(item => item.select);
		//表示用設定
		for (const option of this.m_AfterTvOptionListModel) {
			if (option.detail.optionCD === '0102000173')
				this.setViewMode('宝塚', '表示');
		}
		if (this.m_AfterTvOptionListModel.length === 0) {
			this.m_AfterTvOptionListModel.push(new TvOptionListModel())
			this.m_AftOption = true;
		}
		if (this.m_BeforeTvOptionListModel.length === 0) {
			this.m_BeforeTvOptionListModel.push(new TvOptionListModel())
			this.m_BefOption = true;
		}
		this.setViewData('sentenceDataA', this.m_AfterTvOptionListModel.length + 1);
		this.setListData('AfterTvOptionListModel', this.m_AfterTvOptionListModel);
		this.setViewData('sentenceDataB', this.m_BeforeTvOptionListModel.length + 1);
		this.setListData('BeforeTvOptionListModel', this.m_BeforeTvOptionListModel);
		this.m_AfterTvBaseModel.constrAmount = '0';
		//チェックボックス表示判定
		if (this.m_AfterTvBaseModel.terminalCd === '5') {
			this.setViewMode('agree','未承諾');
			this.setViewMode('ケーブルプラスSTB','表示');
		}
		else 
			this.setViewMode('agree','承諾');
		//画面サイズによるレイアウトの設定
		if (window.outerWidth > 440) {
			this.setViewMode('オプション','PC');
		}
		else {
			this.setViewMode('オプション','SP');
		}
		//セキュリティ会社
		if (this.m_AfterTvBaseModel.securityFlg)
			this.m_AfterTvBaseModel.securityDisp = this.m_AfterTvBaseModel.securityComment;
		else
			this.m_AfterTvBaseModel.securityDisp = 'なし';
		//工事
		if (this.m_AfterTvBaseModel.constractionFlg) {
			this.setConstructionFee();
			this.setViewMode('工事エリア','表示');
		}
		else
			this.setViewMode('工事エリア','非表示');
		//キャンペーン
		if (this.m_AfterTvBaseModel.campaignList) {
			this.setViewMode('キャンペーンコード', '有');
			this.m_CampaignGroup = this.m_AfterTvBaseModel.campaignGroup;
			this.m_CampaignListModel = this.m_AfterTvBaseModel.campaignList;
			this.setListData('CampaignListModel', this.m_CampaignListModel);
			// this.setViewMode('初期費用キャンペーン', this.m_TvBaseModel.campaign.subCd1 === '01' ? '有' : '無');
			// if (this.m_TvBaseModel.campaign.subCd1 === '01' && this.m_TvBaseModel.campaign.subCd2 === '03')
			// 	this.m_TvBaseModel.campaign.num1 = Number(this.m_TvBaseModel.constrAmount);
		}
		else
			this.setViewMode('キャンペーンコード', '無');
	}
	
	/**
	 * 工事費判定
	 */
	private setConstructionFee() {
		if (this.isExceptConstruction()) {
			const fee = (this.m_MypageWizardMngr.getMGenralList(this, MypageCodeTypeConstants.EXCEPT_CONSTRUCTION_FEE, '')).filter(data => data.generalCd === '1');
			if (fee && fee.length > 0) {
				this.m_AfterTvBaseModel.constrAmount = this.m_MypageWizardMngr.getTaxFee(this, String(fee[0].num1));
				this.m_AfterTvBaseModel.payUnit = '円';
			}
		}
		else {
			const req: GetConstructionCostRequest = new GetConstructionCostRequest();
			req.constructionCategoryCd = '04';
			req.temporaryCostCategoryCd = '01';
			req.multipleFlg = false;
			req.freeFlg = false;
			const provChangeFlg = (this.m_BeforeTvBaseModel.applyType === '購入' && this.m_AfterTvBaseModel.applyType === 'レンタル') ? true: false;
			this.m_MypageWizardMngr.getConstructionCost(this, req, this.m_AfterTvBaseModel.grade, false, provChangeFlg,  result => {
				if (result) {
					if (result.temporaryCost.length > 0) {
						this.m_AfterTvBaseModel.constrAmount = this.m_MypageWizardMngr.getTaxFee(this, result.temporaryCost[0].temporaryCost.toString());
						this.m_AfterTvBaseModel.payUnit = '円';
					}
					else
						this.m_AfterTvBaseModel.constrAmount = 'お見積り';
					this.refreshView();
				}
			});
		}
	}

	/**
	 * 工事費例外
	 * 変更前後：サービス(同じ) && 機器の種類(ケープラSTB ⇔ Hit Pot)の場合
	 */
	private isExceptConstruction(): boolean {
		if (this.m_BeforeTvBaseModel.course === this.m_AfterTvBaseModel.course) {
			if (this.m_BeforeTvBaseModel.terminal === 'Hit Pot' && this.m_AfterTvBaseModel.terminal === 'ケーブルプラスSTB-2')
				return true;
			else if (this.m_BeforeTvBaseModel.terminal === 'ケーブルプラスSTB-2' && this.m_AfterTvBaseModel.terminal === 'Hit Pot')
				return true;
			else
				return false;
		}
		else
			return false;
	}
	// --------------------------------------------------------------------------
}
