import { IWprDialogViewProps } from '../props/IWprDialogViewProps';
import { WprBaseViewInfo } from '../WprBaseViewInfo';

/**
 * ダイアログ情報基本クラス
 */
export abstract class WprBaseDialogViewInfo extends WprBaseViewInfo {
	// private 変数  ------------------------------------------------------------
	private m_IsOpen: boolean 	= false;	// ダイアログオープン状態
	// --------------------------------------------------------------------------

	// abstract プロパティ  ------------------------------------------------------
	/** ダイアログタイトル */
	public abstract get title(): string;
	/** バリデーション */
	public abstract get validation(): boolean;
	// --------------------------------------------------------------------------

	// virtual プロパティ  ------------------------------------------------------
	/** 子要素バリデーション */
	public get childValidation(): boolean 	{ return false; 	}
	/** ヘッダー使用フラグ */
	public get useHeader(): boolean 		{ return true; 		}
	/** Divider使用フラグ */
	public get useDividers(): boolean 		{ return true; 		}
	/** フッタ使用フラグ */
	public get useFooter(): boolean 		{ return true; 		}
	/** OKボタン使用フラグ */
	public get useOKButton(): boolean 		{ return true; 		}
	/** キャンセルボタン使用フラグ */
	public get useCancelButton(): boolean 	{ return true; 		}
	/** OKボタンラベル */
	public get okButtonLabel(): string 		{ return null; 		}
	/** キャンセルボタンラベル */
	public get cancelButtonLabel(): string 	{ return null; 		}
	/** サブミットID */
	public get submitId(): string 			{ return null; 		}
	// --------------------------------------------------------------------------

	// override プロパティ  ------------------------------------------------------
	/** ダイアログフラグ */
	public get isDialog(): boolean 			{ return true; 		}
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	public constructor(name: string) {
		super(name);
	}
	// --------------------------------------------------------------------------

	// virtual メソッド  ---------------------------------------------------------
	/**
	 * ダイアログ開始通知
	 * 		待機フラグにtrueを返すとダイアログの表示は待たされる。
	 * 		待機中にrestartDialog()を呼び出すとダイアログが表示される。
	 * 		onStartDialog中又は待機中にダイアログをcloseするとダイアログは表示されずに終了する。
	 * @param sendData 送信データ
	 * @returns 待機フラグ 
	 */
	public onStartDialog(sendData: any): boolean {
		return false;
	}

	/**
	 *  基本クラス用ダイアログ表示通知
	 * @param sendData 送信データ
	 */
	 public onBaseShowDialog(sendData: any): void {}

	 /**
	 * ダイアログ表示通知
	 * @param sendData 送信データ
	 */
	public onShowDialog(sendData: any): void {}

	/**
	 * ダイアログOKイベント
	 * ダイアログを閉じる場合は、this.close()を呼び出す
	 */
	public onDialogOK(): void {
		this.close(true);
	}

	/**
	 * ダイアログキャンセルイベント通知
	 * ダイアログを閉じる場合は、this.close()を呼び出す
	 */
	 public onDialogCancel(): void {
		this.close(false);
	}

	/**
	 * ダイアログクローズイベント通知
	 * ボタン以外でダイアログを閉じる場合に発生する
	 * ダイアログを閉じる場合は、this.close()を呼び出す
	 */
	public onDialogClose(): void {
		this.close(false);
	}
	// --------------------------------------------------------------------------

	// override メソッド  -------------------------------------------------------
	/**
	 * ビュー表示処理
	 * @param isMount マウントフラグ
	 */
	public showView(isMount: boolean = true): void {
		this.setScopeInfo();
		this.FW.view.addViewInfo(this);
		this.initOtherMode();
	}
	
	/**
	 * 表示状態取得
	 */
	 public isShow(): boolean {
		return this.m_IsOpen;
	}
	// --------------------------------------------------------------------------

	// public メソッド  -------------------------------------------------------
	/**
	 * オープン処理
	 * @param sendData 
	 */
	public open(sendData: any): void {
		this.m_IsOpen = true;
		this.setScopeInfo();
		this.injectionObject(sendData);
		this.isInit = true;
		this.clearWaitSendData();
		try {
			this.onBaseShowDialog(sendData);
			this.onShowDialog(sendData);
// ATODE ダイアログでもSubViewできるようにする
//			this.subViewMap.forEach((subView, key, map) => {
//				subView.onShowDialog(sendData);
//			});
		}
		catch (ex) {
			this.addErrorLog(`ダイアログの表示処理(showDialog)で例外が発生しました。[${this.objectName}]`, ex);
		}
		finally {
			this.isInit = false;
		}
		this.showMode();
		if (this.FW.view.startValidate) {
			this.checkValidate(this.FW.view.startValidate, true, false, true);
			if (this.childValidation === true)
				this.checkChildValidate(this.FW.view.startValidate, true, false, true);
		}
}

	/**
	 * ダイアログイベント
	 * @param event イベント
	 */
	public onDialogEvent(event: boolean): void {
		if (event === true) {
			if (this.validation === true) {
				let sts = this.checkValidate('OK', true, false, true);
				if (this.childValidation === true) {
					if (this.checkChildValidate('OK', true, false, true) === false)
						sts = false;
				}
				if (sts === false)
					return;
			}
			this.onDialogOK();
		}
		else {
			this.onDialogCancel();
		}
	}
	// --------------------------------------------------------------------------

	// protected メソッド  -------------------------------------------------------
	/**
	 * ダイアログクローズ処理
	 * @param status 結果
	 * @param resultData 結果データ
	 */
	protected close(status: boolean, resultData: any = null): void {
		this.m_IsOpen = false;
		const props = this.props as IWprDialogViewProps;
		props.view.closeDialog(props.name, status, resultData);
		this.clearWaitSendData();
	}

	/**
	 * ダイアログ再開処理
	 */
	protected restartDialog(): void {
		const props = this.props as IWprDialogViewProps;
		props.view.restartWaitDialog(props.name);
	}
	// --------------------------------------------------------------------------
}
