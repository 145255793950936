import React, { ReactNode } from 'react';
import { WprBaseViewComponent } from '../../../../../wpr-framework/view/component/WprBaseViewComponent';
import { ItscomHomeSelect1View } from './ItscomHomeSelect1View';
import { ProgressBar } from '../../../common/progress_bar/ProgressBar';
import { Divider, Grid, Typography, TableCell, Table, TableBody, TableHead, TableRow, Card, TableContainer } from '@material-ui/core';
import { MatCheckBox } from '../../../../../wpr-framework/component/controls/material/MatCheckBox';
import { MatButton } from '../../../../../wpr-framework/component/controls/material/MatButton';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { WprRowInfo } from '../../../../../wpr-framework/component/props/WprRowInfo';
import { MatTableRow } from '../../../../../wpr-framework/component/controls/material/MatTableRow';
import { MatTypography } from '../../../../../wpr-framework/component/controls/material/MatTypography';
import { MatTableContainer } from '../../../../../wpr-framework/component/controls/material/MatTableContainer';
import { WprDiv } from '../../../../../wpr-framework/component/controls/WprDiv';
import { WprContainer } from '../../../../../wpr-framework/component/controls/WprContainer';
import helpOrangeIcon from '/shared/image/common/icon_Help_bgLigntOrange.png';


/**
 * ITSCOM HOME_追加申し込み(コース選択)用コンポーネント
 */
export class ItscomHomeSelect1 extends WprBaseViewComponent {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: any) { super(new ItscomHomeSelect1View(), props); }
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** ITSCOM HOME_追加申し込み(コース選択)ビュー情報 */
	public get viewInfo(): ItscomHomeSelect1View	{ return this.view as ItscomHomeSelect1View;	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * リストコントロール定義登録
	*/
	protected onRegisterListRender(): void {
		this.addListRender('CourseListModel', null, this.onCourseListModelRender);
	}

	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		return (
			<>
			<div>
				<div className='pageCard'>
					<div className="pageHeader stepFlow">
						<ProgressBar name='progressBar' view={this.view} />
					</div>
					<div className='main'>
						<div className='pageTitle'>
							<div className='title_left'>
								<MatTypography name='title' className='title_text' view={this.view}/>
								<Typography className='title_sentence'>ご希望のコースを選択してください。 <a href='https://www.itscom.co.jp/service/itscomhome/' target='_blank'><img src={helpOrangeIcon} alt=''/></a>
								</Typography>
							</div>
						</div>
						<Grid container>
							<Grid item xs={12}>
								<MatTableContainer listName={'CourseListModel'} view={this.view}>
									<Table className = 'courseTable'>
										<colgroup>
											<col style={{width: '20%'}}/>
											<col style={{width: '40%'}}/>
											<col style={{width: '40%'}}/>
										</colgroup>
										<TableHead className='hedderrow'>
											<TableRow className='toptable'>
												<TableCell align='center'>選択</TableCell>
												<TableCell align='center'>コース名</TableCell>
												<TableCell align='center'>月額（税込）</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{this.renderList('CourseListModel')}
										</TableBody>
									</Table>
								</MatTableContainer>
							</Grid>
						</Grid>
						<ul className='ul_kome attention_fontSize'>
							<WprContainer mode='オーナー契約.IH' view={this.view}>
								<li className='redText'>
									1 アパートメント契約のお客さまの料金については、メールにてご案内いたします。
								</li>
							</WprContainer>
							<li>
								お客さまのご住所および建物の設備状況によっては、サービスをご利用になれない場合や、ご覧になれない機能があります。
							</li>
							<li>
								イッツコムのサービスをご利用ではなく、イッツコム テレビ・プッシュを単体でご利用の場合、月額利用料金の１台目は1,045円（税込）となります。
							</li>
							<li>
								表示されている月額は、実際の請求金額とは異なる場合があります。
							</li>
						</ul>
					</div>
					<Divider className='select1_underSpace'/>
					<div className='pageFooter footer-space'>
						<div>
							<MatButton name='next' view={this.view} className='orangeButton orangeButton_parts' >
								次へ
								<NavigateNextIcon />
							</MatButton>
						</div>
						<div>
							<MatButton name='back' view={this.view} className='backButton' >
								<NavigateBeforeIcon />
								戻る
							</MatButton>
						</div>
					</div>
				</div>
			</div>
			</>
		);
	}
	// --------------------------------------------------------------------------

	// リスト描画  ---------------------------------------------------------------
	/**
	 * リスト名=CourseListModel
	 */
	public onCourseListModelRender(rowInfo: WprRowInfo): any {
		return (
			<MatTableRow className='courseMobile' view={this.view} row={rowInfo}>
				<TableCell align='center'>
					<MatCheckBox name='check1' color='primary' view={this.view} row={rowInfo}/>
				</TableCell>
				<TableCell>
					<MatTypography name='course' className='listPart' view={this.view} row={rowInfo}/>
				</TableCell>
				<TableCell align='center'>
					<WprDiv name='otherPrice' view={this.view} row={rowInfo}>
						<MatTypography name='amount' className='listPart font_bold' view={this.view} row={rowInfo}/>
						<span className='feeUnit'>円</span>
					</WprDiv>
					<WprDiv name='ownerIhPrice' view={this.view} row={rowInfo}>
						<Typography className='listPart font_bold'>-円<span className='ownerIh_kome'> ※1</span></Typography>
					</WprDiv>
				</TableCell>
			</MatTableRow>
		);
	}
	// --------------------------------------------------------------------------
}
