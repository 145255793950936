import { WprBaseConverter } from '../../../../wpr-framework/view/convert/WprBaseConverter';

/**
 * 必要/不要
 */
export class NeedNotNeedConverter extends WprBaseConverter {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() {
		super('必要/不要');
	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * 値を表示用の値に変換する (value -> viewValue)
	 * @param value 値
	 * @returns 表示用の値
	 */
	public convertView(value: any): string {
		if (value === true) {
			return '必要';
		}
		else if (value === false) {
			return '不要';
		}
		return '';
	}
	// --------------------------------------------------------------------------
}
