// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@charset \"UTF-8\";\n/*\r\n * お問い合わせ用CSS\r\n */\n.CustomerSupport-fontBold .MuiTypography-body1 {\n  font-weight: 500; }\n", ""]);
// Exports
exports.locals = {
	"fontBold": "CustomerSupport-fontBold"
};
module.exports = exports;
