import { GetSimulationResponse } from "../../../service/models/dto/mypagerenewal/GetSimulationResponse";
import { WorkScheduleList } from "../../iss/getMypWorkScheduleList/WorkScheduleList";
import { WorkPossibleList } from "../../iss/getVacantMypWorkSchedule/WorkPossibleList";
import { DayOfWeekModel } from "./DayOfWeekModel";

/**
 * 工事リストモデル
 */
export class ConstrDateListModel {
	// public 変数	-----------------------------------------------------------
	public select: boolean;								// 選択
	public type: string;								// 工事区分(テレビ、ネット等)
	public dayOfWeek: string;							// 曜日
	public dayOfWeekDetail: DayOfWeekModel;				// 曜日
	public preferredTime: string;						// 時間
	public workScheduleList: WorkScheduleList;			// 工事予定詳細
	public workPossibleList: WorkPossibleList;			// 工事希望詳細
	// ------------------------------------------------------------------------
}
