import { ReactNode } from 'react';
import { WprBaseViewComponent } from '../../../../wpr-framework/view/component/WprBaseViewComponent';
import { S_01_003View } from './S_01_003View';
import { WprRowInfo } from '../../../../wpr-framework/component/props/WprRowInfo';
import { MatTableRow } from '../../../../wpr-framework/component/controls/material/MatTableRow';
import { Divider, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import { MatButton } from '../../../../wpr-framework/component/controls/material/MatButton';
import { MatTypography } from '../../../../wpr-framework/component/controls/material/MatTypography';
import { MatTableSortLabel } from '../../../../wpr-framework/component/controls/material/MatTableSortLabel';
import { MatTableContainer } from '../../../../wpr-framework/component/controls/material/MatTableContainer';
import { Header } from '../../common/header/Header';
import { Footer } from '../../common/footer/Footer';
import styles from './S_01_003.scss';
import { WprContainer } from '../../../../wpr-framework/component/controls/WprContainer';
import { MatLink } from '../../../../wpr-framework/component/controls/material/MatLink';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { WprAnchor } from '../../../../wpr-framework/component/controls/WprAnchor';

/**
 * 契約一覧用コンポーネント
 */
export class S_01_003 extends WprBaseViewComponent {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: any) { super(new S_01_003View(), props); }
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** 契約一覧ビュー情報 */
	public get viewInfo(): S_01_003View { return this.view as S_01_003View; }
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * スタイルオブジェクト取得
	*/
	public getStylesObject(): any { return styles; }

	/**
	 * リストコントロール定義登録
	*/
	protected onRegisterListRender(): void {
		this.addListRender('ApplicationList', '画面サイズ大', this.onHDocConfirmListRender);
		this.addListRender('ApplicationList', '画面サイズ小', this.onHDocConfirmListSmartphoneRender);
	}
	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		return (
			<>

				<div>
					<div className='pageCard'>
						<div className="pageHeader title">
							契約内容一覧
						</div>
						<Divider />
						<div className='pageContent'>
							<div className='box_gray'>
								<div className='boxContent'>
									<ul className='ul_ten_gray'>
										<li>
											<span>2022年10月11日以前に作成された契約内容確認書は表示されません。</span>
										</li>
										<li>
											契約内容確認書は現在のご契約とは必ずしも一致いたしません。
										</li>
										<li>
											最新のご契約情報を確認する場合には<WprAnchor name='OnL_MyPageUrl' view={this.view} className='link_underline_blue'>こちら</WprAnchor>をご参照ください。
										</li>
									</ul>
								</div>
							</div>
							<div>

								<WprContainer mode='画面サイズ.大' view={this.view}>
									<Table className='table_header_gray'>
										<colgroup>
											<col style={{ width: '30%' }} />{/* 契約名 */}
											<col style={{ width: '25%' }} />{/* 申込日 */}
											<col style={{ width: '25%' }} />{/* 発行日 */}
											<col style={{ width: '20%' }} />{/*  */}
										</colgroup>
										<TableHead className='tableHeader'>
											<TableRow>
												<TableCell align='center'>
													<MatTableSortLabel listName='ApplicationList' item='appID' label='申込書ID' view={this.view} />
												</TableCell>
												<TableCell align='center'>
													<MatTableSortLabel listName='ApplicationList' item='appDate' label='申込日' view={this.view} />
												</TableCell>
												<TableCell align='center'>
													<MatTableSortLabel listName='ApplicationList' item='printDate' label='発行日' view={this.view} />
												</TableCell>
												<TableCell align='center'>
												</TableCell>
											</TableRow>
										</TableHead>
										<TableBody className='tableBody'>
											<WprContainer mode='契約内容一覧.有' view={this.view}>
												{this.renderList('ApplicationList', '画面サイズ大')}
											</WprContainer>
											<WprContainer mode='契約内容一覧.無' view={this.view}>
												<TableRow >
													<TableCell colSpan={4} align='center'>
														<div>
															対象となるデータはございません。
														</div>
													</TableCell>
												</TableRow>
											</WprContainer>
										</TableBody>
									</Table>
								</WprContainer>

								<WprContainer mode='画面サイズ.小' view={this.view}>
									<Table className='table_header_gray'>

										<WprContainer mode='契約内容一覧.有' view={this.view}>
											{this.renderList('ApplicationList', '画面サイズ小')}
										</WprContainer>

										<WprContainer mode='契約内容一覧.無' view={this.view}>
											<TableHead className='tableHeader'>
												<TableRow>
													<TableCell align='center'>
														<MatTableSortLabel listName='ApplicationList' item='appID' label='申込書ID' view={this.view} />
													</TableCell>
													<TableCell align='center'>
														<MatTableSortLabel listName='ApplicationList' item='appDate' label='申込日' view={this.view} />
													</TableCell>
													<TableCell align='center'>
														<MatTableSortLabel listName='ApplicationList' item='printDate' label='発行日' view={this.view} />
													</TableCell>
												</TableRow>
											</TableHead>
											<TableBody className='tableBody'>
												<TableRow >
													<TableCell colSpan={3} align='center'>
														<div>
															対象となるデータはございません。
														</div>
													</TableCell>
												</TableRow>
											</TableBody>
										</WprContainer>
									</Table>
								</WprContainer>
								{/* <MatTableContainer listName='ApplicationList' view={this.view} noDataMessage='閲覧可能な契約内容確認書はありません'> */}
								{/* </MatTableContainer> */}
							</div>



							<div className='pageFooter confirmFooter'>
								<div>
									<MatButton name='next' view={this.view} className='nextButton nextButtonSub backContInfoList' >
										<NavigateBeforeIcon />
										契約内容の確認・変更メニューへ
									</MatButton>
								</div>
							</div>
						</div>
					</div>
				</div>

			</>
		);
	}
	// --------------------------------------------------------------------------

	// リスト描画  ---------------------------------------------------------------
	/**
	 * リスト名=HDocConfirmList2
	 */
	public onHDocConfirmListRender(rowInfo: WprRowInfo): any {
		return (
			<MatTableRow view={this.view} row={rowInfo} >
				{/* 契約名 */}
				<TableCell align='center'>
					{/* <span className='headding'>申込書ID</span> */}
					<MatTypography name='appID' view={this.view} row={rowInfo} />
				</TableCell>
				{/* 申込日 */}
				<TableCell align='center'>
					{/* <span className='headding'>申込日</span> */}
					<MatTypography name='appDate' view={this.view} row={rowInfo} />
				</TableCell>
				{/* 発行日 */}
				<TableCell align='center'>
					{/* <span className='headding'>発行日</span> */}
					<MatTypography name='printDate' view={this.view} row={rowInfo} />
				</TableCell>
				{/* ボタン */}
				<TableCell align='center'>
					<MatButton name='b_ShowPDF' variant='contained' view={this.view} row={rowInfo} className='pdfButton'>PDF</MatButton>
				</TableCell>
			</MatTableRow>
		);
	}
	/**
	 * リスト名=HDocConfirmList2
	 */
	public onHDocConfirmListSmartphoneRender(rowInfo: WprRowInfo): any {
		return (
			<>
				<TableHead className='tableHeader'>
					<TableRow>
						<TableCell align='center'>
							<Typography>申込書ID</Typography>
							{/* <MatTableSortLabel listName='ApplicationList' item='appID' label='' view={this.view} /> */}
						</TableCell>
						<TableCell align='center'>
							<Typography>申込日</Typography>
							{/* <MatTableSortLabel listName='ApplicationList' item='appDate' label='' view={this.view} /> */}
						</TableCell>
						<TableCell align='center'>
							<Typography>発行日</Typography>
							{/* <MatTableSortLabel listName='ApplicationList' item='printDate' label='' view={this.view} /> */}
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody className='tableBody'>

					<MatTableRow view={this.view} row={rowInfo} >
						{/* 契約名 */}
						<TableCell align='center'>
							{/* <span className='headding'>申込書ID</span> */}
							<MatTypography name='appID' view={this.view} row={rowInfo} />
						</TableCell>
						{/* 申込日 */}
						<TableCell align='center'>
							{/* <span className='headding'>申込日</span> */}
							<MatTypography name='appDate' view={this.view} row={rowInfo} />
						</TableCell>
						{/* 発行日 */}
						<TableCell align='center'>
							{/* <span className='headding'>発行日</span> */}
							<MatTypography name='printDate' view={this.view} row={rowInfo} />
						</TableCell>
					</MatTableRow>
					<MatTableRow view={this.view} row={rowInfo} >
						{/* ボタン */}
						<TableCell align='center' colSpan={3}>
							<MatButton name='b_ShowPDF' variant='contained' view={this.view} row={rowInfo} className='pdfButton'>PDF</MatButton>
						</TableCell>
					</MatTableRow>
				</TableBody>
			</>

		);
	}
	// --------------------------------------------------------------------------
}
