import React, { ReactNode } from 'react';
import { WprBaseViewComponent } from '../../../../../wpr-framework/view/component/WprBaseViewComponent';
import { ModNetSelectView } from './ModNetSelectView';
import { Grid, Typography, TableCell, Table, TableBody, TableHead, TableRow, Divider } from '@material-ui/core';
import { MatTypography } from '../../../../../wpr-framework/component/controls/material/MatTypography';
import { ProgressBar } from '../../../common/progress_bar/ProgressBar';
import { WprRowInfo } from '../../../../../wpr-framework/component/props/WprRowInfo';
import { MatTableRow } from '../../../../../wpr-framework/component/controls/material/MatTableRow';
import { MatCheckBox } from '../../../../../wpr-framework/component/controls/material/MatCheckBox';
import { MatTableContainer } from '../../../../../wpr-framework/component/controls/material/MatTableContainer';
import { MatButton } from '../../../../../wpr-framework/component/controls/material/MatButton';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { WprContainer } from '../../../../../wpr-framework/component/controls/WprContainer';
import nextIcon from '/shared/image/common/next_icon_orange.png';
import netIcon from '/shared/image/common/logo_iTSCOMNET.png';
import errorIcon from '/shared/image/common/icon_error.png';
import warningIcon from '../../../../../../shared/image/common/important_yellow.png';

/**
 * ネット変更コース選択用コンポーネント
 */
export class ModNetSelect extends WprBaseViewComponent {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: any) { super(new ModNetSelectView(), props); }
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** ネット変更コース選択ビュー情報 */
	public get viewInfo(): ModNetSelectView	{ return this.view as ModNetSelectView;	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * リストコントロール定義登録
	*/
	protected onRegisterListRender(): void {
		this.addListRender('CourseListModel','PC', this.onCourseListPcModelRender);
		this.addListRender('CourseListModel','SP', this.onCourseListSpModelRender);
		this.addListRender('BaseInfoList', null, this.onBaseInfoListRender);
		this.addListRender('ErrorModel', null, this.onErrorModelRender);
	}

	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		return (
			<>
				<div className='pageCard'>
					<div className="pageHeader stepFlow">
						<ProgressBar name='progressBar' view={this.view} />
					</div>
					<div className='sub-logo2'>
						<img src={netIcon} />
					</div>
					<div className='main net'>
						<div className='pageTitle'>
							<div className='title_left'>
								<MatTypography name='title' className='title_text' view={this.view}/>
								<Typography className='title_sentence'>ご希望のコースを選択してください。</Typography>
							</div>
							<WprContainer mode='エラー.表示' view={this.view}>
								<div className='errorArea'>
									<span className='parallel'>
										<img src={errorIcon} alt='' className='x_img'/>
										<div className='errorList'>
											{this.renderList('ErrorModel')}
										</div>
									</span>
								</div>
							</WprContainer>
							<WprContainer mode={'AP用メッセージ.表示'} view={this.view}>
								<div className='warning_msg' style={{flexDirection: 'column', marginTop: '15px'}}>
									<div style={{display: 'flex'}}>
										<img src={warningIcon} style={{maxWidth: '100%'}} />
										<Typography className='title_memo'>イッツコムアパートメント無料コースをご利用のお客さまへ</Typography>
									</div>
									<Typography style={{fontSize: '16px', margin: '15px 0'}}>「月額利用料金の改定」について</Typography>
									<div className='attention_fontSize' style={{margin: '0'}}>
										■改定時期および改定後料金<br/>
										<span style={{fontWeight: '600'}}>2024年9月利用料金</span>（10月ご請求分）より月額利用料金を以下の通り改定いたします。<br/>
										なお2024年7月31日までにお得パック等長期契約プランのご利用を開始されている場合、<span style={{fontWeight: '600'}}>2024年9月以降の長期契約プラン更新後</span>より料金を改定いたします。
									</div>
									<ul className='ul_ten attention_fontSize' style={{margin: '5px 0 0 0'}}>
										<li>
											テレビサービス：ご契約1世帯あたり <span style={{fontWeight: '600'}}>360円（税込396円）増額</span>
										</li>
										<li>
											インターネットサービス：ご契約1世帯あたり <span style={{fontWeight: '600'}}>360円（税込396円）増額</span>
										</li>
										<li>
											テレビ・インターネットサービス両方：ご契約1世帯あたり <span style={{fontWeight: '600'}}>360円（税込396円）増額</span>
										</li>
									</ul>
									<ul className='ul_kome' style={{margin: '0 0 25px 10px', fontSize: '10px'}}>
										<li>
											テレビ・インターネットの両方をご利用の場合でも、ご契約1世帯あたり360円（税込396円）が増額となります。
										</li>
										<li>
											イッツコムアパートメント無料コースでは今回の料金改定はございません。
										</li>
										<li>
											テレビ、インターネット以外のサービス（固定電話など）につきましては、今回の料金改定はございません。
										</li>
									</ul>
									<div className='attention_fontSize' style={{margin: '0'}}>
										■契約内容確認書の表示に関する注意点<br/>
										2024年9月1日のシステム改修後は、テレビ・インターネットが料金改定後の金額表示となります。<br/>
										長期プランを契約中の場合、次回更新までは料金改定前の現行料金が適用されます。
									</div>
									<ul className='ul_kome' style={{margin: '0 0 15px 0', fontSize: '10px'}}>
										<li>
											更新時期はマイページにてご確認頂けます。
										</li>
									</ul>
									<div className='attention_fontSize' style={{margin: '0'}}>
										詳細につきましては以下でもご案内しております。<br/>
										<a href='https://www.itscom.co.jp/news/information/20240417_A1085_02.html' target='_blank'>＜テレビ・インターネットサービスの月額利用料金改定のお知らせ＞</a>
									</div>
								</div>
							</WprContainer>
							<div className='itscomNet_img whitespace'>
								{/* FTTHの場合 */}
								<WprContainer mode='インターネットコース変更.FTTH' view={this.view}>
									<a href='https://www.itscom.co.jp/support/contract_change/change_course/internet.html' className='courseUrl' target='_blank' >
										<b>インターネットのコース変更について　</b>
										<img src={nextIcon} alt='' className='orange_circleArrow'/>
									</a>
								</WprContainer>
								{/* FTTH以外の場合 */}
								<WprContainer mode='インターネットコース変更.FTTH以外' view={this.view}>
									<a href='https://www.itscom.co.jp/support/contract_change/change_course/internet_cable.html' className='courseUrl' target='_blank' >
										<b>インターネットのコース変更について　</b>
										<img src={nextIcon} alt='' className='orange_circleArrow'/>
									</a>
								</WprContainer>
							</div>
							<div className='itscomNet_img'>
								{/* FTTHの場合 */}
								<WprContainer mode='コース一覧.FTTH' view={this.view}>
									<a href='http://www.itscom.net/hikari/service/net/' className='courseUrl' target='_blank'>
										<b>コース一覧　</b>
										<img src={nextIcon} alt='' className='orange_circleArrow'/>
									</a>
								</WprContainer>
								{/* FTTH以外の場合 */}
								<WprContainer mode='コース一覧.FTTH以外' view={this.view}>
									<a href='http://www.itscom.net/service/internet/index.html' className='courseUrl' target='_blank'>
										<b>コース一覧　</b>
										<img src={nextIcon} alt='' className='orange_circleArrow'/>
									</a>
								</WprContainer>
							</div>
							<div className='title_maru' style={{marginTop:'30px'}}>現在のご契約</div>
							<div className='constractTable'>
								<Grid container className='feeRow'>
									<Grid item xs={12} md={5} className='option'>
										<MatTypography name='contractCourse' className='courseName' view={this.view}/>
										{/* <Typography className='courseName'>(月額<MatTypography name='contractAmount' className='courseName' view={this.view}/>円)</Typography> */}
									</Grid>
									<Grid item xs={12} md={7} className='optstatus'>
										<MatTypography name='contractStatus' className='itemUnit_bold' view={this.view}/>
									</Grid>
								</Grid>	
							</div>
							<Grid container>
								<Grid item xs={12}>
									<MatTableContainer listName={'BaseInfoList'} view={this.view}>
										<Table className ='optionTable'>
											<colgroup>
												<col/>
												<col/>
											</colgroup>
											<TableBody className='optionBody'>
												{this.renderList('BaseInfoList')}
											</TableBody>
										</Table>
									</MatTableContainer>
								</Grid>
							</Grid>
						</div>
						<div className='title_maru'>変更申込み内容</div>
						<Grid container>
							<Grid item xs={12}>
								<MatTableContainer listName={'CourseListModel'} view={this.view} noDataUseHeader noDataMessage=' '>
									<WprContainer mode='コース.PC' view={this.view}>
										{/* PC用 */}
										<Table className='optionSelsctPcTable'>
											<colgroup>
												<col style={{width: '10%'}}/>
												<col style={{width: '30%'}}/>
												<col style={{width: '20%'}}/>
												<col style={{width: '30%'}}/>
											</colgroup>
											<TableHead className='hedderrow'>
												<TableRow className='toptable'>
													<TableCell align='center' className='border_right font_bold'>選択</TableCell>
													<TableCell align='center' className='border_right font_bold'>コース名</TableCell>
													<TableCell align='center' className='border_right font_bold'>月額料金</TableCell>
													<TableCell align='center' className='font_bold'>回線種別</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												<WprContainer mode='データなし.表示' view={this.view}>
													<TableRow>
														<TableCell className='non_list' colSpan={5}>データが存在しませんでした</TableCell>
													</TableRow>
												</WprContainer>
												<WprContainer mode='光回線データなし.表示' view={this.view}>
													<TableRow>
														<TableCell className='non_list' colSpan={5}>申込み可能なコースはございません</TableCell>
													</TableRow>
												</WprContainer>
												{this.renderList('CourseListModel', 'PC')}
											</TableBody>
										</Table>
									</WprContainer>
									<WprContainer mode='コース.SP' view={this.view}>
										{/* SP用 */}
										<Table className='optionSelsctSpTable'>
											<colgroup>
												<col style={{width: '20%'}}/>
												<col style={{width: '80%'}}/>
											</colgroup>
											<TableHead className='hedderrow'>
												<TableRow className='toptable'>
													<TableCell rowSpan={4} align='center' className='border_right font_bold'>選択</TableCell>
													<TableCell rowSpan={1} align='center' className='font_bold'>コース名</TableCell>
												</TableRow>
												<TableRow className='toptable'>
													<TableCell rowSpan={1} align='center' className='font_bold'>月額料金</TableCell>
												</TableRow>
												<TableRow className='toptable'>
													<TableCell rowSpan={1} align='center' className='font_bold'>回線種別</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												<WprContainer mode='データなし.表示' view={this.view}>
													<TableRow>
														<TableCell className='non_list' colSpan={5}>データが存在しませんでした</TableCell>
													</TableRow>
												</WprContainer>
												<WprContainer mode='光回線データなし.表示' view={this.view}>
													<TableRow>
														<TableCell className='non_list' colSpan={5}>申込み可能なコースはございません</TableCell>
													</TableRow>
												</WprContainer>
												{this.renderList('CourseListModel', 'SP')}
											</TableBody>
										</Table>
									</WprContainer>
								</MatTableContainer>
							</Grid>
						</Grid>
						<WprContainer mode='その他コースエリア.FTTH以外' view={this.view}>
							<div className='itscomNet_img' style={{marginTop:'20px'}}>
								<a href='https://www.itscom.co.jp/service/internet/course/hikari/' className='courseUrl' target="_blank">
									<b>その他のインターネットのコース変更について　</b>
									<img src={nextIcon} alt='' className='orange_circleArrow'/>
								</a>
							</div>
							<div className='feeTable'>
								<Grid container className='feeRow'>
									<Grid item xs={12} md={4} className='feeTitle border_right'>
										<div className='otherCourse'>その他のコース</div>
									</Grid>
									<Grid item xs={12} md={8} className='alignCenter courseValue'>
										<MatButton name='other' className='otherCourseButtom' variant='contained' view={this.view}>その他のコースはこちら</MatButton>
									</Grid>
								</Grid>
							</div>
						</WprContainer>
						<div className='attention_fontSize'>
							<ul className='ul_kome'>
								<li>
									IPアドレス種別(プライベートIP/グローバルIP)は、コース変更後もそのまま引き継がれます。変更ご希望の場合は、オプションのIPアドレス種別変更からお申込みください。
								</li>
								<li>
									お客様のご住所および建物の設備状況によっては、ご利用になれない場合がございます。
								</li>
								<WprContainer mode='注釈FTTH以外.表示' view={this.view}>
									<li>
										auスマートバリューをご利用中の場合、かっとびメガ１６０、かっとびワイド、かっとびMANSION LAN以外は割引対象外となりますので、コース変更の際は、ご注意ください。
									</li>
								</WprContainer>
								<li>
									各端末の月額利用料については<a href='https://www.itscom.co.jp/info/covenant/gjslkq0000003us8-att/itscom_price.pdf' target='_blank'>こちら</a>をご確認ください。
									<WprContainer mode='注釈お得パック契約中.表示' view={this.view}>
										<br/>契約期間中にお得パック対象サービスの一部または全部をコース変更または解約する場合、契約違約金がかかります（更新月を除く）。
									</WprContainer>
								</li>
								<li>
									お得パックご利用中のお客さまにおかれましては、お得パックの内容を組みかえて、契約変更させていただきます。<br/>その際、お得パックの利用期間は、組みかえさせていただきました翌月からの起算に変更となりますので、ご了承ください。
									なお、利用機器に変更が必要な場合や、別途お得パックの適用が外れる場合など、確認事項が発生いたしました際には、別途ご連絡いたします。
								</li>
								<li>
									2年コースへの変更：契約期間中にコース変更または解約する場合、1ヶ月分の対象コース利用料が契約解約料として発生いたします。（更新月、更新翌月と翌々月を除く）
								</li>
								<li>
									2年コース内の変更：変更の翌月が契約期間の開始月（1ヶ月目）に変更となります。（契約違約料は発生しません）
								</li>
								<li>
									コース変更により「イッツコム 電気ぐっと割」の割引額が変更になる場合があります。詳細は<a href='https://www.itscom.co.jp/service/energy/denkiguttowari.html' target='_blank'>こちら</a>をご確認ください。
								</li>
								<li>
									イッツコムインターネットサービス160M以上のコース、かっとびMANSION LAN、かっとび光でご利用いただけます。（※そのほか一部物件は除く）
								</li>
							</ul>
						</div>
					</div>
					<Divider className='select1_underSpace'/>
					<div className='pageFooter footer-space'>
						<div>
							<MatButton name='next' view={this.view} className='orangeButton orangeButton_parts' >
								次へ
								<NavigateNextIcon />
							</MatButton>
						</div>
						<div>
							<MatButton name='back' view={this.view} className='backButton' >
								<NavigateBeforeIcon />
								戻る
							</MatButton>
						</div>
					</div>
				</div>
			</>
		);
	}
	// --------------------------------------------------------------------------

	// リスト描画  ---------------------------------------------------------------
	/**
	 * リスト名=CourseListModel
	 * PC用
	 */
	public onCourseListPcModelRender(rowInfo: WprRowInfo): any {
		return (
			<MatTableRow view={this.view} row={rowInfo}>
				<TableCell align='center' className='border_right service'>
					<MatCheckBox name='check' view={this.view} row={rowInfo}/>
				</TableCell>
				<TableCell className='border_right service'>
					<MatTypography name='cDispnamep' className='courseSize' view={this.view} row={rowInfo}/>
				</TableCell>
				<TableCell className='border_right service'>
					<MatTypography name='payAmount' className='courseFee' view={this.view} row={rowInfo}/>
					<MatTypography name='payUnit' className='courseUnit' view={this.view} row={rowInfo}/>
				</TableCell>
				<TableCell className='service'>
					<MatTypography name='nDispnamep' className='courseSize service' view={this.view} row={rowInfo}/>
				</TableCell>
			</MatTableRow>
		);
	}

	/**
	 * リスト名=OptListModel
	 * SP用
	 */
	public onCourseListSpModelRender(rowInfo: WprRowInfo): any {
		return (
			<>
				<TableRow>
					<TableCell className='border_right' align='center' rowSpan ={3}> 
						<MatCheckBox name='check' view={this.view} row={rowInfo}/>
					</TableCell>
					<TableCell rowSpan ={1}>
						<MatTypography name='cDispnamep' className='courseSize' view={this.view} row={rowInfo}/>
					</TableCell>
				</TableRow>
				<TableRow className='optionRow'>
					<TableCell rowSpan ={1}>
						<MatTypography name='payAmount' className='courseFee' view={this.view} row={rowInfo}/>
						<MatTypography name='payUnit' className='courseUnit' view={this.view} row={rowInfo}/>
						{/* <span className='courseUnit'>円</span> */}
					</TableCell>
				</TableRow>
				<TableRow>
					<TableCell rowSpan ={1}>
						<MatTypography name='nDispnamep' className='courseSize' view={this.view} row={rowInfo}/>
					</TableCell>
				</TableRow>
			</>
		);
	}

	/**
	 * リスト名=BaseInfoList
	 */
	public onBaseInfoListRender(rowInfo: WprRowInfo): any {
		return (
			<MatTableRow view={this.view} row={rowInfo} className='hedderrow'>
				<TableCell>
					<MatTypography name='itemName' view={this.view} row={rowInfo}/>
				</TableCell>
				<TableCell className='optionDetail'>
					<MatTypography name='itemData' className='itemData' view={this.view} row={rowInfo}/>
				</TableCell>
			</MatTableRow>
		);
	}

	/**
	 * リスト名=ErrorModel
	 */
	public onErrorModelRender(rowInfo: WprRowInfo): any {
		return (
			<div>
				<MatTypography name='errorMessage' view={this.view} row={rowInfo}/>
			</div>
		);
	}
	// --------------------------------------------------------------------------
}
