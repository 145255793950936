import React, { ReactNode } from 'react';
import { WprBaseViewComponent } from '../../../../../wpr-framework/view/component/WprBaseViewComponent';
import { AddNetSettingView } from './AddNetSettingView';
import { Grid, Divider, Typography } from '@material-ui/core';
import { WprContainer } from '../../../../../wpr-framework/component/controls/WprContainer';
import { WprSpan } from '../../../../../wpr-framework/component/controls/WprSpan';
import { MatButton } from '../../../../../wpr-framework/component/controls/material/MatButton';
import { MatCheckBox } from '../../../../../wpr-framework/component/controls/material/MatCheckBox';
import { MatTypography } from '../../../../../wpr-framework/component/controls/material/MatTypography';
import { ProgressBar } from '../../../common/progress_bar/ProgressBar';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import styles from './AddNetSetting.scss';
import netIcon from '/shared/image/common/logo_iTSCOMNET.png';
import orangeIcon from '/shared/image/common/icon_Help_bgLigntOrange.png';
import nextIcon from '/shared/image/common/next_icon_orange.png';
import importantIcon from '/shared/image/common/important_gray.png';
import errorIcon from '/shared/image/common/icon_error.png';
import { MatDatePicker } from '../../../../../wpr-framework/component/controls/material/MatDatePicker';
import { MatSelect } from '../../../../../wpr-framework/component/controls/material/MatSelect';
import { WprViewError } from '../../../../../wpr-framework/component/controls/WprViewError';
import { MatTextField } from '../../../../../wpr-framework/component/controls/material/MatTextField';
import { WprErrorContainer } from '../../../../../wpr-framework/component/controls/WprErrorContainer';
import { WprRowInfo } from '../../../../../wpr-framework/component/props/WprRowInfo';
/**
 * コース内容の選択用コンポーネント
 */
export class AddNetSetting extends WprBaseViewComponent {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: any) { super(new AddNetSettingView(), props); }
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** コース内容の選択ビュー情報 */
	public get viewInfo(): AddNetSettingView	{ return this.view as AddNetSettingView;}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * スタイルオブジェクト取得ます
	*/
	public getStylesObject(): any { return styles; }

	/**
	 * リストコントロール定義登録
	*/
	protected onRegisterListRender(): void {
		this.addListRender('ErrorListModel', null, this.onErrorListModelRender);
	}

	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		return (
			<>
			<div className='pageCard'>
				<div className='pageHeader stepFlow'>
					<ProgressBar name='progressBar' view={this.view} />
				</div>
				<div className='sub-logo2'>
					<img src={netIcon} />
				</div>
				<div className='main net'>
					<div className='pageTitle'>
						<div className='title_left'>
							<MatTypography name='title' className='title_text' view={this.view}/>
							<Typography className='title_sentence'>ご希望のコースを選択してください。</Typography>
						</div>
						<WprContainer mode='エラー.表示' view={this.view}>
							<div className='errorArea'>
								<span className='parallel'>
									<img src={errorIcon} alt='' className='x_img'/>
									<div className='errorList'>
										{this.renderList('ErrorListModel')}
									</div>
								</span>
							</div>
						</WprContainer>
						<div className='itscomNet_img whitespace'>
							<WprContainer mode='インターネットのコース変更.FTTH' view={this.view}>
								<a href='https://www.itscom.co.jp/support/contract_change/change_course/internet.html' target='_blank' className='courseUrl'>
									<b>インターネットのコース変更について　</b>
									<img src={nextIcon} className='orange_circleArrow'/>
								</a>
							</WprContainer>
							<WprContainer mode='インターネットのコース変更.FTTH以外' view={this.view}>
								<a href='https://www.itscom.co.jp/support/contract_change/change_course/internet_cable.html' target='_blank' className='courseUrl'>
									<b>インターネットのコース変更について　</b>
									<img src={nextIcon} className='orange_circleArrow'/>
								</a>
							</WprContainer>
						</div>
						<div className='feeTable settingBottom'>
							<Grid container className='feeRow'>
								<Grid item xs={12} md={4} className='feeTitle'>
									<div>申込みコース</div>
								</Grid>
								<Grid item xs={12} md={8} className='feeVal courseValue'>
									<WprSpan name='cDispnamep' view={this.view} className='itemUnit'/>
								</Grid>
							</Grid>
							<WprContainer mode='端末の種類.表示' view={this.view}>
								<Grid container className='feeRow'>
									<Grid item xs={12} md={4} className='feeTitle'>
										<div>端末の種類 <img src= {importantIcon} className='impIcon'/><span className='kome'>※1</span></div>
									</Grid>
									<Grid item xs={12} md={8} className='feeVal courseValue checkArea'>
										<MatCheckBox name='cable' color='primary' className='checkbox_size' view={this.view} /><span className='font_black'> ケーブルモデム</span>
										<br/>
										<MatCheckBox name='cableWifi' color='primary' className='checkbox_size' view={this.view} /><span className='font_black'> ケーブルモデム(Wi-Fi)</span>
										<ul className='ul_kome'>
											<li className = 'notes'>ケーブルモデム(Wi-Fi)はレンタル提供のみ</li>
										</ul>
									</Grid>
								</Grid>
							</WprContainer>
							<Grid container className='feeRow'>
								<Grid item xs={12} md={4} className='feeTitle'>
									<div>機器のご提供方法</div>
								</Grid>
								<Grid item xs={12} md={8} className='feeVal courseValue' style={{paddingLeft: '1.1rem'}}>
									<MatCheckBox name='equipmentProvidingCheck' color='primary' className='checkbox_size' view={this.view} /><span className='font_black'> レンタル</span>
								</Grid>
							</Grid>
							<WprContainer mode='お得パック.申込み' view={this.view}>
								<Grid container className='feeRow'>
									<Grid item xs={12} md={4} className='feeTitle'>
										<div>お得パック
											<a href='https://www.itscom.co.jp/service/profit/' target='_blank'><img src= {orangeIcon} alt='' style={{marginLeft: '3px'}}/></a>
										</div>
									</Grid>
									<Grid item xs={12} md={8} className='feeVal courseValue provCheckArea'>
										<span className='itemUnit'>
											<MatCheckBox name='yesOtoku' color='primary' className='checkbox_size' view={this.view}/><span className='itemUnit'>希望する</span>
											<br/>
											<MatCheckBox name='noOtoku' color='primary' className='checkbox_size' view={this.view}/><span className='itemUnit'>希望しない</span>
										</span>
									</Grid>
								</Grid>
							</WprContainer>
							<Grid container className='feeRow'>
								<Grid item xs={12} md={4} className='feeTitle'>
									<div>
										希望場所
										<span className='txtRequired'>*</span>
									</div>
								</Grid>
								<Grid item xs={12} md={8} className='courseValue value' style={{padding: '0.6rem'}}>
									<div className='placeArea'>
										<WprContainer mode='階数.表示' view={this.view}>
											<MatSelect placeholder='(階数)' name='floor' vm='floor' view={this.view} className='floor'/>
										</WprContainer>
										<MatSelect placeholder='(場所)' name='location' vm='location' view={this.view} className='location'/>
									</div>
									<div className='commentArea'>
										<MatTextField placeholder='(場所)' name='comment' view={this.view}/>
									</div>
								</Grid>
							</Grid>
							<Grid container className='feeRow campaignArea'>
								<Grid item xs={12} md={4} className='feeTitle'>
									<div>
										キャンペーンコード
									</div>
								</Grid>
								<Grid item xs={12} md={8} className='feeVal courseValue' style={{padding: '10px'}}>
									<div>
										<MatTextField placeholder='0123456789' name='campaignCode' view={this.view} style={{maxWidth: '200px'}}></MatTextField>
										<Typography style={{fontSize: '10px', color: 'black'}}>※キャンペーンコードをお持ちの場合はご入力ください</Typography>
									</div>
								</Grid>
							</Grid>
						</div>
						<div className='komeArea'>
							<WprContainer mode='FTTH(マンションコース)の場合.補足' view={this.view}>
								<img src= {importantIcon} className='importantIcon'/><span className='kome'>※1</span>
								<Typography className='comment'>ケーブルモデム（Wi-Fi）の場合は、別途月額264円かかります。</Typography>
							</WprContainer>	
							<WprContainer mode='FTTH以外の場合.補足' view={this.view}>
								<img src= {importantIcon} className='importantIcon'/><span className='kome'>※1</span>
								<Typography className='comment'>ケーブルモデム（Wi-Fi）の場合は、別途月額264円かかります。<br/>かっとびメガ160、かっとびワイド、イッツコムひかり30メガ以上のコースは無料でご利用いただけます。</Typography>
							</WprContainer>
						</div>
					</div>
					<div className='title_space'>
						<div className='itemTitle title_maru'>リフォームの確認</div>
						<Typography className='title_comment' style={{marginLeft: '15px'}}>当社にて機器を設置して以降、配線・端子の交換、場所移動を伴うリフォームを行いましたか</Typography>
					</div>
					<div className='title_center'>
						<MatCheckBox name='reformFlg' label='リフォームを行った' view={this.view}/>
					</div>
					<div className='title_space'>
						<div className='itemTitle title_maru'>セキュリティ会社加入の確認</div>
						<Typography className='title_comment' style={{marginLeft: '15px'}}>ホームセキュリティにご加入している場合に工事の際にご連絡が必要な場合があります。</Typography>
					</div>
					<div className='title_center'>
						<div className='title_center'>
							<MatCheckBox name='securityFlg' label='セキュリティ会社に加入しています' view={this.view}/>
							<WprContainer mode={'セキュリティ会社.表示'} view={this.view}>
								<div className='flex'>
									<Typography className='title_comment' style={{marginRight: '15px'}}>	セキュリティ会社</Typography>
									<MatTextField name='securityComment' view={this.view} style={{width: '50%'}}></MatTextField>
								</div>
							</WprContainer>
						</div>
					</div>
					<div className='title_space'>
						<div className='itemTitle title_maru'>TV端子の確認<span className='txtRequired'>*</span></div>
						<Typography className='title_comment' style={{marginLeft: '15px'}}>機器の移動の際に移動先の場所にTV端子があることが必要になります。</Typography>
					</div>
					<div className='title_center'>
						<MatCheckBox name='tvPortFlg' label='移動先の場所にTV端子があることを確認しました' view={this.view}/>
					</div>
					<div className='title_space itemTitle title_maru'>作業の希望日時</div>
					<div className='dateArea'>
						<Grid container spacing={1}>
							<Grid item xs={12} md={6} className='itemTitle2'>
								第一希望
								<span className='txtRequired'>*</span>
								<div className='customerInput'>
									<MatDatePicker name='desiredDate1' view={this.view} placeholder='年/月/日' use='mobile' />
								</div>
							</Grid>
							<Grid item xs={12} md={6} className='itemTitle2'>
								時間帯
								<span className='txtRequired'>*</span>
								<div className='customerInput'>
									<MatSelect name='selectTime1' vm='時間帯' view={this.view} />
								</div>
							</Grid>
							<Grid item xs={12} md={6} className='itemTitle2'>
								第二希望
								<span className='txtRequired'>*</span>
								<div className='customerInput'>
									<MatDatePicker name='desiredDate2' view={this.view} placeholder='年/月/日' use='mobile' />
								</div>
							</Grid>
							<Grid item xs={12} md={6} className='itemTitle2'>
								時間帯
								<span className='txtRequired'>*</span>
								<div className='customerInput'>
									<MatSelect name='selectTime2' vm='時間帯' view={this.view} />
								</div>
							</Grid>
							<Grid item xs={12} md={6} className='itemTitle2'>
								第三希望
								<span className='txtRequired'>*</span>
								<div className='customerInput'>
									<MatDatePicker name='desiredDate3' view={this.view} placeholder='年/月/日' use='mobile' />
								</div>
							</Grid>
							<Grid item xs={12} md={6} className='itemTitle2'>
								時間帯
								<span className='txtRequired'>*</span>
								<div className='customerInput'>
									<MatSelect name='selectTime3' vm='時間帯' view={this.view} />
								</div>
							</Grid>
						</Grid>
					</div>
					<ul className='bikou'>
						<li>訪問担当が到着するおおよその時間帯目安となります。</li>
						<li>ご訪問日時については近日中にお電話にてご連絡いたします。<br/>訪問担当の空き状況によってご希望に沿えない場合もございますことご了承ください。<br/>お建物の状況等によっては工事前に別途調査が必要となる場合がございます。</li>
					</ul>
					<div className='feeTable inputArea' style={{marginTop: '30px'}}>	
						<Grid container className='feeRow'>
							<Grid item xs={12} md={4} className='feeTitle'>
								<div>前日連絡先<span className='txtRequired'>*</span></div>
							</Grid>
							<Grid item xs={12} md={8} className='feeVal courseValue' style={{padding: '15px'}}>
								<MatTextField name='beforeContact' className='itemUnit' placeholder='電話番号ハイフンなし' view={this.view} />
							</Grid>
						</Grid>	
						<Grid container className='feeRow'>
							<Grid item xs={12} md={4} className='feeTitle'>
								<div>当日連絡先<span className='txtRequired'>*</span></div>
							</Grid>
							<Grid item xs={12} md={8} className='feeVal courseValue' style={{padding: '15px'}}>
								<MatTextField name='sameDayContact' className='itemUnit' placeholder='電話番号ハイフンなし' view={this.view} />
							</Grid>
						</Grid>
						<Grid container className='feeRow'>
							<Grid item xs={12} md={4} className='feeTitle'>
								<div>当日立ち合い者<span className='txtRequired'>*</span></div>
							</Grid>
							<Grid item xs={12} md={8} className='feeVal courseValue' style={{padding: '15px'}}>
								<MatTextField name='name' className='itemUnit' placeholder='当日立ち合い者' view={this.view} />
							</Grid>
						</Grid>
					</div>
				</div>
				<Divider className='select1_underSpace'/>
				<div className='pageFooter footer-space'>
					<div>
						<MatButton name='next' view={this.view} className='orangeButton orangeButton_parts' >
							次へ
							<NavigateNextIcon />
						</MatButton>
					</div>
					<div>
						<MatButton name='back' view={this.view} className='backButton' >
							<NavigateBeforeIcon />
							戻る
						</MatButton>
					</div>
				</div>
			</div>
			</>
		);
	}
	// --------------------------------------------------------------------------

	// リスト描画  ---------------------------------------------------------------
	/**
	 * リスト名=ErrorListModel
	 */
	public onErrorListModelRender(rowInfo: WprRowInfo): any {
		return (
			<div>
				<MatTypography name='errorMessage' view={this.view} row={rowInfo}/>
			</div>
		);
	}
	// --------------------------------------------------------------------------
}
