import { WprModel } from '../../../../wpr-framework/view/deco/WprModel';
import { WprDI_ScopeModel } from '../../../../wpr-framework/view/di/WprDI_ScopeModel';
import { AccessAccountService } from '../../../service/AccessAccountService';
import { AccesspassModel } from '../../../service/models/apimodels/AccesspassModel';
import { GetSetContractRadiusAccountRequest } from '../../../service/models/dto/mypagerenewal/GetSetContractRadiusAccountRequest';
import { MypageBaseView } from '../../base/MypageBaseView';
import { ProgressBarData } from '../../common/progress_bar/ProgressBarView';
import { MypageWizardMngr } from '../../wizard/models/MypageWizardMngr';

/**
 * アクセスアカウントパスワード変更確認画面
 */
export class AddAccesspassConfirmView extends MypageBaseView {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() { super('AddAccesspassConfirmView'); }
	// --------------------------------------------------------------------------

	// モデル  ------------------------------------------------------------------
	@WprDI_ScopeModel('MypageWizardMngr')
	private m_MypageWizardMngr: MypageWizardMngr = null;	// 画面ウィザード用モデル
	@WprModel('AccesspassModel')
	private m_AccesspassModel: AccesspassModel = null;	// アクセスアカウントモデル
	// --------------------------------------------------------------------------

	// サービス  ----------------------------------------------------------------
	private m_AccessAccountService: AccessAccountService = new AccessAccountService();	// アクセスアカウントサービス
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * コントロール定義設定
	 */
	public onRegisterConfig(): void {
		this.addConfig('course', 'コース', 'AccesspassModel');
		this.addConfig('radiusAccount', 'アクセスアカウント', 'AccesspassModel');
	}

	/**
	 * アクション登録
	 */
	public onRegisterAction(): void {
		this.addAction('next', this.onNext);
		this.addAction('back', this.onBack);
	}

	/**
	 * ビュー表示通知
	 */
	public onShowView(): void {
		this.m_MypageWizardMngr.initView(this, (result) => {
			this.registAccessLog();
			this.setStorageItem('b_url', window.location.href);
			const sendData: ProgressBarData = new ProgressBarData();
			sendData.progressBarList = this.m_MypageWizardMngr.getProgressModelList(this);
			this.sendChildData('fP_ev_progressBarIntital', sendData);
			this.m_AccesspassModel = this.m_MypageWizardMngr.getCache().m_AccesspassInfo
			this.refreshView();
		});
	}
	// --------------------------------------------------------------------------

	// アクション  ---------------------------------------------------------------
	/**
	 * 申込む
	 */
	public onNext(param: any): void {
		const req = new GetSetContractRadiusAccountRequest();
		req.contractID = this.m_AccesspassModel.contractID;
		req.radiusAccount = this.m_AccesspassModel.radiusAccount;
		req.password = this.m_AccesspassModel.password;
		req.newPassword = this.m_AccesspassModel.newPassword;
		this.m_AccessAccountService.setContractRadiusAccount(this, req, (result) => {
			if (result.resultCD === '0') {
				// 正常の場合画面遷移
				this.m_AccesspassModel.date = result.executeDate.substring(0, 8);
				this.m_MypageWizardMngr.goNext(this);
			}
		});
	}
	/**
	 * 戻る
	 */
	public onBack(param: any): void {
		this.m_MypageWizardMngr.getCache().m_AccesspassInfo = new AccesspassModel();
		this.m_MypageWizardMngr.goBack(this);
	}
	// --------------------------------------------------------------------------

}
