// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@charset \"UTF-8\";\n/*\r\n * ネットその他サービス確認用CSS\r\n */\n.ModOthernetConfirm-title_number {\n  font-size: 16px;\n  margin-top: 14px;\n  font-weight: 600; }\n  @media screen and (max-width: 450px) {\n    .ModOthernetConfirm-title_number {\n      font-size: 15px; } }\n\n.ModOthernetConfirm-text {\n  margin-top: 7px; }\n  @media screen and (max-width: 450px) {\n    .ModOthernetConfirm-text {\n      font-size: 12px; } }\n\n@media screen and (max-width: 450px) {\n  .ModOthernetConfirm-table .feeTitle {\n    padding: 0.4rem 0.6rem !important; } }\n\n@media screen and (max-width: 450px) {\n  .ModOthernetConfirm-table .feeVal {\n    min-height: 53px;\n    padding-top: 18px !important; } }\n\n.ModOthernetConfirm-agree_button .title_center {\n  margin-top: 30px !important; }\n\n@media screen and (max-width: 450px) {\n  .ModOthernetConfirm-agree_button .main {\n    padding: 24px 19px 0px 19px; } }\n\n.ModOthernetConfirm-message .feeTable {\n  margin-bottom: 20px; }\n  @media screen and (max-width: 450px) {\n    .ModOthernetConfirm-message .feeTable {\n      margin-bottom: 16px; } }\n", ""]);
// Exports
exports.locals = {
	"title_number": "ModOthernetConfirm-title_number",
	"text": "ModOthernetConfirm-text",
	"table": "ModOthernetConfirm-table",
	"agree_button": "ModOthernetConfirm-agree_button",
	"message": "ModOthernetConfirm-message"
};
module.exports = exports;
