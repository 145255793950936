import { WprReflectUtil } from '../../../util/WprReflectUtil';
import { WprBaseViewCore } from '../../WprBaseViewCore';
import { WprControlInfo } from '../WprControlInfo';

/**
 * コントロールリフレッシュ情報
 */
export class WprControlRefreshInfo {
	// private 変数  ------------------------------------------------------------
	private m_ViewCore: WprBaseViewCore	= null;		// コントロール保持するビュー情報
	private m_Control: WprControlInfo	= null;		// コントロール情報
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** コントロール情報 */
	public get controlInfo(): WprControlInfo 	{ return this.m_Control; 	}
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	public constructor(viewCore: WprBaseViewCore, control: WprControlInfo) {
		this.m_ViewCore = viewCore;
		this.m_Control = control;
	}
	// --------------------------------------------------------------------------

	// public メソッド  ---------------------------------------------------------
	/**
	 * コントロールの情報でモデルを更新する
	 */
	public refreshModel(): void {
		if (this.m_Control.modelName && this.m_Control.isReadOnly() === false) {
//		if (this.m_Control.modelName) {
			let viewCore = this.m_ViewCore;
			if (this.m_Control.bindName != null) {
				let propModel = viewCore.getModelPropertyName(this.m_Control.modelName);
				if (propModel == null && this.m_ViewCore !== this.m_ViewCore.viewInfo) {
					viewCore = this.m_ViewCore.viewInfo;
					propModel = viewCore.getModelPropertyName(this.m_Control.modelName);
				}
				if (propModel != null) {
					let modelInfo = WprReflectUtil.getProperty(viewCore, propModel);
					if (this.m_Control.subModelList != null) {
						modelInfo = this.getSubModel(modelInfo, this.m_Control.subModelList);
						if (modelInfo == null) {
							this.m_ViewCore.addErrorLog(`MODELが見つかりませんでした。[${this.m_Control.name}][${this.m_Control.configModel}]`);
							return;
						}
					}
					if (modelInfo != null) {
						let cvalue = this.m_Control.value;
						if (this.checkEmpty(cvalue) === true)
							cvalue = this.m_Control.config.emptyValue;
						else if (this.m_Control.config.converter != null)
							cvalue = this.m_Control.config.converter.convertValue(cvalue);
						if (WprReflectUtil.checkProperty(modelInfo, this.m_Control.bindName) === true)
							WprReflectUtil.setProperty(modelInfo, this.m_Control.bindName, cvalue);
						else
							modelInfo[this.m_Control.bindName] = cvalue;
						/*
						if (WprReflectUtil.checkProperty(modelInfo, this.m_Control.bindName) === true) {
							let cvalue = this.m_Control.value;
							if (this.m_Control.config.converter != null)
								cvalue = this.m_Control.config.converter.convertValue(cvalue);
							WprReflectUtil.setProperty(modelInfo, this.m_Control.bindName, cvalue);
						}
						else {
							this.m_ViewCore.addErrorLog(`MODELのプロパティが見つかりませんでした。[${this.m_Control.name}][${this.m_Control.configModel}]`);
						}
						*/
					}
				}
			}
			else {
				const propModel = viewCore.getModelPropertyName(this.m_Control.modelName);
				if (propModel != null) {
					let cvalue = this.m_Control.value;
					if (this.checkEmpty(cvalue) === true)
						cvalue = this.m_Control.config.emptyValue;
					else if (this.m_Control.config.converter != null)
						cvalue = this.m_Control.config.converter.convertValue(cvalue);
					WprReflectUtil.setProperty(viewCore, propModel, cvalue);
				}
				else {
					viewCore.addErrorLog(`MODELが見つかりませんでした。[${this.m_Control.name}][${this.m_Control.configModel}]`);
				}

			}
		}
	}

	/**
	 * モデルの情報でコントロールを更新する
	 */
	public refreshView(): void {
		if (this.m_Control.modelName) {
			let viewCore = this.m_ViewCore;
			this.m_Control.status.inputError = false;
			this.m_Control.validError = null;
			if (this.m_Control.bindName != null) {
				let propModel = viewCore.getModelPropertyName(this.m_Control.modelName);
				if (propModel == null && this.m_ViewCore !== this.m_ViewCore.viewInfo) {
					viewCore = this.m_ViewCore.viewInfo;
					propModel = viewCore.getModelPropertyName(this.m_Control.modelName);
				}
				if (propModel != null) {
					let modelInfo = WprReflectUtil.getProperty(viewCore, propModel);
					if (this.m_Control.subModelList != null) {
						modelInfo = this.getSubModel(modelInfo, this.m_Control.subModelList);
						if (modelInfo == null) {
							this.m_ViewCore.addErrorLog(`MODELが見つかりませんでした。[${this.m_Control.name}][${this.m_Control.configModel}]`);
							return;
						}
					}
					if (modelInfo != null) {
						let mvalue = null;
						if (WprReflectUtil.checkProperty(modelInfo, this.m_Control.bindName) === true)
							mvalue = WprReflectUtil.getProperty(modelInfo, this.m_Control.bindName);
//						if (this.m_Control.config.converter != null)
//							mvalue = this.m_Control.config.converter.convertView(mvalue);
						this.m_Control.value = mvalue;
						/*
						if (WprReflectUtil.checkProperty(modelInfo, this.m_Control.bindName) === true) {
							let mvalue = WprReflectUtil.getProperty(modelInfo, this.m_Control.bindName);
							if (this.m_Control.config.converter != null)
								mvalue = this.m_Control.config.converter.convertView(mvalue);
							this.m_Control.value = mvalue;
						}
						else {
							viewCore.addErrorLog(`MODELのプロパティが見つかりませんでした。[${this.m_Control.name}][${this.m_Control.configModel}]`);
						}
						*/
					}
				}
			}
			else {
				const propModel = viewCore.getModelPropertyName(this.m_Control.modelName);
				if (propModel != null) {
					let mvalue = WprReflectUtil.getProperty(viewCore, propModel);
//					if (this.m_Control.config.converter != null)
//						mvalue = this.m_Control.config.converter.convertView(mvalue);
					this.m_Control.value = mvalue;
				}
				else {
					viewCore.addErrorLog(`MODELが見つかりませんでした。[${this.m_Control.name}][${this.m_Control.configModel}]`);
				}
			}
		}
	}

	/**
	 * コントロールの値をクリアする
	 * @param viewCore ビュー情報
	 */
	public clearValue(viewCore: WprBaseViewCore): void {
		if (this.m_Control.config.notClear === false) {
			try {
				this.m_Control.validError = null;
				this.m_Control.status.inputError = false;
				this.m_Control.dirty = false;
				this.m_Control.value = viewCore.onGetClearValue(this.m_Control.name, this.m_Control.value);
			}
			catch (ex) {
				viewCore.addErrorLog('onGetClearValueで例外が発生しました。', ex);
			}
		}
	}

	/**
	 * コントロールの入力チェック
	 * @return trueの場合、入力済みでエラーなし
	 */
	public isInput(): boolean {
		if (this.m_Control.isReadOnly() === false) {
			if (this.m_Control.status.hissu === true) {
				if (!this.m_Control.value)
					return false;
			}
			if (this.m_Control.isInvalid === false && this.m_Control.validError === null)
				return true;
			return false;
		}
		return true;
	}

	/**
	 * 権限を再設定する
	 * @param viewCore ビュー情報
	 * @param key キー
	 */
	public refreshAuth(): void {
		this.m_Control.setCustumAuth();
		this.m_Control.updateAuth();
	}
	// --------------------------------------------------------------------------

	// private メソッド  --------------------------------------------------------
	/**
	 * サブモデル情報取得
	 * @param modelInfo モデル情報
	 * @param subModelList サブモデル名リスト
	 * @returns サブモデル情報
	 */
	private getSubModel(modelInfo: any, subModelList: string[]): any {
		subModelList.forEach(model => {
			if (modelInfo != null)
				modelInfo = WprReflectUtil.getProperty(modelInfo, model);
		});
		return modelInfo;
	}

	/**
	 * 空チェック
	 * @param val 入力値
	 * @returns trueの場合、空
	 */
	private checkEmpty(val: any): boolean {
		if (this.m_Control.config.emptyValue !== undefined) {
			if (val === '')
				return true;
			if (Array.isArray(val) === true) {
				const array = val as Array<any>;
				if (array.length == 0)
					return true;
			}
		}
		return false;
	}
	// --------------------------------------------------------------------------
}
