// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@charset \"UTF-8\";\n/*\r\n * 支払いコース選択用CSS\r\n */\n.Add_nhk_select-combo-width {\n  width: 192px;\n  font-size: 13px;\n  background-color: #F7F7F7;\n  margin-top: 0px !important; }\n  @media screen and (max-width: 450px) {\n    .Add_nhk_select-combo-width {\n      width: -webkit-fill-available;\n      font-size: 13px;\n      background-color: #F7F7F7;\n      margin-top: 0px !important; } }\n\n.Add_nhk_select-nhk {\n  background: #F7F7F7;\n  padding: 0 15px;\n  border: 1px solid #E3E3E3;\n  border-radius: 4px;\n  height: 44px;\n  width: -webkit-fill-available;\n  outline-color: #6169B8;\n  letter-spacing: 0.1em; }\n", ""]);
// Exports
exports.locals = {
	"combo-width": "Add_nhk_select-combo-width",
	"nhk": "Add_nhk_select-nhk"
};
module.exports = exports;
