// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@charset \"UTF-8\";\n/*\r\n * テレビオプション仮申込確認用CSS\r\n */\n.AddTvopprovConfirm-gray {\n  background-color: #f7f7f7;\n  border-radius: 2%/15%;\n  padding: 20px;\n  margin: 30px 0; }\n  .AddTvopprovConfirm-gray .AddTvopprovConfirm-flex {\n    display: flex;\n    align-items: center;\n    margin-bottom: 3px; }\n    .AddTvopprovConfirm-gray .AddTvopprovConfirm-flex .AddTvopprovConfirm-title {\n      color: #6169B8;\n      font-weight: 700;\n      font-size: 14px;\n      margin-left: 5px; }\n\n.AddTvopprovConfirm-bold p, .AddTvopprovConfirm-bold span {\n  font-weight: 600;\n  color: #000000;\n  font-size: 14px; }\n", ""]);
// Exports
exports.locals = {
	"gray": "AddTvopprovConfirm-gray",
	"flex": "AddTvopprovConfirm-flex",
	"title": "AddTvopprovConfirm-title",
	"bold": "AddTvopprovConfirm-bold"
};
module.exports = exports;
