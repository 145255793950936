/**
 * メッシュWWifi
 */
export class MeshWifiBaseModel {
	// public 変数	-----------------------------------------------------------
	public courseCheck: boolean;			// 申込みコース
	public equipmentCheck: boolean;			// 機器のご提供方法
	public wifiFlg: boolean					// wi-fi(有線モデムセット用)利用フラグ
	public check1: boolean;					// 設置方法１
	public check2: boolean;					// 設置方法２
	public establishment: string;			// 設置方法詳細
	public confirmCheck: boolean;			// 同意確認
	public desiredDate1: string;			// 第一希望
	public desiredDate2: string;			// 第二希望
	public desiredDate3: string;			// 第三希望
	public selectTime1: string;				// 第一希望　時間帯
	public selectTime2: string;				// 第二希望　時間帯
	public selectTime3: string;				// 第三希望　時間帯
	public curFee: string;					// 旧月額
	public newFee: string;					// 新月額
	public serviceCd: string;				// サービスコード
	public courseFee: string;				// 申込みコースの月額
	public countructionCost: string;		// 工事費
	public tokotonFlg: boolean;				// とことんサポート加入済みフラグ
	public tokotonAddFlg: boolean;			// とことんサポート加入フラグ
	public tokosupCheck: boolean;			// とこサポ選択
	public tokosupConfirmCheck: boolean;	// とこサポ同意確認
	public amount: string;					// 月額利用料
	public beforeContact: string;			// 前日連絡先
	public sameDayContact: string;			// 当日連絡先
	public name: string;					// 立ち合い者名
	public constractionFlg: boolean;		// 工事フラグ
	// ------------------------------------------------------------------------
}
