import { WprKeyValue } from './WprKeyValue';

/**
 * ValueMap情報
 */
export class WprValueMap {
	// private 変数  ------------------------------------------------------------
	private m_Map: Map<any, string>			= new Map<any, string>();		// 値マップ
	private m_IdxMap: Map<number, any>		= new Map<number, any>();		// Idxのマップ
	private m_Collection: WprKeyValue[]		= new Array();					// コレクション
	private m_NCollection: WprKeyValue[]	= new Array();					// Null付きコレクション
	// --------------------------------------------------------------------------

	// public メソッド	----------------------------------------------------------
	/**
	 * 値追加
	 * @param key キー
	 * @param value 値
	 */
	public addValue(key: any, value: string): void {
		const idx = this.m_Map.size;
		this.m_IdxMap.set(idx, key);
		this.m_Map.set(key, value);
		this.setCollection();
	}

	/**
	 * 値設定
	 * @param key キー
	 * @param value 値
	 */
	public putValue(key: any, value: string): void {
		if (this.m_Map.has(key) === true)
			this.m_Map.set(key, value);
		else
			this.addValue(key, value);
		this.setCollection();
	}

	/**
	 * 値数取得
	 * @return 値数取得
	 */
	public getCount(): number {
		return this.m_Map.size;
	}

	/**
	 * キー取得
	 * @param idx インデクス
	 * @return キー
	 */
	public getKey(idx: number): any {
		return this.m_IdxMap.get(idx);
	}

	/**
	 * キーから値取得
	 * @param value 値
	 * @return キー
	 */
	public getKeyFormValue(value: string): any {
		let ret;
		this.m_Map.forEach((val, key, map) => {
			if (val === value)
				ret = key;
		});
		return ret;
	}

	/**
	 * 値取得
	 * @param idx インデクス
	 * @return 値
	 */
	public getValue(idx: number): string {
		if (this.m_IdxMap.has(idx) === false)
			return '';
		return this.m_Map.get(this.m_IdxMap.get(idx));
	}

	/**
	 * キーから値取得
	 * @param キー
	 * @return 値
	 */
	public getValueFormKey(key: any): string {
		if (key == null)
			return null;
		if (this.m_Map.has(key) === true)
			return this.m_Map.get(key);
		return null;
	}

	/**
	 * キーからインデクス取得
	 * @param key キー
	 * @return インデクス
	 */
	public getIndex(key: any): number {
		let ret = 0;
		try {
			this.m_IdxMap.forEach((value, mkey, map) => {
				if (value === key)
					ret = mkey;
			});
		}
		catch (ex) {
		}
		return ret;
	}

	/**
	 * クリア処理
	 */
	public clear(): void {
		this.m_Map.clear();
		this.m_IdxMap.clear();
		this.setCollection();
	}

	/**
	 * クローン生成処理
	 */
	public clone(): WprValueMap {
		const vm = new WprValueMap();
		this.m_IdxMap.forEach((value, key, map) => {
			vm.addValue(key, value);
		});
		return vm;
	}

	/**
	 * コレクション取得
	 * @param hasNull Null選択フラグ
	 * @return コレクション
	 */
	public getCollection(hasNull?: boolean): WprKeyValue[] {
		if (hasNull)
			return this.m_NCollection;
		else
			return this.m_Collection;
	}
	// --------------------------------------------------------------------------

	// private メソッド	----------------------------------------------------------
	/**
	 * コレクション設定
	 */
	private setCollection(): void {
		this.m_NCollection = new Array();
		this.m_Collection = new Array();
		this.m_NCollection.push(new WprKeyValue(null, ''));
		this.m_Map.forEach((val, key, map) => {
			this.m_NCollection.push(new WprKeyValue(key, val));
			this.m_Collection.push(new WprKeyValue(key, val));
		});
	}
	// --------------------------------------------------------------------------
}
