import { WorkDetailInfo } from "./WorkDetailInfo";

/**
 * 工事内容情報
 */
export class ApplicationInfo {
	// public 変数	-----------------------------------------------------------
	public customer_id: string;						// お客様番号
	public property_id: string;						// 建物番号
	public system_id: string;						// システムID
	public work_partner_company_id: string;			// 工事協力会社ID
	public work_team_id: string;					// 工事班ID
	public schedule_date: string;					// 工事予定日
	public start_time: string;						// 開始時間
	public end_time: string;						// 終了時間
	public preferred_time_cd: string;				// 希望時間帯(便)コード
	public work_schedule_id: string;				// 工事予約ID
	public adjust_group_id: string;					// 調整後グループID
	public work_area_cd: string;					// 工事エリアコード
	public work_detail_info: WorkDetailInfo[];		// 工事内容情報
	// ------------------------------------------------------------------------
}