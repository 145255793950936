import { WprValueMap } from '../../../../wpr-framework/model/WprValueMap';
import { WprValueMapSetter } from '../../../../wpr-framework/model/WprValueMapSetter';

/**
 * 設置場所
 */
export class LocationValueMap {
	// static メソッド  --------------------------------------------------------
	public static addValueMap(vmSetter: WprValueMapSetter): void {
		let vm = new WprValueMap();
		vm.addValue('リビング', 'リビング');
		vm.addValue('ダイニング', 'ダイニング');
		vm.addValue('寝室', '寝室');
		vm.addValue('和室', '和室');
		vm.addValue('居間', '居間');
		vm.addValue('書斎', '書斎');
		vm.addValue('洋室', '洋室');
		vm.addValue('応接室', '応接室');
		vm.addValue('職場', '職場');
		vm.addValue('休憩室', '休憩室');
		vm.addValue('社長室', '社長室');
		vm.addValue('その他', 'その他');
		vmSetter.addValueMap('location', vm);
	}
	// --------------------------------------------------------------------------
}
