import { WprListData } from '../../../../wpr-framework/view/deco/WprListData';
import { WprBaseScopeViewInfo } from '../../../../wpr-framework/view/WprBaseScopeViewInfo';
import { FileUtil } from '../../../common/util/FileUtil';
import { Application } from '../../../service/models/apimodels/fuse/soap/Application';
import { ObjectUtil } from '../../../common/util/ObjectUtil';
import { ContractConfirmService } from '../../../service/ContractConfirmService';
import { GetDocumentRequest } from '../../../service/models/dto/contractConfirm/GetDocumentRequest';
import { GetDocListRequest } from '../../../service/models/dto/contractConfirm/GetDocListRequest';
import { MypageBaseView } from '../../base/MypageBaseView';

/**
 * 契約一覧
 */
export class S_01_003View extends MypageBaseView {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() { super('S_01_003View'); }
	// --------------------------------------------------------------------------

	// モデル  ------------------------------------------------------------------
	@WprListData('ApplicationList')
	private m_ApplicationList: Application[]	= null;	// 書面確認履歴
	// private m_ContractListUrl: string = null;
	// --------------------------------------------------------------------------

	// サービス  ------------------------------------------------------------------
	private m_ContractConfirmService: ContractConfirmService	= new ContractConfirmService();	// MyPageRenewalサービス
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * コントロール定義設定
	 */
	public onRegisterConfig(): void {
		// this.addListConfig('docConfirmId', '書面確認履歴ID', 'ApplicationList');
		this.addListConfig('appID', '申込書ID', 'ApplicationList');
		this.addListConfig('appDate', '申込日', 'ApplicationList', {converter: '日付（yyyy/MM/dd）'});
		this.addListConfig('printDate', '書面発行日', 'ApplicationList', {converter: '日付（yyyy/MM/dd）'});
		// this.addConfig('ContractListUrl', '現行MyPAGEURL', 'm_ContractListUrl');
	}

	/**
	 * アクション登録
	 */
	public onRegisterAction(): void {
		this.addListAction('b_ShowPDF', 'ApplicationList', this.onB_ShowPDF);
		this.addAction('OnL_MyPageUrl', this.OnL_MyPageUrl);
		this.addAction('next', this.onNext);
	}

	/**
	 * ビュー表示通知
	 */
	public onShowView(): void {
		this.registAccessLog();
		this.setStorageItem('b_url', window.location.href)
		this.getList();
	}

	/**
	 * ウインドウリサイズ通知
	 * @param width 幅
	 * @param height 高さ
	 */
	public onResizeWindow(width: number, height: number): void {
		if (width <= 900) {
			this.setViewMode('画面サイズ', '小');
		}
		else {
			this.setViewMode('画面サイズ', '大');
		}
	}
	// --------------------------------------------------------------------------

	// アクション  ---------------------------------------------------------------
	/**
	 * PDF
	 */
	public onB_ShowPDF(param: any, item: any): void {
		const req: GetDocumentRequest = new GetDocumentRequest();
		req.application = item;
		this.m_ContractConfirmService.getDocument(this, req, (result) => {
			if (result) {
				FileUtil.createPDF(result.document.name, result.document.data);
			}
		})
	}
	/**
	 * 現行MyPAGE
	 */
	 public OnL_MyPageUrl(): void {
		location.href = this.getOldUrl() + "Srvlist/Continfo_list/";
	}

	/**
	 * 契約内容の確認・変更メニューへ
	 */
	public onNext(param: any): void {
		// location.href = this.getOldUrl() + "Menu/Contract_menu_list/";
		// 現行に併せてリンク先修正
		this.OnL_MyPageUrl();
	}
	// --------------------------------------------------------------------------

	// peivate メソッド　---------------------------------------------------------
	/**
	 * 一覧取得
	 */
	private getList(): void {
		let req = new GetDocListRequest();
		req.customerId = this.getCookie("customerId");
		this.m_ContractConfirmService.getList(this, req, (result) => {
			this.m_ApplicationList = result.applicationList;
			if (ObjectUtil.isNullOrUndefined(this.m_ApplicationList)) {
				this.setViewMode('契約内容一覧', '無');
			}
			else {
				this.setListData('ApplicationList', this.m_ApplicationList);
				this.setViewMode('契約内容一覧','有');
			}
			this.setScopeModel('CustomerId', result.customerId);
			this.refreshView();
		});
	}

	// private getList(customerId: String): void {
	// 	const req: GetHDocConfirmListRequest = new GetHDocConfirmListRequest();
	// 	req.customerId = customerId;
	// 	this.m_MyPageRenewalService.getList(this, req, (result) => {
	// 		if (result) {
	// 			this.m_HDocConfirm = result.hdocConfirmList;
	// 			this.setListData('HDocConfirmList', this.m_HDocConfirm);
	// 			this.setListTotalCount('HDocConfirmList', this.m_HDocConfirm.length);
	// 		}
	// 	});
	// }

	// private getList(customerId: String, appId: String): void {
	// 	const req: GetHDocConfirmListSubRequest = new GetHDocConfirmListSubRequest();
	// 	req.customerId = customerId;
	//	req.applicationFormId = appId;
	// 	this.m_MyPageRenewalService.getList(this, req, (result) => {
	// 		if (result) {
	// 			this.m_HDocConfirm = result.hdocConfirmList;
	// 			this.setListData('HDocConfirmList', this.m_HDocConfirm);
	// 			this.setListTotalCount('HDocConfirmList', this.m_HDocConfirm.length);
	// 		}
	// 	});
	// }



	//---------------------------------------------------------------------------
}
