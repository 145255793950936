import { WprBaseViewMultiModeInfo } from '../../../../../../wpr-framework/mode/WprBaseViewMultiMode';
import { WprMultiMode } from '../../../../../../wpr-framework/mode/WprMultiMode';
import { ModTvequipSelectView } from '../ModTvequipSelectView';
import { WprDI_Control } from '../../../../../../wpr-framework/view/di/WprDI_Control';
import { WprControlInfo } from '../../../../../../wpr-framework/view/control/WprControlInfo';
import { WprDI_Object } from '../../../../../../wpr-framework/view/di/WprDI_Object';
import { EquipmentModel } from '../../../../../view/tv/equipment/mod_tvequip_select/models/EquipmentModel';
import { TvBaseModel } from '../../../../../models/mypage/tv/TvBaseModel';

/**
 * 機器変更チェックモード
 */
export class TvEquipCheckMode extends WprBaseViewMultiModeInfo<ModTvequipSelectView> {
	// DI情報  ------------------------------------------------------------------
	@WprDI_Control
	private m_bdHitpot: WprControlInfo	= null;	// BD-HitPot
	@WprDI_Control
	private m_hitpot: WprControlInfo	= null;	// HitPot
	@WprDI_Control
	private m_stb: WprControlInfo	= null;	// STB
	@WprDI_Control
	private m_rental: WprControlInfo	= null;	// レンタル
	@WprDI_Object
	private m_TvBaseModel: TvBaseModel	= null;	// テレビ基本情報モデル
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	public constructor() {
		super('TvEquipCheckMode');
	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * モードリスト取得（この画面モードで使用する全てのモードを定義する）
	 * @param list モードリスト
	 */
	public useModeList(list: string[]): void {
		list.push('切替不可(rental)');
		list.push('切替可(bdHitpot)');
		list.push('切替不可(bdHitpot)');
		list.push('切替可(hitpot)');
		list.push('切替不可(hitpot)');
		list.push('切替可(stb)');
		list.push('切替不可(stb)');
	}

	/**
	 * フィールド定義を登録処理
	 */
	public onRegisterControl(): void {
		this.addControl('bdHitpot', { check: true, readOnly: [ { mode: '切替不可(bdHitpot)', value: true }, { mode: 'other', value: false } ] });
		this.addControl('hitpot', { check: true, readOnly: [ { mode: '切替不可(hitpot)', value: true }, { mode: 'other', value: false } ] });
		this.addControl('stb', { check: true, readOnly: [ { mode: '切替不可(stb)', value: true }, { mode: 'other', value: false } ] });
		this.addControl('rental', { check: true, readOnly: [ { mode: '切替不可(rental)', value: true }, { mode: 'other', value: false } ] });
	}

	/**
	 * 画面の状態をチェックしてモードを変更する
	 * @param curMode 現在のモード
	 * @param newMode 新しいモード
	 */
	public onCheckMultiMode(curMode: WprMultiMode, newMode: WprMultiMode): void {
		if (this.m_rental.value)
			newMode.addMode('切替不可(rental)');
		if (this.m_bdHitpot.value) {
			newMode.addMode('切替不可(bdHitpot)');
			newMode.addMode('切替可(hitpot)');
			newMode.addMode('切替可(stb)');
		}
		else if (this.m_hitpot.value) {
			newMode.addMode('切替不可(hitpot)');
			newMode.addMode('切替可(bdHitpot)');
			newMode.addMode('切替可(stb)');
		}
		else if (this.m_stb.value) {
			newMode.addMode('切替不可(stb)');
			newMode.addMode('切替可(bdHitpot)');
			newMode.addMode('切替可(hitpot)');
		}
	}
	// --------------------------------------------------------------------------
}
