import { WprActionInfo } from './WprActionInfo';
import { WprBaseViewCore } from '../view/WprBaseViewCore';
import { WprRowInfo } from '../component/props/WprRowInfo';

/**
 * リストアクション情報
 */
export class WprListActionInfo extends WprActionInfo {
	// private 変数  ------------------------------------------------------------
	private m_ListName: string									= null;		// リスト名
	private m_ListActionMethod: (param: any, item: any) => void	= null;		// コールバックメソッド
	// --------------------------------------------------------------------------

	// private 変数  ------------------------------------------------------------
	/** リスト名 */
	public get listName(): string 	{ return this.m_ListName;	}
	/** リストアクションフラグ */
	public get isList(): boolean 	{ return true;				}
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	public constructor(name: string, listName: string, viewCore: WprBaseViewCore, actionMethod: (param: any, item: any) => void, isValid: boolean, isEvent: boolean) {
		super(name, viewCore, null, isValid, false, isEvent);
		this.m_ListName = listName;
		this.m_ListActionMethod = actionMethod;
	}
	// --------------------------------------------------------------------------

	// public メソッド  ----------------------------------------------------------
	/**
	 * アクション実行処理
	 * @param event イベント情報
	 * @param item アイテム
	 * @returns falseの場合、バリデーションエラー
	 */
	public execute(isEditCheck: boolean, event: any, item?: WprRowInfo): boolean {
		if (isEditCheck === true && this.isBlur === false) {
			if (this.FW.edit.checkEdit(this.name, this.m_ListName, () => {
					this.execute(false, event, item);
				}) === false) {
				return true; 
			}
		}
		this.addInfoLog(`リストアクション実行 開始[${this.viewCore.objectName}]`);
		let aname = this.name;
		if (this.isBlur === true) {
			if (aname.endsWith('@@blur') === true)
				aname = aname.substring(0, aname.length-6);
			let rinfo = item as WprRowInfo;
			const cinfo = this.viewCore.getControlInfo(aname, rinfo.listName, rinfo.index);
			if (cinfo != null && cinfo.value != null) {
				const val = this.viewCore.onBlur(aname, cinfo.value, cinfo.isBlurChange);
				if (cinfo.value !== val)
					cinfo.value = val;
			}
		}
		if (this.isValid === true) {
			const isView = (this.viewCore.viewInfo === this.viewCore);
			if (this.viewCore.viewInfo.checkValidate(aname, isView, this.isBlur, false, item.rowData) === false) {
				this.addInfoLog(`入力チェックエラー[${this.viewCore.objectName}]`);
				this.viewCore.updateMode();
				return false;
			}
		}
		if (this.m_ListActionMethod) {
			if (this.isEvent === false)
				this.m_ListActionMethod.bind(this.viewCore)(aname, item.rowData);
			else
				this.m_ListActionMethod.bind(this.viewCore)(event, item.rowData);
		}
		this.viewCore.updateMode();
		this.viewCore.viewInfo.checkScopeModelValue();
		this.addInfoLog(`リストアクション実行 終了[${this.viewCore.objectName}]`);
		return true;
	}
	// --------------------------------------------------------------------------
}
