/**
 * ItscomHomeオプションモデル
 */
export class ItscomHomeCourseListModel {
	// public 変数	-----------------------------------------------------------
	public course: string;			// コース
	public amount: string;			// 金額
	public check1: boolean; 		// 選択
	public serviceCd: string; 		// サービスコード(サービスマスタ)
	public ownerIhFlag: boolean;	// オーナーIHフラグ
	// ------------------------------------------------------------------------
}
