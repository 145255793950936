import { MypageBaseView } from '../../../base/MypageBaseView';
import { WprModel } from '../../../../../wpr-framework/view/deco/WprModel';
import { WprDI_ScopeModel } from '../../../../../wpr-framework/view/di/WprDI_ScopeModel';
import { HMvnoTmpSaved } from '../../../../service/models/entity/HMvnoTmpSaved';
import { MvnoMnpInfo } from '../../../../service/models/apimodels/MvnoMnpInfo';
import { MvnoCouponInfo } from '../../../../service/models/apimodels/MvnoCouponInfo';
import { CreateMvnoInquiryRequest } from '../../../../service/models/dto/mvno/CreateMvnoInquiryRequest';
import { MvnoService } from '../../../../service/MvnoService';
import { MvnoCompleteView } from '../../mvno_complete/MvnoCompleteView';
import { CustomerInfo } from '../../../../service/models/apimodels/CustomerInfo';
import { ConvertViewUtil } from '../../../../common/util/ConvertViewUtil';

/**
 * データ容量追加申込確認
 */
export class MvnoAddCapacityConfirmView extends MypageBaseView {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() { super('MvnoAddCapacityConfirm'); }
	// --------------------------------------------------------------------------

	// モデル  ------------------------------------------------------------------
	// @WprDI_ScopeModel('MvnoMnpInfo')
	// @WprModel('MvnoMnpInfo')
	// private m_MvnoMnpInfo: MvnoMnpInfo = null;	// MVNOプラン情報
	@WprDI_ScopeModel('MvnoCouponInfo')
	@WprModel('MvnoCouponInfo')
	private m_MvnoCouponInfo: MvnoCouponInfo = null;	// MVNOクーポン情報
	// --------------------------------------------------------------------------
	// サービス  ----------------------------------------------------------------
	private m_MvnoService: MvnoService = new MvnoService();	// MyPageRenewalサービス
	// --------------------------------------------------------------------------


	// override メソッド  --------------------------------------------------------
	/**
	 * コントロール定義設定
	 */
	public onRegisterConfig(): void {

		this.addConfig('phoneNumber', '回線番号', 'MvnoCouponInfo', {});
		this.addConfig('addCouponCapacity', '追加クーポン容量', 'MvnoCouponInfo', {});
		this.addConfig('addCouponPrice', '追加クーポン価格', 'MvnoCouponInfo', {});
		// this.addConfig('iccId', 'ICCID', 'MvnoMnpInfo', { emptyValue: null, hissu: true });
		// this.addConfig('familyName', '姓漢字', 'MvnoMnpInfo.customerInfo', { emptyValue: null, hissu: true });
		// this.addConfig('firstName', '名漢字', 'MvnoMnpInfo.customerInfo', { emptyValue: null, hissu: true });
		// this.addConfig('familyNameKana', '姓カナ', 'MvnoMnpInfo.customerInfo', { emptyValue: null, hissu: true, validation: '全角カナ' });
		// this.addConfig('firstNameKana', '名カナ', 'MvnoMnpInfo.customerInfo', { emptyValue: null, hissu: true, validation: '全角カナ' });
	}

	/**
	 * アクション登録
	 */
	public onRegisterAction(): void {
		this.addAction('next', this.onNext, true);
		this.addAction('back', this.onBack);
		// this.addAction('iccidLink', this.onIccidLink);
	}

	/**
	 * ビュー表示通知
	 */
	public onShowView(): void {
		this.registAccessLog();
		this.setStorageItem('b_url', window.location.href);
		this.refreshView();
	}
	// --------------------------------------------------------------------------

	// アクション  ---------------------------------------------------------------
	/**
	 * 次へ
	 */
	public onNext(param: any): void {
		this.refreshModel();
		const req: CreateMvnoInquiryRequest = new CreateMvnoInquiryRequest();
		req.customerId = this.getCustomerId();
		req.contractCd = this.getContractCd();
		req.couponInfo = this.m_MvnoCouponInfo;
		req.mvnoInquiryType = MvnoCompleteView.MVNO_ADD_COUPON;
		this.m_MvnoService.createMvnoInquiry(this, req, (result) => {
			this.setScopeMode('MVNO', MvnoCompleteView.MVNO_ADD_COUPON);
			const customerInfo: CustomerInfo = result.couponInfo.customerInfo;
			
			this.setScopeModel('customerName', ConvertViewUtil.convertCustName(customerInfo.familyName, customerInfo.firstName));
			this.pushPath('/mvno_add_capacity_003');
		});
		// this.pushPath('/mvno_mnp_002');
	}

	/**
	 * 戻る
	 */
	public onBack(param: any): void {
		this.pushPath('/mvno_add_capacity_001');
	}
	// --------------------------------------------------------------------------
}
