import { WprErrorMngr } from '../error/WprErrorMngr';
import { WprBaseViewInfo } from '../view/WprBaseViewInfo';
import { WprBaseMngr } from '../WprBaseMngr';

/**
 * ビュー管理機能
 */
export class WprEditMngr extends WprBaseMngr {
	// private 変数  ------------------------------------------------------------
	private m_IsEdit: boolean										= false;			// 編集フラグ
	private m_ViewInfo:	WprBaseViewInfo								= null;				// 編集ビュー情報
	private m_Message: string										= null;				// メッセージ
	private m_DialogList: string[]									= new Array();		// ダイアログリスト
	private m_ErrorMngr: WprErrorMngr								= null;				// エラー管理機能
	private m_CancelMethod: (name: string, parent: string) => void	= null;				// キャンセルメソッド
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	public constructor() {
		super('編集管理機能');
	}
	// --------------------------------------------------------------------------

	// override メソッド	-----------------------------------------------------
	/**
	 * 他の管理機能設定処理
	 * @param mngrMap 管理機能マップ
	 */
	public setManager(mngrMap: Map<string, WprBaseMngr>): void {
		this.m_ErrorMngr = mngrMap.get('エラー管理機能') as WprErrorMngr;
	}
	// --------------------------------------------------------------------------

	// public メソッド	----------------------------------------------------------
	/**
	 * 編集開始処理
	 * @param message 確認メッセージ
	 * @param cancelMethod キャンセルメソッド
	 * @returns falseの場合、既に編集中
	 */
	public startEdit(view: WprBaseViewInfo, message: string, cancelMethod?: (name: string, parent: string) => void): boolean {
		if (this.m_IsEdit === false) {
			this.m_ViewInfo = view;
			this.m_IsEdit = true;
			this.m_Message = message;
			this.m_CancelMethod = cancelMethod;
			this.m_DialogList = new Array();
			return true;
		}
		else {
			return false;
		}
	}

	/**
	 * 編集終了処理
	 * @returns falseの場合、編集中ではない
	 */
	public endEdit(): boolean {
		if (this.m_IsEdit === true) {
			this.m_IsEdit = false;
			return true;
		}
		else {
			return false;
		}
	}

	/**
	 * 編集キャンセル処理
	 * @param name キャンセルの起点　
	 * @param parent キャンセルの起点の親
	 * @returns falseの場合、編集中ではない
	 */
	public cancelEdit(name: string = null, parent: string = null): boolean {
		if (this.m_IsEdit === true) {
			this.m_IsEdit = false;
			if (this.m_CancelMethod)
				this.m_CancelMethod(name, parent);
			return true;
		}
		else {
			return false;
		}
	}

	/**
	 * 編集チェック処理
	 * @param name アクション又はリスト名又はタブモード
	 * @param name アクション又はリスト名又はタブモードの親
	 * @param action アクション
	 * @returns trueの場合は、処理を継続する
	 */
	public checkEdit(name: string, parent: string, action: () => void): boolean {
		if (this.m_IsEdit === true && this.m_DialogList.length === 0 && this.m_ViewInfo.onCheckEdit(name, parent) === true) {
			this.m_ViewInfo.showQuestionMessage(this.m_Message, (result) => {
				if (result === true) {
					this.cancelEdit(name, parent);
					if (this.m_ViewInfo.isStateLock === false)
						action();
					else
						this.m_ViewInfo.setStateWaiteAction(action);
				}
			});
			return false;
		}
		return true;
	}

	/**
	 * ダイアログ表示処理
	 * @param name ダイアログ名 
	 */
	public showDialog(name: string): void {
		if (this.m_IsEdit === true)
			this.m_DialogList.push(name);
	}

	/**
	 * ダイアログクローズ処理
	 * @param name ダイアログ名
	 */
	public closeDialog(name: string): void {
		if (this.m_IsEdit === true) {
			if (this.m_DialogList.length > 0 && this.m_DialogList[this.m_DialogList.length-1] === name)
				this.m_DialogList.pop();
		}
	}
	// --------------------------------------------------------------------------
}