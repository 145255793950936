import { Accordion } from '@material-ui/core';
import React, { ReactNode } from 'react';
import { IWprAccordionMode } from '../../../mode/accordion/IWprAccordionMode';
import { WprControlVisibility } from '../../props/IWprControlState';
import { IWprNoNameLayoutProps } from '../../props/IWprLayoutProps';
import { WprBaseNoNameLayoutComponent } from '../WprBaseNoNameLayoutComponent';

/**
 * アコーディオンプロパティ情報
 */
interface IMatAccordionProps extends IWprNoNameLayoutProps {
	mode: string;		// モード
	name: string;		// 名称
	expand?: boolean;	// 初期展開フラグ
}

/**
 * アコーディオン
 */
export class MatAccordion extends WprBaseNoNameLayoutComponent<IMatAccordionProps, JSX.Element> implements IWprAccordionMode {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: IMatAccordionProps) {
		super(props);
		this.setChangeValueEvent(this.onChangeValue.bind(this));
	}
	// --------------------------------------------------------------------------

	// イベント  ----------------------------------------------------------------
	/**
	 * 値変更処理
	 * @param event 変更イベント 
	 */
	 public onChangeValue(event: React.ChangeEvent<HTMLInputElement>, expanded: boolean): void {
		this.addChangeValueEventLog(event.target, expanded);
		this.props.view.setAccordionMode(this.props.mode, this.props.name, expanded);
	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * マウント通知
	 */
	 public onDidMount() {
		 this.props.view.setAccordionModeComponent(this, this.props.mode, this.props.name, this.props.expand);
	 }

	 /**
	 * 描画
	 */
	public onRender(): ReactNode {
//		if (this.isMount == false)
//			return <></>;
		const props = this.getProps();
		const { view, className, expand, children, ...other} = this.props;
		const events = this.getEvents();
		const options = this.getOptions();
		let accordion = null;
		const state = this.getState(['style', 'value']);
		if (this.state.visibility === WprControlVisibility.VISIBLE) {
			accordion = <Accordion {...props} {...state} {...other} {...options} {...events} ref={this.ref} >{this.props.children}</Accordion>;
		}
		else {
			accordion = <Accordion hidden {...props} {...state} {...other} {...options} ref={this.ref}>{this.props.children}</Accordion>;
		}
		return (
			<>
				{accordion}
			</>
		);
	}
	// --------------------------------------------------------------------------

	// private メソッド  ---------------------------------------------------------
	/**
	 * オプション情報取得
	 * @returns オプション情報
	 */
	private getOptions(): any {
		const rtn: any = {};
		if (this.isMount == false && this.props.expand)
			rtn['expanded'] = true;
		else
			rtn['expanded'] = this.props.name === this.state.value;
		return rtn;
	}
	// --------------------------------------------------------------------------

	// IWprAccordionModeの実装  --------------------------------------------------
	/**
	 * アコーディオンモード設定処理
	 * @param mode モード
	 */
	 public setAccordionMode(mode: string): void {
		this.setState({
			value : mode
		});
	}
	// --------------------------------------------------------------------------
}
