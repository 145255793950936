import { WprBaseViewInfo } from '../view/WprBaseViewInfo';
import { WprValueMapSetter } from './WprValueMapSetter';

/**
 * ValueMap登録用仮View情報
 */
export abstract class WprBaseServiceValueMap extends WprBaseViewInfo {
	// private 変数  ------------------------------------------------------------
	private m_ValueMapName: string		= null;		// ValueMap名
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	public constructor(name: string) {
		super('ValueMap登録用仮View');
		this.m_ValueMapName = name;
	}
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** ValueMap名 */
	public get valueMapName(): string 	{ return this.m_ValueMapName; 	}
	// --------------------------------------------------------------------------

	// abstract メソッド  -------------------------------------------------------
	/**
	 * ValueMap登録処理
	 * @param vmSetter ValueMap設定
	 */
	public abstract registerValueMap(vmSetter: WprValueMapSetter): void;
	// --------------------------------------------------------------------------

	// public メソッド  ---------------------------------------------------------
	/**
	 * サービスを使用してValueMap登録
	 * @param vmSetter ValueMap設定
	 */
	public addServiceValueMap(vmSetter: WprValueMapSetter): void {
		vmSetter.startLoad(this.m_ValueMapName);
		this.registerValueMap(vmSetter);
	}
	// --------------------------------------------------------------------------
}