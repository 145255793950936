import React, { ReactNode } from 'react';
import { WprBaseViewComponent } from '../../../../wpr-framework/view/component/WprBaseViewComponent';
import { VideoPassComfirmView } from './VideoPassComfirmView';
import { Typography, Grid, Divider } from '@material-ui/core';
import { MatButton } from '../../../../wpr-framework/component/controls/material/MatButton';
import { ProgressBar } from '../../common/progress_bar/ProgressBar';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { MatTypography } from '../../../../wpr-framework/component/controls/material/MatTypography';
import styles from './VideoPassComfirm.scss';

/**
 * 動画配信サービス　パスワード再発行確認画面用コンポーネント
 */
export class VideoPassComfirm extends WprBaseViewComponent {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: any) { super(new VideoPassComfirmView(), props); }
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** 動画配信サービス　パスワード再発行確認画面ビュー情報 */
	public get viewInfo(): VideoPassComfirmView	{ return this.view as VideoPassComfirmView;	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * スタイルオブジェクト取得
	*/
	public getStylesObject(): any { return styles; }

	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		return (
			<>
			<div>
				<div className='pageCard'>
					<div className="pageHeader stepFlow">
						<ProgressBar name='progressBar' view={this.view}/>
					</div>
					<div className='main'>
						<div className='pageTitle'>
							<div className='title_left'>
								<MatTypography name='title' className='title_text' view={this.view}/>
								<Typography className='title_comment'>下記の契約者IDでパスワードの再発行を行います。<br/>よろしければ、「再発行」ボタンをクリックしてください。</Typography>
							</div>
						</div>
						<div className='feeTable'>
							<Grid container className='feeRow'>
								<Grid item xs={12} md={4} className='feeTitle'>
									<div>契約者ID</div>
								</Grid>
								<Grid item xs={12} md={8} className='feeVal'>
									<MatTypography name={'id'} view={this.view} className='itemUnit' style={{ color: '#484848' }}></MatTypography>
								</Grid>
							</Grid>	
						</div>
					</div>
					<Divider />
					<div className='pageFooter footer-space'>
						<div>
							<MatButton name='next' view={this.view} className='orangeButton orangeButton_parts' >
								再発行
								<NavigateNextIcon />
							</MatButton>
						</div>
						<div>
							<MatButton name='back' view={this.view} className='backButton' >
								<NavigateBeforeIcon />
								戻る
							</MatButton>

						</div>
					</div>

				</div>
			</div>
			</>
		);
	}
	// --------------------------------------------------------------------------
}
