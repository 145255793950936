import React, { ReactNode } from 'react';
import { WprBaseViewComponent } from '../../../../wpr-framework/view/component/WprBaseViewComponent';
import { TokotonSupportSelectView } from './TokotonSupportSelectView';
import { Typography, Grid, Divider } from '@material-ui/core';
import { MatButton } from '../../../../wpr-framework/component/controls/material/MatButton';
import { MatCheckBox } from '../../../../wpr-framework/component/controls/material/MatCheckBox';
import { MatSelect } from '../../../../wpr-framework/component/controls/material/MatSelect';
import { ProgressBar } from '../../common/progress_bar/ProgressBar';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { MatTypography } from '../../../../wpr-framework/component/controls/material/MatTypography';
import { WprRowInfo } from '../../../../wpr-framework/component/props/WprRowInfo';
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import styles from './TokotonSupportSelect.scss';
import nextIcon from '/shared/image/common/next_icon_orange.png';
import { WprContainer } from '../../../../wpr-framework/component/controls/WprContainer';

/**
 * とことんサポートサービス選択用コンポーネント
 */
export class TokotonSupportSelect extends WprBaseViewComponent {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: any) { super(new TokotonSupportSelectView(), props); }
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** とことんサポートサービス選択ビュー情報 */
	public get viewInfo(): TokotonSupportSelectView { return this.view as TokotonSupportSelectView; }
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * スタイルオブジェクト取得
	*/
	public getStylesObject(): any { return styles; }

	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		return (
			<>
				<div className='pageCard confirmButton'>
					<div className='pageHeader stepFlow'>
						<ProgressBar name='progressBar' view={this.view} />
					</div>
					<div className='main'>
						<div className='pageTitle'>
							<div className='title_left'>
								<Typography className='title_text'>とことんサポート追加申込み</Typography>
								<Typography className='title_comment'>ご希望のコースを選択してください。</Typography>
							</div>
							<div className='detail_link'>
								<a href="https://www.itscom.co.jp/support/assistance/tokoton/" target='_brank' style={{ color: 'black', textDecoration: 'none' }}>
									<b>とことんサポートの詳細はこちら　</b>
									<img src={nextIcon} style={{width:'20px',verticalAlign:'bottom'}}/>
								</a>
							</div>
						</div>
						<div className='table_head confirmButton'>
							<div className='pageContent'>
								<Grid container className='table_gray_headerTop'>
									<Grid item xs={12} sm={6} md={6} className='table_unit'>
										<Grid item xs={12} className='rowTitle'>
											<span style={{ fontSize: '16px' }}>コース名</span>
										</Grid>
										<Grid item xs={12} className='rowContent content_text'>
											<MatTypography name='courseNm' view={this.view}/>
										</Grid>
									</Grid>
									<Grid item xs={12} sm={6} md={6} className='table_unit'>
										<Grid item xs={12} className='rowTitle'>
											<span style={{ fontSize: '16px' }}>申込み</span>
										</Grid>
										<Grid item xs={12} className='rowContent'>
											<MatSelect name='applicationType' vm='とこサポ申込み種別' bind='string' view={this.view} style={{ fontSize: '14px', fontWeight: '400'}}/>
										</Grid>
									</Grid>
								</Grid>
							</div>
							<div className='supportMsgArea'>
								<ul className='aster' style={{ listStyle: 'none', paddingLeft: '1rem', paddingRight: '1rem' }}>
									<span className='textcolor'>
										事前確認項目
									</span>
									<li className='comment'>ご利用開始日の属する月の翌月より6カ月以内に「イッツコムとことんサポート」を解約された場合、解約料金として
										3,300円（税込）がかかります。また、解約後6カ月間は「イッツコムとことんサポート」への再加入はできません。
										<br />
									</li>
								</ul>
							</div>
						</div>
						<WprContainer mode='サポート対応.表示' view={this.view}>
							<div className='title_center' style={{ marginTop: '30px' }}>
								<div className='title_center'>
									<MatCheckBox name='supportFlg' label='サポート対応を希望する' view={this.view} />
								</div>
							</div>
						</WprContainer>
						<div className='noteArea'>
							「イッツコムとことんサポート」は以下サービスご契約中の方へご提供しております。
							<br/>単体でのご提供はしておりませんので予めご了承ください。
							<ol className='ul_ten' style={{marginBottom: 0}}>
								<li>ケーブルテレビジョンサービス</li>
								<li>イッツコムひかり テレビジョンサービス</li>
								<li>ケーブルインターネットサービス</li>
								<li>かっとび光　★</li>
								<li>かっとびＭＡＮＳＩＯＮ ＬＡＮインターネット利用サービス　★</li>
								<li>イッツコムひかり インターネットサービス</li>
								<li>ケーブルプラス電話</li>
								<li>ｉＴＳＣＯＭ ＨＯＭＥ　★</li>
								<li>イッツコム テレビ・プッシュ</li>
								<li>イッツコム ＳＩＭ　★</li>
							</ol>
							※“★”がついているサービスについてはイッツコムサービスエリア内にお住まいのお客さまに限ります。
							<div className='text'>
								ご契約中のサービスに対するサポート提供となります。
								<br/>ご契約のないサービスや機器に関しては対応しておりません。
							</div>
							<div className='text'>
								例)ケーブルテレビジョンサービスご契約　→　ＴＶに関するサポート提供
								<br/>※パソコンに関するサポートはイッツコムひかり テレビジョンサービスもしくはケーブルインターネットサービスのご契約が必要です。
							</div>
							<ul className='ul_kome'>
								<li>
									お申し込みと同時にサポートをご希望の場合にチェックが入っている場合は数日中にご連絡させていただきます。
								</li>
								<li>
									“★”がついているサービスについては<a href='https://www.itscom.co.jp/info/areamap/' target='_blank'>イッツコムサービスエリア内</a>にお住まいのお客さまに限ります。
								</li>
								<li>
									現在、上記サービスをご契約中でないお客さまもこちらのフォームから同時にお申し込みが可能です。
								</li>
								<li>
									「イッツコムとことんサポート」には6ヵ月間の最低契約期間があります。途中解約の場合は違約金3,300円(税込)をお支払いいただきます。
								</li>
								<li>
									お申し込み後、「イッツコムとことんサポート」の専用フリーダイヤルを記載したマグネットと利用規約をご自宅宛てにお送りします。
								</li>
							</ul>
						</div>
						<div className='title_center' style={{ marginTop: '30px' }}>
							<div className='title_center'>
								<MatCheckBox name='confirm' label='同意しました' view={this.view} />
							</div>
						</div>
					</div>
					<Divider style={{ marginTop: '60px' }}/>
					<div className='pageFooter footer-space'>
						<div>
							<MatButton name='next' view={this.view} className='orangeButton orangeButton_parts' >
								申込む
								<NavigateNextIcon />
							</MatButton>
						</div>
						<div>
							<MatButton name='back' view={this.view} className='backButton' >
								<NavigateBeforeIcon />
								戻る
							</MatButton>
						</div>
					</div>
				</div>
			</>
		);
	}
	// --------------------------------------------------------------------------
}
