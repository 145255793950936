import { WprValidErrorInfo } from '../../view/validation/WprValidErrorInfo';
import { IWprError } from '../error/IWprError';
import { WprControlVisibility } from '../props/IWprControlState';
import { IWprLayoutErrorProps, IWprNoNameLayoutErrorProps } from '../props/IWprLayoutProps';
import { WprBaseLayoutComponent } from './WprBaseLayoutComponent';
import { WprBaseNoNameLayoutComponent } from './WprBaseNoNameLayoutComponent';

/**
 * レイアウトエラー基本コンポーネント
 */
export abstract class WprBaseErrorLayoutComponent<T extends IWprLayoutErrorProps, U> extends WprBaseLayoutComponent<T, U> implements IWprError {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: T) {
		super(props);
		this.state = {
			value: null,
			style: null,
			className: null,
			visible: false,
			modeClassName: null,
			visibility: WprControlVisibility.VISIBLE,
			option1: null,
			option2: null,
			option3: null,
			numOption1: 0,
			numOption2: 0,
			boolOption1: false,
			boolOption2: false,
			errList: null
		};
	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * マウント通知
	 */
	 public onDidMount() {
		super.onDidMount();
		this.props.view.addErrorControl(this, this.props.subView);
	 }

	/**
	 * アンマウント通知
	 */
	 public componentWillUnmount() {
		 super.componentWillUnmount();
		this.props.view.deleteErrorControl(this, this.props.subView);
	}
	// --------------------------------------------------------------------------

	// protected メソッド  -------------------------------------------------------
	/**
	 * クラス取得
	 * @param cls 固定クラス 
	 * @returns クラス
	 */
	protected className(cls: string): string {
		cls = super.className(cls);
		const validCls = this.getVlidClassName();
		if (validCls) {
			if (cls)
				cls = validCls + ' ' + cls;
			else
				cls = validCls;
		}
		const local = this.props.view.getLocalClassName(cls);
		if (local != null)
			return local;
		return cls;
	}
	// --------------------------------------------------------------------------

	// private メソッド  --------------------------------------------------------
	/**
	 * バリデーションクラス名取得
	 * @returns バリデーションクラス名
	 */
	private getVlidClassName(): string {
		if (this.props.validControl && this.state.errList != null) {
			let list = null;
			if (this.props.row)
				list = this.state.errList.filter(minfo => (this.props.validControl === undefined || this.checkControlName(minfo.controlName) === true) && minfo.rowIndex === this.props.row.index);
			else
				list = this.state.errList.filter(minfo => this.props.validControl === undefined || this.checkControlName(minfo.controlName) === true);
			if (list.length > 0)
				return this.props.errorClass;
			return this.props.normalClass;
		}
		return null;
	}

	/**
	 * コントロール名チェック
	 * @param controlName コントロール名
	 * @returns trueの場合、対象コントロール
	 */
	 private checkControlName(controlName: string): boolean {
		if (this.props.validControl.includes(',') === false)
			return controlName === this.props.validControl;
		let rtn = false;
		this.props.validControl.split(',').forEach(cname => {
			if (cname.trim() === controlName)
				rtn = true;
		});
		return rtn;
	}
	// --------------------------------------------------------------------------

	// IWprErrorの実装  ----------------------------------------------------------
	/**
	 * エラー情報リスト設定
	 * @param msgList エラー情報リスト
	 */
	public setErrorList(errList: WprValidErrorInfo[]): void {
		if (this.isMount == false)
			return;
		this.setState({
			errList: errList
		});
	}
	// --------------------------------------------------------------------------
}

/**
 * レイアウトエラー基本コンポーネント
 */
export abstract class WprBaseErrorNoNameLayoutComponent<T extends IWprNoNameLayoutErrorProps, U> extends WprBaseNoNameLayoutComponent<T, U> implements IWprError {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: T) {
		super(props);
		this.state = {
			value: null,
			style: null,
			className: null,
			visible: false,
			modeClassName: null,
			visibility: WprControlVisibility.VISIBLE,
			option1: null,
			option2: null,
			option3: null,
			numOption1: 0,
			numOption2: 0,
			boolOption1: false,
			boolOption2: false,
			errList: null
		};
	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * マウント通知
	 */
	public onDidMount() {
		this.props.view.addErrorControl(this, this.props.subView);
	}

	/**
	 * アンマウント通知
	 */
	public componentWillUnmount() {
		this.props.view.deleteErrorControl(this, this.props.subView);
	}
	// --------------------------------------------------------------------------

	// protected メソッド  -------------------------------------------------------
	/**
	 * クラス取得
	 * @param cls 固定クラス 
	 * @returns クラス
	 */
	protected className(cls: string): string {
		if (this.props.className) {
			if (cls)
				cls = this.props.className + ' ' + cls;
			else
				cls = this.props.className;
		}
		const validCls = this.getVlidClassName();
		if (validCls) {
			if (cls)
				cls = validCls + ' ' + cls;
			else
				cls = validCls;
		}
		const local = this.props.view.getLocalClassName(cls);
		if (local != null)
			return local;
		return cls;
	}
	// --------------------------------------------------------------------------

	// private メソッド  --------------------------------------------------------
	/**
	 * バリデーションクラス名取得
	 * @returns バリデーションクラス名
	 */
	private getVlidClassName(): string {
		if (this.props.validControl && this.state.errList != null) {
			let list = null;
			if (this.props.row)
				list = this.state.errList.filter(minfo => (this.props.validControl === undefined || this.checkControlName(minfo.controlName) === true) && minfo.rowIndex === this.props.row.index);
			else
				list = this.state.errList.filter(minfo => this.props.validControl === undefined || this.checkControlName(minfo.controlName) === true);
			if (list.length > 0)
				return this.props.errorClass;
			return this.props.normalClass;
		}
		return null;
	}

	/**
	 * コントロール名チェック
	 * @param controlName コントロール名
	 * @returns trueの場合、対象コントロール
	 */
	private checkControlName(controlName: string): boolean {
		if (this.props.validControl.includes(',') === false)
			return controlName === this.props.validControl;
		let rtn = false;
		this.props.validControl.split(',').forEach(cname => {
			if (cname.trim() === controlName)
				rtn = true;
		});
		return rtn;
	}
	// --------------------------------------------------------------------------

	// IWprErrorの実装  ----------------------------------------------------------
	/**
	 * エラー情報リスト設定
	 * @param msgList エラー情報リスト
	 */
	public setErrorList(errList: WprValidErrorInfo[]): void {
		if (this.isMount == false)
			return;
		this.setState({
			errList: errList
		});
	}
	// --------------------------------------------------------------------------
}