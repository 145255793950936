import { WprBaseConverter } from '../../../../wpr-framework/view/convert/WprBaseConverter';
import { ObjectUtil } from '../../../common/util/ObjectUtil';

/**
 * 郵便番号コンバーター
 */
export class PostCodeConverter extends WprBaseConverter {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() {
		super('郵便番号');
	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * 値を表示用の値に変換する (value -> viewValue)
	 * @param value 値
	 * @returns 表示用の値
	 */
	public convertView(value: any): string {
		const strVal: string = value as string;
		if (ObjectUtil.isNotNullOrUndefined(strVal) && '' !== strVal) {
			return '〒' + strVal.substring(0,3) + '-' + strVal.substring(3,7);
		}
		return value;
	}
	// --------------------------------------------------------------------------
}
