import { HMvnoTmpSaved } from '../../entity/HMvnoTmpSaved';

/**
 * MVNO一時保存取得リクエスト
 */
export class GetHMvnoTmpSavedRequest {
	// public 変数	-----------------------------------------------------------
	public target: HMvnoTmpSaved;	// MVNO一時保存
	// ------------------------------------------------------------------------
}
