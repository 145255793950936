import { InquiryMailInfo } from "../../apimodels/InquiryMailInfo";

/**
 * 共通登録リクエスト
 */
export class RegistInfoRequest {
	// public 変数	-----------------------------------------------------------
	public customerId: string;				// 顧客ID
	public customerMailId: string;			// 顧客メールID
	public systemMailId: string;			// システムメールID
	public inquiryId: string;				// お問い合わせID
	public updateLogId: string;				// 履歴更新ID
	public systemInfo: InquiryMailInfo;		// メール情報(業務部門,お問い合わせ)
	// ------------------------------------------------------------------------
}
