import { MypageBaseView } from '../../../base/MypageBaseView';
import { WprModel } from '../../../../../wpr-framework/view/deco/WprModel';
import { WprDI_ScopeModel } from '../../../../../wpr-framework/view/di/WprDI_ScopeModel';
import { HMvnoTmpSaved } from '../../../../service/models/entity/HMvnoTmpSaved';
import { MvnoEntryInfo } from '../../../../service/models/apimodels/MvnoEntryInfo';
import { WprViewMode } from '../../../../../wpr-framework/view/deco/WprViewMode';
import { NextButtonMode } from './mode/NextButtonMode';
import { MvnoService } from '../../../../service/MvnoService';
import { CreateMvnoInquiryRequest } from '../../../../service/models/dto/mvno/CreateMvnoInquiryRequest';
import { MvnoCapacityChangeInfo } from '../../../../service/models/apimodels/MvnoCapacityChangeInfo';
import { CustomerInfo } from '../../../../service/models/apimodels/CustomerInfo';
import { MypageLinkConstatns } from '../../../../common/constants/MypageLinkConstatns';
import { MvnoCompleteView } from '../../mvno_complete/MvnoCompleteView';
import { ProgressBarModel } from '../../../../models/ProgressBarModel';
import { ProgressBarData } from '../../../common/progress_bar/ProgressBarView';
import { ConvertViewUtil } from '../../../../common/util/ConvertViewUtil';
import { ObjectUtil } from '../../../../common/util/ObjectUtil';
import { GetCapacityChangeRequest } from '../../../../service/models/dto/mvno/GetCapacityChangeRequest';
import { MGeneral } from '../../../../service/models/entity/MGeneral';

/**
 * MVNO容量変更_事前確認事項
 */
export class MvnoCapacityChangePreConfirmView extends MypageBaseView {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() { super('MvnoCapacityChangePreConfirm'); }
	// --------------------------------------------------------------------------

	// モデル  ------------------------------------------------------------------
	// @WprDI_ScopeModel('MvnoEntryInfo')
	// @WprModel('MvnoEntryInfo')
	// private m_EntryInfo: MvnoEntryInfo	= null;	// MVNOプラン情報

	@WprModel('confirm')
	private m_confirm: boolean	= null;	// 確認

	@WprDI_ScopeModel('CapacityChangeInfo')
	private m_CapacityChangeInfo: MvnoCapacityChangeInfo	= null;	// MVNOプラン情報

	@WprDI_ScopeModel('CapacityRadioList')
	private m_CapacityRadioList: MGeneral[] = null;	// 容量ラジオ選択肢リスト

	// --------------------------------------------------------------------------

	// サブビュー/モード  ---------------------------------------------------------
	@WprViewMode
	private m_NextButtonMode = new NextButtonMode();	// 次へボタン用モード
	// --------------------------------------------------------------------------

	// サービス  ----------------------------------------------------------------
	private m_MvnoService: MvnoService = new MvnoService();	// MyPageRenewalサービス
	// --------------------------------------------------------------------------
	

	// override メソッド  --------------------------------------------------------
	/**
	 * コントロール定義設定
	 */
	public onRegisterConfig(): void {
		this.addConfig('c_confirm', '確認', 'confirm', { bindModel: true });
		// this.addConfig('entryTerminalFlg', '端末申込', 'MvnoEntryInfo.entryInfo');
	}

	/**
	 * アクション登録
	 */
	public onRegisterAction(): void {
		this.addAction('next', this.onNext);
		this.addAction('back', this.onBack);
		this.addAction('helpLink', this.onHelpLink);
	}

	/**
	 * ビュー表示通知
	 */
	public onShowView(): void {
		// リロードした際にスコープ情報が消えるため取得し直し
		if (ObjectUtil.isNullOrUndefined(this.m_CapacityChangeInfo)) {
			const capaReq: GetCapacityChangeRequest = new GetCapacityChangeRequest();
			capaReq.cusomerId = this.getCustomerId();
			capaReq.contractId = this.getContractCd();;
			capaReq.capacityServiceCdAfter = this.getCookie('afterCapacityServiceCd');
			this.m_MvnoService.getCapacityChangeInfo(this, capaReq, (result) => {
				this.m_CapacityChangeInfo = result.capacityChangeInfo;
				this.m_CapacityRadioList = result.capacityRadioList;
				this.setScopeModel('CapacityRadioList', this.m_CapacityRadioList);
				this.setScopeModel('CapacityChangeInfo', this.m_CapacityChangeInfo);
				this.refreshView();
			});
		}
		this.registAccessLog();
			this.setStorageItem('b_url', window.location.href)

		const sendData: ProgressBarData = new ProgressBarData();
		sendData.progressBarList = this.getProgressModelList();
		this.sendChildData('fP_ev_progressBarIntital', sendData);
		this.refreshView();
	}
	// --------------------------------------------------------------------------

	// アクション  ---------------------------------------------------------------
	/**
	 * 次へ
	 */
	 public onNext(param: any): void {
		// お問い合わせ
		const req: CreateMvnoInquiryRequest = new CreateMvnoInquiryRequest();
		req.customerId = this.getCustomerId();
		req.contractCd = this.getContractCd();
		req.changeInfo = this.m_CapacityChangeInfo;
		req.mvnoInquiryType = '容量変更';
		this.m_MvnoService.createMvnoInquiry(this, req, (result) => {
			this.setScopeMode('MVNO', MvnoCompleteView.MVNO_CAPACITY_CHANGE);
			const customerInfo: CustomerInfo = this.m_CapacityChangeInfo.customerInfo;
			this.setScopeModel('customerName', ConvertViewUtil.convertCustName(customerInfo.familyName ,customerInfo.firstName) );
			// 選択内容の削除
			this.removeCookie('afterCapacityServiceCd');
			this.pushPath('/mvno_change_004');
		});
	}

	/**
	 * 戻る
	 */
	public onBack(param: any): void {
		this.refreshModel();
		this.pushPath('/mvno_change_001');
	}
	
	/**
	 * 問い合わせリンク
	 */
	public onHelpLink(param: any): void {
		window.open(MypageLinkConstatns.ITSCOM_TOIAWASE);
	}
	// --------------------------------------------------------------------------
	/**
	 * ステップバー
	 * @returns 
	 */
	private getProgressModelList(): ProgressBarModel[] {
		const list: ProgressBarModel[] = new Array();

		list.push(new ProgressBarModel('1', 'お申込み', 'complete'));
		list.push(new ProgressBarModel('2', 'お申込み内容確認', 'complete'));
		list.push(new ProgressBarModel('3', '事前確認事項', 'current'));
		list.push(new ProgressBarModel('4', 'お申込み完了', 'next'));
		return list;
	}
}
