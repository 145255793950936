import { WprBaseScopeInfo } from '../scope/WprBaseScopeInfo';
import { WprBaseViewInfo } from './WprBaseViewInfo';
import { WprBaseViewMode } from '../mode/WprBaseViewMode';
import { WprBaseViewMultiMode } from '../mode/WprBaseViewMultiMode';
import { WprMultiMode } from '../mode/WprMultiMode';

/**
 * スコープ付きView情報基本クラス
 */
export abstract class WprBaseScopeViewInfo extends WprBaseViewInfo {
	// private 変数  ------------------------------------------------------------
	private m_ScopeInfo: WprBaseScopeInfo	= null;		// スコープ情報
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	public constructor(name: string, scope: string) {
		super(name);
		this.FW.scope.scopeMap.forEach((sinfo, key, map) => {
			if (sinfo.isGlobal === false && sinfo.name === scope)
				this.m_ScopeInfo = sinfo;
		});
		if (this.m_ScopeInfo == null)
			this.addErrorLog(`登録されていないスコープ名が指定されています。[${name}][${scope}]`);
	}
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** 前画面のビュー名(onShowView内で有効) */
	public get prevViewName(): string { 
		if (this.m_ScopeInfo != null)
			return this.m_ScopeInfo.prevViewName;
		return null;
	}
	// --------------------------------------------------------------------------

	// override メソッド  -------------------------------------------------------
	/**
	 * スコープ情報取得
	 * @returns スコープ情報
	 */
	public getScopeInfo(): WprBaseScopeInfo {
		return this.m_ScopeInfo;
	}

	/**
	 * 初期化処理
	 */
	public initialize(): void {
		if (this.m_ScopeInfo != null)
			this.m_ScopeInfo.intiView(this);
		super.initialize();
	}

	/**
	 * ビュー表示処理
	 * @param isMount マウントフラグ
	 */
	public showView(isMount: boolean = true): void {
		super.showView(isMount);
		if (this.m_ScopeInfo != null)
			this.m_ScopeInfo.showView(this);
	}

	/**
	 * 終了処理
	 */
	public terminate(): void {
		if (this.m_ScopeInfo != null)
			this.m_ScopeInfo.terminateView(this);
		super.terminate();
	}

	/**
	 * ビューモード変更処理
	 * @param mode モード情報
	 * @param oldMode 古いモード
	 * @param newMode 新しいモード
	 */
	public changeViewMode(mode: WprBaseViewMode, oldMode: string, newMode: string): void {
		if (this.m_ScopeInfo != null)
			this.m_ScopeInfo.changeViewMode(this, mode, oldMode, newMode);
		super.changeViewMode(mode, oldMode, newMode);
	}

	/**
	 * 複数ビューモード変更処理
	 * @param oldMode 変更前モード
	 * @param newMode 変更後モード
	 */
	public changeViewMultiMode(mode: WprBaseViewMultiMode, oldMode: WprMultiMode, newMode: WprMultiMode): void {
		if (this.m_ScopeInfo != null)
			this.m_ScopeInfo.changeViewMultiMode(this, mode, oldMode, newMode);
		super.changeViewMultiMode(mode, oldMode, newMode);
	}

	/**
	 * ビュー表示終了処理
	 */
	public onEndShowView(): void {
		if (this.m_ScopeInfo != null)
			this.m_ScopeInfo.prevViewName = this.name;
	}

	/**
	* サービスエラー通知処理
	* @param url URL
	* @param status ステータス
	* @param message エラーメッセージ 
	* @param error エラー情報
	*/
	public onServiceError(url: string, status: string, message: string, error?: Error): void {
		console.log('Serviceエラー');
		this.pushPath('/error');
	}

	/**
	 * サービスエラー通知処理
	 * @param url URL
	 * @param status ステータス
	 * @param message エラーメッセージ 
	 * @param error エラー情報
	 * @param resMethod 結果処理メソッド
	 */
	public onServiceErrorResult(url: string, status: string, message: string, resMethod?: (result: any) => void): void{
	}
	// --------------------------------------------------------------------------

	// public メソッド  ---------------------------------------------------------
	/**
	 * ビュースコープ通知処理(ビュースコープ内の他のビューに通知)
	 * @param viewInfo 通知元ビュー情報
	 * @param notifyName 通知名
	 * @param info 通知情報
	 * @param toViewName 通知先ビュー名
	 */
	public notifyScopeView(viewInfo: WprBaseViewInfo, notifyName: string, info: any, toViewName?: string) {
		this.m_ScopeInfo.notifyScopeView(viewInfo, notifyName, info, toViewName);
	}
	// --------------------------------------------------------------------------
}