import { MypageBaseView } from '../../../base/MypageBaseView';
import { WprListData } from '../../../../../wpr-framework/view/deco/WprListData';
import { ProgressBarData } from '../../../common/progress_bar/ProgressBarView';
import { WprDI_ScopeModel } from '../../../../../wpr-framework/view/di/WprDI_ScopeModel';
import { MypageWizardMngr } from '../../../wizard/models/MypageWizardMngr';
import { ItscomHomeOptRivComfirmList } from '../../../../models/mypage/smart/ItscomHomeOptRivComfirmList';
import { ItscomHomeOptionListModel } from '../../../../models/mypage/smart/ItscomHomeOptionListModel';
import { BaseInfo } from '../../../../models/mypage/smart/BaseInfo';
/**
 * ITSCOMHOME_オプション変更(申込み内容の確認)
 */
export class ItscomHomeReviceComfirmView extends MypageBaseView {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() { super('ItscomHomeReviceComfirmView'); }
	// --------------------------------------------------------------------------

	// モデル  ------------------------------------------------------------------
	@WprDI_ScopeModel('MypageWizardMngr')
	private m_MypageWizardMngr: MypageWizardMngr			= null;		// 画面ウィザード管理クラス
	@WprListData('OptRivComfirmListModel')
	private m_OptRivComfirmListModel: ItscomHomeOptRivComfirmList[]	= null;	// ItscomHomeオプション変更確認モデル
	@WprListData('OptionListModel')
	private m_OptionListModel: ItscomHomeOptionListModel[]	= null;	// ItscomHomeオプションモデル
	// --------------------------------------------------------------------------

	// メンバ変数  ---------------------------------------------------------------
	private m_BaseInfo: BaseInfo	= null;	// 基本情報
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * コントロール定義設定
	 */
	public onRegisterConfig(): void {
		this.addConfig('option', 'オプション', 'OptRivComfirmList');
		this.addConfig('detail', '詳細', 'OptRivComfirmList');
		this.addListConfig('option', 'オプション', 'OptRivComfirmListModel');
		this.addListConfig('detail', '詳細', 'OptRivComfirmListModel');
	}

	/**
	 * アクション登録
	 */
	public onRegisterAction(): void {
		this.addAction('next', this.onNext);
		this.addAction('back', this.onBack);
	}

	/**
	 * ビュー表示通知
	 */
	public onShowView(): void {
		this.m_MypageWizardMngr.initView(this, (result) => {
			this.registAccessLog();
			this.setStorageItem('b_url', window.location.href)
			const sendData: ProgressBarData = new ProgressBarData();
			sendData.progressBarList = this.m_MypageWizardMngr.getProgressModelList(this);
			this.sendChildData('fP_ev_progressBarIntital', sendData);
			// 基本情報
			this.m_BaseInfo = this.m_MypageWizardMngr.getCache().m_BaseInfo;
			// this.m_BaseInfo.ownerIhflag = false;	// 確認用※後で消す※
			// オプション
			this.m_OptionListModel = this.m_MypageWizardMngr.getCache().m_OptionListModel;	// 前画面の入力情報
			const selectOptionList = this.m_OptionListModel.filter(row => row.quantity1 || row.quantity2);
			this.setComfirmOptionList(selectOptionList);
			this.refreshView();
		});
	}
	// --------------------------------------------------------------------------

	// アクション  ---------------------------------------------------------------
	/**
	 * 申し込む
	 */
	public onNext(param: any): void {
		this.m_MypageWizardMngr.goNext(this);
	}

	/**
	 * 戻る
	 */
	public onBack(param: any): void {
		this.m_MypageWizardMngr.goBack(this);
	}
	// --------------------------------------------------------------------------
	// privateメソッド -----------------------------------------------------------
	/**
	 * オプションリスト(表示用)
	 * @param optList 
	 */
	private setComfirmOptionList(optList:ItscomHomeOptionListModel[]) {
		this.m_OptRivComfirmListModel = new Array();
		for(const list of optList) {
			// レンタルありの場合
			if (list.quantity1) {
				let model = new ItscomHomeOptRivComfirmList();
				// オーナー契約IHの場合
				if (this.m_BaseInfo.ownerIhflag) { 
					model.detail = '※ご連絡にてご案内致します。';
				}
				else {
					model.detail = '月額' + Number(list.rental).toLocaleString() +'円（税込）' + '　' + list.quantity1 + list.rentalUnit;
				}
				model.option = list.option;
				this.m_OptRivComfirmListModel.push(model);
			}
			// 購入ありの場合
			if (list.quantity2) {
				let model = new ItscomHomeOptRivComfirmList();
				if (this.m_BaseInfo.ownerIhflag) {
					model.detail = '※ご連絡にてご案内致します。';
				}
				else {
					model.detail = '価格' + Number(list.price).toLocaleString() + '円（税込）' + '　' + list.quantity2 + list.buyUnit;
				}
				model.option = list.option + '(購入)';
				this.m_OptRivComfirmListModel.push(model);
			}
		}
		this.setListData('OptRivComfirmListModel', this.m_OptRivComfirmListModel);
	}
	// --------------------------------------------------------------------------
}
