import React, { ReactNode } from 'react';
import { WprBaseViewComponent } from '../../../../wpr-framework/view/component/WprBaseViewComponent';
import { NhkEditView } from './NhkEditView';
import { Typography, Grid, Divider } from '@material-ui/core';
import { WprContainer } from '../../../../wpr-framework/component/controls/WprContainer';
import { MatButton } from '../../../../wpr-framework/component/controls/material/MatButton';
import { MatSelect } from '../../../../wpr-framework/component/controls/material/MatSelect';
import { MatTypography } from '../../../../wpr-framework/component/controls/material/MatTypography';
import { WprRowInfo } from '../../../../wpr-framework/component/props/WprRowInfo';
import { ProgressBar } from '../../common/progress_bar/ProgressBar';
import nextIcon from '/shared/image/common/next_icon_orange.png';
import nhkSelect from '/shared/image/common/nhk_select.png';
import importantIcon from '/shared/image/common/important_purple.png';
import errorIcon from '/shared/image/common/icon_error.png';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import styles from './NhkEdit.scss';
import { MatCheckBox } from '../../../../wpr-framework/component/controls/material/MatCheckBox';

/**
 * 支払いコースの変更用コンポーネント
 */
export class NhkEdit extends WprBaseViewComponent {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: any) { super(new NhkEditView(), props); }
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** 支払いコースの変更ビュー情報 */
	public get viewInfo(): NhkEditView	{ return this.view as NhkEditView;	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * スタイルオブジェクト取得
	*/
	public getStylesObject(): any { return styles; }

	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		return (
			<>
				<div>
				<div className='pageCard'>
					<div  className='pageHeader stepFlow'>
						<ProgressBar name='progressBar' view={this.view}/>
					</div>
					<div className='main' style={{marginBottom:'0px',paddingBottom:'20px'}}>
						<div className='pageTitle'>
							<div className='title_left'>
								<MatTypography name='title' className='title_text' view={this.view} />
								<Typography className='title_comment'  style={{fontWeight:'500'}}>NHK団体一括支払いの種類を選択してください。</Typography>
							</div>
						</div>
						<WprContainer mode='オプション.エラー' view={this.view}>
							<div className='errorArea'>
								<span className='parallel'>
									<img src={errorIcon} alt='' className='x_img'/>
									<div className='errorList'>
										{this.renderList('ErrorModel')}
									</div>
								</span>
							</div>
						</WprContainer>
						<div>　</div>
						<div style={{textAlign:'right'}}>
							<a href='https://www.itscom.co.jp/service/cabletv/option/nhk.html' target='_blank' style={{color:'black' ,textDecoration:'none'}}>
								<b>NHK団体一括支払いの詳細はこちら　</b>
								<img src={nextIcon} style={{ width: '20px', verticalAlign: 'bottom' }} />
							</a>
						</div>
						<div className='feeTable'>
							<Grid container className='feeRow'>
								<Grid item xs={12} md={4} className='feeTitle'>
									<div>割引種類</div>
								</Grid>
								<Grid item xs={12} md={8} style={{padding:'1rem',height:'67px', paddingTop:'13px'}} className='courseValue'>
									<span className='itemUnit'>
										<MatSelect name='aftDiscountCode' vm='割引種類' bind='string' className='combo-width' view={this.view}/>
									</span>
								</Grid>
							</Grid>	
						</div>
						{/* <div className='feeTable'>
							<Grid container className='feeRow'>
								<Grid item xs={12} md={4} className='feeTitle'>	
									<div>送付先</div>
								</Grid>
								<Grid item xs={12} md={8} className='feeVal' style={{color:'black',fontSize:'17px'}}>
									<MatCheckBox name='checkBox1' className='checkbox_size' view={this.view} /><span style={{fontWeight:'700'}} className = 'font_black'>契約住所へ送付</span>
								</Grid>
							</Grid>	
							<Grid container className='feeRow' style={{borderTop:'none'}}>
								<Grid item xs={12} md={4} className='feeTitle' >
									
								</Grid>
								<Grid item xs={12} md={8} className='feeVal' style={{color:'black',borderTop:'1px solid #E1E4ED'}}>
									<MatCheckBox name='checkBox2' className='checkbox_size' view={this.view} /><span style={{fontWeight:'700'}} className = 'font_black'>契約住所以外に送付</span>
								</Grid>
							</Grid>	
							<Grid container className='feeRow' style={{borderTop:'none'}}>
								<Grid item xs={12} md={4} className='feeTitle'>
								</Grid>
								<Grid item xs={12} md={8} className='feeVal' style={{color:'black',borderTop:'1px solid #E1E4ED'}}>
									<span style={{fontWeight:'700'}} className = 'font_black'>郵便番号　　　</span>
									<WprInput name='postalNumber' placeholder='1234567' style={{width:'70%',textArrign:'right', backgroundColor:'#F7F7F7'}} className='nhk' view={this.view}/>	
								</Grid>
							</Grid>	
							<Grid container className='feeRow' style={{borderTop:'none'}}>
								<Grid item xs={12} md={4} className='feeTitle'>
								</Grid>
								<Grid item xs={12} md={8} className='feeVal' style={{color:'black',borderTop:'1px solid #E1E4ED'}}>
									<span style={{fontWeight:'700'}} className = 'font_black'>住所　　　　　</span>
									<WprInput  name='address' className= 'nhk' style={{width:'70%' ,marginTop:'17px' , backgroundColor:'#F7F7F7'}} placeholder='世田谷区用賀４－１０－１' view={this.view}/>
								</Grid>
							</Grid>	
							<Grid container className='feeRow' style={{borderTop:'none'}}>
								<Grid item xs={12} md={4} className='feeTitle'>
								</Grid>
								<Grid item xs={12} md={8} className='feeVal' style={{color:'black',borderTop:'1px solid #E1E4ED'}}>
									<span style={{fontWeight:'700'}} className = 'font_black'>建物名　　　　</span>
									<WprInput  name='buildingName' className= 'nhk' style={{width:'70%' ,marginTop:'17px' , backgroundColor:'#F7F7F7'}} placeholder='イッツコムマンション' view={this.view}/>
								</Grid>
							</Grid>	
							<Grid container className='feeRow' style={{borderTop:'none'}}>
								<Grid item xs={12} md={4} className='feeTitle'>
								</Grid>
								<Grid item xs={12} md={8} className='feeVal' style={{color:'black',borderTop:'1px solid #E1E4ED'}}>
									<span style={{fontWeight:'700'}} className = 'font_black'>部屋番号　　　</span>
									<WprInput  name='roomNumber' style={{width:'70%' ,marginTop:'17px' , backgroundColor:'#F7F7F7'}} className= 'nhk' placeholder='306' view={this.view}/>
								</Grid>
							</Grid>	
							<Grid container className='feeRow' style={{borderTop:'none'}}>
								<Grid item xs={12} md={4} className='feeTitle'>
								</Grid>
								<Grid item xs={12} md={8} className='feeVal' style={{color:'black',borderTop:'1px solid #E1E4ED'}}>
									<span style={{fontWeight:'700'}} className = 'font_black'>宛名　　　　　</span>
									<WprInput  name='mailing' style={{width:'70%' ,marginTop:'17px' , backgroundColor:'#F7F7F7'}} className= 'nhk' type='' view={this.view}/>
								</Grid>
								
							</Grid>	
						</div> */}
						<div style = {{marginBottom: '50px'}}>
						</div>
						<div className='supportMsgArea'>
							<span style = {{color:'#6169B8', fontWeight:'700'}}>
								<img style={{ marginRight: '8px',verticalAlign: 'bottom',marginBottom: '3px'}} src={importantIcon}></img>
								事前確認事項
							</span>
							<br/>	
							<div>・申込完了後、弊社からNHK団体一括申込書を送付いたします。</div>
							<div>・NHK団体一括申込書は普通郵便で送付いたします。</div>
							<div>・ケーブルテレビの月額利用料にはNHK受信料は含まれておりません。</div>
							<div>・すでに衛星契約放送受信料をお支払いの方も団体一括支払に変更可能です。</div>
							<div>・衛星契約放送受信料をNHKへ前払いされている方は、団体一括支払を申込まれた場合、前払い期間が終了した後に団体一括支払へ切り替えとなります。</div>
							<div>・口座振替等とは、口座振替・継続振込・クレジットカード継続払いのことをいいます。</div>
							<div>・iTSCOM各種サービスをご利用中で、NHK衛星放送を受信できる状態であれば、「団体一括支払」をお申し込みいただけます。（iTSCOMサービスエリア内でのご契約に限ります）</div>
							<div>・「地上契約」のみのお申し込みはいたしかねます。</div>
						</div>
						<div style = {{marginBottom: '50px'}}>
						</div>
						{/* <img src={nhkSelect} style={{width:'100%',verticalAlign:'bottom'}}/> */}
						<a href='https://www.nhk-cs.jp/jushinryo/compliant_1.html' target='_blank'>
							<b>日本放送協会放送受信規約</b>		
						</a>
						<br/>	
						<a href='https://elaws.e-gov.go.jp/document?lawid=325AC0000000132' target='_blank'>
							<b>放送法</b>	
						</a>
						<div className='title_center'>
							<MatCheckBox name='confirm' label='確認しました' color='primary' view={this.view}/>
						</div>
					</div>
					{/* 送付先確認ボタン */}
					{/* <div style={{textAlign:'center'}}>
						<MatCheckBox name='ConfirmCheck' label='確認しました' view={this.view}/>
					</div> */}
					<Divider />
					<div className='pageFooter footer-space'>
						<div>
							<MatButton name='next' view={this.view} className='orangeButton orangeButton_parts' >
								確認
								<NavigateNextIcon />
							</MatButton>
						</div>
						<div>
							<MatButton name='back' view={this.view} className='backButton' >
								<NavigateBeforeIcon />
								戻る
							</MatButton>
						</div>
					</div>

				</div>
			</div>
			</>
		);
	}
	/**
	 * リスト名=ErrorModel
	 */
		public onErrorModelRender(rowInfo: WprRowInfo): any {
			return (
				<div>
					<MatTypography name='errorMessage' view={this.view} row={rowInfo}/>
				</div>
			);
		}
	// --------------------------------------------------------------------------
}
