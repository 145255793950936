import React, { ReactNode } from 'react';
import { WprBaseViewComponent } from '../../../../wpr-framework/view/component/WprBaseViewComponent';
import { NameChangeErrorView } from './NameChangeErrorView';
import { Divider} from '@material-ui/core';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { MatButton } from '../../../../wpr-framework/component/controls/material/MatButton';

/**
 * 名義変更受付エラー用コンポーネント
 */
export class NameChangeError extends WprBaseViewComponent {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: any) { super(new NameChangeErrorView(), props); }
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** 名義変更受付エラービュー情報 */
	public get viewInfo(): NameChangeErrorView	{ return this.view as NameChangeErrorView;	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		return (
			<>
				<div className='newNameChange'>
					<div className='pageCard newNameChange-card'>
						<div className="pageHeader title">
							エラー
						</div>
						<Divider />
						<div className='pageContent'>
							<div className='planContent'>
								<div className='completeMessage'>
									<div dangerouslySetInnerHTML={{ __html: this.state.data.errorMessage}} />
								</div>
							</div>
						</div>
						<Divider />
						<div className='pageFooter'>
							<div>
								<MatButton name='toMypageTop' view={this.view} className='backButton' style={{ width: '250px' }} >
									<NavigateBeforeIcon />
									マイページトップへ戻る
								</MatButton>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
	// --------------------------------------------------------------------------
}