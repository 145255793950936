import { WprBaseViewModeInfo } from '../../../../../wpr-framework/mode/WprBaseViewMode';
import { RelocateSelect1View } from '../RelocateSelect1View';
import { WprDI_Object } from '../../../../../wpr-framework/view/di/WprDI_Object';
import { RelocateServiceListModel } from '../../../../models/mypage/relocate/RelocateServiceListModel';
import { WprDI_Control } from '../../../../../wpr-framework/view/di/WprDI_Control';
import { WprControlInfo } from '../../../../../wpr-framework/view/control/WprControlInfo';

/**
 * 移設確認モード
 */
export class RelocateComfirmMode extends WprBaseViewModeInfo<RelocateSelect1View> {
	// DI情報  ------------------------------------------------------------------
	@WprDI_Object
	private m_TvListModel: RelocateServiceListModel[]	= null;	// TVサービス一覧モデル
	@WprDI_Object
	private m_NetListModel: RelocateServiceListModel[]	= null;	// netサービス一覧モデル
	@WprDI_Object
	private m_PhoneListModel: RelocateServiceListModel[]	= null;	// phoneサービス一覧モデル
	@WprDI_Control
	private m_confirmFlg: WprControlInfo	= null;	// 同意フラグ
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	public constructor() {
		super('comfirmMode');
	}
	// --------------------------------------------------------------------------
	
	// override メソッド  --------------------------------------------------------
	/**
	 * モードリスト取得（この画面モードで使用する全てのモードを定義する）
	 * @param list モードリスト
	 */
	public useModeList(list: string[]): void {
		list.push('確認');
		list.push('未確認');
	}

	/**
	 * フィールド定義を登録処理
	 */
	public onRegisterControl(): void {
		this.addControl('next', { enabled: [ { mode: '確認', value: true }, { mode: 'other', value: false } ] });
	}

	/**
	 * 画面の状態をチェックしてモードを変更する
	 * @param curMode 現在のモード
	 * @return 新しいモード（変更ない場合は現在のモード）
	 */
	public onCheckMode(curMode: string): string {
		let cnt = 0;
		for (const model of this.m_TvListModel) {
			if (model.select)
				cnt++;
		}
		for (const model of this.m_NetListModel) {
			if (model.select)
				cnt++;
		}
		for (const model of this.m_PhoneListModel) {
			if (model.select)
				cnt++;
		}
		if (cnt > 0 && this.m_confirmFlg.value) 
			return '確認';
		else
			return '未確認';
	}
	// --------------------------------------------------------------------------
}
