import { Card, Divider, TableCell, Typography } from '@material-ui/core';
import { Grid } from '@mui/material';
import React, { ReactNode } from 'react';
import { WprBaseViewComponent } from '../../../../../wpr-framework/view/component/WprBaseViewComponent';
import { MenuBar } from '../../../common/menu_bar/MenuBar';
import { MvnoCapacityChangePreConfirmView } from './MvnoCapacityChangePreConfirmView';
import styles from './MvnoCapacityChangePreConfirm.scss';
import { WprRadio } from '../../../../../wpr-framework/component/controls/WprRadio';
import { MatRadio } from '../../../../../wpr-framework/component/controls/material/MatRadio';
import { CheckMarkRadio } from '../../../../controls/checkMarkRadio/CheckMarkRadio';
import { MvnoPlanSendData, CheckMarkRadioContent } from '../../../../controls/checkMarkRadio/CheckMarkRadioContent';
import { MatButton } from '../../../../../wpr-framework/component/controls/material/MatButton';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { MatOneRowTable } from '../../../../../wpr-framework/component/controls/material/MatOneRowTable';
import { MatTextField } from '../../../../../wpr-framework/component/controls/material/MatTextField';
import { MatCheckBox } from '../../../../../wpr-framework/component/controls/material/MatCheckBox';
import { WprAnchor } from '../../../../../wpr-framework/component/controls/WprAnchor';
import { ProgressBar } from '../../../common/progress_bar/ProgressBar';

/**
 * MVNO容量変更_事前確認事項用コンポーネント
 */
export class MvnoCapacityChangePreConfirm extends WprBaseViewComponent {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: any) { super(new MvnoCapacityChangePreConfirmView(), props); }
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** MVNO容量変更_事前確認事項ビュー情報 */
	public get viewInfo(): MvnoCapacityChangePreConfirmView { return this.view as MvnoCapacityChangePreConfirmView; }
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * スタイルオブジェクト取得
	*/
	public getStylesObject(): any { return styles; }

	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		return (
			<>
				<div>
					<div className='pageCard'>
						<div className="pageHeader stepFlow">
							<ProgressBar name='progressBar' view={this.view} />
						</div>
						<div className='pageContent'>
							<div className='link_blue'>
								<WprAnchor name='helpLink' view={this.view}>申込みについてご不明な方はこちら＞</WprAnchor>
							</div>

							<div className='pageTitle'>
								<div className='title_normal'>
									<Typography className='title_text'>事前確認事項</Typography>
									<div className='title_memo'>
										以下の内容をご確認いただき、✓をお願いいたします。
									</div>

								</div>
							</div>
							<div className='planContent'>
								<div style={{ textAlign: 'left' }}>
									<div className='title_lineLeft'>
										<Typography>プランの変更について</Typography>
									</div>
									<ul>
										<li>
											マイページでの受付分については毎月25日までのお申込み分は翌月より変更後のプラン適用、26日0時以降のお申込み分は翌々月より変更後のプラン適用となります。
										</li>
										<li>
											お電話では月末最終日の前日17時まで翌月より変更後のプラン適用を受付ておりますので、お急ぎの方はお電話にてお申込みください。
										</li>
										<li>
											プラン変更お申込み後のキャンセルや、お申込み内容の変更はできません。
										</li>
										<li>
											プラン変更お申込み後に再度プラン変更をお申込みいただく場合、最短で本お申し込み適用月の翌月からの変更適用となります。
											<br />
											本お申し込みが適用月を迎えましたら、改めてプラン変更のお申込みをおこなっていただくようお願いいたします。
										</li>
									</ul>
									<div className='title_lineLeft'>
										<Typography>ご注意事項</Typography>
									</div>
									<ul>
										<li>
											契約者本人もしくは契約者の了承のうえ申し込みをおこなってください。
										</li>
										<li>
											イッツコム対象サービス未加入の方、かけ放題コースをご契約の方は0GBへの変更はできません。
										</li>
										<li>
											データ容量ジャンプ特典適用中(5GB、8GB、15GB)のかたは、容量変更のお申込みはできません。
											<br />
											変更希望の場合は<WprAnchor name='helpLink' view={this.view} className='link_underline_blue'>こちら</WprAnchor>よりお問い合わせください。
										</li>
									</ul>
								</div>
								<div style={{ textAlign: 'center' }}>
									<MatCheckBox name='c_confirm' view={this.view} label='上記内容に同意してお申込み' />
								</div>
							</div>
						</div>
						<Divider />

						<div className='pageFooter'>
							<div>
								<MatButton name='next' view={this.view} className='nextButton' >
									この内容で申込む
									<NavigateNextIcon />
								</MatButton>

							</div>
							<div>
								<MatButton name='back' view={this.view} className='backButton' >
									<NavigateBeforeIcon />
									内容を修正する
								</MatButton>

							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
	// --------------------------------------------------------------------------
}
