/**
 * 基本情報リスト
 */
export class BaseInfoListModel {
	// public 変数	-----------------------------------------------------------
	public itemName: string;		// 項目名
	public itemData: string;		// 項目データ
	public terminalType: string;	// 機器端末
	public terminalForm: string;	// 端末形態
	public serviceCd: string;		// サービスCD
	// ------------------------------------------------------------------------
}
