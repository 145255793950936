import { WprBaseViewInfo } from '../WprBaseViewInfo';

/**
 * ビュー名称情報
 */
export class WprTabViewNameInfo {
	// private 変数  ------------------------------------------------------------
	private m_ViewName: string				= null;		// ビュー名
	private m_ViewName2: string				= null;		// ビュー名 + 'View'
	private m_Name: string					= null;		// 名称
	private m_ViewInfo: WprBaseViewInfo		= null;		// ビュー情報
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	public constructor(vname: string, name: string) {
		this.m_ViewName = vname;
		this.m_ViewName2 = vname + 'View';
		this.m_Name = name;
	}
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** マウントフラグ */
	public get viewInfo(): WprBaseViewInfo 	{ return this.m_ViewInfo; 	}	public set viewInfo(view: WprBaseViewInfo) { this.m_ViewInfo = view; }
	// --------------------------------------------------------------------------

	// public メソッド  ---------------------------------------------------------
	/**
	 * 名前比較処理
	 * @param vname ビュー名
	 * @param name 名称
	 * @returns trueの場合、一致
	 */
	public compareName(vname: string, name: string): boolean {
		if (this.m_ViewName === vname || this.m_ViewName2 === vname) {
			if (this.m_Name === name)
				return true;
		}
		return false;
	}
	// --------------------------------------------------------------------------
}