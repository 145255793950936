/**
 * ItscomHomeオプションモデル
 */
export class callForwardingModel {
	// public 変数	-----------------------------------------------------------
	public customerId: string;			// 顧客ID
	public contractId: string;			// 契約番号
	public contractStatus: string;		// 契約形態
	// 通常リスト
	public itemName: string;			// 項目名
	public itemValue: string;			// 項目値
	public deviceType: string;			// 機器の種類
	public deviceForm: string;			// 機器の形態
	// オプションリスト
	public check: boolean;				// 選択
	public optionName: string;			// オプション名
	public optionFee: string;			// 月額料金
	public payUnit: string;				// 料金単位
	public optionCd: string;			// オプションサービスCD
	// 確認
	public confirm: boolean;			// 確認チェック
	public identificationFlg: boolean;	// 本人確認フラグ？
	// ------------------------------------------------------------------------
}
