import { Contract } from "../../../../models/bss/contract/Contract";
import { RadiusAccount } from "../../../../models/bss/contract/RadiusAccount";

/**
 * ResetContractRadiusAccountリクエスト
 */
export class ResetContractRadiusAccountRequest {
	// public 変数	-----------------------------------------------------------
	public contractID: string;
	public radiusAccount: string;
	// ------------------------------------------------------------------------
}