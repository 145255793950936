import { WprBaseViewMultiModeInfo } from '../../../../../../wpr-framework/mode/WprBaseViewMultiMode';
import { WprMultiMode } from '../../../../../../wpr-framework/mode/WprMultiMode';
import { WprDI_Control } from '../../../../../../wpr-framework/view/di/WprDI_Control';
import { WprControlInfo } from '../../../../../../wpr-framework/view/control/WprControlInfo';
import { ModTvSelect2View } from '../ModTvSelect2View';
import { WprDI_Object } from '../../../../../../wpr-framework/view/di/WprDI_Object';
import { TvBaseModel } from '../../../../../models/mypage/tv/TvBaseModel';
import { MypageWizardMngr } from '../../../../../view/wizard/models/MypageWizardMngr';

/**
 * テレビチェックボックスモード
 */
export class TvCheckBoxMode extends WprBaseViewMultiModeInfo<ModTvSelect2View> {
	// DI情報  ------------------------------------------------------------------
	@WprDI_Control
	private m_rental: WprControlInfo	= null;	// レンタル
	@WprDI_Control
	private m_otokuApply: WprControlInfo	= null;	// お得パック申込む
	@WprDI_Control
	private m_otokuUnapply: WprControlInfo	= null;	// お得パック申込まない
	@WprDI_Control
	private m_noRental: WprControlInfo	= null;	// レンタルなし
	@WprDI_Object
	private m_AfterTvBaseModel: TvBaseModel	= null;	// テレビ基本情報モデル
	@WprDI_Object
	private m_BeforeTvBaseModel: TvBaseModel	= null;	// テレビ基本情報モデル
	@WprDI_Object
	private m_MypageWizardMngr: MypageWizardMngr	= null;	// 画面ウィザード管理クラス
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	public constructor() {
		super('TvCheckBoxMode');
	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * モードリスト取得（この画面モードで使用する全てのモードを定義する）
	 * @param list モードリスト
	 */
	public useModeList(list: string[]): void {
		list.push('非表示(rental)');
		list.push('表示(rental)');
		list.push('切替可(rental)');
		list.push('切替不可(rental)');
		list.push('切替可(noRental)');
		list.push('切替不可(noRental)');
		list.push('切替可(otokuApply)');
		list.push('切替不可(otokuApply)');
		list.push('切替可(otokuUnapply)');
		list.push('切替不可(otokuUnapply)');
		list.push('入力済み');
		list.push('未入力');
		list.push('入力不要');
	}

	/**
	 * フィールド定義を登録処理
	 */
	public onRegisterControl(): void {
		this.addControl('rental', {
			check: true,
			display: [ { mode: '表示(rental)', value: true }, { mode: '非表示(rental)', value: false } ],
			readOnly: [ { mode: '切替不可(rental)', value: true }, { mode: '切替可(rental)', value: false } ]
		});
		this.addControl('noRental', { check: true, readOnly: [ { mode: '切替不可(noRental)', value: true }, { mode: '切替可(noRental)', value: false } ] });
		this.addControl('otokuApply', { check: true, readOnly: [ { mode: '切替不可(otokuApply)', value: true }, { mode: '切替可(otokuApply)', value: false } ] });
		this.addControl('otokuUnapply', { check: true, readOnly: [ { mode: '切替不可(otokuUnapply)', value: true }, { mode: '切替可(otokuUnapply)', value: false } ] });
		this.addControl('next', { enabled: [ { mode: '入力済み|入力不要', value: true }, { mode: 'other', value: false } ] });
	}

	/**
	 * 画面の状態をチェックしてモードを変更する
	 * @param curMode 現在のモード
	 * @param newMode 新しいモード
	 */
	public onCheckMultiMode(curMode: WprMultiMode, newMode: WprMultiMode): void {
		const customer = this.m_MypageWizardMngr.getViewInfo().customer;
		//FTTHの場合
		if (customer.building[0].buildingLineTypeCD === '2') {
			//変更なしの場合、レンタル非表示
			if (this.m_AfterTvBaseModel.terminalDisp === '変更なし') {
				newMode.addMode('非表示(rental)');
				newMode.addMode('切替不可(rental)');
				newMode.addMode('切替不可(noRental)');

			}
			//変更有の場合、変更なし非活性
			else {
				newMode.addMode('表示(rental)');
				newMode.addMode('切替不可(rental)');
				newMode.addMode('切替不可(noRental)');
			}
		}
		else {
			newMode.addMode('表示(rental)');
			if (this.m_AfterTvBaseModel.terminalDisp !== '変更なし') {
				newMode.addMode('切替不可(rental)');
				newMode.addMode('切替不可(noRental)');
			}
			else {
				if (this.m_rental.value && !this.m_noRental.value) {
					newMode.addMode('切替不可(rental)');
					newMode.addMode('切替可(noRental)');
				}
				if (!this.m_rental.value && this.m_noRental.value) {
					newMode.addMode('切替不可(noRental)');
					newMode.addMode('切替可(rental)');
				}
			}
		}
		if (this.m_otokuApply.value && !this.m_otokuUnapply.value) {
			newMode.addMode('切替不可(otokuApply)');
			newMode.addMode('切替可(otokuUnapply)');
		}
		if (!this.m_otokuApply.value && this.m_otokuUnapply.value) {
			newMode.addMode('切替不可(otokuUnapply)');
			newMode.addMode('切替可(otokuApply)');
		}
		if (this.getViewMode('希望日時') !== '表示')
			newMode.addMode('入力不要');
		else {
			let flg = true;
			if (this.m_AfterTvBaseModel.securityFlg && !this.m_AfterTvBaseModel.securityComment)
				flg = false;
			if (flg && this.m_AfterTvBaseModel.desiredDate1 && 
				this.m_AfterTvBaseModel.desiredDate2 && 
				this.m_AfterTvBaseModel.desiredDate3 && 
				this.m_AfterTvBaseModel.selectTime1 && 
				this.m_AfterTvBaseModel.selectTime2 && 
				this.m_AfterTvBaseModel.selectTime3 &&
				this.m_AfterTvBaseModel.beforeContact &&
				this.m_AfterTvBaseModel.sameDayContact &&
				this.m_AfterTvBaseModel.presentName
				)
				newMode.addMode('入力済み');
			else 
				newMode.addMode('未入力');
		}
	}
	// --------------------------------------------------------------------------
}
