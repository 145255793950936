import React, { ReactNode } from 'react';
import { WprBaseViewComponent } from '../../../../wpr-framework/view/component/WprBaseViewComponent';
import { FooterView } from './FooterView';
import itscom_icon from "../../../data/itscom_icon.png";
import { Typography } from '@material-ui/core';
import styles from './Footer.scss';
import { WprContainer } from '../../../../wpr-framework/component/controls/WprContainer';
import { WprAnchor } from '../../../../wpr-framework/component/controls/WprAnchor';

/**
 * ヘッダー用コンポーネント
 */
export class Footer extends WprBaseViewComponent {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: any) { super(new FooterView(), props); }
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** ヘッダービュー情報 */
	public get viewInfo(): FooterView { return this.view as FooterView; }
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * スタイルオブジェクト取得
	*/
	public getStylesObject(): any { return styles; }

	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		return (
			<>
				<WprContainer mode='画面サイズ.大' view={this.view}>
					<div className='footer' >
						<div className='footerText privacyLink'>
							<WprAnchor name='privacyLink' view={this.view}>アクセス情報の取り扱い</WprAnchor>
						</div>
						<Typography className='footerText'>© its communications Inc. All rights reserved.</Typography>
					</div>
				</WprContainer>
				<WprContainer mode='画面サイズ.小' view={this.view}>
					<div className='footerSmall' >
						<div>
							<WprAnchor name='toServicePage' className='linkText' view={this.view} >
								iTSCOM サービスページ＞
							</WprAnchor>
							<WprAnchor name='toCompanyPage' className='linkText' view={this.view} >
								iTSCOM 会社概要＞
							</WprAnchor>
						</div>
						<div className='footerBottom'>
							<div className='footerText privacyLink'>
								<WprAnchor name='privacyLink' view={this.view}>アクセス情報の取り扱い</WprAnchor>
							</div>
							<Typography className='footerText'>© its communications Inc. All rights reserved.</Typography>
						</div>
					</div>
				</WprContainer>
			</>
		);
	}
	// --------------------------------------------------------------------------
}
