import { BaseService } from './base/BaseService';
import { WprBaseViewCore } from '../../wpr-framework/view/WprBaseViewCore';
import { GetUsageDetailsMonthRequest } from './models/dto/usagedetails/GetUsageDetailsMonthRequest';
import { GetUsageDetailsMonthResponse } from './models/dto/usagedetails/GetUsageDetailsMonthResponse';
import { GetUsageDetailsRequest } from './models/dto/usagedetails/GetUsageDetailsRequest';
import { GetUsageDetailsResponse } from './models/dto/usagedetails/GetUsageDetailsResponse';
import { GetUsagePDFRequest } from './models/dto/usagedetails/GetUsagePDFRequest';
import { GetUsagePDFResponse } from './models/dto/usagedetails/GetUsagePDFResponse';
import { GetUsageDetailsYearRequest } from './models/dto/usagedetails/GetUsageDetailsYearRequest';
import { GetUsageDetailsYearResponse } from './models/dto/usagedetails/GetUsageDetailsYearResponse';
import { GetTokyuDetailsRequest } from './models/dto/usagedetails/GetTokyuDetailsRequest';
import { GetTokyuDetailsResponse } from './models/dto/usagedetails/GetTokyuDetailsResponse';

/**
 * 利用明細サービス
 */
export class UsageDetailsService extends BaseService {
	// サービス メソッド  --------------------------------------------------------
	/**
	 * 対象年月取得
	 * @param viewCore ビュー情報
	 * @param request リクエスト情報
	 * @param resMethod 結果処理メソッド
	 */
	public getMonth(viewCore: WprBaseViewCore, request: GetUsageDetailsMonthRequest, resMethod: (result: GetUsageDetailsMonthResponse) => void): void {
		return this.doPost(viewCore, '/usage/getMonth', request, resMethod);
	}

	/**
	 * 対象月の利用明細を取得
	 * @param viewCore ビュー情報
	 * @param request リクエスト情報
	 * @param resMethod 結果処理メソッド
	 */
	public getDetailMonth(viewCore: WprBaseViewCore, request: GetUsageDetailsRequest, resMethod: (result: GetUsageDetailsResponse) => void): void {
		return this.doPost(viewCore, '/usage/getDetailMonth', request, resMethod);
	}

	/**
	 * PDFデータを取得
	 * @param viewCore ビュー情報
	 * @param request リクエスト情報
	 * @param resMethod 結果処理メソッド
	 */
	public getPDFData(viewCore: WprBaseViewCore, request: GetUsagePDFRequest, resMethod: (result: GetUsagePDFResponse) => void): void {
		return this.doPost(viewCore, '/usage/getPDFData', request, resMethod);
	}

	/**
	 * 年間の明細の取得
	 * @param viewCore ビュー情報
	 * @param request リクエスト情報
	 * @param resMethod 結果処理メソッド
	 */
	public getDetailYear(viewCore: WprBaseViewCore, request: GetUsageDetailsYearRequest, resMethod: (result: GetUsageDetailsYearResponse) => void): void {
		return this.doPost(viewCore, '/usage/getDetailYear', request, resMethod);
	}

	/**
	 * 東急のグラフに表示する明細の取得
	 * @param viewCore ビュー情報
	 * @param request リクエスト情報
	 * @param resMethod 結果処理メソッド
	 */
	public getTokyuGraphDetail(viewCore: WprBaseViewCore, request: GetTokyuDetailsRequest, resMethod: (result: GetTokyuDetailsResponse) => void): void {
		return this.doPost(viewCore, '/usage/getTokyuGraphDetail', request, resMethod);
	}
	
	// --------------------------------------------------------------------------
}
