
/**
 * 進捗バー用モデル
 */
export class ProgressBarModel {
	// public 変数	-----------------------------------------------------------
	public stepNum: string;			// ステップ番号
	public  title: string;			// タイトル
	public status: 'current' | 'next' | 'complete' | 'not';	// 
	public stopModel: ProgressBarStopModel[];		// 番号の間に区切り線がある場合
	// ------------------------------------------------------------------------

	constructor (stepNum: string, title: string, status: 'current' | 'next' | 'complete' | 'not', stopModel?: ProgressBarStopModel[]) {
		this.stepNum = stepNum;
		this.title = title;
		this.status = status;
		this.stopModel = stopModel;
	}
}

export class ProgressBarStopModel {
	public status: 'current' | 'next' | 'complete' | 'not';		// 現在
	
	constructor (status: 'current' | 'next' | 'complete' | 'not') {
		this.status = status;
	}
}
