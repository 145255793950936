import React, { ReactNode } from 'react';
import { WprBaseViewComponent } from '../../../../wpr-framework/view/component/WprBaseViewComponent';
import { Typography, Grid, Divider } from '@material-ui/core';
import { MatButton } from '../../../../wpr-framework/component/controls/material/MatButton';
import { ProgressBar } from '../progress_bar/ProgressBar';
import questionnaireIcon from '/shared/image/common/button_questionnaire_on.png';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { WprAnchor } from '../../../../wpr-framework/component/controls/WprAnchor';
import { MatTypography } from '../../../../wpr-framework/component/controls/material/MatTypography';
import { CommonCompleteView } from './CommonCompleteView';
import { WprContainer } from '../../../../wpr-framework/component/controls/WprContainer';
import { WprSpan } from '../../../../wpr-framework/component/controls/WprSpan';

/**
 * 完了画面用コンポーネント
 */
export class CommonComplete extends WprBaseViewComponent {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: any) { super(new CommonCompleteView(), props); }
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** 動画配信,見放題パック申込完了画面ビュー情報 */
	public get viewInfo(): CommonCompleteView	{ return this.view as CommonCompleteView;	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		return (
			<>
			<div>
				<div className='pageCard'>
					<div className='main'>
						<div className='title_center'>
							<MatTypography className='title_complete' name={'title_complete'} view={this.view}/>
						</div>
						<div className='titleComplete_normal'>
							<MatTypography name={'user'} view={this.view} className='title_border'/>
						</div>
						<div className='titleComplete_normal'>
							{/* <MatTypography name={'msg1'} view={this.view} className='title_msg'/> */}
							<WprSpan name={'msg1'} view={this.view} className='title_msg'></WprSpan>
						</div>
						<div className='titleComplete_normal'>
							<WprSpan name={'msg3'} view={this.view} className='title_msg'></WprSpan>
						</div>
						<div className='titleComplete_normal'>
							<MatTypography name={'bikou1'} view={this.view} className='title_memo'/>
						</div>
						<div className='titleComplete_normal'>
							<MatTypography name={'msg2'} view={this.view} className='title_msg'/>
						</div>
						<div className='titleComplete_normal'>
							<MatTypography name={'msg4'} view={this.view} className='title_msg'/>
						</div>
						<div className='titleComplete_normal'>
							<MatTypography name={'bikou2'} view={this.view} className='title_memo'/>
						</div>
						<div className='questionnaire'>
							<WprAnchor name='toQuestionnaire' view={this.view}  >
								<img src={questionnaireIcon} style={{maxWidth: '100%'}} />
							</WprAnchor>
						</div>
					</div>
					<Divider />
					<div className='pageFooter footer-space'>
						<WprContainer mode={'遷移先.トップ'} view={this.view}>
							<div>
								<MatButton name='back' view={this.view} className='completeButton backContInfoList' >
									<NavigateBeforeIcon />
									<span className='screen'>マイページトップへ戻る</span>
								</MatButton>
							</div>
						</WprContainer>
						<WprContainer mode={'遷移先.一覧'} view={this.view}>
							<div>
								<MatButton name='back' view={this.view} className='completeButton backContInfoList' >
									<NavigateBeforeIcon />
									<span className='screen'>契約内容の確認・変更メニューへ</span>
								</MatButton>
							</div>
						</WprContainer>
					</div>

				</div>
			</div>
			</>
		);
	}
	// --------------------------------------------------------------------------
}
