import { WprValueMap } from '../../../../wpr-framework/model/WprValueMap';
import { WprValueMapSetter } from '../../../../wpr-framework/model/WprValueMapSetter';

/**
 * でんきご契約種別
 */
export class ElectoricContractTypeValueMap {
	// static メソッド  --------------------------------------------------------
	public static addValueMap(vmSetter: WprValueMapSetter): void {
		let vm = new WprValueMap();
		vm.addValue('従量電灯B', '従量電灯B');
		vm.addValue('従量電灯C', '従量電灯C');
		vm.addValue('低圧電力', '低圧電力');
		vmSetter.addValueMap('electoricContractType', vm);
	}
	// --------------------------------------------------------------------------
}
