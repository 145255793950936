import { WprModel } from '../../../../wpr-framework/view/deco/WprModel';
import { WprDI_ScopeModel } from '../../../../wpr-framework/view/di/WprDI_ScopeModel';
import { ObjectUtil } from '../../../common/util/ObjectUtil';
import { MagazineModel } from '../../../service/models/apimodels/MagazineModel';
import { ViewInfoModel } from '../../../service/models/apimodels/ViewInfoModel';
import { MypageBaseView } from '../../base/MypageBaseView';
import { ProgressBarData } from '../../common/progress_bar/ProgressBarView';
import { MypageWizardMngr } from '../../wizard/models/MypageWizardMngr';

/**
 * マガジン申込画面
 */
export class MagazineConfirmView extends MypageBaseView {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() { super('MagazineConfirmView'); }
	// --------------------------------------------------------------------------

	@WprDI_ScopeModel('MypageWizardMngr')
	private m_MypageWizardMngr: MypageWizardMngr = null;	// 画面ウィザード用モデル
	@WprModel('ServiceModel')
	private m_ServiceModel: string = null;	// 月額料金
	private m_MagazineModel: MagazineModel = null;	// Itscomマガジンモデル
	// override メソッド  --------------------------------------------------------
	/**
	 * コントロール定義設定
	 */
	public onRegisterConfig(): void {
		this.addConfig('amount', '金額', 'ServiceModel', { bindModel: true, converter: '3桁カンマ' });
	}

	/**
	 * アクション登録
	 */
	public onRegisterAction(): void {
		this.addAction('next', this.onNext);
		this.addAction('back', this.onBack);
	}

	/**
	 * ビュー表示通知
	 */
	public onShowView(): void {
		this.m_MypageWizardMngr.initView(this, (result: ViewInfoModel) => {
			this.registAccessLog();
			this.setStorageItem('b_url', window.location.href);
			const sendData: ProgressBarData = new ProgressBarData();
			sendData.progressBarList = this.m_MypageWizardMngr.getProgressModelList(this);
			this.sendChildData('fP_ev_progressBarIntital', sendData);
			this.m_MagazineModel = this.m_MypageWizardMngr.getCache().m_MagazineInfo
			const contractId = this.m_MypageWizardMngr.getParams().contractId;
			this.m_MagazineModel.maxFlg = this.m_MypageWizardMngr.getParams().maxFlg;
			// 契約番号のの取得
			if (ObjectUtil.isNotNullOrEmptyStr(contractId)) {
				this.m_MagazineModel.contract = this.m_MypageWizardMngr.getContract(this, contractId);
			}
			// マックス判定
			if (this.m_MagazineModel.maxFlg === '1') {
				this.m_ServiceModel = '月額無料';
			} else {
				const amount = this.m_MypageWizardMngr.getMCommonList(this, 'charge_mag_info', 'price', null, null);
				this.m_MagazineModel.amount = this.m_MypageWizardMngr.getTaxFee(this, amount[0].commonValue)
				this.m_ServiceModel = '月額' + this.m_MagazineModel.amount + '円（税込）'
			}
			this.refreshView();
		});
	}

	// --------------------------------------------------------------------------

	// アクション  ---------------------------------------------------------------
	/**
	 * 申込む
	 */
	public onNext(param: any): void {
		this.m_MypageWizardMngr.goNext(this);
	}

	/**
	 * 戻る
	 */
	public onBack(param: any): void {
		this.m_MypageWizardMngr.goBack(this);
	}

	// --------------------------------------------------------------------------
}
