/**
 * 日付ユーティリティ
 */
export class DateUtil {
	// public static メソッド  ---------------------------------------------------
	// *********************** Date取得 ***********************
	public static getMonthFromDate(date: Date): number {
		return date.getMonth() + 1;
	}
	public static getDateFromDate(date: Date): number {
		return date.getDate();
	}
	public static getHoursFromDate(date: Date): number {
		return date.getHours();
	}
	public static getMinutesFromDate(date: Date): number {
		return date.getMinutes();
	}

	public static getToday(): Date {
		return new Date();
	}
	public static getTodayStr(): string {
		return this.dateLengthEight(this.getToday());
	}

	/**
	 * 指定した日付から月初の日付を返す
	 * @param date yyyyMMdd
	 * @returns 
	 */
	public static getMonthStart(date: Date): string {
		date.setDate(1);
		return this.dateLengthEight(date);
	}

	/**
	 * 指定した日付から翌月の日付を返
	 * @param date yyyyMMdd
	 * @returns 
	 */
	public static getMonthEnd(date: Date): string {
		// 1ヶ月加えて翌月にします.
		date.setMonth(date.getMonth() + 1);
		// 日付に0を設定し、該当月の0日（つまり、前月末）にします.
		date.setDate(0);
		return this.dateLengthEight(date);
	}

	/**
	 * 指定した日付から指定した日数足した日付を返す
	 * @param date yyyyMMdd
	 * @returns 
	 */
	public static getCulcDate(date: Date , num: number): Date {
		date.setDate(date.getDate() + num);
		return date;
	}

	/**
	 * 文字列日付から指定した数を足した日付（文字列）を返す
	 * @param str yyyyMMdd
	 * @returns yyyyMMdd
	 */
	public static getPlusDate(date: string, num: number): string {
		const sdate: string = this.convertToDateWithSlash(date);
		//文字列を日付型にする
		const ddate: Date = new Date(sdate);
		//日付操作
		ddate.setDate(ddate.getDate() + num);
		//日付を文字列にする
		return this.dateLengthEight(ddate);
	}

	/**
	 * 文字列日付から指定した数を引いた日付（文字列）を返す
	 * @param str yyyyMMdd
	 * @returns yyyyMMdd
	 */
	public static getMinusDate(date: string, num: number): string {
		const sdate: string = this.convertToDateWithSlash(date);
		//文字列を日付型にする
		const ddate: Date = new Date(sdate);
		//日付操作
		ddate.setDate(ddate.getDate() - num);
		//日付を文字列にする
		return this.dateLengthEight(ddate);
	}

	/**
	 * 指定した日付から6カ月後を返す
	 * @param date yyyyMMdd
	 * @returns 
	 */
	public static getHalfYear(date: Date): string{
		// 6ヶ月加えて翌月にします.
		date.setMonth(date.getMonth() + 6);
		date.setDate(date.getDate());
		return this.dateLengthEight(date);
	}

	/**
	 * 日付から指定した前の日付を返す
	 * @param date yyyyMMdd
	 * @returns 
	 */
	public static getBefDay(date: Date, month:number ): Date{
		// 6ヶ月加えて翌月にします.
		date.setMonth(date.getMonth() - month);
		date.setDate(date.getDate());
		return date;
	}
	// *********************** フォーマット/変換 ***********************	
	/**
	 * スラッシュ区切りの日付に変換
	 * @param date yyyyMMdd (string)
	 * @returns yyyy/MM/dd (string)
	 */
	public static convertToDateWithSlash(date: string): string {
		const year = date.slice(0, 4);
		const month = date.slice(4, 6);
		const day = date.slice(6, 8);
		return year + '/' + month + '/' + day;
	}

	/**
	 * ハイフン区切りの日付に変換
	 * @param date yyyyMMdd (string)
	 * @returns yyyy-MM-dd (string)
	 */
	public static convertToDateWithHyphen(date: string): string {
		const year = date.slice(0, 4);
		const month = date.slice(4, 6);
		const day = date.slice(6, 8);
		return year + '-' + month + '-' + day;
	}

	/**
	 * DB格納用の文字列に変換
	 * @param date 
	 * @returns 
	 */
	public static dateLengthEightFromStr(date: string, splitVal: '/' | '-'): string {
		const year = date.split(splitVal)[0];
		const month = date.split(splitVal)[1];
		const day = date.split(splitVal)[2];
		return year + month + day;
	}
	/**
	 * DB格納用の文字列に変換
	 * @param date 
	 * @returns 
	 */
	public static dateLengthEight(date: Date): string {
		const year = date.getFullYear().toString();
		let month = (date.getMonth() + 1).toString();
		if (month.length != 2)
			month = '0' + month;
		let day = date.getDate().toString();
		if (day.length != 2)
			day = '0' + day;
		return year + month + day;
	}

	public static convertToDateFromStr(dateStr: string): Date {
		return new Date(this.convertToDateWithSlash(dateStr));
	}
	// *********************** 差分取得 ***********************
	/**
	 * 年数取得（今日時点での）
	 * @param date 日付 ： ex.)誕生日等
	 * @returns 
	 */
	public static getAgeFromStr(dateStr: string): number {
		return this.getAge(this.convertToDateFromStr(dateStr));
	}
	public static getAge(date: Date): number {
		return this.getDiffYears(date, new Date());
	}

	/**
	 * 差分年数取得
	 * @param before 	日付（前）：ex.)2000-05-01
	 * @param after 	日付（後）：ex.)2021-01-01
	 * @returns 		差分年数 ：ex.)20
	 */
	public static getDiffYears(before: Date, after: Date): number {
		// 差分年数：ex.)2021 - 2020 = 21
		let diffYears: number = Number(after.getFullYear()) - Number(before.getFullYear());
		// 日付（前）を、日付（後）の年に置き換えた日付：ex.)2021-05-01
		const beforeDateOnAfterYear: Date = new Date(after.getFullYear(), before.getMonth(), before.getDate());
		if (after < beforeDateOnAfterYear) {
			// 日付（後）の日にちに達していなかった場合、-1
			diffYears--;
		}
		return diffYears;
	}

	// *********************** 判定/チェック ***********************
	/**
	 * 日付チェック
	 * @param year 
	 * @param month 
	 * @param day 
	 * @returns 
	 */
	public static checkDate(year: any, month: any, day: any): any {
		if (!year || !month || !day) return false;
		if (!String(year).match(/^[0-9]{4}$/) || !String(month).match(/^[0-9]{1,2}$/) || !String(day).match(/^[0-9]{1,2}$/)) return false;

		let dateObj = new Date(year, month - 1, day),
			dateObjStr = dateObj.getFullYear() + '' + (dateObj.getMonth() + 1) + '' + dateObj.getDate(),
			checkDateStr = year + '' + month + '' + day;

		if (dateObjStr === checkDateStr) return true;
		return false;
	}

	/**
	 * 日付2が日付1より後か
	 * @param date1 日付1
	 * @param date2 日付2
	 * @returns date1 < date2 の場合 true
	 */
	public static isAfter(date1: Date, date2: Date): boolean {
		return date1.getTime() < date2.getTime();
	}

	// --------------------------------------------------------------------------
}