import React, { ReactNode } from 'react';
import { WprBaseViewComponent } from '../../../../wpr-framework/view/component/WprBaseViewComponent';
import { ConstrListView } from './ConstrListView';
import { Typography, Divider, TableCell } from '@material-ui/core';
import { MatButton } from '../../../../wpr-framework/component/controls/material/MatButton';
import { MatTypography } from '../../../../wpr-framework/component/controls/material/MatTypography';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import styles from './ConstrList.scss';
import { WprRowInfo } from '../../../../wpr-framework/component/props/WprRowInfo';
import { MatSelect } from '../../../../wpr-framework/component/controls/material/MatSelect';
import orangeIcon from '/public/next_icon_orange.png';
import { WprContainer } from '../../../../wpr-framework/component/controls/WprContainer';

/**
 * 工事予定日一覧用コンポーネント
 */
export class ConstrList extends WprBaseViewComponent {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: any) { super(new ConstrListView(), props); }
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** 工事予定日一覧ビュー情報 */
	public get viewInfo(): ConstrListView	{ return this.view as ConstrListView;	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * スタイルオブジェクト取得
	*/
	public getStylesObject(): any { return styles; }

	/**
	 * リストコントロール定義登録
	*/
	protected onRegisterListRender(): void {
		this.addListRender('ConstrAddListModel', null, this.onConstrAddListRender);
		this.addListRender('ConstrEditListModel', null, this.onConstrEditListRender);
		this.addListRender('ConstrDecisionListModel', null, this.onConstrDecisionListRender);
	}

	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		return (
			<>
				<div className='pageCard'>
					<div className='main' style={{minHeight: '500px'}}>
						<div className='pageTitle'>
							<div className='title_left'>
								<MatTypography name='title' className='title_text' view={this.view}/>
							</div>
						</div>
						<div style={{textAlign:'right'}}>
							<MatButton variant='text' name='log' view={this.view} style={{fontWeight: '600'}} >
								過去工事履歴
								<img src={orangeIcon} style={{width:'20px',marginLeft:'5px'}}/>
							</MatButton>
						</div>
						{this.renderList('ConstrAddListModel')}
						{this.renderList('ConstrEditListModel')}
						{this.renderList('ConstrDecisionListModel')}
						<WprContainer mode={'表示内容.なし'} view={this.view}>
							<div className='viewGroup'>
								<Typography style={{padding: '15px 0'}}>直近の工事予定はありません。</Typography>
							</div>
						</WprContainer>
						<div style={{marginBottom: '100px'}}></div>
					</div>
				</div>
			</>
		);
	}
	// --------------------------------------------------------------------------

	// リスト描画  ---------------------------------------------------------------
	/**
	 * リスト名=ConstrAddList
	 */
	public onConstrAddListRender(rowInfo: WprRowInfo): any {
		return (
			<div className='viewGroup'>
				<Typography className='subTitle'>予定日未設定申込</Typography>
				<div className='dateList'>
					<div>
						<MatTypography name={'constrTypeA'} row={rowInfo} view={this.view} style={{fontWeight: '500'}}></MatTypography>
					</div>
				</div>
				<div className='buttonArea'>
						<div>
							<MatButton name='nextNew' view={this.view} row={rowInfo} className='orangeButton orangeButton_parts' >
								予定日未設定の方はこちら
								<NavigateNextIcon />
							</MatButton>
						</div>
				</div>
			</div>
		);
	}
	/**
	 * リスト名=ConstrEditList
	 */
	public onConstrEditListRender(rowInfo: WprRowInfo): any {
		const url = this.state.data.sentenceData;
		return (
			<div className='viewGroup'>
				<Typography className='subTitle'>工事予定日一覧</Typography>
				<div className='dateList'>
					<div>
						<MatTypography name={'constrTypeE'} row={rowInfo} view={this.view} style={{fontWeight: '500'}}></MatTypography>
					</div>
					<div className='flex'>
						<MatTypography name={'dateE'} row={rowInfo} view={this.view} ></MatTypography>
						<span style={{fontWeight: '500'}}>（</span>
						<MatTypography name={'dayOfWeekE'} row={rowInfo} view={this.view} style={{fontWeight: '500'}}></MatTypography>
						<span style={{fontWeight: '500'}}>）　</span>
						<MatTypography name={'timeE'} row={rowInfo} view={this.view} ></MatTypography>
						<Typography style={{fontWeight: '500'}}>の間到着</Typography>
					</div>
				</div>
				<ul className='ul_kome attention_fontSize attention'>
					<li>
						詳細の到着時間は前日にご連絡させていただきます。
					</li>
					<div className='subAttention'>
					予定日の変更やキャンセルは、工事予定日の7日前までマイページにて承ります。<br/>
					7日前を過ぎた場合は<a href={url} target = '_blank'>こちら</a>よりご連絡ください。
					</div>
					でんきサービスとガスサービスの再点日・開栓日の変更/キャンセル/確認は<a href={url} target = '_blank'>こちら</a>へお問い合わせください。
				</ul>
				<div className='buttonArea'>
					<div>
						<MatButton name='nextChange' view={this.view} row={rowInfo} className='orangeButton orangeButton_parts' >
							予定日変更希望の方はこちら
							<NavigateNextIcon />
						</MatButton>
					</div>
					<div>
						<MatButton name='cancel' view={this.view} row={rowInfo} className='backButton' >
							<NavigateBeforeIcon />
							お申込みキャンセル
						</MatButton>

					</div>
				</div>
			</div>
		);
	}
	/**
	 * リスト名=ConstrDecisionList
	 */
	public onConstrDecisionListRender(rowInfo: WprRowInfo): any {
		const url = this.state.data.sentenceData;
		return (
			<div className='viewGroup'>
				<Typography className='subTitle'>工事予定日一覧</Typography>
				<div className='dateList'>
					<div>
						<MatTypography name={'constrTypeD'} row={rowInfo} view={this.view} style={{fontWeight: '500'}}></MatTypography>
					</div>
					<div className='flex'>
						<MatTypography name={'dateD'} row={rowInfo} view={this.view} ></MatTypography>
						<span style={{fontWeight: '500'}}>（</span>
						<MatTypography name={'dayOfWeekD'} row={rowInfo} view={this.view} style={{fontWeight: '500'}}></MatTypography>
						<span style={{fontWeight: '500'}}>）　</span>
						<MatTypography name={'timeD'} row={rowInfo} view={this.view} ></MatTypography>
						<Typography style={{fontWeight: '500'}}>の間到着</Typography>
					</div>
				</div>
				<ul className='ul_kome attention_fontSize attention'>
					<li>
						詳細の到着時間は前日にご連絡させていただきます。
					</li>
					<div className='subAttention'>
					予定日の変更やキャンセルは、工事予定日の7日前までマイページにて承ります。<br/>
						7日前を過ぎた場合は<a href={url} target = '_blank'>こちら</a>よりご連絡ください。
					</div>
					でんきサービスとガスサービスの再点日・開栓日の変更/キャンセル/確認は<a href={url} target = '_blank'>こちら</a>へお問い合わせください。
					<li>
						日程の変更につきましては<a href={url} target = '_blank'>こちら</a>よりお問い合わせお願い致します。
					</li>
				</ul>
			</div>
		);
	}
	// --------------------------------------------------------------------------
}
