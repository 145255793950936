import { Card, Divider, TableCell, Typography } from '@material-ui/core';
import { Grid } from '@mui/material';
import React, { ReactNode } from 'react';
import { WprBaseViewComponent } from '../../../../../wpr-framework/view/component/WprBaseViewComponent';
import { MvnoAddCapacityView } from './MvnoAddCapacityView';
import { MatButton } from '../../../../../wpr-framework/component/controls/material/MatButton';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import styles from './MvnoAddCapacity.scss';
import { MatTextField } from '../../../../../wpr-framework/component/controls/material/MatTextField';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { WprAnchor } from '../../../../../wpr-framework/component/controls/WprAnchor';
import { MatOneRowTable } from '../../../../../wpr-framework/component/controls/material/MatOneRowTable';
import { MatTypography } from '../../../../../wpr-framework/component/controls/material/MatTypography';
import { MatSelect } from '../../../../../wpr-framework/component/controls/material/MatSelect';

/**
 * データ容量追加申込用コンポーネント
 */
export class MvnoAddCapacity extends WprBaseViewComponent {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: any) { super(new MvnoAddCapacityView(), props); }
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** データ容量追加申込ビュー情報 */
	public get viewInfo(): MvnoAddCapacityView { return this.view as MvnoAddCapacityView; }
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * スタイルオブジェクト取得
	*/
	public getStylesObject(): any { return styles; }

	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		return (
			<>
				{/* Milosから追加 START */}



				{/* Milosから追加 END */}
				<div>
					<div className='pageCard'>
						<div className="pageHeader title">
							データ容量追加申込
						</div>
						<Divider />
						<div className='pageContent'>
							<div>
								<div className='table_gray'>
									<Grid container className='tableRow'>
										<Grid item xs={12} md={4} className='rowTitle'>
											<div>回線番号</div>
										</Grid>
										<Grid item xs={12} md={8} className='rowContent'>
											<MatTypography name='phoneNumber' view={this.view} />
										</Grid>
									</Grid>
								</div>
								<div>
									<Grid container>
										<Grid item xs={6} className='gridItemCenter' justifyContent={'end'}>
											<div style={{fontWeight:'400', fontSize:'1rem'}}>
												1GB
											</div>
										</Grid>
										<Grid item xs={6} className='gridItemCenter'>
											<MatTypography name='addCouponPrice' view={this.view} component='span'/>
											<span className='itemUnit'>円</span>
										</Grid>
									</Grid>
								</div>
								<div className='comment'>
									<ul>
										<li>1GBごとの追加申込みとなります。2GB以上申込したい場合は複数回申込を行ってください。</li>
									</ul>
								</div>
							</div>

							<div className='pageFooter'>
								<div>
									<MatButton name='next' view={this.view} className='nextButton' >
										データ容量の追加申込
										<NavigateNextIcon />
									</MatButton>

								</div>
								<div>
									<MatButton name='back' view={this.view} className='backButton' >
										<NavigateBeforeIcon />
										戻る
									</MatButton>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
	// --------------------------------------------------------------------------
}
