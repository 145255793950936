import { WprBaseViewInfo } from '../../view/WprBaseViewInfo';

/**
 * 描画行情報
 */
export class WprRowInfo {
	// private 変数  ------------------------------------------------------------
	private m_ListName: string			= null;		// リスト名
	private m_Type: string				= null;		// 描画種別
	private m_Index: number				= null;		// 行インデクス
	private m_RowData: any				= null;		// 行データ
	private m_ViewInfo: WprBaseViewInfo	= null;		// ビュー情報
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** リスト名 */
	public get listName(): string 	{ return this.m_ListName;	}
	/** 描画種別 */
	public get type(): string 		{ return this.m_Type;		}
	/** 行インデクス */
	public get index(): number 		{ return this.m_Index;		}
	/** 行データ */
	public get rowData(): any 		{ return this.m_RowData;	}
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	constructor(listName: string, type: string, index: number, row: any, vinfo: WprBaseViewInfo) {
		this.m_ListName = listName;
		this.m_Type = type;
		this.m_Index = index;
		this.m_RowData = row;
		this.m_ViewInfo = vinfo;
	}
	// --------------------------------------------------------------------------

	// public メソッド  ---------------------------------------------------------
	/**
	 * 前のリストデータ取得
	 * @pram offset オフセット
	 * @returns 前のリストデータ
	 */
	public getPrevRowData(offset: number=1): any {
		if (this.m_Index-offset < 0)
			return null;
		const list = this.m_ViewInfo.getListInfo(this.listName).listData;
		return list[this.m_Index-offset];
	}

	/**
	 * 次のリストデータ取得
	 * @pram offset オフセット
	 * @returns 次のリストデータ
	 */
	public getNextRowData(offset: number=1): any {
		const list = this.m_ViewInfo.getListInfo(this.listName).listData;
		if (this.m_Index+offset >= list.length)
			return null;
		return list[this.m_Index+offset];
	}
	// --------------------------------------------------------------------------
}
