import { ObjectUtil } from "./ObjectUtil";
import { RoundUtil } from "./RoundUtil";
import { SmallNumberCalcUtil } from "./SmallNumberCalcUtil";
import React from "react";

/**
 * 変換ユーティリティ
 */
export class ConvertUtil {
	/**
	 * 数値変換
	 * @param value 対象値
	 * @returns 数値
	 */
	public static fromAny2Num(value: any): number {
		const num = Number(value);
		if (value == null || Number.isNaN(num)) {
			return 0;
		}
		return num;
	}

	/**
	 * 平米から坪へ変換
	 * @param value 平米
	 * @returns 坪
	 */
	public static convertM2toTsubo(value: any): number {
		const CONVERSION_RATE = 0.3025;		//1平米が0.3025坪
		//坪計算
		const tsubo = SmallNumberCalcUtil.product([
			value,
			CONVERSION_RATE
		]);
		return RoundUtil.round(tsubo, 2);
	}

	/**
	 * 8桁で、0埋めする。
	 */
	public static convertZerofill(value: string): string {
		return ('00000000' + value).slice(-8);
	}

	/**
	 * 年月を変換
	 * @param month 文字列の月（yyyy年MM月）
	 * @returns 
	 */
	public static convertYearMonthLabel(month: string): string {
		if (ObjectUtil.isNotNullOrUndefined(month) && month.length === 6) {
			const retYear: string = month.substring(0, 4);
			let retMonth: string = month.substring(4, 6);
			if (retMonth.charAt(0) === '0') {
				retMonth = retMonth.substring(1);
			}
			return retYear + '年' + retMonth + '月';
		}
		return month;
	}

	/**
	 * 年月日時を変換
	 * @param month 文字列の年月日時（yyyy/MM/dd HH:mm:ss）
	 * @returns 
	 */
	public static convertDayHourLabel(dayHour: string): string {
		if (ObjectUtil.isNotNullOrUndefined(dayHour) && dayHour.length === 19) {
			const yearMonth: string = dayHour.substring(0,4) + dayHour.substring(5,7);
			let day: string = dayHour.substring(8,10);
			if (day.charAt(0) === '0') {
				day = day.substring(1);
			}
			let hour: string = dayHour.substring(11,13);
			if (hour.charAt(0) === '0') {
				hour = hour.substring(1);
			}
			return this.convertYearMonthLabel(yearMonth) + day + '日' + hour + '時';
		}
		return dayHour;
	}

	/**
	 * 年月を変換（月のみ表示）
	 * @param month 文字列の年月（yyyyMM）
	 * @returns 
	 */
	public static convertMonthOnlyLabel(month: string): string {
		const strVal: string = month as string;
		if (ObjectUtil.isNotNullOrUndefined(strVal) && strVal.length === 6) {
			let retMonth: string = strVal.substring(4,6);
			if (retMonth.charAt(0) === '0') {
				retMonth = retMonth.substring(1);
			}
			return retMonth + '月';
		}
		return month;
	}

	/**
	 * 年月を変換（年のみ表示）
	 * @param year 文字列の年月（yyyyMM）
	 * @returns 
	 */
	public static convertYearOnlyLabel(year: string): string {
		const strVal: string = year as string;
		if (ObjectUtil.isNotNullOrUndefined(strVal) && strVal.length === 6) {
			let retYear: string = strVal.substring(0,4);
			return retYear + '年';
		}
		return year;
	}
	
	/**
	 * 3桁カンマに変換する。
	 * @param value 
	 * @returns 
	 */
	public static convertToComma(value: string): string {
		if (ObjectUtil.isNullOrUndefined(value)) return value;
		let slist = String(value).split('.');
		let commaVal = slist[0];
		if (commaVal !== '0')
			commaVal = String(slist[0].replace(/^0+/, '')).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
		if (slist.length > 1)
			commaVal += '.' + slist[1];
		return commaVal;
	}

}