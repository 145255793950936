import React, { ReactNode } from 'react';
import { WprBaseViewComponent } from '../../../../wpr-framework/view/component/WprBaseViewComponent';
import { CommonAgreeView } from './CommonAgreeView';
import { Typography, Divider, TableCell } from '@material-ui/core';
import { MatButton } from '../../../../wpr-framework/component/controls/material/MatButton';
import { MatCheckBox } from '../../../../wpr-framework/component/controls/material/MatCheckBox';
import { ProgressBar } from '../progress_bar/ProgressBar';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { MatTextField } from '../../../../wpr-framework/component/controls/material/MatTextField';
import { WprRowInfo } from '../../../../wpr-framework/component/props/WprRowInfo';
import { MatTableRow } from '../../../../wpr-framework/component/controls/material/MatTableRow';
import { MatTypography } from '../../../../wpr-framework/component/controls/material/MatTypography';
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import { WprContainer } from '../../../../wpr-framework/component/controls/WprContainer';
import styles from './CommonAgree.scss';

/**
 * 約款画面用コンポーネント
 */
export class CommonAgree extends WprBaseViewComponent {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: any) { super(new CommonAgreeView(), props); }
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** 約款画面ビュー情報 */
	public get viewInfo(): CommonAgreeView	{ return this.view as CommonAgreeView;	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * スタイルオブジェクト取得
	*/
	public getStylesObject(): any { return styles; }

	/**
	 * リストコントロール定義登録
	*/
	protected onRegisterListRender(): void {
		this.addListRender('YakkanListModel', null, this.onYakkanListModelRender);
	}

	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		let hiddenClass: string = null;
		const url:string = window.location.href;
		if (url.includes('relocate_agree'))
			hiddenClass = 'hiddenClass';
		return (
			<>
			<div className='pageCard'>
				<div className={hiddenClass}>
					<div className="pageHeader stepFlow ">
						<ProgressBar name='progressBar' view={this.view} />
					</div>
				</div>
				<div className='main'>
					<div className='pageTitle'>
						<div className='title_left'>
							<MatTypography name='title' className='title_text' view={this.view}/>
							<Typography className='title_comment'>以下の契約約款をご承諾の上、「申込む」ボタンをクリックしてください。</Typography>
						</div>
						<div className='title_center' style={{marginTop:'30px'}}>
							{this.renderList('YakkanListModel')}
							{/* <div className='title_center' style={{marginTop:'30px'}}>
								<MatCheckBox name='confirm' label='承諾する' view={this.view}/>
							</div> */}
						</div>
					</div>
				</div>
				<Divider className='confirmButton'/>
				<div className='pageFooter footer-space'>
					<div>
						<MatButton name='next' view={this.view} className='orangeButton orangeButton_parts grayButtom' >
							申込む
							<NavigateNextIcon />
						</MatButton>
					</div>
					<div>
						<MatButton name='back' view={this.view} className='backButton' >
							<NavigateBeforeIcon />
							戻る
						</MatButton>
					</div>
				</div>
			</div>
			</>
		);
	}
	// --------------------------------------------------------------------------

	// リスト描画  ---------------------------------------------------------------
	/**
	 * リスト名=YakkanListModel
	 */
	public onYakkanListModelRender(rowInfo: WprRowInfo): any {
		return (
			<div className='yakkan'>
				<MatTypography name='text1' view={this.view} row={rowInfo} className='bikou'/>
				<MatButton name='yakkan' variant='outlined' className='yakkanButton' view={this.view} row={rowInfo}>
					<MatTypography name='yakkanName' view={this.view} row={rowInfo}/>
					{/* <span className='fileSize'>
						<MatTypography name='fileSize' view={this.view} row={rowInfo} className='number'/>
						<span>kb</span>
					</span> */}
					<TextSnippetOutlinedIcon />
				</MatButton>
				<MatTypography name='text2' view={this.view} row={rowInfo} className='bikou'/>
			</div>
		);
	}
	// --------------------------------------------------------------------------
}
