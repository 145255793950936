import { WprModel } from '../../../../wpr-framework/view/deco/WprModel';
import { WprDI_ScopeModel } from '../../../../wpr-framework/view/di/WprDI_ScopeModel';
import { Customer } from '../../../models/bss/customer/Customer';
import { NameChangeAppModel } from '../../../service/models/apimodels/NameChangeAppModel';
import { RegistNameChangeRequest } from '../../../service/models/dto/mypagerenewal/RegistNameChangeRequest';
import { UpdateNameChangePaymentRequest } from '../../../service/models/dto/mypagerenewal/UpdateNameChangePaymentRequest';
import { NameChangeService } from '../../../service/NameChangeService';
import { MypageBaseView } from '../../base/MypageBaseView';
import { ProgressBarData } from '../../common/progress_bar/ProgressBarView';
import { DateConverter_JPConverter } from '../../framework/Converter/DateConverter_JPConverter';
import { MypageWizardMngr } from '../../wizard/models/MypageWizardMngr';
import { BaseService } from '../../../service/base/BaseService';
/**
 * 名義変更申込み内容確認
 */
export class NameChangeConfirmView extends MypageBaseView {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() { super('NameChangeConfirmView'); }
	// --------------------------------------------------------------------------
	
	// モデル  ------------------------------------------------------------------
	@WprDI_ScopeModel('MypageWizardMngr')
	private m_MypageWizardMngr: MypageWizardMngr = null;	// 画面ウィザード管理クラス
	@WprModel('NameChangeAppModel')
	private m_EntryInfo: NameChangeAppModel = null;			// 名義変更申込み情報モデル
	@WprModel('changePayment')
	private m_ChangePayment: string = null;	// 支払方法
	@WprModel('newPayment')
	private m_NewPayment: string = null;	// 新しい支払方法

	private m_Customer: Customer = null;		// 顧客情報

	private m_NameChangeService: NameChangeService	= new NameChangeService();	// 名義変更サービス
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * コントロール定義設定
	 */
	public onRegisterConfig(): void {
		this.addConfig('nameChangeKbn', '名義変更区分', 'NameChangeAppModel.nameChangeInfo', { converter: '名義変更区分' });
		this.addConfig('newName', '新名義人名', 'NameChangeAppModel.nameChangeInfo');
		this.addConfig('newNameKana', '新名義人名カナ', 'NameChangeAppModel.nameChangeInfo');
		this.addConfig('relationShip', '続柄', 'NameChangeAppModel.nameChangeInfo');
		this.addConfig('gender', '性別', 'NameChangeAppModel.nameChangeInfo');
		this.addConfig('birthdayStr', '生年月日', 'NameChangeAppModel');
		this.addConfig('phoneNumber1', '電話番号1', 'NameChangeAppModel.nameChangeInfo');
		this.addConfig('phoneNumber2', '電話番号2', 'NameChangeAppModel.nameChangeInfo');
		this.addConfig('newMail', 'メールアドレス', 'NameChangeAppModel.nameChangeInfo');
	}
	
	/**
	 * ビュー表示通知
	 */
	 public onShowView(): void {
		this.m_MypageWizardMngr.initView(this, (result) => {
			this.registAccessLog();
			this.setStorageItem('b_url', window.location.href)
			const sendData: ProgressBarData = new ProgressBarData();
			sendData.progressBarList = this.m_MypageWizardMngr.getProgressModelList(this);
			this.sendChildData('fP_ev_progressBarIntital', sendData);
			this.m_EntryInfo = this.m_MypageWizardMngr.getCache().nameChangeAppInfo;
			this.m_Customer = this.m_MypageWizardMngr.getViewInfo().customer;

			this.m_EntryInfo.nameChangeInfo.newName = this.m_EntryInfo.familyName + '　' +  this.m_EntryInfo.firstName;
			this.m_EntryInfo.nameChangeInfo.newNameKana = this.m_EntryInfo.familyNameKana + '　' +  this.m_EntryInfo.firstNameKana;
			if(this.m_EntryInfo.nameChangeInfo.nameChangeKbn !== '1') {
				let month = this.m_EntryInfo.birthdayMonth;
				if (month.length != 2)
					month = '0' + month;
				let day = this.m_EntryInfo.birthdayDay;
				if (day.length != 2)
					day = '0' + day;
				this.m_EntryInfo.nameChangeInfo.birthday = this.m_EntryInfo.birthdayYear + month + day;
				this.m_EntryInfo.birthdayStr = this.m_EntryInfo.birthdayYear + '年' + this.m_EntryInfo.birthdayMonth + '月' + this.m_EntryInfo.birthdayDay + '日';
			}
			this.setViewMode('名義変更区分', this.m_EntryInfo.nameChangeInfo.nameChangeKbn);
			this.refreshView();
		});
	}

	/**
	 * アクション登録
	 */
	public onRegisterAction(): void {
		this.addAction('next', this.onNext);
		this.addAction('back', this.onBack);
	}
	// --------------------------------------------------------------------------
	
	// アクション  ---------------------------------------------------------------
	/**
	 * 次へ
	 */
	public onNext(param: any): void {
		// 旧名義人入力情報 登録
		const req: RegistNameChangeRequest = new RegistNameChangeRequest();
		req.customerId = this.m_EntryInfo.customerId;
		req.oldName = this.m_Customer.surName + '　' + this.m_Customer.givenName;
		req.oldPhone = this.m_Customer.contact[0].phoneNumber;
		req.oldMail = this.m_Customer.contact[2].mailAddress;
		req.nameChangeInfo = this.m_EntryInfo.nameChangeInfo;
		this.m_NameChangeService.registNameChange(this, req, (result) => {
			if (result && result.status === BaseService.SERVICE_SUCCESS_STATUS) {
				this.m_MypageWizardMngr.goNext(this);
			} else {
				this.pushPath('/error');
			}
		});
	}

	/**
	 * 戻る
	 */
	public onBack(param: any): void {
		this.m_EntryInfo.nameChangeInfo.birthday = null;
		this.m_EntryInfo.birthdayStr = null;
		this.m_MypageWizardMngr.goBack(this);
	}
	// --------------------------------------------------------------------------
}
