import { DataUsage, NoEncryption, Tune } from '@material-ui/icons';
import React, { ReactNode } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);
import { UsageCplusGraphDialogView } from './UsageCplusGraphDialogView';
import styles from './UsageCplusGraphDialog.scss';
import { UsageCplusGraphDialogViewComponent } from '../../../../controls/usageDetailsDialog/UsageCplusGraphDialogViewComponent';
import { Grid, Typography } from '@material-ui/core';
import { MatSelect } from '../../../../../wpr-framework/component/controls/material/MatSelect';

import nextArrow from '/shared/image/usagedetails/nextArrow.png';
import backArrow from '/shared/image/usagedetails/backArrow.png';

import { MatButton } from '../../../../../wpr-framework/component/controls/material/MatButton';
import { WprContainer } from '../../../../../wpr-framework/component/controls/WprContainer';

/**
 * ケーブルプラス電話ご利用料金グラフダイアログ用コンポーネント
 */
export class UsageCplusGraphDialog extends UsageCplusGraphDialogViewComponent {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: any) { super(new UsageCplusGraphDialogView(), props); }
	// --------------------------------------------------------------------------
	// プロパティ  ---------------------------------------------------------------
	/** 利用明細グラフダイアログビュー情報 */
	public get viewInfo(): UsageCplusGraphDialogView	{ return this.view as UsageCplusGraphDialogView;	}
	// --------------------------------------------------------------------------

	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * スタイルオブジェクト取得
	*/
	public getStylesObject(): any { return styles; }

	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		const total = this.state.data.totalChargeData;
		const basicChargeDataList = this.state.data.basicChargeData;
		const callChargeDataList = this.state.data.callChaegeData;
		const otherChargeDataList = this.state.data.otherChargeData;

		const data = {
			type: 'bar',
			// x 軸のラベル
			labels: this.state.data.monthLabel,
			datasets: [
				{
					label: '基本料金',
					data: basicChargeDataList,
					// グラフの背景色
					backgroundColor: 'rgba(41, 49, 162)',
					//グラフの枠線の色
					borderColor: 'rgba(41, 49, 162)',
					// グラフの枠線の太さ
					borderWidth: 0.5,

					id: 'y-axis-0'
				},	
				{
					label: '通話料',
					data: callChargeDataList,
					// グラフの背景色
					backgroundColor: 'rgba(154, 159, 227)',
					//グラフの枠線の色
					borderColor: 'rgba(154, 159, 227)',
					// グラフの枠線の太さ
					borderWidth: 0.5
				},
				{
					label: 'その他',
					data: otherChargeDataList,
					// グラフの背景色
					backgroundColor: 'rgba(217, 220, 242',
					//グラフの枠線の色
					borderColor: 'rgba(217, 220, 242)',
					// グラフの枠線の太さ
					borderWidth: 0.5
				}
			]
		};
		const options = {
			// レスポンシブ対応 -> 暫定：モバイル表示時にはスクロール対応となったためコメントアウト
			// responsive: true,
			maintainAspectRatio: false,
			plugins: {
				tooltip: {
					intersect: false,
					mode: 'index',
					// backgroundColor: 'rgba(63, 148, 217)',
					backgroundColor: '#2931A2',
					bodyColor: 'rgba(255, 255, 255)',	
					enabled: true,
					displayColors: false,
					cornerRadius: 10,
					// borderColor: 'rgba(3, 99, 177)',
					borderColor: '#2931A2',
					borderWidth: 1,
					callbacks: {
						title: function(tooltipItem, data) {
							return "合計金額　" 
								+ total[tooltipItem[0].dataIndex].toLocaleString() + "円";
						},
						label: function(tooltipItem, data) {
							// 上から➀その他➁通話料➂基本料金 の順で積む
							if ( tooltipItem.dataset.label == '基本料金' ) {
								return 'その他　　' + otherChargeDataList[tooltipItem.dataIndex].toLocaleString()  + '円';
							} else if ( tooltipItem.dataset.label == '通話料' ) {
								return tooltipItem.dataset.label + '　　' + tooltipItem.formattedValue + '円';
							} else if ( tooltipItem.dataset.label == 'その他' ) {
								return '基本料金　' + basicChargeDataList[tooltipItem.dataIndex].toLocaleString()  + '円';
							}
							return '';
						}
					}
				},
				legend: {
					position: "bottom",
					reverse: true,
					// 凡例クリックの無効化
					onClick: function(){ 
						return false;
					}
				},
				annotation: {
					drawTime: 'beforeDatasetsDraw',
					annotations: {
						id: 'hline',
						type: 'line',
						mode: 'horizontal',
						scaleID: 'y-axis-0',
						value: 0,
						borderColor: 'black',
						borderWidth: 2,
						label: {
							enabled: false
						},
					}
				}	
			},
			categoryPercentage: 0.6,
			barPercentage: 0.4,
			scales: {
				// 積み上げの指定
				x: {
					stacked : true,
					grid: {
						display: false
					}
				},
				y: {
					stacked: true,
		            type: 'linear',
					beginAtZero: true,
					id: 'y-axis-0',
					ticks: {
						minTicksLimit: 7,
						maxTicksLimit: 9
					}
				}
			},
			zoom: {
				pan: {
					enabled: true,
					mode: 'x'
				},
				zoom: {
					enabled: true,
					mode: 'x'
				}
			}
		};
		return (
			<>
				<div className='graphCondition'>
					<Typography>表示する電話番号を選択ください。　</Typography>
					{/* Milosから追加 START */}
					<MatSelect name='phoneNumberSelect' vm='UsageDetailsPhoneNumber' variant='standard' view={this.view}/>
					{/* Milosから追加 END */}
				</div>
				<div className='chartWrapper'>
					<WprContainer mode={'矢印.前へ'} view={this.view}>
						<MatButton name='back' color='primary' view={this.view} className='arrow'>
							<img src={backArrow} />
						</MatButton>
					</WprContainer>
					<div className='graphArea'>
						<Typography>円</Typography>
						<div className='graph'>
							<Bar data={data} options={options}/>
						</div>
					</div>
					<WprContainer mode={'矢印.次へ'} view={this.view}>
						<MatButton name='next' color='primary' view={this.view} className='arrow'>
							<img src={nextArrow} />
						</MatButton>
					</WprContainer>
				</div>
			</>
		);
	}
	// --------------------------------------------------------------------------
}
