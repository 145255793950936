import { WprBaseObject } from '../../WprBaseObject';
import { WprBaseViewCore } from '../WprBaseViewCore';
import { WprControlConfig } from './WprControlConfig';
import { IWprConfigOption } from './IWprConfigOption';
import { WprControlStatus } from './WprControlStatus';
import { IWprControlState, WprControlVisibility } from '../../component/props/IWprControlState';
import { WprBaseControlComponent } from '../../component/controls/WprBaseControlComponent';
import { WprControlValidator } from '../validation/WprControlValidator';
import { WprInvalidInfo } from '../validation/WprInvalidInfo';
import { WprValidError } from '../validation/WprValidError';
import { WprRowInfo } from '../../component/props/WprRowInfo';
import { WprValidErrorInfo } from '../validation/WprValidErrorInfo';
import { WprBaseLayoutComponent } from '../../component/controls/WprBaseLayoutComponent';
import { IWprLayoutConfigOption } from './IWprLayoutConfigOption';
import { WprLayoutStatus } from './WprLayoutStatus';
import { WprLayoutConfig } from './WprLayoutConfig';
import { WprControlAuthInfo } from './WprControlAuthInfo';

/**
 * コントロール情報
 */
export class WprControlInfo extends WprBaseObject {
	// static 変数  -------------------------------------------------------------
	public static	isReadonlyRefresh: boolean	= false;		// 読取専用のみ再表示フラグ
	public static	isChangeValue: boolean 		= false;		// 値変更フラグ
	// --------------------------------------------------------------------------

	// private 変数  ------------------------------------------------------------
	private m_Name: string									= null;									// 名称
	private m_Description: string							= null;									// 説明
	private m_Value: any									= '';									// 値
	private m_ViewValue: any								= null;									// 編集値
	private m_EditValue: any								= null;									// 表示値
	private m_Dirty: boolean								= false;								// 変更フラグ
	private m_Visibility: boolean							= true;									// 表示状態
	private m_Display: boolean								= true;									// 表示状態
	private m_Tag: any										= null;									// タグ
	private m_Class: string									= null;									// スタイルクラス
	private m_AuthClass: string								= null;									// 権限スタイルクラス
	private m_ModelName: string								= null;									// モデル名称
	private m_BindName: string								= null;									// モデルにバインドする名称
	private m_ViewCore: WprBaseViewCore						= null;									// ビュー情報
	private m_Config: WprControlConfig						= null;									// コントロール定義情報
	private m_LayoutConfig: WprLayoutConfig					= null;									// レイアウトコントロール定義情報
	private m_Validator: WprControlValidator				= null;									// バリデータ
	private m_Status: WprControlStatus						= new WprControlStatus();				// コントロールステータス
	private m_LayoutStatus: WprLayoutStatus					= new WprLayoutStatus();				// レイアウトステータス
	private m_SubModelList: string[]						= null;									// サブモデルリスト
	private m_IsDispAuth: boolean 							= true;									// 表示権限
	private m_IsEnableAuth: boolean 						= true;									// 活性化権限
	private m_ConfigModel: string							= null;									// 定義モデル
	private m_isFirst: boolean								= true;									// 初回フラグ
	private m_IsLayout: boolean								= null;									// レイアウトフラグ
	private m_ComponentList: WprBaseControlComponent<any, any>[]	= new Array();					// コンポーネントリスト
	private m_LayoutComponent: WprBaseLayoutComponent<any, any>	= null;								// レイアウトコンポーネント
	private m_InValidList: WprInvalidInfo[]					= null;									// バリデーションエラー情報リスト
	private m_IsInvalid: boolean							= false;								// バリデーションフラグ
	private m_RowIndex: number								= -1;									// 行インデクス
	private m_ValidError: WprValidError						= null;									// バリデーションエラー情報
	private m_ErrorMessageList: string[]					= new Array();							// エラーメッセージリスト
	private m_IsSetModeValue								= false;								// モード値設定フラグ
	private m_SaveAuthInfo: WprControlAuthInfo 				= new WprControlAuthInfo();				// 権限設定保存情報
	private m_Refresh: number								= 0;									// 再表示カウンタ
	private m_FocusValue: any 								= null;									// フォーカスを取得したときの値
	private m_StatusMap: Map<string, WprControlStatus> 		= new Map<string, WprControlStatus>();	// コントロールステータスマップ
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** 名称 */
	public get name(): string 					{ return this.m_Name; 			}
	/** 説明 */
	public get description(): string 			{ return this.m_Description; 	}	public set description(desc: string) 			{ this.m_Description = desc;	}
	/** 値 */
	public get value(): any 					{ return this.m_Value;	 		} 	public set value(value: any) 					{ this.setValue(value);			}
	/** 表示値 */
	public get dispValue(): any 				{ return this.m_ViewValue;	 	}
	/** 変更フラグ */
	public get dirty(): any 					{ return this.m_Dirty;	 		} 	public set dirty(value: any) 					{ this.setDirty(value);			}
	/** タグ */
	public get tag(): string 					{ return this.m_Tag; 			} 	public set tag(tag: string) 					{ this.m_Tag = tag;				}
	/** クラス */
	public get class(): string 					{ return this.m_Class; 			} 	public set class(cls: string) 					{ this.setClass(cls);			}
	/** 権限クラス */
	public get authClass(): string 				{ return this.m_AuthClass; 		} 	public set authClass(cls: string) 				{ this.setAuthClass(cls);		}
	/** モデル名称 */
	public get bindName(): string 				{ return this.m_BindName; 		}
	/** モデルにバインドする名称 */
	public get modelName(): string 				{ return this.m_ModelName; 		}
	/**  コントロール定義情報 */
	public get config(): WprControlConfig 		{ return this.m_Config; 		}	public set config(config: WprControlConfig)		{ this.m_Config = config;		}
	/**  バリデータ */
	public get validator(): WprControlValidator { return this.m_Validator; 		}	public set validator(valid: WprControlValidator) { this.m_Validator = valid;	}
	/** コントロールステータス */
	public get status(): WprControlStatus 		{ return this.m_Status; 		}	public set status(status: WprControlStatus)		{ this.m_Status = status;		}
	/** サブモデルリスト */
	public get subModelList(): string[] 		{ return this.m_SubModelList; 	}
	/** 定義モデル */
	public get configModel(): string	 		{ return this.m_ConfigModel; 	}
	/** ビュー情報 */
	public get viewCore(): WprBaseViewCore		{ return this.m_ViewCore; 		}
	/** 行インデクス */
	public get rowIndex(): number				{ return this.m_RowIndex; 		}	public set rowIndex(index: number) 				{ this.m_RowIndex = index;		}
	/** バリデーションエラー情報 */
	public get validError(): WprValidError		{ return this.m_ValidError; 	}	public set validError(error: WprValidError) 	{ this.setErrorInfo(error);		}
	/** バリデーションフラグ */
	public get isInvalid(): boolean				{ return this.m_IsInvalid; 		}
	/** レイアウトフラグ */
	public get isLayout(): boolean				{ return this.m_IsLayout; 		}	public set isLayout(isLayout: boolean)			{ this.m_IsLayout = isLayout;	}
	/** レイアウトステータス */
	public get layoutStatus(): WprLayoutStatus 	{ return this.m_LayoutStatus; 	}	public set layoutStatus(layout: WprLayoutStatus) { this.m_LayoutStatus = layout; }
	/** モード値設定フラグ */
	public get isSetModeValue(): boolean 		{ return this.m_IsSetModeValue; }	public set isSetModeValue(isSetValue: boolean) 	{ this.m_IsSetModeValue = isSetValue; }
	/** エラーメッセージリスト */
	public get errorMessageList(): string[]		{ return this.m_ErrorMessageList;	}
	/** ロストフォーカス時値変更フラグ */
	public get isBlurChange(): boolean			{ return this.getIsBlurChange();	}
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	public constructor(viewCore: WprBaseViewCore, name: string, description: string, modelName: string, config: IWprConfigOption, layoutConfig: IWprLayoutConfigOption) {
		super();
		this.m_ViewCore = viewCore;
		this.m_Name = name;
		this.m_Description = description;
		this.m_BindName = name;
		this.setModelName(modelName);
		if (config) {
			this.m_Config = new WprControlConfig(name, this.m_ViewCore, config);
			if (config.bindName)
				this.m_BindName = config.bindName;
			if (config.bindModel === true)
				this.m_BindName = null;
			if (config.hissu)
				this.m_Status.hissu = config.hissu;
			if (config.readOnly)
				this.m_Status.readOnly = config.readOnly;
			if (config.disabled)
				this.m_Status.disabled = config.disabled;
			if (config.min)
				this.m_Status.min = config.min;
			if (config.max)
				this.m_Status.max = config.max;
			if (config.minLength)
				this.m_Status.minLength = config.minLength;
			if (config.maxLength)
				this.m_Status.maxLength = config.maxLength;
			this.m_Validator = new WprControlValidator(this.m_Description, config);
		}
		else {
			this.m_Config = new WprControlConfig(name, this.m_ViewCore);
		}
		if (layoutConfig) {
			this.m_LayoutConfig = new WprLayoutConfig(name, this.m_ViewCore, layoutConfig);
			if (layoutConfig.open)
				this.m_LayoutStatus.open = layoutConfig.open;
		}
		else {
			this.m_LayoutConfig = new WprLayoutConfig(name, this.m_ViewCore);
		}
		this.setDispAuth();
		this.setEnableAuth();
		this.setCustumAuth();
	}
	// --------------------------------------------------------------------------

	// virtual メソッド  ---------------------------------------------------------
	/**
	 * 表示権限設定
	 */
	public setDispAuth() {
		this.setDisplay(true);
		if (this.m_Config.dispAuthList != null) {
			if (this.FW.auth.checkUserAuthority(this.m_Config.dispAuthList) === false)
				this.m_IsDispAuth = false;
			else
				this.m_IsDispAuth = true;
		}
	}

	/**
	 * 活性化権限設定
	 */
	public setEnableAuth() {
		if (this.m_Config.enableAuthList != null) {
			if (this.FW.auth.checkUserAuthority(this.m_Config.enableAuthList) === false)
				this.m_IsEnableAuth = false;
			else
				this.m_IsEnableAuth = true;
		}
	}
	// --------------------------------------------------------------------------

	// public メソッド  ---------------------------------------------------------
	/**
	 * 入力チェック処理
	 * @param name アクション名又はコントロール名
	 * @param validError 入力エラー情報
	 * @param isControl コントロールチェックフラグ
	 * @param ieEdit 編集フラグ
	 * @returns falseの場合は、エラー
	 */
	public checkValidate(name: string, validError: WprValidError, isControl: boolean, isEdit: boolean): boolean {
//		if (this.isDisplay() == false)
//			return true;
		try {
			this.resetValidator();
			if (this.m_Validator != null) {
				let check = false;
				if (isControl === true) {
					if (name === this.m_Name || name === this.m_Name+'@@blur')
						check = true;
				}
				else if (this.m_Config.actionList == null || this.m_Config.actionList.includes(name) === true) {
					check = true;
				}
				if (check === true) {
					if (this.m_Validator.checkValidate(this, validError) === false) {
						this.m_Status.inputError = true;
						this.validError = validError;
						return false;
					}
					this.m_Status.inputError = false;
					this.validError = null;
				}
				else {
					return true;
				}
			}
			if (isControl === false) {
				this.m_Status.inputError = false;
				if (isEdit === true)
					this.dirty = true;
				this.validError = null;
			}
			else if (isEdit === true)
				this.dirty = true;
		}
		catch (ex) {
			this.addErrorLog(`Validationで例外が発生しました。[${name}][${this.m_Name}]`, ex);
		}
		return true;
	}

	/**
	 * コントロールコンポーネント設定
	 * @param componet コントロールコンポーネント
	 */
	public setComponent(componet: WprBaseControlComponent<any, any>): void {
		if (this.m_IsLayout == null)
			this.m_IsLayout = false;
		if (this.m_IsLayout === false) {
			this.m_ComponentList.push(componet);
			if (componet.props.id)
				this.m_StatusMap.set(componet.props.id, new WprControlStatus());
		}
		else {
			this.m_ViewCore.addErrorLog(`レイアウトとして定義されたコンポーネントがコントロールです。[${this.m_Name}]`);
		}
	}

	/**
	 * コントロールコンポーネント削除設定
	 * @param componet コントロールコンポーネント
	 */
	public deleteComponent(componet: WprBaseControlComponent<any, any>): void {
		if (this.m_ComponentList.includes(componet) === true) {
			const idx = this.m_ComponentList.indexOf(componet);
			this.m_ComponentList.splice(idx, 1);
		}
	}

	/**
	 * コンポーネントのステータス取得
	 * @param row 行情報
	 * @param option オプション
	 * @param focus フォーカス
	 * @returns コントロールステータス
	 */
	public getComponentState(row?: WprRowInfo, option?: any, focus?: any): IWprControlState {
		let visibility = WprControlVisibility.VISIBLE;
		if (this.m_IsDispAuth === false || this.m_Display === false)
			visibility = WprControlVisibility.COLLAPSED;
		else if (this.m_Visibility === false)
			visibility = WprControlVisibility.HIDDEN;
		let disabled = this.status.disabled;
		if (this.m_IsEnableAuth === false)
			disabled = true;
		let cls = null;
		if (this.m_ValidError)
			cls = this.m_Config.errorClass;
		else if (this.m_Dirty === true || this.value)
			cls = this.m_Config.editClass;
		else
			cls = this.m_Config.noEditClass;
		if (this.m_Status.readOnly)
			cls = this.m_Config.readOnlyClass;
		if (this.m_Status.disabled)
			cls = this.m_Config.disabledClass;
		if (focus === undefined)
			focus = null;
		let viewValue = this.m_ViewValue;
		if (viewValue == null)
			viewValue = this.m_Value;
		let editValue = this.m_EditValue;
		if (editValue == null)
			editValue = this.m_Value;
		return { 
			value: this.m_Value,
			viewValue: viewValue,
			editValue: editValue,
			disabled: disabled,
			readOnly: this.status.readOnly,
			style: null,
			dirty: this.m_Dirty,
			className: cls,
			modeClassName: this.status.modeClass,
			authClassName: this.m_AuthClass,
			min: this.status.min,
			max: this.status.max,
			minLength: this.status.minLength,
			hissu: this.status.hissu,
			maxLength: this.status.maxLength,
			invalid: this.m_IsInvalid,
			visibility: visibility,
			option: option,
			placeholder: null,
			errMessage: null,
			loadWait: false,
			tooltipOpen: false,
			focus: focus,
			refresh: 0,
			numOption1: 0,
			numOption2: 0,
			boolOption1: false,
			boolOption2: false
		};
	}

	/**
	 * レイアウトコンポーネント設定
	 * @param componet レイアウトコンポーネント
	 */
	public setLayoutComponent(componet: WprBaseLayoutComponent<any, any>): void {
		if (this.m_IsLayout == null)
			this.m_IsLayout = true;
		if (this.m_IsLayout === true) {
			if (this.m_LayoutComponent != null)
				this.m_ViewCore.addErrorLog(`レイアウトの名前が重複しています。[${this.m_Name}]`);
			this.m_LayoutComponent = componet;
			this.viewCore.updateControlMode(this.m_Name);
		}
		else {
			this.m_ViewCore.addErrorLog(`コントロールとして定義されたコンポーネントがレイアウトです。[${this.m_Name}]`);
		}
	}

	/**
	 * レイアウトコンポーネント削除
	 * @param componet レイアウトコンポーネント
	 */
	public deleteLayoutComponent(componet: WprBaseLayoutComponent<any, any>) {
		if (this.m_IsLayout === true && this.m_LayoutComponent == componet) {
			this.m_LayoutComponent = null;
		}
	}

	/**
	 * コンポーネントのステータス取得
	 * @param row 行情報
	 * @returns コントロールステータス
	 */
	public getLayoutComponentState(row?: WprRowInfo, option?: any): any {
		// 後で継承したクラスから取り出す必要がある（WprBaseLayoutInfo作ってDrawer等が継承する）
		let visibility = WprControlVisibility.VISIBLE;
		if (this.m_IsDispAuth === false || this.m_Display === false)
			visibility = WprControlVisibility.COLLAPSED;
		else if (this.m_Visibility === false)
			visibility = WprControlVisibility.HIDDEN;
		let open = this.layoutStatus.open;
		return { 
			open: open,
			style: null,
			className: null,
			visibility: visibility,
		};
	}

	/**
	 * 値変更処理
	 * @param value 値
	 * @returns falseの場合、変更を取り消す
	 */
	public changeValue(value: any): boolean {
		try {
			const bval = this.value;
			this.value = value;
			WprControlInfo.isChangeValue = false;
			if (this.m_ViewCore.onChangeValue(this.m_Name, value) === false) {
				this.value = bval;
				return false;
			}
		}
		catch (ex) {
			this.m_ViewCore.addErrorLog(`onChangeValueで例外が発生しました。[${this.m_Name}][${value}]`, ex);
		}
		this.m_ViewCore.changeValue(this.m_Name, value);
		this.m_ViewCore.viewInfo.checkScopeModelValue();
		this.m_ViewCore.viewInfo.updateComponentMode();
		return true;
	}

	/**
	 * リスト値変更処理
	 * @param value 値
	 * @param row 行情報
	 * @returns falseの場合、変更を取り消す
	 */
	public changeListValue(value: any, row: WprRowInfo): boolean {
		try {
			const bval = this.value;
			this.value = value;
			WprControlInfo.isChangeValue = false;
			if (this.m_ViewCore.onChangeListValue(this.m_Name, row.listName, row.rowData, value) === false) {
				this.value = value;
				return false;
			}
		}
		catch (ex) {
			this.m_ViewCore.addErrorLog(`changeListValueで例外が発生しました。[${this.m_Name}][${value}]`, ex);
		}
		this.m_ViewCore.changeListValue(this.m_Name, row.rowData, value);
		this.m_ViewCore.viewInfo.checkScopeModelValue();
		this.m_ViewCore.viewInfo.updateComponentMode();
		return true;
	}

	/**
	 * 非活性状態設定処理
	 * @param disabled 非活性フラグ
	 * @param id コントロールID
	 */
	public setDisabled(disabled: boolean, id?: string): void {
		if (this.m_IsEnableAuth === false)
			return;
		const status = this.getStatusInfo(id);
		if (this.m_Config.enableMode)
			disabled = !this.viewCore.checkMode(this.m_Config.enableMode);
		status.disabled = disabled;
		this.m_ComponentList.forEach(cinfo => {
			if (this.checkCompID(cinfo, id) === true)
				cinfo.setDisabled(disabled);
		});
	}

	/**
	 * 読取り専用設定処理
	 * @param readOnly 読取り専用フラグ
	 * @param id コントロールID
	 */
	public setReadOnly(readOnly: boolean, id?: string): void {
		if (this.m_IsEnableAuth === false)
			return;
		id = undefined;		// 読取専用は、単独では設定できないため
		const status = this.getStatusInfo(id);
		status.readOnly = readOnly;
		this.m_ComponentList.forEach(cinfo => {
			if (this.checkCompID(cinfo, id) === true) {
				cinfo.setReadOnly(readOnly);
				this.setClassName(cinfo);
			}
		});
	}

	/**
	 * 表示状態設定処理
	 * @param display 表示フラグ
	 * @param id コントロールID
	 */
	public setDisplay(display: boolean, id?: string): void {
		if (this.m_IsDispAuth === false)
			return;
		const status = this.getStatusInfo(id);
		status.display = display;
		if (this.m_Display !== display) {
			this.m_Display = display;
		}
		this.m_ComponentList.forEach(cinfo => {
			if (this.checkCompID(cinfo, id) === true)
				cinfo.setVisibility(this.m_Visibility, display);
		});
	}

	/**
	 * 表示状態設定処理
	 * @param visibility 表示フラグ
	 * @param id コントロールID
	 */
	public setVisibility(visibility: boolean, id?: string): void {
		if (this.m_IsDispAuth === false)
			return;
		const status = this.getStatusInfo(id);
		status.visibility = visibility;
		if (this.m_Visibility !== visibility) {
			this.m_Visibility = visibility;
		}
		this.m_ComponentList.forEach(cinfo => {
			if (this.checkCompID(cinfo, id) === true)
				cinfo.setVisibility(visibility, this.m_Display);
		});
	}

	/**
	 * クラス情報設定
	 * @param cls クラス情報
	 */
	public setClass(cls: string): void {
		if (this.m_IsDispAuth === false)
			return;
		this.m_Class = cls;
		if (this.m_IsLayout == false) {
			this.m_ComponentList.forEach(cinfo => {
				cinfo.setClass(cls);
			});
		}
		else if (this.m_LayoutComponent) {
			this.m_LayoutComponent.setClass(cls);
		}
	}

	/**
	 * モードクラス情報設定
	 * @param cls クラス情報
	 * @param id コントロールID
	 */
	 public setModeClass(cls: string, id?: string): void {
		if (this.m_IsDispAuth === false)
			return;
		const status = this.getStatusInfo(id);
		 status.modeClass = cls;
		if (this.m_IsLayout == false) {
			this.m_ComponentList.forEach(cinfo => {
				if (this.checkCompID(cinfo, id) === true)
					cinfo.setModeClass(cls);
			});
		}
		else if (this.m_LayoutComponent) {
			this.m_LayoutComponent.setModeClass(cls);
		}
	}

	/**
	 * 権限クラス情報設定
	 * @param cls クラス情報
	 */
	 public setAuthClass(cls: string): void {
		if (this.m_IsDispAuth === false)
			return;
		this.m_AuthClass = cls;
		if (this.m_IsLayout == false) {
			this.m_ComponentList.forEach(cinfo => {
				cinfo.setAuthClass(cls);
			});
		}
		else if (this.m_LayoutComponent) {
			this.m_LayoutComponent.setAuthClass(cls);
		}
	}

	/**
	 * 最小値設定
	 * @param min 最小値
	 * @param id コントロールID
	 */
	public setMin(min: number | string, id?: string) {
		this.m_Status.min = min;
		if (this.m_IsLayout == false) {
			this.m_ComponentList.forEach(cinfo => {
				if (this.checkCompID(cinfo, id) === true)
					cinfo.setMin(min);
			});
			this.m_Validator.setMin(min);
		}
	}

	/**
	 * 最大値設定
	 * @param max 最大値
	 * @param id コントロールID
	 */
	public setMax(max: number | string, id?: string) {
		this.m_Status.max = max;
		if (this.m_IsLayout == false) {
			this.m_ComponentList.forEach(cinfo => {
				if (this.checkCompID(cinfo, id) === true)
					cinfo.setMax(max);
			});
			this.m_Validator.setMax(max);
		}
	}

	/**
	 * スタイル設定
	 * @param style スタイル
	 * @param id コントロールID
	 */
	public setStyle(style: any, id?: string): void {
		if (this.m_IsDispAuth === false)
			return;
		const status = this.getStatusInfo(id);
		status.style = style;
		if (this.m_IsLayout == false) {
			this.m_ComponentList.forEach(cinfo => {
				if (this.checkCompID(cinfo, id) === true)
					cinfo.setStyle(style);
			});
		}
		else if (this.m_LayoutComponent) {
			this.m_LayoutComponent.setStyle(style);
		}
	}

	/**
	 * placeholder設定
	 * @param placeholder placeholder値
	 * @param id コントロールID
	 */
	public setPlaceholder(placeholder: string, id?: string): void {
		if (this.m_IsDispAuth === false)
			return;
		const status = this.getStatusInfo(id);
		status.placeholder = placeholder;
		this.m_ComponentList.forEach(cinfo => {
			if (this.checkCompID(cinfo, id) === true)
				cinfo.setPlaceholder(placeholder);
		});
	}

	/**
	 * 入力エラー設定
	 * @param name コントロール名
	 * @param errmsg エラーメッセージ
	 */
	public setValidError(name: string, errmsg: string) {
		if (this.m_ValidError == null)
			this.m_ValidError = new WprValidError(this.m_ViewCore);
		this.m_Status.inputError = true;
		this.m_ValidError.addErrorInfo(this, new WprValidErrorInfo(this.name, this.description, this.rowIndex, 'custom', null, errmsg));
		if (!this.m_InValidList)
			this.m_InValidList = new Array();
		this.m_InValidList.push(new WprInvalidInfo(name, null, errmsg));
		/*
		this.m_ComponentList.forEach(cinfo => {
			cinfo.setValue(this.m_Value);
		});
		*/
		this.setInvalid();
		this.m_ComponentList.forEach(cinfo => {
			this.setClassName(cinfo);
		});
	}

	/**
	 * 入力エラークリア処理
	 * @param name コントロール名
	 * @param errmsg エラーメッセージ
	 */
	public clearValidError(name: string, errmsg: string) {
		if (this.m_ValidError != null) {
			if (this.m_ValidError.removeErrorInfo(errmsg) === true)
				this.m_ValidError = null;
		}
		if (this.m_InValidList) {
			let removeList = new Array();
			for(const einfo of this.m_InValidList) {
				if (einfo.errorMessage === errmsg)
					removeList.push(einfo);
			}
			for(const remove of removeList) {
				const idx = this.m_InValidList.indexOf(remove);
				this.m_InValidList.splice(idx, 1);
				if (this.m_InValidList.length === 0)
					this.m_InValidList = null;
			}
			this.setInvalid();
		}
		this.m_ComponentList.forEach(cinfo => {
			this.setClassName(cinfo);
		});
	}

	/**
	 * ValueMapリセット処理
	 * @param clearValue 値クリアフラグ
	 */
	public resetValueMap(clearValue: boolean=true) {
		if (clearValue === true)
			this.value = null;
		this.m_Refresh++;
		this.m_ComponentList.forEach(cinfo => {
			if (cinfo.isMount === true)
				cinfo.setRefresh(this.m_Refresh);
		});
	}

	/**
	 * 入力チェック処理
	 * @param value 入力値
	 * @returns falseの場合、入力無効
	 */
	public checkInput(value: string): boolean {
		this.resetValidator();
		if (this.m_Validator != null)
			return this.m_Validator.checkInput(value);
		return true;
	}

	/**
	 * 入力チェック処理
	 * @returns falseの場合、入力チェックは行なわない
	 */
	public useInputCheck(): boolean {
		this.resetValidator();
		if (this.m_Validator != null)
			return this.m_Validator.useInputCheck();
		return false;
	}

	/**
	 * 文字列長チェック
	 * @param val 文字列
	 * @returns falseの場合、maxLengthより大きい
	 */
	public checkLength(val: string) {
		if (this.m_Config.maxLength && val.length > this.m_Config.maxLength)
			return false;
		return true;
	}

	/**
	 * コンポーネントモード更新処理
	 */
	public updateComponentMode(): void {
		this.setDisabled(this.status.disabled);
	}

	/**
	 * 値クリア処理
	 * @param value クリアに使用する値 
	 */
	public clearValue(value: string = ''): void {
		this.dirty = false;
		this.value = value;
	}

	/**
	 * 強制再表示処理
	 */
	public refresh(): void {
		this.m_ComponentList.forEach(cinfo => {
			if (cinfo.isMount === true)
				cinfo.forceUpdate();
		});
	}

	/**
	 * エラーメッセージ設定
	 * @param errorMsg エラーメッセージ
	 */
	public setErrorMessage(errorMsg: string): void {
		this.m_ErrorMessageList.push(errorMsg);
		this.m_ComponentList.forEach(cinfo => {
			cinfo.setErrorMessage(this.m_ErrorMessageList);
		});
	}

	/**
	 * エラーメッセージクリア
	 * @param errorMsg エラーメッセージ
	 */
	 public clearErrorMessage(errorMsg?: string): void {
		if (errorMsg) {
			const idx = this.m_ErrorMessageList.indexOf(errorMsg);
			this.m_ErrorMessageList.splice(idx, 1);
		}
		else {
			this.m_ErrorMessageList = new Array();
		}
		this.m_ComponentList.forEach(cinfo => {
			 cinfo.setErrorMessage(this.m_ErrorMessageList);
		});
	}

	/**
	 * HTML要素取得
	 * @returns HTML要素 
	 */
	public getElement(): any {
		let element = null;
		if (this.m_IsLayout === false) {
			this.m_ComponentList.forEach(cinfo => {
				if (element == null)
					element = cinfo.ref.current;
			});
		}
		else if (this.m_LayoutComponent) {
			element = this.m_LayoutComponent.ref.current;
		}
		return element;
	}

	/**
	 * 要素矩形情報取得
	 * @returns 要素矩形情報
	 */
	public getRect(): DOMRect {
		const element =  this.getElement();
		if (element != null)
			return element.getBoundingClientRect();
		return null;
	}

	/**
	 * サブモデル名設定処理
	 * @param modelName モデル名
	 */
	public setSubModel(modelName: string): void {
		if (modelName) {
			this.m_ConfigModel = modelName;
			modelName.split('.').forEach((model, index) => {
				if (index > 0) {
					if (this.m_SubModelList == null)
						this.m_SubModelList = new Array();
					this.m_SubModelList.push(model);
				}
			});
		}
	}

	/**
	 * フォーカス設定処理
	 */
	public setFocus(): void {
		if (this.m_IsLayout === true) {
			this.m_ViewCore.addErrorLog(`レイアウトコンポーネントは、フォーカスを設定できません。[${this.m_Name}]`);
		}
		else {
			this.m_ComponentList.forEach(cinfo => {
				cinfo.setFocus();
			});
		}
	}

	/**
	 * 変換値設定
	 * @param viewValue 表示値
	 * @param editValue 編集値
	 */
	public setConvertValue(viewValue: string, editValue: string) {
		this.m_ViewValue = viewValue;
		this.m_EditValue = editValue;
	}

	/**
	 * 権限設定処理
	 */
	 public setCustumAuth() {
		const customAuth = new WprControlAuthInfo();
		this.viewCore.onSetAuth(this.name, customAuth);
		if (customAuth.isReadOnly != null) {
			if (this.m_SaveAuthInfo.isReadOnly == null)
				this.m_SaveAuthInfo.isReadOnly = this.m_Status.readOnly;
			this.setReadOnly(customAuth.isReadOnly);
		}
		else if (this.m_SaveAuthInfo.isReadOnly != null) {
			this.setReadOnly(this.m_SaveAuthInfo.isReadOnly);
			this.m_SaveAuthInfo.isReadOnly = null;
		}
		if (customAuth.isEnabled != null) {
			if (this.m_SaveAuthInfo.isEnabled == null)
				this.m_SaveAuthInfo.isEnabled = !this.m_Status.disabled;
			this.setDisabled(!customAuth.isEnabled);
		}
		else if (this.m_SaveAuthInfo.isEnabled != null) {
			this.setDisabled(!this.m_SaveAuthInfo.isEnabled);
			this.m_SaveAuthInfo.isEnabled = null;
		}
		if (customAuth.styleClass != null)
			this.authClass = customAuth.styleClass;
		else
			this.authClass = null;
	}

	/**
	 * 権限更新処理
	 */
	public updateAuth(): void {
		if (this.m_Config.dispAuthList != null) {
			if (this.FW.auth.checkUserAuthority(this.m_Config.dispAuthList) === false)
				this.m_IsDispAuth = false;
			else
				this.m_IsDispAuth = true;
			this.m_ComponentList.forEach(cinfo => {
				cinfo.setVisibility(this.m_IsDispAuth, this.status.display);
			});
		}
		if (this.m_Config.enableAuthList != null) {
			if (this.FW.auth.checkUserAuthority(this.m_Config.enableAuthList) === false)
				this.m_IsEnableAuth = false;
			else
				this.m_IsEnableAuth = true;
			this.m_ComponentList.forEach(cinfo => {
				cinfo.setDisabled(!this.m_IsEnableAuth);
			});
		}
	}

	/**
	 * バリデーション処理
	 */
	public validValue(): void {
		this.resetValidator();
		if (this.m_Validator != null && this.m_Dirty === true)
			this.m_InValidList = this.m_Validator.checkValue(this.m_Value);
		else
			this.m_InValidList = null;
		this.setInvalid();
	}

	/**
	 * フォーカスOUT処理
	 * @param event フォーカスOUTイベント
	 */
	public onFocus(): void {
		this.m_FocusValue = this.m_Value;
	}

	/**
	 * フォーカス中変更フラグ取得
	 * @returns trueの場合、フォーカス中に変更があった
	 */
	public getIsBlurChange(): boolean {
		if (this.m_FocusValue !== this.m_Value)
			return true;
		return false;
	}
	// --------------------------------------------------------------------------
 
	// private メソッド  ---------------------------------------------------------
	/**
	 * 値設定処理
	 * @param value 値
	 */
	private setValue(value: any): void {
		this.m_ViewValue = value;
		this.m_EditValue = value;
		if (this.m_Config.converter != null) {
			this.m_ViewValue = this.m_Config.converter.convertView(value);
			this.m_EditValue = this.m_Config.converter.convertEdit(value);
		}
		if (this.m_Value !== value) {
			this.m_Value = value;
			this.validValue();
			this.m_ComponentList.forEach(cinfo => {
				if (WprControlInfo.isReadonlyRefresh === true || WprControlInfo.isChangeValue === false || cinfo.isEdit === true) {
					if (WprControlInfo.isReadonlyRefresh === false || cinfo.isEdit === false) {
						cinfo.setValue(value, this.m_EditValue, this.m_ViewValue);
						this.setClassName(cinfo);
					}
				}
			});
		}
		else {
			this.m_ComponentList.forEach(cinfo => {
				if (WprControlInfo.isReadonlyRefresh === true || WprControlInfo.isChangeValue === false || cinfo.isEdit === true) {
					if (WprControlInfo.isReadonlyRefresh === false || cinfo.isEdit === false || this.m_Config.converter != null) {
						cinfo.updateValue(value, this.m_EditValue, this.m_ViewValue, this.m_Config.converter);
					}
				}
			});
		}
	}

	/**
	 * 変更フラグ設定処理
	 * @param dirty 値
	 */
	private setDirty(dirty: boolean): void {
		if (this.m_Dirty !== dirty) {
			this.m_Dirty = dirty;
			this.validValue();
			this.m_ComponentList.forEach(cinfo => {
				cinfo.setDirty(dirty);
				this.setClassName(cinfo);
			});
		}
	}

	/**
	 * 読取専用フラグ取得
	 * @returns 読取専用フラグ
	 */
	public isReadOnly(): boolean {
		for(const cinfo of this.m_ComponentList) {
			if (cinfo.isEdit === true)
				return false;
		}
		return true;
	}

	/**
	 * モデル名設定処理
	 * @param modelName モデル名
	 */
	private setModelName(modelName: string): void {
		if (modelName) {
			if (modelName.includes('.') === false) {
				this.m_ModelName = modelName;
				this.m_ConfigModel = modelName;
			}
			else {
				modelName.split('.').forEach(model => {
					if (this.m_ModelName == null) {
						this.m_ModelName = model;
						this.m_ConfigModel = model;
					}
					else {
						if (this.m_SubModelList == null)
							this.m_SubModelList = new Array();
						this.m_SubModelList.push(model);
						this.m_ConfigModel += '.' + model;
					}
				});
			}
		}
	}
	
	/**
	 * バリデーションエラー設定
	 */
	private setInvalid(): void {
		let invalid = false;
		if (this.m_InValidList != null)
			invalid = true;
		if (this.m_IsInvalid != invalid) {
			this.m_IsInvalid = invalid;
			this.m_ComponentList.forEach(cinfo => {
				cinfo.setInvalid(invalid);
			});
		}
	}

	/**
	 * エラー情報設定
	 * @param error エラー情報
	 */
	private setErrorInfo(error: WprValidError): void {
		this.m_ValidError = error;
		if (error === null)
			this.m_ErrorMessageList = new Array();
		this.m_ComponentList.forEach(cinfo => {
			this.setClassName(cinfo);
			cinfo.setErrorMessage(this.m_ErrorMessageList);
		});
//		this.m_ErrorMessageList = new Array();
	}

	/**
	 * クラス名設定
	 * @param cinfo コンポーネント情報 
	 */
	private setClassName(cinfo: WprBaseControlComponent<any, any>): void {
		let cls = null;
		if (this.m_ValidError || this.m_ErrorMessageList.length > 0)
			cls = this.m_Config.errorClass;
		else if (this.m_Dirty === true || this.value)
			cls = this.m_Config.editClass;
		else
			cls = this.m_Config.noEditClass;
		if (this.m_Status.readOnly)
			cls = this.m_Config.readOnlyClass;
		if (this.m_Status.disabled)
			cls = this.m_Config.disabledClass;
//		if (cls != null)		// errorを消すためには、nullも設定する
			cinfo.setClass(cls);
	}

	/**
	 * 表示状態取得
	 * @returns trueの場合表示
	 */
	private isDisplay(): boolean {
		let isDisp = false;
		this.m_ComponentList.forEach(cinfo => {
			if (cinfo.isDisplay() == true)
				isDisp = true;
		});
		return isDisp;
	}

	/**
	 * 入力状態取得
	 */
	public isInput(): boolean {
		let isInput = false;
		if (this.isLayout === false && this.isReadOnly() === false) {
			if (this.m_Status.readOnly === false && this.m_Status.disabled === false)
				return true;
		}
		return true;
	}

	/**
	 * バリデータリセット処理
	 */
	private resetValidator(): void {
		if (this.m_Validator !== null) {
			if (this.m_Status.hissu === true)
				this.m_Validator.addValidator('必須');
			else
				this.m_Validator.deleteValidator('必須');
		}
		else if (this.m_Status.hissu === true) {
			this.m_Validator = new WprControlValidator(this.description);
			this.m_Validator.addValidator('必須');
		}
	}

	/**
	 * ステータス情報取得
	 * @param id コントロールID
	 * @returns ステータス情報
	 */
	private getStatusInfo(id?: string): WprControlStatus {
		if (id && this.m_StatusMap.has(id) === true)
			return this.m_StatusMap.get(id);
		return this.status;
	}

	/**
	 * コンポーネントIDチェック
	 * @param comp コンポーネント
	 * @param id コントロールID
	 * @returns trueの場合、IDが一致している
	 */
	private checkCompID(comp: WprBaseControlComponent<any, any>, id?: string): boolean {
		if (id && comp.props.id) {
			if (comp.props.id === id)
				return true;
			return false;
		}
		return true;
	}
	// --------------------------------------------------------------------------
}