/**
 * ItscomHomeオプションモデル
 */
export class ItscomHomeOptionListModel {
	// public 変数	-----------------------------------------------------------
	public option: string;		// オプション
	public rental: string;		// 月額レンタル料
	public quantity1: string;	// 数量1
	public rentalUnit: string;	// 個数単位(レンタル)
	public price: string;		// 購入価格
	public priceUnit: string;	// 購入単位(購入)
	public quantity2: string;	// 数量2
	public buyUnit: string;		// 個数単位(購入)
	public buyFlag: boolean;	// 購入フラグ
	public ownerIhFlag: boolean;// オーナー契約IHフラグ
	public childCd: string;		// サービスコード(子)
	public parentCd: string;    // サービスコード(親)
	// ------------------------------------------------------------------------
}
