import { WprValueMap } from '../../../../wpr-framework/model/WprValueMap';
import { WprValueMapSetter } from '../../../../wpr-framework/model/WprValueMapSetter';

/**
 * ガスご契約種別
 */
export class GasContractTypeValueMap {
	// static メソッド  --------------------------------------------------------
	public static addValueMap(vmSetter: WprValueMapSetter): void {
		let vm = new WprValueMap();
		vm.addValue('一般プラン', '一般プラン');
		vm.addValue('エコ給湯器プラン', 'エコ給湯器プラン');
		vm.addValue('床暖房プラン', '床暖房プラン');
		vmSetter.addValueMap('gasContractType', vm);
	}
	// --------------------------------------------------------------------------
}
