/**
 * 工事内容情報
 */
export class TerminalList {
	// public 変数	-----------------------------------------------------------
	public stock_type_cd: string;			// 工事内容コード
	public terminal_type_cd: string;			// 工事内容コード
	public terminal_id: string;			// 工事内容コード
	public terminal_id_2: string;			// 工事内容コード
	public terminal_id_3: string;			// 工事内容コード
	public terminal_id_4: string;			// 工事内容コード
	// ------------------------------------------------------------------------
}
