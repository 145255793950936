/**
 * Key-Valueクラス
 */
export class WprKeyValue {
	// private 変数	-----------------------------------------------------------
	private m_Key: any		= null;		// キー
	private m_Value: string	= null;		// 名前
	// ------------------------------------------------------------------------

	// コンストラクタ	--------------------------------------------------------
	public constructor(key: any, value: string) {
		this.m_Key = key;
		this.m_Value = value;
	}
	// ------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** キー */
	public get key(): any 		{ return this.m_Key; 	}
	/** 名前 */
	public get value(): string 	{ return this.m_Value; 	}
	// --------------------------------------------------------------------------
}
