import { WprBaseViewCore } from '../view/WprBaseViewCore';
import { WprBaseObject } from '../WprBaseObject';

/**
 * デコレータ情報マップ
 */
export class WprDecoInfoMap {
	// private 変数  ------------------------------------------------------------
	private m_PropertyNameMap: Map<object, Map<string, string>>	= new Map<object, Map<string, string>>();	// プロパティ名称マップ
	// --------------------------------------------------------------------------

	// public メソッド	----------------------------------------------------------
	/**
	 * モデル情報追加
	 * @param obj 対象オブジェクト
	 * @param modelName モデル名
	 * @param props プロパティ名
	 */
	public addDecoProperty(obj: WprBaseObject, modelName: string, props: string): void {
		const cobj = obj.constructor;
		if (this.m_PropertyNameMap.has(cobj) === false)
			this.m_PropertyNameMap.set(cobj, new Map<string, string>());
		if (this.m_PropertyNameMap.get(cobj).has(modelName) === false)
			this.m_PropertyNameMap.get(cobj).set(modelName, props);
		else
			obj.addErrorLog(`同一の@WprModelが既に登録されています。[${modelName}]`);
	}

	/**
	 * プロパティマップ取得
	 * @param obj 対象オブジェクト
	 */
	public getPropertyMap(obj: WprBaseObject): Map<string, string> {
		const cobj = obj.constructor;
		if (this.m_PropertyNameMap.has(cobj) === true) {
			const map = this.m_PropertyNameMap.get(cobj);
			return map;
		}
		return new Map<string, string>();
	}
	// --------------------------------------------------------------------------
}