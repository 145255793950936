import { ProgressBarModel } from '../../../models/ProgressBarModel';
import { MypageBaseView } from '../../base/MypageBaseView';
import { ProgressBarData } from '../../common/progress_bar/ProgressBarView';
import { WprModel } from '../../../../wpr-framework/view/deco/WprModel';
import { WprDI_ScopeModel } from '../../../../wpr-framework/view/di/WprDI_ScopeModel';
import { MDisplay } from '../../../service/models/entity/secondary/MDisplay';
import { MypageWizardMngr } from '../../wizard/models/MypageWizardMngr';
import { VideoService } from '../../../service/VideoService';
import { GetIdpRequest } from '../../../service/models/dto/mypagerenewal/GetIdpRequest';
import { IdpInfoModel } from '../../../service/models/apimodels/IdpInfoModel';
import MypageCodeTypeConstants from '../../../common/MypageCodeTypeConstants';
import MypageDispdivcdConstants from '../../../common/MypageDispdivcdConstants';

/**
 * 動画配信,IP-STB申込確認画面
 */
export class VideoIpstbComfirmView extends MypageBaseView {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() { super('VideoIpstbComfirmView'); }
	// --------------------------------------------------------------------------

	// モデル  ------------------------------------------------------------------
	@WprModel('ServiceModel')
	private m_ServiceModel: string	= null;	// 金額
	@WprDI_ScopeModel('MypageWizardMngr')
	private m_MypageWizardMngr: MypageWizardMngr			= null;		// 画面ウィザード管理クラス
	// --------------------------------------------------------------------------

	// メンバ変数  --------------------------------------------------------------
	private m_idpInfo: IdpInfoModel	= null;	// 
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * コントロール定義設定
	 */
	public onRegisterConfig(): void {
		this.addConfig('amount', '金額', 'ServiceModel', { bindModel: true, converter: '3桁カンマ' });
	}

	/**
	 * アクション登録
	 */
	public onRegisterAction(): void {
		this.addAction('next', this.onNext);
		this.addAction('back', this.onBack);
	}
	
	/**
	 * ビュー表示通知
	 */
	public onShowView(): void {
		this.m_MypageWizardMngr.initView(this, (result) => {
			this.registAccessLog();
			this.setStorageItem('b_url', window.location.href)
			const sendData: ProgressBarData = new ProgressBarData();
			sendData.progressBarList = this.m_MypageWizardMngr.getProgressModelList(this);
			this.sendChildData('fP_ev_progressBarIntital', sendData);

			const serviceList = this.m_MypageWizardMngr.getMDisplayList(this, MypageDispdivcdConstants.VIDEO_SERVICE,'');
			const filter = this.m_MypageWizardMngr.getMCommonList(this, 'srvidp', 'ip_stb_code', null, null);
			let data = null;
			if (serviceList && filter)
				data = serviceList.filter(value => value.namecd ===  filter[0].commonValue);
			if (data && !this.m_ServiceModel) {
				this.m_ServiceModel = this.m_MypageWizardMngr.getTaxFee(this, data[0].dispnamep);
				this.refreshView();
			}
		});
	}
	// --------------------------------------------------------------------------

	// アクション  ---------------------------------------------------------------
	/**
	 * 申込む
	 */
	public onNext(param: any): void {
		this.m_MypageWizardMngr.goNext(this);
	}

	/**
	 * 戻る
	 */
	public onBack(param: any): void {
		this.m_MypageWizardMngr.goBack(this);
	}
	// --------------------------------------------------------------------------
	}
