import { MypageBaseView } from '../../../base/MypageBaseView';
import { WprListData } from '../../../../../wpr-framework/view/deco/WprListData';
import { ProgressBarData } from '../../../common/progress_bar/ProgressBarView';
import { WprModel } from '../../../../../wpr-framework/view/deco/WprModel';
import { MypageWizardMngr } from '../../../wizard/models/MypageWizardMngr';
import { WprDI_ScopeModel } from '../../../../../wpr-framework/view/di/WprDI_ScopeModel';
import { WprViewMode } from '../../../../../wpr-framework/view/deco/WprViewMode';
import { OptionListMode } from './mode/OptionListMode';
import { AddServiceModel } from '../../../../service/models/apimodels/AddServiceModel';
import { GetSimulationRequest } from '../../../../service/models/dto/mypagerenewal/GetSimulationRequest';
import { ItscomHomeOptionListModel } from '../../../../models/mypage/smart/ItscomHomeOptionListModel';
import { BaseInfo } from '../../../../models/mypage/smart/BaseInfo';

/**
 * ITSCOMHOME_追加申込み(申し込み内容の確認)
 */
export class ItscomHomeComfirmView extends MypageBaseView {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() { super('ItscomHomeComfirmView'); }
	// --------------------------------------------------------------------------

	// モデル  ------------------------------------------------------------------
	@WprDI_ScopeModel('MypageWizardMngr')
	private m_MypageWizardMngr: MypageWizardMngr					= null;		// 画面ウィザード管理クラス
	@WprListData('OptionListModel')
	private m_OptionListModel: ItscomHomeOptionListModel[]			= null;		// オプションモデル
	@WprListData('OptionComfirmListModel')
	private m_OptionComfirmListModel: ItscomHomeOptionListModel[]	= null;		// オプション確認モデル
	@WprModel('BaseInfo')
	private m_BaseInfo: BaseInfo									= null;		// 基本情報
	// --------------------------------------------------------------------------

	// サブビュー/モード  --------------------------------------------------------
	@WprViewMode
	private m_OptionListMode = new OptionListMode();	// オプションリストモード
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * コントロール定義設定
	 */
	public onRegisterConfig(): void {
		this.addConfig('amount', '金額', 'BaseInfo.courseList', { converter: '3桁カンマ' });
		this.addConfig('course', 'コース', 'BaseInfo.courseList');
		this.addConfig('terminalType', '端末タイプ', 'BaseInfo');
		this.addListConfig('option', 'オプション', 'OptionComfirmListModel');
		this.addListConfig('quantity1', '数量1', 'OptionComfirmListModel');
		this.addListConfig('quantity2', '数量2', 'OptionComfirmListModel');
		this.addListConfig('rentalUnit', '数量単位(レンタル)', 'OptionComfirmListModel');
		this.addListConfig('buyUnit', '数量単位(購入)', 'OptionComfirmListModel');
		this.addListConfig('withRental', 'レンタルあり', 'OptionComfirmListModel');
		this.addListConfig('withoutRental', 'レンタルなし', 'OptionComfirmListModel');
		this.addListConfig('withBuy', '購入あり', 'OptionComfirmListModel');
		this.addListConfig('withoutBuy', '購入なし', 'OptionComfirmListModel');
		this.addConfig('beforeFee', '変更前料金', 'BaseInfo', { converter: '3桁カンマ' });
		this.addConfig('afterFee', '変更後料金', 'BaseInfo', { converter: '3桁カンマ' });
		this.addConfig('totalBuyFee', '購入金額の合計', 'BaseInfo', { converter: '3桁カンマ' });
		this.addConfig('otokuName', 'お得パック名', 'BaseInfo');
	}

	/**
	 * アクション登録
	 */
	public onRegisterAction(): void {
		this.addAction('next', this.onNext);
		this.addAction('back', this.onBack);
	}

	/**
	 * ビュー表示通知
	 */
	public onShowView(): void {
		this.m_MypageWizardMngr.initView(this, (result) => {
			this.registAccessLog();
			this.setStorageItem('b_url', window.location.href)
			const sendData: ProgressBarData = new ProgressBarData();
			sendData.progressBarList = this.m_MypageWizardMngr.getProgressModelList(this);
			this.sendChildData('fP_ev_progressBarIntital', sendData);
			// 基本情報
			this.m_BaseInfo = this.m_MypageWizardMngr.getCache().m_BaseInfo;
			this.setFeeArea();
			// オプション
			this.m_OptionListModel = this.m_MypageWizardMngr.getCache().m_OptionListModel;
			this.m_OptionComfirmListModel = this.m_OptionListModel.filter(row => row.quantity1 || row.quantity2);	// 選択オプションのみ絞込み
			this.setListData('OptionComfirmListModel', this.m_OptionComfirmListModel);
			if (this.m_OptionComfirmListModel.length === 0)
				this.setViewMode('オプション','なし');
			// 料金
			this.paymentSimulation();
			this.calcBuyFee();
			this.refreshView();
		});
	}
	// --------------------------------------------------------------------------

	// アクション  ---------------------------------------------------------------
	/**
	 * 次へ
	 */
	public onNext(param: any): void {
		this.refreshModel();
		this.m_MypageWizardMngr.goNext(this);
	}

	/**
	 * 戻る
	 */
	public onBack(param: any): void {
		this.m_MypageWizardMngr.goBack(this);
	}
	// --------------------------------------------------------------------------
	// privateメソッド  ----------------------------------------------------------
	/**
	 * 料金シミュレーション
	 * ※レンタルのみ計算対象(コース&オプション)
	 */
	private paymentSimulation() {
		const req: GetSimulationRequest = new GetSimulationRequest();
		req.customerId = this.m_MypageWizardMngr.getParams().customerId;
		req.addServiceList = new Array();	// 追加サービスモデルリスト
		// オプション
		for (const optionList of this.m_OptionListModel) {
			if (optionList.quantity1)
				for (let i = 1; i <= Number(optionList.quantity1); i++) {	// 数量分詰める
					const rentalService = new AddServiceModel;
					rentalService.serviceCd = optionList.childCd;	// サービスコード(子) ※IHのみ
					rentalService.rentalFlg = '1';
					req.addServiceList.push(rentalService);
				}
		}
		// コース
		const rentalService = new AddServiceModel;
		rentalService.serviceCd = this.m_BaseInfo.courseList.serviceCd;
		if (this.m_BaseInfo.rental) {
			rentalService.rentalFlg = '1';
			req.addServiceList.push(rentalService);
		}
		if (this.m_BaseInfo.purchase) {
			rentalService.rentalFlg = '0';
			req.addServiceList.push(rentalService);
		}
		this.m_CommonService.getPaymentSimulation(this, req, (result) => {
			if (result) {
				this.m_BaseInfo.beforeFee = result.breforeFee;
				this.m_BaseInfo.afterFee = result.afterFee;
			}
			this.refreshModel();
		});
	}

	/**
	 * 購入金額の合算
	 */
	private calcBuyFee() {
		// コース
		let total: number = 0;
		const courseBuyFeeList = this.m_MypageWizardMngr.getMCommonList(this, 'srvsmart', 'srv_smart_kind', null, null);	// コース毎、購入金額
		if (this.m_BaseInfo.purchase) {
			if (this.m_BaseInfo.courseList.serviceCd === '1201000001') {	// IH
				for (const list of courseBuyFeeList) {
					if (list.category3 === 'srv_i_home_amo') {
						total = Number(this.m_MypageWizardMngr.getTaxFee(this, list.commonValue));
						break;
					}
				}
			}
			else if (this.m_BaseInfo.courseList.serviceCd === '1251000001') { // TV
				for (const list of courseBuyFeeList) {
					if (list.category3 === 'srv_tv_push_amo') {
						total = Number(this.m_MypageWizardMngr.getTaxFee(this, list.commonValue));
						break;
					}
				}
			}
		}
		// オプション
		const buyOptionList = this.m_OptionListModel.filter(row => row.quantity2);
		let totalOption = null;
		if (buyOptionList.length > 0) {
			for (const list of buyOptionList) {
				for (let i = 1; i <= Number(list.quantity2); i++)
					total += Number(list.price);
			}
		}
		// 購入金額合計
		this.m_BaseInfo.totalBuyFee = String(total);
		this.refreshModel();
	}

	/**
	 * 各種エリア表示判定
	 */
	private setFeeArea() {
		// 端末タイプ設定
		if (this.m_BaseInfo.rental) 
			this.m_BaseInfo.terminalType = 'レンタル';
		else 
			this.m_BaseInfo.terminalType = '購入';
		// 月額エリア
		if (this.m_BaseInfo.yesOtoku || this.m_BaseInfo.ownerIhflag)
			this.setViewMode('新月額利用料','※');
		else if (this.m_BaseInfo.otokuFlag) {
			this.setViewMode('新月額利用料','※');
			this.setViewMode('お得パック','加入済み');
		}
		else
			this.setViewMode('新月額利用料','料金');
	}
	// --------------------------------------------------------------------------
}
