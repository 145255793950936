import { WprValueMap } from '../../../../wpr-framework/model/WprValueMap';
import { WprValueMapSetter } from '../../../../wpr-framework/model/WprValueMapSetter';

/**
 * MVNO希望時間帯
 */
export class MvnoRequestTimeValueMap {
	// static メソッド  --------------------------------------------------------
	public static addValueMap(vmSetter: WprValueMapSetter): void {
		let vm = new WprValueMap();
		vm.addValue('10-12時', '10-12時');
		vm.addValue('13-15時', '13-15時');
		vm.addValue('15-17時', '15-17時');
		vmSetter.addValueMap('mvnoRequestTime', vm);
	}
	// --------------------------------------------------------------------------
}
