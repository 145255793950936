import { WprModel } from '../../../../../wpr-framework/view/deco/WprModel';
import { WprDI_ScopeModel } from '../../../../../wpr-framework/view/di/WprDI_ScopeModel';
import MypageDispdivcdConstants from '../../../../common/MypageDispdivcdConstants';
import { TvBaseModel } from '../../../../models/mypage/tv/TvBaseModel';
import { TvOptionListModel } from '../../../../models/mypage/tv/TvOptionListModel';
import { MypageBaseView } from '../../../base/MypageBaseView';
import { CompleteModel } from '../../../common/common_complete/models/CompleteModel';
import { MypageWizardMngr } from '../../../wizard/models/MypageWizardMngr';

/**
 * テレビオプション完了
 */
export class TvopCompleteView extends MypageBaseView {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() { super('TvopCompleteView'); }
	// --------------------------------------------------------------------------

	// モデル  ------------------------------------------------------------------
	@WprModel('CompleteModel')
	private m_CompleteModel: CompleteModel	= null;	// 完了情報モデル
	@WprDI_ScopeModel('MypageWizardMngr')
	private m_MypageWizardMngr: MypageWizardMngr			= null;		// 画面ウィザード管理クラス
	@WprModel('TvOptionModel')
	private m_TvOptionModel: TvOptionListModel	= null;	// テレビ基本情報モデル
	// --------------------------------------------------------------------------
	
	// メンバ変数 ---------------------------------------------------------------
	private m_CustomerName: string							= null;	// 顧客名
	private m_TvOptionListModel: TvOptionListModel[]	= null;	// テレビオプションリストモデル
	private m_TvBaseModel: TvBaseModel	= null;	// テレビ基本情報モデル
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * コントロール定義設定
	 */
	public onRegisterConfig(): void {
		this.addConfig('title_complete', 'タイトル', 'CompleteModel', { bindName: 'title' });
		this.addConfig('user', 'ユーザー', 'CompleteModel', { bindName: 'user' });
		this.addConfig('msg1', '本文1', 'CompleteModel', { bindName: 'massage1' });
		this.addConfig('msg2', '本文2', 'CompleteModel', { bindName: 'massage2' });
		this.addConfig('msg3', '本文3', 'CompleteModel', { bindName: 'massage3' });
		this.addConfig('option', 'オプション', 'TvOptionModel');
		this.addConfig('channel', 'チャンネル', 'TvOptionModel');
		this.addConfig('amount', '月額', 'TvOptionModel', { converter: '3桁カンマ' });
		this.addConfig('applyDate', '契約区分 ( 翌月、翌日 )', 'TvOptionModel');
	}

	/**
	 * アクション登録
	 */
	public onRegisterAction(): void {
		this.addAction('toQuestionnaire', this.onToQuestionnaire);
		this.addAction('back', this.onBack);
		this.addAction('next', this.onNext);
	}

	/**
	 * ビュー表示通知
	 */
	public onShowView(): void {
		this.m_MypageWizardMngr.initView(this, (result) => {
			this.registAccessLog();
			this.setStorageItem('b_url', window.location.href);
			//顧客情報
			const customerInfo = this.m_MypageWizardMngr.getViewInfo().customer;
			if (customerInfo.sexCD === '3' || customerInfo.sexCD === '')
				this.m_CustomerName = customerInfo.corporateName;
			else
				this.m_CustomerName = customerInfo.surName + ' ' + customerInfo.givenName;
			//オプション
			this.m_TvBaseModel = this.m_MypageWizardMngr.getCache().m_TvBaseModel;
			this.m_TvOptionListModel = this.m_MypageWizardMngr.getCache().m_TvOptionListModel;
			const channel = this.m_MypageWizardMngr.getMDisplayList(this, MypageDispdivcdConstants.TV_OPTION_CHANNEL, '');
			for (const option of this.m_TvOptionListModel) {
				if (option.select)
					this.m_TvOptionModel = option;
			}
			const url = (window.location.href).split('/');
			const last = url.slice(-1)[0];
			this.setData(last);
			this.refreshView();
		});
		this.refreshView();
	}
	// --------------------------------------------------------------------------

	// アクション  ---------------------------------------------------------------
	/**
	 * アンケート
	 */
	public onToQuestionnaire(param: any): void {
		window.open('https://questant.jp/q/B7KS5E4S?id=' + this.m_MypageWizardMngr.getParams().customerId);
	}

	/**
	 * 契約内容の確認・変更メニューへ
	 */
	public onBack(param: any): void {
		location.href = this.getOldUrl() + 'Srvlist/Continfo_list/';
	}

	/**
	 * 更に変更する         <NavigateNextIcon />
	 */
	public onNext(param: any): void {
		const view = 'add_tvop_select';
		this.m_MypageWizardMngr.setFlow(this, view, (result) => {
			if (result) {
				this.pushPath(view);
			} else {
				this.showLogMessage('viewDefineが定義されていない');
			}
		});
	}
	// --------------------------------------------------------------------------
	
	// privateメソッド -----------------------------------------------------------
	/**
	 * 文章セット
	 */
	private setData(type: string) {
		const model = new CompleteModel();
		if (type === 'add_tvop_complete') {
			model.title = 'お申込みありがとうございます！';
			model.user = this.m_CustomerName + 'さん';
			model.massage1 = 'お申込みいただき、誠にありがとうございます。<br/>' + this.m_TvOptionModel.applyDate + 'の午前6時ごろから視聴開始できます。';
			model.massage2 = '連絡先メールアドレスに確認メールを送信しました。';
			this.setViewMode('詳細表示', '申込み');
			this.setViewMode('help表示', '申込み');
		}
		else if (type === 'add_tvopprov_complete') {
			model.title = 'お申込みありがとうございます！';
			model.user = this.m_CustomerName + 'さん';
			model.massage1 = 'お申込みいただき、誠にありがとうございます。<br/>以下の内容で申込みを受け付けました。';
			model.massage2 = '連絡先メールアドレスに確認メールを送信しました。';
			if (this.m_TvBaseModel.dummy)
				model.massage3 = '端末に記載されているSTB-ID、CASカード番号、またはMACアドレスのご確認をお願いします。';
			this.setViewMode('詳細表示', '申込み');
			this.setViewMode('help表示', '仮申込み');
		}
		else if (type === 'mod_tvop_complete') {
			model.title = '解約手続きが完了しました。';
			model.user = this.m_CustomerName + 'さん';
			model.massage1 = 'オプションチャンネルの解約を受け付けました。';
			model.massage2 = '連絡先メールアドレスに確認メールを送信しました。';
			if (!this.m_TvBaseModel.dummy)
				model.massage3 = '端末に記載されているSTB-ID、CASカード番号、またはMACアドレスのご確認をお願いします。';
			this.setViewMode('詳細表示', '解約');
			this.setViewMode('help表示', '解約');
		}
		this.m_CompleteModel = model;
		
	}
}
