import React, { RefObject } from 'react';
import { WprControlVisibility } from '../props/IWprControlState';
import { IWprLayoutEvents } from '../props/IWprLayoutEvents';
import { IWprNoNameLayoutProps } from '../props/IWprLayoutProps';
import { IWprLayoutState } from '../props/IWprLayoutState';
import { WprBaseComponent } from './WprBaseComponent';

/**
 * レイアウト基本コンポーネント
 */
export abstract class WprBaseNoNameLayoutComponent<T extends IWprNoNameLayoutProps, U> extends WprBaseComponent<T, IWprLayoutState> {
	// private 変数  ------------------------------------------------------------
	private m_Ref: RefObject<any>			= null;		// DOM参照
	private m_Events: IWprLayoutEvents<U>	= {};		// イベント情報
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** DOM参照 */
	public get ref(): RefObject<any> { return this.m_Ref; } public set ref(ref: RefObject<any>) { this.m_Ref = ref;	}
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	constructor(props: T) {
		super(props);
		this.m_Ref = React.createRef();
		this.state = {
			value: null,
			open: true,
			style: null,
			className: null,
			visible: true,
			visibility: WprControlVisibility.VISIBLE,
			selected: false,
			modeClassName: null,
			option1: null,
			option2: null,
			option3: null,
			numOption1: 0,
			numOption2: 0,
			boolOption1: false,
			boolOption2: false
		};
	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * オプション値取得
	 */
	public getOption(): any {
		return null;
	}

	/**
	 * コントロール名称取得
	 * @returns コントロール名称
	 */
	public getName(): string {
		return null;
	}

	/**
	 * フォーカス設定処理
	 */
	public setFocus() {
		this.props.view.addErrorLog('レイアウトコンポーネントは、フォーカスの設定はできません。');
	}
	// --------------------------------------------------------------------------

	// protected メソッド  -------------------------------------------------------
	/**
	 * クラス取得
	 * @param cls 固定クラス 
	 * @returns クラス
	 */
	protected className(cls?: string): string {
		if (!this.props.className && !this.state.className && !cls && !this.state.modeClassName && !this.state.authClassName)
			return cls;
		let className = '';
		if (this.props.className)
			className += this.props.className;
		if (this.state.className) {
			if (className.length > 0)
				className += ' ';
			className += this.state.className;
		}
		if (this.state.modeClassName) {
			if (className.length > 0)
				className += ' ';
			className += this.state.modeClassName;
		}
		if (this.state.authClassName) {
			if (className.length > 0)
				className += ' ';
			className += this.state.authClassName;
		}
		if (cls) {
			if (className.length > 0)
				className += ' ';
			className += cls;
		}
		const local = this.props.view.getLocalClassName(className);
		if (local != null)
			return local;
		return className;
	}

	/**
	 * ステータス情報抽出
	 * @param list 抽出リスト
	 * @returns ステータス情報
	 */
	protected getState(list: string[]): any {
		const rtn: any = {};
		const state: any = this.state;
		list.forEach(name => {
			rtn[name] = state[name];
		});
		return rtn;
	}

	/**
	 * プロパティ情報取得
	 * @param cls オリジナルクラス
	 * @returns プロパティ情報
	 */
	protected getProps(cls?: string, setClassName: boolean = true): any {
		const rtn: any = {};
		rtn['name'] = this.getName();
		if (setClassName === true)
			rtn['className'] = this.className(cls);
		return rtn;
	}
	
	/**
	 * イベント情報取得
	 * @returns イベント情報
	 */
	protected getEvents(): IWprLayoutEvents<U> {
		return this.m_Events;
	}

	/**
	 * クリックイベントハンドラ登録
	 * @param click クリックイベントハンドラ
	 */
	protected setClickEvent(click: (event: React.MouseEvent<U>) => void) {
		this.m_Events.onClick = click;
	}

	/**
	 * クローズイベントハンドラ登録
	 * @param close クリックイベントハンドラ
	 */
	protected setCloseEvent(close: () => void) {
		this.m_Events.onClose = close;
	}

	/**
	 * 値変更通知イベントハンドラ登録
	 * @param changeValue 値変更通知イベントハンドラ
	 */
	protected setChangeValueEvent(changeValue: (event: React.ChangeEvent<U>) => void) {
		this.m_Events.onChange = changeValue;
	}
	// --------------------------------------------------------------------------

	// public メソッド  ----------------------------------------------------------
	/**
	 * 表示状態設定処理
	 * @param visibility 表示状態
	 * @param display 表示状態
	 */
	public setVisibility(visibility: boolean, display: boolean): void {
		if (this.isMount === false)
			return;
		let visible = WprControlVisibility.VISIBLE;
		if (display === false)
			visible = WprControlVisibility.COLLAPSED;
		else if (visibility === false)
			visible = WprControlVisibility.HIDDEN;
		this.setState({
			visibility : visible
		});
	}

	/**
	 * スタイル設定処理
	 * @param style スタイル
	 */
	public setStyle(style: any) {
		if (this.isMount === false)
			return;
		this.setState({
			style: style
		});
	}

	/**
	 * クラス設定処理
	 * @param cls クラス
	 */
	public setClass(cls: any) {
		if (this.isMount === false)
			return;
		this.setState({
			className: cls
		});
	}

	/**
	 * モードクラス設定処理
	 * @param cls クラス
	 */
	public setModeClass(cls: any) {
		if (this.isMount === false)
			return;
		this.setState({
			modeClassName: cls
		});
	}

	/**
	 * 権限クラス設定処理
	 * @param cls クラス
	 */
	 public setAuthClass(cls: any) {
		if (this.isMount === false)
			return;
		this.setState({
			authClassName: cls
		});
	}

	/**
	 * 表示状態取得
	 * @returns trueの場合表示
	 */
	public isDisplay(): boolean  {
		if (this.m_Ref.current != null)
			return this.checkDisplay(this.m_Ref.current);
		return false;
	}
	// --------------------------------------------------------------------------

	// private メソッド  ---------------------------------------------------------
	/**
	 * 表示チェック処理
	 * @param node チェック要素
	 * @returns trueの場合、表示状態
	 */
	private checkDisplay(node: HTMLElement): boolean {
		if (document.defaultView.getComputedStyle(node, null).display === 'none')
			return false;
		if (!node.parentElement)
			return true;
		return this.checkDisplay(node.parentElement);
	}
	// --------------------------------------------------------------------------
}
