import React, { ReactNode } from 'react';
import { WprBaseViewComponent } from '../../../../../wpr-framework/view/component/WprBaseViewComponent';
import { AddTvopSelectView } from './AddTvopSelectView';
import tvIcon from '/shared/image/common/logo_iTSCOMTV.png';
import helpOrangeIcon from '/shared/image/common/icon_Help_bgLigntOrange.png';
import royalIcon from '/shared/image/common/icon_tq_royal.png';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { Grid, TableContainer, Table, TableBody, TableRow, TableCell, Typography, Divider, TableHead } from '@material-ui/core';
import { WprAnchor } from '../../../../../wpr-framework/component/controls/WprAnchor';
import { WprContainer } from '../../../../../wpr-framework/component/controls/WprContainer';
import { WprSpan } from '../../../../../wpr-framework/component/controls/WprSpan';
import { MatButton } from '../../../../../wpr-framework/component/controls/material/MatButton';
import { MatTypography } from '../../../../../wpr-framework/component/controls/material/MatTypography';
import { ProgressBar } from '../../../common/progress_bar/ProgressBar';
import { MatTableContainer } from '../../../../../wpr-framework/component/controls/material/MatTableContainer';
import { MatTableRow } from '../../../../../wpr-framework/component/controls/material/MatTableRow';
import { WprRowInfo } from '../../../../../wpr-framework/component/props/WprRowInfo';
import importantIcon from '/shared/image/common/important_gray.png';
import { WprDiv } from '../../../../../wpr-framework/component/controls/WprDiv';
import styles from './AddTvopSelect.scss';
import errorIcon from '../../../../../../shared/image/common/icon_error.png'

/**
 * テレビオプション申込選択用コンポーネント
 */
export class AddTvopSelect extends WprBaseViewComponent {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: any) { super(new AddTvopSelectView(), props); }
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** テレビオプション申込選択ビュー情報 */
	public get viewInfo(): AddTvopSelectView	{ return this.view as AddTvopSelectView;	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * スタイルオブジェクト取得
	*/
	public getStylesObject(): any { return styles; }

	/**
	 * リストコントロール定義登録
	*/
	protected onRegisterListRender(): void {
		this.addListRender('TvOptionListModel', null, this.onTvOptionListRender);
		this.addListRender('TvOptionListSPModel', null, this.onTvOptionListSPRender);
	}
	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		return (
			<>
			<div>
				<div className='pageCard'>
					<div className="pageHeader stepFlow">
						<ProgressBar name='progressBar' view={this.view} />
					</div>
					<div className='sub-logo'>
						<img src= {tvIcon}></img>
					</div>
					<div className='main'>
						<div className='pageTitle'>
							<div className='title_left'>
								<MatTypography name='title' className='title_text' view={this.view}/>
								<Typography className='title_comment'>ご希望の変更ボタンをクリックしてください。</Typography>
							</div>
						</div>
						<WprContainer mode='エラー.表示' view={this.view}>
							<div className='errorArea'>
								<span className='parallel'>
									<img src={errorIcon} alt='' className='x_img'/>
									<div className='errorList'>
										<Typography>スターチャンネルはご契約月にご解約いただくことができません。</Typography>
										<Typography>ご不明点がございましたらイッツコムカスタマーセンターへご連絡ください。</Typography>
									</div>
								</span>
							</div>
						</WprContainer>
						<WprContainer mode='エラー.オプション' view={this.view}>
							<div className='errorArea'>
								<span className='parallel'>
									<img src={errorIcon} alt='' className='x_img'/>
									<div className='errorList'>
										<Typography>選択されたオプションチャンネルの契約がありません</Typography>
									</div>
								</span>
							</div>
						</WprContainer>
						<div className='title_space'>
							<div className='itemTitle title_maru'>基本情報</div>
						</div>
						<div className='constractTable'>
							<Grid container className='feeRow'>
								<Grid item xs={12} md={5} className='option' style={{padding: '15px'}}>
									<MatTypography name='course' className='courseName' view={this.view}/>
								</Grid>
								<Grid item xs={12} md={7} className='optstatus' style={{padding: '1rem'}}>
									<MatTypography name='contractStatus' className='itemUnit_bold' view={this.view}/>
								</Grid>
							</Grid>	
						</div>
						<WprContainer mode='オプション.PC' view={this.view}>
							<div className='border_around'>
								<TableContainer>
									<Table>
										<colgroup>
											<col style={{width:'33%'}}/>
											<col style={{width:'42%'}}/>
											<col style={{width:'25%'}}/>
										</colgroup>
										<TableBody >
											<TableRow className='deviceRow'>
												<TableCell align='center' className='deviceTitle border_right firstTitle'>
													<Typography variant='body2' className='font_bold'>A-CAS番号</Typography>
												</TableCell>
												<TableCell colSpan={2}>
													<MatTypography name='aCas' view={this.view}/>
												</TableCell>
											</TableRow>
											<TableRow className='deviceRow'>
												<TableCell align='center' className='deviceTitle border_right'>
													<Typography variant='body2' className='font_bold'>B-CAS番号
														<a href='https://www.itscom.co.jp/support/contract_change/option/' target='_blank'><img src={helpOrangeIcon}  alt='' style={{marginLeft: '3px'}}></img></a>
													</Typography>
												</TableCell>
												<TableCell colSpan={2}>
													<MatTypography name='bCas' view={this.view}/>
												</TableCell>
											</TableRow>
											<TableRow className='deviceRow'>
												<TableCell align='center' className='deviceTitle border_right'>
													<Typography variant='body2' className='font_bold'>C-CAS番号
													<a href='https://www.itscom.co.jp/support/contract_change/option/' target='_blank'><img src={helpOrangeIcon} alt='' style={{marginLeft: '3px'}}></img></a>
													</Typography>
												</TableCell>
												<TableCell colSpan={2}>
													<MatTypography name='cCas' view={this.view}/>
												</TableCell>
											</TableRow>
											<TableRow className='deviceRow'>
												<TableCell align='center' className='deviceTitle border_right'>
													<Typography variant='body2' className='font_bold'>端末タイプ</Typography>
												</TableCell> 
												<TableCell colSpan={2}>
													<WprSpan name='terminal' view={this.view}/> 
													<span>　</span>
													<WprSpan name='applyType' view={this.view}/>
												</TableCell>
											</TableRow>
											<TableRow className='deviceRow'>
												<TableCell align='center' className='deviceTitle border_right'>
													<Typography variant='body2' className='font_bold'>iTSCOM magazine</Typography>
												</TableCell>
												<TableCell colSpan={2}>
													<MatTypography name='magazine' view={this.view}/>
												</TableCell>
											</TableRow>
										</TableBody>
									</Table>
								</TableContainer>
							</div>
						</WprContainer>
						{/* SP用 */}
						<WprContainer mode='オプション.SP' view={this.view}>
							<TableContainer>
								<Table className='tableSP'>
									<colgroup>
										<col style={{width:'70%'}}/>
										<col style={{width:'30%'}}/>
									</colgroup>
									<TableBody >
										<TableRow className='deviceRow'>
											<TableCell className='deviceTitle firstTitle' colSpan={2} style={{backgroundColor: '#F5F5FA', borderRadius: '12px 12px 0 0', padding: '10px'}}>
												<Typography variant='body2' className='font_bold'>A-CAS番号</Typography>
											</TableCell>
										</TableRow>
										<TableRow className='deviceRow'>
											<TableCell colSpan={2}>
												<MatTypography name='aCas' view={this.view}/>
											</TableCell>
										</TableRow>
										<TableRow className='deviceRow'>
											<TableCell className='deviceTitle' colSpan={2} style={{backgroundColor: '#F5F5FA', padding: '10px'}}>
												<Typography variant='body2' className='font_bold'>B-CAS番号
													<a href='https://www.itscom.co.jp/support/contract_change/option/' target='_blank'><img src={helpOrangeIcon}  alt='' style={{marginLeft: '3px'}}></img></a>
												</Typography>
											</TableCell>
										</TableRow>
										<TableRow className='deviceRow'>
											<TableCell colSpan={2}>
												<MatTypography name='bCas' view={this.view}/>
											</TableCell>
										</TableRow>
										<TableRow className='deviceRow'>
											<TableCell className='deviceTitle' colSpan={2} style={{backgroundColor: '#F5F5FA', padding: '10px'}}>
												<Typography variant='body2' className='font_bold'>C-CAS番号
												<a href='https://www.itscom.co.jp/support/contract_change/option/' target='_blank'><img src={helpOrangeIcon} alt='' style={{marginLeft: '3px'}}></img></a>
												</Typography>
											</TableCell>
										</TableRow>
										<TableRow className='deviceRow'>
											<TableCell colSpan={2}>
												<MatTypography name='cCas' view={this.view}/>
											</TableCell>
										</TableRow>
										<TableRow className='deviceRow'>
											<TableCell className='deviceTitle' colSpan={2} style={{backgroundColor: '#F5F5FA', padding: '10px'}}>
												<Typography variant='body2' className='font_bold'>端末タイプ</Typography>
											</TableCell> 
										</TableRow>
										<TableRow className='deviceRow'>
											<TableCell colSpan={2}>
												<WprSpan name='terminal' view={this.view}/> 
												<span>　</span>
												<WprSpan name='applyType' view={this.view}/>
											</TableCell>
										</TableRow>
										<TableRow className='deviceRow'>
											<TableCell className='deviceTitle' colSpan={2} style={{backgroundColor: '#F5F5FA', padding: '10px'}}>
												<Typography variant='body2' className='font_bold'>iTSCOM magazine</Typography>
											</TableCell>
										</TableRow>
										<TableRow className='deviceRow'>
											<TableCell colSpan={2} style={{borderBottom: 'none'}}>
												<MatTypography name='magazine' view={this.view}/>
											</TableCell>
										</TableRow>
									</TableBody>
								</Table>
							</TableContainer>
						</WprContainer>
						<div className='title_space' style={{marginBottom: '30px'}}>
							<div className='itemTitle title_maru'>オプションチャンネル</div>
							<Grid container>
								<Grid item xs={12}>
									<MatTableContainer listName={'TvOptionListModel'} view={this.view} noDataUseHeader noDataMessage=' '>
										<WprContainer mode='オプション.PC' view={this.view}>
											<Table className = 'courseTable'> 
												<colgroup>
													<col style={{width: '38%'}}/>
													<col style={{width: '12%'}}/>
													<col style={{width: '30%'}}/>
													<col style={{width: '20%'}}/>
												</colgroup>
												<TableHead className='hedderrow'>
													<TableRow className='toptable'>
														<TableCell align='center' className='border_right font_bold'>テレビオプション名</TableCell>
														<TableCell align='center' className='border_right font_bold'>月額(税込)</TableCell>
														<TableCell align='center' className='border_right font_bold'>契約の状況</TableCell>
														<TableCell align='center' className='font_bold'>申込み／解約</TableCell>
													</TableRow>
												</TableHead>
												<TableBody>
													{this.renderList('TvOptionListModel')}
													<WprContainer mode='オプション.なし' view={this.view}>
														<TableRow>
															<TableCell className='non_list' colSpan={5}>申込み可能なオプションはございません。</TableCell>
														</TableRow>
													</WprContainer>
												</TableBody>
											</Table>
											<WprContainer mode = 'オプション.なし' view={this.view}>
											</WprContainer>
										</WprContainer>
									</MatTableContainer>
									<MatTableContainer listName={'TvOptionListSPModel'} view={this.view} noDataUseHeader noDataMessage=' '>
										<WprContainer mode='オプション.SP' view={this.view}>
											<Table className = 'courseTable'> 
												<colgroup>
													<col style={{width: '45%'}}/>
													<col style={{width: '55%'}}/>
												</colgroup>
												<TableHead className='hedderrow'>
													<TableRow className='toptable'>
														<TableCell align='center' className='border_right font_bold' rowSpan={3}>テレビオプション名</TableCell>
														<TableCell align='center' className='font_bold'>月額(税込)</TableCell>
													</TableRow>
													<TableRow>
														<TableCell align='center' style={{fontWeight: '600'}}>契約の状況</TableCell>
													</TableRow>
													<TableRow>
														<TableCell align='center' style={{fontWeight: '600'}}>申込み／解約</TableCell>
													</TableRow>
												</TableHead>
												<TableBody>
													{this.renderList('TvOptionListSPModel')}
													<WprContainer mode='オプション.なし' view={this.view}>
														<TableRow>
															<TableCell className='non_list' colSpan={5}>申込み可能なオプションはございません。</TableCell>
														</TableRow>
													</WprContainer>
												</TableBody>
											</Table>
											<WprContainer mode = 'オプション.なし' view={this.view}>
											</WprContainer>
										</WprContainer>
									</MatTableContainer>
								</Grid>
							</Grid>
						</div>
						<div className='title_space' style={{marginBottom: '30px'}}>
							<WprContainer mode={'wowow.表示'} view={this.view}>
								<div className='flexTop'>
									<div className='flex'>
										<img src= {importantIcon}></img>
										<Typography variant='body2' className='font_bold'>※１</Typography>
									</div>
									<div style={{marginLeft: '10px'}}>
										<Typography variant='body2' style={{fontWeight:'600'}}>WOWWOWへ直接お問い合わせください。</Typography>
										<Typography variant='body2' style={{fontSize:'12px'}}>お申込み：0120-800-912／営業時間：9:00～21:00 （年中無休）</Typography>
										<Typography variant='body2' style={{fontSize:'12px'}}>ご解約：0120-580-807／営業時間：9:00～20:00 （年中無休）</Typography>
									</div>
								</div>
							</WprContainer>
							<div className='flexTop'>
								<div className='flex'>
									<img src= {importantIcon}></img>
									<Typography variant='body2' className='font_bold'>※２</Typography>
								</div>
								<div style={{marginLeft: '10px'}}>
									<Typography variant='body2' style={{fontWeight:'600'}}>当社お客様センターへ直接お問い合わせください。</Typography>
									<Typography variant='body2' style={{fontSize:'12px'}}>(0120-109-199)</Typography>
								</div>
							</div>
							<WprContainer mode={'変更受付.表示'} view={this.view}>
								<div className='flexTop'>
									<div className='flex'>
										<img src= {importantIcon}></img>
										<Typography variant='body2' className='font_bold'>※３</Typography>
									</div>
									<div style={{marginLeft: '10px'}}>
										<Typography variant='body2' style={{fontWeight:'600'}}>マイページよりご変更を受け付けておりません。</Typography>
										<Typography variant='body2' style={{fontSize:'12px'}}><a href='http://www.itscom.net/support/callcenter/index.html' target = '_blank'>お客様センター</a>へお問い合わせください。</Typography>
									</div>
								</div>
							</WprContainer>
							<div className='gray'>
								※ 本日より視聴をご希望のお客さまは、営業時間内に<a href='http://www.itscom.net/support/callcenter/index.html' target = '_blank'>お客様センター</a>へお電話でお申し込みください。<br/>
								※1 建物の設備状況によっては、「グリーンチャンネル HD」、「グリーンチャンネル2 HD」、「KNTV」をご視聴いただけない場合があります。<br/>
								<WprContainer mode={'ロイヤル会員.表示'} view={this.view}>※2 東急ロイヤルクラブ会員さま向けの優待価格料金です。優待価格の適用は、お電話でのみ承ります。</WprContainer>
								
							</div>
						</div>
					</div>
					<Divider />
					<div className='pageFooter footer-space'>
						<div>
							<MatButton name='back' view={this.view} className='backButton' >
								<NavigateBeforeIcon />
								戻る
							</MatButton>
						</div>
					</div>


				</div>
			</div>
			</>
		);
	}
	// --------------------------------------------------------------------------

	// リスト描画  ---------------------------------------------------------------
	/**
	 * リスト名=OptListModel
	 */
	public onTvOptionListRender(rowInfo: WprRowInfo): any {
		const name = this.getOptionName(rowInfo);
		const amount = this.getAmount(rowInfo);
		const status = this.getStatus(rowInfo);
		return (
			<MatTableRow view={this.view} row={rowInfo}>
				{name}
				<TableCell className='border_right' align='center' style={{ padding: '15px 0'}}>
					{amount}
				</TableCell>
				<TableCell className='border_right' align='center' style={{ padding: '15px 0'}}>
					{status}
				</TableCell>
				<TableCell align='center' style={{ padding: '15px 0'}}>
					<MatButton name='nextDay' variant='text' view={this.view} row={rowInfo} className='dayButton'>翌日開始</MatButton>
					<MatButton name='nextMonth' variant='text' view={this.view} row={rowInfo} className='monthButton'>来月開始</MatButton>
					<MatButton name='nextCancel' variant='text' view={this.view} row={rowInfo} className='cancelButton'>解約</MatButton>
				</TableCell>
			</MatTableRow>
		);
	}

	public onTvOptionListSPRender(rowInfo: WprRowInfo): any {
		const name = this.getOptionNameSP(rowInfo);
		const amount = this.getAmount(rowInfo);
		const status = this.getStatus(rowInfo);
		return (
			<>
				<MatTableRow view={this.view} row={rowInfo} className='tableOptSP'>
					{name}
					<TableCell className='border_right' align='center' style={{ padding: '15px 0'}}>
						{amount}
					</TableCell>
				</MatTableRow>
				<MatTableRow view={this.view} row={rowInfo}>
					<TableCell className='border_right' align='center' style={{ padding: '15px 0'}}>
						{status}
					</TableCell>
				</MatTableRow>
				<MatTableRow view={this.view} row={rowInfo}>
					<TableCell align='center' style={{ padding: '15px 0'}}>
						<MatButton name='nextDaySP' variant='text' view={this.view} row={rowInfo} className='dayButtonSP'>翌日開始</MatButton>
						<MatButton name='nextMonthSP' variant='text' view={this.view} row={rowInfo} className='monthButtonSP'>来月開始</MatButton>
						<MatButton name='nextCancelSP' variant='text' view={this.view} row={rowInfo} className='cancelButtonSP'>解約</MatButton>
					</TableCell>
				</MatTableRow>
			</>
		);
	}

	// --------------------------------------------------------------------------

	// privateメソッド -----------------------------------------------------------
	private getOptionName(rowInfo: WprRowInfo): any {
		let item: any;
		if (rowInfo.rowData.royalFlg) {
			item = (
				<TableCell className='border_right flexTate' style={{ padding: '15px'}}>
					<MatTypography name='option' className='feeUnit' view={this.view} row={rowInfo} style={{fontSize: '14px'}}/>
					<img src= {royalIcon} style={{width: '130px'}}></img>
				</TableCell>
			)
		}
		else {
			item = (
				<TableCell className='border_right' style={{ padding: '15px'}}>
					<MatTypography name='option' className='feeUnit' view={this.view} row={rowInfo} style={{fontSize: '14px'}}/>
				</TableCell>
			)
		}
		return item;
	}
	private getOptionNameSP(rowInfo: WprRowInfo): any {
		let item: any;
		if (rowInfo.rowData.royalFlg) {
			item = (
				<TableCell className='border_right blueLabel' style={{ padding: '15px'}} rowSpan={3}>
					<MatTypography name='option' className='feeUnit' view={this.view} row={rowInfo} style={{fontSize: '14px'}}/>
					<img src= {royalIcon} style={{width: '130px'}}></img>
				</TableCell>
			)
		}
		else {
			item = (
				<TableCell className='border_right' style={{ padding: '15px'}} rowSpan={3}>
					<MatTypography name='option' className='feeUnit' view={this.view} row={rowInfo} style={{fontSize: '14px'}}/>
				</TableCell>
			)
		}
		return item;
	}

	private getAmount(rowInfo: WprRowInfo): any {
		let item: any;
		if (rowInfo.rowData.wowwowFlg) {
			item = (
				<WprDiv name={'kome1'} view={this.view} className='flex' row={rowInfo}>
					<img src= {importantIcon}></img>
					<Typography variant='body2' className='font_bold' style={{fontSize: '13px'}}>※１</Typography>
				</WprDiv>
			)
		}
		else {
			item = (
				<>
					<MatTypography name='amount' className='font_bold' view={this.view} row={rowInfo} style={{fontSize: '14px'}}></MatTypography>
					<span className='feeUnit'>円</span>
				</>
			)
		}
		return item;
	}

	private getStatus(rowInfo: WprRowInfo): any {
		let item: any;
		let color :string;
		if (rowInfo.rowData.wowwowFlg) {
			item = (
				<WprDiv name={'kome1'} view={this.view} className='flex' row={rowInfo}>
					<img src= {importantIcon}></img>
					<Typography variant='body2' className='font_bold'style={{fontSize: '13px'}}>※１</Typography>
				</WprDiv>
			)
		}
		else if (rowInfo.rowData.tunerFlg || rowInfo.rowData.royalFlg) {
			item = (
				<WprDiv name={'kome2'} view={this.view} className='flex' row={rowInfo}>
					<img src= {importantIcon}></img>
					<Typography variant='body2' className='font_bold'style={{fontSize: '13px'}}>※２</Typography>
				</WprDiv>
			)
		}
		else if (rowInfo.rowData.status === '新規受付は終了いたしました。') {
			item = (<MatTypography name='uncontract' className='font_bold' view={this.view} row={rowInfo} style={{fontSize: '13px'}}/>)
		}
		else {
			if (rowInfo.rowData.statusCd === '0')
				item = (<MatTypography name='status' className='font_bold status' view={this.view} row={rowInfo} style={{fontSize: '13px'}}/>)
			else if ((rowInfo.rowData.statusCd === '1'))
				item = (<MatTypography name='status' className='font_bold status' view={this.view} row={rowInfo} style={{fontSize: '13px', backgroundColor: '#47BE7C', color: 'white'}}/>)
			else if ((rowInfo.rowData.statusCd === '2'))
				item = (<MatTypography name='status' className='font_bold status' view={this.view} row={rowInfo} style={{fontSize: '13px', backgroundColor: '#666666', color: 'white'}}/>)
			else if ((rowInfo.rowData.statusCd === '3'))
				item = (<MatTypography name='status' className='font_bold status' view={this.view} row={rowInfo} style={{fontSize: '13px', backgroundColor: '#666666', color: 'white'}}/>)
			else
				item = (<MatTypography name='status' className='font_bold status' view={this.view} row={rowInfo} style={{fontSize: '13px', backgroundColor: '#E6E6E6'}}/>)

		}
		return item;
	}
	// --------------------------------------------------------------------------
}
