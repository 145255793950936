import { Tooltip } from '@material-ui/core';
import React, { ReactNode } from 'react';
import { IWprControlProps } from '../props/IWprControlProps';
import { WprBaseInputControlComponent } from './WprBaseInputControlComponent';

/**
 * コントロール基本コンポーネント
 */
export abstract class WprBaseMatInputControlComponent<T extends IWprControlProps, U> extends WprBaseInputControlComponent<T, U> {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: T) {
		super(props);
	}
	// --------------------------------------------------------------------------

	// protected メソッド  ------------------------------------------------------
	/**
	 * コントロールノード取得
	 * @param control コントロール
	 * @returns コントロールノード
	 */
	protected getControlNode(control: any): ReactNode {
		if (this.props.tooltip === true) {
			const tval = this.getTootipValue(this.state.value);
			if (tval) {
				let props = this.getTooltipProps(tval);
				const events = this.getTooltipEvents();
				return (
					<Tooltip className='wpr-tooltip' {...props} {...events} arrow>
						<span>{control}</span>
					</Tooltip>
				);
			}
		}
		return (
			<>
				{control}
			</>
		);
	}
	
	/**
	 * ツールチップオープンイベント
	 */
	 protected setTooltipOpen(value: any): void {
		const tval = this.getTootipValue(value);
		this.setState({
			tooltipOpen: (tval != null)
		});
	}
	// --------------------------------------------------------------------------

	// イベント  ----------------------------------------------------------------
	/**
	 * ツールチップオープンイベント
	 */
	private onTooltipOpen(): void {
		const tval = this.getTootipValue(this.state.value);
		this.setState({
			tooltipOpen: (tval != null)
		});
	}

	/**
	 * ツールチップクローズイベント
	 */
	private onTooltipClose(): void {
		this.setState({
			tooltipOpen: false
		});
	}
	// --------------------------------------------------------------------------

	// private メソッド  --------------------------------------------------------
	/**
	 * ツールチップ値取得
	 * @returns ツールチップ値
	 */
	private getTootipValue(value: any): any {
		if (value) {
			if (typeof(value) === 'string' || value instanceof String) {
				const str = value as string;
				if (str.length > 0 && str.includes('\n') === true) {
					const list = new Array();
					const slist = str.split('\n');
					for (const line of slist) {
						if (list.length > 0)
							list.push(<br/>);
						list.push(line);
					}
					return <span>{list}</span>;
				}
			}
			return value;
		}
		return null;
	}

	/**
	 * ツールチッププロパティ取得
	 * @param val 入力値
	 * @returns ツールチッププロパティ
	 */
	 private getTooltipProps(val: string): any {
		const props = {
			title: val, 
			open: this.state.tooltipOpen
		};
		return props;
	}

	/**
	 * ツールチップイベント取得
	 * @returns ツールチップイベント情報
	 */
	private getTooltipEvents(): any {
		const events = {
			onClose: this.onTooltipClose.bind(this),
			onOpen: this.onTooltipOpen.bind(this)
		};
		return events;
	}
	// --------------------------------------------------------------------------
}