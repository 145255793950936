import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { ReactNode } from 'react';
import { IWprDialogEvents } from '../../view/component/WprBaseDialogViewComponent';
import { IWprMessageProps } from '../props/IWprMessageProps';
import { IWprSnackbarProps } from '../props/IWprSnackbarProps';
import { IWprSnackbarState } from '../props/IWprSnackbarState';
import { IWprMessage } from './IWprMessage';
import { IWprSnackbar } from './IWprSnackbar';
import { WprBaseMessagePartsComponent } from './WprBaseMessagePartsComponent';

/**
 * スナックバーコンポーネント
 */
export class WprSnackbarComponent extends WprBaseMessagePartsComponent<IWprSnackbarProps, IWprSnackbarState> implements IWprSnackbar {
	// private 変数  ------------------------------------------------------------
	private m_CloseEvents: IWprDialogEvents		= {};		// クローズイベント
	private m_ReserveOpen: boolean 				= false;	// オープン予約フラグ
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	constructor(props: IWprMessageProps) {
		super(props);
		this.state = {
			open: false,
			message: '',
			serverity: 'info'
		};
	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * マウント通知
	 */
	public componentDidMount() {
		this.props.view.setSnackbarComponent(this);
		this.m_CloseEvents.onClose = this.onClose.bind(this);
	}

	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		const hide = this.getHideDuration();
		return (
			<Snackbar open={this.state.open} {...hide} anchorOrigin={{vertical:'top', horizontal: 'right'}} {...this.m_CloseEvents}>
				<Alert {...this.m_CloseEvents} severity={this.state.serverity} >{this.state.message}</Alert>
			</Snackbar>
		);
	}
	// --------------------------------------------------------------------------

	// イベント  -----------------------------------------------------------------
	/**
	 * クローズイベント処理
	 * @param event 変更イベント 
	 */
	public onClose(): void {
		this.close(true);
	}
	// --------------------------------------------------------------------------

	// private メソッド  --------------------------------------------------------
	/**
	 * クローズ処理
	 * @param status ステータス
	 */
	private close(status: boolean): void {
		if (this.m_ReserveOpen === true) {
			this.m_ReserveOpen = false;
		}
		else {
			this.setState({
				open: false
			});
		}
	}

	/**
	 * 非表示情報取得
	 * @returns 非表示情報
	 */
	private getHideDuration(): any {
		const rtn: any = {};
		if (this.state.serverity !== 'error')
			rtn['autoHideDuration'] = 5000;
		return rtn;
	}
	// --------------------------------------------------------------------------

	// IWprSnackbarの実装  --------------------------------------------------------
	/**
	 * 表示処理
	 */
	public show(type: string, message: string): void {
		let serverity = type as 'success' | 'info' | 'warning' | 'error';
		if (this.state.open === true)
			this.m_ReserveOpen = true;
		this.setState({
			open: true,
			type: type,
			message: message,
			serverity: serverity
		});
	}
	// --------------------------------------------------------------------------
}
