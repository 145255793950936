/**
 * 顧客情報
 */
export class CustomerInfo {
	// public 変数	-----------------------------------------------------------
	public familyName: string;		// 姓漢字
	public firstName: string;		// 名漢字
	public familyNameKana: string;	// 姓カナ
	public firstNameKana: string;	// 名カナ
	public phoneNumber: string;		// 電話番号
	public postCode: string;		// 郵便番号
	public prefectures: string;		// 都道府県
	public city: string;			// 市区町村
	public town: string;			// 町域
	public chome: string;			// 丁目
	public block: string;			// 番地
	public gou: string;				// 号
	public apartName: string;		// 物件名
	public roomNum: string;			// 部屋番号
	public address: string;			// 住所
	// ------------------------------------------------------------------------
}
