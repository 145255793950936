import { BaseService } from './base/BaseService';
import { WprBaseViewCore } from '../../wpr-framework/view/WprBaseViewCore';
import { GetPaymentSimulationRequest } from './models/dto/mvno/GetPaymentSimulationRequest';
import { GetPaymentSimulationResponse } from './models/dto/mvno/GetPaymentSimulationResponse';
import { GetCustomerRequest } from './models/dto/mvno/GetCustomerRequest';
import { GetCustomerResponse } from './models/dto/mvno/GetCustomerResponse';
import { GetTerminalStockRequest } from './models/dto/mvno/GetTerminalStockRequest';
import { GetTerminalStockResponse } from './models/dto/mvno/GetTerminalStockResponse';
import { CreateMvnoInquiryRequest } from './models/dto/mvno/CreateMvnoInquiryRequest';
import { CreateMvnoInquiryResponse } from './models/dto/mvno/CreateMvnoInquiryResponse';
import { UpdateHMvnoTmpSavedRequest } from './models/dto/mvno/UpdateHMvnoTmpSavedRequest';
import { UpdateHMvnoTmpSavedResponse } from './models/dto/mvno/UpdateHMvnoTmpSavedResponse';
import { GetHMvnoTmpSavedRequest } from './models/dto/mvno/GetHMvnoTmpSavedRequest';
import { GetHMvnoTmpSavedResponse } from './models/dto/mvno/GetHMvnoTmpSavedResponse';
import { GetMGeneralListRequest } from './models/dto/mvno/GetMGeneralListRequest';
import { GetMGeneralListResponse } from './models/dto/mvno/GetMGeneralListResponse';
import { CheckMultiContractRequest } from './models/dto/mvno/CheckMultiContractRequest';
import { CheckMultiContractResponse } from './models/dto/mvno/CheckMultiContractResponse';
import { GetCapacityChangeRequest } from './models/dto/mvno/GetCapacityChangeRequest';
import { GetCapacityChangeResponse } from './models/dto/mvno/GetCapacityChangeResponse';
import { GetMvnoContractPriceListRequest } from './models/dto/mvno/GetMvnoContractPriceListRequest';
import { GetMvnoContractPriceListResponse } from './models/dto/mvno/GetMvnoContractPriceListResponse';
import { GetTotalFeeRequest } from './models/dto/mvno/GetTotalFeeRequest';
import { GetTotalFeeResponse } from './models/dto/mvno/GetTotalFeeResponse';
import { CheckMvnoEntryRequest } from './models/dto/mvno/CheckMvnoEntryRequest';
import { CheckMvnoEntryResponse } from './models/dto/mvno/CheckMvnoEntryResponse';
import { GetPreConfirmListRequest } from './models/dto/mvno/GetPreConfirmListRequest';
import { GetPreConfirmListResponse } from './models/dto/mvno/GetPreConfirmListResponse';
import { GetValidCouponListRequest } from './models/dto/mvno/GetValidCouponListRequest';
import { GetValidCouponListResponse } from './models/dto/mvno/GetValidCouponListResponse';

/**
 * MyPageRenewalサービス
 */
export class MvnoService extends BaseService {
	// サービス メソッド  --------------------------------------------------------
	/**
	 * 料金シミュレーション取得
	 * @param viewCore ビュー情報
	 * @param request リクエスト情報
	 * @param resMethod 結果処理メソッド
	 */
	public getPaymentSimulation(viewCore: WprBaseViewCore, request: GetPaymentSimulationRequest, resMethod: (result: GetPaymentSimulationResponse) => void): void {
		return this.doPost(viewCore, '/mvno/getPaymentSimulation', request, resMethod);
	}

	/**
	 * 顧客情報取得
	 * @param viewCore ビュー情報
	 * @param request リクエスト情報
	 * @param resMethod 結果処理メソッド
	 */
	public getCustomer(viewCore: WprBaseViewCore, request: GetCustomerRequest, resMethod: (result: GetCustomerResponse) => void): void {
		return this.doPost(viewCore, '/mvno/getCustomer', request, resMethod);
	}

	/**
	 * 端末在庫情報取得
	 * @param viewCore ビュー情報
	 * @param request リクエスト情報
	 * @param resMethod 結果処理メソッド
	 */
	public getTerminalStock(viewCore: WprBaseViewCore, request: GetTerminalStockRequest, resMethod: (result: GetTerminalStockResponse) => void): void {
		return this.doPost(viewCore, '/mvno/getTerminalStock', request, resMethod);
	}

	/**
	 * MVNOお問い合わせ作成
	 * @param viewCore ビュー情報
	 * @param request リクエスト情報
	 * @param resMethod 結果処理メソッド
	 */
	public createMvnoInquiry(viewCore: WprBaseViewCore, request: CreateMvnoInquiryRequest, resMethod: (result: CreateMvnoInquiryResponse) => void): void {
		return this.doPost(viewCore, '/mvno/createMvnoInquiry', request, resMethod);
	}

	/**
	 * MVNO一時保存更新
	 * @param viewCore ビュー情報
	 * @param request リクエスト情報
	 * @param resMethod 結果処理メソッド
	 */
	public updateHMvnoTmpSaved(viewCore: WprBaseViewCore, request: UpdateHMvnoTmpSavedRequest, resMethod: (result: UpdateHMvnoTmpSavedResponse) => void): void {
		return this.doPost(viewCore, '/mvno/updateHMvnoTmpSaved', request, resMethod);
	}

	/**
	 * MVNO一時保存データ取得
	 * @param viewCore ビュー情報
	 * @param request リクエスト情報
	 * @param resMethod 結果処理メソッド
	 */
	public getHMvnoTmpSaved(viewCore: WprBaseViewCore, request: GetHMvnoTmpSavedRequest, resMethod: (result: GetHMvnoTmpSavedResponse) => void): void {
		return this.doPost(viewCore, '/mvno/getHMvnoTmpSaved', request, resMethod);
	}

	/**
	 * 汎用マスタ一覧取得
	 * @param viewCore ビュー情報
	 * @param request リクエスト情報
	 * @param resMethod 結果処理メソッド
	 */
	public getMGeneralList(viewCore: WprBaseViewCore, request: GetMGeneralListRequest, resMethod: (result: GetMGeneralListResponse) => void): void {
		return this.doPost(viewCore, '/mvno/getMGeneralList', request, resMethod);
	}

	/**
	 * MVNO加入チェック
	 * @param viewCore ビュー情報
	 * @param request リクエスト情報
	 * @param resMethod 結果処理メソッド
	 */
	public checkMvnoEntry(viewCore: WprBaseViewCore, request: CheckMvnoEntryRequest, resMethod: (result: CheckMvnoEntryResponse) => void): void {
		return this.doPost(viewCore, '/mvno/checkMvnoEntry', request, resMethod);
	}

	/**
	 * 複数台契約チェック
	 * @param viewCore ビュー情報
	 * @param request リクエスト情報
	 * @param resMethod 結果処理メソッド
	 */
	public checkMultiContract(viewCore: WprBaseViewCore, request: CheckMultiContractRequest, resMethod: (result: CheckMultiContractResponse) => void): void {
		return this.doPost(viewCore, '/mvno/checkMultiContract', request, resMethod);
	}

	/**
	 * 容量変更情報
	 * @param viewCore ビュー情報
	 * @param request リクエスト情報
	 * @param resMethod 結果処理メソッド
	 */
	public getCapacityChangeInfo(viewCore: WprBaseViewCore, request: GetCapacityChangeRequest, resMethod: (result: GetCapacityChangeResponse) => void): void {
		return this.doPost(viewCore, '/mvno/getCapacityChangeInfo', request, resMethod);
	}

	/**
	 * 合計金額情報取得
	 * @param viewCore ビュー情報
	 * @param request リクエスト情報
	 * @param resMethod 結果処理メソッド
	 */
	public getTotalFee(viewCore: WprBaseViewCore, request: GetTotalFeeRequest, resMethod: (result: GetTotalFeeResponse) => void): void {
		return this.doPost(viewCore, '/mvno/getTotalFee', request, resMethod);
	}

	/**
	 * 事前確認事項取得
	 * @param viewCore ビュー情報
	 * @param request リクエスト情報
	 * @param resMethod 結果処理メソッド
	 */
	public getPreConfirmList(viewCore: WprBaseViewCore, request: GetPreConfirmListRequest, resMethod: (result: GetPreConfirmListResponse) => void): void {
		return this.doPost(viewCore, '/mvno/getPreConfirmList', request, resMethod);
	}

	/**
	 * クーポン残量照会取得
	 * @param viewCore ビュー情報
	 * @param request リクエスト情報
	 * @param resMethod 結果処理メソッド
	 */
	public getValidCouponList(viewCore: WprBaseViewCore, request: GetValidCouponListRequest, resMethod: (result: GetValidCouponListResponse) => void): void {
		return this.doPost(viewCore, '/mvno/getValidCouponList', request, resMethod);
	}

	// /**
	//  * 契約の金額一覧取得
	//  * @param viewCore ビュー情報
	//  * @param request リクエスト情報
	//  * @param resMethod 結果処理メソッド
	//  */
	// public getContractPriceList(viewCore: WprBaseViewCore, request: GetMvnoContractPriceListRequest, resMethod: (result: GetMvnoContractPriceListResponse) => void): void {
	// 	return this.doPost(viewCore, '/mvno/getContractPriceList', request, resMethod);
	// }
	// --------------------------------------------------------------------------
}
