import { WprBaseScopeViewInfo } from '../../../wpr-framework/view/WprBaseScopeViewInfo';
import { ObjectUtil } from '../../common/util/ObjectUtil';
import { VIEW_DEFINE_JSON } from '../../common/viewDefine';
import { MprInfoDialogSendData } from '../common/dialog/mpr_info_dialog/MprInfoDialogView';
import { MvnoService } from '../../service/MvnoService';
import { GetCustomerRequest } from '../../service/models/dto/mvno/GetCustomerRequest';
import { MypageWizardMngr } from '../wizard/models/MypageWizardMngr';
import { WprDI_Control } from '../../../wpr-framework/view/di/WprDI_Control';
import { WprControlInfo } from '../../../wpr-framework/view/control/WprControlInfo';
import { GetViewInfoRequest } from '../../service/models/dto/mypagerenewal/GetViewInfoRequest';
import { CommonService } from '../../service/CommonService';
import { GetViewInfoResponse } from '../../service/models/dto/mypagerenewal/GetViewInfoResponse';
import { RegistInfoRequest } from '../../service/models/dto/mypagerenewal/RegistInfoRequest';
import { RegistInfoResponse } from '../../service/models/dto/mypagerenewal/RegistInfoResponse';
import { WizardPostModel } from '../../models/WizardModel';
import { WprValidError } from '../../../wpr-framework/view/validation/WprValidError';
import { RegistContractRequest } from '../../service/models/dto/mypagerenewal/RegistContractRequest';
import { GetConstructionCostRequest } from '../../service/models/dto/mypagerenewal/GetConstructionCostRequest';
import { GetConstructionCostResponse } from '../../service/models/dto/mypagerenewal/GetConstructionCostResponse';
import { HAccessLog } from '../../service/models/entity/primary/HAccessLog';
import { WprDI_ScopeModel } from '../../../wpr-framework/view/di/WprDI_ScopeModel';
import { MvnoMnpInfo } from '../../service/models/apimodels/MvnoMnpInfo';
import { RegistAccessLogRequest } from '../../service/models/dto/common/RegistAccessLogRequest';

/**
 * マイページベースクラス
 */
export abstract class MypageBaseView extends WprBaseScopeViewInfo {
	// コンストラクタ  -----------------------------------------------------------
	public constructor(name: string) { super(name, 'ContractScopeInfo'); }
	// --------------------------------------------------------------------------

	// DI情報  ------------------------------------------------------------------
	@WprDI_Control
	private m_title: WprControlInfo	= null;	// タイトル
	@WprDI_ScopeModel('MvnoMnpInfo')
	private m_MvnoMnpInfo: MvnoMnpInfo = null;	// MVNOプラン情報
	// --------------------------------------------------------------------------

	// サービス  ----------------------------------------------------------------
	public m_CommonService: CommonService	= new CommonService();	// 共通サービス
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** ウィザード定義 */
	public get mngr(): MypageWizardMngr 				{ 
		setTimeout(() => {
			this.showLogMessage('プロパティ:ウィザード定義がViewに定義されていないので、実施できません。。。');
		}, 1500);
		return null; 					}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * ベースクラス名取得
	 * @returns ベースクラス名
	 */
	public getBaseName(): string {
		return 'MypageBaseView';
	}

	/**
	 * 基本クラス用コントロール定義設定
	 */
	public onBaseRegisterConfig(): void {
		this.addConfig('title', 'タイトル', null);
	}

	/**
	 * 基本クラス用ビュー表示通知
	 */
	public onBaseShowView(): void {
	}

	/**
	 * ドキュメントタイトル取得
	 * @returns ドキュメントタイトル
	 */
	public getDocumentTitle(): string {
		if (ObjectUtil.isNotNullOrEmptyStr(this.name)) {
			const viewDefine = VIEW_DEFINE_JSON.find(view => {
				const url = (window.location.pathname).split('/');
				const last = '/' + url.slice(-1)[0];
				return (last === view.virtualPage);
			});
			if (ObjectUtil.isNotNullOrUndefined(viewDefine) && this.m_title != null) {
				this.m_title.value = viewDefine.title;
				return this.m_title.value;
			}
		}
		return 'MyPage';
	}

	/**
	* 親ビュー再表示処理
	* @returns trueの場合、成功
	*/
	public sendParentData(dataName: string, data?: any): boolean {
		// 【dataNameルール】f：from、v：view、ev：event、P：Parent、C：Child
		// 1.子→親に、子のビュー情報を通知したい場合：fC_vC_XXXXXX（XXXXXには、子のViewのnameを入れる(ex.)RiskCalculationTab）
		// 2.子→親に、親のビュー情報を通知したい場合：fC_vP_YYYYYY（YYYYYには、親のViewのnameを入れる(ex.)ContractDetail））
		// 3.子→親に、イベント通知したい場合　　　　：fC_ev_ZZZZZZ（ZZZZZには、通知したいイベントを入れる(ex.update, approvalRequest)）
		// 【dataルール】
		// 基本的に、ReceiveDataクラスをonReceiveを書いているビュー上に作成する
		return super.sendParentData(dataName, data);

	}

	/**
	* 子ビューへデータを送信
	* @param dataName 送信データ名
	* @param data 送信データ
	* @param childName 子ビュー名(省略時は全ての子が対象)
	* @returns trueの場合、成功　falseの場合、非同期に実行
	*/
	public sendChildData(dataName: string, data?: any, childName?: string): boolean {
		// 【dataNameルール】f：from、v：view、ev：event、P：Parent、C：Child
		// 1.親→子に、子のビュー情報を通知したい場合：fP_vC_XXXXXX（XXXXXには、子のViewのnameを入れる(ex.)RiskCalculationTab）
		// 2.親→子に、親のビュー情報を通知したい場合：fP_vP_YYYYYY（YYYYYには、親のViewのnameを入れる(ex.)ContractDetail））
		// 3.親→子に、イベント通知したい場合　　　　：fP_ev_ZZZZZZ（ZZZZZには、通知したいイベントを入れる(ex.update, approvalRequest)）
		// 【dataルール】
		// 基本的に、ReceiveDataクラスをonReceiveを書いているビュー上に作成する
		return super.sendChildData(dataName, data, childName);
	}

	/**
	* サービスエラー通知処理
	* @param url URL
	* @param status ステータス
	* @param message エラーメッセージ 
	* @param error エラー情報
	*/
	public onServiceError(url: string, status: string, message: string, error?: Error): void {
		const host = location.host;
		this.showLogMessage('Serviceエラー url:' + url + ', status:' + status + ', message:' + message);
		if (!host.includes("localhost"))
			this.pushPath('/error');
	}

	/**
	 * サービスエラー通知処理
	 * @param url URL
	 * @param status ステータス
	 * @param message エラーメッセージ 
	 * @param error エラー情報
	 * @param resMethod 結果処理メソッド
	 */
	public onServiceErrorResult(url: string, status: string, message: string, resMethod?: (result: any) => void): void{
		// サービス接続エラーをスキップ
		const host = location.host;
		if (host.includes("localhost") && url === '/common/GetViewInfo') {
			resMethod.bind(this)(false);
		}
	}

	/**
	 * バリデーションエラー通知
	 * @param actionName アクション名
	 * @param validError バリデーションエラー情報
	 * @param row 行情報
	 * @バリデーションエラー情報
	 */
	protected onValidError(actionName: string, validError: WprValidError, row?: any): void {
		window.scroll({top: 0, behavior: 'smooth'});
	}
	
	/**
	 * ローカルストレージデータ設定
	 * @param key 格納キー
	 * @param data 格納データ
	 */
	public setStorageItem(key: string, data: any): void {
		super.setStorageItem(key, data);
		const host = location.host;
		if (host.includes("localhost")) {
			const devKey = 'dev_' + key;
			localStorage.removeItem(devKey);
			localStorage.setItem(devKey, JSON.stringify(data));
		}
	}

	/**
	 * ローカルストレージデータ削除
	 * @param key 格納キー
	 * @param data 格納データ
	 */
	public removeStorageItem(key: string): void {
		super.removeStorageItem(key);
		const host = location.host;
		if (host.includes("localhost")) {
			const devKey = 'dev_' + key;
			localStorage.removeItem(devKey);
		}
	}

	/**
	 * 画面描画時処理
	 */
	public registAccessLog(): void {
		const model: HAccessLog = new HAccessLog();
		//顧客番号
		model.customerId = this.getCustomerId();
		//前画面情報
		if (this.getStorageItem('b_url')) {
			if (this.getStorageItem('b_url').includes('line_liff') && this.getStorageItem('b_url').includes('#')) {
				const idx = this.getStorageItem('b_url').indexOf('#');
				model.previousViewUrl = this.getStorageItem('b_url').substring(0, idx);
			}
			else
				model.previousViewUrl = this.getStorageItem('b_url');
			const preViewDefine = VIEW_DEFINE_JSON.find(view => {
				const url = (model.previousViewUrl).split('/');
				const path = url[url.length-1].split('?');
				const last = '/' + path[0];
				return (last === view.virtualPage);
			});
			if (ObjectUtil.isNotNullOrUndefined(preViewDefine)) 
				model.previousViewNm = preViewDefine.title;
		}
		//表示画面情報
		if (window.location.href.includes('line_liff') && window.location.href.includes('#')) {
			const idx = window.location.href.indexOf('#');
			model.currentViewUrl = window.location.href.substring(0, idx);
		}
		else 
			model.currentViewUrl = window.location.href;
		const curViewDefine = VIEW_DEFINE_JSON.find(view => {
			const url = (model.currentViewUrl).split('/');
			const path = url[url.length-1].split('?');
			const last = '/' + path[0];
			return (last === view.virtualPage);
		});
		if (ObjectUtil.isNotNullOrUndefined(curViewDefine)) 
			model.currentViewNm = curViewDefine.title;
		//フロー名
		model.flowNm = this.getStorageItem('c_currentWizardFlowName');
		//ブラウザ
		const agent = window.navigator.userAgent.toLowerCase();
		if(agent.indexOf('msie') > -1) 
			model.browser = 'IE';
		else if(agent.indexOf('edg') > -1) 
			model.browser = 'Edge';		  
		else if(agent.indexOf('chrome') > -1) 
			model.browser = 'Chrome';
		else if(agent.indexOf('safari') > -1) 
			model.browser = 'Safari';
		else if(agent.indexOf('firefox') > -1) 
			model.browser = 'Firefox';
		else 
		    model.browser = 'その他';
		//端末
		if (agent.indexOf('iphone') != -1) 
			model.terminal = 'iPhone';
		else if (agent.indexOf('ipad') != -1 || (agent.indexOf('macintosh') > -1 && 'ontouchend' in document)) 
			model.terminal = 'iPad';
		else if (agent.indexOf('macintosh') > -1)
			model.terminal = 'MacBook';
		else if (agent.indexOf('android') != -1) {
			if(agent.indexOf('mobile') != -1) 
				model.terminal = 'androidのスマホ';
			else
			model.terminal = 'androidのタブレット';
		}
		else 
			model.terminal = 'その他(PC)';
		//OS
		if (agent.indexOf('windows') > -1) 
			model.terminal += ' windows';
		else if (agent.indexOf('mac os') > -1)
			model.terminal += ' Mac OS';
		else if (agent.indexOf('linux') > -1)
			model.terminal += ' Linux';
		else if (agent.indexOf('cr os') > -1)
			model.terminal += ' chrome OS';
		else
			model.terminal += ' その他OS';
		//キャッシュ
		if (this.m_MypageWizardMngr) {
			const cache = JSON.stringify(this.m_MypageWizardMngr.getCache());
			if (cache !== 'null')
				model.cacheData = cache;
		}
		else if (this.m_MvnoMnpInfo) {
			const cache = JSON.stringify(this.m_MvnoMnpInfo);
			if (cache !== 'null')
				model.cacheData = cache;
		}
		//登録処理
		const req: RegistAccessLogRequest = new RegistAccessLogRequest();
		req.accessLog = model;
		this.m_CommonService.registAccessLog(this, req, (result) => {
			if (result) {

			}
		});

	}
	// --------------------------------------------------------------------------

	// protected showMprInfoMessage(dialogname: string, message: any, title: string): void {
	// 	const sendData: MprInfoDialogSendData = new MprInfoDialogSendData();
	// 	sendData.message = message;
	// 	this.showDialog(dialogname, sendData, null, title);
	// }

	/**
	 * 顧客番号をCookieから取得
	 * @returns 
	 */
	public getCustomerId(): string {
		return this.getCookie(WizardPostModel.COOKIE_CUSTOMER_ID);
	}

	public getContractCd(): string {
		return this.getCookie('contractCd');
	}

	public getParentContractCd(): string {
		return this.getCookie('parentContractId');
	}

	// protected toMypageTop(): void {
	// 	const environment: string = this.getEnvironment();
	// 	if (environment === MypageBaseView.ENVIRONMENT_UAT) {
	// 		location.href = "https://stage.mypage.itscom.net/Top/My_top/";
	// 	}
	// 	else {
	// 		location.href = "https://119.224.199.206/Top/My_top/";
	// 	}
	// }

	public static readonly ENVIRONMENT_UAT: string = '検証';
	public static readonly ENVIRONMENT_PROD: string = '本番';

	protected getEnvironment(): string {
		const uri: URL = new URL(window.location.href);
		if (uri.hostname === 'stg.mypage.itscom.co.jp') {
			return MypageBaseView.ENVIRONMENT_UAT;
		}
		// else {
		// 	return MypageBaseView.ENVIRONMENT_PROD;
		// }
	}

	/**
	 * クッキー情報取得処理
	 */
	public getOldUrl(): string {
		const host = location.host;
		let url: string;
		if (host.includes("localhost") || host.includes("stg")) {
			url = 'https://stage.mypage.itscom.net/'
		}
		else {
			url = 'https://mypage.itscom.net/'
		}
		return url;
	}

	/**
	 * 速度測定のURL取得処理
	 * @returns 
	 */
	public getNetSpeedCheckUrl(): string {
		const host = location.host;
		let url: string;
		if (host.includes("localhost") || host.includes("stg")) {
			url = 'https://speedcheck.itscom.co.jp/spd_test001/index.php';
		}
		else {
			url = 'https://speedcheck.itscom.co.jp/spd/index.php';
		}
		return url;
	}

	/**
	 * 共通契約情報取得処理
	 * @returns 
	 */
	public getViewInfo(view: MypageBaseView, req: GetViewInfoRequest, resMethod: (result: GetViewInfoResponse) => void): any {
		this.m_CommonService.GetViewInfo(view, req, resMethod);
	}

	/**
	 * 共通登録処理
	 * @returns 
	 */
	public registInfo(view: MypageBaseView, req: RegistInfoRequest, resMethod: (result: RegistInfoResponse) => void): any {
		this.m_CommonService.registInfo(view, req, resMethod);
	}

	/**
	 * 契約情報登録処理
	 * @returns 
	 */
	public registContract(view: MypageBaseView, req: RegistContractRequest, resMethod: (result: RegistInfoResponse) => void): any {
		this.m_CommonService.registContract(view, req, resMethod);
	}

	/**
	 * 一時金取得処理
	 * @returns 
	 */
	public getConstructionCost(view: MypageBaseView, req: GetConstructionCostRequest, resMethod: (result: GetConstructionCostResponse) => void): any {
		this.m_CommonService.getConstructionCost(view, req, resMethod);
	}

	/**
	 * システムログ表示
	 * @returns 
	 */
	public showLogMessage(message: string): void {
		const host = location.host;
		if (host.includes("localhost")) {
			console.log('[ERROR!!!] ' + message);
			this.showErrorMessage('[ERROR!!!] ' + message);
		} else {
			// console.log('[ERROR!!!] ' + message);
		}
	}

	/**
	 * 増設工事費
	 * @param contractList 
	 * @returns 
	 */
	// public getAddConstruction(view: MypageBaseView, contractList: Contract): string {
	// 	let fee: string;
	// 	let flg: boolean = false;
	// 	const tokotonSupport = this.mngr.getMDisplayList(view, MypageDispdivcdConstants.TOKOTON_SUPPORT_COURSE_NAME, '');
	// 	if (contractList && contractList.length > 0) {
	// 		const service = contractList.filter(data => data.serviceCD === tokotonSupport[0].namecd);
	// 		if (service && service.length > 0)
	// 			tokotonFlg = true;
	// 	}
	// 	return fee;
	// }
}
