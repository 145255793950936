import React, { ReactNode } from 'react';
import { IWprErrorProps } from '../props/IWprErrorProps';
import { WprBaseErrorComponent } from './WprBaseErrorComponent';

/**
 * エラーコンテナプロパティ情報
 */
interface IWprErrorContainerProps extends IWprErrorProps {
}

/**
 * エラーコンテナコントロール
 */
export class WprErrorContainer extends WprBaseErrorComponent<IWprErrorContainerProps> {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: IWprErrorContainerProps) {
		super(props);
	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		if (this.state.errList == null)
			return null;
		let list = null;
		if (this.props.row)
			list = this.state.errList.filter(minfo => (this.props.name === undefined || this.checkControlName(minfo.controlName) === true) && minfo.rowIndex === this.props.row.index);
		else
			list = this.state.errList.filter(minfo => this.props.name === undefined || this.checkControlName(minfo.controlName) === true);
		if (list.length > 0) {
			return (
				<>{this.props.children}</>
			);
		}
		return (
			<></>
		);
	}

	/**
	 * コントロール名称取得
	 * @returns コントロール名称
	 */
	public getName(): string {
		return 'WprErrorContainer';
	}
	// --------------------------------------------------------------------------
}