import { WprHttpErrorInfo } from './WprHttpErrorInfo';
import { WprBaseService } from './WprBaseService';
import axios, {	AxiosInstance　} from 'axios';
import { WprServiceErrorInfo } from './WprServiceErrorInfo';
import { Agent } from 'https';

/**
 * Axiosサービス基本クラス
 */
export abstract class WprBaseAxiosService extends WprBaseService {
	// private 変数  ------------------------------------------------------------
	private 	m_Instance: AxiosInstance	= null;		// Axiosインスタンス
	public readonly SERVICE_ERROR_STATUS: string 	= '-1';
	// --------------------------------------------------------------------------

	// abstract メソッド  --------------------------------------------------------
	/**
	 * BaseURL取得
	 * @returns BaseURL
	 */
	protected abstract getBaseURL(): string;

	/**
	 * 結果チェック
	 * @param res 結果情報
	 * @returns nullの場合、正常、エラーの場合は、WprServiceErrorを返す
	 */
	protected abstract checkResult(res: any): WprServiceErrorInfo;
	// --------------------------------------------------------------------------

	// virtual メソッド  --------------------------------------------------------
	/**
	 * ヘッダ取得
	 * @returns ヘッダ
	 */
	protected getHeaders(): any {
		return { 'Content-Type': 'application/json' };
	}

	/**
	 * データ取得
	 * @returns データ
	 */
	protected getData(): any {
		return null;
	}
	// --------------------------------------------------------------------------

	// private メソッド  --------------------------------------------------------
	/**
	 * Axiosインスタンス取得
	 * @returns Axiosインスタンス
	 */
	private getAxios(): AxiosInstance {
		if (this.m_Instance == null) {
			this.m_Instance = axios.create({
				baseURL: this.getBaseURL(),
				headers: this.getHeaders(),
				responseType: 'json',
				timeout: 180000,
				xsrfCookieName: '_trvcsrf',
				withCredentials: true,
				httpsAgent: new Agent({
					rejectUnauthorized: false
				})
			});		
		}
		return this.m_Instance;
	}

	/**
	 * JSONオブジェクトのNull置換処理
	 * @param key JSONキー
	 * @param value JSON値（オブジェクト含む）
	 * @returns undefinedで返却すると、JSONに格納されない
	 */
	private replacer(key: any, value: any) {
		if (value === null) {
			return undefined;
		}
		return value;
	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * POST通信処理
	 * @param url URL
	 * @param request リクエスト情報
	 * @returns 通信結果Promiseオブジェクト
	 */
	protected async executePost(url: string, request: any): Promise<any> {
		return new Promise((resolve, reject) => {
			const reqJson: string = JSON.stringify(request, this.replacer);
			let resJson: any = null; 
			const axiosRes = this.getAxios().post(url, reqJson);
			Promise.all([axiosRes]).then((result) => {
				resJson = JSON.stringify(result[0].data);
				const convertedRes = JSON.parse(resJson);
				const error = this.checkResult(convertedRes);
				if (error != null) {
					reject(new WprHttpErrorInfo(url, error.status, error.message));
				}
				resolve(convertedRes);
			}).catch((error) => {
				reject(new WprHttpErrorInfo(url, this.SERVICE_ERROR_STATUS, 'POST通信に失敗しました。', error));
			});
		});
	}

	/**
	 * GET通信処理
	 * @param url URL
	 * @returns 通信結果Promiseオブジェクト
	 */
	 protected async executeGet(url: string): Promise<any> {
		return new Promise((resolve, reject) => {
			let resJson: any = null; 
			const axiosRes = this.getAxios().get(url);
			Promise.all([axiosRes]).then((result) => {
				resJson = JSON.stringify(result[0].data);
				const convertedRes = JSON.parse(resJson);
				const error = this.checkResult(convertedRes);
				if (error != null) {
					reject(new WprHttpErrorInfo(url, error.status, error.message));
				}
				resolve(convertedRes);
			}).catch((error) => {
				reject(new WprHttpErrorInfo(url, this.SERVICE_ERROR_STATUS, 'GET通信に失敗しました。', error));
			});
		});
	}
	// --------------------------------------------------------------------------
}