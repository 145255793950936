import { WprFramework } from '../../WprFramework';

/**
 * コンバータ基本クラス
 */
export abstract class WprBaseConverter {
	// private 変数  ------------------------------------------------------------
	private m_Name: string				= null;		// コンバータ名称
	private m_RowData: any				= null;		// 行データ
	private m_Framework: WprFramework	= null;		// フレームワーク
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** コンバータ名称 */
	public get name(): string	{ return this.m_Name;		}
	/** リストデータ */
	public get rowData(): any	{ return this.m_RowData;	}	public set rowData(data: any) { this.m_RowData = data;	}
	/** フレームワーク */
	protected get FW(): WprFramework	{
		if (this.m_Framework == null)
			this.m_Framework = WprFramework.getInstance();
		return this.m_Framework;
	}
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	public constructor(name: string) {
		this.m_Name = name;
	}
	// --------------------------------------------------------------------------

	// abstract/virtual メソッド  ------------------------------------------------
	/**
	 * 値を表示用の値に変換する (value -> viewValue)
	 * @param value 値
	 * @returns 表示用の値
	 */
	public abstract convertView(value: any): string

	/**
	 * 編集用の値を値に変換する (editValue -> value)
	 * @param editValue 編集用の値
	 * @returns 値
	 */
	public convertValue(editValue: string): any {
		return editValue;
	}

	/**
	 * 値を編集用の値に変換する (value -> editValue)
	 * @param value 値
	 * @returns 編集用の値
	 */
	public convertEdit(value: any): any {
		return value;
	}
	// --------------------------------------------------------------------------
}
