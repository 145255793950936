import { WprBaseScopeViewInfo } from '../../../../wpr-framework/view/WprBaseScopeViewInfo';

import { ObjectUtil } from '../../../common/util/ObjectUtil';
import { AuthService } from '../../../service/AuthService';
import { GetCertificationRequest } from '../../../service/models/dto/auth/GetCertificationRequest';
import { CertificationInfo } from '../../../service/models/apimodels/CertificationInfo';
import { VIEW_DEFINE_JSON } from '../../../common/viewDefine';
import { GetCertificationResponse } from '../../../service/models/dto/auth/GetCertificationResponse';
import { ContractConfirmService } from '../../../service/ContractConfirmService';
import { CheckContractConfirmRequest } from '../../../service/models/dto/contractConfirm/CheckContractConfirmRequest';
import { BssContract } from '../../../models/bss/contract/BssContract';
import { BssConstants } from '../../../common/constants/BssConstants';
import { MypageWizardMngr } from '../../wizard/models/MypageWizardMngr';
import { WprModel } from '../../../../wpr-framework/view/deco/WprModel';
import { WprDI_ScopeModel } from '../../../../wpr-framework/view/di/WprDI_ScopeModel';
import { WizardPostModel } from '../../../models/WizardModel';
import { MypageBaseView } from '../../base/MypageBaseView';
import { GetLineInfoRequest } from '../../../service/models/dto/auth/GetLineInfoRequest';
import { BaseService } from '../../../service/base/BaseService';
import { IWprUrlParam } from '../../../../wpr-framework/view/route/IWprUrlParam';
import MypageErrorConstants from '../../../common/MypageErrorConstants';

/**
 * リダイレクトページ
 */
export class S_01_001View extends MypageBaseView {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() { super('S_01_001View'); }
	// --------------------------------------------------------------------------

	// モデル  ------------------------------------------------------------------
	@WprDI_ScopeModel('MypageWizardMngr')
	private m_MypageWizardMngr: MypageWizardMngr			= null;		// 画面ウィザード管理クラス
	// --------------------------------------------------------------------------

	// サービス  ------------------------------------------------------------------
	private m_AuthService: AuthService = new AuthService();	// 認証サービス

	private m_ContractConfirmService: ContractConfirmService = new ContractConfirmService();	// 契約書面交付
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * ビュー表示通知
	 */
	public onShowView(): void {
		this.registAccessLog();
		this.setStorageItem('b_url', window.location.href);
		// 契約一覧から遷移してきた場合、フローをクリアする
		this.m_MypageWizardMngr.terminate(this);

		const req: GetCertificationRequest = new GetCertificationRequest();
		const sessionId = this.getUrlParam('SESSIONID');
		const sessionHashCd = this.getUrlParam('AUTHTOKEN');
		
		if (ObjectUtil.isNotNullOrEmptyStr(sessionId) && ObjectUtil.isNotNullOrEmptyStr(sessionHashCd)) {
			const reqCertification: CertificationInfo = new CertificationInfo();
			reqCertification.authToken = sessionHashCd;
			reqCertification.sessionId = sessionId;
			req.certificationInfo = reqCertification;
			this.m_AuthService.getCertification(this, req, (result) => {
				// セッションエラーの場合はエラーページに遷移
				if (result.certificationInfo.sessionFlg == false) {
					this.pushPath('/error?errorCd=' + MypageErrorConstants.SESSION_ERROR);
				}
				else {
					this.toRedirect(result);
				}
				this.setCookie('sessionId', sessionId);
			});
		}
		// セッションエラーの場合はエラーページに遷移
		else {
			const host = location.host;
			// 新からの遷移の場合セッションIDなくてもクッキーから顧客番号取れたらかつ、遷移元(referrer)が新のURLなら分岐入れて遷移させる
			if (ObjectUtil.isNotNullOrEmptyStr(this.getCookie("customerId")) && document.referrer.includes('mypage.itscom.net'))
				this.toContractConfirm(this.getCookie("customerId"));
			else if (!host.includes("localhost") && this.getUrlParam(WizardPostModel.URL_VIEW_ID) !== 'terminal_regist')
				this.pushPath('/error?errorCd=' + MypageErrorConstants.SESSION_ERROR);
			else
				this.toRedirect(null);
		}
	}

	/**
	* サービスエラー通知処理
	* @param url URL
	* @param status ステータス
	* @param message エラーメッセージ 
	* @param error エラー情報
	*/
	public onServiceError(url: string, status: string, message: string, error?: Error): void {
		this.showLogMessage('Serviceエラー url:' + url + ', status:' + status + ', message:' + message);

		// 開発環境ではエラーメッセージ表示してから画面遷移する
		const host = location.host;
		if (host.includes("localhost") && url !== '/common/GetViewInfo') {
			setTimeout(() => {
				this.toRedirect(null);
			}, 2500);
		} else if (host.includes("localhost") && url === '/common/GetViewInfo') {
			// GetViewInfoのサービス接続エラーをスキップ
		} else {
			this.pushPath('/error');
		}
	}
	// --------------------------------------------------------------------------
	/**
	 * 各画面へ遷移
	 * @param result 
	 */
	private toRedirect(result: GetCertificationResponse): void {
		if (result != null) {
			this.setCookie(WizardPostModel.COOKIE_CUSTOMER_ID, result.certificationInfo.customerId, '/', 3600);
			// メニューの制御の判定
			this.setMenuMode(result.contractList);
		}
		const contractId = this.getUrlParam(WizardPostModel.URL_CONTRACT_ID);
		const view = this.getUrlParam(WizardPostModel.URL_VIEW_ID);

		switch (view) {
			// 契約内容確認書
			case 'CONTRACT_CONFIRM':
				this.toContractConfirm(result.certificationInfo.customerId);
				break;
			// MVNO申込
			case 'MVNO_ENTRY':
				this.replacePath('/mvno_001');
				break;
			// MVNO容量変更
			case 'MVNO_CAPACITYCHANGE':
				this.setCookie('contractCd', contractId);
				this.replacePath('/mvno_change_001');
				break;
			// MNP問合わせ
			case 'MVNO_MNP':
				this.setCookie('contractCd', contractId);
				this.replacePath('/mvno_mnp_001');
				break;
			// クーポン対応（データ容量追加申込）
			case 'MVNO_ADDCOUPON':
				this.setCookie('contractCd', contractId);
				this.replacePath('/mvno_capacity_001');
				break;
			// アンバサダー
			case 'AMBASSADOR':
				this.replacePath('/ambassador_001');
				break;
			// 利用明細
			case 'USAGE_DETAILS':
				this.replacePath('/usage_details_001');
				break;
			default:
				const postData: WizardPostModel = new WizardPostModel();
				postData.customerId = this.getCookie(WizardPostModel.COOKIE_CUSTOMER_ID);
				postData.contractId = this.getUrlParam(WizardPostModel.URL_CONTRACT_ID);
				postData.optionId = this.getUrlParam(WizardPostModel.URL_OPTION_ID);
				postData.maxFlg = this.getUrlParam(WizardPostModel.URL_MAXFLG);
				postData.radiusAccount = this.getUrlParam(WizardPostModel.URL_ACOUNTID);
				postData.workScheduleId = this.getUrlParam(WizardPostModel.URL_WORK_SCHEDULE_ID);
				postData.stcd = this.getUrlParam(WizardPostModel.URL_STOCK_TYPE_CD);
				postData.ttcd = this.getUrlParam(WizardPostModel.URL_TERMINAL_TYPE_CD);
				postData.tid = this.getUrlParam(WizardPostModel.URL_TERMINAL_ID);
				this.setStorageItem('c_params', postData);

				if (ObjectUtil.isNullOrEmptyStr(view)) {
					this.onServiceError(view, 'unknown', '表示するURLが指定されていない');
				} else {
					this.m_MypageWizardMngr.setFlow(this, view, (result) => {
						if (result) {
							this.replacePath(view);
						} else {
							this.showLogMessage('viewDefineが定義されていない');
						}
					});
				}
				break;
		}
	}

	/**
	 * 契約内容確認書へ遷移
	 * @param result 
	 */
	private toContractConfirm(custoerId: string): void {
		const req: CheckContractConfirmRequest = new CheckContractConfirmRequest();
		req.customerId = custoerId;
		this.m_ContractConfirmService.checkContractConfirm(this, req, (result) => {
			// 要承諾の場合に電子発行承諾画面に遷移
			if (result.electronicConfirmFlg == true) {
				this.setCookie('appID', result.application.appID, '/', 3600);
				this.replacePath('/s002');
			}
			// その他は一覧に遷移
			else {
				this.replacePath('/s003');
			}
		});
	}

	/**
	 * メニューボタンの制御判定
	 */
	private setMenuMode(contractList: BssContract[]): void {
		// let hpContractFlg: boolean = false;
		// let symanContractFlg: boolean = false;
		// let netCount: number = 0;
		
		let netFlg: boolean = false;
		let netContractFlg: boolean = false;

		if (ObjectUtil.isNotNullOrEmptyArray(contractList)) {
			for (let contract of contractList) {
				const statusCd: string = contract.statusCD;
				const serviceTypeCD: string = contract.serviceTypeCD;
	
				// 2:iTSCOM.net OR 12:NET-F
				if (serviceTypeCD === BssConstants.BSS_CONTRACT_SERVICETYPECD_NET || serviceTypeCD === BssConstants.BSS_CONTRACT_SERVICETYPECD_NETF) {
					// 契約中 OR 停止中 OR 承認前
					if (statusCd === BssConstants.BSS_CONTRACT_STATUSCD_CONTRACT ||
						statusCd === BssConstants.BSS_CONTRACT_STATUSCD_STOP ||
						statusCd === BssConstants.BSS_CONTRACT_STATUSCD_BEFOREAPPROVAL) {
						netContractFlg = true;
					}
					netFlg = true;
				}
			}
		}	
		this.setCookie('netFlg', String(netFlg));
		this.setCookie('netContractFlg', String(netContractFlg));
	}
}
