import { WprBaseViewMultiModeInfo } from '../../../../../../wpr-framework/mode/WprBaseViewMultiMode';
import { WprMultiMode } from '../../../../../../wpr-framework/mode/WprMultiMode';
import { AddTvSelect2View } from '../AddTvSelect2View';
import { WprDI_Control } from '../../../../../../wpr-framework/view/di/WprDI_Control';
import { WprControlInfo } from '../../../../../../wpr-framework/view/control/WprControlInfo';

/**
 * テレビチェックボックスモード
 */
export class TvCheckBoxMode extends WprBaseViewMultiModeInfo<AddTvSelect2View> {
	// DI情報  ------------------------------------------------------------------
	@WprDI_Control
	private m_rental: WprControlInfo	= null;	// レンタル
	@WprDI_Control
	private m_magazineApply: WprControlInfo	= null;	// マガジン申込む
	@WprDI_Control
	private m_magazineUnapply: WprControlInfo	= null;	// マガジン申込まない
	@WprDI_Control
	private m_otokuApply: WprControlInfo	= null;	// お得パック申込む
	@WprDI_Control
	private m_otokuUnapply: WprControlInfo	= null;	// お得パック申込まない
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	public constructor() {
		super('TvCheckBoxMode');
	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * モードリスト取得（この画面モードで使用する全てのモードを定義する）
	 * @param list モードリスト
	 */
	public useModeList(list: string[]): void {
		list.push('切替不可(rental)');
		list.push('切替可(magazineApply)');
		list.push('切替不可(magazineApply)');
		list.push('切替可(magazineUnapply)');
		list.push('切替不可(magazineUnapply)');
		list.push('切替可(otokuApply)');
		list.push('切替不可(otokuApply)');
		list.push('切替可(otokuUnapply)');
		list.push('切替不可(otokuUnapply)');
	}

	/**
	 * フィールド定義を登録処理
	 */
	public onRegisterControl(): void {
		this.addControl('rental', { check: true, readOnly: [ { mode: '切替不可(rental)', value: true }, { mode: 'other', value: false } ] });
		this.addControl('magazineApply', { check: true, readOnly: [ { mode: '切替不可(magazineApply)', value: true }, { mode: '切替可(magazineApply)', value: false } ] });
		this.addControl('magazineUnapply', { check: true, readOnly: [ { mode: '切替不可(magazineUnapply)', value: true }, { mode: '切替可(magazineUnapply)', value: false } ] });
		this.addControl('otokuApply', { check: true, readOnly: [ { mode: '切替不可(otokuApply)', value: true }, { mode: '切替可(otokuApply)', value: false } ] });
		this.addControl('otokuUnapply', { check: true, readOnly: [ { mode: '切替不可(otokuUnapply)', value: true }, { mode: '切替可(otokuUnapply)', value: false } ] });
	}

	/**
	 * 画面の状態をチェックしてモードを変更する
	 * @param curMode 現在のモード
	 * @param newMode 新しいモード
	 */
	public onCheckMultiMode(curMode: WprMultiMode, newMode: WprMultiMode): void {
		if (this.m_rental.value)
			newMode.addMode('切替不可(rental)');
		if (this.m_magazineApply.value && !this.m_magazineUnapply.value) {
			newMode.addMode('切替不可(magazineApply)');
			newMode.addMode('切替可(magazineUnapply)');
		}
		if (!this.m_magazineApply.value && this.m_magazineUnapply.value) {
			newMode.addMode('切替不可(magazineUnapply)');
			newMode.addMode('切替可(magazineApply)');
		}
		if (this.m_otokuApply.value && !this.m_otokuUnapply.value) {
			newMode.addMode('切替不可(otokuApply)');
			newMode.addMode('切替可(otokuUnapply)');
		}
		if (!this.m_otokuApply.value && this.m_otokuUnapply.value) {
			newMode.addMode('切替不可(otokuUnapply)');
			newMode.addMode('切替可(otokuApply)');
		}
	}
	// --------------------------------------------------------------------------
}
