import { WizardModel } from '../../models/WizardModel';
import { MypageWizardFlow } from './MypageWizardFlow';
import { MypageBaseView } from '../base/MypageBaseView';
import { GetViewInfoRequest } from '../../service/models/dto/mypagerenewal/GetViewInfoRequest';
import { MDisplay } from '../../service/models/entity/secondary/MDisplay';
import { MCommon } from '../../service/models/entity/primary/MCommon';
import { MypageWizardMngr } from './models/MypageWizardMngr';
import { TokotonSupportSelectView } from '../tokotonSupport/tokoton_support_select/TokotonSupportSelectView';
import { CommonCompleteView } from '../common/common_complete/CommonCompleteView';
import { TokotonSupportConfirmView } from '../tokotonSupport/tokoton_support_confirm/TokotonSupportConfirmView';
import { CommonAgreeView } from '../common/common_agree/CommonAgreeView';
import { MGeneral } from '../../service/models/entity/MGeneral';
import { YakkanListModel } from '../../service/models/apimodels/YakkanListModel';
import { ContractServiceModel } from '../../service/models/apimodels/ContractServiceModel';
import { TokotonSupportAppModel } from '../tokotonSupport/models/TokotonSupportAppModel';
import MypageDispdivcdConstants from '../../common/MypageDispdivcdConstants';
import MypageCodeTypeConstants from '../../common/MypageCodeTypeConstants';
import { RegistInfoRequest } from '../../service/models/dto/mypagerenewal/RegistInfoRequest';
import { InquiryMailInfo } from '../../service/models/apimodels/InquiryMailInfo';
import MypageYakkanConstants from '../../common/MypageYakkanConstants';
import { MAIL_CONSTANTS } from '../../common/MyPageMailIdConstants';

/**
 * ITSCOM HOME_追加ウィザードクラス
 */
export class TokotonSupportNewWizardFlow extends MypageWizardFlow {
	// private 変数  ------------------------------------------------------------
	private m_DefaultWizardFlow: WizardModel[] = null;		// 画面ウィザードリスト
	private m_StartViewName: string = null;
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	public constructor() {
		super();
		this.m_StartViewName = new TokotonSupportSelectView().name;

		// 遷移パターン１
		this.m_DefaultWizardFlow = new Array();
		this.m_DefaultWizardFlow.push({ stepNum: 0, title: '契約選択', viewName: null, path: this.getOldUrl() + 'Srvlist/Continfo_list/' });
		this.m_DefaultWizardFlow.push({ stepNum: 1, title: 'コース選択', viewName: this.m_StartViewName, path: 'tokoton_support_select' });
		this.m_DefaultWizardFlow.push({ stepNum: 2, title: '申込み内容の確認', viewName: new TokotonSupportConfirmView().name, path: 'tokoton_support_confirm' });
		this.m_DefaultWizardFlow.push({ stepNum: 3, title: '契約約款', viewName: new CommonAgreeView().name, path: 'tokoton_support_useagree' });
		this.m_DefaultWizardFlow.push({ stepNum: 4, title: '申込み完了', viewName: new CommonCompleteView().name, path: 'tokoton_support_complete'});
	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * 画面ウィザードフロー名取得
	 */
	public getWizardFlowName(viewName: string): string {
		if (viewName === this.m_StartViewName)
			return this.constructor.name;
		return null;
	}

	/**
	 * 画面ウィザードリスト取得：契約状態で切り替えたり、選択肢で切り替えたり
	 */
	public getWizardFlow(mngr: MypageWizardMngr): WizardModel[] {
		return this.m_DefaultWizardFlow;
	}

	/**
	 * 画面ウィザードAPI用モデル取得：契約状態で切り替えたり、選択肢で切り替えたり
	 */
	public getWizardApiModel(mngr: MypageWizardMngr, view: MypageBaseView): GetViewInfoRequest {
		const req = new GetViewInfoRequest();
		req.customerId = mngr.getParams().customerId;
		req.paymentFlg = true;
		// 約款の取得
		req.yakkanList = new Array();
		req.yakkanList.push(new MGeneral(MypageCodeTypeConstants.CONTRACT_AGREE, MypageYakkanConstants.ITSCOM_SERVICE_IMPORTANT));
		req.yakkanList.push(new MGeneral(MypageCodeTypeConstants.CONTRACT_AGREE, MypageYakkanConstants.ITSCOM_SERVICE));
		req.yakkanList.push(new MGeneral(MypageCodeTypeConstants.CONTRACT_AGREE, MypageYakkanConstants.ITSCOM_PRICE));
		req.displayList = new Array();
		// M_Displayの取得(申し込み取得)
		const filter1 = new MDisplay();
		filter1.dispdivcd = MypageDispdivcdConstants.TOKOTON_SUPPORT_APPLICATION_TYPE;
		req.displayList.push(filter1);
		// M_Displayの取得(コース名取得)
		const filter2 = new MDisplay();
		filter2.dispdivcd = MypageDispdivcdConstants.TOKOTON_SUPPORT_COURSE_NAME;
		req.displayList.push(filter2);
		req.masterFlg = true;
		// サービスマスタ取得
		const target = new ContractServiceModel();
		target.serviceCd = MypageDispdivcdConstants.TOKOTON_SUPPORT_COURSE_NAME;
		req.serviceKey = new Array();
		req.serviceKey.push(target);
		// 有料サービス（料金シミュでは0円）
		req.generalList = new Array();
		req.generalList.push(new MGeneral(MypageCodeTypeConstants.PAID_SERVICE, null));
		return req;
	}

	/**
	 * 画面ウィザード完了APIモデル取得
	 */
	public getRegistApiModel(mngr: MypageWizardMngr, view: MypageBaseView): RegistInfoRequest{
		let cacheData: TokotonSupportWizardModel = mngr.getCache();
		const req: RegistInfoRequest = new RegistInfoRequest();
		const mailconstants = MAIL_CONSTANTS.find (mail => {
			return mail.view === 'とこサポ_追加';
		});
		// お客様メール
		req.customerId = mngr.getParams().customerId;
		req.customerMailId = mailconstants.customerMail;
		// 業務部門
		req.systemMailId = mailconstants.systemMail;
		// 問い合わせ
		req.inquiryId = mailconstants.inquiryMail;
		req.systemInfo = new InquiryMailInfo();
		req.systemInfo.appService = cacheData.m_ApplicationInfo.courseNm;
		req.systemInfo.startDate = cacheData.m_ApplicationInfo.applicationType;
		if (cacheData.m_ApplicationInfo.applicationType === '即日開始' && cacheData.m_ApplicationInfo.supportFlg)
			req.systemInfo.supportService = '希望する';
		else
			req.systemInfo.supportService = '希望しない';
		return req;
	}

	/**
	 * 画面ウィザードフローの中断判定：例：申し込みが完了していれば、完了済みの画面へ遷移
	 */
	public checkFlowBreak(mngr: MypageWizardMngr): string {
		let test: boolean = false;
		if (test) {
			return '/error';
		}
		return null;
	}

	/**
	 * 画面キャッシュの初期化データ取得
	 */
	public getInitCacheData(mngr: MypageWizardMngr): TokotonSupportWizardModel {
		let cacheData = new TokotonSupportWizardModel();
		cacheData.m_ApplicationInfo = new TokotonSupportAppModel();
		return cacheData;
	 }
	// --------------------------------------------------------------------------
}
/**
 * 画面ウィザードAPI用モデル
 */
export class TokotonSupportWizardModel {
	// public 変数	-----------------------------------------------------------
	public m_ServiceModel	: MDisplay					= null;			// サービス詳細モデル
	public m_YakkanListModel: YakkanListModel[]				= null; 		// 約款リストモデル
	public m_ApplicationInfo: TokotonSupportAppModel			= null;
	// ------------------------------------------------------------------------
}