import { ProgressBarModel } from '../../../models/ProgressBarModel';
import { MypageBaseView } from '../../base/MypageBaseView';
import { ProgressBarData } from '../../common/progress_bar/ProgressBarView';
import { WprModel } from '../../../../wpr-framework/view/deco/WprModel';
import { WizardModel } from '../../../models/WizardModel';
import { MypageWizardMngr } from '../../wizard/models/MypageWizardMngr';
import { WprDI_ScopeModel } from '../../../../wpr-framework/view/di/WprDI_ScopeModel';
import { Contract } from '../../../models/bss/contract/Contract';
import { ObjectUtil } from '../../../common/util/ObjectUtil';
import { MagazineModel } from '../../../service/models/apimodels/MagazineModel';


/**
 * マガジン解約申込画面
 */
export class MagazineCancelConfirmView extends MypageBaseView {
	
	// コンストラクタ  -----------------------------------------------------------
	public constructor() { super('MagazineCancelConfirmView'); }
	// --------------------------------------------------------------------------

	// モデル  ------------------------------------------------------------------
	@WprDI_ScopeModel('MypageWizardMngr')
	private m_MypageWizardMngr: MypageWizardMngr	= null;	// 画面ウィザード用モデル
	// --------------------------------------------------------------------------
	private m_MagazineModel: MagazineModel = null;						
	// override メソッド  --------------------------------------------------------
	/**
	 * コントロール定義設定
	 */
	public onRegisterConfig(): void {
	}

	/**
	 * アクション登録
	 */
	public onRegisterAction(): void {
		this.addAction('next', this.onNext);
		this.addAction('back', this.onBack);
	}

	/**
	 * ビュー表示通知
	 */
	public onShowView(): void {
		this.m_MypageWizardMngr.initView(this, (result) => {
			this.registAccessLog();
			this.setStorageItem('b_url', window.location.href)
			const sendData: ProgressBarData = new ProgressBarData();
			sendData.progressBarList = this.m_MypageWizardMngr.getProgressModelList(this);
			this.sendChildData('fP_ev_progressBarIntital', sendData);
			const contractId = this.m_MypageWizardMngr.getParams().contractId;
			this.m_MagazineModel = this.m_MypageWizardMngr.getCache().m_MagazineInfo;	// 初期化
			if (ObjectUtil.isNotNullOrEmptyStr(contractId)) {
				this.m_MagazineModel.contract = this.m_MypageWizardMngr.getContract(this, contractId);
			}
			this.refreshView();
		});
	}
	// --------------------------------------------------------------------------
	
	// アクション  ---------------------------------------------------------------
	/**
	 * 申込む
	*/
	public onNext(param: any): void {
		this.refreshModel();
		this.m_MypageWizardMngr.goNext(this);
	}

	/**
	 * 戻る
	 */
	public onBack(param: any): void {
		this.m_MypageWizardMngr.goBack(this);
	}

	// --------------------------------------------------------------------------
}
