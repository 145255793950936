import { OtokuModel } from './OtokuModel';
import { ItscomHomeCourseListModel } from './ItscomHomeCourseListModel';

/**
 * 基本情報
 */
export class BaseInfo {
	// public 変数	-----------------------------------------------------------
	public courseList: ItscomHomeCourseListModel;	// コースリスト(選択中)
	public course: string;							// コース名
	public rental: boolean;							// レンタル
	public purchase: boolean;						// 購入
	public yesOtoku: boolean;						// 希望する
	public noOtoku: boolean;						// 希望しない
	public terminalType: string;					// 端末タイプ
	public terminalForm: string;					// 端末形態
	public equipmentTypeCD: string;					// 端末コード
	public MacAddress: string;						// MACアドレス
	public ownerIhflag: boolean;					// オーナーIHフラグ
	public otokuFlag: boolean;						// お得パックフラグ
	public ihFlag: boolean;							// IHフラグ(コースがIHの場合、true)
	public tvFlag: boolean;							// TVフラグ(コースがTVの場合、true)
	// public otokuModel: OtokuModel;					// お得パックモデル(適用中）
	public otokuName: string;						// お得パック名
	public beforeFee: string;						// 変更前料金
	public afterFee: string;						// 変更後料金
	public totalBuyFee: string;						// 購入金額の合計
	public contractStatus: string;					// 契約ステータス
	public contractId: string;						// 契約Id
	// ------------------------------------------------------------------------
}
