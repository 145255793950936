import { Contract } from "../../bss/contract/Contract";
import { RelocateServiceListModel } from "./RelocateServiceListModel";

/**
 * 移設基本モデル
 */
export class RelocateBaseModel {
	// public 変数	-----------------------------------------------------------
	public tvList: RelocateServiceListModel[];		// 選択テレビ一覧
	public netList: RelocateServiceListModel[];		// 選択ネット一覧
	public phoneList: RelocateServiceListModel[];	// 選択電話一覧
	public tokotonFlg: boolean;						// とことんサポート加入済みフラグ
	public tokotonAddFlg: boolean;					// とことんサポート加入フラグ
	public tokosapoFee: string;						// とことんサポート月額
	public reformFlg: boolean;						// リフォームフラグ
	public confirmFlg: boolean;						// 同意フラグ
	public tvPortFlg: boolean;						// TV端子確認フラグ
    public desiredDate1: string;					// 第一希望
    public desiredDate2: string;					// 第二希望
    public desiredDate3: string;					// 第三希望
    public selectTime1: string;						// 第一希望　時間帯
    public selectTime2: string;						// 第二希望　時間帯
    public selectTime3: string;						// 第三希望　時間帯
	public beforeContact: string;					// 前日連絡先
	public sameDayContact: string;					// 当日連絡先
	public presentName: string;						// 立ち合い者名
	public amount: string;							// 工事費
	public reformDisp: string;						// リフォーム(表示用)
	public securityDisp: string;					// セキュリティ(表示用)
	public estimateFlg: boolean;					// 工事費お見積りフラグ
	// ------------------------------------------------------------------------
}
