// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@charset \"UTF-8\";\n/*\r\n * MyPAGE LINE連携同意画面CSS\r\n */\n/*アイコン用ヘッダー*/\n.LineAgree-liff-view .LineAgree-liff-card {\n  margin-top: -25px; }\n  @media screen and (max-width: 700px) {\n    .LineAgree-liff-view .LineAgree-liff-card {\n      margin-top: 50px; } }\n  .LineAgree-liff-view .LineAgree-liff-card ul {\n    padding-left: 30px; }\n  .LineAgree-liff-view .LineAgree-liff-card .LineAgree-notes-area {\n    background-color: #f1eded;\n    padding: 5px 15px 5px 5px;\n    border-radius: 10px; }\n\n.LineAgree-liff-view .LineAgree-icon-hearder {\n  margin-top: -55px;\n  position: absolute;\n  left: 45%; }\n  @media screen and (max-width: 700px) {\n    .LineAgree-liff-view .LineAgree-icon-hearder {\n      left: 15%; } }\n  .LineAgree-liff-view .LineAgree-icon-hearder img {\n    max-width: 30%; }\n    @media screen and (max-width: 700px) {\n      .LineAgree-liff-view .LineAgree-icon-hearder img {\n        max-width: 80%; } }\n\n.LineAgree-liff-view .LineAgree-backButton {\n  background: #FFFFFF;\n  border: 2px solid #F87619;\n  border-radius: 20px;\n  width: 220px;\n  height: 40px;\n  margin-top: 5px; }\n  .LineAgree-liff-view .LineAgree-backButton span {\n    font-style: normal;\n    font-weight: 700;\n    font-size: 16px;\n    line-height: 140%;\n    text-align: center;\n    color: #F87619; }\n\n.LineAgree-liff-view .LineAgree-text_trans span {\n  text-transform: none; }\n", ""]);
// Exports
exports.locals = {
	"liff-view": "LineAgree-liff-view",
	"liff-card": "LineAgree-liff-card",
	"notes-area": "LineAgree-notes-area",
	"icon-hearder": "LineAgree-icon-hearder",
	"backButton": "LineAgree-backButton",
	"text_trans": "LineAgree-text_trans"
};
module.exports = exports;
