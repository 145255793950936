import { WizardModel } from '../../models/WizardModel';
import { MypageWizardFlow } from './MypageWizardFlow';
import { GetViewInfoRequest } from '../../service/models/dto/mypagerenewal/GetViewInfoRequest';
import { MypageBaseView } from '../base/MypageBaseView';
import { MCommon } from '../../service/models/entity/primary/MCommon';
import { MypageWizardMngr } from './models/MypageWizardMngr';
import { AddTvComfirmView } from '../tv/new/add_tv_comfirm/AddTvComfirmView';
import { TvBaseModel } from '../../models/mypage/tv/TvBaseModel';
import { TvNewWizardModel } from './TvNewWizardFlow';
import { RegistInfoRequest } from '../../service/models/dto/mypagerenewal/RegistInfoRequest';
import { AddTvopSelectView } from '../tv/option/add_tvop_select/AddTvopSelectView';
import { AddTvopprovConfirmView } from '../tv/option/add_tvopprov_confirm/AddTvopprovConfirmView';
import { ModTvopConfirmView } from '../tv/option/mod_tvop_confirm/ModTvopConfirmView';
import { TvopCompleteView } from '../tv/option/tvop_complete/TvopCompleteView';
import { TvWizardModel } from '../../models/mypage/tv/TvWizardModel';
import MypageCodeTypeConstants from '../../common/MypageCodeTypeConstants';
import { ContractServiceModel } from '../../service/models/apimodels/ContractServiceModel';
import { MGeneral } from '../../service/models/entity/MGeneral';
import { MDisplay } from '../../service/models/entity/secondary/MDisplay';
import { AddTvopConfirmView } from '../tv/option/add_tvop_confirm/AddTvopConfirmView';
import { InquiryMailInfo } from '../../service/models/apimodels/InquiryMailInfo';
import { Contract } from '../../models/bss/contract/Contract';
import { ObjectUtil } from '../../common/util/ObjectUtil';
import MypageDispdivcdConstants from '../../common/MypageDispdivcdConstants';
import { MAIL_CONSTANTS } from '../../common/MyPageMailIdConstants';

/**
 * テレビオプション_ウィザードクラス
 */
export class TvOptionWizardFlow extends MypageWizardFlow {
	// private 変数  ------------------------------------------------------------
	private m_DefaultWizardFlow: WizardModel[]			= null;		// 申込画面ウィザードリスト
	private m_ProvisionalWizardFlow: WizardModel[]		= null;		// 仮申込画面ウィザードリスト
	private m_ModOptionWizardFlow: WizardModel[]		= null;		// 解約画面ウィザードリスト
	private m_StartViewName: string						= null;
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	public constructor() {
		super();
		this.m_StartViewName = new AddTvopSelectView().name;

		// 遷移パターン１
		this.m_DefaultWizardFlow = new Array();
		this.m_DefaultWizardFlow.push({stepNum: 0, title: '契約選択', viewName: null, path: this.getOldUrl() + 'Srvlist/Continfo_list/'});
		this.m_DefaultWizardFlow.push({stepNum: 1, title: '内容変更', viewName: new AddTvopSelectView().name, path: 'add_tvop_select'});
		this.m_DefaultWizardFlow.push({stepNum: 2, title: '内容確認', viewName: new AddTvopConfirmView().name, path: 'add_tvop_confirm'});
		this.m_DefaultWizardFlow.push({stepNum: 3, title: '変更申込み完了', viewName: new TvopCompleteView().name, path: 'add_tvop_complete'});
		
		// 遷移パターン１
		this.m_ProvisionalWizardFlow = new Array();
		this.m_ProvisionalWizardFlow.push(this.m_DefaultWizardFlow[0]);
		this.m_ProvisionalWizardFlow.push(this.m_DefaultWizardFlow[1]);
		this.m_ProvisionalWizardFlow.push({stepNum: 2, title: '内容確認', viewName: new AddTvopprovConfirmView().name, path: 'add_tvopprov_confirm'});
		this.m_ProvisionalWizardFlow.push({stepNum: 3, title: '変更申込み完了', viewName: new TvopCompleteView().name, path: 'add_tvopprov_complete'});
		
		// 遷移パターン１
		this.m_ModOptionWizardFlow = new Array();
		this.m_ModOptionWizardFlow.push(this.m_DefaultWizardFlow[0]);
		this.m_ModOptionWizardFlow.push(this.m_DefaultWizardFlow[1]);
		this.m_ModOptionWizardFlow.push({stepNum: 2, title: '内容確認', viewName: new ModTvopConfirmView().name, path: 'mod_tvop_confirm'});
		this.m_ModOptionWizardFlow.push({stepNum: 3, title: '変更申込み完了', viewName: new TvopCompleteView().name, path: 'mod_tvop_complete'});
		
	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * 画面ウィザードフロー名取得
	 */
	public getWizardFlowName(viewName: string): string {
		if (viewName === this.m_StartViewName)
			return this.constructor.name;
		return null;
	}
	
	/**
	 * 画面ウィザードリスト取得：契約状態で切り替えたり、選択肢で切り替えたり
	 */
	public getWizardFlow(mngr: MypageWizardMngr): WizardModel[] {
		let cacheData: TvWizardModel = mngr.getCache().m_TvWizardModel;
		//申込
		if (cacheData.defaultFlg)
			return this.m_DefaultWizardFlow;
		//仮申込
		else if (cacheData.provisionalFlg)
			return this.m_ProvisionalWizardFlow;
		//解約
		else if (cacheData.cancelFlg || cacheData.proCancelFlg)
			return this.m_ModOptionWizardFlow;
		return this.m_DefaultWizardFlow;
	}
	
	/**
	 * 画面ウィザードAPI用モデル取得：契約状態で切り替えたり、選択肢で切り替えたり
	 */
	public getWizardApiModel(mngr: MypageWizardMngr, view: MypageBaseView): GetViewInfoRequest {
		const req = new GetViewInfoRequest();
		req.customerId = mngr.getParams().customerId;
		req.displayList = new Array();
		req.displayList.push(new MDisplay(MypageDispdivcdConstants.TV_MAIJU_SERVICE, null));
		req.displayList.push(new MDisplay(MypageDispdivcdConstants.TV_FTTH_SERVICE, null));
		req.displayList.push(new MDisplay(MypageDispdivcdConstants.TV_OTHER_SERVICE, null));
		req.displayList.push(new MDisplay(MypageDispdivcdConstants.TV_TANAKO_SERVICE, null));
		req.displayList.push(new MDisplay(MypageDispdivcdConstants.TV_AP_SERVICE, null));
		req.displayList.push(new MDisplay(MypageDispdivcdConstants.TV_SERVICE, null));
		req.displayList.push(new MDisplay(MypageDispdivcdConstants.TV_OPTION, null));
		req.displayList.push(new MDisplay(MypageDispdivcdConstants.ITSCOMHOME_OTOKUNAME, null));
		req.displayList.push(new MDisplay(MypageDispdivcdConstants.ITSCOMHOME_SERVICESTATUS, null));
		req.displayList.push(new MDisplay(MypageDispdivcdConstants.ITSCOMHOME_TERMINALTYPE, null));
		req.displayList.push(new MDisplay(MypageDispdivcdConstants.ITSCOMHOME_TERMINALSTATUS, null));
		req.displayList.push(new MDisplay(MypageDispdivcdConstants.TV_OPTIONSTATUS, null));
		req.displayList.push(new MDisplay(MypageDispdivcdConstants.TV_OPTION_CHANNEL, null));
		req.yakkanList = new Array();
		req.yakkanList.push(new MGeneral(MypageCodeTypeConstants.IMPORTANT_EXPLANATION));
		req.commonList = new Array();
		req.commonList.push(new MCommon('tvコース名毎充', 'tv_service', 'new_plan', null, null));
		req.commonList.push(new MCommon('tvコース名FTTH', 'tv_service', 'ftth', null, null));
		req.commonList.push(new MCommon('tvコース名HFC', 'tv_service', 'hfc', null, null));
		req.commonList.push(new MCommon('tv追加表示対象', 'tv_service', 'disp_add', null, null));
		req.commonList.push(new MCommon('まいにち充実プラン', 'otoku', 'new_plan_cd', null, null));
		req.commonList.push(new MCommon('お得プラン', 'otoku', 'plan_cd', null, null));
		req.commonList.push(new MCommon('コース名1', 'hanyo1', null, null, null));
		req.commonList.push(new MCommon('コース名2', 'hanyo2', null, null, null));
		req.commonList.push(new MCommon('コース名3', 'hanyo3', null, null, null));
		req.commonList.push(new MCommon('コース名4', 'hanyo4', null, null, null));
		req.commonList.push(new MCommon('コース名5', 'hanyo5', null, null, null));
		req.commonList.push(new MCommon('新コース名1', 'new_plan_hanyo', 'hanyo1', null, null));
		req.commonList.push(new MCommon('新コース名2', 'new_plan_hanyo', 'hanyo2', null, null));
		req.commonList.push(new MCommon('新コース名3', 'new_plan_hanyo', 'hanyo3', null, null));
		req.commonList.push(new MCommon('警告表示対象', 'srvtv', 'prt_course_msg', null, null));
		req.commonList.push(new MCommon('', 'Add_net', 'equ', null, null));
		req.commonList.push(new MCommon('ダミーID', 'Dummy_Equipment', 'dummyid', null, null));
		req.commonList.push(new MCommon('マガジンオプション', 'srvtv', 'magazine_op', null, null));
		req.commonList.push(new MCommon('チューナーオプション', 'srvtv', 'tuner_op', null, null));
		req.commonList.push(new MCommon('wowwowオプション', 'opt', 'digital_wowow', null, null));
		req.commonList.push(new MCommon('HVスターチャンネル', 'opt', 'hv_channel', null, null));
		req.commonList.push(new MCommon('ロイヤルオプション', 'srvtv', 'royal_op', null, null));
		req.ownerFlg = true;
		req.contractFlg = true;
		req.statusCdList = new Array();
		req.statusCdList.push('0');
		req.statusCdList.push('1');
		req.statusCdList.push('2');
		req.statusCdList.push('5');
		req.paymentFlg = true;
		req.serviceKey = new Array();
		const target: ContractServiceModel = new ContractServiceModel();
		target.serviceCd = MypageDispdivcdConstants.TV_MAIJU_SERVICE;
		req.serviceKey.push(target);
		const target2: ContractServiceModel = new ContractServiceModel();
		target2.serviceCd = MypageDispdivcdConstants.TV_FTTH_SERVICE;
		req.serviceKey.push(target2);
		const target3: ContractServiceModel = new ContractServiceModel();
		target3.serviceCd = MypageDispdivcdConstants.TV_OTHER_SERVICE;
		req.serviceKey.push(target3);
		const target4: ContractServiceModel = new ContractServiceModel();
		target4.serviceCd = MypageDispdivcdConstants.TV_TANAKO_SERVICE;
		req.serviceKey.push(target4);
		const target5: ContractServiceModel = new ContractServiceModel();
		target5.serviceCd = MypageDispdivcdConstants.TV_AP_SERVICE;
		req.serviceKey.push(target5);
		const target6: ContractServiceModel = new ContractServiceModel();
		target6.serviceCd = MypageDispdivcdConstants.TV_SERVICE;
		target6.contractId = mngr.getParams().contractId;
		req.serviceKey.push(target6);
		req.masterFlg = true;
		return req;
	}
	
	/**
	 * 画面ウィザード完了APIモデル取得
	 */
	public getRegistApiModel(mngr: MypageWizardMngr, view: MypageBaseView): RegistInfoRequest{
		const req: RegistInfoRequest = new RegistInfoRequest();
		const cacheData = mngr.getCache();
		req.customerId = mngr.getParams().customerId;
		req.systemInfo = new InquiryMailInfo();
		//契約情報取得
		const contractId = mngr.getParams().contractId;
		let contract = null;
		if (ObjectUtil.isNotNullOrEmptyStr(contractId)) {
			contract = mngr.getContract(view, contractId);
		}
		req.systemInfo.contractId = contract.contractID;
		let optionInfo = null;
		for (const option of cacheData.m_TvOptionListModel) {
			if (option.select === true) 
				optionInfo = option;
		}
		//申込
		if (cacheData.m_TvWizardModel.defaultFlg) {
			const mailconstants = MAIL_CONSTANTS.find (mail => {
				return mail.view === 'TVオプション_追加';
			});
			req.customerMailId = mailconstants.customerMail;
		}
		//仮申込
		else if (cacheData.m_TvWizardModel.provisionalFlg) {
			const mailconstants = MAIL_CONSTANTS.find (mail => {
				return mail.view === 'TVオプション_追加_仮申込';
			});
			req.customerMailId = mailconstants.customerMail;
			req.systemMailId = mailconstants.systemMail;
			req.inquiryId = mailconstants.inquiryMail;
			req.systemInfo.startDate = optionInfo.applyDate;
			req.systemInfo.optionName = optionInfo.option;


		}
		//解約
		else if (cacheData.m_TvWizardModel.cancelFlg) {
			const mailconstants = MAIL_CONSTANTS.find (mail => {
				return mail.view === 'TVオプション_解約';
			});
			req.customerMailId = mailconstants.customerMail;
		}
		//仮解約
		else if (cacheData.m_TvWizardModel.proCancelFlg) {
			const mailconstants = MAIL_CONSTANTS.find (mail => {
				return mail.view === 'TVオプション_仮解約';
			});
			req.customerMailId = mailconstants.customerMail;
			req.systemMailId = mailconstants.systemMail;
			req.inquiryId = mailconstants.inquiryMail;
			req.systemInfo.optionName = optionInfo.option;
		}
		if (!cacheData.m_TvBaseModel.dummy)
			req.systemInfo.equipmentInfo = 'ダミーの端末情報が登録されているため、お客様へ、STB-ID、CASカード番号、またはMACアドレスのヒアリングをお願いいたします。';
		else
			req.systemInfo.equipmentInfo = '';
		return req;
	}

	/**
	 * 画面ウィザードフローの中断判定：例：申し込みが完了していれば、完了済みの画面へ遷移
	 */
	public checkFlowBreak(mngr: MypageWizardMngr): string {
		let test: boolean = false;
		if (test) {
			return '/error';
		}
		return null;
	}

	/**
	 * 画面キャッシュの初期化データ取得
	 */
	public getInitCacheData(mngr: MypageWizardMngr): TvNewWizardModel {
		let cacheData = new TvNewWizardModel();
		cacheData.m_TvBaseModel = new TvBaseModel();
		cacheData.m_TvWizardModel = new TvWizardModel();
		cacheData.m_TvOptionListModel = new Array();
		cacheData.m_Contract = new Contract();
		return cacheData;
	}
	// --------------------------------------------------------------------------
}