import { IWprControlMode } from '../mode/IWprControlMode';
import { WprComponentModeInfo } from '../mode/WprComponentModeInfo';
import { WprControlVisibility } from '../props/IWprControlState';
import { IWprLayoutModeProps, IWprNoNameLayoutModeProps } from '../props/IWprLayoutProps';
import { WprBaseLayoutComponent } from './WprBaseLayoutComponent';
import { WprBaseNoNameLayoutComponent } from './WprBaseNoNameLayoutComponent';

/**
 * レイアウト基本コンポーネント
 */
export abstract class WprBaseLayoutModeComponent<T extends IWprLayoutModeProps, U> extends WprBaseLayoutComponent<T, U> implements IWprControlMode {
	// private 変数  ------------------------------------------------------------
	private m_Visible: boolean				= false;	// 表示状態
	private m_ModeInfo: WprLayoutModeInfo	= null;		// レイアウトモード情報
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	constructor(props: T) {
		super(props);
		this.props.view.addModeComponet(this);
		this.m_ModeInfo = new WprLayoutModeInfo(this.props.mode);
		if (!this.controlInfo) {
			this.state = {
				value: null,
				style: null,
				className: null,
				visible: false,
				modeClassName: null,
				visibility: WprControlVisibility.VISIBLE,
				option1: null,
				option2: null,
				option3: null,
				numOption1: 0,
				numOption2: 0,
				boolOption1: false,
				boolOption2: false
			};
		}
	}
	// --------------------------------------------------------------------------

	// protected メソッド  -------------------------------------------------------
	/**
	 * モード保持フラグ取得
	 * @returns モード保持フラグ
	 */
	protected hasMode() {
		return this.m_ModeInfo.hasMode();
	}
	// --------------------------------------------------------------------------

	// IWprControlModeの実装  ----------------------------------------------------
	/**
	 * モード情報リスト取得
	 */
	 public getModeInfoList(): WprComponentModeInfo[] {
		return this.m_ModeInfo.getModeInfoList();
	}

	/**
	 * 非表示フラグ取得
	 * @returns 非表示フラグ
	 */
	public getInvisible(): boolean {
		return this.props.invisible;
	}

	/**
	 * 表示状態設定処理
	 * @param visible 表示状態
	 */
	public setVisible(visible: boolean): void {
		if (this.isMount === false)
			return;
//		if (this.m_Visible !== visible) {
			this.m_Visible = visible;
			this.setState({
				visible: this.m_Visible
			});
//		}
	}
	// --------------------------------------------------------------------------
}

/**
 * レイアウト基本コンポーネント
 */
 export abstract class WprBaseNoNameLayoutModeComponent<T extends IWprNoNameLayoutModeProps, U> extends WprBaseNoNameLayoutComponent<T, U> implements IWprControlMode {
	// private 変数  ------------------------------------------------------------
	private m_Visible: boolean				= true;	// 表示状態
	private m_ModeInfo: WprLayoutModeInfo	= null;		// レイアウトモード情報
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	constructor(props: T) {
		super(props);
		this.props.view.addModeComponet(this);
		this.m_ModeInfo = new WprLayoutModeInfo(this.props.mode);
	}
	// --------------------------------------------------------------------------

	// override メソッド  -------------------------------------------------------
	/**
	 * マウント通知
	 */
	 public onDidMount() {
		super.onDidMount();
		this.props.view.mountModeComponent();
	}
	// --------------------------------------------------------------------------

	// protected メソッド  -------------------------------------------------------
	/**
	 * モード保持フラグ取得
	 * @returns モード保持フラグ
	 */
	 protected hasMode() {
		return this.m_ModeInfo.hasMode();
	}
	// --------------------------------------------------------------------------

	// IWprControlModeの実装  ----------------------------------------------------
	/**
	 * モード情報リスト取得
	 */
	 public getModeInfoList(): WprComponentModeInfo[] {
		return this.m_ModeInfo.getModeInfoList();
	}

	/**
	 * 非表示フラグ取得
	 * @returns 非表示フラグ
	 */
	public getInvisible(): boolean {
		return this.props.invisible;
	}

	/**
	 * 表示状態設定処理
	 * @param visible 表示状態
	 */
	public setVisible(visible: boolean): void {
		if (this.isMount === false)
			return;
//		if (this.m_Visible !== visible) {
			this.m_Visible = visible;
			this.setState({
				visible: this.m_Visible
			});
//		}
	}
	// --------------------------------------------------------------------------
}

/**
 * レイアウト基本コンポーネント
 */
 class WprLayoutModeInfo {
	// private 変数  ------------------------------------------------------------
	private m_ModeInfoList: WprComponentModeInfo[]	= new Array();		// コンポーネントモード情報リスト
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	constructor(mode: string) {
		this.initMode(mode);
	}
	// --------------------------------------------------------------------------

	// public メソッド  ----------------------------------------------------------
	/**
	 * モード保持フラグ取得
	 * @returns モード保持フラグ
	 */
	public hasMode() {
		if (this.m_ModeInfoList.length > 0)
			return true;
		return false;
	}

	/**
	 * モード情報リスト取得
	 */
	 public getModeInfoList(): WprComponentModeInfo[] {
		return this.m_ModeInfoList;
	}
	// --------------------------------------------------------------------------

	// private メソッド  --------------------------------------------------------
	/**
	 * モード情報初期化処理
	 * @param modes モード
	 */
	 private initMode(modes: string) {
		if (modes) {
			const mode = modes.trim();
			if (mode.includes(',') === true) {
				mode.split(',').forEach(mbuf => {
					this.addModeInfoList(mbuf.trim());
				});
			}
			else {
				this.addModeInfoList(mode);
			}
		}
	}

	/**
	 * モード情報リスト追加
	 * @param mode モード
	 */
	private addModeInfoList(mode: string): void {
		if (mode.includes('.') === true) {
			const list = mode.split('.');
			this.m_ModeInfoList.push(new WprComponentModeInfo(list[0].trim(), list[1].trim()));
		}
		else {
			this.m_ModeInfoList.push(new WprComponentModeInfo(undefined, mode));
		}
	}
	// --------------------------------------------------------------------------
}
