import { BaseService } from '../../../service/base/BaseService';
import { WprModel } from '../../../../wpr-framework/view/deco/WprModel';
import { WprDI_ScopeModel } from '../../../../wpr-framework/view/di/WprDI_ScopeModel';
import { Customer } from '../../../models/bss/customer/Customer';
import { NameChangeAppModel } from '../../../service/models/apimodels/NameChangeAppModel';
import { UpdateNameChangePaymentRequest } from '../../../service/models/dto/mypagerenewal/UpdateNameChangePaymentRequest';
import { NameChangeService } from '../../../service/NameChangeService';
import { MypageBaseView } from '../../base/MypageBaseView';
import { ProgressBarData } from '../../common/progress_bar/ProgressBarView';
import { MypageWizardMngr } from '../../wizard/models/MypageWizardMngr';

/**
 * 新名義人申込み内容確認
 */
export class NameChangeNewConfirmView extends MypageBaseView {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() { super('NameChangeNewConfirmView'); }
	// --------------------------------------------------------------------------

	// モデル  ------------------------------------------------------------------
	@WprDI_ScopeModel('MypageWizardMngr')
	private m_MypageWizardMngr: MypageWizardMngr = null;	// 画面ウィザード管理クラス
	@WprModel('NameChangeAppModel')
	private m_EntryInfo: NameChangeAppModel = null;			// 名義変更申込み情報モデル
	@WprModel('changePayment')
	private m_ChangePayment: string = null;	// 支払方法
	@WprModel('newPayment')
	private m_NewPayment: string = null;	// 新しい支払方法

	private m_Customer: Customer = null;		// 顧客情報

	private m_NameChangeService: NameChangeService	= new NameChangeService();	// 名義変更サービス
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * コントロール定義設定
	 */
	public onRegisterConfig(): void {
		this.addConfig('nameChangeKbn', '名義変更区分', 'NameChangeAppModel.nameChangeInfo', { converter: '名義変更区分' });
		this.addConfig('newName', '新名義人名', 'NameChangeAppModel.nameChangeInfo');
		this.addConfig('newNameKana', '新名義人名カナ', 'NameChangeAppModel.nameChangeInfo');
		this.addConfig('relationShip', '続柄', 'NameChangeAppModel.nameChangeInfo');
		this.addConfig('gender', '性別', 'NameChangeAppModel.nameChangeInfo');
		this.addConfig('birthdayStr', '生年月日', 'NameChangeAppModel');
		this.addConfig('phoneNumber1', '電話番号1', 'NameChangeAppModel.nameChangeInfo');
		this.addConfig('phoneNumber2', '電話番号2', 'NameChangeAppModel.nameChangeInfo');
		this.addConfig('newMail', 'メールアドレス', 'NameChangeAppModel.nameChangeInfo');
		this.addConfig('changePayment', '支払方法変更', 'changePayment', { bindModel: true });
		this.addConfig('newPayment', '新しい支払方法', 'newPayment', { bindModel: true });
		this.addConfig('deliveryAddress', '送付先住所', 'NameChangeAppModel.nameChangePayment');
	}
	
	/**
	 * ビュー表示通知
	 */
	 public onShowView(): void {
		this.m_MypageWizardMngr.initView(this, (result) => {
			this.registAccessLog();
			this.setStorageItem('b_url', window.location.href)
			const sendData: ProgressBarData = new ProgressBarData();
			sendData.progressBarList = this.m_MypageWizardMngr.getProgressModelList(this);
			this.sendChildData('fP_ev_progressBarIntital', sendData);
			this.m_EntryInfo = this.m_MypageWizardMngr.getCache().nameChangeAppInfo;
			
			if(this.m_EntryInfo.nameChangePayment.changePaymentFlg) {
				this.m_ChangePayment = '新しい支払方法を登録する';
				this.setViewMode('新しい支払方法', '表示');
				if(this.m_EntryInfo.nameChangePayment.creditFlg) {
					this.m_NewPayment = 'クレジットカードを登録する';
					this.setViewMode('送付先', '非表示');
				} else {
					this.m_NewPayment = '口座を登録する';
					this.setViewMode('送付先', '表示');
					if (this.m_EntryInfo.nameChangePayment.contractAddressFlg) {
						this.m_Customer = this.m_MypageWizardMngr.getViewInfo().customer;
						this.m_EntryInfo.nameChangePayment.deliveryAddress = this.m_Customer.address[0].addressLine1 + this.m_Customer.address[0].addressLine2 
							+ '　' + this.m_Customer.address[0].addressLine3 + '　' + this.m_Customer.address[0].roomNumber;
					} else {
						this.m_EntryInfo.nameChangePayment.deliveryAddress =  '〒' + this.m_EntryInfo.paymentPostCode.substring(0,3) + '-' + this.m_EntryInfo.paymentPostCode.substring(3,7) + '　' + this.m_EntryInfo.paymentAddress + '　' + this.m_EntryInfo.paymentBuilding + '　' + this.m_EntryInfo.paymentRoomNum;
					}
				}
			} else {
				this.m_ChangePayment = '従来の支払方法を利用する';
				this.setViewMode('新しい支払方法', '非表示');
			}
			this.setViewMode('名義変更区分', this.m_EntryInfo.nameChangeInfo.nameChangeKbn);
			this.refreshView();
		});
	}

	/**
	 * アクション登録
	 */
	public onRegisterAction(): void {
		this.addAction('next', this.onNext);
		this.addAction('back', this.onBack);
	}
	// --------------------------------------------------------------------------
	
	// アクション  ---------------------------------------------------------------
	/**
	 * 次へ
	 */
	public onNext(param: any): void {
		const req: UpdateNameChangePaymentRequest = new UpdateNameChangePaymentRequest();
		req.nameChangeId = this.m_EntryInfo.nameChangeId;
		req.customerId = this.m_EntryInfo.customerId;
		req.newMail = this.m_EntryInfo.nameChangeInfo.newMail;
		req.nameChangePayment = this.m_EntryInfo.nameChangePayment;
		this.m_NameChangeService.updateNameChange(this, req, (result) => {
			if (result && result.status === BaseService.SERVICE_SUCCESS_STATUS) {
				this.m_MypageWizardMngr.goNext(this);
			} else {
				this.setCookie('changeStatus', '3');
				this.pushPath('/nameChangeError');
			}
		});
	}

	/**
	 * 戻る
	 */
	public onBack(param: any): void {
		// this.m_EntryInfo.nameChangeInfo.birthday = null;
		// this.m_EntryInfo.birthdayStr = null;
		// this.m_EntryInfo.nameChangePayment.deliveryAddress = null;
		this.m_MypageWizardMngr.goBack(this);
	}
	// --------------------------------------------------------------------------
}
