import { Card, Typography } from '@material-ui/core';
import React, { ReactNode } from 'react';
import { MatButton } from '../../../../wpr-framework/component/controls/material/MatButton';
import { WprBaseViewComponent } from '../../../../wpr-framework/view/component/WprBaseViewComponent';
import { Footer } from '../../common/footer/Footer';
import { Header } from '../../common/header/Header';
import { S_01_005View } from './S_01_005View';
import styles from './S_01_005.scss';
import { CompleteDialog } from '../dialog/complete_dialog/CompleteDialog';

/**
 * セッションエラー用コンポーネント
 */
export class S_01_005 extends WprBaseViewComponent {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: any) { super(new S_01_005View(), props); }
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** セッションエラービュー情報 */
	public get viewInfo(): S_01_005View	{ return this.view as S_01_005View;	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * スタイルオブジェクト取得
	*/
	public getStylesObject(): any { return styles; }

	// override メソッド  --------------------------------------------------------
	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		return (
			// <div className='display'>
			// 	<Header/>
			// 	<div className='common'>
					<Card className='card card_padding'>
						<Typography className='childCenter'>セッションエラー</Typography>
						<Typography className='childCenter'>再度マイページにアクセスしてください</Typography>
						<div  className='childCenter'>
							<MatButton name='b_MyPAGE_Top' color='primary'  className='NextButton' view={this.view}>マイページトップページ</MatButton>
						</div>
					</Card>
			// 	</div>
			// 	// <Footer/>
			// </div>
		);
	}
	// --------------------------------------------------------------------------
}
