import { WprBaseViewMultiModeInfo } from '../../../../../wpr-framework/mode/WprBaseViewMultiMode';
import { WprMultiMode } from '../../../../../wpr-framework/mode/WprMultiMode';
import { WprControlInfo } from '../../../../../wpr-framework/view/control/WprControlInfo';
import { WprDI_Control } from '../../../../../wpr-framework/view/di/WprDI_Control';
import { ObjectUtil } from '../../../../common/util/ObjectUtil';
import { AddMeshWifiSelectView } from '../AddMeshWifiSelectView';

/**
 * 設置方法
 */
export class EstablishmentCheck extends WprBaseViewMultiModeInfo<AddMeshWifiSelectView> {
	// DI情報  ------------------------------------------------------------------
	@WprDI_Control
	private m_check1: WprControlInfo	= null;	// 設置方法１
	@WprDI_Control
	private m_check2: WprControlInfo	= null;	// 設置方法２
	@WprDI_Control
	private m_confirmCheck: WprControlInfo	= null;	// 同意確認
	@WprDI_Control
	private m_desiredDate1: WprControlInfo	= null;	// 第一希望
	@WprDI_Control
	private m_selectTime1: WprControlInfo	= null;	// 第一希望　時間帯
	@WprDI_Control
	private m_desiredDate2: WprControlInfo	= null;	// 第二希望
	@WprDI_Control
	private m_selectTime2: WprControlInfo	= null;	// 第二希望　時間帯
	@WprDI_Control
	private m_desiredDate3: WprControlInfo	= null;	// 第三希望
	@WprDI_Control
	private m_selectTime3: WprControlInfo	= null;	// 第三希望　時間帯
	@WprDI_Control
	private m_tokosupConfirmCheck: WprControlInfo	= null;	// とこサポ同意確認
	@WprDI_Control
	private m_beforeContact: WprControlInfo	= null;	// 前日連絡先
	@WprDI_Control
	private m_sameDayContact: WprControlInfo	= null;	// 当日連絡先
	@WprDI_Control
	private m_name: WprControlInfo	= null;	// 立ち合い者名
	
	// --------------------------------------------------------------------------

	
	
	// コンストラクタ  -----------------------------------------------------------
	public constructor() {
		super('EstablishmentCheckMode');
	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * モードリスト取得（この画面モードで使用する全てのモードを定義する）
	 * @param list モードリスト
	 */
	public useModeList(list: string[]): void {
		list.push('切替可(check1)');
		list.push('切替不可(check1)');
		list.push('切替可(check2)');
		list.push('切替不可(check2)');
		list.push('次に遷移するボタン活性');
		list.push('次に遷移するボタン非活性');
	}

	/**
	 * フィールド定義を登録処理
	 */
	public onRegisterControl(): void {
		this.addControl('check1', {
			check: true,
			readOnly: [ { mode: '切替不可(check1)', value: true }, { mode: '切替可(check1)', value: false } ]
		});
		this.addControl('check2', {
			check: true,
			readOnly: [ { mode: '切替不可(check2)', value: true }, { mode: '切替可(check2)', value: false } ]
		});
		this.addControl('next', { enabled: [ { mode: '次に遷移するボタン活性', value: true }, { mode: 'other', value: false } ] });
		this.addControl('next1', { enabled: [ { mode: '次に遷移するボタン活性', value: true }, { mode: 'other', value: false } ] });
	}

	/**
	 * 画面の状態をチェックしてモードを変更する
	 * @param curMode 現在のモード
	 * @param newMode 新しいモード
	 */
	public onCheckMultiMode(curMode: WprMultiMode, newMode: WprMultiMode): void {
		if (this.m_check1.value && !this.m_check2.value) {
			newMode.addMode('切替不可(check1)');
			newMode.addMode('切替可(check2)');
			if(this.m_confirmCheck.value === true && this.m_tokosupConfirmCheck.value === true){
				newMode.addMode('次に遷移するボタン活性');
			} else {
				newMode.addMode('次に遷移するボタン非活性');
			}
		}
		else if (!this.m_check1.value && this.m_check2.value) {
			newMode.addMode('切替可(check1)');
			newMode.addMode('切替不可(check2)');
			if(this.m_confirmCheck.value && this.m_tokosupConfirmCheck.value && ObjectUtil.isNotNullOrEmptyStr(this.m_desiredDate1.value) && ObjectUtil.isNotNullOrEmptyStr(this.m_selectTime1.value) 
					&& ObjectUtil.isNotNullOrEmptyStr(this.m_desiredDate2.value) && ObjectUtil.isNotNullOrEmptyStr(this.m_selectTime2.value) 
					&& ObjectUtil.isNotNullOrEmptyStr(this.m_desiredDate3.value) && ObjectUtil.isNotNullOrEmptyStr(this.m_selectTime3.value)
					&& ObjectUtil.isNotNullOrEmptyStr(this.m_beforeContact.value) && ObjectUtil.isNotNullOrEmptyStr(this.m_sameDayContact.value) && ObjectUtil.isNotNullOrEmptyStr(this.m_name.value)){
				newMode.addMode('次に遷移するボタン活性');
			}else{
				newMode.addMode('次に遷移するボタン非活性');
			}
		}
	}
	// --------------------------------------------------------------------------
}
