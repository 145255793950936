import React, { ReactNode } from 'react';
import { WprBaseViewComponent } from '../../../../wpr-framework/view/component/WprBaseViewComponent';
import { ConstrConfirmView } from './ConstrConfirmView';
import { Typography, Divider, Grid } from '@material-ui/core';
import { MatButton } from '../../../../wpr-framework/component/controls/material/MatButton';
import { MatTypography } from '../../../../wpr-framework/component/controls/material/MatTypography';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { MatTextField } from '../../../../wpr-framework/component/controls/material/MatTextField';
import { MatSelect } from '../../../../wpr-framework/component/controls/material/MatSelect';
import styles from './ConstrConfirm.scss';
import { WprContainer } from '../../../../wpr-framework/component/controls/WprContainer';

/**
 * 工事日程確認画面用コンポーネント
 */
export class ConstrConfirm extends WprBaseViewComponent {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: any) { super(new ConstrConfirmView(), props); }
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** 工事日程確認画面ビュー情報 */
	public get viewInfo(): ConstrConfirmView	{ return this.view as ConstrConfirmView;	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * スタイルオブジェクト取得
	*/
	public getStylesObject(): any { return styles; }

	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		return (
			<>
			<div className='pageCard'>
				<div className='main card'>
					<div className='pageTitle'>
						<div className='title_left'>
							<MatTypography name='title' className='title_text' view={this.view}/>
						</div>
					</div>
					<div className='feeTable inputArea' style={{marginTop: '30px'}}>	
						<Grid container className='feeRow'>
							<Grid item xs={12} md={4} className='feeTitle'>
								<div>希望予定日</div>
							</Grid>
							<Grid item xs={12} md={8} className='feeVal courseValue'>
								<div className='flex'>
									<MatTypography name={'date'} view={this.view}></MatTypography>
									<span style={{fontWeight: '500'}}>（</span>
									<MatTypography name={'dayOfWeek'} view={this.view} style={{fontWeight: '500'}}></MatTypography>
									<span style={{fontWeight: '500'}}>）</span>
									<MatTypography name={'time'} view={this.view} ></MatTypography>
									<Typography style={{fontWeight: '500'}}>の間到着</Typography>
								</div>

							</Grid>
						</Grid>	
					</div>
					<ul className='ul_kome attention_fontSize'>
						<li>
							弊社担当が到着するおおよその時間帯目安となります。
						</li>
						ご案内事項がある場合、弊社担当よりご連絡を差し上げる場合がございます。
					</ul>
					<div className='feeTable inputArea' style={{marginTop: '30px'}}>	
						<Grid container className='feeRow'>
							<Grid item xs={12} md={4} className='feeTitle'>
								<div>前日連絡先</div>
							</Grid>
							<Grid item xs={12} md={8} className='feeVal courseValue'>
								<MatTypography name='beforeContact' className='itemUnit' view={this.view} />
							</Grid>
						</Grid>	
						<Grid container className='feeRow'>
							<Grid item xs={12} md={4} className='feeTitle'>
								<div>当日連絡先</div>
							</Grid>
							<Grid item xs={12} md={8} className='feeVal courseValue'>
								<MatTypography name='sameDayContact' className='itemUnit' view={this.view} />
							</Grid>
						</Grid>
						<Grid container className='feeRow'>
							<Grid item xs={12} md={4} className='feeTitle'>
								<div>当日立ち合い者</div>
							</Grid>
							<Grid item xs={12} md={8} className='feeVal courseValue'>
								<MatTypography name='name' className='itemUnit' view={this.view} />
							</Grid>
						</Grid>
					</div>
				</div>
				<Divider />
				<div className='pageFooter footer-space'>
					<div>
						<MatButton name='next' view={this.view} className='orangeButton orangeButton_parts' >
							この予定日で申し込む
							<NavigateNextIcon />
						</MatButton>
					</div>
					<div>
						<MatButton name='back' view={this.view} className='backButton' >
							<NavigateBeforeIcon />
							戻る
						</MatButton>

					</div>
				</div>
			</div>
			</>
		);
	}
	// --------------------------------------------------------------------------
}
