import { FormEvent, SyntheticEvent } from 'react';
import { WprRowInfo } from '../component/props/WprRowInfo';
import { WprReflectUtil } from '../util/WprReflectUtil';
import { WprBaseViewCore } from '../view/WprBaseViewCore';
import { WprBaseViewInfo } from '../view/WprBaseViewInfo';
import { WprBaseObject } from '../WprBaseObject';

/**
 * イベントアクション情報
 */
export class WprEventActionInfo extends WprBaseObject {
	// private 変数  ------------------------------------------------------------
	private m_Name: string 												= null;		// イベントアクション名称
	private m_ListName: string 											= null;		// リスト名
	private m_ViewCore: WprBaseViewCore									= null;		// ビュー情報
	private m_PropertyName: string										= null;		// プロパティ名称
	private m_MethodMap: Map<string, (name: any, event: any) => void> 	= new Map<string, (name: any, event: any) => void>();	// メソッドマップ
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	public constructor(name: string, viewCore: WprBaseViewCore, listName: string=null) {
		super();
		this.m_Name = name;
		this.m_ViewCore = viewCore;
		this.m_ListName = listName;
	}
	// --------------------------------------------------------------------------

	// public メソッド  ----------------------------------------------------------
	/**
	 * イベントメソッド追加
	 * @param eventName イベント名
	 * @param actionMethod アクションメソッド
	 * @returns falseの場合、重複している
	 */
	public addEventMethod(eventName: string, actionMethod: (name: any, event: any) => void): void {
		if (this.m_MethodMap.has(eventName) === true)
			this.addErrorLog(`addEventAction:イベントが重複しています。[${name}:${eventName}]`);
		else if (WprReflectUtil.checkProperty(this, eventName) === true)
			this.m_MethodMap.set(eventName, actionMethod);
		else
			this.m_ViewCore.addErrorLog(`addEventAction:イベント名に誤りがあります。(${eventName})`);
	}

	/**
	 * イベント情報取得
	 * @param event イベント情報
	 * @returns イベント情報
	 */
	public getEvents(events?: any): any {
		if (events === undefined)
			events = {};
		this.m_MethodMap.forEach((method, name) => {
			events[name] = this[name].bind(this);
		});
		return events;
	}
	// --------------------------------------------------------------------------

	// private メソッド  --------------------------------------------------------
	/**
	 * イベントメソッド呼出し処理
	 * @param event イベント情報
	 */
	private callEventMethod(event: any, name: string): void {
		if (this.m_ListName == null) {
			this.m_MethodMap.get(name).bind(this.m_ViewCore)(this.m_Name, event);
		}
		else {
			const element = event.currentTarget;
			const data = element.attributes['data-row'];
			if (data) {
				const idx = Number(data.value);
				const linfo = this.m_ViewCore.getListInfo(this.m_ListName);
				const row = linfo.listData[idx];
				const rinfo = new WprRowInfo(this.m_ListName, null, idx, row, this.m_ViewCore as WprBaseViewInfo);
				this.m_MethodMap.get(name).bind(this.m_ViewCore)(this.m_Name, rinfo, event);
			}
		}
	}
	// --------------------------------------------------------------------------

	// イベントハンドラ  ---------------------------------------------------------
	// Clipboard Events
	public onCopy						(event: ClipboardEvent		): void { this.callEventMethod(event, 'onCopy'						); }
	public onCopyCapture				(event: ClipboardEvent		): void { this.callEventMethod(event, 'onCopyCapture'				); }
	public onCut						(event: ClipboardEvent		): void { this.callEventMethod(event, 'onCut'						); }
	public onCutCapture					(event: ClipboardEvent		): void { this.callEventMethod(event, 'onCutCapture'				); }
	public onPaste						(event: ClipboardEvent		): void { this.callEventMethod(event, 'onPaste'						); }
	public onPasteCapture				(event: ClipboardEvent		): void { this.callEventMethod(event, 'onPasteCapture'				); }

	// Composition Events
	public onCompositionEnd				(event: CompositionEvent	): void { this.callEventMethod(event, 'onCompositionEnd'			); }
	public onCompositionEndCapture		(event: CompositionEvent	): void { this.callEventMethod(event, 'onCompositionEndCapture'		); }
	public onCompositionStart			(event: CompositionEvent	): void { this.callEventMethod(event, 'onCompositionStart'			); }
	public onCompositionStartCapture	(event: CompositionEvent	): void { this.callEventMethod(event, 'onCompositionStartCapture'	); }
	public onCompositionUpdate			(event: CompositionEvent	): void { this.callEventMethod(event, 'onCompositionUpdate'			); }
	public onCompositionUpdateCapture	(event: CompositionEvent	): void { this.callEventMethod(event, 'onCompositionUpdateCapture'	); }

	// Focus Events
	public onFocus						(event: FocusEvent			): void { this.callEventMethod(event, 'onFocus'						); }
	public onFocusCapture				(event: FocusEvent			): void { this.callEventMethod(event, 'onFocusCapture'				); }
	public onBlur						(event: FocusEvent			): void { this.callEventMethod(event, 'onBlur'						); }
	public onBlurCapture				(event: FocusEvent			): void { this.callEventMethod(event, 'onBlurCapture'				); }

	// Form Events
	public onChange						(event: FormEvent			): void { this.callEventMethod(event, 'onChange'					); }
	public onChangeCapture				(event: FormEvent			): void { this.callEventMethod(event, 'onChangeCapture'				); }
	public onBeforeInput				(event: FormEvent			): void { this.callEventMethod(event, 'onBeforeInput'				); }
	public onBeforeInputCapture			(event: FormEvent			): void { this.callEventMethod(event, 'onBeforeInputCapture'		); }
	public onInput						(event: FormEvent			): void { this.callEventMethod(event, 'onInput'						); }
	public onInputCapture				(event: FormEvent			): void { this.callEventMethod(event, 'onInputCapture'				); }
	public onReset						(event: FormEvent			): void { this.callEventMethod(event, 'onReset'						); }
	public onResetCapture				(event: FormEvent			): void { this.callEventMethod(event, 'onResetCapture'				); }
	public onSubmit						(event: FormEvent			): void { this.callEventMethod(event, 'onSubmit'					); }
	public onSubmitCapture				(event: FormEvent			): void { this.callEventMethod(event, 'onSubmitCapture'				); }
	public onInvalid					(event: FormEvent			): void { this.callEventMethod(event, 'onInvalid'					); }
	public onInvalidCapture				(event: FormEvent			): void { this.callEventMethod(event, 'onInvalidCapture'			); }

	// Image Events
	public onLoad						(event: SyntheticEvent		): void { this.callEventMethod(event, 'onLoad'						); }
	public onLoadCapture				(event: SyntheticEvent		): void { this.callEventMethod(event, 'onLoadCapture'				); }
	public onError						(event: SyntheticEvent		): void { this.callEventMethod(event, 'onError'						); }
	public onErrorCapture				(event: SyntheticEvent		): void { this.callEventMethod(event, 'onErrorCapture'				); }

	// Keyboard Events
	public onKeyDown					(event: KeyboardEvent		): void { this.callEventMethod(event, 'onKeyDown'					); }
	public onKeyDownCapture				(event: KeyboardEvent		): void { this.callEventMethod(event, 'onKeyDownCapture'			); }
	public onKeyPress					(event: KeyboardEvent		): void { this.callEventMethod(event, 'onKeyPress'					); }
	public onKeyPressCapture			(event: KeyboardEvent		): void { this.callEventMethod(event, 'onKeyPressCapture'			); }
	public onKeyUp						(event: KeyboardEvent		): void { this.callEventMethod(event, 'onKeyUp'						); }
	public onKeyUpCapture				(event: KeyboardEvent		): void { this.callEventMethod(event, 'onKeyUpCapture'				); }

	// Media Events
	public onAbort						(event: SyntheticEvent		): void { this.callEventMethod(event, 'onAbort'						); }
	public onAbortCapture				(event: SyntheticEvent		): void { this.callEventMethod(event, 'onAbortCapture'				); }
	public onCanPlay					(event: SyntheticEvent		): void { this.callEventMethod(event, 'onCanPlay'					); }
	public onCanPlayCapture				(event: SyntheticEvent		): void { this.callEventMethod(event, 'onCanPlayCapture'			); }
	public onCanPlayThrough				(event: SyntheticEvent		): void { this.callEventMethod(event, 'onCanPlayThrough'			); }
	public onCanPlayThroughCapture		(event: SyntheticEvent		): void { this.callEventMethod(event, 'onCanPlayThroughCapture'		); }
	public onDurationChange				(event: SyntheticEvent		): void { this.callEventMethod(event, 'onDurationChange'			); }
	public onDurationChangeCapture		(event: SyntheticEvent		): void { this.callEventMethod(event, 'onDurationChangeCapture'		); }
	public onEmptied					(event: SyntheticEvent		): void { this.callEventMethod(event, 'onEmptied'					); }
	public onEmptiedCapture				(event: SyntheticEvent		): void { this.callEventMethod(event, 'onEmptiedCapture'			); }
	public onEncrypted					(event: SyntheticEvent		): void { this.callEventMethod(event, 'onEncrypted'					); }
	public onEncryptedCapture			(event: SyntheticEvent		): void { this.callEventMethod(event, 'onEncryptedCapture'			); }
	public onEnded						(event: SyntheticEvent		): void { this.callEventMethod(event, 'onEnded'						); }
	public onEndedCapture				(event: SyntheticEvent		): void { this.callEventMethod(event, 'onEndedCapture'				); }
	public onLoadedData					(event: SyntheticEvent		): void { this.callEventMethod(event, 'onLoadedData'				); }
	public onLoadedDataCapture			(event: SyntheticEvent		): void { this.callEventMethod(event, 'onLoadedDataCapture'			); }
	public onLoadedMetadata				(event: SyntheticEvent		): void { this.callEventMethod(event, 'onLoadedMetadata'			); }
	public onLoadedMetadataCapture		(event: SyntheticEvent		): void { this.callEventMethod(event, 'onLoadedMetadataCapture'		); }
	public onLoadStart					(event: SyntheticEvent		): void { this.callEventMethod(event, 'onLoadStart'					); }
	public onLoadStartCapture			(event: SyntheticEvent		): void { this.callEventMethod(event, 'onLoadStartCapture'			); }
	public onPause						(event: SyntheticEvent		): void { this.callEventMethod(event, 'onPause'						); }
	public onPauseCapture				(event: SyntheticEvent		): void { this.callEventMethod(event, 'onPauseCapture'				); }
	public onPlay						(event: SyntheticEvent		): void { this.callEventMethod(event, 'onPlay'						); }
	public onPlayCapture				(event: SyntheticEvent		): void { this.callEventMethod(event, 'onPlayCapture'				); }
	public onPlaying					(event: SyntheticEvent		): void { this.callEventMethod(event, 'onPlaying'					); }
	public onPlayingCapture				(event: SyntheticEvent		): void { this.callEventMethod(event, 'onPlayingCapture'			); }
	public onProgress					(event: SyntheticEvent		): void { this.callEventMethod(event, 'onProgress'					); }
	public onProgressCapture			(event: SyntheticEvent		): void { this.callEventMethod(event, 'onProgressCapture'			); }
	public onRateChange					(event: SyntheticEvent		): void { this.callEventMethod(event, 'onRateChange'				); }
	public onRateChangeCapture			(event: SyntheticEvent		): void { this.callEventMethod(event, 'onRateChangeCapture'			); }
	public onSeeked						(event: SyntheticEvent		): void { this.callEventMethod(event, 'onSeeked'					); }
	public onSeekedCapture				(event: SyntheticEvent		): void { this.callEventMethod(event, 'onSeekedCapture'				); }
	public onSeeking					(event: SyntheticEvent		): void { this.callEventMethod(event, 'onSeeking'					); }
	public onSeekingCapture				(event: SyntheticEvent		): void { this.callEventMethod(event, 'onSeekingCapture'			); }
	public onStalled					(event: SyntheticEvent		): void { this.callEventMethod(event, 'onStalled'					); }
	public onStalledCapture				(event: SyntheticEvent		): void { this.callEventMethod(event, 'onStalledCapture'			); }
	public onSuspend					(event: SyntheticEvent		): void { this.callEventMethod(event, 'onSuspend'					); }
	public onSuspendCapture				(event: SyntheticEvent		): void { this.callEventMethod(event, 'onSuspendCapture'			); }
	public onTimeUpdate					(event: SyntheticEvent		): void { this.callEventMethod(event, 'onTimeUpdate'				); }
	public onTimeUpdateCapture			(event: SyntheticEvent		): void { this.callEventMethod(event, 'onTimeUpdateCapture'			); }
	public onVolumeChange				(event: SyntheticEvent		): void { this.callEventMethod(event, 'onVolumeChange'				); }
	public onVolumeChangeCapture		(event: SyntheticEvent		): void { this.callEventMethod(event, 'onVolumeChangeCapture'		); }
	public onWaiting					(event: SyntheticEvent		): void { this.callEventMethod(event, 'onWaiting'					); }
	public onWaitingCapture				(event: SyntheticEvent		): void { this.callEventMethod(event, 'onWaitingCapture'			); }

	// MouseEvents
	public onAuxClick					(event: MouseEvent			): void { this.callEventMethod(event, 'onAuxClick'					); }
	public onAuxClickCapture			(event: MouseEvent			): void { this.callEventMethod(event, 'onAuxClickCapture'			); }
	public onClick						(event: MouseEvent			): void { this.callEventMethod(event, 'onClick'						); }
	public onClickCapture				(event: MouseEvent			): void { this.callEventMethod(event, 'onClickCapture'				); }
	public onContextMenu				(event: MouseEvent			): void { this.callEventMethod(event, 'onContextMenu'				); }
	public onContextMenuCapture			(event: MouseEvent			): void { this.callEventMethod(event, 'onContextMenuCapture'		); }
	public onDoubleClick				(event: MouseEvent			): void { this.callEventMethod(event, 'onDoubleClick'				); }
	public onDoubleClickCapture			(event: MouseEvent			): void { this.callEventMethod(event, 'onDoubleClickCapture'		); }
	public onDrag						(event: DragEvent			): void { this.callEventMethod(event, 'onDrag'						); }
	public onDragCapture				(event: DragEvent			): void { this.callEventMethod(event, 'onDragCapture'				); }
	public onDragEnd					(event: DragEvent			): void { this.callEventMethod(event, 'onDragEnd'					); }
	public onDragEndCapture				(event: DragEvent			): void { this.callEventMethod(event, 'onDragEndCapture'			); }
	public onDragEnter					(event: DragEvent			): void { this.callEventMethod(event, 'onDragEnter'					); }
	public onDragEnterCapture			(event: DragEvent			): void { this.callEventMethod(event, 'onDragEnterCapture'			); }
	public onDragExit					(event: DragEvent			): void { this.callEventMethod(event, 'onDragExit'					); }
	public onDragExitCapture			(event: DragEvent			): void { this.callEventMethod(event, 'onDragExitCapture'			); }
	public onDragLeave					(event: DragEvent			): void { this.callEventMethod(event, 'onDragLeave'					); }
	public onDragLeaveCapture			(event: DragEvent			): void { this.callEventMethod(event, 'onDragLeaveCapture'			); }
	public onDragOver					(event: DragEvent			): void { this.callEventMethod(event, 'onDragOver'					); }
	public onDragOverCapture			(event: DragEvent			): void { this.callEventMethod(event, 'onDragOverCapture'			); }
	public onDragStart					(event: DragEvent			): void { this.callEventMethod(event, 'onDragStart'					); }
	public onDragStartCapture			(event: DragEvent			): void { this.callEventMethod(event, 'onDragStartCapture'			); }
	public onDrop						(event: DragEvent			): void { this.callEventMethod(event, 'onDrop'						); }
	public onDropCapture				(event: DragEvent			): void { this.callEventMethod(event, 'onDropCapture'				); }
	public onMouseDown					(event: MouseEvent			): void { this.callEventMethod(event, 'onMouseDown'					); }
	public onMouseDownCapture			(event: MouseEvent			): void { this.callEventMethod(event, 'onMouseDownCapture'			); }
	public onMouseEnter					(event: MouseEvent			): void { this.callEventMethod(event, 'onMouseEnter'				); }
	public onMouseLeave					(event: MouseEvent			): void { this.callEventMethod(event, 'onMouseLeave'				); }
	public onMouseMove					(event: MouseEvent			): void { this.callEventMethod(event, 'onMouseMove'					); }
	public onMouseMoveCapture			(event: MouseEvent			): void { this.callEventMethod(event, 'onMouseMoveCapture'			); }
	public onMouseOut					(event: MouseEvent			): void { this.callEventMethod(event, 'onMouseOut'					); }
	public onMouseOutCapture			(event: MouseEvent			): void { this.callEventMethod(event, 'onMouseOutCapture'			); }
	public onMouseOver					(event: MouseEvent			): void { this.callEventMethod(event, 'onMouseOver'					); }
	public onMouseOverCapture			(event: MouseEvent			): void { this.callEventMethod(event, 'onMouseOverCapture'			); }
	public onMouseUp					(event: MouseEvent			): void { this.callEventMethod(event, 'onMouseUp'					); }
	public onMouseUpCapture				(event: MouseEvent			): void { this.callEventMethod(event, 'onMouseUpCapture'			); }

	// Selection Events
	public onSelect						(event: SyntheticEvent		): void { this.callEventMethod(event, 'onSelect'					); }
	public onSelectCapture				(event: SyntheticEvent		): void { this.callEventMethod(event, 'onSelectCapture'				); }

	// Touch Events
	public onTouchCancel				(event: TouchEvent			): void { this.callEventMethod(event, 'onTouchCancel'				); }
	public onTouchCancelCapture			(event: TouchEvent			): void { this.callEventMethod(event, 'onTouchCancelCapture'		); }
	public onTouchEnd					(event: TouchEvent			): void { this.callEventMethod(event, 'onTouchEnd'					); }
	public onTouchEndCapture			(event: TouchEvent			): void { this.callEventMethod(event, 'onTouchEndCapture'			); }
	public onTouchMove					(event: TouchEvent			): void { this.callEventMethod(event, 'onTouchMove'					); }
	public onTouchMoveCapture			(event: TouchEvent			): void { this.callEventMethod(event, 'onTouchMoveCapture'			); }
	public onTouchStart					(event: TouchEvent			): void { this.callEventMethod(event, 'onTouchStart'				); }
	public onTouchStartCapture			(event: TouchEvent			): void { this.callEventMethod(event, 'onTouchStartCapture'			); }

	// Pointer Events
	public onPointerDown				(event: PointerEvent		): void { this.callEventMethod(event, 'onPointerDown'				); }
	public onPointerDownCapture			(event: PointerEvent		): void { this.callEventMethod(event, 'onPointerDownCapture'		); }
	public onPointerMove				(event: PointerEvent		): void { this.callEventMethod(event, 'onPointerMove'				); }
	public onPointerMoveCapture			(event: PointerEvent		): void { this.callEventMethod(event, 'onPointerMoveCapture'		); }
	public onPointerUp					(event: PointerEvent		): void { this.callEventMethod(event, 'onPointerUp'					); }
	public onPointerUpCapture			(event: PointerEvent		): void { this.callEventMethod(event, 'onPointerUpCapture'			); }
	public onPointerCancel				(event: PointerEvent		): void { this.callEventMethod(event, 'onPointerCancel'				); }
	public onPointerCancelCapture		(event: PointerEvent		): void { this.callEventMethod(event, 'onPointerCancelCapture'		); }
	public onPointerEnter				(event: PointerEvent		): void { this.callEventMethod(event, 'onPointerEnter'				); }
	public onPointerEnterCapture		(event: PointerEvent		): void { this.callEventMethod(event, 'onPointerEnterCapture'		); }
	public onPointerLeave				(event: PointerEvent		): void { this.callEventMethod(event, 'onPointerLeave'				); }
	public onPointerLeaveCapture		(event: PointerEvent		): void { this.callEventMethod(event, 'onPointerLeaveCapture'		); }
	public onPointerOver				(event: PointerEvent		): void { this.callEventMethod(event, 'onPointerOver'				); }
	public onPointerOverCapture			(event: PointerEvent		): void { this.callEventMethod(event, 'onPointerOverCapture'		); }
	public onPointerOut					(event: PointerEvent		): void { this.callEventMethod(event, 'onPointerOut'				); }
	public onPointerOutCapture			(event: PointerEvent		): void { this.callEventMethod(event, 'onPointerOutCapture'			); }
	public onGotPointerCapture			(event: PointerEvent		): void { this.callEventMethod(event, 'onGotPointerCapture'			); }
	public onGotPointerCaptureCapture	(event: PointerEvent		): void { this.callEventMethod(event, 'onGotPointerCaptureCapture'	); }
	public onLostPointerCapture			(event: PointerEvent		): void { this.callEventMethod(event, 'onLostPointerCapture'		); }
	public onLostPointerCaptureCapture	(event: PointerEvent		): void { this.callEventMethod(event, 'onLostPointerCaptureCapture'); }

	// UI Events
	public onScroll						(event: UIEvent				): void { this.callEventMethod(event, 'onScroll'					); }
	public onScrollCapture				(event: UIEvent				): void { this.callEventMethod(event, 'onScrollCapture'				); }

	// Wheel Events
	public onWheel						(event: WheelEvent			): void { this.callEventMethod(event, 'onWheel'						); }
	public onWheelCapture				(event: WheelEvent			): void { this.callEventMethod(event, 'onWheelCapture'				); }

	// Animation Events
	public onAnimationStart				(event: AnimationEvent		): void { this.callEventMethod(event, 'onAnimationStart'			); }
	public onAnimationStartCapture		(event: AnimationEvent		): void { this.callEventMethod(event, 'onAnimationStartCapture'		); }
	public onAnimationEnd				(event: AnimationEvent		): void { this.callEventMethod(event, 'onAnimationEnd'				); }
	public onAnimationEndCapture		(event: AnimationEvent		): void { this.callEventMethod(event, 'onAnimationEndCapture'		); }
	public onAnimationIteration			(event: AnimationEvent		): void { this.callEventMethod(event, 'onAnimationIteration'		); }
	public onAnimationIterationCapture	(event: AnimationEvent		): void { this.callEventMethod(event, 'onAnimationIterationCapture'	); }

	// Transition Events
	public onTransitionEnd				(event: TransitionEvent		): void { this.callEventMethod(event, 'onTransitionEnd'				); }
	public onTransitionEndCapture		(event: TransitionEvent		): void { this.callEventMethod(event, 'onTransitionEndCapture'		); }
	// --------------------------------------------------------------------------
}

/**
 * イベント名
 */
export enum WprEventName {
	// Clipboard Events
	onCopy						= 'onCopy',
	onCopyCapture				= 'onCopyCapture',
	onCut						= 'onCut',
	onCutCapture				= 'onCutCapture',
	onPaste						= 'onPaste',
	onPasteCapture				= 'onPasteCapture',

	// Composition Events
	onCompositionEnd			= 'onCompositionEnd',
	onCompositionEndCapture		= 'onCompositionEndCapture',
	onCompositionStart			= 'onCompositionStart',
	onCompositionStartCapture	= 'onCompositionStartCapture',
	onCompositionUpdate			= 'onCompositionUpdate',
	onCompositionUpdateCapture	= 'onCompositionUpdateCapture',

	// Focus Events
	onFocus						= 'onFocus',
	onFocusCapture				= 'onFocusCapture',
	onBlur						= 'onBlur',
	onBlurCapture				= 'onBlurCapture',

	// Form Events
	onChange					= 'onChange',
	onChangeCapture				= 'onChangeCapture',
	onBeforeInput				= 'onBeforeInput',
	onBeforeInputCapture		= 'onBeforeInputCapture',
	onInput						= 'onInput',
	onInputCapture				= 'onInputCapture',
	onReset						= 'onReset',
	onResetCapture				= 'onResetCapture',
	onSubmit					= 'onSubmit',
	onSubmitCapture				= 'onSubmitCapture',
	onInvalid					= 'onInvalid',
	onInvalidCapture			= 'onInvalidCapture',

	// Image Events
	onLoad						= 'onLoad',
	onLoadCapture				= 'onLoadCapture',
	onError						= 'onError',
	onErrorCapture				= 'onErrorCapture',

	// Keyboard Events
	onKeyDown					= 'onKeyDown',
	onKeyDownCapture			= 'onKeyDownCapture',
	onKeyPress					= 'onKeyPress',
	onKeyPressCapture			= 'onKeyPressCapture',
	onKeyUp						= 'onKeyUp',
	onKeyUpCapture				= 'onKeyUpCapture',

	// Media Events
	onAbort						= 'onAbort',
	onAbortCapture				= 'onAbortCapture',
	onCanPlay					= 'onCanPlay',
	onCanPlayCapture			= 'onCanPlayCapture',
	onCanPlayThrough			= 'onCanPlayThrough',
	onCanPlayThroughCapture		= 'onCanPlayThroughCapture',
	onDurationChange			= 'onDurationChange',
	onDurationChangeCapture		= 'onDurationChangeCapture',
	onEmptied					= 'onEmptied',
	onEmptiedCapture			= 'onEmptiedCapture',
	onEncrypted					= 'onEncrypted',
	onEncryptedCapture			= 'onEncryptedCapture',
	onEnded						= 'onEnded',
	onEndedCapture				= 'onEndedCapture',
	onLoadedData				= 'onLoadedData',
	onLoadedDataCapture			= 'onLoadedDataCapture',
	onLoadedMetadata			= 'onLoadedMetadata',
	onLoadedMetadataCapture		= 'onLoadedMetadataCapture',
	onLoadStart					= 'onLoadStart',
	onLoadStartCapture			= 'onLoadStartCapture',
	onPause						= 'onPause',
	onPauseCapture				= 'onPauseCapture',
	onPlay						= 'onPlay',
	onPlayCapture				= 'onPlayCapture',
	onPlaying					= 'onPlaying',
	onPlayingCapture			= 'onPlayingCapture',
	onProgress					= 'onProgress',
	onProgressCapture			= 'onProgressCapture',
	onRateChange				= 'onRateChange',
	onRateChangeCapture			= 'onRateChangeCapture',
	onSeeked					= 'onSeeked',
	onSeekedCapture				= 'onSeekedCapture',
	onSeeking					= 'onSeeking',
	onSeekingCapture			= 'onSeekingCapture',
	onStalled					= 'onStalled',
	onStalledCapture			= 'onStalledCapture',
	onSuspend					= 'onSuspend',
	onSuspendCapture			= 'onSuspendCapture',
	onTimeUpdate				= 'onTimeUpdate',
	onTimeUpdateCapture			= 'onTimeUpdateCapture',
	onVolumeChange				= 'onVolumeChange',
	onVolumeChangeCapture		= 'onVolumeChangeCapture',
	onWaiting					= 'onWaiting',
	onWaitingCapture			= 'onWaitingCapture',

	// MouseEvents
	onAuxClick					= 'onAuxClick',
	onAuxClickCapture			= 'onAuxClickCapture',
	onClick						= 'onClick',
	onClickCapture				= 'onClickCapture',
	onContextMenu				= 'onContextMenu',
	onContextMenuCapture		= 'onContextMenuCapture',
	onDoubleClick				= 'onDoubleClick',
	onDoubleClickCapture		= 'onDoubleClickCapture',
	onDrag						= 'onDrag',
	onDragCapture				= 'onDragCapture',
	onDragEnd					= 'onDragEnd',
	onDragEndCapture			= 'onDragEndCapture',
	onDragEnter					= 'onDragEnter',
	onDragEnterCapture			= 'onDragEnterCapture',
	onDragExit					= 'onDragExit',
	onDragExitCapture			= 'onDragExitCapture',
	onDragLeave					= 'onDragLeave',
	onDragLeaveCapture			= 'onDragLeaveCapture',
	onDragOver					= 'onDragOver',
	onDragOverCapture			= 'onDragOverCapture',
	onDragStart					= 'onDragStart',
	onDragStartCapture			= 'onDragStartCapture',
	onDrop						= 'onDrop',
	onDropCapture				= 'onDropCapture',
	onMouseDown					= 'onMouseDown',
	onMouseDownCapture			= 'onMouseDownCapture',
	onMouseEnter				= 'onMouseEnter',
	onMouseLeave				= 'onMouseLeave',
	onMouseMove					= 'onMouseMove',
	onMouseMoveCapture			= 'onMouseMoveCapture',
	onMouseOut					= 'onMouseOut',
	onMouseOutCapture			= 'onMouseOutCapture',
	onMouseOver					= 'onMouseOver',
	onMouseOverCapture			= 'onMouseOverCapture',
	onMouseUp					= 'onMouseUp',
	onMouseUpCapture			= 'onMouseUpCapture',

	// Selection Events
	onSelect					= 'onSelect',
	onSelectCapture				= 'onSelectCapture',

	// Touch Events
	onTouchCancel				= 'onTouchCancel',
	onTouchCancelCapture		= 'onTouchCancelCapture',
	onTouchEnd					= 'onTouchEnd',
	onTouchEndCapture			= 'onTouchEndCapture',
	onTouchMove					= 'onTouchMove',
	onTouchMoveCapture			= 'onTouchMoveCapture',
	onTouchStart				= 'onTouchStart',
	onTouchStartCapture			= 'onTouchStartCapture',

	// Pointer Events
	onPointerDown				= 'onPointerDown',
	onPointerDownCapture		= 'onPointerDownCapture',
	onPointerMove				= 'onPointerMove',
	onPointerMoveCapture		= 'onPointerMoveCapture',
	onPointerUp					= 'onPointerUp',
	onPointerUpCapture			= 'onPointerUpCapture',
	onPointerCancel				= 'onPointerCancel',
	onPointerCancelCapture		= 'onPointerCancelCapture',
	onPointerEnter				= 'onPointerEnter',
	onPointerEnterCapture		= 'onPointerEnterCapture',
	onPointerLeave				= 'onPointerLeave',
	onPointerLeaveCapture		= 'onPointerLeaveCapture',
	onPointerOver				= 'onPointerOver',
	onPointerOverCapture		= 'onPointerOverCapture',
	onPointerOut				= 'onPointerOut',
	onPointerOutCapture			= 'onPointerOutCapture',
	onGotPointerCapture			= 'onGotPointerCapture',
	onGotPointerCaptureCapture	= 'onGotPointerCaptureCapture',
	onLostPointerCapture		= 'onLostPointerCapture',
	onLostPointerCaptureCapture	= 'onLostPointerCaptureCapture',

	// UI Events
	onScroll					= 'onScroll',
	onScrollCapture				= 'onScrollCapture',

	// Wheel Events
	onWheel						= 'onWheel',
	onWheelCapture				= 'onWheelCapture',

	// Animation Events
	onAnimationStart			= 'onAnimationStart',
	onAnimationStartCapture		= 'onAnimationStartCapture',
	onAnimationEnd				= 'onAnimationEnd',
	onAnimationEndCapture		= 'onAnimationEndCapture',
	onAnimationIteration		= 'onAnimationIteration',
	onAnimationIterationCapture	= 'onAnimationIterationCapture',

	// Transition Events
	onTransitionEnd				= 'onTransitionEnd',
	onTransitionEndCapture		= 'onTransitionEndCapture',
}
