import { MypageBaseView } from '../../base/MypageBaseView';
import { WprListData } from '../../../../wpr-framework/view/deco/WprListData';
import { ConstrDateListModel } from '../../../models/mypage/Construction/ConstrDateListModel';
import { WprDI_ScopeModel } from '../../../../wpr-framework/view/di/WprDI_ScopeModel';
import { MypageWizardMngr } from '../../wizard/models/MypageWizardMngr';
import { ProgressBarData } from '../../common/progress_bar/ProgressBarView';
import { DateUtil } from '../../../common/util/DateUtil';
import { WorkDetailInfo } from '../../../models/iss/getMypWorkScheduleList/WorkDetailInfo';
import { WorkScheduleList } from '../../../models/iss/getMypWorkScheduleList/WorkScheduleList';
import { GetMypWorkScheduleListRequest } from '../../../service/models/dto/mypagerenewal/GetMypWorkScheduleListRequest';
import { GetMypWorkScheduleListResponse } from '../../../service/models/dto/mypagerenewal/GetMypWorkScheduleListResponse';
import { ConstructionService } from '../../../service/ConstructionService';

/**
 * 履歴確認画面
 */
export class ConstrLogView extends MypageBaseView {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() { super('ConstrLogView'); }
	// --------------------------------------------------------------------------

	// モデル  ------------------------------------------------------------------
	@WprListData('ConstrDateListModel')
	private m_ConstrDateListModel: ConstrDateListModel[]	= null;	// 工事リストモデル
	@WprDI_ScopeModel('MypageWizardMngr')
	private m_MypageWizardMngr: MypageWizardMngr			= null;		// 画面ウィザード管理クラス
	// --------------------------------------------------------------------------

	// サービス  ----------------------------------------------------------------
	private m_ConstructionService: ConstructionService	= new ConstructionService();	// 工事日サービス
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * コントロール定義設定
	 */
	public onRegisterConfig(): void {
		this.addListConfig('constrType', '工事区分 ( テレビ、ネット等 )', 'ConstrDateListModel', { bindName: 'type' });
		this.addListConfig('date', '日付', 'ConstrDateListModel.workScheduleList', { bindName: 'schedule_date', converter: '日付（yyyy/MM/dd）' });
		this.addListConfig('dayOfWeek', '曜日', 'ConstrDateListModel', { bindName: 'dayOfWeek' });
		this.addListConfig('time', '開始予定時間', 'ConstrDateListModel', { bindName: 'preferredTime' });
	}

	/**
	 * アクション登録
	 */
	public onRegisterAction(): void {
		this.addAction('back', this.onBack);
	}

	/**
	 * ビュー表示通知
	 */
	public onShowView(): void {
		this.m_MypageWizardMngr.initView(this, (result) => {
			this.registAccessLog();
			this.setStorageItem('b_url', window.location.href)
			const sendData: ProgressBarData = new ProgressBarData();
			sendData.progressBarList = this.m_MypageWizardMngr.getProgressModelList(this);
			this.sendChildData('fP_ev_progressBarIntital', sendData);
			this.m_ConstrDateListModel = new Array();
			const req: GetMypWorkScheduleListRequest = new GetMypWorkScheduleListRequest();
			req.customer_id = this.getCustomerId();
			req.application_id = '2';
			this.m_ConstructionService.getWorkScheduleList(this, req, (result) => {
				if (result) {
					if (result.work_schedule_list && result.work_schedule_list.length > 0) {
						//日時順に並び替え
						const list =  result.work_schedule_list.sort(function(a, b) { 
							if (a.schedule_date === b.schedule_date) 
								return a.schedule_start_time > b.schedule_start_time? -1: 1 
							else
								return a.schedule_date > b.schedule_date? 1: -1 
						});
						let idx = 0;
						for (const schedule of list) {
							const model = new ConstrDateListModel();
							model.workScheduleList = schedule;
							model.type = '';
							if (!model.workScheduleList.preferred_time_cd) {
								if (Number(model.workScheduleList.schedule_start_time.substring(0, 2)) < 11) 
									model.workScheduleList.preferred_time_cd = '01';
								else if (Number(model.workScheduleList.schedule_start_time.substring(0, 2)) < 14)
									model.workScheduleList.preferred_time_cd = '02';
								else
									model.workScheduleList.preferred_time_cd = '03';
							}
							model.preferredTime = this.getValueMap('工事時間帯').getValueFormKey(model.workScheduleList.preferred_time_cd);
							const map = new Map<string, WorkDetailInfo[]>();
							for (const data of schedule.work_detail_info) {
								if (!map.has(data.mypage_nm))
									map.set(data.mypage_nm, []);
								map.get(data.mypage_nm).push(data);
							}
							for (const key of map.keys()) {
								if (key)
									model.type += '【' + key + '】';
							}
							if (!model.type)
								model.type = '【その他】';
							if (schedule.schedule_date)
								model.dayOfWeek = this.getDayOfWeek(schedule.schedule_date);
							this.m_ConstrDateListModel.push(model);	
							this.setViewMode(idx.toString(), '非表示');
							idx++;
						}
						this.setViewMode('表示内容', 'あり');
					}
					else
						this.setViewMode('表示内容', 'なし');
					this.setListData('ConstrDateListModel', this.m_ConstrDateListModel);
					this.refreshView();
				}
			});
		});
	}
	// --------------------------------------------------------------------------

	// アクション  ---------------------------------------------------------------
	/**
	 * 予定日一覧に戻る
	 */
	public onBack(param: any): void {
		this.m_MypageWizardMngr.goBack(this);
	}
	// --------------------------------------------------------------------------
	// privateメソッド -----------------------------------------------------------

	/**
	 * 曜日取得
	 */
	public getDayOfWeek(date: string): string {
		let dayOfWeekStrJP = [ "日", "月", "火", "水", "木", "金", "土" ] ;
		return dayOfWeekStrJP[new Date(DateUtil.convertToDateWithSlash(date)).getDay()];
	}
	// --------------------------------------------------------------------------
}
