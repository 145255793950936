import { Grid, Table, TableBody, TableCell, TableHead, Typography } from '@material-ui/core';
import React, { ReactNode } from 'react';
import { MatSelect } from '../../../../../wpr-framework/component/controls/material/MatSelect';
import { UsagePDFDownloadDialogView } from './UsagePDFDownloadDialogView';
import styles from './UsagePDFDownloadDialog.scss';
import { MatCheckBox } from '../../../../../wpr-framework/component/controls/material/MatCheckBox';
import { MatButton } from '../../../../../wpr-framework/component/controls/material/MatButton';
import { UsageCplusGraphDialogViewComponent } from '../../../../controls/usageDetailsDialog/UsageCplusGraphDialogViewComponent';
import { UsageDetailsRadio } from '../../../../controls/usageDetailsRadio/UsageDetailsRadio';

import pdfDownload from '/shared/image/usagedetails/pdfDownload.png';
import pdfDownloadDisabled from '/shared/image/usagedetails/pdfDownload_disabled.png';

/**
 * 利用明細PDFダウンロードダイアログ用コンポーネント
 */
export class UsagePDFDownloadDialog extends UsageCplusGraphDialogViewComponent {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: any) { super(new UsagePDFDownloadDialogView(), props); }
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** 利用明細PDFダウンロードダイアログビュー情報 */
	public get viewInfo(): UsagePDFDownloadDialogView	{ return this.view as UsagePDFDownloadDialogView;	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * スタイルオブジェクト取得
	*/
	public getStylesObject(): any { return styles; }

	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		let img ;
		if (this.state.data.downloadBtn) {
			img = pdfDownload;
		} else {
			img = pdfDownloadDisabled;
		}
		return (
			<>
				<div className='condition'>
					<div className='conditionHeader'>
						<Typography>ご請求期間を選択</Typography>
					</div>
					<Grid container>
						<Grid item md={5} xs={12}>
							<MatSelect name='fromMonthSelect' vm='usageDetailsTargerMonth' variant='standard' view={this.view}/>
						</Grid>
						<Grid item md={2} xs={12} className='periodCheckBox'>
							<div>
								<MatCheckBox name='listFlg' label='から' color='default' view={this.view}/>
							</div>
						</Grid>
						<Grid item md={5} xs={12}>
							<MatSelect name='toMonthSelect' vm='usageDetailsTargerMonth' variant='standard' view={this.view} />
						</Grid>
					</Grid>
					<div className='conditionHeader'>
						<Typography>表示方法を選択</Typography>
					</div>
					<Grid container spacing={2} className='usageRadio'>
						<Grid item md={4} xs={12}>
							<UsageDetailsRadio name='display' id='display_1' value='詳細' dispType='詳細表示' view={this.view} />
						</Grid>
						<Grid item md={4} xs={12}>
							<UsageDetailsRadio name='display' id='display_2' value='一覧' dispType='一覧表示' view={this.view} />
						</Grid>
						<Grid item md={4} xs={12}>
							<UsageDetailsRadio name='display' id='display_2' value='インボイス' dispType='インボイス表示' view={this.view} />
						</Grid>
					</Grid>
				</div>
				<div className='download submitBtnOrange'>
					<MatButton name='pdfDownload' color='primary' view={this.view}>
						ダウンロード
						<div>
							<img src={img} />
						</div>
					</MatButton>
				</div>
			</>
		);
	}
	// --------------------------------------------------------------------------
}
