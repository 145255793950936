import { MypageWizardFlow } from './MypageWizardFlow';
import { CommonCompleteView } from '../common/common_complete/CommonCompleteView';
import { ModNhkConfirmView } from '../Srvother/mod_nhk_confirm/ModNhkConfirmView';
import { GetViewInfoRequest } from '../../service/models/dto/mypagerenewal/GetViewInfoRequest';
import { MypageBaseView } from '../base/MypageBaseView';
import { MobileConfirmView } from '../Srvnet/mobile_confirm/MobileConfirmView';
import { MCommon } from '../../service/models/entity/primary/MCommon';
import { MypageWizardMngr } from './models/MypageWizardMngr';
import { WizardModel } from '../../models/WizardModel';
import { ContractPlanModel } from '../../service/models/apimodels/ContractPlanModel';
import { Contract } from '../../models/bss/contract/Contract';
import { MobileCompleteView } from '../Srvnet/mobile_complete/MobileCompleteView';
import { RegistInfoRequest } from '../../service/models/dto/mypagerenewal/RegistInfoRequest';
import { InquiryMailInfo } from '../../service/models/apimodels/InquiryMailInfo';
import { Equipment } from '../../models/bss/contract/Equipment';
import { ObjectUtil } from '../../common/util/ObjectUtil';
import { MAIL_CONSTANTS } from '../../common/MyPageMailIdConstants';
/**
 * ITSCOM HOME_追加ウィザードクラス
 */
export class MobileConfirmWizardFlow extends MypageWizardFlow {
	// private 変数  ------------------------------------------------------------
	private m_DefaultWizardFlow: WizardModel[]			= null;		// 画面ウィザードリスト
	private m_StartViewName: string						= null;
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	public constructor() {
		super();
		this.m_StartViewName = new MobileConfirmView().name;

		// 遷移パターン１
		this.m_DefaultWizardFlow = new Array();
		this.m_DefaultWizardFlow.push({stepNum: 0, title: '契約選択', viewName: null, path: this.getOldUrl() + 'Srvlist/Continfo_list/'});
		this.m_DefaultWizardFlow.push({stepNum: 1, title: '内容確認', viewName: this.m_StartViewName, path: 'mobile_confirm'});
		this.m_DefaultWizardFlow.push({stepNum: 2, title: '解約申込み完了', viewName: new MobileCompleteView().name, path: 'mobile_complete'});
	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * 画面ウィザードフロー名取得
	 */
	public getWizardFlowName(viewName: string): string {
		if (viewName === this.m_StartViewName)
			return this.constructor.name;
		return null;
	}
	
	/**
	 * 画面ウィザードリスト取得：契約状態で切り替えたり、選択肢で切り替えたり
	 */
	public getWizardFlow(mngr: MypageWizardMngr): WizardModel[] {
		return this.m_DefaultWizardFlow;
	}

	public getInitCacheData(mngr: MypageWizardMngr): MobileConfirmWizardModel {
		let cacheData = new MobileConfirmWizardModel();
		cacheData.m_Contract = new Contract();	// 契約情報
		cacheData.m_EquipmentModel = new Equipment();
		return cacheData;
	}
	
	/**
	 * 画面ウィザードAPI用モデル取得：契約状態で切り替えたり、選択肢で切り替えたり
	 */
	public getWizardApiModel(mngr: MypageWizardMngr, view: MypageBaseView): GetViewInfoRequest {
		const req = new GetViewInfoRequest();
		req.customerId = mngr.getParams().customerId;
		// 契約情報取得
		req.contractFlg = true;
		req.statusCdList = new Array('0', '1', '2');
		// MCommon取得
		req.commonList = new Array();
		const common = new MCommon('かっとびバリュー月額利用料取得', 'srvnet_info', 'value', 'charge', null);
		req.commonList.push(common);
		return req;
	}

	/**
	 * 画面ウィザード完了APIモデル取得
	 */
	public getRegistApiModel(mngr: MypageWizardMngr, view: MypageBaseView): RegistInfoRequest{
		const req: RegistInfoRequest = new RegistInfoRequest();
		const cacheData: MobileConfirmWizardModel = mngr.getCache();
		const contractInfo = mngr.getContract(view,mngr.getParams().contractId);
		const mailconstants = MAIL_CONSTANTS.find (mail => {
			return mail.view === 'かっとびモバイル_解約';
		});
		// お客様メール
		req.customerMailId = mailconstants.customerMail;
		req.customerId = mngr.getParams().customerId;
		// 業務部門
		req.systemMailId = mailconstants.systemMail;
		// 問い合わせ
		req.inquiryId = mailconstants.inquiryMail;
		req.systemInfo = new InquiryMailInfo();
		req.systemInfo.contractId = contractInfo.contractID;
		const equipmentID = contractInfo.equipment[0].equipmentID;
		if (ObjectUtil.isNullOrUndefined(equipmentID)) {
			req.systemInfo.content = 'かっとびモバイル解約\n申込みシリアル番号：'
		} else {
			req.systemInfo.content = 'かっとびモバイル解約\n申込みシリアル番号：' + equipmentID;
		}
		// req.systemInfo.other = 'かっとびモバイル解約申込み';
		return req;
	}

	/**
	 * 画面ウィザードフローの中断判定：例：申し込みが完了していれば、完了済みの画面へ遷移
	 */
	public checkFlowBreak(mngr: MypageWizardMngr): string {
		let test: boolean = false;
		if (test) {
			return '/error';
		}
		return null;
	}
}

export class MobileConfirmWizardModel {
	public m_Contract: Contract			= null;
	public m_EquipmentModel: Equipment		= null;
}