import { IWprViewProps } from '../props/IWprViewProps';
import { WprBaseViewCoreComponent } from './WprBaseViewCoreComponent';

/**
 * ビューコンポーネント基本クラス
 */
export abstract class WprBaseViewComponent extends WprBaseViewCoreComponent<IWprViewProps> {
	// private 変数  ------------------------------------------------------------
	private m_Params: any = null;		// URLパラメータ
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * パラメータチェック
	 */
	 public onCheckParam(): void {
		const props = this.props as any;
		if (props.location) {
			if (this.m_Params != null) {
				if (this.m_Params !== props.location.search)
					setTimeout(() => {
						this.view.showView(false);
					}, 10);
			}
			this.m_Params = props.location.search;
		}
	 }
	// --------------------------------------------------------------------------

	// private メソッド  ---------------------------------------------------------
	/**
	 * パラメータチェック処理
	 * @param params パラメータ情報
	 * @returns trueの場合、パラメータ変更あり
	 */
	private checkParam(params: any): boolean {
		if (this.m_Params) {
			for(const key in this.m_Params) {
				if (key in params) {
					if (this.m_Params[key] !== params[key])
						return false;
				}
				else {
					return false;
				}
			}
		}
		return true;
	}
	// --------------------------------------------------------------------------
}
