/**
 * テレビウィザードモデル
 */
export class TvWizardModel {
	// public 変数	-----------------------------------------------------------
	public defaultFlg: boolean;		// 申込み
	public provisionalFlg: boolean;	// 仮申込
	public cancelFlg: boolean;		// 解約
	public proCancelFlg: boolean;	// 仮解約
	// ------------------------------------------------------------------------
}
