import { Card, Divider } from '@material-ui/core';
import { Grid } from '@mui/material';
import React, { ReactNode } from 'react';
import { WprBaseViewComponent } from '../../../../../wpr-framework/view/component/WprBaseViewComponent';
import { MenuBar } from '../../../common/menu_bar/MenuBar';
import { MvnoEntryPlan2View } from './MvnoEntryPlan2View';
import styles from './MvnoEntryPlan2.scss';
import { WprRadio } from '../../../../../wpr-framework/component/controls/WprRadio';
import { MatRadio } from '../../../../../wpr-framework/component/controls/material/MatRadio';
import { CheckMarkRadio } from '../../../../controls/checkMarkRadio/CheckMarkRadio';
import { MvnoCapacitySendData, MvnoCourseSendData, MvnoPlanSendData, CheckMarkRadioContent, MvnoTerminalUmuSendData, MvnoInitialSupportSendData } from '../../../../controls/checkMarkRadio/CheckMarkRadioContent';
import { MatButton } from '../../../../../wpr-framework/component/controls/material/MatButton';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { WprContainer } from '../../../../../wpr-framework/component/controls/WprContainer';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { WprRowInfo } from '../../../../../wpr-framework/component/props/WprRowInfo';
import { MGeneral } from '../../../../service/models/entity/MGeneral';
import { MvnoEntryFooter } from '../mvno_entry_footer/MvnoEntryFooter';
import { WprAnchor } from '../../../../../wpr-framework/component/controls/WprAnchor';
import { HelpInitialSupportDialog } from '../../dialog/help_initial_support_dialog/HelpInitialSupportDialog';

import { ProgressBarModel } from '../../../../models/ProgressBarModel';
import { ProgressBar } from '../../../common/progress_bar/ProgressBar';
import { MvnoEntryPlan1View } from '../mvno_entry_plan1/MvnoEntryPlan1View';

/**
 * MVNO申込_容量・コース選択用コンポーネント
 */
export class MvnoEntryPlan2 extends WprBaseViewComponent {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: any) { super(new MvnoEntryPlan2View(), props); }
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** MVNO申込_容量・コース選択ビュー情報 */
	public get viewInfo(): MvnoEntryPlan2View { return this.view as MvnoEntryPlan2View; }
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * リストコントロール定義登録
	*/
	protected onRegisterListRender(): void {
		this.addListRender('CapacityRadioList', null, this.onCapacityRadioListRender);
		this.addListRender('CourseRadioList', null, this.onCourseRadioListRender);
	}
	/**
	 * スタイルオブジェクト取得
	*/
	public getStylesObject(): any { return styles; }



	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		return (
			<>
				<div>
					<div className='pageCard' id='mvnoEntryPlan2ID'>
						<div className="pageHeader stepFlow">
							{/* <ProgressBar progressModelList={this.getProgressModelList()} name={''} view={undefined} /> */}
							<ProgressBar name='progressBar' view={this.view} />
						</div>

						<div className='pageContent'>
							<div className='planContent'>
								<div className='link_blue'>
									<WprAnchor name='helpLink' view={this.view}>申込みについてご不明な方はこちら＞</WprAnchor>
								</div>
								<div className='title_gray'>毎月の通信データ量をお選びください</div>
								<div className='planItem'>
									<Grid container className='entryRadio' spacing={1}>
										{this.renderList('CapacityRadioList')}
									</Grid>
								</div>

								{/* コース  */}
								<WprContainer mode='データ量.選択済' view={this.view}>
									<WprContainer mode='プラン.音声' view={this.view}>
										<div className='title_gray'>音声プランのコースをお選びください</div>
										<div className='planItem'>
											<Grid container className='entryRadio' spacing={1}>
												{this.renderList('CourseRadioList')}
											</Grid>
										</div>
									</WprContainer>
								</WprContainer>

								<WprContainer mode='コース.選択済' view={this.view}>
									<div className='title_gray'>5G通信利用のお申込み</div>
									<div className='planItem'>
										<Grid container className='entryRadio' spacing={1}>
											<Grid item xs={12} md={6}>

												<CheckMarkRadio name='fivegUseFlg' id='fivegUseFlg_1' value={true} itemType={CheckMarkRadioContent.MVNO_TERMINAL_UMU} radioContentData={this.getTerminalUmuData('5G通信を利用する')} view={this.view} />
											</Grid>
											<Grid item xs={12} md={6}>
												<CheckMarkRadio name='fivegUseFlg' id='fivegUseFlg_2' value={false} itemType={CheckMarkRadioContent.MVNO_TERMINAL_UMU} radioContentData={this.getTerminalUmuData('5G通信を利用しない')} view={this.view} />
											</Grid>
										</Grid>
										<div style={{ marginTop: '1rem' }}>
											<div>
												※5G対応端末で「5G設定」を有効にした場合にご利用いただけます。
											</div>
											<div>
												※イッツコム SIMでご利用いただける5Gは、4G(LTE)と一部設備を共用する方式のため、通信速度は4G（LTE）相当となります。
											</div>
										</div>

									</div>
								</WprContainer>

								<WprContainer mode='5G通信.選択済' view={this.view}>
									<WprContainer mode='プラン.音声' view={this.view}>
										<div className='title_gray'>留守番電話オプションのお申込み</div>
										<div className='planItem'>
											<Grid container className='entryRadio' spacing={1}>
												<Grid item xs={12} md={6}>
													<CheckMarkRadio name='rusubanUseFlg' id='rusubanUseFlg_1' value={true} itemType={CheckMarkRadioContent.MVNO_INITITAL_SUPPORT} radioContentData={this.getRusubanData('留守番電話オプションを利用する')} view={this.view} />
												</Grid>
												<Grid item xs={12} md={6}>
													<CheckMarkRadio name='rusubanUseFlg' id='rusubanUseFlg_2' value={false} itemType={CheckMarkRadioContent.MVNO_INITITAL_SUPPORT} radioContentData={this.getRusubanData('留守番電話オプションを利用しない')} view={this.view} />
												</Grid>
											</Grid>
											<div style={{ marginTop: '1rem' }}>
												<div>
													※音声プランご契約の方のみお申し込みいただけます。
												</div>
												<div>
													※SIMご利用開始より、留守番電話利用開始まで最大6日かかります。
												</div>
											</div>

										</div>

									</WprContainer>
								</WprContainer>

							</div>
						</div>
						<Divider />
						<div className='pageFooter'>
							<div>
								<MatButton name='next' view={this.view} className='nextButton' >
									次へ
									<NavigateNextIcon />
								</MatButton>

							</div>
							<div>
								<MatButton name='back' view={this.view} className='backButton' >
									<NavigateBeforeIcon />
									戻る
								</MatButton>

							</div>
							<div className='stepNumber'>2/4</div>
						</div>
					</div>
					<MvnoEntryFooter name='mvnoFooter' view={this.view} />
				</div>
			</>
		);
	}
	// --------------------------------------------------------------------------
	// リスト描画  ---------------------------------------------------------------
	/**
	 * リスト名=CapacityRadioList
	 */
	public onCapacityRadioListRender(rowInfo: WprRowInfo): any {
		const data: MGeneral = rowInfo.rowData as MGeneral;
		const index: string = String(rowInfo.index);

		const disabledClass: string = '';

		return (
			<>

				<Grid item xs={12} className={disabledClass}>
					<div className='capacityRadio'>
						{(data.text2) && (<div className='capacityBalloon'>
							<span className='capacityBalloonText'>{data.text2}</span>
						</div>)}

						<CheckMarkRadio
							name='capacityServiceCd'
							id={'capacity_' + index}
							value={data.generalCd}
							itemType={CheckMarkRadioContent.MVNO_CAPACITY}
							radioContentData={this.getCapacityContentData(data)}
							view={this.view} />
					</div>
				</Grid>
			</>
		);
	}

	/**
	 * リスト名=CapacityRadioList
	 */
	public onCourseRadioListRender(rowInfo: WprRowInfo): any {
		const data: MGeneral = rowInfo.rowData as MGeneral;
		const index: string = String(rowInfo.index);
		const disabled = data.generalCd !== '基本' && (this.view as MvnoEntryPlan2View).getCapacityVal() === '0704000014';
		const disabledClass: string = disabled ? 'disabledRadio' : '';
		return (
			<>
				{disabled &&
					<div className='disabledMessageBaloon courseBallon'>
						<div className='disabledBallonContent'>
							0GBの場合、かけ放題コースはお申し込みいただけません
						</div>
					</div>
				}
				<Grid item xs={12} className={disabledClass}>
					<CheckMarkRadio
						name='courseServiceCd'
						id={'course_' + index}
						value={data.generalCd}
						itemType={CheckMarkRadioContent.MVNO_COURSE}
						disabled={disabled}
						radioContentData={this.getCourseContentData(data)}
						view={this.view} />
				</Grid>
			</>
		);
	}
	// --------------------------------------------------------------------------
	private getCapacityContentData(data: MGeneral): MvnoCapacitySendData {
		const senddata: MvnoCapacitySendData = new MvnoCapacitySendData();
		senddata.val = data.name2;
		senddata.price = data.num2?.toString();
		senddata.decription = data.text1;
		senddata.comment = data.text2;
		return senddata;
	}
	private getCourseContentData(data: MGeneral): MvnoCourseSendData {
		const senddata: MvnoCourseSendData = new MvnoCourseSendData();
		senddata.val = data.name1;
		senddata.price = data.num2?.toString();
		senddata.decription = data.text1;
		return senddata;
	}

	private getTerminalUmuData(val: string): MvnoTerminalUmuSendData {
		const data: MvnoTerminalUmuSendData = new MvnoTerminalUmuSendData();
		data.val = val;
		return data;
	}

	private getRusubanData(val: string): MvnoInitialSupportSendData {
		const data: MvnoInitialSupportSendData = new MvnoInitialSupportSendData();
		data.val = val;
		const price = (this.view as MvnoEntryPlan2View).getRusubanOptionPrice();
		switch (val) {
			case '留守番電話オプションを利用する':
				data.decription = '月額利用料：' + price + '円／台（税込）';
				break;
			case '留守番電話オプションを利用しない':
				data.decription = '';
				break;
			default:
				break;
		}
		return data;
	}
}
