import { WprModel } from '../../../../../wpr-framework/view/deco/WprModel';
import { WprDI_ScopeModel } from '../../../../../wpr-framework/view/di/WprDI_ScopeModel';
import { HMvnoTmpSaved } from '../../../../service/models/entity/HMvnoTmpSaved';
import { MypageBaseView } from '../../../base/MypageBaseView';
import { MvnoService } from '../../../../service/MvnoService';
import { GetMGeneralListRequest } from '../../../../service/models/dto/mvno/GetMGeneralListRequest';
import { MGeneral } from '../../../../service/models/entity/MGeneral';
import { WprListData } from '../../../../../wpr-framework/view/deco/WprListData';
import { MvnoEntryInfo } from '../../../../service/models/apimodels/MvnoEntryInfo';
import { MvnoEntryFooterReceiveData } from '../mvno_entry_footer/MvnoEntryFooterView';
import { WprViewMode } from '../../../../../wpr-framework/view/deco/WprViewMode';
import { NextButtonMode } from './mode/NextButtonMode';
import { MvnoBaseView } from '../../../base/MvnoBaseView';
import { ObjectUtil } from '../../../../common/util/ObjectUtil';
import { GetPaymentSimulationRequest } from '../../../../service/models/dto/mvno/GetPaymentSimulationRequest';
import { MypageLinkConstatns } from '../../../../common/constants/MypageLinkConstatns';

import { ProgressBarModel, ProgressBarStopModel } from '../../../../models/ProgressBarModel';
import { ProgressBarData } from '../../../common/progress_bar/ProgressBarView';
import { MypageConstants } from '../../../../common/constants/MypageConstants';
import { MGeneralListModel } from '../../../../service/models/apimodels/MGeneralListModel';

/**
 * MVNO申込_容量・コース選択
 */
export class MvnoEntryPlan2View extends MvnoBaseView {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() { super('MvnoEntryPlan2'); }
	// --------------------------------------------------------------------------

	// モデル  ------------------------------------------------------------------
	@WprDI_ScopeModel('MvnoEntryInfo')
	@WprModel('MvnoEntryInfo')
	private m_EntryInfo: MvnoEntryInfo = null;	// MVNOプラン情報

	@WprListData('CapacityRadioList')
	private m_CapacityList: MGeneral[] = null;
	@WprListData('CourseRadioList')
	private m_CourseList: MGeneral[] = null;

	@WprDI_ScopeModel('Rusuban')
	private m_Rusuban: MGeneral = null;
	// --------------------------------------------------------------------------

	// サービス  ----------------------------------------------------------------
	private m_MvnoService: MvnoService = new MvnoService();	// MyPageRenewalサービス
	// --------------------------------------------------------------------------

	// サブビュー/モード  ---------------------------------------------------------
	@WprViewMode
	private m_NextButtonMode = new NextButtonMode();	// 次へボタン用モード
	// --------------------------------------------------------------------------
	// override メソッド  --------------------------------------------------------
	/**
	 * コントロール定義設定
	 */
	public onRegisterConfig(): void {
		this.addConfig('capacityServiceCd', '容量サービスコード', 'MvnoEntryInfo.entryInfo');
		this.addConfig('courseServiceCd', 'コースサービスコード', 'MvnoEntryInfo.entryInfo');
		this.addConfig('fivegUseFlg', '5G通信利用', 'MvnoEntryInfo.entryInfo');
		this.addConfig('rusubanUseFlg', '留守番電話オプション', 'MvnoEntryInfo.entryInfo');
	}

	/**
	 * アクション登録
	 */
	public onRegisterAction(): void {
		this.addAction('next', this.onNext);
		this.addAction('back', this.onBack);
		this.addAction('helpLink', this.onHelpLink);
	}

	/**
	 * ビュー表示通知
	 */
	public onShowView(): void {
		this.registAccessLog();
			this.setStorageItem('b_url', window.location.href)
		this.getCookieValListForMvno(this.m_EntryInfo);
		this.getCapacityList();
		const sendData: ProgressBarData = new ProgressBarData();
		sendData.progressBarList = this.getProgressModelList();
		this.sendChildData('fP_ev_progressBarIntital', sendData);
	}
	private getProgressModelList(): ProgressBarModel[] {
		const list: ProgressBarModel[] = new Array();

		list.push(new ProgressBarModel('1', 'お申し込み手続き', 'complete', this.getStopModelList()));
		list.push(new ProgressBarModel('2', '合計金額ご確認', 'not', null));
		list.push(new ProgressBarModel('3', '事前確認事項', 'not', null));
		list.push(new ProgressBarModel('4', 'WEB本人確認', 'not', null));
		list.push(new ProgressBarModel('5', 'お申込み完了', 'not', null));
		return list;
	}

	private getStopModelList(): ProgressBarStopModel[] {
		const list: ProgressBarStopModel[] = new Array();
		list.push(new ProgressBarStopModel('current'));
		list.push(new ProgressBarStopModel('next'));
		list.push(new ProgressBarStopModel('not'));
		list.push(new ProgressBarStopModel('not'));
		return list;
	}
	/**
	 * 値変更通知
	 * @param name 名前
	 * @param value 値
	 * @returns falseの場合、変更を取り消す
	 */
	public onChangeValue(name: string, value: any): boolean {
		this.refreshModel();
		// 容量
		if (name === 'capacityServiceCd') {
			this.m_EntryInfo.entryInfo.capacity = this.m_CapacityList.find(capacity => {
				return (capacity.generalCd === this.m_EntryInfo.entryInfo.capacityServiceCd)
			}).name2;

			// 0GBの場合
			if (value === '0704000014') {
				if (this.m_EntryInfo.entryInfo.courseServiceCd !== '基本') {
					this.m_EntryInfo.entryInfo.courseServiceCd = null;
					this.m_EntryInfo.entryInfo.course = null;
				}
			}
			this.setPlanViewMode();
			this.getPaymentSimulation();
		}
		// コース（※音声プランの場合のみ）
		else if (name === 'courseServiceCd') {
			this.m_EntryInfo.entryInfo.course = this.m_CourseList.find(course => {
				return (course.generalCd === this.m_EntryInfo.entryInfo.courseServiceCd)
			}).name1;
			this.setPlanViewMode();
			this.getPaymentSimulation();
		}
		// 5G通信利用
		else if (name === 'fivegUseFlg') {
			// this.getPaymentSimulation();
			this.setPlanViewMode();
		}
		// 留守番電話オプション（※音声プランの場合のみ）
		else if (name === 'rusubanUseFlg') {
			this.m_EntryInfo.entryInfo.rusubanServiceCd = value ? this.m_Rusuban.generalCd : null;
			this.setPlanViewMode();
			this.getPaymentSimulation();
		}
		this.setCookieValForMvno(this.m_EntryInfo);
		return true;
	}

	/**
	 * データ受信処理
	 * @param dataName データ名
	 * @param data データ
	 */
	public onReceiveData(dataName: string, data?: any): void {
		if (dataName === 'fC_ev_料金明細表示') {
			this.refreshModel();
			// this.m_EntryInfo.entryInfo.capacity = this.m_CapacityList.find(capacity => {
			// 	return (capacity.generalCd === this.m_EntryInfo.entryInfo.capacityServiceCd) 
			// }).name2;
			// this.sendChildFortempSave('データ量選択');
			this.sendChildFortempSave();
		}
	}
	// --------------------------------------------------------------------------

	// アクション  ---------------------------------------------------------------
	/**
	 * 次へ
	 */
	public onNext(param: any): void {
		this.pushPath('/mvno_004');
	}

	/**
	 * 戻る
	 */
	public onBack(param: any): void {
		this.pushPath('/mvno_002');
	}

	/**
	 * 問い合わせリンク
	 */
	public onHelpLink(param: any): void {
		window.open(MypageLinkConstatns.ITSCOM_MVNOENTRY_HELP);
	}
	// --------------------------------------------------------------------------
	/**
	 * 各選択肢情報取得
	 */
	private getCapacityList(): void {
		const onseiFlg: boolean = (this.m_EntryInfo.entryInfo.plan === '音声プラン');
		this.setViewMode('プラン', onseiFlg ? '音声' : 'データ');
		const req: GetMGeneralListRequest = new GetMGeneralListRequest();
		req.filterList = new Array();
		{
			// データ量
			const filter: MGeneralListModel = new MGeneralListModel();
			filter.mgeneral = new MGeneral();
			filter.mgeneral.codeTypeCd = MypageConstants.MVNO_CAPACITY;
			filter.mgeneral.flag1 = !onseiFlg;
			filter.mgeneral.flag2 = onseiFlg;
			filter.mgeneral.flag3 = !this.m_EntryInfo.noJoinFlg
			filter.mgeneral.flag4 = this.m_EntryInfo.noJoinFlg;
			filter.isCalcTaxIn = true;
			req.filterList.push(filter);
		}
		if (onseiFlg) {
			{
				// コース
				const filter: MGeneralListModel = new MGeneralListModel();
				filter.mgeneral = new MGeneral();
				filter.mgeneral.codeTypeCd = MypageConstants.MVNO_COURSE;
				filter.isCalcTaxIn = true;
				req.filterList.push(filter);
			}
			{
				// 留守番
				const filter: MGeneralListModel = new MGeneralListModel();
				filter.mgeneral = new MGeneral();
				filter.mgeneral.codeTypeCd = MypageConstants.MVNO_RUSUBAN;
				filter.isCalcTaxIn = true;
				req.filterList.push(filter);
			}
		}
		this.refreshView();
		this.m_MvnoService.getMGeneralList(this, req, (result) => {
			// for (const [key, value] of result.listMap.entries()) {
			let map: Map<string, MGeneral[]> = new Map<string, any>(Object.entries(result.listMap));
			map.forEach((value, key) => {
				let map2: Map<string, string> = new Map<string, any>(Object.entries(value));
				switch (key) {
					case MypageConstants.MVNO_CAPACITY:
						this.m_CapacityList = value;
						this.setListData('CapacityRadioList', this.m_CapacityList);
						break;
					case MypageConstants.MVNO_COURSE:
						this.m_CourseList = value;
						this.setListData('CourseRadioList', this.m_CourseList);
						break;
					case MypageConstants.MVNO_RUSUBAN:
						this.m_Rusuban = value[0];
						break;
					default:
						break;
				}
			});
			this.setPlanViewMode();
			this.refreshView();

			// if (onseiFlg) {


			// 	this.

			// }
			// if (onseiFlg) {
			// 	const req2: GetMGeneralListRequest = new GetMGeneralListRequest();
			// 	req2.mGeneral = new MGeneral();
			// 	req2.mGeneral.codeTypeCd = '235';
			// 	req2.calcTaxFlg = true;
			// 	this.m_MvnoService.getMGeneralList(this, req2, (result) => {
			// 	});
			// }
			// else {
			// 	this.refreshView();
			// }
		});
	}

	private setPlanViewMode(): void {
		if (this.m_EntryInfo.entryInfo.plan === '音声プラン') {
			this.setViewMode('データ量', this.isNull('capacityServiceCd') ? '選択済' : '未選択');
			this.setViewMode('コース', this.isNull('courseServiceCd') ? '選択済' : '未選択');
			let flg = false;
			if (this.isNull('capacityServiceCd') && this.isNull('courseServiceCd') && this.isNull('fivegUseFlg')) 
				flg = true;
			this.setViewMode('5G通信', flg? '選択済' : '未選択');
		}
		else {
			this.setViewMode('コース', this.isNull('capacityServiceCd') ? '選択済' : '未選択');
		}
	}
	private isNull(column: string): boolean {
		const val: any = this.m_EntryInfo.entryInfo[column];
		return ObjectUtil.isNotNullOrUndefined(val);
	}


	/**
	 * 料金シミュレーション取得
	 */
	private getPaymentSimulation(): void {
		const req: GetPaymentSimulationRequest = new GetPaymentSimulationRequest();
		req.entryInfo = this.m_EntryInfo;
		req.isIncludeContract = false;
		this.m_MvnoService.getPaymentSimulation(this, req, (result) => {
			this.m_EntryInfo.total = result.total;
			this.refreshView();
			this.sendChildData('fP_ev_料金シミュレーション取得');
		});
	}

	public getCapacityVal(): string {
		return this.m_EntryInfo.entryInfo.capacityServiceCd;
	}

	public getRusubanOptionPrice(): number {
		return this.m_Rusuban?.num2;
	}
}
