import { Divider, Tab, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import React, { ReactNode } from 'react';
import { MatOneRowTable } from '../../../../../wpr-framework/component/controls/material/MatOneRowTable';
import { MatTypography } from '../../../../../wpr-framework/component/controls/material/MatTypography';
import { WprContainer } from '../../../../../wpr-framework/component/controls/WprContainer';
import { WprBaseDialogViewComponent } from '../../../../../wpr-framework/view/component/WprBaseDialogViewComponent';
import { HelpIdentificationDocDialogView } from './HelpIdentificationDocDialogView';
import insuranceCardFront from '/shared/image/mvno/identification/insurance_card_front.png';
import insuranceCardBack from '/shared/image/mvno/identification/insurance_card_back.png';

/**
 * ヘルプ：MNP予約番号について用コンポーネント
 */
export class HelpIdentificationDocDialog extends WprBaseDialogViewComponent {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: any) { super(new HelpIdentificationDocDialogView(), props); }
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** ヘルプ：MNP予約番号についてビュー情報 */
	public get viewInfo(): HelpIdentificationDocDialogView { return this.view as HelpIdentificationDocDialogView; }
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		return (
			<>
				<div className='dialogHeader'>
					<Typography className='title'>本人確認書類について</Typography>
				</div>

				<div className='dialogContent'>
					<div className='itemTitle title_maru'>本人確認書類</div>
					<div>
						※本人確認書類はお手続きによって提出できるものが異なっています。
						各お申し込み時の説明事項でご確認をお願いいたします。
					</div>

					<div>
						<div>


							<Table className='table_header_blue'>
								<TableHead className='tableHeader'>
									<TableRow>
										<TableCell>
											<Typography>書類名</Typography>
										</TableCell>
										<TableCell>
											<Typography>補足</Typography>
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody className='tableBody'>
									<TableRow>
										<TableCell>
											<div>運転免許証</div>
										</TableCell>
										<TableCell>
										</TableCell>
									</TableRow>
									<TableRow>
										<TableCell>
											<div>運転免許証・運転経歴証明書</div>
										</TableCell>
										<TableCell>
										</TableCell>
									</TableRow>
									<TableRow>
										<TableCell>
											<div>パスポート（日本国旅券）</div>
										</TableCell>
										<TableCell>
											<div>住所記載があるもの（自筆可）※1</div>
										</TableCell>
									</TableRow>
									<TableRow>
										<TableCell>
											<div>在留カード</div>
										</TableCell>
										<TableCell>
											顔写真要、残存有効日数90日以上
										</TableCell>
									</TableRow>
									<TableRow>
										<TableCell>
											<div>マイナンバーカード（個人番号カード）</div>
										</TableCell>
										<TableCell>
											<div>うら面の提出は不要です</div>
										</TableCell>
									</TableRow>
									<TableRow>
										<TableCell>
											<div>健康保険証</div>
										</TableCell>
										<TableCell>
											<div>着信転送をお申し込みの場合はご利用いただけません。</div>
											<div>名義変更手続きでは補助書類が必要となります。</div>
										</TableCell>
									</TableRow>
									<TableRow>
										<TableCell>
											<div>その他</div>
										</TableCell>
										<TableCell>
											<div>弊社から「その他書類」を指定の場合のみ</div>
										</TableCell>
									</TableRow>
								</TableBody>
							</Table>
						</div>
						<div>
							※1 住所記載欄がない2020年2月4日以降に発給申請されたパスポートの場合は、オンラインでの本人確認はできません。他の対象書類でご対応ください。
						</div>
						<div className='whitespace' />	
						<div className='itemTitle title_maru'>健康保険証のアップロードについて</div>
							<div className='disp-flex-block'>
								<img src={insuranceCardFront}/>
								<img src={insuranceCardBack}/>
							</div>
							<div className='whitespace' />	
							<div>
							①　以下については必ず、付箋等で隠してから撮影を行ってください。<br/>
							　・記号<br/>
							　・番号<br/>
							　・保険者番号<br/>
							　・QRコード(印字されている場合)<br/>
							<span style={{ color: 'red' }}>　※万が一上記情報が隠されていない場合は、当社にて撮影写真は削除させていただきます。</span><br/>
							<br/>
							②お申込時にご利用者さま情報に入力する住所、氏名、生年月日と、健康保険証に記載されている住所、氏名、生年月日が一致していない場合お申込みは承れません。
						</div>
					</div>
				</div>

			</>
		);
	}
	// --------------------------------------------------------------------------
}
