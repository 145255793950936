/**
 * 利用明細グラフ情報
 */
export class UsageGraphInfo {
	// public 変数	-----------------------------------------------------------
	public customerId: string;			// お客様番号
	public monthList: string[];			// 年月リスト
	public callChargeList: number[];	// 通話料金リスト
	public basicChargeList: number[];	// 基本料金リスト
	public otherChargeList: number[];	// その他料金リスト
	public totalChargeList: number[];	// 合計金額リスト
	public phoneNumberList: string[];	// 電話番号リスト（セレクトで使用）
	// ------------------------------------------------------------------------
}
