import { Card, Divider, TableCell, Typography } from '@material-ui/core';
import { Grid } from '@mui/material';
import React, { ReactNode } from 'react';
import { WprBaseViewComponent } from '../../../../../wpr-framework/view/component/WprBaseViewComponent';
import { MenuBar } from '../../../common/menu_bar/MenuBar';
import { MvnoEntryIdentificationView } from './MvnoEntryIdentificationView';
import styles from './MvnoEntryIdentification.scss';
import { MatButton } from '../../../../../wpr-framework/component/controls/material/MatButton';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { MatCheckBox } from '../../../../../wpr-framework/component/controls/material/MatCheckBox';
import { IdentificationLabel } from '../../../../controls/IdentificationLabel';
import { WprAnchor } from '../../../../../wpr-framework/component/controls/WprAnchor';
import { HelpIdentificationDocDialog } from '../../dialog/help_identification_doc_dialog/HelpIdentificationDocDialog';
import { ProgressBar } from '../../../common/progress_bar/ProgressBar';
import { WprContainer } from '../../../../../wpr-framework/component/controls/WprContainer';
import { Header } from '../../../common/header/Header';
import { MatTypography } from '../../../../../wpr-framework/component/controls/material/MatTypography';

/**
 * MVNO申込_本人確認用コンポーネント
 */
export class MvnoEntryIdentification extends WprBaseViewComponent {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: any) { super(new MvnoEntryIdentificationView(), props); }
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** MVNO申込_本人確認ビュー情報 */
	public get viewInfo(): MvnoEntryIdentificationView { return this.view as MvnoEntryIdentificationView; }
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * スタイルオブジェクト取得
	*/
	public getStylesObject(): any { return styles; }

	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		return (
			<>
				<div>
					<div>
						<IdentificationLabel name='customerId' view={this.view} style={{ display: 'none' }} />
					</div>
					<div>
						<label style={{ display: 'none' }}>MyPage</label>
					</div>
					<div>
						<IdentificationLabel name='environment' view={this.view} style={{ display: 'none' }} />
					</div>
					<div>
						<label style={{ display: 'none' }}>MVNO契約者</label>
					</div>
				</div>

				{/* <Header /> */}
				{/* <div> */}
				<div className='pageCard'>
					<div className="pageHeader stepFlow">
						<ProgressBar name='progressBar' view={this.view} />
					</div>
					<div className='pageContent'>
						<div className='link_blue'>
							<WprAnchor name='helpLink' view={this.view}>申込みについてご不明な方はこちら＞</WprAnchor>
						</div>

						<div className='pageTitle'>
							<div className='title_normal'>
								<Typography className='title_text'>WEB本人確認</Typography>
								<div className='title_memo'>
									本サービスをより安全にご利用になれるよう、<br />
									本人確認にご協力お願いします。
								</div>

							</div>
						</div>


						<div>
							<div className='link_blue'>
								<WprAnchor name='helpIdentificationDoc' view={this.view}>本人確認書類について⊕</WprAnchor>


							</div>
							<div>
								<div style={{ marginBottom: '1em' }}>
									本人確認書類の撮影および、顔認証を行います。
									<br />
									本人確認書類と、スマートフォンが必要です。
									<br />※一部カメラ機能付きパソコンでもご利用可能ですが、本人確認情報が読み取れない場合には画像不鮮明で再対応となる可能性があるため、スマートフォンでの撮影を推奨しております。確認画面で券種の住所がぼやけている場合には、別端末でお試しください。
									<br />本人確認を途中でやり直す場合にはWEB本人確認の画面から入り直しをお願いいたします。
									<br />「オンラインで本人確認をする」選択後の本人確認ページURLのみコピー&ペーストされると、最終画面でエラーになりますのでご注意ください。
									<br />以下の「ご本人さま確認における説明事項」を一読の上、ご本人さま確認へお進みください。





									{/* 以下の点に注意いただき撮影をお願い致します。 <br />
									<ul className='ul_none'>
										<li>本人確認書類に光の反射がないか</li>
										<li>本人確認書類のガイドサイズにあっているか</li>
										<li>撮影モードの設定などで不鮮明でないか</li>
										<li>画面が暗いなどで本人確認書類を読み取れない状況ではないか</li>
										<li>指や文字などで写真が隠れていないか</li>
										<li>斜め撮影の際、厚みが確認できない角度になっていないか</li>
									</ul> */}
								</div>
							</div>
							<div className='box_blue2'>
								<div className='boxTitle'>
									ご本人さま確認における説明事項
								</div>
								<div className='boxContent identificationCheckList'>
									{/* <li>
										お客さま（利用者全員）の本人確認のため次のような本人確認書類等の提示が必要です。
										<br />※有効期間内で現住所のあるものとします。
										<br />※住民票、公共料金領収証、登記簿謄本、印鑑登録証明書については、発行日から3ヵ月以内のものに限ります。
									</li> */}
									<div>

										<div className='boxItemTitle'>
											本人確認書類
										</div>


										<ul className='ul_ten'>
											<li>
												運転免許証
											</li>
											<li>
												運転経歴証明書
											</li>
											<li>
												パスポート（日本国旅券） ※住所記載があるもの（自筆可）
											</li>
											<li>
												在留カード ※顔写真要、残存有効日数 90日以上
											</li>
											<li>
												マイナンバーカード（個人番号カード） ※うら面の提出は不要です。
											</li>
										</ul>
										
										<div className='boxItemComment'>
											<ul className='ul_kome'>
												<li style={{ color: 'red' }}>
													本人確認書類に記載されている氏名・住所・生年月日がお申し込みいただくご契約者さまの「契約者名」「契約者住所」 「生年月日」と一致しているかご確認ください。
												</li>
												<li>
													ご契約者さまとイッツコムスマホのご利用者さまが異なる場合は、ご利用者さまの確認書類もご用意ください。
												</li>
											</ul>
										</div>
									</div>
									<br />
									<div>
										<div className='boxItemTitle'>
											手順
										</div>
										<div>
											ブレたり、見切れたりしないよう注意してください。
											<ol className='ul_ten'>
												<li>
													本人確認書類を撮影（表面・うら面・厚み）※マイナンバーカードのみ、うら面のご提出は不要です。
												</li>
												<li>
													顔の撮影　※本人確認書類との類似性確認ができない場合、再撮影となります。他の本人確認書類もお試しください。
												</li>
												<li>
													かたむきチェック
												</li>
											</ol>
											<br />本人確認情報送信後、内容の確認ができ次第弊社よりご連絡をさせていただきます。
										</div>

									</div>
									<br />
									<div>
										<div className='boxItemTitle'>
											チェック項目
										</div>
										<div>
											以下の点に注意いただき撮影をお願い致します。<br />
											<ul className='ul_check'>
												<li>
													本人確認書類に光の反射がないか
												</li>
												<li>
													本人確認書類のガイドサイズにあっているか
												</li>
												<li>
													撮影モードの設定などで不鮮明でないか
												</li>
												<li>
													画面が暗いなどで本人確認書類を読み取れない状況ではないか
												</li>
												<li>
													指や文字などで写真が隠れていないか
												</li>
												<li>
													斜め撮影の際、厚みが確認できない角度になっていないか
												</li>
											</ul>
										</div>

									</div>
									<br />
									<div>
										<div className='boxItemTitle'>
											【注意事項】
										</div>
										<ul className='ul_ten'>
											<li>
												画像が不鮮明、本人確認情報が不一致等の場合は、再度の対応が必要となります。
											</li>
											<li>
												上記の本人確認書類をご送付いただいた場合でも、他の確認書類の送付をお願いする場合があります。
											</li>
											<li>
												撮影された画像データは返却いたしませんので、あらかじめご了承ください。
											</li>
											<li>
												いただいた画像データは、「電気通信事業法」、「携帯電話不正利用防止法」に基づいたご本人確認の目的のみに利用します。
											</li>
											<li>
												上記法令に基づき、ご契約時にご本人さまを特定させていただいた事項については、当社で記録し、保存させていただきます。
											</li>
										</ul>

									</div>

								</div>
							</div>
							<div style={{ textAlign: 'center' }}>
								<MatCheckBox name='c_confirm' view={this.view} label='ご本人さま確認における説明事項について確認しました。' />


							</div>
							<div>
								<WprContainer mode='確認用チェックボックス.表示' view={this.view}>
									{/* 後で消す */}
									<div style={{ textAlign: 'center' }}>
										<MatCheckBox name='isCompleteIdentification' view={this.view} label='本人確認完了（テスト用）' />
									</div>
									{/* 後で消す */}
								</WprContainer>
							</div>
							<div style={{ textAlign: 'center' }}>
								<MatTypography className='identificationTitle'  name='identificationTitle' view={this.view} />
							</div>
						</div>

					</div>
					<div className='pageFooter'>
						<div>
							<MatButton name='next' view={this.view} className='nextButton' >
								<MatTypography className='MuiButton-label' name='nextButtonName' view={this.view} />
								<NavigateNextIcon />
							</MatButton>

						</div>
						<div>
							<MatButton name='back' view={this.view} className='backButton' >
								<NavigateBeforeIcon />
								戻る
							</MatButton>

						</div>
					</div>
				</div>
				{/* </div> */}

				{/* ダイアログ ---------------------------------------------------------------- */}
				<HelpIdentificationDocDialog name='ヘルプ：本人確認書類について' view={this.view} className='dialog-medium' />
			</>
		);
	}
	// --------------------------------------------------------------------------
}
