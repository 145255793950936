import '@babel/polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import * as serviceWorker from './serviceWorker';
import { MyPageRouter } from './mypagerenewal/view/router/MyPageRouter';
import { FrameworkComponent } from './mypagerenewal/view/framework/FrameworkComponent';
import { MuiThemeProvider } from '@material-ui/core';
import theme from '../public/theme';

ReactDOM.render(
    <React.StrictMode>
        <MuiThemeProvider theme={theme}>
          <FrameworkComponent/>
          <MyPageRouter/>
        </MuiThemeProvider>
    </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
serviceWorker.unregister();
