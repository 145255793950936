import { WprBaseViewMode } from './WprBaseViewMode';
import { WprBaseModeSetter } from './setter/WprBaseModeSetter';
import { IWprConfigModeOption } from './inter/IWprConfigModeOption';
import { WprEnableModeSetter } from './setter/WprEnableModeSetter';
import { WprDisplayModeSetter } from './setter/WprDisplayModeSetter';
import { WprVisibilityModeSetter } from './setter/WprVisibilityModeSetter';
import { WprHissuModeSetter } from './setter/WprHissuModeSetter';
import { WprValueModeSetter } from './setter/WprValueModeSetter';
import { WprStyleModeSetter } from './setter/WprStyleModeSetter';
import { WprClassModeSetter } from './setter/WprClassModeSetter';
import { WprControlInfo } from '../view/control/WprControlInfo';
import { WprListInfo } from '../view/control/list/WprListInfo';
import { WprReadOnlyModeSetter } from './setter/WprReadOnlyModeSetter';
import { WprPlaceholderModeSetter } from './setter/WprPlaceholderModeSetter';

/**
 * モードコントロール定義
 */
export class WprConfigModeControl {
	// private 変数  ------------------------------------------------------------
	private m_Name: string							= null;			// 名称
	private m_ID: string							= null;			// コントロールID
	private m_Check: boolean						= false;		// 判定に使用するフィールドの場合にtrueに設定する
	private m_Render: string						= null;			// レンダー種別
	private m_ModeSetterList: WprBaseModeSetter[] 	= new Array();	// モード設定情報リスト
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	public constructor(name: string, option: IWprConfigModeOption, viewMoode: WprBaseViewMode, id?: string) {
		this.m_Name = name;
		this.m_ID = id;
		if (option.check)
			this.m_Check = option.check;
		if (option.render)
			this.m_Render = option.render;
		this.m_ModeSetterList.push(new WprEnableModeSetter(option, viewMoode, id));
		this.m_ModeSetterList.push(new WprDisplayModeSetter(option, viewMoode, id));
		this.m_ModeSetterList.push(new WprVisibilityModeSetter(option, viewMoode, id));
		this.m_ModeSetterList.push(new WprReadOnlyModeSetter(option, viewMoode, id));
		this.m_ModeSetterList.push(new WprHissuModeSetter(option, viewMoode, id));
		this.m_ModeSetterList.push(new WprValueModeSetter(option, viewMoode, id));
		this.m_ModeSetterList.push(new WprStyleModeSetter(option, viewMoode, id));
		this.m_ModeSetterList.push(new WprClassModeSetter(option, viewMoode, id));
		this.m_ModeSetterList.push(new WprPlaceholderModeSetter(option, viewMoode, id));
	}
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** 名称 */
	public get name(): string 		{ return this.m_Name; 	}
	/** コントロールID */
	public get id(): string 		{ return this.m_ID; 	}
	/** 判定に使用するフィールドの場合にtrueに設定する */
	public get isCheck(): boolean 	{ return this.m_Check; 	}
	/** レンダー種別 */
	public get render(): string 	{ return this.m_Render; }
	// --------------------------------------------------------------------------

	// public メソッド  ----------------------------------------------------------
	/**
	 * コントロール設定処理
	 * @param control コントロール情報
	 * @param modeList モードリスト
	 */
	public setControl(control: WprControlInfo, modeList: string[]): void {
		this.m_ModeSetterList.forEach(setter => {
			setter.controlInfo = control;
			setter.initialize();
			setter.checkMode(modeList);
		});
	}

	/**
	 * リスト情報設定処理
	 * @param listInfo リスト情報
	 * @param modeList モードリスト
	 */
	public setListInfo(listInfo: WprListInfo, modeList: string[]): void {
		this.m_ModeSetterList.forEach(setter => {
			setter.listInfo = listInfo;
			setter.controlName = this.m_Name;
			setter.initialize();
			setter.checkMode(modeList);
		});
	}

	/**
	 * フィールドの状態を設定する
	 * @param mode モード
	 */
	public setStatus(mode: string): void {
		this.m_ModeSetterList.forEach(setter => {
			setter.setStatus(mode);
		});
	}

	/**
	 * 複数モードのフィールドの状態を設定する
	 * @param modeList モードリスト
	 * @param bmode 画面モード
	 */
	public setMultiStatus(modeList: string[], bmode: WprBaseViewMode): void {
		this.m_ModeSetterList.forEach(setter => {
			setter.setMultiStatus(modeList);
		});
	}
	// --------------------------------------------------------------------------
}
