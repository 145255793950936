import { WprBaseViewListMultiModeInfo } from '../../../../../../wpr-framework/mode/WprBaseViewListMultiModeInfo';
import { WprMultiMode } from '../../../../../../wpr-framework/mode/WprMultiMode';
import { TvOptionListModel } from '../../../../../models/mypage/tv/TvOptionListModel';
import { AddTvopSelectView } from '../AddTvopSelectView';

/**
 * オプションボタンモード
 */
export class OpotionButtonMode extends WprBaseViewListMultiModeInfo<AddTvopSelectView, TvOptionListModel> {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() {
		super('TvOptionListModel');
	}
	// --------------------------------------------------------------------------
	
	// override メソッド  --------------------------------------------------------
	/**
	 * モードリスト取得（この画面モードで使用する全てのモードを定義する）
	 * @param list モードリスト
	 */
	public useModeList(list: string[]): void {
		list.push('申込み不可');
		list.push('仮申込み');
		list.push('申込み');
		list.push('仮解約');
		list.push('解約');
	}

	/**
	 * フィールド定義を登録処理
	 */
	public onRegisterControl(): void {
		this.addControl('nextDay', { display: [ { mode: '仮申込み|申込み', value: true }, { mode: 'other', value: false } ] });
		this.addControl('nextMonth', { display: [ { mode: '仮申込み|申込み', value: true }, { mode: 'other', value: false } ] });
		this.addControl('nextCancel', { display: [ { mode: '仮解約|解約', value: true }, { mode: 'other', value: false } ] });
	}

	/**
	 * ビューの状態をチェックして行のモードを変更する
	 * @param curMode 現在のモード
	 * @param newMode 新しいモード
	 * @param row 行データ
	 */
	public onCheckListMultiMode(curMode: WprMultiMode, newMode: WprMultiMode, row: TvOptionListModel): void {
		newMode.addMode(row.contractType); //申込み不可,仮申込み,申込み,仮解約,解約
	}
	// --------------------------------------------------------------------------
}
