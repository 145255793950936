import { BaseService } from './base/BaseService';
import { WprBaseViewCore } from '../../wpr-framework/view/WprBaseViewCore';
import { GetMypWorkScheduleListRequest } from './models/dto/mypagerenewal/GetMypWorkScheduleListRequest';
import { GetMypWorkScheduleListResponse } from './models/dto/mypagerenewal/GetMypWorkScheduleListResponse';
import { GetVacantMypWorkScheduleRequest } from './models/dto/mypagerenewal/GetVacantMypWorkScheduleRequest';
import { GetVacantMypWorkScheduleResponse } from './models/dto/mypagerenewal/GetVacantMypWorkScheduleResponse';
import { SetMypWorkScheduleRequest } from './models/dto/mypagerenewal/SetMypWorkScheduleRequest';
import { SetMypWorkScheduleResponse } from './models/dto/mypagerenewal/SetMypWorkScheduleResponse';
import { CancelMypWorkScheduleRequest } from './models/dto/mypagerenewal/CancelMypWorkScheduleRequest';
import { CancelMypWorkScheduleResponse } from './models/dto/mypagerenewal/CancelMypWorkScheduleResponse';
import { SetMypInstallTerminalRequest } from './models/dto/mypagerenewal/SetMypInstallTerminalRequest';
import { SetMypInstallTerminalResponse } from './models/dto/mypagerenewal/SetMypInstallTerminalResponse';

/**
 * 端末登録サービス
 */
export class TerminalService extends BaseService {
	// サービス メソッド  --------------------------------------------------------
	/**
	 * 端末登録
	 * @param viewCore ビュー情報
	 * @param request リクエスト情報
	 * @param resMethod 結果処理メソッド
	 */
	public setMypInstallTerminal(viewCore: WprBaseViewCore, request: SetMypInstallTerminalRequest, resMethod: (result: SetMypInstallTerminalResponse) => void): void {
		return this.doPost(viewCore, '/terminal/registTerminal', request, resMethod);
	}
	// --------------------------------------------------------------------------
}
