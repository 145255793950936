import { GetGradeResponse } from "../../../service/models/dto/mypagerenewal/GetGradeResponse";
import { GetSimulationResponse } from "../../../service/models/dto/mypagerenewal/GetSimulationResponse";
import { MGeneral } from "../../../service/models/entity/MGeneral";
import { MCampaign } from "../../../service/models/entity/primary/MCampaign";
import { MCampaignGroup } from "../../../service/models/entity/primary/MCampaignGroup";

/**
 * テレビ基本情報モデル
 */
export class TvBaseModel {
	// public 変数	-----------------------------------------------------------
	public type: string;						// 表示区分(まいにち充実プラン、FTTH、その他)
	public courseCd: string;					// コースコード
	public course: string;						// コース
	public tanakoSarviceCd: string;				// 店子サービスコード
	public amount: string;						// 金額
	public terminalCd: string;					// 機器コード
	public terminal: string;					// 機器名
	public terminalDisp: string;				// 機器名
	public rental: boolean;						// レンタル
	public noRental: boolean;					// レンタルなし
	public magazineApply: boolean;				// マガジン申込む
	public magazineUnapply: boolean;			// マガジン申込まない
	public otokuApply: boolean;					// お得パック申込む
	public otokuUnapply: boolean;				// お得パック申込まない
	public monthlyFee: GetSimulationResponse;	// 月額利用料
	public magazine: string;					// マガジン(表示用)
	public magazineFee: string;					// マガジン(月額税込)
	public magazineDisp: string;				// マガジン(2表示用)
	public otokuName: string;					// お得パック(表示用)
	public applyType: string;					// 購入、レンタル(表示用)
	public contractStatus: string;				// コースステータス
	public aCas: string;						// A-CAS番号
	public bCas: string;						// B-CAS番号
	public cCas: string;						// C-CAS番号
	public dummy: boolean;						// ダミー端末
	public directFlg: boolean;					// 申込判定
	public directResult: string;				// 申込判定
	public startDate: string;					// 契約開始日
	public campaignCode: string;				// 入力キャンペーンコード
	public campaignGroup: MCampaignGroup;		// キャンペーングループ
	public campaignList: MCampaign[];				// キャンペーン詳細
	public floor: string;						// 階数
	public location: string;					// 場所
	public comment: string;						// フリー入力
    public desiredDate1: string;				// 第一希望
    public desiredDate2: string;				// 第二希望
    public desiredDate3: string;				// 第三希望
    public selectTime1: string;					// 第一希望　時間帯
    public selectTime2: string;					// 第二希望　時間帯
    public selectTime3: string;					// 第三希望　時間帯
	public reformFlg: boolean;					// リフォームフラグ
	public tvPortFlg: boolean;					// TV端子確認フラグ
	public securityFlg: boolean;				// セキュリティフラグ
	public securityComment: string;				// セキュリティフリー入力
	public beforeContact: string;				// 前日連絡先
	public sameDayContact: string;				// 当日連絡先
	public presentName: string;					// 立ち合い者名
	public constractionFlg: boolean;			// 工事フラグ
	public constrAmount: string;				// 工事費
	public payUnit: string;						// 工事費
	public grade: GetGradeResponse;				// グレード情報
	public error: string;						// エラー内容
	public paymentDispname: string;				// 支払い方法
	public reformDisp: string;					// リフォーム(表示用)
	public securityDisp: string;				// セキュリティ(表示用)
	// ------------------------------------------------------------------------
}
