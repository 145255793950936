import { BaseResponse } from '../BaseResponse';
import { Application } from '../../apimodels/fuse/soap/Application';

/**
 * 書面一覧取得リクエスト
 */
export class GetDocListRequest extends BaseResponse {
	// public 変数	-----------------------------------------------------------
	public applicationList: Application[];	// 書面リスト
	public sessionFlg: boolean;				// 
	public electronicConfirmFlg: boolean;	// 
	public customerId: string;				// 
	public sessionId: string;				// 
	public authToken: string;				// 
	// ------------------------------------------------------------------------
}
