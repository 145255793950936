/**
 * テレビ基本情報モデル
 */
export class DayOfWeekModel {
	// public 変数	-----------------------------------------------------------
	public monday: boolean;								// 月曜日
	public tuesday: boolean;							// 火曜日
	public wednesday: boolean;							// 水曜日
	public thursday: boolean;							// 木曜日
	public friyday: boolean;							// 金曜日
	public saturday: boolean;							// 土曜日
	public sunday: boolean;								// 日曜日
	// ------------------------------------------------------------------------
}
