/**
 * コンポーネントモード情報
 */
export class WprComponentModeInfo {
	// public 変数	-----------------------------------------------------------
	private m_ViewModeName: string;		// ビューモード名
	private m_Mode: string;				// モード
	// ------------------------------------------------------------------------

	// コンストラクタ	--------------------------------------------------------
	constructor(viewMode: string, mode: string) {
		this.m_ViewModeName = viewMode;
		this.m_Mode = mode;
	}
	// ------------------------------------------------------------------------

	// プロパティ  ------------------------------------------------------------
	/** ビューモード名 */
	public get viewModeName(): string 	{ return this.m_ViewModeName; 	}
	/** モード */
	public get mode(): string 			{ return this.m_Mode; 			}
	// ------------------------------------------------------------------------
}
