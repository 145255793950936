import React, { ReactNode } from 'react';
import { WprBaseViewComponent } from '../../../../wpr-framework/view/component/WprBaseViewComponent';
import { LineLiffView } from './LineLiffView';
/**
 * LINE LIFF画面用コンポーネント
 */
export class LineLiff extends WprBaseViewComponent {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: any) { super(new LineLiffView(), props); }
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** 画面ビュー情報 */
	public get viewInfo(): LineLiffView { return this.view as LineLiffView; }
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------

	public onRender(): ReactNode {
		return (
			<>
			</>
		);
	}
	// --------------------------------------------------------------------------
}
