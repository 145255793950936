import { HDocConfirm } from '../../entity/HDocConfirm';

/**
 * データ更新リクエスト
 */
export class UpdateDetailRequest {
	// public 変数	-----------------------------------------------------------
	public hDocConfirm: HDocConfirm;	// 書面確認履歴
	// ------------------------------------------------------------------------
}
