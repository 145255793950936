import { Card, Divider, TableCell, Typography } from '@material-ui/core';
import { Grid } from '@mui/material';
import React, { ReactNode } from 'react';
import { WprBaseViewComponent } from '../../../../../wpr-framework/view/component/WprBaseViewComponent';
import { MenuBar } from '../../../common/menu_bar/MenuBar';
import { MvnoCapacityChange1View } from './MvnoCapacityChange1View';
import styles from './MvnoCapacityChange1.scss';
import { WprRadio } from '../../../../../wpr-framework/component/controls/WprRadio';
import { MatRadio } from '../../../../../wpr-framework/component/controls/material/MatRadio';
import { CheckMarkRadio } from '../../../../controls/checkMarkRadio/CheckMarkRadio';
import { MvnoCapacitySendData, MvnoPlanSendData, CheckMarkRadioContent } from '../../../../controls/checkMarkRadio/CheckMarkRadioContent';
import { MatButton } from '../../../../../wpr-framework/component/controls/material/MatButton';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { MatOneRowTable } from '../../../../../wpr-framework/component/controls/material/MatOneRowTable';
import { MatTextField } from '../../../../../wpr-framework/component/controls/material/MatTextField';
import { WprRowInfo } from '../../../../../wpr-framework/component/props/WprRowInfo';
import { MatTypography } from '../../../../../wpr-framework/component/controls/material/MatTypography';
import { MGeneral } from '../../../../service/models/entity/MGeneral';
import { WprAnchor } from '../../../../../wpr-framework/component/controls/WprAnchor';
import { textAlign } from '@mui/system';
import { ProgressBar } from '../../../common/progress_bar/ProgressBar';

/**
 * MVNO容量変更_申込用コンポーネント
 */
export class MvnoCapacityChange1 extends WprBaseViewComponent {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: any) { super(new MvnoCapacityChange1View(), props); }
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** MVNO容量変更_申込ビュー情報 */
	public get viewInfo(): MvnoCapacityChange1View { return this.view as MvnoCapacityChange1View; }
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * リストコントロール定義登録
	*/
	protected onRegisterListRender(): void {
		this.addListRender('CapacityRadioList', null, this.onCapacityRadioListRender);
	}
	/**
	 * スタイルオブジェクト取得
	*/
	public getStylesObject(): any { return styles; }

	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		return (
			<>
				<div>
					<div className='pageCard'>
						<div className="pageHeader stepFlow">
							<ProgressBar name='progressBar' view={this.view}/>
						</div>
						<div className='pageContent'>
							{/* <div className='planContent'> */}
							<div className='link_blue'>
								<WprAnchor name='helpLink' view={this.view}>申込みについてご不明な方はこちら＞</WprAnchor>
							</div>

							<div className='pageTitle'>
								<div className='title_normal'>
									<Typography className='title_text'>通信データ量を変更</Typography>
								</div>
							</div>


							{/* 現在のお申込み内容 */}
							<div>
								<div className='title_gray'>現在のお申込み内容</div>
								<div className='box_gray'>
									<div className='boxContent onlyPcColGroup'>
										<MatOneRowTable cols={[3, 2]} className='detailItem'>
											<TableCell className='itemTitle'>
												<Typography>プラン</Typography>
											</TableCell>
											<TableCell align='right' className='itemText'>
												{/* <Typography component='span'>6GB（月額：0,000円）</Typography> */}
												<MatTypography name='capacityBefore' view={this.view} component='span' />（月額：<MatTypography name='capacityFeeBefore' view={this.view} component='span' />円）
											</TableCell>
										</MatOneRowTable>
										<MatOneRowTable cols={[3, 2]} className='detailItem'>
											<TableCell className='itemTitle'>
												<Typography>回線番号</Typography>
											</TableCell>
											<TableCell align='right' className='itemText'>
												{/* <Typography component='span'>08041060982</Typography> */}
												<MatTypography name='phoneNumber' view={this.view} component='span' />
											</TableCell>
										</MatOneRowTable>
										<MatOneRowTable cols={[3, 2]} className='detailItem'>
											<TableCell className='itemTitle'>
												<Typography>ICCID</Typography>
											</TableCell>
											<TableCell align='right' className='itemText'>
												{/* <Typography component='span'>GD0605813960830</Typography> */}
												<MatTypography name='iccId' view={this.view} component='span' />
											</TableCell>
										</MatOneRowTable>
									</div>
								</div>
							</div>

							<div>
								{/* 新しい通信データ量 */}
								<div className='title_gray'>新しい通信データ量を選択してください</div>
								<div className='planItem'>
									<Grid container className='entryRadio' spacing={1}>
										{this.renderList('CapacityRadioList')}
									</Grid>
								</div>
							</div>
							{/* </div> */}
						</div>
						<Divider />
						<div className='pageFooter'>
							<div>
								<MatButton name='next' view={this.view} className='nextButton' >
									次へ
									<NavigateNextIcon />
								</MatButton>

							</div>
							<div>
								<MatButton name='back' view={this.view} className='backButton' >
									<NavigateBeforeIcon />
									戻る
								</MatButton>

							</div>
						</div>

					</div>
				</div>
			</>
		);
	}
	// --------------------------------------------------------------------------
	// リスト描画  ---------------------------------------------------------------
	/**
	 * リスト名=CapacityRadioList
	 */
	public onCapacityRadioListRender(rowInfo: WprRowInfo): any {
		const data: MGeneral = rowInfo.rowData as MGeneral;
		const index: string = String(rowInfo.index);
		const selected: string = (this.view as MvnoCapacityChange1View).getCurrentCapcity();
		const disabledSlected: boolean = (data.name2 === selected);
		const disalbed0gb: boolean = (this.view as MvnoCapacityChange1View).isUnlimitedCourse() && data.generalCd === '0704000014';
		
		const disabled: boolean = disabledSlected || disalbed0gb;
		const disabledClass: string = disabled ? 'disabledRadio' : '';
		return (
			<>
				{disabledSlected &&
					<div className='disabledMessageBaloon capacityBallon'>
						<div className='disabledBallonContent'>
							現在利用中プランのため、<br />
							選択不可サービスです
						</div>
					</div>
				}
				{disalbed0gb &&
					<div className='disabledMessageBaloon capacityBallon' style={{top: '85px'}}>
						<div className='disabledBallonContent'>
							かけ放題コースをご利用中のため、<br />
							選択不可サービスです
						</div>
					</div>
				}
				<Grid item xs={12} className={disabledClass}>
					<div className='capacityRadio'>
						{(data.text2) && (<div className='capacityBalloon'>
							<span className='capacityBalloonText'>{data.text2}</span>
						</div>)}
						<CheckMarkRadio
							name='capacityServiceCdAfter'
							id={'capacity_' + index}
							value={data.generalCd}
							itemType={CheckMarkRadioContent.MVNO_CAPACITY}
							radioContentData={this.getCapacityContentData(data)}
							view={this.view}
							disabled={disabled} />
					</div>
				</Grid>
			</>
		);
	}
	// --------------------------------------------------------------------------


	private getCapacityContentData(data: MGeneral): MvnoCapacitySendData {
		const senddata: MvnoCapacitySendData = new MvnoCapacitySendData();
		senddata.val = data.name2;
		senddata.price = data.num2?.toString();
		senddata.decription = data.text1;
		senddata.comment = data.text2;
		return senddata;
	}
}

