import React, { ReactNode } from 'react';
import { WprBaseViewComponent } from '../../../wpr-framework/view/component/WprBaseViewComponent';
import { MenuView } from './MenuView';
import { MatButton } from '../../../wpr-framework/component/controls/material/MatButton';
import { MatTextField } from '../../../wpr-framework/component/controls/material/MatTextField';
import { Divider } from '@material-ui/core';
import { MatSelect } from '../../../wpr-framework/component/controls/material/MatSelect';
import { MatTypography } from '../../../wpr-framework/component/controls/material/MatTypography';
import { WprSpan } from '../../../wpr-framework/component/controls/WprSpan';
import styles from './Menu.scss';
import { WprContainer } from '../../../wpr-framework/component/controls/WprContainer';

/**
 * メニュー（仮）用コンポーネント
 */
export class Menu extends WprBaseViewComponent {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: any) { super(new MenuView(), props); }
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** メニュー（仮）ビュー情報 */
	public get viewInfo(): MenuView { return this.view as MenuView; }
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * スタイルオブジェクト取得
	*/
	public getStylesObject(): any { return styles; }

	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		return (
			<>
				<div>
					↓ここに入力した値はcookieに保存されます
					<div>
						顧客番号（this.getCustomerId()）
						<MatTextField name='customerId' view={this.view} className='select'/>
						<MatButton name='searchViewInfo' view={this.view}>検索</MatButton>
					</div>
					<Divider />
					<div>
						パラメーター部分（?以降）
						<div>
							①SESSIONIDとAUTHTOKEN
							※例：SESSIONID=3da5c9c1bcb49371c64b40da3f40ea4e&AUTHTOKEN=8c2c40d6b5f6dab7aa88af270b6d0ebd
							<div>
								<MatTextField name='parameter' view={this.view} style={{ width: '100%' }} />
							</div>
						</div>
					</div>
				</div>
				<Divider />
				{/* ツールから追加 START */}
				<div>
					<h2>契約内容確認</h2>
					<div>
						契約番号（this.getContractCd()）
						<MatTextField name='contractId' view={this.view} />
						<MatButton name='ContractDetails' view={this.view}>契約内容確認へ</MatButton>
					</div>
				</div>
				<div>
					<h2>MVNO</h2>
					<MatButton name='SimDataENtry' view={this.view}>音声SIM・データ申込へ</MatButton>
					<div>
						契約番号（this.getContractCd()）
						<MatTextField name='contractId' view={this.view} />
						<MatButton name='CapacityChange' view={this.view}>容量変更へ</MatButton>
					</div>
					<MatButton name='MnpInquiry' view={this.view}>MNP開通問い合わせ</MatButton>
					<div>
						契約番号（this.getContractCd()）
						<MatTextField name='contractId' view={this.view} />
						<MatButton name='MvnoAddCoupon' view={this.view}>クーポン対応（データ容量追加申込）へ</MatButton>
					</div>
				</div>
				<div>
					<h2>アンバサダー</h2>
					<MatButton name='Ambassador' view={this.view}>アンバサダーへ</MatButton>
				</div>
				<div>
					<h2>利用明細</h2>
					<MatButton name='UsageDetails' view={this.view}>利用明細へ</MatButton>
				</div>
				<div>
					<h2>契約内容一覧</h2>
					<MatButton name='ContractList' view={this.view}>契約内容一覧へ</MatButton>
				</div>
				<div>
					<h2>ご契約内容確認・変更</h2>
					<MatSelect name='service' vm='serviceList' view={this.view} className='select'/>
					<MatButton name='ServiceList' view={this.view}>サービス変更へ</MatButton>
					<div>
						顧客番号（this.getCustomerId()）
						<MatTextField name='customerId' view={this.view} className='select'/>
						<MatButton name='searchViewInfo' view={this.view}>検索</MatButton>
					</div>
					<div>
						契約番号（this.m_MypageWizardMngr.getParams().contractId）
						<MatSelect name='contractId' vm='契約ID' view={this.view} className='select'/>
					</div>
					<div>
						オプション（this.m_MypageWizardMngr.getParams().optionId）
						<MatSelect name='optionId' vm='オプションID' view={this.view} className='select'/>
					</div>
					<div>
						機器番号
						<MatTextField name='tid' view={this.view} />
					</div>
					<div>
						在庫種別
						<MatTextField name='stcd' view={this.view} />
					</div>
					<div>
						機器タイプ
						<MatTextField name='ttcd' view={this.view} />
					</div>
				</div>
				<div>
					<h2>検索結果</h2>
					<WprSpan name='searchResult' view={this.view}/>
				</div>
				{/* ツールから追加 END */}
			</>
		);
	}
	// --------------------------------------------------------------------------
}
