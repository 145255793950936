import { WprBaseValueModeSetter } from './WprBaseValueModeSetter';
import { IWprModeValue } from '../inter/IWprModeValue';
import { WprBaseViewMode } from '../WprBaseViewMode';
import { IWprConfigModeOption } from '../inter/IWprConfigModeOption';

/**
 * モード値設定
 */
export class WprValueModeSetter extends WprBaseValueModeSetter<IWprModeValue> {
	// コンストラクタ  -----------------------------------------------------------
	public constructor(option: IWprConfigModeOption, viewMoode: WprBaseViewMode, id?: string) {
		super('value', option, viewMoode, id);
	}
	// --------------------------------------------------------------------------

	// override メソッド	-----------------------------------------------------
	/**
	 * 初期化処理
	 * @param option モード定義オプション
	 */
	public initialize(): void {
		this.initializeModeList(this.option.value);
	}

	/**
	 * コントロールステータス設定処理
	 * @param vinfo 値情報
	 * @param id コントロールID
	 */
	public setControlStatus(vinfo: IWprModeValue, id?: string): void {
		this.controlInfo.value = vinfo.value;
		this.controlInfo.isSetModeValue = true;
	}
	// --------------------------------------------------------------------------
}
