import { WprValidErrorInfo } from './WprValidErrorInfo';
import { WprBaseViewCore } from '../WprBaseViewCore';
import { WprControlInfo } from '../control/WprControlInfo';

/**
 * 入力エラー情報
 */
export class WprValidError {
	// private 変数  ------------------------------------------------------------
	private m_ViewCore: WprBaseViewCore				= null;		// ビュー情報
	private m_ErrorInfoList: WprValidErrorInfo[]	= null;		// エラー情報リスト
	private m_ErrorMessageList: string[]			= null;		// エラーメッセージリスト
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** エラーフラグ */
	public get isError(): boolean 					{ return this.m_ErrorInfoList != null;	}
	/** エラーフラグ */
	public get errorInfoList(): WprValidErrorInfo[] { return this.m_ErrorInfoList;			}
	// --------------------------------------------------------------------------

	// コンストラクタ	----------------------------------------------------------
	constructor(viewCore: WprBaseViewCore) {
		this.m_ViewCore = viewCore;
	}
	// --------------------------------------------------------------------------

	// public メソッド  ----------------------------------------------------------
	/**
	 * エラー情報追加
	 * @param cinfo コントロール情報
	 * @param errorInfo エラー情報
	 */
	public addErrorInfo(cinfo: WprControlInfo, errorInfo: WprValidErrorInfo): void {
		if (this.m_ErrorInfoList == null) {
			this.m_ErrorInfoList = new Array();
			this.m_ErrorMessageList = new Array();
		}
		for(const einfo of this.m_ErrorInfoList) {
			if (einfo.controlName === errorInfo.controlName && einfo.rowIndex === errorInfo.rowIndex && einfo.validatorName === errorInfo.validatorName)
				return;
		}
		this.m_ErrorInfoList.push(errorInfo);
		let msg = null;
		let orgMsg = errorInfo.errorMessage;
		if (errorInfo.isCustom === true || errorInfo.controlName === 'custom' || errorInfo.validatorName === 'custom') {
			msg = errorInfo.errorMessage;
			orgMsg = msg;
		}
		else if (errorInfo.rowIndex >= 0) {
			msg = `${errorInfo.controlDesc}：${errorInfo.rowIndex+1}行目：${errorInfo.errorMessage}`;
		}
		else {
			msg = `${errorInfo.controlDesc}：${errorInfo.errorMessage}`;
		}
		const oldmsg = msg;
		msg = this.m_ViewCore.onInvalidMessage(errorInfo.controlName, errorInfo.rowIndex, errorInfo.validatorName, msg);
		if (msg !== oldmsg)
			orgMsg = msg;
		if (msg != null)
			this.m_ErrorMessageList.push(msg);
		if (orgMsg != null && cinfo != null)
			cinfo.setErrorMessage(orgMsg);
		errorInfo.errorMessage = msg;
	}

	/**
	 * エラー情報削除
	 * @param errmsg エラーメッセージ
	 * @returns trueの場合は、エラーは無くなった
	 */
	public removeErrorInfo(errmsg: string): boolean {
		if (this.m_ErrorInfoList != null) {
			const removeList = new Array();
			for(const einfo of this.m_ErrorInfoList) {
				if (einfo.errorMessage === errmsg || (errmsg == null && einfo.validatorName === 'custom'))
					removeList.push(einfo);
			}
			for(const remove of removeList) {
				const idx = this.m_ErrorInfoList.indexOf(remove);
				this.m_ErrorInfoList.splice(idx, 1);
			}
			if (this.m_ErrorInfoList.length === 0) {
				this.m_ErrorInfoList = null;
				return true;
			}
		}
		return false;
	}

	/**
	 * エラー追加処理(相関チェックなどのエラーを追加する)
	 * @param errorMessage エラーメッセージ
	 * @param name エラー対象となる名前
	 * @return 追加したエラー情報
	 */
	public addError(errorMessage: string, name: string = 'custom'): WprValidErrorInfo {
		const errInfo = new WprValidErrorInfo(name, 'custom', -1, 'custom', 'custom', errorMessage);
		this.addErrorInfo(null, errInfo);
		this.m_ViewCore.customValidErrorCount++;
		return errInfo;
	}

	/**
	 * エラーメッセージリスト取得
	 * @エラーメッセージ
	 */
	public getErrorMessageList(): string[] {
		return this.m_ErrorMessageList;
	}
	// --------------------------------------------------------------------------
}
