import { WprBaseViewMultiMode } from './WprBaseViewMultiMode';

/**
 * 複数モード情報
 */
export class WprMultiMode {
	// private 変数  ------------------------------------------------------------
	private m_MultiMode: WprBaseViewMultiMode	= null;				// 画面モード情報
	private m_ModeList: string[]				= null;				// 指定可能モードリスト
	private m_CurModeList: string[]				= new Array();		// 現在のモードリスト
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	public constructor(modeInfo: WprBaseViewMultiMode, list: string[]) {
		this.m_MultiMode = modeInfo;
		this.m_ModeList = list;
	}
	// --------------------------------------------------------------------------

	// public メソッド  ----------------------------------------------------------
	/**
	 * モード設定
	 * @param mode モード
	 */
	public addMode(mode: string): void {
		if (this.m_ModeList.includes(mode) === true) {
			if (this.m_CurModeList.includes(mode) === false)
				this.m_CurModeList.push(mode);
		}
		else {
			this.m_MultiMode.addErrorLog(`モード（addModeの設定値）に誤りがあります。[${mode}]`);
		}
	}

	/**
	 * 現在のモード取得
	 * @return 現在のモード
	 */
	public getMode(): string {
		let sb = '';
		this.m_CurModeList.forEach(mode => {
			if (sb.length > 0)
				sb += ',';
			sb += mode;
		});
		return sb;
	}

	/**
	 * モード数取得
	 * @return モード数
	 */
	public getModeCount(): number {
		return this.m_CurModeList.length;
	}

	/**
	 * モードリスト取得
	 * @return モードリスト
	 */
	public getModeList(): string[] {
		const list = new Array();
		this.m_CurModeList.forEach(mode => {
			list.push(mode);
		});
		return list;
	}

	/**
	 * モードが現在のモードに含まれているか調べる
	 * @param mode モード
	 * @return trueの場合含まれる
	 */
	public includes(mode: string): boolean {
		return this.m_CurModeList.includes(mode);
	}
	// --------------------------------------------------------------------------
}
