import { Card, Divider, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import { Grid } from '@mui/material';
import React, { ReactNode } from 'react';
import { WprBaseViewComponent } from '../../../../../wpr-framework/view/component/WprBaseViewComponent';
import { MenuBar } from '../../../common/menu_bar/MenuBar';
import { MvnoMnpInquiryConfirmView } from './MvnoMnpInquiryConfirmView';
import { MatButton } from '../../../../../wpr-framework/component/controls/material/MatButton';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { MatTypography } from '../../../../../wpr-framework/component/controls/material/MatTypography';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { MatOneRowTable } from '../../../../../wpr-framework/component/controls/material/MatOneRowTable';
import { WprContainer } from '../../../../../wpr-framework/component/controls/WprContainer';
import { MatCheckBox } from '../../../../../wpr-framework/component/controls/material/MatCheckBox';
import { WprAnchor } from '../../../../../wpr-framework/component/controls/WprAnchor';
import styles from './MvnoMnpInquiryConfirm.scss';

/**
 * MVNOMNP開通問い合わせ_確認用コンポーネント
 */
export class MvnoMnpInquiryConfirm extends WprBaseViewComponent {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: any) { super(new MvnoMnpInquiryConfirmView(), props); }
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** MVNOMNP開通問い合わせ_確認ビュー情報 */
	public get viewInfo(): MvnoMnpInquiryConfirmView { return this.view as MvnoMnpInquiryConfirmView; }
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * スタイルオブジェクト取得
	*/
	public getStylesObject(): any { return styles; }

	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		return (
			<>
				{/* Milosから追加 START */}



				{/* Milosから追加 END */}
				<div>
					<div className='pageCard'>
						<div className="pageHeader title">
							MNP開通問い合わせ確認
						</div>
						<Divider />

						{/* 申込内容 */}
						<div className='pageContent'>
							<div className='title_gray'>
								<span>入力情報のご確認</span>
							</div>
							<div>
								<div className='box_gray'>

									<div className='boxContent onlyPcColGroup'>
										<MatOneRowTable cols={[3, 2]} className='detailItem'>
											<TableCell className='itemTitle'>
												<Typography>利用者氏名</Typography>
											</TableCell>
											<TableCell align='right' className='itemText'>
												<MatTypography name='familyName' view={this.view} component='span' />
												<MatTypography name='firstName' view={this.view} component='span' />
											</TableCell>
										</MatOneRowTable>

										<MatOneRowTable cols={[3, 2]} className='detailItem'>
											<TableCell className='itemTitle'>
												<Typography>利用者シメイ</Typography>
											</TableCell>
											<TableCell align='right' className='itemText'>
												<MatTypography name='familyNameKana' view={this.view} component='span' />
												<MatTypography name='firstNameKana' view={this.view} component='span' />
											</TableCell>
										</MatOneRowTable>

										<MatOneRowTable cols={[3, 2]} className='detailItem'>
											<TableCell className='itemTitle'>
												<Typography>MNP対象の電話番号</Typography>
											</TableCell>
											<TableCell align='right' className='itemText'>
												<MatTypography name='phoneNumber' view={this.view} />
											</TableCell>
										</MatOneRowTable>

										<MatOneRowTable cols={[3, 2]} className='detailItem'>
											<TableCell className='itemTitle'>
												<Typography>ICCID</Typography>
											</TableCell>
											<TableCell align='right' className='itemText'>
												<MatTypography name='iccId' view={this.view} />
											</TableCell>
										</MatOneRowTable>
									</div>
								</div>
							</div>

							<div className='title_gray'>
								<span>注意事項</span>
							</div>
							<div>
								<div className='mnpAlert'>
									<Table className='table_header_blue'>
										<TableHead className='tableHeader'>
											<TableRow>
												<TableCell>
													<Typography>お問い合わせ時間</Typography>
												</TableCell>
												<TableCell>
													<Typography>MNP完了予定時間</Typography>
												</TableCell>
											</TableRow>
										</TableHead>
										<TableBody className='tableBody'>
											<TableRow>
												<TableCell>
													<div>9:30~17:29</div>
												</TableCell>
												<TableCell>
													<div>当日中2時間程度</div>
												</TableCell>
											</TableRow>
											<TableRow>
												<TableCell>
													<div>17:30~翌9:29</div>
												</TableCell>
												<TableCell>
													<div>
														翌9:30～12時頃までの間
													</div>
												</TableCell>
											</TableRow>
										</TableBody>
									</Table>
									<div className='comments'>
										万が一、開通しない場合には<WprAnchor name='supportlink' view={this.view} className='link_underline_blue'>こちら</WprAnchor>よりイッツコムスマホご利用ガイド（イッツコムSIM音声プラン）をご確認ください。
									</div>
								</div>
								<div style={{ textAlign: 'center' }}>
									<MatCheckBox name='confirm' view={this.view} label='上記の注意事項について確認しました。' />
								</div>
							</div>
						</div>

						<div className='pageFooter'>
							<div>
								<MatButton name='next' view={this.view} className='nextButton' >
									この内容で申込む
									<NavigateNextIcon />
								</MatButton>

							</div>
							<div>
								<MatButton name='back' view={this.view} className='backButton' >
									<NavigateBeforeIcon />
									戻る
								</MatButton>

							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
	// --------------------------------------------------------------------------
}
