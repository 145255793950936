import BigNumber from "bignumber.js";
import { ConvertUtil } from "./ConvertUtil";

/**
 * 小数点を含む数値の計算
 */
export class SmallNumberCalcUtil {
	/**
	 * 対象配列の数値を合計
	 * @param list 対象配列(String or Number)
	 * @returns 合計
	 */
	public static sum(list: any[]): number {
		let result = new BigNumber(0);
		for (const item of list) {
			result = result.plus(ConvertUtil.fromAny2Num(item))
		}
		return result.toNumber();
	}

	/**
	 * 対象配列の数値と積
	 * @param list 対象値配列(String or Number)
	 * @returns 積
	 */
	public static product(list: any[]): number {
		let result = new BigNumber(1);
		list.forEach((item, index) => {
			result = result.times(ConvertUtil.fromAny2Num(item))
		});
		return result.toNumber();
	}

	/**
	 * 足し算
	 * @param num1 数値(String or Number)
	 * @param num2 数値(String or Number)
	 * @returns 計算結果
	 */
	public static plus(num1: any, num2: any): number {
		let result = new BigNumber(ConvertUtil.fromAny2Num(num1)).plus(ConvertUtil.fromAny2Num(num2));
		return result.toNumber();
	}

	/**
	 * 引き算
	 * @param num1 引かれる数値(String or Number)
	 * @param num2 引く数値(String or Number)
	 * @returns 計算結果
	 */
	public static minus(num1: any, num2: any): number {
		let result = new BigNumber(ConvertUtil.fromAny2Num(num1)).minus(ConvertUtil.fromAny2Num(num2));
		return result.toNumber();
	}

	/**
	 * 掛け算
	 * @param num1 数値(String or Number)
	 * @param num2 数値(String or Number)
	 * @returns 計算結果
	 */
	public static times(num1: any, num2: any): number {
		let result = new BigNumber(ConvertUtil.fromAny2Num(num1)).times(ConvertUtil.fromAny2Num(num2));
		return result.toNumber();
	}

	/**
	 * 割り算
	 * @param num1 割られる数値(String or Number)
	 * @param num2 割る数値(String or Number)
	 * @returns 計算結果
	 */
	public static div(num1: any, num2: any): number {
		let result = new BigNumber(ConvertUtil.fromAny2Num(num1)).div(ConvertUtil.fromAny2Num(num2));
		return result.toNumber();
	}

	/**
	 * 割り算の整数部
	 * @param num1 割られる数値(String or Number)
	 * @param num2 割る数値(String or Number)
	 * @returns 計算結果(商)
	 */
	public static idiv(num1: any, num2: any): number {
		let result = new BigNumber(ConvertUtil.fromAny2Num(num1)).idiv(ConvertUtil.fromAny2Num(num2));
		return result.toNumber();
	}

	/**
	 * 割り算の剰余
	 * @param num1 割られる数値(String or Number)
	 * @param num2 割る数値(String or Number)
	 * @returns 計算結果
	 */
	public static mod(num1: any, num2: any): number {
		let result = new BigNumber(ConvertUtil.fromAny2Num(num1)).mod(ConvertUtil.fromAny2Num(num2));
		return result.toNumber();
	}
}