import { WprDI_ScopeModel } from '../../../../../wpr-framework/view/di/WprDI_ScopeModel';
import { MypageBaseView } from '../../../base/MypageBaseView';
import { ProgressBarData } from '../../../common/progress_bar/ProgressBarView';
import { MypageWizardMngr } from '../../../wizard/models/MypageWizardMngr';
import { WprModel } from '../../../../../wpr-framework/view/deco/WprModel';
import { WprListData } from '../../../../../wpr-framework/view/deco/WprListData';
import { Contract } from '../../../../models/bss/contract/Contract';
import { Contract as OwnerContract} from "../../../../models/bss/customer/Contract";
import { ObjectUtil } from '../../../../common/util/ObjectUtil';
import { Customer } from '../../../../models/bss/customer/Customer';
import { ContractInfo } from '../../../../view/Srvnet/revise/mod_net_select/models/ContractInfo';
import MypageDispdivcdConstants from '../../../../common/MypageDispdivcdConstants';
import { ServiceModel } from '../../../../service/models/apimodels/ServiceModel';
import { MCommon } from '../../../../service/models/entity/primary/MCommon';
import { NetServiceNewWizardModel } from '../../../wizard/NetServiceNewWizardFlow';
import { MDisplay } from '../../../../service/models/entity/secondary/MDisplay';
import { WprViewMode } from '../../../../../wpr-framework/view/deco/WprViewMode';
import { CourseListMode } from './mode/CourseListMode';
import { BaseInfoListModel } from './models/BaseInfoListModel';
import { GetNetInfoRequest } from '../../../../service/models/dto/mypagerenewal/GetNetInfoRequest';
import { ItscomNetService } from '../../../../service/ItscomNetService';
import { TanakoModel } from '../../../../service/models/apimodels/TanakoModel';
import { CourseListModel } from '../../../../models/mypage/net/CourseListModel';
import { NetFlowModel } from '../../../../models/mypage/net/NetFlowModel';
import { ErrorModel } from '../../../../models/mypage/smart/ErrorModel';
import MypageCodeTypeConstants from '../../../../common/MypageCodeTypeConstants';
import { MGeneral } from '../../../../service/models/entity/MGeneral';

/**
 * ネット変更コース選択
 */
export class ModNetSelectView extends MypageBaseView {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() { super('ModNetSelectView'); }
	// --------------------------------------------------------------------------

	// モデル  ------------------------------------------------------------------
	@WprDI_ScopeModel('MypageWizardMngr')
	private m_MypageWizardMngr: MypageWizardMngr			= null;	// 画面ウィザード管理クラス
	@WprListData('CourseListModel')
	private m_CourseListModel: CourseListModel[]			= null;	// コースリスト
	@WprModel('ContractInfo')
	private m_ContractInfo: ContractInfo					= null;	// 契約情報
	@WprModel('NetFlowModel')
	private m_NetFlowModel: NetFlowModel					= null;	// ネット遷移情報
	@WprListData('BaseInfoList')
	private m_BaseInfoList: BaseInfoListModel[]				= null;	// 基本情報リスト
	@WprListData('ErrorModel')
	private m_ErrorModelList: ErrorModel[]					= null;	// エラーモデル
	// --------------------------------------------------------------------------

	// サブビュー/モード  --------------------------------------------------------
	@WprViewMode
	private m_CourseListMode = new CourseListMode();		// コースリストモード
	// --------------------------------------------------------------------------

	// メンバ変数  --------------------------------------------------------------
	private m_Contract: Contract							= null;	// 契約情報
	private m_Customer:	Customer							= null;	// 顧客情報
	private m_OwnerContract: OwnerContract[]				= null; // オーナー契約情報
	private m_BuildingType: MCommon							= null;	// 物件プラン情報
	private m_CourseServiceList: ServiceModel[]				= null;	// コース情報リスト
	private m_LineType: MDisplay							= null; // 回線種別
	private m_TanakoList: CourseListModel[]					= null;	// 店子コースリスト
	private m_TanakoModel: TanakoModel						= null; // 店子モデル
	private m_CoursePlus: MCommon							= null;	// かっとびプラス
	private m_CourseJust: MCommon							= null;	// かっとびジャスト
	private m_NetServiceNewModel: NetServiceNewWizardModel  = null;	// ネット追加モデル
	// --------------------------------------------------------------------------

	// サービス  ----------------------------------------------------------------
	private m_ItscomNetService: ItscomNetService	= new ItscomNetService();	// ItscomNetサービス
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** ウィザード定義 */
	public get mngr(): MypageWizardMngr 					{ return this.m_MypageWizardMngr; }
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * コントロール定義設定
	 */
	public onRegisterConfig(): void {
		this.addListConfig('cDispnamep', 'コース名', 'CourseListModel');
		this.addListConfig('nDispnamep', '回線種別', 'CourseListModel');
		this.addListConfig('payAmount', '料金一覧', 'CourseListModel', { converter: '3桁カンマ' });
		this.addListConfig('payUnit', '料金単位', 'CourseListModel');
		this.addListConfig('check', '選択', 'CourseListModel');
		this.addConfig('contractCourse', '契約コース名', 'ContractInfo');
		this.addConfig('contractAmount', '月額', 'ContractInfo', { converter: '3桁カンマ' });
		this.addConfig('contractStatus', '契約ステータス', 'ContractInfo');
		this.addConfig('macAddress', 'MACアドレス', 'ContractInfo');
		this.addConfig('deviceType', '機器の種類', 'ContractInfo');
		this.addConfig('deviceForm', '機器の形態', 'ContractInfo');
		this.addConfig('accessAccount', 'アクセスアカウント', 'ContractInfo');
		this.addListConfig('itemName', '項目名', 'BaseInfoList');
		this.addListConfig('itemData', '項目データ', 'BaseInfoList');
		// エラーメッセージ
		this.addListConfig('errorMessage', 'エラーメッセージ', 'ErrorModel');
	}

	/**
	 * アクション登録
	 */
	public onRegisterAction(): void {
		this.addAction('next', this.onNext);
		this.addAction('back', this.onBack);
		this.addAction('other', this.onOther);
	}

	/**
	 * ビュー表示通知
	 */
	public onShowView(): void {
		this.m_MypageWizardMngr.initView(this, (result) => {
			this.registAccessLog();
			this.setStorageItem('b_url', window.location.href)
			const sendData: ProgressBarData = new ProgressBarData();
			sendData.progressBarList = this.m_MypageWizardMngr.getProgressModelList(this);
			this.sendChildData('fP_ev_progressBarIntital', sendData);
			// 遷移情報
			this.m_NetFlowModel = this.m_MypageWizardMngr.getCache().m_NetFlowModel;
			this.m_NetServiceNewModel = new NetServiceNewWizardModel();
			// 申し込み情報のクリア
			// this.m_MypageWizardMngr.getCache().m_NetInfo = new CourseListModel();
			// this.m_MypageWizardMngr.getCache().m_OtherCourseListModel = new Array();
			// 契約情報
			this.m_ContractInfo = this.m_MypageWizardMngr.getCache().m_ContractInfo;
			if (this.m_ContractInfo.dupFlg) {
				this.setViewMode('エラー', '表示');
				this.m_ErrorModelList = new Array();
				const model = new ErrorModel();
				model.errorMessage = '同一のご契約を選択されています。変更後の契約内容を選択してお申込ください。';
				this.m_ErrorModelList.push(model);
				this.setListData('ErrorModel', this.m_ErrorModelList);
			}
			const contractId = this.m_MypageWizardMngr.getParams().contractId;
			if (ObjectUtil.isNotNullOrEmptyStr(contractId)) {
				this.m_Contract = this.m_MypageWizardMngr.getContract(this, contractId);
				this.m_ContractInfo.contractId = this.m_Contract.contractID;
			}
			// 顧客情報
			this.m_Customer = this.m_MypageWizardMngr.getViewInfo().customer;
			// オーナー契約
			this.m_OwnerContract = this.m_MypageWizardMngr.getViewInfo().ownerContractList;
			// 物件プラン
			this.m_BuildingType = this.m_MypageWizardMngr.getBuildingPlan(this, this.m_OwnerContract);
			this.setViewInfo();									// 画面情報設定
			this.m_NetServiceNewModel.setCourseDisplay(this);	// コースリスト(PC/SP)判定
			this.setCourseList();
			this.refreshView();
		});
	}

	/**
	 * リスト値変更通知
	 * @param name 名前
	 * @param listName リスト名
	 * @param row 行データ
	 * @param value 値
	 * @returns falseの場合、変更を取り消す
	 */
	public onChangeListValue(name: string, listName: string, row: any, value: any): boolean {
		if (name === 'check') {
			// check全て外す
			for (const list of this.m_CourseListModel)
				list.check = false;
			row.check = true;
			this.refreshListView();
		}
		return true;
	}
	// --------------------------------------------------------------------------

	// アクション  ---------------------------------------------------------------
	/**
	 * 確認
	 */
	public onNext(param: any): void {
		this.refreshModel();
		this.m_NetFlowModel.otherFlg = false;
		this.m_NetFlowModel.kattobiFlg = false;
		this.m_NetFlowModel.defaultFlg = true;
		this.m_MypageWizardMngr.goNext(this);
	}

	/**
	 * 戻る
	 */
	public onBack(param: any): void {
		this.m_MypageWizardMngr.getCache().m_CourseListModel = new Array();
		this.m_MypageWizardMngr.goBack(this);
	}

	/**
	 * その他のコースはこちら
	 */
	public onOther(param: any): void {
		this.refreshModel();
		this.m_NetFlowModel.otherFlg = true;
		this.m_NetFlowModel.defaultFlg = false;
		this.m_MypageWizardMngr.goNext(this);
	}
	// --------------------------------------------------------------------------

	// private メソッド  --------------------------------------------------------
	/**
	 * 画面情報設定
	 */
	private setViewInfo() {
		/** インターネットのコース変更 */
		if (this.m_Customer.building[0].buildingLineTypeCD === '2') {
			// FTTHの場合
			this.setViewMode('インターネットコース変更','FTTH');
			this.setViewMode('コース一覧','FTTH');
		}
		else {
			// FTTH以外の場合
			this.setViewMode('インターネットコース変更','FTTH以外');
			this.setViewMode('コース一覧','FTTH以外');
			this.setViewMode('注釈FTTH以外','表示');
			this.setViewMode('その他コースエリア', 'FTTH以外');
		}
		// お得パック
		const ContractPlan = this.m_MypageWizardMngr.getViewInfo().contractModel;
		const otokuFlg = ObjectUtil.isNotNullOrUndefined(this.m_MypageWizardMngr.getOtokuPlanName(this, ContractPlan.planList));
		if (otokuFlg) {
			this.setViewMode('注釈お得パック契約中','表示');
		}
		this.m_ContractInfo = this.m_MypageWizardMngr.getCache().m_ContractInfo;
		// 契約コース
		const contractCourseServise = this.m_MypageWizardMngr.getServiceList(this, MypageDispdivcdConstants.ACCESSPASS_SERVICE);
		this.m_ContractInfo.contractCourse = contractCourseServise[0].display.dispnamep;
		this.m_ContractInfo.contractAmount = this.m_MypageWizardMngr.getTaxFee(this, String(contractCourseServise[0].service.payment[0].amount));
		this.m_ContractInfo.serviceCd = contractCourseServise[0].service.serviceCD;			
		//AP無料サービス判定
		const apService = this.m_MypageWizardMngr.getMGenralList(this, MypageCodeTypeConstants.AP_FREE_CODE, '');
		const constractService = apService.filter(item => item.generalCd === contractCourseServise[0].display.namecd);
		// 契約ステータス
		const statusName = this.m_MypageWizardMngr.getMDisplayList(this, MypageDispdivcdConstants.ITSCOMHOME_SERVICESTATUS, '');
		this.m_ContractInfo.contractStatus = statusName.filter(row => row.namecd === this.m_Contract.statusCD)[0].dispnamep;
		// 契約リスト
		this.m_BaseInfoList = this.m_MypageWizardMngr.getCache().m_BaseInfoList;
		if (ObjectUtil.isNullOrUndefined(this.m_BaseInfoList) || this.m_BaseInfoList.length === 0) {
			// MACアドレス
			const macInfo = new BaseInfoListModel();
			macInfo.itemName = 'MACアドレス';
			macInfo.itemData = this.m_Contract.equipment[0].equipmentID;
			this.m_BaseInfoList.push(macInfo);
			// 機器の種類/形態
			const deviceInfo = new BaseInfoListModel();
			deviceInfo.itemName = '機器の種類';
			const equ1 = this.m_MypageWizardMngr.getMCommonList(this, 'Add_net', 'equ', 'req', null);
			const equ2 = this.m_MypageWizardMngr.getMCommonList(this, 'Add_net', 'equ', 'req2', null);
			if (ObjectUtil.isNullOrUndefined(this.m_Contract.equipment[0].equipmentTypeCD) || this.m_Contract.equipment[0].equipmentTypeCD === equ1[0].commonValue ||this.m_Contract.equipment[0].equipmentTypeCD === equ2[0].commonValue) {
				const device10000 = this.m_MypageWizardMngr.getMCommonList(this, 'Add_net', 'equ', 'rep', null);
				this.m_ContractInfo.equipmentTypeCd = device10000[0].commonValue;
				deviceInfo.itemData = '';
			}
			else {
				this.m_ContractInfo.equipmentTypeCd = this.m_Contract.equipment[0].equipmentTypeCD;
				const deviceNameList = this.m_MypageWizardMngr.getMDisplayList(this, MypageDispdivcdConstants.ITSCOMHOME_TERMINALTYPE, '');
				const deviceFormList = this.m_MypageWizardMngr.getMDisplayList(this, MypageDispdivcdConstants.ITSCOMHOME_TERMINALSTATUS, '');
				const deviceType = deviceNameList.filter(row => row.namecd === this.m_Contract.equipment[0].equipmentTypeCD);
				const deviceForm = deviceFormList.filter(row => row.namecd === this.m_Contract.equipment[0].rentalFlag);
				if (ObjectUtil.isNotNullOrUndefined(deviceType) && deviceType.length > 0)
					deviceInfo.terminalType = deviceType[0].dispnamep;
				if (ObjectUtil.isNotNullOrUndefined(deviceForm) && deviceForm.length > 0)
					deviceInfo.terminalForm = deviceForm[0].dispnamep;
				if (ObjectUtil.isNotNullOrUndefined(deviceInfo.terminalType) && ObjectUtil.isNotNullOrUndefined(deviceInfo.terminalForm))
					deviceInfo.itemData = deviceInfo.terminalType + ' ' + deviceInfo.terminalForm;
				// 機器の種類のない場合(※要確認)
				else if (ObjectUtil.isNullOrUndefined(deviceInfo.terminalType) && ObjectUtil.isNotNullOrUndefined(deviceInfo.terminalForm))
					deviceInfo.itemData = deviceInfo.terminalForm;
			}
			this.m_BaseInfoList.push(deviceInfo);
			// アクセスアカウント
			const accessAccountList = this.m_MypageWizardMngr.getMCommonList(this, 'srvnet', 'accessaccount', 'serviceid', null);
			for (const accessAccount of accessAccountList) {
				if (this.m_Contract.serviceCD === accessAccount.commonValue) {
					for (const account of  this.m_Contract.radiusAccount) {
						const accessInfo = new BaseInfoListModel();
						accessInfo.itemName = 'アクセスアカウント';
						accessInfo.itemData = account.radiusAccountID;
						this.m_BaseInfoList.push(accessInfo);
					}
				}
			}
		}
		this.setListData('BaseInfoList', this.m_BaseInfoList);
		// ダミー端末
		const dummyList = this.m_MypageWizardMngr.getMCommonList(this, 'Dummy_Equipment', 'dummyid', null, null);
		this.m_ContractInfo.dummyFlg = false;
		for (const dummy of dummyList) {
			if (this.m_Contract.equipment[0].equipmentID && this.m_Contract.equipment[0].equipmentID.includes(dummy.commonValue)) {
				this.m_ContractInfo.dummyFlg = true;
				break;
			}
		}
	}

	/**
	 * コースリスト作成
	 */
	// private setCourseList() {
	// 	this.m_CourseListModel = this.m_MypageWizardMngr.getCache().m_CourseListModel;
	// 	const areaFig: boolean = this.m_MypageWizardMngr.getViewInfo().inAreaFlg;
	// 	const netServiceList = this.m_MypageWizardMngr.getMGenralList(this, MypageCodeTypeConstants.NET_TEN_SERVICE, '');
	// 	if (ObjectUtil.isNullOrUndefined(this.m_CourseListModel) || this.m_CourseListModel.length === 0) {
	// 		const req: GetNetInfoRequest = new GetNetInfoRequest();
	// 		req.customerId = this.m_MypageWizardMngr.getParams().customerId;
	// 		req.ownerList = this.m_OwnerContract;
	// 		req.buildingLineTypeCD = this.m_Customer.building[0].buildingLineTypeCD;
	// 		req.buildingTypeCD = this.m_Customer.building[0].buildingTypeCD;
	// 		this.m_ItscomNetService.getOwnerList(this, req, (result) => {
	// 			if (result) {
	// 				const lineList = this.m_MypageWizardMngr.getMDisplayList(this, MypageDispdivcdConstants.ITSCOMNET_LINETYPE, '');
	// 				loop: for (const course of result.tanakoModel.tanakoList) {
	// 					for (const netSevice of netServiceList) {
	// 						if (!areaFig && netSevice.generalCd === course.tanakoServiceCd) {
	// 							continue loop;
	// 						}
	// 					}
	// 					const model = new CourseListModel();
	// 					model.cDispnamep = course.serviceDispName;
	// 					const amount = result.tanakoModel.serviceList.filter(data => data.serviceCD === course.tanakoServiceCd);
	// 					if (amount && amount.length > 0) {
	// 						if (amount[0].payment[0].amount === 0)
	// 							model.payAmount = '-';
	// 						else {
	// 							model.payAmount = this.m_MypageWizardMngr.getTaxFee(this, String(amount[0].payment[0].amount));
	// 							model.payUnit = '円';
	// 						}
	// 					}
	// 					if (course.ftthFlg)
	// 						model.nDispnamep = lineList.filter(data => data.namecd === '20')[0].dispnamep;
	// 					else
	// 						model.nDispnamep = lineList.filter(data => data.namecd === '10')[0].dispnamep;
	// 					model.serviceCd = course.normalServiceCd;
	// 					model.tanakoServiceCd = course.tanakoServiceCd;
	// 					model.tanakoOwnerCd = course.ownerServiceCd;
	// 					this.m_CourseListModel.push(model);
	// 				}
	// 			}
	// 			// デフォルトチェック
	// 			if (this.m_CourseListModel.length > 0)
	// 				this.m_CourseListModel[0].check = true;
	// 			else
	// 				this.setNonMsg();
	// 			this.setListData('CourseListModel', this.m_CourseListModel);
	// 		});
	// 	}
	// 	else 
	// 		this.setListData('CourseListModel', this.m_CourseListModel);
	// }

	private setCourseList() {
		this.m_CourseListModel = this.m_MypageWizardMngr.getCache().m_CourseListModel;
		if (ObjectUtil.isNullOrUndefined(this.m_CourseListModel) || this.m_CourseListModel.length === 0) {
			const req: GetNetInfoRequest = new GetNetInfoRequest();
			req.customerId = this.m_MypageWizardMngr.getParams().customerId;
			req.ownerList = this.m_OwnerContract;
			req.buildingLineTypeCD = this.m_Customer.building[0].buildingLineTypeCD;
			req.buildingTypeCD = this.m_Customer.building[0].buildingTypeCD;
			this.m_ItscomNetService.getOwnerList(this, req, (result) => {
				if (result) {
					const lineList = this.m_MypageWizardMngr.getMDisplayList(this, MypageDispdivcdConstants.ITSCOMNET_LINETYPE, '');
					for (const course of result.tanakoModel.tanakoList) {
						const model = new CourseListModel();
						model.cDispnamep = course.serviceDispName;
						const amount = result.tanakoModel.serviceList.filter(data => data.serviceCD === course.tanakoServiceCd);
						if (amount && amount.length > 0) {
							if (amount[0].payment[0].amount === 0)
								model.payAmount = '-';
							else {
								model.payAmount = this.m_MypageWizardMngr.getTaxFee(this, String(amount[0].payment[0].amount));
								model.payUnit = '円';
							}
						}
						if (course.ftthFlg)
							model.nDispnamep = lineList.filter(data => data.namecd === '20')[0].dispnamep;
						else
							model.nDispnamep = lineList.filter(data => data.namecd === '10')[0].dispnamep;
						model.serviceCd = course.normalServiceCd;
						model.tanakoServiceCd = course.tanakoServiceCd;
						model.tanakoOwnerCd = course.ownerServiceCd;
						this.m_CourseListModel.push(model);
					}
				}
				// デフォルトチェック
				if (this.m_CourseListModel.length > 0)
					this.m_CourseListModel[0].check = true;
				else
					this.setNonMsg();
				this.setListData('CourseListModel', this.m_CourseListModel);
			});
		}
		else 
			this.setListData('CourseListModel', this.m_CourseListModel);
	}

	/**
	 * コースリストが空の場合
	 */
	private setNonMsg() {
		if (this.m_Customer.building[0].buildingLineTypeCD === '2' && this.m_Customer.building[0].buildingTypeCD === '1' && 
		(this.m_BuildingType.commonValue === '1' || this.m_BuildingType.commonValue === '2'))
			this.setViewMode('光回線データなし','表示');
		else
			this.setViewMode('データなし','表示');
	}
	// --------------------------------------------------------------------------
}
