import { WprValueMap } from '../../../../wpr-framework/model/WprValueMap';
import { WprValueMapSetter } from '../../../../wpr-framework/model/WprValueMapSetter';

/**
 * MVNO申込台数
 */
export class MvnoEntryNumValueMap {
	// static メソッド  --------------------------------------------------------
	public static addValueMap(vmSetter: WprValueMapSetter): void {
		let vm = new WprValueMap();
		vm.addValue('1', '1');
		vm.addValue('2', '2');
		vm.addValue('3', '3');
		vm.addValue('4', '4');
		vm.addValue('5', '5');
		vmSetter.addValueMap('mvnoEntryNum', vm);
	}
	// --------------------------------------------------------------------------
}
