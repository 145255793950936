import { WprBaseViewMultiModeInfo } from '../../../../../../wpr-framework/mode/WprBaseViewMultiMode';
import { WprMultiMode } from '../../../../../../wpr-framework/mode/WprMultiMode';
import { MvnoEntryBaseMode } from '../../../../base/mode/MvnoEntryBaseMode';
import { WprDI_Object } from '../../../../../../wpr-framework/view/di/WprDI_Object';
import { MvnoEntryInfo } from '../../../../../service/models/apimodels/MvnoEntryInfo';
import { HMvnoTmpSaved } from '../../../../../service/models/entity/HMvnoTmpSaved';
import { ObjectUtil } from '../../../../../common/util/ObjectUtil';
import { WprDI_Control } from '../../../../../../wpr-framework/view/di/WprDI_Control';
import { WprControlInfo } from '../../../../../../wpr-framework/view/control/WprControlInfo';
import { runInThisContext } from 'vm';
import { MvnoEntryPreConfirmView } from '../MvnoEntryPreConfirmView';

/**
 * 次へボタン用モード
 */
export class NextButtonMode extends WprBaseViewMultiModeInfo<MvnoEntryPreConfirmView> {
	// DI情報  ------------------------------------------------------------------
	@WprDI_Control
	private m_c_confirmList: WprControlInfo	= null;	// 確認
	@WprDI_Control
	private m_c_confirm1: WprControlInfo	= null;	// 確認
	@WprDI_Control
	private m_c_confirm2: WprControlInfo	= null;	// 確認
	@WprDI_Control
	private m_c_confirm3: WprControlInfo	= null;	// 確認
	@WprDI_Object
	private m_EntryInfo: MvnoEntryInfo	= null;	// MVNOプラン情報
	// @WprDI_Control
	// private m_capacityServiceCd: WprControlInfo	= null;	// 容量
	// @WprDI_Control
	// private m_course: WprControlInfo	= null;	// 容量
	// @WprDI_Control
	// private m_fivegUseFlg: WprControlInfo	= null;	// 容量
	// --------------------------------------------------------------------------


	// コンストラクタ  -----------------------------------------------------------
	public constructor() {
		super('nextButtonMode');
	}
	// --------------------------------------------------------------------------
	/**
	 * モードリスト取得（この画面モードで使用する全てのモードを定義する）
	 * @param list モードリスト
	 */
	public useModeList(list: string[]): void {
		list.push('次へ活性');
		list.push('次へ非活性');
		list.push('重説確認済');
		list.push('重説未確認');
		list.push('iPhone重説確認済');
		list.push('iPhone重説未確認');
	}
	// override メソッド  --------------------------------------------------------
	/**
	 * フィールド定義を登録処理
	 */
	public onRegisterControl(): void {
		// this.addControl('course', { check: true });
		// this.addControl('capacityServiceCd', { check: true });
		// this.addControl('fivegUseFlg', { check: true });
		this.addControl('next', { enabled: [ { mode: '次へ活性', value: true }, { mode: 'other', value: false } ] });
		this.addControl('c_confirm1', { check: true });
		this.addControl('c_confirm2', { check: true });
		this.addControl('c_confirm3', { check: true });
		this.addControl('c_confirmList', { check: true });
		this.addControl('b_importantDoc1', { class: [ { mode: '重説確認済', class: 'checked' }, { mode: 'other', class: '' } ] });
		this.addControl('b_importantDoc2', { class: [ { mode: 'iPhone重説確認済', class: 'checked' }, { mode: 'other', class: '' } ] });
	}
	// --------------------------------------------------------------------------
	/**
	 * ビューの状態をチェックしてモードを変更する
	 * @param curMode 現在のモード
	 * @param newMode 新しいモード
	 */
	public onCheckMultiMode(curMode: WprMultiMode, newMode: WprMultiMode): void {
		const isCheck: boolean = this.checkIsChecked([this.m_c_confirmList, this.m_c_confirm1, this.m_c_confirm2, this.m_c_confirm3]);

		const isClicked1: boolean = (this.getViewMode('重説') === '確認済');
		newMode.addMode(isClicked1 ? '重説確認済' : '重説未確認');	// 重説確認済,重説未確認

		const isClicked2: boolean = (this.getViewMode('iPhone重説') === '確認済');
		newMode.addMode(isClicked2 ? 'iPhone重説確認済' : 'iPhone重説未確認');	// iPhone重説確認済,iPhone重説未確認

		let isEnabled: boolean = false;
		if (this.m_EntryInfo.iPhoneFlg) {
			isEnabled = (isCheck && isClicked1 && isClicked2);
		} else {
			isEnabled = (isCheck && isClicked1);
		}
		// const isEnabled: boolean = (isCheck && isClicked)
		if (isEnabled) {
			newMode.addMode('次へ活性');
		}
		else {
			newMode.addMode('次へ非活性');
		}


	}

	/**
	 * 値が入っているかチェック
	 * @param controlList 
	 * @returns 
	 */
	private checkIsChecked(controlList: WprControlInfo[]): boolean {
		for (let control of controlList) {
			const val: any = control.value;
			if (!val)  
				return false;
		}
		return true;
	}
	
}
