import { WprBaseViewMultiModeInfo } from '../../../../../wpr-framework/mode/WprBaseViewMultiMode';
import { WprMultiMode } from '../../../../../wpr-framework/mode/WprMultiMode';
import { ConstrSearchView } from '../ConstrSearchView';
import { WprDI_Object } from '../../../../../wpr-framework/view/di/WprDI_Object';
import { ConstrDateListModel } from '../../../../models/mypage/Construction/ConstrDateListModel';
import { ConstrBaseModel } from '../../../../models/mypage/Construction/ConstrBaseModel';

/**
 * InputCheckMode
 */
export class InputCheckMode extends WprBaseViewMultiModeInfo<ConstrSearchView> {
	// DI情報  ------------------------------------------------------------------
	@WprDI_Object
	private m_ConstrDateListModel: ConstrDateListModel[]	= null;	// 工事リストモデル
	@WprDI_Object
	private m_ConstrBaseModel: ConstrBaseModel	= null;	// 工事日基本情報モデル
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	public constructor() {
		super('InputCheckMode');
	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * モードリスト取得（この画面モードで使用する全てのモードを定義する）
	 * @param list モードリスト
	 */
	public useModeList(list: string[]): void {
		list.push('入力済み');
		list.push('未入力');
	}

	/**
	 * フィールド定義を登録処理
	 */
	public onRegisterControl(): void {
		this.addControl('next', { enabled: [ { mode: '入力済み', value: true }, { mode: 'other', value: false } ] });
	}

	/**
	 * 画面の状態をチェックしてモードを変更する
	 * @param curMode 現在のモード
	 * @param newMode 新しいモード
	 */
	public onCheckMultiMode(curMode: WprMultiMode, newMode: WprMultiMode): void {
		let flg = false;
		if (this.m_ConstrDateListModel) {
			for (const data of this.m_ConstrDateListModel) {
				if (data.select)
					flg = true;
			}
		}
		if (flg && 
			this.m_ConstrBaseModel.beforeContact &&
			this.m_ConstrBaseModel.sameDayContact &&
			this.m_ConstrBaseModel.name)
			newMode.addMode('入力済み');
		else
			newMode.addMode('未入力');
	}
	// --------------------------------------------------------------------------
}
