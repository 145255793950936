import { WprKeyValue } from "../../../../wpr-framework/model/WprKeyValue";
import { WprControlInfo } from "../../../../wpr-framework/view/control/WprControlInfo";
import { WprModel } from "../../../../wpr-framework/view/deco/WprModel";
import { WprDI_Control } from "../../../../wpr-framework/view/di/WprDI_Control";
import { WprDI_ScopeModel } from "../../../../wpr-framework/view/di/WprDI_ScopeModel";
import MypageDispdivcdConstants from "../../../common/MypageDispdivcdConstants";
import { Nhk } from "../../../models/bss/nhk/Nhk";
import { NhkDiscountService } from "../../../service/NhkDiscountService";
import { MDisplay } from "../../../service/models/entity/secondary/MDisplay";
import { MypageBaseView } from "../../base/MypageBaseView";
import { ProgressBarData } from "../../common/progress_bar/ProgressBarView";
import { MypageWizardMngr } from "../../wizard/models/MypageWizardMngr";
import { WprViewMode } from '../../../../wpr-framework/view/deco/WprViewMode';
import { ConfirmMode } from './mode/ConfirmMode';


/**
 * 支払いコース選択
 */
export class AddNhkSelectView extends MypageBaseView {

	// コンストラクタ  -----------------------------------------------------------
	public constructor() { super('AddNhkSelectView'); }
	// --------------------------------------------------------------------------

	// DI情報  ------------------------------------------------------------------
	@WprDI_Control
	private m_discountSelect: WprControlInfo	= null;	// 割引種類
	
	// --------------------------------------------------------------------------

	// private m_ZenkakuValidator: WprZenkakuValidator = new WprZenkakuValidator();    //全角バリデーター
    // private m_NumberValidator: WprNumberValidator = new WprNumberValidator();   //整数バリデーター
	private m_MDisplayList: MDisplay[];
	
	// モデル  ------------------------------------------------------------------
	@WprDI_ScopeModel('MypageWizardMngr')
	private m_MypageWizardMngr: MypageWizardMngr			= null;		// 画面ウィザード管理クラス
	@WprModel('discountInfo')
	private m_discountInfo: Nhk	= null;	// nhkモデル
	// @WprListData('ErrorModel')
	// private m_ErrorModelList: ErrorModel[]	= null;	// エラーモデル
	
	// --------------------------------------------------------------------------

	// サービス  ----------------------------------------------------------------
	 private m_NhkDiscountService: NhkDiscountService	= new NhkDiscountService();	// Nhk団体一括サービス
	// --------------------------------------------------------------------------

	// サブビュー/モード  --------------------------------------------------------
	@WprViewMode
	private m_ConfirmMode = new ConfirmMode();	// 確認モード
	// @WprViewMode
	// private m_MultiCheck = new MultiCheck();	// ボタンの活性
	// @WprViewMode
	// private m_CheckAddress = ne---------------w CheckAddress();	// 住所の活性
	// -----------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * コントロール定義設定
	 */
	public onRegisterConfig(): void {
		this.addConfig('discountSelect', '割引種類', 'discountInfo', { hissu: true });
		this.addConfig('confirm', '確認しました', null);
		// this.addConfig('checkBox1', '契約住所へ送付', 'discountInfo');
		// this.addConfig('checkBox2', '契約住所以外に送付', 'discountInfo');
		// this.addConfig('ConfirmCheck', '確認ボタン', null, { disabled: true , hissu: true });
		// this.addConfig('postalNumber', '郵便番号', null, { maxLength: 7});
		// this.addConfig('address', '住所', 'discountInfo', { maxLength: 50});
		// this.addConfig('buildingName', '建物名', 'discountInfo', { maxLength: 50});
		// this.addConfig('roomNumber', '部屋番号', 'discountInfo', { maxLength: 5});
		// this.addConfig('mailing', '宛名', 'discountInfo', { maxLength: 50});
		// this.addListConfig('errorMessage', 'エラーメッセージ', 'ErrorModel');
	}

	/**
	 * アクション登録
	 */
	public onRegisterAction(): void {
		// this.addAction('next', this.onNext, true);←確認押さなかった場合上までスクロールする
		this.addAction('next', this.onNext);
		this.addAction('back', this.onBack);
	}
	/**
	 * ビュー表示通知
	 */
	public onShowView(): void {
		this.m_MypageWizardMngr.initView(this, (result) => {
			this.registAccessLog();
			this.setStorageItem('b_url', window.location.href)
			const sendData: ProgressBarData = new ProgressBarData();
			sendData.progressBarList = this.m_MypageWizardMngr.getProgressModelList(this);
			this.sendChildData('fP_ev_progressBarIntital', sendData);
			this.m_discountSelect.resetValueMap();
			// this.m_ErrorModelList = new Array();
			// const model = new ErrorModel();
			// model.errorMessage = 'エラーリスト';
			// this.m_ErrorModelList.push(model);
			// this.setListData('ErrorModel', this.m_ErrorModelList);

			this.m_MDisplayList = [] 
			this.m_MDisplayList.push(this.m_MypageWizardMngr.getMDisplay(this,MypageDispdivcdConstants.NHK_DISCOUNTTYPE,'1'));
			this.m_MDisplayList.push(this.m_MypageWizardMngr.getMDisplay(this,MypageDispdivcdConstants.NHK_DISCOUNTTYPE,'2'));
			this.m_MDisplayList.push(this.m_MypageWizardMngr.getMDisplay(this,MypageDispdivcdConstants.NHK_DISCOUNTTYPE,'3'));
			this.m_discountInfo = this.m_MypageWizardMngr.getCache().discountInfo;
			this.m_discountSelect.value = '1';
		
			// 画面を戻しても値を保持できているように
			// if(this.m_discountInfo){
				// this.m_address.value = this.m_discountInfo.address;
				// this.m_buildingName.value = this.m_discountInfo.buildingName;
				// this.m_mailing.value = this.m_discountInfo.mailing;
				// this.m_postalNumber.value = this.m_discountInfo.postalNumber;
				// this.m_roomNumber.value = this.m_discountInfo.roomNumber;
				// this.m_checkBox1.value = this.m_discountInfo.checkBox1;
				// this.m_checkBox2.value = this.m_discountInfo.checkBox2;
			// }
			//  画面を戻した際に割引種類の情報を保持できているように
			if(this.m_discountInfo.discountSelect){
				const changeVm = this.vm('discountSelect','割引種類');
				for(const value of changeVm){
					if(value.value === this.m_discountInfo.discountSelect){
						this.m_discountSelect.value = value.key;
					}
				}
			}
			// this.refreshView();	
		});
		
		// this.refreshView();
	}

	// public onChangeValue(name: string , value: any): boolean{
	// 	if(name === 'checkBox1' || name === 'checkBox2'){
	// 		if(value === true){
	// 			for(let i = 1 ;i<3 ; i++){
	// 				if('checkBox' + i !== name){
	// 					this['m_checkBox' + i].value = false
	// 				}
	// 			}
	// 		}
	// 		if(this.m_checkBox1.value === false && this.m_checkBox2.value === false){
	// 			this.m_ConfirmCheck.value = false;
	// 		}
			
	// 	this.refreshView();
	// 	}
	// 	return true;
	// }

	/**
	 * ValueMap再設定処理
	 * @param name コントロール名
	 * @param id ValueMapID
	 * @list ValueMapのKey-Valueリスト
	 * @returns 再設定したKey-Valueリスト
	 */
	public onResetValueMap(name: string, id: string, list: WprKeyValue[]): WprKeyValue[] {
	
		if(name === 'discountSelect' && this.m_MDisplayList){
			list = new Array();
			for(const model of this.m_MDisplayList){	
				list.push(new WprKeyValue(model.namecd, model.dispnamep));
			}
		}
		
		return list;
	}


	// /**
	// * バリデーションエラーメッセージ通知
	// * @param name コントロール名
	// * @param rowIndex 行数(Listの場合)
	// * @param validatorName バリデーション名
	// * @param msg エラーメッセージ
	// * @returns エラーメッセージ（変更する場合に指定する、メッセージを使用しない場合は、nullを返す）
	// */
	// public onInvalidMessage(name: string, rowIndex: number, validatorName: string, msg: string): string {
	// 	if(this.m_checkBox2.value === true){
	// 		if (name === 'postalNumber') {
	// 			// if (validatorName === '必須') {
	// 			// 	return '郵便番号を入力してください';
	// 			// }
	// 			if (validatorName === '最大文字数'|| validatorName === '最小文字数') {
	// 				return '郵便番号は7桁で入力してください';
	// 			} else{
	// 				return ''
	// 			}
	// 		}
	// 		else if (name === 'address') {
	// 			// 住所
	// 			// if (validatorName === '必須') {
	// 			// 	return '住所を入力してください';
	// 			// }
	// 			// 住所文字数チェック
	// 			if (validatorName === '最大文字数') {
	// 				return '住所は50文字以内で入力して下さい';
	// 			}
	// 		}
	// 		else if (name === 'buildingName') {
	// 			// 建物名
	// 			if(validatorName === '最大文字数') {
	// 				return '住所は50文字以内で入力して下さい';
	// 			}
	// 		}else if (name === 'roomNumber') {
	// 			//部屋番号
	// 			if (validatorName === '最大文字数') {
	// 				return '部屋番号は5桁以内で入力して下さい';
	// 			}
	// 		}else if (name === 'mailing') {
	// 			//宛名
	// 			// if (validatorName === '必須') {
	// 			// 	return '宛名を入力してください';
	// 			// }
	// 			// 新しいパスワード（再入力）文字数チェック
	// 			if (validatorName === '最大文字数') {
	// 				return '宛名は50文字以内で入力して下さい';
	// 			}
	// 		}
	// 	}
	// 	return msg;
		
	// }

	/**
	 * 入力チェック（独自のValidationを実施する場合に使用する）
	 *	エラー追加例：validError.addError('エラーメッセージ');				// 相関チェックなどのエラー
	 * 				 this.setControlError('control', 'error');			 // コントロールのエラー
	 * 				 validError.showErrorMessage.('エラーメッセージ');	   // エラーメッセージ表示
	 * 		※上記のエラーを追加した場合は、アクションは実行しません。
	 * 		※コントロールの値をチェックする場合は、refreshModelは使用しないでください。
	 * 		　コントロールの値をチェックする場合は、コントロールをDIしてvalueを使用してください。
	 * 		　コントロールが双方向のコンバータを使用している場合は、dataValueを使用してください。
	 * @param actionName アクション名
	 * @param validError バリデーションエラー情報
	 * @param row 行情報
	 */
	//  protected onValid(actionName: string, validError: WprValidError, row?: any): void {
	// 	if(this.m_checkBox2.value === true){
	// 		if (actionName === 'next') {
	// 			this.m_ErrorModelList = new Array();
	// 			// 郵便番号
	// 			if (this.m_postalNumber.value) {
	// 				// 数字チェック
	// 				if(this.m_postalNumber.value && !this.m_NumberValidator.checkValidValue(this.m_postalNumber.value)){
	// 					const model = new ErrorModel();
	// 					model.errorMessage = '郵便番号は半角数字で入力してください';
	// 					this.m_ErrorModelList.push(model);
	// 				}else if(this.m_postalNumber.value.length !== 7){
	// 					const model = new ErrorModel();
	// 					model.errorMessage = '郵便番号は7桁で入力してください';
	// 					this.m_ErrorModelList.push(model);
	// 				}
	// 				// else {
	// 				// 	// 全角→半角変換
	// 				// 	this.m_postalNumber.value = this.m_postalNumber.value.replace(/[０-９]/g, function(s) {
	// 				// 		return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
	// 				// 	});
	// 				// }
	// 			}
	// 			else{
	// 				const model = new ErrorModel();
	// 				model.errorMessage = '郵便番号を入力してください';
	// 				this.m_ErrorModelList.push(model);
	// 			}
				
	// 			// 部屋番号
	// 			if (this.m_roomNumber.value) {
	// 				// 数字チェック
	// 				if(this.m_roomNumber.value && !this.m_NumberValidator.checkValidValue(this.m_roomNumber.value)){
	// 					const model = new ErrorModel();
	// 					model.errorMessage = '部屋番号は半角数字で入力してください';
	// 					this.m_ErrorModelList.push(model);
	// 				}
	// 				// else {
	// 				// 	// 全角→半角変換
	// 				// 	this.m_roomNumber.value = this.m_roomNumber.value.replace(/[０-９]/g, function(s) {
	// 				// 		return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
	// 				// 	});
	// 				// }
	// 			}
				
	// 			// 住所
	// 			if (this.m_address.value){
	// 				if(this.m_address.value && !this.m_ZenkakuValidator.checkValidValue(this.m_address.value)){
	// 					const model = new ErrorModel();
	// 					model.errorMessage = '住所は全角で入力してください';
	// 					this.m_ErrorModelList.push(model);
	// 				}
	// 			}
	// 			else if(this.m_address.value === ''){
	// 				const model = new ErrorModel();
	// 				model.errorMessage = '住所を入力してください';
	// 				this.m_ErrorModelList.push(model);
	// 			}
	// 			// 建物名
	// 			if (this.m_buildingName.value){
	// 				if(this.m_buildingName.value && !this.m_ZenkakuValidator.checkValidValue(this.m_buildingName.value)){
	// 					const model = new ErrorModel();
	// 					model.errorMessage = '建物名は全角で入力してください';
	// 					this.m_ErrorModelList.push(model);
	// 				}
	// 			}
	// 			// 宛名
	// 			if (this.m_mailing.value){
	// 				if(this.m_mailing.value && !this.m_ZenkakuValidator.checkValidValue(this.m_mailing.value)){
	// 					const model = new ErrorModel();
	// 					model.errorMessage = '宛名は全角で入力してください';
	// 					this.m_ErrorModelList.push(model);
	// 				}
	// 			}
	// 			else if(this.m_mailing.value === ''){
	// 					const model = new ErrorModel();
	// 					model.errorMessage = '宛名を入力してください';
	// 					this.m_ErrorModelList.push(model);
	// 			}
	// 			if (this.m_ErrorModelList.length !== 0) {
	// 				this.setViewMode('オプション','エラー')
	// 				this.setListData('ErrorModel', this.m_ErrorModelList);
	// 				validError.addError('エラー');		
	// 			}
	// 			this.refreshListView('ErrorModel');
	// 		}
	// 	}
	// }
	// --------------------------------------------------------------------------

	// アクション  ---------------------------------------------------------------
	

	/**
	 * 確認         <NavigateNextIcon />
	 */
	public onNext(param: any): void {
		this.refreshModel();
		// this.m_discountInfo.postalNumber = this.m_postalNumber.value;
		// this.m_discountInfo.address = this.m_address.value;
		// this.m_discountInfo.buildingName = this.m_buildingName.value;
		// this.m_discountInfo.roomNumber = this.m_roomNumber.value;
		// this.m_discountInfo.mailing = this.m_mailing.value;
		// this.m_discountInfo.checkBox1 = this.m_checkBox1.value;
		// this.m_discounttInfo.checkBox2 = this.m_checkBox2.value;
		
		const changeValue = this.vm('discountSelect','割引種類');
		for(const value of changeValue){
			if(value.key === this.m_discountSelect.value){
				this.m_discountInfo.discountSelect = value.value;
			}
		}
		this.m_MypageWizardMngr.goNext(this);
	}
	/**
	 * <NavigateBeforeIcon />         戻る
	 */
	public onBack(param: any): void {
		this.m_MypageWizardMngr.goBack(this);
	}

	// --------------------------------------------------------------------------

}
