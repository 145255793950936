/**
 * ログレベル
 */
export enum WprLogLevel {
	/** 情報 */
	DEBUG		= 0,
	/** 情報 */
	INFO		= 1,
	/** 警告 */
	WARNING		= 2,
	/** エラー */
	ERROR		= 3,
	/** 例外 */
	EXCEPTION   = 4,
	/** ユーザ指定 */
	USER 		= 5
}

/**
 * エラー情報
 */
export class WprErrorInfo {
	// private 変数  ------------------------------------------------------------
	private m_Date: Date				= null;		// 発生時刻
	private m_Level: WprLogLevel		= null;		// レベル
	private m_ClassName: string			= null;		// クラス名
	private m_Message: string			= null;		// メッセージ
	private m_ExMessage: string			= '';		// 例外メッセージ
	private m_StackTrace: string		= '';		// スタックトレース
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** 発生時刻 */
	public get date(): Date 		{ return this.m_Date; 			}
	/** 発生時刻文字列 */
	public get dateString(): string { return WprErrorInfo.getDateString(this.m_Date); 	}
	/** レベル */
	public get level(): WprLogLevel	{ return this.m_Level; 			}
	/** クラス名 */
	public get className(): string 	{ return this.m_ClassName; 		}
	/** メッセージ */
	public get message(): string 	{ return this.m_Message; 		}
	/** 例外メッセージ */
	public get exMessage(): string 	{ return this.m_ExMessage; 		}
	/** スタックトレース */
	public get stackTrace(): string { return this.m_StackTrace; 	}
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	public constructor(level: WprLogLevel, clsName: string, msg: string, ex: Error) {
		this.m_Date = new Date();
		this.m_Level = level;
		this.m_ClassName = clsName;
		this.m_Message = msg;
		if (ex !== undefined) {
			this.m_ExMessage = ex.message;
			this.m_StackTrace = ex.stack;
		}
	}
	// --------------------------------------------------------------------------

	// public メソッド	----------------------------------------------------------
	/**
	 * ログメッセージ取得
	 * @returns ログメッセージ
	 */
	public getLogMessage(): string {
		return `${WprErrorInfo.getDateString(this.m_Date)} ${WprErrorInfo.getStrLevel(this.m_Level)} ${this.m_Message} : ${this.m_ClassName}`;
	}
	// --------------------------------------------------------------------------

	// private メソッド	----------------------------------------------------------
	/**
	 * 発生時刻文字列取得
	 * @param date 発生時刻
	 * @return 発生時刻文字列
	 */
	public static getDateString(date: Date): string {
		const year 		= ('0000' + date.getFullYear()).slice( -4 );	// yyyy
		const month 	= ('00'   + (date.getMonth() + 1)).slice( -2 );	// mm
		const day 		= ('00'   + date.getDate()).slice( -2 );		// dd
		const hour 		= ('00'   + date.getHours()).slice( -2 );		// hh
		const min 		= ('00'   + date.getMinutes()).slice( -2 );		// MM
		const second 	= ('00'   + date.getSeconds()).slice( -2 );		// ss
		const milli 	= ('000' + date.getMilliseconds()).slice( -3 );	// fff
		return `${year}/${month}/${day} ${hour}:${min}:${second}.${milli}`;
	}

	/**
	 * レベル文字取得
	 * @param level レベル
	 * @returns レベル文字
	 */
	public static getStrLevel(level: WprLogLevel): string {
		switch (level) {
			case WprLogLevel.DEBUG		: return 'D';
			case WprLogLevel.INFO		: return 'I';
			case WprLogLevel.WARNING	: return 'W';
			case WprLogLevel.ERROR		: return 'E';
			case WprLogLevel.EXCEPTION	: return 'F';
			case WprLogLevel.USER		: return 'U';
		}
		return '?';
	}
	// --------------------------------------------------------------------------
}
