import React, { ReactNode } from 'react';
import { WprBaseViewComponent } from '../../../../wpr-framework/view/component/WprBaseViewComponent';
import { AddPhoneConfirmView } from './AddPhoneConfirmView';
import { Card, Divider, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';
import { CheckBox } from '@material-ui/icons';
import { MatButton } from '../../../../wpr-framework/component/controls/material/MatButton';
import { ProgressBar } from '../../common/progress_bar/ProgressBar';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { MatTypography } from '../../../../wpr-framework/component/controls/material/MatTypography';
import { WprSpan } from '../../../../wpr-framework/component/controls/WprSpan';
import { WprContainer } from '../../../../wpr-framework/component/controls/WprContainer';
import phoneIcon from '/shared/image/common/logo_iTSCOMphone.png';
import nextIcon from '/shared/image/common/next_icon_orange.png';
import styles from './AddPhoneConfirm.scss';
import { MatDatePicker } from '../../../../wpr-framework/component/controls/material/MatDatePicker';
import { MatSelect } from '../../../../wpr-framework/component/controls/material/MatSelect';
import { MatTableContainer } from '../../../../wpr-framework/component/controls/material/MatTableContainer';
import { WprRowInfo } from '../../../../wpr-framework/component/props/WprRowInfo';
import { MatTableRow } from '../../../../wpr-framework/component/controls/material/MatTableRow';


/**
 * 追加確認用コンポーネント
 */
export class AddPhoneConfirm extends WprBaseViewComponent {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: any) { super(new AddPhoneConfirmView(), props); }
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** 追加確認ビュー情報 */
	public get viewInfo(): AddPhoneConfirmView { return this.view as AddPhoneConfirmView; }
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * スタイルオブジェクト取得
	*/
	public getStylesObject(): any { return styles; }

	/**
	 * リストコントロール定義登録
	*/
	protected onRegisterListRender(): void {
		this.addListRender('OptionConfirmList', null, this.onOptionConfirmListRender);
	}


	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		return (
			<>
				<div className='pageCard'>
					<div className='pageHeader stepFlow'>
						<ProgressBar name='progressBar' view={this.view} />
					</div>
					<div className='sub-logo'>
						<img src={phoneIcon}></img>
					</div>
					<div className='main'>
						<div className='pageTitle'>
							<div className='title_left'>
								<MatTypography name='title' className='title_text' view={this.view} />
								<Typography className='title_comment'>以下の内容でよろしければ、「申込む」ボタンをクリックしてください。<br /></Typography>
							</div>
						</div>
						<div style={{ textAlign: 'right' }}>
							<a href='http://www.itscom.net/service/ipphone/index.html' target='_blank' style={{ color: 'black', textDecoration: 'none' }}>
								<br /><b>電話サービスについて　</b>
								<img src={nextIcon} style={{ width: '20px', verticalAlign: 'bottom' }} />
							</a>
						</div>
						<WprContainer mode='お得パック.希望しない' view={this.view}>
							<div className='feeTable'>
								<Grid container className='feeRow'>
									<Grid item xs={12} md={4} className='feeTitle'>
										<div>現在の月額利用料の合計</div>
									</Grid>
									<Grid item xs={12} md={8} className='feeVal courseValue'>
										<MatTypography name={'curFee'} view={this.view} className='itemUnit'/>
										<span className='feeUnit' style={{fontWeight: '500'}}>円</span>
									</Grid>
								</Grid>
								<Grid container className='feeRow'>
									<Grid item xs={12} md={4} className='feeTitle'>
										<div>新しい月額利用料の合計</div>
									</Grid>
									<Grid item xs={12} md={8} className='feeVal courseValue'>
										<MatTypography name={'newFee'} className='newFeeUnit' view={this.view} />
										<span className='feeUnit_bold'>円</span>
									</Grid>
								</Grid>
							</div>
						</WprContainer>
						<WprContainer mode='お得パック.希望する' view={this.view}>
							<div className='feeTable'>
								<Grid container className='feeRow'>
									<Grid item xs={12} md={4} className='feeTitle'>
										<div>現在の月額利用料の合計</div>
									</Grid>
									<Grid item xs={12} md={8} className='feeVal courseValue'>
										<MatTypography name={'curFee'} view={this.view} className='itemUnit'/>
										<span className='feeUnit' style={{fontWeight: '500'}}>円</span>
									</Grid>
								</Grid>
								<Grid container className='feeRow'>
									<Grid item xs={12} md={4} className='feeTitle'>
										<div>新しい月額利用料の合計</div>
									</Grid>
									<Grid item xs={12} md={8} className='feeVal courseValue'>
										<Typography className='itemUnit'>※ ご連絡にてご案内致します。</Typography>
									</Grid>
								</Grid>
							</div>
						</WprContainer>
						<WprContainer mode='お得パック.加入者' view={this.view}>
							<div className='feeTable'>
								<Grid container className='feeRow'>
									<Grid item xs={12} md={4} className='feeTitle'>
										<div>現在の月額利用料の合計</div>
									</Grid>
									<Grid item xs={12} md={8} className='currentFeeArea courseValue'>
										<Grid className='feeVal courseValue'>
											<MatTypography name={'curFee'} view={this.view} className='itemUnit'/>
											<span className='feeUnit' style={{fontWeight: '500'}}>円</span>
										</Grid>
										<span className='otokuName'>
											<MatTypography name = {'otokuPlan'} view={this.view}/>
										</span>				
									</Grid>
								</Grid>
								<Grid container className='feeRow'>
									<Grid item xs={12} md={4} className='feeTitle'>
										<div>新しい月額利用料の合計</div>
									</Grid>
									<Grid item xs={12} md={8} className='feeVal courseValue'>
										<Typography className='itemUnit'>※ ご連絡にてご案内致します。</Typography>
									</Grid>
								</Grid>
							</div>
						</WprContainer>
						<div className='title_maru'>追加申込み内容</div>
						<div className='feeTable'>
							<Grid container className='feeRow'>
								<Grid item xs={12} md={4} className='feeTitle'>
									<div>申込みコースの月額</div>
								</Grid>
								<Grid item xs={12} md={8} className='feeVal courseValue'>
									<MatTypography name={'total'} className='itemUnit' view={this.view} />
									<span className='feeUnit' style={{fontWeight: '500'}}>円</span>
								</Grid>
							</Grid>
							<Grid container className='feeRow'>
								<Grid item xs={12} md={4} className='feeTitle'>
									<div>申込みコース</div>
								</Grid>
								<Grid item xs={12} md={8} className='feeVal courseValue'>
									<WprSpan name={'course'} view={this.view} className='itemUnit' style={{verticalAlign: 'bottom' }}></WprSpan>
								</Grid>
							</Grid>
							<Grid container className='feeRow'>
								<Grid item xs={12} md={4} className='feeTitle'>
									<div>機器のご提供方法</div>
								</Grid>
								<Grid item xs={12} md={8} className='feeVal courseValue'>
									<WprSpan name={'equipmentProviding'} view={this.view} className='itemUnit'></WprSpan>
								</Grid>
							</Grid>
							<Grid container className='feeRow'>
								<Grid item xs={12} md={4} className='feeTitle'>
									<div>希望場所</div>
								</Grid>
								<Grid item xs={12} md={8} className='feeVal courseValue'>
									<div className='flex'>
										<WprContainer mode='階数.表示' view={this.view}>
											<MatTypography name='floor' className='itemUnit' view={this.view}/>
										</WprContainer>
										<MatTypography name='location' className='itemUnit' view={this.view}/>
									</div>
									<div>
										<MatTypography name='comment' className='itemUnit' view={this.view}/>
									</div>
								</Grid>
							</Grid>
						</div>
						<div className='title_maru'>オプション</div>
						<Card className='optionList'>
							<TableContainer>
								<Table className = 'top'>
									<colgroup>
										<col style={{width:'42%'}}/>
										<col style={{width:'58%'}}/>
									</colgroup>
									<TableBody className='radius'>
										<TableRow className='hedderCell'>
											<TableCell align='center' className='' rowSpan={this.state.data.sentenceData} style={{borderBottom: 'none'}}>
												<Typography variant='body2' className='font_bold' style={{fontSize:'15px'}}>オプション名</Typography>
											</TableCell>
										</TableRow>
										{this.renderList('OptionConfirmList')}
									</TableBody>
								</Table>
							</TableContainer>
						</Card>
						<div>
							<ul className='ul_kome'>
								<li style={{fontSize: '12px'}}>
									表示されている月額は定価となります。実際の請求金額とは異なる場合があります。
								</li>
								<li style={{fontSize: '12px'}}>
									合計月額には割引も適用されています。
								</li>
							</ul>
						</div>
						<div className='itemTitle title_maru'>作業の希望日時</div>
						<div className='feeTable'>
							<Grid container className='feeRow'>
								<Grid item xs={12} md={4} className='feeTitle'>
									<div>第一希望</div>
								</Grid>
								<Grid item xs={12} md={8} className='feeVal courseValue value'>
									<MatTypography name='desiredDate1' view={this.view} className='itemUnit' style={{marginRight: '20px'}}/>
									<MatSelect name='selectTime1' vm='時間帯' view={this.view} className='itemSelect'/>
								</Grid>
							</Grid>	
							<Grid container className='feeRow'>
								<Grid item xs={12} md={4} className='feeTitle'>
									<div>第二希望</div>
								</Grid>
								<Grid item xs={12} md={8} className='feeVal courseValue value'>
									<MatTypography name='desiredDate2' view={this.view} className='itemUnit' style={{marginRight: '20px'}}/>
									<MatSelect name='selectTime2' vm='時間帯' view={this.view} className='itemSelect'/>
								</Grid>
							</Grid>	
							<Grid container className='feeRow'>
								<Grid item xs={12} md={4} className='feeTitle'>
									<div>第三希望</div>
								</Grid>
								<Grid item xs={12} md={8} className='feeVal courseValue value'>
									<MatTypography name='desiredDate3' view={this.view} className='itemUnit' style={{marginRight: '20px'}}/>
									<MatSelect name='selectTime3' vm='時間帯' view={this.view} className='itemSelect'/>
								</Grid>
							</Grid>	
						</div>
						<div className='itemTitle title_maru'>工事費</div>
						<div className='feeTable'>	
							<Grid container className='feeRow'>
								<Grid item xs={12} md={4} className='feeTitle'>
									<div>工事費</div>
								</Grid>
								<Grid item xs={12} md={8} className='feeVal courseValue'>
									<div className='flex'>
										<WprSpan name='cost' view={this.view} className='itemUnit'/>
										<MatTypography name='payUnit' className='itemUnit' view={this.view}/>
									</div>
								</Grid>
							</Grid>
						</div>
						<div className='feeTable' style={{marginTop: '30px'}}>	
							<Grid container className='feeRow'>
								<Grid item xs={12} md={4} className='feeTitle'>
									<div>前日連絡先</div>
								</Grid>
								<Grid item xs={12} md={8} className='feeVal courseValue'>
									<MatTypography name='beforeContact' className='itemUnit' view={this.view} />
								</Grid>
							</Grid>	
							<Grid container className='feeRow'>
								<Grid item xs={12} md={4} className='feeTitle'>
									<div>当日連絡先</div>
								</Grid>
								<Grid item xs={12} md={8} className='feeVal courseValue'>
									<MatTypography name='sameDayContact' className='itemUnit' view={this.view} />
								</Grid>
							</Grid>
							<Grid container className='feeRow'>
								<Grid item xs={12} md={4} className='feeTitle'>
									<div>当日立ち合い者</div>
								</Grid>
								<Grid item xs={12} md={8} className='feeVal courseValue'>
									<MatTypography name='name' className='itemUnit' view={this.view} />
								</Grid>
							</Grid>
							<Grid container className='feeRow'>
								<Grid item xs={12} md={4} className='feeTitle'>
									<div>リフォーム</div>
								</Grid>
								<Grid item xs={12} md={8} className='feeVal courseValue'>
									<MatTypography name='reformDisp' view={this.view} className='itemUnit'/>
								</Grid>
							</Grid>
							<Grid container className='feeRow'>
								<Grid item xs={12} md={4} className='feeTitle'>
									<div>セキュリティ会社</div>
								</Grid>
								<Grid item xs={12} md={8} className='feeVal courseValue'>
									<MatTypography name='securityDisp' view={this.view} className='itemUnit'/>
								</Grid>
							</Grid>
						</div>
					</div>
					<Divider />
					<div className='pageFooter footer-space'>
						<div>
							<MatButton name='next' view={this.view} className='orangeButton orangeButton_parts' >
								この内容で申込む
								<NavigateNextIcon />
							</MatButton>
						</div>
						<div>
							<MatButton name='back' view={this.view} className='backButton' >
								<NavigateBeforeIcon />
								戻る
							</MatButton>
						</div>
					</div>
				</div>
			</>
		);
	}
	// --------------------------------------------------------------------------

	// リスト描画  ---------------------------------------------------------------
	/**
	 * リスト名=OptionConfirmList
	 */
	public onOptionConfirmListRender(rowInfo: WprRowInfo): any {
		return (
			<MatTableRow view={this.view} row={rowInfo}>
				<TableCell className='bodyCell'>
					<MatTypography name='optionName' view={this.view} row={rowInfo} style={{fontSize: '13px', fontWeight: '500', color: '#333333'}}/>
				</TableCell>
			</MatTableRow>
		);
	}
	// --------------------------------------------------------------------------
}
