import { WprModel } from '../../../../wpr-framework/view/deco/WprModel';
import { WprDI_ScopeModel } from '../../../../wpr-framework/view/di/WprDI_ScopeModel';
import { CryptRijndaelUtil } from '../../../common/util/CryptRijndaelUtil';
import { MCommon } from '../../../service/models/entity/primary/MCommon';
import { MypageBaseView } from '../../base/MypageBaseView';
import { MypageWizardMngr } from '../../wizard/models/MypageWizardMngr';
import { ReiAccessAccountPwModel } from '../rei_accesspass_complete/models/ReiAccessAccountPwModel';
import { ReiAccessAccountModel } from '../rei_accesspass_confirm/models/ReiAccessAccountModel';

/**
 * アクセスアカウントパスワード発行印刷
 */
export class ReiAccesspassPrintView extends MypageBaseView {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() { super('ReiAccesspassPrintView'); }
	// --------------------------------------------------------------------------

	// モデル  ------------------------------------------------------------------
	@WprModel('ReiAccessAccountModel')
	private m_ReiAccessAccountModel: ReiAccessAccountModel = null;		// アクセスアカウントパスワード発行モデル
	@WprDI_ScopeModel('MypageWizardMngr')
	@WprModel('MypageWizardMngr')
	private m_MypageWizardMngr: MypageWizardMngr = null;		// 画面ウィザード管理クラス
	@WprModel('ReiAccessAccountPwModel')
	private m_ReiAccessAccountPwModel: ReiAccessAccountPwModel = new ReiAccessAccountPwModel();		// アクセスアカウント複合化パスワードモデル
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * コントロール定義設定
	 */
	public onRegisterConfig(): void {
		this.addConfig('course', 'コース', 'ReiAccessAccountModel');
		this.addConfig('accessAccount', 'アクセスアカウント', 'ReiAccessAccountModel');
		this.addConfig('password', 'パスワード', 'ReiAccessAccountPwModel');
		this.addConfig('passwordKana', 'パスワード読み仮名', 'ReiAccessAccountModel');
		this.addConfig('date', '変更日', 'ReiAccessAccountModel', { converter: '日付（yyyy年MM月dd日）' });
	}

	/**
	 * アクション登録
	 */
	public onRegisterAction(): void {
		this.addAction('print', this.onPrint);
		this.addAction('close', this.onClose);
	}

	/**
	 * ビュー表示通知
	 */
	public onShowView(): void {
		this.m_MypageWizardMngr.initView(this, (result) => {
			this.registAccessLog();
			this.setStorageItem('b_url', window.location.href);
			this.m_ReiAccessAccountModel = this.m_MypageWizardMngr.getCache().m_ReiAccessAccountModel;
			// 複合化
			this.getDecodePassword();
			this.setViewData('sentenceData', this.m_ReiAccessAccountPwModel.passwordKana)
			this.refreshView();
		});
	}
	// --------------------------------------------------------------------------

	// アクション  ---------------------------------------------------------------
	/**
	 * 印刷
	 */
	public onPrint(param: any): void {
		window.print();
	}

	/**
	 * 閉じる
	 */
	public onClose(param: any): void {
		window.close();
	}
	// --------------------------------------------------------------------------
	// privateメソッド  ----------------------------------------------------------

	private getDecodePassword(): void {
		const mCommonList: MCommon[] = this.m_MypageWizardMngr.getMCommonList(this, 'cryptkey', null, null, null);
		const decrypted: string = mCommonList[0].commonValue;
		this.m_ReiAccessAccountPwModel.password = CryptRijndaelUtil.decodePW(decrypted, this.m_ReiAccessAccountModel.password);
		this.m_ReiAccessAccountPwModel.passwordKana = CryptRijndaelUtil.decodePW(decrypted, this.m_ReiAccessAccountModel.passwordKana);
	}
	// --------------------------------------------------------------------------
}
