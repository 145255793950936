import { Tab, Tabs } from '@material-ui/core';
import React, { ReactNode } from 'react';
import { IWprTabMode } from '../../../mode/tab/IWprTabMode';
import { WprControlVisibility } from '../../props/IWprControlState';
import { IWprNoNameLayoutProps } from '../../props/IWprLayoutProps';
import { WprBaseNoNameLayoutComponent } from '../WprBaseNoNameLayoutComponent';

/**
 * タブプロパティ情報
 */
interface IMatTabsProps extends IWprNoNameLayoutProps {
	mode: string;											// モード
	indicatorColor?: 'secondary' | 'primary';				// 色
	textColor?: 'secondary' | 'primary' | 'inherit';		// 文字色
	variant?: 'standard' | 'scrollable' | 'fullWidth';		// 形状
	scrollButtons?: 'auto' | 'desktop' | 'on' | 'off';		// スクロール位置
	orientation?: 'horizontal' | 'vertical';				// 配置
	maxDispTab?:number;										// 表示タブ最大数
	maxMobileDispTab?: number;								// モバイル表示タブ最大数
}

/**
 * タブ
 */
export class MatTabs extends WprBaseNoNameLayoutComponent<IMatTabsProps, JSX.Element> implements IWprTabMode {
	// private 変数  ------------------------------------------------------------
	private m_TabMap: Map<number, string>	= new Map<number, string>();	// タブマップ
	private m_ChildList: any[] 				= null;							// 子要素リスト（リストによる更新用）
	private m_IsUpdate: boolean				= false;						// 更新チェックフラグ
	private m_ResizeEventMethod: any 		= null;							// リサイズイベントメソッド
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	constructor(props: IMatTabsProps) {
		super(props);
		this.setChangeValueEvent(this.onChangeValue.bind(this));
		if (this.props.view.isMobile() === true) {
			this.setState({
				boolOption1: true
			});
		}
	}
	// --------------------------------------------------------------------------

	// イベント  -----------------------------------------------------------------
	/**
	 * 値変更処理
	 * @param event 変更イベント 
	 */
	 public onChangeValue(event: React.ChangeEvent<HTMLInputElement>, newValue: number): void {
		this.addClickEventLog(event.target);
		this.props.view.setTabMode(this.props.mode, this.m_TabMap.get(newValue));
	}

	/**
	 * リサイズ処理
	 */
	private onResize(): void {
		let isMobile = this.props.view.isMobile();
		if (this.state.boolOption1 !== isMobile) {
			this.setState({
				boolOption1: isMobile
			});
		}
	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * マウント通知
	 */
	public onDidMount() {
		this.setTabModeComponent();
		this.m_ChildList = this.getChildTabList();
		if (this.props.maxDispTab || this.props.maxMobileDispTab) {
			this.m_ResizeEventMethod = this.onResize.bind(this);
			window.addEventListener('resize', this.m_ResizeEventMethod, true);
		}
	}

	/**
	 * アンマウント通知
	 */
	public onWillUnmount() {
		this.props.view.deleteTabModeComponent(this, this.props.mode, null);
		if (this.m_ResizeEventMethod != null) {
			window.removeEventListener('resize', this.m_ResizeEventMethod, true);
			this.m_ResizeEventMethod = null;
		}
	}

	 /**
	 * 描画
	 */
	public onRender(): ReactNode {
		if (this.isMount == false)
			return <></>;
		const props = this.getProps();
		const { view, className, maxDispTab, maxMobileDispTab, variant, scrollButtons, children, ...other} = this.props;
		const events = this.getEvents();
		const options = this.getOptions();
		let tabs = null;
		const state = this.getState(['style', 'value']);
		if (this.state.visibility === WprControlVisibility.VISIBLE) {
			tabs = <Tabs {...props} {...state} {...other} {...options} {...events} ref={this.ref}>{this.props.children}</Tabs>;
		}
		else {
			tabs = <Tabs hidden {...props} {...state} {...other} {...options} ref={this.ref}>{this.props.children}</Tabs>;
		}
		this.props.view.renderTabsComponent(this.props.mode);
		setTimeout(() => {
			this.checkChildList();
		}, 10);
		return (
			<>
				{tabs}
			</>
		);
	}

	/**
	 * 要素固有XPath情報取得
	 * @param element 要素
	 * @returns 要素固有XPath情報
	 */
	protected getElementXPathInfo(element: HTMLElement): string {
		const button = element.parentElement;
		const modeDiv = this.getParentElement(element, 'DIV', 'mode', this.props.mode);
		const idx = this.getChildIndex(button);
		return `//${modeDiv.tagName}[@mode='${this.props.mode}']//${button.tagName}[${idx}]`;
	}
	// --------------------------------------------------------------------------

	// private メソッド  ---------------------------------------------------------
	/**
	 * オプション情報取得
	 * @returns オプション情報
	 */
	private getOptions(): any {
		const rtn: any = {};
		if (this.props.variant)
			rtn['variant'] = this.props.variant;
		else if (this.props.maxDispTab || this.props.maxMobileDispTab)
			rtn['variant'] = 'scrollable';
		if (this.props.scrollButtons)
			rtn['scrollButtons'] = this.props.scrollButtons;
		else if (this.props.maxDispTab || this.props.maxMobileDispTab)
			rtn['scrollButtons'] = 'auto';
		return rtn;
	}

	/**
	 * タブモードコンポーネント設定処理
	 * @param isUpdate 更新フラグ
	 */
	private setTabModeComponent(isUpdate: boolean = false): void {
		let idx = 0;
		const list = new Array();
		const map = new Map<string, string>();
		React.Children.forEach(this.props.children, (child) => {
			const tab = child as any;
			if (tab) {
				let label = tab.props.label as string;
				const labelMode = tab.props.labelMode as string;
				if (labelMode)
					label = labelMode;
				if (label) {
					this.m_TabMap.set(idx, label);
					list.push(label);
					idx++;
					const mode = tab.props.mode as string;
					const vinfo = tab.props.view;
					if (mode && vinfo)
						map.set(label, mode);
				}
			}
		});
		this.props.view.setTabModeComponent(this, this.props.mode, null, list, map, isUpdate);
	}

	/**
	 * タブ幅設定
	 */
	private setTabWidth() {
		React.Children.forEach(this.props.children, (child) => {
			const tab = child as any;
			if (tab) {
				let label = tab.props.label as string;
				const labelMode = tab.props.labelMode as string;
				if (labelMode)
					label = labelMode;
				let cnt = 0;
				if (this.props.maxDispTab)
					cnt = this.props.maxDispTab;
				if (this.props.view.isMobile() === true) {		// モバイル
					if (this.props.maxMobileDispTab)
						cnt = this.props.maxMobileDispTab;
				}
				if (cnt) {
					this.props.view.setTabWidth(label, cnt);
					this.setState({
						numOption1: cnt
					});
				}
			}
		});
	}

	/**
	 * タブ子要素リスト取得
	 * @returns タブ子要素リスト
	 */
	private getChildTabList(): any[] {
		const list = new Array();
		React.Children.forEach(this.props.children, (child) => {
			const tab = child as any;
			if (tab) {
				list.push(tab);
			}
		});
		return list;
	}

	/**
	 * 子要素リストチェック
	 */
	private checkChildList(): void {
		if (this.m_ChildList.length === 0 || this.m_IsUpdate === true) {
			this.m_IsUpdate = true;
			const list = this.getChildTabList();
			let update = true;
			if (list.length === this.m_ChildList.length) {
				update = false;
				for (let i = 0; i < list.length; i++) {
					if (list[i] !== this.m_ChildList[i]) {
						update = true;
						break;
					}
				}
			}
			if (update === true) {
				this.m_ChildList = list;
				this.setTabModeComponent(true);
			}
		}
		this.setTabWidth();
	}
	// --------------------------------------------------------------------------

	// IWprTabModeの実装  --------------------------------------------------------
	/**
	 * タブモード設定処理
	 * @param mode モード
	 */
	 public setTabMode(mode: string): void {
		const index = this.props.view.getTabIndex(this.props.mode, mode);
		this.setState({
			value : index
		});
	}
	// --------------------------------------------------------------------------
}
