import { CustomerInfo } from '../../../models/CustomerInfo';

/**
 * MVNO容量変更情報
 */
export class MvnoMnpInfo {
	// public 変数	-----------------------------------------------------------
	public iccId: string;				// ICCID
	public phoneNumber: string;			// 回線番号
	public customerInfo: CustomerInfo;	// 
	// ------------------------------------------------------------------------
}
