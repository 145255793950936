import { WprLogLevel } from './error/WprErrorInfo';

/**
 * フレームワーク設定情報
 */
export class WprSettingInfo {
	// public 変数	-----------------------------------------------------------
	private m_LogLevel: WprLogLevel			= WprLogLevel.WARNING;	// 出力ログレベル
	private m_OutputEventLog: boolean		= false;				// イベントログ出力フラグ
	private m_UseWaitComponent: boolean		= true;					// 待機表示コンポーネント使用フラグ
	private m_UseMessageComponent: boolean 	= true;					// メッセージコンポーネント使用フラグ
	private m_IsRadioClear: boolean			= false;				// ラジオ選択クリアフラグ（選択済みのラジオボタンをクリックし場合に選択をクリアする）
	// private m_DefaultDialogConfig: MatDialogConfig	= undefined;				// ダイアログ定義情報
	// ------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** 出力ログレベル */
	public get logLevel(): WprLogLevel 			{ return this.m_LogLevel; 				}	public set logLevel(level: WprLogLevel) 			{ this.m_LogLevel = level;					}
	/** イベントログ出力フラグ */
	public get outputEventLog(): boolean 		{ return this.m_OutputEventLog; 		}	public set outputEventLog(outputEventLog: boolean) 	{ this.m_OutputEventLog = outputEventLog;	}
	/** 待機表示コンポーネント使用フラグ */
	public get useWaitComponent(): boolean 		{ return this.m_UseWaitComponent; 		}	public set useWaitComponent(useWait: boolean) 		{ this.m_UseWaitComponent = useWait;		}
	/** メッセージコンポーネント使用フラグ */
	public get useMessageComponent(): boolean 	{ return this.m_UseMessageComponent; 	}	public set useMessageComponent(useMsg: boolean) 	{ this.m_UseMessageComponent = useMsg;		}
	/** ラジオ選択クリアフラグ（選択済みのラジオボタンをクリックし場合に選択をクリアする） */
	public get isRadioClear(): boolean 			{ return this.m_IsRadioClear; 			}	public set isRadioClear(isRadioClear: boolean) 		{ this.m_IsRadioClear = isRadioClear;		}
	/** ダイアログ定義情報 */
	// public get defaultDialogConfig(): MatDialogConfig	{ return this.m_DefaultDialogConfig; 	}	public set defaultDialogConfig(config: MatDialogConfig) { this.m_DefaultDialogConfig = config;	}
	// --------------------------------------------------------------------------
}