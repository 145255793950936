import React, { ReactNode } from 'react';
import { WprBaseViewComponent } from '../../../../../wpr-framework/view/component/WprBaseViewComponent';
import { TvCourseListPartsView } from './TvCourseListPartsView';
import { Grid, TableContainer, TableCell, Typography, Card, Table, TableHead, TableRow, TableBody } from '@material-ui/core';
import { MatAutoComplete } from '../../../../../wpr-framework/component/controls/material/MatAutoComplete';
import { MatButton } from '../../../../../wpr-framework/component/controls/material/MatButton';
import { MatOneRowTable } from '../../../../../wpr-framework/component/controls/material/MatOneRowTable';
import { MatTablePagination } from '../../../../../wpr-framework/component/controls/material/MatTablePagination';
import { MatTextField } from '../../../../../wpr-framework/component/controls/material/MatTextField';
import { MatTypography } from '../../../../../wpr-framework/component/controls/material/MatTypography';
import { WprRowInfo } from '../../../../../wpr-framework/component/props/WprRowInfo';
import { MatTableRow } from '../../../../../wpr-framework/component/controls/material/MatTableRow';
import { MatCheckBox } from '../../../../../wpr-framework/component/controls/material/MatCheckBox';
import { WprContainer } from '../../../../../wpr-framework/component/controls/WprContainer';

/**
 * テレビ_コースパーツ用コンポーネント
 */
export class TvCourseListParts extends WprBaseViewComponent {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: any) { super(new TvCourseListPartsView(), props); }
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** テレビ_コースパーツビュー情報 */
	public get viewInfo(): TvCourseListPartsView	{ return this.view as TvCourseListPartsView;	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------

	/**
	 * リストコントロール定義登録
	*/
	protected onRegisterListRender(): void {
		this.addListRender('TvCourseListModel', null, this.onTvCourseListModelRender);
	}

	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		return (
			<>
			<Card className='blueCard'>
				<TableContainer>
					<WprContainer mode='オプション.PC' view={this.view}>
						<Table className = 'pc'>
							<colgroup>
								<col style={{width:'25%'}}/>
								<col style={{width:'25%'}}/>
								<col style={{width:'25%'}}/>
								<col style={{width:'25%'}}/>
							</colgroup>
							<TableBody>
								<TableRow className='hedderrow'>
									<TableCell align='center' rowSpan={this.state.data.sentenceData} className='headerLeft'>
										<MatTypography name='course' view={this.view} className='font_bold' style={{ color: '#FFFFFF'}}></MatTypography>
									</TableCell>
									<TableCell align='center' className='border_right'>
										<Typography variant='body2' className='font_bold'>選択</Typography>
									</TableCell>
									<TableCell align='center' className='border_right'>
										<Typography variant='body2' className='font_bold'>機器の種類</Typography>
									</TableCell>
									<TableCell align='center'>
										<Typography variant='body2' className='font_bold'>月額料金</Typography>
									</TableCell>
								</TableRow>
								{this.renderList('TvCourseListModel')}
							</TableBody>
						</Table>
					</WprContainer>
					{/* SP用 */}
					<WprContainer mode='オプション.SP' view={this.view}>
						<Table className = 'sp'> 
							<colgroup>
								<col style={{width:'33%'}}/>
								<col style={{width:'34%'}}/>
								<col style={{width:'33%'}}/>
							</colgroup>
							<TableHead>
								<TableRow className='headerTop'>
									<TableCell colSpan={3} align='center' className='border_right font_bold'>
										<MatTypography name='course' view={this.view} className='font_bold' style={{ color: '#FFFFFF'}}></MatTypography>
									</TableCell>
								</TableRow>
								<TableRow className='hedderrow'>
									<TableCell align='center' className='font_bold border_right'>選択</TableCell>
									<TableCell align='center' className='font_bold border_right'>機器の種類</TableCell>
									<TableCell align='center' className='font_bold'>月額料金</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{this.renderList('TvCourseListModel')}
							</TableBody>
						</Table>
					</WprContainer>
				</TableContainer>
			</Card>
			</>
		);
	}
	// --------------------------------------------------------------------------

	// リスト描画  ---------------------------------------------------------------
	/**
	 * リスト名=TvCourseListModel
	 */
	public onTvCourseListModelRender(rowInfo: WprRowInfo): any {
		return (
			<MatTableRow view={this.view} row={rowInfo}>
				<TableCell align='center' className='border_right' style={{padding: '5px'}}>
					<MatCheckBox name='select' color='primary' view={this.view} row={rowInfo}/>
				</TableCell>
				<TableCell align='center' className='border_right' style={{padding: '5px'}}>
					<MatTypography name='terminal' view={this.view} row={rowInfo} style={{ fontSize: '15px'}}/>
				</TableCell>
				<TableCell align='center' style={{padding: '5px'}}>
					<MatTypography name='amount' view={this.view} row={rowInfo} className='font_bold'/>
					<span className='feeUnit'>円</span>
				</TableCell>
			</MatTableRow>
		);
	}
	// --------------------------------------------------------------------------
}
