import { MypageBaseView } from '../base/MypageBaseView';
import { WprModel } from '../../../wpr-framework/view/deco/WprModel';
import { AmbassadorService } from '../../service/AmbassadorService';
import { GetReferralUrlRequest } from '../../service/models/dto/ambassador/GetReferralUrlRequest';
import { UrlInfo } from '../../models/UrlInfo';
import { ConvertUtil } from '../../common/util/ConvertUtil';
import { GetCertificationRequest } from '../../service/models/dto/auth/GetCertificationRequest';
import { ObjectUtil } from '../../common/util/ObjectUtil';
import { AuthService } from '../../service/AuthService';
import { CertificationInfo } from '../../service/models/apimodels/CertificationInfo';
import { GetAmbassadorCampaignRequest } from '../../service/models/dto/ambassador/GetAmbassadorCampaignRequest';
import { MGeneral } from '../../service/models/entity/MGeneral';

/**
 * アンバサダー制度
 */
export class AmbassadorView extends MypageBaseView {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() { super('Ambassador'); }
	// --------------------------------------------------------------------------
	
	// モデル  ------------------------------------------------------------------
	@WprModel('urlInfo')
	private m_UrlInfo: UrlInfo	= new UrlInfo();	// 紹介URL情報
	@WprModel('IntroducerCampaign')
	public m_introducerCampaign: MGeneral = null;	// 
	@WprModel('ApplicantCampaign')
	public m_applicantCampaign: MGeneral = null;	// 
	// --------------------------------------------------------------------------

	// private変数  -----------------------------------------------------------
	public m_customerId: string	= null;	// 顧客番号
	// --------------------------------------------------------------------------

	// サービス  ----------------------------------------------------------------
	private m_AuthService: AuthService	= new AuthService();	// 認証サービス
	private m_AmbassadorService: AmbassadorService	= new AmbassadorService();	// MyPageRenewalサービス
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * コントロール定義設定
	 */
	public onRegisterConfig(): void {
		this.addConfig('createUrlBtn', 'URL発行ボタン', null);
		this.addConfig('shareUrlBtn', 'URLを共有する', null);
		this.addConfig('url', '紹介URL', 'urlInfo');
		this.addConfig('validDate', 'URL期限', 'urlInfo');
		this.addConfig('introducerCampaignOverview', '紹介者用キャンペーン概要', 'IntroducerCampaign', { bindName: 'name1'});
		this.addConfig('applicantCampaignOverview', '被紹介者用キャンペーン概要', 'ApplicantCampaign', { bindName: 'name1'});
		this.addConfig('introducerCampaignExplain', '紹介者用キャンペーン説明', 'IntroducerCampaign', { bindName: 'name2'});
		this.addConfig('applicantCampaignExplain', '被紹介者用キャンペーン説明', 'ApplicantCampaign', { bindName: 'name2'});
		this.addConfig('campaignPeriod', '紹介者用キャンペーン概要', 'IntroducerCampaign', { bindName: 'name3'});
	}

	/**
	 * アクション登録
	 */
	public onRegisterAction(): void {
		this.addAction('createUrlBtn', this.oncreateUrlBtn, true);
		this.addAction('copyIcon', this.onUrlCopy);
		this.addAction('webShareIcon', this.onWebShare);
	}

	/**
	 * ビュー表示通知
	 */
	public onShowView(): void {
		this.registAccessLog();
		this.setStorageItem('b_url', window.location.href);
		// /ambassador?SESSIONID=e77a04cc6971264c5225470d82112fc9&AUTHTOKEN=c59c0145be31da9583537c1e40f945b4
		// /ambassador?SESSIONID=8db73b7fce2762b457c320a9b15f9a5b&AUTHTOKEN=4d63ac8a12b44f8fcf8f4d0b0c3b7f84
		// 顧客番号取得
		// エラー画面/s005に遷移していいか確認する
		// const req: GetCertificationRequest = new GetCertificationRequest();
		// const sessionId = this.getUrlParam('SESSIONID');
		// const sessionHashCd = this.getUrlParam('AUTHTOKEN');
		// if (ObjectUtil.isNotNullOrEmptyStr(sessionId) && ObjectUtil.isNotNullOrEmptyStr(sessionHashCd)) {
		// 	const reqCertification: CertificationInfo = new CertificationInfo();
		// 	reqCertification.authToken = sessionHashCd;
		// 	reqCertification.sessionId = sessionId;
		// 	req.certificationInfo = reqCertification;
		// 	this.m_AuthService.getCertification(this, req, (result) => {
		// 		// セッションエラーの場合はエラーページに遷移
		// 		if (result.certificationInfo.sessionFlg == false) {
		// 			this.pushPath('/s005');
		// 		} else {
		// 			this.setCookie('customerID',result.certificationInfo.customerId,'/',3600);
		// 			this.m_customerId = result.certificationInfo.customerId;
		// 		}
		// 	});
		// }
		// セッションエラーの場合はエラーページに遷移
		// else {
		// 	this.pushPath('/s005');
		// }
		this.m_customerId = this.getCustomerId();
		const req: GetAmbassadorCampaignRequest = new GetAmbassadorCampaignRequest();
			req.customerId = this.m_customerId;
			this.m_AmbassadorService.getAmbassadorCampaign(this, req, (result) => {
				this.m_introducerCampaign = result.introducerCampaign;
				this.m_applicantCampaign = result.applicantCampaign;
				this.setViewMode('URL発行', '未');
				this.refreshView();
			});
	}
	// --------------------------------------------------------------------------
	
	// アクション  ---------------------------------------------------------------
	/**
	 * URLを発行する
	 */
	public oncreateUrlBtn(param: any): void {
		const req: GetReferralUrlRequest = new GetReferralUrlRequest();
		req.customerId = this.m_customerId;
		this.m_AmbassadorService.getReferralUrl(this, req, (result) => {
			if (result) {
				this.m_UrlInfo = result.urlInfo;
				this.m_UrlInfo.validDate = ConvertUtil.convertDayHourLabel(this.m_UrlInfo.validDate);	// yyyy年MM月dd日HH時
				this.setViewMode('URL発行', '済');
				this.refreshView();
			}
		});
	}
	
	/**
	 * URLをコピーする
	 */
	public onUrlCopy(param: any): void {
		// navigator.clipboard.writeText(this.getShareText() + '\n' + this.getInfoUrl()).then( (success) => console.log('テキストのコピーに成功'), (error) => console.log('テキストのコピーに失敗') );
		navigator.clipboard.writeText(this.m_UrlInfo.url).then( (success) => console.log('テキストのコピーに成功'), (error) => console.log('テキストのコピーに失敗') );
		this.showInfoMessage('コピーしました');
	}

	/**
	 * WebShare機能
	 */
	 public onWebShare(param: any): void {
		navigator.share({text:this.getShareText() + '\n' + this.getInfoUrl()}).then( (success) => console.log('WebShareダイアログ表示に成功'), (error) => console.log('WebShareダイアログ表示に失敗') );
	}

	/**
	 * お友達紹介特典の詳細リンク先
	 */
	public getInfoUrl(): string {
		return 'https://www.itscom.co.jp/lp/ambasader.html';
	}
	/**
	 * SNSシェア内容
	 */
	public getShareText(): string {
		return '紹介専用お申込みページ' + '\n' + this.m_UrlInfo.url + '\n'+ '\n'+ 'お友達紹介特典の詳細はこちら';
	}
}
