import { CertificationInfo } from '../../apimodels/CertificationInfo';

/**
 * セッション情報削除リクエスト
 */
export class DeleteSessionInfoRequest {
	// public 変数	-----------------------------------------------------------
	public certificationInfo: CertificationInfo;	// 認証情報
	// ------------------------------------------------------------------------
}
