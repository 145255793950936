import React, { ReactNode } from 'react';
import { WprBaseViewComponent } from '../../../../../wpr-framework/view/component/WprBaseViewComponent';
import { ItscomHomeComfirmView } from './ItscomHomeComfirmView';
import { Grid, Typography, TableCell, Table, TableBody, TableHead, TableRow } from '@material-ui/core';
import { ProgressBar } from '../../../common/progress_bar/ProgressBar';
import { WprRowInfo } from '../../../../../wpr-framework/component/props/WprRowInfo';
import { MatTableRow } from '../../../../../wpr-framework/component/controls/material/MatTableRow';
import { MatTypography } from '../../../../../wpr-framework/component/controls/material/MatTypography';
import { MatTableContainer } from '../../../../../wpr-framework/component/controls/material/MatTableContainer';
import { MatButton } from '../../../../../wpr-framework/component/controls/material/MatButton';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import Divider from '@mui/material/Divider/Divider';
import { WprContainer } from '../../../../../wpr-framework/component/controls/WprContainer';
import { WprDiv } from '../../../../../wpr-framework/component/controls/WprDiv';

/**
 * ITSCOMHOME_追加申込み(申し込み内容の確認)用コンポーネント
 */
export class ItscomHomeComfirm extends WprBaseViewComponent {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: any) { super(new ItscomHomeComfirmView(), props); }
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** ITSCOMHOME_追加申込み(申し込み内容の確認)ビュー情報 */
	public get viewInfo(): ItscomHomeComfirmView	{ return this.view as ItscomHomeComfirmView;	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * リストコントロール定義登録
	*/
	protected onRegisterListRender(): void {
		this.addListRender('OptionComfirmListModel', null, this.onOptionComfirmListModelRender);
	}

	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		return (
			<>
			<div className='pageCard'>
				<div className="pageHeader stepFlow">
					<ProgressBar name='progressBar' view={this.view} />
				</div>
				<div className='main'>
					<div className='pageTitle'>
						<div className='title_left'>
							<MatTypography name='title' className='title_text' view={this.view}/>
							<Typography className='title_sentence'>以下の内容でよろしければ、「申込み」ボタンをクリックしてください。</Typography>
						</div>
					</div>
					<div className='feeTable' style={{marginTop:'40px'}}>
						<Grid container className='feeRow'>
							<Grid item xs={12} md={4} className='feeTitle'>
								<div>現在の月額利用料の合計</div>
							</Grid>
							<Grid item xs={12} md={8} className='currentFeeArea courseValue'>
								<Grid item xs={12} md={8} className='feeVal courseValue'>
									<MatTypography name='beforeFee' className='itemUnit unit' view={this.view}/>
									<span className='feeUnit' style={{fontWeight: '500'}}>円</span>
								</Grid>
								<WprContainer mode='お得パック.加入済み' view={this.view}>
									<span className='otokuName'>
										<MatTypography name='otokuName' view={this.view} style={{color: '#333333'}}/>
									</span>
								</WprContainer>
							</Grid>
						</Grid>	
						<Grid container className='feeRow'>
							<Grid item xs={12} md={4} className='feeTitle'>
								<div>新しい月額利用料の合計</div>
							</Grid>
							<Grid item xs={12} md={8} className='feeVal courseValue'>
								<WprContainer mode='新月額利用料.料金' view={this.view}>
									<MatTypography name='afterFee' className='newFeeUnit' view={this.view}/>
									<span className='feeUnit_bold'>円</span>
								</WprContainer>
								<WprContainer mode='新月額利用料.※' view={this.view}>
									<Typography className='itemUnit'>※ ご連絡にてご案内致します。</Typography>
								</WprContainer>
							</Grid>
						</Grid>	
						<Grid container className='feeRow'>
							<Grid item xs={12} md={4} className='feeTitle'>
								<div>購入金額の合計</div>
							</Grid>
							<Grid item xs={12} md={8} className='feeVal courseValue'>
								<MatTypography name='totalBuyFee' className='newFeeUnit' view={this.view}/>
								<span className='feeUnit_bold'>円</span>
							</Grid>
						</Grid>	
					</div>
					<div className='itemTitle title_maru'>基本情報</div>
					<div className='feeTable'>
						<Grid container className='feeRow'>
							<Grid item xs={12} md={4} className='feeTitle'>
								<div>申込みコースの月額</div>
							</Grid>
							<Grid item xs={12} md={8} className='feeVal courseValue'>
								<MatTypography name='amount' className='itemUnit unit' view={this.view}/>
								<span className='feeUnit' style={{fontWeight: '500'}}>円</span>
							</Grid>
						</Grid>	
						<Grid container className='feeRow'>
							<Grid item xs={12} md={4} className='feeTitle'>
								<div>申込みサービス</div>
							</Grid>
							<Grid item xs={12} md={8} className='feeVal courseValue'>
								<MatTypography name='course' className='itemUnit' view={this.view}/>
							</Grid>
						</Grid>	
						<Grid container className='feeRow'>
							<Grid item xs={12} md={4} className='feeTitle'>
								<div>端末の形態</div>
							</Grid>
							<Grid item xs={12} md={8} className='feeVal courseValue'>
								<MatTypography name='terminalType' className='itemUnit' view={this.view}/>
							</Grid>
						</Grid>	
					</div>
					<div className='itemTitle title_maru'>オプション</div>
					<Grid container>
						<Grid item xs={12}>
							<MatTableContainer listName={'OptionComfirmListModel'} view={this.view} noDataUseHeader noDataMessage=' '>
								<Table className = 'optionComfirmTable'>
									<colgroup>
										<col/>
										<col/>
										<col/>
									</colgroup>
									<TableHead className='hedderrow'>
										<TableRow className='toptable'>
											<TableCell align='center' >オプション名</TableCell>
											<TableCell align='center'>レンタル数量</TableCell>
											<TableCell align='center'>購入数量</TableCell>
										</TableRow>
									</TableHead>
									<TableBody className='optComfirmBody'>
										{this.renderList('OptionComfirmListModel')}
										<WprContainer mode='オプション.なし' view={this.view}>
											<TableRow>
												<TableCell className='non_list' colSpan={5}>追加するオプションはございません。</TableCell>
											</TableRow>
										</WprContainer>
									</TableBody>
								</Table>
							</MatTableContainer>
						</Grid>
					</Grid>
					<div style={{ fontSize: '12px', marginTop: '30px'}}>
						<ul className='ul_kome'>
							<li>
								表示されている月額は定価となります。実際の請求金額とは異なる場合があります。
							</li>
							<li>
								合計月額には割引も適用されています。
							</li>
							<li>
								スマートスピーカーオプションは家電コントローラーのレンタルが必須となります。
							</li>
						</ul>
					</div>
				</div>
				<Divider className='nonMuiDiver'/>
				<div className='pageFooter footer-space'>
					<div>
						<MatButton name='next' view={this.view} className='orangeButton orangeButton_parts' >
							この内容で申込む
							<NavigateNextIcon />
						</MatButton>
					</div>
					<div>
						<MatButton name='back' view={this.view} className='backButton' >
							<NavigateBeforeIcon />
							戻る
						</MatButton>
					</div>
				</div>
			</div>
			</>
		);
	}
	// --------------------------------------------------------------------------

	// リスト描画  ---------------------------------------------------------------
	/**
	 * リスト名=OptionComfirmListModel
	*/
	public onOptionComfirmListModelRender(rowInfo: WprRowInfo): any {
		return (
			<MatTableRow view={this.view} row={rowInfo}>
				<TableCell align='center'>
					<MatTypography name='option' style={{fontWeight: '500'}} view={this.view} row={rowInfo}/>
				</TableCell>
				<TableCell align='center'>
					<WprDiv name='withRental' view={this.view} row={rowInfo}>
						<MatTypography name='quantity1' className='optionComfirm' style={{fontWeight: '500'}} view={this.view} row={rowInfo}/>
						<MatTypography name='rentalUnit' className='quanUnit' style={{fontWeight: '500'}} view={this.view} row={rowInfo}/>
					</WprDiv>
					<WprDiv name='withoutRental' view={this.view} row={rowInfo}>
						<Typography style={{fontWeight: '500'}}>-</Typography>
					</WprDiv>
				</TableCell>
				<TableCell align='center'>
					<WprDiv name='withBuy' view={this.view} row={rowInfo}>
						<MatTypography name='quantity2' className='optionComfirm' style={{fontWeight: '500'}} view={this.view} row={rowInfo}/>
						<MatTypography name='buyUnit' className='quanUnit' style={{fontWeight: '500'}} view={this.view} row={rowInfo}/>
					</WprDiv>
					<WprDiv name='withoutBuy' view={this.view} row={rowInfo}>
						<Typography>-</Typography>
					</WprDiv>
				</TableCell>
			</MatTableRow>
		);
	}
	// --------------------------------------------------------------------------
}
