import { WprBaseConverter } from '../WprBaseConverter';

/**
 * 性別コンバータ
 */
export class WprGenderConverter extends WprBaseConverter {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() {
		super('性別');
	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * 値を表示用の値に変換する (value -> viewValue)
	 * @param value 値
	 * @returns 表示用の値
	 */
	 public convertView(value: any): string {
		if (value === 'M')
			return '男性';
		if (value === 'F')
			return '女性';
		return '';
	}
	// --------------------------------------------------------------------------
}
