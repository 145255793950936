import { Contract } from "../../../../models/bss/customer/Contract";


/**
 * テレビ詳細取得リクエスト
 */
export class GetTvInfoRequest {
	// public 変数	-----------------------------------------------------------
	public customerId: string;		// 
	public ownerList: Contract[];	// 
	public ftthFlg: string;			// 
	public terminal: string;			// 
	public buildingTypeCD: string;			// 
	public buildingLineTypeCD: string;			// 
	// ------------------------------------------------------------------------
}
