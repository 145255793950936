import { WprBaseDialogViewInfo } from '../../../../../wpr-framework/view/dialog/WprBaseDialogViewInfo';
import { WprModel } from '../../../../../wpr-framework/view/deco/WprModel';

export class MvnoTmpGetDialogData {
	public saveView: string;
}
export class MvnoTmpGetDialogResultData {
	public saveView: string;
}
/**
 * MVNO一時保存データ取得ダイアログ
 */
export class MvnoTmpGetDialogView extends WprBaseDialogViewInfo {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() { super('MvnoTmpGetDialog'); }
	// --------------------------------------------------------------------------

	// モデル  ------------------------------------------------------------------
	private m_SaveView: string	= null;	// saveView
	// --------------------------------------------------------------------------

	// override プロパティ  ------------------------------------------------------
	/** ダイアログタイトル */
	public get title(): string { return ''; }
	/** バリデーション */
	public get validation(): boolean { return false; }
	/** Divider使用フラグ */
	public get useDividers(): boolean { return false; }
	/** フッタ使用フラグ */
	public get useFooter(): boolean { return false; }
	/** OKボタン使用フラグ */
	public get useOKButton(): boolean { return false; }
	/** キャンセルボタン使用フラグ */
	public get useCancelButton(): boolean { return false; }
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * コントロール定義設定
	 */
	public onRegisterConfig(): void {

	}

	/**
	 * アクション登録
	 */
	public onRegisterAction(): void {
		this.addAction('toSavedView', this.onToSavedView);
	}

	/**
	 * ダイアログ表示通知
	 * @param sendData 送信データ
	 */
	public onShowDialog(sendData: MvnoTmpGetDialogData) {
		this.m_SaveView = sendData.saveView;
	}
	// --------------------------------------------------------------------------

	// アクション  ---------------------------------------------------------------
	/**
	 * 再開
	 */
	public onToSavedView(param: any): void {
		const resultData: MvnoTmpGetDialogResultData = new MvnoTmpGetDialogResultData();
		resultData.saveView = this.m_SaveView;
		this.close(true, resultData);
	}
	// --------------------------------------------------------------------------
}
