/**
 * パスワード発行リクエスト
 */
export class GetSetContractRadiusAccountRequest {
	// public 変数	-----------------------------------------------------------
	public contractID: string;		// 顧客番号
	public radiusAccount: string;	// アクセスアカウント
	public password: string;		// 旧パスワード
	public newPassword: string;		// 新パスワード
	public statusCD: string;		// 状態コード
	// ------------------------------------------------------------------------
}
