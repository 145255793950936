import { MypageBaseView } from '../../../base/MypageBaseView';
import { WprModel } from '../../../../../wpr-framework/view/deco/WprModel';
import { WprDI_ScopeModel } from '../../../../../wpr-framework/view/di/WprDI_ScopeModel';
import { HMvnoTmpSaved } from '../../../../service/models/entity/HMvnoTmpSaved';
import { MvnoService } from '../../../../service/MvnoService';
import { CheckMultiContractRequest } from '../../../../service/models/dto/mvno/CheckMultiContractRequest';
import { GetCustomerRequest } from '../../../../service/models/dto/mvno/GetCustomerRequest';
import { MvnoEntryInfo } from '../../../../service/models/apimodels/MvnoEntryInfo';
import { GetHMvnoTmpSavedRequest } from '../../../../service/models/dto/mvno/GetHMvnoTmpSavedRequest';
import { MvnoBaseView } from '../../../base/MvnoBaseView';
import { MvnoHelpDialogData } from '../../dialog/mvno_help_dialog/MvnoHelpDialogView';
import { send } from 'process';
import { CheckMvnoEntryRequest } from '../../../../service/models/dto/mvno/CheckMvnoEntryRequest';
import { MvnoTmpGetDialogData, MvnoTmpGetDialogResultData } from '../../dialog/mvno_tmp_get_dialog/MvnoTmpGetDialogView';
import { VIEW_DEFINE_JSON } from '../../../../common/viewDefine';
import { MypageLinkConstatns } from '../../../../common/constants/MypageLinkConstatns';

/**
 * MVNO申込_手続き選択
 */
export class MvnoEntryProcessView extends MvnoBaseView {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() { super('MvnoEntryProcess'); }
	// --------------------------------------------------------------------------

	// モデル  ------------------------------------------------------------------
	@WprDI_ScopeModel('MvnoEntryInfo')
	private m_EntryInfo: MvnoEntryInfo = null;	// MVNOプラン情報
	// --------------------------------------------------------------------------

	// サービス  ----------------------------------------------------------------
	private m_MvnoService: MvnoService = new MvnoService();	// MyPageRenewalサービス
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * コントロール定義設定
	 */
	public onRegisterConfig(): void {
		// this.addConfig('plan', 'プラン名', 'MvnoEntryInfo.entryInfo');
		// this.addConfig('entryTerminalFlg', '端末申込', 'MvnoEntryInfo.entryInfo');
	}

	/**
	 * アクション登録
	 */
	public onRegisterAction(): void {
		this.addAction('skipToNext', this.onNext);
		this.addAction('next', this.onNext);
		this.addAction('toShop', this.onToShop);
		this.addAction('toOnline', this.onToOnline);
		this.addAction('aboutIdentifiction', this.onOpenDialog);
		this.addAction('aboutMnp', this.onOpenMnpDialog);
		this.addAction('back', this.onBack);
	}

	/**
	 * ビュー表示通知
	 */
	public onShowView(): void {
		this.registAccessLog();
		this.setStorageItem('b_url', window.location.href)
		const customerId: string = this.getCustomerId();
		const req: CheckMvnoEntryRequest = new CheckMvnoEntryRequest();
		req.customerId = customerId;
		this.m_MvnoService.checkMvnoEntry(this, req, (result) => {
			this.m_EntryInfo.noJoinFlg = result.noJoinFlg;
			this.setCookie('noJoinFlg', String(this.m_EntryInfo.noJoinFlg));
			// １：複数台契約
			if (!result.multiContractOk) {
				const errormessage: string = '既に5台契約済みのため、お申込みできません。';
				this.setScopeModel('errorMessage', errormessage);
				this.pushPath('/error');
			} 
			// ２：リマインダー
			else if (!result.reminderOk) {
				const errormessage: string = '申込みにあたりご利用料のお支払いについてご案内事項がございます。<br/>恐れ入りますがフリーダイヤル（0120-109199）までお問い合わせください。';
				this.setScopeModel('errorMessage', errormessage);
				this.pushPath('/error');
			}
			// ３：支払い
			else if (!result.paymentOk) {
				const errormessage: string = '申込みには3か月以上の当社有料サービスのご利用実績が必要となります。もしくはお支払い方法をクレジットカードで登録いただく必要がございます。<br/>マイページからもクレジットカードへのご変更が可能です。';
				this.setScopeModel('errorMessage', errormessage);
				this.pushPath('/error');
			} 
			// ４：対象サービス加入
			else if (!result.serviceOk) {
				let errormessage: string = '<div>イッツコム スマホのご契約には、イッツコム他サービスのご契約が必要です。</div>';
				errormessage += '<div>他サービスに加入希望の方は<a href="' + MypageLinkConstatns.ITSCOM_TOIAWASE + '" target="_blank" rel="noopener noreferrer" className="link_underline_blue">こちら</a>からお問合せください。</div>'
				// 以下メッセージを記載する必要がある
				// 対象サービスに加入希望の方はこちらからお問合せください。
				// ※こちらは問合せフォームへリンクさせる
				this.setScopeModel('errorMessage', errormessage);
				this.pushPath('/error');
			}
			else {
				const request: GetHMvnoTmpSavedRequest = new GetHMvnoTmpSavedRequest();
				request.target = new HMvnoTmpSaved();
				request.target.customerId = customerId;
				this.m_MvnoService.getHMvnoTmpSaved(this, request, (result) => {
					this.m_EntryInfo.entryInfo = result.detail;
					this.m_EntryInfo.total = result.total;
					this.setCookieValForMvno(this.m_EntryInfo);
					this.setCookie('total', this.m_EntryInfo.total);
					if (result.detail?.mvnoTmpSavedId) {
						const sendData: MvnoTmpGetDialogData =  new MvnoTmpGetDialogData();
						sendData.saveView = result.detail.saveViewName;
						this.showDialog('MVNO一時保存データ取得ダイアログ', sendData, (status, result) => {
							if (status === true) {
								const resultData: MvnoTmpGetDialogResultData = result;
								// const viewPath: string = 
							const viewDefine = VIEW_DEFINE_JSON.find(view => {
								return (resultData.saveView === view.name);
							});
							const viewPath: string = viewDefine?.virtualPage;
							this.pushPath(viewPath);
							}
						});
					}
				this.refreshView();
				});
			}
		});
	}

	/**
	 * ウインドウリサイズ通知
	 * @param width 幅
	 * @param height 高さ
	 */
	public onResizeWindow(width: number, height: number): void {
		const currentMode: string = this.getViewMode('画面サイズ');
		if (width >= 960) {
			if (currentMode != '大') {
				// this.setChildViewMode('画面サイズ', '大');
				this.setViewMode('画面サイズ', '大');
			}
		}
		else {
			if (currentMode != '中') {
				// this.setChildViewMode('画面サイズ', '中');
				this.setViewMode('画面サイズ', '中');
			}
		}
	}
	// --------------------------------------------------------------------------

	// アクション  ---------------------------------------------------------------
	/**
	 * 次へ
	 */
	public onNext(param: any): void {
		this.refreshModel();
		this.pushPath('/mvno_002');
	}

	/**
	 * 次へ
	 */
	public onToShop(param: any): void {
		const url: string = 'https://reg34.smp.ne.jp/regist/is?SMPFORM=ndpe-lboamj-286a2537526720b08a2f7b9b5bd1c2bb&_gl=1*1qgknbu*_ga*MTgzMjkxOTE5NS4xNTY1MDc3MTU5*_ga_TFJMGXD6WE*MTY3NzY1OTEwOC4xODguMS4xNjc3NjYwNzE1LjkuMC4w&_ga=2.110055302.266835623.1677570459-1832919195.1565077159&_bdld=htERc.o6aC+X1.1677660719&_bdsid=htERc.o6aC+X1.1677659108867.1677660719&_bd_prev_page=https%3A%2F%2Fwww.itscom.co.jp%2Fservice%2Fmobile%2Fcheck.html&_bdrpf=1';
		window.open(url);
	}

	/**
	 * 次へ
	 */
	public onToOnline(param: any): void {
		const url: string = 'https://www.itscom.co.jp/lp/mvno-onlinesoudan.html';
		window.open(url);
	}

	/**
	 * 次へ
	 */
	public onOpenDialog(param: any): void {
		this.showDialog('ヘルプ：本人確認書類について');
	}

	/**
	 * 次へ
	 */
	public onOpenMnpDialog(param: any): void {
		const sendData: MvnoHelpDialogData = new MvnoHelpDialogData();
		sendData.title = '採番方法について';
		this.showDialog('MVNOヘルプダイアログ', sendData);
	}

	/**
	 * 戻る
	 * 現行の契約内容一覧へ戻る
	 */
	public onBack(param: any): void {
		location.href = this.getOldUrl() + "Srvlist/Continfo_list/";
	}	
	// --------------------------------------------------------------------------
}
