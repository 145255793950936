/**
 * グレードリクエスト
 */
export class GetGradeRequest {
	// public 変数	-----------------------------------------------------------
	public befServiceCd: string;	// 変更前サービスコード
	public aftServiceCd: string;	// 変更後サービスコード
	public befEquipmentCd: string;	// 変更前端末コード
	public aftEquipmentName: string;// 変更後端末名
	// ------------------------------------------------------------------------
}
