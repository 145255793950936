import { WizardModel } from '../../models/WizardModel';
import { MypageWizardFlow } from './MypageWizardFlow';
import { MDisplay } from '../../service/models/entity/secondary/MDisplay';
import { CommonCompleteView } from '../common/common_complete/CommonCompleteView';
import { VideoIpstbComfirmView } from '../videoService/video_ipstb_comfirm/VideoIpstbComfirmView';
import { GetViewInfoRequest } from '../../service/models/dto/mypagerenewal/GetViewInfoRequest';
import { MypageBaseView } from '../base/MypageBaseView';
import { CommonAgreeView } from '../common/common_agree/CommonAgreeView';
import { MCommon } from '../../service/models/entity/primary/MCommon';
import MypageDispdivcdConstants from '../../common/MypageDispdivcdConstants';
import MypageCodeTypeConstants from '../../common/MypageCodeTypeConstants';
import { MGeneral } from '../../service/models/entity/MGeneral';
import { MypageWizardMngr } from './models/MypageWizardMngr';
import { RegistInfoRequest } from '../../service/models/dto/mypagerenewal/RegistInfoRequest';
import { Mail } from '../../service/models/apimodels/Mail';
import { InquiryMailInfo } from '../../service/models/apimodels/InquiryMailInfo';
import MypageYakkanConstants from '../../common/MypageYakkanConstants';
import { MAIL_CONSTANTS } from '../../common/MyPageMailIdConstants';

/**
 * 動画配信サービス_見放題パックプライム_ウィザードクラス
 */
export class VideoIpstbWizardFlow extends MypageWizardFlow {
	// private 変数  ------------------------------------------------------------
	private m_DefaultWizardFlow: WizardModel[]			= null;		// 画面ウィザードリスト
	private m_StartViewName: string						= null;
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	public constructor() {
		super();
		this.m_StartViewName = new VideoIpstbComfirmView().name;

		// 遷移パターン１
		this.m_DefaultWizardFlow = new Array();
		this.m_DefaultWizardFlow.push({stepNum: 0, title: '契約選択', viewName: null, path: this.getOldUrl() + 'Srvlist/Continfo_list/'});
		this.m_DefaultWizardFlow.push({stepNum: 1, title: '申込み内容の確認', viewName: new VideoIpstbComfirmView().name, path: 'video_ipstb_comfirm'});
		this.m_DefaultWizardFlow.push({stepNum: 2, title: '契約約款', viewName: new CommonAgreeView().name, path: 'video_ipstb_agree'});
		this.m_DefaultWizardFlow.push({stepNum: 3, title: '変更申込み完了', viewName: new CommonCompleteView().name, path: 'video_ipstb_complete'});
	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * 画面ウィザードフロー名取得
	 */
	public getWizardFlowName(viewName: string): string {
		if (viewName === this.m_StartViewName)
			return this.constructor.name;
		return null;
	}
	
	/**
	 * 画面ウィザードリスト取得：契約状態で切り替えたり、選択肢で切り替えたり
	 */
	public getWizardFlow(mngr: MypageWizardMngr): WizardModel[] {
		return this.m_DefaultWizardFlow;
	}
	
	/**
	 * 画面ウィザードAPI用モデル取得：契約状態で切り替えたり、選択肢で切り替えたり
	 */
	public getWizardApiModel(mngr: MypageWizardMngr, view: MypageBaseView): GetViewInfoRequest {
		const req = new GetViewInfoRequest();
		req.customerId = mngr.getParams().customerId;
		req.displayList = new Array();
		req.displayList.push(new MDisplay(MypageDispdivcdConstants.VIDEO_SERVICE, null));
		req.yakkanList = new Array();
		req.yakkanList.push(new MGeneral(MypageCodeTypeConstants.CONTRACT_AGREE, MypageYakkanConstants.ITSCOM_SERVICE_IMPORTANT));
		req.yakkanList.push(new MGeneral(MypageCodeTypeConstants.CONTRACT_AGREE, MypageYakkanConstants.ITSCOM_UNLIMITED));
		req.commonList = new Array();
		req.commonList.push(new MCommon('IP-STB月額', 'srvidp', 'ip_stb_code', null, null));
		return req;
	}
	
	/**
	 * 画面ウィザード完了APIモデル取得
	 */
	public getRegistApiModel(mngr: MypageWizardMngr, view: MypageBaseView): RegistInfoRequest{
		const req: RegistInfoRequest = new RegistInfoRequest();
		req.customerId = mngr.getParams().customerId;
		const mailconstants = MAIL_CONSTANTS.find (mail => {
			return mail.view === 'みるプラス_IP-STB_申込み';
		});
		req.customerMailId = mailconstants.customerMail;
		req.systemMailId = mailconstants.systemMail;
		req.inquiryId = mailconstants.inquiryMail;
		req.systemInfo = new InquiryMailInfo();
		req.systemInfo.appService = 'IP-STB'
		return req;
	}

	/**
	 * 画面ウィザードフローの中断判定：例：申し込みが完了していれば、完了済みの画面へ遷移
	 */
	public checkFlowBreak(mngr: MypageWizardMngr): string {
		let test: boolean = false;
		if (test) {
			return '/error';
		}
		return null;
	}
	// --------------------------------------------------------------------------
}
