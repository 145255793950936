import React, { ReactNode } from 'react';
import { WprBaseViewComponent } from '../../../../wpr-framework/view/component/WprBaseViewComponent';
import { AddAccesspassSelectView } from './AddAccesspassSelectView';
import { Typography, Grid, Divider } from '@material-ui/core';
import { MatButton } from '../../../../wpr-framework/component/controls/material/MatButton';
import { ProgressBar } from '../../common/progress_bar/ProgressBar';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { WprViewError } from '../../../../wpr-framework/component/controls/WprViewError';
import { WprSpan } from '../../../../wpr-framework/component/controls/WprSpan';
import { MatTypography } from '../../../../wpr-framework/component/controls/material/MatTypography';
import { WprContainer } from '../../../../wpr-framework/component/controls/WprContainer';
import { WprRowInfo } from '../../../../wpr-framework/component/props/WprRowInfo';
import { WprErrorContainer } from '../../../../wpr-framework/component/controls/WprErrorContainer';
import { WprInput } from '../../../../wpr-framework/component/controls/WprInput';
import styles from './AddAccesspassSelect.scss';
import netIcon from '/shared/image/common/logo_iTSCOMNET.png';
import importantIcon from '/shared/image/common/important_purple.png';
import errorIcon from '/shared/image/common/icon_error.png';
/**
 * アクセスアカウントパスワード変更用コンポーネント
 */
export class AddAccesspassSelect extends WprBaseViewComponent {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: any) { super(new AddAccesspassSelectView(), props); }
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** アクセスアカウントパスワード変更ビュー情報 */
	public get viewInfo(): AddAccesspassSelectView { return this.view as AddAccesspassSelectView; }
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * スタイルオブジェクト取得
	*/
	public getStylesObject(): any { return styles; }

	/**
	 * リストコントロール定義登録
	*/
	protected onRegisterListRender(): void {
		this.addListRender('ErrorModel', null, this.onErrorModelRender);
	}

	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		return (
			<>
				<div className='pageCard'>
					<div className="pageHeader stepFlow">
						<ProgressBar name='progressBar' view={this.view} />
					</div>
					<div className='sub-logo'>
						<img src={netIcon} />
					</div>
					<div className='main'>
						<div className='pageTitle'>
							<div className='title_left'>
								<MatTypography name='title' className='title_text' view={this.view} />
								<Typography className='title_comment' style={{ fontWeight: '500' }}>新しいパスワードを入力し、「確認」ボタンをクリックしてください。</Typography>
							</div>
						</div>
						<WprErrorContainer view={this.view}>
							<div className='errorArea'>
								<span className='parallel'>
									<img src={errorIcon} alt='' className='x_img' />
									<div className='errorList'>
										<WprViewError className = 'error'view={this.view}/>
									</div>
								</span>
							</div>
						</WprErrorContainer>
						<WprContainer mode='オプション.エラー' view={this.view}>
							<div className='errorArea'>
								<span className='parallel'>
									<img src={errorIcon} alt='' className='x_img'/>
									<div className='errorList'>
										{this.renderList('ErrorModel')}
									</div>
								</span>
							</div>
						</WprContainer>
						<WprContainer mode='オプション.エラーなし' view={this.view}></WprContainer>
						<div className='feeTable'>
							<Grid container className='feeRow'>
								<Grid item xs={12} md={4} className='feeTitleTop'>
									<div>コース</div>
								</Grid>
								<Grid item xs={12} md={8} className='feeVal courseValue' style={{ color: 'black' }}>
									<span>
										<WprSpan name={'course'} view={this.view} style={{ color: '#484848', fontWeight: '550',textalign: 'left' }}></WprSpan>
									</span>
								</Grid>
							</Grid>
							<Grid container className='feeRow'>
								<Grid item xs={12} md={4} className='feeTitle2'>
									<div>アクセスアカウント</div>
								</Grid>
								<Grid item xs={12} md={8} className='feeVal courseValue' style={{ color: 'black'}}>
									<WprSpan name={'radiusAccount'} view={this.view} style={{ color: '#484848', fontWeight: '550' }}></WprSpan>
								</Grid>
							</Grid>
							<Grid container className='feeRow'>
								<Grid item xs={12} md={4} className='feeTitle'>
									<div>現在のパスワード</div>
								</Grid>
								<Grid item xs={12} md={8} className='feeVal2 courseValue' style={{ color: 'black' }}>
									<WprInput name='password' className='password' type='password' view={this.view} />
								</Grid>
							</Grid>
							<Grid container className='feeRow'>
								<Grid item xs={12} md={4} className='feeTitle'>
									<div>新しいパスワード</div>
								</Grid>
								<Grid item xs={12} md={8} className='feeVal2 courseValue' style={{ color: 'black'}}>
									<WprInput name='newPassword' className='password' type='password' view={this.view} />
								</Grid>
							</Grid>
							<Grid container className='feeRow'>
								<WprViewError name='passwordCheckError' view={this.view} ></WprViewError>
								<Grid item xs={12} md={4} className='feeTitle'>
									<div>新パスワードの再入力</div>
								</Grid>
								<Grid item xs={12} md={8} className='feeVal2 courseValue' style={{ color: 'black' }}>
									<WprInput name='reEnterPassword' className='password' type='password' view={this.view} />
								</Grid>
							</Grid>
						</div>
						<div className='supportMsgArea supportText'>
								<span className='textcolor'>
									<img src= {importantIcon} alt='purple' style={{ verticalAlign: 'middle' }} />
									パスワードの登録文字制限について
								</span>
								<li style={{ paddingLeft: '5px' }}>
									文字数：5～16文字
									<br />
									ご利用可能文字：半角英数字、特殊文字（%、+、.、/、:、=、@、^、_、-）
								</li>
						</div>
					</div>
					<Divider />
					<div className='pageFooter footer-space'>
						<div>
							<MatButton name='next' view={this.view} className='orangeButton orangeButton_parts' >
								確認
								<NavigateNextIcon />
							</MatButton>
						</div>
						<div>
							<MatButton name='back' view={this.view} className='backButton' >
								<NavigateBeforeIcon />
								戻る
							</MatButton>
						</div>
					</div>
				</div>
			</>
		);
	}

	/**
 * リスト名=ErrorModel
 */
	public onErrorModelRender(rowInfo: WprRowInfo): any {
		return (
			<div>
				<MatTypography name='errorMessage' view={this.view} row={rowInfo} />
			</div>
		);
	}

	// --------------------------------------------------------------------------
}
