import { Divider, Grid } from '@material-ui/core';
import React, { ReactNode } from 'react';
import { MatButton } from '../../../../../wpr-framework/component/controls/material/MatButton';
import { MatTypography } from '../../../../../wpr-framework/component/controls/material/MatTypography';
import { WprBaseViewComponent } from '../../../../../wpr-framework/view/component/WprBaseViewComponent';
import { MvnoEntryFooterView } from './MvnoEntryFooterView';
import { MvnoFeeDialog } from '../../dialog/mvno_fee_dialog/MvnoFeeDialog';
import styles from './MvnoEntryFooter.scss';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { MvnoTmpSaveDialog } from '../../dialog/mvno_tmp_save_dialog/MvnoTmpSaveDialog';

/**
 * MVNO申込_フッター（一時保存・合計）用コンポーネント
 */
export class MvnoEntryFooter extends WprBaseViewComponent {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: any) { super(new MvnoEntryFooterView(), props); }
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** MVNO申込_フッター（一時保存・合計）ビュー情報 */
	public get viewInfo(): MvnoEntryFooterView { return this.view as MvnoEntryFooterView; }
	// --------------------------------------------------------------------------
	/**
	 * スタイルオブジェクト取得
	*/
	public getStylesObject(): any { return styles; }

	// override メソッド  --------------------------------------------------------
	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		return (
			<>
				<div className='mvnoFooter' id='mvnoFooter'>
					<Grid container style={{height: '100%'}}>
						<Grid item xs={12} md={6} className='saveItemParent'>

							<div style={{height: '100%'}}>
								<MatButton variant='text' name='tempSave' className='tempSave' view={this.view}>
									<CheckCircleIcon />
									現在の内容を一時保存する
								</MatButton>
							</div>
							{/* <Divider variant='' /> */}
						</Grid>
						<Grid item xs={12} md={6}>
							<div className='currentFee'>
								<Grid container alignItems='center' style={{height: '100%'}}>
									<Grid item xs={7} className='currentFeeTitle'>
										現在の料金
										<MatTypography name='total' className='total' view={this.view} component='span' />円
									</Grid>
									<Grid item xs={5}>
										<div className='feeDetailBUtton'>
											<MatButton variant='text' name='feeDetail' view={this.view} className='feeDetailButton'>
												明細
												<AddCircleIcon />
											</MatButton>
										</div>
									</Grid>
								</Grid>
							</div>


						</Grid>
					</Grid>
				</div>

				{/* ダイアログ */}
				<MvnoFeeDialog name='MVNO_料金明細ダイアログ' className='dialog-medium' view={this.view} />
				<MvnoTmpSaveDialog name='MVNO一時保存完了ダイアログ' view={this.view} />
			</>
		);
	}
	// --------------------------------------------------------------------------
}
