// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@charset \"UTF-8\";\n/*\r\n * 着信転送申込み用CSS\r\n */\n.CallForwardingSelect-gray {\n  background-color: #f7f7f7;\n  border-radius: 2%/15%;\n  padding: 20px;\n  margin-top: 30px; }\n  .CallForwardingSelect-gray li {\n    text-indent: -1em;\n    padding-left: 1em;\n    list-style: none;\n    line-height: 18px;\n    padding-bottom: 2px;\n    color: #333333; }\n  .CallForwardingSelect-gray li:before {\n    content: \"・\"; }\n  .CallForwardingSelect-gray .CallForwardingSelect-title {\n    font-weight: 700; }\n  @media screen and (max-width: 440px) {\n    .CallForwardingSelect-gray {\n      margin-bottom: 0; } }\n", ""]);
// Exports
exports.locals = {
	"gray": "CallForwardingSelect-gray",
	"title": "CallForwardingSelect-title"
};
module.exports = exports;
