import React, { ReactNode } from 'react';
import { WprBaseViewComponent } from '../../../../wpr-framework/view/component/WprBaseViewComponent';
import { ProgressBarView } from './ProgressBarView';
import itscom_icon from "../../../data/itscom_icon.png";
import { Grid, Typography } from '@material-ui/core';
import styles from './ProgressBar.scss';
import { WprRowInfo } from '../../../../wpr-framework/component/props/WprRowInfo';
import { MatTypography } from '../../../../wpr-framework/component/controls/material/MatTypography';
import { ProgressBarModel, ProgressBarStopModel } from '../../../models/ProgressBarModel';

/**
 * 進捗バー用コンポーネント
 */
export class ProgressBar extends WprBaseViewComponent {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: any) { super(new ProgressBarView(), props); }
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** 進捗バービュー情報 */
	public get viewInfo(): ProgressBarView { return this.view as ProgressBarView; }
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * スタイルオブジェクト取得
	*/
	public getStylesObject(): any { return styles; }

	/**
	 * リストコントロール定義登録
	*/
	protected onRegisterListRender(): void {
		this.addListRender('ProgressBarList', null, this.onProgressBarListRender);
		this.addListRender('ProgressBarStopList', null, this.onProgressBarStopListRender);
	}

	/**
	 * 描画
	 */
	public onRender(): ReactNode {

		return (
			<>
				{/* Milosから追加 START */}

				{/* Milosから追加 END */}
				<div className='stepper'>

					<div className='stepBar' id='stepBarParentId'>
						<div style={{ display: 'flex' }}>
							<Grid container id='stepBarId'>
								{this.renderList('ProgressBarStopList')}
							</Grid>
						</div>
						<div className='stepBar_active'></div>
					</div>

					{this.renderList('ProgressBarList')}
				</div>
			</>
		);
	}
	// --------------------------------------------------------------------------

	// リスト描画  ---------------------------------------------------------------
	/**
	 * リスト名=ProgressBarList
	 */
	public onProgressBarListRender(rowInfo: WprRowInfo): any {

		const data: ProgressBarModel = rowInfo.rowData as ProgressBarModel;
		let className = null;
		const url = window.location.pathname;
		if (url.includes('relocate')) {
			className = 'stepItem_relocate';
		} else {
			className = 'stepItem';
		}
		// className = 'stepItem';
		switch (data.status) {
			case 'complete':
				className += ' complete';
				break;
			case 'current':
				className += ' current';
				break;
			case 'next':
				className += ' next';
				break;

			case 'not':
				break;


			default:
				break;
		}
		return (
			<>
				<div className={className}>
					<div>
						<div className='stepNumber'>
							<span className='stepNumberSpan'>{data.stepNum}</span>
						</div>
						<div className='title'>{data.title}</div>
					</div>
				</div>
			</>

		);
	}

	/**
	 * リスト名=ProgressBarList
	 */
	public onProgressBarStopListRender(rowInfo: WprRowInfo): any {

		const data: ProgressBarStopModel = rowInfo.rowData as ProgressBarStopModel;
		let className = 'stepBar_stop';
		switch (data.status) {
			case 'next':
				className += ' next';
				break;
			default:
				break;
		}
		return (
			<>
				<Grid item xs={3}>
					<div className={className}></div>
				</Grid>
			</>

		);
	}
	// --------------------------------------------------------------------------
}
