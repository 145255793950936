/**
 * 認証情報
 */
export class CertificationInfo {
	// public 変数	-----------------------------------------------------------
	public sessionFlg: boolean;	// ログイン可否
	public sessionId: string;	// 認証ID
	public authToken: string;	// 認証トークン
	public customerId: string;	// 顧客番号
	// ------------------------------------------------------------------------
}
