import { WprControlInfo } from '../WprControlInfo';
import { WprListInfo } from './WprListInfo';
import { WprBaseViewCore } from '../../WprBaseViewCore';
import { IWprConfigOption } from '../IWprConfigOption';
import { WprRowInfo } from '../../../component/props/WprRowInfo';
import { WprControlVisibility, IWprControlState } from '../../../component/props/IWprControlState';
import { IWprLayoutConfigOption } from '../IWprLayoutConfigOption';

/**
 * リストコントロール情報
 */
export class WprListControlInfo extends WprControlInfo {
	// private 変数  ------------------------------------------------------------
	private m_ListInfo: WprListInfo					= null;			// リスト情報
	private m_UseFormControl						= true;			// フォームコントロール使用フラグ
	private m_ControlList: WprControlInfo[]			= new Array();	// コントロール情報リスト
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** コントロール情報リスト */
	public get controlList(): WprControlInfo[] 	{ return this.m_ControlList; 	}
	/** フォームコントロール使用フラグ */
	public get useFormControl(): boolean 		{ return this.m_UseFormControl; }	public set useFormControl(use: boolean)	{ this.m_UseFormControl = use;	}
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	public constructor(linfo: WprListInfo, viewCore: WprBaseViewCore, name: string, description: string, config: IWprConfigOption, layoutConfig: IWprLayoutConfigOption) {
		super(viewCore, name, description, null, config, layoutConfig);
		this.m_ListInfo = linfo;
		this.rowIndex = 0;
	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * コンポーネントのステータス取得
	 * @param row 行情報
	 * @returns コントロールステータス
	 */
	public getComponentState(row?: WprRowInfo): IWprControlState {
		return this.m_ControlList[row.index].getComponentState();
	}

	/**
	 * 表示権限設定
	 */
	public setDispAuth() {
		if (this.m_ControlList) {
			this.m_ControlList.forEach(cinfo =>{
				cinfo.setDispAuth();
			});
		}
	}

	/**
	 * 活性化権限設定
	 */
	public setEnableAuth() {
		if (this.m_ControlList) {
			this.m_ControlList.forEach(cinfo => {
				cinfo.setEnableAuth();
			});
		}
	}
	// --------------------------------------------------------------------------

	// public メソッド  ---------------------------------------------------------
	/**
	 * コントロールクリア
	 */
	public clearControl(): void {
		this.m_ControlList = new Array();
	}

	/**
	 * コントロール追加
	 * @returns 追加したコントロール
	 */
	public addControl(): void {
		const control = new WprControlInfo(this.viewCore, this.name, this.description, this.modelName, undefined, undefined);
		control.validator = this.validator;
		control.config = this.config;
		control.status = this.status.clone();
		this.m_ControlList.push(control);
		control.setEnableAuth();
		control.setDispAuth();
	}

	/**
	 * コントロール値設定
	 * @param idx 設定一
	 * @param val 値
	 */
	public setControlValue(idx: number, val: any): void {
		if (this.config.converter)
			this.config.converter.rowData = this.m_ListInfo.listData[idx];
		this.m_ControlList[idx].value = val;
		let viewValue = val;
		let editValue = val;
		if (this.config.converter) {
			this.config.converter.rowData = this.m_ListInfo.listData[idx];
			viewValue = this.config.converter.convertView(val);
			editValue = this.config.converter.convertEdit(val);
		}
		this.m_ControlList[idx].setConvertValue(viewValue, editValue);
	}

	/**
	 * ValueMapリセット処理
	 * @param rdata 行データ
	 * @param name 名前 
	 * @param clearValue 値クリアフラグ
	 * @returns trueの場合成功
	 */
	public resetListValueMap(idx: number, clearValue: boolean=true) {
		this.m_ControlList[idx].resetValueMap(clearValue);
	}

	/**
	 * コントロール値取得
	 * @param idx 設定位置
	 * @returns 値
	 */
	public getControlValue(idx: number): any {
		return this.m_ControlList[idx].value;
	}

	/**
	 * モード値設定フラグ取得
	 * @param idx 取得位置
	 * @returns モード値設定フラグ
	 */
	public getIsSetModeValue(idx: number): boolean {
		return this.m_ControlList[idx].isSetModeValue;
	}

	/**
	 * コントロール情報取得
	 * @returns コントロール情報
	 */
	public getRowControlInfo(): WprControlInfo {
		return this.m_ControlList[this.rowIndex];
	}

	/**
	 * コントロールカスタム権限設定
	 * @param idx 設定一
	 * @param val 値
	 */
	 public setControlCustumAuth(idx: number): void {
		this.m_ControlList[idx].setCustumAuth();
	}
	// --------------------------------------------------------------------------
}
