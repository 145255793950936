import { MypageBaseView } from '../../../base/MypageBaseView';
import { WprModel } from '../../../../../wpr-framework/view/deco/WprModel';
import { WprDI_ScopeModel } from '../../../../../wpr-framework/view/di/WprDI_ScopeModel';
import { HMvnoTmpSaved } from '../../../../service/models/entity/HMvnoTmpSaved';
import { MvnoMnpInfo } from '../../../../service/models/apimodels/MvnoMnpInfo';
import { MvnoService } from '../../../../service/MvnoService';
import { GetValidCouponListRequest } from '../../../../service/models/dto/mvno/GetValidCouponListRequest';
import { WprListData } from '../../../../../wpr-framework/view/deco/WprListData';
import { CouponModel } from '../../../../service/models/apimodels/CouponModel';
import { MvnoCouponInfo } from '../../../../service/models/apimodels/MvnoCouponInfo';

/**
 * MVNお_データ容量確認
 */
export class MvnoConfirmCapacityView extends MypageBaseView {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() { super('MvnoConfirmCapacity'); }
	// --------------------------------------------------------------------------

	// モデル  ------------------------------------------------------------------
	// @WprDI_ScopeModel('MvnoMnpInfo')
	// @WprModel('MvnoMnpInfo')
	// private m_MvnoMnpInfo: MvnoMnpInfo = null;	// MVNOプラン情報
	@WprListData('CouponList')
	private m_CouponList: CouponModel[]	= null;	// クーポンモデル

	@WprDI_ScopeModel('MvnoCouponInfo')
	@WprModel('MvnoCouponInfo')
	private m_MvnoCouponInfo: MvnoCouponInfo	= null;	// MVNOクーポン情報
	// --------------------------------------------------------------------------

	// サービス  ----------------------------------------------------------------
	private m_MvnoService: MvnoService	= new MvnoService();	// MyPageRenewalサービス
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * コントロール定義設定
	 */
	public onRegisterConfig(): void {

		// this.addConfig('iccId', 'ICCID', 'MvnoMnpInfo', { emptyValue: null, hissu: true });
		this.addConfig('phoneNumber', '回線番号', 'MvnoCouponInfo');
		this.addListConfig('expiration', '有効期限', 'CouponList', { converter: '日付（yyyy/MM/dd）' });
		this.addListConfig('capacity', '容量', 'CouponList', { converter: '3桁カンマ' });
	}

	/**
	 * アクション登録
	 */
	public onRegisterAction(): void {
		this.addAction('next', this.onNext);
		this.addAction('mvnoCapacityChangeLink', this.onMvnoCapacityChangeLink);
		// this.addAction('iccidLink', this.onIccidLink);
		this.addAction('back', this.onBack);
	}

	/**
	 * ビュー表示通知
	 */
	public onShowView(): void {
		this.registAccessLog();
			this.setStorageItem('b_url', window.location.href)
		
		const req: GetValidCouponListRequest = new GetValidCouponListRequest();
		req.customerId = this.getCustomerId();
		req.contractId = this.getContractCd();
		this.m_MvnoService.getValidCouponList(this, req, (result) => {
			this.m_MvnoCouponInfo = result.couponInfo;
			this.m_CouponList = result.couponInfo.couponList;
			this.setListData('CouponList', this.m_CouponList);
			this.setViewMode('クーポンリスト', this.m_CouponList.length > 0 ? '有' : '無');
			this.refreshView();
		});
	}
	// --------------------------------------------------------------------------

	// アクション  ---------------------------------------------------------------
	/**
	 * 次へ
	 */
	public onNext(param: any): void {
		this.setScopeModel('MvnoCouponInfo', this.m_MvnoCouponInfo);
		this.pushPath('/mvno_add_capacity_001');
	}


	/**
	 * データ容量変更へ
	 */
	public onMvnoCapacityChangeLink(param: any): void {
		this.pushPath('/mvno_change_001');
	}

	/**
	 * 戻る
	 * 現行の契約内容一覧へ戻る
	 */
	public onBack(param: any): void {
		location.href = this.getOldUrl() + "Srvlist/Continfo_list/";
	}
	// --------------------------------------------------------------------------
}
