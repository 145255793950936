import { WprBaseViewListMultiModeInfo } from '../../../../../wpr-framework/mode/WprBaseViewListMultiModeInfo';
import { WprMultiMode } from '../../../../../wpr-framework/mode/WprMultiMode';
import { RelocateServiceListModel } from '../../../../models/mypage/relocate/RelocateServiceListModel';
import { RelocateSelect2View } from '../RelocateSelect2View';

/**
 * ネットリストモード
 */
export class NetListMode extends WprBaseViewListMultiModeInfo<RelocateSelect2View, RelocateServiceListModel> {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() {
		super('NetListModel');
	}
	// --------------------------------------------------------------------------
	
	// override メソッド  --------------------------------------------------------
	/**
	 * モードリスト取得（この画面モードで使用する全てのモードを定義する）
	 * @param list モードリスト
	 */
	public useModeList(list: string[]): void {
		list.push('フリー入力表示');
		list.push('フリー入力非表示');
	}

	/**
	 * フィールド定義を登録処理
	 */
	public onRegisterControl(): void {
		this.addControl('comment', {
			display: [ { mode: 'フリー入力表示', value: true }, { mode: 'other', value: false } ],
			hissu: [ { mode: 'フリー入力表示', value: true }, { mode: 'other', value: false } ]
		});
	}

	/**
	 * ビューの状態をチェックして行のモードを変更する
	 * @param curMode 現在のモード
	 * @param newMode 新しいモード
	 * @param row 行データ
	 */
	public onCheckListMultiMode(curMode: WprMultiMode, newMode: WprMultiMode, row: RelocateServiceListModel): void {
		if (row.location === 'その他')
			newMode.addMode('フリー入力表示');
		else 
			newMode.addMode('フリー入力非表示');
	}
	// --------------------------------------------------------------------------
}
