import React, { ReactNode } from 'react';
import { WprBaseViewComponent } from '../../../../../wpr-framework/view/component/WprBaseViewComponent';
import { AddTvopConfirmView } from './AddTvopConfirmView';
import { Typography, Grid, Divider } from '@material-ui/core';
import { MatButton } from '../../../../../wpr-framework/component/controls/material/MatButton';
import { MatTypography } from '../../../../../wpr-framework/component/controls/material/MatTypography';
import { ProgressBar } from '../../../common/progress_bar/ProgressBar';
import tvIcon from '/shared/image/common/logo_iTSCOMTV.png';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import importantIcon from '/shared/image/common/important_purple.png';
import styles from './AddTvopConfirm.scss';
import { WprContainer } from '../../../../../wpr-framework/component/controls/WprContainer';

/**
 * テレビオプション申込確認用コンポーネント
 */
export class AddTvopConfirm extends WprBaseViewComponent {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: any) { super(new AddTvopConfirmView(), props); }
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** テレビオプション申込確認ビュー情報 */
	public get viewInfo(): AddTvopConfirmView	{ return this.view as AddTvopConfirmView;	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * スタイルオブジェクト取得
	*/
	public getStylesObject(): any { return styles; }

	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		return (
			<>
			<div>
				<div className='pageCard'>
					<div className="pageHeader stepFlow">
						<ProgressBar name='progressBar' view={this.view} />
					</div>
					<div className='sub-logo'>
						<img src= {tvIcon}></img>
					</div>
					<div className='main'>
						<div className='pageTitle'>
							<div className='title_left'>
								<MatTypography name='title' className='title_text' view={this.view}/>
								<Typography className='title_comment'>以下のチャンネルのお申込みでよろしければ、「申込む」ボタンをクリックしてください。</Typography>
							</div>
						</div>
						<div className='feeTable' style={{marginTop: '30px'}}>	
							<Grid container className='feeRow'>
								<Grid item xs={12} md={4} className='feeTitle bold' style={{padding: '15px 5px'}}>
									<MatTypography name='option' className='itemUnit' view={this.view} style={{display: 'inline'}}/>
									<MatTypography name='channel' className='itemUnit' view={this.view} style={{display: 'inline'}}/>
								</Grid>
								<Grid item xs={12} md={8} className='feeVal courseValue' style={{padding: '15px 10px',margin: 'auto 0'}}>
									<span className='feeUnit'>月額：</span>
									<MatTypography name='amount' className='itemUnit' view={this.view}/>
									<span className='feeUnit'>円（税込）</span>
								</Grid>
							</Grid>	
							<Grid container className='feeRow'>
								<Grid item xs={12} md={4} className='feeTitle bold' style={{padding: '15px 5px'}}>
									<Typography>契約開始日</Typography>
								</Grid>
								<Grid item xs={12} md={8} className='feeVal courseValue' style={{padding: '15px 10px'}}>
									<MatTypography name='applyDate' className='itemUnit' view={this.view}/>
								</Grid>
							</Grid>
						</div>
						<WprContainer mode={'宝塚.表示'} view={this.view}>
							<div className='title_space'>
								<div className='title_left'>
									<Typography className='title_comment'>■「タカラヅカ・スカイ・ステージ」スタートキャンペーン■<br/>8/1～8/31の期間中にタカラヅカ・スカイ・ステージにご契約いただくと、1ヵ月分の利用料金で最大2ヵ月間視聴可能となるキャンペーンを実施しています。</Typography>
									<Typography className='title_comment'>
										※キャンペーン運用期間終了後は、タカラヅカ・スカイ・ステージ月額利用料金（2,970円／税込）にて自動継続となります。<br/>
										※本キャンペーンは、視聴開始月＋1ヵ月間のご利用を条件としております。<br/>
										※原則、キャンペーン期間中の解約はできません。万一、途中解約をされる場合においても、1ヵ月分のご利用料金をお支払いいただきます。
									</Typography>
								</div>
							</div>
						</WprContainer>
						<div className='gray'>
							<div className='flex'>
								<img src= {importantIcon} style={{width: '15px'}}></img>
								<Typography className='title'>ご請求について</Typography> 
							</div>
							<div>
								スターチャンネルは視聴開始の翌月より請求が発生します。<br/>
								その他テレビオプションは視聴開始の当月分より請求が発生します。
							</div>
						</div>
					</div>
					<Divider />
					<div className='pageFooter footer-space'>
						<div>
							<MatButton name='next' view={this.view} className='orangeButton orangeButton_parts' >
								この内容で申込む
								<NavigateNextIcon />
							</MatButton>
						</div>
						<div>
							<MatButton name='back' view={this.view} className='backButton' >
								<NavigateBeforeIcon />
								戻る
							</MatButton>
						</div>
					</div>
				</div>
			</div>
			</>
		);
	}
	// --------------------------------------------------------------------------
}
