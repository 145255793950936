import { AddServiceModel } from "../../apimodels/AddServiceModel";

/**
 * 料金シミュレーション取得リクエスト
 */
export class GetSimulationRequest {
	// public 変数	-----------------------------------------------------------
	public customerId: string;			// 顧客Id
	public addServiceList: AddServiceModel[];	// 追加サービスコードリスト
	public statusCdList: String[];		// ステータスコードリスト
	// ------------------------------------------------------------------------
}
