import React, { ReactNode } from 'react';
import { WprBaseViewComponent } from '../../../../wpr-framework/view/component/WprBaseViewComponent';
import { AddAccesspassCompleteView } from './AddAccesspassCompleteView';
import { Typography, Grid, Divider } from '@material-ui/core';
import { WprAnchor } from '../../../../wpr-framework/component/controls/WprAnchor';
import { MatButton } from '../../../../wpr-framework/component/controls/material/MatButton';
import { MatTypography } from '../../../../wpr-framework/component/controls/material/MatTypography';
import printIcon from '/shared/image/common/icon_print_on.png';
import questionnaireIcon from '/shared/image/common/button_questionnaire_on.png';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import warningIcon from '/shared/image/common/important_yellow.png';
import styles from './AddAccesspassComplete.scss';

/**
 * アクセスアカウントパスワード変更完了画面用コンポーネント
 */
export class AddAccesspassComplete extends WprBaseViewComponent {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: any) { super(new AddAccesspassCompleteView(), props); }
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** アクセスアカウントパスワード変更完了画面ビュー情報 */
	public get viewInfo(): AddAccesspassCompleteView { return this.view as AddAccesspassCompleteView; }
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * スタイルオブジェクト取得
	*/
	public getStylesObject(): any { return styles; }

	
	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		return (
			<>
				<div>
					<div className='pageCard'>
						<div className='main'>
							<div className='title_center'>
								<Typography className='title_complete'>アクセスアカウントのパスワード変更が完了しました。</Typography>
							</div>
							<div className='title_normal'>
								<MatTypography name={'user'} view={this.view} className='title_border' />
							</div>
							<div className='subText' style={{ textAlign: 'center', margin: '2rem' }}>
								以下の内容で変更が完了しました
							</div>
							<div className='gray'>
								<Grid container>
									<Grid item xs={12} md={4} className='grid-print-4' style={{ fontSize: '16px', fontWeight: 'bold' }}>
										<div>コース</div>
									</Grid>
									<Grid item xs={12} md={8} style={{ color: 'black' }} className='grid-print-8'>
										<MatTypography name={'course'} view={this.view} className='itemUnit bold-black'></MatTypography>
									</Grid>
								</Grid>
								<Grid container>
									<Grid item xs={12} md={4} className='grid-print-4' style={{ fontSize: '16px', fontWeight: 'bold' }}>
										<div>アクセスアカウント</div>
									</Grid>
									<Grid item xs={12} md={8} style={{ color: 'black' }} className='grid-print-8'>
										<MatTypography name={'radiusAccount'} view={this.view} className='itemUnit bold-black'></MatTypography>
									</Grid>
								</Grid>
								<Grid container>
									<Grid item xs={12} md={4} className='grid-print-4' style={{ fontSize: '16px', fontWeight: 'bold' }}>
										<div>パスワード</div>
									</Grid>
									<Grid item xs={12} md={8} style={{ color: 'black' }} className='grid-print-8'>
										<Typography style={{ color: '#484848' }}>●●●●●●●●</Typography>
									</Grid>
								</Grid>
								<Grid container>
									<Grid item xs={12} md={4} className='grid-print-4' style={{ fontSize: '16px', fontWeight: 'bold' }}>
										<div>変更日</div>
									</Grid>
									<Grid item xs={12} md={8} style={{ color: 'black' }} className='grid-print-8'>
										<MatTypography name={'date'} view={this.view} className='itemUnit bold-black'></MatTypography>
									</Grid>
								</Grid>
							</div>
							<div className='blueText_underline'>
								<WprAnchor name='print' className='printButton' view={this.view} style={{ display: 'flex' }} >
									<Typography >印刷画面へ</Typography>
									<img src={printIcon} style={{ maxWidth: '20px' }} />
								</WprAnchor>
							</div>
							<div className='warning_msg'>
								<img src={warningIcon} style={{ maxWidth: '100%' }} />
								<Typography className='title_memo'>こちらの画面に表示された内容は、以後確認はできません。印刷する場合は右上の『印刷画面へ』をご利用ください。</Typography>
							</div>
							<div className='questionnaire' style={{ marginTop: '60px' }}>
								<WprAnchor name='toQuestionnaire' view={this.view}  >
									<img src={questionnaireIcon} style={{ maxWidth: '100%' }} />
								</WprAnchor>
							</div>
						</div>
						<Divider />
						<div className='pageFooter footer-space'>
							<div>
								<MatButton name='back' view={this.view} className='completeButton backContInfoList' >
									<NavigateBeforeIcon />
									<span className='screen'>契約内容の確認・変更メニューへ</span>
								</MatButton>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
	// --------------------------------------------------------------------------
}
