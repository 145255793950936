import React, { ReactNode } from 'react';
import { WprBaseViewInfo } from '../../view/WprBaseViewInfo';
import { WprBaseComponent } from '../controls/WprBaseComponent';
import { Switch, Route } from 'react-router-dom';

/**
 * RouterSwitchコントロールプロパティ情報
 */
interface IWprSwitchProps {
	view: WprBaseViewInfo;		// ビュー情報
}

/**
 * RouterSwitchコントロールステータス情報
 */
interface IWprSwithState {
	check: boolean;				// Router変更チェックフラグ
	pathList: string[];			// Routerパスリスト
}

/**
 * RouterSwitchコントロール
 */
export class WprSwitch extends WprBaseComponent<IWprSwitchProps, IWprSwithState> {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: IWprSwitchProps) {
		super(props);
		this.state = {
			check: true,
			pathList: null
		};
	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * マウント通知
	 */
	public onDidMount() {
		this.props.view.setSwitchComponet(this);
	}

	/**
	 * コントロール名称取得
	 * @returns コントロール名称
	 */
	public getName(): string {
		return 'WprSwith';
	}

	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		return (
			<Switch>
				{this.props.children}
			</Switch>
		);
	}
	// --------------------------------------------------------------------------
}