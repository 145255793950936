import React, { ReactNode } from 'react';
import { WprBaseViewComponent } from '../../../../wpr-framework/view/component/WprBaseViewComponent';
import { MagazineConfirmView } from './MagazineConfirmView';
import { Typography, Grid, Divider } from '@material-ui/core';
import { MatButton } from '../../../../wpr-framework/component/controls/material/MatButton';
import { ProgressBar } from '../../common/progress_bar/ProgressBar';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { WprSpan } from '../../../../wpr-framework/component/controls/WprSpan';
import { MatTypography } from '../../../../wpr-framework/component/controls/material/MatTypography';
import styles from './MagazineConfirm.scss';
import importantIcon from '/shared/image/common/important_purple.png';
import netIcon from '/shared/image/common/logo_iTSCOMTV.png';
/**
 * マガジン申込画面用コンポーネント
 */
export class MagazineConfirm extends WprBaseViewComponent {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: any) { super(new MagazineConfirmView(), props); }
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** マガジン申込画面ビュー情報 */
	public get viewInfo(): MagazineConfirmView { return this.view as MagazineConfirmView; }
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * スタイルオブジェクト取得
	*/
	public getStylesObject(): any { return styles; }

	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		return (
			<>
				<div className='pageCard'>
					<div className="pageHeader stepFlow">
						<ProgressBar name='progressBar' view={this.view} />
					</div>
					<div className='inContractArea'>
						<div className='sub-logo'>
							<img src={netIcon} alt="purple" style={{ verticalAlign: 'middle' }} />
						</div>
					</div>
					<div className='main'>
						<div className='pageTitle'>
							<div className='title_left'>
								<MatTypography name='title' className='title_text'  view={this.view} />
								<Typography className='title_comment' style={{ fontWeight: '500' }}>以下の内容でよろしければ、「申込む」ボタンをクリックしてください。</Typography>
							</div>
						</div>
						<div className='feeTable'>
							<Grid container className='feeRow'>
								<Grid item xs={12} md={4} className='feeTitle'>
									<div>iTSCOM magazine</div>
								</Grid>
								<Grid item xs={12} md={8} className='feeVal courseValue' style={{ color: 'black' }}>
									<WprSpan name={'amount'} className='itemUnit' view={this.view} style={{ color: '#484848', fontWeight: '550', fontsize: '40spx' }}></WprSpan>
								</Grid>
							</Grid>
						</div>
						<div className='supportMsgArea supportText'>
								<span className='textcolor'>
									<img src= {importantIcon} alt='purple' style={{ verticalAlign: 'middle' }} />
									配送開始時期に関して
								</span>
								<li >お申し込み月の、翌月号より配送開始、課金開始となります。
									<br />
									当月号から配送をご希望の場合は、お客さまセンターまでご連絡ください。
									<br />
								</li>
						</div>
					</div>
					<Divider />
					<div className='pageFooter footer-space'>
						<div>
							<MatButton name='next' className='orangeButton orangeButton_parts' view={this.view}>この内容で申込む
								<NavigateNextIcon />
							</MatButton>
						</div>
						<div>
							<MatButton name='back' view={this.view} className='backButton' >
								<NavigateBeforeIcon />
								戻る
							</MatButton>
						</div>
					</div>
				</div>
			</>
		);
	}
	// --------------------------------------------------------------------------
}
