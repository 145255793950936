import { WprModel } from '../../../../wpr-framework/view/deco/WprModel';
import { WprDI_ScopeModel } from '../../../../wpr-framework/view/di/WprDI_ScopeModel';
import { RelocateBaseModel } from '../../../models/mypage/relocate/RelocateBaseModel';
import { MypageBaseView } from '../../base/MypageBaseView';
import { ProgressBarData } from '../../common/progress_bar/ProgressBarView';
import { MypageWizardMngr } from '../../wizard/models/MypageWizardMngr';
import { WprListData } from '../../../../wpr-framework/view/deco/WprListData';
import { RelocateServiceListModel } from '../../../models/mypage/relocate/RelocateServiceListModel';
import { WprViewMode } from '../../../../wpr-framework/view/deco/WprViewMode';
import { TvListMode } from './mode/TvListMode';
import { NetListMode } from './mode/NetListMode';
import { PhoneListMode } from './mode/PhoneListMode';
import { InputComfirmMode } from './mode/InputComfirmMode';
import { WprValidError } from '../../../../wpr-framework/view/validation/WprValidError';
import { ObjectUtil } from '../../../common/util/ObjectUtil';
import { MeshWifiBaseModel } from '../../../models/mypage/meshWifi/MeshWifiBaseModel';
import { DateUtil } from '../../../common/util/DateUtil';
import { Customer } from '../../../models/bss/customer/Customer';
import { ErrorModel } from '../../../models/mypage/smart/ErrorModel';

/**
 * 移設申込選択2画面
 */
export class RelocateSelect2View extends MypageBaseView {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() { super('RelocateSelect2View'); }
	// --------------------------------------------------------------------------
	// モデル  ------------------------------------------------------------------
	@WprDI_ScopeModel('MypageWizardMngr')
	private m_MypageWizardMngr: MypageWizardMngr			= null;		// 画面ウィザード管理クラス
	@WprModel('RelocateBaseModel')
	private m_RelocateBaseModel: RelocateBaseModel	= null;	// 移設基本モデル
	@WprListData('TvListModel')
	private m_TvListModel: RelocateServiceListModel[]	= null;	// 移設サービス一覧モデル
	@WprListData('NetListModel')
	private m_NetListModel: RelocateServiceListModel[]	= null;	// 移設サービス一覧モデル
	@WprListData('PhoneListModel')
	private m_PhoneListModel: RelocateServiceListModel[]	= null;	// 移設サービス一覧モデル
	@WprListData('ErrorModel')
	private m_ErrorModelList: ErrorModel[]	= null;	// エラーモデル
	// --------------------------------------------------------------------------

	// private変数 --------------------------------------------------------------
	private m_Customer:	Customer							= null;	// 顧客情報
	// --------------------------------------------------------------------------

	// サブビュー/モード  --------------------------------------------------------
	@WprViewMode
	private m_TvListMode = new TvListMode();	// テレビリストモード
	@WprViewMode
	private m_NetListMode = new NetListMode();	// ネットリストモード
	@WprViewMode
	private m_PhoneListMode = new PhoneListMode();	// 電話リストモード
	@WprViewMode
	private m_InputComfirmMode = new InputComfirmMode();	// 入力確認モード
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * コントロール定義設定
	 */
	public onRegisterConfig(): void {
		this.addConfig('desiredDate1', '第一希望', 'RelocateBaseModel', { emptyValue: null, hissu: true, max: this.m_MypageWizardMngr.getMaxReqDate(), min: this.m_MypageWizardMngr.getMinReqDate() });
		this.addConfig('selectTime1', '第一希望　時間帯', 'RelocateBaseModel', { emptyValue: null, hissu: true });
		this.addConfig('desiredDate2', '第二希望', 'RelocateBaseModel', { emptyValue: null, hissu: true, max: this.m_MypageWizardMngr.getMaxReqDate(), min: this.m_MypageWizardMngr.getMinReqDate() });
		this.addConfig('selectTime2', '第二希望　時間帯', 'RelocateBaseModel', { emptyValue: null, hissu: true });
		this.addConfig('desiredDate3', '第三希望', 'RelocateBaseModel', { emptyValue: null, hissu: true, max: this.m_MypageWizardMngr.getMaxReqDate(), min: this.m_MypageWizardMngr.getMinReqDate() });
		this.addConfig('selectTime3', '第三希望　時間帯', 'RelocateBaseModel', { emptyValue: null, hissu: true });
		this.addListConfig('no', '台目', 'TvListModel', { bindName: 'no' });
		this.addListConfig('serviceInfo', 'サービス等 ( 表示用 )', 'TvListModel', { bindName: 'serviceInfo' });
		this.addListConfig('floor', '階数', 'TvListModel', { bindName: 'floor' });
		this.addListConfig('location', '場所', 'TvListModel', { bindName: 'location', hissu: true });
		this.addListConfig('comment', 'フリー入力', 'TvListModel', { bindName: 'comment', maxLength: 100 });
		this.addListConfig('no', '台目', 'NetListModel', { bindName: 'no' });
		this.addListConfig('serviceInfo', 'サービス等 ( 表示用 )', 'NetListModel', { bindName: 'serviceInfo' });
		this.addListConfig('floor', '階数', 'NetListModel', { bindName: 'floor' });
		this.addListConfig('location', '場所', 'NetListModel', { bindName: 'location', hissu: true });
		this.addListConfig('comment', 'フリー入力', 'NetListModel', { bindName: 'comment', maxLength: 100 });
		this.addListConfig('no', '台目', 'PhoneListModel', { bindName: 'no' });
		this.addListConfig('serviceInfo', 'サービス等 ( 表示用 )', 'PhoneListModel', { bindName: 'serviceInfo' });
		this.addListConfig('floor', '階数', 'PhoneListModel', { bindName: 'floor' });
		this.addListConfig('location', '場所', 'PhoneListModel', { bindName: 'location', hissu: true });
		this.addListConfig('comment', 'フリー入力', 'PhoneListModel', { bindName: 'comment', maxLength: 100 });
		this.addConfig('tvPortFlg', 'TV端子確認フラグ', 'RelocateBaseModel');;
		this.addListConfig('errorMessage', 'エラーメッセージ', 'ErrorModel');
		this.addConfig('beforeContact', '前日連絡先', 'RelocateBaseModel', { maxLength: 11, validation: '整数' });
		this.addConfig('sameDayContact', '当日連絡先', 'RelocateBaseModel', { maxLength: 11, validation: '整数' });
		this.addConfig('name', '立ち合い者名', 'RelocateBaseModel', { bindName: 'presentName' });
	}

	/**
	 * アクション登録
	 */
	public onRegisterAction(): void {
		this.addAction('next', this.onNext, true);
		this.addAction('back', this.onBack);
	}

	/**
	 * ビュー表示通知
	 */
	public onShowView(): void {
		this.m_MypageWizardMngr.initView(this, (result) => {
			this.registAccessLog();
			this.setStorageItem('b_url', window.location.href);
			const sendData: ProgressBarData = new ProgressBarData();
			sendData.progressBarList = this.m_MypageWizardMngr.getProgressModelList(this);
			this.sendChildData('fP_ev_progressBarIntital', sendData);
			this.m_RelocateBaseModel = this.m_MypageWizardMngr.getCache().m_RelocateBaseModel;
			this.m_TvListModel = this.m_MypageWizardMngr.getCache().m_RelocateBaseModel.tvList;
			this.m_NetListModel = this.m_MypageWizardMngr.getCache().m_RelocateBaseModel.netList;
			this.m_PhoneListModel = this.m_MypageWizardMngr.getCache().m_RelocateBaseModel.phoneList;
			this.m_Customer = this.m_MypageWizardMngr.getViewInfo().customer;
			if (this.m_TvListModel.length > 0) {
				this.setListData('TvListModel', this.m_TvListModel);
				this.setViewMode('テレビ', '表示');
			}
			if (this.m_NetListModel.length > 0) {
				this.setListData('NetListModel', this.m_NetListModel);
				this.setViewMode('ネット', '表示');
			}
			if (this.m_PhoneListModel.length > 0) {
				this.setListData('PhoneListModel', this.m_PhoneListModel);
				this.setViewMode('電話', '表示');
			}
			const customer = this.m_MypageWizardMngr.getViewInfo().customer;
			if (customer.building[0].buildingTypeCD === '1')
				this.setViewMode('物件', '集合');
			else if (customer.building[0].buildingTypeCD === '3')
				this.setViewMode('物件', '戸建');
			this.refreshView();
		});
	}

	/**
	 * 値変更通知
	 * @param name 名前
	 * @param value 値
	 * @returns falseの場合、変更を取り消す
	 */
	public onChangeValue(name: string, value: any): boolean {
		this.refreshModel();
		this.updateMode();
		return true;
	}

	/**
	 * リスト値変更通知
	 * @param name 名前
	 * @param listName リスト名
	 * @param row 行データ
	 * @param value 値
	 * @returns falseの場合、変更を取り消す
	 */
	public onChangeListValue(name: string, listName: string, row: any, value: any): boolean {
		this.refreshModel();
		this.updateMode();
		return true;
	}

	/**
	 * バリデーションエラーメッセージ通知
	 * @param name コントロール名
	 * @param rowIndex 行数(Listの場合)
	 * @param validatorName バリデーション名
	 * @param msg エラーメッセージ
	 * @returns エラーメッセージ（変更する場合に指定する、メッセージを使用しない場合は、nullを返す）
	 */
	public onInvalidMessage(name: string, rowIndex: number, validatorName: string, msg: string): string {
		if (((name === 'desiredDate1' || name === 'desiredDate2' || name === 'desiredDate3') && validatorName === '最小値')
		|| ((name === 'beforeContact' || name === 'sameDayContact') && (validatorName === '整数')))
			msg = null;
		return msg;
	}

	/**
	 * 入力チェック（独自のValidationを実施する場合に使用する）
	 *   エラー追加例：validError.addError('エラーメッセージ');		// 相関チェックなどのエラー
	 * 				  this.setControlError('control', 'error');		// コントロールのエラー
	 * 		※コントロールの値をチェックする場合は、refreshModelは使用しないでください。
	 * 		　コントロールの値をチェックする場合は、コントロールをDIしてvalueを使用してください。
	 * @param actionName アクション名
	 * @param validError バリデーションエラー情報
	 * @param row 行情報
	 */
	protected onValid(actionName: string, validError: WprValidError, row?: any): void {
		if (actionName == 'next') {
			this.m_ErrorModelList = new Array();
			const desireDateTime: RelocateBaseModel = this.m_RelocateBaseModel;
			const reqTimeHissuErrorFlg1: boolean = !this.isNotEmpty(desireDateTime.desiredDate1, desireDateTime.selectTime1);
			const reqTimeHissuErrorFlg2: boolean = !this.isNotEmpty(desireDateTime.desiredDate2, desireDateTime.selectTime2);
			const reqTimeHissuErrorFlg3: boolean = !this.isNotEmpty(desireDateTime.desiredDate3, desireDateTime.selectTime3);
			if (reqTimeHissuErrorFlg1 || reqTimeHissuErrorFlg2 || reqTimeHissuErrorFlg3) {
				const itemStr: string = this.setErrorReqDateItem([{ flag: reqTimeHissuErrorFlg1, dispVal: '第一希望' }, { flag: reqTimeHissuErrorFlg2, dispVal: '第二希望' }, { flag: reqTimeHissuErrorFlg3, dispVal: '第三希望' },]);
				// validError.addError(itemStr + '：日付、時間帯のいずれかが入力されていません。再度入力してください。', 'sameReqDateError');
				const model = new ErrorModel();
				model.errorMessage = itemStr + '：日付、時間帯のいずれかが入力されていません。再度入力してください。';
				this.m_ErrorModelList.push(model);
			}
			else {
				const selectTime1: string = desireDateTime.desiredDate1 + desireDateTime.selectTime1;
				const selectTime2: string = desireDateTime.desiredDate2 + desireDateTime.selectTime2;
				const selectTime3: string = desireDateTime.desiredDate3 + desireDateTime.selectTime3;
				let reqTimeErrorFlg1: boolean = false;
				let reqTimeErrorFlg2: boolean = false;
				let reqTimeErrorFlg3: boolean = false;
				if (ObjectUtil.isNotNullOrUndefined(desireDateTime.desiredDate1) && ObjectUtil.isNotNullOrUndefined(desireDateTime.desiredDate2) && selectTime1 === selectTime2) reqTimeErrorFlg1 = true;
				if (ObjectUtil.isNotNullOrUndefined(desireDateTime.desiredDate1) && ObjectUtil.isNotNullOrUndefined(desireDateTime.desiredDate3) && selectTime1 === selectTime3) reqTimeErrorFlg2 = true;
				if (ObjectUtil.isNotNullOrUndefined(desireDateTime.desiredDate2) && ObjectUtil.isNotNullOrUndefined(desireDateTime.desiredDate3) && selectTime2 === selectTime3) reqTimeErrorFlg3 = true;

				if (reqTimeErrorFlg1 || reqTimeErrorFlg2 || reqTimeErrorFlg3) {
					let itemStr: string;
					if (reqTimeErrorFlg1 === true && reqTimeErrorFlg3 == true) {
						itemStr = '第一希望と第二希望と第三希望';
					}
					else {
						itemStr = this.setErrorReqDateItem([{ flag: reqTimeErrorFlg1, dispVal: '第一希望と第二希望' }, { flag: reqTimeErrorFlg2, dispVal: '第一希望と第三希望' }, { flag: reqTimeErrorFlg3, dispVal: '第二希望と第三希望' },]);
					}
					// validError.addError(itemStr + '：同じ希望日時が入力されています。別の日時を指定してください。', 'sameReqDateError');
					const model = new ErrorModel();
					model.errorMessage = itemStr + '：同じ希望日時が入力されています。別の日時を指定してください。';
					this.m_ErrorModelList.push(model);
				}
			}
			if (this.m_RelocateBaseModel.desiredDate1 < this.m_MypageWizardMngr.getMinReqDate()) {
				const date = this.m_MypageWizardMngr.convertToDate(this.m_MypageWizardMngr.getMinReqDate());
				// validError.addError('第一希望に' + date + '以降を入力してください', 'sameReqDateError');
				const model = new ErrorModel();
				model.errorMessage = '第一希望に' + date + '以降を入力してください', 'sameReqDateError';
				this.m_ErrorModelList.push(model);
			}
			if (this.m_RelocateBaseModel.desiredDate2 < this.m_MypageWizardMngr.getMinReqDate() ) {
				const date = this.m_MypageWizardMngr.convertToDate(this.m_MypageWizardMngr.getMinReqDate());
				// validError.addError('第二希望に' + date + '以降を入力してください', 'sameReqDateError');
				const model = new ErrorModel();
				model.errorMessage = '第二希望に' + date + '以降を入力してください', 'sameReqDateError';
				this.m_ErrorModelList.push(model);
			}
			if (this.m_RelocateBaseModel.desiredDate3 < this.m_MypageWizardMngr.getMinReqDate()) {
				const date = this.m_MypageWizardMngr.convertToDate(this.m_MypageWizardMngr.getMinReqDate());
				// validError.addError('第三希望に' + date + '以降を入力してください', 'sameReqDateError');
				const model = new ErrorModel();
				model.errorMessage = '第三希望に' + date + '以降を入力してください', 'sameReqDateError';
				this.m_ErrorModelList.push(model);
			}
			if (!this.m_RelocateBaseModel.beforeContact.match(/^[0-9]+$/)) {
				const model = new ErrorModel();
				model.errorMessage = '前日連絡先は半角数字で入力してください。';
				this.m_ErrorModelList.push(model);
			}
			if (!this.m_RelocateBaseModel.sameDayContact.match(/^[0-9]+$/)) {
				const model = new ErrorModel();
				model.errorMessage = '当日連絡先は半角数字で入力してください。';
				this.m_ErrorModelList.push(model);
			}
		}
		if (this.m_ErrorModelList.length !== 0) {
			this.setViewMode('エラー','表示')
			this.setListData('ErrorModel', this.m_ErrorModelList);
			validError.addError('エラー');	
		}
	}

	/**
	 * エラー
	 */
	private setErrorReqDateItem(list: { flag: boolean, dispVal: string }[]): string {
		let itemList: string[] = new Array();
		list.forEach(val => {
			if (val.flag) itemList.push(val.dispVal);
		});
		let itemStr: string = '';
		for (let item of itemList) {
			if (itemStr != '') itemStr += '、';
			itemStr += item;
		}
		return itemStr;
	}
	private isNotEmpty(reqTime: string, reqDate: string): boolean {
		const valFlg1 = ObjectUtil.isNullOrEmptyStr(reqTime);
		const valFlg2 = ObjectUtil.isNullOrEmptyStr(reqDate);
		return (valFlg1 === valFlg2);
	}
	// --------------------------------------------------------------------------

	// アクション  ---------------------------------------------------------------
	/**
	 * 確認         <NavigateNextIcon />
	 */
	public onNext(param: any): void {
		this.m_MypageWizardMngr.goNext(this);
	}

	/**
	 * <NavigateBeforeIcon />         戻る
	 */
	public onBack(param: any): void {
		this.m_RelocateBaseModel.desiredDate1 = null;
		this.m_RelocateBaseModel.desiredDate2 = null;
		this.m_RelocateBaseModel.desiredDate3 = null;
		this.m_RelocateBaseModel.selectTime1 = null;
		this.m_RelocateBaseModel.selectTime2 = null;
		this.m_RelocateBaseModel.selectTime3 = null;
		this.m_RelocateBaseModel.tvPortFlg = false;
		this.m_RelocateBaseModel.beforeContact = null;
		this.m_RelocateBaseModel.sameDayContact = null;
		this.m_RelocateBaseModel.presentName = null;
		this.m_MypageWizardMngr.goBack(this);
	}
	// --------------------------------------------------------------------------
}
