/**
 * 
 */
export class Equipment {
	// public 変数	-----------------------------------------------------------
    public equipmentTypeCD: string;
    public rentalFlag: string;
    public equipmentKindCD: string;
    public equipmentID: string;
    public equipmentID2: string;
    public equipmentID3: string;
}