import { WprBaseMngr } from '../WprBaseMngr';
import { WprBaseScopeInfo } from './WprBaseScopeInfo';
import { WprErrorMngr } from '../error/WprErrorMngr';
import { WprSettingInfo } from '../WprSettingInfo';
import { WprScopeSetter } from './WprScopeSetter';
import { WprBaseGlobalScopeInfo } from './WprBaseGlobalScopeInfo';
import { WprBaseFrameworkComponent } from '../component/base/WprBaseFrameworkComponent';

/**
 * スコープ管理機能
 */
export class WprScopeMngr extends WprBaseMngr {
	// private 変数  ------------------------------------------------------------
	private m_GlobalScopeInfo: WprBaseGlobalScopeInfo	= null;									// グローバルスコープ情報
	private m_ScopeMap: Map<string, WprBaseScopeInfo>	= new Map<string, WprBaseScopeInfo>();	// スコープ情報マップ
	private m_ErrorMngr: WprErrorMngr					= null;									// エラー管理機能
	private m_GetModelMap: Map<string, object[]>		= new Map<string, object[]>();					// グローバルモデル取得メソッドマップ
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** グローバルスコープ情報 */
	public get globalScopeInfo(): WprBaseGlobalScopeInfo	{ return this.m_GlobalScopeInfo; 	}
	/** スコープ情報マップ */
	public get scopeMap(): Map<string, WprBaseScopeInfo>	{ return this.m_ScopeMap; 			}
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	public constructor() {
		super('スコープ管理機能');
	}
	// --------------------------------------------------------------------------

	// override メソッド	-----------------------------------------------------
	/**
	 * 初期化処理
	 * @param loader データローダ
	 * @param setting 設定情報
	 */
	public initialize(loader: WprBaseFrameworkComponent, setting: WprSettingInfo): void {
		const setter = new WprScopeSetter(this);
		loader.onRegisterScope(setter);
		if (this.m_GlobalScopeInfo != null)
			this.m_GlobalScopeInfo.setScopeMap(this.m_ScopeMap);
		this.m_ScopeMap.forEach((sinfo, key, map) => {
			sinfo.globalScopeInfo = this.m_GlobalScopeInfo;
			sinfo.initialize();
		});
	}

	/**
	 * 他の管理機能設定処理
	 * @param mngrMap 管理機能マップ
	 */
	public setManager(mngrMap: Map<string, WprBaseMngr>): void {
		this.m_ErrorMngr = mngrMap.get('エラー管理機能') as WprErrorMngr;
	}
	// --------------------------------------------------------------------------

	// public メソッド	----------------------------------------------------------
	/**
	 * スコープ情報登録
	 * @param scope スコープ情報
	 */
	public addScope(scope: WprBaseScopeInfo): void {
		if (scope.isGlobal === true) {
			if (this.m_GlobalScopeInfo == null) {
				this.m_GlobalScopeInfo = scope as WprBaseGlobalScopeInfo;
			}
			else {
				this.m_ErrorMngr.addErrorLog('WprLoader', `グローバルスコープ情報を複数登録しようとしています。[${scope.objectName}][${this.m_GlobalScopeInfo.objectName}]`);
				return;
			}
		}
		if (this.m_ScopeMap.has(scope.name) === false)
			this.m_ScopeMap.set(scope.name, scope);
		else
			this.m_ErrorMngr.addErrorLog('WprLoader', `同一のスコープ情報が既に登録されています。[${scope.name}]`);
	}

	/**
	 * グローバルスコープモデル取得
	 * @param name グローバルスコープ名 
	 * @returns グローバルスコープモデル
	 */
	 public getGlobalScopeModel(name: string): object {
		 if (this.m_GlobalScopeInfo != null)
			return this.m_GlobalScopeInfo.getModel(name);
		 else
			this.m_ErrorMngr.addErrorLog('WprScopeMngr', 'グローバルスコープは登録されていません。');
	}

	/**
	 * グローバルスコープモデル取得メソッド登録
	 * @param name グローバルモデル名
	 * @param getModel モデル取得メソッド
	 */
	public addGlobalScopeModelMethod(name: string, getModel: (model: any) => void): void {
		if (this.m_GetModelMap.has(name) === false)
			this.m_GetModelMap.set(name, new Array());
		this.m_GetModelMap.get(name).push(getModel);
	}

	/**
	 * グローバルスコープモデル設定通知
	 * @param name グローバルモデル名
	 * @param model モデル
	 */
	public onSetGlobalScopeModel(name: string, model: any): void {
		if (this.m_GetModelMap.has(name) === true) {
			this.m_GetModelMap.get(name).forEach(obj =>{
				const method = obj as (model: any) => void;
				method(model);
			});
			this.m_GetModelMap.delete(name);
		}
	}
	// --------------------------------------------------------------------------
}
