import { Contract } from "../../bss/contract/Contract";

/**
 * テレビ直接申込判定モデル
 */
export class TvDirectModel {
	// public 変数	-----------------------------------------------------------
	public directFlg: boolean;		// 申込判定
	public otokuFlg: boolean;		// お得パック未契約かつ未希望
	public dummyFlg: boolean;		// ダミー端末フラグ
	public cancelDateFlg: boolean;	// 解約日がない
	public result: string;			// 申込判定結果
	public errorMsg: string;		// エラーメッセージ
	public contract: Contract;		// 契約情報
	// ------------------------------------------------------------------------
}
