import { Card, Divider, Typography } from '@material-ui/core';
import { Grid } from '@mui/material';
import React, { ReactNode } from 'react';
import { WprBaseViewComponent } from '../../../../../wpr-framework/view/component/WprBaseViewComponent';
import { MvnoMnpInquiryView } from './MvnoMnpInquiryView';
import { MatButton } from '../../../../../wpr-framework/component/controls/material/MatButton';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import styles from './MvnoMnpInquiry.scss';
import { MatTextField } from '../../../../../wpr-framework/component/controls/material/MatTextField';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { WprAnchor } from '../../../../../wpr-framework/component/controls/WprAnchor';

/**
 * MVNO_MNP開通問い合わせ用コンポーネント
 */
export class MvnoMnpInquiry extends WprBaseViewComponent {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: any) { super(new MvnoMnpInquiryView(), props); }
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** MVNO_MNP開通問い合わせビュー情報 */
	public get viewInfo(): MvnoMnpInquiryView { return this.view as MvnoMnpInquiryView; }
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * スタイルオブジェクト取得
	*/
	public getStylesObject(): any { return styles; }

	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		return (
			<>
				{/* Milosから追加 START */}



				{/* Milosから追加 END */}
				<div>
					<div className='pageCard'>
						<div className="pageHeader title">
							MNP開通問い合わせ
						</div>
						<Divider />
						<div className='pageContent'>
							<div>
								{/* ご利用者名（漢字） */}
								<div className='customerItem'>
									<div>
										<Typography className='itemTitle'>ご利用者名（漢字）</Typography>
									</div>
									<Grid container spacing={1} textAlign='left'>
										<Grid item xs={12} md={6}>
											<div className='customerInput'>
												<Grid container justifyContent='center' alignItems='center'>
													<Grid item xs={2} md={1} className='customerInputLabel'>
														<span>姓</span>
													</Grid>
													<Grid item xs={10} md={11}>
														<MatTextField name='familyName' placeholder='田中' view={this.view} />
													</Grid>
												</Grid>
											</div>
										</Grid>
										<Grid item xs={12} md={6}>
											<div className='customerInput'>
												<Grid item xs={2} md={1} className='customerInputLabel'>
													<span>名</span>
												</Grid>
												<Grid item xs={10} md={11}>
													<MatTextField name='firstName' placeholder='太郎' view={this.view} />
												</Grid>
											</div>
										</Grid>
									</Grid>
								</div>

								{/* フリガナ */}
								<Divider />
								<div className='customerItem'>
									<div>
										<Typography className='itemTitle'>フリガナ（全角）</Typography>
									</div>
									<Grid container spacing={1} textAlign='left'>
										<Grid item xs={12} md={6}>
											<div className='customerInput'>
												<Grid container>
													<Grid item xs={2} md={1} className='customerInputLabel'>
														<span>セイ</span>
													</Grid>
													<Grid item xs={10} md={11}>
														<MatTextField name='familyNameKana' placeholder='タナカ' view={this.view} />
													</Grid>
												</Grid>
											</div>
										</Grid>
										<Grid item xs={12} md={6}>
											<div className='customerInput'>
												<Grid item xs={2} md={1} className='customerInputLabel'>
													<span>メイ</span>

												</Grid>
												<Grid item xs={10} md={11}>

													<MatTextField name='firstNameKana' placeholder='タロウ' view={this.view} />

												</Grid>
											</div>
										</Grid>
									</Grid>
								</div>

								{/* MNP対象の電話番号 */}
								<Divider />
								<div className='customerItem'>
									<div>
										<Typography className='itemTitle'>MNP対象の電話番号</Typography>
									</div>
									<Grid container spacing={1} textAlign='left'>
										<Grid item xs={12} md={6}>
											<div className='customerInput'>
												<MatTextField name='phoneNumber' placeholder='08012345678' view={this.view} />
											</div>
										</Grid>

									</Grid>
								</div>

								{/* ICCID */}
								<Divider />
								<div className='customerItem'>
									<div>
										<Typography className='itemTitle'>ICCID</Typography>
									</div>
									<div className='link_blue'>
										<WprAnchor name='iccidLink' view={this.view}>ICCIDとは＞</WprAnchor>
									</div>
									<Grid container spacing={1} textAlign='left'>
										<Grid item xs={12} md={6}>
											<div className='customerInput'>
												<MatTextField name='iccId' view={this.view} />
											</div>
											<div className='comments'>※SIMカードの台紙に記載されております。</div>
										</Grid>
									</Grid>
								</div>
							</div>

							<div>
								{/* <div>
									<div>利用者氏名</div>
									<MatTextField name='familyName' view={this.view} />
									<MatTextField name='firstName' view={this.view} />
								</div>
								<div>
									<div>利用者シメイ</div>
									<MatTextField name='familyNameKana' view={this.view} />
									<MatTextField name='firstNameKana' view={this.view} />
								</div> */}

								{/* <div>
									<div>MNP対象の電話番号</div>
									<MatTextField name='phoneNumber' view={this.view} />
								</div>
								<div>
									<div>ICCID</div>
									<MatTextField name='iccId' view={this.view} />
								</div>
							</div> */}
								{/*  */}
							</div>

							<div className='pageFooter'>
								<div>
									<MatButton name='next' view={this.view} className='nextButton' >
										次へ
										<NavigateNextIcon />
									</MatButton>

								</div>
								<div>
									<MatButton name='back' view={this.view} className='backButton' >
										<NavigateBeforeIcon />
										戻る
									</MatButton>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
	// --------------------------------------------------------------------------
}
