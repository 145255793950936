import { ObjectUtil } from "./ObjectUtil";

export class UrlUtil {

	/**
	 * リンク詳細遷移（タブ遷移）
	 * @param path 
	 * @param name 遷移に必要なID名
	 * @param id 遷移に必要なID値
	 * @param name2 ２つ目のID名
	 * @param id2 ２つ目のID値
	 */
	public static openNewTab(path: string, name: string, id: number, name2?: string, id2?:any): void {
		if (id) {
			let url: string = `/management${path}?${name}=${String(id)}`;
			if (ObjectUtil.isNotNullOrUndefined(id2) && ObjectUtil.isNotNullOrUndefined(name2)) {
				url += `&${name2}=${String(id2)}`;
			}
			window.open(url);
		}
	}

	public static getEnvironmentName(): string {
		const uri: URL = new URL(window.location.href);
		if (uri.hostname === 'stg.mypage.itscom.co.jp') {
			return '検証';
		}
		else if (uri.hostname === 'localhost') {
			return 'ローカル';
		}
		else {
			return '本番';
		}
	}
}