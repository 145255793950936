import { WizardModel } from '../../models/WizardModel';
import { MypageWizardFlow } from './MypageWizardFlow';
import { VideoUnlimitedComfirmView } from '../videoService/video_unlimited_comfirm/VideoUnlimitedComfirmView';
import { MDisplay } from '../../service/models/entity/secondary/MDisplay';
import { CommonCompleteView } from '../common/common_complete/CommonCompleteView';
import { GetViewInfoRequest } from '../../service/models/dto/mypagerenewal/GetViewInfoRequest';
import { MypageBaseView } from '../base/MypageBaseView';
import { CommonAgreeView } from '../common/common_agree/CommonAgreeView';
import { MGeneral } from '../../service/models/entity/MGeneral';
import { MCommon } from '../../service/models/entity/primary/MCommon';
import MypageDispdivcdConstants from '../../common/MypageDispdivcdConstants';
import MypageCodeTypeConstants from '../../common/MypageCodeTypeConstants';
import { MypageWizardMngr } from './models/MypageWizardMngr';
import { AddTvComfirmView } from '../tv/new/add_tv_comfirm/AddTvComfirmView';
import { AddTvSelect1View } from '../tv/new/add_tv_select1/AddTvSelect1View';
import { AddTvSelect2View } from '../tv/new/add_tv_select2/AddTvSelect2View';
import { ContractServiceModel } from '../../service/models/apimodels/ContractServiceModel';
import { TvCourseModel } from '../../models/mypage/tv/TvCourseModel';
import { TvBaseModel } from '../../models/mypage/tv/TvBaseModel';
import { TvOptionListModel } from '../../models/mypage/tv/TvOptionListModel';
import { ObjectUtil } from '../../common/util/ObjectUtil';
import { TvCourseListModel } from '../../models/mypage/tv/TvCourseListModel';
import { ViewInfoModel } from '../../service/models/apimodels/ViewInfoModel';
import { GetTvInfoRequest } from '../../service/models/dto/mypagerenewal/GetTvInfoRequest';
import { ItscomTvService } from '../../service/ItscomTvService';
import { RegistInfoRequest } from '../../service/models/dto/mypagerenewal/RegistInfoRequest';
import { TvWizardModel } from '../../models/mypage/tv/TvWizardModel';
import { InquiryMailInfo } from '../../service/models/apimodels/InquiryMailInfo';
import { Contract } from '../../models/bss/contract/Contract';
import { TanakoModel } from '../../service/models/apimodels/TanakoModel';
import { GetTvInfoResponse } from '../../service/models/dto/mypagerenewal/GetTvInfoResponse';
import { TvSelectSendModel } from '../../models/mypage/tv/TvSelectSendModel';
import { CustomerSupportView } from '../common/customer_support/CustomerSupportView';
import { MAIL_CONSTANTS } from '../../common/MyPageMailIdConstants';
import MypageYakkanConstants from '../../common/MypageYakkanConstants';

/**
 * 動画配信サービス_見放題パックプライム_ウィザードクラス
 */
export class TvNewWizardFlow extends MypageWizardFlow {
	// private 変数  ------------------------------------------------------------
	private m_DefaultWizardFlow: WizardModel[]			= null;		// 画面ウィザードリスト
	private m_StartViewName: string						= null;
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	public constructor() {
		super();
		this.m_StartViewName = new AddTvSelect1View().name;

		// 遷移パターン１
		this.m_DefaultWizardFlow = new Array();
		this.m_DefaultWizardFlow.push({stepNum: 0, title: '契約選択', viewName: null, path: this.getOldUrl() + 'Srvlist/Continfo_list/'});
		this.m_DefaultWizardFlow.push({stepNum: 1, title: 'コース選択', viewName: new AddTvSelect1View().name, path: 'add_tv_select1'});
		this.m_DefaultWizardFlow.push({stepNum: 2, title: 'コース内容の選択', viewName: new AddTvSelect2View().name, path: 'add_tv_select2'});
		this.m_DefaultWizardFlow.push({stepNum: 3, title: '申込み内容の確認', viewName: new AddTvComfirmView().name, path: 'add_tv_comfirm'});
		this.m_DefaultWizardFlow.push({stepNum: 4, title: '契約約款', viewName: new CommonAgreeView().name, path: 'add_tv_agree'});
		this.m_DefaultWizardFlow.push({stepNum: 5, title: '申込み完了', viewName: new CommonCompleteView().name, path: 'add_tv_complete'});
	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * 画面ウィザードフロー名取得
	 */
	public getWizardFlowName(viewName: string): string {
		if (viewName === this.m_StartViewName)
			return this.constructor.name;
		return null;
	}
	
	/**
	 * 画面ウィザードリスト取得：契約状態で切り替えたり、選択肢で切り替えたり
	 */
	public getWizardFlow(mngr: MypageWizardMngr): WizardModel[] {
		return this.m_DefaultWizardFlow;
	}
	
	/**
	 * 画面ウィザードAPI用モデル取得：契約状態で切り替えたり、選択肢で切り替えたり
	 */
	public getWizardApiModel(mngr: MypageWizardMngr, view: MypageBaseView): GetViewInfoRequest {
		const req = new GetViewInfoRequest();
		req.customerId = mngr.getParams().customerId;
		req.displayList = new Array();
		req.displayList.push(new MDisplay(MypageDispdivcdConstants.TV_MAIJU_SERVICE, null));
		req.displayList.push(new MDisplay(MypageDispdivcdConstants.TV_FTTH_SERVICE, null));
		req.displayList.push(new MDisplay(MypageDispdivcdConstants.TV_OTHER_SERVICE, null));
		req.displayList.push(new MDisplay(MypageDispdivcdConstants.TV_TANAKO_SERVICE, null));
		req.displayList.push(new MDisplay(MypageDispdivcdConstants.TV_SERVICE, null));
		req.displayList.push(new MDisplay(MypageDispdivcdConstants.TV_OPTION, null));
		req.displayList.push(new MDisplay(MypageDispdivcdConstants.ITSCOMHOME_OTOKUNAME, null));
		req.displayList.push(new MDisplay(MypageDispdivcdConstants.TV_AP_SERVICE, null));
		req.displayList.push(new MDisplay(MypageDispdivcdConstants.TOKOTON_SUPPORT_COURSE_NAME));
		req.displayList.push(new MDisplay(MypageDispdivcdConstants.PAYMENT_DISPNAME));
		req.commonList = new Array();
		req.commonList.push(new MCommon('tvコース名毎充', 'tv_service', 'new_plan', null, null));
		req.commonList.push(new MCommon('tvコース名FTTH', 'tv_service', 'ftth', null, null));
		req.commonList.push(new MCommon('tvコース名HFC', 'tv_service', 'hfc', null, null));
		req.commonList.push(new MCommon('tv追加表示対象', 'tv_service', 'disp_add', null, null));
		req.commonList.push(new MCommon('まいにち充実プラン', 'otoku', 'new_plan_cd', null, null));
		req.commonList.push(new MCommon('お得プラン', 'otoku', 'plan_cd', null, null));
		req.commonList.push(new MCommon('コース名1', 'hanyo1', null, null, null));
		req.commonList.push(new MCommon('コース名2', 'hanyo2', null, null, null));
		req.commonList.push(new MCommon('コース名3', 'hanyo3', null, null, null));
		req.commonList.push(new MCommon('コース名4', 'hanyo4', null, null, null));
		req.commonList.push(new MCommon('コース名5', 'hanyo5', null, null, null));
		req.commonList.push(new MCommon('新コース名1', 'new_plan_hanyo', 'hanyo1', null, null));
		req.commonList.push(new MCommon('新コース名2', 'new_plan_hanyo', 'hanyo2', null, null));
		req.commonList.push(new MCommon('新コース名3', 'new_plan_hanyo', 'hanyo3', null, null));
		req.commonList.push(new MCommon('警告表示対象', 'srvtv', 'prt_course_msg', null, null));
		req.generalList = new Array();
		req.generalList.push(new MGeneral(MypageCodeTypeConstants.INTRODUCTION_CODE, null));
		req.contractFlg = true;
		req.ownerFlg = true;
		req.paymentFlg = true;
		req.serviceKey = new Array();
		const target: ContractServiceModel = new ContractServiceModel();
		target.serviceCd = MypageDispdivcdConstants.TV_MAIJU_SERVICE;
		req.serviceKey.push(target);
		const target2: ContractServiceModel = new ContractServiceModel();
		target2.serviceCd = MypageDispdivcdConstants.TV_FTTH_SERVICE;
		req.serviceKey.push(target2);
		const target3: ContractServiceModel = new ContractServiceModel();
		target3.serviceCd = MypageDispdivcdConstants.TV_OTHER_SERVICE;
		req.serviceKey.push(target3);
		const target4: ContractServiceModel = new ContractServiceModel();
		target4.serviceCd = MypageDispdivcdConstants.TV_TANAKO_SERVICE;
		req.serviceKey.push(target4);
		const target5: ContractServiceModel = new ContractServiceModel();
		target5.serviceCd = MypageDispdivcdConstants.TV_AP_SERVICE;
		req.serviceKey.push(target5);
		req.masterFlg = true;
		req.buildingFlg = true;
		req.serviceLineFlg = true;
		//約款
		req.yakkanList = new Array();
		req.yakkanList.push(new MGeneral(MypageCodeTypeConstants.CONTRACT_AGREE, MypageYakkanConstants.ITSCOM_SERVICE_IMPORTANT));
		req.yakkanList.push(new MGeneral(MypageCodeTypeConstants.CONTRACT_AGREE, MypageYakkanConstants.ITSCOM_SERVICE));
		if (mngr.getCache()) {
			if (mngr.getCache().m_TvBaseModel.type === MypageDispdivcdConstants.TV_MAIJU_SERVICE || mngr.getCache().m_TvBaseModel.type === MypageDispdivcdConstants.TV_FTTH_SERVICE) {
				req.yakkanList.push(new MGeneral(MypageCodeTypeConstants.CONTRACT_AGREE, MypageYakkanConstants.ITSCOM_HIKARI_TV));
				req.yakkanList.push(new MGeneral(MypageCodeTypeConstants.CONTRACT_AGREE, MypageYakkanConstants.ITSCOM_UNLIMITED));
			}
			else if (mngr.getViewInfo() && mngr.getViewInfo().customer.building[0].buildingLineTypeCD  === '2') {
				req.yakkanList.push(new MGeneral(MypageCodeTypeConstants.CONTRACT_AGREE, MypageYakkanConstants.ITSCOM_HIKARI_TV));
				req.yakkanList.push(new MGeneral(MypageCodeTypeConstants.CONTRACT_AGREE, MypageYakkanConstants.ITSCOM_UNLIMITED));
				req.yakkanList.push(new MGeneral(MypageCodeTypeConstants.CONTRACT_AGREE, MypageYakkanConstants.CABLETV_BCAS));
			}
			else {
				req.yakkanList.push(new MGeneral(MypageCodeTypeConstants.CONTRACT_AGREE, MypageYakkanConstants.CABLETV_CATV));
				req.yakkanList.push(new MGeneral(MypageCodeTypeConstants.CONTRACT_AGREE, MypageYakkanConstants.CABLETV_BCAS));
			}
		}
		req.yakkanList.push(new MGeneral(MypageCodeTypeConstants.CONTRACT_AGREE, MypageYakkanConstants.ITSCOM_PRICE));
		return req;
	}
	
	/**
	 * 画面ウィザード完了APIモデル取得
	 */
	public getRegistApiModel(mngr: MypageWizardMngr, view: MypageBaseView): RegistInfoRequest{
		const req: RegistInfoRequest = new RegistInfoRequest();
		const cacheData = mngr.getCache();
		const customer = mngr.getViewInfo().customer;
		const mailconstants = MAIL_CONSTANTS.find (mail => {
			return mail.view === 'TV_追加';
		});
		req.customerId = mngr.getParams().customerId;
		req.customerMailId = mailconstants.customerMail;
		req.systemMailId = mailconstants.systemMail;
		req.inquiryId = mailconstants.inquiryMail;
		req.systemInfo = new InquiryMailInfo();
		req.systemInfo.appService = mngr.getCache().m_TvBaseModel.course;
		// お得パック状況
		if (cacheData.m_TvBaseModel.otokuName) {
			req.systemInfo.otokuStatus = '契約中';
		}
		else if (!cacheData.m_TvBaseModel.otokuFlag) {
			if (cacheData.m_TvBaseModel.otokuApply) {
				req.systemInfo.otokuStatus = '未契約(お得パック希望する)';
			}
			else if (cacheData.m_TvBaseModel.otokuUnapply) {
				req.systemInfo.otokuStatus = '未契約(お得パック希望しない)';
			}
			else {
				req.systemInfo.otokuStatus = '';
			}
		}
		//レンタル
		req.systemInfo.rental = 'レンタル';
		//オプション
		req.systemInfo.optionRentalList = new Array();
		for (const option of cacheData.m_TvOptionListModel) {
			if (option.select)
				req.systemInfo.optionRentalList.push(option.option);
		}
		//マガジン
		req.systemInfo.magazineApp = cacheData.m_TvBaseModel.magazine;
		//STB
		req.systemInfo.stb = cacheData.m_TvBaseModel.terminal;
		// 工事
		if (cacheData.m_TvBaseModel.constractionFlg) {
			//希望時間
			req.systemInfo.desiredDate1 = cacheData.m_TvBaseModel.desiredDate1.replace(/-/g, '/');
			req.systemInfo.desiredDate2 = cacheData.m_TvBaseModel.desiredDate2.replace(/-/g, '/');
			req.systemInfo.desiredDate3 = cacheData.m_TvBaseModel.desiredDate3.replace(/-/g, '/');
			req.systemInfo.selectTime1 = view.getValueMap('時間帯').getValueFormKey(cacheData.m_TvBaseModel.selectTime1);
			req.systemInfo.selectTime2 = view.getValueMap('時間帯').getValueFormKey(cacheData.m_TvBaseModel.selectTime2);
			req.systemInfo.selectTime3 = view.getValueMap('時間帯').getValueFormKey(cacheData.m_TvBaseModel.selectTime3);
			//希望場所
			let location = '';
			if (customer.building[0].buildingTypeCD === '3')
				location += cacheData.m_TvBaseModel.floor + ' ';
			location += cacheData.m_TvBaseModel.location;
			if (cacheData.m_TvBaseModel.location === 'その他')
				location += ' ' + cacheData.m_TvBaseModel.comment;
			req.systemInfo.location = location;
			//セキュリティ会社
			if (cacheData.m_TvBaseModel.securityFlg)
				req.systemInfo.securityComment = cacheData.m_TvBaseModel.securityComment;
			else
				req.systemInfo.securityComment = 'なし';
			//リフォーム
			if(cacheData.m_TvBaseModel.reformFlg)
				req.systemInfo.reform = 'あり';
			else
				req.systemInfo.reform = 'なし';
			//工事費
			if (customer.building[0].buildingTypeCD === '3')
				req.systemInfo.constructionCost = cacheData.m_TvBaseModel.constrAmount;
			else {
				if (cacheData.m_TvBaseModel.payUnit)
					req.systemInfo.constructionCost = cacheData.m_TvBaseModel.constrAmount + cacheData.m_TvBaseModel.payUnit;
				else
					req.systemInfo.constructionCost = cacheData.m_TvBaseModel.constrAmount;
			}
			//連絡先等
			req.systemInfo.beforeContact = cacheData.m_TvBaseModel.beforeContact;
			req.systemInfo.sameDayContact = cacheData.m_TvBaseModel.sameDayContact;
			req.systemInfo.witness = cacheData.m_TvBaseModel.presentName;
		}
		// キャンペーン
		if (cacheData.m_TvBaseModel.campaignCode) {
			req.systemInfo.campaignFlg = true;
			req.systemInfo.campaignGroupName = cacheData.m_TvBaseModel.campaignGroup.campaignGroupName;
			req.systemInfo.campaignList = cacheData.m_TvBaseModel.campaignList;
		}
		// 支払い方法
		const payment = mngr.getMDisplayList(view, MypageDispdivcdConstants.PAYMENT_DISPNAME, '').filter(data => data.namecd === mngr.getViewInfo().customerPayment[0].payment[0].paymentTypeCD);
		if (payment && payment.length > 0)
			req.systemInfo.paymentDispname = payment[0].dispnamep;
		else
			req.systemInfo.paymentDispname = '';
		return req;
	}

	/**
	 * 画面ウィザードフローの中断判定：例：申し込みが完了していれば、完了済みの画面へ遷移
	 */
	public checkFlowBreak(mngr: MypageWizardMngr, view: MypageBaseView): string {
		const viewCode = '001001';
		if (view.m_Name === 'AddTvSelect1View') {
			//トリプルの場合：追加判定
			const customer = mngr.getViewInfo().customer;
			const building = mngr.getViewInfo().buildingList[0];
			//戸建賃貸の場合:002
			if (customer.building[0].buildingTypeCD === '2') {
				return '/customer_support?no=002' + viewCode;
			}
			//2軸物件の対象の場合:003
			else if (building.ftthTokkiMethod === '3' && building.bunkatuMotoTatemonoNum) {
				return '/customer_support?no=003' + viewCode;
			}
			//回線種別：その他の場合:004
			else if (customer.building[0].buildingLineTypeCD === '3') {
				return '/customer_support?no=004' + viewCode;
			}
			//マイグレ
			else if (mngr.isMigration(view)) {
				return '/customer_support?no=007' + viewCode;
			}
		}
		else if (view.m_Name === 'AddTvComfirmView') {
			//設置場所：006
			const location = /応接室|職場|休憩室|社長室/;
			if (location.test(mngr.getCache().m_TvBaseModel.location)) {
				view.setStorageItem('c_customerSupportCache', mngr.getCache().m_TvBaseModel.location);
				return '/customer_support?no=006' + viewCode;
			}
		}
		return null;
	}

	/**
	 * 画面キャッシュの初期化データ取得
	 */
	public getInitCacheData(mngr: MypageWizardMngr): TvNewWizardModel {
		let cacheData = new TvNewWizardModel();
		cacheData.m_TvCourseModel = new Array();
		cacheData.m_TvBaseModel = new TvBaseModel();
		cacheData.m_TvOptionListModel = new Array();
		return cacheData;
	}
	// --------------------------------------------------------------------------
}
/**
 * 画面ウィザードAPI用モデル
 */
export class TvNewWizardModel {
	// public 変数	-----------------------------------------------------------
	public m_TvCourseModel: TvCourseModel[]					= null; 	// tvコースモデル
	public m_TvBaseModel: TvBaseModel						= null; 	// tv基本情報モデル
	public m_TvOptionListModel: TvOptionListModel[]			= null; 	// tvオプションモデル
	public m_BeforeTvBaseModel: TvBaseModel					= null; 	// tv基本情報モデル
	public m_BeforeTvOptionListModel: TvOptionListModel[]	= null; 	// tvオプションモデル
	public m_TvWizardModel: TvWizardModel					= null; 	// tvウィザードモデル
	public m_Contract: Contract								= null; 	// 契約情報
	// サービス  ----------------------------------------------------------------
	private m_ItscomTvService: ItscomTvService	= new ItscomTvService();	// ItscomTvサービス
	// --------------------------------------------------------------------------

	// public メソッド  --------------------------------------------------------
	/**
	 * 表示情報取得
	 */
	public getTvViewInfo(view: MypageBaseView,　viewInfo: ViewInfoModel, befTerminal: string, befServiceCd: string, resMethod: (result: TvSelectSendModel) => void): void {
		const req: GetTvInfoRequest = new GetTvInfoRequest();
		req.customerId = view.getCustomerId();
		req.ownerList = viewInfo.ownerContractList
		req.ftthFlg = viewInfo.customer.building[0].buildingLineTypeCD;
		req.buildingTypeCD = viewInfo.customer.building[0].buildingTypeCD;
		req.buildingLineTypeCD = viewInfo.customer.building[0].buildingLineTypeCD;
		if (befTerminal)
			req.terminal = befTerminal;
		this.m_ItscomTvService.getOwnerList(view, req, (result) => {
			let serviceList = new Array();					//サービス一覧
			let displayServiceList = new Array();	//表示用サービス一覧
			let type = null;	//区分
			let tanakoFlg = false;
			//まいにち充実プラン
			for (const plan of viewInfo.contractModel.planList) {
				for (const service of view.mngr.getMCommonList(view, 'otoku', 'new_plan_cd', null, null)) {
					if (plan.planCD === service.commonValue) {
						type = MypageDispdivcdConstants.TV_MAIJU_SERVICE;
						displayServiceList = displayServiceList.concat(view.mngr.getMCommonList(view, 'tv_service', 'new_plan', null, null));
						//追加の場合CD0100
						if (!befServiceCd)
							serviceList = view.mngr.getMDisplayList(view, MypageDispdivcdConstants.TV_MAIJU_SERVICE, '');
						//変更：1台目でない場合CD0100
						else if (befServiceCd !== result.firstService)
							serviceList = view.mngr.getMDisplayList(view, MypageDispdivcdConstants.TV_MAIJU_SERVICE, '');
						//変更：FTTHの場合CD0087
						else if (view.mngr.getViewInfo().customer.building[0].buildingLineTypeCD === '2')
							serviceList = view.mngr.getMDisplayList(view, MypageDispdivcdConstants.TV_FTTH_SERVICE, '');
						//変更：その他の場合CD0053
						else
							serviceList = view.mngr.getMDisplayList(view, MypageDispdivcdConstants.TV_OTHER_SERVICE, '');
					}
				}
			}
			//serviceCombinationから取得する場合
			if (serviceList.length === 0) {
				if (result.tanakoModel && result.tanakoModel.tanakoList && result.tanakoModel.tanakoList.length > 0) {
					const list = view.mngr.getMDisplayList(view, MypageDispdivcdConstants.TV_TANAKO_SERVICE, '').concat(view.mngr.getMDisplayList(view, MypageDispdivcdConstants.TV_SERVICE, '')).concat(view.mngr.getMDisplayList(view, MypageDispdivcdConstants.TV_AP_SERVICE, ''));
					for (const tanako of result.tanakoModel.tanakoList) {
						const service = list.filter(item => tanako.tanakoServiceCd === item.namecd)[0];
						if (service) {
							service.namecd = tanako.tanakoServiceCd;
							serviceList.push(service);
							tanakoFlg = true;
						}
					}
					if (tanakoFlg) {
						if (viewInfo.customer.building[0].buildingLineTypeCD === '2')
							displayServiceList = displayServiceList.concat(view.mngr.getMCommonList(view, 'tv_service', 'ftth', null, null));
						else
							displayServiceList = displayServiceList.concat(view.mngr.getMCommonList(view, 'tv_service', 'hfc', null, null));
					}
				}
			}
			//機器の種類、金額取得
			let listModel: TvCourseListModel[] = new Array();
			//serviceCombination
			if (tanakoFlg) {
				for (const tanako of result.tanakoModel.tanakoList) {
					const model = new TvCourseListModel();
					const service = result.tanakoModel.serviceList.filter(item => item.serviceCD === tanako.tanakoServiceCd);
					const display = serviceList.filter(item => item.namecd === tanako.tanakoServiceCd);
					model.amount = view.mngr.getTaxFee(view, service[0].payment[0].amount.toString());
					model.normalServiceCd = tanako.normalServiceCd;
					model.detail = display[0];
					model.detail.dispnamep = tanako.serviceDispName;
					model.serviceName = tanako.serviceDispName;
					if (tanako.afterChangeFlg)
						model.terminal = tanako.terminalName;
					else
						model.terminal = '変更なし';
					listModel.push(model);
				}
			}
			//毎日充実プラン
			else {
				for (const data of serviceList) {
					const model = new TvCourseListModel();
					model.amount = this.getAmount(view, data);
					model.detail = data;
					model.terminal = this.getTerminal(view, data);
					model.serviceName = data.dispnamep;
					listModel.push(model);
				}
			}
			const model = new TvSelectSendModel();
			model.mstInfo = listModel;
			model.srvtvCourseName = displayServiceList;
			model.tanako = result;
			model.type = type;
			model.tanakoFlg = tanakoFlg;
			resMethod.bind(view)(model);
		});
	}

	/**
	 * 機器名称取得
	 */
	public getTerminal(view: MypageBaseView, data: MDisplay): string {
		if (data) {
			if (data.genecd === '1')
				return view.mngr.getMCommonList(view, 'hanyo1', null, null, null)[0].commonValue;
			else if (data.genecd === '2')
				return view.mngr.getMCommonList(view, 'hanyo2', null, null, null)[0].commonValue;
			else if (data.genecd === '3')
				return view.mngr.getMCommonList(view, 'hanyo3', null, null, null)[0].commonValue;
			else if (data.genecd === '4')
				return view.mngr.getMCommonList(view, 'hanyo4', null, null, null)[0].commonValue;
			else if (data.genecd === '5')
				return view.mngr.getMCommonList(view, 'hanyo5', null, null, null)[0].commonValue;
		}
		return null;
	}

	/**
	 * 金額取得
	 */
	public getAmount(view: MypageBaseView, data: MDisplay): string {
		let serviceList = view.mngr.getServiceList(view, data.dispdivcd);
		let amount = null;
		for (const service of serviceList) {
			if (service.service.serviceCD === data.namecd)
				amount =  service.service.payment[0].amount.toString();
		}
		return view.mngr.getTaxFee(view, amount);
	}

	/**
	 * メッセージ表示確認1
	 */
	public checkMessage1(view: MypageBaseView, data: TvCourseListModel): string {
		const commonList = view.mngr.getMCommonList(view, 'srvtv', 'prt_course_msg', null, null);
		let message = '';
		for (const common of commonList) {
			if (common.category3.substring(3) === data.normalServiceCd) {
				view.setViewMode('コース警告', '表示');
				return data.detail.dispnamep;
			}
		}
		return message;
	}

	/**
	 * メッセージ表示確認2
	 */
	public checkMessage2(view: MypageBaseView, data: string) {
		const massageList = ['マックスplus', 'スタンダードplus', 'ミニplus'];
		if (massageList.includes(data))
			view.setViewMode('plus警告', '表示');
	}
	// ------------------------------------------------------------------------
}
