import { WprBaseViewModeInfo } from '../../../../../wpr-framework/mode/WprBaseViewMode';
import { WprBaseViewMultiModeInfo } from '../../../../../wpr-framework/mode/WprBaseViewMultiMode';
import { WprMultiMode } from '../../../../../wpr-framework/mode/WprMultiMode';
import { WprControlInfo } from '../../../../../wpr-framework/view/control/WprControlInfo';
import { WprDI_Control } from '../../../../../wpr-framework/view/di/WprDI_Control';
import { NameChangeIdentificationView } from '../NameChangeIdentificationView';

/**
 * 次へボタンモード
 */
export class NextButtonMode extends WprBaseViewMultiModeInfo<NameChangeIdentificationView> {
	// DI情報  ------------------------------------------------------------------
	@WprDI_Control
	private m_confirm: WprControlInfo	= null;	// 確認
	// --------------------------------------------------------------------------
	
	// コンストラクタ  -----------------------------------------------------------
	public constructor() {
		super('NextButtonMode');
	}
	// --------------------------------------------------------------------------
	/**
	 * モードリスト取得（この画面モードで使用する全てのモードを定義する）
	 * @param list モードリスト
	 */
	 public useModeList(list: string[]): void {
		list.push('次へ活性');
		list.push('次へ非活性');
	}
	// override メソッド  --------------------------------------------------------
	/**
	 * フィールド定義を登録処理
	 */
	public onRegisterControl(): void {
		this.addControl('next', {check: true, enabled: [ { mode: '次へ活性', value: true }, { mode: '次へ非活性', value: false } ] });
		this.addControl('confirm', { check: true });
		this.addControl('isCompleteIdentification', { check: true });
	}
	// --------------------------------------------------------------------------
	/**
	 * ビューの状態をチェックしてモードを変更する
	 * @param curMode 現在のモード
	 * @param newMode 新しいモード
	 */
	 public onCheckMultiMode(curMode: WprMultiMode, newMode: WprMultiMode): void {
		const isEnabled: boolean = (this.m_confirm.value) && this.view.isCompleteIdentification;
		if (isEnabled)
			newMode.addMode('次へ活性');
		else
			newMode.addMode('次へ非活性');
	}
	
}
