/**
 * お問い合わせモデル
 */
export class CustomerSupportModel {
	// public 変数	-----------------------------------------------------------
	public msg1: string;	// 文言1
	public msg2: string;	// 文言2
	public type: string;	// 区分
	// ------------------------------------------------------------------------
}
