import { WizardModel } from '../../models/WizardModel';
import { ProgressBarModel } from '../../models/ProgressBarModel';
import { MypageBaseView } from '../base/MypageBaseView';
import { GetViewInfoRequest } from '../../service/models/dto/mypagerenewal/GetViewInfoRequest';
import { MypageWizardMngr } from './models/MypageWizardMngr';
import { Public } from '@material-ui/icons';
import { RegistInfoRequest } from '../../service/models/dto/mypagerenewal/RegistInfoRequest';
import { ViewInfoModel } from '../../service/models/apimodels/ViewInfoModel';
import { MGeneral } from '../../service/models/entity/MGeneral';
import { RegistContractRequest } from '../../service/models/dto/mypagerenewal/RegistContractRequest';

/**
 * マイページウィザードクラス
 */
export class MypageWizardFlow {
	
	/**
	 * クッキー情報取得処理 test
	 */
	protected getOldUrl(): string {
		const host = location.host;
		let url: string;
		if (host.includes("localhost") || host.includes("stg")) {
			url = 'https://stage.mypage.itscom.net/'
		}
		else {
			url = 'https://mypage.itscom.net/'
		}
		return url;
	}

	// public メソッド  ----------------------------------------------------------
	/**
	 * 画面ウィザードフロー名取得
	 */
	public getWizardFlowName(viewName: string): string { return null; }

	/**
	 * 画面ウィザードリスト取得：契約状態で切り替えたり、選択肢で切り替えたり
	 */
	public getWizardFlow(mngr: MypageWizardMngr): WizardModel[] { return null; }

	/**
	 * 画面ウィザードAPI用モデル取得：契約状態で切り替えたり、選択肢で切り替えたり
	 */
	public getWizardApiModel(mngr: MypageWizardMngr, view: MypageBaseView): GetViewInfoRequest { return null; }

	/**
	 * 画面表示情報更新
	 */
	public updateViewInfo(mngr: MypageWizardMngr, view: MypageBaseView): ViewInfoModel {return mngr.getViewInfo()}

	/**
	 * 画面ウィザードフローの中断判定：例：申し込みが完了していれば、完了済みの画面へ遷移
	 */
	public checkFlowBreak(mngr: MypageWizardMngr, view: MypageBaseView): string { return null; }

	/**
	 * 画面キャッシュの初期化データ取得
	 */
	public getInitCacheData(mngr: MypageWizardMngr): any { return null; }

	/**
	 * 画面ウィザード完了APIモデル取得
	 */
	public getRegistApiModel(mngr: MypageWizardMngr, view: MypageBaseView, resultCd?: string): RegistInfoRequest{ return null; }

	/**
	 * 画面ウィザード完了APIモデル取得
	 */
	public getRegistDataApiModel(mngr: MypageWizardMngr, view: MypageBaseView): RegistContractRequest{ return null; }
	// --------------------------------------------------------------------------
}
