import { WprBaseViewListMultiModeInfo } from '../../../../../../wpr-framework/mode/WprBaseViewListMultiModeInfo';
import { WprMultiMode } from '../../../../../../wpr-framework/mode/WprMultiMode';
import { TvOptionListModel } from '../../../../../models/mypage/tv/TvOptionListModel';
import { ModTvComfirmView } from '../ModTvComfirmView';
import { WprDI_Object } from '../../../../../../wpr-framework/view/di/WprDI_Object';

/**
 * AfterOptionMode
 */
export class AfterOptionMode extends WprBaseViewListMultiModeInfo<ModTvComfirmView, TvOptionListModel> {
	// DI情報  ------------------------------------------------------------------
	@WprDI_Object
	private m_AfterTvOptionListModel: TvOptionListModel[]	= null;	// テレビオプションリストモデル ( 画面表示分 )
	// --------------------------------------------------------------------------
	// コンストラクタ  -----------------------------------------------------------
	public constructor() {
		super('AfterTvOptionListModel');
	}
	// --------------------------------------------------------------------------
	
	// override メソッド  --------------------------------------------------------
	/**
	 * モードリスト取得（この画面モードで使用する全てのモードを定義する）
	 * @param list モードリスト
	 */
	public useModeList(list: string[]): void {
		list.push('afterBottomNone');
	}

	/**
	 * フィールド定義を登録処理
	 */
	public onRegisterControl(): void {
		this.addControl('option', { class: [ { mode: 'afterBottomNone', class: 'borderBottom' }, { mode: 'other', class: '' } ] });
	}

	/**
	 * ビューの状態をチェックして行のモードを変更する
	 * @param curMode 現在のモード
	 * @param newMode 新しいモード
	 * @param row 行データ
	 */
	public onCheckListMultiMode(curMode: WprMultiMode, newMode: WprMultiMode, row: TvOptionListModel): void {
		const idx = this.m_AfterTvOptionListModel.length;
		if (this.m_AfterTvOptionListModel[idx-1].option === row.option)
			newMode.addMode('afterBottomNone');
	}
	// --------------------------------------------------------------------------
}
