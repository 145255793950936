// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@charset \"UTF-8\";\n/*\r\n * 着信転送完了用CSS\r\n */\n.CallForwardingComplete-blueArea {\n  background: #F5F5FA;\n  border-radius: 12px;\n  width: 100%;\n  margin-bottom: 30px; }\n  .CallForwardingComplete-blueArea .CallForwardingComplete-header {\n    color: #003894;\n    padding: 10px 0;\n    border-bottom: 1px solid #BBBDDC;\n    text-align: center;\n    font-size: 18px;\n    line-height: 29px;\n    font-weight: 700; }\n  .CallForwardingComplete-blueArea .CallForwardingComplete-body {\n    padding: 36px 55px;\n    display: flex; }\n    @media screen and (max-width: 440px) {\n      .CallForwardingComplete-blueArea .CallForwardingComplete-body {\n        display: inline-block;\n        padding: 10px 15px; } }\n    .CallForwardingComplete-blueArea .CallForwardingComplete-body p, .CallForwardingComplete-blueArea .CallForwardingComplete-body span {\n      font-weight: 700;\n      font-size: 16px;\n      line-height: 26px; }\n      @media screen and (max-width: 440px) {\n        .CallForwardingComplete-blueArea .CallForwardingComplete-body p, .CallForwardingComplete-blueArea .CallForwardingComplete-body span {\n          font-size: 13px; } }\n\n.CallForwardingComplete-comment .CallForwardingComplete-header {\n  border-radius: 12px 12px 0px 0px;\n  background: #6169B8;\n  color: #ffffff;\n  font-weight: 700;\n  font-size: 14px;\n  line-height: 21px;\n  padding: 7px 14px; }\n\n.CallForwardingComplete-comment .CallForwardingComplete-body {\n  border-radius: 0px 0px 12px 12px;\n  border: 2px solid #6169B8;\n  font-weight: 500;\n  font-size: 14px;\n  line-height: 22px; }\n  .CallForwardingComplete-comment .CallForwardingComplete-body p, .CallForwardingComplete-comment .CallForwardingComplete-body ul {\n    margin: 12px 16px;\n    font-weight: 500;\n    font-size: 14px;\n    line-height: 22px; }\n", ""]);
// Exports
exports.locals = {
	"blueArea": "CallForwardingComplete-blueArea",
	"header": "CallForwardingComplete-header",
	"body": "CallForwardingComplete-body",
	"comment": "CallForwardingComplete-comment"
};
module.exports = exports;
