import { WprBaseViewCore } from '../../WprBaseViewCore';

/**
 * コントロールエラーマップ
 */
export class WprControlErrorMap {
	// private 変数  ------------------------------------------------------------
	private m_CurInfo: WprControlErrorList		= null; 		// 現在のエラー情報
	private m_ErrorList: WprControlErrorList[]	= new Array();	// エラー情報リスト
	// --------------------------------------------------------------------------

	// public メソッド  ---------------------------------------------------------
	/**
	 * チェック開始
	 */
	public startCheck(): void {
		this.m_CurInfo = new WprControlErrorList();
	}

	/**
	 * チェック終了
	 */
	public endCheck(): void {
		if (this.m_CurInfo != null && this.m_CurInfo.isError() === true)
			this.m_ErrorList.push(this.m_CurInfo);
		this.m_CurInfo = null;
	}

	/**
	 * エラー追加
	 * @param name エラーコントロール名 
	 */
	public addError(name: string): void {
		if (this.m_CurInfo != null)
			this.m_CurInfo.addError(name);
	}

	/**
	 * コントロールエラークリア処理
	 * @param vinfo ビュー情報
	 * @param isControl コントロールフラグ
	 * @param name コントロール名
	 */
	 public clearControlError(vinfo: WprBaseViewCore, isControl: boolean, name: string): void {
		if (isControl === true) {
			let errorList = this.getErrorNameList(name) as string[];
			if (errorList != null) {
				vinfo.controlMap.forEach((cinfo, key, map) => {
					if (errorList.includes(cinfo.name) === true) {
						cinfo.status.inputError = false;
						cinfo.validError = null;
						cinfo.validValue();
						cinfo.clearErrorMessage();
					}
				});
			}
		}
		else {
			this.clear();
			vinfo.controlMap.forEach((cinfo, key, map) => {
				cinfo.clearErrorMessage();
			});
		}
	}
	// --------------------------------------------------------------------------

	// private メソッド  --------------------------------------------------------
	/**
	 * チェッククリア
	 */
	private clear(): void {
		this.m_ErrorList = new Array();
	}

	/**
	 * エラーコントロール名リスト取得
	 * @param name コントロール名
	 * @returns エラーコントロール名リスト
	 */
	private getErrorNameList(name: string): string[] {
		let error: WprControlErrorList = null;
		for (const einfo of this.m_ErrorList) {
			if (einfo.nameList.includes(name) === true) {
				error = einfo;
				break;
			}
		}
		if (error != null) {
			const idx = this.m_ErrorList.indexOf(error);
			this.m_ErrorList.splice(idx, 1);
			return error.nameList;
		}
		return null;
	}
	// --------------------------------------------------------------------------
}

/**
 * コントロールエラーリスト
 */
class WprControlErrorList {
	// private 変数  ------------------------------------------------------------
	private m_NameList: string[]	= new Array();		// エラー名称リスト
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** エラー名称リスト */
	public get nameList(): string[] 			{ return this.m_NameList;		}
	// --------------------------------------------------------------------------

	// public メソッド  ---------------------------------------------------------
	/**
	 * エラーフラグ取得
	 * @returns エラーフラグ
	 */
	public isError(): boolean {
		return (this.m_NameList.length > 0);
	}

	/**
	 * エラー追加
	 * @param name エラーコントロール名 
	 */
	public addError(name: string): void {
		if (this.m_NameList.includes(name) === false)
			this.m_NameList.push(name);
	}
	// --------------------------------------------------------------------------
}