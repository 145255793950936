import React, { ReactNode } from 'react';
import { WprBaseViewComponent } from '../../../../wpr-framework/view/component/WprBaseViewComponent';
import { RelocateSelect1View } from './RelocateSelect1View';
import { Typography, Grid, TableContainer, Table, TableBody, TableRow, TableCell, Divider, TableHead } from '@material-ui/core';
import { WprContainer } from '../../../../wpr-framework/component/controls/WprContainer';
import { WprSpan } from '../../../../wpr-framework/component/controls/WprSpan';
import { MatButton } from '../../../../wpr-framework/component/controls/material/MatButton';
import { MatTypography } from '../../../../wpr-framework/component/controls/material/MatTypography';
import { ProgressBar } from '../../common/progress_bar/ProgressBar';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { MatCheckBox } from '../../../../wpr-framework/component/controls/material/MatCheckBox';
import { MatTableContainer } from '../../../../wpr-framework/component/controls/material/MatTableContainer';
import { WprRowInfo } from '../../../../wpr-framework/component/props/WprRowInfo';
import styles from './RelocateSelect1.scss';

/**
 * 移設申込選択1画面用コンポーネント
 */
export class RelocateSelect1 extends WprBaseViewComponent {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: any) { super(new RelocateSelect1View(), props); }
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** 移設申込選択1画面ビュー情報 */
	public get viewInfo(): RelocateSelect1View	{ return this.view as RelocateSelect1View;	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * スタイルオブジェクト取得
	*/
	public getStylesObject(): any { return styles; }


	/**
	 * リストコントロール定義登録
	*/
	protected onRegisterListRender(): void {
		this.addListRender('TvListModel', null, this.onTvListModelRender);
		this.addListRender('NetListModel', null, this.onNetListModelRender);
		this.addListRender('PhoneListModel', null, this.onPhoneListModelRender);
	}
	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		return (
			<>
			<div>
				<div className='pageCard'>
					<div className="pageHeader stepFlow">
						<ProgressBar name='progressBar' view={this.view} />
					</div>
					<div className='sub-logo'>
					</div>
					<div className='main card'>
						<div className='pageTitle'>
							<div className='title_left'>
								<MatTypography name='title' className='title_text' view={this.view}/>
							</div>
						</div>
						<WprContainer mode={'テレビ.表示'} view={this.view}>
							<div className='title_space'>
								<div className='itemTitle title_maru'>テレビ</div>
							</div>
							<Grid container>
								<Grid item xs={12}>
									<Table className = 'courseTable'> 
										<colgroup>
											<col style={{width: '20%'}}/>
											<col style={{width: '15%'}}/>
											<col style={{width: '65%'}}/>
										</colgroup>
										<TableHead className='hedderrow'>
											<TableRow className='toptable'>
												<TableCell align='center' className='border_right font_bold'>選択</TableCell>
												<TableCell align='center' className='border_right font_bold'></TableCell>
												<TableCell align='center' className='font_bold'>ご利用中のサービス</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{this.renderList('TvListModel')}
										</TableBody>
									</Table>
								</Grid>
							</Grid>
						</WprContainer>
						<WprContainer mode={'ネット.表示'} view={this.view}>
							<div className='title_space'>
								<div className='itemTitle title_maru'>インターネット</div>
							</div>
							<Grid container>
								<Grid item xs={12}>
									<Table className = 'courseTable'> 
										<colgroup>
											<col style={{width: '20%'}}/>
											<col style={{width: '15%'}}/>
											<col style={{width: '65%'}}/>
										</colgroup>
										<TableHead className='hedderrow'>
											<TableRow className='toptable'>
												<TableCell align='center' className='border_right font_bold'>選択</TableCell>
												<TableCell align='center' className='border_right font_bold'></TableCell>
												<TableCell align='center' className='font_bold'>ご利用中のサービス</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{this.renderList('NetListModel')}
										</TableBody>
									</Table>
								</Grid>
							</Grid>
						</WprContainer>
						<WprContainer mode={'電話.表示'} view={this.view}>
							<div className='title_space'>
								<div className='itemTitle title_maru'>ケーブルプラス電話</div>
							</div>
							<Grid container>
								<Grid item xs={12}>
									<Table className = 'courseTable'> 
										<colgroup>
											<col style={{width: '20%'}}/>
											<col style={{width: '15%'}}/>
											<col style={{width: '65%'}}/>
										</colgroup>
										<TableHead className='hedderrow'>
											<TableRow className='toptable'>
												<TableCell align='center' className='border_right font_bold'>選択</TableCell>
												<TableCell align='center' className='border_right font_bold'></TableCell>
												<TableCell align='center' className='font_bold'>ご利用中のサービス</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{this.renderList('PhoneListModel')}
										</TableBody>
									</Table>
								</Grid>
							</Grid>
						</WprContainer>
						<WprContainer mode={'とことんサポート.表示'} view={this.view}>
							<div className='title_space'>
								<div className='itemTitle title_maru'>とことんサポートのお申込み</div>
									<Typography className='title_comment' style={{marginLeft: '15px'}}>
										イッツコムとことんサポートはイッツコムが提供する様々なサービスのお手伝いを<br/>
										専用フリーダイヤルやリモートサポート、訪問サポートにてご相談いただけるサービスです。<br/>
										<a href='https://www.itscom.co.jp/support/assistance/tokoton/' target = '_blank' style={{textDecoration: 'none'}}>『とことんサポートの詳細はこちら』</a>
									</Typography>
									<Typography className='title_comment' style={{marginLeft: '15px'}}>
										とことんサポートにご加入いただく事で基本作業費￥5500を割引する事が出来ます。<br/>
										ご物件形態やリフォーム状況により工事費はお見積りとなる場合がございます。<br/>
										とことんサポートは月額550円、最低ご利用期間は工事設置日の翌月から6ヶ月となります。
									</Typography>
							</div>
							<div className='title_center'>
								<div className='title_center labelSize'>
									<MatCheckBox name='tokotonAddFlg' label='とことんサポートをお申込み' view={this.view}/>
								</div>
							</div>
						</WprContainer>
						<div className='title_space'>
							<div className='itemTitle title_maru'>リフォームの確認</div>
							<Typography className='title_comment' style={{marginLeft: '15px'}}>当社にて機器を設置して以降、配線・端子の交換、場所移動を伴うリフォームを行いましたか</Typography>
						</div>
						<div className='title_center'>
							<div className='title_center labelSize'>
								<MatCheckBox name='reformFlg' label='リフォームを行った' view={this.view}/>
							</div>
						</div>
						<div className='title_space'>
							<div className='itemTitle title_maru'>注意事項<span className='txtRequired'>*</span></div>
							<Typography className='title_comment' style={{marginLeft: '15px'}}>
								※設置工事1ヵ月以内の端末の移設を希望する場合にはフリーダイヤル(0120-119119)までご連絡をお願いいたします。<br/>
								TV配線・端子の変更や移動があった場合には移設作業が行えない場合がございます。<br/>
								移設希望場所の作業スペースの確保のため、家具などがお近くにある場合には事前に移動いただきますようよろしくお願い致します。
							</Typography>
						</div>
						<div className='title_center'>
							<div className='title_center labelSize'>
								<MatCheckBox name='confirmFlg' label='注意事項の内容を確認しました。' view={this.view}/>
							</div>
						</div>
					</div>
					<Divider />
					<div className='pageFooter footer-space'>
						<div>
							<MatButton name='next' view={this.view} className='orangeButton orangeButton_parts' >
								次へ
								<NavigateNextIcon />
							</MatButton>
						</div>
						<div>
							<MatButton name='back' view={this.view} className='backButton'>
								<NavigateBeforeIcon />
								戻る
							</MatButton>
						</div>
					</div>


				</div>
			</div>
			</>
		);
	}

	// リスト描画  ---------------------------------------------------------------
	/**
	 * リスト名=TvListModel
	 */
	public onTvListModelRender(rowInfo: WprRowInfo): any {
		return (
			<TableRow className='deviceRow body'>
				<TableCell align='center' className='border_right'>
					<MatCheckBox name='select' view={this.view} row={rowInfo}/>
				</TableCell>
				<TableCell align='center' className='border_right' style={{padding: '5px'}}>
					<MatTypography name='no' row={rowInfo} view={this.view} className='noStyle'/>
				</TableCell>
				<TableCell>
					<MatTypography name='serviceInfo' row={rowInfo} view={this.view} style={{ fontSize: '15px'}}/>
				</TableCell>
			</TableRow>
		);
	}
	/**
	 * リスト名=NetListModel
	 */
	public onNetListModelRender(rowInfo: WprRowInfo): any {
		return (
			<TableRow className='deviceRow body'>
				<TableCell align='center' className='border_right'>
					<MatCheckBox name='select' view={this.view} row={rowInfo}/>
				</TableCell>
				<TableCell align='center' className='border_right' style={{padding: '5px'}}>
					<MatTypography name='no' row={rowInfo} view={this.view} className='noStyle'/>
				</TableCell>
				<TableCell>
					<MatTypography name='serviceInfo' row={rowInfo} view={this.view} style={{ fontSize: '15px'}}/>
				</TableCell>
			</TableRow>
		);
	}
	/**
	 * リスト名=PhoneListModel
	 */
	public onPhoneListModelRender(rowInfo: WprRowInfo): any {
		return (
			<TableRow className='deviceRow body'>
				<TableCell align='center' className='border_right'>
					<MatCheckBox name='select' view={this.view} row={rowInfo}/>
				</TableCell>
				<TableCell align='center' className='border_right' style={{padding: '5px'}}>
					<MatTypography name='no' row={rowInfo} view={this.view} className='noStyle'/>
				</TableCell>
				<TableCell>
					<MatTypography name='serviceInfo' row={rowInfo} view={this.view} style={{ fontSize: '15px'}}/>
				</TableCell>
			</TableRow>
		);
	}
	// --------------------------------------------------------------------------
}
