import { WorkScheduleList } from "../../iss/getMypWorkScheduleList/WorkScheduleList";
import { WorkPossibleList } from "../../iss/getVacantMypWorkSchedule/WorkPossibleList";
import { ConstrDateListModel } from "./ConstrDateListModel";
import { DayOfWeekModel } from "./DayOfWeekModel";


/**
 * テレビ基本情報モデル
 */
export class ConstrBaseModel {
	// public 変数	-----------------------------------------------------------
	public workScheduleList: ConstrDateListModel;		// 工事予定詳細
	public confirmFlg: boolean;							// 確認フラグ
	public searchFlg: boolean;							// 検索フラグ
	public beforeContact: string;						// 前日連絡先
	public sameDayContact: string;						// 当日連絡先
	public name: string;								// 立ち合い者名
	public searchDate: string;							// 検索条件(日付)
	public searchDateTime: string;						// 検索条件(時間key)
	public searchDayOfWeek: DayOfWeekModel;				// 検索条件(曜日)
	public selectDateList: ConstrDateListModel[];		// 工事可能日時
	public workPossibleList: ConstrDateListModel;		// 工事希望詳細
	// ------------------------------------------------------------------------
}
