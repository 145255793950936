import { WprFramework } from '../../../wpr-framework/WprFramework';
import { WprBaseAxiosService } from '../../../wpr-framework/service/WprBaseAxiosService';
import { WprHttpErrorInfo } from '../../../wpr-framework/service/WprHttpErrorInfo';
import { WprServiceError } from '../../../wpr-framework/service/WprServiceError';
import { WprServiceErrorInfo } from '../../../wpr-framework/service/WprServiceErrorInfo';
import { WprBaseViewCore } from '../../../wpr-framework/view/WprBaseViewCore';
import MypageErrorConstants from '../../common/MypageErrorConstants';

/**
 * ベースサービス
 */
export abstract class BaseService extends WprBaseAxiosService {
	// 定数  --------------------------------------------------------------------
	/** Result Status */
	public static readonly SERVICE_SUCCESS_STATUS: string = '0000';
	public static readonly SERVICE_ERROR_STATUS: string = '9000';
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * BaseURL取得
	 * @returns BaseURL
	 */
	protected getBaseURL(): string {
		const uri: URL = new URL(window.location.href);
		return `${uri.origin}/MyPageRenewal`;
	}

	/**
	 * 結果チェック
	 * @param res 結果情報
	 * @returns nullの場合、正常、エラーの場合は、WprServiceErrorを返す
	 */
	protected checkResult(res: any): WprServiceErrorInfo {
		if (BaseService.SERVICE_SUCCESS_STATUS !== res.status)
			return new WprServiceErrorInfo(res.status, res.message);
		return null;
	}
	/**
	 * Post通信処理
	 * @param viewCore ビュー情報
	 * @param url URL
	 * @param request リクエスト情報 
	 * @param resMethod 結果処理メソッド
	 */
	public doPost(viewCore: WprBaseViewCore, url: string, request: any, resMethod: (result: any) => void): void {
		const status = WprFramework.getInstance().startWait();
		this.addInfoLog(`サービス(doPost)：開始[${url}][${viewCore.objectName}]`);
		viewCore.clearServiceError();
		viewCore.startStateLock();
		this.executePost(url, request).then((result) => {
			if (result['status']) {
				const status = result['status'] as string;
				if (status) {
					if (status.replace(/0/g, '') !== '') {
						let message = result['message'] as string;
						this.addErrorLog(`サービス(doPost)：エラー[${url}][${status}][${message}][${viewCore.objectName}]`);
						viewCore.onServiceError(url, status, message);
						// viewCore.onServiceErrorResult(url, status, message, resMethod);
						if (status === '9999')
							viewCore.pushPath('/error?errorCd=' + MypageErrorConstants.API_ERROR);
						else if (status === '9000')
							viewCore.pushPath('/error?errorCd=' + MypageErrorConstants.SERVICE_ERROR);
						else
						viewCore.onServiceErrorResult(url, status, message, resMethod);
						return;
					} 
				}
			}
			this.addInfoLog(`サービス(doPost)：成功[${url}][${viewCore.objectName}]`);
			resMethod.bind(viewCore)(result);
			viewCore.updateMode();
			viewCore.viewInfo.checkScopeModelValue();
		}).catch((error) => {
			const einfo = error as WprHttpErrorInfo;
			if (einfo) {
				viewCore.addErrorLog(`サービス(doPost)：通信エラー${einfo.message}[${einfo.url}]`);
				viewCore.onServiceError(einfo.url, einfo.status, einfo.message, einfo.error);
				// viewCore.onServiceErrorResult(einfo.url, einfo.status, einfo.message, resMethod);
				if (einfo.status === '9999')
					viewCore.pushPath('/error?errorCd=' + MypageErrorConstants.API_ERROR);
				else if (einfo.status === '9000' && !einfo.url.includes('registAccessLog'))
					viewCore.pushPath('/error?errorCd=' + MypageErrorConstants.SERVICE_ERROR);
				else
					viewCore.onServiceErrorResult(einfo.url, einfo.status, einfo.message, resMethod);
			}
			else {
				viewCore.addErrorLog(`サービス(doPost)：通信エラー発生時にWprHttpErrorInfo以外の情報が返されました。[${url}]`);
				viewCore.onServiceError(url, '', '未定義エラー');
				// viewCore.onServiceErrorResult(url, '', '未定義エラー', resMethod);
				if (einfo.status === '9999')
					viewCore.pushPath('/error?errorCd=' + MypageErrorConstants.API_ERROR);
				else if (einfo.status === '9000' && !einfo.url.includes('registAccessLog'))
					viewCore.pushPath('/error?errorCd=' + MypageErrorConstants.SERVICE_ERROR);
				else
					viewCore.onServiceErrorResult(url, '', '未定義エラー', resMethod);
			}
		}).finally(() => {
			if (status === true)
				WprFramework.getInstance().endWait();
			viewCore.endStateLock();
		});
	}
	// --------------------------------------------------------------------------
}
