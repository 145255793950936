
import { WprModel } from "../../../../wpr-framework/view/deco/WprModel";
import { WprDI_ScopeModel } from "../../../../wpr-framework/view/di/WprDI_ScopeModel";
import { Nhk } from "../../../models/bss/nhk/Nhk";
import { GetSimulationRequest } from "../../../service/models/dto/mypagerenewal/GetSimulationRequest";
import { MypageBaseView } from "../../base/MypageBaseView";
import { ProgressBarData } from "../../common/progress_bar/ProgressBarView";
import { MypageWizardMngr } from "../../wizard/models/MypageWizardMngr";


/**
 * 支払い申込内容の確認
 */
export class AddNhkConfirmView extends MypageBaseView {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() { super('AddNhkConfirmView'); }
	// --------------------------------------------------------------------------

	// モデル  ------------------------------------------------------------------
	@WprDI_ScopeModel('MypageWizardMngr')
	private m_MypageWizardMngr: MypageWizardMngr = null;		// 画面ウィザード管理クラス
	@WprModel('discountInfo')
	private m_discountInfo: Nhk = null;	// nhkモデル
	// --------------------------------------------------------------------------
	
	// override メソッド  --------------------------------------------------------

	/**
	 * アクション登録
	 */
	public onRegisterAction(): void {
		this.addAction('next', this.onNext);
		this.addAction('back', this.onBack);
	}
	/**
	 * コントロール定義設定
	 */
		public onRegisterConfig(): void {
			this.addConfig('discountSelect', '割引種類', 'discountInfo');
		}
	
	/**
	 * ビュー表示通知
	 */
	public onShowView(): void {
		this.m_MypageWizardMngr.initView(this, (result) => {
			this.registAccessLog();
			this.setStorageItem('b_url', window.location.href);
			const sendData: ProgressBarData = new ProgressBarData();
			sendData.progressBarList = this.m_MypageWizardMngr.getProgressModelList(this);
			this.sendChildData('fP_ev_progressBarIntital', sendData);
			this.m_discountInfo = this.m_MypageWizardMngr.getCache().discountInfo;
			// 料金シミュレーション
			const req: GetSimulationRequest = new GetSimulationRequest();
			req.customerId = this.m_MypageWizardMngr.getParams().customerId;
			this.m_CommonService.getPaymentSimulation(this, req, (result) => {
				if (result) {
					const payment = this.m_MypageWizardMngr.getViewInfo().customerPayment;
					if (result.breforeFee === '0' && payment && 
						payment[0].payment[0].paymentTypeCD === '2' && !this.m_MypageWizardMngr.isPaidService(this))
						this.setViewMode('請求書有料発行', '表示');
					this.refreshView();
				}
			});
			this.refreshView();
		});
	}
	// --------------------------------------------------------------------------

	// アクション  ---------------------------------------------------------------
	/**
	 * 申込む         <NavigateNextIcon />
	 */
	public onNext(param: any): void {
		this.m_MypageWizardMngr.goNext(this);

	}

	/**
	 * <NavigateBeforeIcon />         戻る
	 */
	public onBack(param: any): void {
		this.m_MypageWizardMngr.goBack(this);
	}
	// --------------------------------------------------------------------------

}
