import { WprBaseViewMultiModeInfo } from '../../../../../../wpr-framework/mode/WprBaseViewMultiMode';
import { WprMultiMode } from '../../../../../../wpr-framework/mode/WprMultiMode';
import { AddTvSelect2View } from '../AddTvSelect2View';
import { WprDI_Object } from '../../../../../../wpr-framework/view/di/WprDI_Object';
import { TvBaseModel } from '../../../../../models/mypage/tv/TvBaseModel';
import { Customer } from '../../../../../models/bss/customer/Customer';

/**
 * InputMode
 */
export class InputMode extends WprBaseViewMultiModeInfo<AddTvSelect2View> {
	// DI情報  ------------------------------------------------------------------
	@WprDI_Object
	private m_TvBaseModel: TvBaseModel	= null;	// テレビ基本情報モデル
	@WprDI_Object
	private m_Customer: Customer	= null;	// 顧客情報
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	public constructor() {
		super('InputMode');
	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * モードリスト取得（この画面モードで使用する全てのモードを定義する）
	 * @param list モードリスト
	 */
	public useModeList(list: string[]): void {
		list.push('フリー入力表示');
		list.push('フリー入力非表示');
		list.push('入力済み');
		list.push('未入力');
	}

	/**
	 * フィールド定義を登録処理
	 */
	public onRegisterControl(): void {
		this.addControl('comment', { display: [ { mode: 'フリー入力表示', value: true }, { mode: 'フリー入力非表示', value: false } ] });
		this.addControl('next', { enabled: [ { mode: '入力済み', value: true }, { mode: 'other', value: false } ] });
	}

	/**
	 * 画面の状態をチェックしてモードを変更する
	 * @param curMode 現在のモード
	 * @param newMode 新しいモード
	 */
	public onCheckMultiMode(curMode: WprMultiMode, newMode: WprMultiMode): void {
		if (this.m_TvBaseModel.location === 'その他')
			newMode.addMode('フリー入力表示');
		else 
			newMode.addMode('フリー入力非表示');
		let flg = true;
		if (!this.m_TvBaseModel.location)
			flg =false;
		else if (this.m_Customer.building[0].buildingTypeCD === '3' && !this.m_TvBaseModel.floor)
			flg =false;
		else if (this.m_TvBaseModel.location === 'その他' && !this.m_TvBaseModel.comment)
			flg =false;
		else if (this.m_TvBaseModel.securityFlg && !this.m_TvBaseModel.securityComment)
			flg = false;
		if (flg && 
			this.m_TvBaseModel.tvPortFlg &&
			this.m_TvBaseModel.desiredDate1 && 
			this.m_TvBaseModel.desiredDate2 && 
			this.m_TvBaseModel.desiredDate3 && 
			this.m_TvBaseModel.selectTime1 && 
			this.m_TvBaseModel.selectTime2 && 
			this.m_TvBaseModel.selectTime3 &&
			this.m_TvBaseModel.beforeContact &&
			this.m_TvBaseModel.sameDayContact &&
			this.m_TvBaseModel.presentName)
			newMode.addMode('入力済み');
		else 
			newMode.addMode('未入力');
	}
	// --------------------------------------------------------------------------
}
