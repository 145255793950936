/**
 * HAccessLog generated by hbm2java
 */
export class HAccessLog {
	// public 変数	-----------------------------------------------------------
	public accessLogId: number;		// 
	public customerId: string;		// 
	public currentViewNm: string;	// 
	public currentViewUrl: string;	// 
	public previousViewNm: string;	// 
	public previousViewUrl: string;	// 
	public flowNm: string;			// 
	public operationDate: Date;		// 
	public browser: string;			// 
	public terminal: string;		// 
	public cacheData: string;		// 
	public entryDate: Date;			// 
	public entryCd: string;			// 
	public updateDate: Date;		// 
	public updateCd: string;		// 
	public deleteFlag: boolean;		// 
	public deleteDate: Date;		// 
	public deleteCd: string;		// 
	// ------------------------------------------------------------------------
}
