import { WprModel } from '../../../../wpr-framework/view/deco/WprModel';
import { WprDI_ScopeModel } from '../../../../wpr-framework/view/di/WprDI_ScopeModel';
import { ObjectUtil } from '../../../common/util/ObjectUtil';
import { Contract } from '../../../models/bss/contract/Contract';
import { Equipment } from '../../../models/bss/contract/Equipment';
import { ContractPlanModel } from '../../../service/models/apimodels/ContractPlanModel';
import { MypageBaseView } from '../../base/MypageBaseView';
import { CompleteModel } from '../../common/common_complete/models/CompleteModel';
import { MypageWizardMngr } from '../../wizard/models/MypageWizardMngr';

/**
 * かっとびモバイル解約申込み完了
 */
export class MobileCompleteView extends MypageBaseView {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() { super('MobileCompleteView'); }
	// --------------------------------------------------------------------------

	// モデル  ------------------------------------------------------------------
	@WprDI_ScopeModel('MypageWizardMngr')
	private m_MypageWizardMngr: MypageWizardMngr			= null;		// 画面ウィザード管理クラス
	private m_Contract: Contract				= null;	// 契約情報
	private m_ContractModel: ContractPlanModel				= null;	// 契約情報
	@WprModel('EquipmentModel')
	private m_EquipmentModel: Equipment	= null;	// Equipmentモデル
	@WprModel('CompleteModel')
	private m_CompleteModel: CompleteModel	= null;	// 完了情報モデル
	// --------------------------------------------------------------------------

	// pravate 変数 ---------------------------------------------------------------
	private m_CustomerName: string							= null;	// 顧客名
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * コントロール定義設定
	 */
	public onRegisterConfig(): void {
		this.addConfig('equipmentID', 'シリアル番号', 'EquipmentModel');
		this.addConfig('user', 'ユーザー', 'CompleteModel', { bindName: 'user' });
	}

	/**
	 * アクション登録
	 */
	public onRegisterAction(): void {
		this.addAction('toQuestionnaire', this.onToQuestionnaire);
		this.addAction('back', this.onBack);
	}

	/**
	 * ビュー表示通知
	 */
	public onShowView(): void {
		this.m_MypageWizardMngr.initView(this, (result) => {
			this.registAccessLog();
			this.setStorageItem('b_url', window.location.href);
			let contractId: string = this.m_MypageWizardMngr.getParams().contractId;
            this.m_ContractModel =this.m_MypageWizardMngr.getViewInfo().contractModel;
            // 契約情報からシリアル番号取得
			if (ObjectUtil.isNotNullOrEmptyStr(contractId)) {
				this.m_Contract = this.m_MypageWizardMngr.getContract(this, contractId);
				if (ObjectUtil.isNotNullOrUndefined(this.m_Contract) && this.m_Contract.serviceTypeCD === '2') {
					if (ObjectUtil.isNotNullOrEmptyArray(this.m_Contract.equipment)) {
						this.m_EquipmentModel = this.m_Contract.equipment[0];
					}
				}
			}
			// 名前表示
			const customerInfo = this.m_MypageWizardMngr.getViewInfo().customer;
			if (customerInfo.sexCD === '3' || customerInfo.sexCD === '')
				this.m_CustomerName = customerInfo.corporateName;
			else
				this.m_CustomerName = customerInfo.surName + ' ' + customerInfo.givenName + 'さん';
			this.m_CompleteModel = new CompleteModel();
			this.m_CompleteModel.user = this.m_CustomerName;
			this.refreshView();
		});
	}
	// --------------------------------------------------------------------------

	// アクション  ---------------------------------------------------------------
	/**
	 * <img src={questionnaireIcon} style={{maxWidth: '100%'}} />
	 */
	public onToQuestionnaire(param: any): void {
		window.open('https://questant.jp/q/B7KS5E4S?id=' + this.m_MypageWizardMngr.getParams().customerId);
	}

	/**
	 * <NavigateBeforeIcon />         契約内容の確認・変更メニューへ
	 */
	public onBack(param: any): void {
		location.href = this.getOldUrl() + 'Srvlist/Continfo_list/';
	}
	// --------------------------------------------------------------------------
}
