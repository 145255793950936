import { WprBaseViewInfo } from '../../../wpr-framework/view/WprBaseViewInfo';

/**
 * router
 */
export class MyPageRouterView extends WprBaseViewInfo {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() { super('MyPageRouter'); }
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * コントロール定義設定
	 */
	public onRegisterConfig(): void {
	}
	// --------------------------------------------------------------------------
}
