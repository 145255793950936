import { WprControlInfo } from '../../view/control/WprControlInfo';
import { WprBaseViewMode } from '../WprBaseViewMode';
import { IWprConfigModeOption } from '../inter/IWprConfigModeOption';
import { IWprBaseModeInfo, WprModeJoinCondition } from '../inter/IWprBaseModeInfo';
import { WprListInfo } from '../../view/control/list/WprListInfo';

/**
 * モード設定基本クラス
 */
export abstract class WprBaseModeSetter {
	// private 変数  ------------------------------------------------------------
	private m_Name: string					= null;		// モード設定名
	private m_ID: string					= null;		// コントロールID
	private m_ControlName: string			= null;		// コントロール名
	private m_ControlInfo: WprControlInfo	= null;		// コントロール情報
	private m_ListInfo: WprListInfo			= null;		// リスト情報
	private m_ViewMode: WprBaseViewMode		= null;		// ビューモード情報
	private m_Option: IWprConfigModeOption	= null;		// オプション
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** モード設定名 */
	public get name(): string					{ return this.m_Name;				}
	/** コントロールID */
	public get id(): string 		{			 return this.m_ID; 					}
	/** コントロール名 */
	public get controlName(): string			{ return this.m_ControlName;		}	public set controlName(name: string) 				{ this.m_ControlName = name;		}
	/** コントロール情報 */
	public get controlInfo(): WprControlInfo	{ return this.getControlInfo();		}	public set controlInfo(controlInfo: WprControlInfo) { this.setControlInfo(controlInfo);	}
	/** リスト情報 */
	public get listInfo(): WprListInfo			{ return this.m_ListInfo;			}	public set listInfo(listInfo: WprListInfo) 			{ this.m_ListInfo = listInfo; 		}
	/** ビューモード情報 */
	public get viewMode(): WprBaseViewMode		{ return this.m_ViewMode;			}
	/** オプション */
	public get option(): IWprConfigModeOption	{ return this.m_Option;				}
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	public constructor(name: string, option: IWprConfigModeOption, viewMoode: WprBaseViewMode, id?: string) {
		this.m_Name = name;
		this.m_Option = option;
		this.m_ViewMode = viewMoode;
		this.m_ID = id;
	}
	// --------------------------------------------------------------------------

	// abstract メソッド	-----------------------------------------------------
	/**
	 * 初期化処理
	 */
	public abstract initialize(): void;

	/**
	 * モードチェック処理
	 * @modeList モードリスト
	 */
	public abstract checkMode(modeList: string[]): void;

	/**
	 * コントロールの状態を設定する
	 * @param mode モード
	 */
	public abstract setStatus(mode: string): void;

	/**
	 * 複数モードのコントロールの状態を設定する
	 * @modeList modeList モードリスト
	 */
	public abstract setMultiStatus(modeList: string[]): void;
	// --------------------------------------------------------------------------

	// protected メソッド	-----------------------------------------------------
	/**
	 * モードリスト取得
	 * @param minfo モード情報
	 * @param sname 設定名称
	 */
	protected getModeList(minfo: IWprBaseModeInfo, sname: string): boolean {
		if (minfo.mode) {
			const list = new Array();
			const or = minfo.mode.includes('|');
			const and = minfo.mode.includes('&');
			if (or === true && and === true) {
				this.viewMode.addErrorLog(`モードの指定に誤りがあります（or(|)とand(&)は同時に指定できません）。[${this.viewMode.objectName}][${this.controlName}][${sname}][${minfo.mode}]]`);
				return false;
			}
			let condition = WprModeJoinCondition.OR;
			if (and === true)
				condition = WprModeJoinCondition.AND;
			minfo.mode.split(condition).forEach(str => {
				const mode = str.trim();
				if (mode.length > 0) {
					if (list.includes(mode) === false)
						list.push(mode);
					else
						this.viewMode.addErrorLog(`同一のモードが指定されています。[${this.viewMode.objectName}][${this.controlName}][${sname}][${minfo.mode}][${mode}]`);
				}
			});
			minfo.condition = condition;
			minfo.modeList = list;
			return true;
		}
		return false;
	}
	// --------------------------------------------------------------------------

	// private メソッド	---------------------------------------------------------
	/**
	 * コントロール情報設定処理
	 * @param controlInfo コントロール情報
	 */
	private setControlInfo(controlInfo: WprControlInfo) {
		this.m_ControlInfo = controlInfo;
		this.m_ControlName = this.m_ControlInfo.name;
	}

	/**
	 * コントロール情報取得
	 * @returns コントロール情報
	 */
	private getControlInfo(): WprControlInfo {
		if (this.m_ControlInfo != null)
			return this.m_ControlInfo;
		if (this.m_ListInfo != null)
			return this.m_ListInfo.getRowControlInfo(this.m_ControlName);
		return null;
	}
	// --------------------------------------------------------------------------
}
