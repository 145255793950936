import { NameChangeInfo } from "../../apimodels/NameChangeInfo";

/**
 * 名義変更取得リクエスト
 */
 export class GetNameChangeRequest {
	// public 変数	-----------------------------------------------------------
	public customerId: string;				// お客様番号
	public tokenCd: string;					// トークンコード
	// ------------------------------------------------------------------------
}