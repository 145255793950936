import React from 'react';
import { WprErrorMngr } from '../../error/WprErrorMngr';
import { WprFramework } from '../../WprFramework';

/**
 * コントロール基本コンポーネント
 */
export abstract class WprBaseEventLogComponent<T, U> extends React.PureComponent<T, U> {
	// private 変数  ------------------------------------------------------------
	private m_ErrorMngr: WprErrorMngr	= null;		// エラー管理
	// --------------------------------------------------------------------------

	// virtual メソッド  --------------------------------------------------------
	/**
	 * 要素固有XPath情報取得
	 * @param element 要素
	 * @returns 要素固有XPath情報
	 */
	protected getElementXPathInfo(element: HTMLElement): string {
		return null;
	}
	// --------------------------------------------------------------------------

	// protected メソッド  -------------------------------------------------------
	/**
	 * クリックイベントログ出力
	 * @param element 要素名
	 */
	protected addClickEventLog(element: HTMLElement) {
		if (this.erroMngr.outputEventLog === true) {
			const xpath = this.getXPath(element);
			this.erroMngr.addEventLog(`xpath=(${xpath}),event=(click)`);
		}
	}

	/**
	 * 値変更イベントログ出力
	 * @param element 要素名
	 * @param value 値
	 */
	protected addChangeValueEventLog(element: HTMLElement, value: any) {
		if (this.erroMngr.outputEventLog === true) {
			const xpath = this.getXPath(element);
			this.erroMngr.addEventLog(`xpath=(${xpath}),event=(changeValue),value=(${value})`);
		}
	}

	/**
	 * 親要素取得
	 * @param element 要素
	 * @param tagName タグ名
	 * @param attrName 属性名
	 * @param value 値
	 * @returns 親要素
	 */
	protected getParentElement(element: HTMLElement, tagName: string, attrName?: string, value?: string): HTMLElement {
		if (attrName === undefined) {
			if (element.tagName === tagName)
				return element as HTMLElement;
		}
		else {
			if (element.tagName === tagName && element.attributes[attrName] && element.getAttribute(attrName) === value)
			return element as HTMLElement;
		}
		if (element.parentElement)
			return this.getParentElement(element.parentElement, tagName, attrName, value);
		return null;
	}

	/**
	 * 親から見たインデクス取得
	 * @param element ボタン要素
	 * @returns 親から見たインデクス
	 */
	protected getChildIndex(element: HTMLElement): number {
		let idx = 1;
		for(const child of element.parentElement.childNodes) {
			const celement = child as HTMLElement;
			if (celement) {
				if (celement.tagName === element.tagName) {
					if (celement === element)
						return idx;
					idx++;
				}
			}
		}
		return idx;
	}
	// --------------------------------------------------------------------------

	// private メソッド  --------------------------------------------------------
	/**
	 * エラー管理取得
	 * @returns エラー管理
	 */
	private get erroMngr(): WprErrorMngr {
		if (this.m_ErrorMngr == null)
			this.m_ErrorMngr = WprFramework.getInstance().error;
		return this.m_ErrorMngr;
	}

	/**
	 * 要素のXPath取得
	 * @param elemen 要素
	 * @returns XPath
	 */
	private getXPath(element: HTMLElement): string {
		let xpath = this.getXPathString(element);
		const listPath = this.getListPath(xpath, element);
		if (listPath)
			xpath = this.getXPathString(element, listPath);
		return xpath;
	}

	/**
	 * 要素のXPath文字列取得
	 * @param elemen 要素
	 * @param listPath 繰り返しパス
	 * @param isStart 開始フラグ
	 * @returns XPath文字列
	 */
	private getXPathString(element: HTMLElement, listPath?: string, isStart: boolean=true): string {
		let name = null as string;
		if (isStart === true)
			name = this.getElementXPathInfo(element);
		if (name == null) {
			name = '';
			if (element.id)
				name = `//${element.tagName}[@id='${element.id}']`;
			else if (element.attributes['name'])
				name = `//${element.tagName}[@name='${element.getAttribute('name')}']`;
		}
		if (name && listPath && name.startsWith('//'))
			name = name.replace('//', '//' + listPath);
		if (element.parentElement) {
			if (name == '')
				return this.getXPathString(element.parentElement, null, false);
			return this.getXPathString(element.parentElement, null, false) + name;
		}
		return name;
	}

	/**
	 * 繰り返しパス
	 * @param xpath XPath
	 * @param element 要素
	 * @returns 繰り返しパス取得
	 */
	private getListPath(xpath: string, element: HTMLElement): string {
		const result = document.evaluate(xpath, document, null, XPathResult.ANY_TYPE, null);
		if (result.resultType === 4 || result.resultType === 5) {
			const nlist = new Array();
			let node = null;
			let idx = 1;
			let elementIndex = 0;
			while((node = result.iterateNext())) {
				nlist.push(node);
				if (node === element)
					elementIndex = idx;
				idx++;
			}
			if (nlist.length > 1 && elementIndex > 0) {
				const plist1 = this.getParentElements(nlist[0]);
				const plist2 = this.getParentElements(nlist[1]);
				const plist3 = this.getParentElements(nlist[elementIndex-1]);
				const parent = this.getParent(plist1, plist2);
				return this.getParentPath(parent, plist3);
			}
		}
		return null;
	}

	/**
	 * 親要素リスト取得
	 * @param element 要素
	 * @param list 親要素リスト
	 * @returns 親要素リスト
	 */
	private getParentElements(element: HTMLElement, list?: HTMLElement[]): HTMLElement[] {
		if (list === undefined) {
			list = new Array();
		}
		const parent = element.parentElement;
		if (parent) {
			list.push(parent);
			this.getParentElements(parent, list);
		}
		return list;
	}

	/**
	 * 共通親要素取得
	 * @param plist1 親要素リスト１
	 * @param plist2 親要素リスト２
	 * @returns 共通親要素
	 */
	private getParent(plist1: HTMLElement[], plist2: HTMLElement[]): HTMLElement {
		for (const element of plist1) {
			if (plist2.includes(element) === true)
				return element;	
		}
		return null;
	}

	/**
	 * 親要素パス取得
	 * @param parent 親要素
	 * @param plist 親要素リスト
	 * @returns 親要素パス取得
	 */
	private getParentPath(parent: HTMLElement, plist: HTMLElement[]): string {
		let path = null;
		let level = 0;
		for (const element of plist) {
			level++;
			if (parent === element)
				break;
		}
		for (let i=0; i<level; i++) {
			const element = plist[i];
			if (i == level-2) {
				const idx = this.getChildIndex(element);
				path = `${element.tagName}[${idx}]/${path}`;
			}
			else {
				if (path == null)
					path = element.tagName;
				else
					path = element.tagName + '/' + path;
			}
		}
		return path + '/';
	}
	// --------------------------------------------------------------------------
}
