import { WprBaseValueModeSetter } from './WprBaseValueModeSetter';
import { IWprModeStyle } from '../inter/IWprModeStyle';
import { WprBaseViewMode } from '../WprBaseViewMode';
import { IWprConfigModeOption } from '../inter/IWprConfigModeOption';

/**
 * モードスタイル設定
 */
export class WprStyleModeSetter extends WprBaseValueModeSetter<IWprModeStyle> {
	// コンストラクタ  -----------------------------------------------------------
	public constructor(option: IWprConfigModeOption, viewMoode: WprBaseViewMode, id?: string) {
		super('style', option, viewMoode, id);
	}
	// --------------------------------------------------------------------------

	// override メソッド	-----------------------------------------------------
	/**
	 * 初期化処理
	 * @param option モード定義オプション
	 */
	public initialize(): void {
		this.initializeModeList(this.option.style);
	}

	/**
	 * コントロールステータス設定処理
	 * @param vinfo 値情報
	 * @param id コントロールID
	 */
	public setControlStatus(vinfo: IWprModeStyle, id?: string): void {
		this.controlInfo.setStyle(vinfo.style, id);
	}
	// --------------------------------------------------------------------------
}
