// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@charset \"UTF-8\";\n/*\r\n * テレビ追加選択2用CSS\r\n */\n.AddTvSelect2-fontBlack {\n  font-size: medium !important;\n  font-weight: 600 !important;\n  color: #000000 !important; }\n\n.AddTvSelect2-inputArea .MuiOutlinedInput-root {\n  width: 95%; }\n\n.AddTvSelect2-inputArea .MuiSelect-root {\n  height: auto !important;\n  color: #484848 !important;\n  font-size: 14px !important;\n  font-weight: 500 !important; }\n\n.AddTvSelect2-inputArea .MuiInputBase-input {\n  height: auto !important;\n  color: #484848 !important;\n  font-size: 14px !important;\n  font-weight: 500 !important; }\n\n.AddTvSelect2-inputArea .MuiSelect-icon {\n  top: auto; }\n\n.AddTvSelect2-inputArea path {\n  color: #000000 !important; }\n\n.AddTvSelect2-itemTitle2 {\n  font-size: 15px; }\n\n.AddTvSelect2-customerInput {\n  background: #F5F5FA;\n  border-radius: 4px;\n  min-height: 56px;\n  display: flex;\n  justify-content: center;\n  align-items: center; }\n  .AddTvSelect2-customerInput .MuiFormControl-root {\n    width: 100%; }\n  .AddTvSelect2-customerInput .MuiOutlinedInput-notchedOutline {\n    border: none; }\n\n.AddTvSelect2-flex {\n  display: flex;\n  align-items: center;\n  margin-left: 15px; }\n\n@media screen and (max-width: 440px) {\n  .AddTvSelect2-labelSize .wpr-checkbox-label .MuiFormControlLabel-label {\n    font-size: 12px; } }\n", ""]);
// Exports
exports.locals = {
	"fontBlack": "AddTvSelect2-fontBlack",
	"inputArea": "AddTvSelect2-inputArea",
	"itemTitle2": "AddTvSelect2-itemTitle2",
	"customerInput": "AddTvSelect2-customerInput",
	"flex": "AddTvSelect2-flex",
	"labelSize": "AddTvSelect2-labelSize"
};
module.exports = exports;
