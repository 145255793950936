import { WprBaseViewListMultiModeInfo } from '../../../../../../wpr-framework/mode/WprBaseViewListMultiModeInfo';
import { WprMultiMode } from '../../../../../../wpr-framework/mode/WprMultiMode';
import { CourseListModel } from '../../../../../models/mypage/net/CourseListModel';
import { AddNetSelectView } from '../AddNetSelectView';

/**
 * コースリストモード
 */
export class CourseListMode extends WprBaseViewListMultiModeInfo<AddNetSelectView, CourseListModel> {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() {
		super('CourseListModel');
	}
	// --------------------------------------------------------------------------
	
	// override メソッド  --------------------------------------------------------
	/**
	 * モードリスト取得（この画面モードで使用する全てのモードを定義する）
	 * @param list モードリスト
	 */
	public useModeList(list: string[]): void {
		list.push('切替可');
		list.push('切替不可');
	}

	/**
	 * フィールド定義を登録処理
	 */
	public onRegisterControl(): void {
		this.addControl('check', { 
			check: true
			,readOnly: [ { mode: '切替不可', value: true }, { mode: '切替可', value: false } ] });
	}

	/**
	 * ビューの状態をチェックして行のモードを変更する
	 * @param curMode 現在のモード
	 * @param newMode 新しいモード
	 * @param row 行データ
	 */
	public onCheckListMultiMode(curMode: WprMultiMode, newMode: WprMultiMode, row: CourseListModel): void {
		if (row.check) {
			newMode.addMode('切替不可');
		}
		else {
			newMode.addMode('切替可');
		}
	}
	// --------------------------------------------------------------------------
}
