// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@charset \"UTF-8\";\n/*\r\n * とことんサポート確認画面用CSS\r\n */\n.TokotonSupportConfirm-message {\n  font-size: 14px; }\n  @media screen and (max-width: 450px) {\n    .TokotonSupportConfirm-message {\n      font-size: 12px;\n      line-height: 17px; } }\n\n@media screen and (max-width: 450px) {\n  .TokotonSupportConfirm-table_header {\n    padding: 0 0.6rem;\n    line-height: 36px;\n    text-align: left !important; } }\n\n.TokotonSupportConfirm-margin .feeTable {\n  margin-bottom: 0px; }\n\n.TokotonSupportConfirm-aster {\n  padding-left: 1rem; }\n  @media screen and (max-width: 450px) {\n    .TokotonSupportConfirm-aster {\n      padding-left: 0;\n      padding: 0px 12px; } }\n\n.TokotonSupportConfirm-supportMsgArea {\n  background: #F5F5F5;\n  border-radius: 12px;\n  padding: 6px;\n  margin-top: 20px;\n  font-style: normal; }\n  @media screen and (max-width: 450px) {\n    .TokotonSupportConfirm-supportMsgArea {\n      margin-bottom: 0px;\n      margin-top: 16px;\n      padding: 1px 1px; } }\n  .TokotonSupportConfirm-supportMsgArea .TokotonSupportConfirm-textcolor {\n    display: flex;\n    color: #6169B8;\n    font-weight: 700;\n    font-size: 14px;\n    height: 18px;\n    margin-bottom: 2px;\n    line-height: 18px; }\n    @media screen and (max-width: 450px) {\n      .TokotonSupportConfirm-supportMsgArea .TokotonSupportConfirm-textcolor {\n        color: #6169B8;\n        font-weight: 700;\n        font-size: 14px;\n        height: 18px;\n        margin-bottom: 1px; } }\n    .TokotonSupportConfirm-supportMsgArea .TokotonSupportConfirm-textcolor img {\n      margin-right: 5px;\n      vertical-align: bottom; }\n", ""]);
// Exports
exports.locals = {
	"message": "TokotonSupportConfirm-message",
	"table_header": "TokotonSupportConfirm-table_header",
	"margin": "TokotonSupportConfirm-margin",
	"aster": "TokotonSupportConfirm-aster",
	"supportMsgArea": "TokotonSupportConfirm-supportMsgArea",
	"textcolor": "TokotonSupportConfirm-textcolor"
};
module.exports = exports;
