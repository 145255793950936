import { MCampaign } from "../entity/primary/MCampaign";
import { RelocateModel } from "./RelocateModel";

/**
 * お問い合わせメール情報
 */
export class InquiryMailInfo {
	// public 変数	-----------------------------------------------------------
	public subject: string;					// お申込み種別
	public customerId: string;				// お客様ID
	public contractId: string;				// 契約番号
	public name: string;					// 契約者氏名
	public nameKana: string;				// 契約者氏名
	public addrline1: string;				// 住所
	public addrline2: string;				// 建物名等
	public tel: string;						// 連絡先電話番号
	public daytimeTel: string;				// 日中の連絡先
	public enteredTel1: string;				// 契約電話番号1
	public enteredTel2: string;				// 契約電話番号2
	public enteredAddr: string;				// 住所
	public enteredBuilName: string;			// 建物名
	public enteredRoomNum: string;			// 部屋番号
	public enterDay: string;				// 入居日
	public mail: string;					// メールアドレス
	public newMail: string;					// 名義変更用 新名義人メールアドレス
	public relationDispName: string;		// 現在の契約者との関係
	public changedReciverName: string;		// 変更届送付先宛名
	public changedZipcd: string;			// 変更届送付先郵便番号
	public changedAddr: string;				// 変更届送付先住所
	public paymentDispname: string;			// 支払い方法について
	public content: string;					// リモコン交換の理由
	public appService: string;				// 申込みサービス
	public equipment: string;				// 端末の種類
	public beforeAppService: string;		// 変更前サービス
	public beforeEquipment: string;			// 変更前
	public otokuStatus: string;				// お得パック状況
	public startDate: string;				// 開始日
	public ipOption: string;				// IPアドレス種別
	public other: string;					// */
	public equipmentInfo: string;			// */
	public appOpchannel: string;			// 申込みオプションチャンネル
	public discount: string;				// 割引種別
	public befDiscount: string;				// 変更前割引
	public aftDiscount: string;				// 変更後割引
	public modemForm: string;				// 宅内機器の形態
	public rental: string;					// レンタル／購入
	public optionName: string;				// オプション
	public optionBuyName: string;			// 購入オプション
	public magazineApp: string;				// イッツコムマガジン
	public stb: string;						// セットトップボックス
	public beforeStb: string;				// 変更前サービス
	public beforeRental: string;			// 変更前サービス
	public beforeMagazinApp: string;		// 変更前サービス
	public beforeOptionName: string;		// 変更前サービス
	public noticeInfo: string;				// */
	public optionRentalList: string[];		// オプションリスト
	public contentList: string[];			// リモコン交換の理由リスト
	public otherList: string[];				// */
	public beforeOptionNameList: string[];	// 変更前サービスリスト
	public sex: string;						// 性別
	public birthday: string;				// 生年月日
	public enteredName: string;				// 新契約名義人
	public enteredNameKana: string;			// 新契約名義人カナ
	public nameChangeKbn: string;			// 新名義人区分
	public campaignFlg: boolean;			// キャンペーンフラグ
	public campaignGroupName: string;		// キャンペーングループ名称
	public campaignList: MCampaign[];		// キャンペーン

	/** 増設 */
	public constractionFlg: boolean;		// 工事フラグ
	public desiredDate1: string;			// 第一希望
    public desiredDate2: string;			// 第二希望
    public desiredDate3: string;			// 第三希望
    public selectTime1: string;				// 第一希望　時間帯
    public selectTime2: string;				// 第二希望　時間帯
    public selectTime3: string;				// 第三希望　時間帯
	public floor: string;					// 階数
	public location: string;				// 場所
	public comment: string;					// フリー入力
	public securityComment: string;			// セキュリティフリー入力
	public constructionCost: string;		// 工事費
	public beforeContact: string;			// 前日連絡先
	public sameDayContact: string;			// 当日連絡先
	public witness: string;					// 当日立ち会い者		
	public reform: string;					// リフォーム有無
	public establishment: string;			// 設置方法
	public tokotonApp: string;				// とこサポ加入有無
	public workScheduleId: string;			// 工事予約ID
	public desiredDate: string;				// 工事予定日
	public startTime: string;				// 工事予定開始時間
	public endTime: string;					// 工事予定終了時間
	public relocateList: RelocateModel[]	//移設用モデル
	public wireModem: string;				// 有線モデム
	// ケープラ対応
	public applicationType: string;			// 申込み種別
	public landlineNumer: string;			// 固定電話番号
	public contractorName: string;			// 契約者名
	public auPhoneNunber: string;			// au携帯番号
	public multiModularJacks: string;		// モジュラージャック複数利用
	public teleLineService: string;			// 回線電話各種サービス
	public newLandlineNumerFlg: boolean;	// 新規固定電話番号フラグ
	public nameChangeType: string;			// 名義変更用タイトル
	public team: string;					// NHKチーム
	public escalationFlg: boolean;			// エスカフラグ
	public supportService: string;			// サポート対応
	// 端末登録
	public terminalId: string;				// MACアドレス
	public error101: boolean;			// -101エラーフラグ
	public error102: boolean;			// -101エラーフラグ
	public error103: boolean;			// -101エラーフラグ
	public error104: boolean;			// -101エラーフラグ
	public error105: boolean;			// -101エラーフラグ
	public error106: boolean;			// -101エラーフラグ
	public error200: boolean;			// -101エラーフラグ
	public error300: boolean;			// -101エラーフラグ
	public error400: boolean;			// -101エラーフラグ
	public errorCd:string;				// エラーコード
	public escalationCd: string;			// エスカレーションコード
	// ------------------------------------------------------------------------
}
