/**
 * パスワード再発行モデル
 */
export class ResetPasswordModel {
	// public 変数	-----------------------------------------------------------
	public idpID: string;			// idpid
	public password: string;		// パスワード
	public passwordKana: string;	// パスワードカナ
	public date: string;			// 日付
	// ------------------------------------------------------------------------
}
