import { HMvnoTmpSaved } from '../entity/HMvnoTmpSaved';
import { CustomerInfo } from '../../../models/CustomerInfo';
import { MGeneral } from '../entity/MGeneral';

/**
 * MVNO申込情報
 */
export class MvnoEntryInfo {
	// public 変数	-----------------------------------------------------------
	public entryInfo: HMvnoTmpSaved;	// MVNOプラン情報5
	public total: string;				// 変更前合計金額（税込）
	public initialCostTotal: string;	// 初期費用_合計金額
	public initialCostItem: string;		// 初期費用_内訳
	public initialCostItemPrice: string;		// 初期費用_内訳
	public customerInfo: CustomerInfo;	// 顧客情報
	public coursePrice: string;			// 
	public terminalPrice: string;		// 
	public capacityPrice: string;		// 
	public simOptionPrice: string;		// 
	public fivegPrice: string;			// 
	public initialSupportPrice: string;	// 
	public webFilteringPrice: string;	// 
	public terminalGuaranteePrice: string;
	public rusubanPrice: string;
	public campaignCode: MGeneral;		// 
	public campaignCodeList: MGeneral[];		// キャンペーンの一覧 #61758 新キャンペーン追加 
	public initialCampaignFlg: boolean;
	public identificationFlg: boolean;
	public iPhoneFlg: boolean;	
	public noJoinFlg: boolean;				//未加入フラグ
	// ------------------------------------------------------------------------
}
