import { IWprConfigModeOption } from './inter/IWprConfigModeOption';
import { WprListInfo } from '../view/control/list/WprListInfo';
import { WprBaseViewMultiMode } from './WprBaseViewMultiMode';
import { WprMultiMode } from './WprMultiMode';
import { WprConfigModeControl } from './WprConfigModeControl';
import { WprBaseViewCore } from '../view/WprBaseViewCore';
import { WprControRefreshMap } from '../view/control/map/WprControRefreshMap';

/**
 * ビューリスト複数モード基本クラス
 */
export abstract class WprBaseViewListMultiMode<T, U extends WprListInfo> extends WprBaseViewMultiMode {
	// private 変数  ------------------------------------------------------------
	private m_ListName: string				= null;		// テーブル名
	private m_ListInfo: U					= null;		// テーブル情報
	private m_ListData: Array<any>			= null;		// テーブルデータ
	private m_CurModeList: WprMultiMode[]	= null;		// 現在のモードリスト
	private m_RowData: any					= null;		// 行データ
	private m_RowIndex: number				= 0;		// 行インデクス
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** リスト名 */
	public get listName(): string 	{ return this.m_ListName; 	}
	/** リスト名 */
	public get rowData(): any	 	{ return this.m_RowData; 	}
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	public constructor(listName: string) {
		super(listName);
		this.m_ListName = listName;
	}
	// --------------------------------------------------------------------------

	// abstract メソッド  -------------------------------------------------------
	/**
	 * リスト情報取得
	 * @param listName リスト名
	 * @returns リスト情報
	 */
	public abstract getListInfo(listName: string): U;
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * モードクリア処理
	 */
	public clearMode(): void {
		// this.m_CurModeList = new Array();
	}

	/**
	 * モードチェック処理
	 * @param isClear trueの場合現在モードをクリアする(モードが変わらなくて反応する)
	 */
	public checkMode(isClear?: boolean): void {
		if (this.m_ListInfo == null) {
			this.addErrorLog('リスト情報が決まっていません。onRegisterControlを確認してください。');
		}
		if (this.m_ListData != null) {
			let idx =  0;
			for(const row of this.m_ListData) {
				this.m_ListInfo.setRowIndex(idx);
				this.m_RowData = row;
				this.m_RowIndex = idx;
				this.setMultiMode(this.m_CurModeList[idx]);
				super.checkMode(isClear);
				this.m_CurModeList[idx] = this.getMultiMode();
				idx++;
			}
		}
	}

	/**
	 * コントロール定義を追加する
	 * @param name コントロール名
	 * @param option 定義オプション
	 * @param id コントロールID
	 */
	protected addControl(name: string, option: IWprConfigModeOption, id?: string): void {
		if (this.m_ListInfo == null)
			this.m_ListInfo = this.getListInfo(this.listName);
		if (this.m_ListInfo == null) {
			// this.addErrorLog(`モード定義に対するコントロールが見つかりませんでした。[${name}]`);
			// ATODE
			// this.viewCore.viewInfo.addConfig(name, '', null);
		}
		if (this.checkControlName(name, id) === false) {
			this.addErrorLog(`モード定義が重複しています。[${name}]`);
		}
		else {
			const cinfo = new WprConfigModeControl(name, option, this, id);
			cinfo.setListInfo(this.m_ListInfo, this.modeList);
			this.controlList.push(cinfo);
			if (cinfo.isCheck === true)
				this.checkControlList.push(name);
		}
	}

	/**
	 * カテゴリ定義を追加する
	 * @param name カテゴリ名
	 * @param option 定義オプション
	 */
	protected addCategory(name: string, option: IWprConfigModeOption): void {
		if (this.m_ListInfo == null)
			this.m_ListInfo = this.getListInfo(this.listName);
		if (this.m_ListInfo == null) {
			// this.addErrorLog(`モード定義に対するコントロールが見つかりませんでした。[${name}]`);
			// ATODE
			// this.viewCore.viewInfo.addConfig(name, '', null);
		}
		this.viewCore.viewInfo.getCategoryControlList(name, this.listName).forEach(info => {
			if (this.checkControlName(info.name) === false) {
				this.addErrorLog(`モード定義が重複しています。[${info.name}]`);
			}
			else {
				name = info.name;
				const cinfo = new WprConfigModeControl(name, option, this);
				cinfo.setListInfo(this.m_ListInfo, this.modeList);
				this.controlList.push(cinfo);
				if (cinfo.isCheck === true)
					this.checkControlList.push(name);
			}
		});
	}

	/**
	 * インジェクション実行処理
	 */
	protected injection() {
		this.execListInjection(this.m_ListName, this.m_RowIndex);
	}
	// --------------------------------------------------------------------------

	// public メソッド  ---------------------------------------------------------
	/**
	 * テーブルデータ設定処理
	 * @param tableData テーブルデータ
	 */
	public setListData(tableData: Array<any>): void {
		this.m_ListData = tableData;
		this.m_CurModeList = new Array();
		for(const row of this.m_ListData)
			this.m_CurModeList.push(this.getInitMode());
	}
	// --------------------------------------------------------------------------
}

/**
 * ビュー複数モード基本情報
 */
export abstract class WprBaseViewListMultiModeInfo<T extends WprBaseViewCore, U> extends WprBaseViewListMultiMode<U, WprListInfo> {
	// private 変数  ------------------------------------------------------------
	private m_Parent: T	= null;			// 親ビュー情報
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** 親ビュー情報 */
	public get view():  T	{ return this.m_Parent; }
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	public constructor(listName: string) {
		super(listName);
	}
	// --------------------------------------------------------------------------

	// abstract メソッド  --------------------------------------------------------
	/**
	 * ビューの状態をチェックして行のモードを変更する
	 * @param curMode 現在のモード
	 * @param newMode 新しいモード
	 * @param row 行データ
	 */
	public abstract onCheckListMultiMode(curMode: WprMultiMode, newMode: WprMultiMode, row: U): void;
	// --------------------------------------------------------------------------

	// virtual メソッド  ---------------------------------------------------------
	/**
	 * モード変更通知
	 * @param oldMode 古いモード
	 * @param newMode 新しいモード
	 * @param row 行データ
	 */
	public onChangeListMultiMode(oldMode: WprMultiMode, newMode: WprMultiMode, row: U): void {}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * ビューの状態をチェックしてモードを変更する
	 * @param curMode 現在のモード
	 * @param newMode 新しいモード
	 */
	public onCheckMultiMode(curMode: WprMultiMode, newMode: WprMultiMode): void {
		this.onCheckListMultiMode(curMode, newMode, this.rowData);
	}

	/**
	 * モード変更通知
	 * @param oldMode 変更前モード
	 * @param newMode 変更後モード
	 */
	public onChangeMultiMode(oldMode: WprMultiMode, newMode: WprMultiMode): void {
		this.onChangeListMultiMode(oldMode, newMode,  this.rowData);
	}

	/**
	 * View情報設定処理
	 * @param Binder バインダ
	 */
	public setViewCore(viewCore: WprBaseViewCore): void {
		this.m_Parent = viewCore as T;
		super.setViewCore(viewCore);
	}

	/**
	 * DI用コントロール情報マップ取得
	 * @returns DI要コントロール情報マップ
	 */
	public getDIControlMap(): WprControRefreshMap {
		return this.viewCore.getDIControlMap();
	}

	/**
	 * DI元インスタンス取得
	 * @returns DI元インスタンス
	 */
	public getDIObjectFromInstance(): any {
		return this.viewCore;
	}

	/**
	 * リスト情報取得
	 * @param listName リスト名
	 * @returns リスト情報
	 */
	public getListInfo(listName: string): WprListInfo {
		return this.viewCore.viewInfo.getListInfo(this.listName);
	}
	// --------------------------------------------------------------------------
}
