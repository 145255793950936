import React, { ReactNode } from 'react';
import { WprBaseViewComponent } from '../../../../wpr-framework/view/component/WprBaseViewComponent';
import { CallForwardingSelectView } from './CallForwardingSelectView';
import phoneIcon from '/shared/image/common/logo_iTSCOMphone.png';
import nextIcon from '/shared/image/common/next_icon_orange.png';
import { ProgressBar } from '../../common/progress_bar/ProgressBar';
import { MatTypography } from '../../../../wpr-framework/component/controls/material/MatTypography';
import { Divider, Grid, Table, TableBody, Typography, TableCell, TableHead, TableRow } from '@material-ui/core';
import { MatButton } from '../../../../wpr-framework/component/controls/material/MatButton';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { MatTableContainer } from '../../../../wpr-framework/component/controls/material/MatTableContainer';
import { WprRowInfo } from '../../../../wpr-framework/component/props/WprRowInfo';
import { MatTableRow } from '../../../../wpr-framework/component/controls/material/MatTableRow';
import { MatCheckBox } from '../../../../wpr-framework/component/controls/material/MatCheckBox';
import styles from './CallForwardingSelect.scss';
import { IdentificationLabel } from '../../../controls/IdentificationLabel';

/**
 * 着信転送申込み用コンポーネント
 */
export class CallForwardingSelect extends WprBaseViewComponent {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: any) { super(new CallForwardingSelectView(), props); }
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** 着信転送申込みビュー情報 */
	public get viewInfo(): CallForwardingSelectView	{ return this.view as CallForwardingSelectView;	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * スタイルオブジェクト取得
	*/
	public getStylesObject(): any { return styles; }

	/**
	 * リストコントロール定義登録
	*/
	protected onRegisterListRender(): void {
		this.addListRender('ContractInfoList', null, this.onContractInfoListRender);
		this.addListRender('CallOptionList', null, this.onCallOptionListRender);
	}

	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		return (
			<>
				<div className='pageCard'>
					<div className="pageHeader stepFlow">
						<ProgressBar name='progressBar' view={this.view} />
					</div>
					<div className='sub-logo2'>
						<img src={phoneIcon} />
					</div>
					<div className='main net'>
						<div className='pageTitle'>
							<div className='title_left'>
								<MatTypography name='title' className='title_text' view={this.view} />
								<Typography className='title_sentence'>ご希望の変更ボタンをクリックしてください。</Typography>
							</div>
						</div>
						<div className='title_maru' style={{marginTop:'30px'}}>基本情報</div>
						<div className='constractTable'>
							<Grid container className='feeRow'>
								<Grid item xs={12} md={5} className='option'>
									<Typography className='courseName'>ケーブルプラス電話</Typography>
								</Grid>
								<Grid item xs={12} md={7} className='optstatus'>
									<MatTypography name='contractStatus' className='itemUnit_bold' view={this.view}/>
								</Grid>
							</Grid>	
						</div>
						<Grid container>
							<Grid item xs={12}>
								<MatTableContainer listName={'ContractInfoList'} view={this.view}>
									<Table className ='optionTable'>
										<colgroup>
											<col/>
											<col/>
										</colgroup>
										<TableBody className='optionBody'>
											{this.renderList('ContractInfoList')}
										</TableBody>
									</Table>
								</MatTableContainer>
							</Grid>
						</Grid>
						<div className='itscomNet_img'>
							<a href='https://www.itscom.co.jp/service/ipphone/course.html' className='courseUrl' target='_blank'>
								<b>オプション詳細 </b>
								<img src={nextIcon} alt='' className='orange_circleArrow'/>
							</a>
						</div>	
						<div className='title_maru'>オプション</div>
						<Grid container>
							<Grid item xs={12}>
								<MatTableContainer listName={'CallOptionList'} view={this.view} noDataUseHeader noDataMessage=' '>
									<Table className='optionSelsctPcTable'>
										<colgroup>
											<col style={{width: '20%'}}/>
											<col style={{width: '40%'}}/>
											<col style={{width: '40%'}}/>
										</colgroup>
										<TableHead className='hedderrow'>
											<TableRow className='toptable'>
												<TableCell align='center' className='border_right font_bold'>選択</TableCell>
												<TableCell align='center' className='border_right font_bold'>オプション名</TableCell>
												<TableCell align='center' className='font_bold'>月額料金</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{this.renderList('CallOptionList')}
										</TableBody>
									</Table>
								</MatTableContainer>
							</Grid>
						</Grid>
						<ul className='gray'>
							<u className='title'>≪着信転送≫</u>
							<li>着信転送のお申込みには本人確認が必要になります。</li>
							<li>
								転送先には次の番号を設定できません。
								<br/>110番や104番などの3ケタの番号、0120-・・・、0800-・・・、050-・・・(一部の番号)、00△△で始まる番号など、#ダイヤル、伝言ダイヤル*、国際電話の番号
							</li>
							<li>転送先から別の転送先までの通話料は、転送先のご負担とります。</li>
							<li>スケジュール転送の設定は、My auでご登録ください。電話番号からは設定できません。転送先の電話機には、発信者の電話番号が表示される場合があります。</li>
						</ul>
						<div style = {{marginBottom: '50px'}}>
							<ul className='ul_kome attention_fontSize' style={{marginBottom: '0', marginTop: '0px'}}>
								<li>
									着信転送のお申込みについては本人確認書類の提出が必要になります。<br/>お手元にご準備の上、お手続きをお願いいたします。
								</li>
							</ul>
						</div>
					</div>
					<Divider className='select1_underSpace'/>
					<div className='pageFooter footer-space'>
						<div>
							<MatButton name='next' view={this.view} className='orangeButton orangeButton_parts' >
								確認
								<NavigateNextIcon />
							</MatButton>
						</div>
						<div>
							<MatButton name='back' view={this.view} className='backButton' >
								<NavigateBeforeIcon />
								戻る
							</MatButton>
						</div>
					</div>
				</div>
			</>
		);
	}
	// --------------------------------------------------------------------------

	// リスト描画  ---------------------------------------------------------------
	/**
	 * リスト名=ContractInfoList
	 */
	public onContractInfoListRender(rowInfo: WprRowInfo): any {
		return (
			<MatTableRow view={this.view} row={rowInfo} className='hedderrow'>
				<TableCell>
					<MatTypography name='itemName' view={this.view} row={rowInfo}/>
				</TableCell>
				<TableCell className='optionDetail'>
					<MatTypography name='itemValue' className='itemData' view={this.view} row={rowInfo}/>
				</TableCell>
			</MatTableRow>
		);
	}

	/**
	 * リスト名=CallOptionList
	 */
	public onCallOptionListRender(rowInfo: WprRowInfo): any {
		return (
			<MatTableRow view={this.view} row={rowInfo}>
				<TableCell align='center' className='border_right service'>
					<MatCheckBox name='check' view={this.view} row={rowInfo}/>
				</TableCell>
				<TableCell className='border_right service'>
					<MatTypography name='optionName' className='courseSize' view={this.view} row={rowInfo}/>
				</TableCell>
				<TableCell align='center' className='service'>
					<MatTypography name='optionFee' className='courseFee' view={this.view} row={rowInfo}/>
					<MatTypography name='payUnit' className='courseUnit' view={this.view} row={rowInfo}/>
				</TableCell>
			</MatTableRow>
		);
	}
	// --------------------------------------------------------------------------
}
