import React, { ReactNode } from 'react';
import { WprBaseViewComponent } from '../../../../../wpr-framework/view/component/WprBaseViewComponent';
import { Typography, Grid, Divider, TableCell, Table, TableBody } from '@material-ui/core';
import { MatTypography } from '../../../../../wpr-framework/component/controls/material/MatTypography';
import { ProgressBar } from '../../../common/progress_bar/ProgressBar';
import { MatButton } from '../../../../../wpr-framework/component/controls/material/MatButton';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { ModNetConfirmView } from './ModNetConfirmView';
import { WprContainer } from '../../../../../wpr-framework/component/controls/WprContainer';
import { MatTableRow } from '../../../../../wpr-framework/component/controls/material/MatTableRow';
import { WprRowInfo } from '../../../../../wpr-framework/component/props/WprRowInfo';
import { MatTableContainer } from '../../../../../wpr-framework/component/controls/material/MatTableContainer';
import netIcon from '/shared/image/common/logo_iTSCOMNET.png';
import { MatSelect } from '../../../../../wpr-framework/component/controls/material/MatSelect';
import styles from './ModNetConfirm.scss';
import { WprSpan } from '../../../../../wpr-framework/component/controls/WprSpan';

/**
 * [サービス変更申し込み]申込内容の確認用コンポーネント
 */
export class ModNetConfirm extends WprBaseViewComponent {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: any) { super(new ModNetConfirmView(), props); }
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** [サービス変更申し込み]申込内容の確認ビュー情報 */
	public get viewInfo(): ModNetConfirmView	{ return this.view as ModNetConfirmView;	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * スタイルオブジェクト取得
	*/
	public getStylesObject(): any { return styles; }


	protected onRegisterListRender(): void {
	
		this.addListRender('ConfirmListModel', null, this.onCourseListModelRender);
		this.addListRender('BefConfirmList', null, this.onBefConfirmListRender);
		// this.addListRender('CampaignListModel', null, this.onCampaignListModelRender);
	}
	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		return (
			<>
			<div className='pageCard'>
				<div className="pageHeader stepFlow">
					<ProgressBar name='progressBar' view={this.view} />
				</div>
				<div className='sub-logo2'>
					<img src={netIcon} />
				</div>
				<div className='main net'>
					<div className='pageTitle'>
						<div className='title_left'>
							<MatTypography name='title' className='title_text' view={this.view}/>
							<Typography className='title_comment'>以下の内容でよろしければ、「申込み」ボタンをクリックしてください。</Typography>
						</div>
					</div>
					<WprContainer mode='キャンペーンコード.有' view={this.view}>
						<div className='campaignArea'>
							<span className='campaignTitle' style={{ color: '#484848', fontWeight:'700'}}>適用キャンペーン</span><br/>
							<MatTypography name='campaignName' style={{ color: '484848'}} view={this.view} className='campaignText'/>
							<MatTypography name='campaignExplanation' style={{ color: '484848'}} view={this.view}className='campaignText'/>
						</div>
					</WprContainer>
					<div className='feeTable'>
						<div className='feeTable'>
							<Grid container className='feeRow'>
								<Grid item xs={12} md={4} className='feeTitle'>
									<div>現在の月額利用料の合計</div>
								</Grid>
								<Grid item xs={12} md={8} className='currentFeeArea courseValue'>
									{/* <span className='feeValue'>
										<MatTypography name={'curFee'} view={this.view} className='itemUnit'/>
										<span className='feeUnit'>円</span>
									</span> */}
									<Grid item xs={12} md={8} className='feeVal courseValue'>
										<MatTypography name={'curFee'} className='itemUnit unit' view={this.view}/>
										<span className='feeUnit' style={{fontWeight: '500'}}>円</span>
									</Grid>
									<WprContainer mode='お得パック.加入中' view={this.view}>
										<span className='otokuName'>
											<MatTypography name='otokuPlan' view={this.view} style={{color: '#333333'}}/>
										</span>
									</WprContainer>
								</Grid>
							</Grid>
							<Grid container className='feeRow'>
								<Grid item xs={12} md={4} className='feeTitle'>
									<div>新しい月額利用料の合計</div>
								</Grid>
								<Grid item xs={12} md={8} className='feeVal courseValue'>
									<WprContainer mode='新月額利用料.料金' view={this.view}>
										<MatTypography name={'newFee'}  className='newFeeUnit' view={this.view} />
										<span className='feeUnit_bold'>円</span>
									</WprContainer>
									<WprContainer mode='新月額利用料.※' view={this.view}>
										<Typography className='itemUnit'>※担当者より数日中にご連絡させていただきます。</Typography>
									</WprContainer>
								</Grid>
							</Grid>
							<WprContainer mode={'直接申込.表示'} view={this.view}>
								<Grid container className='feeRow'>
									<Grid item xs={12} md={4} className='feeTitle'>
										<div>契約開始日</div>
									</Grid>
									<Grid item xs={12} md={8} className='feeVal courseValue'>
										<MatTypography style={{fontWeight:'500'}} name='startDate' className='itemUnit' view={this.view}/>
									</Grid>
								</Grid>	
							</WprContainer>
						</div>
					</div>
					<div className='itemTitle title_maru'>変更申込み内容</div>
					<div className='feeTable nonMargin'>
						<Grid container>
							<Grid item xs={12}>
								<MatTableContainer listName={'ConfirmListModel'} view={this.view}>
									<Table className ='optionTable' style={{marginBottom: '0px'}}>
										<colgroup>
											<col style={{width: '42%'}}/>
											<col/>
										</colgroup>
										<TableBody className='optionBody'  style={{marginBottom: '0px'}}>
											{this.renderList('ConfirmListModel')}
										</TableBody>
									</Table>
								</MatTableContainer>
							</Grid>
						</Grid>
					</div>
					<div className='feeTable'>
						<Grid container>
							<Grid item xs={12}>
								<MatTableContainer listName={'BefConfirmList'} view={this.view}>
									<Table className ='optionTable' style={{marginBottom: '0px'}}>
										<colgroup>
											<col style={{width: '42%'}}/>
											<col/>
										</colgroup>
										<TableBody className='optionBody'  style={{marginBottom: '0px'}}>
											{this.renderList('BefConfirmList')}
										</TableBody>
									</Table>
								</MatTableContainer>
							</Grid>
						</Grid>
					</div>
					<div className='attention_fontSize'>
						<ul className='ul_kome'>
							<li>
								表示されている月額は定価となります。実際の請求金額とは異なる場合があります。
							</li>
							<li>
								月額利用料の合計には割引も適用されています。（キャンペーン等の期間限定割引を除く。）
							</li>
							<li>
								キャンペーン等の期間限定割引につきましては、契約内容の変更により割引が継続されない場合がございます。
							</li>
							<WprContainer mode='請求書有料発行.表示' view={this.view}>
								<li>
									現在お支払い方法が未設定または現金振込となっております。<br/>お支払いが発生した際は、請求書（発行手数料330円［税込］/通）をお送りいたします。<br/>
									クレジットカード、口座振替へお支払い方法の変更が可能です。詳細はこちらをご確認ください。
								</li>
							</WprContainer>
						</ul>
					</div>
					<WprContainer mode='工事エリア.表示' view={this.view}>
						<div className='title_space itemTitle title_maru'>作業の希望日時</div>
						<div className='feeTable'>
							<Grid container className='feeRow'>
								<Grid item xs={12} md={4} className='feeTitle'>
									<div>第一希望</div>
								</Grid>
								<Grid item xs={12} md={8} className='feeVal courseValue value'>
									<MatTypography name='desiredDate1' view={this.view} className='itemUnit' style={{marginRight: '20px'}}/>
									<MatSelect name='selectTime1' vm='時間帯' view={this.view} className='itemSelect'/>
								</Grid>
							</Grid>	
							<Grid container className='feeRow'>
								<Grid item xs={12} md={4} className='feeTitle'>
									<div>第二希望</div>
								</Grid>
								<Grid item xs={12} md={8} className='feeVal courseValue value'>
									<MatTypography name='desiredDate2' view={this.view} className='itemUnit' style={{marginRight: '20px'}}/>
									<MatSelect name='selectTime2' vm='時間帯' view={this.view} className='itemSelect'/>
								</Grid>
							</Grid>	
							<Grid container className='feeRow'>
								<Grid item xs={12} md={4} className='feeTitle'>
									<div>第三希望</div>
								</Grid>
								<Grid item xs={12} md={8} className='feeVal courseValue value'>
									<MatTypography name='desiredDate3' view={this.view} className='itemUnit' style={{marginRight: '20px'}}/>
									<MatSelect name='selectTime3' vm='時間帯' view={this.view} className='itemSelect'/>
								</Grid>
							</Grid>	
						</div>
						<div className='itemTitle title_maru'>工事費</div>
						<div className='feeTable'>	
							<Grid container className='feeRow'>
								<Grid item xs={12} md={4} className='feeTitle'>
									<div>工事費</div>
								</Grid>
								<Grid item xs={12} md={8} className='feeVal courseValue'>
									<WprSpan name='cost' view={this.view} className='itemUnit'/>
									<MatTypography name='payUnit' className='itemUnit' view={this.view}/>
								</Grid>
							</Grid>
						</div>
						<div className='feeTable' style={{marginTop: '30px'}}>	
							<Grid container className='feeRow'>
								<Grid item xs={12} md={4} className='feeTitle'>
									<div>前日連絡先</div>
								</Grid>
								<Grid item xs={12} md={8} className='feeVal courseValue'>
									<MatTypography name='beforeContact' className='itemUnit' view={this.view} />
								</Grid>
							</Grid>	
							<Grid container className='feeRow'>
								<Grid item xs={12} md={4} className='feeTitle'>
									<div>当日連絡先</div>
								</Grid>
								<Grid item xs={12} md={8} className='feeVal courseValue'>
									<MatTypography name='sameDayContact' className='itemUnit' view={this.view} />
								</Grid>
							</Grid>
							<Grid container className='feeRow'>
								<Grid item xs={12} md={4} className='feeTitle'>
									<div>当日立ち合い者</div>
								</Grid>
								<Grid item xs={12} md={8} className='feeVal courseValue'>
									<MatTypography name='name' className='itemUnit' view={this.view} />
								</Grid>
							</Grid>
							<Grid container className='feeRow'>
								<Grid item xs={12} md={4} className='feeTitle'>
									<div>セキュリティ会社</div>
								</Grid>
								<Grid item xs={12} md={8} className='feeVal courseValue'>
									<MatTypography name='securityDisp' view={this.view} className='itemUnit'/>
								</Grid>
							</Grid>
						</div>
					</WprContainer>
					{/* <WprContainer mode='初期費用キャンペーン.有' view={this.view}>
						<div className='title_space'>
							<div className='itemTitle title_maru'>キャンペーン</div>
						</div>
						<div className='feeTable'>	
							<Grid container className='feeRow'>
								<Grid item xs={12} md={4} className='feeTitle'>
									<div>内容</div>
								</Grid>
								<Grid item xs={12} md={8} className='feeVal courseValue'>
									<MatTypography name='campaignName' view={this.view} className='itemUnit'/>
								</Grid>
							</Grid>
							<Grid container className='feeRow'>
								<Grid item xs={12} md={4} className='feeTitle'>
									<div>工事・配送費</div>
								</Grid>
								<Grid item xs={12} md={8} className='feeVal courseValue'>
									-<MatTypography name='campaignDiscount' view={this.view} component='span' className='itemUnit'/><span className='itemUnit'>円</span>
								</Grid>
							</Grid>
						</div>
					</WprContainer> */}
				</div>
				<Divider className='select1_underSpace'/>
				<div className='pageFooter footer-space'>
					<div>
						<MatButton name='next' view={this.view} className='orangeButton orangeButton_parts' >
							この内容で申込む
							<NavigateNextIcon />
						</MatButton>
					</div>
					<div>
						<MatButton name='back' view={this.view} className='backButton' >
							<NavigateBeforeIcon />
							戻る
						</MatButton>
					</div>
				</div>
			</div>
			</>
		);
	}
	// --------------------------------------------------------------------------

	// リスト描画  ---------------------------------------------------------------
	/**
	 * リスト名=BefConfirmList
	 */
	public onBefConfirmListRender(rowInfo: WprRowInfo): any {
		return (
			<MatTableRow view={this.view} row={rowInfo} className='hedderrow'>
				<TableCell>
					<MatTypography name='itemName' view={this.view} row={rowInfo}/>
				</TableCell>
				<TableCell className='courseDetail'>
					<MatTypography name='itemData' className='itemData' style={{fontWeight: '500'}} view={this.view} row={rowInfo}/>
				</TableCell>
			</MatTableRow>
		);
	}

	/**
	 * リスト名=ConfirmListModel
	 */
	public onCourseListModelRender(rowInfo: WprRowInfo): any {
		return (
			<MatTableRow view={this.view} row={rowInfo} className='hedderrow'>
				<TableCell>
					<MatTypography name='itemName' view={this.view} row={rowInfo}/>
				</TableCell>
				<TableCell className='courseDetail'>
					<MatTypography name='itemData' className='itemData' style={{fontWeight: '500'}} view={this.view} row={rowInfo}/>
					<MatTypography name='comment' className='itemData' view={this.view} row={rowInfo}/>
				</TableCell>
			</MatTableRow>
		);
	}
	/**
	 * リスト名=CampaignListModel
	 */
	// public onCampaignListModelRender(rowInfo: WprRowInfo): any {
	// 	return (
	// 		<MatTypography name='campaignName' style={{ color: '484848'}} view={this.view} row={rowInfo} className='campaignText'/>
	// 	);
	// }
	// --------------------------------------------------------------------------

}
