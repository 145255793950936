/**
 * 完了情報モデル
 */
export class CompleteModel {
	// public 変数	-----------------------------------------------------------
	public title: string;		// タイトル
	public user: string;		// ユーザー
	public massage1: string;	// 本文1
	public bikou1: string;		// 備考1
	public massage2: string;	// 本文2
	public bikou2: string;		// 備考2
	public massage3: string;	// 本文3
	public massage4: string;	// 本文4
	// ------------------------------------------------------------------------
}
