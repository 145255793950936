import { WprValueMap } from '../../../../wpr-framework/model/WprValueMap';
import { WprValueMapSetter } from '../../../../wpr-framework/model/WprValueMapSetter';
import { WprBaseServiceValueMap } from '../../../../wpr-framework/model/WprBaseServiceValueMap';

/**
 * クーポン容量
 */
export class CouponCapacityValueMap extends WprBaseServiceValueMap {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() {
		super('couponCapacity');
	}
	// --------------------------------------------------------------------------

	// override メソッド  -------------------------------------------------------
	/**
	 * ValueMap登録処理
	 * @param vmSetter ValueMap設定
	 */
	public registerValueMap(vmSetter: WprValueMapSetter): void {
		// TODO : サービスを呼び出してValueMap設定
		// let vm = new WprValueMap();
		// vm.addValue(key, value);
		// ...
		// vmSetter.addValueMap(this.valueMapName, vm);
		vmSetter.addValueMap(this.valueMapName, new WprValueMap());
	}
	// --------------------------------------------------------------------------
}
