import { WprBaseViewCore } from '../../WprBaseViewCore';
import { WprListInfo } from './WprListInfo';
import { WprBaseObject } from '../../../WprBaseObject';
import { WprActionInfo } from '../../../action/WprActionInfo';
import { IWprConfigOption } from '../IWprConfigOption';
import { WprReflectUtil } from '../../../util/WprReflectUtil';
import { WprValidError } from '../../validation/WprValidError';
import { WprControlInfo } from '../WprControlInfo';
import { WprRowInfo } from '../../../component/props/WprRowInfo';
import { WprEventName } from '../../../action/WprEventActionInfo';

/**
 * リスト情報マップ基本クラス
 */
export abstract class WprBaseListInfoMap<T extends WprListInfo> extends WprBaseObject {
	// private 変数  ------------------------------------------------------------
	private m_ViewCore: WprBaseViewCore									// ビュー情報
	private m_ListInfoMap: Map<string, T>	= new Map<string, T>();		// リスト情報マップ
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	public constructor(viewCore: WprBaseViewCore) {
		super();
		this.m_ViewCore = viewCore;
	}
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** ビュー情報 */
	public get viewCore(): WprBaseViewCore 	{ return this.m_ViewCore;	}
	/** データタイプ */
	public get dataType(): string 			{ return 'リスト'; 			}
	/** データタイプ */
	public get dataEType(): string 			{ return 'List'; 			}
	// --------------------------------------------------------------------------

	// abstract メソッド  --------------------------------------------------------
	/**
	 * リストデータマップ取得
	 * @returns リストデータマップ
	 */
	public abstract getListDataMap(): Map<string, string>;

	/**
	 * 新しいView情報取得
	 * @param listName リスト名
	 */
	public abstract getNewListInfo(listName: string): T;

	/**
	 * 新しいアクション情報取得
	 * @param name アクション名
	 * @param listName リスト名
	 * @param actionMethod リストアクションメソッド
	 * @param isValid バリデーションフラグ
	 * @param isEvent イベントフラグ
	 */
	public abstract getNewActionInfo(name: string, listName: string, actionMethod: (param: any, item: any) => void, isValid: boolean, isEvent: boolean): WprActionInfo;
	// --------------------------------------------------------------------------

	// public メソッド  ----------------------------------------------------------
	/**
	 * リスト情報登録処理
	 * @param listName リスト名
	 */
	public addListInfo(listName: string) {
		if (this.m_ListInfoMap.has(listName) === false) {
			const tinfo = this.getNewListInfo(listName);
			this.m_ListInfoMap.set(listName, tinfo);
		}
	}

	/**
	 * リストコントロール定義追加
	 * @param name 名前
	 * @param description 説明
	 * @param listName リスト名
	 * @param option オプション
	 * @param checkError エラーチェックフラグ
	 * @returns リスト情報
	 */
	public addListConfig(name: string, description: string, listName: string, option?: IWprConfigOption, checkError: boolean = true): T {
		let tinfo = null;
		const modelName = listName;
		if (listName.includes('.') === true)
			listName = listName.substr(0, listName.indexOf('.'));
		if (this.getListDataMap().has(listName) === true) {
			if (this.m_ListInfoMap.has(listName) === false) {
				tinfo = this.getNewListInfo(listName);
				this.m_ListInfoMap.set(listName, tinfo);
			}
			else {
				tinfo = this.m_ListInfoMap.get(listName);
			}
			const cinfo = tinfo.addConfig(name, description, option) as WprControlInfo;
			if (modelName.includes('.') === true)
				cinfo.setSubModel(modelName);
		}
		else if (checkError === true) {
			this.m_ViewCore.addErrorLog(`add${this.dataEType}Config：${this.dataType}名に誤りがあります。[${listName}]`);
		}
		return tinfo;
	}

	/**
	 * リストアクション登録処理
	 * @param name アクション名
	 * @param listName リスト名
	 * @param actionMethod リストアクションメソッド
	 * @param isValid バリデーションフラグ
	 * @param isEvent イベントフラグ
	 */
	public addListAction(name: string, listName: string, actionMethod: (param: any, item: any) => void, isValid: boolean = false, isEvent: boolean = false): WprActionInfo {
//		let isBlur = false;
		let aname = name;
		if (listName)
			aname = name + '@' + listName;
		if (this.m_ViewCore.actionMap.has(aname) === false) {
//			if (name.endsWith('@@blur') === true) {
//				name = name.substring(0, name.length-6);
//				isBlur = true;
//			}
			const ainfo = this.getNewActionInfo(name, listName, actionMethod, isValid, isEvent);
			this.m_ViewCore.actionMap.set(aname, ainfo);
			let tinfo = null;
			if (this.m_ListInfoMap.has(listName) === false) {
				tinfo = this.getNewListInfo(listName);
				this.m_ListInfoMap.set(listName, tinfo);
			}
			else {
				tinfo = this.m_ListInfoMap.get(listName);
			}
//			if (isBlur === false) {
				const cinfo = tinfo.addConfig(name, '');
				cinfo.useFormControl = false;
//			}
			return ainfo;
		}
		else {
			this.m_ViewCore.addErrorLog(`同一のリストアクションが既に登録されています。[${name}][${listName}]`);
			return null;
		}
	}

	/**
	 * リストイベントアクション登録処理
	 * @param name アクション名
	 * @param listName リスト名
	 * @param eventName イベント名
	 * @param actionMethod リストアクションメソッド
	 */
	public addListEventAction(name: string, listName: string, eventName: WprEventName, actionMethod: (name: any, row: WprRowInfo, event: any) => void): void {
		const linfo = this.getListInfo(listName);
		linfo.addListEventAction(name, eventName, actionMethod);
	}

	/**
	 * リスト情報取得処理
	 * @param listName リスト名
	 * @returns リスト情報
	 */
	public getListInfo(listName: string): WprListInfo {
		if (this.m_ListInfoMap.has(listName) === true)
			return this.m_ListInfoMap.get(listName);
 		return null;
	}

	/**
	 * リストコントロール情報取得
	 * @param listName リスト名
	 * @param controlName コントロール名
	 * @param index 行インデクス
	 * @returns コントロール情報
	 */
	public getListControlInfo(listName: string, controlName: string, index: number): WprControlInfo {
		if (this.m_ListInfoMap.has(listName) === true)
			return this.m_ListInfoMap.get(listName).getRowControlInfo(controlName, index);
		return null;
	}

	/**
	 * リストカテゴリコントロール情報リスト取得
	 * @param listName リスト名
	 * @param category  カテゴリ名
	 * @param index 行インデクス
	 * @returns コントロール情報リスト
	 */
	public getListCategoryControlList(listName: string, category: string, index: number): WprControlInfo[] {
		if (this.m_ListInfoMap.has(listName) === true)
			return this.m_ListInfoMap.get(listName).getRowCategoryControlList(category, index);
		return new Array();
	}

	/**
	 * リストデータ設定処理
	 * @param listName リスト名
	 * @param listData リストデータ
	 * @returns trueの場合、設定成功
	 */
	public setListData(listName: string, listData: Array<any>): boolean {
		if (this.getListDataMap().has(listName) === true) {
			const prop = this.getListDataMap().get(listName);
			if (this.m_ListInfoMap.has(listName) === true)
				this.m_ListInfoMap.get(listName).setListData(listData);
			this.m_ViewCore.viewInfo.setViewModeListData(listName, listData);
			WprReflectUtil.setProperty(this.m_ViewCore, prop, listData);
			this.refreshListView(listName);
			return true;
		}
		return false;
	}

	/**
	 * リストデータステータス設定
	 * @param listData リストデータステータス
	 */
	public setListDataState(listData: any) {
		this.m_ListInfoMap.forEach((info, key, map) => {
			listData[info.name] = info.listData;
		});
	}

	/**
	 * リストデータをビューに反映する
	 * @param listName リスト名
	 * @returns trueの場合、成功
	 */
	public refreshListView(listName?: string): boolean {
		let ret = false;
		for(const linfo of this.m_ListInfoMap.values()) {
			if (listName === undefined || listName === linfo.name) {
				linfo.refreshView();
				ret = true;
			}
		}
		return ret;
	}

	/**
	 * リストデータをビューに反映する
	 * @param listName リスト名
	 * @returns trueの場合、成功
	 */
	public refreshListModel(listName?: string): boolean {
		let ret = false;
		for(const linfo of this.m_ListInfoMap.values()) {
			if (listName === undefined || listName === linfo.name) {
				linfo.refreshModel();
				ret = true;
			}
		}
		return ret;
	}

	/**
	 * リストデータをクリアする
	 * @param listName リスト名
	 * @returns trueの場合、成功
	 */
	public clearListValue(listName?: string): boolean {
		let ret = false;
		for(const linfo of this.m_ListInfoMap.values()) {
			if (listName === undefined || listName === linfo.name) {
				linfo.clearValue();
				ret = true;
			}
		}
		return ret;
	}

	/**
	 * リスト入力チェック
	 * @param listName リスト名
	 * @param isErrorCheck エラーチェックフラグ
	 * @return trueの場合、入力済みでエラーなし
	 */
	public isInputList(listName?: string): boolean {
		for(const linfo of this.m_ListInfoMap.values()) {
			if (listName === undefined || listName === linfo.name) {
				if (linfo.isInput() === false)
					return false;
			}
		}
		return true;
	}

	/**
	 * リスト情報クリア処理
	 */
	public clearListInfo(): void {
		this.m_ListInfoMap.forEach((tinfo, key, map) => {
			tinfo.clear();
		});
		this.m_ListInfoMap.clear();
	}

	/**
	 * 入力チェック処理
	 * @param actionName アクション名称
	 * @param validError バリデーションエラー情報
	 * @param isControl コントロールチェックフラグ
	 * @param row 行情報
	 */
	public checkValidate(actionName: string, validError: WprValidError, isControl: boolean, row?: any): void {
		this.m_ListInfoMap.forEach((info, key, map) => {
			info.checkValidate(actionName, validError, isControl, row);
		});
	}

	/**
	 * 入力エラーリスト取得
	 * @returns 入力エラーリスト
	 */
	public getValidErrorList(): WprValidError[] {
		const errorList = new Array();
		this.m_ListInfoMap.forEach(info => {
			info.getValidErrorList(errorList);
		});
		return errorList;
	}

	/**
	 * リストコントロール値取得
	 * @param name コントロール名
	 * @param listName リスト名
	 * @param row 行情報
	 */
	public getControlValue(name: string, listName: string, row: any): any {
		if (this.getListDataMap().has(listName) === true) {
			if (this.m_ListInfoMap.has(listName) === true) {
				const linfo = this.m_ListInfoMap.get(listName);
				if (linfo.listData.includes(row) === true) {
					const idx = linfo.listData.indexOf(row);
					const cinfo = linfo.getRowControlInfo(name, idx); 
					if (cinfo != null)
						return cinfo.value;
					this.m_ViewCore.addErrorLog(`getListControlValue：コントロールが見つかりません。[${listName}][${name}]`);
				}
				else {
					this.m_ViewCore.addErrorLog(`getListControlValue：行データに誤りがあります。[${listName}]`);
				}
			}
			else {
				this.m_ViewCore.addErrorLog(`getListControlValue：リスト情報は登録されていません。[${listName}]`);
			}
		}
		else {
			this.m_ViewCore.addErrorLog(`getListControlValue：${this.dataType}名に誤りがあります。[${listName}]`);
		}
		return null;
	}

	/**
	 * リストコントロール情報取得
	 * @param name コントロール名
	 * @param listName リスト名
	 * @param row 行情報
	 * @returns リストコントロール情報
	 */
	public getControl(name: string, listName: string, row: any): WprControlInfo {
		if (this.getListDataMap().has(listName) === true) {
			if (this.m_ListInfoMap.has(listName) === true) {
				const linfo = this.m_ListInfoMap.get(listName);
				if (linfo.listData.includes(row) === true) {
					const idx = linfo.listData.indexOf(row);
					return linfo.getRowControlInfo(name, idx); 
				}
				else {
					this.m_ViewCore.addErrorLog(`getListControlValue：行データに誤りがあります。[${listName}]`);
				}
			}
			else {
				this.m_ViewCore.addErrorLog(`getListControlValue：リスト情報は登録されていません。[${listName}]`);
			}
		}
		else {
			this.m_ViewCore.addErrorLog(`getListControlValue：${this.dataType}名に誤りがあります。[${listName}]`);
		}
		return null;
	}
	
	/**
	 * リスト権限再設定
	 * @param listName リスト名
	 * @returns trueの場合、成功
	 */
	 public refreshListAuth(listName?: string): boolean {
		let ret = false;
		for(const linfo of this.m_ListInfoMap.values()) {
			if (listName === undefined || listName === linfo.name) {
				linfo.refreshAuth();
				ret = true;
			}
		}
		return ret;
	}
	// --------------------------------------------------------------------------
}