/**
 * 入力エラー情報
 */
export class WprValidErrorInfo {
	// private 変数  ------------------------------------------------------------
	private m_ControlName: string	= null;			// コントロール名
	private m_ControlDesc: string	= null;			// コントロール説明
	private m_RowIndex: number		= -1;			// 行インデクス
	private m_ValidatorName: string	= null;			// バリデータ名称
	private m_ValidatorOption: any	= undefined;	// バリデータオプション
	private m_ErrorMessage: string	= null;			// エラーメッセージ
	private m_IsCustom: boolean		= false;		// カスタムフラグ
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** コントロール名 */
	public get controlName(): string 		{ return this.m_ControlName;		}	public set controlName(name: string) { this.m_ControlName = name;	}
	/** コントロール説明 */
	public get controlDesc(): string 		{ return this.m_ControlDesc;		}
	/** 行インデクス */
	public get rowIndex(): number 			{ return this.m_RowIndex;			}
	/** バリデータ名称 */
	public get validatorName(): string 		{ return this.m_ValidatorName;		}
	/** バリデータオプション */
	public get validatorOption(): string 	{ return this.m_ValidatorOption;	}
	/** エラーメッセージ */
	public get errorMessage(): string 		{ return this.m_ErrorMessage;		}	public set errorMessage(msg: string) { this.m_ErrorMessage = msg;	}
	/** カスタムフラグ */
	public get isCustom(): boolean 			{ return this.m_IsCustom;			}	public set isCustom(custom: boolean) { this.m_IsCustom = custom;	}
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	public constructor(controlName: string, description: string, rowIndex: number, validName: string, option: any, message: string) {
		this.m_ControlName = controlName;
		this.m_ControlDesc = description;
		this.m_RowIndex = rowIndex;
		this.m_ValidatorName = validName;
		this.m_ValidatorOption = option;
		this.m_ErrorMessage = message;
	}
	// --------------------------------------------------------------------------
}
