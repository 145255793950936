import React, { ReactNode } from 'react';
import { IWprErrorProps } from '../props/IWprErrorProps';
import { WprBaseErrorComponent } from './WprBaseErrorComponent';

/**
 * ビュー入力エラープロパティ情報
 */
interface IWprViewErrorProps extends IWprErrorProps {
	tagName?: string;			// タグ名
	dispMax?: number;			// 表示最大数
	prefix?: string;			// 接頭辞
}

/**
 * ビュー入力エラーコントロール
 */
export class WprViewError extends WprBaseErrorComponent<IWprViewErrorProps> {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: IWprViewErrorProps) {
		super(props);
//		this.props.view.addViewErrorControl(this, props.subView);
		this.state = {
			errList: null
		};
	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		if (this.state.errList == null)
			return null;
		let list = null;
		if (this.props.row)
			list = this.state.errList.filter(minfo => (this.props.name === undefined || this.checkControlName(minfo.controlName) === true) && minfo.rowIndex === this.props.row.index);
		else
			list = this.state.errList.filter(minfo => this.props.name === undefined || this.checkControlName(minfo.controlName) === true);
		if (this.props.dispMax && list.length > this.props.dispMax) 
			list = list.splice(0, this.props.dispMax);
		if (this.props.tagName && this.props.tagName === 'div') {
			const errors = list.map((minfo, index) => 
				<div className={this.className('wpr-view-error-msg')} key={index}>{this.props.prefix}{this.getBrContent(minfo.errorMessage)}</div>
			);
			return (
				<>
					<div className={this.className('wpr-view-error')}>{errors}</div>
				</>
			);
		}
		else if (this.props.tagName && this.props.tagName === 'span') {
			const errors = list.map((minfo, index) => 
				<span className='wpr-view-error-msg' key={index}>{this.props.prefix}{this.getBrContent(minfo.errorMessage)}</span>
			);
			return (
				<>
					<span className={this.className('wpr-view-error')}>{errors}</span>
				</>
			);
		}
		else {
			const errors = list.map((minfo, index) =>
				<p className={this.className('wpr-view-error-msg')} key={index}>{this.props.prefix}{this.getBrContent(minfo.errorMessage)}</p>
			);
			return (
				<>
					{errors}
				</>
			);
		}
	}

	/**
	 * コントロール名称取得
	 * @returns コントロール名称
	 */
	public getName(): string {
		return 'WprViewError';
	}
	// --------------------------------------------------------------------------

	// private メソッド  --------------------------------------------------------
	/**
	 * <br>付きコンテント取得
	 * @param content コンテント
	 * @returns <br>付きコンテント
	 */
	private getBrContent(content: string): any[] {
		const contentList = new Array();
		let key = 1;
		while(content.includes('<br') === true) {
			const sidx = content.indexOf('<br');
			const eidx = content.indexOf('/>');
			contentList.push(content.substring(0, sidx));
			contentList.push(this.getBr(content.substring(sidx, eidx), key));
			content = content.substring(eidx+2);
			key++;
		}
		contentList.push(content);
		return contentList;
	}

	/**
	 * <br>取得（classNameのみ判定）
	 * @returns <br>
	 */
	private getBr(content: string, key: number): ReactNode {
		if (content.includes('className') === true) {
			const idx = content.indexOf('className');
			const str = content.substring(idx);
			const list = str.split('\'');
			return <br className={list[1]} key={key}/>;
		}
		return <br key={key}/>;
	}
	// --------------------------------------------------------------------------
}