import { MGeneral } from '../../entity/MGeneral';

/**
 * GetMGeneralリクエスト
 */
export class GetMGeneralRequest {
	// public 変数	-----------------------------------------------------------
	public generalList: MGeneral[];
	// ------------------------------------------------------------------------
}
