import { WprModel } from '../../../../../wpr-framework/view/deco/WprModel';
import { WprViewMode } from '../../../../../wpr-framework/view/deco/WprViewMode';
import { WprDI_ScopeModel } from '../../../../../wpr-framework/view/di/WprDI_ScopeModel';
import { CourseListModel } from '../../../../models/mypage/net/CourseListModel';
import { NetFlowModel } from '../../../../models/mypage/net/NetFlowModel';
import { MypageBaseView } from '../../../base/MypageBaseView';
import { AgreeMode } from '../../../common/common_agree/mode/AgreeMode';
import { ProgressBarData } from '../../../common/progress_bar/ProgressBarView';
import { MypageWizardMngr } from '../../../wizard/models/MypageWizardMngr';

/**
 * ネットその他サービス確認
 */
export class ModOthernetConfirmView extends MypageBaseView {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() { super('ModOthernetConfirmView'); }
	// --------------------------------------------------------------------------
	// モデル  ------------------------------------------------------------------
	@WprDI_ScopeModel('MypageWizardMngr')
	private m_MypageWizardMngr: MypageWizardMngr = null;	// 画面ウィザード用モデル
	@WprModel('NetInfo')
	private m_NetInfo: CourseListModel					= null;	// 画面遷移用
	@WprModel('NetFlowModel')
	private m_NetFlowModel: NetFlowModel					= null;		// ネット遷移情報
	// サブビュー/モード  --------------------------------------------------------
	@WprViewMode
	private m_AgreeMode = new AgreeMode();
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * コントロール定義設定
	 */
	public onRegisterConfig(): void {
		this.addConfig('confirm', '承諾', null);
		this.addConfig('cDispnamep', 'コース名', 'NetInfo');
	}

	/**
	* アクション登録
	*/
	public onRegisterAction(): void {
		this.addAction('next', this.onNext, true);
		this.addAction('back', this.onBack);
	}

	/**
	 * ビュー表示通知
	 */
	public onShowView(): void {
		this.m_MypageWizardMngr.initView(this, (result) => {
			this.registAccessLog();
			this.setStorageItem('b_url', window.location.href);
			const sendData: ProgressBarData = new ProgressBarData();
			sendData.progressBarList = this.m_MypageWizardMngr.getProgressModelList(this);
			this.sendChildData('fP_ev_progressBarIntital', sendData);
			this.m_NetInfo = this.m_MypageWizardMngr.getCache().m_NetInfo;
			this.m_NetFlowModel = this.m_MypageWizardMngr.getCache().m_NetFlowModel;
			this.refreshView();
		});
		this.setViewMode('agree','未承諾');
	}

	/**
	 * 値変更通知
	 * @param name 名前
	 * @param value 値
	 * @returns falseの場合、変更を取り消す
	 */
	public onChangeValue(name: string, value: any): boolean {
		if(name === 'confirm')	{
			if(value === true) {
				this.setViewMode('agree','承諾');
			}
			else {
				this.setViewMode('agree','未承諾');
			}
		}
		return true;
	}

	// アクション  ---------------------------------------------------------------
	/**
	 * 申し込む
	*/
	public onNext(param: any): void {
		this.refreshModel();
		this.m_MypageWizardMngr.goNext(this);
	}

	/**
	 * 戻る
	*/
	public onBack(param: any): void {
		this.m_MypageWizardMngr.goBack(this);
	}
	// --------------------------------------------------------------------------
}
