import { WprRowInfo } from '../../../component/props/WprRowInfo';
import { WprBaseObject } from '../../../WprBaseObject';
import { WprBaseViewCoreComponent } from '../WprBaseViewCoreComponent';

/**
 * アクション情報
 */
export class WprListRenderInfo extends WprBaseObject {
	// private 変数  ------------------------------------------------------------
	private m_ListName: string								= null;		// リスト名
	private m_Type: string									= null;		// 描画タイプ
	private m_ViewComponent: WprBaseViewCoreComponent<any>	= null;		// ビューコンポーネント
	private m_RenderMethod: (row: WprRowInfo) => any		= null;		// 描画メソッド
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** リスト名 */
	public get listName(): string 	{ return this.m_ListName;	}
	/** 描画タイプ */
	public get type(): string 		{ return this.m_Type;		}
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	public constructor(listName: string, type: string, viewComp: WprBaseViewCoreComponent<any>, renderMethod: (row: WprRowInfo) => void) {
		super();
		this.m_ListName = listName;
		this.m_Type = listName;
		this.m_ViewComponent = viewComp;
		this.m_RenderMethod = renderMethod;
	}
	// --------------------------------------------------------------------------

	// public メソッド  ----------------------------------------------------------
	/**
	 * 描画実行処理
	 * @param row 行情報
	 */
	public render(row: WprRowInfo): any {
		return this.m_RenderMethod.bind(this.m_ViewComponent)(row);
	}
	// --------------------------------------------------------------------------
}
