import { WprModel } from '../../../../wpr-framework/view/deco/WprModel';
import { WprDI_ScopeModel } from '../../../../wpr-framework/view/di/WprDI_ScopeModel';
import { ResetPasswordModel } from '../../../service/models/apimodels/ResetPasswordModel';
import { MypageBaseView } from '../../base/MypageBaseView';
import { MypageWizardMngr } from '../../wizard/models/MypageWizardMngr';

/**
 * 動画配信サービスパスワード再発行印刷画面
 */
export class VideoPassPrintView extends MypageBaseView {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() { super('VideoPassPrintView'); }
	// --------------------------------------------------------------------------

	// モデル  ------------------------------------------------------------------
	@WprModel('PasswordInfo')
	private m_PasswordInfo: ResetPasswordModel	= null;	// 顧客モデル
	@WprDI_ScopeModel('MypageWizardMngr')
	private m_MypageWizardMngr: MypageWizardMngr			= null;		// 画面ウィザード管理クラス
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * コントロール定義設定
	 */
	public onRegisterConfig(): void {
		this.addConfig('id', 'id', 'PasswordInfo', { bindName: 'idpID' });
		this.addConfig('password', 'パスワード', 'PasswordInfo', { bindName: 'password' });
		this.addConfig('date', '変更日', 'PasswordInfo', { bindName: 'date', converter: '日付（yyyy年MM月dd日）' });
	}

	/**
	 * アクション登録
	 */
	public onRegisterAction(): void {
		this.addAction('print', this.onPrint);
		this.addAction('close', this.onClose);
	}

	/**
	 * ビュー表示通知
	 */
	public onShowView(): void {
		this.m_MypageWizardMngr.initView(this, (result) => {
			this.registAccessLog();
			this.setStorageItem('b_url', window.location.href)
			this.m_PasswordInfo = this.m_MypageWizardMngr.getCache().passwordInfo;
			this.setViewData('sentenceData', this.m_PasswordInfo.passwordKana)
			this.refreshView();
		});
		this.m_PasswordInfo = this.getCookieByJson('pass');
		this.refreshView();
	}
	// --------------------------------------------------------------------------

	// アクション  ---------------------------------------------------------------
	/**
	 * 印刷
	 */
	public onPrint(param: any): void {
		window.print();
	}

	/**
	 * 閉じる
	 */
	public onClose(param: any): void {
		window.close();
	}
	// --------------------------------------------------------------------------
}

