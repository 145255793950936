/**
 * 契約情報
 */
export class ContractInfo {
	// public 変数	-----------------------------------------------------------
	public contractCourse: string;	// 契約コース名
	public contractAmount: string;	// 月額
	public contractId: string;		// 契約番号
	public contractStatus: string;	// 契約ステータス
	public equipmentTypeCd: string;	// device10000判定用
	public serviceCd: string;		// サービスコード
	public dummyFlg: boolean;		// ダミー端末フラグ
	public dupFlg: boolean;			// 重複フラグ
	// ------------------------------------------------------------------------
}
