import { MypageBaseView } from '../../../base/MypageBaseView';
import { WprModel } from '../../../../../wpr-framework/view/deco/WprModel';
import { WprDI_ScopeModel } from '../../../../../wpr-framework/view/di/WprDI_ScopeModel';
import { HMvnoTmpSaved } from '../../../../service/models/entity/HMvnoTmpSaved';
import { MvnoMnpInfo } from '../../../../service/models/apimodels/MvnoMnpInfo';

/**
 * MVNO_MNP開通問い合わせ
 */
export class MvnoMnpInquiryView extends MypageBaseView {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() { super('MvnoMnpInquiry'); }
	// --------------------------------------------------------------------------

	// モデル  ------------------------------------------------------------------
	@WprDI_ScopeModel('MvnoMnpInfo')
	@WprModel('MvnoMnpInfo')
	private m_MvnoMnpInfo: MvnoMnpInfo = null;	// MVNOプラン情報
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * コントロール定義設定
	 */
	public onRegisterConfig(): void {

		this.addConfig('iccId', 'ICCID', 'MvnoMnpInfo', { emptyValue: null, hissu: true });
		this.addConfig('phoneNumber', '回線番号', 'MvnoMnpInfo', { emptyValue: null, hissu: true, maxLength: 11, validation: '整数' });
		this.addConfig('familyName', '姓漢字', 'MvnoMnpInfo.customerInfo', { emptyValue: null, hissu: true });
		this.addConfig('firstName', '名漢字', 'MvnoMnpInfo.customerInfo', { emptyValue: null, hissu: true });
		this.addConfig('familyNameKana', '姓カナ', 'MvnoMnpInfo.customerInfo', { emptyValue: null, hissu: true, validation: '全角カナ' });
		this.addConfig('firstNameKana', '名カナ', 'MvnoMnpInfo.customerInfo', { emptyValue: null, hissu: true, validation: '全角カナ' });
	}

	/**
	 * アクション登録
	 */
	public onRegisterAction(): void {
		this.addAction('next', this.onNext, true);
		this.addAction('iccidLink', this.onIccidLink);
		this.addAction('back', this.onBack);
	}

	/**
	 * ビュー表示通知
	 */
	public onShowView(): void {
		this.registAccessLog();
		this.setStorageItem('b_url', window.location.href);
		this.refreshView();
	}
	// --------------------------------------------------------------------------

	// アクション  ---------------------------------------------------------------
	/**
	 * 次へ
	 */
	public onNext(param: any): void {
		this.refreshModel();
		this.pushPath('/mvno_mnp_002');
	}

	/**
	 * ICCIDとは
	 */
	public onIccidLink(param: any): void {
		// window.open('http://faq.itscom.net/its/web1247/faq/detail.aspx?id=28130&isCrawler=1');
		window.open('https://faq.itscom.net/faq/show/460');
	}
	
	/**
	* 戻る
	* 現行の契約内容一覧へ戻る
	*/
	public onBack(param: any): void {
		location.href = this.getOldUrl() + "Srvlist/Continfo_list/";
	}

	// --------------------------------------------------------------------------
}
