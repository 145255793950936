import { WprAuthorityMngr } from './WprAuthorityMngr';

/**
 * 権限設定
 */
export class WprAuthoritySetter {
	// private 変数  ------------------------------------------------------------
	private m_AuthMngr: WprAuthorityMngr		= null;			// 権限管理機能
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	public constructor(mngr: WprAuthorityMngr) {
		this.m_AuthMngr = mngr;
	}
	// --------------------------------------------------------------------------

	// public メソッド	----------------------------------------------------------
	/**
	 * 権限登録
	 * @param name 権限名
	 * @param description 権限説明
	 */
	public addAuthority(name: string, description: string): void {
		this.m_AuthMngr.addAuthority(name, description);
	}

	/**
	 * 権限一覧登録
	 * @param authList 権限名一覧
	 */
	public addAuthorityList(authList: string[]): void {
		this.m_AuthMngr.addAuthorityList(authList);
	}
	// --------------------------------------------------------------------------
}
