
/**
 *  エラーコード
 */
class MypageErrorConstants {
    
	/** 000~099 =========================================== */
	/** 名義変更エラー */
    NAMECHANGE_ERROR:string = '80001';
	/** セッションエラー */
    SESSION_ERROR:string = '90001';
	/** APIエラー */
    API_ERROR:string = '90002';
	/** 9000エラー */
    SERVICE_ERROR:string = '90003';
	/** 端末登録正常終了 */
    TERMINAL_SUCCESS:string = '0';
	/** 端末登録APIエラー */
    INSTALL_TERMINAL_API_ERROR:string = '-10';
	/** 端末登録完了済みエラー */
    TERMINAL_FINISHED_ERROR:string = '-100';
	/** 端末登録-101エラー */
    TERMINAL_ERROR101:string = '-101';
	/** 端末登録-102エラー */
    TERMINAL_ERROR102:string = '-102';
	/** 端末登録-103エラー */
    TERMINAL_ERROR103:string = '-103';
	/** 端末登録-104エラー */
    TERMINAL_ERROR104:string = '-104';
	/** 端末登録-105エラー */
    TERMINAL_ERROR105:string = '-105';
	/** 端末登録-106エラー */
    TERMINAL_ERROR106:string = '-106';
	/** 端末登録-200エラー */
    TERMINAL_ERROR200:string = '-200';
	/** 端末登録-300エラー */
    TERMINAL_ERROR300:string = '-300';
	/** 端末登録-400エラー */
    TERMINAL_ERROR400:string = '-400';
}

export default new MypageErrorConstants();