// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@charset \"UTF-8\";\n/*=================== 変数管理ファイル =================== */\n/* ------- 色 ------- */\n/* ------- width ------- */\n.MyPageRouter-hiddenClass {\n  display: none !important; }\n", ""]);
// Exports
exports.locals = {
	"hiddenClass": "MyPageRouter-hiddenClass"
};
module.exports = exports;
