/**
 * 年間利用明細リクエスト
 */
export class GetUsageDetailsYearRequest {
	// public 変数	-----------------------------------------------------------
	public customerId: string;	// お客様ID
	public monthList: String[];	// 対象年月リスト
	public name: string;		// サービス名称
	public lineNo: string;		// 回線番号
	// ------------------------------------------------------------------------
}
