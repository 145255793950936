import { WprBaseDialogViewInfo } from '../../../../../wpr-framework/view/dialog/WprBaseDialogViewInfo';
import { MypageLinkConstatns } from '../../../../common/constants/MypageLinkConstatns';

export class MvnoHelpDialogData {
	public title: string;
}
/**
 * MVNOヘルプダイアログ
 */
export class MvnoHelpDialogView extends WprBaseDialogViewInfo {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() { super('MvnoHelpDialog'); }
	// --------------------------------------------------------------------------

	private dialogTitle: string = null;

	// override プロパティ  ------------------------------------------------------
	/** ダイアログタイトル */
	public get title(): string { return null; }
	/** バリデーション */
	public get validation(): boolean { return false; }
	/** Divider使用フラグ */
	public get useDividers(): boolean { return false; }
	/** フッタ使用フラグ */
	public get useFooter(): boolean { return false; }
	/** OKボタン使用フラグ */
	public get useOKButton(): boolean { return false; }
	/** キャンセルボタン使用フラグ */
	public get useCancelButton(): boolean { return false; }
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * コントロール定義設定
	 */
	public onRegisterConfig(): void {
	}

	/**
	 * アクション登録
	 */
	public onRegisterAction(): void {
		this.addAction('simFreeLink', this.onSimFreeLink);
	}

	/**
	 * ダイアログ表示通知
	 * @param sendData 送信データ
	 */
	public onShowDialog(sendData: MvnoHelpDialogData) {
		this.dialogTitle = sendData.title;
		this.setViewMode('ダイアログ', this.dialogTitle);
		this.refreshView();
	}
	// --------------------------------------------------------------------------

	// アクション  ---------------------------------------------------------------
	/**
	 * 「動作確認済み端末一覧」（PDF)
	 */
	public onSimFreeLink(param: any): void {
		window.open(MypageLinkConstatns.ITSCOM_SIM_FREE);
	}
	// --------------------------------------------------------------------------
}
