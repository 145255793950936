import { WprBaseViewCore } from '../../WprBaseViewCore';
import { WprControlRefreshInfo } from './WprControlRefreshInfo';
import { WprControlInfo } from '../WprControlInfo';

/**
 * コントロールリフレッシュ情報マップ
 */
export class WprControRefreshMap {
	// private 変数  ------------------------------------------------------------
	private m_Name: string										= null;										// リスト名称
	private m_ControlMap: Map<string, WprControlRefreshInfo>	= new Map<string, WprControlRefreshInfo>();	// コントロールリフレッシュ情報
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** リスト名称 */
	public get name(): string 										{ return this.m_Name; 		}
	/** コントロールリフレッシュ情報 */
	public get controlMap(): Map<string, WprControlRefreshInfo> 	{ return this.m_ControlMap; }
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	public constructor(name: string) {
		this.m_Name = name;
	}
	// --------------------------------------------------------------------------

	// public メソッド  ---------------------------------------------------------
	/**
	 * コントロール情報追加
	 * @param cinfo コントロール情報
	 */
	public addControlInfo(viewCore: WprBaseViewCore, cinfo: WprControlInfo) {
		if (this.m_ControlMap.has(cinfo.name) === false)
			this.m_ControlMap.set(cinfo.name, new WprControlRefreshInfo(viewCore, cinfo));
	}

	/**
	 * コントロール存在チェック
	 * @param name コントロール名
	 * @returns trueの場合は存在している
	 */
	public has(name: string): boolean {
		return this.m_ControlMap.has(name);
	}

	/**
	 * コントロールリフレッシュ情報取得
	 * @param name コントロール名
	 * @returns コントロールリフレッシュ情報
	 */
	public getControlRefreshInfo(name: string): WprControlRefreshInfo {
		if (this.m_ControlMap.has(name) === true)
			return this.m_ControlMap.get(name);
		return null;
	}

	/**
	 * コントロール情報取得
	 * @param name コントロール名
	 * @param index 行インデクス
	 * @returns コントロール情報
	 */
	public getControlInfo(name: string): WprControlInfo {
		if (this.m_ControlMap.has(name) === true)
			return this.m_ControlMap.get(name).controlInfo;
		return null;
	}

	/**
	 * コントロールの情報でモデルを更新する
	 */
	public refreshModel(): void {
		this.m_ControlMap.forEach((cinfo, key, map) => {
			cinfo.refreshModel();
		});
	}

	/**
	 * モデルの情報でコントロールを更新する
	 */
	public refreshView(): void {
		this.m_ControlMap.forEach((cinfo, key, map) => {
			cinfo.refreshView();
		});
	}

	/**
	 * コントロールの値をクリアする
	 */
	public clearValue(viewCore: WprBaseViewCore): void {
		this.m_ControlMap.forEach((cinfo, key, map) => {
			cinfo.clearValue(viewCore);
		});
	}

	/**
	 * コントロールの入力チェック
	 * @return trueの場合、入力済みでエラーなし
	 */
	public isInput(): boolean {
		for(const cinfo of this.m_ControlMap.values()) {
			if (cinfo.isInput() === false)
				return false;
		}
		return true;
	}

	/**
	 * コントロール情報リスト取得
	 * @returns コントロール情報リスト
	 */
	public getControlInfoList(): WprControlInfo[] {
		const list = new Array();
		this.m_ControlMap.forEach((info, key, map) => {
			list.push(info.controlInfo);
		});
		return list;
	}

	/**
	 * 権限を再設定する
	 */
	public refreshAuth(): void {
		this.m_ControlMap.forEach((cinfo, key, map) => {
			cinfo.refreshAuth();
		});
	}

	/**
	 * 変更フラグ取得
	 * @return 変更フラグ
	 */
	 public getDirty(): boolean {
		let dirty = false;
		for (const cinfo of this.m_ControlMap.values()) {
			if (cinfo.controlInfo.dirty === true)
				return true;
		}
		return false;
	}
	// --------------------------------------------------------------------------
}
