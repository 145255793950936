import React, { ReactNode } from 'react';
import { WprBaseViewComponent } from '../../../../../wpr-framework/view/component/WprBaseViewComponent';
import { ModTvSelect2View } from './ModTvSelect2View';
import { Typography, Grid, Table, TableHead, TableRow, TableCell, TableBody, Divider } from '@material-ui/core';
import { WprContainer } from '../../../../../wpr-framework/component/controls/WprContainer';
import { MatButton } from '../../../../../wpr-framework/component/controls/material/MatButton';
import { MatCheckBox } from '../../../../../wpr-framework/component/controls/material/MatCheckBox';
import { MatTableContainer } from '../../../../../wpr-framework/component/controls/material/MatTableContainer';
import { MatTableRow } from '../../../../../wpr-framework/component/controls/material/MatTableRow';
import { MatTypography } from '../../../../../wpr-framework/component/controls/material/MatTypography';
import { WprRowInfo } from '../../../../../wpr-framework/component/props/WprRowInfo';
import { ProgressBar } from '../../../common/progress_bar/ProgressBar';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import tvIcon from '/shared/image/common/logo_iTSCOMTV.png';
import helpOrangeIcon from '/shared/image/common/icon_Help_bgLigntOrange.png';
import styles from './ModTvSelect2.scss';
import { MatDatePicker } from '../../../../../wpr-framework/component/controls/material/MatDatePicker';
import { MatSelect } from '../../../../../wpr-framework/component/controls/material/MatSelect';
import { WprViewError } from '../../../../../wpr-framework/component/controls/WprViewError';
import errorIcon from '../../../../../../shared/image/common/icon_error.png'
import { WprErrorContainer } from '../../../../../wpr-framework/component/controls/WprErrorContainer';
import { MatTextField } from '../../../../../wpr-framework/component/controls/material/MatTextField';

/**
 * テレビ変更選択画面2用コンポーネント
 */
export class ModTvSelect2 extends WprBaseViewComponent {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: any) { super(new ModTvSelect2View(), props); }
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** テレビ変更選択画面2ビュー情報 */
	public get viewInfo(): ModTvSelect2View	{ return this.view as ModTvSelect2View;	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * スタイルオブジェクト取得
	*/
	public getStylesObject(): any { return styles; }

	/**
	 * リストコントロール定義登録
	*/
	protected onRegisterListRender(): void {
		this.addListRender('TvOptionListModel', null, this.onTvOptionListRender);
		this.addListRender('ErrorModel', null, this.onErrorModelRender);
	}
	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		return (
			<>
			<div>
				<div className='pageCard'>
					<div className="pageHeader stepFlow">
						<ProgressBar name='progressBar' view={this.view} />
					</div>
					<div className='sub-logo'>
						<img src= {tvIcon}></img>
					</div>
					<div className='main'>
						<div className='pageTitle'>
							<div className='title_left'>
								<MatTypography name='title' className='title_text' view={this.view}/>
							</div>
						</div>
						<WprContainer mode='エラー.表示' view={this.view}>
							<div className='errorArea'>
								<span className='parallel'>
									<img src={errorIcon} alt='' className='x_img'/>
									<div className='errorList'>
										{this.renderList('ErrorModel')}
									</div>
								</span>
							</div>
						</WprContainer>
						<div className='title_space'>
							<div className='itemTitle title_maru'>基本情報</div>
						</div>
						{/* PC用 */}
						<WprContainer mode='オプション.PC' view={this.view}>
							<div className='feeTable'>	
								<Grid container className='top'>
									<Grid item xs={12} md={4} className='feeTitle border_right'>
										<div>項目名</div>
									</Grid>
									<Grid item xs={12} md={4} className='feeTitle border_right'>
										<div>変更前</div>
									</Grid>
									<Grid item xs={12} md={4} className='feeTitle'>
										<div>申込み</div>
									</Grid>
								</Grid>	
								<Grid container className='feeRow'>
									<Grid item xs={12} md={4} className='feeTitle' style={{padding: '0 30px', fontWeight: '500'}}>
										<div style={{textAlign: 'left'}}>申込みコース</div>
									</Grid>
									<Grid item xs={12} md={4} className='feeVal border_right' style={{padding: '10px 15px'}}>
										<Typography className='font_black'><MatTypography name='courseB' className='itemUnit' view={this.view}/>
											{/* (月額<MatTypography name='amountB' className='itemUnit' view={this.view}/>円) */}
										</Typography>
									</Grid>
									<Grid item xs={12} md={4} className='feeVal' style={{padding: '10px 15px'}}>
										<Typography className='font_black'><MatTypography name='courseA' className='itemUnit' view={this.view}/>(月額<MatTypography name='amountA' className='itemUnit' view={this.view}/>円)</Typography>
									</Grid>
								</Grid>
								<Grid container className='feeRow'>
									<Grid item xs={12} md={4} className='feeTitle' style={{padding: '0 30px', fontWeight: '500'}}>
										<div style={{textAlign: 'left'}}>機器の種類<a href='https://www.itscom.co.jp/service/cabletv/equipment/' target='_blank'><img src={helpOrangeIcon}  alt='' style={{marginLeft: '3px'}}></img></a></div>
									</Grid>
									<Grid item xs={12} md={4} className='feeVal border_right' style={{padding: '10px 15px'}}>
										<Typography className='font_black'><MatTypography name='terminalB' className='itemUnit' view={this.view}/></Typography>
									</Grid>
									<Grid item xs={12} md={4} className='feeVal' style={{padding: '10px 15px'}}>
										<Typography className='font_black'><MatTypography name='terminalA' className='itemUnit' view={this.view}/></Typography>
									</Grid>
								</Grid>	
								<Grid container className='feeRow'>
									<Grid item xs={12} md={4} className='feeTitle' style={{padding: '0 30px', fontWeight: '500'}}>
										<div style={{textAlign: 'left'}}>機器のご提供</div>
									</Grid>
									<Grid item xs={12} md={4} className='feeVal border_right' style={{padding: '10px 15px'}}>
										<Typography className='font_black'><MatTypography name='applyType' className='itemUnit' view={this.view}/></Typography>
									</Grid>
									<Grid item xs={12} md={4} className='feeVal' style={{padding: '10px 15px'}}>
										<MatCheckBox name='rental' color='primary' label='レンタル' className='checkbox_size checkbox_label' view={this.view} />
										<MatCheckBox name='noRental' color='primary' label='変更なし' className='checkbox_size checkbox_label' view={this.view} />
									</Grid>
								</Grid>	
								<Grid container className='feeRow'>
									<Grid item xs={12} md={4} className='feeTitle' style={{padding: '0 30px', fontWeight: '500'}}>
										<div style={{textAlign: 'left'}}>iTSCOM magazine<a href='https://www.itscom.co.jp/service/cabletv/option/annai-shi.html' target='_blank'><img src={helpOrangeIcon}  alt='' style={{marginLeft: '3px'}}></img></a></div>
									</Grid>
									<Grid item xs={12} md={4} className='feeVal border_right' style={{padding: '10px 15px'}}>
										<Typography className='font_black'><MatTypography name='magazine' className='itemUnit' view={this.view}/></Typography>
									</Grid>
									<Grid item xs={12} md={4} className='feeVal' style={{padding: '10px 15px'}}>
										<span className = 'font_black'>iTSCOM magazineの契約を変更される場合は、「ご契約内容確認・変更」の「申込または解約」からお手続きをお願いいたします。※<MatTypography name={'magazineFee'} view={this.view} style={{display: 'contents', color: '#484848', fontWeight: '500'}}></MatTypography>円/月(税込)　「マックスplus、マックス」の月額利用料には購読料が含まれております。</span>
									</Grid>
								</Grid>	
								<WprContainer mode='お得パックエリア.表示' view={this.view}>
									<Grid container className='feeRow'>
										<Grid item xs={12} md={4} className='feeTitle' style={{padding: '0 30px', fontWeight: '500'}}>
											<div style={{textAlign: 'left'}}>お得パック<a href='https://www.itscom.co.jp/support/contract_change/option/' target='_blank'><img src={helpOrangeIcon}  alt='' style={{marginLeft: '3px'}}></img></a></div>
										</Grid>
										<Grid item xs={12} md={8} className='feeVal' style={{padding: '10px 15px'}}>
											<MatCheckBox name='otokuApply' color='primary' className='checkbox_size' view={this.view} /><span className = 'font_black'>希望する</span>
											<MatCheckBox name='otokuUnapply' color='primary' className='checkbox_size' view={this.view}/><span className = 'font_black'>希望しない</span>
										</Grid>
									</Grid>	
								</WprContainer>
								<Grid container className='feeRow'>
									<Grid item xs={12} md={4} className='feeTitle' style={{padding: '0 30px', fontWeight: '500'}}>
										<div>
										<div style={{textAlign: 'left'}}>キャンペーンコード</div>
										</div>
									</Grid>
									<Grid item xs={12} md={8} style={{padding: '10px 15px'}}>
										<div className='inputArea'>
											<MatTextField placeholder='0123456789' name='campaignCode' view={this.view} style={{maxWidth: '200px'}}></MatTextField>
											<Typography style={{fontSize: '10px', color: 'black', fontWeight: '600'}}>※キャンペーンコードをお持ちの場合はご入力ください</Typography>
										</div>
									</Grid>
								</Grid>
							</div>
						</WprContainer>
						{/* SP用 */}
						<WprContainer mode='オプション.SP' view={this.view}>
							<table className='table'>	
								<colgroup>
									<col style={{width:'40%'}}/>
									<col style={{width:'60%'}}/>
								</colgroup>
								<TableRow className='header' style={{borderRadius: '12px 12px 0 0'}}>
									<TableCell rowSpan={2} style={{borderRadius: '12px 0 0 0'}} className='border_right'>
										項目名
									</TableCell>
									<TableCell style={{borderRadius: '0 12px 0 0'}}>
										変更前
									</TableCell>
								</TableRow>
								<TableRow className='header'>
									<TableCell>
										申込み
									</TableCell>
								</TableRow>
								<TableRow className='row'>
									<TableCell className='bodyTitle border_right' rowSpan={2}>
										申込みコース
									</TableCell>
									<TableCell className='body'>
										<MatTypography name='courseB' view={this.view} style={{display: 'inline'}}/>
										{/* (月額<MatTypography name='amountB' view={this.view} style={{display: 'inline'}}/>円) */}
									</TableCell>
								</TableRow>
								<TableRow className='row'>
									<TableCell className='body'>
										<MatTypography name='courseA' view={this.view} style={{display: 'inline'}}/>(月額<MatTypography name='amountA' view={this.view} style={{display: 'inline'}}/>円)
									</TableCell>
								</TableRow>
								<TableRow className='row'>
									<TableCell className='bodyTitle border_right' rowSpan={2}>
										機器の種類<a href='https://www.itscom.co.jp/service/cabletv/equipment/' target='_blank'><img src={helpOrangeIcon}  alt='' style={{marginLeft: '3px'}}></img></a>
									</TableCell>
									<TableCell className='body'>
										<Typography className='font_black'><MatTypography name='terminalB' className='itemUnit' view={this.view}/></Typography>
									</TableCell>
								</TableRow>
								<TableRow className='row'>
									<TableCell className='body'>
										<Typography className='font_black'><MatTypography name='terminalA' className='itemUnit' view={this.view}/></Typography>
									</TableCell>
								</TableRow>
								<TableRow className='row'>
									<TableCell className='bodyTitle border_right' rowSpan={2}>
										機器のご提供
									</TableCell>
									<TableCell className='body'>
										<Typography className='font_black'><MatTypography name='applyType' className='itemUnit' view={this.view}/></Typography>
									</TableCell>
								</TableRow>
								<TableRow className='row'>
									<TableCell className='body'>
										<MatCheckBox name='rental' color='primary' label='レンタル' className='checkbox_size checkbox_label' view={this.view} />
										<MatCheckBox name='noRental' color='primary' label='変更なし' className='checkbox_size checkbox_label' view={this.view} />
									</TableCell>
								</TableRow>
								<TableRow className='row'>
									<TableCell className='bodyTitle border_right' rowSpan={2}>
										iTSCOM magazine<a href='https://www.itscom.co.jp/service/cabletv/option/annai-shi.html' target='_blank'><img src={helpOrangeIcon}  alt='' style={{marginLeft: '3px'}}></img></a>
									</TableCell>
									<TableCell className='body'>
										<Typography className='font_black'><MatTypography name='magazine' className='itemUnit' view={this.view}/></Typography>
									</TableCell>
								</TableRow>
								<TableRow className='row'>
									<TableCell className='body'>
										<span className = 'font_black'>iTSCOM magazineの契約を変更される場合は、「ご契約内容確認・変更」の「申込または解約」からお手続きをお願いいたします。※275円/月(税込)　「マックスplus、マックス」の月額利用料には購読料が含まれております。</span>
									</TableCell>
								</TableRow>
								<WprContainer mode='お得パックエリア.表示' view={this.view}>
									<TableRow className='row'></TableRow>
									<TableRow className='row'>
										<TableCell className='bodyTitle border_right' >
											お得パック<a href='https://www.itscom.co.jp/support/contract_change/option/' target='_blank'><img src={helpOrangeIcon}  alt='' style={{marginLeft: '3px'}}></img></a>
										</TableCell>
										<TableCell className='body'>
											<MatCheckBox name='otokuApply' color='primary' className='checkbox_size' view={this.view} /><span className = 'font_black'>希望する</span>
												<MatCheckBox name='otokuUnapply' color='primary' className='checkbox_size' view={this.view}/><span className = 'font_black'>希望しない</span>
										</TableCell>
									</TableRow>
								</WprContainer>
								<TableRow className='row'>
									<TableCell className='bodyTitle border_right' >
										キャンペーンコード
									</TableCell>
									<TableCell className='body'>
										<MatTextField placeholder='0123456789' name='campaignCode' view={this.view} style={{maxWidth: '200px'}}></MatTextField>
										<Typography style={{fontSize: '10px', color: 'black'}}>※キャンペーンコードをお持ちの場合はご入力ください</Typography>
									</TableCell>
								</TableRow>
							</table>
						</WprContainer>
						<div className='title_space'>
							<div className='itemTitle title_maru'>オプションチャンネル</div>
							<Grid container>
								<Grid item xs={12}>
									<MatTableContainer listName={'TvOptionListModel'} view={this.view} noDataUseHeader noDataMessage=' '>
										<Table className = 'courseTable'> 
											<colgroup>
												<col style={{width: '22%'}}/>
												<col style={{width: '39%'}}/>
												<col style={{width: '39%'}}/>
											</colgroup>
											<TableHead className='hedderrow'>
												<TableRow className='toptable'>
													<TableCell align='center' className='border_right font_bold'>選択</TableCell>
													<TableCell align='center' className='border_right font_bold'>テレビオプション名</TableCell>
													<TableCell align='center' className='font_bold'>月額料金</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												{this.renderList('TvOptionListModel')}
												<WprContainer mode='オプション一覧.なし' view={this.view}>
													<TableRow>
														<TableCell className='non_list' colSpan={5}>申込み可能なオプションはございません。</TableCell>
													</TableRow>
												</WprContainer>
											</TableBody>
										</Table>
									</MatTableContainer>
								</Grid>
							</Grid>
						</div>
						<div style = {{marginBottom: '50px'}}>
							<ul className='ul_kome attention_fontSize'>
								<li>
									表示されている月額は定価となります。実際の請求金額とは異なる場合があります。
								</li>
								<li>
									各端末の月額利用料等については<a href='http://www.itscom.net/info/covenant/pdf/itscom_price.pdf' target = '_blank'>こちら</a>をご確認ください。
								</li>
							</ul>
						</div>
						<WprContainer mode={'希望日時.表示'} view={this.view}>
							<div style={{marginBottom: '50px'}}>
								<div className='title_space'>
									<div className='itemTitle title_maru'>セキュリティ会社加入の確認</div>
									<Typography className='title_comment' style={{marginLeft: '15px'}}>ホームセキュリティにご加入している場合に工事の際にご連絡が必要な場合があります。</Typography>
								</div>
								<div className='title_center'>
									<div className='title_center labelSize'>
										<MatCheckBox name='securityFlg' label='セキュリティ会社に加入しています' view={this.view}/>
										<WprContainer mode={'セキュリティ会社.表示'} view={this.view}>
											<div className='flex'>
												<Typography className='title_comment' style={{marginRight: '15px'}}>	セキュリティ会社</Typography>
												<MatTextField name='securityComment' view={this.view} style={{width: '50%'}}></MatTextField>
											</div>
										</WprContainer>
									</div>
								</div>
								<div className='title_space'>
									<div className='itemTitle title_maru'>作業の希望日時</div>
								</div>
								<div style={{margin: '10px 0 25px 15px'}}>
									<Grid container spacing={1}>
										<Grid item xs={12} md={6} className='itemTitle2'>
											第一希望
											<span className='txtRequired'>*</span>
											<div className='customerInput'>
												<MatDatePicker name='desiredDate1' view={this.view} placeholder='年/月/日' use='mobile' />
											</div>
										</Grid>
										<Grid item xs={12} md={6} className='itemTitle2'>
											時間帯
											<span className='txtRequired'>*</span>
											<div className='customerInput'>
												<MatSelect name='selectTime1' vm='時間帯' view={this.view} />
											</div>
										</Grid>
										<Grid item xs={12} md={6} className='itemTitle2'>
											第二希望
											<span className='txtRequired'>*</span>
											<div className='customerInput'>
												<MatDatePicker name='desiredDate2' view={this.view} placeholder='年/月/日' use='mobile' />
											</div>
										</Grid>
										<Grid item xs={12} md={6} className='itemTitle2'>
											時間帯
											<span className='txtRequired'>*</span>
											<div className='customerInput'>
												<MatSelect name='selectTime2' vm='時間帯' view={this.view} />
											</div>
										</Grid>
										<Grid item xs={12} md={6} className='itemTitle2'>
											第三希望
											<span className='txtRequired'>*</span>
											<div className='customerInput'>
												<MatDatePicker name='desiredDate3' view={this.view} placeholder='年/月/日' use='mobile' />
											</div>
										</Grid>
										<Grid item xs={12} md={6} className='itemTitle2'>
											時間帯
											<span className='txtRequired'>*</span>
											<div className='customerInput'>
												<MatSelect name='selectTime3' vm='時間帯' view={this.view} />
											</div>
										</Grid>
									</Grid>
								</div>
								<ul className='ul_kome attention_fontSize'>
									<li>
										訪問担当が到着するおおよその時間帯目安となります。
									</li>
									<li>
									ご訪問日時については近日中にお電話にてご連絡いたします。
									</li>
									訪問担当の空き状況によってご希望に沿えない場合もございますことご了承ください。<br/>お建物の状況等によっては工事前に別途調査が必要となる場合がございます。
								</ul>
								<div className='feeTable inputArea' style={{marginTop: '30px'}}>	
									<Grid container className='feeRow'>
										<Grid item xs={12} md={4} className='feeTitle'>
											<div>前日連絡先<span className='txtRequired'>*</span></div>
										</Grid>
										<Grid item xs={12} md={8} className='feeVal courseValue' style={{padding: '15px'}}>
											<MatTextField name='beforeContact' className='itemUnit' placeholder='電話番号ハイフンなし' view={this.view} />
										</Grid>
									</Grid>	
									<Grid container className='feeRow'>
										<Grid item xs={12} md={4} className='feeTitle'>
											<div>当日連絡先<span className='txtRequired'>*</span></div>
										</Grid>
										<Grid item xs={12} md={8} className='feeVal courseValue' style={{padding: '15px'}}>
											<MatTextField name='sameDayContact' className='itemUnit' placeholder='電話番号ハイフンなし' view={this.view} />
										</Grid>
									</Grid>
									<Grid container className='feeRow'>
										<Grid item xs={12} md={4} className='feeTitle'>
											<div>当日立ち合い者<span className='txtRequired'>*</span></div>
										</Grid>
										<Grid item xs={12} md={8} className='feeVal courseValue' style={{padding: '15px'}}>
											<MatTextField name='name' className='itemUnit' placeholder='当日立ち合い者' view={this.view} />
										</Grid>
									</Grid>
								</div>
							</div>
						</WprContainer>
					</div>
					<Divider />
					<div className='pageFooter footer-space'>
						<div>
							<MatButton name='next' view={this.view} className='orangeButton orangeButton_parts' >
								次へ
								<NavigateNextIcon />
							</MatButton>
						</div>
						<div>
							<MatButton name='back' view={this.view} className='backButton' >
								<NavigateBeforeIcon />
								戻る
							</MatButton>
						</div>
					</div>
				</div>
			</div>
			</>
		);
	}
	// --------------------------------------------------------------------------

	// リスト描画  ---------------------------------------------------------------
	/**
	 * リスト名=OptListModel
	 */
	public onTvOptionListRender(rowInfo: WprRowInfo): any {
		return (
			<MatTableRow view={this.view} row={rowInfo}>
				<TableCell align='center' className='border_right' style={{ padding: '5px'}}>
					<MatCheckBox name='select' view={this.view} row={rowInfo}/>
				</TableCell>
				<TableCell className='border_right' style={{ padding: '5px'}}>
					<MatTypography name='option' className='feeUnit' view={this.view} row={rowInfo} style={{fontSize: '14px', fontWeight: '600'}}/>
				</TableCell>
				<TableCell align='center' style={{ padding: '5px'}}>
					<MatTypography name='amount' className='font_bold' view={this.view} row={rowInfo} style={{fontSize: '14px'}}/>
					<span className='feeUnit' style={{fontWeight: '600'}}>円</span>
				</TableCell>
			</MatTableRow>
		);
	}
	/**
	 * リスト名=ErrorModel
	 */
	public onErrorModelRender(rowInfo: WprRowInfo): any {
		return (
			<div>
				<MatTypography name='errorMessage' view={this.view} row={rowInfo}/>
			</div>
		);
	}
	// --------------------------------------------------------------------------
}
