/**
 * The persistent class for the M_DISPLAY database table.
 */
export class MDisplay {
	// public 変数	-----------------------------------------------------------
	public actvfg: boolean;
	public  dispdivcd: string;
	public  dispnamek: string;
	public  dispnamep: string;
	public dispsort: number;
	public  genecd: string;
	public  namecd: string;
	public  xcrecd: string;
	public xcredt: Date;
	public  xcrepg: string;
	public  xmodcd: string;
	public xmoddt: Date;
	public  xmodpg: string;
	// ------------------------------------------------------------------------

	// コンストラクタ ----------------------------------------------------------
	constructor(dispdivcd?: string, namecd?: string)  {
		this.dispdivcd =dispdivcd;
		this.namecd = namecd;
	}
	// ------------------------------------------------------------------------
}
