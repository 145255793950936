import React, { ReactNode } from 'react';
import { WprBaseViewComponent } from '../../../../../wpr-framework/view/component/WprBaseViewComponent';
import { AddTvSelect2View } from './AddTvSelect2View';
import { Typography, Divider, Grid, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { WprContainer } from '../../../../../wpr-framework/component/controls/WprContainer';
import { MatButton } from '../../../../../wpr-framework/component/controls/material/MatButton';
import { MatTypography } from '../../../../../wpr-framework/component/controls/material/MatTypography';
import { ProgressBar } from '../../../common/progress_bar/ProgressBar';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { MatCheckBox } from '../../../../../wpr-framework/component/controls/material/MatCheckBox';
import { MatTableContainer } from '../../../../../wpr-framework/component/controls/material/MatTableContainer';
import { WprDiv } from '../../../../../wpr-framework/component/controls/WprDiv';
import { MatTableRow } from '../../../../../wpr-framework/component/controls/material/MatTableRow';
import { MatTextField } from '../../../../../wpr-framework/component/controls/material/MatTextField';
import { WprRowInfo } from '../../../../../wpr-framework/component/props/WprRowInfo';
import tvIcon from '/shared/image/common/logo_iTSCOMTV.png';
import helpOrangeIcon from '/shared/image/common/icon_Help_bgLigntOrange.png';
import styles from './AddTvSelect2.scss';
import { MatSelect } from '../../../../../wpr-framework/component/controls/material/MatSelect';
import { MatDatePicker } from '../../../../../wpr-framework/component/controls/material/MatDatePicker';
import { WprViewError } from '../../../../../wpr-framework/component/controls/WprViewError';
import { WprErrorContainer } from '../../../../../wpr-framework/component/controls/WprErrorContainer';
import errorIcon from '../../../../../../shared/image/common/icon_error.png'
/**
 * テレビ追加選択2用コンポーネント
 */
export class AddTvSelect2 extends WprBaseViewComponent {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: any) { super(new AddTvSelect2View(), props); }
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** テレビ追加選択2ビュー情報 */
	public get viewInfo(): AddTvSelect2View	{ return this.view as AddTvSelect2View;	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * スタイルオブジェクト取得
	*/
	public getStylesObject(): any { return styles; }

	/**
	 * リストコントロール定義登録
	*/
	protected onRegisterListRender(): void {
		this.addListRender('TvOptionListModel', null, this.onTvOptionListRender);
		this.addListRender('ErrorModel', null, this.onErrorModelRender);
	}
	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		return (
			<>
			<div>
				<div className='pageCard'>
					<div className="pageHeader stepFlow">
						<ProgressBar name='progressBar' view={this.view} />
					</div>
					<div className='sub-logo'>
						<img src={tvIcon}></img>
					</div>
					<div className='main'>
						<div className='pageTitle'>
							<div className='title_left'>
								<MatTypography name='title' className='title_text' view={this.view}/>
								<Typography className='title_comment'>ご希望の内容を選択してください。</Typography>
							</div>
						</div>
						<WprContainer mode='エラー.表示' view={this.view}>
							<div className='errorArea'>
								<span className='parallel'>
									<img src={errorIcon} alt='' className='x_img'/>
									<div className='errorList'>
										{this.renderList('ErrorModel')}
									</div>
								</span>
							</div>
						</WprContainer>
						<div className='title_space'>
							<div className='itemTitle title_maru'>基本情報</div>
						</div>
						<div className='feeTable'>
							<Grid container className='feeRow'>
								<Grid item xs={12} md={4} className='feeTitle'>
									<div>申込みコース</div>
								</Grid>
								<Grid item xs={12} md={8} className='feeVal courseValue'>
									<Typography className='font_black'><MatTypography name='course' className='itemUnit' view={this.view}/>(月額<MatTypography name='amount' className='itemUnit' view={this.view}/>円)</Typography>
								</Grid>
							</Grid>	
							<Grid container className='feeRow'>
								<Grid item xs={12} md={4} className='feeTitle'>
									<div>機器の種類
										<a href='https://www.itscom.co.jp/service/cabletv/equipment/' target='_blank'><img src= {helpOrangeIcon} alt='' style={{marginLeft: '3px'}}/></a>
									</div>
								</Grid>
								<Grid item xs={12} md={8} className='feeVal courseValue'>
									<Typography className='font_black'><MatTypography name='terminal' className='itemUnit' view={this.view}/></Typography>
								</Grid>
							</Grid>	
							<Grid container className='feeRow'>
								<Grid item xs={12} md={4} className='feeTitle'>
									<div>機器のご提供</div>
								</Grid>
								<Grid item xs={12} md={8} className='feeVal courseValue'>
									<MatCheckBox name='rental' color='primary' className='checkbox_size' view={this.view} /><span className = 'font_black'>レンタル</span>
								</Grid>
							</Grid>	
							<Grid container className='feeRow'>
								<Grid item xs={12} md={4} className='feeTitle'>
									<div>iTSCOM magazine
										<a href='https://www.itscom.co.jp/service/cabletv/option/annai-shi.html' target='_blank'><img src= {helpOrangeIcon} alt='' style={{marginLeft: '3px'}}/></a>
									</div>
								</Grid>
								<Grid item xs={12} md={8} className='feeVal courseValue'>
									<MatCheckBox name='magazineApply' color='primary' className='checkbox_size' view={this.view} /><span className = 'font_black'>申込む</span>
									<MatCheckBox name='magazineUnapply' color='primary' className='checkbox_size' view={this.view}/><span className = 'font_black'>申し込まない</span>
								</Grid>
							</Grid>	
							<WprContainer mode='お得パックエリア.表示' view={this.view}>
								<Grid container className='feeRow'>
									<Grid item xs={12} md={4} className='feeTitle'>
										<div>お得パック
											<a href='https://www.itscom.co.jp/service/profit/' target='_blank'><img src= {helpOrangeIcon} alt='' style={{marginLeft: '3px'}}/></a>
										</div>
									</Grid>
									<Grid item xs={12} md={8} className='feeVal courseValue'>
										<MatCheckBox name='otokuApply' color='primary' className='checkbox_size' view={this.view} /><span className = 'font_black'>希望する</span>
										<MatCheckBox name='otokuUnapply' color='primary' className='checkbox_size' view={this.view}/><span className = 'font_black'>希望しない</span>
									</Grid>
								</Grid>	
							</WprContainer>
							<Grid container className='feeRow inputArea'>
								<Grid item xs={12} md={4} className='feeTitle'>
									<div>
										希望場所
										<span className='txtRequired'>*</span>
									</div>
								</Grid>
								<Grid item xs={12} md={8} className='feeVal courseValue' style={{padding: '10px 25px'}}>
									<WprContainer mode={'物件.戸建'} view={this.view}>
										<div>
											<MatSelect placeholder='(階数)' name='floor' vm='floor' view={this.view} style={{width: '125px', marginRight: '5px'}}/>
											<MatSelect placeholder='(場所)' name='location' vm='location' view={this.view} style={{width: '175px'}}/>
										</div>
									</WprContainer>
									<WprContainer mode={'物件.集合'} view={this.view}>
										<div>
											<MatSelect placeholder='(場所)' name='location' vm='location' view={this.view} style={{width: '175px'}}/>
										</div>
									</WprContainer>
									<div><MatTextField placeholder='(場所)' name='comment' view={this.view} style={{width: '95%'}}></MatTextField></div>
								</Grid>
							</Grid>
							<Grid container className='feeRow inputArea'>
								<Grid item xs={12} md={4} className='feeTitle'>
									<div>
										キャンペーンコード
									</div>
								</Grid>
								<Grid item xs={12} md={8} className='feeVal courseValue' style={{padding: '10px 25px'}}>
									<div>
										<MatTextField placeholder='0123456789' name='campaignCode' view={this.view} style={{maxWidth: '200px'}}></MatTextField>
										<Typography style={{fontSize: '10px', color: 'black'}}>※キャンペーンコードをお持ちの場合はご入力ください</Typography>
									</div>
								</Grid>
							</Grid>
						</div>
						<div className='title_space'>
							<div className='itemTitle title_maru'>オプションチャンネル</div>
							<Grid container>
								<Grid item xs={12}>
									<MatTableContainer listName={'TvOptionListModel'} view={this.view} noDataUseHeader noDataMessage=' '>
										<Table className = 'courseTable'> 
											<colgroup>
												<col style={{width: '22%'}}/>
												<col style={{width: '39%'}}/>
												<col style={{width: '39%'}}/>
											</colgroup>
											<TableHead className='hedderrow'>
												<TableRow className='toptable'>
													<TableCell align='center' className='border_right font_bold'>選択</TableCell>
													<TableCell align='center' className='border_right font_bold'>テレビオプション名</TableCell>
													<TableCell align='center' className='font_bold'>月額料金</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												{this.renderList('TvOptionListModel')}
												<WprContainer mode='オプション.なし' view={this.view}>
													<TableRow>
														<TableCell className='non_list' colSpan={5}>申込み可能なオプションはございません。</TableCell>
													</TableRow>
												</WprContainer>
											</TableBody>
										</Table>
									</MatTableContainer>
								</Grid>
							</Grid>
						</div>
						<div style = {{marginBottom: '50px'}}>
							<ul className='ul_kome attention_fontSize'>
								<li>
									表示されている月額は定価となります。実際の請求金額とは異なる場合があります。
								</li>
								<li>
									各端末の月額利用料等については<a href='http://www.itscom.net/info/covenant/pdf/itscom_price.pdf' target = '_blank'>こちら</a>をご確認ください。
								</li>
							</ul>
						</div>
						<div className='title_space'>
							<div className='itemTitle title_maru'>リフォームの確認</div>
							<Typography className='title_comment' style={{marginLeft: '15px'}}>当社にて機器を設置して以降、配線・端子の交換、場所移動を伴うリフォームを行いましたか。</Typography>
						</div>
						<div className='title_center'>
							<div className='title_center labelSize'>
								<MatCheckBox name='reformFlg' label='リフォームを行った' view={this.view}/>
							</div>
						</div>
						<div className='title_space'>
							<div className='itemTitle title_maru'>セキュリティ会社加入の確認</div>
							<Typography className='title_comment' style={{marginLeft: '15px'}}>ホームセキュリティにご加入している場合に工事の際にご連絡が必要な場合があります。</Typography>
						</div>
						<div className='title_center'>
							<div className='title_center labelSize'>
								<MatCheckBox name='securityFlg' label='セキュリティ会社に加入しています' view={this.view}/>
								<WprContainer mode={'セキュリティ会社.表示'} view={this.view}>
									<div className='flex'>
										<Typography className='title_comment' style={{marginRight: '15px'}}>	セキュリティ会社</Typography>
										<MatTextField name='securityComment' view={this.view} style={{width: '50%'}}></MatTextField>
									</div>
								</WprContainer>
							</div>
						</div>
						<div className='title_space'>
							<div className='itemTitle title_maru'>TV端子の確認<span className='txtRequired'>*</span></div>
							<Typography className='title_comment' style={{marginLeft: '15px'}}>機器の移動の際に移動先の場所にTV端子があることが必要になります。</Typography>
						</div>
						<div className='title_center'>
							<div className='title_center labelSize'>
								<MatCheckBox name='tvPortFlg' label='移動先の場所にTV端子があることを確認しました' view={this.view}/>
							</div>
						</div>
						<div style={{marginBottom: '50px'}}>
							<div className='title_space'>
								<div className='itemTitle title_maru'>作業の希望日時</div>
							</div>
							<div style={{margin: '10px 0 25px 15px'}}>
								<Grid container spacing={1}>
									<Grid item xs={12} md={6} className='itemTitle2'>
										第一希望
										<span className='txtRequired'>*</span>
										<div className='customerInput'>
											<MatDatePicker name='desiredDate1' view={this.view} placeholder='年/月/日' use='mobile' />
										</div>
									</Grid>
									<Grid item xs={12} md={6} className='itemTitle2'>
										時間帯
										<span className='txtRequired'>*</span>
										<div className='customerInput'>
											<MatSelect name='selectTime1' vm='時間帯' view={this.view} />
										</div>
									</Grid>
									<Grid item xs={12} md={6} className='itemTitle2'>
										第二希望
										<span className='txtRequired'>*</span>
										<div className='customerInput'>
											<MatDatePicker name='desiredDate2' view={this.view} placeholder='年/月/日' use='mobile' />
										</div>
									</Grid>
									<Grid item xs={12} md={6} className='itemTitle2'>
										時間帯
										<span className='txtRequired'>*</span>
										<div className='customerInput'>
											<MatSelect name='selectTime2' vm='時間帯' view={this.view} />
										</div>
									</Grid>
									<Grid item xs={12} md={6} className='itemTitle2'>
										第三希望
										<span className='txtRequired'>*</span>
										<div className='customerInput'>
											<MatDatePicker name='desiredDate3' view={this.view} placeholder='年/月/日' use='mobile' />
										</div>
									</Grid>
									<Grid item xs={12} md={6} className='itemTitle2'>
										時間帯
										<span className='txtRequired'>*</span>
										<div className='customerInput'>
											<MatSelect name='selectTime3' vm='時間帯' view={this.view} />
										</div>
									</Grid>
								</Grid>
							</div>
							<ul className='ul_kome attention_fontSize'>
								<li>
									訪問担当が到着するおおよその時間帯目安となります。
								</li>
								<li>
									ご訪問日時については近日中にお電話にてご連絡いたします。
								</li>
								訪問担当の空き状況によってご希望に沿えない場合もございますことご了承ください。<br/>お建物の状況等によっては工事前に別途調査が必要となる場合がございます。
							</ul>
							<div className='feeTable inputArea' style={{marginTop: '30px'}}>	
								<Grid container className='feeRow'>
									<Grid item xs={12} md={4} className='feeTitle'>
										<div>前日連絡先<span className='txtRequired'>*</span></div>
									</Grid>
									<Grid item xs={12} md={8} className='feeVal courseValue' style={{padding: '15px'}}>
										<MatTextField name='beforeContact' className='itemUnit' placeholder='電話番号ハイフンなし' view={this.view} />
									</Grid>
								</Grid>	
								<Grid container className='feeRow'>
									<Grid item xs={12} md={4} className='feeTitle'>
										<div>当日連絡先<span className='txtRequired'>*</span></div>
									</Grid>
									<Grid item xs={12} md={8} className='feeVal courseValue' style={{padding: '15px'}}>
										<MatTextField name='sameDayContact' className='itemUnit' placeholder='電話番号ハイフンなし' view={this.view} />
									</Grid>
								</Grid>
								<Grid container className='feeRow'>
									<Grid item xs={12} md={4} className='feeTitle'>
										<div>当日立ち合い者<span className='txtRequired'>*</span></div>
									</Grid>
									<Grid item xs={12} md={8} className='feeVal courseValue' style={{padding: '15px'}}>
										<MatTextField name='name' className='itemUnit' placeholder='当日立ち合い者' view={this.view} />
									</Grid>
								</Grid>
							</div>
						</div>
					</div>
					<Divider />
					<div className='pageFooter footer-space'>
						<div>
							<MatButton name='next' view={this.view} className='orangeButton orangeButton_parts' >
								次へ
								<NavigateNextIcon />
							</MatButton>
						</div>
						<div>
							<MatButton name='back' view={this.view} className='backButton' >
								<NavigateBeforeIcon />
								戻る
							</MatButton>
						</div>
					</div>
				</div>
			</div>
			</>
		);
	}
	// --------------------------------------------------------------------------

	// リスト描画  ---------------------------------------------------------------
	/**
	 * リスト名=OptListModel
	 */
	public onTvOptionListRender(rowInfo: WprRowInfo): any {
		return (
			<MatTableRow view={this.view} row={rowInfo}>
				<TableCell align='center' className='border_right' style={{ padding: '5px'}}>
					<MatCheckBox name='select' view={this.view} row={rowInfo}/>
				</TableCell>
				<TableCell className='border_right' style={{ padding: '5px'}}>
					<MatTypography name='option' className='feeUnit' view={this.view} row={rowInfo} style={{fontSize: '14px'}}/>
				</TableCell>
				<TableCell align='center' style={{ padding: '5px'}}>
					<MatTypography name='amount' className='font_bold' view={this.view} row={rowInfo} style={{fontSize: '14px'}}/>
					<span className='feeUnit'>円</span>
				</TableCell>
			</MatTableRow>
		);
	}
	/**
	 * リスト名=ErrorModel
	 */
	public onErrorModelRender(rowInfo: WprRowInfo): any {
		return (
			<div>
				<MatTypography name='errorMessage' view={this.view} row={rowInfo}/>
			</div>
		);
	}
	// --------------------------------------------------------------------------
}
