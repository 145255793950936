/**
 * アクセスアカウントパスワード発行モデル
 */
export class ReiAccessAccountModel {
	// public 変数	-----------------------------------------------------------
	public course: string;	// コース
	public accessAccount: string;	// アクセスアカウント
	public password: string;		// パスワード
	public passwordKana: string;	// パスワード読み仮名
	public date: string;				// 変更日
	// ------------------------------------------------------------------------
}
