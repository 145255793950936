import { CustomerInfo } from '../../../models/CustomerInfo';
import { MGeneral } from '../entity/MGeneral';
import { NameChangeInfo } from './NameChangeInfo';
import { NameChangePayment } from './NameChangePayment';

/**
 * 名義変更申込み情報モデル
 */
export class NameChangeAppModel {
	// public 変数	-----------------------------------------------------------
	public nameChangeId: number;			// 名義変更ID
	public customerId: string;				// お客様番号
	public nameChangeInfo: NameChangeInfo;			// 旧名義人入力情報
	public nameChangePayment: NameChangePayment;	// 新名義人支払情報

	public familyName: string;			// 新名義人 姓漢字
	public firstName: string;			// 新名義人 名漢字
	public familyNameKana: string;		// 新名義人 姓カナ
	public firstNameKana: string;		// 新名義人 名カナ
	public birthdayYear: string;		// 生年月日（年）
	public birthdayMonth: string;		// 生年月日（月）
	public birthdayDay: string;			// 生年月日（日）
	public birthdayStr: string;			// 生年月日表示用
	public paymentPostCode: string;		// 異なる住所へ送付 郵便番号
	public paymentAddress: string;		// 異なる住所へ送付 住所
	public paymentBuilding: string;		// 異なる住所へ送付 建物名
	public paymentRoomNum: string;		// 異なる住所へ送付 部屋番号
	// ------------------------------------------------------------------------
}
