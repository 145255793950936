import { WprValidErrorInfo } from '../../view/validation/WprValidErrorInfo';
import { IWprError } from '../error/IWprError';
import { IWprErrorProps } from '../props/IWprErrorProps';
import { IWprErrorState } from '../props/IWprErrorState';
import { WprBaseComponent } from './WprBaseComponent';

/**
 * コントロールエラー基本コンポーネント
 */
export abstract class WprBaseErrorComponent<T extends IWprErrorProps> extends WprBaseComponent<T, IWprErrorState> implements IWprError {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: T) {
		super(props);
		this.state = {
			errList: null
		};
	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * マウント通知
	 */
	 public onDidMount() {
		this.props.view.addErrorControl(this, this.props.subView);
	 }

	/**
	 * アンマウント通知
	 */
	 public componentWillUnmount() {
		this.props.view.deleteErrorControl(this, this.props.subView);
	}
	// --------------------------------------------------------------------------

	// protected メソッド  -------------------------------------------------------
	/**
	 * クラス取得
	 * @param cls 固定クラス 
	 * @returns クラス
	 */
	protected className(cls: string): string {
		if (this.props.className)
			return this.props.className + ' ' + cls;
		const local = this.props.view.getLocalClassName(cls);
		if (local != null)
			return local;
		return cls;
	}

	/**
	 * コントロール名チェック
	 * @param controlName コントロール名
	 * @returns trueの場合、対象コントロール
	 */
	protected checkControlName(controlName: string): boolean {
		if (this.props.name.includes(',') === false)
			return controlName === this.props.name;
		let rtn = false;
		this.props.name.split(',').forEach(cname => {
			if (cname.trim() === controlName)
				rtn = true;
		});
		return rtn;
	}
	// --------------------------------------------------------------------------

	// IWprErrorの実装  ----------------------------------------------------------
	/**
	 * エラー情報リスト設定
	 * @param msgList エラー情報リスト
	 */
	public setErrorList(errList: WprValidErrorInfo[]): void {
		if (this.isMount == false)
			return;
		this.setState({
			errList: errList
		});
	}
	// --------------------------------------------------------------------------
}