// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@charset \"UTF-8\";\n/*\r\n * 工事_注意事項用CSS\r\n */\n.ContsrNotes-attentionArea {\n  background-color: #F5F5FA;\n  padding: 10px 20px;\n  margin: 24px 0;\n  /* border: 10px solid #F5F5FA; */\n  border-radius: 15px;\n  line-height: normal; }\n", ""]);
// Exports
exports.locals = {
	"attentionArea": "ContsrNotes-attentionArea"
};
module.exports = exports;
