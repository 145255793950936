import React, { ReactNode } from 'react';
import { IWprModeProps } from '../props/IWprModeProps';
import { WprBaseModeComponent } from './WprBaseModeComponent';

/**
 * コンテナプロパティ情報
 */
interface IWprContainerProps extends IWprModeProps {
}

/**
 * コンテナコントロール
 */
export class WprContainer extends WprBaseModeComponent<IWprContainerProps> {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: IWprContainerProps){
		super(props);
	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		if (this.state.visible === false || this.isMount == false)
			return <></>;
		return (
			<>
				{this.props.children}
			</>
		);
	}

	/**
	 * コントロール名称取得
	 * @returns コントロール名称
	 */
	public getName(): string {
		return 'WprContainer';
	}
	// --------------------------------------------------------------------------
}