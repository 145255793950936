import React, { ReactNode } from 'react';
import { WprBaseViewComponent } from '../../../../wpr-framework/view/component/WprBaseViewComponent';
import { VideoUnlimitedComfirmView } from './VideoUnlimitedComfirmView';
import { Typography, TableCell, Grid, Divider } from '@material-ui/core';
import { WprAnchor } from '../../../../wpr-framework/component/controls/WprAnchor';
import { MatButton } from '../../../../wpr-framework/component/controls/material/MatButton';
import { MatOneRowTable } from '../../../../wpr-framework/component/controls/material/MatOneRowTable';
import { MatTypography } from '../../../../wpr-framework/component/controls/material/MatTypography';
import { ProgressBar } from '../../common/progress_bar/ProgressBar';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { WprSpan } from '../../../../wpr-framework/component/controls/WprSpan';
import styles from './VideoUnlimitedComfirm.scss';

/**
 * 動画配信,見放題パック申込確認画面用コンポーネント
 */
export class VideoUnlimitedComfirm extends WprBaseViewComponent {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: any) { super(new VideoUnlimitedComfirmView(), props); }
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** 動画配信,見放題パック申込確認画面ビュー情報 */
	public get viewInfo(): VideoUnlimitedComfirmView	{ return this.view as VideoUnlimitedComfirmView;	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * スタイルオブジェクト取得
	*/
	public getStylesObject(): any { return styles; }

	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		return (
			<>
				<div className='pageCard'>
					<div className="pageHeader stepFlow">
						<ProgressBar name='progressBar' view={this.view}/>
					</div>
					<div className='main'>
						<div className='pageTitle'>
							<div className='title_left'>
								<MatTypography name='title' className='title_text' view={this.view}/>
								<Typography className='title_comment'>以下の内容のお申込みでよろしければ、「申込む」ボタンをクリックしてください。</Typography>
							</div>
						</div>
						<div className='feeTable'>
							<Grid container className='feeRow'>
								<Grid item xs={12} md={4} className='feeTitle'>
									<div>見放題パックプライム</div>
								</Grid>
								<Grid item xs={12} md={8} className='feeVal' style={{ color: 'black' }}>
									<span className='itemUnit' style={{ color: '#484848' }}>月額：</span>
									<WprSpan name={'amount'} view={this.view} style={{ color: '#484848', fontWeight: '500'}}></WprSpan>
									<span className='itemUnit' style={{ color: '#484848' }}>円(税込)</span>
								</Grid>
							</Grid>	
						</div>
						<div className='title_left'>
							<Typography className='title_memo'>※表示されている月額は定価となります。実際の請求金額とは異なる場合があります。</Typography>
						</div>
					</div>
					<Divider />
					<div className='pageFooter footer-space'>
						<div>
							<MatButton name='next' view={this.view} className='orangeButton orangeButton_parts' >
								この内容で申込む
								<NavigateNextIcon />
							</MatButton>
						</div>
						<div>
							<MatButton name='back' view={this.view} className='backButton' >
								<NavigateBeforeIcon />
								戻る
							</MatButton>

						</div>
					</div>
				</div>
			</>
		);
	}
	// --------------------------------------------------------------------------
}
