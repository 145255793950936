import { WprBaseViewCore } from '../../WprBaseViewCore';
import { WprBaseListInfoMap } from './WprBaseListInfoMap';
import { WprListInfo } from './WprListInfo';
import { WprActionInfo } from '../../../action/WprActionInfo';
import { WprListActionInfo } from '../../../action/WprListActionInfo';

/**
 * リスト情報マップ
 */
export class WprListInfoMap extends WprBaseListInfoMap<WprListInfo> {
	// private 変数  ------------------------------------------------------------
	private m_ListDataMap:  Map<string, string>;			// リストデータマップ
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	public constructor(viewCore: WprBaseViewCore) {
		super(viewCore);
	}
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** データタイプ */
	public get dataType(): string 			{ return 'リスト'; 			}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * リストデータマップ取得
	 * @returns リストデータマップ
	 */
	public getListDataMap(): Map<string, string> {
		if (!this.m_ListDataMap)
			this.m_ListDataMap = this.FW.model.getDecoNameMap('ListName', this.viewCore);
		return this.m_ListDataMap;
	}

	/**
	 * 新しいView情報取得
	 * @param listName リスト名
	 */
	public getNewListInfo(listName: string): WprListInfo {
		return new WprListInfo(this.viewCore, listName);
	}

	/**
	 * 新しいアクション情報取得
	 * @param name アクション名
	 * @param listName リスト名
	 * @param actionMethod リストアクションメソッド
	 * @param isValid バリデーションフラグ
	 * @param isEvent イベントフラグ
	 */
	public getNewActionInfo(name: string, listName: string, actionMethod: (param: any, item: any) => void, isValid: boolean, isEvent: boolean): WprActionInfo {
		return new WprListActionInfo(name, listName, this.viewCore, actionMethod, isValid, isEvent);
	}
	// --------------------------------------------------------------------------
}