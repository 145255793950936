import { WprKeyValue } from "../../../../wpr-framework/model/WprKeyValue";
import { WprModel } from "../../../../wpr-framework/view/deco/WprModel";
import { WprDI_ScopeModel } from "../../../../wpr-framework/view/di/WprDI_ScopeModel";
import { ObjectUtil } from "../../../common/util/ObjectUtil";
import { ProgressBarModel, ProgressBarStopModel } from "../../../models/ProgressBarModel";
import { MvnoEntryInfo } from "../../../service/models/apimodels/MvnoEntryInfo";
import { HMvnoTmpSaved } from "../../../service/models/entity/HMvnoTmpSaved";
import { MypageBaseView } from "../../base/MypageBaseView";
import { ProgressBarData } from "../../common/progress_bar/ProgressBarView";
import { MvnoEntryDetailPartsData } from "../entry/parts/mvno_entry_detail_parts/MvnoEntryDetailPartsView";

/**
 * MVNO_完了
 */
export class MvnoCompleteView extends MypageBaseView {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() { super('MvnoComplete'); }
	// --------------------------------------------------------------------------

	// モデル  ------------------------------------------------------------------
	@WprDI_ScopeModel('MvnoEntryInfo')
	@WprModel('MvnoEntryInfo')
	private m_EntryInfo: MvnoEntryInfo = null;	// MVNOプラン情報


	@WprDI_ScopeModel('customerName')
	@WprModel('customerName')
	private m_customerName: string = null;

	// --------------------------------------------------------------------------
	// private変数  -------------------------------------------------------------
	public m_AddFlg: boolean	= false;	// 容量追加フラグ
	// --------------------------------------------------------------------------

	public static readonly MVNO_ENTRY: string = 'MVNO申込';
	public static readonly MVNO_CAPACITY_CHANGE: string = 'MVNO容量変更';
	public static readonly MVNO_MNP: string = 'MNP問い合わせ';
	public static readonly MVNO_ADD_COUPON: string = 'データ容量追加申込';

	// override メソッド  --------------------------------------------------------
	/**
	 * コントロール定義設定
	 */
	public onRegisterConfig(): void {

		// this.addConfig('entryTerminalFlg', '端末申込', 'MvnoEntryInfo.entryInfo');
		this.addConfig('terminal', '端末', 'MvnoEntryInfo.entryInfo');
		this.addConfig('simSize', 'SIMサイズ', 'MvnoEntryInfo.entryInfo');

		this.addConfig('customerName', '氏名', 'customerName', { bindModel: true });
		// this.addConfig('firstName', '氏名（名）', 'MvnoEntryInfo.entryInfo');
	}

	/**
	 * アクション登録
	 */
	public onRegisterAction(): void {
		this.addAction('next', this.onNext);
		this.addAction('back', this.onBack);
		this.addAction('toQuestionnaire', this.onToQuestionnaire);
	}

	/**
	 * ビュー表示通知
	 */
	public onShowView(): void {
		this.registAccessLog();
			this.setStorageItem('b_url', window.location.href)
		const type: string = this.getScopeMode('MVNO');
		if (ObjectUtil.isNullOrUndefined(type)) {
			this.pushPath('error');
		}

		switch (type) {
			case MvnoCompleteView.MVNO_ENTRY:
				this.sendToProgress(this.getProgressModelListEntry());
				this.setInfoForENtry();
				// もう一台申込む用に申込み内容を空にする
				this.m_EntryInfo = new MvnoEntryInfo();
				this.setScopeModel('MvnoEntryInfo', this.m_EntryInfo);
				break;
			case MvnoCompleteView.MVNO_CAPACITY_CHANGE:
				this.sendToProgress(this.getProgressModelListChange());
				break;
			case MvnoCompleteView.MVNO_MNP:
				break;
			case MvnoCompleteView.MVNO_ADD_COUPON:
				this.m_AddFlg = true;
				break;
			default:
				break;
		}

		// クッキーの値をリセット（customerId以外(容量追加の場合は契約番号も消さない)）
		const cookieList: WprKeyValue[] = this.getCookieList();
		cookieList.forEach(cookie => {
			const key: string = cookie.key;
			if (key === 'customerId') {
			} else if (this.m_AddFlg && key === 'contractCd') {
			}
			else {
				this.removeCookie(key);
			}
		});
		if (this.m_AddFlg) {
			this.setViewMode('戻る', '容量追加');
		} else {
			this.setViewMode('戻る', '契約一覧');
		}
		this.refreshView();
	}
	// --------------------------------------------------------------------------

	// アクション  ---------------------------------------------------------------
	/**
	 * 次へ
	 */
	public onNext(param: any): void {
		this.pushPath('/mvno_001');
	}

	/**
	 * 戻る
	 */
	public onBack(param: any): void {
		// 容量追加の場合はデータ容量確認・追加申込画面へ遷移
		if (this.m_AddFlg) {
			this.pushPath('/mvno_capacity_001');
		} else {
			location.href = this.getOldUrl() + "Srvlist/Continfo_list/";
		}
	}

	/**
	 * アンケート
	 */
	public onToQuestionnaire(param: any): void {
		window.open('https://questant.jp/q/B7KS5E4S?id=' + this.getCustomerId());
	}
	// --------------------------------------------------------------------------

	private sendToProgress(progressList: ProgressBarModel[]): void {
		const sendData: ProgressBarData = new ProgressBarData();
		sendData.progressBarList = progressList;
		this.sendChildData('fP_ev_progressBarIntital', sendData, 'progressBar');

	}
	private setInfoForENtry(): void {
		const data: MvnoEntryDetailPartsData = new MvnoEntryDetailPartsData();
		data.entryInfo = this.m_EntryInfo;
		this.sendChildData('fP_vP_MvnoCompleteView', data, 'entryDetailParts');
	}

	/**
	 * ステップバー情報セット
	 */
	private getProgressModelListChange(): ProgressBarModel[] {
		const list: ProgressBarModel[] = new Array();

		list.push(new ProgressBarModel('1', 'お申込み', 'complete'));
		list.push(new ProgressBarModel('2', 'お申込み内容確認', 'complete'));
		list.push(new ProgressBarModel('3', '事前確認事項', 'complete'));
		list.push(new ProgressBarModel('4', 'お申込み完了', 'current'));
		return list;
	}
	private getProgressModelListEntry(): ProgressBarModel[] {
		const list: ProgressBarModel[] = new Array();

		list.push(new ProgressBarModel('1', 'お申し込み手続き', 'complete', this.getStopModelList()));
		list.push(new ProgressBarModel('2', '合計金額ご確認', 'complete', null));
		list.push(new ProgressBarModel('3', '事前確認事項', 'complete', null));
		list.push(new ProgressBarModel('4', 'WEB本人確認', 'complete', null));
		list.push(new ProgressBarModel('5', 'お申込み完了', 'current', null));
		return list;
	}
	private getStopModelList(): ProgressBarStopModel[] {
		const list: ProgressBarStopModel[] = new Array();
		list.push(new ProgressBarStopModel('complete'));
		list.push(new ProgressBarStopModel('complete'));
		list.push(new ProgressBarStopModel('complete'));
		list.push(new ProgressBarStopModel('complete'));
		return list;
	}
}
