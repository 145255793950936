import { WprSettingInfo } from './WprSettingInfo';
import { WprBaseFrameworkComponent } from './component/base/WprBaseFrameworkComponent';

/**
 * 管理機能基本クラス
 */
export abstract class WprBaseMngr {
	// private 変数  ------------------------------------------------------------
	private m_Name: string		= null;			// 管理機能名
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** 管理機能名 */
	public get name(): string	{ return this.m_Name;	}
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	public constructor(name: string) {
		this.m_Name = name;
	}
	// --------------------------------------------------------------------------

	// virtual メソッド  --------------------------------------------------------
	/**
	 * 初期化処理
	 * @param loader データローダ
	 * @param setting 設定情報
	 */
	public initialize(loader: WprBaseFrameworkComponent, setting: WprSettingInfo): void {
	}

	/**
	 * 他の管理機能設定処理
	 * @param mngrMap 管理機能マップ
	 */
	public setManager(mngrMap: Map<string, WprBaseMngr>): void {
	}
	// --------------------------------------------------------------------------
}
