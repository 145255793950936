import React, { ReactNode } from 'react';
import { WprBaseViewComponent } from '../../../../wpr-framework/view/component/WprBaseViewComponent';
import { CallForwardingCompleteView } from './CallForwardingCompleteView';
import { Typography, Divider } from '@material-ui/core';
import { WprAnchor } from '../../../../wpr-framework/component/controls/WprAnchor';
import { WprContainer } from '../../../../wpr-framework/component/controls/WprContainer';
import { WprSpan } from '../../../../wpr-framework/component/controls/WprSpan';
import { MatButton } from '../../../../wpr-framework/component/controls/material/MatButton';
import { MatTypography } from '../../../../wpr-framework/component/controls/material/MatTypography';
import importantIcon from '/shared/image/common/important_purple.png';
import questionnaireIcon from '/shared/image/common/button_questionnaire_on.png';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import styles from './CallForwardingComplete.scss';
import { WprRowInfo } from '../../../../wpr-framework/component/props/WprRowInfo';

/**
 * 着信転送完了用コンポーネント
 */
export class CallForwardingComplete extends WprBaseViewComponent {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: any) { super(new CallForwardingCompleteView(), props); }
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** 着信転送完了ビュー情報 */
	public get viewInfo(): CallForwardingCompleteView	{ return this.view as CallForwardingCompleteView;	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * スタイルオブジェクト取得
	*/
	public getStylesObject(): any { return styles; }

	/**
	 * リストコントロール定義登録
	*/
	protected onRegisterListRender(): void {
		this.addListRender('CallOptionList', null, this.onCallOptionListRender);
	}

	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		return (
			<>
			<div>
				<div className='pageCard'>
					<div className='main'>
						<div className='title_center'>
							<MatTypography className='title_complete' name={'title_complete'} view={this.view}/>
						</div>
						<div className='title_normal'>
							<MatTypography name={'user'} view={this.view} className='title_border'/>
						</div>
						<div className='title_normal' style={{ textAlign: 'center', margin: '2rem' }}>
							<WprSpan name={'msg1'} view={this.view} className='title_msg'></WprSpan>
						</div>
						<div className='titleComplete_normal'>
							<MatTypography name={'bikou1'} view={this.view} className='title_memo'/>
						</div>
						<div className='blueArea' style={{marginTop: '50px'}}>
							<div className='header'>
								お申込み内容
							</div>
							<div className='body' style={{justifyContent: 'space-between'}}>
								{this.renderList('CallOptionList')}
							</div>
						</div>
						<div className='blueArea'>
							<div className='header'>
								契約開始日
							</div>
							<div className='body'>
								<span className='itemUnit'>
									WEB本人確認の情報が確認できた翌日
									<br/>※なお、本人確認が未完了の場合、申込み手続きは完了いたしません。あらかじめご了承ください。
								</span>
							</div>
						</div>
						<div className='questionnaire'>
							<WprAnchor name='toQuestionnaire' view={this.view}  >
								<img src={questionnaireIcon} style={{maxWidth: '100%'}} />
							</WprAnchor>
						</div>
					</div>
					<Divider />
					<div className='pageFooter footer-space'>
						<div>
							<MatButton name='back' view={this.view} className='completeButton backContInfoList' >
								<NavigateBeforeIcon />
								<span className='screen'>契約内容の確認・変更メニューへ</span>
							</MatButton>
						</div>
					</div>
				</div>
			</div>
			</>
		);
	}
	// --------------------------------------------------------------------------

	// リスト描画  ---------------------------------------------------------------
	/**
	 * リスト名=CallOptionList
	 */
	public onCallOptionListRender(rowInfo: WprRowInfo): any {
		return (
			<div>
				<div style={{display: 'flex'}} className='feeUnit'>
					<MatTypography name='optionName' view={this.view} row={rowInfo}/>
					<span>：月額：</span>
					<MatTypography name='optionFee' view={this.view} row={rowInfo}/>
					<MatTypography name='payUnit' view={this.view} row={rowInfo}/>
					<span>（税込）</span>
				</div>
			</div>
		);
	}
	// --------------------------------------------------------------------------
}
