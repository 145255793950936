import { WprDI_ScopeModel } from '../../../../wpr-framework/view/di/WprDI_ScopeModel';
import { MypageBaseView } from '../../base/MypageBaseView';
import { ProgressBarData } from '../../common/progress_bar/ProgressBarView';
import { MypageWizardMngr } from '../../wizard/models/MypageWizardMngr';
import { WprListData } from '../../../../wpr-framework/view/deco/WprListData';
import { RelocateServiceListModel } from '../../../models/mypage/relocate/RelocateServiceListModel';
import { ObjectUtil } from '../../../common/util/ObjectUtil';
import { BssConstants } from '../../../common/constants/BssConstants';
import MypageDispdivcdConstants from '../../../common/MypageDispdivcdConstants';
import { WprModel } from '../../../../wpr-framework/view/deco/WprModel';
import { RelocateBaseModel } from '../../../models/mypage/relocate/RelocateBaseModel';
import { WprViewMode } from '../../../../wpr-framework/view/deco/WprViewMode';
import { RelocateComfirmMode } from './mode/RelocateComfirmMode';

/**
 * 移設申込選択1画面
 */
export class RelocateSelect1View extends MypageBaseView {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() { super('RelocateSelect1View'); }
	// --------------------------------------------------------------------------
	// モデル  ------------------------------------------------------------------
	@WprDI_ScopeModel('MypageWizardMngr')
	private m_MypageWizardMngr: MypageWizardMngr			= null;		// 画面ウィザード管理クラス
	@WprListData('TvListModel')
	private m_TvListModel: RelocateServiceListModel[]	= null;	// TVサービス一覧モデル
	@WprListData('NetListModel')
	private m_NetListModel: RelocateServiceListModel[]	= null;	// netサービス一覧モデル
	@WprListData('PhoneListModel')
	private m_PhoneListModel: RelocateServiceListModel[]	= null;	// phoneサービス一覧モデル
	@WprModel('RelocateBaseModel')
	private m_RelocateBaseModel: RelocateBaseModel	= null;	// 移設基本モデル
	// --------------------------------------------------------------------------

	// サブビュー/モード  --------------------------------------------------------
	@WprViewMode
	private m_RelocateComfirmMode = new RelocateComfirmMode();	// 移設確認モード
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * コントロール定義設定
	 */
	public onRegisterConfig(): void {
		this.addListConfig('select', '選択', 'TvListModel');
		this.addListConfig('no', '台目', 'TvListModel');
		this.addListConfig('serviceInfo', 'サービス名', 'TvListModel');
		this.addListConfig('select', '選択', 'NetListModel');
		this.addListConfig('no', '台目', 'NetListModel');
		this.addListConfig('serviceInfo', 'サービス名', 'NetListModel');
		this.addListConfig('select', '選択', 'PhoneListModel');
		this.addListConfig('no', '台目', 'PhoneListModel');
		this.addListConfig('serviceInfo', 'サービス名', 'PhoneListModel');
		this.addConfig('reformFlg', 'リフォームフラグ', 'RelocateBaseModel');
		this.addConfig('confirmFlg', '同意フラグ', 'RelocateBaseModel');
		this.addConfig('tokotonAddFlg', 'とことんサポート加入フラグ', 'RelocateBaseModel');
	}

	/**
	 * アクション登録
	 */
	public onRegisterAction(): void {
		this.addAction('next', this.onNext);
		this.addAction('back', this.onBack);
	}

	/**
	 * ビュー表示通知
	 */
	public onShowView(): void {
		this.m_MypageWizardMngr.initView(this, (result) => {
			this.registAccessLog();
			this.setStorageItem('b_url', window.location.href);
			const sendData: ProgressBarData = new ProgressBarData();
			sendData.progressBarList = this.m_MypageWizardMngr.getProgressModelList(this);
			this.sendChildData('fP_ev_progressBarIntital', sendData);

			this.m_TvListModel = this.m_MypageWizardMngr.getCache().m_TvServiceList;
			this.m_NetListModel = this.m_MypageWizardMngr.getCache().m_NetServiceList;
			this.m_PhoneListModel = this.m_MypageWizardMngr.getCache().m_PhoneServiceList;
			this.m_RelocateBaseModel = this.m_MypageWizardMngr.getCache().m_RelocateBaseModel;
			const contractList = this.m_MypageWizardMngr.getViewInfo().contractModel.contractList;
			if (this.m_TvListModel.length === 0 && this.m_NetListModel.length === 0 && this.m_PhoneListModel.length === 0) {
				let tvNo = 1;
				let netNo = 1;
				let phoneNo = 1;
				this.m_RelocateBaseModel.tokotonFlg = false
				for (const contract of contractList) {
					const model = new RelocateServiceListModel();
					//解約予定のないものを移設対象とする
					if (!contract.cancelDate || (contract.cancelDate && contract.cancelDate === '99991231') ) {
						//tv
						if (contract.serviceTypeCD === BssConstants.BSS_CONTRACT_SERVICETYPECD_TVF || contract.serviceTypeCD === BssConstants.BSS_CONTRACT_SERVICETYPECD_TV) {
							model.contract = contract;
							const serviceName = this.m_MypageWizardMngr.getMDisplayList(this, MypageDispdivcdConstants.TV_SERVICE, '').filter(item => item.namecd === contract.serviceCD);
							if (serviceName.length > 0) {
								model.serviceName = serviceName[0].dispnamep;
								const deviceNameList = this.m_MypageWizardMngr.getMDisplayList(this, MypageDispdivcdConstants.ITSCOMHOME_TERMINALTYPE, '');
								const deviceFormList = this.m_MypageWizardMngr.getMDisplayList(this, MypageDispdivcdConstants.ITSCOMHOME_TERMINALSTATUS, '');
								const deviceType = deviceNameList.filter(row => row.namecd === contract.equipment[0].equipmentTypeCD);
								const deviceForm = deviceFormList.filter(row => row.namecd === contract.equipment[0].rentalFlag);
								if (ObjectUtil.isNotNullOrUndefined(deviceType) && deviceType.length > 0)
									model.terminalName = deviceType[0].dispnamep;
								else 
									model.terminalName = '';
								if (ObjectUtil.isNotNullOrUndefined(deviceForm) && deviceForm.length > 0)
									model.appltype = deviceForm[0].dispnamep;
								model.serviceInfo = model.serviceName + '　' + model.terminalName + '（' + model.appltype + '）';
								model.no = tvNo + '台目';
								tvNo++;
								this.m_TvListModel.push(model);
							}
						}
						//net
						else if (contract.serviceTypeCD === BssConstants.BSS_CONTRACT_SERVICETYPECD_NETF || contract.serviceTypeCD === BssConstants.BSS_CONTRACT_SERVICETYPECD_NET) {
							model.contract = contract;
							const serviceName = this.m_MypageWizardMngr.getMDisplayList(this, MypageDispdivcdConstants.ACCESSPASS_SERVICE, '').filter(item => item.namecd === contract.serviceCD);
							if (serviceName.length > 0) {
								model.serviceName = serviceName[0].dispnamep;
								const equ1 = this.m_MypageWizardMngr.getMCommonList(this, 'Add_net', 'equ', 'req', null);
								const equ2 = this.m_MypageWizardMngr.getMCommonList(this, 'Add_net', 'equ', 'req2', null);
								let equType = null;
								if (ObjectUtil.isNullOrUndefined(contract.equipment[0].equipmentTypeCD) || contract.equipment[0].equipmentTypeCD === equ1[0].commonValue ||contract.equipment[0].equipmentTypeCD === equ2[0].commonValue) {
									const device10000 = this.m_MypageWizardMngr.getMCommonList(this, 'Add_net', 'equ', 'rep', null);
									equType = device10000[0].commonValue;
									model.terminalName = '';
									model.serviceInfo = model.serviceName;
								}
								else {
									equType =contract.equipment[0].equipmentTypeCD;
									const donu = this.m_MypageWizardMngr.getMCommonList(this, 'srvnet', 'd_onu_code', null, null).filter(item => item.commonValue === equType);
									if (donu.length === 0) {
										const deviceNameList = this.m_MypageWizardMngr.getMDisplayList(this, MypageDispdivcdConstants.ITSCOMHOME_TERMINALTYPE, '');
										const deviceFormList = this.m_MypageWizardMngr.getMDisplayList(this, MypageDispdivcdConstants.ITSCOMHOME_TERMINALSTATUS, '');
										const deviceType = deviceNameList.filter(row => row.namecd === contract.equipment[0].equipmentTypeCD);
										const deviceForm = deviceFormList.filter(row => row.namecd === contract.equipment[0].rentalFlag);
										if (ObjectUtil.isNotNullOrUndefined(deviceType) && deviceType.length > 0)
											model.terminalName = deviceType[0].dispnamep;
										else 
											model.terminalName = '';
										if (ObjectUtil.isNotNullOrUndefined(deviceForm) && deviceForm.length > 0)
											model.appltype = deviceForm[0].dispnamep;
										model.serviceInfo = model.serviceName + '　' + model.terminalName + '（' + model.appltype + '）';
									}
								}
								if (model.serviceInfo) {
									model.no = netNo + '台目';
									netNo++;
									this.m_NetListModel.push(model);
								}		
							}
						}
						//phone
						else if (contract.serviceTypeCD === BssConstants.BSS_CONTRACT_SERVICETYPECD_KDDI || contract.serviceTypeCD === BssConstants.BSS_CONTRACT_SERVICETYPECD_PHONEF) {
							model.contract = contract;
							let service = this.m_MypageWizardMngr.getMDisplayList(this, MypageDispdivcdConstants.ITSCOMPHONE_SERVICE2, '').filter(item => item.namecd === contract.serviceCD);
							if (service.length > 0)
								model.serviceName = service[0].dispnamep;
							service = this.m_MypageWizardMngr.getMDisplayList(this, MypageDispdivcdConstants.ITSCOMPHONE_SERVICE3, '').filter(item => item.namecd === contract.serviceCD);
							if (service.length > 0)
								model.serviceName = service[0].dispnamep;
							service = this.m_MypageWizardMngr.getMDisplayList(this, MypageDispdivcdConstants.ITSCOMPHONE_SERVICE1, '').filter(item => item.namecd === contract.serviceCD);
							if (service.length > 0)
								model.serviceName = service[0].dispnamep;
							if (model.serviceName) {
								model.appltype = this.m_MypageWizardMngr.getMDisplayList(this, MypageDispdivcdConstants.ITSCOMHOME_TERMINALSTATUS, '').filter(item => item.namecd === contract.equipment[0].rentalFlag)[0].dispnamep;
								model.no = phoneNo + '台目';
								model.serviceInfo = model.serviceName + '（' + model.appltype + '）';
								phoneNo++;
								this.m_PhoneListModel.push(model);
							}
						}
						//とことんサポート加入判定
						const tokotonList = this.m_MypageWizardMngr.getMDisplayList(this, MypageDispdivcdConstants.TOKOTON_SUPPORT_COURSE_NAME, '').filter(item => item.namecd === contract.serviceCD);
						if (tokotonList.length !== 0) 
							this.m_RelocateBaseModel.tokotonFlg = true;
					}
				}
			}
			if (this.m_TvListModel.length > 0) {
				this.setListData('TvListModel', this.m_TvListModel);
				this.setViewMode('テレビ', '表示');
			}
			if (this.m_NetListModel.length > 0) {
				this.setListData('NetListModel', this.m_NetListModel);
				this.setViewMode('ネット', '表示');
			}
			if (this.m_PhoneListModel.length > 0) {
				this.setListData('PhoneListModel', this.m_PhoneListModel);
				this.setViewMode('電話', '表示');
			}
			if (!this.m_RelocateBaseModel.tokotonFlg)
				this.setViewMode('とことんサポート', '表示');
			this.refreshView();
		});
	}

	/**
	 * 値変更通知
	 * @param name 名前
	 * @param value 値
	 * @returns falseの場合、変更を取り消す
	 */
	public onChangeValue(name: string, value: any): boolean {
		this.refreshModel()
		this.updateMode();
		return true;
	}

	/**
	 * リスト値変更通知
	 * @param name 名前
	 * @param listName リスト名
	 * @param row 行データ
	 * @param value 値
	 * @returns falseの場合、変更を取り消す
	 */
	public onChangeListValue(name: string, listName: string, row: any, value: any): boolean {
		this.refreshModel()
		this.updateMode();
		return true;
	}
	// --------------------------------------------------------------------------

	// アクション  ---------------------------------------------------------------
	/**
	 * 次へ         <NavigateNextIcon />
	 */
	public onNext(param: any): void {
		this.refreshModel();
		this.m_RelocateBaseModel.tvList = new Array();
		this.m_RelocateBaseModel.netList = new Array();
		this.m_RelocateBaseModel.phoneList = new Array();
		for (const tv of this.m_TvListModel) {
			tv.floor = null;
			tv.location = null;
			tv.comment = null;
			if (tv.select) 
				this.m_RelocateBaseModel.tvList.push(tv);
		}
		for (const net of this.m_NetListModel) {
			net.floor = null;
			net.location = null;
			net.comment = null;
			if (net.select)
				this.m_RelocateBaseModel.netList.push(net);
		}
		for (const phone of this.m_PhoneListModel) {
			phone.floor = null;
			phone.location = null;
			phone.comment = null;
			if (phone.select)
				this.m_RelocateBaseModel.phoneList.push(phone);
		}
		this.m_MypageWizardMngr.goNext(this);
	}
	/**
	 * <NavigateBeforeIcon />         戻る
	 */
	public onBack(param: any): void {
		this.m_MypageWizardMngr.goBack(this);
	}
	// --------------------------------------------------------------------------
}
