/**
 * 契約情報登録リクエスト
 */
export class RegistContractRequest {
	// public 変数	-----------------------------------------------------------
	public customerId: string;				// お客様番号
	public serviceCd: string;				// サービスコード
	public startDate: string;				// 契約開始日
	public oldContractId: string;			// 契約番号
	public paymentCustomerId: string;		// 支払いお客様番号
	public chargeTypeCd: string;			// 支払区分
	public equipmentTypeCd: string;			// 機器区分
	public rentalFlag: string;				// レンタルフラグ
	public infrastructureTypeCd: string;	// インフラ区分
	public optionList: String[];			// オプション一覧リスト
	// ------------------------------------------------------------------------
}
