import { WizardModel } from '../../models/WizardModel';
import { MypageWizardFlow } from './MypageWizardFlow';
import { MypageBaseView } from '../base/MypageBaseView';
import { AddAccesspassSelectView } from '../internet/add_accesspass_select/AddAccesspassSelectView';
import { AddAccesspassConfirmView } from '../internet/add_accesspass_confirm/AddAccesspassConfirmView';
import { GetViewInfoRequest } from '../../service/models/dto/mypagerenewal/GetViewInfoRequest';
import { MDisplay } from '../../service/models/entity/secondary/MDisplay';
import { AccesspassPrintView } from '../internet/accesspass_print/AccesspassPrintView';
import { MCommon } from '../../service/models/entity/primary/MCommon';
import { AccesspassModel } from '../../service/models/apimodels/AccesspassModel';
import { MypageWizardMngr } from './models/MypageWizardMngr';
import MypageDispdivcdConstants from '../../common/MypageDispdivcdConstants';
import { AddAccesspassCompleteView } from '../internet/add_accesspass_complete/AddAccesspassCompleteView';
import { RegistInfoRequest } from '../../service/models/dto/mypagerenewal/RegistInfoRequest';

/**
 * ITSCOM HOME_追加ウィザードクラス
 */
export class AddAccesspassNewWizardFlow extends MypageWizardFlow {
	// private 変数  ------------------------------------------------------------
	private m_DefaultWizardFlow: WizardModel[] = null;		// 画面ウィザードリスト
	private m_StartViewName: string = null;
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	public constructor() {
		super();
		this.m_StartViewName = new AddAccesspassSelectView().name;

		// 遷移パターン１
		this.m_DefaultWizardFlow = new Array();
		this.m_DefaultWizardFlow.push({ stepNum: 0, title: '契約選択', viewName: null, path: this.getOldUrl() + 'Srvlist/Continfo_list/' });
		this.m_DefaultWizardFlow.push({ stepNum: 1, title: '変更内容の入力', viewName: this.m_StartViewName, path: 'add_accesspass_select' });
		this.m_DefaultWizardFlow.push({ stepNum: 2, title: '変更内容の確認', viewName: new AddAccesspassConfirmView().name, path: 'add_accesspass_confirm' });
		this.m_DefaultWizardFlow.push({ stepNum: 3, title: '変更完了', viewName: new AddAccesspassCompleteView().name, path: 'add_accesspass_complete' });
		this.m_DefaultWizardFlow.push({ stepNum: 0, title: '印刷', viewName: new AccesspassPrintView().name, path: 'accesspass_print'});
	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * 画面ウィザードフロー名取得
	 */
	public getWizardFlowName(viewName: string): string {
		if (viewName === this.m_StartViewName)
			return this.constructor.name;
		return null;
	}

	/**
	 * 画面ウィザードリスト取得：契約状態で切り替えたり、選択肢で切り替えたり
	 */
	public getWizardFlow(mngr: MypageWizardMngr): WizardModel[] {
		return this.m_DefaultWizardFlow;
	}

	/**
	 * 画面ウィザードAPI用モデル取得：契約状態で切り替えたり、選択肢で切り替えたり
	 */
	public getWizardApiModel(mngr: MypageWizardMngr, view: MypageBaseView): GetViewInfoRequest {
		const req = new GetViewInfoRequest();
		req.customerId = mngr.getParams().customerId;
		req.displayList = new Array();
		req.contractFlg = true;
		const filter = new MDisplay();
		filter.dispdivcd = MypageDispdivcdConstants.ACCESSPASS_SERVICE;
		req.displayList.push(filter);
		req.commonList = new Array();
		req.commonList.push(new MCommon('暗号化キー','cryptkey'));
		return req;
	}

	/**
	 * 画面ウィザードフローの中断判定：例：申し込みが完了していれば、完了済みの画面へ遷移
	 */
	public checkFlowBreak(mngr: MypageWizardMngr): string {
		let test: boolean = false;
		if (test) {
			return '/error';
		}
		return null;
	}

	/**
	 * 画面キャッシュの初期化データ取得
	 */
	public getInitCacheData(mngr: MypageWizardMngr): AddAccesspassWizardModel {
		let cacheData = new AddAccesspassWizardModel();
		cacheData.m_AccesspassInfo = new AccesspassModel();
		return cacheData;
	 }
	// --------------------------------------------------------------------------
}
/**
 * 画面ウィザードAPI用モデル
 */
export class AddAccesspassWizardModel {
	// public 変数	-----------------------------------------------------------
	public m_AccesspassInfo: AccesspassModel = null;	// ItscomHomeコースモデル
	// 	// ------------------------------------------------------------------------
}