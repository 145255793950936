import { GetGradeResponse } from "../../../service/models/dto/mypagerenewal/GetGradeResponse";
import { MGeneral } from "../../../service/models/entity/MGeneral";
import { MCampaign } from "../../../service/models/entity/primary/MCampaign";
import { MCampaignGroup } from "../../../service/models/entity/primary/MCampaignGroup";

/**
 * ネット画面情報
 */
export class CourseListModel {
	// public 変数	-----------------------------------------------------------
	public cDispnamep: string;						// コース名
	public nDispnamep: string;						// 回線種別
	public payAmount: string;						// 料金一覧
	public payUnit: string;							// 料金単位
	public check: boolean;							// 選択
	public equipmentProvidingCheck: boolean;		// 機器のご提供方法（レンタル）
	public buyCheck: boolean;						// 機器のご提供方法（購入）
	public noChange: boolean;						// 機器のご提供方法（希望無し）
	public curFee: string;							// 旧月額
	public newFee: string;							// 新月額
	public yesOtoku: boolean;						// お得パック希望
	public noOtoku: boolean;						// お得パック希望しない
	public otokuFlg: boolean;						// お得パック加入判定
	public otokuPlan: string;						// 現在のプラン名
	public cable: boolean;							// ケーブルモデム
	public cableWifi: boolean;						// ケーブルモデム（wifi）
	public noChangeCable: boolean;					// ケーブルモデム(変更なし)
	public equipmentSelect: string; 				// 機器の種類
	public contractCourse: string;					// 契約コース名
	public deviceType: string;						// 機器の種類(変更前)
	public deviceForm: string;						// 機器の形態(変更前)
	public contractAmount: string;					// 月額
	public afterEquipment: string;					// 変更後の機器の種類
	public afterProvision: string;					// 変更後の機器の提供方法
	public serviceCd: string;						// 変更前サービスコード
	public aftServiceCd: string;					// 変更後サービスコード
	public tanakoServiceCd:string;					// 店子サービスCD
	public macAddress: string;						// MACアドレス
	public accessAccount: string;					// アクセスアカウント
	public contractStatus: string;					// 契約形態
	public directflg: boolean;						// 契約種別
	public startDate: string;						// 契約開始日
	public itemName: string;						// 項目名
	public itemData: string;						// 項目データ
	public planCd: string;							// プランコード(メモ：お得パック名のコードを入れている？)
	public tanakoOwnerCd: string;					// サービスCD(店子)
	public befCableFlg: boolean;						// 前契約ケーブルフラグ
	public befCableWifiFlg: boolean;					// 前契約ケーブルWifiフラグ
	public kattobiFlg: boolean;						// かっとびコースフラグ
	public directResult: boolean;					// 直接申込み/仮申込み
	public campaignCode: string;					// 入力キャンペーンコード
	public campaignGroup: MCampaignGroup;					// キャンペーングループ
	public campaignList: MCampaign[];					// キャンペーン詳細
	/** 移設 */
	public desiredDate1: string;					// 第一希望
    public desiredDate2: string;					// 第二希望
    public desiredDate3: string;					// 第三希望
    public selectTime1: string;						// 第一希望　時間帯
    public selectTime2: string;						// 第二希望　時間帯
    public selectTime3: string;						// 第三希望　時間帯
	public floor: string;							// 階数
	public location: string;						// 場所
	public comment: string;							// フリー入力
	public beforeContact: string;					// 前日連絡先
	public sameDayContact: string;					// 当日連絡先
	public name: string;							// 当日立ち合い者
	public dateFlg: boolean;						// 希望日時フラグ
	public reformFlg: boolean;						// リフォームフラグ
	public securityFlg: boolean;					// セキュリティフラグ
	public securityComment: string;					// セキュリティフリー入力
	public tvPortFlg: boolean;						// TV端子確認フラグ
	public constractionFlg: boolean;				// 工事フラグ
	public cost: string;							// 工事費
	public grade: GetGradeResponse;					// グレード情報
	public reformDisp: string;						// リフォーム(表示用)
	public securityDisp: string;					// セキュリティ(表示用)
	// ------------------------------------------------------------------------
}
