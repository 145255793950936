/**
 * コントロール権限情報
 */
export class WprControlAuthInfo {
	// private 変数  ------------------------------------------------------------
	private m_IsReadOnly: boolean	= null;		// 読取専用
	private m_IsEnabled: boolean	= null;		// 活性
	private m_StyleClass: string	= null;		// スタイルクラス
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** 読取専用 */
	public get isReadOnly(): boolean	{ return this.m_IsReadOnly; }	public set isReadOnly(isReadOnly: boolean)	{ this.m_IsReadOnly = isReadOnly;	}
	/** 活性 */
	public get isEnabled(): boolean		{ return this.m_IsEnabled; 	}	public set isEnabled(isEnabled: boolean)	{ this.m_IsEnabled = isEnabled;		}
	/** スタイルクラス */
	public get styleClass(): string		{ return this.m_StyleClass; }	public set styleClass(styleClass: string)	{ this.m_StyleClass = styleClass;	}
	// --------------------------------------------------------------------------
}
