import React, { ReactNode } from 'react';
import { WprBaseViewComponent } from '../../../../wpr-framework/view/component/WprBaseViewComponent';
import { NameChangeNewInformationView } from './NameChangeNewInformationView';
import styles from './NameChangeNewInformation.scss';
import { MatButton } from '../../../../wpr-framework/component/controls/material/MatButton';
import itscomIcon from '/shared/image/common/itscomMyp_icon_blue.png';
import { ProgressBar } from '../../common/progress_bar/ProgressBar';
import { MatCheckBox } from '../../../../wpr-framework/component/controls/material/MatCheckBox';
import { Divider, Typography } from '@material-ui/core';
import { MatTypography } from '../../../../wpr-framework/component/controls/material/MatTypography';
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';

/**
 * 新名義人受付の案内用コンポーネント
 */
export class NameChangeNewInformation extends WprBaseViewComponent {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: any) { super(new NameChangeNewInformationView(), props); }
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** 新名義人受付の案内ビュー情報 */
	public get viewInfo(): NameChangeNewInformationView	{ return this.view as NameChangeNewInformationView;	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * スタイルオブジェクト取得
	*/
	public getStylesObject(): any { return styles; }

	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		return (
			<>
				<div className='newNameChange'>
					<div className='nameChange-icon-hearder'>
						<img src={itscomIcon}/>
					</div>
					<div className='newNameChange-card'>
						<div className='main'>
							<div className='title_center'>
								<Typography className='title_complete'>利用規約</Typography>
							</div>
							<br />
							<Divider />
							<p className='ColumText'>
								マイページをご利用いただくためには、本利用規約に同意のうえ、ご利用ください。<br/>
								利用規約はログイン後、ヘルプページからもご確認いただくことができます。<br/>
								なお、利用規約を改定された場合、改めて同意いただく場合がございますので予めご了承ください。
							</p>
							<div className='userpolicyLink'>
								<MatButton name='yakkan' variant='outlined' view={this.view}>
									<MatTypography name='yakkanName' view={this.view}/>
									<TextSnippetOutlinedIcon />
								</MatButton>
							</div>
							<div style={{ textAlign: 'center' }}>
								<MatCheckBox name='confirm' view={this.view} label='承諾する' />
							</div>
							<br />
							<div className='pageFooter footer-space'>
								<MatButton name='next' view={this.view} className='orangeButton orangeButton_parts grayButtom' >
									新名義人受付フォームへ
								</MatButton>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
	// --------------------------------------------------------------------------
}
