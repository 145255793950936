// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@charset \"UTF-8\";\n/*\r\n * 着信転送確認用CSS\r\n */\n.CallForwardingConfirm-identificationCheckList {\n  height: 49vh;\n  overflow-y: scroll; }\n  .CallForwardingConfirm-identificationCheckList .CallForwardingConfirm-boxItemTitle {\n    font-style: normal;\n    font-weight: 700;\n    font-size: 16px;\n    line-height: 160%; }\n  .CallForwardingConfirm-identificationCheckList ul,\n  .CallForwardingConfirm-identificationCheckList ol {\n    margin-block-start: 0; }\n\n.MuiTypography-root.identificationTitle {\n  font-style: normal;\n  font-weight: 700;\n  font-size: 24px;\n  color: #6169b8;\n  line-height: 160%; }\n", ""]);
// Exports
exports.locals = {
	"identificationCheckList": "CallForwardingConfirm-identificationCheckList",
	"boxItemTitle": "CallForwardingConfirm-boxItemTitle"
};
module.exports = exports;
