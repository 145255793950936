/**
 * 契約情報取得リクエスト
 */
export class GetCapacityChangeRequest {
	// public 変数	-----------------------------------------------------------
	public cusomerId: string;				// 顧客ID
	public contractId: string;				// 
	public capacityServiceCdAfter: string;	// 
	// ------------------------------------------------------------------------
}
