

export class MypageConstants {
	// static 変数 -------------------------------------------------
	// MVNO：容量
	public static readonly MVNO_CAPACITY: string = '230';
	// MVNO：コース（※音声プラン）
	public static readonly MVNO_COURSE: string = '235';
	// MVNO：留守番（※音声プラン）
	public static readonly MVNO_RUSUBAN: string = '271';
	// MVNO：クーポン容量
	public static readonly MVNO_COUPON_CAPACITY: string = '280';
}