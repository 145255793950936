import { WprBaseConverter } from '../../../../wpr-framework/view/convert/WprBaseConverter';
import { ObjectUtil } from '../../../common/util/ObjectUtil';

/**
 * 日付
 */
export class DateConverter extends WprBaseConverter {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() {
		super('日付（yyyy/MM/dd）');
	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * 値を表示用の値に変換する (value -> viewValue)
	 * @param value 値
	 * @returns 表示用の値
	 */
	public convertView(value: any): string {
		const strVal: string = value as string;
		if (ObjectUtil.isNotNullOrUndefined(strVal) && strVal.length === 8) {
			const year: string = strVal.substring(0, 4);
			const month: string = strVal.substring(4, 6);
			const date: string = strVal.substring(6);
			return year + '/' + month + '/' + date;
		}
		else if (ObjectUtil.isNotNullOrUndefined(strVal) && strVal.length === 10) {
			const year: string = strVal.substring(0, 4);
			const month: string = strVal.substring(5, 7);
			const date: string = strVal.substring(8);
			return year + '/' + month + '/' + date;
		}
		return value;
	}

	/**
	 * 編集用の値を値に変換する (editValue -> value)
	 * @param editValue 編集用の値
	 * @returns 値
	 */
	public convertValue(editValue: string): any {
		// const strVal: string = value as string;
		if (ObjectUtil.isNotNullOrUndefined(editValue) && editValue.length === 10) {
			const year: string = editValue.substring(0, 4);
			const month: string = editValue.substring(5, 7);
			const date: string = editValue.substring(8);
			return year + month + date;
		}
		return editValue;
	}

	/**
	 * 値を編集用の値に変換する (value -> editValue)
	 * @param value 値
	 * @returns 編集用の値
	 */
	public convertEdit(value: any): any {
		const strVal: string = value as string;
		if (ObjectUtil.isNotNullOrUndefined(strVal) && strVal.length === 8) {
			const year: string = strVal.substring(0, 4);
			const month: string = strVal.substring(4, 6);
			const date: string = strVal.substring(6);
			return year + '-' + month + '-' + date;
		}
		return value;
	}
	// --------------------------------------------------------------------------
}
