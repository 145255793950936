import { Grid, Typography } from "@mui/material";
import { info } from "node-sass";
import { ConvertUtil } from "../../common/util/ConvertUtil";
import { ConvertViewUtil } from "../../common/util/ConvertViewUtil";

import phone_icon from "/shared/image/mvno/plan/phone_icon.png";


// データ ----------------------------------------------------------------------
export class MvnoPlanSendData {

}
export class MvnoTerminalSendData {
    val: string;
    imgBase64: string;
    price: string;
    // stockNum: number;
}
export class MvnoTerminalColorSendData {
    val: string;
    imgBase64: string;
    stockNum: number;
}
export class MvnoCapacitySendData {
    val: string;
    price: string;
    decription: string;
    comment: string;

    // selected: boolean;
}
export class MvnoCourseSendData {
    val: string;
    price: string;
    decription: string;
}

export class MvnoTerminalUmuSendData {
    val: string;
    decription: string;
}

export class MvnoSimSizeSendData {
    val: string;
    img: any;	// 画像
    tate: string;
    yoko: string;
}
export class MvnoInitialSupportSendData {
    val: string;
    decription: string;
}

export class MvnoWebFilteringSendData {
    val: string;
    account: string;
    decription: string;
}

export class MvnoSimOptionSendData {
    val: string;
    img: any;	// 画像
    decription: string;
}
// ------------------------------------------------------------------------------------------

export class CheckMarkRadioContent {
    public static readonly MVNO_PLAN: string = 'プラン';
    public static readonly MVNO_CAPACITY: string = 'データ量';
    public static readonly MVNO_COURSE: string = 'コース';
    public static readonly MVNO_TERMINAL_UMU: string = '端末有無';
    public static readonly MVNO_TERMINAL: string = '端末';
    public static readonly MVNO_TERMINAL_COLOR: string = '端末カラー';
    public static readonly MVNO_SIM_SIZE: string = 'SIMサイズ';
    public static readonly MVNO_SIM_OPTION: string = 'SIMオプション';
    public static readonly MVNO_INITITAL_SUPPORT: string = '初期サポート';
    public static readonly MVNO_WEB_FILTERING: string = 'Webフィルタリング';

    // public ----------------------------------------------------------------
    /**
     * 
     * @param radioType 
     * @param data 
     * @returns 
     */
    public static getContent(radioType: string, data: any, disabled: boolean): any {
        switch (radioType) {
            // 1
            case CheckMarkRadioContent.MVNO_PLAN:
                return this.getPlanContent(data as MvnoPlanSendData);
            // 2
            case CheckMarkRadioContent.MVNO_CAPACITY:
                return this.getCapacityContent(data as MvnoCapacitySendData);
            case CheckMarkRadioContent.MVNO_COURSE:
                return this.getCourseContent(data as MvnoCourseSendData, disabled);
            // 3
            case CheckMarkRadioContent.MVNO_TERMINAL_UMU:
                return this.getTerminalUmuContent(data as MvnoTerminalUmuSendData);
            case CheckMarkRadioContent.MVNO_TERMINAL:
                return this.getTerminalContent(data as MvnoTerminalSendData, disabled);
            case CheckMarkRadioContent.MVNO_TERMINAL_COLOR:
                return this.getTerminalColorContent(data as MvnoTerminalColorSendData, disabled);
            case CheckMarkRadioContent.MVNO_SIM_SIZE:
                return this.getSimSizeContent(data as MvnoSimSizeSendData);
            case CheckMarkRadioContent.MVNO_SIM_OPTION:
                return this.getSimOptioContent(data as MvnoSimOptionSendData);
            case CheckMarkRadioContent.MVNO_INITITAL_SUPPORT:
                return this.getInitialSupportContent(data as MvnoInitialSupportSendData);
            case CheckMarkRadioContent.MVNO_WEB_FILTERING:
                return this.getWebFilteringContent(data as MvnoWebFilteringSendData);
            // 4
            default:
                break;
        }
    }

    // private ---------------------------------------------------------------
    // ************************************************************************
    /**
     * プラン
     * @param data 
     * @returns 
     */
    private static getPlanContent(data: MvnoPlanSendData): any {
        return (
            <>
                <Grid container textAlign='center' alignItems="center" justifyItems="center">
                    <Grid item md={2} xs={4}>
                        <div>
                            <img src={phone_icon} className='planIcon' />
                        </div>
                    </Grid>
                    <Grid item md={10} xs={8} style={{ textAlign: 'left' }}>
                        <div className='radioTitle1'>
                            音声プラン
                        </div>
                        <div className='radioText'>
                            通信料・端末料金を押さえたい方やガラケーからの乗り換えや、初めてスマートフォンをご利用される方におすすめ！
                        </div>

                    </Grid>
                </Grid>
            </>
        );

    }

    // ************************************************************************   
    /**
     * データ量
    * @param data 
    * @returns 
    */
    private static getCapacityContent(data: MvnoCapacitySendData): any {
        const val: string = data.val.split('GB')[0];
        // const price: string = data.price;
        const price: string = ConvertUtil.convertToComma(data.price);
        const description: string = data.decription;
        return (
            <>
                <Grid container textAlign='center' alignItems="center" justifyItems="center" spacing={1}>
                    <Grid item xs={3} md={1}>
                        <div>
                            <span className='capacityValue'>{val}</span>
                            <span className='capacityGB'>GB</span>
                        </div>
                    </Grid>
                    <Grid item xs={9} md={11} style={{ textAlign: 'left', padding: '0.5rem' }}>
                        <div className='radioTitle1'>
                            {price}円
                        </div>
                        <div className='radioText'>
                            {description}
                        </div>

                    </Grid>
                </Grid>
            </>
        );

    }

    /**
    * コース
    * @param data 
    * @returns 
    */
    private static getCourseContent(data: MvnoCourseSendData, disabled: boolean): any {
        const val: string = data.val;
        const price: string = ConvertUtil.convertToComma(data.price);

        return (
            <>
                <Grid container textAlign={"left"} spacing={1}>
                    <Grid item xs={12}>
                        <div className='radioTitle2'>
                            <span>{val}コース</span>
                            {price != null &&
                                <span>　{price}円</span>
                            }
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <div className='radioText'>
                            {ConvertViewUtil.convertIndention(data.decription)}
                        </div>

                    </Grid>
                </Grid>
            </>
        );

    }

    // ************************************************************************
    /**
     * 端末申込有無
     * @param data 
     * @returns 
     */
    private static getTerminalUmuContent(data: MvnoTerminalUmuSendData): any {
        const val: string = data.val;

        return (
            <>
                <Grid container textAlign={'center'}>
                    <Grid item xs={12}>
                        <div className="radioTitle2">{val}</div>
                    </Grid>
                </Grid>
            </>
        );

    }

    /**
     * 端末
     * @param data 
     * @returns 
     */
    private static getTerminalContent(data: MvnoTerminalSendData, disabled: boolean): any {
        const val: string = data.val;
        const price: string = ConvertUtil.convertToComma(data.price);
        // const disabledLabel: any = (disabled) ? 'nyukamachidisabled nyukamachi' : 'nyukamachi';
        return (
            <>
                <Grid container alignItems={'center'}>
                    <Grid item xs={12} >
                        <div style={{ background: 'url(\"data:image/png;base64,' + data.imgBase64 + '\") no-repeat' }} className='terminal'>
                            {disabled &&
                                <div className='nyukamachi'>
                                    <div className='nyukamachiText'>
                                        入荷待ち
                                    </div>
                                </div>
                            }
                        </div>
                        {/* <img src={'data:application/pdf;base64,' + data.imgBase64} style={{ width: '100%' }} /> */}
                    </Grid>
                    <Grid item xs={12} textAlign='center'>
                        <Typography component='div' className='radioTitle2'>{val}</Typography>
                        {
                            price != null && 
                            <Typography component='div' className='radioText'>月額利用料：{price}円／台（税込）</Typography>
                        }
                    </Grid>
                </Grid>
            </>
        );
    }


    /**
     * 端末：カラー
     * @param data 
     * @returns 
     */
    private static getTerminalColorContent(data: MvnoTerminalColorSendData, disabled: boolean): any {
        const val: string = data.val;
        // const disabledLabel: any = (disabled) ? 'nyukamachidisabled nyukamachi' : 'nyukamachi';
        return (
            <>
                <Grid container alignItems={'center'}>
                    <Grid item xs={12}>
                        <div style={{ background: 'url(\"data:image/png;base64,' + data.imgBase64 + '\") no-repeat' }} className='terminalColor'>
                            {disabled &&
                                <div className='nyukamachi'>
                                    <div className='nyukamachiText'>
                                        入荷待ち
                                    </div>
                                </div>
                            }

                        </div>
                    </Grid>
                    <Grid item xs={12} style={{textAlign: 'center'}}>
                        <Typography component='div' className='radioText'>{val}</Typography>
                    </Grid>
                </Grid>
            </>
        );
    }

    /**
     * SIMサイズ
     * @param data 
     * @returns 
     */
    private static getSimSizeContent(data: MvnoSimSizeSendData): any {
        const val: string = data.val;

        return (
            <>
                <Grid container textAlign='center'>
                    <Grid item xs={12}>
                        <img src={data.img} />
                    </Grid>
                    <Grid item xs={12}>
                        <div>
                            <Typography component='span' className='radioTitle3'>{val}</Typography>
                            <Typography component='div' className='radioText'>タテ：{data.tate}</Typography>
                            <Typography component='div' className='radioText'>ヨコ：{data.yoko}</Typography>
                        </div>
                    </Grid>
                </Grid>
            </>
        );

    }

    /**
     * 初期設定サポート
     * @param data 
     * @returns 
     */
    private static getInitialSupportContent(data: MvnoInitialSupportSendData): any {
        const val: string = data.val;

        return (
            <>
                <Grid container textAlign='center'>
                    <Grid item xs={12}>
                        <div className="radioTitle3">{data.val}</div>
                    </Grid>
                    <Grid item xs={12}>
                        <div>{ConvertViewUtil.convertIndention(data.decription)}</div>
                    </Grid>
                </Grid>
            </>
        );

    }

     /**
     * Webフィルタリング
     * @param data 
     * @returns 
     */
     private static getWebFilteringContent(data: MvnoWebFilteringSendData): any {
        const val: string = data.val;

        return (
            <>
                <Grid container textAlign='center'>
                    <Grid item xs={12}>
                        <div className="radioTitle3">{data.val}</div>
                    </Grid>
                    <Grid item xs={12}>
                        <div className="radioTitle3">{data.account}</div>
                    </Grid>
                    <Grid item xs={12}>
                        <div>{ConvertViewUtil.convertIndention(data.decription)}</div>
                    </Grid>
                </Grid>
            </>
        );

    }

    /**
     * SIMオプション
     * @param data 
     * @returns 
     */
    private static getSimOptioContent(data: MvnoSimOptionSendData): any {
        const val: string = data.val;

        return (
            <>
                <Grid container>
                    <Grid item md={3} style={{ textAlign: 'center' }}>
                        <img src={data.img} style={{width: '55%'}}/>
                    </Grid>
                    <Grid item md={9}>
                        <Grid container>
                            <Grid item xs={12}>
                                <div className='radioTitle2'>{val}</div>
                            </Grid>
                            <Grid item xs={12}>
                                <div>{data.decription}</div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </>
        );

    }
}