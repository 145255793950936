import { WprModel } from '../../../../wpr-framework/view/deco/WprModel';
import { AuthService } from '../../../service/AuthService';
import { GetLineInfoRequest } from '../../../service/models/dto/auth/GetLineInfoRequest';
import { MypageBaseView } from '../../base/MypageBaseView';
import { ProgressBarData } from '../../common/progress_bar/ProgressBarView';

/**
 * LINE同意承諾画面
 */
export class LineAgreeView extends MypageBaseView {
	
	// コンストラクタ  -----------------------------------------------------------
	public constructor() { super('LineAgreeView'); }
	// --------------------------------------------------------------------------

	// モデル  ------------------------------------------------------------------
	private m_loginToken: string = null;
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * コントロール定義設定
	 */
	public onRegisterConfig(): void {
	}

	/**
	 * アクション登録
	 */
	public onRegisterAction(): void {
		this.addAction('onLogin', this.onLogin);
		this.addAction('onRegist', this.onRegist);
	}

	/**
	 * ビュー表示通知
	 */
	public onShowView(): void {
		this.registAccessLog();
		this.setStorageItem('b_url', window.location.href)
		this.m_loginToken = this.getCookie('idToken');	;
		// オプションリスト(PC/SP判定)
		if (window.outerWidth > 440)
			this.setViewMode('タイトル','PC');
		else
			this.setViewMode('タイトル','SP');
	}
	// --------------------------------------------------------------------------

	// アクション  ---------------------------------------------------------------
	/**
	 * ログイン画面へ
	 */
	public onLogin(param: any): void {
		if (this.getUrlParam('tid'))
			location.href = this.getOldUrl() + "Top/Login/" + this.m_loginToken + '/' + '?tid=' + this.getUrlParam('tid') +'&stcd=' + this.getUrlParam('stcd') + '&ttcd=' + this.getUrlParam('ttcd');
		else
			location.href = this.getOldUrl() + "Top/Login/" + this.m_loginToken;
	}

	/**
	 * iTSCOM ID登録へ
	 */
	public onRegist(param: any): void {
		if (this.getUrlParam('tid'))
			location.href = this.getOldUrl() + "Firstreg/Continfo_Mail_Entry/" + this.m_loginToken + '/' + '?tid=' + this.getUrlParam('tid') +'&stcd=' + this.getUrlParam('stcd') + '&ttcd=' + this.getUrlParam('ttcd');
		else
			location.href = this.getOldUrl() + "Firstreg/Continfo_Mail_Entry/" + this.m_loginToken;
	}
	// --------------------------------------------------------------------------
}
