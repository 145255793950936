import { WprBaseViewMultiModeInfo } from '../../../../../wpr-framework/mode/WprBaseViewMultiMode';
import { WprMultiMode } from '../../../../../wpr-framework/mode/WprMultiMode';
import { AddPhoneSelectView } from '../AddPhoneSelectView';
import { WprDI_Object } from '../../../../../wpr-framework/view/di/WprDI_Object';
import { Customer } from '../../../../models/bss/customer/Customer';
import { WprDI_Control } from '../../../../../wpr-framework/view/di/WprDI_Control';
import { WprControlInfo } from '../../../../../wpr-framework/view/control/WprControlInfo';
import { PhoneInfoModel } from '../../../../view/Phone/add_phone_select/models/PhoneInfoModel';

/**
 * 入力確認モード
 */
export class InputConfirmMode extends WprBaseViewMultiModeInfo<AddPhoneSelectView> {
	// DI情報  ------------------------------------------------------------------
	@WprDI_Object
	private m_Customer: Customer	= null;	// 顧客情報
	@WprDI_Control
	private m_floor: WprControlInfo	= null;	// 階数
	@WprDI_Control
	private m_location: WprControlInfo	= null;	// 場所
	@WprDI_Control
	private m_comment: WprControlInfo	= null;	// フリー入力
	@WprDI_Control
	private m_desiredDate1: WprControlInfo	= null;	// 第一希望
	@WprDI_Control
	private m_selectTime1: WprControlInfo	= null;	// 第一希望　時間帯
	@WprDI_Control
	private m_desiredDate2: WprControlInfo	= null;	// 第二希望
	@WprDI_Control
	private m_selectTime2: WprControlInfo	= null;	// 第二希望　時間帯
	@WprDI_Control
	private m_desiredDate3: WprControlInfo	= null;	// 第三希望
	@WprDI_Control
	private m_selectTime3: WprControlInfo	= null;	// 第三希望　時間帯
	@WprDI_Control
	private m_tvPortFlg: WprControlInfo	= null;	// TV端子確認フラグ
	@WprDI_Control
	private m_prankCallFlg: WprControlInfo	= null;	// 迷惑電話同意フラグ
	@WprDI_Control
	private m_beforeContact: WprControlInfo	= null;	// 前日連絡先
	@WprDI_Control
	private m_sameDayContact: WprControlInfo	= null;	// 当日連絡先
	@WprDI_Control
	private m_name: WprControlInfo	= null;	// 当日立ち合い者
	@WprDI_Control
	private m_phoneServiceConfirm: WprControlInfo	= null;	// 電話サービス注意事項
	@WprDI_Control
	private m_noService: WprControlInfo	= null;	// 利用なし
	@WprDI_Control
	private m_homeSecurity: WprControlInfo	= null;	// ホームセキュリティ
	@WprDI_Control
	private m_emergencyCall: WprControlInfo	= null;	// 緊急通報システム、福祉電話、ホームエレベータの利用
	@WprDI_Control
	private m_intercom: WprControlInfo	= null;	// インターフォン利用
	@WprDI_Control
	private m_homeBusinessPhone: WprControlInfo	= null;	// ホームテレフォン・ビジネスフォン利用
	@WprDI_Control
	private m_gasWater: WprControlInfo	= null;	// ガス・水道等の検針サービス
	@WprDI_Control
	private m_phoneRental: WprControlInfo	= null;	// 電話機を回線業者よりレンタルしている
	@WprDI_Control
	private m_otherService: WprControlInfo	= null;	// その他
	@WprDI_Control
	private m_securityFlg: WprControlInfo	= null;	// セキュリティフラグ
	@WprDI_Control
	private m_securityComment: WprControlInfo	= null;	// セキュリティフリー入力
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	public constructor() {
		super('InputConfirmMode');
	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * モードリスト取得（この画面モードで使用する全てのモードを定義する）
	 * @param list モードリスト
	 */
	public useModeList(list: string[]): void {
		list.push('入力済み');
		list.push('未入力');
		list.push('入力必須');
	}

	/**
	 * フィールド定義を登録処理
	 */
	public onRegisterControl(): void {
		this.addControl('next', { enabled: [ { mode: '入力済み', value: true }, { mode: '未入力', value: false } ] });
		this.addControl('floor', { hissu: [ { mode: '入力必須', value: true }, { mode: 'other', value: false } ] });
	}

	/**
	 * 画面の状態をチェックしてモードを変更する
	 * @param curMode 現在のモード
	 * @param newMode 新しいモード
	 */
	public onCheckMultiMode(curMode: WprMultiMode, newMode: WprMultiMode): void {
		let flg = true;
		// 工事入力
		if (!this.m_location.value)
			flg =false;
		else if ((this.m_Customer && this.m_Customer.building[0].buildingTypeCD === '3') && !this.m_floor.value)
			flg =false;
		else if (this.m_location.value === 'その他' && !this.m_comment.value)
			flg =false;
		else if (this.m_securityFlg.value && !this.m_securityComment.value)
			flg = false;
		else if (!this.m_desiredDate1.value || !this.m_selectTime1.value || !this.m_desiredDate2.value ||
			!this.m_selectTime2.value || !this.m_desiredDate3.value || !this.m_selectTime3.value || !this.m_tvPortFlg.value ||
			!this.m_beforeContact.value || !this.m_sameDayContact.value || !this.m_name.value || !this.m_phoneServiceConfirm.value) {
				flg =false;
		}
		// 各種サービスが未選択の場合
		else if (!this.m_noService.value && !this.m_homeSecurity.value && !this.m_emergencyCall.value && !this.m_intercom.value
			 && !this.m_homeBusinessPhone.value && !this.m_gasWater.value && !this.m_phoneRental.value && !this.m_otherService.value) {
				flg =false;
		}
		// 迷惑電話同意書
		if (this.getViewMode('迷惑電話') === '表示' && !this.m_prankCallFlg.value)
			flg =false;
		if (!flg)
			newMode.addMode('未入力');
		else 
			newMode.addMode('入力済み');
		// 階数必須
		if (this.m_Customer && this.m_Customer.building[0].buildingTypeCD === '3')
			newMode.addMode('入力必須');
	}
	// --------------------------------------------------------------------------
}
