import { WprBaseDIManage } from '../view/di/WprBaseDIManage';
import { WprBaseObject } from '../WprBaseObject';

/**
 * デコレータ情報リスト
 */
export class WprDecoInfoList {
	// private 変数  ------------------------------------------------------------
	private m_PropertyNameList: Map<object, string[]>	= new Map<object, string[]>();	// プロパティ名称マップ
	// --------------------------------------------------------------------------

	// public メソッド	----------------------------------------------------------
	/**
	 * デコレータプロパティ情報追加
	 * @param obj 対象オブジェクト
	 * @param props プロパティ名
	 */
	public addDecoProperty(obj: WprBaseObject, props: string) {
		const cobj = obj.constructor;
		if (this.m_PropertyNameList.has(cobj) === false)
			this.m_PropertyNameList.set(cobj, new Array());
		this.m_PropertyNameList.get(cobj).push(props);
	}

	/**
	 * プロパティリスト取得
	 * @param obj 対象オブジェクト
	 * @param diMap IDObjectマップ
	 * @returns プロパティリスト
	 */
	public getPropertyList(obj: WprBaseDIManage, diMap: Map<string, WprBaseObject>): string[] {
		const cobj = obj.constructor;
		const plist = new Array();
		const nameList = obj.getBaseNameList();
		nameList.forEach(baseName => {
			if (diMap.has(baseName) === true) {
				const cobj = diMap.get(baseName).constructor;
				this.setPropertyList(plist, cobj);
			}
		});
		this.setPropertyList(plist, cobj);
		return plist;
	}

	/**
	 * プロパティリスト設定
	 * @param plist 
	 * @param cobj 
	 */
	private setPropertyList(plist: string[], cobj: object): void {
		if (this.m_PropertyNameList.has(cobj) === true) {
			const list = this.m_PropertyNameList.get(cobj);
			list.forEach(name => {
				plist.push(name);
			});
		}
	}
	// --------------------------------------------------------------------------
}