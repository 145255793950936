import { WprBaseObject } from '../WprBaseObject';
import { WprBaseViewCore } from '../view/WprBaseViewCore';

/**
 * モデルアクション情報
 */
export class WprModelActionInfo extends WprBaseObject {
	// private 変数  ------------------------------------------------------------
	private m_Name: string																= null;		// モデル名称
	private m_PropertyName: string														= null;		// プロパティ名称
	private m_ActionMethod: (modelName: string, model: any, propName: string) => void	= null;		// コールバックメソッド
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	public constructor(name: string, actionMethod: (modelName: string, model: any, propName: string) => void, propName: string) {
		super();
		this.m_Name = name;
		this.m_PropertyName = propName;
		this.m_ActionMethod = actionMethod;
	}
	// --------------------------------------------------------------------------

	// public メソッド  ----------------------------------------------------------
	/**
	 * アクション実行処理
	 * @param viewCore アクション実行View情報
	 * @param model モデル
	 */
	public execute(viewCore: WprBaseViewCore, model: any): void {
		// 非同期処理は、これで良いのか？　開放が良くわからないのでPromiseを仕様する
		// this.m_ActionMethod.bind(viewCore)(this.m_Name, model);
		const promise = new Promise((resolve, reject) => {
			this.addInfoLog(`モデルアクション実行 開始[${viewCore.objectName}]`);
			this.m_ActionMethod.bind(viewCore)(this.m_Name, model, this.m_PropertyName);
			this.addInfoLog(`モデルアクション実行 終了[${viewCore.objectName}]`);
			resolve(true);
		});
	}
	// --------------------------------------------------------------------------
}
