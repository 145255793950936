import { WprValueMap } from '../../../../wpr-framework/model/WprValueMap';
import { WprValueMapSetter } from '../../../../wpr-framework/model/WprValueMapSetter';

/**
 * とことんサポート申込み
 */
export class TokotonSupportApplicationValueMap {
	// static メソッド  --------------------------------------------------------
	public static addValueMap(vmSetter: WprValueMapSetter): void {
		let vm = new WprValueMap();
		vmSetter.addValueMap('とこサポ申込み種別', vm);
	}
	// --------------------------------------------------------------------------
}
