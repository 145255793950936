import { WprModel } from '../../../../wpr-framework/view/deco/WprModel';
import { WprDI_ScopeModel } from '../../../../wpr-framework/view/di/WprDI_ScopeModel';
import { DateUtil } from '../../../common/util/DateUtil';
import { ObjectUtil } from '../../../common/util/ObjectUtil';
import { NameChangeAppModel } from '../../../service/models/apimodels/NameChangeAppModel';
import { MypageBaseView } from '../../base/MypageBaseView';
import { ProgressBarData } from '../../common/progress_bar/ProgressBarView';
import { MypageWizardMngr } from '../../wizard/models/MypageWizardMngr';
import { WprViewMode } from '../../../../wpr-framework/view/deco/WprViewMode';
import { WprValidError } from '../../../../wpr-framework/view/validation/WprValidError';
import { WprDI_Control } from '../../../../wpr-framework/view/di/WprDI_Control';
import { WprControlInfo } from '../../../../wpr-framework/view/control/WprControlInfo';
import { WprListData } from '../../../../wpr-framework/view/deco/WprListData';
import { WprMailAddressValidator } from '../../../../wpr-framework/view/validation/validator/WprMailAddressValidator';
import { WprNumberValidator } from '../../../../wpr-framework/view/validation/validator/WprNumberValidator';
import { WprZKanaValidator } from '../../../../wpr-framework/view/validation/validator/WprZKanaValidator';
import { WprZenkakuValidator } from '../../../../wpr-framework/view/validation/validator/WprZenkakuValidator';
import { NameChangeService } from '../../../service/NameChangeService';
import { GetNameChangeRequest } from '../../../service/models/dto/mypagerenewal/GetNameChangeRequest';
import { DateConverter_JPConverter } from '../../framework/Converter/DateConverter_JPConverter';
import { ErrorModel } from '../../../models/mypage/smart/ErrorModel';
import { Customer } from '../../../models/bss/customer/Customer';
/**
 * 名義変更受付フォーム
 */
export class NameChangeFormView extends MypageBaseView {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() { super('NameChangeFormView'); }
	// --------------------------------------------------------------------------

	// DI情報  ------------------------------------------------------------------
	@WprDI_Control
	private m_FamilyName: WprControlInfo	= null;	// 新名義人 姓
	@WprDI_Control
	private m_FirstName: WprControlInfo	= null;	// 新名義人 名
	@WprDI_Control
	private m_FamilyNameKana: WprControlInfo	= null;	// 新名義人 セイ
	@WprDI_Control
	private m_FirstNameKana: WprControlInfo	= null;	// 新名義人 メイ
	@WprDI_Control
	private m_RelationShip: WprControlInfo	= null;	// 続柄
	@WprDI_Control
	private m_Gender: WprControlInfo	= null;	// 性別
	@WprDI_Control
	private m_BirthdayYear: WprControlInfo	= null;	// 生年月日_年
	@WprDI_Control
	private m_BirthdayMonth: WprControlInfo	= null;	// 生年月日_月
	@WprDI_Control
	private m_BirthdayDay: WprControlInfo	= null;	// 生年月日_日
	@WprDI_Control
	private m_PhoneNumber1: WprControlInfo	= null;	// 電話番号1
	@WprDI_Control
	private m_PhoneNumber2: WprControlInfo	= null;	// 電話番号2
	@WprDI_Control
	private m_NewMail: WprControlInfo	= null;	// メールアドレス
	@WprDI_Control
	private m_ChangePaymentFlg: WprControlInfo	= null;	// 支払方法変更フラグ
	@WprDI_Control
	private m_CreditFlg: WprControlInfo	= null;	// 支払方法クレカフラグ
	@WprDI_Control
	private m_ContractAddressFlg: WprControlInfo	= null;	// 契約住所に送付フラグ
	@WprDI_Control
	private m_PaymentAddress: WprControlInfo	= null;	// 口座送付先住所
	@WprDI_Control
	private m_PaymentBuilding: WprControlInfo	= null;	// 口座送付先建物名
	@WprDI_Control
	private m_PaymentRoomNum: WprControlInfo	= null;	// 口座送付先部屋番号
	// --------------------------------------------------------------------------
	
	// モデル  ------------------------------------------------------------------
	@WprDI_ScopeModel('MypageWizardMngr')
	private m_MypageWizardMngr: MypageWizardMngr = null;	// 画面ウィザード管理クラス
	@WprModel('NameChangeAppModel')
	private m_EntryInfo: NameChangeAppModel = null;			// 名義変更申込み情報モデル
	@WprListData('ErrorModel')
	private m_ErrorModelList: ErrorModel[]	= null;			// エラーモデル
	@WprModel('titleComment')
	private m_TitleComment: string = null;					// タイトルコメント
	private m_Customer: Customer = null;		// 顧客情報
	private m_NameChangeService: NameChangeService	= new NameChangeService();	// 名義変更サービス

	private m_ZenkakuValidator: WprZenkakuValidator = new WprZenkakuValidator();	//全角バリデーター
	private m_NewMailValidator: WprMailAddressValidator = new WprMailAddressValidator();	//メールアドレスバリデーター
	private m_NumberValidator: WprNumberValidator = new WprNumberValidator();	//整数バリデーター
	private m_ZKanaValidator: WprZKanaValidator = new WprZKanaValidator();	//全角カナバリデーター
	private m_DateConverter_JPConverter: DateConverter_JPConverter = new DateConverter_JPConverter();	//日付コンバーター
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * コントロール定義設定
	 */
	public onRegisterConfig(): void {
		// 共通------------------------------------
		this.addConfig('nameChangeKbn', '名義変更区分', 'NameChangeAppModel.nameChangeInfo', { converter: '名義変更区分' });
		this.addListConfig('errorMessage', 'エラーメッセージ', 'ErrorModel');
		this.addConfig('titleComment', 'タイトルコメント', 'titleComment', { bindModel: true });
		// 旧名義人パターン-------------------------
		this.addConfig('familyName', '新名義人 姓', 'NameChangeAppModel');
		this.addConfig('firstName', '新名義人 名', 'NameChangeAppModel');
		this.addConfig('familyNameKana', '新名義人 セイ', 'NameChangeAppModel');
		this.addConfig('firstNameKana', '新名義人 メイ', 'NameChangeAppModel');
		this.addConfig('relationShip', '続柄', 'NameChangeAppModel.nameChangeInfo');
		this.addConfig('gender', '性別', 'NameChangeAppModel.nameChangeInfo');
		this.addConfig('birthdayYear', '生年月日_年', 'NameChangeAppModel');
		this.addConfig('birthdayMonth', '生年月日_月', 'NameChangeAppModel');
		this.addConfig('birthdayDay', '生年月日_日', 'NameChangeAppModel');
		this.addConfig('phoneNumber1', '電話番号1', 'NameChangeAppModel.nameChangeInfo', { maxLength: 11 });
		this.addConfig('phoneNumber2', '電話番号2', 'NameChangeAppModel.nameChangeInfo', { maxLength: 11 });
		this.addConfig('newMail', 'メールアドレス', 'NameChangeAppModel.nameChangeInfo');
	}

	/**
	 * ビュー表示通知
	 */
	 public onShowView(): void {
		this.m_MypageWizardMngr.initView(this, (result) => {
			this.registAccessLog();
			this.setStorageItem('b_url', window.location.href)
			const sendData: ProgressBarData = new ProgressBarData();
			sendData.progressBarList = this.m_MypageWizardMngr.getProgressModelList(this);
			this.sendChildData('fP_ev_progressBarIntital', sendData);
			this.m_EntryInfo = this.m_MypageWizardMngr.getCache().nameChangeAppInfo;
			this.m_Customer = this.m_MypageWizardMngr.getViewInfo().customer;
			this.m_EntryInfo.nameChangeInfo.phoneNumber1 = this.m_Customer.contact[0].phoneNumber;
			this.m_EntryInfo.nameChangeInfo.phoneNumber2 = this.m_Customer.contact[1].phoneNumber;
			this.m_EntryInfo.nameChangeInfo.newMail = this.m_Customer.contact[2].mailAddress;
			this.m_TitleComment = '以下のフォームへご入力ください。'
			if(this.m_EntryInfo.birthdayYear === null) {
				this.m_EntryInfo.birthdayYear = '1990';
			}
			this.setViewMode('名義変更区分', this.m_EntryInfo.nameChangeInfo.nameChangeKbn);
			
			this.refreshView();
		});
		
		this.m_ErrorModelList = new Array();
		const model = new ErrorModel();
		model.errorMessage = 'エラーリスト';
		this.m_ErrorModelList.push(model);
		this.setListData('ErrorModel', this.m_ErrorModelList);
		this.refreshView();
	}
	/**
	 * 入力チェック（独自のValidationを実施する場合に使用する）
	 *	エラー追加例：validError.addError('エラーメッセージ');				// 相関チェックなどのエラー
	 * 				 this.setControlError('control', 'error');			 // コントロールのエラー
	 * 				 validError.showErrorMessage.('エラーメッセージ');	   // エラーメッセージ表示
	 * 		※上記のエラーを追加した場合は、アクションは実行しません。
	 * 		※コントロールの値をチェックする場合は、refreshModelは使用しないでください。
	 * 		　コントロールの値をチェックする場合は、コントロールをDIしてvalueを使用してください。
	 * 		　コントロールが双方向のコンバータを使用している場合は、dataValueを使用してください。
	 * @param actionName アクション名
	 * @param validError バリデーションエラー情報
	 * @param row 行情報
	 */
	protected onValid(actionName: string, validError: WprValidError, row?: any): void {
		if (actionName === 'next') {
			this.m_ErrorModelList = new Array();
			if (this.m_FamilyName.value && this.m_FirstName.value) {
				if(!this.m_ZenkakuValidator.checkValidValue(this.m_FamilyName.value) || !this.m_ZenkakuValidator.checkValidValue(this.m_FirstName.value)) {
					const model = new ErrorModel();
					model.errorMessage = '新契約名義人（漢字）は全角で入力してください';
					this.m_ErrorModelList.push(model);
				}
			} else {
				const model = new ErrorModel();
				model.errorMessage = '新契約名義人（漢字）を入力してください';
				this.m_ErrorModelList.push(model);
			}
			if (this.m_FamilyNameKana.value && this.m_FirstNameKana.value) {
				if (!this.m_ZKanaValidator.checkValidValue(this.m_FamilyNameKana.value) || !this.m_ZKanaValidator.checkValidValue(this.m_FirstNameKana.value)) {
					const model = new ErrorModel();
					model.errorMessage = '新契約名義人（フリガナ）は全角カタカナで入力してください';
					this.m_ErrorModelList.push(model);
				}
			} else {
				const model = new ErrorModel();
				model.errorMessage = '新契約名義人（フリガナ）を入力してください';
				this.m_ErrorModelList.push(model);
			}
			if (this.m_EntryInfo.nameChangeInfo.nameChangeKbn !== '1') {
				// 名義変更区分が逝去または同居の場合
				if (!this.m_RelationShip.value) {
					const model = new ErrorModel();
					model.errorMessage = '続柄を選択してください';
					this.m_ErrorModelList.push(model);
				}
				if (!this.m_Gender.value) {
					const model = new ErrorModel();
					model.errorMessage = '性別を選択してください';
					this.m_ErrorModelList.push(model);
				}
				if (this.m_BirthdayYear.value && this.m_BirthdayMonth.value && this.m_BirthdayDay.value) {
					if (this.isUnder18()) {
						const model = new ErrorModel();
						model.errorMessage = '18歳未満の場合、申込できません。';
						this.m_ErrorModelList.push(model);
						validError.addError('未成年の場合はこちら', 'under18Error');
					}
				} else {
					const model = new ErrorModel();
					model.errorMessage = '生年月日を入力してください';
					this.m_ErrorModelList.push(model);
				}
			}
			if (this.m_PhoneNumber1.value) {
				if (!this.m_NumberValidator.checkValidValue(this.m_PhoneNumber1.value)) {
					const model = new ErrorModel();
					model.errorMessage = '電話番号1は半角数字で入力してください';
					this.m_ErrorModelList.push(model);
				}
			} else {
				const model = new ErrorModel();
				model.errorMessage = '電話番号1を入力してください';
				this.m_ErrorModelList.push(model);
			}
			if (this.m_PhoneNumber2.value) {
				if (!this.m_NumberValidator.checkValidValue(this.m_PhoneNumber2.value)) {
					const model = new ErrorModel();
					model.errorMessage = '電話番号2は半角数字で入力してください';
					this.m_ErrorModelList.push(model);
				}
			} else {
				const model = new ErrorModel();
				model.errorMessage = '電話番号2を入力してください';
				this.m_ErrorModelList.push(model);
			}
			if (this.m_NewMail.value) {
				if (!this.m_NewMailValidator.checkValidValue(this.m_NewMail.value)) {
					const model = new ErrorModel();
					model.errorMessage = 'メールアドレスを正しく入力してください';
					this.m_ErrorModelList.push(model);
				}
			} else {
				const model = new ErrorModel();
				model.errorMessage = 'メールアドレスを入力してください';
				this.m_ErrorModelList.push(model);
			}
			if (this.m_ErrorModelList.length !== 0) {
				this.setViewMode('オプション','エラー')
				this.setListData('ErrorModel', this.m_ErrorModelList);
				validError.addError('エラー');		
			}
			this.refreshListView('ErrorModel');
		}
	}

	/**
	 * アクション登録
	 */
	public onRegisterAction(): void {
		this.addAction('next', this.onNext, true);
		this.addAction('back', this.onBack);
	}
	// --------------------------------------------------------------------------
	
	// アクション  ---------------------------------------------------------------
	/**
	 * 未成年かどうかの判定
	 * @returns 
	 */
	 private isUnder18(): boolean {
		const year: string = this.m_BirthdayYear.value;
		const month: string = ('00' + this.m_BirthdayMonth.value).slice(-2);
		const day: string = ('00' + this.m_BirthdayDay.value).slice(-2);
		const birthday = year + month + day;
		const age: number = DateUtil.getAgeFromStr(birthday);
		return (age < 18);
	}

	/**
	 * 入力情報リセット
	 */
	 private resetInfo(): void {
		// this.m_EntryInfo.nameChangeInfo.nameChangeKbn = null;
		this.m_EntryInfo.familyName = null;
		this.m_EntryInfo.firstName = null;
		this.m_EntryInfo.familyNameKana = null;
		this.m_EntryInfo.firstNameKana = null;
		this.m_EntryInfo.nameChangeInfo.relationShip = null;
		this.m_EntryInfo.nameChangeInfo.gender = null;
		this.m_EntryInfo.birthdayYear = null;
		this.m_EntryInfo.birthdayMonth = null;
		this.m_EntryInfo.birthdayDay = null;
		this.m_EntryInfo.nameChangeInfo.phoneNumber1 = this.m_Customer.contact[0].phoneNumber;
		this.m_EntryInfo.nameChangeInfo.phoneNumber2 = this.m_Customer.contact[1].phoneNumber;
		this.m_EntryInfo.nameChangeInfo.newMail = null;
	}

	/**
	 * 次へ
	 */
	public onNext(param: any): void {
		this.refreshModel();
		this.m_MypageWizardMngr.goNext(this);
	}

	/**
	 * 戻る
	 */
	public onBack(param: any): void {
		this.resetInfo();
		this.m_MypageWizardMngr.goBack(this);
	}

	// --------------------------------------------------------------------------
}
