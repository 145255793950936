/**
 * 工事費取得リクエスト
 */
export class GetConstructionCostRequest {
	// public 変数	-----------------------------------------------------------
	public housingKbnCd: string;			// 住居形態コード
	public constructionCategoryCd: string;	// 工事カテゴリーコード
	public applicationDetailsCd: string;	// 申込内容コード
	public temporaryCostCategoryCd: string;	// 一時金カテゴリーコード
	public tokotonSuportFlg: boolean;		// とことんサポート加入フラグ
	public multipleFlg: boolean;			// 二代目以降フラグ
	public freeFlg: boolean;				// 無料フラグ
	// ------------------------------------------------------------------------
}
