// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@charset \"UTF-8\";\n/*\r\n * 申込み内容の確認用CSS\r\n */\n.AddMeshWifiConfirm-message .supportMsgArea {\n  margin-bottom: 36px; }\n  @media screen and (max-width: 450px) {\n    .AddMeshWifiConfirm-message .supportMsgArea {\n      padding: 1px 12px 1px 18px; } }\n\n.AddMeshWifiConfirm-mainPage .main {\n  padding-bottom: 60px; }\n\n.AddMeshWifiConfirm-messageText {\n  list-style: none;\n  padding-left: 1rem;\n  padding-right: 7px; }\n  @media screen and (max-width: 450px) {\n    .AddMeshWifiConfirm-messageText {\n      padding-left: 0px;\n      padding-right: 0px; } }\n\n@media screen and (max-width: 450px) {\n  .AddMeshWifiConfirm-tableBorder .feeTitle {\n    border-bottom: 1px solid #E1E4ED; } }\n\n.AddMeshWifiConfirm-value {\n  display: flex;\n  align-items: baseline; }\n  .AddMeshWifiConfirm-value .AddMeshWifiConfirm-itemSelect .MuiSelect-root {\n    width: auto !important;\n    height: auto !important;\n    background-color: #FFFFFF !important;\n    color: #333333 !important;\n    font-weight: 500 !important;\n    font-size: 14px;\n    padding: 0; }\n  .AddMeshWifiConfirm-value .AddMeshWifiConfirm-itemSelect svg {\n    display: none; }\n  .AddMeshWifiConfirm-value .AddMeshWifiConfirm-itemSelect fieldset {\n    border: 0px; }\n", ""]);
// Exports
exports.locals = {
	"message": "AddMeshWifiConfirm-message",
	"mainPage": "AddMeshWifiConfirm-mainPage",
	"messageText": "AddMeshWifiConfirm-messageText",
	"tableBorder": "AddMeshWifiConfirm-tableBorder",
	"value": "AddMeshWifiConfirm-value",
	"itemSelect": "AddMeshWifiConfirm-itemSelect"
};
module.exports = exports;
