/**
 * オブジェクトユーティリティ
 * 
 */
export class ObjectUtil {

	// *********************** 判定 ***********************
	// 配列/リスト -----------------------------------------------
	/**
	 * 配列の空判定
	 * @param list
	 * @returns boolean
	 */
	public static isNullOrEmptyArray(list: Array<any>): boolean {
		if (list == null)
			return true;
		else if (Array.isArray(list) !== true)
			return true;
		else if (list.length === 0)
			return true;
		return false;
	}

	/**
	 * 配列の空判定
	 * @param list
	 * @returns boolean
	 */
	public static isNotNullOrEmptyArray(list: Array<any>): boolean {
		return !this.isNullOrEmptyArray(list);
	}

	// オブジェクト ---------------------------------------------
	/**
	 * オブジェクトの空判定
	 * @param value
	 * @returns boolean true: 空
	 */
	public static isNullOrUndefined(value: any): boolean {
		if (value == null || value === undefined)
			return true;
		return false;
	}

	/**
	 * オブジェクトの空判定
	 * @param value
	 * @returns boolean true: 値あり
	 */
	public static isNotNullOrUndefined(value: any): boolean {
		return !this.isNullOrUndefined(value);
	}

	// 文字列string ----------------------------------------------------
	/**
	 * 文字列の空判定
	 * @param str
	 * @returns boolean true: 空
	 */
	public static isNullOrEmptyStr(str: string): boolean {
		if (str == null || str === '')
			return true;
		return false;
	}

	/**
	 * 文字列の空判定
	 * @param str 
	 * @returns boolean true: 値あり
	 */
	public static isNotNullOrEmptyStr(str: string): boolean {
		return !this.isNullOrEmptyStr(str);
	}

	/**
	 * string型の空判定付きイコールズ
	 * @param num
	 * @returns boolean true: 等しい
	 */
	 public static equalsStr(str1: string, str2: string): boolean {
		if (this.isNullOrEmptyStr(str1) || this.isNullOrUndefined(str1))
			return false;
		if (this.isNullOrEmptyStr(str2) || this.isNullOrUndefined(str2))
			return false;
		if (str1 === str2)
			return true
		return false;
	}

	// 数値number ---------------------------------------------------
	/**
	 * 数値の空判定
	 * @param num
	 * @returns boolean true: 空
	 */
	public static isNullOrEmptyNum(num: number): boolean {
		if (num == null || num === 0 || num === undefined || Number.isNaN(num))
			return true;
		return false;
	}

	/**
	 * 数値の空判定
	 * @param num 
	 * @returns boolean true: 値あり
	 */
	public static isNotNullOrEmptyNum(num: number): boolean {
		return !this.isNullOrEmptyNum(num);
	}

	
	/**
	 * 数値の空判定付きイコールズ
	 * @param num
	 * @returns boolean true: 等しい
	 */
	 public static equalsAndNotNullOrEmptyNum(num1: number, num2: number): boolean {
		if (this.isNullOrEmptyNum(num1))
			return false;
		if (this.isNullOrEmptyNum(num2))
			return false;
		if (num1 === num2)
			return true
		return false;
	}

	// フラグBoolean ----------------------------------------------------
	/**
	 * フラグの空判定
	 * @param bool
	 * @returns boolean true: 空
	 */
	public static isNullOrEmptyBool(bool: Boolean): boolean {
		if (bool == null)
			return true;
		return false;
	}

	/**
	 * フラグの空判定
	 * @param bool 
	 * @returns boolean true: 値あり
	 */
	public static isNotNullBool(bool: Boolean): boolean {
		return !this.isNullOrEmptyBool(bool);
	}

	public static stringToBoolean(value: string | null | undefined): boolean {
		// null、undefined は false
		if (this.isNullOrUndefined(value)) return false;
		
		// 小文字にして判定
		return value.toLowerCase() === 'true' || value === '1';
	}

	// *********************** 操作 ***********************
	// クローン生成 --------------------------------------
	/**
	 * オブジェクトのクローンを生成
	 * @param from コピー元オブジェクト
	 * @returns クローンオブジェクト
	 */
	public static clone(from: any): any {
		if(this.isNullOrUndefined(from))
			return null;
		return JSON.parse(JSON.stringify(from));
	}
}