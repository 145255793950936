/**
 * コントロール状態情報
 */
export class WprControlStatus {
	// private 変数  ------------------------------------------------------------
	private m_Hissu: boolean		= false;		// 必須入力
	private m_Display: boolean		= true;			// 表示状態
	private m_Visibility: boolean	= true;			// 表示状態
	private m_Enable: boolean		= true;			// 活性状態
	private m_ReadOnly: boolean		= false;		// 読取り専用
	private m_Min: number | string	= undefined;	// 最大値
	private m_Max: number | string	= undefined;	// 最小値
	private m_MinLength: number		= undefined;	// 最小長
	private m_MaxLength: number		= undefined;	// 最大長
	private m_ModeClass: string		= undefined;	// モードクラス
	private m_Placeholder: string 	= undefined;	// placeholder
	private m_Style: object			= null;			// スタイル
	private m_InputError: boolean	= false;		// 入力エラーフラグ(エラーの場合true)
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** 必須入力 */
	public get hissu(): boolean 		{ return this.m_Hissu; 			}	public set hissu(hissu: boolean) 				{ this.m_Hissu = hissu;				}
	/** 表示状態 */
	public get display(): boolean 		{ return this.m_Display; 		}	public set display(display: boolean) 			{ this.m_Display = display;			}
	/** 表示状態 */
	public get visibility(): boolean 	{ return this.m_Visibility; 	}	public set visibility(visibility: boolean) 		{ this.m_Visibility = visibility;	}
	/** 活性状態 */
	public get disabled(): boolean 		{ return !this.m_Enable; 		}	public set disabled(disable: boolean) 			{ this.m_Enable = !disable;			}
	/** 活性状態 */
	public get readOnly(): boolean 		{ return this.m_ReadOnly; 		}	public set readOnly(readOnly: boolean) 			{ this.m_ReadOnly = readOnly;		}
	/** 最小値 */
	public get min(): number | string 	{ return this.m_Min; 			}	public set min(min: number | string) 			{ this.m_Min = min;					}
	/** 最大値 */
	public get max(): number | string 	{ return this.m_Max; 			}	public set max(max: number | string) 			{ this.m_Max = max;					}
	/** 最小長 */
	public get minLength(): number 		{ return this.m_MinLength; 		}	public set minLength(minLength: number) 		{ this.m_MinLength = minLength;		}
	/** 最大長 */
	public get maxLength(): number 		{ return this.m_MaxLength; 		}	public set maxLength(maxLength: number) 		{ this.m_MaxLength = maxLength;		}
	/** モードクラス */
	public get modeClass(): string 		{ return this.m_ModeClass; 		}	public set modeClass(cls: string)				{ this.m_ModeClass = cls; 			}
	/** placeholder */
	public get placeholder(): string 	{ return this.m_Placeholder;	} 	public set placeholder(placeholder: string) 	{ this.m_Placeholder = placeholder;	}
	/** スタイル */
	public get style(): object 			{ return this.m_Style; 			} 	public set style(style: object) 				{ this.m_Style = style;				}
	/** 入力エラーフラグ(エラーの場合true) */
	public get inputError(): boolean 	{ return this.m_InputError; 	}	public set inputError(inputError: boolean) 		{ this.m_InputError = inputError;	}
	// --------------------------------------------------------------------------

	// public メソッド  ---------------------------------------------------------
	/**
	 * クローン取得
	 * @returns クローン
	 */
	public clone(): WprControlStatus {
		const clone = new WprControlStatus();
		clone.hissu 		= this.hissu;
		clone.display　		= this.display;
		clone.visibility　	= this.visibility;
		clone.disabled　	= this.disabled;
		clone.readOnly　	= this.readOnly;
		clone.min　			= this.min;
		clone.max　			= this.max;
		clone.minLength　	= this.minLength;
		clone.maxLength　	= this.maxLength;
		clone.inputError　	= this.inputError;
		return clone;
	}
	// --------------------------------------------------------------------------
}
