import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Paper, Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import React, { ReactNode } from 'react';
import Draggable from 'react-draggable';
import { WprMessageComponent } from '../../component/message/WprMessageComponent';
import { WprSnackbarComponent } from '../../component/message/WprSnackbarComponent';
import { IWprControlEvents } from '../../component/props/IWprControlEvents';
import { IWprDialog } from '../dialog/IWprDialog';
import { WprBaseDialogViewInfo } from '../dialog/WprBaseDialogViewInfo';
import { IWprDialogViewProps } from '../props/IWprDialogViewProps';
import { WprBaseViewCoreComponent } from './WprBaseViewCoreComponent';

/**
 * ダイアログイベント情報
 */
export interface IWprDialogEvents {
	onClose?: () => void;	// クローズ
}

/**
 * ダイアログビューコンポーネント基本クラス
 */
export abstract class WprBaseDialogViewComponentCore<T extends IWprDialogViewProps> extends WprBaseViewCoreComponent<T> implements IWprDialog {
	// protected 変数  ----------------------------------------------------------
	protected m_DialogViewInfo: WprBaseDialogViewInfo 				= null;		// ダイアログView情報
	protected m_OKEvents: IWprControlEvents<HTMLButtonElement> 		= {};		// OKボタンイベント
	protected m_CancelEvents: IWprControlEvents<HTMLButtonElement> 	= {};		// キャンセルボタンイベント
	protected m_CloseBtnEvents: IWprControlEvents<HTMLButtonElement> 	= {};		// クローズボタンイベント
	protected m_CloseEvents: IWprDialogEvents							= {};		// クローズイベント
	protected m_CloseButtonStyle: React.CSSProperties					= null;		// クローズボタンスタイル
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * マウント通知
	 */
	public componentDidMount() {
		this.m_DialogViewInfo = this.view as WprBaseDialogViewInfo;
		this.props.view.setDialog(this, this.props.name, this.m_DialogViewInfo);
		super.componentDidMount();
		this.m_OKEvents.onClick = this.onOK.bind(this);
		this.m_CancelEvents.onClick = this.onCancel.bind(this);
		this.m_CloseBtnEvents.onClick = this.onTitleClose.bind(this);
		this.m_CloseEvents.onClose = this.onClose.bind(this);
	}

	/**
	 * アンマウント通知
	 */
	 public componentWillUnmount() {
		super.componentDidMount();
		this.props.view.deleteDialog(this.props.name);
	}

	/**
	 * 描画
	 */
	public render(): ReactNode {
		this.initStyles();
		const {view, ...other} = this.props;
		return (
			<>
				<Dialog open={this.state.open} {...this.m_CloseEvents} PaperComponent={PaperComponent} scroll='paper' {...other}>
					{this.getDialogTitle()}
					{this.getDialogContent()}
					{this.getDialogAction()}
				</Dialog>
			</>
		);
	}

	/**
	 * 要素固有XPath情報取得
	 * @param element 要素
	 * @returns 要素固有XPath情報
	 */
	protected getElementXPathInfo(element: HTMLElement): string {
		const button = this.getParentElement(element, 'BUTTON', 'aria-label', 'close');
		if (button == null)
			return null;
		return `//${button.tagName}`;
	}
	// --------------------------------------------------------------------------

	// イベント  -----------------------------------------------------------------
	/**
	 * OK処理
	 * @param event イベント 
	 */
	public onOK(event: React.MouseEvent<HTMLButtonElement>): void {
		this.addClickEventLog(event.target as HTMLButtonElement);
		if (this.m_DialogViewInfo.FW.edit.checkEdit('dialog_ok', this.m_DialogViewInfo.objectName, () => {
				this.m_DialogViewInfo.onDialogEvent(true);
			}) === false) {
			return; 
		}
		this.m_DialogViewInfo.onDialogEvent(true);
	}

	/**
	 * キャンセル処理
	 * @param event イベント 
	 */
	public onCancel(event: React.MouseEvent<HTMLButtonElement>): void {
		this.addClickEventLog(event.target as HTMLButtonElement);
		if (this.m_DialogViewInfo.FW.edit.checkEdit('dialog_ok', this.m_DialogViewInfo.objectName, () => {
				this.m_DialogViewInfo.onDialogEvent(false);
			}) === false) {
			return; 
		}
		this.m_DialogViewInfo.onDialogEvent(false);
	}

	/**
	 * タイトルのクローズ処理
	 * @param event イベント 
	 */
	 public onTitleClose(event: React.MouseEvent<HTMLButtonElement>): void {
		this.addClickEventLog(event.target as HTMLButtonElement);
		this.m_DialogViewInfo.onDialogClose();
	}

	/**
	 * クローズイベント処理
	 * @param event 変更イベント 
	 */
	public onClose(event: React.MouseEvent<HTMLButtonElement>, reason: string): void {
		if (reason !== 'backdropClick')
			this.m_DialogViewInfo.onDialogClose();
	}
	// --------------------------------------------------------------------------

	// protected メソッド  -------------------------------------------------------
	/**
	 * ダイアログタイトル取得
	 * @returns ダイアログタイトル
	 */
	protected getDialogTitle(): ReactNode {
		if (this.m_DialogViewInfo != null && this.m_DialogViewInfo.useHeader === true) {
			let title = this.getTitle();
			if (!title)
				title = '　';
			return (
				<DialogTitle disableTypography style={{cursor: 'default'}}>
					<Typography variant='subtitle1' id='draggable-dialog-title'>{title}</Typography>
					<IconButton aria-label='close' {...this.m_CancelEvents} style={this.m_CloseButtonStyle}>
						<CloseIcon />
					</IconButton>
				</DialogTitle>
			);
		}
		else {
			return <></>;
		}
	}

	/**
	 * ダイアログコンテント取得
	 * @returns ダイアログコンテント
	 */
	protected getDialogContent(): ReactNode {
		let dividers = false;
		if (this.m_DialogViewInfo != null && this.m_DialogViewInfo.useDividers === true)
			dividers = true;
		return (
			<DialogContent dividers={dividers}>
				{this.getRenderComponent()}
				<WprMessageComponent view={this.view} />
				<WprSnackbarComponent view={this.view} />
			</DialogContent>
		);
	}

	/**
	 * ダイアログアクション取得
	 * @returns ダイアログアクション
	 */
	protected getDialogAction(): ReactNode {
		const buttons = this.getButtons();
		if (this.m_DialogViewInfo != null && this.m_DialogViewInfo.useFooter === true) {
			return (
				<DialogActions>
					{buttons}
				</DialogActions>
			);
		}
		else {
			return <></>;
		}
	}

	/**
	 * ダイアログタイトル取得
	 * @returns ダイアログタイトル
	 */
	protected getTitle(): string {
		if (!this.m_DialogViewInfo)
			return '';
		if (this.state.title)
			return this.state.title;
		return this.m_DialogViewInfo.title;
	}

	/**
	 * ボタンコンポーネント取得
	 * @returns ボタンコンポーネント
	 */
	protected getButtons(): ReactNode {
		const options = this.getOptions();
		let ok = 'OK';
		let cancel = 'キャンセル';
		if (this.m_DialogViewInfo) {
			ok = this.m_DialogViewInfo.okButtonLabel;
			cancel = this.m_DialogViewInfo.cancelButtonLabel;
			if (ok == null)
				ok = 'OK';
			if (this.m_DialogViewInfo.useOKButton === false) {
				if (this.m_DialogViewInfo.useCancelButton === true) {
					if (cancel == null)
						cancel = '閉じる';
					return (
						<>
							<Button name='wpr-dialog-close' variant='contained' color='primary' {...this.m_CancelEvents}>{cancel}</Button>
						</>
					);
				}
				return <></>;
			}
			else if (this.m_DialogViewInfo.useCancelButton === false) {
				return (
					<>
						<Button name='wpr-dialog-ok' variant='contained' color='primary' {...options} {...this.m_OKEvents}>{ok}</Button>
					</>
				);
			}
			if (cancel == null)
				cancel = 'キャンセル';
		}
		return (
			<>
				<Button name='wpr-dialog-ok' variant='contained' color='primary' {...options} {...this.m_OKEvents}>{ok}</Button>
				<Button name='wpr-dialog-cancel' variant='contained' color='primary' {...this.m_CancelEvents}>{cancel}</Button>
			</>
		);
	}
	// --------------------------------------------------------------------------

	// private メソッド  ---------------------------------------------------------
	/**
	 * スタイル初期化
	 */
	private initStyles(): void {
		if (this.m_CloseButtonStyle == null) {
			this.m_CloseButtonStyle = {
				position: 'absolute',
				right: 1,
				top: 1
			};
		}
	}

	/**
	 * オプション情報取得
	 * @returns オプション情報
	 */
	 private getOptions(): any {
		const rtn: any = {};
		if (this.m_DialogViewInfo && this.m_DialogViewInfo.submitId) {
			rtn['form'] = this.m_DialogViewInfo.submitId;
			rtn['type'] = 'submit';
		}
		return rtn;
	}
	// --------------------------------------------------------------------------

	// IWprDialogの実装  --------------------------------------------------------
	/**
	 * オープン処理
	 * @param sendData 送信データ
	 * @param title タイトル
	 */
	public show(sendData: any, title: string): void {
		this.m_DialogViewInfo.execInjection();
		this.setState({
			open: true,
			title: title
		});
		setTimeout(() => {
			this.m_DialogViewInfo.open(sendData);
		}, 10);
	}

	/**
	 * クローズ処理
	 */
	public close(): void {
		this.setState({
			open: false
		});
	}
	// --------------------------------------------------------------------------
}

function PaperComponent(props: any) {
	return (
	  <Draggable handle='#draggable-dialog-title' cancel={'[class*="MuiDialogContent-root"]'} bounds='parent'>
		<Paper {...props} />
	  </Draggable>
	);
}
