import { BaseService } from './base/BaseService';
import { WprBaseViewCore } from '../../wpr-framework/view/WprBaseViewCore';
import { GetDocumentRequest } from './models/dto/contractConfirm/GetDocumentRequest';
import { GetDocumentResponse } from './models/dto/contractConfirm/GetDocumentResponse';
import { GetDocListRequest } from './models/dto/contractConfirm/GetDocListRequest';
import { GetDocListResponse } from './models/dto/contractConfirm/GetDocListResponse';
import { UpdateDetailRequest } from './models/dto/contractConfirm/UpdateDetailRequest';
import { UpdateDetailResponse } from './models/dto/contractConfirm/UpdateDetailResponse';
import { GetConfirmDetailRequest } from './models/dto/contractConfirm/GetConfirmDetailRequest';
import { GetConfirmDetailResponse } from './models/dto/contractConfirm/GetConfirmDetailResponse';
import { CheckContractConfirmRequest } from './models/dto/contractConfirm/CheckContractConfirmRequest';
import { CheckContractConfirmResponse } from './models/dto/contractConfirm/CheckContractConfirmResponse';

/**
 * MyPageRenewalサービス
 */
export class ContractConfirmService extends BaseService {
	// サービス メソッド  --------------------------------------------------------
	/**
	 * 資料データ取得
	 * @param viewCore ビュー情報
	 * @param request リクエスト情報
	 * @param resMethod 結果処理メソッド
	 */
	public getDocument(viewCore: WprBaseViewCore, request: GetDocumentRequest, resMethod: (result: GetDocumentResponse) => void): void {
		return this.doPost(viewCore, '/contractConfirm/getDocument', request, resMethod);
	}

	/**
	 * 一覧取得
	 * @param viewCore ビュー情報
	 * @param request リクエスト情報
	 * @param resMethod 結果処理メソッド
	 */
	public getList(viewCore: WprBaseViewCore, request: GetDocListRequest, resMethod: (result: GetDocListResponse) => void): void {
		return this.doPost(viewCore, '/contractConfirm/getList', request, resMethod);
	}

	/**
	 * データ更新
	 * @param viewCore ビュー情報
	 * @param request リクエスト情報
	 * @param resMethod 結果処理メソッド
	 */
	public updateDetail(viewCore: WprBaseViewCore, request: UpdateDetailRequest, resMethod: (result: UpdateDetailResponse) => void): void {
		return this.doPost(viewCore, '/contractConfirm/updateDetail', request, resMethod);
	}

	/**
	 * 詳細取得
	 * @param viewCore ビュー情報
	 * @param request リクエスト情報
	 * @param resMethod 結果処理メソッド
	 */
	public getConfirmDetail(viewCore: WprBaseViewCore, request: GetConfirmDetailRequest, resMethod: (result: GetConfirmDetailResponse) => void): void {
		return this.doPost(viewCore, '/contractConfirm/getConfirmDetail', request, resMethod);
	}

	/**
	 * 契約書面交付判定
	 * @param viewCore ビュー情報
	 * @param request リクエスト情報
	 * @param resMethod 結果処理メソッド
	 */
	public checkContractConfirm(viewCore: WprBaseViewCore, request: CheckContractConfirmRequest, resMethod: (result: CheckContractConfirmResponse) => void): void {
		return this.doPost(viewCore, '/contractConfirm/checkContractConfirm', request, resMethod);
	}
	// --------------------------------------------------------------------------
}
