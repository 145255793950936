import { Card, Divider, TableCell, Typography } from '@material-ui/core';
import { Grid } from '@mui/material';
import React, { ReactNode } from 'react';
import { MatOneRowTable } from '../../../../../../wpr-framework/component/controls/material/MatOneRowTable';
import { MatTypography } from '../../../../../../wpr-framework/component/controls/material/MatTypography';
import { WprContainer } from '../../../../../../wpr-framework/component/controls/WprContainer';
import { WprBaseViewComponent } from '../../../../../../wpr-framework/view/component/WprBaseViewComponent';
import { MvnoEntryDetailPartsView } from './MvnoEntryDetailPartsView';
import styles from './MvnoEntryDetailParts.scss';


/**
 * MVNO申込内容パーツ用コンポーネント
 */
export class MvnoEntryDetailParts extends WprBaseViewComponent {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: any) { super(new MvnoEntryDetailPartsView(), props); }
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** MVNO申込内容パーツビュー情報 */
	public get viewInfo(): MvnoEntryDetailPartsView { return this.view as MvnoEntryDetailPartsView; }
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	// /**
	//  * スタイルオブジェクト取得
	// */
	// public getStylesObject(): any { return styles; }

	/**
	 * スタイルオブジェクト取得
	*/
	public getStylesObject(): any { return styles; }

	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		return (
			<>
				<div className='totalFeeBlock'>
					<div className='feeDetail onlyPcColGroup'>

						{/* 通信データ量 */}
						<MatOneRowTable cols={[1, 1]}>
							<TableCell align='left' className='feeItem'>
								<div className='itemTitle'>通信データ量</div>
							</TableCell>
							<TableCell align='right'>
								<MatTypography name='capacity' view={this.view} className='itemVal' />
							</TableCell>
						</MatOneRowTable>

						{/* 音声プランコース */}
						<WprContainer mode='プラン.音声プラン' view={this.view}>
							<MatOneRowTable cols={[1, 1]}>
								<TableCell align='left' className='feeItem'>
									<div className='itemTitle'>音声プランコース</div>
								</TableCell>
								<TableCell align='right'>
									<MatTypography name='course' view={this.view} className='itemVal' />
								</TableCell>
							</MatOneRowTable>

							<MatOneRowTable cols={[1, 1]}>
								<TableCell align='left' className='feeItem'>
									<div className='itemTitle'>留守番電話オプション</div>
								</TableCell>
								<TableCell align='right'>
									<MatTypography name='rusubanUseFlg' view={this.view} className='itemVal' />
								</TableCell>
							</MatOneRowTable>
						</WprContainer>

						{/* 端末 */}
						<WprContainer mode='端末申込.有' view={this.view}>
							{/* 端末 */}
							<MatOneRowTable cols={[1, 1]}>
								<TableCell align='left' className='feeItem'>
									<div className='itemTitle'>端末</div>
								</TableCell>
								<TableCell align='right'>
									<MatTypography name='terminal' view={this.view} className='itemVal' component='span' />
									<WprContainer mode='端末色.有' view={this.view}>
										（<MatTypography name='terminalColor' view={this.view} className='itemVal' component='span' />）
									</WprContainer>
								</TableCell>
							</MatOneRowTable>

							{/* 端末 */}
							<MatOneRowTable cols={[1, 1]}>
								<TableCell align='left' className='feeItem'>
									<div className='itemTitle'>端末延長保証</div>
								</TableCell>
								<TableCell align='right'>
									<MatTypography name='terminalGuaranteeFlg' view={this.view} className='itemVal' component='span' />
								</TableCell>
							</MatOneRowTable>
						</WprContainer>

						{/* SIMサイズ */}
						<MatOneRowTable cols={[1, 1]}>
							<TableCell align='left' className='feeItem'>
								<div className='itemTitle'>SIMサイズ</div>
							</TableCell>
							<TableCell align='right'>
								<MatTypography name='simSize' view={this.view} className='itemVal' />
							</TableCell>
						</MatOneRowTable>

						{/* SIMサイズ */}
						<WprContainer mode='プラン.音声プラン' view={this.view}>
							<WprContainer mode='端末申込.有' view={this.view}>
								<MatOneRowTable cols={[1, 1]}>
									<TableCell align='left' className='feeItem'>
										<div className='itemTitle'>SIMオプション</div>
									</TableCell>
									<TableCell align='right'>
										<MatTypography name='simOption' view={this.view} className='itemVal' />
									</TableCell>
								</MatOneRowTable>
							</WprContainer>
						</WprContainer>

						{/* 5G通信利用 */}
						<MatOneRowTable cols={[1, 1]}>
							<TableCell align='left' className='feeItem'>
								<div className='itemTitle'>5G通信利用</div>
							</TableCell>
							<TableCell align='right'>
								<MatTypography name='fivegUseFlg' view={this.view} className='itemVal' />
							</TableCell>
						</MatOneRowTable>

						{/* 初期サポート */}
						<WprContainer mode='プラン.音声プラン' view={this.view}>
							{/* <WprContainer mode='端末申込.有' view={this.view}> */}
								<MatOneRowTable cols={[1, 1]}>
									<TableCell align='left' className='feeItem'>
										<div className='itemTitle'>初期設定サポート</div>
									</TableCell>
									<TableCell align='right'>
										<MatTypography name='initialSupportFlg' view={this.view} className='itemVal' />
									</TableCell>
								</MatOneRowTable>
							{/* </WprContainer> */}
							{/* 初期サポート */}
							<MatOneRowTable cols={[1, 1]}>
								<TableCell align='left' className='feeItem'>
									<div className='itemTitle'>Webフィルタリング</div>
								</TableCell>
								<TableCell align='right'>
									<MatTypography name='webFilteringFlg' view={this.view} className='itemVal' />
								</TableCell>
							</MatOneRowTable>
						</WprContainer>


					</div>
					<Divider />
					<div className='feeTotal'>
						<MatOneRowTable cols={[1, 1]}>
							<TableCell align='left' className='feeItem'>
								<Typography className='itemTitle'>合計</Typography>
							</TableCell>
							<TableCell align='right'>
								<MatTypography name='total' view={this.view} className='itemVal' component='span' /><span className='itemUnit'>円</span>
							</TableCell>
						</MatOneRowTable>

					</div>
				</div>
			</>
		);
	}
	// --------------------------------------------------------------------------

	// リスト描画  ---------------------------------------------------------------

	// --------------------------------------------------------------------------
}
