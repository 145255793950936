import { CustomerInfo } from '../../../models/CustomerInfo';
import { MGeneral } from '../entity/MGeneral';

/**
 * 名義変更 支払情報
 */
export class NameChangePayment {
	// public 変数	-----------------------------------------------------------
	public changePaymentFlg: boolean;	// 支払方法変更フラグ
	public creditFlg: boolean;			// 新しい支払方法クレカフラグ
	public contractAddressFlg: boolean;	// 契約住所へ送付フラグ
	public postCode: string;			// 送付先郵便番号
	public deliveryAddress: string;		// 送付先住所
	// ------------------------------------------------------------------------
}
