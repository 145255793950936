// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@charset \"UTF-8\";\n/*=================== 変数管理ファイル =================== */\n/* ------- 色 ------- */\n/* ------- width ------- */\n/*フッター*/\n.Footer-footer {\n  text-align: center;\n  margin-top: auto;\n  margin-top: 60px;\n  padding-bottom: 10px; }\n  @media screen and (max-width: 700px) {\n    .Footer-footer {\n      padding-bottom: 65px; } }\n  .Footer-footer .Footer-footerText {\n    font-style: normal;\n    font-weight: 400;\n    font-size: 11px;\n    line-height: 13px;\n    color: #666666; }\n  .Footer-footer .Footer-privacyLink {\n    margin-bottom: 9px; }\n\n.base:has(#usageDetailsID) .Footer-footerSmall {\n  margin-bottom: 150px; }\n\n.base:has(#mvnoEntryCustomerID, #mvnoEntryPlan1ID, #mvnoEntryPlan2ID, #mvnoEntryPlan3ID, #mvnoEntryTotalFeeID) .Footer-footerSmall {\n  margin-bottom: 145px; }\n\n.Footer-footerSmall {\n  text-align: center;\n  margin-bottom: 80px;\n  margin-top: 50px; }\n  .Footer-footerSmall .Footer-linkText + .Footer-linkText {\n    margin-left: 10px; }\n  .Footer-footerSmall .Footer-linkText {\n    font-style: normal;\n    font-weight: 400;\n    font-size: 11px;\n    line-height: 14px;\n    color: #666666; }\n  .Footer-footerSmall .Footer-footerBottom {\n    margin-top: 7px; }\n    .Footer-footerSmall .Footer-footerBottom .Footer-footerText {\n      background: #DCE6F2;\n      height: 25px;\n      font-style: normal;\n      font-weight: 400;\n      font-size: 11px;\n      line-height: 13px;\n      color: #666666;\n      display: flex;\n      justify-content: center;\n      align-items: center; }\n", ""]);
// Exports
exports.locals = {
	"footer": "Footer-footer",
	"footerText": "Footer-footerText",
	"privacyLink": "Footer-privacyLink",
	"footerSmall": "Footer-footerSmall",
	"linkText": "Footer-linkText",
	"footerBottom": "Footer-footerBottom"
};
module.exports = exports;
