import { WprBaseDialogViewInfo } from '../../../../../wpr-framework/view/dialog/WprBaseDialogViewInfo';
import { WprListData } from '../../../../../wpr-framework/view/deco/WprListData';
import { TokyuGraphModel } from '../../../../models/TokyuGraphModel';
import { WprViewMode } from '../../../../../wpr-framework/view/deco/WprViewMode';
import { UsageDetailsService } from '../../../../service/UsageDetailsService';
import { GetTokyuDetailsRequest } from '../../../../service/models/dto/usagedetails/GetTokyuDetailsRequest';
import { WprModel } from '../../../../../wpr-framework/view/deco/WprModel';
import { WprControlInfo } from '../../../../../wpr-framework/view/control/WprControlInfo';
import { WprDI_Control } from '../../../../../wpr-framework/view/di/WprDI_Control';
import { WprKeyValue } from '../../../../../wpr-framework/model/WprKeyValue';
import { ConvertUtil } from '../../../../common/util/ConvertUtil';
import { ObjectUtil } from '../../../../common/util/ObjectUtil';
import { RestoreOutlined } from '@material-ui/icons';
import { GetTokyuDetailsResponse } from '../../../../service/models/dto/usagedetails/GetTokyuDetailsResponse';

// ReceiveData
export class TokyuGraphReceiveData { 
	public customerId: string;								// お客様ID
	public monthList: string[];								// 対象年月のリスト
	public selectedIdx: number;								// 選択月のインデックス
	public energyMonthList: string[];								// 対象年月のリスト
}
/**
 * 東急でんき＆ガス利用明細グラフダイアログ
 */
export class UsageToukyuGraphDialogView extends WprBaseDialogViewInfo {
	// private変数  ------------------------------------------------------------------
	private m_energyMonthList: string[]	= null;	// 対象年月リスト
	private m_customerId: string	=	null;	// お客様ID
	// --------------------------------------------------------------------------

	// DI情報  ------------------------------------------------------------------
	@WprDI_Control
	private m_fromMonthSelect: WprControlInfo	= null;	// グラフ出力開始年月コンボボックス
	@WprDI_Control
	private m_toMonthSelect: WprControlInfo	= null;	// グラフ出力終了年月コンボボックス
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	public constructor() { super('UsageToukyuGraphDialog'); }
	// --------------------------------------------------------------------------

	// モデル  ------------------------------------------------------------------
	@WprListData('SelectedTokyuDetailList')
	private m_SelectedTokyuDetailList: TokyuGraphModel[]	= null;	// 選択中のカテゴリ明細
	// --------------------------------------------------------------------------

	// サービス  ----------------------------------------------------------------
	private m_UsageDetailsService: UsageDetailsService	= new UsageDetailsService();	// 利用明細サービス
	// --------------------------------------------------------------------------

	// privateメソッド -----------------------------------------------------------
	private m_denkiDetailsList: TokyuGraphModel[]	=	null;		// でんきの明細リスト
	private m_gasDetailsList: TokyuGraphModel[]	=	null;			// ガスの明細リスト
	private m_otherDetailsList: TokyuGraphModel[]	=	null;		// その他の明細リスト
	// --------------------------------------------------------------------------

	// override プロパティ  ------------------------------------------------------
	/** ダイアログタイトル */
	public get title(): string { return '東急でんき＆ガス ご利用料金'; }
	/** バリデーション */
	public get validation(): boolean { return false; }
	/** OKボタン使用フラグ */
	public get useOKButton(): boolean { return false; }
	/** Divider使用フラグ */
	public get useDividers(): boolean { return true; }
	/** フッタ使用フラグ */
	public get useFooter(): boolean { return false; }
	/** キャンセルボタン使用フラグ */
	public get useCancelButton(): boolean { return false; }	
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * コントロール定義設定
	 */
	public onRegisterConfig(): void {
		this.addConfig('fromMonthSelect', 'グラフ出力開始月', 'fromMonth', { bindModel: true, hissu: true });
		this.addConfig('toMonthSelect', 'グラフ出力終了月', 'toMonth', { bindModel: true, hissu: true });

		this.addListConfig('month', '請求月', 'SelectedTokyuDetailList', { converter: '年月（yyyy年MM月）' });
		this.addListConfig('fromMonth', '使用開始月', 'SelectedTokyuDetailList', { converter: '日付（yyyy/MM/dd）' });
		this.addListConfig('toMonth', '使用終了月', 'SelectedTokyuDetailList', { converter: '日付（yyyy/MM/dd）' });
		this.addListConfig('day', '日数', 'SelectedTokyuDetailList');
		this.addListConfig('consumption', '使用量', 'SelectedTokyuDetailList');
		this.addListConfig('item', '項目', 'SelectedTokyuDetailList');
		this.addListConfig('taxIn', '料金（税込み）', 'SelectedTokyuDetailList', {converter: '3桁カンマ'});
	}

	/**
	 * ダイアログ表示通知
	 * @param sendData 送信データ
	 */
	public onShowDialog(sendData: any) {
		const data: TokyuGraphReceiveData = sendData as TokyuGraphReceiveData;
		this.m_customerId = data.customerId;
		this.m_energyMonthList = data.energyMonthList;
		// デフォルト：タブで選択中の年月のみ表示
		let req: GetTokyuDetailsRequest = this.setTokyuApiResponse(data.customerId, data.selectedIdx, data.selectedIdx);
		this.m_UsageDetailsService.getTokyuGraphDetail(this, req, (result) => {
			if (result) {
				this.m_denkiDetailsList = result.tokyuDenkDetailsiList;
				this.m_gasDetailsList = result.tokyuGasDetailsList;
				this.m_otherDetailsList = result.otherDetailsList;
			}
			// 年月vmを親から受け取った対象年月に再設定
			this.m_fromMonthSelect.resetValueMap();
			this.m_toMonthSelect.resetValueMap();
			// デフォルト：タブで選択中の年月
			this.m_fromMonthSelect.value = data.selectedIdx;
			this.m_toMonthSelect.value = data.selectedIdx;

			// デフォルト：でんきのグラフ
			this.setDenkiGraph('でんき');
			this.refreshView();
		});
	}

	/**
	 * ダイアログOKイベント
	 * ダイアログを閉じる場合は、this.close()を呼び出す
	 */
	public onDialogOK(): void {
		this.close(true);
	}

	/**
	 * タブモード変更通知
	 * @param mode タブモード
	 * @param oldLabel 古いラベル
	 * @param newLabel 新しいラベル
	 */
	public onChangeTabMode(mode: string, oldLabel: string, newLabel: string): void {
		if (newLabel === 'でんき') {
			this.setDenkiGraph(newLabel);
		} else if (newLabel === 'ガス') {
			this.setGasGraph(newLabel);
		} else if (newLabel === 'その他') {
			this.setOtherGraph(newLabel);
		}
		this.refreshView();
	}

	/**
	 * 値変更通知
	 * @param name 名前
	 * @param value 値
	 * @returns falseの場合、変更を取り消す
	 */
	public onChangeValue(name: string, value: any): boolean {
		// プルダウンの選択されたタイミングで、グラフを再描画する
		// 開始月
		if (name === 'fromMonthSelect') {
			if (value > this.m_toMonthSelect.value) {
				// 開始月が終了月よりも未来の場合、終了月を開始月に揃えて表示
				this.m_toMonthSelect.value = value;
			}
			let req: GetTokyuDetailsRequest = this.setTokyuApiResponse(this.m_customerId, value, this.m_toMonthSelect.value);
			this.m_UsageDetailsService.getTokyuGraphDetail(this, req, (result) => {
				if (result) {
					this.m_denkiDetailsList = result.tokyuDenkDetailsiList;
					this.m_gasDetailsList = result.tokyuGasDetailsList;
					this.m_otherDetailsList = result.otherDetailsList;
				}
				this.onChangeTabMode('東急グラフ', null ,this.getTabMode('東急グラフ'));
				this.refreshView();
			});
		}
		// 終了月
		if (name === 'toMonthSelect') {
			if (value < this.m_fromMonthSelect.value) {
				// 終了月が開始月よりも過去の場合、開始月を終了月に揃えて表示
				this.m_fromMonthSelect.value = value;
			}
			let req: GetTokyuDetailsRequest = this.setTokyuApiResponse(this.m_customerId, this.m_fromMonthSelect.value, value);
			this.m_UsageDetailsService.getTokyuGraphDetail(this, req, (result) => {
				if (result) {
					this.m_denkiDetailsList = result.tokyuDenkDetailsiList;
					this.m_gasDetailsList = result.tokyuGasDetailsList;
					this.m_otherDetailsList = result.otherDetailsList;
				}
				this.onChangeTabMode('東急グラフ', null ,this.getTabMode('東急グラフ'));
				this.refreshView();
			});
		}
		return true;
	}
	
	/**
	 * ValueMap再設定処理
	 * @param name コントロール名
	 * @param id ValueMapID
	 * @list ValueMapのKey-Valueリスト
	 * @returns 再設定したKey-Valueリスト
	 */
	public onResetValueMap(name: string, id: string, list: WprKeyValue[]): WprKeyValue[] {
		if (ObjectUtil.isNotNullOrEmptyArray(this.m_energyMonthList)) {
			list = [];
			this.m_energyMonthList.forEach((value, index) => {
				const monthLabel: string = ConvertUtil.convertYearMonthLabel(value);	// yyyy年MM月
				list.push(new WprKeyValue(index, monthLabel));
			});
		}
		return list;
	}
	// --------------------------------------------------------------------------

	// privateメソッド -----------------------------------------------------------
	/**
	 * APIリクエストの作成
	 * @param customerId 
	 * @param fromIdx 
	 * @param toIdx 
	 * @returns 
	 */
	private setTokyuApiResponse(customerId: string, fromIdx: number, toIdx: number): GetTokyuDetailsRequest {
		let req: GetTokyuDetailsRequest = new GetTokyuDetailsRequest();
		req.customerId = customerId;
		let monthList: string[] = new Array();
		// 対象年月を詰める
		for (let idx: number = fromIdx; idx <= toIdx; idx++) {
			monthList.push(this.m_energyMonthList[idx]);
		}
		req.monthList = monthList;
		return req;
	}
	
	// グラフ表示順序 -> 年月の降順のため、reverse()
	/**
	 * でんきのグラフをセットする
	 * @param newMode 
	 */
	private setDenkiGraph(newMode: string): void {
		this.setListData('SelectedTokyuDetailList', this.getListReverse(this.m_denkiDetailsList));
		this.setViewMode('期間', '表示');
		this.setViewMode('日数', '表示');
		this.setViewMode('使用量', '表示');
		this.setViewMode('単位', newMode);
		this.setViewMode('項目', '');
		this.setTabMode('東急グラフ', newMode);	
	}
	
	/**
	 * ガスのグラフをセットする
	 * @param newMode 
	 */
	private setGasGraph(newMode: string): void {
		this.setListData('SelectedTokyuDetailList', this.getListReverse(this.m_gasDetailsList));
		this.setViewMode('期間', '表示');
		this.setViewMode('日数', '表示');
		this.setViewMode('使用量', '表示');
		this.setViewMode('単位', newMode);
		this.setViewMode('項目', '');
		this.setTabMode('東急グラフ', newMode);	
	}

	/**
	 * その他のグラフをセットする
	 * @param newMode 
	 */
	private setOtherGraph(newMode: string): void {
		this.setListData('SelectedTokyuDetailList', this.getListReverse(this.m_otherDetailsList));
		this.setViewMode('期間', '');
		this.setViewMode('日数', '');
		this.setViewMode('使用量', '');
		this.setViewMode('単位', '');
		this.setViewMode('項目', '表示');
		this.setTabMode('東急グラフ', newMode);	
	}

	/**
	 * リスト順序変更
	 * @param list 
	 * @returns 
	 */
	private getListReverse(list: TokyuGraphModel[]) : TokyuGraphModel[] {
		// list.reverse()だと、元のリストを変更してしまうので、[...list]でシャローコピーしたリストをrevese()して返却
		const reverse: TokyuGraphModel[] = [...list].reverse();
		return reverse;
	}
}
