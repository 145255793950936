import { WprBaseViewInfo } from '../../view/WprBaseViewInfo';
import { IWprTableSortMode } from './IWprTableSortMode';

/**
 * テーブルソートモード情報
 */
export class WprTableSortModeInfo {
	// private 変数  ------------------------------------------------------------
	private m_ModeName: string					= null;				// モード名
	private m_SortCompList: IWprTableSortMode[]	= new Array();		// ソートコンポーネントリスト
	private m_Mode: string						= null;				// モード
	private m_Asc: boolean						= null;				// 昇順フラグ
	private m_SortItemList: string[]			= new Array();		// ソートアイテムリスト
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** モード名 */
	public get name(): string		{ return this.m_ModeName;	}
	/** モード */
	public get mode(): string		{ return this.m_Mode;		}
	/** 昇順フラグ */
	public get asc(): boolean		{ return this.m_Asc;		}
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	public constructor(modeName: string) {
		this.m_ModeName = modeName;
	}
	// --------------------------------------------------------------------------

	// public メソッド  ----------------------------------------------------------
	/**
	 * ソートコンポーネント追加処理
	 * @param comp タブコンポーネント
	 * @param plabel タブパネルラベル
	 * @param list タブラベルリスト
	 */
	public addTableSortComponent(comp: IWprTableSortMode, item: string): void {
		this.m_SortCompList.push(comp);
		if (item != null)
			this.m_SortItemList.push(item);
	}

	/**
	 * クリア処理
	 */
	public clear() {
		this.m_Mode = null;
		this.m_Asc = null;
		for(const sinfo of this.m_SortCompList)
			sinfo.setSortMode(null, null);
	}

	/**
	 * モード設定処理
	 * @param mode モード 
	 * @param isAsc 昇順フラグ 
	 */
	public setMode(mode: string, isAsc: boolean): void {
		this.m_Mode = mode;
		this.m_Asc = isAsc;
		for(const sinfo of this.m_SortCompList)
			sinfo.setSortMode(mode, isAsc);
	}

	/**
	 * ソートモード情報取得
	 * @returns ソートモード情報
	 */
	public getSortModeInfo(): WprSortModeInfo {
		return new WprSortModeInfo(this.m_Mode, this.m_Asc);
	}

	/**
	 * テーブルソート初期化処理
	 * @returns falseの場合エラーあり
	 */
	public initialize(view: WprBaseViewInfo): boolean {
		const listInfo = view.getListInfo(this.m_ModeName);
		if (listInfo === null) {
			view.addErrorLog(`MatTableSortLabelのlistNameに誤りがあります。[${this.m_ModeName}]`);
			return false;
		}
		let ret = true;
		const list = new Array();
		this.m_SortItemList.forEach((item) => {
			if (!item) {
				view.addErrorLog(`MatTableSortLabelは、labelかitemを指定してください。[${this.m_ModeName}]`);
				ret = false;
			}
			else if (list.includes(item) == true) {
				view.addErrorLog(`ソートアイテムが重複しています。[${this.m_ModeName}][${item}]`);
				ret = false;
			}
		});
		return ret;
	}
	// --------------------------------------------------------------------------
}

/**
 * ソート情報
 */
export class WprSortModeInfo {
	// private 変数  ------------------------------------------------------------
	private m_Mode: string		= null;		// モード
	private m_Asc: boolean		= null;		// 昇順フラグ
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** モード */
	public get mode(): string		{ return this.m_Mode;		}
	/** 昇順フラグ */
	public get asc(): boolean		{ return this.m_Asc;		}
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	public constructor(mode: string, isAsc: boolean) {
		this.m_Mode = mode;
		this.m_Asc = isAsc;
	}
	// --------------------------------------------------------------------------
}