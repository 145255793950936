/**
 * 
 */
export class MCommon {
	// public 変数	-----------------------------------------------------------
	public commonId: number;		// 
	public category1: string;	// 
	public category2: string;	// 
	public category3: string;	// 
	public category4: string;	// 
	public commonValue: string;	// 
	public commonMemo: string;	// 
	public entryDate: Date;		// 
	public entryCd: string;		// 
	public updateDate: Date;	// 
	public updateCd: string;	// 
	public deleteFlag: boolean;	// 
	public deleteDate: Date;	// 
	public deleteCd: string;	// 
	// ------------------------------------------------------------------------

	// コンストラクタ ----------------------------------------------------------
	constructor(commonMemo: string, category1?: string, category2?: string, category3?: string, category4?: string)  {
		this.commonMemo = commonMemo;
		this.category1 =category1;
		this.category2 = category2;
		this.category3 = category3;
		this.category4 = category4;
	}
	// ------------------------------------------------------------------------
}
