import { WprBaseViewInfo } from '../WprBaseViewInfo';
import { WprTabViewNameInfo } from './WprTabViewNameInfo';

/**
 * タブ子ビュー情報
 */
export class WprTabChildViewInfo {
	// private 変数  ------------------------------------------------------------
	private m_Parent: WprBaseViewInfo						= null;										// 親ビュー情報
	private m_Mode: string									= null;										// タブモード
	private m_Label: string									= null;										// 現在ラベル
	private m_ViewMap: Map<string, WprTabViewNameInfo[]> 	= new Map<string, WprTabViewNameInfo[]>();	// ビュー情報マップ
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	public constructor(mode: string, parent: WprBaseViewInfo) {
		this.m_Mode = mode;
		this.m_Parent = parent;
	}
	// --------------------------------------------------------------------------

	// public メソッド  ---------------------------------------------------------
	/**
	 * タブ変更チェック
	 * @param label ラベル
	 * @returns trueの場合、変更できる
	 */
	 public checkChangeTab(label: string): boolean {
		if (this.m_ViewMap.has(label) === true) {
			if (this.m_Label === label)
			return false;
		}
		return true;
	}

	/**
	 * タブ変更処理
	 * @param label ラベル
	 */
	public changeTab(label: string): void {
		if (this.m_ViewMap.has(label) === true) {
			const before = this.m_Label;
			this.m_Label = label;
			if (before != null) {
				this.m_ViewMap.get(before).forEach(vinfo => {
					if (vinfo.viewInfo != null) {
						this.m_Parent.deleteChildViewInfo(vinfo.viewInfo.props.name, vinfo.viewInfo);
						vinfo.viewInfo.setTabDisp(false);
					}
				});
			}
			this.m_ViewMap.get(label).forEach(vinfo => {
				if (vinfo.viewInfo != null) {
					this.m_Parent.addChildViewInfo(vinfo.viewInfo.props.name, vinfo.viewInfo);
					vinfo.viewInfo.setTabDisp(true);
				}
			});
		}
		else {
			this.m_Label = label;
		}
	}

	/**
	 * ビュー追加
	 * @param label ラベル
	 * @param vname ビュー名
	 * @param name 名称
	 */
	public addView(label: string, vname: string, name: string): void {
		if (this.m_ViewMap.has(label) === false)
			this.m_ViewMap.set(label, new Array());
		const vlist = this.m_ViewMap.get(label);
		for (const vinfo of vlist) {
			if (vinfo.compareName(vname, name) === true)
				return;
		}
		this.m_ViewMap.get(label).push(new WprTabViewNameInfo(vname, name));
	}

	/**
	 * 子ビュー情報設定
	 * @param name ビュー名
	 * @param view ビュー情報
	 * @returns trueの場合、設定
	 */
	public setChildViewInfo(name: string, view: WprBaseViewInfo): boolean {
		for (const label of this.m_ViewMap.keys()) {
			const vlist = this.m_ViewMap.get(label);
			for (const vinfo of vlist) {
				if (vinfo.compareName(name, view.props.name) === true) {
					vinfo.viewInfo = view;
					if (this.m_Label != null && this.m_Label === label) {
						this.m_Parent.addChildViewInfo(view.props.name, vinfo.viewInfo);
						view.setTabDisp(true);
					}
					else {
						view.addInfoLog('== Wait tab View ==');
					}
					return true;
				}
			}
		}
		return false;
	}

	/**
	 * 子ビュー表示チェック
	 * @param view ビュー情報
	 * @returns trueの場合は、子ビューとして表示する
	 */
	 public isShowChildView(view: WprBaseViewInfo): boolean {
		let rtn = false;
		this.m_ViewMap.forEach((vlist, lebel) => {
			if (this.m_Label != null && this.m_Label === lebel) {
				for (const vinfo of vlist) {
					if (vinfo.compareName(view.name, view.props.name) === true) {
						vinfo.viewInfo.setTabDisp(true);
						rtn = true;
						break;
					}
				}
			}
		});
		return rtn;
	}

	/**
	 * 子ビュー表示チェック
	 * @param view ビュー情報
	 * @returns trueの場合は、子ビューとして表示する
	 */
	public isChildView(view: WprBaseViewInfo): boolean {
		let rtn = false;
		this.m_ViewMap.forEach((vlist) => {
			for (const vinfo of vlist) {
				if (vinfo.compareName(view.name, view.props.name) === true) {
					rtn = true;
					break;
				}
			}
		});
		return rtn;
	}
	// --------------------------------------------------------------------------
}
