import { WprBaseDIManage } from '../view/di/WprBaseDIManage';
import { WprBaseViewInfo } from '../view/WprBaseViewInfo';
import { WprScopeModelDIList } from './WprScopeModelDIList';
import { WprBaseGlobalScopeInfo } from './WprBaseGlobalScopeInfo';
import { WprScopeUpdatePropertyInfo } from './WprScopeUpdatePropertyInfo';
import { WprBaseViewMode } from '../mode/WprBaseViewMode';
import { WprBaseViewMultiMode } from '../mode/WprBaseViewMultiMode';
import { WprMultiMode } from '../mode/WprMultiMode';
import { WprScopeModelDIInfo } from './WprScopeModelDIInfo';
import { WprFramework } from '../WprFramework';

/**
 * スコープ基本情報
 */
export abstract class WprBaseScopeInfo extends WprBaseDIManage {
	// private 変数  ------------------------------------------------------------
	private m_Name: string								= null;										// スコープ名
	private m_IsGlobal: boolean							= false;									// グローバルフラグ
	private m_ModelMap: Map<string, any>				= new Map<string, any>();					// モデルマップ
	private m_ModeMap: Map<string, string>				= new Map<string, string>();				// モードマップ
	private m_DIMap: Map<string, WprScopeModelDIList>	= new Map<string, WprScopeModelDIList>();	// スコープモデルDI情報マップ
	private m_ViewInfoList: WprBaseViewInfo[] 			= new Array();								// ビュー情報リスト
	private m_GlobalScopeInfo: WprBaseGlobalScopeInfo	= null;										// グローバルスコープ情報
	private m_PrevViewName: string						= null;										// 前画面のビュー名(onShowView内で有効)
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** スコープ名 */
	public get name(): string								{ return this.m_Name;				}
	/** スコープ名 */
	public get isGlobal(): boolean							{ return this.m_IsGlobal;			}
	/** グローバルスコープ情報 */
	public get globalScopeInfo(): WprBaseGlobalScopeInfo 	{ return this.m_GlobalScopeInfo; 	}	public set globalScopeInfo(sinfo: WprBaseGlobalScopeInfo)	{ this.m_GlobalScopeInfo = sinfo;	}
	/** 前画面のビュー名(onShowView内で有効) */
	public get prevViewName(): string 						{ return this.m_PrevViewName; 		}	public set prevViewName(prev: string) 						{ this.m_PrevViewName = prev;		}
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	public constructor(name: string, isGlobal: boolean = false) {
		super(name);
		this.m_Name = name;
		this.m_IsGlobal = isGlobal;
	}
	// --------------------------------------------------------------------------

	// virtual メソッド  --------------------------------------------------------
	/**
	 * 初期化通知
	 */
	public onInitialize(): void {}

	/**
	 * ビュー初期化通知
	 * @param viewName ビュー名
	 */
	public onInitView(viewName: string): void {}

	/**
	 * ビュー表示通知
	 * @param viewName ビュー名
	 */
	public onShowView(viewName: string): void {}

	/**
	 * ビュー終了通知
	 * @param viewName ビュー名
	 */
	public onTerminateView(viewName: string): void {}

	/**
	 * ビューモード変更通知
	 * @param viewName ビュー名
	 * @param mode モード情報
	 * @param oldMode 古いモード
	 * @param newMode 新しいモード
	 */
	public onChangeViewMode(viewName: string, mode: WprBaseViewMode, oldMode: string, newMode: string): void {}

	/**
	 * 複数ビューモード変更処理
	 * @param viewName ビュー名
	 * @param oldMode 変更前モード
	 * @param newMode 変更後モード
	 */
	public onChangeViewMultiMode(viewName: string, mode: WprBaseViewMultiMode, oldMode: WprMultiMode, newMode: WprMultiMode): void {}

	/**
	 * モデル設定通知
	 * @param name モデル名
	 * @param model モデル
	 */
	public onSetScopeModel(name: string, model: any): void {}

	/**
	 * グローバルモデル設定通知
	 * @param name モデル名
	 * @param model モデル
	 */
	public onSetGlobalScopeModel(name: string, model: any): void {}
	// --------------------------------------------------------------------------

	// public メソッド  ---------------------------------------------------------
	/**
	 * 初期化処理
	 */
	public initialize() {
		try {
			this.setDIModelInfo(this, this.FW.model.getDecoNameMap('ScopeModel', this));
			if (this.globalScopeInfo != null) {
				this.globalScopeInfo.setDIModelInfo(this, this.FW.model.getDecoNameMap('GlobalScopeModel', this));
			}
			this.onInitialize();
		}
		catch (ex) {
			this.addErrorLog('onInitializeで例外が発生しました。', ex);
		}
	}

	/**
	 * ビュー初期化通知
	 * @param viewInfo ビュー情報
	 */
	public intiView(viewInfo: WprBaseViewInfo) {
		try {
			this.m_ViewInfoList.push(viewInfo);
			this.onInitView(viewInfo.name);
		}
		catch (ex) {
			this.addErrorLog(`onIntiViewで例外が発生しました。[${viewInfo.objectName}]`, ex);
		}
	}

	/**
	 * ビュー表示処理
	 * @param viewInfo ビュー表示情報
	 */
	public showView(viewInfo: WprBaseViewInfo): void {
		try {
			this.onShowView(viewInfo.name);
		}
		catch (ex) {
			this.addErrorLog(`onShowViewで例外が発生しました。[${viewInfo.objectName}]`, ex);
		}
	}

	/**
	 * ビュー終了処理
	 * @param viewInfo ビュー表示情報
	 */
	public terminateView(viewInfo: WprBaseViewInfo): void {
		try {
			this.m_ViewInfoList = this.m_ViewInfoList.filter(item => {
				return item !== viewInfo;
			});
			this.onTerminateView(viewInfo.name);
		}
		catch (ex) {
			this.addErrorLog(`onTerminateViewで例外が発生しました。[${viewInfo.objectName}]`, ex);
		}
	}

	/**
	 * ビューモード変更処理
	 * @param viewInfo ビュー情報
	 * @param mode モード情報
	 * @param oldMode 古いモード
	 * @param newMode 新しいモード
	 */
	public changeViewMode(viewInfo: WprBaseViewInfo, mode: WprBaseViewMode, oldMode: string, newMode: string): void {
		try {
			this.onChangeViewMode(viewInfo.name, mode, oldMode, newMode);
		}
		catch (ex) {
			this.addErrorLog(`onChangeViewModeで例外が発生しました。[${viewInfo.objectName}]`, ex);
		}
	}

	/**
	 * 複数ビューモード変更処理
	 * @param viewInfo ビュー情報
	 * @param oldMode 変更前モード
	 * @param newMode 変更後モード
	 */
	public changeViewMultiMode(viewInfo: WprBaseViewInfo, mode: WprBaseViewMultiMode, oldMode: WprMultiMode, newMode: WprMultiMode): void {
		try {
			this.onChangeViewMultiMode(viewInfo.name, mode, oldMode, newMode);
		}
		catch (ex) {
			this.addErrorLog(`onChangeViewMultiModeで例外が発生しました。[${viewInfo.objectName}]`, ex);
		}
	}

	/**
	 * スコープモデル設定処理
	 * @param name モデル名称
	 * @param model モデル
	 */
	public setScopeModel(name: string, model: any): void {
		if (this.m_IsGlobal === true)
			this.addErrorLog('setScopeModelは、グローバルスコープでは使用できません。');
		else
			this.setModel(name, model);
		const data = this.getScopeModelState();
		this.m_ViewInfoList.forEach(viewInfo => {
			viewInfo.setScopeModelState(data);
		});
	}

	/**
	 * スコープモード設定処理
	 * @param name モード名称
	 * @param mode モード
	 * @returns falseの場合、モードは変化がない
	 */
	public setScopeMode(name: string, mode: string): boolean {
		if (this.m_IsGlobal === true) {
			this.addErrorLog('setScopeModeは、グローバルスコープでは使用できません。');
			return false;
		}
		if (this.setMode(name, mode) === true) {
			const minfo = this.getScopeModeState();
			this.m_ViewInfoList.forEach(viewInfo => {
				viewInfo.setScopeModeState(minfo);
			});
			return true;
		}
		return false;
	}

	/**
	 * スコープモード取得処理
	 * @param name モード名称
	 * @returns モード
	 */
	public getScopeMode(name: string): string {
		if (this.m_ModeMap.has(name) === true)
			return this.m_ModeMap.get(name);
		return null;
	}

	/**
	 * グローバルスコープモード設定処理
	 * @param name モード名称
	 * @param mode モード
	 * @returns falseの場合、モードは変化がない
	 */
	public setGlobalScopeMode(name: string, mode: any): boolean {
		let gscope = null;
		if (this.m_IsGlobal === true)
			gscope = this;
		else if (this.m_GlobalScopeInfo != null)
			gscope = this.m_GlobalScopeInfo;
		if (gscope != null) {
			if (gscope.setMode(name, mode) === true) {
				const minfo = gscope.getScopeModeState();
				this.FW.view.activeViewList.forEach(viewInfo => {
					viewInfo.setGlobalModeState(minfo);
				});
				return true;
			}
		}
		return false;
	}

	/**
	 * グローバルスコープモデル設定処理
	 * @param name モデル名称
	 * @param model モデル
	 */
	public setGlobalScopeModel(name: string, model: any): void {
		let gscope = null;
		if (this.m_IsGlobal === true)
			gscope = this;
		else if (this.m_GlobalScopeInfo != null)
			gscope = this.m_GlobalScopeInfo;
		if (gscope != null) {
			gscope.setModel(name, model);
			const data = gscope.getScopeModelState();
			this.m_ViewInfoList.forEach(viewInfo => {
				viewInfo.setGlobalModelState(data);
			});
			this.FW.scope.onSetGlobalScopeModel(name, model);
		}
	}

	/**
	 * DIモデル情報設定
	 * @param obj DI対象オブジェクト
	 * @param diNameMap DI名称マップ
	 */
	public setDIModelInfo(obj: WprBaseDIManage, diNameMap: Map<string, string>) {
		if (diNameMap) {
			diNameMap.forEach((prop, key, map) => {
				const diInfo = new WprScopeModelDIInfo(obj, key, prop, this.m_IsGlobal);
				if (this.m_DIMap.has(key) === false)
					this.m_DIMap.set(key, new WprScopeModelDIList(key));
				this.m_DIMap.get(key).addDIInfo(diInfo);
			});
		}
	}

	/**
	 * モデル値チェック処理
	 */
	public checkModelValue(): void {
		this.m_DIMap.forEach((diList, key, map) => {
			diList.checkModelValue();
		});
	}

	/**
	 * DI情報削除処理
	 * @param obj 対象オブジェクト
	 */
	public deleteDIInfo(obj: WprBaseDIManage) {
		this.m_DIMap.forEach((diList, key, map) => {
			diList.deleteDIInfo(obj);
		});
	}

	/**
	 * スコープモデル更新時にチェックするプロパティをチェックする
	 * @param uinfo スコープ更新プロパティ情報
	 */
	public setUpdateProperty(uinfo: WprScopeUpdatePropertyInfo): void {
		if (this.m_DIMap.has(uinfo.name) === true)
			this.m_DIMap.get(uinfo.name).setUpdateProperty(uinfo.obj, uinfo.propName, this.m_IsGlobal);
	}

	/**
	 * ビュースコープ通知処理(ビュースコープ内の他のビューに通知)
	 * @param viewInfo 通知元ビュー情報
	 * @param notifyName 通知名
	 * @param info 通知情報
	 * @param toViewName 通知先ビュー名
	 */
	public notifyScopeView(viewInfo: WprBaseViewInfo, notifyName: string, info: any, toViewName?: string) {
		for(const vinfo of this.m_ViewInfoList) {
			if (viewInfo !== vinfo && (toViewName === undefined || toViewName === toViewName)) {
				try {
					if (vinfo.isMount === true) {
						vinfo.onNotifyScopeView(notifyName, info);
						vinfo.subViewMap.forEach((subView, key, map) => {
							subView.onNotifyScopeView(notifyName, info);
						});
					}
				}
				catch (ex) {
					this.addErrorLog(`onNotifyScopeViewで例外が発生しました。[${vinfo.objectName}]`, ex);
				}
			}
		}
	}

	/**
	 * モードチェック処理
	 * @param viewMode モード名
	 * @param mode モード
	 * @returns tureの場合、モードが含まれている
	 */
	public checkMode(modeName: string, mode: string): boolean {
		for(const mname of this.m_ModeMap.keys()) {
			if (!modeName || mname === modeName) {
				if (this.m_ModeMap.get(mname) === mode)
					return true;
			}
		}
		return false;
	}

	/**
	 * スコープモデルステータス取得
	 * @returns スコープモデル
	 */
	public getScopeModelState(): any {
		let model: any = {};
		this.m_ModelMap.forEach((value, name, map) => {
			model[name] = value;
		});
		return model;
	}

	/**
	 * スコープモードステータス取得
	 * @returns スコープモード
	 */
	public getScopeModeState(): any {
		let mode: any = {};
		this.m_ModeMap.forEach((value, name, map) => {
			mode[name] = value;
		});
		return mode;
	}

	/**
	 * モード設定処理
	 * @param name モード名称
	 * @param model モード
	 * @returns
	 */
	public setMode(name: string, mode: any): boolean {
		if (this.m_ModeMap.has(name) === true && this.m_ModeMap.get(name) === mode)
			return false;
		this.m_ModeMap.set(name, mode);
		return true;
	}

	/**
	 * モデル設定処理
	 * @param name モデル名称
	 * @param model モデル
	 */
	public setModel(name: string, model: any): void {
		this.m_ModelMap.set(name, model);
		if (this.m_DIMap.has(name) === false)
			this.m_DIMap.set(name, new WprScopeModelDIList(name));
		this.m_DIMap.get(name).setModel(model);
		this.m_DIMap.get(name).updateModelValue();
		if (this.m_IsGlobal === false)
			this.onSetScopeModel(name, model);
		else
			this.onSetGlobalScopeModel(name, model);
	}

	/**
	 * モデル取得
	 * @param name モデル名
	 * @returns モデル
	 */
	public getModel(name: string) {
		if (this.m_ModelMap.has(name) === true)
			return this.m_ModelMap.get(name);
		return null;
	}
	// --------------------------------------------------------------------------
}
