import { Card, Divider, TableCell, Typography } from '@material-ui/core';
import { Grid } from '@mui/material';
import React, { ReactNode } from 'react';
import { WprBaseViewComponent } from '../../../../../wpr-framework/view/component/WprBaseViewComponent';
import { MvnoAddCapacityConfirmView } from './MvnoAddCapacityConfirmView';
import { MatButton } from '../../../../../wpr-framework/component/controls/material/MatButton';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import styles from './MvnoAddCapacityConfirm.scss';
import { MatTextField } from '../../../../../wpr-framework/component/controls/material/MatTextField';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { WprAnchor } from '../../../../../wpr-framework/component/controls/WprAnchor';
import { MatSelect } from '../../../../../wpr-framework/component/controls/material/MatSelect';
import { MatOneRowTable } from '../../../../../wpr-framework/component/controls/material/MatOneRowTable';
import { MatTypography } from '../../../../../wpr-framework/component/controls/material/MatTypography';

/**
 * データ容量追加申込確認用コンポーネント
 */
export class MvnoAddCapacityConfirm extends WprBaseViewComponent {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: any) { super(new MvnoAddCapacityConfirmView(), props); }
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** データ容量追加申込確認ビュー情報 */
	public get viewInfo(): MvnoAddCapacityConfirmView { return this.view as MvnoAddCapacityConfirmView; }
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * スタイルオブジェクト取得
	*/
	public getStylesObject(): any { return styles; }

	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		return (
			<>
				{/* Milosから追加 START */}



				{/* Milosから追加 END */}
				<div>
					<div className='pageCard'>
						<div className="pageHeader title">
							データ容量追加申込確認
						</div>
						<Divider />
						<div className='pageContent'>
							<div>
								<div className='title_gray'>
									<span>お申込み内容のご確認</span>
								</div>
								<div className='box_gray'>
									<div className='boxContent'>
										<MatOneRowTable cols={[3, 2]} className='detailItem'>
											<TableCell className='itemTitle'>
												<Typography>回線番号</Typography>
											</TableCell>
											<TableCell align='right' className='itemText'>
												{/* <Typography component='span'>6GB（月額：0,000円）</Typography> */}
												<MatTypography name='phoneNumber' view={this.view} component='span' />
											</TableCell>
										</MatOneRowTable>

										<MatOneRowTable cols={[3, 2]} className='detailItem'>
											<TableCell className='itemTitle'>
												<MatTypography name='addCouponCapacity' view={this.view} component='span' />
											</TableCell>
											<TableCell align='right' className='itemText'>
												<MatTypography name='addCouponPrice' view={this.view} component='span' />
												<span className='itemUnit'>円（税込）</span>
												{/* <MatTypography name='capacityAfter' view={this.view} component='span' /> */}
											</TableCell>
										</MatOneRowTable>

									</div>
								</div>
								<div className='comment'>
									<ul className='ul_kome'>
										<li>
											「データ容量追加」オプションは申込後キャンセルができません。
										</li>
									</ul>
								</div>
							</div>

							<div className='pageFooter'>
								<div>
									<MatButton name='next' view={this.view} className='nextButton' >
										上記内容で申込む
										<NavigateNextIcon />
									</MatButton>

								</div>
								<div>
									<MatButton name='back' view={this.view} className='backButton' >
										<NavigateBeforeIcon />
										戻る
									</MatButton>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
	// --------------------------------------------------------------------------
}
