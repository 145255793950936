// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@charset \"UTF-8\";\n/*\r\n * とことんサポートサービス選択用CSS\r\n */\n.TokotonSupportSelect-comment {\n  font-size: 14px; }\n  @media screen and (max-width: 450px) {\n    .TokotonSupportSelect-comment {\n      font-size: 12px; } }\n\n.TokotonSupportSelect-detail_link {\n  font-size: 14px;\n  text-align: right; }\n  @media screen and (max-width: 450px) {\n    .TokotonSupportSelect-detail_link {\n      margin: 16px 0 4px 0; } }\n\n.TokotonSupportSelect-table {\n  display: flex; }\n  @media screen and (max-width: 450px) {\n    .TokotonSupportSelect-table {\n      display: none; } }\n\n.TokotonSupportSelect-table_head .pageContent > div > div + div {\n  margin-top: 0 !important; }\n\n.TokotonSupportSelect-table_head .pageContent {\n  padding: 0; }\n\n.TokotonSupportSelect-content_text .MuiTypography-body1 {\n  margin-top: 13px;\n  font-size: 14px;\n  font-weight: 500; }\n  @media screen and (max-width: 450px) {\n    .TokotonSupportSelect-content_text .MuiTypography-body1 {\n      font-size: 13px;\n      margin: 0; } }\n\n@media screen and (max-width: 450px) {\n  .TokotonSupportSelect-confirmButton .main {\n    padding: 24px 19px 0px 19px; } }\n\n.TokotonSupportSelect-confirmButton .pageContent {\n  margin-bottom: 0px; }\n\n.TokotonSupportSelect-confirmButton .supportMsgArea {\n  margin-bottom: 0px; }\n\n.TokotonSupportSelect-noteArea {\n  margin-top: 1rem; }\n  .TokotonSupportSelect-noteArea .TokotonSupportSelect-text {\n    margin-top: 1rem; }\n  @media screen and (max-width: 450px) {\n    .TokotonSupportSelect-noteArea {\n      font-size: 12px; } }\n", ""]);
// Exports
exports.locals = {
	"comment": "TokotonSupportSelect-comment",
	"detail_link": "TokotonSupportSelect-detail_link",
	"table": "TokotonSupportSelect-table",
	"table_head": "TokotonSupportSelect-table_head",
	"content_text": "TokotonSupportSelect-content_text",
	"confirmButton": "TokotonSupportSelect-confirmButton",
	"noteArea": "TokotonSupportSelect-noteArea",
	"text": "TokotonSupportSelect-text"
};
module.exports = exports;
