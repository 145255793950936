import { WprActionInfo } from '../action/WprActionInfo';
import { WprEventActionInfo, WprEventName } from '../action/WprEventActionInfo';
import { WprModelActionInfo } from '../action/WprModelActionInfo';
import { WprBaseCustomComponent } from '../component/controls/WprBaseCustomComponent';
import { IWprError, IWprServiceError } from '../component/error/IWprError';
import { WprRowInfo } from '../component/props/WprRowInfo';
import { WprDebugLogInfo } from '../debug/WprDebugLogInfo';
import { WprErrorInfo, WprLogLevel } from '../error/WprErrorInfo';
import { WprBaseExtention } from '../extention/WprBaseExtention';
import { WprSortModeInfo } from '../mode/table/WprTableSortModeInfo';
import { WprBaseViewListMode } from '../mode/WprBaseViewListModeInfo';
import { WprBaseViewMode } from '../mode/WprBaseViewMode';
import { WprBaseViewMultiMode } from '../mode/WprBaseViewMultiMode';
import { WprMultiMode } from '../mode/WprMultiMode';
import { WprKeyValue } from '../model/WprKeyValue';
import { WprValueMap } from '../model/WprValueMap';
import { WprReflectUtil } from '../util/WprReflectUtil';
import { IWprConfigOption } from './control/IWprConfigOption';
import { IWprLayoutConfigOption } from './control/IWprLayoutConfigOption';
import { WprListInfo } from './control/list/WprListInfo';
import { WprListInfoMap } from './control/list/WprListInfoMap';
import { WprControlAuthInfo } from './control/WprControlAuthInfo';
import { WprControlInfo } from './control/WprControlInfo';
import { WprBaseDIManage } from './di/WprBaseDIManage';
import { IWprUrlParam } from './route/IWprUrlParam';
import { WprControlErrorMap } from './validation/control/WprControlErrorMap';
import { WprValidError } from './validation/WprValidError';
import { WprValidErrorInfo } from './validation/WprValidErrorInfo';
import { WprBaseViewInfo } from './WprBaseViewInfo';
import { CryptRijndaelUtil } from '../../mypagerenewal/common/util/CryptRijndaelUtil';

/**
 * ビュー情報基本コアクラス
 */
export abstract class WprBaseViewCore extends WprBaseDIManage {
	// private 変数  ------------------------------------------------------------
	private m_ControlMap: Map<string, WprControlInfo>				= new Map<string, WprControlInfo>();		// コントロール情報マップ
	private m_ActionMap: Map<string, WprActionInfo>					= new Map<string, WprActionInfo>();			// アクション情報マップ
	private m_ModelActionMap: Map<string, WprModelActionInfo>		= new Map<string, WprModelActionInfo>();	// モデルアクション情報マップ
	private m_GlobalModelActionMap: Map<string, WprModelActionInfo>	= new Map<string, WprModelActionInfo>();	// グローバルモデルアクション情報マップ
	private m_EventActionMap: Map<string, WprEventActionInfo> 		= new Map<string, WprEventActionInfo>();	// イベントアクション情報マップ
	private m_ModelMap:  Map<string, string>;																	// モデルマップ
	private m_ListInfoMap: WprListInfoMap							= new WprListInfoMap(this);					// リスト情報マップ
	private m_ViewModeMap: Map<string, WprBaseViewMode> 			= new Map<string, WprBaseViewMode>();		// ビューモード情報マップ
	private m_InputErrorMsgList: WprValidErrorInfo[]				= null;										// 入力エラーメッセージ
	private m_ServiceErrorMsgList: string[]							= null;
	private m_ErrorInterList: IWprError[]							= new Array(); 								// エラーコンポーネント情報リスト
	private m_ServiceErrorInterList: IWprServiceError[] 			= new Array(); 								// サービスエラーコンポーネント情報リスト
	private m_ExtentionMap: Map<string, WprBaseExtention> 			= new Map<string, WprBaseExtention>();		// 拡張機能マップ
	private m_CustomCompMap: Map<string, WprBaseCustomComponent<any, any>>	= new Map<string, WprBaseCustomComponent<any, any>>();	// カスタムコンポーネントマップ
	private m_CustomValidErrorCount: number							= 0;										// カスタムバリデーションエラー数
	private m_ReserveViewModeList: WprKeyValue[] 					= new Array();								// 予約ビューモード設定情報リスト
	private m_IsStartValid: boolean									= false;									// バリデーション開始フラグ
	private m_ControlErrorMap: WprControlErrorMap					= new WprControlErrorMap();					// コントロールエラーマップ
	private m_DebugLogList: WprDebugLogInfo[]						= new Array();								// デバックログ情報リスト
	private m_UseScrollEvent: boolean								= true;										// スクロールイベント使用フラグ
	private m_UseResizeEvent: boolean								= true;										// リサイズイベント使用フラグ
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** ビュー情報 */
	public abstract get viewInfo(): WprBaseViewInfo;			public abstract set viewInfo(viewInfo: WprBaseViewInfo);
	/** モデルマップ */
	protected get modelMap(): Map<string, string> 				{ return this.getModelMap();		}
	/** コントロール情報マップ */
	public get controlMap(): Map<string, WprControlInfo> 		{ return this.m_ControlMap;			}
	/** アクション情報マップ */
	public get actionMap(): Map<string, WprActionInfo> 			{ return this.m_ActionMap;			}
	/** ビューモード情報マップ */
	public get viewModeMap(): Map<string, WprBaseViewMode> 		{ return this.m_ViewModeMap;		}
	/** リスト情報マップ */
	public get listInfoMap(): WprListInfoMap 					{ return this.m_ListInfoMap;		}
	/** リストデータマップ */
	public get listDataMap(): Map<string, string> 				{ return this.m_ListInfoMap.getListDataMap();	}
	/** ビュー状態 */
	public get invalid(): boolean 								{ return this.m_InputErrorMsgList != null;		}
	/** エラーコンポーネント情報リスト */
	protected get errorInterList(): IWprError[] 				{ return this.m_ErrorInterList; 				}
	/** サービスエラーコンポーネント情報リスト */
	protected get serviceErrorInterList(): IWprServiceError[] 	{ return this.m_ServiceErrorInterList; 			}
	/** カスタムバリデーションエラー数 */
	public get customValidErrorCount(): number 					{ return this.m_CustomValidErrorCount;			}	public set customValidErrorCount(num: number) { this.m_CustomValidErrorCount = num;		}
	/** バリデーション開始フラグ */
	public get isStartValid(): boolean 							{ return this.m_IsStartValid;					}
	/** デバックログ情報リスト */
	public get debugLogList(): WprDebugLogInfo[] 				{ return this.m_DebugLogList;					}
	/** スクロールイベント使用フラグ */
	public get useScrollEvent(): boolean						{ return this.m_UseScrollEvent;					}
	/** リサイズイベント使用フラグ */
	public get useResizeEvent(): boolean						{ return this.m_UseResizeEvent;					}
	// --------------------------------------------------------------------------

	// abstract メソッド  -------------------------------------------------------
	/**
	 * モデル更新処理
	 * @param name コントロール名称
	 */
	public abstract refreshModel(name?: string): void;

	/**
	 * View(FormControlの値)更新処理
	 * @param name コントロール名称
	 */
	public abstract refreshView(name?: string): void;

	/**
	 * 権限再設定処理
	 * @param name コントロール名
	 */
	public abstract refreshAuth(name?: string): void;

	/**
	 * コントロール値変更処理
	 * @param name コントロール名
	 * @param val 値
	 */
	public abstract changeValue(name: string, val: any): void;

	/**
	 * リストコントロール値変更処理
	 * @param name コントロール名
	 * @param row 行データ
	 * @param val 値
	 */
	public abstract changeListValue(name: string, row: any, val: any): void;

	/**
	 * ビューモード変更処理
	 * @param mode モード情報
	 * @param oldMode 古いモード
	 * @param newMode 新しいモード
	 */
	public abstract changeViewMode(mode: WprBaseViewMode, oldMode: string, newMode: string): void;

	/**
	 * 複数ビューモード変更処理
	 * @param oldMode 変更前モード
	 * @param newMode 変更後モード
	 */
	public abstract changeViewMultiMode(mode: WprBaseViewMultiMode, oldMode: WprMultiMode, newMode: WprMultiMode): void;

	/**
	 * ビューデータ設定処理
	 * @param name ビューデータ名
	 * @param value ビューデータ
	 */
	public abstract setViewData(name: string, value: any): void;

	/**
	 * ビューデータ取得処理
	 * @param name ビューデータ名
	 * @returns ビューデータ
	 */
	public abstract getViewData(name: string): any;

	/**
	 * ビューモード設定処理
	 * @param name: ビューモード名
	 * @param mode モード
	 * @returns falseの場合、モードは変化がない
	 */
	public abstract setViewMode(name: string, mode: string): boolean;

	/**
	 * ビューモード取得処理
	 * @param name ビューモード名
	 * @returns ビューモード
	 */
	public abstract getViewMode(name: string): any;

	/**
	 * 子ビューモード設定処理
	 * @param name: ビューモード名
	 * @param mode モード
	 * @param childName 子ビュー名
	 * @returns falseの場合、モードは変化がない（一部変化がない）
	 */
	public abstract setChildViewMode(name: string, mode: any, childName?: string): boolean;

	/**
	 * 親ビューモード設定処理
	 * @param name: ビューモード名
	 * @param mode モード
	 * @returns falseの場合、モードは変化がない
	 */
	public abstract setParentViewMode(name: string, mode: any): boolean;

	/**
	 * 親ビューモード取得処理
	 * @param name ビューモード名
	 * @returns 
	 */
	public abstract getParentViewMode(name: string): any;

	/**
	 * タブモード設定処理
	 * @param name: タブモード名
	 * @param mode モード
	 * @returns falseの場合、モードは変化がない
	 */
	public abstract setTabMode(name: string, mode: string): boolean;

	/**
	 * タブインデクス取得
	 * @param name: タブモード名
	 * @param mode モード
	 * @returns タブインデクス
	 */
	public abstract getTabIndex(name: string, mode: string): number;

	/**
	 * タブモード取得処理
	 * @param name タブモード名
	 * @returns タブモード
	 */
	public abstract getTabMode(name: string): any;
 
	/**
	 * テーブルソートモード設定処理
	 * @param name: テーブルソートモード名
	 * @param mode モード
	 * @param isAsc 昇順フラグ
	 * @param isNotify 通知フラグ
	 * @returns falseの場合、モードは変化がない
	 */
	 public abstract setTableSortMode(name: string, mode: string, isAsc: boolean, isNotify?: boolean): boolean;

	/**
	 * テーブルソートモード取得処理
	 * @param name テーブルソートモード名
	 * @returns テーブルソートモード
	 */
	public abstract getTableSortMode(name: string): WprSortModeInfo;

	/**
	 * View(FormControlの値)クリア処理
	 * @param name コントロール名称
	 */
	public abstract clearView(name?: string): void;

	/**
	 * スコープモデル設定処理
	 * @param name モデル名称
	 * @param model モデル
	 */
	public abstract setScopeModel(name: string, model: any): void;

	/**
	 * スコープモード設定処理
	 * @param name モード名称
	 * @param mode モード
	 * @returns falseの場合、モードは変化がない
	 */
	public abstract setScopeMode(name: string, mode: any): boolean;

	/**
	 * スコープモード取得処理
	 * @param name スコープモード名
	 * @returns スコープモード
	 */
	public abstract getScopeMode(name: string): any;

	/**
	 * グローバルスコープモデル設定処理
	 * @param name モデル名称
	 * @param model モデル
	 */
	public abstract setGlobalScopeModel(name: string, model: any): void;

	/**
	 * グローバルモード設定処理
	 * @param name モード名称
	 * @param mode モード
	 * @returns falseの場合、モードは変化がない
	 */
	public abstract setGlobalMode(name: string, mode: any): boolean;

	/**
	 * グローバルモード取得処理
	 * @param name モード名
	 * @returns モード
	 */
	public abstract getGlobalMode(name: string): any;

	/**
	 * モードチェック
	 * @param mode モード
	 * @returns trueの場合、モードは含まれている
	 */
	public abstract checkMode(mode: string): boolean;

	/**
	 * サービスエラー通知処理
	 * @param url URL
	 * @param status ステータス
	 * @param message エラーメッセージ 
	 * @param error エラー情報
	 * @param resMethod 結果処理メソッド
	 */
	public abstract onServiceError(url: string, status: string, message: string, error?: Error): void;

	/**
	 * サービスエラー通知処理
	 * @param url URL
	 * @param status ステータス
	 * @param message エラーメッセージ 
	 * @param error エラー情報
	 * @param resMethod 結果処理メソッド
	 */
	public abstract onServiceErrorResult(url: string, status: string, message: string, resMethod?: (result: any) => void): void;

	/**
	 * 次のPathに移動する
	 * @param path 移動するパス
	 * @param params パラメータ情報リスト
	 * @param isValid バリデーション実行フラグ
	 */
	public abstract pushPath(path: string, params?: IWprUrlParam[], isValid?: boolean): void;

	/**
	 * 前のPathに戻る
	 * @param isValid バリデーション実行フラグ
	 */
	public abstract goBack(isValid?: boolean): void;

	/**
	 * urlパラメータ取得
	 * @param name パラメータ名
	 * @returns urlパラメータ
	 */
	 public abstract getUrlParam(name: string): string;

	/**
	 * 質問メッセージ表示処理
	 * @param message　メッセージ 
	 * @param resultMethod 結果通知メソッド
	 */
	public abstract showQuestionMessage(message: string, resultMethod: (result: boolean) => void): void;

	/**
	 * 確認メッセージ表示処理
	 * @param message　メッセージ 
	 * @param confirm　確認フラグ 
	 * @param resultMethod 結果通知メソッド
	 */
	public abstract showInfoMessage(message: string, confirm?: boolean, resultMethod?: (result: boolean) => void): void;

	/**
	 * 警告メッセージ表示処理
	 * @param message　メッセージ 
	 * @param confirm　確認フラグ 
	 * @param resultMethod 結果通知メソッド
	 */
	public abstract showWarningMessage(message: string, confirm?: boolean, resultMethod?: (result: boolean) => void): void;

	/**
	 * エラーメッセージ表示処理
	 * @param message　メッセージ 
	 * @param confirm　確認フラグ 
	 * @param resultMethod 結果通知メソッド
	 */
	public abstract showErrorMessage(message: string, confirm?: boolean, resultMethod?: (result: boolean) => void): void;

	/**
	 * ダイアログ表示処理
	 * @param name ダイアログ名
	 * @param sendData 送信データ
	 * @param resultMethod 結果通知メソッド
	 */
	public abstract showDialog(name: string, sendData?: any, resultMethod?: (status: boolean, result?: any) => void): void;

	/**
	 * ダイアログクローズ処理
	 * @param sutatus 結果
	 * @param resultData 結果データ
	 */
	public abstract closeDialog(name: string, status: boolean, resultData: any): void;

	/**
	 * 子モデル更新処理
	 * @param name 子ビュー名
	 * @returns trueの場合、成功
	 */
	public abstract refreshChildModel(name?: string): boolean;

	/**
	 * 子ビュー再表示処理
	 * @param name 子ビュー名
	 * @returns trueの場合、成功
	 */
	public abstract refreshChildView(name?: string): boolean;

	/**
	 * ビューエラーメッセージ再表示
	 * @param msgList メッセージ情報リスト
	 * @param error エラーインターフェース
	 */
	public abstract refreshViewErrorMessage(msgList: WprValidErrorInfo[], error?: IWprError): void;

	/**
	 * サービスエラーメッセージ再表示
	 * @param msgList メッセージリスト
	 * @param error サービスエラーインターフェース
	 */
	public abstract refreshServiceErrorMessage(msgList: string[], error?: IWprServiceError): void;

	/**
	 * ビューエラーメッセージリスト取得
	 * @param msgList メッセージ情報リスト
	 */
	protected abstract getViewErrorList(msgList: WprValidErrorInfo[]): void;

	/**
	 * ビューエラー数取得
	 * @returns ビューエラー数
	 */
	public abstract getViewErrorCount(): number;

	/**
	 * サービスエラーメッセージリスト取得
	 * @param msgList メッセージリスト
	 */
	public abstract getServiceErrorList(msgList: string[]): void;

	/**
	 * UserAgentレンダ―取得
	 * @returns UserAgentレンダ―
	 */
	public abstract getUserAgentRender(): string;

	/**
	 * 編集開始処理
	 * @param message メッセージ
	 * @param cancelMethod キャンセルメソッド
	 */
	public abstract startEdit(message: string, cancelMethod?: (name: string, parent: string) => void): boolean;

	/**
	 * 編集終了処理
	 * @returns falseの場合、編集中ではない
	 */
	public abstract endEdit(): boolean;

	/**
	 * 編集キャンセル処理
	 * @returns falseの場合、編集中ではない
	 */
	public abstract cancelEdit(): boolean;
	// --------------------------------------------------------------------------

	// virtual メソッド  ---------------------------------------------------------
	/**
	 * コントロール定義設定
	 */
	public onRegisterConfig(): void {}

	/**
	 * アクション登録
	 */
	public onRegisterAction(): void {}

	/**
	 * ビュー表示通知
	 */
	public onShowView(): void {}

	/**
	 * 値変更通知
	 * @param name 名前
	 * @param value 値
	 * @returns falseの場合、変更を取り消す
	 */
	public onChangeValue(name: string, value: any): boolean {
		return true;
	}

	/**
	 * リスト値変更通知
	 * @param name 名前
	 * @param listName リスト名
	 * @param row 行データ
	 * @param value 値
	 * @returns falseの場合、変更を取り消す
	 */
	public onChangeListValue(name: string, listName: string, row: any, value: any): boolean {
		return true;
	}

	/**
	 * リスト選択変更通知
	 * @param listName 名前
	 * @param row 選択行データ
	 * @param idx 選択行インデクス
	 * @returns falseの場合、変更を取り消す
	 */
	 public onChangeListSelection(listName: string, row: any, idx: number): boolean {
		return true;
	}

	/**
	 * ビューモード変更通知
	 * @param mode モード情報
	 * @param oldMode 古いモード
	 * @param newMode 新しいモード
	 */
	public onChangeViewMode(mode: WprBaseViewMode, oldMode: string, newMode: string): void {}

	/**
	 * 複数ビューモード変更通知
	 * @param oldMode 変更前モード
	 * @param newMode 変更後モード
	 */
	public onChangeViewMultiMode(mode: WprBaseViewMultiMode, oldMode: WprMultiMode, newMode: WprMultiMode): void {}

	/**
	 * タブモード変更通知
	 * @param mode タブモード
	 * @param oldLabel 古いラベル
	 * @param newLabel 新しいラベル
	 */
	public onChangeTabMode(mode: string, oldLabel: string, newLabel: string): void {}

	/**
	 * アコーディオンモード変更通知
	 * @param mode アコーディオンモード
	 * @param oldLabel 古いラベル
	 * @param newLabel 新しいラベル
	 */
	public onChangeAccordionMode(mode: string, oldName: string, newName: string): void {}

	/**
	 * テーブルソート変更通知
	 * @param listName リスト名
	 * @param item ソートアイテム
	 * @param subModelList サブモデルリスト
	 * @param isAsc 昇順フラグ
	 */
	public onChangeTableSort(listName: string, item: string, subModelList: string[], isAsc: boolean): void {
		this.sortListData(listName, item, subModelList, isAsc);
	}

	/**
	 * ページング変更通知
	 * @param listName リスト名
	 * @param page ページ番号
	 * @param rowsPerPage ページ内行数
	 */
	public onChangeTablePage(listName: string, page: number, rowsPerPage: number): void {
	}

	/**
	 * ValueMap再設定処理
	 * @param name コントロール名
	 * @param id ValueMapID
	 * @list ValueMapのKey-Valueリスト
	 * @returns 再設定したKey-Valueリスト
	 */
	public onResetValueMap(name: string, id: string, list: WprKeyValue[]): WprKeyValue[] {
		return list;
	}

	/**
	 * ValueMap再設定処理(リスト用)
	 * @param name コントロール名
	 * @param id ValueMapID
	 * @param row 行データ
	 * @param list ValueMapのKey-Valueリスト
	 * @returns 再設定したKey-Valueリスト
	 */
	public onResetListValueMap(name: string, id: string, row: WprRowInfo, list: WprKeyValue[]): WprKeyValue[] {
		return list;
	}

	/**
	 * 入力チェック（独自のValidationを実施する場合に使用する）
	 *   エラー追加例：WprValidError.addError('エラーメッセージ');
	 * 				  this.setControlError('control', 'error');
	 * @param actionName アクション名
	 * @param validError バリデーションエラー情報
	 * @param row 行情報
	 */
	protected onValid(actionName: string, validError: WprValidError, row?: any): void {
	}

	/**
	 * バリデーションエラー通知
	 * @param actionName アクション名
	 * @param validError バリデーションエラー情報
	 * @param row 行情報
	 * @バリデーションエラー情報
	 */
	protected onValidError(actionName: string, validError: WprValidError, row?: any): void {
	}

	/**
	 * バリデーションエラーメッセージ通知
	 * @param name コントロール名
	 * @param rowIndex 行数(Listの場合)
	 * @param validatorName バリデーション名
	 * @param msg エラーメッセージ
	 * @returns エラーメッセージ（変更する場合に指定する、メッセージを使用しない場合は、nullを返す）
	 */
	public onInvalidMessage(name: string, rowIndex: number, validatorName: string, msg: string): string {
		return msg;
	}
	
	/**
	 * クリア時にvalueに設定する値を取得する
	 * @param name コントロール名称
	 * @param value 値
	 * @returns クリア時にvalueに設定する値
	 */
	public onGetClearValue(name: string, value: any): any {
		return '';
	}

	/**
	 * リストクリア時にvalueに設定する値を取得する
	 * @param name コントロール名称
	 * @param row 行データ
	 * @param value 値
	 * @returns クリア時にvalueに設定する値
	 */
	public onGetListClearValue(name: string, listName: string, row: any, value: any): any {
		return '';
	}

	/**
	 * ロストフォーカス通知
	 * @param name コントロール名称
	 * @param value コントロール値
	 * @param isChange 値変更フラグ
	 * @returns コントロール値（コントロール値を変更しない場合は、valueをそのまま返す）
	 */
	public onBlur(name: string, value: any, isChange?: boolean): any {
		return value;
	}

	/**
	 * データ受信処理
	 * @param dataName データ名
	 * @param data データ
	 */
	public onReceiveData(dataName: string, data?: any): void {
	}

	/**
	 * ビュー表示終了処理
	 */
	public onEndShowView() {
	}

	/**
	 * レンダー終了通知
	 */
	public onEndRender() {
	}

	/**
	 * 子ビュー入力チェック処理
	 * @param name アクション名又はコントロール名
	 * @param isView メインビューフラグ
	 * @param isControl コントロールチェックフラグ
	 * @param isEdit 編集フラグ
	 * @param row 行情報
	 * @returns falseの場合入力エラー
	 */
	public checkChildValidate(name: string, isView: boolean = false, isControl: boolean, isEdit: boolean, row?: any): boolean {
		 return true;
	 }

	/**
	 * 行データ設定通知
	 * @param data 行データ
	 */
	public onSetRowData(data: any): void {
	}

	/**
	 * ウインドウリサイズ通知
	 * @param width 幅
	 * @param height 高さ
	 */
	public onResizeWindow(width: number, height: number): void {
		this.m_UseResizeEvent = false;
	}

	/**
	 * ウインドウスクロール通知
	 * @param pageXOffset Xオフセット
	 * @param pageYOffset Yオフセット
	 */
	public onScrollWindow(pageXOffset: number, pageYOffset: number): void {
		this.m_UseScrollEvent = false;
	}

	/**
	 * コントロール権限設定
	 * @param name コントロール名
	 * @param authSetting 設定する権限情報
	 */
	public onSetAuth(name: string, authSetting: WprControlAuthInfo): void {
	}

	/**
	 * 基本クラス用コントロール定義設定
	 */
	public onBaseRegisterConfig(): void {}

	/**
	 * 基本クラス用アクション登録
	 */
	public onBaseRegisterAction(): void {}
 
	/**
	 * 基本クラス用ビュー表示通知
	 */
	public onBaseShowView(): void {}

	/**
	 * ステータス更新ロック開始
	 */
	public startStateLock(): void {
	}

	/**
	 * ステータス更新ロック終了
	 */
	public endStateLock(): void {
	}

	/**
	 * ステータス更新ロックキャンセル
	 */
	public cancelStateLock(): void {
	}

	/**
	 * デバック情報ログ出力
	 * @param dmsg デバックメッセージ
	 * @param lmsg ログメッセージ
	 */
	public addDebugInfoLog(dmsg: string, lmsg: string = null): void {
		if (lmsg !== null)
			this.addInfoLog(lmsg);
		this.addDebugLogMessage(WprLogLevel.INFO, dmsg);
	}

	/**
	 * 終了処理
	 */
	public terminate(): void {
		this.m_ViewModeMap.forEach(minfo => {
			minfo.terminate();
		});
		this.m_ControlMap.clear();
		this.m_ActionMap.clear();
		this.m_ModelActionMap.clear();
		this.m_GlobalModelActionMap.clear();
		this.m_ModelMap = null;
//		this.m_ListInfoMap = null;
		this.m_ViewModeMap.clear();
		this.m_InputErrorMsgList = null;
		this.m_ServiceErrorInterList = null;
		this.m_ErrorInterList = new Array();
		this.m_ExtentionMap.clear();
		this.m_CustomCompMap.clear();
		this.m_ReserveViewModeList = new Array();
		this.m_ControlErrorMap = null;
		this.m_DebugLogList = new Array();
	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * リストコントロール情報取得
	 * @param listName リスト名
	 * @param controlName コントロール名
	 * @param idx リストインデクス
	 */
	public getListControlInfo(listName: string, controlName: string, idx: number): WprControlInfo {
		return this.m_ListInfoMap.getListControlInfo(listName, controlName, idx);
	}

	/**
	 * エラーログ出力
	 * @param msg メッセージ
	 * @param ex 例外情報
	 */
	public addErrorLog(msg: string, ex?: Error): void {
		super.addErrorLog(msg, ex);
		this.addDebugLogMessage(WprLogLevel.ERROR, msg);
	}

	/**
	 * WprDI_Objectの設定処理
	 * @param name WprDI_Objectメンバ名
	 * @param obj 設定値
	 */
	protected setDIObject(name: string, obj: any) {
		if (obj == null)
			this.addDebugInfoLog(`setDIObject('${name}', null)`);
		else
			this.addDebugInfoLog(`setDIObject('${name}')`);
	}
	/**
	 * WprDI_Controlの設定処理
	 * @param name WprDI_Controlメンバ名
	 * @param cinfo コントロール情報
	 */
	protected setDIControl(name: string, cinfo: WprControlInfo) {
		this.addDebugInfoLog(`setDIControl('${name}')`);
	}
	// --------------------------------------------------------------------------

	// public メソッド  ---------------------------------------------------------
	/**
	 * コントロール定義登録処理
	 */
	public registerConfig(): void {
		try {
			const map = this.listDataMap;
			this.listDataMap.forEach((member, key, map) => {
				this.m_ListInfoMap.addListInfo(key);
			});
			this.onBaseRegisterConfig();
			this.onRegisterConfig();
		}
		catch (ex) {
			this.addErrorLog('onRegisterConfigで例外が発生しました。', ex);
		}
	}

	/**
	 * アクション登録処理
	 */
	public registerAction(): void {
		try {
			this.onBaseRegisterAction();
			this.onRegisterAction();
		}
		catch (ex) {
			this.addErrorLog('onRegisterActionで例外が発生しました。', ex);
		}
	}

	/**
	 * コントロール定義追加
	 * @param name 名前
	 * @param description 説明
	 * @param modelName モデル名
	 * @param option オプション
	 */
	public addConfig(name: string, description: string, modelName: string, option?: IWprConfigOption): void {
		if (this.m_ControlMap.has(name) === false) {
			const cinfo = new WprControlInfo(this, name, description, modelName, option, undefined);
			cinfo.isLayout = false;
			if (cinfo.modelName != null && this.getModelPropertyName(cinfo.modelName) == null && this.viewInfo.getModelPropertyName(cinfo.modelName) == null)
				this.addErrorLog(`コントロール定義のモデル名の指定に誤りがあります。[${name}][${cinfo.modelName}]`);
			this.m_ControlMap.set(name, cinfo);
		}
		else {
			this.addErrorLog(`同一のコントロール定義が既に登録されています。[${name}]`);
		}
	}

	/**
	 * レイアウトコントロール定義追加
	 * @param name 名前
	 * @param description 説明
	 * @param modelName モデル名
	 * @param option オプション
	 */
	 public addLayoutConfig(name: string, description: string, option?: IWprLayoutConfigOption): void {
		if (this.m_ControlMap.has(name) === false) {
			const cinfo = new WprControlInfo(this, name, description, null, undefined, option);
			cinfo.isLayout = true;
			this.m_ControlMap.set(name, cinfo);
		}
		else {
			this.addErrorLog(`同一のコントロール定義が既に登録されています。[${name}]`);
		}
	}

	/**
	 * アクション登録処理
	 * @param name アクション名
	 * @param actionMethod アクションメソッド
	 * @param isValid バリデーション実行フラグ
	 * @param isChildValid 子ビューバリデーション実行フラグ
	 * @param isEvent イベント情報使用フラグ
	 */
	protected addAction(name: string, actionMethod: (param: any) => void, isValid: boolean = false, isChildValid: boolean = false, isEvent: boolean = false) {
		if (this.m_ActionMap.has(name) === false) {
			const ainfo = new WprActionInfo(name, this, actionMethod, isValid,　isChildValid, isEvent);
			this.m_ActionMap.set(name, ainfo);
			if (this.m_ControlMap.has(name) === false) {
				this.addConfig(name, name, null);
				this.m_ControlMap.get(name).isLayout = null;	// この時点では不定
			}
		}
		else {
			this.addErrorLog(`同一のアクションが既に登録されています。[${name}]`);
		}
	}

	/**
	 * ロストフォーカスアクション登録処理
	 * @param name アクション名
	 * @param actionMethod リストアクションメソッド
	 * @param isValid バリデーション実行フラグ
	 * @param isChildValid 子ビューバリデーション実行フラグ
	 * @param isEvent イベント情報使用フラグ
	 */
	protected addBlurAction(name: string, actionMethod: (param: any) => void, isValid: boolean = false, isChildValid: boolean = false, isEvent: boolean = false): void {
		const aname = `${name}@@blur`;
		if (this.m_ActionMap.has(aname) === false) {
			const ainfo = new WprActionInfo(name, this, actionMethod, isValid, isChildValid, isEvent);
			ainfo.isBlur = true;
			this.m_ActionMap.set(aname, ainfo);
		}
		else {
			this.addErrorLog(`同一のBlurアクションが既に登録されています。[${name}]`);
		}
	}

	/**
	 * バリデーション実行アクション登録処理
	 * @param name アクション名
	 * @param isChildValid 子ビューバリデーション実行フラグ
	 */
	protected addValidAction(name: string, isChildValid: boolean = false): void {
		if (this.m_ActionMap.has(name) === false) {
			const ainfo = new WprActionInfo(name, this, null, true, isChildValid, false);
			this.m_ActionMap.set(name, ainfo);
		}
		else {
			this.addErrorLog(`同一のバリデーション実行アクションが既に登録されています。[${name}]`);
		}
	}

	/**
	 * スコープモデル更新アクション登録処理
	 * @param modelName スコープモデル名
	 * @param actionMethod アクションメソッド
	 * @param propName プロパティ名
	 */
	protected addModelAction(modelName: string, actionMethod: (modelName: string, model: any, propName: string) => void, propName?: string): void {
		let mname = modelName;
		if (propName)
			mname = `${modelName}@${propName}`;
		if (this.m_ModelActionMap.has(mname) === false) {
			// if (this.getModelPropertyName(modelName) == null && this.viewInfo.getModelPropertyName(modelName) == null)
			// this.addErrorLog(`addModelActionのモデル名の指定に誤りがあります。[${modelName}]`);
			const ainfo = new WprModelActionInfo(modelName, actionMethod, propName);
			this.m_ModelActionMap.set(mname, ainfo);
			if (propName)
				this.viewInfo.setScopeModelUpdateProperty(this, modelName, propName);
		}
		else {
			this.addErrorLog(`同一のモデルアクションが既に登録されています。[${mname}]`);
		}
	}

	/**
	 * スコープモデル更新アクション登録処理
	 * @param modelName スコープモデル名
	 * @param actionMethod アクションメソッド
	 * @param propName プロパティ名
	 */
	protected addGlobalModelAction(modelName: string, actionMethod: (modelName: string, model: any, propName: string) => void, propName?: string) {
		if (this.m_GlobalModelActionMap.has(modelName) === false) {
			const ainfo = new WprModelActionInfo(modelName, actionMethod, propName);
			if (propName) {
				this.m_GlobalModelActionMap.set(`${modelName}@${propName}`, ainfo);
				this.viewInfo.setGlobalScopeModelUpdateProperty(this, modelName, propName);
			}
			else {
				this.m_GlobalModelActionMap.set(modelName, ainfo);
			}
		}
	}

	/**
	 * イベントアクション取得
	 * @param name コントロール名
	 * @param eventName イベント名
	 * @param actionMethod アクションメソッド
	 * @param isConfig コントロール定義フラグ（trueの場合は、コントロール定義が必要）
	 */
	protected addEventAction(name: string, eventName: WprEventName, actionMethod: (name: any, event: any) => void, isConfig: boolean=true): void {
		let ainfo = null;
		if (this.m_EventActionMap.has(name) === true) {
			ainfo = this.m_EventActionMap.get(name);
		}
		else {
			if (isConfig === false || this.m_ControlMap.has(name) === true) {
				ainfo = new WprEventActionInfo(name, this);
				this.m_EventActionMap.set(name, ainfo);
			}
			else {
				this.addErrorLog(`addEventAction:コントロール定義が見つかりません。[${name}]`);
			}
		}
		if (ainfo != null)
			ainfo.addEventMethod(eventName, actionMethod);
	}

	/**
	 * イベント情報取得
	 * @param name コントロール名
	 * @param name 行データ
	 * @param events イベント情報
	 * @returns イベント情報
	 */
	public getEventsInfo(name: string, rowData?: WprRowInfo, events?: any): any {
		if (rowData) {
			const linfo = this.getListInfo(rowData.listName);
			if (linfo) {
				events = linfo.getEventsInfo(name, events);
				events['data-row'] = String(rowData.index);
			}
			return events;
		}
		else {
			if (this.m_EventActionMap.has(name) === true) {
				const ainfo = this.m_EventActionMap.get(name);
				return ainfo.getEvents(events);
			}
			else {
				if (events === undefined)
					this.addErrorLog(`getEventsInfo:コントロール名に誤りがあります。[${name}]`);
				else
					return events;
			}
		}
		return null;
	}

	/**
	 * モデルプロパティ名取得
	 * @param modelName
	 */
	public getModelPropertyName(modelName: string): string {
		if (this.modelMap) {
			if (this.modelMap.has(modelName) === true)
				return this.modelMap.get(modelName);
		}
		return null;
	}

	/**
	 * モデル情報追加処理
	 * @param modelName モデル名称
	 * @param propName プロパティ名
	 */
	public addModelInfo(modelName: string, propName: string): void {
		if (this.m_ModelMap === undefined)
			this.m_ModelMap = new Map<string, string>();
		if (this.m_ModelMap.has(modelName) === false)
			this.m_ModelMap.set(modelName, propName);
		else
			this.addErrorLog(`同一の@WprModelが既に登録されています。[${modelName}]`);
	}

	/**
	 * コントロール情報取得処理
	 * @param name コントロール名
	 * @param listName リスト名
	 * @param index 行インデクス
	 * @returns コントロール情報
	 */
	public getControlInfo(name: string, listName?: string, index?: number): WprControlInfo {
		if (listName) 
			return this.m_ListInfoMap.getListControlInfo(listName, name, index);
		else if (this.m_ControlMap.has(name) === true)
			return this.m_ControlMap.get(name);
		return null;
	}

	/**
	 * カテゴリコントロールリスト取得
	 * @param category カテゴリ名
	 * @param listName リスト名
	 * @param index インデクス
	 * @returns カテゴリコントロールリスト
	 */
	public getCategoryControlList(category: string, listName?: string, index?: number): WprControlInfo[] {
		const list = new Array();
		if (listName) {
			return this.m_ListInfoMap.getListCategoryControlList(listName, category, index);
		}
		else {
			this.m_ControlMap.forEach(cinfo => {
				if (cinfo.config.categoryList) {
					if (cinfo.config.categoryList.includes(category) === true)
						list.push(cinfo);
				}
			});
		}
		return list;
	}

	/**
	 * アクション情報取得
	 * @param controlName コントロール名
	 * @param listName リスト名
	 * @return アクション情報
	 */
	public getActionInfo(controlName: string, listName?: string): WprActionInfo {
		let aname = controlName;
		if (listName)
			aname = controlName + '@' + listName;
		if (this.m_ActionMap.has(aname) === true) {
			const action = this.m_ActionMap.get(aname);
			if (listName && action.isList === false)
				return null;
			return action;
		}
		return null;
	}

	/**
	 * アクションチェック処理
	 * @param name アクション名
	 * @param listName リスト名
	 * @returns falseの場合はアクションは登録されていない
	 */
	public checkAction(name: string, listName?: string): boolean {
		let aname = name;
		if (listName)
			aname = name + '@' + listName;
		return this.m_ActionMap.has(aname);
	}

	/**
	 * 入力チェック処理
	 * @param name アクション名又はコントロール名
	 * @param isView メインビューフラグ
	 * @param isControl コントロールチェックフラグ
	 * @param isEdit 編集フラグ
	 * @param row 行情報
	 * @returns falseの場合入力エラー
	 */
	public checkValidate(name: string, isView: boolean = false, isControl: boolean = true, isEdit: boolean = false, row?: any): boolean {
		try {
			this.m_IsStartValid = true;
			this.m_InputErrorMsgList = null;
			const validError = new WprValidError(this);
			this.m_ControlErrorMap.clearControlError(this.viewInfo, isControl, name);
			this.m_ControlErrorMap.startCheck();
			if (row) {
				this.listInfoMap.checkValidate(name, validError, isControl, row);
			}
			else if (isView === false) {
				this.controlMap.forEach((cinfo, key, map) => {
					cinfo.checkValidate(name, validError, isControl, isEdit);
				});
				this.listInfoMap.checkValidate(name, validError, isControl, row);
			}
			else {
				this.viewInfo.controlMap.forEach((cinfo, key, map) => {
					cinfo.checkValidate(name, validError, isControl, isEdit);
				});
				this.listInfoMap.checkValidate(name, validError, isControl, row);
				for (const subView of this.viewInfo.subViewMap.values()) {
					subView.controlMap.forEach((cinfo, key, map) => {
						cinfo.checkValidate(name, validError, isControl, isEdit);
					});
				}
			}
			try {
				if (this.m_CustomCompMap) {
					this.m_CustomCompMap.forEach((customComp, key, map) => {
						customComp.onValid(name, validError, row);
					});
				}
				this.onValid(name, validError, row);
			}
			catch (ex) {
				this.addErrorLog('onValidで例外が発生しました。', ex);
			}
			if (validError.isError === true) {
				try {
					this.onValidError(name, validError, row);
				}
				catch (ex) {
					this.addErrorLog('onValidErrorで例外が発生しました。', ex);
				}
				if (isControl === false)
					this.m_InputErrorMsgList = validError.errorInfoList;
			}
			return !validError.isError;
		}
		catch (ex) {
			this.addErrorLog('入力チェック処理で例外が発生しました。', ex);
			return false;
		}
		finally {
			this.m_IsStartValid = false;
			this.m_ControlErrorMap.endCheck();
			this.refreshViewErrorList();
		}
	}

	/**
	 * コントロール入力チェック
	 * @param name コントロール名
	 * @returns falseの場合入力エラー
	 */
	public checkControlValidate(name: string): boolean {
		return this.checkValidate(name, true, true, true);
	}

	/**
	 * リスト項目入力チェック
	 * @param name コントロール名
	 * @param row 行情報
	 * @returns falseの場合入力エラー
	 */
	public checkListValidate(name: string, row?: any): boolean {
		return this.checkValidate(name, true, true, true, row);
	}

	/**
	 * ビューモード情報初期化
	 */
	public initViewModeInfo() {
		this.FW.model.getDecoNameList('ViewMode', this).forEach((mname) => {
			const viewMode = WprReflectUtil.getProperty(this, mname) as WprBaseViewMode;
			if (viewMode) {
				if (this.m_ViewModeMap.has(viewMode.name) === false) {
					viewMode.setViewCore(this);
					this.m_ViewModeMap.set(viewMode.name, viewMode);
				}
				else {
					this.addErrorLog(`同一の@WprViewModeが既に登録されています。[${viewMode.name}][${viewMode.objectName}]`);
				}
			}
		});
	}

	/**
	 * ビューモード変更処理
	 * @param mode モード情報
	 * @param oldMode 古いモード
	 * @param newMode 新しいモード
	 */
	public changeMode(mode: WprBaseViewMode, oldMode: string, newMode: string): void {
		try {
			this.onChangeViewMode(mode, oldMode, newMode);
		}
		catch (ex) {
			this.addErrorLog('onChangeViewModeで例外が発生しました。', ex);
		}
		this.m_ExtentionMap.forEach((einfo, key, map) => {
			try {
				einfo.onChangeViewMode(mode, oldMode, newMode);
			}
			catch (ex) {
				einfo.addErrorLog('onChangeViewModeで例外が発生しました。', ex);
			}
		});
	}

	/**
	 * 複数ビューモード変更処理
	 * @param oldMode 変更前モード
	 * @param newMode 変更後モード
	 */
	public changeMultiMode(mode: WprBaseViewMultiMode, oldMode: WprMultiMode, newMode: WprMultiMode): void {
		try {
			this.onChangeViewMultiMode(mode, oldMode, newMode);
		}
		catch (ex) {
			this.addErrorLog('onChangeViewMultiModeで例外が発生しました。', ex);
		}
		this.m_ExtentionMap.forEach((einfo, key, map) => {
			try {
				einfo.onChangeViewMultiMode(mode, oldMode, newMode);
			}
			catch (ex) {
				einfo.addErrorLog('onChangeViewMultiModeで例外が発生しました。', ex);
			}
		});
	}

	/**
	 * モードコントロール値変更処理
	 * @param name コントロール名
	 * @param val 値
	 */
	public changeModeValue(name: string, val: any): void {
		this.m_ViewModeMap.forEach((minfo, key, map) => {
			try {
				minfo.onChangeValue(name);
			}
			catch (ex) {
				minfo.addErrorLog('onChangeValueで例外が発生しました。', ex);
			}
		});
	}

	/**
	 * 拡張機能コントロール値変更処理
	 * @param name コントロール名
	 * @param val 値
	 */
	public changeExtentionValue(name: string, val: any): void {
		this.m_ExtentionMap.forEach((einfo, key, map) => {
			try {
				einfo.onChangeValue(name, val);
			}
			catch (ex) {
				einfo.addErrorLog('onChangeValueで例外が発生しました。', ex);
			}
		});
	}

	/**
	 * ビューモード更新処理
	 */
	public updateMode(isClear?: boolean): void {
		this.m_ViewModeMap.forEach((minfo, key, map) => {
			try {
				minfo.updateMode(isClear);
			}
			catch (ex) {
				minfo.addErrorLog('updateModeで例外が発生しました。', ex);
			}
		});
	}

	/**
	 * コントロールモード更新処理
	 * @param name コントロール名
	 */
	public updateControlMode(name: string): void {
		this.m_ViewModeMap.forEach((minfo, key, map) => {
			try {
				minfo.updateControlMode(name);
			}
			catch (ex) {
				minfo.addErrorLog('updateControlModeで例外が発生しました。', ex);
			}
		});
	}

	/**
	 * 入力エラー状態取得
	 * @return trueの場合、入力エラー
	 */
	public getInputError(): boolean {
		return (this.m_InputErrorMsgList != null);
	}

	/**
	 * 入力エラーメッセージ取得
	 * @return 入力エラーメッセージ
	 */
	/** 入力エラーメッセージ */
	public getInputErrorMsgList(): WprValidErrorInfo[] {
		return this.m_InputErrorMsgList;
	}

	/**
	 * エラー情報登録処理
	 * @param error エラーインターフェース
	 * @param subView サブビュー名
	 */
	public addErrorControl(error: IWprError, subView?: string): void {
		if (this.m_ErrorInterList.includes(error) === false)
			this.m_ErrorInterList.push(error);
		this.refreshViewErrorList(error);
	}

	/**
	 * エラー情報削除処理
	 * @param error エラーインターフェース
	 * @param subView サブビュー名
	 */
	public deleteErrorControl(error: IWprError, subView?: string): void {
		if (this.m_ErrorInterList.includes(error) === true) {
			const idx = this.m_ErrorInterList.indexOf(error);
			this.m_ErrorInterList.splice(idx, 1);
		}
	}


	/**
	 * サービスエラー情報登録処理
	 * @param error サービスエラーインターフェース
	 * @param subView サブビュー名
	 */
	public addServiceErrorControl(error: IWprServiceError, subView?: string): void {
		if (this.m_ServiceErrorInterList.includes(error) === false)
			this.m_ServiceErrorInterList.push(error);
		this.refreshServiceErrorList(error);
	}

	/**
	 * サービスエラー情報削除処理
	 * @param error サービスエラーインターフェース
	 * @param subView サブビュー名
	 */
	public deleteServiceErrorControl(error: IWprServiceError, subView?: string): void {
		if (this.m_ServiceErrorInterList != null) {
			if (this.m_ServiceErrorInterList.includes(error) === true) {
				const idx = this.m_ServiceErrorInterList.indexOf(error);
				this.m_ServiceErrorInterList.splice(idx, 1);
			}
		}
	}

	/**
	 * 入力エラーメッセージ追加
	 * @param list 入力エラーメッセージ
	 */
	public setInputErrorList(list: WprValidErrorInfo[]) {
		this.m_InputErrorMsgList = list;
		this.refreshViewErrorList();
	}

	/**
	 * コントロールエラー設定
	 * @param name コントロール名
	 * @param err エラー名
	 * @param errFlg エラーフラグ
	 * @param listName リスト名
	 * @param index 行インデクス
	 */
	public setControlError(name: string, err: string, listName?: string, index?: number) {
		this.m_ControlErrorMap.addError(name);
//		console.log(`*** setControlError name=[${name}] err=[${err}]`);
		const cinfo = this.viewInfo.getControlInfo(name, listName, index);
		if (cinfo) {
//			console.log(`    Before : cinfo=[${cinfo.validError}][${cinfo.errorMessageList.length}]`);
			this.m_CustomValidErrorCount++;
			cinfo.setValidError(name, err);
//			cinfo.setErrorMessage(err);
			this.refreshViewErrorList();
//			console.log(`    After : cinfo=[${cinfo.validError}][${cinfo.errorMessageList.length}]`);
		}
		else {
			this.addErrorLog(`setControlError：コントロール名に誤りがあります。[${name}]`);
		}
	}

	/**
	 * ビュー拡張機能初期化
	 */
	public initExtentionInfo() {
		this.FW.model.getDecoNameList('Extention', this).forEach((ename) => {
			const extention = WprReflectUtil.getProperty(this, ename) as WprBaseExtention;
			if (extention) {
				if (this.m_ExtentionMap.has(extention.name) === false) {
					extention.setViewCore(this);
					this.m_ExtentionMap.set(extention.name, extention);
				}
				else {
					this.addErrorLog(`同一の@Extentionが既に登録されています。[${extention.name}][${extention.objectName}]`);
				}
			}
		});
	}

	/**
	 * モデル更新通知処理
	 * @param name モデル名
	 * @param model モデル
	 * @param isGlobal グローバルフラグ
	 * @param prop プロパティ名
	 */
	public updateModel(name: string, model: any, isGlobal: boolean, prop?: string): void {
		try {
			if (prop)
				name = `${name}@${prop}`;
			if (isGlobal === false) {
				if (this.m_ModelActionMap.has(name) === true)
					this.m_ModelActionMap.get(name).execute(this, model);
			}
			else {
				if (this.m_GlobalModelActionMap.has(name) === true)
					this.m_GlobalModelActionMap.get(name).execute(this, model);
			}
		}
		catch (ex) {
			this.addErrorLog(`モデル更新アクションの実行で例外が発生しました。[${name}]`, ex);
		}
	}

	/**
	 * ビュー表示モード処理
	 */
	 public showMode(): void {
		this.m_ViewModeMap.forEach((minfo, key, map) => {
			try {
				minfo.onShowView();
			}
			catch (ex) {
				minfo.addErrorLog('onShowModeで例外が発生しました。', ex);
			}
		});
	}

	/**
	 * ビューモード名チェック処理
	 * @param viewMode ビューモード名
	 * @returns tureの場合、ビューモードが含まれている
	 */
	public checkViewModeName(viewMode: string): boolean {
		for(const minfo of this.m_ViewModeMap.values()) {
			if (minfo.name === viewMode)
				return true;
		}
		return false;
	}

	/**
	 * ビューモードチェック処理
	 * @param viewMode ビューモード名
	 * @param mode モード
	 * @returns tureの場合、モードが含まれている
	 */
	public checkViewMode(viewMode: string, mode: string): boolean {
		for(const minfo of this.m_ViewModeMap.values()) {
			if (!viewMode || minfo.name === viewMode) {
				if (minfo.containsMode(mode) === true)
					return true;
			}
		}
		return false;
	}

	/**
	 * モードリストデータ設定
	 * @param listName リスト名
	 * @param listData リストデータ
	 */
	public setViewModeListData(listName: string, listData: Array<any>) {
		this.viewModeMap.forEach((mode, key, map) => {
			if (mode.listName === listName) {
				const tmode = mode as WprBaseViewListMode<any, any>;
				tmode.setListData(listData);
			}
		});
	}

	/**
	 * ValueMap登録
	 * @param id ValueMapのID
	 * @param vm ValueMap
	 */
	public addValueMap(id: string, vm: WprValueMap): void {
		this.FW.model.addValueMap(id, vm);
	}

	/**
	 * ValueMap登録チェック処理
	 * @param id ValueMapのID
	 * @returns trueの場合、ValueMapは登録済み
	 */
	public hasValueMap(id: string): boolean {
		return this.FW.model.hasValueMap(id);
	}
	
	/**
	 * Blurイベント停止処理
	 */
	public stopBlurEvent(): void {
		this.FW.view.stopBlurEvent();
	}

	/**
	 * Blurイベント再開処理
	 */
	public restartBlurEvent(): void {
		this.FW.view.restartBlurEvent();
	}

	/**
	 * カスタムコンポーネント登録処理
	 * @param name 名前
	 * @param comp カスタムコンポーネント
	 */
	public addCustomComponent(name: string, comp: WprBaseCustomComponent<any, any>): void {
		if (this.m_CustomCompMap.has(name) === true)
			this.addErrorLog(`カスタムコンポーネントが重複しています。[${name}]`);
		// else
			this.m_CustomCompMap.set(name, comp);
	}

	/**
	 * カスタムコンポーネントデータ設定
	 * @param name カスタムコンポーネント
	 * @param data データ
	 */
	protected setCustomComponentData(name: string, data: object) {
		if (this.m_CustomCompMap.has(name) === false)
			this.addErrorLog(`カスタムコンポーネントが登録されていません。[${name}]`);
		else
			this.m_CustomCompMap.get(name).setData(data);
	}

	/**
	 * カスタムコンポーネント値取得
	 * @param name カスタムコンポーネント
	 * @returns 値
	 */
	protected getCustomComponentValue(name: string): any {
		if (this.m_CustomCompMap.has(name) === false) {
			this.addErrorLog(`カスタムコンポーネントが登録されていません。[${name}]`);
			return null;
		}
		else 
			return this.m_CustomCompMap.get(name).getValue();
	}

	/**
	 * フォーカス設定処理
	 * @param 名称
	 * @returns trueの場合、成功
	 */
	public setFocus(name: string): boolean {
		if (this.m_ControlMap.has(name) === true) {
			this.m_ControlMap.get(name).setFocus();
			return true;
		}
		this.addErrorLog(`setFocus：コントロール名に誤りがあります。[${name}]`);
		return false;
	}

	/**
	 * アクション実行処理
	 * @param name アクション名
	 * @returns trueの場合、成功
	 */
	protected executeAction(name: string): boolean {
		const ainfo = this.getActionInfo(name);
		if (ainfo)
			return ainfo.execute(true, null);
		this.addErrorLog(`executeAction：アクション名に誤りがあります。[${name}]`);
		return false;
	}

	/**
	 * ビューエラーメッセージリスト設定
	 * @param msgList メッセージ情報リスト
	 */
	public setViewErrorList(msgList: WprValidErrorInfo[]): void {
		if (this.m_InputErrorMsgList != null) {
			for (const info of this.m_InputErrorMsgList)
				msgList.push(info);
		}
		this.controlMap.forEach((cinfo, key, map) => {
			if (cinfo.validError != null) {
				for (const info of cinfo.validError.errorInfoList) {
					if (msgList.includes(info) === false)
						msgList.push(info);
				}
			}
		});
		this.listInfoMap.getValidErrorList().forEach(validError => {
			for (const info of validError.errorInfoList) {
				if (msgList.includes(info) === false)
					msgList.push(info);
			}
		});
	}

	/**
	 * サービスエラー追加
	 * @param msg エラーメッセージ
	 */
	public addServiceError(msg: string) {
		const list = this.m_ServiceErrorMsgList;
		this.m_ServiceErrorMsgList = new Array();
		for(const msg of list)
			this.m_ServiceErrorMsgList.push(msg);
		this.m_ServiceErrorMsgList.push(msg);
		this.refreshServiceErrorMessage(this.m_ServiceErrorMsgList);
	}


	/**
	 * サービスエラークリア
	 */
	public clearServiceError() {
		this.m_ServiceErrorMsgList = new Array();
		this.refreshServiceErrorMessage(this.m_ServiceErrorMsgList);
	}

	/**
	 * サービスエラーメッセージリスト設定
	 * @param msgList サービスメッセージ情報リスト
	 */
	public setServiceErrorList(msgList: string[]): void {
		if (this.m_ServiceErrorMsgList != null) {
			for (const msg of this.m_ServiceErrorMsgList)
				msgList.push(msg);
		}
	}

	/**
	 * クッキー設定
	 * @param name 名前
	 * @param value 値
	 * @param path 有効範囲
	 * @param expire 有効期限(秒)
	 */
	public setCookie(name: string, value: string, path:string='/', expire?: number): void {
		this.addDebugInfoLog(`setCookie('${name}'. '${value}'. '${path}')`);
		let cookie = `${name}=${encodeURIComponent(value)}`;
		if (path)
			cookie = cookie + `; path=${path}`;
		if (expire) {
			const date = new Date();
			date.setTime(date.getTime() + 1000*expire);
			cookie = cookie + `; expires=${date.toUTCString()}`;
		}
		document.cookie = cookie;
	}

	/**
	 * クッキー削除
	 * @param name 名前
	 * @param path 有効範囲
	 */
	public removeCookie(name: string, path:string='/'): void {
		this.addDebugInfoLog(`removeCookie('${name}'. '${path}')`);
		let cookie = `${name}=`;
		if (path)
			cookie = cookie + `; path=${path}`;
		const date = new Date();
		date.setTime( date.getTime() - 1 );		
		document.cookie = cookie + `; expires=${date.toUTCString()}`;
	}

	/**
	 * クッキー値取得
	 * @param name 名前
	 * @returns クッキー値
	 */
	public getCookie(name: string): string {
		const cookieStr = document.cookie;
		name += '=';
		if (cookieStr) {
			let sidx = cookieStr.indexOf(name);
			if (sidx >= 0) {
				sidx = sidx+name.length;
				let eidx = cookieStr.indexOf(';', sidx);
				if(eidx < 0)
					eidx = cookieStr.length;
				if (eidx >= 0)
					return decodeURIComponent(cookieStr.substring(sidx, eidx));
			}
		}
		return null;
	}

	/**
	 * クッキーリスト取得
	 * @returns クッキーリスト
	 */
	public getCookieList(): WprKeyValue[] {
		const clist = new Array();
		const cookieStr = document.cookie;
		if (cookieStr) {
			const cookies = cookieStr.split(';');
			cookies.forEach(cookie => {
				const slist = cookie.split('=');
				if (slist.length >= 2)
					clist.push(new WprKeyValue(slist[0].trim(), decodeURIComponent(slist[1].trim())));
			});
		}
		return clist;
	}

	/**
	 * ローカルストレージデータ取得
	 * @param key 格納キー
	 * @param data 格納データ
	 */
	public getStorageItem(key: string): any {
		const cryptKey = (key + '78012378901230123459456859467612').slice(0, 32);
		let data = localStorage.getItem(key);
		if (data == null)
			return null;
		data = CryptRijndaelUtil.decodePW(cryptKey, data);
		return JSON.parse(decodeURIComponent(data.trim()));
	}

	/**
	 * ローカルストレージデータ設定
	 * @param key 格納キー
	 * @param data 格納データ
	 */
	public setStorageItem(key: string, data: any): void {
		const cryptKey = (key + '78012378901230123459456859467612').slice(0, 32);
		localStorage.removeItem(key);
		localStorage.setItem(key, CryptRijndaelUtil.encodePW(cryptKey, encodeURIComponent(JSON.stringify(data))));
	}

	/**
	 * ローカルストレージデータ削除
	 * @param key 格納キー
	 * @param data 格納データ
	 */
	public removeStorageItem(key: string): void {
		localStorage.removeItem(key);
	}

	/**
	 * 予約ビューモード設定（設定は、次のRenderの後に行われる）
	 * @param name モード名
	 * @param mode モード
	 */
	public setReserveViewMode(name: string, mode: string): void {
		this.m_ReserveViewModeList.push(new WprKeyValue(name, mode));
	}

	/**
	 * 予約ビューモードの更新を行う
	 * @returns trueの場合、予約あり
	 */
	 public updateReserveViewMode(): boolean {
		if (this.m_ReserveViewModeList.length > 0) {
			this.m_ReserveViewModeList.forEach(keyValue => {
				this.setViewMode(keyValue.key, keyValue.value);
			});
			this.m_ReserveViewModeList = new Array();
			this.viewInfo.updateMode();
			return true;
		}
		return false;
	}

	/**
	 * メッセージコンポーネント使用フラグ取得
	 * @returns メッセージコンポーネント使用フラグ
	 */
	public useMessageComponent(): boolean {
		return this.FW.useMessageComponent();
	}

	/**
	 * ValueMap取得
	 * @param id ValueMapのID
	 * @returns ValueMap
	 */
	public getValueMap(id: string): WprValueMap {
		return this.FW.model.getValueMap(id);
	}

	/**
	 * 待機表示開始
	 */
	public startWait(): boolean {
		this.addDebugInfoLog('startWait()');
		return this.FW.startWait();
	}

	/**
	 * 待機表示終了
	 */
	public endWait(): void {
		this.addDebugInfoLog('endWait()');
		this.FW.endWait();
	}

	/**
	 * 待機表示継続
	 * @returns fakseの場合、待機継続中ではない
	 */
	public continueWait(): boolean {
		this.addDebugInfoLog('continueWait()');
		return this.FW.continueWait();
	}
	// --------------------------------------------------------------------------

	// ListInfoMap メソッド  -----------------------------------------------------
	/**
	 * リストコントロール定義追加
	 * @param name 名前
	 * @param description 説明
	 * @param tableName リスト名
	 * @param option オプション
	 * @param checkError エラーチェックフラグ
	 * @returns リスト情報
	 */
	public addListConfig(name: string, description: string, listName: string, option?: IWprConfigOption, checkError: boolean = true): WprListInfo {
		return this.m_ListInfoMap.addListConfig(name, description, listName, option, checkError);
	}

	/**
	 * レイアウトコントロール定義追加
	 * @param name 名前
	 * @param description 説明
	 * @param modelName モデル名
	 * @param option オプション
	 */
	public addListLayoutConfig(name: string, description: string, listName: string, checkError: boolean = true): WprListInfo {
		return this.m_ListInfoMap.addListConfig(name, description, listName, null, checkError);
	}

	/**
	 * リストアクション登録処理
	 * @param name アクション名
	 * @param listName リスト名
	 * @param actionMethod リストアクションメソッド
	 * @param isValid バリデーション実行フラグ
	 * @param isEvent イベント情報使用フラグ
	 */
	protected addListAction(name: string, listName: string, actionMethod: (param: any, item: any) => void, isValid: boolean = false, isEvent: boolean = false): void {
		this.m_ListInfoMap.addListAction(name, listName, actionMethod, isValid, isEvent);
	}

	/**
	 * リストロストフォーカスアクション登録処理
	 * @param name アクション名
	 * @param listName リスト名
	 * @param actionMethod リストアクションメソッド
	 * @param isValid バリデーション実行フラグ
	 * @param isEvent イベント情報使用フラグ
	 */
	protected addListBlurAction(name: string, listName: string, actionMethod: (param: any, item: any) => void, isValid: boolean = false, isEvent: boolean = false): void {
		const aname = `${name}@@blur`;
		const ainfo = this.m_ListInfoMap.addListAction(aname, listName, actionMethod, isValid, isEvent);
		if (ainfo != null)
			ainfo.isBlur = true;
	}

	/**
	 * リストイベントアクション登録処理
	 * @param name アクション名
	 * @param listName リスト名
	 * @param eventName イベント名
	 * @param actionMethod リストアクションメソッド
	 */
	protected addListEventAction(name: string, listName: string, eventName: WprEventName, actionMethod: (name: any, row: WprRowInfo, event: any) => void): void {
		this.m_ListInfoMap.addListEventAction(name, listName, eventName, actionMethod);
	}

	/**
	 * リストコントロール値取得
	 * @param name コントロール名
	 * @param listName リスト名
	 * @param row 行情報
	 * @returns リストコントロール値
	 */
	protected getListControlValue(name: string, listName: string, row: any): any {
		return this.m_ListInfoMap.getControlValue(name, listName, row);
	}

	/**
	 * リストコントロール情報取得
	 * @param name コントロール名
	 * @param listName リスト名
	 * @param row 行情報
	 * @returns リストコントロール情報
	 */
	protected getListControl(name: string, listName: string, row: any): WprControlInfo {
		return this.m_ListInfoMap.getControl(name, listName, row);
	}

	/**
	 * リスト情報取得処理
	 * @param listName リスト名
	 * @returns リスト情報
	 */
	public getListInfo(listName: string): WprListInfo {
		return this.m_ListInfoMap.getListInfo(listName);
	}

	/**
	 * リストデータ設定処理
	 * @param tableName リスト名
	 * @param tableData リストデータ
	 */
	public abstract setListData(listName: string, listData: Array<any>): void;

	/**
	 * リストデータ追加処理
	 * @param listName リスト名
	 * @param data 追加データ
	 */
	public abstract pushListData(listName: string, data: any): void;

	/**
	 * リストデータ挿入処理
	 * @param listName リスト名
	 * @param index 挿入位置
	 * @param data 追加データ
	 */
	public abstract insertListData(listName: string, index: number, data: any): void;

	 /**
	  * リストデータ置換え処理
	  * @param listName リスト名
	  * @param before 置換え前データ
	  * @param after 置換え後データ
	  */
	public abstract replaceListData(listName: string, before: any, after: any): void;
 
	 /**
	  * リストデータ削除処理
	  * @param listName リスト名
	  * @param delData 削除データ
	  */
	public abstract deleteListData(listName: string, delData: any): void;
 
	 /**
	  * リストデータ更新処理
	  * @param listName リスト名
	  */
	public abstract updateListData(listName: string): void;
 
	/**
	 * 行再表示処理
	 * @param listName リスト名
	 * @param row 行データ 
	 * @returns 
	 */
	 public abstract refreshRowView(listName: string, row: any): boolean;

	 /**
	  * 行データ選択処理
	  * @param listName リスト名
	  * @param rowData 行データ
	  * @returns falseの場合、選択失敗
	  */
	public abstract selectListData(listName: string, rowData: any): boolean;
 
	/**
	 * 行データ選択ロック開始
	 * @param listName リスト名
	 */
	public abstract startSelectLock(listName: string): void;

	/**
	 * 行データ選択ロック終了
	 * @param listName リスト名
	 */
	 public abstract endSelectLock(listName: string): void;

	/**
	 * リストデータをビューに反映する
	 * @param listName リスト名
	 */
	public abstract refreshListView(listName?: string): void;

	/**
	 * リストデータをビューに反映する
	 * @param listName リスト名
	 */
	public abstract refreshListModel(listName?: string): void;

	/**
	 * リスト情報クリア処理
	 */
	public abstract clearListInfo(): void;
	
	/**
	 * 行情報のインデクス取得
	 * @param listName リスト名
	 * @param row 行情報
	 * @returns 行情報のインデクス
	 */
	public abstract getListRowIndex(listName: string, row: any): number;

	/**
	 * リストデータソート処理
	 * @param listName リスト名
	 * @param item ソートアイテム
	 * @param subModelList サブモデルリスト
	 * @param isAsc 昇順フラグ
	 */
	public abstract sortListData(listName: string, item: string,subModelList: string[], isAsc: boolean): void;

	/**
	 * リストデータ総数設定（ページング時に使用）
	 * @param listName リスト名
	 * @param total リストデータ総数
	 */
	public abstract setListTotalCount(listName: string, total: number): void;

	/**
	 * リストデータ総数取得（ページング時に使用）
	 * @param listName リスト名
	 * @returns リストデータ総数
	 */
	public abstract getListTotalCount(listName: string): number;
	// --------------------------------------------------------------------------

	// private メソッド  ---------------------------------------------------------
	/**
	 * モデルマップ取得
	 * @returns モデルマップ
	 */
	private getModelMap(): Map<string, string> {
		if (!this.m_ModelMap)
			this.m_ModelMap = this.FW.model.getDecoNameMap('ModelName', this);
		return this.m_ModelMap;
	}

	/**
	 * エラーリスト再表示処理
	 * @param error エラーインターフェース
	 */
	private refreshViewErrorList(error?: IWprError): void {
		let msgList = new Array();
		this.getViewErrorList(msgList);
		if (msgList.length === 0)
			msgList = null;
		this.refreshViewErrorMessage(msgList, error);
	}

	/**
	 * サービスエラーリスト再表示処理
	 * @param error サービスエラーインターフェース
	 */
	private refreshServiceErrorList(error?: IWprServiceError): void {
		let msgList = new Array();
		this.getServiceErrorList(msgList);
		if (msgList.length === 0)
			msgList = null;
		this.refreshServiceErrorMessage(msgList, error);
	}

	/**
	 * デバックログメッセージ追加
	 * @param level 種別
	 * @param msg メッセージ
	 */
	private addDebugLogMessage(level: WprLogLevel, msg: string): void {
		if (this.m_DebugLogList.length > 0) {
			const prev = this.m_DebugLogList[this.m_DebugLogList.length-1];
			if (prev.message === msg) {
				prev.count++;
				return;
			}
		}
		const log = new WprDebugLogInfo(WprErrorInfo.getDateString(new Date()), WprErrorInfo.getStrLevel(level), msg);
		this.m_DebugLogList.push(log);
	}
	// --------------------------------------------------------------------------
}
