import { WprListData } from '../../../../../wpr-framework/view/deco/WprListData';
import { WprModel } from '../../../../../wpr-framework/view/deco/WprModel';
import { WprDI_ScopeModel } from '../../../../../wpr-framework/view/di/WprDI_ScopeModel';
import { TvBaseModel } from '../../../../models/mypage/tv/TvBaseModel';
import { TvCourseListModel } from '../../../../models/mypage/tv/TvCourseListModel';
import { TvCourseModel } from '../../../../models/mypage/tv/TvCourseModel';
import { ItscomTvService } from '../../../../service/ItscomTvService';
import { MypageBaseView } from '../../../base/MypageBaseView';
import { ProgressBarData } from '../../../common/progress_bar/ProgressBarView';
import { TvNewWizardModel } from '../../../wizard/TvNewWizardFlow';
import { MypageWizardMngr } from '../../../wizard/models/MypageWizardMngr';
import { TvOptionListModel } from '../../../../models/mypage/tv/TvOptionListModel';
import { ObjectUtil } from '../../../../common/util/ObjectUtil';
import { Contract } from '../../../../models/bss/contract/Contract';
import { Option } from "../../../../models/bss/contract/Option";
import { WprViewMode } from '../../../../../wpr-framework/view/deco/WprViewMode';
import { OptionListMode } from './mode/OptionListMode';
import MypageDispdivcdConstants from '../../../../common/MypageDispdivcdConstants';
import MypageCodeTypeConstants from '../../../../common/MypageCodeTypeConstants';

/**
 * テレビ変更選択画面
 */
export class ModTvSelect1View extends MypageBaseView {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() { super('ModTvSelect1View'); }
	// --------------------------------------------------------------------------

	// モデル  ------------------------------------------------------------------
	@WprDI_ScopeModel('MypageWizardMngr')
	private m_MypageWizardMngr: MypageWizardMngr			= null;			// 画面ウィザード管理クラス
	@WprModel('courseMessage')	
	private m_courseMessage: string	= null;									// 警告用コース名
	@WprListData('TvCourseModel')
	private m_TvCourseModel: TvCourseModel[]	= null;						// テレビ_コースモデル
	@WprListData('BeforeTvOptionListModel')
	private m_BeforeTvOptionListModel: TvOptionListModel[]	= null;	// テレビオプションリストモデル
	@WprModel('BeforeTvBaseModel')
	private m_BeforeTvBaseModel: TvBaseModel	= null;	// テレビ基本情報モデル
	// --------------------------------------------------------------------------

	// サブビュー/モード  --------------------------------------------------------
	@WprViewMode
	private m_OptionListMode = new OptionListMode();	// OptionListMode
	// --------------------------------------------------------------------------

	// private変数 --------------------------------------------------------------
	private m_AfterTvBaseModel: TvBaseModel	= null;	// テレビ基本情報モデル
	private m_TvNewWizardModel: TvNewWizardModel = new TvNewWizardModel();
	private m_Contract: Contract = null;
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** ウィザード定義 */
	public get mngr(): MypageWizardMngr 				{ return this.m_MypageWizardMngr; }
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * コントロール定義設定
	 */
	public onRegisterConfig(): void {
		this.addConfig('courseMessage', '警告用コース名', 'courseMessage', { bindModel: true });
		this.addConfig('course', 'コース', 'BeforeTvBaseModel');
		this.addConfig('amount', '金額', 'BeforeTvBaseModel');
		this.addConfig('contractStatus', 'コースステータス', 'BeforeTvBaseModel');
		this.addConfig('aCas', 'A-CAS番号', 'BeforeTvBaseModel');
		this.addConfig('bCas', 'B-CAS番号', 'BeforeTvBaseModel');
		this.addConfig('cCas', 'C-CAS番号', 'BeforeTvBaseModel');
		this.addListConfig('option', 'オプション', 'BeforeTvOptionListModel');
		this.addListConfig('status', 'ステータス', 'BeforeTvOptionListModel');
		this.addConfig('terminal', '機器名', 'BeforeTvBaseModel');
		this.addConfig('applyType', '購入、レンタル ( 表示用 )', 'BeforeTvBaseModel');
		this.addConfig('magazine', 'マガジン ( 表示用 )', 'BeforeTvBaseModel');
	}

	/**
	 * アクション登録
	 */
	public onRegisterAction(): void {
		this.addAction('next', this.onNext);
		this.addAction('back', this.onBack);
		this.addAction('helpLink', this.onHelpLink);
	}

	/**
	 * ビュー表示通知
	 */
	public onShowView(): void {
		this.m_MypageWizardMngr.initView(this, (result) => {
			this.registAccessLog();
			this.setStorageItem('b_url', window.location.href);
			const sendData: ProgressBarData = new ProgressBarData();
			sendData.progressBarList = this.m_MypageWizardMngr.getProgressModelList(this);
			this.sendChildData('fP_ev_progressBarIntital', sendData);
			this.m_BeforeTvBaseModel = this.m_MypageWizardMngr.getCache().m_BeforeTvBaseModel;
			this.m_AfterTvBaseModel = this.m_MypageWizardMngr.getCache().m_TvBaseModel;
			this.m_TvCourseModel = this.m_MypageWizardMngr.getCache().m_TvCourseModel;
			this.m_BeforeTvOptionListModel = this.m_MypageWizardMngr.getCache().m_BeforeTvOptionListModel;
			this.m_Contract = this.m_MypageWizardMngr.getCache().m_Contract;
			this.m_AfterTvBaseModel.rental = false;
			this.m_AfterTvBaseModel.noRental = false;
			//契約情報取得
			const contractId = this.m_MypageWizardMngr.getParams().contractId;
			if (ObjectUtil.isNotNullOrEmptyStr(contractId)) {
				this.m_Contract = this.m_MypageWizardMngr.getContract(this, contractId);
			}
			//equipmentTypeCDがZZZCM、ZZSTB、nullの場合10000に変更
			const equipment = this.m_Contract.equipment[0];
			const equ = this.m_MypageWizardMngr.getMCommonList(this, 'Add_net', 'equ', null, null);
			const zzzcm = equ.filter(item => item.category3 === 'req')[0].commonValue;
			const zzstb = equ.filter(item => item.category3 === 'req2')[0].commonValue;
			const rep = equ.filter(item => item.category3 === 'rep')[0].commonValue;
			if (equipment.equipmentTypeCD) {
				if (equipment.equipmentTypeCD === zzzcm || equipment.equipmentTypeCD === zzstb)
					equipment.equipmentTypeCD = rep;
			}
			else 
				equipment.equipmentTypeCD = rep;
			//ダミー端末チェック
			this.m_BeforeTvBaseModel.dummy = true;
			const dummy = this.m_MypageWizardMngr.getMCommonList(this, 'Dummy_Equipment', 'dummyid', null, null)[0].commonValue;
			if (equipment.equipmentKindCD === 'S') {
				if ((equipment.equipmentID && equipment.equipmentID.includes(dummy)) || (equipment.equipmentID2 && equipment.equipmentID2.includes(dummy)))
					this.m_BeforeTvBaseModel.dummy = false;
			}
			if (equipment.equipmentKindCD === 'C' && equipment.equipmentID.includes(dummy)) 
					this.m_BeforeTvBaseModel.dummy = false;
			//基本情報表示
			const service = this.m_MypageWizardMngr.getServiceList(this, MypageDispdivcdConstants.TV_SERVICE);
			this.m_BeforeTvBaseModel.courseCd = this.m_Contract.serviceCD;
			this.m_BeforeTvBaseModel.course = service[0].display.dispnamep;
			this.m_BeforeTvBaseModel.amount = this.m_MypageWizardMngr.getTaxFee(this, service[0].service.payment[0].amount.toString());
			this.m_BeforeTvBaseModel.contractStatus = this.m_MypageWizardMngr.getMDisplayList(this, MypageDispdivcdConstants.ITSCOMHOME_SERVICESTATUS, '').filter(item => item.namecd === this.m_Contract.statusCD)[0].dispnamep;
			//AP無料サービス判定
			const apService = this.m_MypageWizardMngr.getMGenralList(this, MypageCodeTypeConstants.AP_FREE_CODE, '');
			const constractService = apService.filter(item => item.generalCd === this.m_BeforeTvBaseModel.courseCd);
			if (constractService.length > 0)
				this.setViewMode('AP用メッセージ', '表示');
			else
				this.setViewMode('AP用メッセージ', '非表示');
			//機器名、提供方法
			for (const eqp of this.m_Contract.equipment) {
				if (eqp.equipmentTypeCD === '10059') {
					if (eqp.equipmentID.slice(0, 4) === '0100')
						this.m_BeforeTvBaseModel.aCas = eqp.equipmentID;
					else
						this.m_BeforeTvBaseModel.bCas = eqp.equipmentID;
				}
				else if (eqp.equipmentTypeCD === '10060')
					this.m_BeforeTvBaseModel.cCas = eqp.equipmentID;
				else if (eqp.equipmentTypeCD !== rep) {
					this.m_BeforeTvBaseModel.terminal = this.m_MypageWizardMngr.getMDisplayList(this, MypageDispdivcdConstants.ITSCOMHOME_TERMINALTYPE, '').filter(item => item.namecd === eqp.equipmentTypeCD)[0].dispnamep;
					this.m_BeforeTvBaseModel.applyType = this.m_MypageWizardMngr.getMDisplayList(this, MypageDispdivcdConstants.ITSCOMHOME_TERMINALSTATUS, '').filter(item => item.namecd === eqp.rentalFlag)[0].dispnamep;
					if (eqp.rentalFlag === '9')
						this.m_BeforeTvBaseModel.applyType = null;
				}
			}
			//支払い方法
			if (this.m_MypageWizardMngr.getViewInfo().customerPayment) {
				const paymentCd  = this.m_MypageWizardMngr.getViewInfo().customerPayment[0].payment[0].paymentTypeCD;
				this.m_AfterTvBaseModel.paymentDispname = this.m_MypageWizardMngr.getMDisplayList(this, 'CD0005', '').filter(item => item.namecd === paymentCd)[0].dispnamep;
			}
			//マガジン
			this.m_BeforeTvBaseModel.magazine = '未契約';
			this.m_BeforeTvBaseModel.magazineDisp = '未契約';						
			const amount = this.m_MypageWizardMngr.getMCommonList(this, 'charge_mag_info', 'price', null, null)[0].commonValue;
			this.m_BeforeTvBaseModel.magazineFee = this.m_MypageWizardMngr.getTaxFee(this, amount);
			if (this.m_BeforeTvOptionListModel.length === 0) {
				for (const option of this.m_Contract.option) {
					const magazineOp = this.m_MypageWizardMngr.getMCommonList(this, 'srvtv', 'magazine_op', null, null)[0].commonValue;
					if (option.optionCD === magazineOp) {
						this.m_BeforeTvBaseModel.magazine = '契約中';
						const maxList = this.m_MypageWizardMngr.getMCommonList(this, 'Mod_tv_confirm', 'max_ftth', null, null).concat(this.m_MypageWizardMngr.getMCommonList(this, 'Mod_tv_confirm', 'max', null, null));

						for (const service of this.m_MypageWizardMngr.getViewInfo().contractModel.contractList) {
							const maxService = maxList.filter(item => item.commonValue === service.serviceCD);
							if (maxService.length > 0)
								this.m_BeforeTvBaseModel.magazineDisp = '契約中（月額無料）';
							else
								this.m_BeforeTvBaseModel.magazineDisp = '契約中（月額' + amount + '円）';
						}
					}
					//オプション
					const model = new TvOptionListModel();
					const optionName = this.m_MypageWizardMngr.getMDisplayList(this, MypageDispdivcdConstants.TV_OPTION, '').filter(item => item.namecd === option.optionCD);
					if (optionName.length > 0) {
						if (option.statusCD === '1') {
							model.option = optionName[0].dispnamep;
							model.status = this.m_MypageWizardMngr.getMDisplayList(this, MypageDispdivcdConstants.TV_OPTIONSTATUS, '').filter(item => item.namecd === option.statusCD)[0].dispnamep;
							this.m_BeforeTvOptionListModel.push(model);
						}
					}
				}
				if (this.m_BeforeTvOptionListModel.length === 0)
					this.setViewMode('変更前オプション', 'なし');
			}
			this.setViewData('sentenceData', this.m_Contract.option.length + 1);
			this.setListData('BeforeTvOptionListModel', this.m_BeforeTvOptionListModel);
			//お得パック表示設定
			const contract = this.m_MypageWizardMngr.getViewInfo().contractModel;
			const paymentList  = this.m_MypageWizardMngr.getViewInfo().customerPayment;
			if (paymentList && this.m_MypageWizardMngr.showOtokuPlan(this, paymentList[0].payment[0], contract.planList)) 
				this.setViewMode('お得パックエリア','表示');
			//画面サイズによるレイアウトの設定
			if (window.outerWidth > 440) {
				this.setViewMode('オプション','PC');
			}
			else {
				this.setViewMode('オプション','SP');
			}
			this.refreshView();
			//コース一覧取得
			if (this.m_TvCourseModel.length === 0) {
				const viewInfo = this.m_MypageWizardMngr.getViewInfo();
				this.m_TvNewWizardModel.getTvViewInfo(this, viewInfo, this.m_BeforeTvBaseModel.terminal, this.m_BeforeTvBaseModel.courseCd, (result) => {
					//画面表示用に変換
					if (result) {
						let changeflg = true;	//変更なしフラグ
						let displayList:TvCourseListModel[] = new Array();
						const nameList = result.srvtvCourseName.reverse();
						if (result.tanakoFlg) {
							for (const tanako of result.mstInfo) {
								displayList.push(tanako);
							}
						}
						else {
							for (const courseName of nameList) {
								if (changeflg) {
									//契約中のサービスCD
									let curServiceCd = null;
									let service = null;
									if (result.tanako.tanakoModel.tanakoList && result.tanako.tanakoModel.tanakoList.length > 0) {
										service = result.tanako.tanakoModel.tanakoList.filter(item => item.tanakoServiceCd === this.m_BeforeTvBaseModel.courseCd);
										if (service.length > 0) {
											this.m_BeforeTvBaseModel.tanakoSarviceCd = service[0].normalServiceCd;
											curServiceCd = service[0].normalServiceCd;
										}
										else 
											curServiceCd = this.m_BeforeTvBaseModel.courseCd;
									}
									else 
										curServiceCd = this.m_BeforeTvBaseModel.courseCd;
									//HFC,FTTH判定
									let ftthFlg = false;
									let genecd = null;
									genecd = this.m_MypageWizardMngr.getMDisplayList(this, MypageDispdivcdConstants.TV_FTTH_SERVICE, '').filter(item => item.namecd === curServiceCd);
									if (genecd.length === 0) {
										genecd = this.m_MypageWizardMngr.getMDisplayList(this, MypageDispdivcdConstants.TV_MAIJU_SERVICE, '').filter(item => item.namecd === curServiceCd);
									}
									else 
										ftthFlg = true;
									//契約中のサービス情報取得
									let serviceCd = null;
									if (genecd && genecd.length !== 0) {
										if (this.m_MypageWizardMngr.getViewInfo().customer.building[0].buildingLineTypeCD === '2' && !ftthFlg) {
											if (genecd[0].genecd === '3')
												genecd[0].genecd = '4';
												serviceCd = this.m_MypageWizardMngr.getMDisplayList(this, MypageDispdivcdConstants.TV_FTTH_SERVICE, '').filter(item => item.genecd === genecd[0].genecd && item.dispnamep === this.m_BeforeTvBaseModel.course)[0].namecd;
										}
										else if (this.m_MypageWizardMngr.getViewInfo().customer.building[0].buildingLineTypeCD === '2' && ftthFlg) {
											if (result.type === MypageDispdivcdConstants.TV_MAIJU_SERVICE && result.tanako.firstService !== curServiceCd)
												serviceCd = this.m_MypageWizardMngr.getMDisplayList(this, MypageDispdivcdConstants.TV_MAIJU_SERVICE, '').filter(item => item.genecd === genecd[0].genecd && item.dispnamep === this.m_BeforeTvBaseModel.course)[0].namecd;
											else 
												serviceCd = this.m_MypageWizardMngr.getMDisplayList(this, MypageDispdivcdConstants.TV_FTTH_SERVICE, '').filter(item => item.genecd === genecd[0].genecd && item.dispnamep === this.m_BeforeTvBaseModel.course)[0].namecd;
										}									}
									//変更なし表示判定
									let changeDispFlg = false;	//変更なし表示フラグ
									let tanakoCd = null;		//店子サービスCD
									if (service && service.length > 0)
										tanakoCd = service[0].tanakoServiceCd;
										let mst = null;
										if (genecd && genecd.length > 0)
											mst = result.mstInfo.filter(item => item.detail.dispnamep === courseName.commonValue && item.detail.genecd === genecd[0].genecd);
										if (mst && mst.length > 0) {
											const model = new TvCourseListModel();
											model.amount = mst[0].amount;
											model.terminal = '変更なし';
											model.detail = mst[0].detail;
											changeDispFlg = true;
											displayList.push(model);
										}
									let targetList = result.mstInfo.filter(item => item.detail.dispnamep === courseName.commonValue);
									//まいにち充実プラン
									if (result.type === MypageDispdivcdConstants.TV_MAIJU_SERVICE) {
										const firstService = this.mngr.getMDisplayList(this, MypageDispdivcdConstants.TV_FTTH_SERVICE, '').filter(item => item.namecd === result.tanako.firstService);
										if (targetList.length > 0) {
											for (const target of targetList) {
												if (firstService.length > 0 && curServiceCd!==firstService[0].namecd && firstService[0].genecd != '4' && target.detail.genecd === '4') {
													//何もしない
												}
												else
													displayList =  displayList.concat(target);
											}
										}
									}
								}
							}
						}
						const map = new Map<string, TvCourseListModel[]>();
						this.m_courseMessage = '';
						for (const data of displayList) {
							if (result.tanakoFlg) {
								if (!map.has(data.serviceName))
									map.set(data.serviceName, []);
								map.get(data.serviceName).push(data);
								const message = this.m_TvNewWizardModel.checkMessage1(this, data);
								if (message !== '')
									this.m_courseMessage += '「' + message + '」';
							}
							else {
								if (!map.has(data.detail.dispnamep))
									map.set(data.detail.dispnamep, []);
								map.get(data.detail.dispnamep).push(data);
							}
						}
						for (const key of map.keys()) {
							this.m_TvNewWizardModel.checkMessage2(this, key);
							const model = new TvCourseModel();
							model.course = key;
							const list  =  map.get(key).sort(function(a, b) { 
								if (a.terminal === '変更なし') return -1;
								else if (b.terminal === '変更なし') return 1;
								else if (a.amount !== b.amount) return Number(b.amount) - Number(a.amount);
								else return 1 });
							model.detailList = list;
							this.m_TvCourseModel.push(model);
						}
						this.m_TvCourseModel[0].detailList[0].select = true;
						this.setList();
					}

				});
			}
			else 
				this.setList();
			this.refreshView();
		});
	}

	/**
	 * データ受信処理
	 * @param dataName データ名
	 * @param data データ
	 */
	public onReceiveData(dataName: string, data?: any): void {
		if (dataName === 'checkData') {
			for (const data of this.m_TvCourseModel) {
				this.sendChildData('checkBox', 'true', data.course);
				for (const listData of data.detailList) {
					if (listData.select)
						this.setViewMode('check', data.course + ':' + listData.terminal);
				}
			}
		}
	}
	// --------------------------------------------------------------------------

	// アクション  ---------------------------------------------------------------
	/**
	 * 次へ
	 */
	public onNext(param: any): void {
		this.setBaseInfo();
		this.m_MypageWizardMngr.goNext(this);
	}

	/**
	 * 戻る
	 */
	public onBack(param: any): void {
		this.m_MypageWizardMngr.goBack(this);
	}

	/**
	 * 申込みについてご不明な方はこちら＞
	 */
	public onHelpLink(param: any): void {
		if (this.m_MypageWizardMngr.getViewInfo().customer.building[0].buildingLineTypeCD === '2')
			window.open('http://www.itscom.net/hikari/service/tv/');
		else
			window.open('http://www.itscom.net/service/cabletv/');
	}
	// --------------------------------------------------------------------------

	// privateメソッド -----------------------------------------------------------
	/**
	 * リストセット
	 */
	private setList(): void {
		this.setListData('TvCourseModel', this.m_TvCourseModel);
		for (const data of this.m_TvCourseModel) {
			this.sendChildData('serviceList', data, data.course);
		}
		this.refreshView();
	}

	/**
	 * 選択情報セット
	 */
	private setBaseInfo(): void {
		this.refreshModel();
		for (const model of this.m_TvCourseModel) {
			for (const course of model.detailList) {
				if (course.select) {
					this.m_AfterTvBaseModel.course = model.course;
					this.m_AfterTvBaseModel.courseCd = course.detail.namecd;
					this.m_AfterTvBaseModel.amount = course.amount;
					this.m_AfterTvBaseModel.terminalCd = course.detail.genecd;
					if (!course.detail.genecd)
						this.m_AfterTvBaseModel.terminal = course.terminal;
					this.m_AfterTvBaseModel.magazineApply = true;
					this.m_AfterTvBaseModel.otokuUnapply = true;
					this.m_AfterTvBaseModel.type = course.detail.dispdivcd;
					if (course.terminal === '変更なし') {
						this.m_AfterTvBaseModel.terminal = this.m_BeforeTvBaseModel.terminal;
						this.m_AfterTvBaseModel.terminalDisp = '変更なし';
					}
					else {
						this.m_AfterTvBaseModel.terminal = course.terminal;
						this.m_AfterTvBaseModel.terminalDisp = course.terminal;
					}

				}
			}
		}
		this.refreshModel();
	}
	// --------------------------------------------------------------------------
}
